import { FormattedMessage, injectIntl, intlShape } from '../util/reactIntl';

const options = [
  //outerwear
  {
    key: 'coat',
    label: <FormattedMessage id="productType.coat" />,
    productSubcategory: 'outerwear',
  },
  {
    key: 'trenchCoat',
    label: <FormattedMessage id="productType.trenchCoat" />,
    productSubcategory: 'outerwear',
  },
  {
    key: 'jacket',
    label: <FormattedMessage id="productType.jacket" />,
    productSubcategory: 'outerwear',
  },
  {
    key: 'pufferJacket',
    label: <FormattedMessage id="productType.pufferJacket" />,
    productSubcategory: 'outerwear',
  },
  {
    key: 'leatherJacket',
    label: <FormattedMessage id="productType.leatherJacket" />,
    productSubcategory: 'outerwear',
  },
  {
    key: 'vest',
    label: <FormattedMessage id="productType.vest" />,
    productSubcategory: 'outerwear',
  },
  {
    key: 'blazer',
    label: <FormattedMessage id="productType.blazer" />,
    productSubcategory: 'outerwear',
  },
  {
    key: 'knitwear',
    label: <FormattedMessage id="productType.knitwear" />,
    productSubcategory: 'outerwear',
  },
  {
    key: 'kimono',
    label: <FormattedMessage id="productType.kimono" />,
    productSubcategory: 'outerwear',
  },
  {
    key: 'sweatshirt',
    label: <FormattedMessage id="productType.sweatshirt" />,
    productSubcategory: 'outerwear',
  },

  //top
  { key: 'tshirt', label: <FormattedMessage id="productType.tshirt" />, productSubcategory: 'top' },
  { key: 'blouse', label: <FormattedMessage id="productType.blouse" />, productSubcategory: 'top' },
  { key: 'shirt', label: <FormattedMessage id="productType.shirt" />, productSubcategory: 'top' },
  {
    key: 'camisole',
    label: <FormattedMessage id="productType.camisole" />,
    productSubcategory: 'top',
  },
  {
    key: 'cropTop',
    label: <FormattedMessage id="productType.cropTop" />,
    productSubcategory: 'top',
  },

  //bottom
  {
    key: 'skirt',
    label: <FormattedMessage id="productType.skirt" />,
    productSubcategory: 'bottom',
  },
  {
    key: 'shorts',
    label: <FormattedMessage id="productType.shorts" />,
    productSubcategory: 'bottom',
  },
  {
    key: 'bermudas',
    label: <FormattedMessage id="productType.bermudas" />,
    productSubcategory: 'bottom',
  },
  {
    key: 'trousers',
    label: <FormattedMessage id="productType.trousers" />,
    productSubcategory: 'bottom',
  },
  {
    key: 'jeans',
    label: <FormattedMessage id="productType.jeans" />,
    productSubcategory: 'bottom',
  },
  {
    key: 'leggings',
    label: <FormattedMessage id="productType.leggings" />,
    productSubcategory: 'bottom',
  },
  {
    key: 'sweatpants',
    label: <FormattedMessage id="productType.sweatpants" />,
    productSubcategory: 'bottom',
  },

  //dress
  {
    key: 'shortDress',
    label: <FormattedMessage id="productType.shortDress" />,
    productSubcategory: 'dress',
  },
  {
    key: 'midiDress',
    label: <FormattedMessage id="productType.midiDress" />,
    productSubcategory: 'dress',
  },
  {
    key: 'longDress',
    label: <FormattedMessage id="productType.longDress" />,
    productSubcategory: 'dress',
  },
  {
    key: 'shirtDress',
    label: <FormattedMessage id="productType.shirtDress" />,
    productSubcategory: 'dress',
  },

  //jumpsuit
  {
    key: 'shortJumpsuit',
    label: <FormattedMessage id="productType.shortJumpsuit" />,
    productSubcategory: 'jumpsuit',
  },
  {
    key: 'midiJumpsuit',
    label: <FormattedMessage id="productType.midiJumpsuit" />,
    productSubcategory: 'jumpsuit',
  },
  {
    key: 'longJumpsuit',
    label: <FormattedMessage id="productType.longJumpsuit" />,
    productSubcategory: 'jumpsuit',
  },

  //beachwear
  {
    key: 'swimTop',
    label: <FormattedMessage id="productType.swimTop" />,
    productSubcategory: 'beachwear',
  },
  {
    key: 'swimBottom',
    label: <FormattedMessage id="productType.swimBottom" />,
    productSubcategory: 'beachwear',
  },
  {
    key: 'swimOnePiece',
    label: <FormattedMessage id="productType.swimOnePiece" />,
    productSubcategory: 'beachwear',
  },
  {
    key: 'kaftan',
    label: <FormattedMessage id="productType.kaftan" />,
    productSubcategory: 'beachwear',
  },
  {
    key: 'kimono',
    label: <FormattedMessage id="productType.kimono" />,
    productSubcategory: 'beachwear',
  },

  //ski & snowboard
  {
    key: 'skiSnowboardJacket',
    label: <FormattedMessage id="productType.skiAndSnowboardJacket" />,
    productSubcategory: 'skiSnowboard',
  },
  {
    key: 'skiSnowboardTrousers',
    label: <FormattedMessage id="productType.skiAndSnowboardTrousers" />,
    productSubcategory: 'skiSnowboard',
  },
  {
    key: 'skiSnowboardJumpsuit',
    label: <FormattedMessage id="productType.skiAndSnowboardJumpsuit" />,
    productSubcategory: 'skiSnowboard',
  },

  //Athleisure
  {
    key: 'sportsBra',
    label: <FormattedMessage id="productType.sportsBra" />,
    productSubcategory: 'athleisure',
  },
  {
    key: 'leggings',
    label: <FormattedMessage id="productType.leggings" />,
    productSubcategory: 'athleisure',
  },
  {
    key: 'shorts',
    label: <FormattedMessage id="productType.shorts" />,
    productSubcategory: 'athleisure',
  },
  {
    key: 'sportsTop',
    label: <FormattedMessage id="productType.sportsTop" />,
    productSubcategory: 'athleisure',
  },
  {
    key: 'sportsSkirt',
    label: <FormattedMessage id="productType.sportsSkirt" />,
    productSubcategory: 'athleisure',
  },
  {
    key: 'sweatshirt',
    label: <FormattedMessage id="productType.sweatshirt" />,
    productSubcategory: 'athleisure',
  },
  {
    key: 'sweatpants',
    label: <FormattedMessage id="productType.sweatpants" />,
    productSubcategory: 'athleisure',
  },

  //set
  {
    key: 'fashionSet',
    label: <FormattedMessage id="productType.fashionSet" />,
    productSubcategory: 'set',
  },
  { key: 'skiSet', label: <FormattedMessage id="productType.skiSet" />, productSubcategory: 'set' },
  {
    key: 'snowboardSet',
    label: <FormattedMessage id="productType.snowboardSet" />,
    productSubcategory: 'set',
  },
  {
    key: 'sportSet',
    label: <FormattedMessage id="productType.sportSet" />,
    productSubcategory: 'set',
  },
  {
    key: 'beachwearSet',
    label: <FormattedMessage id="productType.beachwearSet" />,
    productSubcategory: 'set',
  },

  //bags
  {
    key: 'toteBag',
    label: <FormattedMessage id="productType.toteBag" />,
    productSubcategory: 'bags',
  },
  {
    key: 'shoulderBag',
    label: <FormattedMessage id="productType.shoulderBag" />,
    productSubcategory: 'bags',
  },
  {
    key: 'miniBag',
    label: <FormattedMessage id="productType.miniBag" />,
    productSubcategory: 'bags',
  },
  {
    key: 'backpack',
    label: <FormattedMessage id="productType.backpack" />,
    productSubcategory: 'bags',
  },

  //jewellery
  {
    key: 'earrings',
    label: <FormattedMessage id="productType.earrings" />,
    productSubcategory: 'jewellery',
  },
  {
    key: 'necklace',
    label: <FormattedMessage id="productType.necklace" />,
    productSubcategory: 'jewellery',
  },
  {
    key: 'bracelet',
    label: <FormattedMessage id="productType.bracelet" />,
    productSubcategory: 'jewellery',
  },
  {
    key: 'anckleBracelet',
    label: <FormattedMessage id="productType.anckleBracelet" />,
    productSubcategory: 'jewellery',
  },
  {
    key: 'bodyJewellery',
    label: <FormattedMessage id="productType.bodyJewellery" />,
    productSubcategory: 'jewellery',
  },
  {
    key: 'ring',
    label: <FormattedMessage id="productType.ring" />,
    productSubcategory: 'jewellery',
  },
  {
    key: 'watch',
    label: <FormattedMessage id="productType.watch" />,
    productSubcategory: 'jewellery',
  },

  //belts
  {
    key: 'hipsBelt',
    label: <FormattedMessage id="productType.hipsBelt" />,
    productSubcategory: 'belts',
  },
  {
    key: 'waistBelt',
    label: <FormattedMessage id="productType.waistBelt" />,
    productSubcategory: 'belts',
  },

  //for sleeping
  {
    key: 'woodenCot',
    label: <FormattedMessage id="productType.woodenCot" />,
    productSubcategory: 'forSleeping',
  },
  {
    key: 'mobileCot',
    label: <FormattedMessage id="productType.mobileCot" />,
    productSubcategory: 'forSleeping',
  },
  {
    key: 'bedPartition',
    label: <FormattedMessage id="productType.bedPartition" />,
    productSubcategory: 'forSleeping',
  },
  {
    key: 'bedRing',
    label: <FormattedMessage id="productType.bedRing" />,
    productSubcategory: 'forSleeping',
  },
  {
    key: 'nursery',
    label: <FormattedMessage id="productType.nursery" />,
    productSubcategory: 'forSleeping',
  },
  {
    key: 'blanket',
    label: <FormattedMessage id="productType.blanket" />,
    productSubcategory: 'forSleeping',
  },
  {
    key: 'cocoon',
    label: <FormattedMessage id="productType.cocoon" />,
    productSubcategory: 'forSleeping',
  },
  {
    key: 'others',
    label: <FormattedMessage id="productType.others" />,
    productSubcategory: 'forSleeping',
  },

  //for eating
  {
    key: 'canteen',
    label: <FormattedMessage id="productType.canteen" />,
    productSubcategory: 'forEating',
  },
  {
    key: 'bottle',
    label: <FormattedMessage id="productType.bottle" />,
    productSubcategory: 'forEating',
  },
  {
    key: 'bowl',
    label: <FormattedMessage id="productType.bowl" />,
    productSubcategory: 'forEating',
  },
  {
    key: 'diningSet',
    label: <FormattedMessage id="productType.dinningSet" />,
    productSubcategory: 'forEating',
  },
  {
    key: 'others',
    label: <FormattedMessage id="productType.others" />,
    productSubcategory: 'forEating',
  },

  //for organization
  {
    key: 'diaperBasket',
    label: <FormattedMessage id="productType.diaperBasket" />,
    productSubcategory: 'forOrganisation',
  },
  {
    key: 'toyBasket',
    label: <FormattedMessage id="productType.toyBasket" />,
    productSubcategory: 'forOrganisation',
  },
  {
    key: 'shelvesOrganisation',
    label: <FormattedMessage id="productType.organization" />,
    productSubcategory: 'forOrganisation',
  },
  {
    key: 'others',
    label: <FormattedMessage id="productType.others" />,
    productSubcategory: 'forOrganisation',
  },

  //decor
  {
    key: 'paintings',
    label: <FormattedMessage id="productType.painting" />,
    productSubcategory: 'decor',
  },
  {
    key: 'posters',
    label: <FormattedMessage id="productType.posters" />,
    productSubcategory: 'decor',
  },
  {
    key: 'decorativePillows',
    label: <FormattedMessage id="productType.decorativePillows" />,
    productSubcategory: 'decor',
  },
  {
    key: 'carpets',
    label: <FormattedMessage id="productType.carpets" />,
    productSubcategory: 'decor',
  },
  {
    key: 'others',
    label: <FormattedMessage id="productType.others" />,
    productSubcategory: 'decor',
  },

  //furniture
  {
    key: 'chair',
    label: <FormattedMessage id="productType.chair" />,
    productSubcategory: 'furniture',
  },
  {
    key: 'table',
    label: <FormattedMessage id="productType.table" />,
    productSubcategory: 'furniture',
  },
  {
    key: 'sofa',
    label: <FormattedMessage id="productType.sofa" />,
    productSubcategory: 'furniture',
  },
  {
    key: 'chestOfDrawersCupboard',
    label: <FormattedMessage id="productType.chestOfDrawersCupboard" />,
    productSubcategory: 'furniture',
  },
  { key: 'bed', label: <FormattedMessage id="productType.bed" />, productSubcategory: 'furniture' },
  {
    key: 'others',
    label: <FormattedMessage id="productType.others" />,
    productSubcategory: 'furniture',
  },
];

export default options;
