import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './PrivacyPolicy.module.css';

const PrivacyPolicy = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
      <div className={css.textWrapper}>
  <h3 style={{marginTop: '0in', marginRight: '0in', marginBottom: '13.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '19px',  textAlign: 'justify'}}><span style={{fontSize: '17px', lineHeight: '139%', background: '#FEFEFE'}}>&nbsp;Политика за поверителност и обработване на лични данни</span></h3>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>В съответствие с изискванията за информираност по чл. 13 и чл. 14 от Регламент 2016/679 на ЕП и Съвета от 27.04.2016 г. относно защитата на физическите лица във връзка с обработването на лични данни и относно свободното движение на такива данни и за отмяна на Директива 95/46/ЕО (наричан по-долу „Регламент 2016/679“), „НОЛД” ЕООД Ви информира за извършваните от нас дейности по обработване на лични данни, целите, за които обработваме същите, мерките, които предприемаме за тяхната защита, Вашите права и начина за тяхното осъществяване.</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><strong><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>&nbsp;</span></strong></p>
  <ol style={{marginBottom: '0in', marginTop: '0in'}} type={1} start={1}>
    <li style={{marginTop: '11.0pt', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '115%', fontSize: '15px'}}><strong><span style={{fontSize: '15px', lineHeight: '115%', background: '#FEFEFE'}}>Информация за администратора на лични данни</span></strong></li>
  </ol>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>„НОЛД” ЕООД е дружество, регистрирано в Търговския регистър на Агенцията по вписванията с ЕИК 205435275, със седалище и адрес на управление: гр. София, р-н Оборище, ул. „Бачо Киро” № 5, ет. 4, ап. 17, тел: +359 897 976979; e-mail: hello@thenold.com</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><strong><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>&nbsp;</span></strong></p>
  <ol style={{marginBottom: '0in', marginTop: '0in'}} type={1} start={1}>
    <li style={{marginTop: '11.0pt', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '115%', fontSize: '15px'}}><strong><span style={{fontSize: '15px', lineHeight: '115%', background: '#FEFEFE'}}>II. Основания за обработване на личните Ви данни</span></strong></li>
  </ol>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>Ние обработваме Вашите лични данни на следните основания, предвидени като правомерни съгласно чл. 6 от Общия регламент относно защитата на данните:</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>а/ за изпълнение на договор или в процеса на преддоговорни отношения;</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>б/ при предвидено задължение по закон;</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>в/ при изрично съгласие от Ваша страна;</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>&nbsp;</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>Представяме Ви следната по-подробна информация относно обработването на личните Ви данни в зависимост от посоченото по-горе основание, на което ги обработваме:</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><strong><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>&nbsp;</span></strong></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><strong><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>а/ За изпълнение на договор или в процеса на преддоговорни отношения</span></strong></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>Ние обработваме личните Ви данни, за да изпълняваме договорните и преддоговорни задължения и да се ползваме от правата по договорите, сключени с Вас.</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>Целите на тази обработка са следните:</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>- установяване на Вашата самоличност;</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>- управление и изпълнение на Ваша заявка и изпълнение на сключен договор;</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>- администриране на плащането на сумите за промотиране на продукт или колекция в сайта NOLD BY YOU;</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>- запазване на кореспонденция чата на NOLD BY YOU между ползватели на платформата</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>- изготвяне на профил на регистриран потребител;</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>- събиране на адрес, телефон и име с цел предаването им към трета страна Speedy за изготвяне на товарителница по доставка. Тази информация не се предоставя към страната която получава или съответно изпраща пратката.&nbsp;</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>На основание сключения между нас и Вас договор обработваме информация за вида и съдържанието на договорното отношение, както и всяка друга информация, свързана с договорното правоотношение, включително:</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>- лични данни за контакт - адрес за контакт, имейл, телефонен номер;</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>- данни за идентификация<strong>&nbsp;-&nbsp;</strong>трите имена;</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>- данни за направените товарителници чрез &nbsp;API на Спиди;</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>- електронна поща, писма, и чатове с други потребители на NOLD BY YOU;</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>- информация за кредитна или дебитна карта, номер на банкова сметка или друга банкова и платежна информация във връзка с извършваните плащания за промотиране на гардероб или артикул се пазят от трети страни Stripe, Epay, Easypay;</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>Обработката на посочените лични данни за нас се явява задължителна, за да можем да сключим договора с Вас и да го изпълняваме.</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>Ние предоставяме Ваши лични данни на трети лица, като основната ни цел е да Ви предложим качествено, бързо и комплексно обслужване.</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>Лични данни предоставяме на следните категории получатели (администратори на лични данни): пощенски оператори и куриерски фирми;</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><strong><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>&nbsp;</span></strong></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>Данните, събрани на това основание изтриваме 5 години след прекратяване на договорното отношение, независимо дали поради изтичане срока или действието на договора и задълженията по него, разваляне или друго основание. &nbsp;Срокът е определен от 5-годишния давностен срок за възможните претенции от договора.</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><strong><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>&nbsp;</span></strong></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><strong><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>б/ при предвидено задължение по закон</span></strong></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>Възможно е в закона или в друг нормативен акт да е предвидено задължение за нас да обработваме личните Ви данни. В тези случаи ние сме длъжни да извършим обработката, като например:</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>&nbsp;- задължения по Закона за мерките срещу изпиране на пари и Закона за мерките срещу финансиране на тероризма;</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>- изпълнение на задължения във връзка с продажбата от разстояние, продажбата извън търговския обект, предвидени в Закона за защита на потребителите;</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>- предоставяне на информация на Комисията за защита на потребителите или трети лица, предвидени в Закона за защита на потребителите;</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>- предоставяне на информация на Комисията за защита на личните данни във връзка със задължения, предвидени в нормативната уредба за защита на личните данни;</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>- задължения, предвидени в Закона за счетоводството и Данъчно-осигурителния процесуален кодекс и други свързани нормативни актове, във връзка с воденето на законосъобразно счетоводство;</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>- предоставяне на информация на съда и трети лица, в рамките на производство пред съд, съобразно изискванията на приложимите към производството нормативни актове;</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>- удостоверяване на възраст при пазаруване онлайн.</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>Данните, събрани съгласно предвидено задължение в закона, изтриваме след като задължението за събиране и съхранение бъде изпълнено или отпадне. Например: по Закона за счетоводството за съхранение и обработка на счетоводни данни (11 години), при задължения за предоставяне на информация на съда, компетентни държавни органи и др. основания, предвидени в действащото законодателство (5 години).</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>Когато по закон за нас е предвидено задължение, е възможно да предоставим Вашите лични данни на компетентния държавен орган, физическо или юридическо лице.</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><strong><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>в/ след ваше съгласие</span></strong></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>Ние обработваме Вашите лични данни на това основание само след изрично, недвусмислено и доброволно съгласие от Ваша страна. Ние няма да предвиждаме каквито и да е неблагоприятни последици за Вас, ако откажете обработването на личните данни.</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>Съгласието е отделно основание за обработване на личните ви данни и целта на обработката е посочена в него, и не се покрива с целите, изброени в тази политика. Ако ни дадете съответното съгласие и до неговото оттегляне или прекратяване на всякакви договорни отношения с Вас изготвяме подходящи за вас предложения за продукти/услуги.</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>На това основание обработваме само данните, за които сте ни дали изричното си съгласие. Конкретните данни се определят за всеки индивидуален случай. Обикновено данните включват:</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>- имейл;</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>- телефон;</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>- адрес;</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>- имена;</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>На това основание можем да предоставим Вашите данни на маркетинг агенции и трети лица.</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>Предоставените съгласия могат да бъдат оттеглени по всяко време. Оттеглянето на съгласието няма отражение върху изпълнението на договорните задължения. Ако оттеглите съгласието си за обработване на лични данни за някой или всички начини, описани по-горе, ние няма да използваме личните Ви данни и информация за определените по-горе цели.</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>Данните, събрани на това основание, изтриваме при искане от Ваша страна или 1 година след първоначалното им събиране.</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>Уебсайтът на дружеството &nbsp;използва бисквитки съобразно посоченото в Политиката за бисквитките. Тяхното използване се осъществява след дадено от Вас съгласие за това.</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><strong><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>&nbsp;</span></strong></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><strong><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>III. Обработка на анонимизирани данни</span></strong></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>Ние обработваме Вашите данни за статистически цели, това означава за анализи, в които резултатите са само обобщаващи и следователно данните са анонимни. Идентифицирането на конкретно лице от тази информация е невъзможно.</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><strong><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>Как защитаваме Вашите лични данни</span></strong></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>За осигуряване на адекватна защита на данните на компанията и своите клиенти, ние прилагаме всички необходими организационни и технически мерки, предвидени в Закона за защита на личните данни.</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>С цел максимална сигурност при обработка, пренос и съхранение на Вашите данни, може да използваме допълнителни механизми за защита като криптиране, псевдонимизация и др.</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>Разширена информация за допълнителните cookies прилагани от платформата, на която е базиран NOLD, можете да намерите тук:&nbsp;</span><a href="https://www.sharetribe.com/"><span style={{fontSize: '15px', lineHeight: '139%', color: '#1155CC', background: '#FEFEFE'}}>Sharetribe</span></a> / <a href="https://www.sharetribe.com/Sharetribe-Privacy_policy-20190301.pdf"><span style={{fontSize: '15px', lineHeight: '139%', color: '#1155CC', background: '#FEFEFE'}}>Cookies</span></a></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>Със даване на съгласието си при влизане на сайта на NOLD потребителя се съгласява тези данни да бъдат събирани и обработвани от Sharetribe в полза на NOLD, съобразени с Европейските регулации.&nbsp;</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><strong><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>&nbsp;VI. Права на потребителите</span></strong></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>Всеки потребител на сайта се ползва с всички права за защита на личните данни съгласно правото на Европейския съюз и българското законодателство.</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>Предприетите мерки за защита на личните данни в съответствие с изискванията Регламент 2016/679, са насочени към осигуряване правата на субектите, чиито лични данни се обработват, а именно:</span></p>
  <ul className="decimal_type" style={{listStyleType: 'undefined', marginLeft: '0in'}}>
    <li><span style={{lineHeight: '115%', fontSize: '11.5pt', background: '#FEFEFE'}}>право на достъп;</span></li>
    <li><span style={{lineHeight: '115%', fontSize: '11.5pt', background: '#FEFEFE'}}>право на коригиране на неточни или непълни данни;</span></li>
    <li><span style={{lineHeight: '115%', fontSize: '11.5pt', background: '#FEFEFE'}}>право на изтриване (правото да бъдеш забравен), ако са приложими условията на чл. 17 от Регламент 2016/679;</span></li>
    <li><span style={{lineHeight: '115%', fontSize: '11.5pt', background: '#FEFEFE'}}>право на ограничение на обработването;</span></li>
    <li><span style={{lineHeight: '115%', fontSize: '11.5pt', background: '#FEFEFE'}}>право на преносимост на данните, ако са налице условията за преносимост по чл. 20 от Регламент 2016/679;</span></li>
    <li><span style={{lineHeight: '115%', fontSize: '11.5pt', background: '#FEFEFE'}}>право на възражение, ако са налице условията на чл. 21 от Регламент 2016/679;</span></li>
    <li><span style={{lineHeight: '115%', fontSize: '11.5pt', background: '#FEFEFE'}}>право субектът на данни да не бъде обект на решение, основаващо се единствено на автоматизирано обработване, включващо профилиране;</span></li>
  </ul>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>Горните права може да упражните чрез отправено искане до Дружеството (писмено или по електронен път) в което следва да посочите конкретното си искане, като индивидуализирате в достатъчна степен искането си и данните, за които то се отнася. Искането следва да бъде подписано и изпратено на посочените по-горе адреси на „НОЛД” ЕООД.</span></p>
  <p style={{marginTop: '0in', marginRight: '0in', marginBottom: '11.0pt', marginLeft: '0in', lineHeight: '139%', fontSize: '15px',  textAlign: 'justify'}}><strong><span style={{fontSize: '15px', lineHeight: '139%', background: '#FEFEFE'}}>Право на жалба до Комисия за защита на личните данни или до съда.&nbsp;</span></strong></p>
</div>

    </div>
  );
};

PrivacyPolicy.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

PrivacyPolicy.propTypes = {
  rootClassName: string,
  className: string,
};

export default PrivacyPolicy;
