const bgSites = [
  {
    id: '14',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АБЛАНИЦА',
    nameEn: 'ABLANITSA',
    municipality: 'ХАДЖИДИМОВО',
    municipalityEn: 'HADZHIDIMOVO',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2932',
    addressNomenclature: '0',
    x: '23.934398',
    y: '41.536921',
    servingDays: '1111100',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '28',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АБЛАНИЦА',
    nameEn: 'ABLANITSA',
    municipality: 'ЛОВЕЧ',
    municipalityEn: 'LOVECH',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5574',
    addressNomenclature: '0',
    x: '24.693866',
    y: '43.040249',
    servingDays: '1010100',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '881',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АБЛАНИЦА',
    nameEn: 'ABLANITSA',
    municipality: 'ВЕЛИНГРАД',
    municipalityEn: 'VELINGRAD',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4645',
    addressNomenclature: '0',
    x: '23.861914',
    y: '42.009569',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '31',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АБРИТ',
    nameEn: 'ABRIT',
    municipality: 'КРУШАРИ',
    municipalityEn: 'KRUSHARI',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9407',
    addressNomenclature: '0',
    x: '27.795803',
    y: '43.90785',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '59',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АВРАМОВО',
    nameEn: 'AVRAMOVO',
    municipality: 'ЯКОРУДА',
    municipalityEn: 'YAKORUDA',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2795',
    addressNomenclature: '0',
    x: '23.812212',
    y: '42.031027',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '62',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АВРАМОВО',
    nameEn: 'AVRAMOVO',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6797',
    addressNomenclature: '0',
    x: '25.166557',
    y: '41.698017',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '84',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АВРЕН',
    nameEn: 'AVREN',
    municipality: 'АВРЕН',
    municipalityEn: 'AVREN',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9135',
    addressNomenclature: '0',
    x: '27.666863',
    y: '43.115658',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '93',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АВРЕН',
    nameEn: 'AVREN',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6930',
    addressNomenclature: '0',
    x: '25.709554',
    y: '41.345594',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '103',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АГАТОВО',
    nameEn: 'AGATOVO',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5431',
    addressNomenclature: '0',
    x: '25.00203',
    y: '43.137614',
    servingDays: '0101000',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '117',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АЗМАНИТЕ',
    nameEn: 'AZMANITE',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5350',
    addressNomenclature: '0',
    x: '25.467563',
    y: '42.840143',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '895',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АЙДЕМИР',
    nameEn: 'AYDEMIR',
    municipality: 'СИЛИСТРА',
    municipalityEn: 'SILISTRA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7538',
    addressNomenclature: '0',
    x: '27.168801',
    y: '44.097759',
    servingDays: '1111110',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '148',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АЙРОВО',
    nameEn: 'AYROVO',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6639',
    addressNomenclature: '0',
    x: '25.365989',
    y: '41.60837',
    servingDays: '1111100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '151',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'АЙТОС',
    nameEn: 'AYTOS',
    municipality: 'АЙТОС',
    municipalityEn: 'AYTOS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8500',
    addressNomenclature: '2',
    x: '27.248971',
    y: '42.698961',
    servingDays: '1111110',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '165',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АКАНДЖИЕВО',
    nameEn: 'AKANDZHIEVO',
    municipality: 'БЕЛОВО',
    municipalityEn: 'BELOVO',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4483',
    addressNomenclature: '0',
    x: '24.044226',
    y: '42.249537',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '179',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АКАЦИЕВО',
    nameEn: 'AKATSIEVO',
    municipality: 'ВИДИН',
    municipalityEn: 'VIDIN',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3727',
    addressNomenclature: '0',
    x: '22.806708',
    y: '44.016213',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '182',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'АКСАКОВО',
    nameEn: 'AKSAKOVO',
    municipality: 'АКСАКОВО',
    municipalityEn: 'AKSAKOVO',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9154',
    addressNomenclature: '0',
    x: '27.818661',
    y: '43.256366',
    servingDays: '1111110',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '196',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АЛАМОВЦИ',
    nameEn: 'ALAMOVTSI',
    municipality: 'ЗЛАТОГРАД',
    municipalityEn: 'ZLATOGRAD',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4984',
    addressNomenclature: '0',
    x: '24.997908',
    y: '41.392711',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '206',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АЛБАНЦИ',
    nameEn: 'ALBANTSI',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6850',
    addressNomenclature: '0',
    x: '25.270636',
    y: '41.495091',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '99958',
    countryId: '100',
    mainSiteId: '0',
    type: 'к.к.',
    typeEn: 'k.k.',
    name: 'АЛБЕНА',
    nameEn: 'ALBENA',
    municipality: 'БАЛЧИК',
    municipalityEn: 'BALCHIK',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9620',
    addressNomenclature: '0',
    x: '28.074341',
    y: '43.365608',
    servingDays: '1111110',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '215',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АЛВАНОВО',
    nameEn: 'ALVANOVO',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7752',
    addressNomenclature: '0',
    x: '26.669555',
    y: '43.29765',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '223',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АЛДОМИРОВЦИ',
    nameEn: 'ALDOMIROVTSI',
    municipality: 'СЛИВНИЦА',
    municipalityEn: 'SLIVNITSA',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2222',
    addressNomenclature: '0',
    x: '22.989472',
    y: '42.847285',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '254',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АЛЕКО КОНСТАНТИНОВО',
    nameEn: 'ALEKO KONSTANTINOVO',
    municipality: 'ПАЗАРДЖИК',
    municipalityEn: 'PAZARDZHIK',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4411',
    addressNomenclature: '0',
    x: '24.289034',
    y: '42.145158',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '237',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АЛЕКОВО',
    nameEn: 'ALEKOVO',
    municipality: 'СВИЩОВ',
    municipalityEn: 'SVISHTOV',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5288',
    addressNomenclature: '0',
    x: '25.419506',
    y: '43.46624',
    servingDays: '1010100',
    servingOfficeId: '250',
    servingHubOfficeId: '11',
  },
  {
    id: '240',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АЛЕКОВО',
    nameEn: 'ALEKOVO',
    municipality: 'АЛФАТАР',
    municipalityEn: 'ALFATAR',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7555',
    addressNomenclature: '0',
    x: '27.343389',
    y: '43.845376',
    servingDays: '0101000',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '268',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АЛЕКСАНДРИЯ',
    nameEn: 'ALEKSANDRIA',
    municipality: 'КРУШАРИ',
    municipalityEn: 'KRUSHARI',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9404',
    addressNomenclature: '0',
    x: '27.75118',
    y: '43.885401',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '271',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АЛЕКСАНДРОВО',
    nameEn: 'ALEKSANDROVO',
    municipality: 'ПОМОРИЕ',
    municipalityEn: 'POMORIE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8211',
    addressNomenclature: '0',
    x: '27.596504',
    y: '42.697223',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '285',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АЛЕКСАНДРОВО',
    nameEn: 'ALEKSANDROVO',
    municipality: 'СВИЩОВ',
    municipalityEn: 'SVISHTOV',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5289',
    addressNomenclature: '0',
    x: '25.398206',
    y: '43.44916',
    servingDays: '1010100',
    servingOfficeId: '250',
    servingHubOfficeId: '11',
  },
  {
    id: '299',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АЛЕКСАНДРОВО',
    nameEn: 'ALEKSANDROVO',
    municipality: 'ЛОВЕЧ',
    municipalityEn: 'LOVECH',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5560',
    addressNomenclature: '0',
    x: '24.943343',
    y: '43.263841',
    servingDays: '0101000',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '309',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АЛЕКСАНДРОВО',
    nameEn: 'ALEKSANDROVO',
    municipality: 'ПАВЕЛ БАНЯ',
    municipalityEn: 'PAVEL BANYA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6154',
    addressNomenclature: '0',
    x: '25.092049',
    y: '42.594642',
    servingDays: '1010100',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '312',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АЛЕКСАНДРОВО',
    nameEn: 'ALEKSANDROVO',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7761',
    addressNomenclature: '0',
    x: '26.423547',
    y: '43.227826',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '326',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АЛЕКСАНДРОВО',
    nameEn: 'ALEKSANDROVO',
    municipality: 'ХАСКОВО',
    municipalityEn: 'HASKOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6351',
    addressNomenclature: '0',
    x: '25.725345',
    y: '41.982863',
    servingDays: '0101000',
    servingOfficeId: '42',
    servingHubOfficeId: '5',
  },
  {
    id: '330',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АЛЕКСАНДРОВО',
    nameEn: 'ALEKSANDROVO',
    municipality: 'СМЯДОВО',
    municipalityEn: 'SMYADOVO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9829',
    addressNomenclature: '0',
    x: '26.972298',
    y: '42.991166',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '343',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АЛЕКСАНДРОВО',
    nameEn: 'ALEKSANDROVO',
    municipality: 'СТРАЛДЖА',
    municipalityEn: 'STRALDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8653',
    addressNomenclature: '0',
    x: '26.88102',
    y: '42.32427',
    servingDays: '0101000',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '360',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АЛЕКСАНДЪР СТАМБОЛИЙСКИ',
    nameEn: 'ALEKSANDAR STAMBOLIYSKI',
    municipality: 'ГЕНЕРАЛ ТОШЕВО',
    municipalityEn: 'GENERAL TOSHEVO',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9565',
    addressNomenclature: '0',
    x: '28.357803',
    y: '43.685766',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '83421',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АЛЕНДАРОВА',
    nameEn: 'ALENDAROVA',
    municipality: 'ВЕЛИНГРАД',
    municipalityEn: 'VELINGRAD',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4621',
    addressNomenclature: '0',
    x: '23.881152',
    y: '42.019983',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '388',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АЛИГОВСКА',
    nameEn: 'ALIGOVSKA',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4787',
    addressNomenclature: '0',
    x: '24.641954',
    y: '41.449229',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '391',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АЛИНО',
    nameEn: 'ALINO',
    municipality: 'САМОКОВ',
    municipalityEn: 'SAMOKOV',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2019',
    addressNomenclature: '0',
    x: '23.383139',
    y: '42.401826',
    servingDays: '0101000',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '401',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АЛТИМИР',
    nameEn: 'ALTIMIR',
    municipality: 'БЯЛА СЛАТИНА',
    municipalityEn: 'BYALA SLATINA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3251',
    addressNomenclature: '0',
    x: '23.809979',
    y: '43.529515',
    servingDays: '1010100',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '415',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'АЛФАТАР',
    nameEn: 'ALFATAR',
    municipality: 'АЛФАТАР',
    municipalityEn: 'ALFATAR',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7570',
    addressNomenclature: '2',
    x: '27.290692',
    y: '43.945592',
    servingDays: '1111100',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '429',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АЛЦЕК',
    nameEn: 'ALTSEK',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9434',
    addressNomenclature: '0',
    x: '27.541485',
    y: '43.775878',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '446',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АНГЕЛ ВОЙВОДА',
    nameEn: 'ANGEL VOYVODA',
    municipality: 'МИНЕРАЛНИ БАНИ',
    municipalityEn: 'MINERALNI BANI',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6373',
    addressNomenclature: '0',
    x: '25.285963',
    y: '41.828532',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '432',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АНГЕЛАРИЙ',
    nameEn: 'ANGELARIY',
    municipality: 'ТЕРВЕЛ',
    municipalityEn: 'TERVEL',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9483',
    addressNomenclature: '0',
    x: '27.527149',
    y: '43.829362',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '456',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АНГЕЛОВ',
    nameEn: 'ANGELOV',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5342',
    addressNomenclature: '0',
    x: '25.365253',
    y: '42.923703',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '480',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АНЕВО',
    nameEn: 'ANEVO',
    municipality: 'СОПОТ',
    municipalityEn: 'SOPOT',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4331',
    addressNomenclature: '0',
    x: '24.723876',
    y: '42.656384',
    servingDays: '1010100',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '494',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АНТИМОВО',
    nameEn: 'ANTIMOVO',
    municipality: 'ТУТРАКАН',
    municipalityEn: 'TUTRAKAN',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7628',
    addressNomenclature: '0',
    x: '26.689771',
    y: '43.981781',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '919',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АНТИМОВО',
    nameEn: 'ANTIMOVO',
    municipality: 'ВИДИН',
    municipalityEn: 'VIDIN',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3776',
    addressNomenclature: '0',
    x: '22.941688',
    y: '44.030141',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '504',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АНТОН',
    nameEn: 'ANTON',
    municipality: 'АНТОН',
    municipalityEn: 'ANTON',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2089',
    addressNomenclature: '2',
    x: '24.259247',
    y: '42.734933',
    servingDays: '0101000',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '518',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'АНТОНОВО',
    nameEn: 'ANTONOVO',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7970',
    addressNomenclature: '2',
    x: '26.159819',
    y: '43.150044',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '521',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АПЛАЦИ',
    nameEn: 'APLATSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5094',
    addressNomenclature: '0',
    x: '25.817795',
    y: '42.863507',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '549',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АПРИЛОВО',
    nameEn: 'APRILOVO',
    municipality: 'ГОРНА МАЛИНА',
    municipalityEn: 'GORNA MALINA',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2128',
    addressNomenclature: '0',
    x: '23.683675',
    y: '42.668804',
    servingDays: '0101000',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '552',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АПРИЛОВО',
    nameEn: 'APRILOVO',
    municipality: 'ГЪЛЪБОВО',
    municipalityEn: 'GALABOVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6281',
    addressNomenclature: '0',
    x: '25.85673',
    y: '42.194444',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '566',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АПРИЛОВО',
    nameEn: 'APRILOVO',
    municipality: 'ПОПОВО',
    municipalityEn: 'POPOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7864',
    addressNomenclature: '0',
    x: '26.278778',
    y: '43.260334',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '571',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АПРИЛЦИ',
    nameEn: 'APRILTSI',
    municipality: 'ПАЗАРДЖИК',
    municipalityEn: 'PAZARDZHIK',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4445',
    addressNomenclature: '0',
    x: '24.290195',
    y: '42.312224',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '905',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АПРИЛЦИ',
    nameEn: 'APRILTSI',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6888',
    addressNomenclature: '0',
    x: '25.37202',
    y: '41.319141',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '52218',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'АПРИЛЦИ',
    nameEn: 'APRILTSI',
    municipality: 'АПРИЛЦИ',
    municipalityEn: 'APRILTSI',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5641',
    addressNomenclature: '2',
    x: '24.91613',
    y: '42.841222',
    servingDays: '1111100',
    servingOfficeId: '44',
    servingHubOfficeId: '11',
  },
  {
    id: '583',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АРБАНАСИ',
    nameEn: 'ARBANASI',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5029',
    addressNomenclature: '0',
    x: '25.666537',
    y: '43.098122',
    servingDays: '1111110',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '597',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АРДА',
    nameEn: 'ARDA',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4790',
    addressNomenclature: '0',
    x: '24.639669',
    y: '41.462841',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '607',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'АРДИНО',
    nameEn: 'ARDINO',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6750',
    addressNomenclature: '2',
    x: '25.135475',
    y: '41.582562',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '610',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АРЗАН',
    nameEn: 'ARZAN',
    municipality: 'БРЕЗНИК',
    municipalityEn: 'BREZNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2386',
    addressNomenclature: '0',
    x: '22.922189',
    y: '42.759704',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '624',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АРКОВНА',
    nameEn: 'ARKOVNA',
    municipality: 'ДЪЛГОПОЛ',
    municipalityEn: 'DALGOPOL',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9246',
    addressNomenclature: '0',
    x: '27.199788',
    y: '43.035788',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '638',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АРМЕНИТЕ',
    nameEn: 'ARMENITE',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5347',
    addressNomenclature: '0',
    x: '25.225041',
    y: '42.929415',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '641',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АРМЯНКОВЦИ',
    nameEn: 'ARMYANKOVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5363',
    addressNomenclature: '0',
    x: '25.570169',
    y: '42.871563',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '655',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АРНАУТИТО',
    nameEn: 'ARNAUTITO',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6069',
    addressNomenclature: '0',
    x: '25.543155',
    y: '42.302923',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '669',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АРПАДЖИК',
    nameEn: 'ARPADZHIK',
    municipality: 'МАДАН',
    municipalityEn: 'MADAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4922',
    addressNomenclature: '0',
    x: '24.94383',
    y: '41.53409',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '672',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АРЧАР',
    nameEn: 'ARCHAR',
    municipality: 'ДИМОВО',
    municipalityEn: 'DIMOVO',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3770',
    addressNomenclature: '0',
    x: '22.918864',
    y: '43.810929',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '697',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АСЕН',
    nameEn: 'ASEN',
    municipality: 'ПАВЕЛ БАНЯ',
    municipalityEn: 'PAVEL BANYA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6149',
    addressNomenclature: '0',
    x: '25.198022',
    y: '42.6557',
    servingDays: '1111100',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '702',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'АСЕНОВГРАД',
    nameEn: 'ASENOVGRAD',
    municipality: 'АСЕНОВГРАД',
    municipalityEn: 'ASENOVGRAD',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4230',
    addressNomenclature: '1',
    x: '24.878686',
    y: '42.010658',
    servingDays: '1111110',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '713',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АСЕНОВЕЦ',
    nameEn: 'ASENOVETS',
    municipality: 'НОВА ЗАГОРА',
    municipalityEn: 'NOVA ZAGORA',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8933',
    addressNomenclature: '0',
    x: '25.95185',
    y: '42.527843',
    servingDays: '1010100',
    servingOfficeId: '56',
    servingHubOfficeId: '5',
  },
  {
    id: '730',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АСЕНОВО',
    nameEn: 'ASENOVO',
    municipality: 'СТРАЖИЦА',
    municipalityEn: 'STRAZHITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5159',
    addressNomenclature: '0',
    x: '26.026255',
    y: '43.280033',
    servingDays: '0101000',
    servingOfficeId: '46',
    servingHubOfficeId: '11',
  },
  {
    id: '744',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АСЕНОВО',
    nameEn: 'ASENOVO',
    municipality: 'НИКОПОЛ',
    municipalityEn: 'NIKOPOL',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5957',
    addressNomenclature: '0',
    x: '24.865746',
    y: '43.555302',
    servingDays: '0101000',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '758',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АСЕНОВО',
    nameEn: 'ASENOVO',
    municipality: 'ТУНДЖА',
    municipalityEn: 'TUNDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8667',
    addressNomenclature: '0',
    x: '26.658967',
    y: '42.36768',
    servingDays: '0101000',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '761',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АСЕНОВЦИ',
    nameEn: 'ASENOVTSI',
    municipality: 'ЛЕВСКИ',
    municipalityEn: 'LEVSKI',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5913',
    addressNomenclature: '0',
    x: '25.099836',
    y: '43.327909',
    servingDays: '0101000',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '775',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АСПАРУХОВО',
    nameEn: 'ASPARUHOVO',
    municipality: 'КАРНОБАТ',
    municipalityEn: 'KARNOBAT',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8485',
    addressNomenclature: '0',
    x: '27.11076',
    y: '42.516917',
    servingDays: '0101010',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '789',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АСПАРУХОВО',
    nameEn: 'ASPARUHOVO',
    municipality: 'ДЪЛГОПОЛ',
    municipalityEn: 'DALGOPOL',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9260',
    addressNomenclature: '0',
    x: '27.321405',
    y: '42.979447',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '792',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АСПАРУХОВО',
    nameEn: 'ASPARUHOVO',
    municipality: 'МЕДКОВЕЦ',
    municipalityEn: 'MEDKOVETS',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3661',
    addressNomenclature: '0',
    x: '23.212735',
    y: '43.70361',
    servingDays: '1010100',
    servingOfficeId: '831',
    servingHubOfficeId: '20',
  },
  {
    id: '802',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АСПАРУХОВО',
    nameEn: 'ASPARUHOVO',
    municipality: 'ЛЕВСКИ',
    municipalityEn: 'LEVSKI',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5914',
    addressNomenclature: '0',
    x: '25.126587',
    y: '43.393315',
    servingDays: '1010100',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '63015',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АТИЯ',
    nameEn: 'ATIA',
    municipality: 'СОЗОПОЛ',
    municipalityEn: 'SOZOPOL',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8140',
    addressNomenclature: '0',
    x: '27.58596',
    y: '42.438273',
    servingDays: '1111110',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '816',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АТОЛОВО',
    nameEn: 'ATOLOVO',
    municipality: 'СТРАЛДЖА',
    municipalityEn: 'STRALDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8683',
    addressNomenclature: '0',
    x: '26.732858',
    y: '42.598019',
    servingDays: '1010100',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '828',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АУСТА',
    nameEn: 'AUSTA',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6821',
    addressNomenclature: '0',
    x: '25.501794',
    y: '41.47674',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '833',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'АХЕЛОЙ',
    nameEn: 'AHELOY',
    municipality: 'ПОМОРИЕ',
    municipalityEn: 'POMORIE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8217',
    addressNomenclature: '2',
    x: '27.649498',
    y: '42.645702',
    servingDays: '1111110',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '847',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АХМАТОВО',
    nameEn: 'AHMATOVO',
    municipality: 'САДОВО',
    municipalityEn: 'SADOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4128',
    addressNomenclature: '0',
    x: '25.051541',
    y: '42.110708',
    servingDays: '1010100',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '864',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'АХРЯНСКО',
    nameEn: 'AHRYANSKO',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6761',
    addressNomenclature: '0',
    x: '25.108781',
    y: '41.600326',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '878',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'АХТОПОЛ',
    nameEn: 'AHTOPOL',
    municipality: 'ЦАРЕВО',
    municipalityEn: 'TSAREVO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8280',
    addressNomenclature: '2',
    x: '27.937604',
    y: '42.099414',
    servingDays: '1111100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '2018',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАБА СТАНА',
    nameEn: 'BABA STANA',
    municipality: 'ТРОЯН',
    municipalityEn: 'TROYAN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '',
    addressNomenclature: '0',
    x: '24.790593',
    y: '42.883859',
    servingDays: '1010100',
    servingOfficeId: '44',
    servingHubOfficeId: '11',
  },
  {
    id: '2021',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАБА ТОНКА',
    nameEn: 'BABA TONKA',
    municipality: 'ПОПОВО',
    municipalityEn: 'POPOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7861',
    addressNomenclature: '0',
    x: '26.165296',
    y: '43.249072',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '2035',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАБЕК',
    nameEn: 'BABEK',
    municipality: 'БРЕЗОВО',
    municipalityEn: 'BREZOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4156',
    addressNomenclature: '0',
    x: '25.073891',
    y: '42.442753',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '2049',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАБИНО',
    nameEn: 'BABINO',
    municipality: 'БОБОВ ДОЛ',
    municipalityEn: 'BOBOV DOL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2683',
    addressNomenclature: '0',
    x: '22.970044',
    y: '42.358359',
    servingDays: '1010100',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '2052',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАБИНСКА РЕКА',
    nameEn: 'BABINSKA REKA',
    municipality: 'БОБОВ ДОЛ',
    municipalityEn: 'BOBOV DOL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2668',
    addressNomenclature: '0',
    x: '22.995076',
    y: '42.331242',
    servingDays: '1010100',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '7884',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАБИНЦИ',
    nameEn: 'BABINTSI',
    municipality: 'ТЕТЕВЕН',
    municipalityEn: 'TETEVEN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5726',
    addressNomenclature: '0',
    x: '24.268115',
    y: '42.948733',
    servingDays: '0101000',
    servingOfficeId: '88',
    servingHubOfficeId: '2',
  },
  {
    id: '2066',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАБИЦА',
    nameEn: 'BABITSA',
    municipality: 'БРЕЗНИК',
    municipalityEn: 'BREZNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2365',
    addressNomenclature: '0',
    x: '22.954321',
    y: '42.735927',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '2076',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАБЛОН',
    nameEn: 'BABLON',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4774',
    addressNomenclature: '0',
    x: '24.700614',
    y: '41.479216',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '2083',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАБОВО',
    nameEn: 'BABOVO',
    municipality: 'СЛИВО ПОЛЕ',
    municipalityEn: 'SLIVO POLE',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7068',
    addressNomenclature: '0',
    x: '26.302187',
    y: '43.98352',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '2097',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАБУК',
    nameEn: 'BABUK',
    municipality: 'СИЛИСТРА',
    municipalityEn: 'SILISTRA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7573',
    addressNomenclature: '0',
    x: '27.246304',
    y: '44.012508',
    servingDays: '1010100',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '2107',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАБЯК',
    nameEn: 'BABYAK',
    municipality: 'БЕЛИЦА',
    municipalityEn: 'BELITSA',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2784',
    addressNomenclature: '0',
    x: '23.681105',
    y: '41.955328',
    servingDays: '1010100',
    servingOfficeId: '125',
    servingHubOfficeId: '13',
  },
  {
    id: '2110',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАГАЛЕВЦИ',
    nameEn: 'BAGALEVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5094',
    addressNomenclature: '0',
    x: '25.812937',
    y: '42.892813',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '2124',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАГРА',
    nameEn: 'BAGRA',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6749',
    addressNomenclature: '0',
    x: '25.321082',
    y: '41.700326',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '2138',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАГРЕНЦИ',
    nameEn: 'BAGRENTSI',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2530',
    addressNomenclature: '0',
    x: '22.760483',
    y: '42.279051',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '2141',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАГРИЛЦИ',
    nameEn: 'BAGRILTSI',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6944',
    addressNomenclature: '0',
    x: '25.792121',
    y: '41.472376',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '2155',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАГРЯНКА',
    nameEn: 'BAGRYANKA',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6819',
    addressNomenclature: '0',
    x: '25.421826',
    y: '41.508672',
    servingDays: '1111110',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '2169',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАДЕВЦИ',
    nameEn: 'BADEVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5093',
    addressNomenclature: '0',
    x: '25.748646',
    y: '42.883736',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '2172',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАДИНО',
    nameEn: 'BADINO',
    municipality: 'БОБОШЕВО',
    municipalityEn: 'BOBOSHEVO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2676',
    addressNomenclature: '0',
    x: '23.103512',
    y: '42.17755',
    servingDays: '0101000',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '2191',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАЕВЦИ',
    nameEn: 'BAEVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5087',
    addressNomenclature: '0',
    x: '25.786967',
    y: '42.923356',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '2207',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАЕВЦИ',
    nameEn: 'BAEVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5304',
    addressNomenclature: '0',
    x: '25.283756',
    y: '42.811881',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '2213',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАЖДАРИ',
    nameEn: 'BAZHDARI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5095',
    addressNomenclature: '0',
    x: '25.77308',
    y: '42.841417',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '2227',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАЙКАЛ',
    nameEn: 'BAYKAL',
    municipality: 'ДОЛНА МИТРОПОЛИЯ',
    municipalityEn: 'DOLNA MITROPOLIA',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5861',
    addressNomenclature: '0',
    x: '24.422518',
    y: '43.710365',
    servingDays: '0101000',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '2230',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАЙКАЛСКО',
    nameEn: 'BAYKALSKO',
    municipality: 'РАДОМИР',
    municipalityEn: 'RADOMIR',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2427',
    addressNomenclature: '0',
    x: '22.830538',
    y: '42.401361',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '2244',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАЙКОВО',
    nameEn: 'BAYKOVO',
    municipality: 'ХИТРИНО',
    municipalityEn: 'HITRINO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9767',
    addressNomenclature: '0',
    x: '26.838011',
    y: '43.459615',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '2258',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАЙЛОВО',
    nameEn: 'BAYLOVO',
    municipality: 'ГОРНА МАЛИНА',
    municipalityEn: 'GORNA MALINA',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2133',
    addressNomenclature: '0',
    x: '23.82054',
    y: '42.656086',
    servingDays: '0101000',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '2261',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАКАЛИТЕ',
    nameEn: 'BAKALITE',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6698',
    addressNomenclature: '0',
    x: '25.228862',
    y: '41.739823',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '2289',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАКЬОВО',
    nameEn: 'BAKYOVO',
    municipality: 'СВОГЕ',
    municipalityEn: 'SVOGE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2295',
    addressNomenclature: '0',
    x: '23.463718',
    y: '42.877656',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '2292',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАЛАБАНОВО',
    nameEn: 'BALABANOVO',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6816',
    addressNomenclature: '0',
    x: '25.381303',
    y: '41.570996',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '2302',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАЛАБАНСКО',
    nameEn: 'BALABANSKO',
    municipality: 'ТРОЯН',
    municipalityEn: 'TROYAN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5615',
    addressNomenclature: '0',
    x: '24.651577',
    y: '42.894797',
    servingDays: '1010100',
    servingOfficeId: '44',
    servingHubOfficeId: '11',
  },
  {
    id: '2322',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАЛАБАНЧЕВО',
    nameEn: 'BALABANCHEVO',
    municipality: 'СУНГУРЛАРЕ',
    municipalityEn: 'SUNGURLARE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '',
    addressNomenclature: '0',
    x: '26.775053',
    y: '42.783139',
    servingDays: '0101000',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '2333',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАЛАЛЕЯ',
    nameEn: 'BALALEYA',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5370',
    addressNomenclature: '0',
    x: '25.431113',
    y: '42.991613',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '2347',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАЛАНИТЕ',
    nameEn: 'BALANITE',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5301',
    addressNomenclature: '0',
    x: '25.369418',
    y: '42.831769',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '2350',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАЛАНОВО',
    nameEn: 'BALANOVO',
    municipality: 'ДУПНИЦА',
    municipalityEn: 'DUPNITSA',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2680',
    addressNomenclature: '0',
    x: '23.05884',
    y: '42.248956',
    servingDays: '0101000',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '2364',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАЛВАН',
    nameEn: 'BALVAN',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5064',
    addressNomenclature: '0',
    x: '25.412149',
    y: '43.085271',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '2378',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАЛВАНЦИТЕ',
    nameEn: 'BALVANTSITE',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5388',
    addressNomenclature: '0',
    x: '25.449775',
    y: '43.019098',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '2381',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАЛДЕВО',
    nameEn: 'BALDEVO',
    municipality: 'ГЪРМЕН',
    municipalityEn: 'GARMEN',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2948',
    addressNomenclature: '0',
    x: '23.769419',
    y: '41.629227',
    servingDays: '0101000',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '2395',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАЛЕЙ',
    nameEn: 'BALEY',
    municipality: 'БРЕГОВО',
    municipalityEn: 'BREGOVO',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3797',
    addressNomenclature: '0',
    x: '22.647309',
    y: '44.188794',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '2405',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАЛИК',
    nameEn: 'BALIK',
    municipality: 'ТЕРВЕЛ',
    municipalityEn: 'TERVEL',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9485',
    addressNomenclature: '0',
    x: '27.582858',
    y: '43.801231',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '2419',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАЛИНОВЦИ',
    nameEn: 'BALINOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5342',
    addressNomenclature: '0',
    x: '25.380218',
    y: '42.921654',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '2436',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАЛКАН',
    nameEn: 'BALKAN',
    municipality: 'СТАМБОЛОВО',
    municipalityEn: 'STAMBOLOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6363',
    addressNomenclature: '0',
    x: '25.572668',
    y: '41.766878',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '2453',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАЛКАН МАХАЛА',
    nameEn: 'BALKAN MAHALA',
    municipality: 'ЛЪКИ',
    municipalityEn: 'LAKI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4245',
    addressNomenclature: '0',
    x: '24.856767',
    y: '41.74659',
    servingDays: '0101000',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '2448',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАЛКАНЕЦ',
    nameEn: 'BALKANETS',
    municipality: 'ТРОЯН',
    municipalityEn: 'TROYAN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5634',
    addressNomenclature: '0',
    x: '24.669696',
    y: '42.837392',
    servingDays: '1010100',
    servingOfficeId: '44',
    servingHubOfficeId: '11',
  },
  {
    id: '2467',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАЛКАНСКИ',
    nameEn: 'BALKANSKI',
    municipality: 'РАЗГРАД',
    municipalityEn: 'RAZGRAD',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7265',
    addressNomenclature: '0',
    x: '26.401477',
    y: '43.523573',
    servingDays: '0101000',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '2470',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАЛКАНЦИ',
    nameEn: 'BALKANTSI',
    municipality: 'СТРАЖИЦА',
    municipalityEn: 'STRAZHITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5162',
    addressNomenclature: '0',
    x: '26.008948',
    y: '43.174766',
    servingDays: '1010100',
    servingOfficeId: '46',
    servingHubOfficeId: '11',
  },
  {
    id: '2484',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАЛКАНЦИ',
    nameEn: 'BALKANTSI',
    municipality: 'ГЕНЕРАЛ ТОШЕВО',
    municipalityEn: 'GENERAL TOSHEVO',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9549',
    addressNomenclature: '0',
    x: '28.162879',
    y: '43.598701',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '2498',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАЛУЦИ',
    nameEn: 'BALUTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5081',
    addressNomenclature: '0',
    x: '25.823885',
    y: '42.950715',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '2508',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'БАЛЧИК',
    nameEn: 'BALCHIK',
    municipality: 'БАЛЧИК',
    municipalityEn: 'BALCHIK',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9600',
    addressNomenclature: '2',
    x: '28.166805',
    y: '43.411607',
    servingDays: '1111110',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '2511',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАЛША',
    nameEn: 'BALSHA',
    municipality: 'СТОЛИЧНА',
    municipalityEn: 'STOLICHNA',
    region: 'СОФИЯ (СТОЛИЦА)',
    regionEn: 'SOFIA (STOLITSA)',
    postCode: '1217',
    addressNomenclature: '2',
    x: '23.267858',
    y: '42.847044',
    servingDays: '1111100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '2539',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАЛЬОВЦИ',
    nameEn: 'BALYOVTSI',
    municipality: 'ИХТИМАН',
    municipalityEn: 'IHTIMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2060',
    addressNomenclature: '0',
    x: '23.683253',
    y: '42.570562',
    servingDays: '1111100',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '2542',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАЛЮВИЦА',
    nameEn: 'BALYUVITSA',
    municipality: 'БЕРКОВИЦА',
    municipalityEn: 'BERKOVITSA',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3529',
    addressNomenclature: '0',
    x: '23.205548',
    y: '43.297039',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '2556',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАНАРИ',
    nameEn: 'BANARI',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5370',
    addressNomenclature: '0',
    x: '25.513668',
    y: '42.938404',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '2563',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАНГЕЙЦИ',
    nameEn: 'BANGEYTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5351',
    addressNomenclature: '0',
    x: '25.451244',
    y: '42.84962',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '2587',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАНИСКА',
    nameEn: 'BANISKA',
    municipality: 'ДВЕ МОГИЛИ',
    municipalityEn: 'DVE MOGILI',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7167',
    addressNomenclature: '0',
    x: '25.901705',
    y: '43.458259',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '44402',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАНИТЕ',
    nameEn: 'BANITE',
    municipality: 'БАНИТЕ',
    municipalityEn: 'BANITE',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4940',
    addressNomenclature: '0',
    x: '25.006175',
    y: '41.615095',
    servingDays: '1111100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '2590',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАНИЦА',
    nameEn: 'BANITSA',
    municipality: 'ВРАЦА',
    municipalityEn: 'VRATSA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3077',
    addressNomenclature: '0',
    x: '23.692326',
    y: '43.344012',
    servingDays: '1010100',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '2600',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАНИЧАН',
    nameEn: 'BANICHAN',
    municipality: 'ГОЦЕ ДЕЛЧЕВ',
    municipalityEn: 'GOTSE DELCHEV',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2905',
    addressNomenclature: '0',
    x: '23.737429',
    y: '41.620812',
    servingDays: '1111100',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '2614',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАНИЩЕ',
    nameEn: 'BANISHTE',
    municipality: 'БРЕЗНИК',
    municipalityEn: 'BREZNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2388',
    addressNomenclature: '0',
    x: '22.728479',
    y: '42.719194',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '2628',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАНКОВЕЦ',
    nameEn: 'BANKOVETS',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7997',
    addressNomenclature: '0',
    x: '26.160986',
    y: '43.077085',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '2631',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАНКОВЦИ',
    nameEn: 'BANKOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5300',
    addressNomenclature: '0',
    x: '25.320098',
    y: '42.930048',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '2645',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАНКЯ',
    nameEn: 'BANKYA',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2466',
    addressNomenclature: '0',
    x: '22.680153',
    y: '42.862904',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '2659',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'БАНКЯ',
    nameEn: 'BANKYA',
    municipality: 'СТОЛИЧНА',
    municipalityEn: 'STOLICHNA',
    region: 'СОФИЯ (СТОЛИЦА)',
    regionEn: 'SOFIA (STOLITSA)',
    postCode: '1320',
    addressNomenclature: '2',
    x: '23.147057',
    y: '42.706974',
    servingDays: '1111110',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '2662',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАНОВО',
    nameEn: 'BANOVO',
    municipality: 'СУВОРОВО',
    municipalityEn: 'SUVOROVO',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9144',
    addressNomenclature: '0',
    x: '27.677665',
    y: '43.264544',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '2676',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'БАНСКО',
    nameEn: 'BANSKO',
    municipality: 'БАНСКО',
    municipalityEn: 'BANSKO',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2770',
    addressNomenclature: '1',
    x: '23.49091',
    y: '41.836986',
    servingDays: '1111110',
    servingOfficeId: '125',
    servingHubOfficeId: '13',
  },
  {
    id: '2689',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАНЧОВЦИ',
    nameEn: 'BANCHOVTSI',
    municipality: 'ИХТИМАН',
    municipalityEn: 'IHTIMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2060',
    addressNomenclature: '0',
    x: '23.697106',
    y: '42.522721',
    servingDays: '1010100',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '2693',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАНЯ',
    nameEn: 'BANYA',
    municipality: 'РАЗЛОГ',
    municipalityEn: 'RAZLOG',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2778',
    addressNomenclature: '0',
    x: '23.522761',
    y: '41.882756',
    servingDays: '1111110',
    servingOfficeId: '125',
    servingHubOfficeId: '13',
  },
  {
    id: '2703',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАНЯ',
    nameEn: 'BANYA',
    municipality: 'НЕСЕБЪР',
    municipalityEn: 'NESEBAR',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8239',
    addressNomenclature: '0',
    x: '27.818421',
    y: '42.775641',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '2717',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАНЯ',
    nameEn: 'BANYA',
    municipality: 'ПАНАГЮРИЩЕ',
    municipalityEn: 'PANAGYURISHTE',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4523',
    addressNomenclature: '0',
    x: '24.136477',
    y: '42.463742',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '2720',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'БАНЯ',
    nameEn: 'BANYA',
    municipality: 'КАРЛОВО',
    municipalityEn: 'KARLOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4360',
    addressNomenclature: '2',
    x: '24.830358',
    y: '42.545333',
    servingDays: '0101010',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '2734',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАНЯ',
    nameEn: 'BANYA',
    municipality: 'НОВА ЗАГОРА',
    municipalityEn: 'NOVA ZAGORA',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8914',
    addressNomenclature: '0',
    x: '25.991077',
    y: '42.606457',
    servingDays: '1111100',
    servingOfficeId: '56',
    servingHubOfficeId: '5',
  },
  {
    id: '2748',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАРАКОВО',
    nameEn: 'BARAKOVO',
    municipality: 'КОЧЕРИНОВО',
    municipalityEn: 'KOCHERINOVO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2634',
    addressNomenclature: '0',
    x: '23.059921',
    y: '42.063158',
    servingDays: '1111100',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '2751',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАРАЦИ',
    nameEn: 'BARATSI',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6910',
    addressNomenclature: '0',
    x: '25.647244',
    y: '41.51064',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '2779',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАРУТИН',
    nameEn: 'BARUTIN',
    municipality: 'ДОСПАТ',
    municipalityEn: 'DOSPAT',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4830',
    addressNomenclature: '0',
    x: '24.181386',
    y: '41.600816',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '2796',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАСАРБОВО',
    nameEn: 'BASARBOVO',
    municipality: 'РУСЕ',
    municipalityEn: 'RUSE',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7071',
    addressNomenclature: '0',
    x: '25.950064',
    y: '43.778667',
    servingDays: '1111100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '2806',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАСКАЛЦИ',
    nameEn: 'BASKALTSI',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2883',
    addressNomenclature: '0',
    x: '23.006675',
    y: '41.47408',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '2810',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАТА',
    nameEn: 'BATA',
    municipality: 'ПОМОРИЕ',
    municipalityEn: 'POMORIE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8218',
    addressNomenclature: '0',
    x: '27.491656',
    y: '42.736292',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '2823',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАТАК',
    nameEn: 'BATAK',
    municipality: 'ПАВЛИКЕНИ',
    municipalityEn: 'PAVLIKENI',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5228',
    addressNomenclature: '0',
    x: '25.338769',
    y: '43.349909',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '2837',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'БАТАК',
    nameEn: 'BATAK',
    municipality: 'БАТАК',
    municipalityEn: 'BATAK',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4580',
    addressNomenclature: '2',
    x: '24.218877',
    y: '41.944092',
    servingDays: '1111100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '72223',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'БАТАНОВЦИ',
    nameEn: 'BATANOVTSI',
    municipality: 'ПЕРНИК',
    municipalityEn: 'PERNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2340',
    addressNomenclature: '2',
    x: '22.955596',
    y: '42.598595',
    servingDays: '1111100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '2854',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАТИН',
    nameEn: 'BATIN',
    municipality: 'БОРОВО',
    municipalityEn: 'BOROVO',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7172',
    addressNomenclature: '0',
    x: '25.680078',
    y: '43.656524',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '2868',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАТИШНИЦА',
    nameEn: 'BATISHNITSA',
    municipality: 'ДВЕ МОГИЛИ',
    municipalityEn: 'DVE MOGILI',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7169',
    addressNomenclature: '0',
    x: '25.869795',
    y: '43.533591',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '2871',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАТОВО',
    nameEn: 'BATOVO',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9364',
    addressNomenclature: '0',
    x: '27.960554',
    y: '43.413744',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '2885',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАТОШЕВО',
    nameEn: 'BATOSHEVO',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5470',
    addressNomenclature: '0',
    x: '25.083007',
    y: '42.908589',
    servingDays: '1010100',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '2899',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАТУЛИЯ',
    nameEn: 'BATULIA',
    municipality: 'СВОГЕ',
    municipalityEn: 'SVOGE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2295',
    addressNomenclature: '0',
    x: '23.417903',
    y: '42.896928',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '2909',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАТУЛЦИ',
    nameEn: 'BATULTSI',
    municipality: 'ЯБЛАНИЦА',
    municipalityEn: 'YABLANITSA',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5764',
    addressNomenclature: '0',
    x: '24.00602',
    y: '43.087186',
    servingDays: '0101000',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '2912',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАУРЕНЕ',
    nameEn: 'BAURENE',
    municipality: 'КРИВОДОЛ',
    municipalityEn: 'KRIVODOL',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3068',
    addressNomenclature: '0',
    x: '23.486307',
    y: '43.443057',
    servingDays: '0101000',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '2926',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАХАЛИН',
    nameEn: 'BAHALIN',
    municipality: 'СЛИВНИЦА',
    municipalityEn: 'SLIVNITSA',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2219',
    addressNomenclature: '0',
    x: '22.914424',
    y: '42.885228',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '2935',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАХОВИЦА',
    nameEn: 'BAHOVITSA',
    municipality: 'ЛОВЕЧ',
    municipalityEn: 'LOVECH',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5567',
    addressNomenclature: '0',
    x: '24.682852',
    y: '43.188458',
    servingDays: '1111100',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '2943',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАХРЕЦИ',
    nameEn: 'BAHRETSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5367',
    addressNomenclature: '0',
    x: '25.531545',
    y: '42.828638',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '2957',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАЦОВА МАХАЛА',
    nameEn: 'BATSOVA MAHALA',
    municipality: 'НИКОПОЛ',
    municipalityEn: 'NIKOPOL',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5948',
    addressNomenclature: '0',
    x: '24.996614',
    y: '43.506667',
    servingDays: '1010100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '2960',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАЧЕВО',
    nameEn: 'BACHEVO',
    municipality: 'РАЗЛОГ',
    municipalityEn: 'RAZLOG',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2769',
    addressNomenclature: '0',
    x: '23.452763',
    y: '41.921555',
    servingDays: '1111110',
    servingOfficeId: '125',
    servingHubOfficeId: '13',
  },
  {
    id: '2974',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАЧКОВО',
    nameEn: 'BACHKOVO',
    municipality: 'АСЕНОВГРАД',
    municipalityEn: 'ASENOVGRAD',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4251',
    addressNomenclature: '0',
    x: '24.858674',
    y: '41.95179',
    servingDays: '0101000',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '2991',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАШЕВО',
    nameEn: 'BASHEVO',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6777',
    addressNomenclature: '0',
    x: '25.152108',
    y: '41.661305',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '3006',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАЩИНО',
    nameEn: 'BASHTINO',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6669',
    addressNomenclature: '0',
    x: '25.56444',
    y: '41.717534',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '3017',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАЩИНО',
    nameEn: 'BASHTINO',
    municipality: 'ГЛАВИНИЦА',
    municipalityEn: 'GLAVINITSA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7619',
    addressNomenclature: '0',
    x: '26.92771',
    y: '43.952223',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '3023',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАЩИНО',
    nameEn: 'BASHTINO',
    municipality: 'ОПАН',
    municipalityEn: 'OPAN',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6068',
    addressNomenclature: '0',
    x: '25.740384',
    y: '42.101611',
    servingDays: '0101000',
    servingOfficeId: '42',
    servingHubOfficeId: '5',
  },
  {
    id: '3037',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БАЯЧЕВО',
    nameEn: 'BAYACHEVO',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7745',
    addressNomenclature: '0',
    x: '26.662911',
    y: '43.229601',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '3040',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БДИНЦИ',
    nameEn: 'BDINTSI',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9381',
    addressNomenclature: '0',
    x: '27.520789',
    y: '43.522859',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '3054',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕБРОВО',
    nameEn: 'BEBROVO',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5084',
    addressNomenclature: '0',
    x: '26.009049',
    y: '42.953235',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '3068',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕГЛЕЖ',
    nameEn: 'BEGLEZH',
    municipality: 'ПЛЕВЕН',
    municipalityEn: 'PLEVEN',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5872',
    addressNomenclature: '0',
    x: '24.486573',
    y: '43.246891',
    servingDays: '1010100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '3085',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕГОВО',
    nameEn: 'BEGOVO',
    municipality: 'КАЛОЯНОВО',
    municipalityEn: 'KALOYANOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4166',
    addressNomenclature: '0',
    x: '24.828367',
    y: '42.411126',
    servingDays: '0101000',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '3099',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕГУНОВЦИ',
    nameEn: 'BEGUNOVTSI',
    municipality: 'БРЕЗНИК',
    municipalityEn: 'BREZNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2395',
    addressNomenclature: '0',
    x: '22.834098',
    y: '42.693841',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '3109',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕГУНЦИ',
    nameEn: 'BEGUNTSI',
    municipality: 'КАРЛОВО',
    municipalityEn: 'KARLOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4359',
    addressNomenclature: '0',
    x: '24.879601',
    y: '42.546702',
    servingDays: '1010100',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '3112',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕДЕН',
    nameEn: 'BEDEN',
    municipality: 'ДЕВИН',
    municipalityEn: 'DEVIN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4817',
    addressNomenclature: '0',
    x: '24.47218',
    y: '41.71674',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '3126',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕДЖЕНЕ',
    nameEn: 'BEDZHENE',
    municipality: 'НОВИ ПАЗАР',
    municipalityEn: 'NOVI PAZAR',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9924',
    addressNomenclature: '0',
    x: '27.265996',
    y: '43.448242',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '3143',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЖАНОВО',
    nameEn: 'BEZHANOVO',
    municipality: 'ЛУКОВИТ',
    municipalityEn: 'LUKOVIT',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5792',
    addressNomenclature: '0',
    x: '24.396464',
    y: '43.22903',
    servingDays: '1010100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '3157',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЖАНОВО',
    nameEn: 'BEZHANOVO',
    municipality: 'ГЕНЕРАЛ ТОШЕВО',
    municipalityEn: 'GENERAL TOSHEVO',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9567',
    addressNomenclature: '0',
    x: '28.390135',
    y: '43.712567',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '3174',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЗВОДИЦА',
    nameEn: 'BEZVODITSA',
    municipality: 'БАЛЧИК',
    municipalityEn: 'BALCHIK',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9637',
    addressNomenclature: '0',
    x: '27.973215',
    y: '43.521587',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '3188',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЗВОДНО',
    nameEn: 'BEZVODNO',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6718',
    addressNomenclature: '0',
    x: '25.100123',
    y: '41.750634',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '3191',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЗДЕН',
    nameEn: 'BEZDEN',
    municipality: 'КОСТИНБРОД',
    municipalityEn: 'KOSTINBROD',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2236',
    addressNomenclature: '0',
    x: '23.110454',
    y: '42.880644',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '3201',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЗДЕНИЦА',
    nameEn: 'BEZDENITSA',
    municipality: 'МОНТАНА',
    municipalityEn: 'MONTANA',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3454',
    addressNomenclature: '0',
    x: '23.194647',
    y: '43.548281',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '3215',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЗМЕР',
    nameEn: 'BEZMER',
    municipality: 'ТЕРВЕЛ',
    municipalityEn: 'TERVEL',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9480',
    addressNomenclature: '0',
    x: '27.414604',
    y: '43.79811',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '3229',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЗМЕР',
    nameEn: 'BEZMER',
    municipality: 'ТУНДЖА',
    municipalityEn: 'TUNDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8637',
    addressNomenclature: '0',
    x: '26.397292',
    y: '42.475916',
    servingDays: '0101000',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '3232',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЙКОВЦИ',
    nameEn: 'BEYKOVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5094',
    addressNomenclature: '0',
    x: '25.854915',
    y: '42.867406',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '95181',
    countryId: '100',
    mainSiteId: '0',
    type: 'к.',
    typeEn: 'k.',
    name: 'БЕКЛЕМЕТО',
    nameEn: 'BEKLEMETO',
    municipality: 'ТРОЯН',
    municipalityEn: 'TROYAN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5673',
    addressNomenclature: '0',
    x: '24.625933',
    y: '42.796052',
    servingDays: '1010100',
    servingOfficeId: '44',
    servingHubOfficeId: '11',
  },
  {
    id: '3246',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕКРИИТЕ',
    nameEn: 'BEKRIITE',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5300',
    addressNomenclature: '0',
    x: '25.298941',
    y: '42.934714',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '3561',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛ КАМЕН',
    nameEn: 'BEL KAMEN',
    municipality: 'ЯКОРУДА',
    municipalityEn: 'YAKORUDA',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2793',
    addressNomenclature: '0',
    x: '23.74573',
    y: '41.997518',
    servingDays: '1010100',
    servingOfficeId: '125',
    servingHubOfficeId: '13',
  },
  {
    id: '3263',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛА',
    nameEn: 'BELA',
    municipality: 'ДИМОВО',
    municipalityEn: 'DIMOVO',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3965',
    addressNomenclature: '0',
    x: '22.709336',
    y: '43.709407',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '3280',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛА РАДА',
    nameEn: 'BELA RADA',
    municipality: 'ВИДИН',
    municipalityEn: 'VIDIN',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3731',
    addressNomenclature: '0',
    x: '22.758678',
    y: '43.97565',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '3277',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛАНИЦА',
    nameEn: 'BELANITSA',
    municipality: 'РАДОМИР',
    municipalityEn: 'RADOMIR',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2453',
    addressNomenclature: '0',
    x: '22.937717',
    y: '42.482944',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '3294',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛАСИЦА',
    nameEn: 'BELASITSA',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2881',
    addressNomenclature: '0',
    x: '23.133448',
    y: '41.366692',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '3304',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛАЩИЦА',
    nameEn: 'BELASHTITSA',
    municipality: 'РОДОПИ',
    municipalityEn: 'RODOPI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4124',
    addressNomenclature: '0',
    x: '24.747118',
    y: '42.06529',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '3318',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛГУН',
    nameEn: 'BELGUN',
    municipality: 'КАВАРНА',
    municipalityEn: 'KAVARNA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9690',
    addressNomenclature: '0',
    x: '28.325397',
    y: '43.602135',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '3321',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛЕВ ДОЛ',
    nameEn: 'BELEV DOL',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4738',
    addressNomenclature: '0',
    x: '24.935962',
    y: '41.613009',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '3335',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛЕВЕХЧЕВО',
    nameEn: 'BELEVEHCHEVO',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2800',
    addressNomenclature: '0',
    x: '23.307214',
    y: '41.565472',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '3349',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛЕВРЕН',
    nameEn: 'BELEVREN',
    municipality: 'СРЕДЕЦ',
    municipalityEn: 'SREDETS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8345',
    addressNomenclature: '0',
    x: '27.177799',
    y: '42.093454',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '3366',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'БЕЛЕНЕ',
    nameEn: 'BELENE',
    municipality: 'БЕЛЕНЕ',
    municipalityEn: 'BELENE',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5930',
    addressNomenclature: '2',
    x: '25.125362',
    y: '43.644044',
    servingDays: '1111110',
    servingOfficeId: '108',
    servingHubOfficeId: '11',
  },
  {
    id: '3373',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛЕНЦИ',
    nameEn: 'BELENTSI',
    municipality: 'ЛУКОВИТ',
    municipalityEn: 'LUKOVIT',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5783',
    addressNomenclature: '0',
    x: '24.063532',
    y: '43.119081',
    servingDays: '0101000',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '3383',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛИ БРЕГ',
    nameEn: 'BELI BREG',
    municipality: 'БОЙЧИНОВЦИ',
    municipalityEn: 'BOYCHINOVTSI',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3441',
    addressNomenclature: '0',
    x: '23.430725',
    y: '43.510509',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '3397',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛИ БРОД',
    nameEn: 'BELI BROD',
    municipality: 'БОЙЧИНОВЦИ',
    municipalityEn: 'BOYCHINOVTSI',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3447',
    addressNomenclature: '0',
    x: '23.576172',
    y: '43.536273',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '3407',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛИ БРЯГ',
    nameEn: 'BELI BRYAG',
    municipality: 'РАДНЕВО',
    municipalityEn: 'RADNEVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6262',
    addressNomenclature: '0',
    x: '25.941636',
    y: '42.239057',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '3410',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛИ ВИР',
    nameEn: 'BELI VIR',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6702',
    addressNomenclature: '0',
    x: '25.310717',
    y: '41.73027',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '3424',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛИ ДОЛ',
    nameEn: 'BELI DOL',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6590',
    addressNomenclature: '0',
    x: '25.955913',
    y: '41.409679',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '3438',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛИ ИЗВОР',
    nameEn: 'BELI IZVOR',
    municipality: 'ВРАЦА',
    municipalityEn: 'VRATSA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3040',
    addressNomenclature: '0',
    x: '23.454954',
    y: '43.273064',
    servingDays: '1111100',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '3441',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛИ ИСКЪР',
    nameEn: 'BELI ISKAR',
    municipality: 'САМОКОВ',
    municipalityEn: 'SAMOKOV',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2011',
    addressNomenclature: '0',
    x: '23.538411',
    y: '42.271639',
    servingDays: '0101000',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '24092',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛИ ЛОМ',
    nameEn: 'BELI LOM',
    municipality: 'ЛОЗНИЦА',
    municipalityEn: 'LOZNITSA',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7293',
    addressNomenclature: '0',
    x: '26.719732',
    y: '43.429486',
    servingDays: '0101000',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '3486',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛИ ОСЪМ',
    nameEn: 'BELI OSAM',
    municipality: 'ТРОЯН',
    municipalityEn: 'TROYAN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5662',
    addressNomenclature: '0',
    x: '24.632093',
    y: '42.849886',
    servingDays: '1010100',
    servingOfficeId: '44',
    servingHubOfficeId: '11',
  },
  {
    id: '3499',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛИ ПЛАСТ',
    nameEn: 'BELI PLAST',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6689',
    addressNomenclature: '0',
    x: '25.42879',
    y: '41.776002',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '3455',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛИЛА',
    nameEn: 'BELILA',
    municipality: 'СРЕДЕЦ',
    municipalityEn: 'SREDETS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8316',
    addressNomenclature: '0',
    x: '27.102281',
    y: '42.330867',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '3469',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛИМЕЛ',
    nameEn: 'BELIMEL',
    municipality: 'ЧИПРОВЦИ',
    municipalityEn: 'CHIPROVTSI',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3462',
    addressNomenclature: '0',
    x: '22.974467',
    y: '43.43331',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '3472',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛИНЦИ',
    nameEn: 'BELINTSI',
    municipality: 'ИСПЕРИХ',
    municipalityEn: 'ISPERIH',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7432',
    addressNomenclature: '0',
    x: '26.958385',
    y: '43.650665',
    servingDays: '0101000',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '3504',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'БЕЛИЦА',
    nameEn: 'BELITSA',
    municipality: 'БЕЛИЦА',
    municipalityEn: 'BELITSA',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2780',
    addressNomenclature: '2',
    x: '23.557441',
    y: '41.950749',
    servingDays: '1111100',
    servingOfficeId: '125',
    servingHubOfficeId: '13',
  },
  {
    id: '3513',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛИЦА',
    nameEn: 'BELITSA',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5363',
    addressNomenclature: '0',
    x: '25.579359',
    y: '42.852064',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '3527',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛИЦА',
    nameEn: 'BELITSA',
    municipality: 'ТУТРАКАН',
    municipalityEn: 'TUTRAKAN',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7648',
    addressNomenclature: '0',
    x: '26.664851',
    y: '43.92723',
    servingDays: '1111100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '3530',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛИЦА',
    nameEn: 'BELITSA',
    municipality: 'ЛЪКИ',
    municipalityEn: 'LAKI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4242',
    addressNomenclature: '0',
    x: '24.896609',
    y: '41.826581',
    servingDays: '0101000',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '3544',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛИЦА',
    nameEn: 'BELITSA',
    municipality: 'ЛЮБИМЕЦ',
    municipalityEn: 'LYUBIMETS',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6557',
    addressNomenclature: '0',
    x: '26.005845',
    y: '41.827621',
    servingDays: '1010100',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '7960',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛИЦА',
    nameEn: 'BELITSA',
    municipality: 'ИХТИМАН',
    municipalityEn: 'IHTIMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2062',
    addressNomenclature: '0',
    x: '23.890419',
    y: '42.509733',
    servingDays: '0101000',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '3558',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛИШ',
    nameEn: 'BELISH',
    municipality: 'ТРОЯН',
    municipalityEn: 'TROYAN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5628',
    addressNomenclature: '0',
    x: '24.718647',
    y: '42.932272',
    servingDays: '0101000',
    servingOfficeId: '44',
    servingHubOfficeId: '11',
  },
  {
    id: '3664',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛО ПОЛЕ',
    nameEn: 'BELO POLE',
    municipality: 'БЛАГОЕВГРАД',
    municipalityEn: 'BLAGOEVGRAD',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2709',
    addressNomenclature: '0',
    x: '23.047019',
    y: '42.032283',
    servingDays: '1111100',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '3678',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛО ПОЛЕ',
    nameEn: 'BELO POLE',
    municipality: 'РУЖИНЦИ',
    municipalityEn: 'RUZHINTSI',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3961',
    addressNomenclature: '0',
    x: '22.89972',
    y: '43.648951',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '3575',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛОВЕЦ',
    nameEn: 'BELOVETS',
    municipality: 'КУБРАТ',
    municipalityEn: 'KUBRAT',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7321',
    addressNomenclature: '0',
    x: '26.384277',
    y: '43.814216',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '3589',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛОВИЦА',
    nameEn: 'BELOVITSA',
    municipality: 'ХИСАРЯ',
    municipalityEn: 'HISARYA',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4182',
    addressNomenclature: '0',
    x: '24.531715',
    y: '42.424736',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '3592',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'БЕЛОВО',
    nameEn: 'BELOVO',
    municipality: 'БЕЛОВО',
    municipalityEn: 'BELOVO',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4470',
    addressNomenclature: '2',
    x: '24.018005',
    y: '42.214677',
    servingDays: '1111100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '3602',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛОГРАДЕЦ',
    nameEn: 'BELOGRADETS',
    municipality: 'ВЕТРИНО',
    municipalityEn: 'VETRINO',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9230',
    addressNomenclature: '0',
    x: '27.331221',
    y: '43.35208',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '3616',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'БЕЛОГРАДЧИК',
    nameEn: 'BELOGRADCHIK',
    municipality: 'БЕЛОГРАДЧИК',
    municipalityEn: 'BELOGRADCHIK',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3900',
    addressNomenclature: '2',
    x: '22.686773',
    y: '43.625922',
    servingDays: '1111100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '27440',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛОДОЛ',
    nameEn: 'BELODOL',
    municipality: 'ПОМОРИЕ',
    municipalityEn: 'POMORIE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8219',
    addressNomenclature: '0',
    x: '27.451442',
    y: '42.721618',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '3620',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛОЗЕМ',
    nameEn: 'BELOZEM',
    municipality: 'РАКОВСКИ',
    municipalityEn: 'RAKOVSKI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4130',
    addressNomenclature: '0',
    x: '25.046355',
    y: '42.197286',
    servingDays: '1111100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '3633',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛОКОПИТОВО',
    nameEn: 'BELOKOPITOVO',
    municipality: 'ШУМЕН',
    municipalityEn: 'SHUMEN',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9801',
    addressNomenclature: '0',
    x: '26.897477',
    y: '43.332205',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '3647',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛОМОРЦИ',
    nameEn: 'BELOMORTSI',
    municipality: 'ОМУРТАГ',
    municipalityEn: 'OMURTAG',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7917',
    addressNomenclature: '0',
    x: '26.430914',
    y: '43.140637',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '3650',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛОМЪЖИТЕ',
    nameEn: 'BELOMAZHITE',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5340',
    addressNomenclature: '0',
    x: '25.40787',
    y: '42.925188',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '3681',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛОПОЛЦИ',
    nameEn: 'BELOPOLTSI',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6588',
    addressNomenclature: '0',
    x: '25.806448',
    y: '41.521304',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '3695',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛОПОЛЯНЕ',
    nameEn: 'BELOPOLYANE',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6584',
    addressNomenclature: '0',
    x: '26.150003',
    y: '41.457317',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '3705',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛОПОПЦИ',
    nameEn: 'BELOPOPTSI',
    municipality: 'ГОРНА МАЛИНА',
    municipalityEn: 'GORNA MALINA',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2132',
    addressNomenclature: '0',
    x: '23.767695',
    y: '42.668371',
    servingDays: '0101000',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '3719',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'БЕЛОСЛАВ',
    nameEn: 'BELOSLAV',
    municipality: 'БЕЛОСЛАВ',
    municipalityEn: 'BELOSLAV',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9178',
    addressNomenclature: '0',
    x: '27.703279',
    y: '43.187204',
    servingDays: '1111110',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '3722',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛОТИНЦИ',
    nameEn: 'BELOTINTSI',
    municipality: 'МОНТАНА',
    municipalityEn: 'MONTANA',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3499',
    addressNomenclature: '0',
    x: '22.92958',
    y: '43.549482',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '3745',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛЦОВ',
    nameEn: 'BELTSOV',
    municipality: 'ЦЕНОВО',
    municipalityEn: 'TSENOVO',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7138',
    addressNomenclature: '0',
    x: '25.641214',
    y: '43.566198',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '3753',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛЧЕВЦИ',
    nameEn: 'BELCHEVTSI',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5035',
    addressNomenclature: '0',
    x: '25.621247',
    y: '42.887532',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '3767',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛЧИН',
    nameEn: 'BELCHIN',
    municipality: 'САМОКОВ',
    municipalityEn: 'SAMOKOV',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2025',
    addressNomenclature: '0',
    x: '23.380733',
    y: '42.361759',
    servingDays: '0101000',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '3770',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛЧИНСКИ БАНИ',
    nameEn: 'BELCHINSKI BANI',
    municipality: 'САМОКОВ',
    municipalityEn: 'SAMOKOV',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2024',
    addressNomenclature: '0',
    x: '23.412067',
    y: '42.366724',
    servingDays: '0101000',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '3784',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛЬОВО',
    nameEn: 'BELYOVO',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2824',
    addressNomenclature: '0',
    x: '23.549913',
    y: '41.480665',
    servingDays: '1010100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '3798',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛЯКОВЕЦ',
    nameEn: 'BELYAKOVETS',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5031',
    addressNomenclature: '0',
    x: '25.585853',
    y: '43.10512',
    servingDays: '1111110',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '3808',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕЛЯНОВО',
    nameEn: 'BELYANOVO',
    municipality: 'ЦЕНОВО',
    municipalityEn: 'TSENOVO',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7134',
    addressNomenclature: '0',
    x: '25.615542',
    y: '43.612479',
    servingDays: '1010100',
    servingOfficeId: '250',
    servingHubOfficeId: '11',
  },
  {
    id: '3811',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕНКОВСКИ',
    nameEn: 'BENKOVSKI',
    municipality: 'АВРЕН',
    municipalityEn: 'AVREN',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9133',
    addressNomenclature: '0',
    x: '27.78497',
    y: '43.114884',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '3825',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕНКОВСКИ',
    nameEn: 'BENKOVSKI',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6865',
    addressNomenclature: '0',
    x: '25.256244',
    y: '41.377616',
    servingDays: '1111100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '3839',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕНКОВСКИ',
    nameEn: 'BENKOVSKI',
    municipality: 'МАРИЦА',
    municipalityEn: 'MARITSA',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4201',
    addressNomenclature: '0',
    x: '24.649914',
    y: '42.208192',
    servingDays: '1111100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '3842',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕНКОВСКИ',
    nameEn: 'BENKOVSKI',
    municipality: 'МИРКОВО',
    municipalityEn: 'MIRKOVO',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2076',
    addressNomenclature: '0',
    x: '24.01023',
    y: '42.647724',
    servingDays: '0101000',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '3856',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕНКОВСКИ',
    nameEn: 'BENKOVSKI',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6055',
    addressNomenclature: '0',
    x: '25.889747',
    y: '42.410935',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '3860',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕНКОВСКИ',
    nameEn: 'BENKOVSKI',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9380',
    addressNomenclature: '0',
    x: '27.478738',
    y: '43.598475',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '3873',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕРАЙНЦИ',
    nameEn: 'BERAYNTSI',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2488',
    addressNomenclature: '0',
    x: '22.594537',
    y: '42.808171',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '3887',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕРЕНДЕ',
    nameEn: 'BERENDE',
    municipality: 'ЗЕМЕН',
    municipalityEn: 'ZEMEN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2435',
    addressNomenclature: '0',
    x: '22.818602',
    y: '42.615696',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '3890',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕРЕНДЕ',
    nameEn: 'BERENDE',
    municipality: 'ДРАГОМАН',
    municipalityEn: 'DRAGOMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2212',
    addressNomenclature: '0',
    x: '22.933968',
    y: '42.990402',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '3900',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕРЕНДЕ ИЗВОР',
    nameEn: 'BERENDE IZVOR',
    municipality: 'ДРАГОМАН',
    municipalityEn: 'DRAGOMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2212',
    addressNomenclature: '0',
    x: '22.898629',
    y: '43.000264',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '3914',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕРИЕВО',
    nameEn: 'BERIEVO',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5438',
    addressNomenclature: '0',
    x: '24.893925',
    y: '42.968003',
    servingDays: '1010100',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '3928',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'БЕРКОВИЦА',
    nameEn: 'BERKOVITSA',
    municipality: 'БЕРКОВИЦА',
    municipalityEn: 'BERKOVITSA',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3500',
    addressNomenclature: '2',
    x: '23.125089',
    y: '43.237148',
    servingDays: '1111100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '3931',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕРКОВСКИ',
    nameEn: 'BERKOVSKI',
    municipality: 'ПОПОВО',
    municipalityEn: 'POPOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7857',
    addressNomenclature: '0',
    x: '26.212504',
    y: '43.240034',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '3945',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕРКОВЦИ',
    nameEn: 'BERKOVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5093',
    addressNomenclature: '0',
    x: '25.72756',
    y: '42.885894',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '3959',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕРОНОВО',
    nameEn: 'BERONOVO',
    municipality: 'СУНГУРЛАРЕ',
    municipalityEn: 'SUNGURLARE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8448',
    addressNomenclature: '0',
    x: '26.702955',
    y: '42.829052',
    servingDays: '1010100',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '3962',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕРСИН',
    nameEn: 'BERSIN',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2587',
    addressNomenclature: '0',
    x: '22.777662',
    y: '42.244881',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '3976',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕСЛЕН',
    nameEn: 'BESLEN',
    municipality: 'ХАДЖИДИМОВО',
    municipalityEn: 'HADZHIDIMOVO',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2934',
    addressNomenclature: '0',
    x: '23.96511',
    y: '41.47257',
    servingDays: '1010100',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '3986',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЕСНУРКА',
    nameEn: 'BESNURKA',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6698',
    addressNomenclature: '0',
    x: '25.23545',
    y: '41.758728',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '3993',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БИВОЛАРЕ',
    nameEn: 'BIVOLARE',
    municipality: 'ДОЛНА МИТРОПОЛИЯ',
    municipalityEn: 'DOLNA MITROPOLIA',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5849',
    addressNomenclature: '0',
    x: '24.558849',
    y: '43.485654',
    servingDays: '1010100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '4008',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БИВОЛЯНЕ',
    nameEn: 'BIVOLYANE',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6837',
    addressNomenclature: '0',
    x: '25.504009',
    y: '41.588901',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '4011',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БИЖОВЦИ',
    nameEn: 'BIZHOVTSI',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5035',
    addressNomenclature: '0',
    x: '25.651767',
    y: '42.826654',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '4025',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БИЖОВЦИ',
    nameEn: 'BIZHOVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5350',
    addressNomenclature: '0',
    x: '25.476486',
    y: '42.892543',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '4039',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БИКОВО',
    nameEn: 'BIKOVO',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8885',
    addressNomenclature: '0',
    x: '26.190489',
    y: '42.476578',
    servingDays: '0101000',
    servingOfficeId: '56',
    servingHubOfficeId: '5',
  },
  {
    id: '4042',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БИЛИНЦИ',
    nameEn: 'BILINTSI',
    municipality: 'БРЕЗНИК',
    municipalityEn: 'BREZNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2383',
    addressNomenclature: '0',
    x: '22.819027',
    y: '42.791391',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '4056',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БИЛКА',
    nameEn: 'BILKA',
    municipality: 'РУЕН',
    municipalityEn: 'RUEN',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8551',
    addressNomenclature: '0',
    x: '27.227934',
    y: '42.935613',
    servingDays: '1111100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '4068',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БИЛКИНИ',
    nameEn: 'BILKINI',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5370',
    addressNomenclature: '0',
    x: '25.549366',
    y: '42.940578',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '4090',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БИЛО',
    nameEn: 'BILO',
    municipality: 'КАВАРНА',
    municipalityEn: 'KAVARNA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9691',
    addressNomenclature: '0',
    x: '28.40465',
    y: '43.645747',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '4100',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БИЛЯНСКА',
    nameEn: 'BILYANSKA',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4789',
    addressNomenclature: '0',
    x: '24.61833',
    y: '41.449879',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '4114',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БИНКОС',
    nameEn: 'BINKOS',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8855',
    addressNomenclature: '0',
    x: '26.096432',
    y: '42.64848',
    servingDays: '0101000',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '7836',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БИРКОВА',
    nameEn: 'BIRKOVA',
    municipality: 'ВЕЛИНГРАД',
    municipalityEn: 'VELINGRAD',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4650',
    addressNomenclature: '0',
    x: '23.815007',
    y: '41.98471',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '4128',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БИСЕР',
    nameEn: 'BISER',
    municipality: 'ХАРМАНЛИ',
    municipalityEn: 'HARMANLI',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6470',
    addressNomenclature: '0',
    x: '25.9884',
    y: '41.866434',
    servingDays: '1010100',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '4131',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БИСЕРЦИ',
    nameEn: 'BISERTSI',
    municipality: 'КУБРАТ',
    municipalityEn: 'KUBRAT',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7334',
    addressNomenclature: '0',
    x: '26.505752',
    y: '43.884484',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '4145',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БИСТРА',
    nameEn: 'BISTRA',
    municipality: 'АЛФАТАР',
    municipalityEn: 'ALFATAR',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7557',
    addressNomenclature: '0',
    x: '27.375866',
    y: '43.840451',
    servingDays: '0101000',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '4159',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БИСТРА',
    nameEn: 'BISTRA',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7777',
    addressNomenclature: '0',
    x: '26.561959',
    y: '43.329683',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '4162',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БИСТРЕНЦИ',
    nameEn: 'BISTRENTSI',
    municipality: 'БЯЛА',
    municipalityEn: 'BYALA',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7121',
    addressNomenclature: '0',
    x: '25.816859',
    y: '43.419397',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '4176',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БИСТРЕЦ',
    nameEn: 'BISTRETS',
    municipality: 'СРЕДЕЦ',
    municipalityEn: 'SREDETS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8334',
    addressNomenclature: '0',
    x: '27.015412',
    y: '42.314164',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '4193',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БИСТРЕЦ',
    nameEn: 'BISTRETS',
    municipality: 'КРУШАРИ',
    municipalityEn: 'KRUSHARI',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9412',
    addressNomenclature: '0',
    x: '27.729819',
    y: '43.831657',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '4203',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БИСТРИЛИЦА',
    nameEn: 'BISTRILITSA',
    municipality: 'БЕРКОВИЦА',
    municipalityEn: 'BERKOVITSA',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3527',
    addressNomenclature: '0',
    x: '23.105592',
    y: '43.357379',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '4217',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БИСТРИЦА',
    nameEn: 'BISTRITSA',
    municipality: 'БЛАГОЕВГРАД',
    municipalityEn: 'BLAGOEVGRAD',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2724',
    addressNomenclature: '0',
    x: '23.183439',
    y: '42.053195',
    servingDays: '1111110',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '4220',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БИСТРИЦА',
    nameEn: 'BISTRITSA',
    municipality: 'ДУПНИЦА',
    municipalityEn: 'DUPNITSA',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2644',
    addressNomenclature: '0',
    x: '23.163641',
    y: '42.239743',
    servingDays: '1111110',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '4234',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БИСТРИЦА',
    nameEn: 'BISTRITSA',
    municipality: 'СТОЛИЧНА',
    municipalityEn: 'STOLICHNA',
    region: 'СОФИЯ (СТОЛИЦА)',
    regionEn: 'SOFIA (STOLITSA)',
    postCode: '1444',
    addressNomenclature: '2',
    x: '23.359716',
    y: '42.585809',
    servingDays: '1111100',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '4248',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БИСТРОГЛЕД',
    nameEn: 'BISTROGLED',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6769',
    addressNomenclature: '0',
    x: '25.12608',
    y: '41.556356',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '4251',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЛАГОВО',
    nameEn: 'BLAGOVO',
    municipality: 'МОНТАНА',
    municipalityEn: 'MONTANA',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3488',
    addressNomenclature: '0',
    x: '23.219929',
    y: '43.353167',
    servingDays: '1111100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '4265',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЛАГОВО',
    nameEn: 'BLAGOVO',
    municipality: 'ШУМЕН',
    municipalityEn: 'SHUMEN',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9812',
    addressNomenclature: '0',
    x: '27.057335',
    y: '43.222108',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '4279',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'БЛАГОЕВГРАД',
    nameEn: 'BLAGOEVGRAD',
    municipality: 'БЛАГОЕВГРАД',
    municipalityEn: 'BLAGOEVGRAD',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2700',
    addressNomenclature: '1',
    x: '23.089689',
    y: '42.01198',
    servingDays: '1111110',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '4282',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЛАГОЕВО',
    nameEn: 'BLAGOEVO',
    municipality: 'СТРАЖИЦА',
    municipalityEn: 'STRAZHITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5178',
    addressNomenclature: '0',
    x: '25.959855',
    y: '43.19524',
    servingDays: '1010100',
    servingOfficeId: '46',
    servingHubOfficeId: '11',
  },
  {
    id: '4296',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЛАГОЕВО',
    nameEn: 'BLAGOEVO',
    municipality: 'РАЗГРАД',
    municipalityEn: 'RAZGRAD',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7275',
    addressNomenclature: '0',
    x: '26.401677',
    y: '43.45497',
    servingDays: '0101000',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '4306',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЛАГУН',
    nameEn: 'BLAGUN',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6955',
    addressNomenclature: '0',
    x: '25.742419',
    y: '41.376303',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '4314',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЛАЖИЕВО',
    nameEn: 'BLAZHIEVO',
    municipality: 'БОБОШЕВО',
    municipalityEn: 'BOBOSHEVO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2678',
    addressNomenclature: '0',
    x: '23.048886',
    y: '42.202416',
    servingDays: '0101000',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '4323',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЛАТЕЦ',
    nameEn: 'BLATETS',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2548',
    addressNomenclature: '0',
    x: '22.602452',
    y: '42.315043',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '4337',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЛАТЕЦ',
    nameEn: 'BLATETS',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8878',
    addressNomenclature: '0',
    x: '26.536365',
    y: '42.633059',
    servingDays: '1010100',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '4340',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЛАТЕШНИЦА',
    nameEn: 'BLATESHNITSA',
    municipality: 'ЗЕМЕН',
    municipalityEn: 'ZEMEN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2441',
    addressNomenclature: '0',
    x: '22.777061',
    y: '42.447924',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '4354',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЛАТИНО',
    nameEn: 'BLATINO',
    municipality: 'ДУПНИЦА',
    municipalityEn: 'DUPNITSA',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2617',
    addressNomenclature: '0',
    x: '23.097805',
    y: '42.30095',
    servingDays: '1111110',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '4368',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЛАТНИЦА',
    nameEn: 'BLATNITSA',
    municipality: 'СТРЕЛЧА',
    municipalityEn: 'STRELCHA',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4537',
    addressNomenclature: '0',
    x: '24.402466',
    y: '42.372759',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '4371',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЛАТО',
    nameEn: 'BLATO',
    municipality: 'БОБОВ ДОЛ',
    municipalityEn: 'BOBOV DOL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2657',
    addressNomenclature: '0',
    x: '22.938166',
    y: '42.239436',
    servingDays: '1010100',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '4399',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЛАТСКА',
    nameEn: 'BLATSKA',
    municipality: 'ХАДЖИДИМОВО',
    municipalityEn: 'HADZHIDIMOVO',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2941',
    addressNomenclature: '0',
    x: '23.865457',
    y: '41.5335',
    servingDays: '1111100',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '4409',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЛЕНИКА',
    nameEn: 'BLENIKA',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6742',
    addressNomenclature: '0',
    x: '25.32017',
    y: '41.672794',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '4412',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЛИЗНАК',
    nameEn: 'BLIZNAK',
    municipality: 'МАЛКО ТЪРНОВО',
    municipalityEn: 'MALKO TARNOVO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8174',
    addressNomenclature: '0',
    x: '27.305866',
    y: '42.160062',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '4426',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЛИЗНАЦИ',
    nameEn: 'BLIZNATSI',
    municipality: 'АВРЕН',
    municipalityEn: 'AVREN',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9132',
    addressNomenclature: '0',
    x: '27.86708',
    y: '43.060094',
    servingDays: '1111100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '4430',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЛИЗНАЦИ',
    nameEn: 'BLIZNATSI',
    municipality: 'ХИТРИНО',
    municipalityEn: 'HITRINO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9787',
    addressNomenclature: '0',
    x: '26.947558',
    y: '43.472258',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '4443',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЛИЗНЕЦ',
    nameEn: 'BLIZNETS',
    municipality: 'ТВЪРДИЦА',
    municipalityEn: 'TVARDITSA',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8896',
    addressNomenclature: '0',
    x: '26.051751',
    y: '42.632981',
    servingDays: '0101000',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '4457',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЛЪСКОВО',
    nameEn: 'BLASKOVO',
    municipality: 'ПРОВАДИЯ',
    municipalityEn: 'PROVADIA',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9240',
    addressNomenclature: '0',
    x: '27.328971',
    y: '43.121591',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '4460',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЛЪСКОВЦИ',
    nameEn: 'BLASKOVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5078',
    addressNomenclature: '0',
    x: '25.853349',
    y: '42.955681',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '4474',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОАЗЪТ',
    nameEn: 'BOAZAT',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5453',
    addressNomenclature: '0',
    x: '24.96186',
    y: '42.906525',
    servingDays: '1010100',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '4488',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОБЕВЦИ',
    nameEn: 'BOBEVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5300',
    addressNomenclature: '0',
    x: '25.300258',
    y: '42.942233',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '4491',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОБЕШИНО',
    nameEn: 'BOBESHINO',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2567',
    addressNomenclature: '0',
    x: '22.427898',
    y: '42.304704',
    servingDays: '0101000',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '4501',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'БОБОВ ДОЛ',
    nameEn: 'BOBOV DOL',
    municipality: 'БОБОВ ДОЛ',
    municipalityEn: 'BOBOV DOL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2670',
    addressNomenclature: '2',
    x: '23.006188',
    y: '42.363229',
    servingDays: '1111100',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '4515',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОБОВЕЦ',
    nameEn: 'BOBOVETS',
    municipality: 'БАЛЧИК',
    municipalityEn: 'BALCHIK',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9616',
    addressNomenclature: '0',
    x: '28.087252',
    y: '43.441094',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '4529',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОБОРАЦИ',
    nameEn: 'BOBORATSI',
    municipality: 'РАДОМИР',
    municipalityEn: 'RADOMIR',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2454',
    addressNomenclature: '0',
    x: '22.96562',
    y: '42.472419',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '4532',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'БОБОШЕВО',
    nameEn: 'BOBOSHEVO',
    municipality: 'БОБОШЕВО',
    municipalityEn: 'BOBOSHEVO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2660',
    addressNomenclature: '2',
    x: '23.000388',
    y: '42.152706',
    servingDays: '1111100',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '4546',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОВ',
    nameEn: 'BOV',
    municipality: 'СВОГЕ',
    municipalityEn: 'SVOGE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2270',
    addressNomenclature: '0',
    x: '23.377648',
    y: '43.032897',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '7925',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОГАТИНО',
    nameEn: 'BOGATINO',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6781',
    addressNomenclature: '0',
    x: '25.187383',
    y: '41.625457',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '4555',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОГАТОВО',
    nameEn: 'BOGATOVO',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5421',
    addressNomenclature: '0',
    x: '25.156275',
    y: '43.028383',
    servingDays: '0101000',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '4563',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОГДАН',
    nameEn: 'BOGDAN',
    municipality: 'КАРЛОВО',
    municipalityEn: 'KARLOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4343',
    addressNomenclature: '0',
    x: '24.643851',
    y: '42.642791',
    servingDays: '1010100',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '4580',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОГДАН',
    nameEn: 'BOGDAN',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9348',
    addressNomenclature: '0',
    x: '27.78681',
    y: '43.507068',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '4594',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОГДАНИЦА',
    nameEn: 'BOGDANITSA',
    municipality: 'САДОВО',
    municipalityEn: 'SADOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4115',
    addressNomenclature: '0',
    x: '25.026486',
    y: '42.103926',
    servingDays: '1010100',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '4604',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОГДАНЛИЯ',
    nameEn: 'BOGDANLIA',
    municipality: 'ЕЛИН ПЕЛИН',
    municipalityEn: 'ELIN PELIN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2116',
    addressNomenclature: '0',
    x: '23.687977',
    y: '42.615214',
    servingDays: '1010100',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '4618',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОГДАНОВ ДОЛ',
    nameEn: 'BOGDANOV DOL',
    municipality: 'ПЕРНИК',
    municipalityEn: 'PERNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2341',
    addressNomenclature: '0',
    x: '22.970501',
    y: '42.615156',
    servingDays: '1111100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '4621',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОГДАНОВО',
    nameEn: 'BOGDANOVO',
    municipality: 'СРЕДЕЦ',
    municipalityEn: 'SREDETS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8337',
    addressNomenclature: '0',
    x: '27.260711',
    y: '42.23121',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '4635',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОГДАНОВО',
    nameEn: 'BOGDANOVO',
    municipality: 'НОВА ЗАГОРА',
    municipalityEn: 'NOVA ZAGORA',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8948',
    addressNomenclature: '0',
    x: '25.997063',
    y: '42.392255',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '4649',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОГДАНОВЦИ',
    nameEn: 'BOGDANOVTSI',
    municipality: 'ИХТИМАН',
    municipalityEn: 'IHTIMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2060',
    addressNomenclature: '0',
    x: '23.766917',
    y: '42.537203',
    servingDays: '0101000',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '4652',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОГДАНСКО',
    nameEn: 'BOGDANSKO',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5084',
    addressNomenclature: '0',
    x: '26.030974',
    y: '42.958371',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '4666',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОГДАНЦИ',
    nameEn: 'BOGDANTSI',
    municipality: 'САМУИЛ',
    municipalityEn: 'SAMUIL',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7449',
    addressNomenclature: '0',
    x: '26.81327',
    y: '43.610032',
    servingDays: '1010100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '4670',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОГДАНЦИ',
    nameEn: 'BOGDANTSI',
    municipality: 'ГЛАВИНИЦА',
    municipalityEn: 'GLAVINITSA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7611',
    addressNomenclature: '0',
    x: '26.788978',
    y: '43.975582',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '4683',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОГДАНЧОВЦИ',
    nameEn: 'BOGDANCHOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5301',
    addressNomenclature: '0',
    x: '25.364184',
    y: '42.836679',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '4697',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОГОЙНА',
    nameEn: 'BOGOYNA',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2467',
    addressNomenclature: '0',
    x: '22.66101',
    y: '42.870995',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '4707',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОГОЛИН',
    nameEn: 'BOGOLIN',
    municipality: 'САТОВЧА',
    municipalityEn: 'SATOVCHA',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2931',
    addressNomenclature: '0',
    x: '23.957102',
    y: '41.540036',
    servingDays: '1111100',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '4724',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОГОМИЛ',
    nameEn: 'BOGOMIL',
    municipality: 'ХАРМАНЛИ',
    municipalityEn: 'HARMANLI',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6460',
    addressNomenclature: '0',
    x: '26.023558',
    y: '41.989787',
    servingDays: '1010100',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '4738',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОГОМИЛОВО',
    nameEn: 'BOGOMILOVO',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6065',
    addressNomenclature: '0',
    x: '25.560152',
    y: '42.387678',
    servingDays: '1111110',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '4741',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОГОМИЛЦИ',
    nameEn: 'BOGOMILTSI',
    municipality: 'САМУИЛ',
    municipalityEn: 'SAMUIL',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7458',
    addressNomenclature: '0',
    x: '26.732866',
    y: '43.494882',
    servingDays: '1010100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '4755',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОГОМОЛСКО',
    nameEn: 'BOGOMOLSKO',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7997',
    addressNomenclature: '0',
    x: '26.111549',
    y: '43.044055',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '4772',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОГОРОВО',
    nameEn: 'BOGOROVO',
    municipality: 'СИЛИСТРА',
    municipalityEn: 'SILISTRA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7542',
    addressNomenclature: '0',
    x: '27.363733',
    y: '44.021199',
    servingDays: '0101000',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '4786',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОГОРОВО',
    nameEn: 'BOGOROVO',
    municipality: 'СТРАЛДЖА',
    municipalityEn: 'STRALDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8687',
    addressNomenclature: '0',
    x: '26.854866',
    y: '42.451169',
    servingDays: '0101000',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '66322',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОГОРОДИЦА',
    nameEn: 'BOGORODITSA',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2875',
    addressNomenclature: '0',
    x: '23.188795',
    y: '41.510424',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '4796',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОГОСЛОВ',
    nameEn: 'BOGOSLOV',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2509',
    addressNomenclature: '0',
    x: '22.673127',
    y: '42.253328',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '4801',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОГУТЕВО',
    nameEn: 'BOGUTEVO',
    municipality: 'ЧЕПЕЛАРЕ',
    municipalityEn: 'CHEPELARE',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4855',
    addressNomenclature: '0',
    x: '24.718423',
    y: '41.758044',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '4813',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОГЬОВЦИ',
    nameEn: 'BOGYOVTSI',
    municipality: 'КОСТИНБРОД',
    municipalityEn: 'KOSTINBROD',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2235',
    addressNomenclature: '0',
    x: '23.1365',
    y: '42.877697',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '4827',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОДЕНЕЦ',
    nameEn: 'BODENETS',
    municipality: 'МЕЗДРА',
    municipalityEn: 'MEZDRA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3128',
    addressNomenclature: '0',
    x: '23.699478',
    y: '43.15944',
    servingDays: '1010100',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '4844',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОДРОВО',
    nameEn: 'BODROVO',
    municipality: 'ДИМИТРОВГРАД',
    municipalityEn: 'DIMITROVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6446',
    addressNomenclature: '0',
    x: '25.318047',
    y: '42.026601',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '4861',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЕВО',
    nameEn: 'BOEVO',
    municipality: 'РУДОЗЕМ',
    municipalityEn: 'RUDOZEM',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4964',
    addressNomenclature: '0',
    x: '24.841727',
    y: '41.518686',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '4892',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЕРИЦА',
    nameEn: 'BOERITSA',
    municipality: 'ИХТИМАН',
    municipalityEn: 'IHTIMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2066',
    addressNomenclature: '0',
    x: '23.719386',
    y: '42.455632',
    servingDays: '0101000',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '4902',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЖАК',
    nameEn: 'BOZHAK',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6744',
    addressNomenclature: '0',
    x: '25.2853',
    y: '41.701699',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '4916',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЖАН',
    nameEn: 'BOZHAN',
    municipality: 'ТЕРВЕЛ',
    municipalityEn: 'TERVEL',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9475',
    addressNomenclature: '0',
    x: '27.4568',
    y: '43.759855',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '4927',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЖАНОВО',
    nameEn: 'BOZHANOVO',
    municipality: 'ШАБЛА',
    municipalityEn: 'SHABLA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9677',
    addressNomenclature: '0',
    x: '28.49063',
    y: '43.62231',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '4933',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЖДОВО',
    nameEn: 'BOZHDOVO',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2811',
    addressNomenclature: '0',
    x: '23.360336',
    y: '41.601583',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '4947',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЖЕВЦИ',
    nameEn: 'BOZHEVTSI',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8844',
    addressNomenclature: '0',
    x: '26.13369',
    y: '42.865844',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '4950',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЖЕНИЦА',
    nameEn: 'BOZHENITSA',
    municipality: 'БОТЕВГРАД',
    municipalityEn: 'BOTEVGRAD',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2157',
    addressNomenclature: '0',
    x: '23.815494',
    y: '43.010689',
    servingDays: '0101000',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '4964',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЖЕНЦИ',
    nameEn: 'BOZHENTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5349',
    addressNomenclature: '0',
    x: '25.423725',
    y: '42.872975',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '4981',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЖИЧЕН',
    nameEn: 'BOZHICHEN',
    municipality: 'ИВАНОВО',
    municipalityEn: 'IVANOVO',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7089',
    addressNomenclature: '0',
    x: '25.956397',
    y: '43.718218',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '5009',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЖУРЕЦ',
    nameEn: 'BOZHURETS',
    municipality: 'КАВАРНА',
    municipalityEn: 'KAVARNA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9656',
    addressNomenclature: '0',
    x: '28.292239',
    y: '43.424466',
    servingDays: '1111100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '5013',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЖУРИЦА',
    nameEn: 'BOZHURITSA',
    municipality: 'ДОЛНА МИТРОПОЛИЯ',
    municipalityEn: 'DOLNA MITROPOLIA',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5848',
    addressNomenclature: '0',
    x: '24.596868',
    y: '43.510381',
    servingDays: '1010100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '5027',
    countryId: '100',
    mainSiteId: '68134',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'БОЖУРИЩЕ',
    nameEn: 'BOZHURISHTE',
    municipality: 'БОЖУРИЩЕ',
    municipalityEn: 'BOZHURISHTE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2227',
    addressNomenclature: '2',
    x: '23.198412',
    y: '42.763043',
    servingDays: '1111110',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '5030',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЖУРКА',
    nameEn: 'BOZHURKA',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7765',
    addressNomenclature: '0',
    x: '26.447364',
    y: '43.183197',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '5044',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЖУРЛУК',
    nameEn: 'BOZHURLUK',
    municipality: 'ЛЕВСКИ',
    municipalityEn: 'LEVSKI',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5926',
    addressNomenclature: '0',
    x: '25.182214',
    y: '43.535915',
    servingDays: '1010100',
    servingOfficeId: '108',
    servingHubOfficeId: '11',
  },
  {
    id: '5058',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЖУРОВО',
    nameEn: 'BOZHUROVO',
    municipality: 'КУБРАТ',
    municipalityEn: 'KUBRAT',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7335',
    addressNomenclature: '0',
    x: '26.435235',
    y: '43.883275',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '5061',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЖУРОВО',
    nameEn: 'BOZHUROVO',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9368',
    addressNomenclature: '0',
    x: '27.763046',
    y: '43.703963',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '5075',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЖУРОВО',
    nameEn: 'BOZHUROVO',
    municipality: 'ВЪРБИЦА',
    municipalityEn: 'VARBITSA',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9874',
    addressNomenclature: '0',
    x: '26.607599',
    y: '42.994953',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '5089',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЖУРЦИ',
    nameEn: 'BOZHURTSI',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6704',
    addressNomenclature: '0',
    x: '25.333562',
    y: '41.747029',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '5092',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЗАДЖИИ',
    nameEn: 'BOZADZHII',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8872',
    addressNomenclature: '0',
    x: '26.329308',
    y: '42.479001',
    servingDays: '1010100',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '5102',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЗВЕЛИЙСКО',
    nameEn: 'BOZVELIYSKO',
    municipality: 'ПРОВАДИЯ',
    municipalityEn: 'PROVADIA',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9273',
    addressNomenclature: '0',
    x: '27.475515',
    y: '43.102203',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '17960',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЗДУГАНОВО',
    nameEn: 'BOZDUGANOVO',
    municipality: 'РАДНЕВО',
    municipalityEn: 'RADNEVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6269',
    addressNomenclature: '0',
    x: '25.763109',
    y: '42.340701',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '7853',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЗЬОВА',
    nameEn: 'BOZYOVA',
    municipality: 'ВЕЛИНГРАД',
    municipalityEn: 'VELINGRAD',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4654',
    addressNomenclature: '0',
    x: '23.760883',
    y: '41.935289',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '5124',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОИЛ',
    nameEn: 'BOIL',
    municipality: 'ДУЛОВО',
    municipalityEn: 'DULOVO',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7683',
    addressNomenclature: '0',
    x: '26.954561',
    y: '43.806766',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '5147',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЙКОВЕЦ',
    nameEn: 'BOYKOVETS',
    municipality: 'ЕТРОПОЛЕ',
    municipalityEn: 'ETROPOLE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2181',
    addressNomenclature: '0',
    x: '23.926483',
    y: '42.827829',
    servingDays: '0101000',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '5150',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЙКОВО',
    nameEn: 'BOYKOVO',
    municipality: 'РОДОПИ',
    municipalityEn: 'RODOPI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4103',
    addressNomenclature: '0',
    x: '24.620977',
    y: '42.001942',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '5164',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЙКОВЦИ',
    nameEn: 'BOYKOVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5080',
    addressNomenclature: '0',
    x: '26.095053',
    y: '42.899298',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '5181',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЙНИК',
    nameEn: 'BOYNIK',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6958',
    addressNomenclature: '0',
    x: '25.591031',
    y: '41.60411',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '5195',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЙНИЦА',
    nameEn: 'BOYNITSA',
    municipality: 'БОЙНИЦА',
    municipalityEn: 'BOYNITSA',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3840',
    addressNomenclature: '2',
    x: '22.532411',
    y: '43.956979',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '5205',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЙНО',
    nameEn: 'BOYNO',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6730',
    addressNomenclature: '0',
    x: '25.27748',
    y: '41.601602',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '5219',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЙНОВЦИ',
    nameEn: 'BOYNOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5307',
    addressNomenclature: '0',
    x: '25.217511',
    y: '42.854619',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '5222',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЙЧЕВИ КОЛИБИ',
    nameEn: 'BOYCHEVI KOLIBI',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5050',
    addressNomenclature: '0',
    x: '25.590193',
    y: '42.953629',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '7908',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЙЧЕТА',
    nameEn: 'BOYCHETA',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5309',
    addressNomenclature: '0',
    x: '25.359943',
    y: '42.792538',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '5236',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'БОЙЧИНОВЦИ',
    nameEn: 'BOYCHINOVTSI',
    municipality: 'БОЙЧИНОВЦИ',
    municipalityEn: 'BOYCHINOVTSI',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3430',
    addressNomenclature: '2',
    x: '23.33378',
    y: '43.472346',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '5240',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЙЧОВЦИ',
    nameEn: 'BOYCHOVTSI',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5038',
    addressNomenclature: '0',
    x: '25.640569',
    y: '42.860972',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '5253',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОКИЛОВЦИ',
    nameEn: 'BOKILOVTSI',
    municipality: 'БЕРКОВИЦА',
    municipalityEn: 'BERKOVITSA',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3517',
    addressNomenclature: '0',
    x: '23.200601',
    y: '43.275287',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '5267',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЛТАТА',
    nameEn: 'BOLTATA',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5340',
    addressNomenclature: '0',
    x: '25.397592',
    y: '42.900222',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '5270',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЛЯРИНО',
    nameEn: 'BOLYARINO',
    municipality: 'РАКОВСКИ',
    municipalityEn: 'RAKOVSKI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4132',
    addressNomenclature: '0',
    x: '25.048775',
    y: '42.237075',
    servingDays: '0101000',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '5284',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'БОЛЯРОВО',
    nameEn: 'BOLYAROVO',
    municipality: 'БОЛЯРОВО',
    municipalityEn: 'BOLYAROVO',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8720',
    addressNomenclature: '2',
    x: '26.807891',
    y: '42.149268',
    servingDays: '1010100',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '5298',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЛЯРСКИ ИЗВОР',
    nameEn: 'BOLYARSKI IZVOR',
    municipality: 'ХАРМАНЛИ',
    municipalityEn: 'HARMANLI',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6474',
    addressNomenclature: '0',
    x: '25.818287',
    y: '41.802537',
    servingDays: '0101000',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '5308',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЛЯРСКО',
    nameEn: 'BOLYARSKO',
    municipality: 'ТУНДЖА',
    municipalityEn: 'TUNDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8631',
    addressNomenclature: '0',
    x: '26.35404',
    y: '42.431196',
    servingDays: '1010100',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '5311',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЛЯРЦИ',
    nameEn: 'BOLYARTSI',
    municipality: 'АВРЕН',
    municipalityEn: 'AVREN',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9124',
    addressNomenclature: '0',
    x: '27.797605',
    y: '43.069858',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '5325',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЛЯРЦИ',
    nameEn: 'BOLYARTSI',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6681',
    addressNomenclature: '0',
    x: '25.454407',
    y: '41.704613',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '5339',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЛЯРЦИ',
    nameEn: 'BOLYARTSI',
    municipality: 'САДОВО',
    municipalityEn: 'SADOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4114',
    addressNomenclature: '0',
    x: '24.955674',
    y: '42.067452',
    servingDays: '1010100',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '5342',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОНЕВО',
    nameEn: 'BONEVO',
    municipality: 'ТЕРВЕЛ',
    municipalityEn: 'TERVEL',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9472',
    addressNomenclature: '0',
    x: '27.379873',
    y: '43.729644',
    servingDays: '0101010',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '7870',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОР',
    nameEn: 'BOR',
    municipality: 'АСЕНОВГРАД',
    municipalityEn: 'ASENOVGRAD',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4233',
    addressNomenclature: '0',
    x: '25.04794',
    y: '41.835751',
    servingDays: '0101000',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '5356',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОРЕЦ',
    nameEn: 'BORETS',
    municipality: 'БРЕЗОВО',
    municipalityEn: 'BREZOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4144',
    addressNomenclature: '0',
    x: '24.93401',
    y: '42.346435',
    servingDays: '0101000',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '5365',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОРИЕ',
    nameEn: 'BORIE',
    municipality: 'РУДОЗЕМ',
    municipalityEn: 'RUDOZEM',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4961',
    addressNomenclature: '0',
    x: '24.873456',
    y: '41.499083',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '5387',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОРИКА',
    nameEn: 'BORIKA',
    municipality: 'МАДАН',
    municipalityEn: 'MADAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4926',
    addressNomenclature: '0',
    x: '24.923196',
    y: '41.566581',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '5390',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОРИКА',
    nameEn: 'BORIKA',
    municipality: 'ИХТИМАН',
    municipalityEn: 'IHTIMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2064',
    addressNomenclature: '0',
    x: '23.662226',
    y: '42.487811',
    servingDays: '0101000',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '5400',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОРИКИ',
    nameEn: 'BORIKI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5335',
    addressNomenclature: '0',
    x: '25.362358',
    y: '42.875072',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '5414',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОРИКОВО',
    nameEn: 'BORIKOVO',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4762',
    addressNomenclature: '0',
    x: '24.613081',
    y: '41.493177',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '5428',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОРИЛОВЕЦ',
    nameEn: 'BORILOVETS',
    municipality: 'БОЙНИЦА',
    municipalityEn: 'BOYNITSA',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3838',
    addressNomenclature: '0',
    x: '22.497198',
    y: '43.998545',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '5431',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОРИЛОВО',
    nameEn: 'BORILOVO',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6046',
    addressNomenclature: '0',
    x: '25.552363',
    y: '42.482722',
    servingDays: '1111110',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '5445',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОРИМА',
    nameEn: 'BORIMA',
    municipality: 'ТРОЯН',
    municipalityEn: 'TROYAN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5672',
    addressNomenclature: '0',
    x: '24.578425',
    y: '42.973725',
    servingDays: '0101000',
    servingOfficeId: '44',
    servingHubOfficeId: '11',
  },
  {
    id: '5459',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОРИМЕЧКОВО',
    nameEn: 'BORIMECHKOVO',
    municipality: 'ЛЕСИЧОВО',
    municipalityEn: 'LESICHOVO',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4464',
    addressNomenclature: '0',
    x: '24.124406',
    y: '42.401587',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '5462',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОРИНО',
    nameEn: 'BORINO',
    municipality: 'БОРИНО',
    municipalityEn: 'BORINO',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4824',
    addressNomenclature: '2',
    x: '24.292536',
    y: '41.683555',
    servingDays: '1111100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '5476',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОРИНОВО',
    nameEn: 'BORINOVO',
    municipality: 'МАДАН',
    municipalityEn: 'MADAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4905',
    addressNomenclature: '0',
    x: '24.948576',
    y: '41.475557',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '5480',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОРИНЦИ',
    nameEn: 'BORINTSI',
    municipality: 'КОТЕЛ',
    municipalityEn: 'KOTEL',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8999',
    addressNomenclature: '0',
    x: '26.212441',
    y: '42.934734',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '5493',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОРИСЛАВ',
    nameEn: 'BORISLAV',
    municipality: 'ПОРДИМ',
    municipalityEn: 'PORDIM',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5889',
    addressNomenclature: '0',
    x: '24.965177',
    y: '43.320061',
    servingDays: '0101000',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '5503',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОРИСЛАВЦИ',
    nameEn: 'BORISLAVTSI',
    municipality: 'МАДЖАРОВО',
    municipalityEn: 'MADZHAROVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6478',
    addressNomenclature: '0',
    x: '25.925144',
    y: '41.659949',
    servingDays: '0101000',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '5517',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОРИСОВО',
    nameEn: 'BORISOVO',
    municipality: 'СЛИВО ПОЛЕ',
    municipalityEn: 'SLIVO POLE',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7064',
    addressNomenclature: '0',
    x: '26.231189',
    y: '43.933831',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '5520',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОРИСОВО',
    nameEn: 'BORISOVO',
    municipality: 'ЕЛХОВО',
    municipalityEn: 'ELHOVO',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8731',
    addressNomenclature: '0',
    x: '26.729619',
    y: '42.292139',
    servingDays: '0101000',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '5534',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОРНАРЕВО',
    nameEn: 'BORNAREVO',
    municipality: 'РАДОМИР',
    municipalityEn: 'RADOMIR',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2407',
    addressNomenclature: '0',
    x: '22.932254',
    y: '42.536017',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '5551',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОРОВ ДОЛ',
    nameEn: 'BOROV DOL',
    municipality: 'ТВЪРДИЦА',
    municipalityEn: 'TVARDITSA',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8898',
    addressNomenclature: '0',
    x: '26.082567',
    y: '42.732488',
    servingDays: '0101000',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '5548',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОРОВАН',
    nameEn: 'BOROVAN',
    municipality: 'БОРОВАН',
    municipalityEn: 'BOROVAN',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3240',
    addressNomenclature: '0',
    x: '23.754278',
    y: '43.427409',
    servingDays: '1111100',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '5565',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОРОВЕЦ',
    nameEn: 'BOROVETS',
    municipality: 'КОЧЕРИНОВО',
    municipalityEn: 'KOCHERINOVO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2626',
    addressNomenclature: '0',
    x: '23.021436',
    y: '42.105966',
    servingDays: '1111100',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '98226',
    countryId: '100',
    mainSiteId: '0',
    type: 'к.к.',
    typeEn: 'k.k.',
    name: 'БОРОВЕЦ',
    nameEn: 'BOROVETS',
    municipality: 'САМОКОВ',
    municipalityEn: 'SAMOKOV',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2010',
    addressNomenclature: '0',
    x: '23.605417',
    y: '42.270675',
    servingDays: '1111100',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '5579',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОРОВИНА',
    nameEn: 'BOROVINA',
    municipality: 'МАДАН',
    municipalityEn: 'MADAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4923',
    addressNomenclature: '0',
    x: '24.924654',
    y: '41.58513',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '5582',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОРОВИЦА',
    nameEn: 'BOROVITSA',
    municipality: 'БЕЛОГРАДЧИК',
    municipalityEn: 'BELOGRADCHIK',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3955',
    addressNomenclature: '0',
    x: '22.733745',
    y: '43.578325',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '80176',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОРОВИЦА',
    nameEn: 'BOROVITSA',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6790',
    addressNomenclature: '0',
    x: '25.220922',
    y: '41.657973',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '5596',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОРОВИЧЕНЕ',
    nameEn: 'BOROVICHENE',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2884',
    addressNomenclature: '0',
    x: '23.010526',
    y: '41.419744',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '5606',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОРОВО',
    nameEn: 'BOROVO',
    municipality: 'ГОЦЕ ДЕЛЧЕВ',
    municipalityEn: 'GOTSE DELCHEV',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2904',
    addressNomenclature: '0',
    x: '23.734527',
    y: '41.592896',
    servingDays: '1111100',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '5611',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'БОРОВО',
    nameEn: 'BOROVO',
    municipality: 'БОРОВО',
    municipalityEn: 'BOROVO',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7174',
    addressNomenclature: '0',
    x: '25.809047',
    y: '43.485592',
    servingDays: '1111100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '5623',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОРОВО',
    nameEn: 'BOROVO',
    municipality: 'ЛЪКИ',
    municipalityEn: 'LAKI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4243',
    addressNomenclature: '0',
    x: '24.873677',
    y: '41.846524',
    servingDays: '0101000',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '5637',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОРОВО',
    nameEn: 'BOROVO',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6070',
    addressNomenclature: '0',
    x: '25.573787',
    y: '42.252843',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '5640',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОРОВСКО',
    nameEn: 'BOROVSKO',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6698',
    addressNomenclature: '0',
    x: '25.232675',
    y: '41.733377',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '5654',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОРОВЦИ',
    nameEn: 'BOROVTSI',
    municipality: 'БЕРКОВИЦА',
    municipalityEn: 'BERKOVITSA',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3528',
    addressNomenclature: '0',
    x: '23.176114',
    y: '43.322451',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '5668',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОРСКОТО',
    nameEn: 'BORSKOTO',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5333',
    addressNomenclature: '0',
    x: '25.168689',
    y: '42.897072',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '5671',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОРУЩИЦА',
    nameEn: 'BORUSHTITSA',
    municipality: 'МЪГЛИЖ',
    municipalityEn: 'MAGLIZH',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6187',
    addressNomenclature: '0',
    x: '25.579457',
    y: '42.725853',
    servingDays: '0101000',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '5685',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОРЦИ',
    nameEn: 'BORTSI',
    municipality: 'ВЕНЕЦ',
    municipalityEn: 'VENETS',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9752',
    addressNomenclature: '0',
    x: '26.887534',
    y: '43.541689',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '5699',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОРЯНА',
    nameEn: 'BORYANA',
    municipality: 'ДЪЛГОПОЛ',
    municipalityEn: 'DALGOPOL',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9255',
    addressNomenclature: '0',
    x: '27.278023',
    y: '43.052868',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '5709',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОСЕВЦИ',
    nameEn: 'BOSEVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5084',
    addressNomenclature: '0',
    x: '25.958499',
    y: '42.973335',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '5712',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОСИЛИЦА',
    nameEn: 'BOSILITSA',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6697',
    addressNomenclature: '0',
    x: '25.231906',
    y: '41.749134',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '5726',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОСИЛКОВО',
    nameEn: 'BOSILKOVO',
    municipality: 'СУНГУРЛАРЕ',
    municipalityEn: 'SUNGURLARE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8446',
    addressNomenclature: '0',
    x: '27.005437',
    y: '42.82131',
    servingDays: '0101000',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '5737',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОСИЛКОВО',
    nameEn: 'BOSILKOVO',
    municipality: 'БАНИТЕ',
    municipalityEn: 'BANITE',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4945',
    addressNomenclature: '0',
    x: '24.952315',
    y: '41.699065',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '5743',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОСИЛКОВЦИ',
    nameEn: 'BOSILKOVTSI',
    municipality: 'БЯЛА',
    municipalityEn: 'BYALA',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7128',
    addressNomenclature: '0',
    x: '25.588791',
    y: '43.476223',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '5757',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОСНА',
    nameEn: 'BOSNA',
    municipality: 'СИТОВО',
    municipalityEn: 'SITOVO',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7533',
    addressNomenclature: '0',
    x: '26.969441',
    y: '43.962855',
    servingDays: '1010100',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '5760',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОСНЕК',
    nameEn: 'BOSNEK',
    municipality: 'ПЕРНИК',
    municipalityEn: 'PERNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2345',
    addressNomenclature: '0',
    x: '23.179222',
    y: '42.493696',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '5788',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОСТАНИТЕ',
    nameEn: 'BOSTANITE',
    municipality: 'ЧУПРЕНЕ',
    municipalityEn: 'CHUPRENE',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '',
    addressNomenclature: '0',
    x: '22.683781',
    y: '43.566831',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '5791',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОСТАНЦИ',
    nameEn: 'BOSTANTSI',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6716',
    addressNomenclature: '0',
    x: '25.378213',
    y: '41.759267',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '5801',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОСТИНА',
    nameEn: 'BOSTINA',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4745',
    addressNomenclature: '0',
    x: '24.759',
    y: '41.608398',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '5815',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'БОТЕВГРАД',
    nameEn: 'BOTEVGRAD',
    municipality: 'БОТЕВГРАД',
    municipalityEn: 'BOTEVGRAD',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2140',
    addressNomenclature: '2',
    x: '23.791004',
    y: '42.907581',
    servingDays: '1111110',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '5829',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОТЕВО',
    nameEn: 'BOTEVO',
    municipality: 'АКСАКОВО',
    municipalityEn: 'AKSAKOVO',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9159',
    addressNomenclature: '0',
    x: '27.720355',
    y: '43.429343',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '5832',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОТЕВО',
    nameEn: 'BOTEVO',
    municipality: 'ВИДИН',
    municipalityEn: 'VIDIN',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3746',
    addressNomenclature: '0',
    x: '22.860292',
    y: '43.83824',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '5846',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОТЕВО',
    nameEn: 'BOTEVO',
    municipality: 'ХАЙРЕДИН',
    municipalityEn: 'HAYREDIN',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3353',
    addressNomenclature: '0',
    x: '23.746295',
    y: '43.580568',
    servingDays: '1010100',
    servingOfficeId: '64',
    servingHubOfficeId: '20',
  },
  {
    id: '5852',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОТЕВО',
    nameEn: 'BOTEVO',
    municipality: 'ВЪЛЧЕДРЪМ',
    municipalityEn: 'VALCHEDRAM',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3634',
    addressNomenclature: '0',
    x: '23.462154',
    y: '43.747809',
    servingDays: '0101000',
    servingOfficeId: '831',
    servingHubOfficeId: '20',
  },
  {
    id: '5863',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОТЕВО',
    nameEn: 'BOTEVO',
    municipality: 'ТУНДЖА',
    municipalityEn: 'TUNDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8638',
    addressNomenclature: '0',
    x: '26.360285',
    y: '42.355496',
    servingDays: '0101000',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '5877',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОТРОВ',
    nameEn: 'BOTROV',
    municipality: 'БЯЛА',
    municipalityEn: 'BYALA',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7125',
    addressNomenclature: '0',
    x: '25.712759',
    y: '43.513368',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '5894',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОТУНЯ',
    nameEn: 'BOTUNYA',
    municipality: 'КРИВОДОЛ',
    municipalityEn: 'KRIVODOL',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3046',
    addressNomenclature: '0',
    x: '23.359924',
    y: '43.287832',
    servingDays: '0101000',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '5904',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОТУРЧЕ',
    nameEn: 'BOTURCHE',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6589',
    addressNomenclature: '0',
    x: '25.941369',
    y: '41.378936',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '5918',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОХОВА',
    nameEn: 'BOHOVA',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2483',
    addressNomenclature: '0',
    x: '22.497559',
    y: '42.764267',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '5921',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОХОТ',
    nameEn: 'BOHOT',
    municipality: 'ПЛЕВЕН',
    municipalityEn: 'PLEVEN',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5891',
    addressNomenclature: '0',
    x: '24.691992',
    y: '43.321259',
    servingDays: '0101000',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '5935',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЧКОВЦИ',
    nameEn: 'BOCHKOVTSI',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5035',
    addressNomenclature: '0',
    x: '25.673296',
    y: '42.834688',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '5949',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОШУЛЯ',
    nameEn: 'BOSHULYA',
    municipality: 'СЕПТЕМВРИ',
    municipalityEn: 'SEPTEMVRI',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4481',
    addressNomenclature: '0',
    x: '24.189334',
    y: '42.244962',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '5952',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЯДЖИК',
    nameEn: 'BOYADZHIK',
    municipality: 'ТУНДЖА',
    municipalityEn: 'TUNDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8640',
    addressNomenclature: '0',
    x: '26.302278',
    y: '42.397722',
    servingDays: '1010100',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '5966',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЯН',
    nameEn: 'BOYAN',
    municipality: 'ВЕНЕЦ',
    municipalityEn: 'VENETS',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9771',
    addressNomenclature: '0',
    x: '26.839671',
    y: '43.482019',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '5983',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЯН БОТЕВО',
    nameEn: 'BOYAN BOTEVO',
    municipality: 'МИНЕРАЛНИ БАНИ',
    municipalityEn: 'MINERALNI BANI',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6374',
    addressNomenclature: '0',
    x: '25.302124',
    y: '41.835384',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '5978',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЯНА',
    nameEn: 'BOYANA',
    municipality: 'ВЪЛЧИ ДОЛ',
    municipalityEn: 'VALCHI DOL',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9147',
    addressNomenclature: '0',
    x: '27.648718',
    y: '43.435321',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '5997',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЯНОВО',
    nameEn: 'BOYANOVO',
    municipality: 'ГРАМАДА',
    municipalityEn: 'GRAMADA',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3832',
    addressNomenclature: '0',
    x: '22.689452',
    y: '43.875187',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '6001',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЯНОВО',
    nameEn: 'BOYANOVO',
    municipality: 'ЕЛХОВО',
    municipalityEn: 'ELHOVO',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8730',
    addressNomenclature: '0',
    x: '26.630296',
    y: '42.255804',
    servingDays: '0101000',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '6015',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЯНОВЦИ',
    nameEn: 'BOYANOVTSI',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5041',
    addressNomenclature: '0',
    x: '25.646041',
    y: '42.928596',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '6029',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БОЯНЦИ',
    nameEn: 'BOYANTSI',
    municipality: 'АСЕНОВГРАД',
    municipalityEn: 'ASENOVGRAD',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4246',
    addressNomenclature: '0',
    x: '24.945483',
    y: '42.039453',
    servingDays: '1010100',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '6032',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРАДВАРИ',
    nameEn: 'BRADVARI',
    municipality: 'СИЛИСТРА',
    municipalityEn: 'SILISTRA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7568',
    addressNomenclature: '0',
    x: '27.125486',
    y: '43.927618',
    servingDays: '1010100',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '6046',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРАКНИЦА',
    nameEn: 'BRAKNITSA',
    municipality: 'ПОПОВО',
    municipalityEn: 'POPOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7855',
    addressNomenclature: '0',
    x: '26.120528',
    y: '43.236358',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '6050',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРАКЬОВЦИ',
    nameEn: 'BRAKYOVTSI',
    municipality: 'ГОДЕЧ',
    municipalityEn: 'GODECH',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2251',
    addressNomenclature: '0',
    x: '23.158589',
    y: '43.06034',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '6077',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРАНИПОЛЕ',
    nameEn: 'BRANIPOLE',
    municipality: 'РОДОПИ',
    municipalityEn: 'RODOPI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4109',
    addressNomenclature: '0',
    x: '24.756202',
    y: '42.078056',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '6080',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРАНИЦА',
    nameEn: 'BRANITSA',
    municipality: 'ХАРМАНЛИ',
    municipalityEn: 'HARMANLI',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6463',
    addressNomenclature: '0',
    x: '26.085805',
    y: '42.000765',
    servingDays: '1010100',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '6094',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРАНИЧЕВО',
    nameEn: 'BRANICHEVO',
    municipality: 'КАОЛИНОВО',
    municipalityEn: 'KAOLINOVO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9967',
    addressNomenclature: '0',
    x: '27.090868',
    y: '43.683245',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '6104',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРАНИЩЕ',
    nameEn: 'BRANISHTE',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9361',
    addressNomenclature: '0',
    x: '27.845128',
    y: '43.529433',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '6118',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРАНКОВЦИ',
    nameEn: 'BRANKOVTSI',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5041',
    addressNomenclature: '0',
    x: '25.652615',
    y: '42.942427',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '6121',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРАНКОВЦИ',
    nameEn: 'BRANKOVTSI',
    municipality: 'ГРАМАДА',
    municipalityEn: 'GRAMADA',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3824',
    addressNomenclature: '0',
    x: '22.568886',
    y: '43.827831',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '6135',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРАТАН',
    nameEn: 'BRATAN',
    municipality: 'КОТЕЛ',
    municipalityEn: 'KOTEL',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8983',
    addressNomenclature: '0',
    x: '26.341979',
    y: '42.985914',
    servingDays: '1010100',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '6149',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРАТАНИЦА',
    nameEn: 'BRATANITSA',
    municipality: 'ПАЗАРДЖИК',
    municipalityEn: 'PAZARDZHIK',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4412',
    addressNomenclature: '0',
    x: '24.220138',
    y: '42.165971',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '6152',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРАТОВО',
    nameEn: 'BRATOVO',
    municipality: 'БУРГАС',
    municipalityEn: 'BURGAS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8111',
    addressNomenclature: '0',
    x: '27.304121',
    y: '42.504187',
    servingDays: '1111110',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '6166',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРАТОВО',
    nameEn: 'BRATOVO',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7764',
    addressNomenclature: '0',
    x: '26.411557',
    y: '43.179696',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '6175',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРАТУШКОВО',
    nameEn: 'BRATUSHKOVO',
    municipality: 'СЛИВНИЦА',
    municipalityEn: 'SLIVNITSA',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2222',
    addressNomenclature: '0',
    x: '22.976584',
    y: '42.829588',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '6183',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРАТЯ ДАСКАЛОВИ',
    nameEn: 'BRATYA DASKALOVI',
    municipality: 'БРАТЯ ДАСКАЛОВИ',
    municipalityEn: 'BRATYA DASKALOVI',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6250',
    addressNomenclature: '2',
    x: '25.209117',
    y: '42.29718',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '6197',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРАТЯ КУНЧЕВИ',
    nameEn: 'BRATYA KUNCHEVI',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6057',
    addressNomenclature: '0',
    x: '25.851849',
    y: '42.499625',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '6207',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'БРАЦИГОВО',
    nameEn: 'BRATSIGOVO',
    municipality: 'БРАЦИГОВО',
    municipalityEn: 'BRATSIGOVO',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4579',
    addressNomenclature: '2',
    x: '24.369533',
    y: '42.023229',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '6210',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЕГАРЕ',
    nameEn: 'BREGARE',
    municipality: 'ДОЛНА МИТРОПОЛИЯ',
    municipalityEn: 'DOLNA MITROPOLIA',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5862',
    addressNomenclature: '0',
    x: '24.330363',
    y: '43.595115',
    servingDays: '1010100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '6224',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'БРЕГОВО',
    nameEn: 'BREGOVO',
    municipality: 'БРЕГОВО',
    municipalityEn: 'BREGOVO',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3790',
    addressNomenclature: '2',
    x: '22.638816',
    y: '44.152485',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '7942',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЕГОВО',
    nameEn: 'BREGOVO',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6878',
    addressNomenclature: '0',
    x: '25.349745',
    y: '41.429421',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '7939',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЕЖАНА',
    nameEn: 'BREZHANA',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6850',
    addressNomenclature: '0',
    x: '25.216317',
    y: '41.489742',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '6238',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЕЖАНИ',
    nameEn: 'BREZHANI',
    municipality: 'СИМИТЛИ',
    municipalityEn: 'SIMITLI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2750',
    addressNomenclature: '0',
    x: '23.185518',
    y: '41.863617',
    servingDays: '1111100',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '6241',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЕЖНИЦИТЕ',
    nameEn: 'BREZHNITSITE',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5360',
    addressNomenclature: '0',
    x: '25.514491',
    y: '42.803511',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '6255',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЕЗА',
    nameEn: 'BREZA',
    municipality: 'РУДОЗЕМ',
    municipalityEn: 'RUDOZEM',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4959',
    addressNomenclature: '0',
    x: '24.87099',
    y: '41.450314',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '6269',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЕЗЕ',
    nameEn: 'BREZE',
    municipality: 'ДЕВИН',
    municipalityEn: 'DEVIN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4849',
    addressNomenclature: '0',
    x: '24.513542',
    y: '41.723335',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '6272',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЕЗЕ',
    nameEn: 'BREZE',
    municipality: 'СВОГЕ',
    municipalityEn: 'SVOGE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2278',
    addressNomenclature: '0',
    x: '23.215242',
    y: '43.019533',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '77116',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЕЗЕН',
    nameEn: 'BREZEN',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6792',
    addressNomenclature: '0',
    x: '25.22519',
    y: '41.626817',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '6286',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'БРЕЗНИК',
    nameEn: 'BREZNIK',
    municipality: 'БРЕЗНИК',
    municipalityEn: 'BREZNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2360',
    addressNomenclature: '0',
    x: '22.904879',
    y: '42.738853',
    servingDays: '1111100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '6306',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЕЗНИЦА',
    nameEn: 'BREZNITSA',
    municipality: 'ГОЦЕ ДЕЛЧЕВ',
    municipalityEn: 'GOTSE DELCHEV',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2972',
    addressNomenclature: '0',
    x: '23.663459',
    y: '41.6723',
    servingDays: '1111100',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '6313',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЕЗНИШКИ ИЗВОР',
    nameEn: 'BREZNISHKI IZVOR',
    municipality: 'БРЕЗНИК',
    municipalityEn: 'BREZNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2385',
    addressNomenclature: '0',
    x: '22.808498',
    y: '42.727438',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '6327',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЕЗОВДОЛ',
    nameEn: 'BREZOVDOL',
    municipality: 'СВОГЕ',
    municipalityEn: 'SVOGE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2292',
    addressNomenclature: '0',
    x: '23.489638',
    y: '42.964667',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '6344',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЕЗОВО',
    nameEn: 'BREZOVO',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5083',
    addressNomenclature: '0',
    x: '26.012918',
    y: '42.92789',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '6358',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЕЗОВО',
    nameEn: 'BREZOVO',
    municipality: 'ТЕТЕВЕН',
    municipalityEn: 'TETEVEN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '',
    addressNomenclature: '0',
    x: '24.313932',
    y: '42.86011',
    servingDays: '1010100',
    servingOfficeId: '88',
    servingHubOfficeId: '2',
  },
  {
    id: '6361',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'БРЕЗОВО',
    nameEn: 'BREZOVO',
    municipality: 'БРЕЗОВО',
    municipalityEn: 'BREZOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4160',
    addressNomenclature: '2',
    x: '25.076772',
    y: '42.348771',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '6375',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЕНИЦА',
    nameEn: 'BRENITSA',
    municipality: 'КНЕЖА',
    municipalityEn: 'KNEZHA',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5834',
    addressNomenclature: '0',
    x: '24.132448',
    y: '43.431805',
    servingDays: '1010100',
    servingOfficeId: '59',
    servingHubOfficeId: '20',
  },
  {
    id: '6389',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЕНИЦА',
    nameEn: 'BRENITSA',
    municipality: 'ТУТРАКАН',
    municipalityEn: 'TUTRAKAN',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7649',
    addressNomenclature: '0',
    x: '26.615735',
    y: '43.890992',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '6392',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЕСТ',
    nameEn: 'BREST',
    municipality: 'ТРЕКЛЯНО',
    municipalityEn: 'TREKLYANO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2558',
    addressNomenclature: '0',
    x: '22.609372',
    y: '42.461165',
    servingDays: '0101000',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '6402',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЕСТ',
    nameEn: 'BREST',
    municipality: 'ГУЛЯНЦИ',
    municipalityEn: 'GULYANTSI',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5971',
    addressNomenclature: '0',
    x: '24.591399',
    y: '43.642477',
    servingDays: '1111100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '6416',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЕСТАК',
    nameEn: 'BRESTAK',
    municipality: 'ВЪЛЧИ ДОЛ',
    municipalityEn: 'VALCHI DOL',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9290',
    addressNomenclature: '0',
    x: '27.539477',
    y: '43.459185',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '6421',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЕСТАКА',
    nameEn: 'BRESTAKA',
    municipality: 'МИРКОВО',
    municipalityEn: 'MIRKOVO',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2072',
    addressNomenclature: '0',
    x: '23.919528',
    y: '42.605816',
    servingDays: '0101000',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '6433',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЕСТЕ',
    nameEn: 'BRESTE',
    municipality: 'ЧЕРВЕН БРЯГ',
    municipalityEn: 'CHERVEN BRYAG',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5992',
    addressNomenclature: '0',
    x: '24.004266',
    y: '43.259589',
    servingDays: '1010100',
    servingOfficeId: '59',
    servingHubOfficeId: '20',
  },
  {
    id: '6447',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЕСТНИК',
    nameEn: 'BRESTNIK',
    municipality: 'РОДОПИ',
    municipalityEn: 'RODOPI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4107',
    addressNomenclature: '0',
    x: '24.766788',
    y: '42.053375',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '6450',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЕСТНИЦА',
    nameEn: 'BRESTNITSA',
    municipality: 'ЯБЛАНИЦА',
    municipalityEn: 'YABLANITSA',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5761',
    addressNomenclature: '0',
    x: '24.203289',
    y: '43.066367',
    servingDays: '1010100',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '6464',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЕСТНИЦА',
    nameEn: 'BRESTNITSA',
    municipality: 'ТЕРВЕЛ',
    municipalityEn: 'TERVEL',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9484',
    addressNomenclature: '0',
    x: '27.571096',
    y: '43.881847',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '6478',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЕСТОВА',
    nameEn: 'BRESTOVA',
    municipality: 'ГУРКОВО',
    municipalityEn: 'GURKOVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6194',
    addressNomenclature: '0',
    x: '25.741353',
    y: '42.661377',
    servingDays: '0101000',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '6481',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЕСТОВЕНЕ',
    nameEn: 'BRESTOVENE',
    municipality: 'ЗАВЕТ',
    municipalityEn: 'ZAVET',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7331',
    addressNomenclature: '0',
    x: '26.599225',
    y: '43.759153',
    servingDays: '1111100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '6495',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЕСТОВЕЦ',
    nameEn: 'BRESTOVETS',
    municipality: 'ПЛЕВЕН',
    municipalityEn: 'PLEVEN',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5884',
    addressNomenclature: '0',
    x: '24.609614',
    y: '43.353552',
    servingDays: '0101000',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '6505',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЕСТОВИЦА',
    nameEn: 'BRESTOVITSA',
    municipality: 'РОДОПИ',
    municipalityEn: 'RODOPI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4224',
    addressNomenclature: '0',
    x: '24.594305',
    y: '42.078425',
    servingDays: '1111100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '6519',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЕСТОВИЦА',
    nameEn: 'BRESTOVITSA',
    municipality: 'БОРОВО',
    municipalityEn: 'BOROVO',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7177',
    addressNomenclature: '0',
    x: '25.756125',
    y: '43.534543',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '6522',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЕСТОВО',
    nameEn: 'BRESTOVO',
    municipality: 'СИМИТЛИ',
    municipalityEn: 'SIMITLI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2735',
    addressNomenclature: '0',
    x: '23.000998',
    y: '41.829065',
    servingDays: '1111110',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '6536',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЕСТОВО',
    nameEn: 'BRESTOVO',
    municipality: 'ЛОВЕЧ',
    municipalityEn: 'LOVECH',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5551',
    addressNomenclature: '0',
    x: '24.926751',
    y: '43.137433',
    servingDays: '0101000',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '6547',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРОД',
    nameEn: 'BROD',
    municipality: 'ДИМИТРОВГРАД',
    municipalityEn: 'DIMITROVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6442',
    addressNomenclature: '0',
    x: '25.682796',
    y: '42.046432',
    servingDays: '1111100',
    servingOfficeId: '42',
    servingHubOfficeId: '5',
  },
  {
    id: '6553',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРОДИЛОВО',
    nameEn: 'BRODILOVO',
    municipality: 'ЦАРЕВО',
    municipalityEn: 'TSAREVO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8282',
    addressNomenclature: '0',
    x: '27.857648',
    y: '42.087786',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '6567',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРОШ',
    nameEn: 'BROSH',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6737',
    addressNomenclature: '0',
    x: '25.329759',
    y: '41.644739',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '6570',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'БРУСАРЦИ',
    nameEn: 'BRUSARTSI',
    municipality: 'БРУСАРЦИ',
    municipalityEn: 'BRUSARTSI',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3680',
    addressNomenclature: '2',
    x: '23.06633',
    y: '43.6629',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '6584',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРУСЕВЦИ',
    nameEn: 'BRUSEVTSI',
    municipality: 'МАДЖАРОВО',
    municipalityEn: 'MADZHAROVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6488',
    addressNomenclature: '0',
    x: '25.932062',
    y: '41.583946',
    servingDays: '0101000',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '6598',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРУСЕН',
    nameEn: 'BRUSEN',
    municipality: 'МЕЗДРА',
    municipalityEn: 'MEZDRA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3126',
    addressNomenclature: '0',
    x: '23.749279',
    y: '43.136406',
    servingDays: '0101000',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '6608',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРУСЕН',
    nameEn: 'BRUSEN',
    municipality: 'ЕТРОПОЛЕ',
    municipalityEn: 'ETROPOLE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2185',
    addressNomenclature: '0',
    x: '24.110069',
    y: '42.901457',
    servingDays: '0101000',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '6625',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРУСИНО',
    nameEn: 'BRUSINO',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6595',
    addressNomenclature: '0',
    x: '25.935661',
    y: '41.51123',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '6639',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРУСНИК',
    nameEn: 'BRUSNIK',
    municipality: 'БРЕЗНИК',
    municipalityEn: 'BREZNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2372',
    addressNomenclature: '0',
    x: '22.838514',
    y: '42.835577',
    servingDays: '1111100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '6642',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЪНЕЦИТЕ',
    nameEn: 'BRANETSITE',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5342',
    addressNomenclature: '0',
    x: '25.359729',
    y: '42.902105',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '6662',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЪЧКОВЦИ',
    nameEn: 'BRACHKOVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5081',
    addressNomenclature: '0',
    x: '25.805528',
    y: '42.930067',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '6673',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЪШЛЕН',
    nameEn: 'BRASHLEN',
    municipality: 'СЛИВО ПОЛЕ',
    municipalityEn: 'SLIVO POLE',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7069',
    addressNomenclature: '0',
    x: '26.364826',
    y: '43.996591',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '6687',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЪШЛЯН',
    nameEn: 'BRASHLYAN',
    municipality: 'МАЛКО ТЪРНОВО',
    municipalityEn: 'MALKO TARNOVO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8163',
    addressNomenclature: '0',
    x: '27.428124',
    y: '42.046901',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '6690',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЪШЛЯНИЦА',
    nameEn: 'BRASHLYANITSA',
    municipality: 'ПЛЕВЕН',
    municipalityEn: 'PLEVEN',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5844',
    addressNomenclature: '0',
    x: '24.731372',
    y: '43.546946',
    servingDays: '0101000',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '6700',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЪЩЕН',
    nameEn: 'BRASHTEN',
    municipality: 'ДОСПАТ',
    municipalityEn: 'DOSPAT',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4821',
    addressNomenclature: '0',
    x: '24.091164',
    y: '41.560649',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '6728',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЯГОВЕЦ',
    nameEn: 'BRYAGOVETS',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6929',
    addressNomenclature: '0',
    x: '25.789558',
    y: '41.645891',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '6731',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЯГОВИЦА',
    nameEn: 'BRYAGOVITSA',
    municipality: 'СТРАЖИЦА',
    municipalityEn: 'STRAZHITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5152',
    addressNomenclature: '0',
    x: '25.88612',
    y: '43.192669',
    servingDays: '0101000',
    servingOfficeId: '46',
    servingHubOfficeId: '11',
  },
  {
    id: '6745',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЯГОВО',
    nameEn: 'BRYAGOVO',
    municipality: 'ПЪРВОМАЙ',
    municipalityEn: 'PARVOMAY',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4294',
    addressNomenclature: '0',
    x: '25.173411',
    y: '41.985417',
    servingDays: '0101000',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '6759',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЯГОВО',
    nameEn: 'BRYAGOVO',
    municipality: 'ХАСКОВО',
    municipalityEn: 'HASKOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6355',
    addressNomenclature: '0',
    x: '25.784735',
    y: '41.914943',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '6762',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЯСТ',
    nameEn: 'BRYAST',
    municipality: 'ДИМИТРОВГРАД',
    municipalityEn: 'DIMITROVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6434',
    addressNomenclature: '0',
    x: '25.587028',
    y: '42.106345',
    servingDays: '1010100',
    servingOfficeId: '42',
    servingHubOfficeId: '5',
  },
  {
    id: '6776',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЯСТОВЕЦ',
    nameEn: 'BRYASTOVETS',
    municipality: 'БУРГАС',
    municipalityEn: 'BURGAS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8113',
    addressNomenclature: '0',
    x: '27.463578',
    y: '42.672482',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '6788',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЯСТОВО',
    nameEn: 'BRYASTOVO',
    municipality: 'НОВА ЗАГОРА',
    municipalityEn: 'NOVA ZAGORA',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8935',
    addressNomenclature: '0',
    x: '25.933038',
    y: '42.524153',
    servingDays: '1010100',
    servingOfficeId: '56',
    servingHubOfficeId: '5',
  },
  {
    id: '6793',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЯСТОВО',
    nameEn: 'BRYASTOVO',
    municipality: 'БАЛЧИК',
    municipalityEn: 'BALCHIK',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9647',
    addressNomenclature: '0',
    x: '28.205022',
    y: '43.457184',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '6803',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БРЯСТОВО',
    nameEn: 'BRYASTOVO',
    municipality: 'МИНЕРАЛНИ БАНИ',
    municipalityEn: 'MINERALNI BANI',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6345',
    addressNomenclature: '0',
    x: '25.321484',
    y: '41.924055',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '6817',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БУБИНО',
    nameEn: 'BUBINO',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6582',
    addressNomenclature: '0',
    x: '25.811205',
    y: '41.558249',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '6848',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БУЗОВГРАД',
    nameEn: 'BUZOVGRAD',
    municipality: 'КАЗАНЛЪК',
    municipalityEn: 'KAZANLAK',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6159',
    addressNomenclature: '0',
    x: '25.371317',
    y: '42.581861',
    servingDays: '1111110',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '6851',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БУЗЯКОВЦИ',
    nameEn: 'BUZYAKOVTSI',
    municipality: 'ИХТИМАН',
    municipalityEn: 'IHTIMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2060',
    addressNomenclature: '0',
    x: '23.790454',
    y: '42.535836',
    servingDays: '0101000',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '6865',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БУЙНОВИЦА',
    nameEn: 'BUYNOVITSA',
    municipality: 'ВЕНЕЦ',
    municipalityEn: 'VENETS',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9756',
    addressNomenclature: '0',
    x: '26.944834',
    y: '43.590317',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '6879',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БУЙНОВО',
    nameEn: 'BUYNOVO',
    municipality: 'БОРИНО',
    municipalityEn: 'BORINO',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4812',
    addressNomenclature: '0',
    x: '24.314631',
    y: '41.560379',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '6882',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БУЙНОВО',
    nameEn: 'BUYNOVO',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7753',
    addressNomenclature: '0',
    x: '26.756399',
    y: '43.376846',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '6896',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БУЙНОВЦИ',
    nameEn: 'BUYNOVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5097',
    addressNomenclature: '0',
    x: '25.891697',
    y: '42.867392',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '6906',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БУК',
    nameEn: 'BUK',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6966',
    addressNomenclature: '0',
    x: '25.552475',
    y: '41.347274',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '6919',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БУКАК',
    nameEn: 'BUKAK',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7996',
    addressNomenclature: '0',
    x: '26.171377',
    y: '43.054449',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '6923',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БУКАТА',
    nameEn: 'BUKATA',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4763',
    addressNomenclature: '0',
    x: '24.654177',
    y: '41.495289',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '2988',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БУКАЦИТЕ',
    nameEn: 'BUKATSITE',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4782',
    addressNomenclature: '0',
    x: '24.73496',
    y: '41.470222',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '6937',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БУКОВА ПОЛЯНА',
    nameEn: 'BUKOVA POLYANA',
    municipality: 'МАДАН',
    municipalityEn: 'MADAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4935',
    addressNomenclature: '0',
    x: '25.000538',
    y: '41.529778',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '6940',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БУКОВЕЦ',
    nameEn: 'BUKOVETS',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5063',
    addressNomenclature: '0',
    x: '25.486924',
    y: '43.046817',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '6954',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БУКОВЕЦ',
    nameEn: 'BUKOVETS',
    municipality: 'ВИДИН',
    municipalityEn: 'VIDIN',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3762',
    addressNomenclature: '0',
    x: '22.762505',
    y: '43.932477',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '6968',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БУКОВЕЦ',
    nameEn: 'BUKOVETS',
    municipality: 'БЯЛА СЛАТИНА',
    municipalityEn: 'BYALA SLATINA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3242',
    addressNomenclature: '0',
    x: '23.830851',
    y: '43.356271',
    servingDays: '0101000',
    servingOfficeId: '59',
    servingHubOfficeId: '20',
  },
  {
    id: '6971',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БУКОВЕЦ',
    nameEn: 'BUKOVETS',
    municipality: 'БРУСАРЦИ',
    municipalityEn: 'BRUSARTSI',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3678',
    addressNomenclature: '0',
    x: '22.97482',
    y: '43.598287',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '6985',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БУКОВЕЦ',
    nameEn: 'BUKOVETS',
    municipality: 'СВОГЕ',
    municipalityEn: 'SVOGE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2293',
    addressNomenclature: '0',
    x: '23.492148',
    y: '42.932349',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '6999',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БУКОВЛЪК',
    nameEn: 'BUKOVLAK',
    municipality: 'ПЛЕВЕН',
    municipalityEn: 'PLEVEN',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5839',
    addressNomenclature: '0',
    x: '24.61564',
    y: '43.443854',
    servingDays: '0101000',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '7003',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БУКОВО',
    nameEn: 'BUKOVO',
    municipality: 'ГОЦЕ ДЕЛЧЕВ',
    municipalityEn: 'GOTSE DELCHEV',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2915',
    addressNomenclature: '0',
    x: '23.714635',
    y: '41.711826',
    servingDays: '0101000',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '7017',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БУКОВО',
    nameEn: 'BUKOVO',
    municipality: 'МАДАН',
    municipalityEn: 'MADAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4930',
    addressNomenclature: '0',
    x: '24.996099',
    y: '41.507168',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '56335',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БУКОВО',
    nameEn: 'BUKOVO',
    municipality: 'ПЪРВОМАЙ',
    municipalityEn: 'PARVOMAY',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4295',
    addressNomenclature: '0',
    x: '25.244983',
    y: '41.94607',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '7020',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БУКОРОВЦИ',
    nameEn: 'BUKOROVTSI',
    municipality: 'ГОДЕЧ',
    municipalityEn: 'GODECH',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2253',
    addressNomenclature: '0',
    x: '22.970277',
    y: '42.991904',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '7034',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БУЛАИР',
    nameEn: 'BULAIR',
    municipality: 'ДОЛНИ ЧИФЛИК',
    municipalityEn: 'DOLNI CHIFLIK',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9115',
    addressNomenclature: '0',
    x: '27.447384',
    y: '42.89757',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '7048',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БУНОВО',
    nameEn: 'BUNOVO',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2539',
    addressNomenclature: '0',
    x: '22.781412',
    y: '42.394188',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '7051',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БУНОВО',
    nameEn: 'BUNOVO',
    municipality: 'МИРКОВО',
    municipalityEn: 'MIRKOVO',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2074',
    addressNomenclature: '0',
    x: '23.931348',
    y: '42.715617',
    servingDays: '1010100',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '7822',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БУНЦЕВО',
    nameEn: 'BUNTSEVO',
    municipality: 'ЯКОРУДА',
    municipalityEn: 'YAKORUDA',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2791',
    addressNomenclature: '0',
    x: '23.71057',
    y: '42.003032',
    servingDays: '1010100',
    servingOfficeId: '125',
    servingHubOfficeId: '13',
  },
  {
    id: '7065',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БУРАНОВО',
    nameEn: 'BURANOVO',
    municipality: 'КОЧЕРИНОВО',
    municipalityEn: 'KOCHERINOVO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2628',
    addressNomenclature: '0',
    x: '23.015331',
    y: '42.092118',
    servingDays: '1111100',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '7079',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'БУРГАС',
    nameEn: 'BURGAS',
    municipality: 'БУРГАС',
    municipalityEn: 'BURGAS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8000',
    addressNomenclature: '1',
    x: '27.464419',
    y: '42.513077',
    servingDays: '1111110',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '7911',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БУРЕВО',
    nameEn: 'BUREVO',
    municipality: 'НЕДЕЛИНО',
    municipalityEn: 'NEDELINO',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4998',
    addressNomenclature: '0',
    x: '25.133326',
    y: '41.501215',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '7082',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БУРЯ',
    nameEn: 'BURYA',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5424',
    addressNomenclature: '0',
    x: '25.31362',
    y: '43.028999',
    servingDays: '0101000',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '7096',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БУСИНЦИ',
    nameEn: 'BUSINTSI',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2486',
    addressNomenclature: '0',
    x: '22.625531',
    y: '42.781615',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '7106',
    countryId: '100',
    mainSiteId: '68134',
    type: 'с.',
    typeEn: 's.',
    name: 'БУСМАНЦИ',
    nameEn: 'BUSMANTSI',
    municipality: 'СТОЛИЧНА',
    municipalityEn: 'STOLICHNA',
    region: 'СОФИЯ (СТОЛИЦА)',
    regionEn: 'SOFIA (STOLITSA)',
    postCode: '1520',
    addressNomenclature: '2',
    x: '23.432991',
    y: '42.675985',
    servingDays: '1111100',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '7116',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БУТАН',
    nameEn: 'BUTAN',
    municipality: 'КОЗЛОДУЙ',
    municipalityEn: 'KOZLODUY',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3326',
    addressNomenclature: '0',
    x: '23.755253',
    y: '43.652108',
    servingDays: '1111100',
    servingOfficeId: '64',
    servingHubOfficeId: '20',
  },
  {
    id: '7123',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БУТОВО',
    nameEn: 'BUTOVO',
    municipality: 'ПАВЛИКЕНИ',
    municipalityEn: 'PAVLIKENI',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5232',
    addressNomenclature: '0',
    x: '25.220727',
    y: '43.302367',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '7867',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БУТРЕВА',
    nameEn: 'BUTREVA',
    municipality: 'ВЕЛИНГРАД',
    municipalityEn: 'VELINGRAD',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4652',
    addressNomenclature: '0',
    x: '23.799398',
    y: '42.006229',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '7137',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БУТРОИНЦИ',
    nameEn: 'BUTROINTSI',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2473',
    addressNomenclature: '0',
    x: '22.742995',
    y: '42.850772',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '7140',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'БУХОВО',
    nameEn: 'BUHOVO',
    municipality: 'СТОЛИЧНА',
    municipalityEn: 'STOLICHNA',
    region: 'СОФИЯ (СТОЛИЦА)',
    regionEn: 'SOFIA (STOLITSA)',
    postCode: '1830',
    addressNomenclature: '2',
    x: '23.564199',
    y: '42.762501',
    servingDays: '1111100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '7154',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БУХОВЦИ',
    nameEn: 'BUHOVTSI',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7757',
    addressNomenclature: '0',
    x: '26.710348',
    y: '43.312135',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '7171',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БУЧИН ПРОХОД',
    nameEn: 'BUCHIN PROHOD',
    municipality: 'КОСТИНБРОД',
    municipalityEn: 'KOSTINBROD',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2236',
    addressNomenclature: '0',
    x: '23.14022',
    y: '42.972579',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '7168',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БУЧИНО',
    nameEn: 'BUCHINO',
    municipality: 'БЛАГОЕВГРАД',
    municipalityEn: 'BLAGOEVGRAD',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2742',
    addressNomenclature: '0',
    x: '23.02343',
    y: '42.045298',
    servingDays: '1111110',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '7185',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БУЧУКОВЦИ',
    nameEn: 'BUCHUKOVTSI',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5380',
    addressNomenclature: '0',
    x: '25.445766',
    y: '42.918109',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '7199',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЪДЕЩЕ',
    nameEn: 'BADESHTE',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6058',
    addressNomenclature: '0',
    x: '25.677106',
    y: '42.310412',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '7209',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЪЗОВЕЦ',
    nameEn: 'BAZOVETS',
    municipality: 'ВЪЛЧЕДРЪМ',
    municipalityEn: 'VALCHEDRAM',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3635',
    addressNomenclature: '0',
    x: '23.554704',
    y: '43.63048',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '7212',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЪЗОВЕЦ',
    nameEn: 'BAZOVETS',
    municipality: 'ДВЕ МОГИЛИ',
    municipalityEn: 'DVE MOGILI',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7168',
    addressNomenclature: '0',
    x: '25.880366',
    y: '43.503739',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '7226',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЪЗОВИЦА',
    nameEn: 'BAZOVITSA',
    municipality: 'ТРЕКЛЯНО',
    municipalityEn: 'TREKLYANO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2555',
    addressNomenclature: '0',
    x: '22.542851',
    y: '42.597039',
    servingDays: '0101000',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '7231',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЪЗЪН',
    nameEn: 'BAZAN',
    municipality: 'РУСЕ',
    municipalityEn: 'RUSE',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7077',
    addressNomenclature: '0',
    x: '26.106128',
    y: '43.737526',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '7243',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЪЛГАРАНОВО',
    nameEn: 'BALGARANOVO',
    municipality: 'ОМУРТАГ',
    municipalityEn: 'OMURTAG',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7944',
    addressNomenclature: '0',
    x: '26.495281',
    y: '43.022561',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '7257',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЪЛГАРЕВО',
    nameEn: 'BALGAREVO',
    municipality: 'КАВАРНА',
    municipalityEn: 'KAVARNA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9660',
    addressNomenclature: '0',
    x: '28.413007',
    y: '43.402632',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '7260',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЪЛГАРЕНЕ',
    nameEn: 'BALGARENE',
    municipality: 'ЛОВЕЧ',
    municipalityEn: 'LOVECH',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5583',
    addressNomenclature: '0',
    x: '24.766049',
    y: '43.045267',
    servingDays: '0101000',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '7274',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЪЛГАРЕНЕ',
    nameEn: 'BALGARENE',
    municipality: 'ЛЕВСКИ',
    municipalityEn: 'LEVSKI',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5923',
    addressNomenclature: '0',
    x: '25.075951',
    y: '43.440073',
    servingDays: '1010100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '7288',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЪЛГАРЕНЕ',
    nameEn: 'BALGARENE',
    municipality: 'РАДНЕВО',
    municipalityEn: 'RADNEVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6274',
    addressNomenclature: '0',
    x: '25.908221',
    y: '42.249853',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '7291',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЪЛГАРИ',
    nameEn: 'BALGARI',
    municipality: 'ЦАРЕВО',
    municipalityEn: 'TSAREVO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8274',
    addressNomenclature: '0',
    x: '27.729688',
    y: '42.088343',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '7301',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЪЛГАРИ',
    nameEn: 'BALGARI',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '',
    addressNomenclature: '0',
    x: '24.914697',
    y: '42.934653',
    servingDays: '1010100',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '7315',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЪЛГАРИН',
    nameEn: 'BALGARIN',
    municipality: 'ХАРМАНЛИ',
    municipalityEn: 'HARMANLI',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6460',
    addressNomenclature: '0',
    x: '25.954326',
    y: '41.954997',
    servingDays: '1010100',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '7329',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЪЛГАРКА',
    nameEn: 'BALGARKA',
    municipality: 'СИЛИСТРА',
    municipalityEn: 'SILISTRA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7544',
    addressNomenclature: '0',
    x: '27.327923',
    y: '44.023184',
    servingDays: '0101000',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '7332',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'БЪЛГАРОВО',
    nameEn: 'BALGAROVO',
    municipality: 'БУРГАС',
    municipalityEn: 'BURGAS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8110',
    addressNomenclature: '0',
    x: '27.305419',
    y: '42.617876',
    servingDays: '1111110',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '7346',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЪЛГАРСКА ПОЛЯНА',
    nameEn: 'BALGARSKA POLYANA',
    municipality: 'ТОПОЛОВГРАД',
    municipalityEn: 'TOPOLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6569',
    addressNomenclature: '0',
    x: '26.198365',
    y: '42.030173',
    servingDays: '1010100',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '7357',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЪЛГАРСКИ ИЗВОР',
    nameEn: 'BALGARSKI IZVOR',
    municipality: 'ТЕТЕВЕН',
    municipalityEn: 'TETEVEN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5733',
    addressNomenclature: '0',
    x: '24.287919',
    y: '43.035574',
    servingDays: '1010100',
    servingOfficeId: '88',
    servingHubOfficeId: '2',
  },
  {
    id: '7363',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЪЛГАРСКО СЛИВОВО',
    nameEn: 'BALGARSKO SLIVOVO',
    municipality: 'СВИЩОВ',
    municipalityEn: 'SVISHTOV',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5284',
    addressNomenclature: '0',
    x: '25.299751',
    y: '43.545097',
    servingDays: '0101000',
    servingOfficeId: '250',
    servingHubOfficeId: '11',
  },
  {
    id: '7377',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЪЛГАРЧЕВО',
    nameEn: 'BALGARCHEVO',
    municipality: 'БЛАГОЕВГРАД',
    municipalityEn: 'BLAGOEVGRAD',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2746',
    addressNomenclature: '0',
    x: '23.03501',
    y: '42.026024',
    servingDays: '1111110',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '7380',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЪНЗАРЕТО',
    nameEn: 'BANZARETO',
    municipality: 'МЪГЛИЖ',
    municipalityEn: 'MAGLIZH',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6186',
    addressNomenclature: '0',
    x: '25.607195',
    y: '42.703703',
    servingDays: '1111110',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '7394',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЪРДАРЕВО',
    nameEn: 'BARDAREVO',
    municipality: 'ДОЛНИ ЧИФЛИК',
    municipalityEn: 'DOLNI CHIFLIK',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9117',
    addressNomenclature: '0',
    x: '27.600556',
    y: '42.923399',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '7404',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЪРДАРИТЕ',
    nameEn: 'BARDARITE',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5360',
    addressNomenclature: '0',
    x: '25.438508',
    y: '42.811044',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '7418',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЪРДАРСКИ ГЕРАН',
    nameEn: 'BARDARSKI GERAN',
    municipality: 'БЯЛА СЛАТИНА',
    municipalityEn: 'BYALA SLATINA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3259',
    addressNomenclature: '0',
    x: '23.95219',
    y: '43.547112',
    servingDays: '0101000',
    servingOfficeId: '59',
    servingHubOfficeId: '20',
  },
  {
    id: '7421',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЪРДЕНИ',
    nameEn: 'BARDENI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5365',
    addressNomenclature: '0',
    x: '25.556332',
    y: '42.891815',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '7435',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЪРДО',
    nameEn: 'BARDO',
    municipality: 'ИХТИМАН',
    municipalityEn: 'IHTIMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2060',
    addressNomenclature: '0',
    x: '23.806866',
    y: '42.568939',
    servingDays: '0101000',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '7452',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЪРДОКВА',
    nameEn: 'BARDOKVA',
    municipality: 'ИСПЕРИХ',
    municipalityEn: 'ISPERIH',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7448',
    addressNomenclature: '0',
    x: '26.799777',
    y: '43.634755',
    servingDays: '1010100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '7472',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЪРЗА РЕКА',
    nameEn: 'BARZA REKA',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6710',
    addressNomenclature: '0',
    x: '25.380183',
    y: '41.73184',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '7483',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЪРЗЕЯ',
    nameEn: 'BARZEYA',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '',
    addressNomenclature: '0',
    x: '25.323467',
    y: '41.349439',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '7497',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЪРЗИНА',
    nameEn: 'BARZINA',
    municipality: 'ХАЙРЕДИН',
    municipalityEn: 'HAYREDIN',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3359',
    addressNomenclature: '0',
    x: '23.717161',
    y: '43.553917',
    servingDays: '1010100',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '7507',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЪРЗИЦА',
    nameEn: 'BARZITSA',
    municipality: 'ПРОВАДИЯ',
    municipalityEn: 'PROVADIA',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9274',
    addressNomenclature: '0',
    x: '27.526966',
    y: '43.074059',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '7510',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЪРЗИЯ',
    nameEn: 'BARZIA',
    municipality: 'БЕРКОВИЦА',
    municipalityEn: 'BERKOVITSA',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3520',
    addressNomenclature: '2',
    x: '23.161295',
    y: '43.200475',
    servingDays: '1111100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '7524',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЪРКАЧ',
    nameEn: 'BARKACH',
    municipality: 'ДОЛНИ ДЪБНИК',
    municipalityEn: 'DOLNI DABNIK',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5877',
    addressNomenclature: '0',
    x: '24.440678',
    y: '43.293147',
    servingDays: '1010100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '7538',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЪРКАЧЕВО',
    nameEn: 'BARKACHEVO',
    municipality: 'БЯЛА СЛАТИНА',
    municipalityEn: 'BYALA SLATINA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3245',
    addressNomenclature: '0',
    x: '23.930432',
    y: '43.407382',
    servingDays: '0101000',
    servingOfficeId: '59',
    servingHubOfficeId: '20',
  },
  {
    id: '7541',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЪРЛОЖНИЦА',
    nameEn: 'BARLOZHNITSA',
    municipality: 'СЛИВНИЦА',
    municipalityEn: 'SLIVNITSA',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2222',
    addressNomenclature: '0',
    x: '22.942333',
    y: '42.849486',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '7555',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЪРЛЯ',
    nameEn: 'BARLYA',
    municipality: 'ГОДЕЧ',
    municipalityEn: 'GODECH',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2255',
    addressNomenclature: '0',
    x: '22.983455',
    y: '43.09925',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '7569',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЪРЧЕВО',
    nameEn: 'BARCHEVO',
    municipality: 'РУДОЗЕМ',
    municipalityEn: 'RUDOZEM',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4965',
    addressNomenclature: '0',
    x: '24.821411',
    y: '41.470449',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '7572',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЪТА',
    nameEn: 'BATA',
    municipality: 'ПАНАГЮРИЩЕ',
    municipalityEn: 'PANAGYURISHTE',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4527',
    addressNomenclature: '0',
    x: '24.181945',
    y: '42.450837',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '7586',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЯГА',
    nameEn: 'BYAGA',
    municipality: 'БРАЦИГОВО',
    municipalityEn: 'BRATSIGOVO',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4587',
    addressNomenclature: '0',
    x: '24.378022',
    y: '42.066281',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '7729',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЯЛ БРЯГ',
    nameEn: 'BYAL BRYAG',
    municipality: 'СМЯДОВО',
    municipalityEn: 'SMYADOVO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9824',
    addressNomenclature: '0',
    x: '27.107479',
    y: '43.058059',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '7747',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЯЛ ИЗВОР',
    nameEn: 'BYAL IZVOR',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6770',
    addressNomenclature: '0',
    x: '25.073167',
    y: '41.546506',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '7750',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЯЛ ИЗВОР',
    nameEn: 'BYAL IZVOR',
    municipality: 'ОПАН',
    municipalityEn: 'OPAN',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6073',
    addressNomenclature: '0',
    x: '25.638062',
    y: '42.157558',
    servingDays: '1010100',
    servingOfficeId: '42',
    servingHubOfficeId: '5',
  },
  {
    id: '7778',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЯЛ КЛАДЕНЕЦ',
    nameEn: 'BYAL KLADENETS',
    municipality: 'НОВА ЗАГОРА',
    municipalityEn: 'NOVA ZAGORA',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8961',
    addressNomenclature: '0',
    x: '26.166062',
    y: '42.299515',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '7781',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЯЛ КЛАДЕНЕЦ',
    nameEn: 'BYAL KLADENETS',
    municipality: 'СТАМБОЛОВО',
    municipalityEn: 'STAMBOLOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6377',
    addressNomenclature: '0',
    x: '25.632636',
    y: '41.647911',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '7598',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'БЯЛА',
    nameEn: 'BYALA',
    municipality: 'БЯЛА',
    municipalityEn: 'BYALA',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9101',
    addressNomenclature: '2',
    x: '27.88079',
    y: '42.879753',
    servingDays: '1111110',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '7603',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'БЯЛА',
    nameEn: 'BYALA',
    municipality: 'БЯЛА',
    municipalityEn: 'BYALA',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7100',
    addressNomenclature: '2',
    x: '25.738468',
    y: '43.460241',
    servingDays: '1111100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '7613',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЯЛА',
    nameEn: 'BYALA',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8850',
    addressNomenclature: '0',
    x: '26.188677',
    y: '42.729615',
    servingDays: '0101000',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '7627',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЯЛА ВОДА',
    nameEn: 'BYALA VODA',
    municipality: 'МАЛКО ТЪРНОВО',
    municipalityEn: 'MALKO TARNOVO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8171',
    addressNomenclature: '0',
    x: '27.454789',
    y: '42.165507',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '7630',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЯЛА ВОДА',
    nameEn: 'BYALA VODA',
    municipality: 'БЕЛЕНЕ',
    municipalityEn: 'BELENE',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5932',
    addressNomenclature: '0',
    x: '25.040843',
    y: '43.629343',
    servingDays: '0101000',
    servingOfficeId: '108',
    servingHubOfficeId: '11',
  },
  {
    id: '7644',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЯЛА ПАЛАНКА',
    nameEn: 'BYALA PALANKA',
    municipality: 'ТВЪРДИЦА',
    municipalityEn: 'TVARDITSA',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8892',
    addressNomenclature: '0',
    x: '26.09649',
    y: '42.761292',
    servingDays: '0101000',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '7658',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЯЛА ПОЛЯНА',
    nameEn: 'BYALA POLYANA',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6662',
    addressNomenclature: '0',
    x: '25.628048',
    y: '41.678902',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '7661',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЯЛА РЕКА',
    nameEn: 'BYALA REKA',
    municipality: 'СУХИНДОЛ',
    municipalityEn: 'SUHINDOL',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5237',
    addressNomenclature: '0',
    x: '25.202973',
    y: '43.132872',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '7675',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЯЛА РЕКА',
    nameEn: 'BYALA REKA',
    municipality: 'ПЪРВОМАЙ',
    municipalityEn: 'PARVOMAY',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4285',
    addressNomenclature: '0',
    x: '25.264261',
    y: '42.058722',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '7689',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЯЛА РЕКА',
    nameEn: 'BYALA REKA',
    municipality: 'РУДОЗЕМ',
    municipalityEn: 'RUDOZEM',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4966',
    addressNomenclature: '0',
    x: '24.816566',
    y: '41.491868',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '7692',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЯЛА РЕКА',
    nameEn: 'BYALA REKA',
    municipality: 'ВЪРБИЦА',
    municipalityEn: 'VARBITSA',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9882',
    addressNomenclature: '0',
    x: '26.773374',
    y: '42.994454',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '7702',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'БЯЛА СЛАТИНА',
    nameEn: 'BYALA SLATINA',
    municipality: 'БЯЛА СЛАТИНА',
    municipalityEn: 'BYALA SLATINA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3200',
    addressNomenclature: '2',
    x: '23.937209',
    y: '43.470406',
    servingDays: '1111110',
    servingOfficeId: '59',
    servingHubOfficeId: '20',
  },
  {
    id: '7716',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'БЯЛА ЧЕРКВА',
    nameEn: 'BYALA CHERKVA',
    municipality: 'ПАВЛИКЕНИ',
    municipalityEn: 'PAVLIKENI',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5220',
    addressNomenclature: '0',
    x: '25.310746',
    y: '43.205269',
    servingDays: '1111100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '7733',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЯЛГРАДЕЦ',
    nameEn: 'BYALGRADETS',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6590',
    addressNomenclature: '0',
    x: '25.910206',
    y: '41.420723',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '7764',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЯЛКА',
    nameEn: 'BYALKA',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6656',
    addressNomenclature: '0',
    x: '25.42104',
    y: '41.67142',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '7795',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЯЛКОВО',
    nameEn: 'BYALKOVO',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5344',
    addressNomenclature: '0',
    x: '25.28032',
    y: '42.944104',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '7805',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЯЛКОВЦИ',
    nameEn: 'BYALKOVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5084',
    addressNomenclature: '0',
    x: '26.006229',
    y: '42.945678',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '7819',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'БЯЛО ПОЛЕ',
    nameEn: 'BYALO POLE',
    municipality: 'ОПАН',
    municipalityEn: 'OPAN',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6054',
    addressNomenclature: '0',
    x: '25.718582',
    y: '42.162486',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '10015',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВАЗОВО',
    nameEn: 'VAZOVO',
    municipality: 'ИСПЕРИХ',
    municipalityEn: 'ISPERIH',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7422',
    addressNomenclature: '0',
    x: '26.814743',
    y: '43.773142',
    servingDays: '0101000',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '10029',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВАКАРЕЛ',
    nameEn: 'VAKAREL',
    municipality: 'ИХТИМАН',
    municipalityEn: 'IHTIMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2060',
    addressNomenclature: '2',
    x: '23.709888',
    y: '42.559401',
    servingDays: '1111100',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '10032',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВАКЛИНО',
    nameEn: 'VAKLINO',
    municipality: 'ШАБЛА',
    municipalityEn: 'SHABLA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9672',
    addressNomenclature: '0',
    x: '28.503408',
    y: '43.646925',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '10046',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВАКЛИНОВО',
    nameEn: 'VAKLINOVO',
    municipality: 'САТОВЧА',
    municipalityEn: 'SATOVCHA',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2957',
    addressNomenclature: '0',
    x: '24.063538',
    y: '41.5959',
    servingDays: '1111100',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '10050',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВАКСЕВО',
    nameEn: 'VAKSEVO',
    municipality: 'НЕВЕСТИНО',
    municipalityEn: 'NEVESTINO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2590',
    addressNomenclature: '0',
    x: '22.854421',
    y: '42.161591',
    servingDays: '1010100',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '13069',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВАЛЕВЦИ',
    nameEn: 'VALEVTSI',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5462',
    addressNomenclature: '0',
    x: '25.086826',
    y: '42.837444',
    servingDays: '1010100',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '10063',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВАЛЕТО',
    nameEn: 'VALETO',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5099',
    addressNomenclature: '0',
    x: '25.988111',
    y: '42.875101',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '10080',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВАРАНА',
    nameEn: 'VARANA',
    municipality: 'ЛЕВСКИ',
    municipalityEn: 'LEVSKI',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5911',
    addressNomenclature: '0',
    x: '25.219103',
    y: '43.355613',
    servingDays: '0101000',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '10094',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВАРВАРА',
    nameEn: 'VARVARA',
    municipality: 'ЦАРЕВО',
    municipalityEn: 'TSAREVO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8278',
    addressNomenclature: '0',
    x: '27.900512',
    y: '42.121237',
    servingDays: '1111100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '10104',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВАРВАРА',
    nameEn: 'VARVARA',
    municipality: 'СЕПТЕМВРИ',
    municipalityEn: 'SEPTEMVRI',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4492',
    addressNomenclature: '0',
    x: '24.125587',
    y: '42.161874',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '10118',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВАРДИМ',
    nameEn: 'VARDIM',
    municipality: 'СВИЩОВ',
    municipalityEn: 'SVISHTOV',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5281',
    addressNomenclature: '0',
    x: '25.470068',
    y: '43.61273',
    servingDays: '1010100',
    servingOfficeId: '250',
    servingHubOfficeId: '11',
  },
  {
    id: '10121',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВАРДУН',
    nameEn: 'VARDUN',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7780',
    addressNomenclature: '0',
    x: '26.530642',
    y: '43.133469',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '10135',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ВАРНА',
    nameEn: 'VARNA',
    municipality: 'ВАРНА',
    municipalityEn: 'VARNA',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9000',
    addressNomenclature: '1',
    x: '27.910886',
    y: '43.20483',
    servingDays: '1111110',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '10149',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВАРНЕНЦИ',
    nameEn: 'VARNENTSI',
    municipality: 'ТУТРАКАН',
    municipalityEn: 'TUTRAKAN',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7638',
    addressNomenclature: '0',
    x: '26.617412',
    y: '43.941921',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '10152',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВАРНИК',
    nameEn: 'VARNIK',
    municipality: 'СВИЛЕНГРАД',
    municipalityEn: 'SVILENGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6536',
    addressNomenclature: '0',
    x: '26.512405',
    y: '41.866998',
    servingDays: '0101000',
    servingOfficeId: '48',
    servingHubOfficeId: '5',
  },
  {
    id: '10166',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВАРОВНИК',
    nameEn: 'VAROVNIK',
    municipality: 'СРЕДЕЦ',
    municipalityEn: 'SREDETS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8338',
    addressNomenclature: '0',
    x: '27.233977',
    y: '42.21733',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '10176',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВАСИЛ ДРУМЕВ',
    nameEn: 'VASIL DRUMEV',
    municipality: 'ШУМЕН',
    municipalityEn: 'SHUMEN',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9814',
    addressNomenclature: '0',
    x: '27.027137',
    y: '43.247427',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '10207',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВАСИЛ ЛЕВСКИ',
    nameEn: 'VASIL LEVSKI',
    municipality: 'КАРЛОВО',
    municipalityEn: 'KARLOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4363',
    addressNomenclature: '0',
    x: '24.898873',
    y: '42.615096',
    servingDays: '1010100',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '10210',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВАСИЛ ЛЕВСКИ',
    nameEn: 'VASIL LEVSKI',
    municipality: 'АЛФАТАР',
    municipalityEn: 'ALFATAR',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7571',
    addressNomenclature: '0',
    x: '27.382401',
    y: '43.933246',
    servingDays: '0101000',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '10224',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВАСИЛ ЛЕВСКИ',
    nameEn: 'VASIL LEVSKI',
    municipality: 'ОПАН',
    municipalityEn: 'OPAN',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6068',
    addressNomenclature: '0',
    x: '25.774716',
    y: '42.107661',
    servingDays: '0101000',
    servingOfficeId: '42',
    servingHubOfficeId: '5',
  },
  {
    id: '10238',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВАСИЛ ЛЕВСКИ',
    nameEn: 'VASIL LEVSKI',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7749',
    addressNomenclature: '0',
    x: '26.629489',
    y: '43.264966',
    servingDays: '1111100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '10183',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВАСИЛЕВО',
    nameEn: 'VASILEVO',
    municipality: 'ГЕНЕРАЛ ТОШЕВО',
    municipalityEn: 'GENERAL TOSHEVO',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9543',
    addressNomenclature: '0',
    x: '28.178443',
    y: '43.612185',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '10197',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВАСИЛКОВСКА МАХАЛА',
    nameEn: 'VASILKOVSKA MAHALA',
    municipality: 'УГЪРЧИН',
    municipalityEn: 'UGARCHIN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '',
    addressNomenclature: '0',
    x: '24.353642',
    y: '43.033412',
    servingDays: '1010100',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '10255',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВАСИЛОВЦИ',
    nameEn: 'VASILOVTSI',
    municipality: 'БРУСАРЦИ',
    municipalityEn: 'BRUSARTSI',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3658',
    addressNomenclature: '0',
    x: '23.132497',
    y: '43.716625',
    servingDays: '1010100',
    servingOfficeId: '831',
    servingHubOfficeId: '20',
  },
  {
    id: '10269',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВАСИЛОВЦИ',
    nameEn: 'VASILOVTSI',
    municipality: 'ДРАГОМАН',
    municipalityEn: 'DRAGOMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2205',
    addressNomenclature: '0',
    x: '23.081024',
    y: '42.945693',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '10272',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВАСИЛЬОВО',
    nameEn: 'VASILYOVO',
    municipality: 'ТЕТЕВЕН',
    municipalityEn: 'TETEVEN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5723',
    addressNomenclature: '0',
    x: '24.368577',
    y: '42.878249',
    servingDays: '1010100',
    servingOfficeId: '88',
    servingHubOfficeId: '2',
  },
  {
    id: '10286',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВАСКОВО',
    nameEn: 'VASKOVO',
    municipality: 'ЛЮБИМЕЦ',
    municipalityEn: 'LYUBIMETS',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6556',
    addressNomenclature: '0',
    x: '26.198271',
    y: '41.917051',
    servingDays: '1010100',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '10291',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕДРАРЕ',
    nameEn: 'VEDRARE',
    municipality: 'КАРЛОВО',
    municipalityEn: 'KARLOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4364',
    addressNomenclature: '0',
    x: '24.878648',
    y: '42.574448',
    servingDays: '1111100',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '10307',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕДРИНА',
    nameEn: 'VEDRINA',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9355',
    addressNomenclature: '0',
    x: '27.666759',
    y: '43.505708',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '10313',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕДРОВО',
    nameEn: 'VEDROVO',
    municipality: 'СУНГУРЛАРЕ',
    municipalityEn: 'SUNGURLARE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8447',
    addressNomenclature: '0',
    x: '26.98254',
    y: '42.83322',
    servingDays: '0101000',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '10327',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕЗЕНКОВО',
    nameEn: 'VEZENKOVO',
    municipality: 'СУНГУРЛАРЕ',
    municipalityEn: 'SUNGURLARE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8457',
    addressNomenclature: '0',
    x: '26.70681',
    y: '42.853697',
    servingDays: '1010100',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '10330',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕКИЛСКИ',
    nameEn: 'VEKILSKI',
    municipality: 'НИКОЛА КОЗЛЕВО',
    municipalityEn: 'NIKOLA KOZLEVO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9941',
    addressNomenclature: '0',
    x: '27.382148',
    y: '43.570376',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '10344',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕЛЕНЦИ',
    nameEn: 'VELENTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5351',
    addressNomenclature: '0',
    x: '25.461985',
    y: '42.871923',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '10358',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕЛЕШАНИ',
    nameEn: 'VELESHANI',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6727',
    addressNomenclature: '0',
    x: '25.274177',
    y: '41.575688',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '10361',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕЛИКА',
    nameEn: 'VELIKA',
    municipality: 'ЦАРЕВО',
    municipalityEn: 'TSAREVO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8271',
    addressNomenclature: '0',
    x: '27.779814',
    y: '42.183339',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '10375',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕЛИКАН',
    nameEn: 'VELIKAN',
    municipality: 'ДИМИТРОВГРАД',
    municipalityEn: 'DIMITROVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6431',
    addressNomenclature: '0',
    x: '25.453984',
    y: '42.105563',
    servingDays: '1010100',
    servingOfficeId: '42',
    servingHubOfficeId: '5',
  },
  {
    id: '10389',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕЛИКДЕНЧЕ',
    nameEn: 'VELIKDENCHE',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6845',
    addressNomenclature: '0',
    x: '25.351564',
    y: '41.451408',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '10392',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕЛИКДЕНЧЕ',
    nameEn: 'VELIKDENCHE',
    municipality: 'ОМУРТАГ',
    municipalityEn: 'OMURTAG',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7916',
    addressNomenclature: '0',
    x: '26.383056',
    y: '43.095067',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '58222',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ВЕЛИКИ ПРЕСЛАВ',
    nameEn: 'VELIKI PRESLAV',
    municipality: 'ВЕЛИКИ ПРЕСЛАВ',
    municipalityEn: 'VELIKI PRESLAV',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9850',
    addressNomenclature: '2',
    x: '26.811977',
    y: '43.160509',
    servingDays: '1111100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '10447',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ВЕЛИКО ТЪРНОВО',
    nameEn: 'VELIKO TARNOVO',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5000',
    addressNomenclature: '1',
    x: '25.627192',
    y: '43.078593',
    servingDays: '1111110',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '10416',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕЛИКОВО',
    nameEn: 'VELIKOVO',
    municipality: 'ГЪЛЪБОВО',
    municipalityEn: 'GALABOVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6282',
    addressNomenclature: '0',
    x: '25.799027',
    y: '42.187278',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '10422',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕЛИКОВО',
    nameEn: 'VELIKOVO',
    municipality: 'ГЕНЕРАЛ ТОШЕВО',
    municipalityEn: 'GENERAL TOSHEVO',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9547',
    addressNomenclature: '0',
    x: '28.234222',
    y: '43.645901',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '10433',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕЛИКОВЦИ',
    nameEn: 'VELIKOVTSI',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7964',
    addressNomenclature: '0',
    x: '26.168103',
    y: '43.037518',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '10450',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ВЕЛИНГРАД',
    nameEn: 'VELINGRAD',
    municipality: 'ВЕЛИНГРАД',
    municipalityEn: 'VELINGRAD',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4600',
    addressNomenclature: '2',
    x: '23.992369',
    y: '42.026127',
    servingDays: '1111110',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '10464',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕЛИНО',
    nameEn: 'VELINO',
    municipality: 'ШУМЕН',
    municipalityEn: 'SHUMEN',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9793',
    addressNomenclature: '0',
    x: '27.03178',
    y: '43.419774',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '10478',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕЛИНОВО',
    nameEn: 'VELINOVO',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2476',
    addressNomenclature: '0',
    x: '22.700206',
    y: '42.789369',
    servingDays: '1111100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '76056',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕЛИСЛАВ',
    nameEn: 'VELISLAV',
    municipality: 'СУНГУРЛАРЕ',
    municipalityEn: 'SUNGURLARE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8458',
    addressNomenclature: '0',
    x: '26.76217',
    y: '42.864224',
    servingDays: '1010100',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '10481',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕЛИЧКА',
    nameEn: 'VELICHKA',
    municipality: 'ОМУРТАГ',
    municipalityEn: 'OMURTAG',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7941',
    addressNomenclature: '0',
    x: '26.615204',
    y: '43.015503',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '10495',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕЛИЧКОВО',
    nameEn: 'VELICHKOVO',
    municipality: 'ДЪЛГОПОЛ',
    municipalityEn: 'DALGOPOL',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9263',
    addressNomenclature: '0',
    x: '27.445799',
    y: '43.049016',
    servingDays: '1111100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '10505',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕЛИЧКОВО',
    nameEn: 'VELICHKOVO',
    municipality: 'ПАЗАРДЖИК',
    municipalityEn: 'PAZARDZHIK',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4413',
    addressNomenclature: '0',
    x: '24.223369',
    y: '42.252342',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '10519',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕЛКОВО',
    nameEn: 'VELKOVO',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5365',
    addressNomenclature: '0',
    x: '25.591883',
    y: '42.876407',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '10522',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕЛКОВЦИ',
    nameEn: 'VELKOVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5097',
    addressNomenclature: '0',
    x: '25.907288',
    y: '42.892937',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '10536',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕЛКОВЦИ',
    nameEn: 'VELKOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5343',
    addressNomenclature: '0',
    x: '25.372067',
    y: '42.940687',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '10548',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕЛКОВЦИ',
    nameEn: 'VELKOVTSI',
    municipality: 'БРЕЗНИК',
    municipalityEn: 'BREZNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2375',
    addressNomenclature: '0',
    x: '22.944272',
    y: '42.667845',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '10553',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕЛЧЕВО',
    nameEn: 'VELCHEVO',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5032',
    addressNomenclature: '0',
    x: '25.70583',
    y: '43.00188',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '10567',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕЛЧЕВО',
    nameEn: 'VELCHEVO',
    municipality: 'АПРИЛЦИ',
    municipalityEn: 'APRILTSI',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5643',
    addressNomenclature: '0',
    x: '24.834585',
    y: '42.890481',
    servingDays: '1010100',
    servingOfficeId: '44',
    servingHubOfficeId: '11',
  },
  {
    id: '10570',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕЛЧОВЦИ',
    nameEn: 'VELCHOVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5367',
    addressNomenclature: '0',
    x: '25.572798',
    y: '42.80182',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '10584',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕЛЬОВО',
    nameEn: 'VELYOVO',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7985',
    addressNomenclature: '0',
    x: '26.28726',
    y: '43.085483',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '10598',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕЛЮВЦИ',
    nameEn: 'VELYUVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5070',
    addressNomenclature: '0',
    x: '25.837723',
    y: '42.969683',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '10608',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕЛЮЩЕЦ',
    nameEn: 'VELYUSHTETS',
    municipality: 'СТРУМЯНИ',
    municipalityEn: 'STRUMYANI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2849',
    addressNomenclature: '0',
    x: '23.141904',
    y: '41.58872',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '10611',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕНЕЛИН',
    nameEn: 'VENELIN',
    municipality: 'ДОЛНИ ЧИФЛИК',
    municipalityEn: 'DOLNI CHIFLIK',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9119',
    addressNomenclature: '0',
    x: '27.668861',
    y: '43.047056',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '10625',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕНЕЦ',
    nameEn: 'VENETS',
    municipality: 'КАРНОБАТ',
    municipalityEn: 'KARNOBAT',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8473',
    addressNomenclature: '0',
    x: '26.802302',
    y: '42.639668',
    servingDays: '1111100',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '10639',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕНЕЦ',
    nameEn: 'VENETS',
    municipality: 'ОПАН',
    municipalityEn: 'OPAN',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6078',
    addressNomenclature: '0',
    x: '25.733493',
    y: '42.191997',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '10642',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕНЕЦ',
    nameEn: 'VENETS',
    municipality: 'ВЕНЕЦ',
    municipalityEn: 'VENETS',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9751',
    addressNomenclature: '0',
    x: '26.922373',
    y: '43.542964',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '10656',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕНКОВЕЦ',
    nameEn: 'VENKOVETS',
    municipality: 'ИХТИМАН',
    municipalityEn: 'IHTIMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2066',
    addressNomenclature: '0',
    x: '23.696822',
    y: '42.453793',
    servingDays: '0101000',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '10663',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕНЧАН',
    nameEn: 'VENCHAN',
    municipality: 'ПРОВАДИЯ',
    municipalityEn: 'PROVADIA',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9219',
    addressNomenclature: '0',
    x: '27.389613',
    y: '43.235333',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '10673',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕРЕН',
    nameEn: 'VEREN',
    municipality: 'БРАТЯ ДАСКАЛОВИ',
    municipalityEn: 'BRATYA DASKALOVI',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6244',
    addressNomenclature: '0',
    x: '25.189344',
    y: '42.347982',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '10687',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕРЕНЦИ',
    nameEn: 'VERENTSI',
    municipality: 'ОМУРТАГ',
    municipalityEn: 'OMURTAG',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7957',
    addressNomenclature: '0',
    x: '26.443612',
    y: '43.065303',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '10690',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕРИНСКО',
    nameEn: 'VERINSKO',
    municipality: 'ИХТИМАН',
    municipalityEn: 'IHTIMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2063',
    addressNomenclature: '0',
    x: '23.754447',
    y: '42.479346',
    servingDays: '0101000',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '10700',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕРСКО',
    nameEn: 'VERSKO',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6710',
    addressNomenclature: '0',
    x: '25.339445',
    y: '41.709377',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '10714',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕСЕЛЕЦ',
    nameEn: 'VESELETS',
    municipality: 'ЗАВЕТ',
    municipalityEn: 'ZAVET',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7327',
    addressNomenclature: '0',
    x: '26.732735',
    y: '43.811583',
    servingDays: '0101000',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '10728',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕСЕЛЕЦ',
    nameEn: 'VESELETS',
    municipality: 'ОМУРТАГ',
    municipalityEn: 'OMURTAG',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7942',
    addressNomenclature: '0',
    x: '26.486376',
    y: '43.030369',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '10731',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕСЕЛИЕ',
    nameEn: 'VESELIE',
    municipality: 'ПРИМОРСКО',
    municipalityEn: 'PRIMORSKO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8145',
    addressNomenclature: '0',
    x: '27.620617',
    y: '42.327257',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '10745',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕСЕЛИНА',
    nameEn: 'VESELINA',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5094',
    addressNomenclature: '0',
    x: '25.857273',
    y: '42.862147',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '10759',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕСЕЛИНА',
    nameEn: 'VESELINA',
    municipality: 'ЛОЗНИЦА',
    municipalityEn: 'LOZNITSA',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7289',
    addressNomenclature: '0',
    x: '26.68509',
    y: '43.457687',
    servingDays: '0101000',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '10762',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕСЕЛИНОВО',
    nameEn: 'VESELINOVO',
    municipality: 'СМЯДОВО',
    municipalityEn: 'SMYADOVO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9828',
    addressNomenclature: '0',
    x: '27.027101',
    y: '42.984307',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '10776',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕСЕЛИНОВО',
    nameEn: 'VESELINOVO',
    municipality: 'ТУНДЖА',
    municipalityEn: 'TUNDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8632',
    addressNomenclature: '0',
    x: '26.544816',
    y: '42.532893',
    servingDays: '1111100',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '10789',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕСЛЕЦ',
    nameEn: 'VESLETS',
    municipality: 'ВРАЦА',
    municipalityEn: 'VRATSA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3053',
    addressNomenclature: '0',
    x: '23.667794',
    y: '43.258757',
    servingDays: '1010100',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '10803',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ВЕТОВО',
    nameEn: 'VETOVO',
    municipality: 'ВЕТОВО',
    municipalityEn: 'VETOVO',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7080',
    addressNomenclature: '2',
    x: '26.264392',
    y: '43.703979',
    servingDays: '1111100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '10817',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕТРЕН',
    nameEn: 'VETREN',
    municipality: 'НЕВЕСТИНО',
    municipalityEn: 'NEVESTINO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2579',
    addressNomenclature: '0',
    x: '22.776858',
    y: '42.049911',
    servingDays: '1010100',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '10820',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ВЕТРЕН',
    nameEn: 'VETREN',
    municipality: 'СЕПТЕМВРИ',
    municipalityEn: 'SEPTEMVRI',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4480',
    addressNomenclature: '0',
    x: '24.045303',
    y: '42.280577',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '10834',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕТРЕН',
    nameEn: 'VETREN',
    municipality: 'СИЛИСТРА',
    municipalityEn: 'SILISTRA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7588',
    addressNomenclature: '0',
    x: '27.032742',
    y: '44.12469',
    servingDays: '1010100',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '10848',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕТРЕН',
    nameEn: 'VETREN',
    municipality: 'МЪГЛИЖ',
    municipalityEn: 'MAGLIZH',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6175',
    addressNomenclature: '0',
    x: '25.686093',
    y: '42.606528',
    servingDays: '1111100',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '10851',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕТРЕН ДОЛ',
    nameEn: 'VETREN DOL',
    municipality: 'СЕПТЕМВРИ',
    municipalityEn: 'SEPTEMVRI',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4493',
    addressNomenclature: '0',
    x: '24.148602',
    y: '42.155432',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '10865',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕТРИНО',
    nameEn: 'VETRINO',
    municipality: 'ВЕТРИНО',
    municipalityEn: 'VETRINO',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9220',
    addressNomenclature: '0',
    x: '27.435357',
    y: '43.316574',
    servingDays: '1111100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '10879',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕТРИНЦИ',
    nameEn: 'VETRINTSI',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5066',
    addressNomenclature: '0',
    x: '25.43098',
    y: '43.069636',
    servingDays: '1111100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '10882',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕТРИЩЕ',
    nameEn: 'VETRISHTE',
    municipality: 'ШУМЕН',
    municipalityEn: 'SHUMEN',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9809',
    addressNomenclature: '0',
    x: '27.033405',
    y: '43.184276',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '10896',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕТРОВО',
    nameEn: 'VETROVO',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5300',
    addressNomenclature: '0',
    x: '25.294454',
    y: '42.929057',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '10906',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕТРУШКА',
    nameEn: 'VETRUSHKA',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6591',
    addressNomenclature: '0',
    x: '25.864613',
    y: '41.452875',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '10910',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕХТИНО',
    nameEn: 'VEHTINO',
    municipality: 'МАДАН',
    municipalityEn: 'MADAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4924',
    addressNomenclature: '0',
    x: '25.016676',
    y: '41.546877',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '10923',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕХТОВО',
    nameEn: 'VEHTOVO',
    municipality: 'ШУМЕН',
    municipalityEn: 'SHUMEN',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9818',
    addressNomenclature: '0',
    x: '27.083176',
    y: '43.169921',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '10937',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЕЩИЦА',
    nameEn: 'VESHTITSA',
    municipality: 'БЕЛОГРАДЧИК',
    municipalityEn: 'BELOGRADCHIK',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3918',
    addressNomenclature: '0',
    x: '22.595157',
    y: '43.679078',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '10954',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВИДЕН',
    nameEn: 'VIDEN',
    municipality: 'ПАВЕЛ БАНЯ',
    municipalityEn: 'PAVEL BANYA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6156',
    addressNomenclature: '0',
    x: '25.235853',
    y: '42.601799',
    servingDays: '1010100',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '10971',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ВИДИН',
    nameEn: 'VIDIN',
    municipality: 'ВИДИН',
    municipalityEn: 'VIDIN',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3700',
    addressNomenclature: '2',
    x: '22.875103',
    y: '43.986488',
    servingDays: '1111110',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '10985',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВИДИНЦИ',
    nameEn: 'VIDINTSI',
    municipality: 'ТУНДЖА',
    municipalityEn: 'TUNDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8671',
    addressNomenclature: '0',
    x: '26.386054',
    y: '42.311685',
    servingDays: '1010100',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '10999',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВИДЛИЦА',
    nameEn: 'VIDLITSA',
    municipality: 'ГЕОРГИ ДАМЯНОВО',
    municipalityEn: 'GEORGI DAMYANOVO',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3478',
    addressNomenclature: '0',
    x: '23.066253',
    y: '43.418633',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '11003',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВИДНО',
    nameEn: 'VIDNO',
    municipality: 'КАВАРНА',
    municipalityEn: 'KAVARNA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9668',
    addressNomenclature: '0',
    x: '28.377622',
    y: '43.562176',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '11017',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВИДРАР',
    nameEn: 'VIDRAR',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2495',
    addressNomenclature: '0',
    x: '22.594142',
    y: '42.672713',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '11020',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВИДРАРЕ',
    nameEn: 'VIDRARE',
    municipality: 'ПРАВЕЦ',
    municipalityEn: 'PRAVETS',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2166',
    addressNomenclature: '0',
    x: '24.010274',
    y: '42.992367',
    servingDays: '1010100',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '11034',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВИДРИЦА',
    nameEn: 'VIDRITSA',
    municipality: 'БРЕЗНИК',
    municipalityEn: 'BREZNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2364',
    addressNomenclature: '0',
    x: '22.8654',
    y: '42.758566',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '11048',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВИЕВО',
    nameEn: 'VIEVO',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4748',
    addressNomenclature: '0',
    x: '24.828937',
    y: '41.640891',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '11051',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВИЗИЦА',
    nameEn: 'VIZITSA',
    municipality: 'МАЛКО ТЪРНОВО',
    municipalityEn: 'MALKO TARNOVO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8167',
    addressNomenclature: '0',
    x: '27.602104',
    y: '42.124402',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '11065',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВИЛАРЕ',
    nameEn: 'VILARE',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5035',
    addressNomenclature: '0',
    x: '25.607994',
    y: '42.819261',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '11079',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВИНАРОВО',
    nameEn: 'VINAROVO',
    municipality: 'НОВО СЕЛО',
    municipalityEn: 'NOVO SELO',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3773',
    addressNomenclature: '0',
    x: '22.817004',
    y: '44.096457',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '11082',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВИНАРОВО',
    nameEn: 'VINAROVO',
    municipality: 'ЧИРПАН',
    municipalityEn: 'CHIRPAN',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6238',
    addressNomenclature: '0',
    x: '25.407003',
    y: '42.301023',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '11096',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВИНАРСКО',
    nameEn: 'VINARSKO',
    municipality: 'КАМЕНО',
    municipalityEn: 'KAMENO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8118',
    addressNomenclature: '0',
    x: '27.201005',
    y: '42.601787',
    servingDays: '1111100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '11106',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВИНЕВО',
    nameEn: 'VINEVO',
    municipality: 'МИНЕРАЛНИ БАНИ',
    municipalityEn: 'MINERALNI BANI',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6375',
    addressNomenclature: '0',
    x: '25.3078',
    y: '41.811171',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '11123',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВИНИЦА',
    nameEn: 'VINITSA',
    municipality: 'ПЪРВОМАЙ',
    municipalityEn: 'PARVOMAY',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4284',
    addressNomenclature: '0',
    x: '25.13627',
    y: '42.135268',
    servingDays: '1010100',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '11137',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВИНИЩЕ',
    nameEn: 'VINISHTE',
    municipality: 'МОНТАНА',
    municipalityEn: 'MONTANA',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3482',
    addressNomenclature: '0',
    x: '23.071114',
    y: '43.483209',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '11140',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВИНОГРАД',
    nameEn: 'VINOGRAD',
    municipality: 'СТРАЖИЦА',
    municipalityEn: 'STRAZHITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5171',
    addressNomenclature: '0',
    x: '25.828815',
    y: '43.339247',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '11154',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВИНОГРАДЕЦ',
    nameEn: 'VINOGRADETS',
    municipality: 'СЕПТЕМВРИ',
    municipalityEn: 'SEPTEMVRI',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4487',
    addressNomenclature: '0',
    x: '24.127688',
    y: '42.291814',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '11168',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВИНОГРАДИ',
    nameEn: 'VINOGRADI',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2822',
    addressNomenclature: '0',
    x: '23.383724',
    y: '41.498079',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '11171',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВИРОВЕ',
    nameEn: 'VIROVE',
    municipality: 'МОНТАНА',
    municipalityEn: 'MONTANA',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3455',
    addressNomenclature: '0',
    x: '23.233085',
    y: '43.520324',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '11185',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВИРОВСКО',
    nameEn: 'VIROVSKO',
    municipality: 'ВРАЦА',
    municipalityEn: 'VRATSA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3057',
    addressNomenclature: '0',
    x: '23.794129',
    y: '43.298314',
    servingDays: '1010100',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '11199',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВИС',
    nameEn: 'VIS',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6591',
    addressNomenclature: '0',
    x: '25.855506',
    y: '41.44628',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '11209',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВИСКЯР',
    nameEn: 'VISKYAR',
    municipality: 'ПЕРНИК',
    municipalityEn: 'PERNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2358',
    addressNomenclature: '0',
    x: '22.964596',
    y: '42.714043',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '11212',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВИСОК',
    nameEn: 'VISOK',
    municipality: 'ОМУРТАГ',
    municipalityEn: 'OMURTAG',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7955',
    addressNomenclature: '0',
    x: '26.484469',
    y: '43.058642',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '11226',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВИСОКА',
    nameEn: 'VISOKA',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6744',
    addressNomenclature: '0',
    x: '25.234827',
    y: '41.719558',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '11232',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВИСОКА МОГИЛА',
    nameEn: 'VISOKA MOGILA',
    municipality: 'БОБОШЕВО',
    municipalityEn: 'BOBOSHEVO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2665',
    addressNomenclature: '0',
    x: '23.023239',
    y: '42.192369',
    servingDays: '0101000',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '11243',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВИСОКА ПОЛЯНА',
    nameEn: 'VISOKA POLYANA',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6678',
    addressNomenclature: '0',
    x: '25.505765',
    y: '41.671209',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '11257',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВИСОКА ПОЛЯНА',
    nameEn: 'VISOKA POLYANA',
    municipality: 'ХИТРИНО',
    municipalityEn: 'HITRINO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9765',
    addressNomenclature: '0',
    x: '26.775031',
    y: '43.468719',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '11260',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВИСОКИТЕ',
    nameEn: 'VISOKITE',
    municipality: 'МАДАН',
    municipalityEn: 'MADAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4921',
    addressNomenclature: '0',
    x: '24.95707',
    y: '41.528115',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '11274',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВИСОКОВЦИ',
    nameEn: 'VISOKOVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5098',
    addressNomenclature: '0',
    x: '25.957461',
    y: '42.850033',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '11288',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВИТАНОВЦИ',
    nameEn: 'VITANOVTSI',
    municipality: 'ПЕРНИК',
    municipalityEn: 'PERNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2354',
    addressNomenclature: '0',
    x: '22.981099',
    y: '42.64802',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '11301',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВИТИНА',
    nameEn: 'VITINA',
    municipality: 'РУДОЗЕМ',
    municipalityEn: 'RUDOZEM',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4975',
    addressNomenclature: '0',
    x: '24.766815',
    y: '41.430784',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '11315',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВИХРЕН',
    nameEn: 'VIHREN',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2800',
    addressNomenclature: '0',
    x: '23.331407',
    y: '41.614754',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '11329',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВИЧОВО',
    nameEn: 'VICHOVO',
    municipality: 'ГЕНЕРАЛ ТОШЕВО',
    municipalityEn: 'GENERAL TOSHEVO',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9563',
    addressNomenclature: '0',
    x: '28.31585',
    y: '43.740768',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '11332',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВИШАН',
    nameEn: 'VISHAN',
    municipality: 'ДРАГОМАН',
    municipalityEn: 'DRAGOMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2213',
    addressNomenclature: '0',
    x: '22.796587',
    y: '42.938153',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '11346',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВИШЕГРАД',
    nameEn: 'VISHEGRAD',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6646',
    addressNomenclature: '0',
    x: '25.408707',
    y: '41.611928',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '11358',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВИШЛЕНЕ',
    nameEn: 'VISHLENE',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2895',
    addressNomenclature: '0',
    x: '23.06338',
    y: '41.438109',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '11363',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВИШНА',
    nameEn: 'VISHNA',
    municipality: 'РУЕН',
    municipalityEn: 'RUEN',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8562',
    addressNomenclature: '0',
    x: '27.184741',
    y: '42.893161',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '11377',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВИШНЕВО',
    nameEn: 'VISHNEVO',
    municipality: 'БАНИТЕ',
    municipalityEn: 'BANITE',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4939',
    addressNomenclature: '0',
    x: '25.044206',
    y: '41.616735',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '11380',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВИШОВГРАД',
    nameEn: 'VISHOVGRAD',
    municipality: 'ПАВЛИКЕНИ',
    municipalityEn: 'PAVLIKENI',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5239',
    addressNomenclature: '0',
    x: '25.298936',
    y: '43.156827',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '11394',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЛАДАЯ',
    nameEn: 'VLADAYA',
    municipality: 'СТОЛИЧНА',
    municipalityEn: 'STOLICHNA',
    region: 'СОФИЯ (СТОЛИЦА)',
    regionEn: 'SOFIA (STOLITSA)',
    postCode: '1641',
    addressNomenclature: '2',
    x: '23.195551',
    y: '42.628359',
    servingDays: '1111100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '11404',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЛАДИМИР',
    nameEn: 'VLADIMIR',
    municipality: 'РАДОМИР',
    municipalityEn: 'RADOMIR',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2411',
    addressNomenclature: '0',
    x: '23.081195',
    y: '42.443588',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '11418',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЛАДИМИРОВО',
    nameEn: 'VLADIMIROVO',
    municipality: 'БОЙЧИНОВЦИ',
    municipalityEn: 'BOYCHINOVTSI',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3440',
    addressNomenclature: '0',
    x: '23.394907',
    y: '43.533964',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '11421',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЛАДИМИРОВО',
    nameEn: 'VLADIMIROVO',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9379',
    addressNomenclature: '0',
    x: '27.52791',
    y: '43.555427',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '11435',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЛАДИМИРОВО',
    nameEn: 'VLADIMIROVO',
    municipality: 'ТОПОЛОВГРАД',
    municipalityEn: 'TOPOLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6545',
    addressNomenclature: '0',
    x: '26.142516',
    y: '42.128564',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '11449',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЛАДИМИРОВЦИ',
    nameEn: 'VLADIMIROVTSI',
    municipality: 'САМУИЛ',
    municipalityEn: 'SAMUIL',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7440',
    addressNomenclature: '0',
    x: '26.864822',
    y: '43.626155',
    servingDays: '1010100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '11452',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЛАДИНЯ',
    nameEn: 'VLADINYA',
    municipality: 'ЛОВЕЧ',
    municipalityEn: 'LOVECH',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5563',
    addressNomenclature: '0',
    x: '24.796737',
    y: '43.291423',
    servingDays: '0101000',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '11466',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЛАДИСЛАВ',
    nameEn: 'VLADISLAV',
    municipality: 'СТРАЖИЦА',
    municipalityEn: 'STRAZHITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5179',
    addressNomenclature: '0',
    x: '25.994911',
    y: '43.190227',
    servingDays: '1010100',
    servingOfficeId: '46',
    servingHubOfficeId: '11',
  },
  {
    id: '11483',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЛАДИСЛАВЦИ',
    nameEn: 'VLADISLAVTSI',
    municipality: 'ДРАГОМАН',
    municipalityEn: 'DRAGOMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2215',
    addressNomenclature: '0',
    x: '22.841133',
    y: '42.920752',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '11497',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЛАДИЧЕНЦИ',
    nameEn: 'VLADICHENTSI',
    municipality: 'ДИМОВО',
    municipalityEn: 'DIMOVO',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3748',
    addressNomenclature: '0',
    x: '22.849417',
    y: '43.780951',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '11510',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЛАДО ТРИЧКОВ',
    nameEn: 'VLADO TRICHKOV',
    municipality: 'СВОГЕ',
    municipalityEn: 'SVOGE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2299',
    addressNomenclature: '0',
    x: '23.368355',
    y: '42.864464',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '11507',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЛАДОВЦИ',
    nameEn: 'VLADOVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5365',
    addressNomenclature: '0',
    x: '25.552995',
    y: '42.92025',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '11524',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЛАЙЧОВЦИ',
    nameEn: 'VLAYCHOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5344',
    addressNomenclature: '0',
    x: '25.255067',
    y: '42.948508',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '11541',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЛАСАТИЛИ',
    nameEn: 'VLASATILI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5367',
    addressNomenclature: '0',
    x: '25.587773',
    y: '42.806881',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '11555',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЛАСАТИЦА',
    nameEn: 'VLASATITSA',
    municipality: 'ВРАЦА',
    municipalityEn: 'VRATSA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3041',
    addressNomenclature: '0',
    x: '23.469495',
    y: '43.302533',
    servingDays: '0101000',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '11569',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЛАХИ',
    nameEn: 'VLAHI',
    municipality: 'КРЕСНА',
    municipalityEn: 'KRESNA',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2841',
    addressNomenclature: '0',
    x: '23.229206',
    y: '41.738256',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '11572',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЛАХОВО',
    nameEn: 'VLAHOVO',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4724',
    addressNomenclature: '0',
    x: '24.805788',
    y: '41.574515',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '11586',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЛАХОВЦИ',
    nameEn: 'VLAHOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5349',
    addressNomenclature: '0',
    x: '25.400843',
    y: '42.883111',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '11604',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОДАТА',
    nameEn: 'VODATA',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4713',
    addressNomenclature: '0',
    x: '24.631512',
    y: '41.648367',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '11613',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОДАЧ',
    nameEn: 'VODACH',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6709',
    addressNomenclature: '0',
    x: '25.297478',
    y: '41.759382',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '11627',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОДЕН',
    nameEn: 'VODEN',
    municipality: 'ПЪРВОМАЙ',
    municipalityEn: 'PARVOMAY',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4289',
    addressNomenclature: '0',
    x: '25.296519',
    y: '41.965644',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '11644',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОДЕН',
    nameEn: 'VODEN',
    municipality: 'ДИМИТРОВГРАД',
    municipalityEn: 'DIMITROVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6412',
    addressNomenclature: '0',
    x: '25.657157',
    y: '42.010266',
    servingDays: '0101000',
    servingOfficeId: '42',
    servingHubOfficeId: '5',
  },
  {
    id: '11658',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОДЕН',
    nameEn: 'VODEN',
    municipality: 'БОЛЯРОВО',
    municipalityEn: 'BOLYAROVO',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8750',
    addressNomenclature: '0',
    x: '26.898248',
    y: '42.073515',
    servingDays: '1010100',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '11661',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОДЕНИЧАНЕ',
    nameEn: 'VODENICHANE',
    municipality: 'СТРАЛДЖА',
    municipalityEn: 'STRALDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8693',
    addressNomenclature: '0',
    x: '26.693273',
    y: '42.545446',
    servingDays: '0101000',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '11675',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОДЕНИЧАРОВО',
    nameEn: 'VODENICHAROVO',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6051',
    addressNomenclature: '0',
    x: '25.498059',
    y: '42.239036',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '11689',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОДЕНИЧАРСКО',
    nameEn: 'VODENICHARSKO',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6852',
    addressNomenclature: '0',
    x: '25.255598',
    y: '41.493286',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '11692',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОДЕНЦИ',
    nameEn: 'VODENTSI',
    municipality: 'СТАМБОЛОВО',
    municipalityEn: 'STAMBOLOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6382',
    addressNomenclature: '0',
    x: '25.686062',
    y: '41.683215',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '11702',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОДИЦА',
    nameEn: 'VODITSA',
    municipality: 'АКСАКОВО',
    municipalityEn: 'AKSAKOVO',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9141',
    addressNomenclature: '0',
    x: '27.734864',
    y: '43.369201',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '11716',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОДИЦА',
    nameEn: 'VODITSA',
    municipality: 'ПОПОВО',
    municipalityEn: 'POPOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7851',
    addressNomenclature: '0',
    x: '26.052681',
    y: '43.355387',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '11720',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОДНА',
    nameEn: 'VODNA',
    municipality: 'ГРАМАДА',
    municipalityEn: 'GRAMADA',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3831',
    addressNomenclature: '0',
    x: '22.703411',
    y: '43.852164',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '11733',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОДНИ ПАД',
    nameEn: 'VODNI PAD',
    municipality: 'ДЕВИН',
    municipalityEn: 'DEVIN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4826',
    addressNomenclature: '0',
    x: '24.380281',
    y: '41.551914',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '11750',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОДНО',
    nameEn: 'VODNO',
    municipality: 'СТРАЖИЦА',
    municipalityEn: 'STRAZHITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5166',
    addressNomenclature: '0',
    x: '26.042809',
    y: '43.108629',
    servingDays: '1010100',
    servingOfficeId: '46',
    servingHubOfficeId: '11',
  },
  {
    id: '11764',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОДНО',
    nameEn: 'VODNO',
    municipality: 'ДУЛОВО',
    municipalityEn: 'DULOVO',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7657',
    addressNomenclature: '0',
    x: '27.14881',
    y: '43.858893',
    servingDays: '1010100',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '11778',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОДНЯНЦИ',
    nameEn: 'VODNYANTSI',
    municipality: 'ДИМОВО',
    municipalityEn: 'DIMOVO',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3924',
    addressNomenclature: '0',
    x: '22.859382',
    y: '43.700047',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '11781',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОДНЯНЦИ',
    nameEn: 'VODNYANTSI',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9435',
    addressNomenclature: '0',
    x: '27.548971',
    y: '43.746546',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '11795',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОДОЛЕЙ',
    nameEn: 'VODOLEY',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5061',
    addressNomenclature: '0',
    x: '25.511126',
    y: '43.215553',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '11805',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОЖДОВО',
    nameEn: 'VOZHDOVO',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6716',
    addressNomenclature: '0',
    x: '25.371383',
    y: '41.760727',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '11819',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОЙВОДА',
    nameEn: 'VOYVODA',
    municipality: 'НОВИ ПАЗАР',
    municipalityEn: 'NOVI PAZAR',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9958',
    addressNomenclature: '0',
    x: '27.125884',
    y: '43.460083',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '11822',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОЙВОДЕНЕЦ',
    nameEn: 'VOYVODENETS',
    municipality: 'СТАМБОЛОВО',
    municipalityEn: 'STAMBOLOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6381',
    addressNomenclature: '0',
    x: '25.736339',
    y: '41.656477',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '11836',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОЙВОДИНО',
    nameEn: 'VOYVODINO',
    municipality: 'ВЪЛЧИ ДОЛ',
    municipalityEn: 'VALCHI DOL',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9292',
    addressNomenclature: '0',
    x: '27.619977',
    y: '43.404247',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '11845',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОЙВОДИНОВО',
    nameEn: 'VOYVODINOVO',
    municipality: 'МАРИЦА',
    municipalityEn: 'MARITSA',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4135',
    addressNomenclature: '0',
    x: '24.795974',
    y: '42.200248',
    servingDays: '1111100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '11853',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОЙВОДОВО',
    nameEn: 'VOYVODOVO',
    municipality: 'МИЗИЯ',
    municipalityEn: 'MIZIA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3332',
    addressNomenclature: '0',
    x: '23.8429',
    y: '43.649654',
    servingDays: '1010100',
    servingOfficeId: '64',
    servingHubOfficeId: '20',
  },
  {
    id: '11867',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОЙВОДОВО',
    nameEn: 'VOYVODOVO',
    municipality: 'ХАСКОВО',
    municipalityEn: 'HASKOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6353',
    addressNomenclature: '0',
    x: '25.545913',
    y: '41.855975',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '11870',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОЙКОВА ЛЪКА',
    nameEn: 'VOYKOVA LAKA',
    municipality: 'РУДОЗЕМ',
    municipalityEn: 'RUDOZEM',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4967',
    addressNomenclature: '0',
    x: '24.833271',
    y: '41.477219',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '11884',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОЙНЕГОВЦИ',
    nameEn: 'VOYNEGOVTSI',
    municipality: 'СТОЛИЧНА',
    municipalityEn: 'STOLICHNA',
    region: 'СОФИЯ (СТОЛИЦА)',
    regionEn: 'SOFIA (STOLITSA)',
    postCode: '1223',
    addressNomenclature: '2',
    x: '23.422222',
    y: '42.799904',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '11898',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОЙНЕЖА',
    nameEn: 'VOYNEZHA',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5045',
    addressNomenclature: '0',
    x: '25.674903',
    y: '42.882159',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '11908',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОЙНИКА',
    nameEn: 'VOYNIKA',
    municipality: 'СТРАЛДЖА',
    municipalityEn: 'STRALDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8661',
    addressNomenclature: '0',
    x: '26.821452',
    y: '42.384169',
    servingDays: '0101000',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '11911',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОЙНИКОВО',
    nameEn: 'VOYNIKOVO',
    municipality: 'ТЕРВЕЛ',
    municipalityEn: 'TERVEL',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9473',
    addressNomenclature: '0',
    x: '27.367699',
    y: '43.702591',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '11925',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОЙНИЦА',
    nameEn: 'VOYNITSA',
    municipality: 'ВИДИН',
    municipalityEn: 'VIDIN',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3734',
    addressNomenclature: '0',
    x: '22.698279',
    y: '43.936683',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '11939',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОЙНИЦИ',
    nameEn: 'VOYNITSI',
    municipality: 'МОНТАНА',
    municipalityEn: 'MONTANA',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3481',
    addressNomenclature: '0',
    x: '23.174448',
    y: '43.449877',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '11956',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОЙНИЦИТЕ',
    nameEn: 'VOYNITSITE',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5350',
    addressNomenclature: '0',
    x: '25.472766',
    y: '42.903676',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '11960',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОЙНИШКА',
    nameEn: 'VOYNISHKA',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5458',
    addressNomenclature: '0',
    x: '25.032138',
    y: '42.854895',
    servingDays: '1010100',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '11987',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОЙНОВО',
    nameEn: 'VOYNOVO',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6698',
    addressNomenclature: '0',
    x: '25.177886',
    y: '41.749797',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '11990',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОЙНОВО',
    nameEn: 'VOYNOVO',
    municipality: 'КАЙНАРДЖА',
    municipalityEn: 'KAYNARDZHA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7551',
    addressNomenclature: '0',
    x: '27.44178',
    y: '43.974906',
    servingDays: '0101000',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '12005',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОЙНЯГОВО',
    nameEn: 'VOYNYAGOVO',
    municipality: 'КАРЛОВО',
    municipalityEn: 'KARLOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4347',
    addressNomenclature: '0',
    x: '24.755277',
    y: '42.573602',
    servingDays: '1010100',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '12019',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОЙСИЛ',
    nameEn: 'VOYSIL',
    municipality: 'МАРИЦА',
    municipalityEn: 'MARITSA',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4203',
    addressNomenclature: '0',
    x: '24.628291',
    y: '42.215942',
    servingDays: '1111100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '12022',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОКИЛ',
    nameEn: 'VOKIL',
    municipality: 'ДУЛОВО',
    municipalityEn: 'DULOVO',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7670',
    addressNomenclature: '0',
    x: '27.063646',
    y: '43.775708',
    servingDays: '1010100',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '12036',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОЛНО',
    nameEn: 'VOLNO',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2886',
    addressNomenclature: '0',
    x: '23.027233',
    y: '41.478523',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '12042',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОЛОВАРОВО',
    nameEn: 'VOLOVAROVO',
    municipality: 'ЧИРПАН',
    municipalityEn: 'CHIRPAN',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6231',
    addressNomenclature: '0',
    x: '25.400617',
    y: '42.208461',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '12053',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОЛОВАРЦИ',
    nameEn: 'VOLOVARTSI',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6633',
    addressNomenclature: '0',
    x: '25.310707',
    y: '41.578107',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '12067',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОЛОВО',
    nameEn: 'VOLOVO',
    municipality: 'БОРОВО',
    municipalityEn: 'BOROVO',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7175',
    addressNomenclature: '0',
    x: '25.796537',
    y: '43.525836',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '12084',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОЛУЯК',
    nameEn: 'VOLUYAK',
    municipality: 'СТОЛИЧНА',
    municipalityEn: 'STOLICHNA',
    region: 'СОФИЯ (СТОЛИЦА)',
    regionEn: 'SOFIA (STOLITSA)',
    postCode: '1346',
    addressNomenclature: '2',
    x: '23.242846',
    y: '42.773544',
    servingDays: '1111100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '12098',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВОНЕЩА ВОДА',
    nameEn: 'VONESHTA VODA',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5035',
    addressNomenclature: '0',
    x: '25.644813',
    y: '42.87821',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '12108',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВРАБЕВО',
    nameEn: 'VRABEVO',
    municipality: 'ТРОЯН',
    municipalityEn: 'TROYAN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5660',
    addressNomenclature: '0',
    x: '24.813377',
    y: '42.993124',
    servingDays: '1111100',
    servingOfficeId: '44',
    servingHubOfficeId: '11',
  },
  {
    id: '12111',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВРАБЦИТЕ',
    nameEn: 'VRABTSITE',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5307',
    addressNomenclature: '0',
    x: '25.208133',
    y: '42.862902',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '12139',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВРАБЧА',
    nameEn: 'VRABCHA',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2470',
    addressNomenclature: '0',
    x: '22.707211',
    y: '42.867587',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '12142',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВРАКУПОВИЦА',
    nameEn: 'VRAKUPOVITSA',
    municipality: 'СТРУМЯНИ',
    municipalityEn: 'STRUMYANI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2834',
    addressNomenclature: '0',
    x: '23.090158',
    y: '41.662116',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '12989',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВРАНЕНЦИ',
    nameEn: 'VRANENTSI',
    municipality: 'ВЕЛИНГРАД',
    municipalityEn: 'VELINGRAD',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4649',
    addressNomenclature: '0',
    x: '23.81581',
    y: '41.994669',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '12156',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВРАНИ КОН',
    nameEn: 'VRANI KON',
    municipality: 'ОМУРТАГ',
    municipalityEn: 'OMURTAG',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7940',
    addressNomenclature: '0',
    x: '26.559695',
    y: '43.023178',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '12168',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВРАНИЛОВЦИ',
    nameEn: 'VRANILOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5347',
    addressNomenclature: '0',
    x: '25.219531',
    y: '42.913306',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '12173',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВРАНИНО',
    nameEn: 'VRANINO',
    municipality: 'КАВАРНА',
    municipalityEn: 'KAVARNA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9663',
    addressNomenclature: '0',
    x: '28.307104',
    y: '43.526918',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '12187',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВРАНИНЦИ',
    nameEn: 'VRANINTSI',
    municipality: 'МАДАН',
    municipalityEn: 'MADAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4906',
    addressNomenclature: '0',
    x: '24.952174',
    y: '41.513655',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '12190',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВРАНСКО',
    nameEn: 'VRANSKO',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6912',
    addressNomenclature: '0',
    x: '25.641123',
    y: '41.485909',
    servingDays: '1111100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '12200',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВРАНЯ',
    nameEn: 'VRANYA',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2830',
    addressNomenclature: '0',
    x: '23.401826',
    y: '41.449148',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '12228',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВРАНЯ СТЕНА',
    nameEn: 'VRANYA STENA',
    municipality: 'ЗЕМЕН',
    municipalityEn: 'ZEMEN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2445',
    addressNomenclature: '0',
    x: '22.731292',
    y: '42.535414',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '12214',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВРАНЯК',
    nameEn: 'VRANYAK',
    municipality: 'БЯЛА СЛАТИНА',
    municipalityEn: 'BYALA SLATINA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3248',
    addressNomenclature: '0',
    x: '23.933878',
    y: '43.372582',
    servingDays: '0101000',
    servingOfficeId: '59',
    servingHubOfficeId: '20',
  },
  {
    id: '12992',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВРАТА',
    nameEn: 'VRATA',
    municipality: 'АСЕНОВГРАД',
    municipalityEn: 'ASENOVGRAD',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4234',
    addressNomenclature: '0',
    x: '24.966584',
    y: '41.86017',
    servingDays: '0101000',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '12231',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВРАТАРИТЕ',
    nameEn: 'VRATARITE',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9382',
    addressNomenclature: '0',
    x: '27.543202',
    y: '43.517165',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '12245',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВРАТИЦА',
    nameEn: 'VRATITSA',
    municipality: 'КАМЕНО',
    municipalityEn: 'KAMENO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8109',
    addressNomenclature: '0',
    x: '27.161125',
    y: '42.589508',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '48355',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВРАТЦА',
    nameEn: 'VRATTSA',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2575',
    addressNomenclature: '0',
    x: '22.590419',
    y: '42.263346',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '12259',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ВРАЦА',
    nameEn: 'VRATSA',
    municipality: 'ВРАЦА',
    municipalityEn: 'VRATSA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3000',
    addressNomenclature: '1',
    x: '23.546884',
    y: '43.207723',
    servingDays: '1111110',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '12262',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВРАЧАНЦИ',
    nameEn: 'VRACHANTSI',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9345',
    addressNomenclature: '0',
    x: '27.780489',
    y: '43.614378',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '12283',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВРАЧЕШ',
    nameEn: 'VRACHESH',
    municipality: 'БОТЕВГРАД',
    municipalityEn: 'BOTEVGRAD',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2151',
    addressNomenclature: '0',
    x: '23.75217',
    y: '42.892406',
    servingDays: '1111100',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '12293',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВРЕЛО',
    nameEn: 'VRELO',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6834',
    addressNomenclature: '0',
    x: '25.494591',
    y: '41.521077',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '12303',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВРЕСОВО',
    nameEn: 'VRESOVO',
    municipality: 'РУЕН',
    municipalityEn: 'RUEN',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8560',
    addressNomenclature: '0',
    x: '27.198526',
    y: '42.812431',
    servingDays: '1111100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '12317',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВРЪВ',
    nameEn: 'VRAV',
    municipality: 'БРЕГОВО',
    municipalityEn: 'BREGOVO',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3779',
    addressNomenclature: '0',
    x: '22.735892',
    y: '44.193534',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '12320',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВРЪДЛОВЦИ',
    nameEn: 'VRADLOVTSI',
    municipality: 'ГОДЕЧ',
    municipalityEn: 'GODECH',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2254',
    addressNomenclature: '0',
    x: '22.931011',
    y: '43.038616',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '12334',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВСЕМИРЦИ',
    nameEn: 'VSEMIRTSI',
    municipality: 'ВЕЛИНГРАД',
    municipalityEn: 'VELINGRAD',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4622',
    addressNomenclature: '0',
    x: '23.867665',
    y: '42.030375',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '12348',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВУКАН',
    nameEn: 'VUKAN',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2485',
    addressNomenclature: '0',
    x: '22.610829',
    y: '42.773735',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '12351',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВУКОВО',
    nameEn: 'VUKOVO',
    municipality: 'БОБОШЕВО',
    municipalityEn: 'BOBOSHEVO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2661',
    addressNomenclature: '0',
    x: '22.969937',
    y: '42.195721',
    servingDays: '1111110',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '12365',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪБЕЛ',
    nameEn: 'VABEL',
    municipality: 'НИКОПОЛ',
    municipalityEn: 'NIKOPOL',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5953',
    addressNomenclature: '0',
    x: '24.90487',
    y: '43.65113',
    servingDays: '0101000',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '12382',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪГЛАРОВО',
    nameEn: 'VAGLAROVO',
    municipality: 'ХАСКОВО',
    municipalityEn: 'HASKOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6313',
    addressNomenclature: '0',
    x: '25.448218',
    y: '41.906802',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '12396',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪГЛЕВЦИ',
    nameEn: 'VAGLEVTSI',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5041',
    addressNomenclature: '0',
    x: '25.649418',
    y: '42.909816',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '12406',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪГЛЕН',
    nameEn: 'VAGLEN',
    municipality: 'АКСАКОВО',
    municipalityEn: 'AKSAKOVO',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9157',
    addressNomenclature: '0',
    x: '27.850333',
    y: '43.29445',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '12414',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪГЛЕН',
    nameEn: 'VAGLEN',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8851',
    addressNomenclature: '0',
    x: '26.225864',
    y: '42.751616',
    servingDays: '0101000',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '12423',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪЗЕЛ',
    nameEn: 'VAZEL',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6710',
    addressNomenclature: '0',
    x: '25.353658',
    y: '41.706332',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '12440',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪЛКАН',
    nameEn: 'VALKAN',
    municipality: 'ГЛАВИНИЦА',
    municipalityEn: 'GLAVINITSA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7643',
    addressNomenclature: '0',
    x: '26.891014',
    y: '43.832468',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '12454',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪЛКОВ ДОЛ',
    nameEn: 'VALKOV DOL',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5349',
    addressNomenclature: '0',
    x: '25.401857',
    y: '42.893817',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '12468',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪЛКОВИЧ',
    nameEn: 'VALKOVICH',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6850',
    addressNomenclature: '0',
    x: '25.318649',
    y: '41.520445',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '12471',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪЛКОВО',
    nameEn: 'VALKOVO',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2807',
    addressNomenclature: '0',
    x: '23.222052',
    y: '41.579609',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '12485',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪЛКОВЦИ',
    nameEn: 'VALKOVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5365',
    addressNomenclature: '0',
    x: '25.550993',
    y: '42.903197',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '12499',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪЛКОСЕЛ',
    nameEn: 'VALKOSEL',
    municipality: 'САТОВЧА',
    municipalityEn: 'SATOVCHA',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2930',
    addressNomenclature: '0',
    x: '23.985103',
    y: '41.528181',
    servingDays: '1111100',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '12509',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪЛНАРИ',
    nameEn: 'VALNARI',
    municipality: 'НИКОЛА КОЗЛЕВО',
    municipalityEn: 'NIKOLA KOZLEVO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9963',
    addressNomenclature: '0',
    x: '27.218262',
    y: '43.599179',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '12530',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪЛЧА ПОЛЯНА',
    nameEn: 'VALCHA POLYANA',
    municipality: 'ЕЛХОВО',
    municipalityEn: 'ELHOVO',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8748',
    addressNomenclature: '0',
    x: '26.67779',
    y: '42.008889',
    servingDays: '1010100',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '12512',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪЛЧАН',
    nameEn: 'VALCHAN',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4775',
    addressNomenclature: '0',
    x: '24.773782',
    y: '41.48227',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '12526',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪЛЧАН ДОЛ',
    nameEn: 'VALCHAN DOL',
    municipality: 'БАНИТЕ',
    municipalityEn: 'BANITE',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4947',
    addressNomenclature: '0',
    x: '25.001173',
    y: '41.697212',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '13055',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪЛЧАНКА',
    nameEn: 'VALCHANKA',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6893',
    addressNomenclature: '0',
    x: '25.441279',
    y: '41.351144',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '72059',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪЛЧАНОВО',
    nameEn: 'VALCHANOVO',
    municipality: 'СРЕДЕЦ',
    municipalityEn: 'SREDETS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8341',
    addressNomenclature: '0',
    x: '27.068123',
    y: '42.262525',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '12560',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪЛЧЕ ПОЛЕ',
    nameEn: 'VALCHE POLE',
    municipality: 'ЛЮБИМЕЦ',
    municipalityEn: 'LYUBIMETS',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6587',
    addressNomenclature: '0',
    x: '26.011541',
    y: '41.689455',
    servingDays: '1010100',
    servingOfficeId: '48',
    servingHubOfficeId: '5',
  },
  {
    id: '12543',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ВЪЛЧЕДРЪМ',
    nameEn: 'VALCHEDRAM',
    municipality: 'ВЪЛЧЕДРЪМ',
    municipalityEn: 'VALCHEDRAM',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3650',
    addressNomenclature: '2',
    x: '23.444752',
    y: '43.696103',
    servingDays: '1111100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '12557',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪЛЧЕК',
    nameEn: 'VALCHEK',
    municipality: 'МАКРЕШ',
    municipalityEn: 'MAKRESH',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3851',
    addressNomenclature: '0',
    x: '22.713999',
    y: '43.786379',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '12574',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ВЪЛЧИ ДОЛ',
    nameEn: 'VALCHI DOL',
    municipality: 'ВЪЛЧИ ДОЛ',
    municipalityEn: 'VALCHI DOL',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9280',
    addressNomenclature: '2',
    x: '27.552608',
    y: '43.403891',
    servingDays: '1111100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '12588',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪЛЧИ ИЗВОР',
    nameEn: 'VALCHI IZVOR',
    municipality: 'БОЛЯРОВО',
    municipalityEn: 'BOLYAROVO',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8723',
    addressNomenclature: '0',
    x: '26.91507',
    y: '42.143989',
    servingDays: '0101000',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '12591',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪЛЧИН',
    nameEn: 'VALCHIN',
    municipality: 'СУНГУРЛАРЕ',
    municipalityEn: 'SUNGURLARE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8443',
    addressNomenclature: '0',
    x: '26.896365',
    y: '42.728335',
    servingDays: '0101000',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '12601',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪЛЧИТРЪН',
    nameEn: 'VALCHITRAN',
    municipality: 'ПОРДИМ',
    municipalityEn: 'PORDIM',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5890',
    addressNomenclature: '0',
    x: '24.87021',
    y: '43.328295',
    servingDays: '0101000',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '12615',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪЛЧОВЦИ',
    nameEn: 'VALCHOVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5086',
    addressNomenclature: '0',
    x: '25.822986',
    y: '42.942761',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '12629',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪЛЧОВЦИ',
    nameEn: 'VALCHOVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5081',
    addressNomenclature: '0',
    x: '26.118868',
    y: '42.917462',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '12632',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪРБА',
    nameEn: 'VARBA',
    municipality: 'БЕЛОГРАДЧИК',
    municipalityEn: 'BELOGRADCHIK',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3939',
    addressNomenclature: '0',
    x: '22.625978',
    y: '43.689968',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '12655',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪРБА',
    nameEn: 'VARBA',
    municipality: 'МАДАН',
    municipalityEn: 'MADAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4920',
    addressNomenclature: '0',
    x: '24.892855',
    y: '41.479184',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '12663',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪРБАК',
    nameEn: 'VARBAK',
    municipality: 'ХИТРИНО',
    municipalityEn: 'HITRINO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9781',
    addressNomenclature: '0',
    x: '26.932597',
    y: '43.388106',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '120',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪРБЕН',
    nameEn: 'VARBEN',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6873',
    addressNomenclature: '0',
    x: '25.367395',
    y: '41.405943',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '12680',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪРБЕН',
    nameEn: 'VARBEN',
    municipality: 'БРЕЗОВО',
    municipalityEn: 'BREZOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4145',
    addressNomenclature: '0',
    x: '24.966774',
    y: '42.419898',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '12694',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪРБЕНЦИ',
    nameEn: 'VARBENTSI',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6745',
    addressNomenclature: '0',
    x: '25.275249',
    y: '41.721037',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '12704',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪРБЕШНИЦА',
    nameEn: 'VARBESHNITSA',
    municipality: 'МЕЗДРА',
    municipalityEn: 'MEZDRA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3147',
    addressNomenclature: '0',
    x: '23.681051',
    y: '43.202999',
    servingDays: '1010100',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '12718',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪРБИНА',
    nameEn: 'VARBINA',
    municipality: 'МАДАН',
    municipalityEn: 'MADAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4921',
    addressNomenclature: '0',
    x: '24.973518',
    y: '41.562441',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '12721',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪРБИНО',
    nameEn: 'VARBINO',
    municipality: 'ДУЛОВО',
    municipalityEn: 'DULOVO',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7674',
    addressNomenclature: '0',
    x: '27.250956',
    y: '43.792835',
    servingDays: '0101000',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '12735',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪРБИЦА',
    nameEn: 'VARBITSA',
    municipality: 'ГОРНА ОРЯХОВИЦА',
    municipalityEn: 'GORNA ORYAHOVITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5128',
    addressNomenclature: '0',
    x: '25.829002',
    y: '43.180868',
    servingDays: '1010100',
    servingOfficeId: '46',
    servingHubOfficeId: '11',
  },
  {
    id: '12749',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪРБИЦА',
    nameEn: 'VARBITSA',
    municipality: 'ВРАЦА',
    municipalityEn: 'VRATSA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3056',
    addressNomenclature: '0',
    x: '23.843843',
    y: '43.289486',
    servingDays: '1010100',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '12752',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪРБИЦА',
    nameEn: 'VARBITSA',
    municipality: 'ПЛЕВЕН',
    municipalityEn: 'PLEVEN',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5845',
    addressNomenclature: '0',
    x: '24.697703',
    y: '43.47029',
    servingDays: '0101000',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '12766',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ВЪРБИЦА',
    nameEn: 'VARBITSA',
    municipality: 'ВЪРБИЦА',
    municipalityEn: 'VARBITSA',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9870',
    addressNomenclature: '2',
    x: '26.670658',
    y: '42.993225',
    servingDays: '1111100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '76087',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪРБИЦА',
    nameEn: 'VARBITSA',
    municipality: 'ДИМИТРОВГРАД',
    municipalityEn: 'DIMITROVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6445',
    addressNomenclature: '0',
    x: '25.343021',
    y: '42.050745',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '12770',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪРБНИЦА',
    nameEn: 'VARBNITSA',
    municipality: 'ГОДЕЧ',
    municipalityEn: 'GODECH',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2248',
    addressNomenclature: '0',
    x: '23.028541',
    y: '42.982441',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '12783',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪРБОВКА',
    nameEn: 'VARBOVKA',
    municipality: 'ПАВЛИКЕНИ',
    municipalityEn: 'PAVLIKENI',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5233',
    addressNomenclature: '0',
    x: '25.197596',
    y: '43.238371',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '12797',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪРБОВО',
    nameEn: 'VARBOVO',
    municipality: 'ЧУПРЕНЕ',
    municipalityEn: 'CHUPRENE',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3951',
    addressNomenclature: '0',
    x: '22.650477',
    y: '43.543108',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '12807',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪРБОВО',
    nameEn: 'VARBOVO',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4710',
    addressNomenclature: '0',
    x: '24.565441',
    y: '41.698261',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '12810',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪРБОВО',
    nameEn: 'VARBOVO',
    municipality: 'ХАРМАНЛИ',
    municipalityEn: 'HARMANLI',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6488',
    addressNomenclature: '0',
    x: '25.857524',
    y: '41.762767',
    servingDays: '0101000',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '12824',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪРБОВЧЕЦ',
    nameEn: 'VARBOVCHETS',
    municipality: 'ДИМОВО',
    municipalityEn: 'DIMOVO',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3948',
    addressNomenclature: '0',
    x: '22.764332',
    y: '43.619241',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '12838',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪРБЯНЕ',
    nameEn: 'VARBYANE',
    municipality: 'КАСПИЧАН',
    municipalityEn: 'KASPICHAN',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9943',
    addressNomenclature: '0',
    x: '27.087777',
    y: '43.396663',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '12841',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪРГОВ ДОЛ',
    nameEn: 'VARGOV DOL',
    municipality: 'МАДАН',
    municipalityEn: 'MADAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4907',
    addressNomenclature: '0',
    x: '24.966521',
    y: '41.495492',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '12855',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪРЗИЛКОВЦИ',
    nameEn: 'VARZILKOVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5081',
    addressNomenclature: '0',
    x: '25.809483',
    y: '42.92706',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '12869',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪРЗУЛИЦА',
    nameEn: 'VARZULITSA',
    municipality: 'ПОЛСКИ ТРЪМБЕШ',
    municipalityEn: 'POLSKI TRAMBESH',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5191',
    addressNomenclature: '0',
    x: '25.460082',
    y: '43.429636',
    servingDays: '1010100',
    servingOfficeId: '250',
    servingHubOfficeId: '11',
  },
  {
    id: '12886',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪРЛИ ДОЛ',
    nameEn: 'VARLI DOL',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6873',
    addressNomenclature: '0',
    x: '25.320411',
    y: '41.351963',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '12896',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪРЛИ ДОЛ',
    nameEn: 'VARLI DOL',
    municipality: 'НЕДЕЛИНО',
    municipalityEn: 'NEDELINO',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4989',
    addressNomenclature: '0',
    x: '25.022356',
    y: '41.464998',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '12913',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪРЛИНКА',
    nameEn: 'VARLINKA',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5050',
    addressNomenclature: '0',
    x: '25.636193',
    y: '42.963496',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '12927',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪРЛИНО',
    nameEn: 'VARLINO',
    municipality: 'НЕДЕЛИНО',
    municipalityEn: 'NEDELINO',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4983',
    addressNomenclature: '0',
    x: '25.161611',
    y: '41.469622',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '12958',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪРТОП',
    nameEn: 'VARTOP',
    municipality: 'ВИДИН',
    municipalityEn: 'VIDIN',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3752',
    addressNomenclature: '0',
    x: '22.790307',
    y: '43.802911',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '86030',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪРХАРИ',
    nameEn: 'VARHARI',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6814',
    addressNomenclature: '0',
    x: '25.379389',
    y: '41.541123',
    servingDays: '1111110',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '12961',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ВЪРШЕЦ',
    nameEn: 'VARSHETS',
    municipality: 'ВЪРШЕЦ',
    municipalityEn: 'VARSHETS',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3540',
    addressNomenclature: '2',
    x: '23.284568',
    y: '43.192173',
    servingDays: '1111100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '12975',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ВЪРШИЛО',
    nameEn: 'VARSHILO',
    municipality: 'СОЗОПОЛ',
    municipalityEn: 'SOZOPOL',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8157',
    addressNomenclature: '0',
    x: '27.378447',
    y: '42.257999',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '14012',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАБАРЕ',
    nameEn: 'GABARE',
    municipality: 'БЯЛА СЛАТИНА',
    municipalityEn: 'BYALA SLATINA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3265',
    addressNomenclature: '0',
    x: '23.914645',
    y: '43.312032',
    servingDays: '0101000',
    servingOfficeId: '59',
    servingHubOfficeId: '20',
  },
  {
    id: '14026',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАБАРЕВО',
    nameEn: 'GABAREVO',
    municipality: 'ПАВЕЛ БАНЯ',
    municipalityEn: 'PAVEL BANYA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6162',
    addressNomenclature: '0',
    x: '25.164406',
    y: '42.624202',
    servingDays: '1111100',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '14034',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАБЕР',
    nameEn: 'GABER',
    municipality: 'ДРАГОМАН',
    municipalityEn: 'DRAGOMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2215',
    addressNomenclature: '0',
    x: '22.860693',
    y: '42.896813',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '14043',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАБЕР',
    nameEn: 'GABER',
    municipality: 'КРУШАРИ',
    municipalityEn: 'KRUSHARI',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9419',
    addressNomenclature: '0',
    x: '27.678378',
    y: '43.917989',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '14057',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАБЕРОВО',
    nameEn: 'GABEROVO',
    municipality: 'ПОМОРИЕ',
    municipalityEn: 'POMORIE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8212',
    addressNomenclature: '0',
    x: '27.525911',
    y: '42.697015',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '14060',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАБЕРОВО',
    nameEn: 'GABEROVO',
    municipality: 'МАДЖАРОВО',
    municipalityEn: 'MADZHAROVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6482',
    addressNomenclature: '0',
    x: '25.901066',
    y: '41.625171',
    servingDays: '0101000',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '14074',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАБРА',
    nameEn: 'GABRA',
    municipality: 'ЕЛИН ПЕЛИН',
    municipalityEn: 'ELIN PELIN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2112',
    addressNomenclature: '0',
    x: '23.613646',
    y: '42.533737',
    servingDays: '1010100',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '14088',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАБРАКА',
    nameEn: 'GABRAKA',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5097',
    addressNomenclature: '0',
    x: '25.867328',
    y: '42.834553',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '14091',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАБРЕНЕ',
    nameEn: 'GABRENE',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2898',
    addressNomenclature: '0',
    x: '22.973819',
    y: '41.367148',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '14101',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАБРЕШЕВЦИ',
    nameEn: 'GABRESHEVTSI',
    municipality: 'ТРЕКЛЯНО',
    municipalityEn: 'TREKLYANO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2557',
    addressNomenclature: '0',
    x: '22.639494',
    y: '42.522171',
    servingDays: '0101000',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '14115',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАБРИНА',
    nameEn: 'GABRINA',
    municipality: 'МАДАН',
    municipalityEn: 'MADAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4921',
    addressNomenclature: '0',
    x: '24.96483',
    y: '41.614922',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '14129',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАБРИЦА',
    nameEn: 'GABRITSA',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4795',
    addressNomenclature: '0',
    x: '24.766199',
    y: '41.543587',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '14132',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАБРИЦА',
    nameEn: 'GABRITSA',
    municipality: 'ВЕНЕЦ',
    municipalityEn: 'VENETS',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9779',
    addressNomenclature: '0',
    x: '26.973173',
    y: '43.507754',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '14146',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАБРОВДОЛ',
    nameEn: 'GABROVDOL',
    municipality: 'ЗЕМЕН',
    municipalityEn: 'ZEMEN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2392',
    addressNomenclature: '0',
    x: '22.792954',
    y: '42.643',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '14163',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАБРОВИЦА',
    nameEn: 'GABROVITSA',
    municipality: 'БЕЛОВО',
    municipalityEn: 'BELOVO',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4467',
    addressNomenclature: '0',
    x: '23.899597',
    y: '42.254175',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '14180',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАБРОВНИЦА',
    nameEn: 'GABROVNITSA',
    municipality: 'МОНТАНА',
    municipalityEn: 'MONTANA',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3453',
    addressNomenclature: '0',
    x: '23.264591',
    y: '43.534457',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '14194',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАБРОВНИЦА',
    nameEn: 'GABROVNITSA',
    municipality: 'СВОГЕ',
    municipalityEn: 'SVOGE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2277',
    addressNomenclature: '0',
    x: '23.473744',
    y: '43.065392',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '14204',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАБРОВО',
    nameEn: 'GABROVO',
    municipality: 'БЛАГОЕВГРАД',
    municipalityEn: 'BLAGOEVGRAD',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2747',
    addressNomenclature: '0',
    x: '22.944918',
    y: '41.898432',
    servingDays: '1111110',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '14218',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ГАБРОВО',
    nameEn: 'GABROVO',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5300',
    addressNomenclature: '1',
    x: '25.317299',
    y: '42.877109',
    servingDays: '1111110',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '14221',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАБРОВО',
    nameEn: 'GABROVO',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6691',
    addressNomenclature: '0',
    x: '25.273928',
    y: '41.796637',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '14235',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАБРОВЦИ',
    nameEn: 'GABROVTSI',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5051',
    addressNomenclature: '0',
    x: '25.597075',
    y: '42.945919',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '14249',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАБЪР',
    nameEn: 'GABAR',
    municipality: 'СОЗОПОЛ',
    municipalityEn: 'SOZOPOL',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8158',
    addressNomenclature: '0',
    x: '27.353748',
    y: '42.312147',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '14252',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАБЪРНИЦА',
    nameEn: 'GABARNITSA',
    municipality: 'ВЕТРИНО',
    municipalityEn: 'VETRINO',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9227',
    addressNomenclature: '0',
    x: '27.495162',
    y: '43.245825',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '14275',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАВРАИЛОВО',
    nameEn: 'GAVRAILOVO',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8854',
    addressNomenclature: '0',
    x: '26.180307',
    y: '42.642164',
    servingDays: '0101000',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '14283',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАВРИЛ ГЕНОВО',
    nameEn: 'GAVRIL GENOVO',
    municipality: 'ГЕОРГИ ДАМЯНОВО',
    municipalityEn: 'GEORGI DAMYANOVO',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3469',
    addressNomenclature: '0',
    x: '23.060309',
    y: '43.395246',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '14297',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАГАНИЦА',
    nameEn: 'GAGANITSA',
    municipality: 'БЕРКОВИЦА',
    municipalityEn: 'BERKOVITSA',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3525',
    addressNomenclature: '0',
    x: '23.105568',
    y: '43.32798',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '14307',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАГОВО',
    nameEn: 'GAGOVO',
    municipality: 'ПОПОВО',
    municipalityEn: 'POPOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7831',
    addressNomenclature: '0',
    x: '26.195251',
    y: '43.403946',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '14310',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАЙДАРИ',
    nameEn: 'GAYDARI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5367',
    addressNomenclature: '0',
    x: '25.589001',
    y: '42.800949',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '14324',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАЙКИНИ',
    nameEn: 'GAYKINI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5300',
    addressNomenclature: '0',
    x: '25.302891',
    y: '42.939547',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '14338',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАЙТАНЕВО',
    nameEn: 'GAYTANEVO',
    municipality: 'ГОРНА МАЛИНА',
    municipalityEn: 'GORNA MALINA',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2114',
    addressNomenclature: '0',
    x: '23.752554',
    y: '42.647262',
    servingDays: '0101000',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '14341',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАЙТАНИНОВО',
    nameEn: 'GAYTANINOVO',
    municipality: 'ХАДЖИДИМОВО',
    municipalityEn: 'HADZHIDIMOVO',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2924',
    addressNomenclature: '0',
    x: '23.712806',
    y: '41.452043',
    servingDays: '1010100',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '14355',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАЙТАНИТЕ',
    nameEn: 'GAYTANITE',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5345',
    addressNomenclature: '0',
    x: '25.286251',
    y: '42.910685',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '14369',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАЙТАНЦИ',
    nameEn: 'GAYTANTSI',
    municipality: 'ВИДИН',
    municipalityEn: 'VIDIN',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3753',
    addressNomenclature: '0',
    x: '22.81046',
    y: '43.832767',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '14386',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАЛАТА',
    nameEn: 'GALATA',
    municipality: 'ТЕТЕВЕН',
    municipalityEn: 'TETEVEN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5731',
    addressNomenclature: '0',
    x: '24.27781',
    y: '43.017582',
    servingDays: '1010100',
    servingOfficeId: '88',
    servingHubOfficeId: '2',
  },
  {
    id: '14390',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАЛАТИН',
    nameEn: 'GALATIN',
    municipality: 'КРИВОДОЛ',
    municipalityEn: 'KRIVODOL',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3071',
    addressNomenclature: '0',
    x: '23.533756',
    y: '43.410493',
    servingDays: '0101000',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '14406',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАЛИЧЕ',
    nameEn: 'GALICHE',
    municipality: 'БЯЛА СЛАТИНА',
    municipalityEn: 'BYALA SLATINA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3250',
    addressNomenclature: '0',
    x: '23.867896000000002',
    y: '43.558138',
    servingDays: '1010100',
    servingOfficeId: '59',
    servingHubOfficeId: '20',
  },
  {
    id: '14413',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАЛИЩЕ',
    nameEn: 'GALISHTE',
    municipality: 'МАДАН',
    municipalityEn: 'MADAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4934',
    addressNomenclature: '0',
    x: '25.014823',
    y: '41.500168',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '14427',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАЛОВО',
    nameEn: 'GALOVO',
    municipality: 'ОРЯХОВО',
    municipalityEn: 'ORYAHOVO',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3342',
    addressNomenclature: '0',
    x: '24.08098',
    y: '43.647305',
    servingDays: '1010100',
    servingOfficeId: '64',
    servingHubOfficeId: '20',
  },
  {
    id: '14430',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАНЕВ ДОЛ',
    nameEn: 'GANEV DOL',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5084',
    addressNomenclature: '0',
    x: '25.966747',
    y: '42.949606',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '14458',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАНЧОВЕЦ',
    nameEn: 'GANCHOVETS',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5397',
    addressNomenclature: '0',
    x: '25.518872',
    y: '42.988525',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '14461',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАРА БОВ',
    nameEn: 'GARA BOV',
    municipality: 'СВОГЕ',
    municipalityEn: 'SVOGE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2271',
    addressNomenclature: '0',
    x: '23.353577',
    y: '43.021915',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '18490',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАРА ЕЛИН ПЕЛИН',
    nameEn: 'GARA ELIN PELIN',
    municipality: 'ЕЛИН ПЕЛИН',
    municipalityEn: 'ELIN PELIN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2109',
    addressNomenclature: '0',
    x: '23.583623',
    y: '42.628626',
    servingDays: '1111110',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '14475',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАРА ЛАКАТНИК',
    nameEn: 'GARA LAKATNIK',
    municipality: 'СВОГЕ',
    municipalityEn: 'SVOGE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2272',
    addressNomenclature: '0',
    x: '23.388089',
    y: '43.083512',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '14489',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАРА ОРЕШЕЦ',
    nameEn: 'GARA ORESHETS',
    municipality: 'ДИМОВО',
    municipalityEn: 'DIMOVO',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3940',
    addressNomenclature: '0',
    x: '22.738938',
    y: '43.657463',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '14533',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАРВАН',
    nameEn: 'GARVAN',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5345',
    addressNomenclature: '0',
    x: '25.24312',
    y: '42.890321',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '14547',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАРВАН',
    nameEn: 'GARVAN',
    municipality: 'СИТОВО',
    municipalityEn: 'SITOVO',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7589',
    addressNomenclature: '0',
    x: '26.901227',
    y: '44.084376',
    servingDays: '1010100',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '14550',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАРВАНОВО',
    nameEn: 'GARVANOVO',
    municipality: 'ХАСКОВО',
    municipalityEn: 'HASKOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6349',
    addressNomenclature: '0',
    x: '25.441835',
    y: '41.968327',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '14578',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГАЩЕВЦИ',
    nameEn: 'GASHTEVTSI',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5041',
    addressNomenclature: '0',
    x: '25.669967',
    y: '42.914367',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '14581',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЕГА',
    nameEn: 'GEGA',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2882',
    addressNomenclature: '0',
    x: '23.004842',
    y: '41.450791',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '14605',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЕЛА',
    nameEn: 'GELA',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4710',
    addressNomenclature: '0',
    x: '24.572848',
    y: '41.643813',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '14619',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЕЛЕМЕНОВО',
    nameEn: 'GELEMENOVO',
    municipality: 'ПАЗАРДЖИК',
    municipalityEn: 'PAZARDZHIK',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4444',
    addressNomenclature: '0',
    x: '24.315351',
    y: '42.270178',
    servingDays: '1111100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '14647',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЕНЕРАЛ ГЕШЕВО',
    nameEn: 'GENERAL GESHEVO',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6856',
    addressNomenclature: '0',
    x: '25.193872',
    y: '41.43229',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '32740',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЕНЕРАЛ ИНЗОВО',
    nameEn: 'GENERAL INZOVO',
    municipality: 'ТУНДЖА',
    municipalityEn: 'TUNDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8670',
    addressNomenclature: '0',
    x: '26.434659',
    y: '42.322078',
    servingDays: '1010100',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '14653',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЕНЕРАЛ КАНТАРДЖИЕВО',
    nameEn: 'GENERAL KANTARDZHIEVO',
    municipality: 'АКСАКОВО',
    municipalityEn: 'AKSAKOVO',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9150',
    addressNomenclature: '0',
    x: '27.981194',
    y: '43.35922',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '14667',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЕНЕРАЛ КИСЕЛОВО',
    nameEn: 'GENERAL KISELOVO',
    municipality: 'ВЪЛЧИ ДОЛ',
    municipalityEn: 'VALCHI DOL',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9142',
    addressNomenclature: '0',
    x: '27.59958',
    y: '43.462717',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '14670',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЕНЕРАЛ КОЛЕВО',
    nameEn: 'GENERAL KOLEVO',
    municipality: 'ВЪЛЧИ ДОЛ',
    municipalityEn: 'VALCHI DOL',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9295',
    addressNomenclature: '0',
    x: '27.365385',
    y: '43.52132',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '14684',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЕНЕРАЛ КОЛЕВО',
    nameEn: 'GENERAL KOLEVO',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9373',
    addressNomenclature: '0',
    x: '27.941332',
    y: '43.646999',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '14698',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЕНЕРАЛ МАРИНОВО',
    nameEn: 'GENERAL MARINOVO',
    municipality: 'ВИДИН',
    municipalityEn: 'VIDIN',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3732',
    addressNomenclature: '0',
    x: '22.744209',
    y: '43.99824',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '58390',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЕНЕРАЛ ТОДОРОВ',
    nameEn: 'GENERAL TODOROV',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2873',
    addressNomenclature: '0',
    x: '23.28692',
    y: '41.454993',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '14711',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ГЕНЕРАЛ ТОШЕВО',
    nameEn: 'GENERAL TOSHEVO',
    municipality: 'ГЕНЕРАЛ ТОШЕВО',
    municipalityEn: 'GENERAL TOSHEVO',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9500',
    addressNomenclature: '2',
    x: '28.036559',
    y: '43.700308',
    servingDays: '1111100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '14725',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЕНЕРАЛ ТОШЕВО',
    nameEn: 'GENERAL TOSHEVO',
    municipality: 'ТУНДЖА',
    municipalityEn: 'TUNDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8625',
    addressNomenclature: '0',
    x: '26.293479',
    y: '42.223019',
    servingDays: '0101000',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '14708',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЕНЕРАЛОВО',
    nameEn: 'GENERALOVO',
    municipality: 'СВИЛЕНГРАД',
    municipalityEn: 'SVILENGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6523',
    addressNomenclature: '0',
    x: '26.276126',
    y: '41.722477',
    servingDays: '1010100',
    servingOfficeId: '48',
    servingHubOfficeId: '5',
  },
  {
    id: '14742',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЕНОВЦИ',
    nameEn: 'GENOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5307',
    addressNomenclature: '0',
    x: '25.233334',
    y: '42.845867',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '14756',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЕНЧОВЦИ',
    nameEn: 'GENCHOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5301',
    addressNomenclature: '0',
    x: '25.390934',
    y: '42.827614',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '56304',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЕНЧОВЦИ',
    nameEn: 'GENCHOVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5350',
    addressNomenclature: '0',
    x: '25.469017',
    y: '42.879512',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '14762',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЕНЯ',
    nameEn: 'GENYA',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5397',
    addressNomenclature: '0',
    x: '25.495196',
    y: '43.005791',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '14773',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЕОРГИ ДАМЯНОВО',
    nameEn: 'GEORGI DAMYANOVO',
    municipality: 'ГЕОРГИ ДАМЯНОВО',
    municipalityEn: 'GEORGI DAMYANOVO',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3470',
    addressNomenclature: '2',
    x: '23.029936',
    y: '43.385606',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '14787',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЕОРГИ ДОБРЕВО',
    nameEn: 'GEORGI DOBREVO',
    municipality: 'ЛЮБИМЕЦ',
    municipalityEn: 'LYUBIMETS',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6559',
    addressNomenclature: '0',
    x: '26.13566',
    y: '41.863627',
    servingDays: '1010100',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '14800',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЕРГЕВЕЦ',
    nameEn: 'GERGEVETS',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8869',
    addressNomenclature: '0',
    x: '26.346622',
    y: '42.591284',
    servingDays: '1010100',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '14814',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЕРГИНИ',
    nameEn: 'GERGINI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5345',
    addressNomenclature: '0',
    x: '25.24686',
    y: '42.885402',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '14831',
    countryId: '100',
    mainSiteId: '68134',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЕРМАН',
    nameEn: 'GERMAN',
    municipality: 'СТОЛИЧНА',
    municipalityEn: 'STOLICHNA',
    region: 'СОФИЯ (СТОЛИЦА)',
    regionEn: 'SOFIA (STOLITSA)',
    postCode: '1186',
    addressNomenclature: '2',
    x: '23.414503',
    y: '42.613144',
    servingDays: '1111110',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '18589',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЕЦОВО',
    nameEn: 'GETSOVO',
    municipality: 'РАЗГРАД',
    municipalityEn: 'RAZGRAD',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7219',
    addressNomenclature: '0',
    x: '26.477957',
    y: '43.544854',
    servingDays: '1111110',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '14859',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЕША',
    nameEn: 'GESHA',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5370',
    addressNomenclature: '0',
    x: '25.435533',
    y: '42.981049',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '14862',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЕШАНОВО',
    nameEn: 'GESHANOVO',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9393',
    addressNomenclature: '0',
    x: '27.530553',
    y: '43.656388',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '14876',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГИГЕН',
    nameEn: 'GIGEN',
    municipality: 'ГУЛЯНЦИ',
    municipalityEn: 'GULYANTSI',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5970',
    addressNomenclature: '0',
    x: '24.484759',
    y: '43.695207',
    servingDays: '1111100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '14893',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГИГИНЦИ',
    nameEn: 'GIGINTSI',
    municipality: 'БРЕЗНИК',
    municipalityEn: 'BREZNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2393',
    addressNomenclature: '0',
    x: '22.812483',
    y: '42.661469',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '14903',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГИНЦИ',
    nameEn: 'GINTSI',
    municipality: 'ГОДЕЧ',
    municipalityEn: 'GODECH',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2250',
    addressNomenclature: '0',
    x: '23.102647',
    y: '43.08447',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '14917',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГИРЧЕВЦИ',
    nameEn: 'GIRCHEVTSI',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2546',
    addressNomenclature: '0',
    x: '22.767433',
    y: '42.292741',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '14920',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГИТА',
    nameEn: 'GITA',
    municipality: 'ЧИРПАН',
    municipalityEn: 'CHIRPAN',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6215',
    addressNomenclature: '0',
    x: '25.45949',
    y: '42.19909',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '14934',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЛАВА',
    nameEn: 'GLAVA',
    municipality: 'ЧЕРВЕН БРЯГ',
    municipalityEn: 'CHERVEN BRYAG',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5985',
    addressNomenclature: '0',
    x: '24.197895',
    y: '43.398233',
    servingDays: '1010100',
    servingOfficeId: '59',
    servingHubOfficeId: '20',
  },
  {
    id: '14948',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЛАВАН',
    nameEn: 'GLAVAN',
    municipality: 'СИЛИСТРА',
    municipalityEn: 'SILISTRA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7543',
    addressNomenclature: '0',
    x: '27.408758',
    y: '43.98997',
    servingDays: '0101000',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '14951',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЛАВАН',
    nameEn: 'GLAVAN',
    municipality: 'ГЪЛЪБОВО',
    municipalityEn: 'GALABOVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6295',
    addressNomenclature: '0',
    x: '26.107511',
    y: '42.067621',
    servingDays: '1010100',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '14965',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЛАВАНОВЦИ',
    nameEn: 'GLAVANOVTSI',
    municipality: 'ГЕОРГИ ДАМЯНОВО',
    municipalityEn: 'GEORGI DAMYANOVO',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3468',
    addressNomenclature: '0',
    x: '22.963162',
    y: '43.32158',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '14979',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЛАВАНОВЦИ',
    nameEn: 'GLAVANOVTSI',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2480',
    addressNomenclature: '0',
    x: '22.519757',
    y: '42.817917',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '14982',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЛАВАНЦИ',
    nameEn: 'GLAVANTSI',
    municipality: 'ТЕРВЕЛ',
    municipalityEn: 'TERVEL',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9499',
    addressNomenclature: '0',
    x: '27.252286',
    y: '43.730792',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '14996',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЛАВАТАР',
    nameEn: 'GLAVATAR',
    municipality: 'КАЛОЯНОВО',
    municipalityEn: 'KALOYANOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4171',
    addressNomenclature: '0',
    x: '24.870081',
    y: '42.332805',
    servingDays: '0101000',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '15000',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЛАВАТАРЦИ',
    nameEn: 'GLAVATARTSI',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6738',
    addressNomenclature: '0',
    x: '25.310256',
    y: '41.647072',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '15014',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЛАВАЦИ',
    nameEn: 'GLAVATSI',
    municipality: 'КРИВОДОЛ',
    municipalityEn: 'KRIVODOL',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3045',
    addressNomenclature: '0',
    x: '23.380796',
    y: '43.291408',
    servingDays: '0101000',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '15028',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЛАВИНИЦА',
    nameEn: 'GLAVINITSA',
    municipality: 'ПАЗАРДЖИК',
    municipalityEn: 'PAZARDZHIK',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4409',
    addressNomenclature: '0',
    x: '24.310517',
    y: '42.158916',
    servingDays: '1111100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '15031',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ГЛАВИНИЦА',
    nameEn: 'GLAVINITSA',
    municipality: 'ГЛАВИНИЦА',
    municipalityEn: 'GLAVINITSA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7630',
    addressNomenclature: '2',
    x: '26.836553',
    y: '43.912054',
    servingDays: '1111100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '18544',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЛАВНИК',
    nameEn: 'GLAVNIK',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6789',
    addressNomenclature: '0',
    x: '25.054325',
    y: '41.52478',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '15045',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЛАШАТАЙ',
    nameEn: 'GLASHATAY',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7986',
    addressNomenclature: '0',
    x: '26.232308',
    y: '43.055104',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '15059',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЛЕДАЦИ',
    nameEn: 'GLEDATSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5345',
    addressNomenclature: '0',
    x: '25.268578',
    y: '42.909942',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '15062',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЛЕДАЧЕВО',
    nameEn: 'GLEDACHEVO',
    municipality: 'РАДНЕВО',
    municipalityEn: 'RADNEVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6263',
    addressNomenclature: '0',
    x: '26.02187',
    y: '42.253952',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '15093',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЛЕДКА',
    nameEn: 'GLEDKA',
    municipality: 'СТАМБОЛОВО',
    municipalityEn: 'STAMBOLOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6362',
    addressNomenclature: '0',
    x: '25.658341',
    y: '41.778701',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '15103',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЛОГИНКА',
    nameEn: 'GLOGINKA',
    municipality: 'ПОПОВО',
    municipalityEn: 'POPOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7879',
    addressNomenclature: '0',
    x: '26.351322',
    y: '43.294884',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '15117',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЛОГИНО',
    nameEn: 'GLOGINO',
    municipality: 'БАНИТЕ',
    municipalityEn: 'BANITE',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4948',
    addressNomenclature: '0',
    x: '24.962927',
    y: '41.713246',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '15120',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЛОГОВЕЦ',
    nameEn: 'GLOGOVETS',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5080',
    addressNomenclature: '0',
    x: '26.121741',
    y: '42.967008',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '15134',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЛОГОВИЦА',
    nameEn: 'GLOGOVITSA',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2463',
    addressNomenclature: '0',
    x: '22.668154',
    y: '42.792217',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '15148',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЛОГОВО',
    nameEn: 'GLOGOVO',
    municipality: 'ТЕТЕВЕН',
    municipalityEn: 'TETEVEN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5724',
    addressNomenclature: '0',
    x: '24.2757',
    y: '42.976763',
    servingDays: '0101000',
    servingOfficeId: '88',
    servingHubOfficeId: '2',
  },
  {
    id: '15151',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ГЛОДЖЕВО',
    nameEn: 'GLODZHEVO',
    municipality: 'ВЕТОВО',
    municipalityEn: 'VETOVO',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7040',
    addressNomenclature: '0',
    x: '26.387827',
    y: '43.732945',
    servingDays: '1111100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '15165',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЛОЖЕНЕ',
    nameEn: 'GLOZHENE',
    municipality: 'ТЕТЕВЕН',
    municipalityEn: 'TETEVEN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5740',
    addressNomenclature: '0',
    x: '24.186495',
    y: '42.990152',
    servingDays: '1111100',
    servingOfficeId: '88',
    servingHubOfficeId: '2',
  },
  {
    id: '18505',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЛОЖЕНЕ',
    nameEn: 'GLOZHENE',
    municipality: 'КОЗЛОДУЙ',
    municipalityEn: 'KOZLODUY',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3327',
    addressNomenclature: '0',
    x: '23.812049',
    y: '43.69166',
    servingDays: '1111100',
    servingOfficeId: '64',
    servingHubOfficeId: '20',
  },
  {
    id: '15179',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЛУМОВО',
    nameEn: 'GLUMOVO',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6572',
    addressNomenclature: '0',
    x: '25.902498',
    y: '41.5341',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '15182',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЛУМЧЕ',
    nameEn: 'GLUMCHE',
    municipality: 'КАРНОБАТ',
    municipalityEn: 'KARNOBAT',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8429',
    addressNomenclature: '0',
    x: '27.026807',
    y: '42.6995',
    servingDays: '1010100',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '15196',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЛУТНИЦИТЕ',
    nameEn: 'GLUTNITSITE',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5363',
    addressNomenclature: '0',
    x: '25.587356',
    y: '42.833804',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '15206',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЛУФИШЕВО',
    nameEn: 'GLUFISHEVO',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8866',
    addressNomenclature: '0',
    x: '26.321736',
    y: '42.544767',
    servingDays: '1010100',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '15216',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЛУХАР',
    nameEn: 'GLUHAR',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6647',
    addressNomenclature: '0',
    x: '25.392799',
    y: '41.593295',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '15223',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЛУШКА',
    nameEn: 'GLUSHKA',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5370',
    addressNomenclature: '0',
    x: '25.518278',
    y: '42.944768',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '15237',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЛУШНИК',
    nameEn: 'GLUSHNIK',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8879',
    addressNomenclature: '0',
    x: '26.502661',
    y: '42.659341',
    servingDays: '1010100',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '15240',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГНЕЗДАРЕ',
    nameEn: 'GNEZDARE',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6590',
    addressNomenclature: '0',
    x: '25.929259',
    y: '41.388524',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '15268',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГНЯЗДОВО',
    nameEn: 'GNYAZDOVO',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6675',
    addressNomenclature: '0',
    x: '25.549979',
    y: '41.654197',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '15271',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОВЕДАРЕ',
    nameEn: 'GOVEDARE',
    municipality: 'ПАЗАРДЖИК',
    municipalityEn: 'PAZARDZHIK',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4453',
    addressNomenclature: '0',
    x: '24.497557',
    y: '42.150008',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '15285',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОВЕДАРЦИ',
    nameEn: 'GOVEDARTSI',
    municipality: 'САМОКОВ',
    municipalityEn: 'SAMOKOV',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2020',
    addressNomenclature: '0',
    x: '23.475158',
    y: '42.258763',
    servingDays: '0101000',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '15299',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОВЕЖДА',
    nameEn: 'GOVEZHDA',
    municipality: 'ГЕОРГИ ДАМЯНОВО',
    municipalityEn: 'GEORGI DAMYANOVO',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3474',
    addressNomenclature: '0',
    x: '22.966419',
    y: '43.302011',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '15309',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ГОДЕЧ',
    nameEn: 'GODECH',
    municipality: 'ГОДЕЧ',
    municipalityEn: 'GODECH',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2240',
    addressNomenclature: '2',
    x: '23.047392',
    y: '43.015297',
    servingDays: '1111100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '15312',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОДЕШЕВО',
    nameEn: 'GODESHEVO',
    municipality: 'САТОВЧА',
    municipalityEn: 'SATOVCHA',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2938',
    addressNomenclature: '0',
    x: '24.051839',
    y: '41.475087',
    servingDays: '1010100',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '15326',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОДЛЕВО',
    nameEn: 'GODLEVO',
    municipality: 'РАЗЛОГ',
    municipalityEn: 'RAZLOG',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2768',
    addressNomenclature: '0',
    x: '23.478568',
    y: '41.929047',
    servingDays: '1111110',
    servingOfficeId: '125',
    servingHubOfficeId: '13',
  },
  {
    id: '15331',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЗ',
    nameEn: 'GOZ',
    municipality: 'БРЕЗНИК',
    municipalityEn: 'BREZNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2366',
    addressNomenclature: '0',
    x: '22.938821',
    y: '42.751045',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '15343',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЗДЕВИЦА',
    nameEn: 'GOZDEVITSA',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4791',
    addressNomenclature: '0',
    x: '24.658301',
    y: '41.452817',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '15357',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЗДЕЙКА',
    nameEn: 'GOZDEYKA',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5392',
    addressNomenclature: '0',
    x: '25.499984',
    y: '43.022271',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '15374',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛАК',
    nameEn: 'GOLAK',
    municipality: 'КОСТЕНЕЦ',
    municipalityEn: 'KOSTENETS',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2036',
    addressNomenclature: '0',
    x: '23.967118',
    y: '42.297393',
    servingDays: '1010100',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '15549',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЕМ ЦАЛИМ',
    nameEn: 'GOLEM TSALIM',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2800',
    addressNomenclature: '0',
    x: '23.336574',
    y: '41.607689',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '15432',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЕМА РАКОВИЦА',
    nameEn: 'GOLEMA RAKOVITSA',
    municipality: 'ЕЛИН ПЕЛИН',
    municipalityEn: 'ELIN PELIN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2113',
    addressNomenclature: '0',
    x: '23.797757',
    y: '42.621265',
    servingDays: '1010100',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '15446',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЕМА ФУЧА',
    nameEn: 'GOLEMA FUCHA',
    municipality: 'БОБОВ ДОЛ',
    municipalityEn: 'BOBOV DOL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2667',
    addressNomenclature: '0',
    x: '22.944841',
    y: '42.374987',
    servingDays: '1010100',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '15391',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЕМАНИ',
    nameEn: 'GOLEMANI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5070',
    addressNomenclature: '0',
    x: '25.835172',
    y: '42.956823',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '15401',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЕМАНИТЕ',
    nameEn: 'GOLEMANITE',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5054',
    addressNomenclature: '0',
    x: '25.648021',
    y: '42.961602',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '15415',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЕМАНОВО',
    nameEn: 'GOLEMANOVO',
    municipality: 'КУЛА',
    municipalityEn: 'KULA',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3839',
    addressNomenclature: '0',
    x: '22.446761',
    y: '43.921969',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '15429',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЕМАНЦИ',
    nameEn: 'GOLEMANTSI',
    municipality: 'ХАСКОВО',
    municipalityEn: 'HASKOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6358',
    addressNomenclature: '0',
    x: '25.481484',
    y: '41.784867',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '15463',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЕМИ БЪЛГАРЕНИ',
    nameEn: 'GOLEMI BALGARENI',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5370',
    addressNomenclature: '0',
    x: '25.524998',
    y: '42.941313',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '15480',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЕМИ СТАНЧОВЦИ',
    nameEn: 'GOLEMI STANCHOVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5365',
    addressNomenclature: '0',
    x: '25.535256',
    y: '42.908118',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '15494',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЕМО БАБИНО',
    nameEn: 'GOLEMO BABINO',
    municipality: 'КРИВОДОЛ',
    municipalityEn: 'KRIVODOL',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3063',
    addressNomenclature: '0',
    x: '23.43301',
    y: '43.348964',
    servingDays: '0101000',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '15504',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЕМО БУЧИНО',
    nameEn: 'GOLEMO BUCHINO',
    municipality: 'ПЕРНИК',
    municipalityEn: 'PERNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2348',
    addressNomenclature: '0',
    x: '23.120052',
    y: '42.630534',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '15518',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЕМО МАЛОВО',
    nameEn: 'GOLEMO MALOVO',
    municipality: 'ДРАГОМАН',
    municipalityEn: 'DRAGOMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2204',
    addressNomenclature: '0',
    x: '23.003798',
    y: '42.934362',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '15535',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЕМО СЕЛО',
    nameEn: 'GOLEMO SELO',
    municipality: 'БОБОВ ДОЛ',
    municipalityEn: 'BOBOV DOL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2635',
    addressNomenclature: '0',
    x: '23.042001',
    y: '42.293155',
    servingDays: '1010100',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '15552',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЕЦ',
    nameEn: 'GOLETS',
    municipality: 'УГЪРЧИН',
    municipalityEn: 'UGARCHIN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5573',
    addressNomenclature: '0',
    x: '24.588976',
    y: '43.045755',
    servingDays: '1010100',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '15566',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЕШ',
    nameEn: 'GOLESH',
    municipality: 'КАЙНАРДЖА',
    municipalityEn: 'KAYNARDZHA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7547',
    addressNomenclature: '0',
    x: '27.613822',
    y: '43.953889',
    servingDays: '0101000',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '15572',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЕШ',
    nameEn: 'GOLESH',
    municipality: 'ГОДЕЧ',
    municipalityEn: 'GODECH',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2254',
    addressNomenclature: '0',
    x: '22.944679',
    y: '43.049076',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '15583',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЕШОВО',
    nameEn: 'GOLESHOVO',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2818',
    addressNomenclature: '0',
    x: '23.590598',
    y: '41.432234',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '15597',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛИЦА',
    nameEn: 'GOLITSA',
    municipality: 'ДОЛНИ ЧИФЛИК',
    municipalityEn: 'DOLNI CHIFLIK',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9114',
    addressNomenclature: '0',
    x: '27.546545',
    y: '42.914227',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '15607',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛОБРАД',
    nameEn: 'GOLOBRAD',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6793',
    addressNomenclature: '0',
    x: '25.23862',
    y: '41.653129',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '15610',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛОБРАДОВО',
    nameEn: 'GOLOBRADOVO',
    municipality: 'СТАМБОЛОВО',
    municipalityEn: 'STAMBOLOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6386',
    addressNomenclature: '0',
    x: '25.67107',
    y: '41.633865',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '15457',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЯМ ВЪРБОВНИК',
    nameEn: 'GOLYAM VARBOVNIK',
    municipality: 'БОБОВ ДОЛ',
    municipalityEn: 'BOBOV DOL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2690',
    addressNomenclature: '0',
    x: '22.976888',
    y: '42.253191',
    servingDays: '1010100',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '15727',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЯМ ДЕВЕСИЛ',
    nameEn: 'GOLYAM DEVESIL',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6932',
    addressNomenclature: '0',
    x: '25.648058',
    y: '41.340872',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '15730',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЯМ ДЕРВЕНТ',
    nameEn: 'GOLYAM DERVENT',
    municipality: 'ЕЛХОВО',
    municipalityEn: 'ELHOVO',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8757',
    addressNomenclature: '0',
    x: '26.75132',
    y: '41.984416',
    servingDays: '1010100',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '15744',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЯМ ДОЛ',
    nameEn: 'GOLYAM DOL',
    municipality: 'БРАТЯ ДАСКАЛОВИ',
    municipalityEn: 'BRATYA DASKALOVI',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6245',
    addressNomenclature: '0',
    x: '25.226504',
    y: '42.332152',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '15758',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЯМ ИЗВОР',
    nameEn: 'GOLYAM IZVOR',
    municipality: 'ТЕТЕВЕН',
    municipalityEn: 'TETEVEN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5741',
    addressNomenclature: '0',
    x: '24.107782',
    y: '42.955933',
    servingDays: '0101000',
    servingOfficeId: '88',
    servingHubOfficeId: '2',
  },
  {
    id: '15761',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЯМ ИЗВОР',
    nameEn: 'GOLYAM IZVOR',
    municipality: 'САМУИЛ',
    municipalityEn: 'SAMUIL',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7459',
    addressNomenclature: '0',
    x: '26.721074',
    y: '43.566944',
    servingDays: '1010100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '15775',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЯМ ИЗВОР',
    nameEn: 'GOLYAM IZVOR',
    municipality: 'СТАМБОЛОВО',
    municipalityEn: 'STAMBOLOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6395',
    addressNomenclature: '0',
    x: '25.719578',
    y: '41.761478',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '15789',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЯМ МАНАСТИР',
    nameEn: 'GOLYAM MANASTIR',
    municipality: 'ТУНДЖА',
    municipalityEn: 'TUNDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8624',
    addressNomenclature: '0',
    x: '26.34911',
    y: '42.208409',
    servingDays: '1010100',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '15953',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЯМ ПОРОВЕЦ',
    nameEn: 'GOLYAM POROVETS',
    municipality: 'ИСПЕРИХ',
    municipalityEn: 'ISPERIH',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7444',
    addressNomenclature: '0',
    x: '26.788039',
    y: '43.669021',
    servingDays: '1010100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '15970',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЯМ ЧАРДАК',
    nameEn: 'GOLYAM CHARDAK',
    municipality: 'СЪЕДИНЕНИЕ',
    municipalityEn: 'SAEDINENIE',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4193',
    addressNomenclature: '0',
    x: '24.626641',
    y: '42.315765',
    servingDays: '0101000',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '15641',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЯМА БАРА',
    nameEn: 'GOLYAMA BARA',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6744',
    addressNomenclature: '0',
    x: '25.265383',
    y: '41.716632',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '15655',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЯМА БРЕСТНИЦА',
    nameEn: 'GOLYAMA BRESTNITSA',
    municipality: 'ЯБЛАНИЦА',
    municipalityEn: 'YABLANITSA',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5753',
    addressNomenclature: '0',
    x: '24.200019',
    y: '43.093931',
    servingDays: '1010100',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '15669',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЯМА ВОДА',
    nameEn: 'GOLYAMA VODA',
    municipality: 'САМУИЛ',
    municipalityEn: 'SAMUIL',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7452',
    addressNomenclature: '0',
    x: '26.82746',
    y: '43.569837',
    servingDays: '1010100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '15698',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЯМА ДОЛИНА',
    nameEn: 'GOLYAMA DOLINA',
    municipality: 'МАДЖАРОВО',
    municipalityEn: 'MADZHAROVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6476',
    addressNomenclature: '0',
    x: '25.863291',
    y: '41.69576',
    servingDays: '0101000',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '15703',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЯМА ЖЕЛЯЗНА',
    nameEn: 'GOLYAMA ZHELYAZNA',
    municipality: 'ТРОЯН',
    municipalityEn: 'TROYAN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5670',
    addressNomenclature: '0',
    x: '24.46432',
    y: '42.977787',
    servingDays: '0101000',
    servingOfficeId: '44',
    servingHubOfficeId: '11',
  },
  {
    id: '15713',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЯМА ЧИНКА',
    nameEn: 'GOLYAMA CHINKA',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6940',
    addressNomenclature: '0',
    x: '25.564684',
    y: '41.403498',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '15792',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЯМО АСЕНОВО',
    nameEn: 'GOLYAMO ASENOVO',
    municipality: 'ДИМИТРОВГРАД',
    municipalityEn: 'DIMITROVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6424',
    addressNomenclature: '0',
    x: '25.675528',
    y: '42.104012',
    servingDays: '1010100',
    servingOfficeId: '42',
    servingHubOfficeId: '5',
  },
  {
    id: '15802',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЯМО БЕЛОВО',
    nameEn: 'GOLYAMO BELOVO',
    municipality: 'БЕЛОВО',
    municipalityEn: 'BELOVO',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4473',
    addressNomenclature: '0',
    x: '23.987662',
    y: '42.19195',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '15816',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЯМО БУКОВО',
    nameEn: 'GOLYAMO BUKOVO',
    municipality: 'СРЕДЕЦ',
    municipalityEn: 'SREDETS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8329',
    addressNomenclature: '0',
    x: '27.184292',
    y: '42.190044',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '15829',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЯМО ВРАНОВО',
    nameEn: 'GOLYAMO VRANOVO',
    municipality: 'СЛИВО ПОЛЕ',
    municipalityEn: 'SLIVO POLE',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7065',
    addressNomenclature: '0',
    x: '26.339211',
    y: '43.962117',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '15833',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЯМО ГРАДИЩЕ',
    nameEn: 'GOLYAMO GRADISHTE',
    municipality: 'ОПАКА',
    municipalityEn: 'OPAKA',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7848',
    addressNomenclature: '0',
    x: '26.052084',
    y: '43.456958',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '15850',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЯМО ДОЛЯНЕ',
    nameEn: 'GOLYAMO DOLYANE',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7960',
    addressNomenclature: '0',
    x: '26.221278',
    y: '43.017154',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '15864',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЯМО ДРЯНОВО',
    nameEn: 'GOLYAMO DRYANOVO',
    municipality: 'КАЗАНЛЪК',
    municipalityEn: 'KAZANLAK',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6146',
    addressNomenclature: '0',
    x: '25.251134',
    y: '42.664917',
    servingDays: '1010100',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '15878',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЯМО КАМЕНЯНЕ',
    nameEn: 'GOLYAMO KAMENYANE',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6925',
    addressNomenclature: '0',
    x: '25.708751',
    y: '41.406576',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '15881',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЯМО КРУШЕВО',
    nameEn: 'GOLYAMO KRUSHEVO',
    municipality: 'БОЛЯРОВО',
    municipalityEn: 'BOLYAROVO',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8729',
    addressNomenclature: '0',
    x: '26.924457',
    y: '42.194649',
    servingDays: '0101000',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '15895',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЯМО НОВО',
    nameEn: 'GOLYAMO NOVO',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7789',
    addressNomenclature: '0',
    x: '26.374713',
    y: '43.332406',
    servingDays: '1111100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '15905',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЯМО ОСОЕ',
    nameEn: 'GOLYAMO OSOE',
    municipality: 'ТЕТЕВЕН',
    municipalityEn: 'TETEVEN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '',
    addressNomenclature: '0',
    x: '24.145706',
    y: '42.936905',
    servingDays: '0101000',
    servingOfficeId: '88',
    servingHubOfficeId: '2',
  },
  {
    id: '15521',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЯМО ПЕЩЕНЕ',
    nameEn: 'GOLYAMO PESHTENE',
    municipality: 'ВРАЦА',
    municipalityEn: 'VRATSA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3058',
    addressNomenclature: '0',
    x: '23.738058',
    y: '43.319214',
    servingDays: '1010100',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '15919',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЯМО СОКОЛОВО',
    nameEn: 'GOLYAMO SOKOLOVO',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7778',
    addressNomenclature: '0',
    x: '26.499591',
    y: '43.333845',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '15922',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЯМО ЦЪРКВИЩЕ',
    nameEn: 'GOLYAMO TSARKVISHTE',
    municipality: 'ОМУРТАГ',
    municipalityEn: 'OMURTAG',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7924',
    addressNomenclature: '0',
    x: '26.548117',
    y: '43.043944',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '15936',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЯМО ЧОЧОВЕНИ',
    nameEn: 'GOLYAMO CHOCHOVENI',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8863',
    addressNomenclature: '0',
    x: '26.141478',
    y: '42.670176',
    servingDays: '0101000',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '15984',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОЛЯНОВЦИ',
    nameEn: 'GOLYANOVTSI',
    municipality: 'КОСТИНБРОД',
    municipalityEn: 'KOSTINBROD',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2231',
    addressNomenclature: '0',
    x: '23.239227',
    y: '42.828492',
    servingDays: '1111100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '15998',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОМОТАРЦИ',
    nameEn: 'GOMOTARTSI',
    municipality: 'ВИДИН',
    municipalityEn: 'VIDIN',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3772',
    addressNomenclature: '0',
    x: '22.967891',
    y: '44.091313',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '16002',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРАН',
    nameEn: 'GORAN',
    municipality: 'ЛОВЕЧ',
    municipalityEn: 'LOVECH',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5552',
    addressNomenclature: '0',
    x: '24.743518',
    y: '43.202453',
    servingDays: '0101000',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '16016',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРАНОВЦИ',
    nameEn: 'GORANOVTSI',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5035',
    addressNomenclature: '0',
    x: '25.627143',
    y: '42.801862',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '16026',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРАНОВЦИ',
    nameEn: 'GORANOVTSI',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2565',
    addressNomenclature: '0',
    x: '22.628413',
    y: '42.386478',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '16033',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРЕМЕ',
    nameEn: 'GOREME',
    municipality: 'СТРУМЯНИ',
    municipalityEn: 'STRUMYANI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2834',
    addressNomenclature: '0',
    x: '23.093029',
    y: '41.640562',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '16047',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРЕН ЕНЕВЕЦ',
    nameEn: 'GOREN ENEVETS',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5050',
    addressNomenclature: '0',
    x: '25.568029',
    y: '42.920165',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '16050',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРЕН ЧИФЛИК',
    nameEn: 'GOREN CHIFLIK',
    municipality: 'ДОЛНИ ЧИФЛИК',
    municipalityEn: 'DOLNI CHIFLIK',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9100',
    addressNomenclature: '0',
    x: '27.63028',
    y: '43.01064',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '16064',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРИЦА',
    nameEn: 'GORITSA',
    municipality: 'ПОМОРИЕ',
    municipalityEn: 'POMORIE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8225',
    addressNomenclature: '0',
    x: '27.528442',
    y: '42.791646',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '16078',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРИЦА',
    nameEn: 'GORITSA',
    municipality: 'БЯЛА',
    municipalityEn: 'BYALA',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9101',
    addressNomenclature: '0',
    x: '27.84512',
    y: '42.91081',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '16081',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРИЦА',
    nameEn: 'GORITSA',
    municipality: 'ПОПОВО',
    municipalityEn: 'POPOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7854',
    addressNomenclature: '0',
    x: '26.115448',
    y: '43.263717',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '18575',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРИЦА',
    nameEn: 'GORITSA',
    municipality: 'ГЕНЕРАЛ ТОШЕВО',
    municipalityEn: 'GENERAL TOSHEVO',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9558',
    addressNomenclature: '0',
    x: '28.165133',
    y: '43.639446',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '16095',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРИЧАНЕ',
    nameEn: 'GORICHANE',
    municipality: 'ШАБЛА',
    municipalityEn: 'SHABLA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9688',
    addressNomenclature: '0',
    x: '28.453467',
    y: '43.528156',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '16105',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРИЧЕВО',
    nameEn: 'GORICHEVO',
    municipality: 'КУБРАТ',
    municipalityEn: 'KUBRAT',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7337',
    addressNomenclature: '0',
    x: '26.450101',
    y: '43.850954',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '2782',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНА АРДА',
    nameEn: 'GORNA ARDA',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4788',
    addressNomenclature: '0',
    x: '24.608792',
    y: '41.449924',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '16119',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНА БЕЛА РЕЧКА',
    nameEn: 'GORNA BELA RECHKA',
    municipality: 'ВЪРШЕЦ',
    municipalityEn: 'VARSHETS',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3544',
    addressNomenclature: '0',
    x: '23.356393',
    y: '43.178904',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '16122',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНА БЕШОВИЦА',
    nameEn: 'GORNA BESHOVITSA',
    municipality: 'МЕЗДРА',
    municipalityEn: 'MEZDRA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3155',
    addressNomenclature: '0',
    x: '23.816685',
    y: '43.209961',
    servingDays: '0101000',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '18513',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНА БИРКОВА',
    nameEn: 'GORNA BIRKOVA',
    municipality: 'ВЕЛИНГРАД',
    municipalityEn: 'VELINGRAD',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4657',
    addressNomenclature: '0',
    x: '23.805582',
    y: '41.958804',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '16136',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНА БРЕЗНИЦА',
    nameEn: 'GORNA BREZNITSA',
    municipality: 'КРЕСНА',
    municipalityEn: 'KRESNA',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2842',
    addressNomenclature: '0',
    x: '23.116324',
    y: '41.743502',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '16141',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНА БРЕСТНИЦА',
    nameEn: 'GORNA BRESTNITSA',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2575',
    addressNomenclature: '0',
    x: '22.610824',
    y: '42.282783',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '18561',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНА ВАСИЛИЦА',
    nameEn: 'GORNA VASILITSA',
    municipality: 'КОСТЕНЕЦ',
    municipalityEn: 'KOSTENETS',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2046',
    addressNomenclature: '0',
    x: '23.850971',
    y: '42.347086',
    servingDays: '1010100',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '16153',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНА ВЕРЕНИЦА',
    nameEn: 'GORNA VERENITSA',
    municipality: 'МОНТАНА',
    municipalityEn: 'MONTANA',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3484',
    addressNomenclature: '0',
    x: '23.101062',
    y: '43.44137',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '16167',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНА ВРАБЧА',
    nameEn: 'GORNA VRABCHA',
    municipality: 'ЗЕМЕН',
    municipalityEn: 'ZEMEN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2439',
    addressNomenclature: '0',
    x: '22.727938',
    y: '42.604566',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '16170',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНА ГЛОГОВИЦА',
    nameEn: 'GORNA GLOGOVITSA',
    municipality: 'ЗЕМЕН',
    municipalityEn: 'ZEMEN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2459',
    addressNomenclature: '0',
    x: '22.676432',
    y: '42.622485',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '16198',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНА ГРАЩИЦА',
    nameEn: 'GORNA GRASHTITSA',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2535',
    addressNomenclature: '0',
    x: '22.810102',
    y: '42.312802',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '16208',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНА ДИКАНЯ',
    nameEn: 'GORNA DIKANYA',
    municipality: 'РАДОМИР',
    municipalityEn: 'RADOMIR',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2419',
    addressNomenclature: '0',
    x: '23.185268',
    y: '42.423505',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '18527',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНА ДЪБЕВА',
    nameEn: 'GORNA DABEVA',
    municipality: 'ВЕЛИНГРАД',
    municipalityEn: 'VELINGRAD',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4648',
    addressNomenclature: '0',
    x: '23.820604',
    y: '41.997499',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '16211',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНА ЗЛАТИЦА',
    nameEn: 'GORNA ZLATITSA',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7969',
    addressNomenclature: '0',
    x: '26.149135',
    y: '43.183611',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '16225',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНА КАБДА',
    nameEn: 'GORNA KABDA',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7865',
    addressNomenclature: '0',
    x: '26.351696',
    y: '43.228603',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '16239',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНА КОВАЧИЦА',
    nameEn: 'GORNA KOVACHITSA',
    municipality: 'ЧИПРОВЦИ',
    municipalityEn: 'CHIPROVTSI',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3463',
    addressNomenclature: '0',
    x: '23.001553',
    y: '43.412296',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '16242',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНА КОЗНИЦА',
    nameEn: 'GORNA KOZNITSA',
    municipality: 'БОБОВ ДОЛ',
    municipalityEn: 'BOBOV DOL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2687',
    addressNomenclature: '0',
    x: '22.908896',
    y: '42.323026',
    servingDays: '1010100',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '16256',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНА КРЕМЕНА',
    nameEn: 'GORNA KREMENA',
    municipality: 'МЕЗДРА',
    municipalityEn: 'MEZDRA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3154',
    addressNomenclature: '0',
    x: '23.729397',
    y: '43.186503',
    servingDays: '0101000',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '16267',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНА КРЕПОСТ',
    nameEn: 'GORNA KREPOST',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6685',
    addressNomenclature: '0',
    x: '25.464716',
    y: '41.724118',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '16273',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНА КРУШИЦА',
    nameEn: 'GORNA KRUSHITSA',
    municipality: 'СТРУМЯНИ',
    municipalityEn: 'STRUMYANI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2827',
    addressNomenclature: '0',
    x: '23.165492',
    y: '41.668182',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '16287',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНА КУЛА',
    nameEn: 'GORNA KULA',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6952',
    addressNomenclature: '0',
    x: '25.600538',
    y: '41.495553',
    servingDays: '1111100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '16290',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНА ЛИПНИЦА',
    nameEn: 'GORNA LIPNITSA',
    municipality: 'ПАВЛИКЕНИ',
    municipalityEn: 'PAVLIKENI',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5227',
    addressNomenclature: '0',
    x: '25.403233',
    y: '43.300419',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '16300',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНА ЛУКА',
    nameEn: 'GORNA LUKA',
    municipality: 'ЧИПРОВЦИ',
    municipalityEn: 'CHIPROVTSI',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3458',
    addressNomenclature: '0',
    x: '22.898561',
    y: '43.449875',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '16314',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНА МАЛИНА',
    nameEn: 'GORNA MALINA',
    municipality: 'ГОРНА МАЛИНА',
    municipalityEn: 'GORNA MALINA',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2131',
    addressNomenclature: '0',
    x: '23.706369',
    y: '42.693707',
    servingDays: '1010100',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '16328',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНА МАХАЛА',
    nameEn: 'GORNA MAHALA',
    municipality: 'КАЛОЯНОВО',
    municipalityEn: 'KALOYANOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4196',
    addressNomenclature: '0',
    x: '24.777389',
    y: '42.444514',
    servingDays: '0101000',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '16331',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНА МЕЛНА',
    nameEn: 'GORNA MELNA',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2491',
    addressNomenclature: '0',
    x: '22.519309',
    y: '42.722764',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '16345',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНА МИТРОПОЛИЯ',
    nameEn: 'GORNA MITROPOLIA',
    municipality: 'ДОЛНА МИТРОПОЛИЯ',
    municipalityEn: 'DOLNA MITROPOLIA',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5869',
    addressNomenclature: '0',
    x: '24.459598',
    y: '43.461925',
    servingDays: '1010100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '16359',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ГОРНА ОРЯХОВИЦА',
    nameEn: 'GORNA ORYAHOVITSA',
    municipality: 'ГОРНА ОРЯХОВИЦА',
    municipalityEn: 'GORNA ORYAHOVITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5100',
    addressNomenclature: '1',
    x: '25.692958',
    y: '43.136885',
    servingDays: '1111110',
    servingOfficeId: '46',
    servingHubOfficeId: '11',
  },
  {
    id: '16362',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНА РИБНИЦА',
    nameEn: 'GORNA RIBNITSA',
    municipality: 'СТРУМЯНИ',
    municipalityEn: 'STRUMYANI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2836',
    addressNomenclature: '0',
    x: '23.036895',
    y: '41.643677',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '16376',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНА РОСИЦА',
    nameEn: 'GORNA ROSITSA',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5448',
    addressNomenclature: '0',
    x: '25.123514',
    y: '42.944855',
    servingDays: '1010100',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '16382',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНА СЕКИРНА',
    nameEn: 'GORNA SEKIRNA',
    municipality: 'БРЕЗНИК',
    municipalityEn: 'BREZNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2399',
    addressNomenclature: '0',
    x: '22.665795',
    y: '42.679176',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '16393',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНА СТУДЕНА',
    nameEn: 'GORNA STUDENA',
    municipality: 'СВИЩОВ',
    municipalityEn: 'SVISHTOV',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5294',
    addressNomenclature: '0',
    x: '25.3546',
    y: '43.424061',
    servingDays: '1010100',
    servingOfficeId: '250',
    servingHubOfficeId: '11',
  },
  {
    id: '16403',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНА СУШИЦА',
    nameEn: 'GORNA SUSHITSA',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2811',
    addressNomenclature: '0',
    x: '23.3863',
    y: '41.555038',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '16417',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНА ХАДЖИЙСКА',
    nameEn: 'GORNA HADZHIYSKA',
    municipality: 'ЗЛАТАРИЦА',
    municipalityEn: 'ZLATARITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5089',
    addressNomenclature: '0',
    x: '25.972931',
    y: '43.037516',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '16420',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНА ХУБАВКА',
    nameEn: 'GORNA HUBAVKA',
    municipality: 'ОМУРТАГ',
    municipalityEn: 'OMURTAG',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7913',
    addressNomenclature: '0',
    x: '26.385341',
    y: '43.141734',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '16448',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНИ БОГРОВ',
    nameEn: 'GORNI BOGROV',
    municipality: 'СТОЛИЧНА',
    municipalityEn: 'STOLICHNA',
    region: 'СОФИЯ (СТОЛИЦА)',
    regionEn: 'SOFIA (STOLITSA)',
    postCode: '1806',
    addressNomenclature: '2',
    x: '23.529121',
    y: '42.717759',
    servingDays: '1111100',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '16451',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНИ ВАДИН',
    nameEn: 'GORNI VADIN',
    municipality: 'ОРЯХОВО',
    municipalityEn: 'ORYAHOVO',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3343',
    addressNomenclature: '0',
    x: '24.242455',
    y: '43.681994',
    servingDays: '1010100',
    servingOfficeId: '64',
    servingHubOfficeId: '20',
  },
  {
    id: '16479',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНИ ВЪРПИЩА',
    nameEn: 'GORNI VARPISHTA',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5380',
    addressNomenclature: '0',
    x: '25.425694',
    y: '42.929766',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '16496',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНИ ГЛАВАНАК',
    nameEn: 'GORNI GLAVANAK',
    municipality: 'МАДЖАРОВО',
    municipalityEn: 'MADZHAROVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6485',
    addressNomenclature: '0',
    x: '25.823743',
    y: '41.677161',
    servingDays: '0101000',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '16506',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНИ ДАМЯНОВЦИ',
    nameEn: 'GORNI DAMYANOVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5367',
    addressNomenclature: '0',
    x: '25.547639',
    y: '42.814724',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '16513',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНИ ДОМЛЯН',
    nameEn: 'GORNI DOMLYAN',
    municipality: 'КАРЛОВО',
    municipalityEn: 'KARLOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4368',
    addressNomenclature: '0',
    x: '24.896477',
    y: '42.567053',
    servingDays: '1010100',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '16523',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНИ ДРАГОЙЧА',
    nameEn: 'GORNI DRAGOYCHA',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5380',
    addressNomenclature: '0',
    x: '25.484176',
    y: '42.922322',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '16537',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНИ ДЪБНИК',
    nameEn: 'GORNI DABNIK',
    municipality: 'ДОЛНИ ДЪБНИК',
    municipalityEn: 'DOLNI DABNIK',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5880',
    addressNomenclature: '0',
    x: '24.352688',
    y: '43.379848',
    servingDays: '1010100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '16554',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНИ КОРИТЕН',
    nameEn: 'GORNI KORITEN',
    municipality: 'ТРЕКЛЯНО',
    municipalityEn: 'TREKLYANO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2557',
    addressNomenclature: '0',
    x: '22.573004',
    y: '42.504543',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '16568',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНИ КРАЙ',
    nameEn: 'GORNI KRAY',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5096',
    addressNomenclature: '0',
    x: '25.753439',
    y: '42.812497',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '16571',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНИ ЛОМ',
    nameEn: 'GORNI LOM',
    municipality: 'ЧУПРЕНЕ',
    municipalityEn: 'CHUPRENE',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3960',
    addressNomenclature: '0',
    x: '22.742556',
    y: '43.482752',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '16585',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНИ МАРЕНЦИ',
    nameEn: 'GORNI MARENTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5360',
    addressNomenclature: '0',
    x: '25.411642',
    y: '42.836233',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '16599',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНИ ОКОЛ',
    nameEn: 'GORNI OKOL',
    municipality: 'САМОКОВ',
    municipalityEn: 'SAMOKOV',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2027',
    addressNomenclature: '0',
    x: '23.51631',
    y: '42.464863',
    servingDays: '0101000',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '16609',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНИ РАДКОВЦИ',
    nameEn: 'GORNI RADKOVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5360',
    addressNomenclature: '0',
    x: '25.422273',
    y: '42.833243',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '16612',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНИ РОМАНЦИ',
    nameEn: 'GORNI ROMANTSI',
    municipality: 'БРЕЗНИК',
    municipalityEn: 'BREZNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2371',
    addressNomenclature: '0',
    x: '22.889308',
    y: '42.782905',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '16626',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНИ ТАНЧЕВЦИ',
    nameEn: 'GORNI TANCHEVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5098',
    addressNomenclature: '0',
    x: '25.91964',
    y: '42.837271',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '16639',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНИ ЦИБЪР',
    nameEn: 'GORNI TSIBAR',
    municipality: 'ВЪЛЧЕДРЪМ',
    municipalityEn: 'VALCHEDRAM',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3632',
    addressNomenclature: '0',
    x: '23.530819',
    y: '43.800558',
    servingDays: '0101000',
    servingOfficeId: '831',
    servingHubOfficeId: '20',
  },
  {
    id: '16643',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНИ ЦОНЕВЦИ',
    nameEn: 'GORNI TSONEVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5360',
    addressNomenclature: '0',
    x: '25.454519',
    y: '42.807805',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '16660',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНИ ЮРУЦИ',
    nameEn: 'GORNI YURUTSI',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6954',
    addressNomenclature: '0',
    x: '25.91827',
    y: '41.348303',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '16540',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНИК',
    nameEn: 'GORNIK',
    municipality: 'ЧЕРВЕН БРЯГ',
    municipalityEn: 'CHERVEN BRYAG',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5991',
    addressNomenclature: '0',
    x: '24.042753',
    y: '43.287641',
    servingDays: '1010100',
    servingOfficeId: '59',
    servingHubOfficeId: '20',
  },
  {
    id: '16674',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНО АБЛАНОВО',
    nameEn: 'GORNO ABLANOVO',
    municipality: 'БОРОВО',
    municipalityEn: 'BOROVO',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7173',
    addressNomenclature: '0',
    x: '25.732302',
    y: '43.632574',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '16688',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНО АЛЕКСАНДРОВО',
    nameEn: 'GORNO ALEKSANDROVO',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8883',
    addressNomenclature: '0',
    x: '26.631436',
    y: '42.653485',
    servingDays: '1111100',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '16691',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНО БЕЛЕВО',
    nameEn: 'GORNO BELEVO',
    municipality: 'БРАТЯ ДАСКАЛОВИ',
    municipalityEn: 'BRATYA DASKALOVI',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6219',
    addressNomenclature: '0',
    x: '25.233387',
    y: '42.262766',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '16701',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНО БОТЕВО',
    nameEn: 'GORNO BOTEVO',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6053',
    addressNomenclature: '0',
    x: '25.804611',
    y: '42.413422',
    servingDays: '1111110',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '16729',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНО ВОЙВОДИНО',
    nameEn: 'GORNO VOYVODINO',
    municipality: 'ХАСКОВО',
    municipalityEn: 'HASKOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6388',
    addressNomenclature: '0',
    x: '25.549714',
    y: '41.777547',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '16732',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНО ВЪРШИЛО',
    nameEn: 'GORNO VARSHILO',
    municipality: 'СЕПТЕМВРИ',
    municipalityEn: 'SEPTEMVRI',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4497',
    addressNomenclature: '0',
    x: '24.003425',
    y: '42.331628',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '16754',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНО ДРАГЛИЩЕ',
    nameEn: 'GORNO DRAGLISHTE',
    municipality: 'РАЗЛОГ',
    municipalityEn: 'RAZLOG',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2797',
    addressNomenclature: '0',
    x: '23.505605',
    y: '41.950849',
    servingDays: '1111100',
    servingOfficeId: '125',
    servingHubOfficeId: '13',
  },
  {
    id: '16763',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНО ДРЯНОВО',
    nameEn: 'GORNO DRYANOVO',
    municipality: 'ГЪРМЕН',
    municipalityEn: 'GARMEN',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2963',
    addressNomenclature: '0',
    x: '23.828981',
    y: '41.651314',
    servingDays: '0101000',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '16780',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНО ИЗВОРОВО',
    nameEn: 'GORNO IZVOROVO',
    municipality: 'КАЗАНЛЪК',
    municipalityEn: 'KAZANLAK',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6181',
    addressNomenclature: '0',
    x: '25.487757',
    y: '42.661772',
    servingDays: '0101000',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '16794',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНО КАМАРЦИ',
    nameEn: 'GORNO KAMARTSI',
    municipality: 'ГОРНА МАЛИНА',
    municipalityEn: 'GORNA MALINA',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2138',
    addressNomenclature: '0',
    x: '23.822093',
    y: '42.756436',
    servingDays: '0101000',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '18558',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНО КИРКОВО',
    nameEn: 'GORNO KIRKOVO',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6884',
    addressNomenclature: '0',
    x: '25.349389',
    y: '41.322839',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '16804',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНО КОБИЛЕ',
    nameEn: 'GORNO KOBILE',
    municipality: 'ТРЕКЛЯНО',
    municipalityEn: 'TREKLYANO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2556',
    addressNomenclature: '0',
    x: '22.519818',
    y: '42.565143',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '16818',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНО КОЗАРЕВО',
    nameEn: 'GORNO KOZAREVO',
    municipality: 'ОМУРТАГ',
    municipalityEn: 'OMURTAG',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7927',
    addressNomenclature: '0',
    x: '26.508059',
    y: '43.099371',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '77596',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНО КРАИЩЕ',
    nameEn: 'GORNO KRAISHTE',
    municipality: 'БЕЛИЦА',
    municipalityEn: 'BELITSA',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2783',
    addressNomenclature: '0',
    x: '23.591405',
    y: '41.901719',
    servingDays: '1111100',
    servingOfficeId: '125',
    servingHubOfficeId: '13',
  },
  {
    id: '16821',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНО КЪПИНОВО',
    nameEn: 'GORNO KAPINOVO',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6871',
    addressNomenclature: '0',
    x: '25.258757',
    y: '41.274416',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '16835',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНО ЛУКОВО',
    nameEn: 'GORNO LUKOVO',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6585',
    addressNomenclature: '0',
    x: '26.086581',
    y: '41.363417',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '16849',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНО НОВКОВО',
    nameEn: 'GORNO NOVKOVO',
    municipality: 'ОМУРТАГ',
    municipalityEn: 'OMURTAG',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7939',
    addressNomenclature: '0',
    x: '26.585178',
    y: '43.095146',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '16852',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНО НОВО СЕЛО',
    nameEn: 'GORNO NOVO SELO',
    municipality: 'БРАТЯ ДАСКАЛОВИ',
    municipalityEn: 'BRATYA DASKALOVI',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6225',
    addressNomenclature: '0',
    x: '25.23302',
    y: '42.453109',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '16866',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНО ОЗИРОВО',
    nameEn: 'GORNO OZIROVO',
    municipality: 'ВЪРШЕЦ',
    municipalityEn: 'VARSHETS',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3547',
    addressNomenclature: '0',
    x: '23.394656',
    y: '43.215306',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '16870',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНО ОСЕНОВО',
    nameEn: 'GORNO OSENOVO',
    municipality: 'СИМИТЛИ',
    municipalityEn: 'SIMITLI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2756',
    addressNomenclature: '0',
    x: '23.264645',
    y: '41.992344',
    servingDays: '0101000',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '16883',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНО ПАВЛИКЕНЕ',
    nameEn: 'GORNO PAVLIKENE',
    municipality: 'ЛОВЕЧ',
    municipalityEn: 'LOVECH',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5549',
    addressNomenclature: '0',
    x: '24.794534',
    y: '43.101459',
    servingDays: '0101000',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '16897',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНО ПЕЩЕНЕ',
    nameEn: 'GORNO PESHTENE',
    municipality: 'ВРАЦА',
    municipalityEn: 'VRATSA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3052',
    addressNomenclature: '0',
    x: '23.70938',
    y: '43.277411',
    servingDays: '1010100',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '16907',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНО ПОЛЕ',
    nameEn: 'GORNO POLE',
    municipality: 'МАДЖАРОВО',
    municipalityEn: 'MADZHAROVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6486',
    addressNomenclature: '0',
    x: '25.853073',
    y: '41.658761',
    servingDays: '0101000',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '16910',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНО ПРАХОВО',
    nameEn: 'GORNO PRAHOVO',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6780',
    addressNomenclature: '0',
    x: '25.156313',
    y: '41.645317',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '16924',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНО САХРАНЕ',
    nameEn: 'GORNO SAHRANE',
    municipality: 'ПАВЕЛ БАНЯ',
    municipalityEn: 'PAVEL BANYA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6151',
    addressNomenclature: '0',
    x: '25.21456',
    y: '42.648236',
    servingDays: '1111100',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '16938',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНО СЕЛО',
    nameEn: 'GORNO SELO',
    municipality: 'ДРАГОМАН',
    municipalityEn: 'DRAGOMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2213',
    addressNomenclature: '0',
    x: '22.778749',
    y: '42.869538',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '16969',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНО СПАНЧЕВО',
    nameEn: 'GORNO SPANCHEVO',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2824',
    addressNomenclature: '0',
    x: '23.500491',
    y: '41.50203',
    servingDays: '1010100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '16986',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНО ТРАПЕ',
    nameEn: 'GORNO TRAPE',
    municipality: 'ТРОЯН',
    municipalityEn: 'TROYAN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5614',
    addressNomenclature: '0',
    x: '24.649788',
    y: '42.876244',
    servingDays: '1010100',
    servingOfficeId: '44',
    servingHubOfficeId: '11',
  },
  {
    id: '16995',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНО УЙНО',
    nameEn: 'GORNO UYNO',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2561',
    addressNomenclature: '0',
    x: '22.57358',
    y: '42.451761',
    servingDays: '0101000',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '17004',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНО ХЪРСОВО',
    nameEn: 'GORNO HARSOVO',
    municipality: 'БЛАГОЕВГРАД',
    municipalityEn: 'BLAGOEVGRAD',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2727',
    addressNomenclature: '0',
    x: '23.178087',
    y: '42.018645',
    servingDays: '1111110',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '17018',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНО ЦЕРОВЕНЕ',
    nameEn: 'GORNO TSEROVENE',
    municipality: 'МОНТАНА',
    municipalityEn: 'MONTANA',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3487',
    addressNomenclature: '0',
    x: '23.117195',
    y: '43.40509',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '49076',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНО ЧЕРКОВИЩЕ',
    nameEn: 'GORNO CHERKOVISHTE',
    municipality: 'КАЗАНЛЪК',
    municipalityEn: 'KAZANLAK',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6165',
    addressNomenclature: '0',
    x: '25.309262',
    y: '42.573991',
    servingDays: '1010100',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '17035',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНО ЯБЪЛКОВО',
    nameEn: 'GORNO YABALKOVO',
    municipality: 'СРЕДЕЦ',
    municipalityEn: 'SREDETS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8343',
    addressNomenclature: '0',
    x: '27.101971',
    y: '42.110408',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '16715',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНОВА МОГИЛА',
    nameEn: 'GORNOVA MOGILA',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5304',
    addressNomenclature: '0',
    x: '25.267011',
    y: '42.843334',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '16941',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНОСЕЛЦИ',
    nameEn: 'GORNOSELTSI',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6574',
    addressNomenclature: '0',
    x: '25.985806',
    y: '41.577205',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '16955',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНОСЛАВ',
    nameEn: 'GORNOSLAV',
    municipality: 'АСЕНОВГРАД',
    municipalityEn: 'ASENOVGRAD',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4253',
    addressNomenclature: '0',
    x: '24.957582',
    y: '41.922139',
    servingDays: '0101000',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '16972',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРНОТО СЕЛО',
    nameEn: 'GORNOTO SELO',
    municipality: 'ТЕТЕВЕН',
    municipalityEn: 'TETEVEN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '',
    addressNomenclature: '0',
    x: '24.131005',
    y: '42.958845',
    servingDays: '0101000',
    servingOfficeId: '88',
    servingHubOfficeId: '2',
  },
  {
    id: '17049',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРОВО',
    nameEn: 'GOROVO',
    municipality: 'СУНГУРЛАРЕ',
    municipalityEn: 'SUNGURLARE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8469',
    addressNomenclature: '0',
    x: '26.835128',
    y: '42.739633',
    servingDays: '0101000',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '17052',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРОВО',
    nameEn: 'GOROVO',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4776',
    addressNomenclature: '0',
    x: '24.732677',
    y: '41.435855',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '17066',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРОЦВЕТ',
    nameEn: 'GOROTSVET',
    municipality: 'ЛОЗНИЦА',
    municipalityEn: 'LOZNITSA',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7288',
    addressNomenclature: '0',
    x: '26.6372',
    y: '43.435288',
    servingDays: '0101000',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '17077',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРОЧЕВЦИ',
    nameEn: 'GOROCHEVTSI',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2496',
    addressNomenclature: '0',
    x: '22.589633',
    y: '42.707114',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '17083',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРСКА',
    nameEn: 'GORSKA',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5083',
    addressNomenclature: '0',
    x: '26.007999',
    y: '42.897054',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '17097',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРСКА ПОЛЯНА',
    nameEn: 'GORSKA POLYANA',
    municipality: 'БОЛЯРОВО',
    municipalityEn: 'BOLYAROVO',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8721',
    addressNomenclature: '0',
    x: '26.93963',
    y: '42.116836',
    servingDays: '0101000',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '17107',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРСКИ ГОРЕН ТРЪМБЕШ',
    nameEn: 'GORSKI GOREN TRAMBESH',
    municipality: 'ГОРНА ОРЯХОВИЦА',
    municipalityEn: 'GORNA ORYAHOVITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5132',
    addressNomenclature: '0',
    x: '25.840223',
    y: '43.222708',
    servingDays: '1010100',
    servingOfficeId: '46',
    servingHubOfficeId: '11',
  },
  {
    id: '17124',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРСКИ ДОЛЕН ТРЪМБЕШ',
    nameEn: 'GORSKI DOLEN TRAMBESH',
    municipality: 'ГОРНА ОРЯХОВИЦА',
    municipalityEn: 'GORNA ORYAHOVITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5131',
    addressNomenclature: '0',
    x: '25.836045',
    y: '43.209451',
    servingDays: '1010100',
    servingOfficeId: '46',
    servingHubOfficeId: '11',
  },
  {
    id: '17138',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРСКИ ИЗВОР',
    nameEn: 'GORSKI IZVOR',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6865',
    addressNomenclature: '0',
    x: '25.161388',
    y: '41.350139',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '17141',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРСКИ ИЗВОР',
    nameEn: 'GORSKI IZVOR',
    municipality: 'ДИМИТРОВГРАД',
    municipalityEn: 'DIMITROVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6420',
    addressNomenclature: '0',
    x: '25.425047',
    y: '42.021401',
    servingDays: '1010100',
    servingOfficeId: '42',
    servingHubOfficeId: '5',
  },
  {
    id: '17155',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРСКИ СЕНОВЕЦ',
    nameEn: 'GORSKI SENOVETS',
    municipality: 'СТРАЖИЦА',
    municipalityEn: 'STRAZHITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5167',
    addressNomenclature: '0',
    x: '25.896397',
    y: '43.28003',
    servingDays: '1010100',
    servingOfficeId: '46',
    servingHubOfficeId: '11',
  },
  {
    id: '17169',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРСКО',
    nameEn: 'GORSKO',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6590',
    addressNomenclature: '0',
    x: '25.836235',
    y: '41.421497',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '17172',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРСКО АБЛАНОВО',
    nameEn: 'GORSKO ABLANOVO',
    municipality: 'ОПАКА',
    municipalityEn: 'OPAKA',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7847',
    addressNomenclature: '0',
    x: '26.07544',
    y: '43.499649',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '17186',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРСКО ДЮЛЕВО',
    nameEn: 'GORSKO DYULEVO',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6800',
    addressNomenclature: '0',
    x: '25.410827',
    y: '41.456285',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '17192',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРСКО КАЛУГЕРОВО',
    nameEn: 'GORSKO KALUGEROVO',
    municipality: 'СУХИНДОЛ',
    municipalityEn: 'SUHINDOL',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5238',
    addressNomenclature: '0',
    x: '25.231237',
    y: '43.11576',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '17208',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРСКО КОСОВО',
    nameEn: 'GORSKO KOSOVO',
    municipality: 'СУХИНДОЛ',
    municipalityEn: 'SUHINDOL',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5235',
    addressNomenclature: '0',
    x: '25.169218',
    y: '43.127403',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '17213',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРСКО НОВО СЕЛО',
    nameEn: 'GORSKO NOVO SELO',
    municipality: 'ЗЛАТАРИЦА',
    municipalityEn: 'ZLATARITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5075',
    addressNomenclature: '0',
    x: '25.939081',
    y: '43.088145',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '17227',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРСКО ПИСАРЕВО',
    nameEn: 'GORSKO PISAREVO',
    municipality: 'ЗЛАТАРИЦА',
    municipalityEn: 'ZLATARITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5088',
    addressNomenclature: '0',
    x: '26.040823',
    y: '43.046473',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '17230',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРСКО СЕЛО',
    nameEn: 'GORSKO SELO',
    municipality: 'ОМУРТАГ',
    municipalityEn: 'OMURTAG',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7947',
    addressNomenclature: '0',
    x: '26.357842',
    y: '43.043079',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '17244',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРСКО СЛИВОВО',
    nameEn: 'GORSKO SLIVOVO',
    municipality: 'ЛЕТНИЦА',
    municipalityEn: 'LETNITSA',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5557',
    addressNomenclature: '0',
    x: '25.069569',
    y: '43.20553',
    servingDays: '0101000',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '17258',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРТАЛОВО',
    nameEn: 'GORTALOVO',
    municipality: 'ПЛЕВЕН',
    municipalityEn: 'PLEVEN',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5888',
    addressNomenclature: '0',
    x: '24.561572',
    y: '43.318973',
    servingDays: '0101000',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '17275',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРУН',
    nameEn: 'GORUN',
    municipality: 'ШАБЛА',
    municipalityEn: 'SHABLA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9689',
    addressNomenclature: '0',
    x: '28.485278',
    y: '43.495746',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '17289',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРУНАКА',
    nameEn: 'GORUNAKA',
    municipality: 'ЕТРОПОЛЕ',
    municipalityEn: 'ETROPOLE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2191',
    addressNomenclature: '0',
    x: '23.969522',
    y: '42.862505',
    servingDays: '0101000',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '17292',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРУНЬОВО',
    nameEn: 'GORUNYOVO',
    municipality: 'ТЕТЕВЕН',
    municipalityEn: 'TETEVEN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '',
    addressNomenclature: '0',
    x: '24.312614',
    y: '42.840247',
    servingDays: '1010100',
    servingOfficeId: '88',
    servingHubOfficeId: '2',
  },
  {
    id: '17302',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРЧЕВО',
    nameEn: 'GORCHEVO',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2882',
    addressNomenclature: '0',
    x: '23.026709',
    y: '41.449866',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '17316',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОРЯНИ',
    nameEn: 'GORYANI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5367',
    addressNomenclature: '0',
    x: '25.567902',
    y: '42.835121',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '17323',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОСПОДИНОВО',
    nameEn: 'GOSPODINOVO',
    municipality: 'БЯЛА',
    municipalityEn: 'BYALA',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9107',
    addressNomenclature: '0',
    x: '27.770637',
    y: '42.896917',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '17333',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОСПОДИНОВО',
    nameEn: 'GOSPODINOVO',
    municipality: 'КАЙНАРДЖА',
    municipalityEn: 'KAYNARDZHA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7561',
    addressNomenclature: '0',
    x: '27.487559',
    y: '43.922519',
    servingDays: '0101000',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '17347',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОСПОДИНЦИ',
    nameEn: 'GOSPODINTSI',
    municipality: 'ГОЦЕ ДЕЛЧЕВ',
    municipalityEn: 'GOTSE DELCHEV',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2914',
    addressNomenclature: '0',
    x: '23.729634',
    y: '41.657439',
    servingDays: '1111100',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '17350',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОСТИЛИЦА',
    nameEn: 'GOSTILITSA',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5390',
    addressNomenclature: '0',
    x: '25.367876',
    y: '43.023921',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '17364',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОСТИЛЯ',
    nameEn: 'GOSTILYA',
    municipality: 'ДОЛНА МИТРОПОЛИЯ',
    municipalityEn: 'DOLNA MITROPOLIA',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5864',
    addressNomenclature: '0',
    x: '24.223274',
    y: '43.565978',
    servingDays: '0101000',
    servingOfficeId: '59',
    servingHubOfficeId: '20',
  },
  {
    id: '17378',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОСТИНЯ',
    nameEn: 'GOSTINYA',
    municipality: 'ЛОВЕЧ',
    municipalityEn: 'LOVECH',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5543',
    addressNomenclature: '0',
    x: '24.835152',
    y: '43.151715',
    servingDays: '0101000',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '17381',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГОСТУН',
    nameEn: 'GOSTUN',
    municipality: 'БАНСКО',
    municipalityEn: 'BANSKO',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2773',
    addressNomenclature: '0',
    x: '23.690838',
    y: '41.798226',
    servingDays: '0101000',
    servingOfficeId: '125',
    servingHubOfficeId: '13',
  },
  {
    id: '17395',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ГОЦЕ ДЕЛЧЕВ',
    nameEn: 'GOTSE DELCHEV',
    municipality: 'ГОЦЕ ДЕЛЧЕВ',
    municipalityEn: 'GOTSE DELCHEV',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2900',
    addressNomenclature: '1',
    x: '23.728899',
    y: '41.573651',
    servingDays: '1111110',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '17405',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРАДЕВО',
    nameEn: 'GRADEVO',
    municipality: 'СИМИТЛИ',
    municipalityEn: 'SIMITLI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2755',
    addressNomenclature: '0',
    x: '23.194825',
    y: '41.917779',
    servingDays: '1010100',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '17419',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРАДЕЖНИЦА',
    nameEn: 'GRADEZHNITSA',
    municipality: 'ТЕТЕВЕН',
    municipalityEn: 'TETEVEN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5732',
    addressNomenclature: '0',
    x: '24.234709',
    y: '43.019113',
    servingDays: '0101000',
    servingOfficeId: '88',
    servingHubOfficeId: '2',
  },
  {
    id: '17422',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРАДЕЦ',
    nameEn: 'GRADETS',
    municipality: 'ВИДИН',
    municipalityEn: 'VIDIN',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3780',
    addressNomenclature: '0',
    x: '22.753695',
    y: '44.031738',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '17436',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРАДЕЦ',
    nameEn: 'GRADETS',
    municipality: 'КОТЕЛ',
    municipalityEn: 'KOTEL',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8990',
    addressNomenclature: '0',
    x: '26.540571',
    y: '42.793989',
    servingDays: '0101000',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '17449',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРАДЕЦ',
    nameEn: 'GRADETS',
    municipality: 'КОСТИНБРОД',
    municipalityEn: 'KOSTINBROD',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2239',
    addressNomenclature: '0',
    x: '23.205688',
    y: '42.88165',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '17453',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРАДЕШНИЦА',
    nameEn: 'GRADESHNITSA',
    municipality: 'КРИВОДОЛ',
    municipalityEn: 'KRIVODOL',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3074',
    addressNomenclature: '0',
    x: '23.479924',
    y: '43.483794',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '17470',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРАДИНА',
    nameEn: 'GRADINA',
    municipality: 'ДОЛНИ ДЪБНИК',
    municipalityEn: 'DOLNI DABNIK',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5879',
    addressNomenclature: '0',
    x: '24.479301',
    y: '43.352005',
    servingDays: '1010100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '17484',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРАДИНА',
    nameEn: 'GRADINA',
    municipality: 'ПЪРВОМАЙ',
    municipalityEn: 'PARVOMAY',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4290',
    addressNomenclature: '0',
    x: '25.208167',
    y: '42.145694',
    servingDays: '1010100',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '17498',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРАДИНА',
    nameEn: 'GRADINA',
    municipality: 'ЛОЗНИЦА',
    municipalityEn: 'LOZNITSA',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7239',
    addressNomenclature: '0',
    x: '26.569588',
    y: '43.44297',
    servingDays: '0101000',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '17508',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРАДИНАРОВО',
    nameEn: 'GRADINAROVO',
    municipality: 'ПРОВАДИЯ',
    municipalityEn: 'PROVADIA',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9243',
    addressNomenclature: '0',
    x: '27.279516',
    y: '43.142619',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '17511',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРАДИНИ',
    nameEn: 'GRADINI',
    municipality: 'ГЕНЕРАЛ ТОШЕВО',
    municipalityEn: 'GENERAL TOSHEVO',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9552',
    addressNomenclature: '0',
    x: '27.881698',
    y: '43.781064',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '17542',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРАДИЩЕ',
    nameEn: 'GRADISHTE',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5435',
    addressNomenclature: '0',
    x: '25.013714',
    y: '43.095945',
    servingDays: '0101000',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '17556',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРАДИЩЕ',
    nameEn: 'GRADISHTE',
    municipality: 'ЛЕВСКИ',
    municipalityEn: 'LEVSKI',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5910',
    addressNomenclature: '0',
    x: '25.203909',
    y: '43.323996',
    servingDays: '0101000',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '17573',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРАДИЩЕ',
    nameEn: 'GRADISHTE',
    municipality: 'ШУМЕН',
    municipalityEn: 'SHUMEN',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9803',
    addressNomenclature: '0',
    x: '26.838992',
    y: '43.326798',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '17587',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРАДНИЦА',
    nameEn: 'GRADNITSA',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5439',
    addressNomenclature: '0',
    x: '24.956423',
    y: '42.957661',
    servingDays: '1010100',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '17590',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРАДНИЦА',
    nameEn: 'GRADNITSA',
    municipality: 'ТЕРВЕЛ',
    municipalityEn: 'TERVEL',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9498',
    addressNomenclature: '0',
    x: '27.22483',
    y: '43.723796',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '17600',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРАДСКО',
    nameEn: 'GRADSKO',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8852',
    addressNomenclature: '0',
    x: '26.124067',
    y: '42.766401',
    servingDays: '0101000',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '17614',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРАДСКОВСКИ КОЛИБИ',
    nameEn: 'GRADSKOVSKI KOLIBI',
    municipality: 'БОЙНИЦА',
    municipalityEn: 'BOYNITSA',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3835',
    addressNomenclature: '0',
    x: '22.443464',
    y: '43.999022',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '17631',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРАДЪТ',
    nameEn: 'GRADAT',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4749',
    addressNomenclature: '0',
    x: '24.840135',
    y: '41.587091',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '17645',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ГРАМАДА',
    nameEn: 'GRAMADA',
    municipality: 'ГРАМАДА',
    municipalityEn: 'GRAMADA',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3830',
    addressNomenclature: '2',
    x: '22.659879',
    y: '43.834117',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '17659',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРАМАДЕ',
    nameEn: 'GRAMADE',
    municipality: 'ДУПНИЦА',
    municipalityEn: 'DUPNITSA',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2679',
    addressNomenclature: '0',
    x: '23.035398',
    y: '42.216547',
    servingDays: '0101000',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '17676',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРАМАДЕ',
    nameEn: 'GRAMADE',
    municipality: 'РУДОЗЕМ',
    municipalityEn: 'RUDOZEM',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4968',
    addressNomenclature: '0',
    x: '24.838364',
    y: '41.450561',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '17680',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРАМАЖДАНО',
    nameEn: 'GRAMAZHDANO',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2574',
    addressNomenclature: '0',
    x: '22.650913',
    y: '42.259414',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '17693',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРАМАТИКОВО',
    nameEn: 'GRAMATIKOVO',
    municipality: 'МАЛКО ТЪРНОВО',
    municipalityEn: 'MALKO TARNOVO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8166',
    addressNomenclature: '0',
    x: '27.65428',
    y: '42.062624',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '17703',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРАМАТИЦИ',
    nameEn: 'GRAMATITSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5099',
    addressNomenclature: '0',
    x: '25.989167',
    y: '42.896463',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '17717',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРАНИТ',
    nameEn: 'GRANIT',
    municipality: 'БРАТЯ ДАСКАЛОВИ',
    municipalityEn: 'BRATYA DASKALOVI',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6252',
    addressNomenclature: '0',
    x: '25.136755',
    y: '42.252909',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '17720',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРАНИТЕЦ',
    nameEn: 'GRANITETS',
    municipality: 'СРЕДЕЦ',
    municipalityEn: 'SREDETS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8333',
    addressNomenclature: '0',
    x: '26.960068',
    y: '42.244418',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '17734',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРАНИТОВО',
    nameEn: 'GRANITOVO',
    municipality: 'БЕЛОГРАДЧИК',
    municipalityEn: 'BELOGRADCHIK',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3914',
    addressNomenclature: '0',
    x: '22.692061',
    y: '43.677852',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '17748',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРАНИТОВО',
    nameEn: 'GRANITOVO',
    municipality: 'ЕЛХОВО',
    municipalityEn: 'ELHOVO',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8740',
    addressNomenclature: '0',
    x: '26.533295',
    y: '42.100802',
    servingDays: '1010100',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '17751',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРАНИЦА',
    nameEn: 'GRANITSA',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2589',
    addressNomenclature: '0',
    x: '22.743737',
    y: '42.255129',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '17765',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРАНИЧАК',
    nameEn: 'GRANICHAK',
    municipality: 'БЕЛОГРАДЧИК',
    municipalityEn: 'BELOGRADCHIK',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3934',
    addressNomenclature: '0',
    x: '22.587153',
    y: '43.6432',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '17779',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРАНИЧАР',
    nameEn: 'GRANICHAR',
    municipality: 'СРЕДЕЦ',
    municipalityEn: 'SREDETS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8347',
    addressNomenclature: '0',
    x: '27.242676',
    y: '42.135246',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '17782',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРАНИЧАР',
    nameEn: 'GRANICHAR',
    municipality: 'ШАБЛА',
    municipalityEn: 'SHABLA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9669',
    addressNomenclature: '0',
    x: '28.502097',
    y: '43.721904',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '17806',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРАФ ИГНАТИЕВО',
    nameEn: 'GRAF IGNATIEVO',
    municipality: 'МАРИЦА',
    municipalityEn: 'MARITSA',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4198',
    addressNomenclature: '0',
    x: '24.733752',
    y: '42.283956',
    servingDays: '1111100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '17810',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРАФИТОВО',
    nameEn: 'GRAFITOVO',
    municipality: 'НОВА ЗАГОРА',
    municipalityEn: 'NOVA ZAGORA',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '',
    addressNomenclature: '0',
    x: '26.111314',
    y: '42.452164',
    servingDays: '0101000',
    servingOfficeId: '56',
    servingHubOfficeId: '5',
  },
  {
    id: '17823',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРАШЕВО',
    nameEn: 'GRASHEVO',
    municipality: 'ВЕЛИНГРАД',
    municipalityEn: 'VELINGRAD',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4618',
    addressNomenclature: '0',
    x: '23.923588',
    y: '41.958822',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '17837',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРАЩИЦА',
    nameEn: 'GRASHTITSA',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4714',
    addressNomenclature: '0',
    x: '24.620452',
    y: '41.652717',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '17840',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРЕЕВЦИ',
    nameEn: 'GREEVTSI',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7960',
    addressNomenclature: '0',
    x: '26.216725',
    y: '43.020898',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '17854',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРИВИЦА',
    nameEn: 'GRIVITSA',
    municipality: 'ПЛЕВЕН',
    municipalityEn: 'PLEVEN',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5841',
    addressNomenclature: '0',
    x: '24.705414',
    y: '43.413207',
    servingDays: '0101000',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '17868',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРИВКА',
    nameEn: 'GRIVKA',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6965',
    addressNomenclature: '0',
    x: '25.49229',
    y: '41.375178',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '17871',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРИВЯК',
    nameEn: 'GRIVYAK',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6890',
    addressNomenclature: '0',
    x: '25.471492',
    y: '41.38901',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '17885',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРИГОРЕВО',
    nameEn: 'GRIGOREVO',
    municipality: 'ЕЛИН ПЕЛИН',
    municipalityEn: 'ELIN PELIN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2108',
    addressNomenclature: '0',
    x: '23.601599',
    y: '42.693187',
    servingDays: '1010100',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '17909',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРОЗДЕН',
    nameEn: 'GROZDEN',
    municipality: 'СУНГУРЛАРЕ',
    municipalityEn: 'SUNGURLARE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8464',
    addressNomenclature: '0',
    x: '26.753404',
    y: '42.720943',
    servingDays: '0101000',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '17912',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРОЗДЬОВО',
    nameEn: 'GROZDYOVO',
    municipality: 'ДОЛНИ ЧИФЛИК',
    municipalityEn: 'DOLNI CHIFLIK',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9106',
    addressNomenclature: '0',
    x: '27.54093',
    y: '43.026019',
    servingDays: '1111100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '17943',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРОМШИН',
    nameEn: 'GROMSHIN',
    municipality: 'БОЙЧИНОВЦИ',
    municipalityEn: 'BOYCHINOVTSI',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3444',
    addressNomenclature: '0',
    x: '23.49149',
    y: '43.525744',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '17957',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРОХОТНО',
    nameEn: 'GROHOTNO',
    municipality: 'ДЕВИН',
    municipalityEn: 'DEVIN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4815',
    addressNomenclature: '0',
    x: '24.378413',
    y: '41.698949',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '17988',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРУЕВО',
    nameEn: 'GRUEVO',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6809',
    addressNomenclature: '0',
    x: '25.405532',
    y: '41.566061',
    servingDays: '1111110',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '17991',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРЪБЛЕВЦИ',
    nameEn: 'GRABLEVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5300',
    addressNomenclature: '0',
    x: '25.314153',
    y: '42.93566',
    servingDays: '1111110',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '18006',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРЪЛСКА ПАДИНА',
    nameEn: 'GRALSKA PADINA',
    municipality: 'ДРАГОМАН',
    municipalityEn: 'DRAGOMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2213',
    addressNomenclature: '0',
    x: '22.766703',
    y: '42.890545',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '18018',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГРЪНЧАРОВО',
    nameEn: 'GRANCHAROVO',
    municipality: 'ДУЛОВО',
    municipalityEn: 'DULOVO',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7669',
    addressNomenclature: '0',
    x: '27.13108',
    y: '43.731271',
    servingDays: '0101000',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '18037',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГУБЕШ',
    nameEn: 'GUBESH',
    municipality: 'ГОДЕЧ',
    municipalityEn: 'GODECH',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2255',
    addressNomenclature: '0',
    x: '23.042878',
    y: '43.077522',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '18040',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГУБИСЛАВ',
    nameEn: 'GUBISLAV',
    municipality: 'СВОГЕ',
    municipalityEn: 'SVOGE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2274',
    addressNomenclature: '0',
    x: '23.314669',
    y: '43.09721',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '18054',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГУГУТКА',
    nameEn: 'GUGUTKA',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6590',
    addressNomenclature: '0',
    x: '25.923914',
    y: '41.416824',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '18068',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГУДЕВИЦА',
    nameEn: 'GUDEVITSA',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4792',
    addressNomenclature: '0',
    x: '24.650602',
    y: '41.452882',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '18071',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГУЛИЙКА',
    nameEn: 'GULIYKA',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6943',
    addressNomenclature: '0',
    x: '25.722069',
    y: '41.451041',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '18085',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГУЛИЯ',
    nameEn: 'GULIA',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6926',
    addressNomenclature: '0',
    x: '25.683664',
    y: '41.418838',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '18099',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ГУЛЯНЦИ',
    nameEn: 'GULYANTSI',
    municipality: 'ГУЛЯНЦИ',
    municipalityEn: 'GULYANTSI',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5960',
    addressNomenclature: '2',
    x: '24.690531',
    y: '43.637317',
    servingDays: '1111100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '18109',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГУМОЩНИК',
    nameEn: 'GUMOSHTNIK',
    municipality: 'ТРОЯН',
    municipalityEn: 'TROYAN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5654',
    addressNomenclature: '0',
    x: '24.852152',
    y: '42.926521',
    servingDays: '1010100',
    servingOfficeId: '44',
    servingHubOfficeId: '11',
  },
  {
    id: '18112',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГУРБАНОВЦИ',
    nameEn: 'GURBANOVTSI',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2567',
    addressNomenclature: '0',
    x: '22.475148',
    y: '42.354808',
    servingDays: '0101000',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '18126',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГУРГУЛИЦА',
    nameEn: 'GURGULITSA',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6830',
    addressNomenclature: '0',
    x: '25.548771',
    y: '41.585138',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '18133',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГУРГУЛЯТ',
    nameEn: 'GURGULYAT',
    municipality: 'СЛИВНИЦА',
    municipalityEn: 'SLIVNITSA',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2223',
    addressNomenclature: '0',
    x: '22.978421',
    y: '42.79228',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '18143',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГУРКОВО',
    nameEn: 'GURKOVO',
    municipality: 'БОТЕВГРАД',
    municipalityEn: 'BOTEVGRAD',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2145',
    addressNomenclature: '0',
    x: '23.688661',
    y: '42.975659',
    servingDays: '0101000',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '18157',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ГУРКОВО',
    nameEn: 'GURKOVO',
    municipality: 'ГУРКОВО',
    municipalityEn: 'GURKOVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6199',
    addressNomenclature: '2',
    x: '25.795722',
    y: '42.657393',
    servingDays: '1111100',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '18160',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГУРКОВО',
    nameEn: 'GURKOVO',
    municipality: 'БАЛЧИК',
    municipalityEn: 'BALCHIK',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9644',
    addressNomenclature: '0',
    x: '28.220638',
    y: '43.485477',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '18174',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГУРМАЗОВО',
    nameEn: 'GURMAZOVO',
    municipality: 'БОЖУРИЩЕ',
    municipalityEn: 'BOZHURISHTE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2226',
    addressNomenclature: '0',
    x: '23.171451',
    y: '42.744367',
    servingDays: '1111100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '18188',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГУСЛА',
    nameEn: 'GUSLA',
    municipality: 'КАОЛИНОВО',
    municipalityEn: 'KAOLINOVO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9969',
    addressNomenclature: '0',
    x: '27.055617',
    y: '43.666983',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '18191',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГУСЛАР',
    nameEn: 'GUSLAR',
    municipality: 'ТЕРВЕЛ',
    municipalityEn: 'TERVEL',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9479',
    addressNomenclature: '0',
    x: '27.44163',
    y: '43.836931',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '18201',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГУЦАЛ',
    nameEn: 'GUTSAL',
    municipality: 'САМОКОВ',
    municipalityEn: 'SAMOKOV',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2045',
    addressNomenclature: '0',
    x: '23.699876',
    y: '42.343117',
    servingDays: '0101000',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '18215',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЪБЕНЕ',
    nameEn: 'GABENE',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5333',
    addressNomenclature: '0',
    x: '25.156803',
    y: '42.910202',
    servingDays: '1010100',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '18229',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЪЛЪБЕЦ',
    nameEn: 'GALABETS',
    municipality: 'ПОМОРИЕ',
    municipalityEn: 'POMORIE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8220',
    addressNomenclature: '0',
    x: '27.52923',
    y: '42.770314',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '18232',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЪЛЪБЕЦ',
    nameEn: 'GALABETS',
    municipality: 'ХАСКОВО',
    municipalityEn: 'HASKOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6366',
    addressNomenclature: '0',
    x: '25.437036',
    y: '41.843551',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '18259',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЪЛЪБИНЦИ',
    nameEn: 'GALABINTSI',
    municipality: 'ТУНДЖА',
    municipalityEn: 'TUNDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8642',
    addressNomenclature: '0',
    x: '26.258717',
    y: '42.427653',
    servingDays: '0101000',
    servingOfficeId: '56',
    servingHubOfficeId: '5',
  },
  {
    id: '18263',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЪЛЪБНИК',
    nameEn: 'GALABNIK',
    municipality: 'РАДОМИР',
    municipalityEn: 'RADOMIR',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2426',
    addressNomenclature: '0',
    x: '23.079113',
    y: '42.402529',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '850',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЪЛЪБОВО',
    nameEn: 'GALABOVO',
    municipality: 'БАНИТЕ',
    municipalityEn: 'BANITE',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4938',
    addressNomenclature: '0',
    x: '25.075719',
    y: '41.625527',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '15638',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЪЛЪБОВО',
    nameEn: 'GALABOVO',
    municipality: 'БЕЛИЦА',
    municipalityEn: 'BELITSA',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2784',
    addressNomenclature: '0',
    x: '23.655425',
    y: '41.969542',
    servingDays: '1010100',
    servingOfficeId: '125',
    servingHubOfficeId: '13',
  },
  {
    id: '18277',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЪЛЪБОВО',
    nameEn: 'GALABOVO',
    municipality: 'КУКЛЕН',
    municipalityEn: 'KUKLEN',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4125',
    addressNomenclature: '0',
    x: '24.72068',
    y: '42.024815',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '18280',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ГЪЛЪБОВО',
    nameEn: 'GALABOVO',
    municipality: 'ГЪЛЪБОВО',
    municipalityEn: 'GALABOVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6280',
    addressNomenclature: '2',
    x: '25.875236',
    y: '42.142884',
    servingDays: '1111110',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '18294',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЪЛЪБОВЦИ',
    nameEn: 'GALABOVTSI',
    municipality: 'СЛИВНИЦА',
    municipalityEn: 'SLIVNITSA',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2223',
    addressNomenclature: '0',
    x: '23.006402',
    y: '42.810255',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '18304',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЪМЗОВО',
    nameEn: 'GAMZOVO',
    municipality: 'БРЕГОВО',
    municipalityEn: 'BREGOVO',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3785',
    addressNomenclature: '0',
    x: '22.760544',
    y: '44.097004',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '18318',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЪРБИНО',
    nameEn: 'GARBINO',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2550',
    addressNomenclature: '0',
    x: '22.713282',
    y: '42.413165',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '18321',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЪРБИЩЕ',
    nameEn: 'GARBISHTE',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6760',
    addressNomenclature: '0',
    x: '25.153405',
    y: '41.534363',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '18335',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЪРДЕВЦИ',
    nameEn: 'GARDEVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5087',
    addressNomenclature: '0',
    x: '25.780066',
    y: '42.916833',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '18349',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЪРЛО',
    nameEn: 'GARLO',
    municipality: 'БРЕЗНИК',
    municipalityEn: 'BREZNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2381',
    addressNomenclature: '0',
    x: '22.848043',
    y: '42.760849',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '18352',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЪРЛЯНО',
    nameEn: 'GARLYANO',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2568',
    addressNomenclature: '0',
    x: '22.550867',
    y: '42.254271',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '18366',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЪРМЕН',
    nameEn: 'GARMEN',
    municipality: 'ГЪРМЕН',
    municipalityEn: 'GARMEN',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2960',
    addressNomenclature: '2',
    x: '23.808183',
    y: '41.595574',
    servingDays: '1111100',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '18374',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЪРНАТИ',
    nameEn: 'GARNATI',
    municipality: 'НЕДЕЛИНО',
    municipalityEn: 'NEDELINO',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4991',
    addressNomenclature: '0',
    x: '25.144182',
    y: '41.492533',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '18397',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЪРНЯ',
    nameEn: 'GARNYA',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5370',
    addressNomenclature: '0',
    x: '25.50139',
    y: '42.973278',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '18407',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЪРЧИНОВО',
    nameEn: 'GARCHINOVO',
    municipality: 'ОПАКА',
    municipalityEn: 'OPAKA',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7844',
    addressNomenclature: '0',
    x: '26.14244',
    y: '43.508543',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '18410',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЪСКОВО',
    nameEn: 'GASKOVO',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6651',
    addressNomenclature: '0',
    x: '25.44174',
    y: '41.664096',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '18424',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЬОВРЕН',
    nameEn: 'GYOVREN',
    municipality: 'ДЕВИН',
    municipalityEn: 'DEVIN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4811',
    addressNomenclature: '0',
    x: '24.372773',
    y: '41.662374',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '18455',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЮЕШЕВО',
    nameEn: 'GYUESHEVO',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2570',
    addressNomenclature: '0',
    x: '22.473586',
    y: '42.238868',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '18469',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЮЛЬОВЦА',
    nameEn: 'GYULYOVTSA',
    municipality: 'НЕСЕБЪР',
    municipalityEn: 'NESEBAR',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8249',
    addressNomenclature: '0',
    x: '27.599129',
    y: '42.769398',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '18472',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЮРГЕВО',
    nameEn: 'GYURGEVO',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '',
    addressNomenclature: '0',
    x: '23.156205',
    y: '41.480955',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '18486',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ГЮРГИЧ',
    nameEn: 'GYURGICH',
    municipality: 'РУЖИНЦИ',
    municipalityEn: 'RUZHINTSI',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3932',
    addressNomenclature: '0',
    x: '22.873473',
    y: '43.550374',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '20027',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДАВЕРИ',
    nameEn: 'DAVERI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5083',
    addressNomenclature: '0',
    x: '25.995342',
    y: '42.937395',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '20033',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДАВИДКОВО',
    nameEn: 'DAVIDKOVO',
    municipality: 'БАНИТЕ',
    municipalityEn: 'BANITE',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4944',
    addressNomenclature: '0',
    x: '24.963992',
    y: '41.666592',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '20047',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДАВИДОВО',
    nameEn: 'DAVIDOVO',
    municipality: 'КАЙНАРДЖА',
    municipalityEn: 'KAYNARDZHA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7563',
    addressNomenclature: '0',
    x: '27.463245',
    y: '43.882812',
    servingDays: '0101000',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '20050',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДАВИДОВО',
    nameEn: 'DAVIDOVO',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7758',
    addressNomenclature: '0',
    x: '26.561428',
    y: '43.290696',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '20064',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДАГОНОВО',
    nameEn: 'DAGONOVO',
    municipality: 'БЕЛИЦА',
    municipalityEn: 'BELITSA',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2779',
    addressNomenclature: '0',
    x: '23.593466',
    y: '41.954382',
    servingDays: '1111100',
    servingOfficeId: '125',
    servingHubOfficeId: '13',
  },
  {
    id: '20078',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДАЕВЦИ',
    nameEn: 'DAEVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5365',
    addressNomenclature: '0',
    x: '25.552038',
    y: '42.879402',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '20081',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДАЙНОВЦИ',
    nameEn: 'DAYNOVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5084',
    addressNomenclature: '0',
    x: '25.983053',
    y: '42.994009',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '20105',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДАМЯНИЦА',
    nameEn: 'DAMYANITSA',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2813',
    addressNomenclature: '0',
    x: '23.267242',
    y: '41.509499',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '20119',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДАМЯНОВО',
    nameEn: 'DAMYANOVO',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5437',
    addressNomenclature: '0',
    x: '24.931879',
    y: '43.001506',
    servingDays: '1010100',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '20153',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДАСКАЛ АТАНАСОВО',
    nameEn: 'DASKAL ATANASOVO',
    municipality: 'РАДНЕВО',
    municipalityEn: 'RADNEVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6261',
    addressNomenclature: '0',
    x: '25.900047',
    y: '42.341144',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '20167',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДАСКАЛОВО',
    nameEn: 'DASKALOVO',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6707',
    addressNomenclature: '0',
    x: '25.282468',
    y: '41.783198',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '20184',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ДВЕ МОГИЛИ',
    nameEn: 'DVE MOGILI',
    municipality: 'ДВЕ МОГИЛИ',
    municipalityEn: 'DVE MOGILI',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7150',
    addressNomenclature: '2',
    x: '25.874962',
    y: '43.593614',
    servingDays: '1111100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '20198',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДВЕ ТОПОЛИ',
    nameEn: 'DVE TOPOLI',
    municipality: 'БАНИТЕ',
    municipalityEn: 'BANITE',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4949',
    addressNomenclature: '0',
    x: '25.03225',
    y: '41.714373',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '20208',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДВОРИЩЕ',
    nameEn: 'DVORISHTE',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2541',
    addressNomenclature: '0',
    x: '22.74935',
    y: '42.339208',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '20211',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДВОРИЩЕ',
    nameEn: 'DVORISHTE',
    municipality: 'ГУРКОВО',
    municipalityEn: 'GURKOVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6193',
    addressNomenclature: '0',
    x: '25.719514',
    y: '42.661138',
    servingDays: '0101000',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '20225',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕБЕЛ ДЯЛ',
    nameEn: 'DEBEL DYAL',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5307',
    addressNomenclature: '0',
    x: '25.179861',
    y: '42.876919',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '20242',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ДЕБЕЛЕЦ',
    nameEn: 'DEBELETS',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5030',
    addressNomenclature: '2',
    x: '25.621014',
    y: '43.043666',
    servingDays: '1111110',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '20599',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕБЕЛЕЦ',
    nameEn: 'DEBELETS',
    municipality: 'ДЪЛГОПОЛ',
    municipalityEn: 'DALGOPOL',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9261',
    addressNomenclature: '0',
    x: '27.330096',
    y: '43.013066',
    servingDays: '1111100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '20256',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕБЕЛИ ЛАГ',
    nameEn: 'DEBELI LAG',
    municipality: 'РАДОМИР',
    municipalityEn: 'RADOMIR',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2416',
    addressNomenclature: '0',
    x: '22.871096',
    y: '42.460514',
    servingDays: '1111100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '20268',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕБЕЛИ РЪТ',
    nameEn: 'DEBELI RAT',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5084',
    addressNomenclature: '0',
    x: '25.99355',
    y: '43.005031',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '20273',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕБЕЛТ',
    nameEn: 'DEBELT',
    municipality: 'СРЕДЕЦ',
    municipalityEn: 'SREDETS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8314',
    addressNomenclature: '0',
    x: '27.265417',
    y: '42.394253',
    servingDays: '1111110',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '20287',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕБЕЛЦОВО',
    nameEn: 'DEBELTSOVO',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5435',
    addressNomenclature: '0',
    x: '25.09042',
    y: '43.09767',
    servingDays: '0101000',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '20290',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕБЕЛЯНОВО',
    nameEn: 'DEBELYANOVO',
    municipality: 'БАНИТЕ',
    municipalityEn: 'BANITE',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4946',
    addressNomenclature: '0',
    x: '24.981852',
    y: '41.664726',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '20300',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕБНЕВО',
    nameEn: 'DEBNEVO',
    municipality: 'ТРОЯН',
    municipalityEn: 'TROYAN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5657',
    addressNomenclature: '0',
    x: '24.844222',
    y: '42.961318',
    servingDays: '1111100',
    servingOfficeId: '44',
    servingHubOfficeId: '11',
  },
  {
    id: '20314',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕБОВО',
    nameEn: 'DEBOVO',
    municipality: 'НИКОПОЛ',
    municipalityEn: 'NIKOPOL',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5958',
    addressNomenclature: '0',
    x: '24.883016',
    y: '43.588155',
    servingDays: '0101000',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '20328',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕБОЧИЦА',
    nameEn: 'DEBOCHITSA',
    municipality: 'БЛАГОЕВГРАД',
    municipalityEn: 'BLAGOEVGRAD',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2748',
    addressNomenclature: '0',
    x: '22.94748',
    y: '41.865142',
    servingDays: '1111110',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '20331',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕБРЕН',
    nameEn: 'DEBREN',
    municipality: 'ГЪРМЕН',
    municipalityEn: 'GARMEN',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2961',
    addressNomenclature: '0',
    x: '23.822825',
    y: '41.584138',
    servingDays: '1111100',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '20345',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕБРЕНЕ',
    nameEn: 'DEBRENE',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2803',
    addressNomenclature: '0',
    x: '23.314288',
    y: '41.575676',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '20359',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕБРЕНЕ',
    nameEn: 'DEBRENE',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9353',
    addressNomenclature: '0',
    x: '27.86445',
    y: '43.388157',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '20362',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕБРЪЩИЦА',
    nameEn: 'DEBRASHTITSA',
    municipality: 'ПАЗАРДЖИК',
    municipalityEn: 'PAZARDZHIK',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4414',
    addressNomenclature: '0',
    x: '24.255803',
    y: '42.104413',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '20376',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕВЕНЕ',
    nameEn: 'DEVENE',
    municipality: 'ВРАЦА',
    municipalityEn: 'VRATSA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3065',
    addressNomenclature: '0',
    x: '23.612764',
    y: '43.40928',
    servingDays: '0101000',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '20383',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕВЕНЦИ',
    nameEn: 'DEVENTSI',
    municipality: 'ЧЕРВЕН БРЯГ',
    municipalityEn: 'CHERVEN BRYAG',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5995',
    addressNomenclature: '0',
    x: '24.165121',
    y: '43.321312',
    servingDays: '1010100',
    servingOfficeId: '59',
    servingHubOfficeId: '20',
  },
  {
    id: '20393',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕВЕСИЛИЦА',
    nameEn: 'DEVESILITSA',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6934',
    addressNomenclature: '0',
    x: '25.678651',
    y: '41.361574',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '20403',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕВЕСИЛОВО',
    nameEn: 'DEVESILOVO',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6933',
    addressNomenclature: '0',
    x: '25.667521',
    y: '41.337662',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '20417',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕВЕТАК',
    nameEn: 'DEVETAK',
    municipality: 'КАРНОБАТ',
    municipalityEn: 'KARNOBAT',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8480',
    addressNomenclature: '0',
    x: '26.825963',
    y: '42.573366',
    servingDays: '1010100',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '20420',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕВЕТАКИ',
    nameEn: 'DEVETAKI',
    municipality: 'ЛОВЕЧ',
    municipalityEn: 'LOVECH',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5555',
    addressNomenclature: '0',
    x: '24.901077',
    y: '43.221032',
    servingDays: '0101000',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '20434',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕВЕТАЦИТЕ',
    nameEn: 'DEVETATSITE',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5050',
    addressNomenclature: '0',
    x: '25.589547',
    y: '42.927217',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '20448',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕВЕТИНЦИ',
    nameEn: 'DEVETINTSI',
    municipality: 'КАРНОБАТ',
    municipalityEn: 'KARNOBAT',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8474',
    addressNomenclature: '0',
    x: '26.826451',
    y: '42.594705',
    servingDays: '1010100',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '20465',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ДЕВИН',
    nameEn: 'DEVIN',
    municipality: 'ДЕВИН',
    municipalityEn: 'DEVIN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4800',
    addressNomenclature: '2',
    x: '24.397118',
    y: '41.741218',
    servingDays: '1111100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '20479',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕВИНО',
    nameEn: 'DEVINO',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7972',
    addressNomenclature: '0',
    x: '26.122616',
    y: '43.12933',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '24966',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕВИНЦИ',
    nameEn: 'DEVINTSI',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6833',
    addressNomenclature: '0',
    x: '25.568783',
    y: '41.566884',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '20482',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ДЕВНЯ',
    nameEn: 'DEVNYA',
    municipality: 'ДЕВНЯ',
    municipalityEn: 'DEVNYA',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9160',
    addressNomenclature: '2',
    x: '27.557435',
    y: '43.218285',
    servingDays: '1111110',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '20496',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕДЕЦ',
    nameEn: 'DEDETS',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6861',
    addressNomenclature: '0',
    x: '25.220791',
    y: '41.386768000000004',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '20506',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕДИНА',
    nameEn: 'DEDINA',
    municipality: 'ЗЛАТАРИЦА',
    municipalityEn: 'ZLATARITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5089',
    addressNomenclature: '0',
    x: '25.940633',
    y: '43.035211',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '24880',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕДИНО',
    nameEn: 'DEDINO',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6779',
    addressNomenclature: '0',
    x: '25.182471',
    y: '41.658157',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '20514',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕДИНЦИ',
    nameEn: 'DEDINTSI',
    municipality: 'ЗЛАТАРИЦА',
    municipalityEn: 'ZLATARITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5089',
    addressNomenclature: '0',
    x: '25.944348',
    y: '43.024296',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '20523',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕДОВО',
    nameEn: 'DEDOVO',
    municipality: 'РОДОПИ',
    municipalityEn: 'RODOPI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4106',
    addressNomenclature: '0',
    x: '24.658662',
    y: '41.995092',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '20537',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕКОВ',
    nameEn: 'DEKOV',
    municipality: 'БЕЛЕНЕ',
    municipalityEn: 'BELENE',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5935',
    addressNomenclature: '0',
    x: '25.116513',
    y: '43.601087',
    servingDays: '1010100',
    servingOfficeId: '108',
    servingHubOfficeId: '11',
  },
  {
    id: '20540',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕЛВИНО',
    nameEn: 'DELVINO',
    municipality: 'БЛАГОЕВГРАД',
    municipalityEn: 'BLAGOEVGRAD',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2728',
    addressNomenclature: '0',
    x: '23.132292',
    y: '42.022814',
    servingDays: '1111110',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '20554',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕЛВИНО',
    nameEn: 'DELVINO',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6896',
    addressNomenclature: '0',
    x: '25.457219',
    y: '41.378611',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '20568',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕЛЕЙНА',
    nameEn: 'DELEYNA',
    municipality: 'БРЕГОВО',
    municipalityEn: 'BREGOVO',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3795',
    addressNomenclature: '0',
    x: '22.629251',
    y: '44.053642',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '20571',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕЛОВА МАХАЛА',
    nameEn: 'DELOVA MAHALA',
    municipality: 'ЗЛАТАРИЦА',
    municipalityEn: 'ZLATARITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5084',
    addressNomenclature: '0',
    x: '26.033609',
    y: '43.009448',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '20585',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕЛЧЕВО',
    nameEn: 'DELCHEVO',
    municipality: 'ГОЦЕ ДЕЛЧЕВ',
    municipalityEn: 'GOTSE DELCHEV',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2917',
    addressNomenclature: '0',
    x: '23.696376',
    y: '41.556117',
    servingDays: '1111110',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '20609',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕЛЧЕВО',
    nameEn: 'DELCHEVO',
    municipality: 'ИСПЕРИХ',
    municipalityEn: 'ISPERIH',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7427',
    addressNomenclature: '0',
    x: '26.958603',
    y: '43.709586',
    servingDays: '1010100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '20612',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕЛЯН',
    nameEn: 'DELYAN',
    municipality: 'ДУПНИЦА',
    municipalityEn: 'DUPNITSA',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2652',
    addressNomenclature: '0',
    x: '23.10309',
    y: '42.38288',
    servingDays: '0101000',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '20626',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕЛЯН',
    nameEn: 'DELYAN',
    municipality: 'БОЖУРИЩЕ',
    municipalityEn: 'BOZHURISHTE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2225',
    addressNomenclature: '0',
    x: '23.004396',
    y: '42.759025',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '20630',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕЛЯНОВЦИ',
    nameEn: 'DELYANOVTSI',
    municipality: 'СВИЩОВ',
    municipalityEn: 'SVISHTOV',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5292',
    addressNomenclature: '0',
    x: '25.141584',
    y: '43.441303',
    servingDays: '0101000',
    servingOfficeId: '250',
    servingHubOfficeId: '11',
  },
  {
    id: '20643',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕННИЦА',
    nameEn: 'DENNITSA',
    municipality: 'ВЕНЕЦ',
    municipalityEn: 'VENETS',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9772',
    addressNomenclature: '0',
    x: '26.863561',
    y: '43.475249',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '20657',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕННИЦА',
    nameEn: 'DENNITSA',
    municipality: 'БОЛЯРОВО',
    municipalityEn: 'BOLYAROVO',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8726',
    addressNomenclature: '0',
    x: '26.866376',
    y: '42.264312',
    servingDays: '0101000',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '20660',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕНЧЕВЦИ',
    nameEn: 'DENCHEVTSI',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5377',
    addressNomenclature: '0',
    x: '25.441203',
    y: '43.00426',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '20674',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕРВИШКА МОГИЛА',
    nameEn: 'DERVISHKA MOGILA',
    municipality: 'СВИЛЕНГРАД',
    municipalityEn: 'SVILENGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6539',
    addressNomenclature: '0',
    x: '26.354667',
    y: '41.922098',
    servingDays: '0101000',
    servingOfficeId: '48',
    servingHubOfficeId: '5',
  },
  {
    id: '20688',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕРМАНЦИ',
    nameEn: 'DERMANTSI',
    municipality: 'ЛУКОВИТ',
    municipalityEn: 'LUKOVIT',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5780',
    addressNomenclature: '0',
    x: '24.282212',
    y: '43.151255',
    servingDays: '1010100',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '2765',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕТЕЛИНА',
    nameEn: 'DETELINA',
    municipality: 'КАРНОБАТ',
    municipalityEn: 'KARNOBAT',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8483',
    addressNomenclature: '0',
    x: '27.035277',
    y: '42.558974',
    servingDays: '1010100',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '20701',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕТЕЛИНА',
    nameEn: 'DETELINA',
    municipality: 'ДОЛНИ ЧИФЛИК',
    municipalityEn: 'DOLNI CHIFLIK',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9111',
    addressNomenclature: '0',
    x: '27.772546',
    y: '42.984991',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '20715',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЕЧКОВЦИ',
    nameEn: 'DECHKOVTSI',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5041',
    addressNomenclature: '0',
    x: '25.663604',
    y: '42.918638',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '20729',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЖАМУЗОВЦИ',
    nameEn: 'DZHAMUZOVTSI',
    municipality: 'ИХТИМАН',
    municipalityEn: 'IHTIMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2060',
    addressNomenclature: '0',
    x: '23.804304',
    y: '42.535794',
    servingDays: '0101000',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '20732',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЖАНКА',
    nameEn: 'DZHANKA',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6908',
    addressNomenclature: '0',
    x: '25.671442',
    y: '41.544439',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '20746',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ДЖЕБЕЛ',
    nameEn: 'DZHEBEL',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6850',
    addressNomenclature: '2',
    x: '25.304106',
    y: '41.496539',
    servingDays: '1111100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '20755',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЖЕЛЕПСКО',
    nameEn: 'DZHELEPSKO',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6824',
    addressNomenclature: '0',
    x: '25.526606',
    y: '41.495964',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '20763',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЖЕРМАН',
    nameEn: 'DZHERMAN',
    municipality: 'ДУПНИЦА',
    municipalityEn: 'DUPNITSA',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2648',
    addressNomenclature: '0',
    x: '23.082661',
    y: '42.224322',
    servingDays: '1111110',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '20777',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЖЕРОВО',
    nameEn: 'DZHEROVO',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6872',
    addressNomenclature: '0',
    x: '25.308082',
    y: '41.31193',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '20780',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЖИГУРОВО',
    nameEn: 'DZHIGUROVO',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2812',
    addressNomenclature: '0',
    x: '23.311656',
    y: '41.558836',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '20804',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЖИНОТ',
    nameEn: 'DZHINOT',
    municipality: 'СТРАЛДЖА',
    municipalityEn: 'STRALDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8691',
    addressNomenclature: '0',
    x: '26.656565',
    y: '42.511903',
    servingDays: '1010100',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '20818',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЖИНЧОВЦИ',
    nameEn: 'DZHINCHOVTSI',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2483',
    addressNomenclature: '0',
    x: '22.468146',
    y: '42.784234',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '20835',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЖУЛЮНИЦА',
    nameEn: 'DZHULYUNITSA',
    municipality: 'ЛЯСКОВЕЦ',
    municipalityEn: 'LYASKOVETS',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5146',
    addressNomenclature: '0',
    x: '25.901717',
    y: '43.137436',
    servingDays: '1010100',
    servingOfficeId: '46',
    servingHubOfficeId: '11',
  },
  {
    id: '20849',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЖУЛЮНИЦА',
    nameEn: 'DZHULYUNITSA',
    municipality: 'ЦЕНОВО',
    municipalityEn: 'TSENOVO',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7137',
    addressNomenclature: '0',
    x: '25.60783',
    y: '43.578119',
    servingDays: '1010100',
    servingOfficeId: '250',
    servingHubOfficeId: '11',
  },
  {
    id: '20852',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЖУМРИИТЕ',
    nameEn: 'DZHUMRIITE',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5343',
    addressNomenclature: '0',
    x: '25.338242',
    y: '42.922703',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '20883',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЖУРКОВО',
    nameEn: 'DZHURKOVO',
    municipality: 'ЛЪКИ',
    municipalityEn: 'LAKI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4241',
    addressNomenclature: '0',
    x: '24.819809',
    y: '41.774605',
    servingDays: '0101000',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '20897',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЖУРОВО',
    nameEn: 'DZHUROVO',
    municipality: 'ПРАВЕЦ',
    municipalityEn: 'PRAVETS',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2163',
    addressNomenclature: '0',
    x: '24.050073',
    y: '42.959082',
    servingDays: '0101000',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '20907',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЖУРОВЦИ',
    nameEn: 'DZHUROVTSI',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5380',
    addressNomenclature: '0',
    x: '25.490333',
    y: '42.946059',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '20938',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДИБИЧ',
    nameEn: 'DIBICH',
    municipality: 'ШУМЕН',
    municipalityEn: 'SHUMEN',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9811',
    addressNomenclature: '0',
    x: '27.000703',
    y: '43.228498',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '20941',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДИВА СЛАТИНА',
    nameEn: 'DIVA SLATINA',
    municipality: 'ГЕОРГИ ДАМЯНОВО',
    municipalityEn: 'GEORGI DAMYANOVO',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3492',
    addressNomenclature: '0',
    x: '22.912551',
    y: '43.283895',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '20969',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДИВЕЦИ',
    nameEn: 'DIVETSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5345',
    addressNomenclature: '0',
    x: '25.275602',
    y: '42.908566',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '20972',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДИВЛЯ',
    nameEn: 'DIVLYA',
    municipality: 'ЗЕМЕН',
    municipalityEn: 'ZEMEN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2457',
    addressNomenclature: '0',
    x: '22.68946',
    y: '42.583682',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '20986',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДИВОТИНО',
    nameEn: 'DIVOTINO',
    municipality: 'ПЕРНИК',
    municipalityEn: 'PERNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2350',
    addressNomenclature: '0',
    x: '23.049326',
    y: '42.655374',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '20996',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДИВЧОВОТО',
    nameEn: 'DIVCHOVOTO',
    municipality: 'ТЕТЕВЕН',
    municipalityEn: 'TETEVEN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5727',
    addressNomenclature: '0',
    x: '24.225229',
    y: '42.834117',
    servingDays: '1010100',
    servingOfficeId: '88',
    servingHubOfficeId: '2',
  },
  {
    id: '21004',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДИМАНОВО',
    nameEn: 'DIMANOVO',
    municipality: 'НЕДЕЛИНО',
    municipalityEn: 'NEDELINO',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4994',
    addressNomenclature: '0',
    x: '25.115542',
    y: '41.476051',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '21035',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДИМИЕВЦИ',
    nameEn: 'DIMIEVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5363',
    addressNomenclature: '0',
    x: '25.57958',
    y: '42.867517',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '21049',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДИМИТРИЕВО',
    nameEn: 'DIMITRIEVO',
    municipality: 'ЧИРПАН',
    municipalityEn: 'CHIRPAN',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6259',
    addressNomenclature: '0',
    x: '25.555508',
    y: '42.191376',
    servingDays: '1010100',
    servingOfficeId: '42',
    servingHubOfficeId: '5',
  },
  {
    id: '21052',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ДИМИТРОВГРАД',
    nameEn: 'DIMITROVGRAD',
    municipality: 'ДИМИТРОВГРАД',
    municipalityEn: 'DIMITROVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6400',
    addressNomenclature: '1',
    x: '25.58791',
    y: '42.056106',
    servingDays: '1111110',
    servingOfficeId: '42',
    servingHubOfficeId: '5',
  },
  {
    id: '21066',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДИМИТРОВЦИ',
    nameEn: 'DIMITROVTSI',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5050',
    addressNomenclature: '0',
    x: '25.582689',
    y: '42.9494',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '21078',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДИМИТРОВЧЕ',
    nameEn: 'DIMITROVCHE',
    municipality: 'СВИЛЕНГРАД',
    municipalityEn: 'SVILENGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6518',
    addressNomenclature: '0',
    x: '26.251799',
    y: '41.814368',
    servingDays: '0101000',
    servingOfficeId: '48',
    servingHubOfficeId: '5',
  },
  {
    id: '21097',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ДИМОВО',
    nameEn: 'DIMOVO',
    municipality: 'ДИМОВО',
    municipalityEn: 'DIMOVO',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3750',
    addressNomenclature: '2',
    x: '22.726678',
    y: '43.741482',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '21107',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДИМОВО',
    nameEn: 'DIMOVO',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4757',
    addressNomenclature: '0',
    x: '24.614946',
    y: '41.538843',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '21110',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДИМОВЦИ',
    nameEn: 'DIMOVTSI',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5035',
    addressNomenclature: '0',
    x: '25.632809',
    y: '42.847356',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '21124',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДИМОВЦИ',
    nameEn: 'DIMOVTSI',
    municipality: 'ГУРКОВО',
    municipalityEn: 'GURKOVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6192',
    addressNomenclature: '0',
    x: '25.73467',
    y: '42.67284',
    servingDays: '0101000',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '21138',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДИМЧА',
    nameEn: 'DIMCHA',
    municipality: 'ПАВЛИКЕНИ',
    municipalityEn: 'PAVLIKENI',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5234',
    addressNomenclature: '0',
    x: '25.203296',
    y: '43.21322',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '21141',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДИМЧЕВО',
    nameEn: 'DIMCHEVO',
    municipality: 'БУРГАС',
    municipalityEn: 'BURGAS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8159',
    addressNomenclature: '0',
    x: '27.409281',
    y: '42.405014',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '21155',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДИНЕВО',
    nameEn: 'DINEVO',
    municipality: 'ХАСКОВО',
    municipalityEn: 'HASKOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6340',
    addressNomenclature: '0',
    x: '25.698776',
    y: '41.887602',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '21169',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДИНК',
    nameEn: 'DINK',
    municipality: 'МАРИЦА',
    municipalityEn: 'MARITSA',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4149',
    addressNomenclature: '0',
    x: '24.801876',
    y: '42.26872',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '21172',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДИНКАТА',
    nameEn: 'DINKATA',
    municipality: 'ЛЕСИЧОВО',
    municipalityEn: 'LESICHOVO',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4458',
    addressNomenclature: '0',
    x: '24.228987',
    y: '42.288737',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '21186',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДИНКОВИЦА',
    nameEn: 'DINKOVITSA',
    municipality: 'ВИДИН',
    municipalityEn: 'VIDIN',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3783',
    addressNomenclature: '0',
    x: '22.777395',
    y: '44.037836',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '21193',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДИНКОВО',
    nameEn: 'DINKOVO',
    municipality: 'РУЖИНЦИ',
    municipalityEn: 'RUZHINTSI',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3921',
    addressNomenclature: '0',
    x: '23.037363',
    y: '43.702835',
    servingDays: '1010100',
    servingOfficeId: '831',
    servingHubOfficeId: '20',
  },
  {
    id: '21209',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДИНЯ',
    nameEn: 'DINYA',
    municipality: 'РАДНЕВО',
    municipalityEn: 'RADNEVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6268',
    addressNomenclature: '0',
    x: '25.836981',
    y: '42.321023',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '21213',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДИРАЛО',
    nameEn: 'DIRALO',
    municipality: 'МАДАН',
    municipalityEn: 'MADAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4927',
    addressNomenclature: '0',
    x: '25.006018',
    y: '41.556852',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '24935',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДИСЕВИЦА',
    nameEn: 'DISEVITSA',
    municipality: 'ПЛЕВЕН',
    municipalityEn: 'PLEVEN',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5886',
    addressNomenclature: '0',
    x: '24.516593',
    y: '43.398586',
    servingDays: '1010100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '21227',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДИСМАНИЦА',
    nameEn: 'DISMANITSA',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5441',
    addressNomenclature: '0',
    x: '24.942751',
    y: '42.903763',
    servingDays: '1010100',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '21230',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДИЧЕВО',
    nameEn: 'DICHEVO',
    municipality: 'ГЛАВИНИЦА',
    municipalityEn: 'GLAVINITSA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7625',
    addressNomenclature: '0',
    x: '26.887833',
    y: '43.986638',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '21244',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДИЧИН',
    nameEn: 'DICHIN',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5059',
    addressNomenclature: '0',
    x: '25.481527',
    y: '43.216633',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '21258',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЛЪГНЕВО',
    nameEn: 'DLAGNEVO',
    municipality: 'ДИМИТРОВГРАД',
    municipalityEn: 'DIMITROVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6432',
    addressNomenclature: '0',
    x: '25.54587',
    y: '42.118037',
    servingDays: '1010100',
    servingOfficeId: '42',
    servingHubOfficeId: '5',
  },
  {
    id: '21261',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЛЪГНЯ',
    nameEn: 'DLAGNYA',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5392',
    addressNomenclature: '0',
    x: '25.508538',
    y: '43.014633',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '21275',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЛЪЖКА ПОЛЯНА',
    nameEn: 'DLAZHKA POLYANA',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7997',
    addressNomenclature: '0',
    x: '26.12047',
    y: '43.089204',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '21289',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЛЪЖКО',
    nameEn: 'DLAZHKO',
    municipality: 'ХИТРИНО',
    municipalityEn: 'HITRINO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9789',
    addressNomenclature: '0',
    x: '26.862105',
    y: '43.385677',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '21292',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЛЪХЧЕВО-САБЛЯР',
    nameEn: 'DLAHCHEVO-SABLYAR',
    municipality: 'НЕВЕСТИНО',
    municipalityEn: 'NEVESTINO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2582',
    addressNomenclature: '0',
    x: '22.803743',
    y: '42.181816',
    servingDays: '1010100',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '21302',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОБОРСКО',
    nameEn: 'DOBORSKO',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6947',
    addressNomenclature: '0',
    x: '25.773658',
    y: '41.490188',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '21333',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОБРА ПОЛЯНА',
    nameEn: 'DOBRA POLYANA',
    municipality: 'РУЕН',
    municipalityEn: 'RUEN',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8580',
    addressNomenclature: '0',
    x: '27.312441',
    y: '42.838215',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '21316',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОБРАВИЦА',
    nameEn: 'DOBRAVITSA',
    municipality: 'СВОГЕ',
    municipalityEn: 'SVOGE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2279',
    addressNomenclature: '0',
    x: '23.238964',
    y: '43.018775',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '21324',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОБРАЛЪК',
    nameEn: 'DOBRALAK',
    municipality: 'КУКЛЕН',
    municipalityEn: 'KUKLEN',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4259',
    addressNomenclature: '0',
    x: '24.752965',
    y: '41.95388',
    servingDays: '1010000',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '21347',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОБРЕВА ЧЕРЕША',
    nameEn: 'DOBREVA CHERESHA',
    municipality: 'РУДОЗЕМ',
    municipalityEn: 'RUDOZEM',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4917',
    addressNomenclature: '0',
    x: '24.824161',
    y: '41.511399',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '21350',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОБРЕВО',
    nameEn: 'DOBREVO',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9399',
    addressNomenclature: '0',
    x: '27.841918',
    y: '43.746466',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '21364',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОБРЕВЦИ',
    nameEn: 'DOBREVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5095',
    addressNomenclature: '0',
    x: '25.814686',
    y: '42.837609',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '21378',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОБРЕВЦИ',
    nameEn: 'DOBREVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5365',
    addressNomenclature: '0',
    x: '25.500027',
    y: '42.891565',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '21381',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОБРЕВЦИ',
    nameEn: 'DOBREVTSI',
    municipality: 'ЯБЛАНИЦА',
    municipalityEn: 'YABLANITSA',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5762',
    addressNomenclature: '0',
    x: '24.084052',
    y: '43.0665',
    servingDays: '0101000',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '21395',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОБРЕНИТЕ',
    nameEn: 'DOBRENITE',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5380',
    addressNomenclature: '0',
    x: '25.456166',
    y: '42.943404',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '21405',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОБРИ ВОЙНИКОВО',
    nameEn: 'DOBRI VOYNIKOVO',
    municipality: 'ХИТРИНО',
    municipalityEn: 'HITRINO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9782',
    addressNomenclature: '0',
    x: '26.858823',
    y: '43.439423',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '21419',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОБРИ ДОЛ',
    nameEn: 'DOBRI DOL',
    municipality: 'АВРЕН',
    municipalityEn: 'AVREN',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9128',
    addressNomenclature: '0',
    x: '27.752258',
    y: '43.071341',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '21422',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОБРИ ДОЛ',
    nameEn: 'DOBRI DOL',
    municipality: 'ТРЕКЛЯНО',
    municipalityEn: 'TREKLYANO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2559',
    addressNomenclature: '0',
    x: '22.665114',
    y: '42.495903',
    servingDays: '0101000',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '21436',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОБРИ ДОЛ',
    nameEn: 'DOBRI DOL',
    municipality: 'ЛОМ',
    municipalityEn: 'LOM',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3667',
    addressNomenclature: '0',
    x: '23.001953',
    y: '43.769162',
    servingDays: '1010100',
    servingOfficeId: '831',
    servingHubOfficeId: '20',
  },
  {
    id: '21440',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОБРИ ДОЛ',
    nameEn: 'DOBRI DOL',
    municipality: 'ПЪРВОМАЙ',
    municipalityEn: 'PARVOMAY',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4292',
    addressNomenclature: '0',
    x: '25.311867',
    y: '42.127996',
    servingDays: '1010100',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '21453',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОБРИ ДЯЛ',
    nameEn: 'DOBRI DYAL',
    municipality: 'ЛЯСКОВЕЦ',
    municipalityEn: 'LYASKOVETS',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5149',
    addressNomenclature: '0',
    x: '25.831477',
    y: '43.111728',
    servingDays: '1010100',
    servingOfficeId: '46',
    servingHubOfficeId: '11',
  },
  {
    id: '21467',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОБРИ ЛАКИ',
    nameEn: 'DOBRI LAKI',
    municipality: 'СТРУМЯНИ',
    municipalityEn: 'STRUMYANI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2838',
    addressNomenclature: '0',
    x: '22.996613',
    y: '41.580594',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '21470',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОБРИН',
    nameEn: 'DOBRIN',
    municipality: 'КРУШАРИ',
    municipalityEn: 'KRUSHARI',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9402',
    addressNomenclature: '0',
    x: '27.834807',
    y: '43.868429',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '21484',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОБРИНА',
    nameEn: 'DOBRINA',
    municipality: 'ПРОВАДИЯ',
    municipalityEn: 'PROVADIA',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9216',
    addressNomenclature: '0',
    x: '27.462215',
    y: '43.179037',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '21498',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ДОБРИНИЩЕ',
    nameEn: 'DOBRINISHTE',
    municipality: 'БАНСКО',
    municipalityEn: 'BANSKO',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2777',
    addressNomenclature: '2',
    x: '23.562131',
    y: '41.820714',
    servingDays: '1111110',
    servingOfficeId: '125',
    servingHubOfficeId: '13',
  },
  {
    id: '21508',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОБРИНОВО',
    nameEn: 'DOBRINOVO',
    municipality: 'КАРНОБАТ',
    municipalityEn: 'KARNOBAT',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8477',
    addressNomenclature: '0',
    x: '26.911238',
    y: '42.475314',
    servingDays: '1010100',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '21511',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОБРИНОВО',
    nameEn: 'DOBRINOVO',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6670',
    addressNomenclature: '0',
    x: '25.537557',
    y: '41.713462',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '21525',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОБРИНЦИ',
    nameEn: 'DOBRINTSI',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6857',
    addressNomenclature: '0',
    x: '25.181054',
    y: '41.415914',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '21539',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОБРИЧ',
    nameEn: 'DOBRICH',
    municipality: 'ДИМИТРОВГРАД',
    municipalityEn: 'DIMITROVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6437',
    addressNomenclature: '0',
    x: '25.522669',
    y: '42.028987',
    servingDays: '1111100',
    servingOfficeId: '42',
    servingHubOfficeId: '5',
  },
  {
    id: '21542',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОБРИЧ',
    nameEn: 'DOBRICH',
    municipality: 'ЕЛХОВО',
    municipalityEn: 'ELHOVO',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8724',
    addressNomenclature: '0',
    x: '26.666225',
    y: '42.173126',
    servingDays: '0101000',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '72624',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ДОБРИЧ',
    nameEn: 'DOBRICH',
    municipality: 'ДОБРИЧ',
    municipalityEn: 'DOBRICH',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9300',
    addressNomenclature: '1',
    x: '27.827286',
    y: '43.572777',
    servingDays: '1111110',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '21556',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОБРОВНИЦА',
    nameEn: 'DOBROVNITSA',
    municipality: 'ПАЗАРДЖИК',
    municipalityEn: 'PAZARDZHIK',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4405',
    addressNomenclature: '0',
    x: '24.379696',
    y: '42.210154',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '21565',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОБРОВО',
    nameEn: 'DOBROVO',
    municipality: 'БОБОШЕВО',
    municipalityEn: 'BOBOSHEVO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2662',
    addressNomenclature: '0',
    x: '22.978995',
    y: '42.172552',
    servingDays: '1111110',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '21587',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОБРОГЛЕД',
    nameEn: 'DOBROGLED',
    municipality: 'АКСАКОВО',
    municipalityEn: 'AKSAKOVO',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9145',
    addressNomenclature: '0',
    x: '27.764369',
    y: '43.266546',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '21590',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОБРОДАН',
    nameEn: 'DOBRODAN',
    municipality: 'ТРОЯН',
    municipalityEn: 'TROYAN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5658',
    addressNomenclature: '0',
    x: '24.707902',
    y: '42.968344',
    servingDays: '1010100',
    servingOfficeId: '44',
    servingHubOfficeId: '11',
  },
  {
    id: '21600',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОБРОЛЕВО',
    nameEn: 'DOBROLEVO',
    municipality: 'БОРОВАН',
    municipalityEn: 'BOROVAN',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3253',
    addressNomenclature: '0',
    x: '23.784579',
    y: '43.497849',
    servingDays: '1010100',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '21614',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОБРОМИР',
    nameEn: 'DOBROMIR',
    municipality: 'РУЕН',
    municipalityEn: 'RUEN',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8552',
    addressNomenclature: '0',
    x: '27.286959',
    y: '42.952027',
    servingDays: '1111100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '21628',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОБРОМИРКА',
    nameEn: 'DOBROMIRKA',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5430',
    addressNomenclature: '0',
    x: '25.259717',
    y: '43.08152',
    servingDays: '0101000',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '21631',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОБРОМИРЦИ',
    nameEn: 'DOBROMIRTSI',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6861',
    addressNomenclature: '0',
    x: '25.209986',
    y: '41.380858',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '21645',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОБРОПЛОДНО',
    nameEn: 'DOBROPLODNO',
    municipality: 'ВЕТРИНО',
    municipalityEn: 'VETRINO',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9281',
    addressNomenclature: '0',
    x: '27.375545',
    y: '43.395796',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '21659',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОБРОСЕЛЕЦ',
    nameEn: 'DOBROSELETS',
    municipality: 'ТОПОЛОВГРАД',
    municipalityEn: 'TOPOLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6560',
    addressNomenclature: '0',
    x: '26.308349',
    y: '42.149249',
    servingDays: '1010100',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '21662',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОБРОСЛАВЦИ',
    nameEn: 'DOBROSLAVTSI',
    municipality: 'СТОЛИЧНА',
    municipalityEn: 'STOLICHNA',
    region: 'СОФИЯ (СТОЛИЦА)',
    regionEn: 'SOFIA (STOLITSA)',
    postCode: '1247',
    addressNomenclature: '2',
    x: '23.282773',
    y: '42.821978',
    servingDays: '1111100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '21676',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОБРОСТАН',
    nameEn: 'DOBROSTAN',
    municipality: 'АСЕНОВГРАД',
    municipalityEn: 'ASENOVGRAD',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4256',
    addressNomenclature: '0',
    x: '24.925128',
    y: '41.905255',
    servingDays: '0101000',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '21680',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОБРОТИНО',
    nameEn: 'DOBROTINO',
    municipality: 'ГОЦЕ ДЕЛЧЕВ',
    municipalityEn: 'GOTSE DELCHEV',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2919',
    addressNomenclature: '0',
    x: '23.67166',
    y: '41.585747',
    servingDays: '1111110',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '21693',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОБРОТИЦА',
    nameEn: 'DOBROTITSA',
    municipality: 'СИТОВО',
    municipalityEn: 'SITOVO',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7531',
    addressNomenclature: '0',
    x: '27.014317',
    y: '43.953628',
    servingDays: '1010100',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '21703',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОБРОТИЦА',
    nameEn: 'DOBROTITSA',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7990',
    addressNomenclature: '0',
    x: '26.220567',
    y: '43.185325',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '21717',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОБРОТИЧ',
    nameEn: 'DOBROTICH',
    municipality: 'ВЪЛЧИ ДОЛ',
    municipalityEn: 'VALCHI DOL',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9282',
    addressNomenclature: '0',
    x: '27.426249',
    y: '43.396768',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '21720',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОБРУДЖАНКА',
    nameEn: 'DOBRUDZHANKA',
    municipality: 'КАЙНАРДЖА',
    municipalityEn: 'KAYNARDZHA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7548',
    addressNomenclature: '0',
    x: '27.576995',
    y: '43.968005',
    servingDays: '0101000',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '21734',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОБРУША',
    nameEn: 'DOBRUSHA',
    municipality: 'КРИВОДОЛ',
    municipalityEn: 'KRIVODOL',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3069',
    addressNomenclature: '0',
    x: '23.424873',
    y: '43.440367',
    servingDays: '0101000',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '21748',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОБЪРСКО',
    nameEn: 'DOBARSKO',
    municipality: 'РАЗЛОГ',
    municipalityEn: 'RAZLOG',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2799',
    addressNomenclature: '0',
    x: '23.47639',
    y: '41.970322',
    servingDays: '0101000',
    servingOfficeId: '125',
    servingHubOfficeId: '13',
  },
  {
    id: '21751',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОБЪРЧИН',
    nameEn: 'DOBARCHIN',
    municipality: 'СВОГЕ',
    municipalityEn: 'SVOGE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2289',
    addressNomenclature: '0',
    x: '23.291057',
    y: '43.000228',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '21779',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОГАНОВО',
    nameEn: 'DOGANOVO',
    municipality: 'ЕЛИН ПЕЛИН',
    municipalityEn: 'ELIN PELIN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2115',
    addressNomenclature: '0',
    x: '23.686968',
    y: '42.632624',
    servingDays: '1010100',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '21782',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЖДЕВИЦА',
    nameEn: 'DOZHDEVITSA',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2547',
    addressNomenclature: '0',
    x: '22.576651',
    y: '42.329301',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '21796',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЙНОВЦИ',
    nameEn: 'DOYNOVTSI',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5035',
    addressNomenclature: '0',
    x: '25.657743',
    y: '42.85556',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '21806',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЙРАНЦИ',
    nameEn: 'DOYRANTSI',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6799',
    addressNomenclature: '0',
    x: '25.201726',
    y: '41.712551',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '21811',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЙРАНЦИ',
    nameEn: 'DOYRANTSI',
    municipality: 'КАОЛИНОВО',
    municipalityEn: 'KAOLINOVO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9961',
    addressNomenclature: '0',
    x: '27.16628',
    y: '43.606685',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '21823',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЙРЕНЦИ',
    nameEn: 'DOYRENTSI',
    municipality: 'ЛОВЕЧ',
    municipalityEn: 'LOVECH',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5550',
    addressNomenclature: '0',
    x: '24.831096',
    y: '43.235299',
    servingDays: '1111100',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '21837',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОКАТИЧЕВО',
    nameEn: 'DOKATICHEVO',
    municipality: 'СИМИТЛИ',
    municipalityEn: 'SIMITLI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2734',
    addressNomenclature: '0',
    x: '23.056311',
    y: '41.882979',
    servingDays: '1111110',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '21840',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОКТОР ЙОСИФОВО',
    nameEn: 'DOKTOR YOSIFOVO',
    municipality: 'МОНТАНА',
    municipalityEn: 'MONTANA',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3449',
    addressNomenclature: '0',
    x: '23.14561',
    y: '43.507479',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '21854',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОКЬОВЦИ',
    nameEn: 'DOKYOVTSI',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2497',
    addressNomenclature: '0',
    x: '22.599015',
    y: '42.64911',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '21868',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛЕН',
    nameEn: 'DOLEN',
    municipality: 'САТОВЧА',
    municipalityEn: 'SATOVCHA',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2968',
    addressNomenclature: '0',
    x: '23.931593',
    y: '41.623763',
    servingDays: '0101000',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '21871',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛЕН',
    nameEn: 'DOLEN',
    municipality: 'ЗЛАТОГРАД',
    municipalityEn: 'ZLATOGRAD',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4985',
    addressNomenclature: '0',
    x: '25.13384',
    y: '41.423098',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '21899',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛЕН ЕНЕВЕЦ',
    nameEn: 'DOLEN ENEVETS',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5050',
    addressNomenclature: '0',
    x: '25.566678',
    y: '42.931067',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '21885',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛЕНЕ',
    nameEn: 'DOLENE',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2887',
    addressNomenclature: '0',
    x: '23.03034',
    y: '41.445443',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '21909',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛЕНИ',
    nameEn: 'DOLENI',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2811',
    addressNomenclature: '0',
    x: '23.414171',
    y: '41.57172',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '21926',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛЕЦ',
    nameEn: 'DOLETS',
    municipality: 'ДУЛОВО',
    municipalityEn: 'DULOVO',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7682',
    addressNomenclature: '0',
    x: '26.982383',
    y: '43.79259',
    servingDays: '1010100',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '21937',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛЕЦ',
    nameEn: 'DOLETS',
    municipality: 'ПОПОВО',
    municipalityEn: 'POPOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7858',
    addressNomenclature: '0',
    x: '26.227403',
    y: '43.235729',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '21943',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛИЕ',
    nameEn: 'DOLIE',
    municipality: 'МАДАН',
    municipalityEn: 'MADAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4921',
    addressNomenclature: '0',
    x: '24.913341',
    y: '41.565509',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '21957',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛИНА',
    nameEn: 'DOLINA',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9358',
    addressNomenclature: '0',
    x: '27.686566',
    y: '43.553202',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '21960',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛИНА',
    nameEn: 'DOLINA',
    municipality: 'КАОЛИНОВО',
    municipalityEn: 'KAOLINOVO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9947',
    addressNomenclature: '0',
    x: '27.115524',
    y: '43.535798',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '21974',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛИСТОВО',
    nameEn: 'DOLISTOVO',
    municipality: 'БОБОВ ДОЛ',
    municipalityEn: 'BOBOV DOL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2637',
    addressNomenclature: '0',
    x: '23.009743',
    y: '42.3036',
    servingDays: '1010100',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '21988',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛИЩЕ',
    nameEn: 'DOLISHTE',
    municipality: 'АКСАКОВО',
    municipalityEn: 'AKSAKOVO',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9152',
    addressNomenclature: '0',
    x: '27.891944',
    y: '43.338654',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '21991',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛИЩЕ',
    nameEn: 'DOLISHTE',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6663',
    addressNomenclature: '0',
    x: '25.593891',
    y: '41.676583',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '22006',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ДОЛНА БАНЯ',
    nameEn: 'DOLNA BANYA',
    municipality: 'ДОЛНА БАНЯ',
    municipalityEn: 'DOLNA BANYA',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2040',
    addressNomenclature: '2',
    x: '23.763913',
    y: '42.310936',
    servingDays: '1111100',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '22019',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНА БЕЛА РЕЧКА',
    nameEn: 'DOLNA BELA RECHKA',
    municipality: 'ВЪРШЕЦ',
    municipalityEn: 'VARSHETS',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3545',
    addressNomenclature: '0',
    x: '23.341555',
    y: '43.194154',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '22023',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНА БЕШОВИЦА',
    nameEn: 'DOLNA BESHOVITSA',
    municipality: 'РОМАН',
    municipalityEn: 'ROMAN',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3143',
    addressNomenclature: '0',
    x: '23.887128',
    y: '43.185222',
    servingDays: '0101000',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '24894',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНА ВАСИЛИЦА',
    nameEn: 'DOLNA VASILITSA',
    municipality: 'КОСТЕНЕЦ',
    municipalityEn: 'KOSTENETS',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2034',
    addressNomenclature: '0',
    x: '23.896359',
    y: '42.308515',
    servingDays: '1010100',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '22040',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНА ВЕРЕНИЦА',
    nameEn: 'DOLNA VERENITSA',
    municipality: 'МОНТАНА',
    municipalityEn: 'MONTANA',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3485',
    addressNomenclature: '0',
    x: '23.13524',
    y: '43.430388',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '22054',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНА ВРАБЧА',
    nameEn: 'DOLNA VRABCHA',
    municipality: 'ЗЕМЕН',
    municipalityEn: 'ZEMEN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2458',
    addressNomenclature: '0',
    x: '22.726217',
    y: '42.567623',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '22068',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНА ГРАДЕШНИЦА',
    nameEn: 'DOLNA GRADESHNITSA',
    municipality: 'КРЕСНА',
    municipalityEn: 'KRESNA',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2843',
    addressNomenclature: '0',
    x: '23.184351',
    y: '41.680125',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '22071',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНА ГРАЩИЦА',
    nameEn: 'DOLNA GRASHTITSA',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2536',
    addressNomenclature: '0',
    x: '22.792645',
    y: '42.299233',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '22085',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНА ДИКАНЯ',
    nameEn: 'DOLNA DIKANYA',
    municipality: 'РАДОМИР',
    municipalityEn: 'RADOMIR',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2420',
    addressNomenclature: '0',
    x: '23.126987',
    y: '42.441203',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '24325',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНА ДЪБЕВА',
    nameEn: 'DOLNA DABEVA',
    municipality: 'ВЕЛИНГРАД',
    municipalityEn: 'VELINGRAD',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4655',
    addressNomenclature: '0',
    x: '23.881702',
    y: '42.026361',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '22099',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНА ЗЛАТИЦА',
    nameEn: 'DOLNA ZLATITSA',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7968',
    addressNomenclature: '0',
    x: '26.150459',
    y: '43.201799',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '22109',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНА КАБДА',
    nameEn: 'DOLNA KABDA',
    municipality: 'ПОПОВО',
    municipalityEn: 'POPOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7867',
    addressNomenclature: '0',
    x: '26.331631',
    y: '43.237676',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '22112',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНА КОЗНИЦА',
    nameEn: 'DOLNA KOZNITSA',
    municipality: 'НЕВЕСТИНО',
    municipalityEn: 'NEVESTINO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2595',
    addressNomenclature: '0',
    x: '22.911461',
    y: '42.265842',
    servingDays: '1010100',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '22126',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНА КРЕМЕНА',
    nameEn: 'DOLNA KREMENA',
    municipality: 'МЕЗДРА',
    municipalityEn: 'MEZDRA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3153',
    addressNomenclature: '0',
    x: '23.73811',
    y: '43.171889',
    servingDays: '0101000',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '22134',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНА КРЕПОСТ',
    nameEn: 'DOLNA KREPOST',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6686',
    addressNomenclature: '0',
    x: '25.474965',
    y: '41.726299',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '22143',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНА КРУШИЦА',
    nameEn: 'DOLNA KRUSHITSA',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2888',
    addressNomenclature: '0',
    x: '22.983764',
    y: '41.424841',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '22157',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНА КУЛА',
    nameEn: 'DOLNA KULA',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6953',
    addressNomenclature: '0',
    x: '25.640077',
    y: '41.552368',
    servingDays: '1111100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '22160',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНА ЛИПНИЦА',
    nameEn: 'DOLNA LIPNITSA',
    municipality: 'ПАВЛИКЕНИ',
    municipalityEn: 'PAVLIKENI',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5226',
    addressNomenclature: '0',
    x: '25.461541',
    y: '43.314613',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '22174',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНА МАЛИНА',
    nameEn: 'DOLNA MALINA',
    municipality: 'ГОРНА МАЛИНА',
    municipalityEn: 'GORNA MALINA',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2107',
    addressNomenclature: '0',
    x: '23.671274',
    y: '42.679184',
    servingDays: '0101000',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '22188',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНА МАРГАТИНА',
    nameEn: 'DOLNA MARGATINA',
    municipality: 'ТРОЯН',
    municipalityEn: 'TROYAN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '',
    addressNomenclature: '0',
    x: '24.783243',
    y: '42.934742',
    servingDays: '1010100',
    servingOfficeId: '44',
    servingHubOfficeId: '11',
  },
  {
    id: '22191',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНА МАХАЛА',
    nameEn: 'DOLNA MAHALA',
    municipality: 'КАЛОЯНОВО',
    municipalityEn: 'KALOYANOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4200',
    addressNomenclature: '0',
    x: '24.779958',
    y: '42.416737',
    servingDays: '0101000',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '22201',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНА МЕЛНА',
    nameEn: 'DOLNA MELNA',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2490',
    addressNomenclature: '0',
    x: '22.526717',
    y: '42.675337',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '22215',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ДОЛНА МИТРОПОЛИЯ',
    nameEn: 'DOLNA MITROPOLIA',
    municipality: 'ДОЛНА МИТРОПОЛИЯ',
    municipalityEn: 'DOLNA MITROPOLIA',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5855',
    addressNomenclature: '2',
    x: '24.523805',
    y: '43.467836',
    servingDays: '1111100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '22229',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНА НЕВЛЯ',
    nameEn: 'DOLNA NEVLYA',
    municipality: 'ДРАГОМАН',
    municipalityEn: 'DRAGOMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2213',
    addressNomenclature: '0',
    x: '22.777064',
    y: '42.935022',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '22232',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ДОЛНА ОРЯХОВИЦА',
    nameEn: 'DOLNA ORYAHOVITSA',
    municipality: 'ГОРНА ОРЯХОВИЦА',
    municipalityEn: 'GORNA ORYAHOVITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5130',
    addressNomenclature: '0',
    x: '25.740834',
    y: '43.160487',
    servingDays: '1111110',
    servingOfficeId: '46',
    servingHubOfficeId: '11',
  },
  {
    id: '22246',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНА РИБНИЦА',
    nameEn: 'DOLNA RIBNITSA',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2892',
    addressNomenclature: '0',
    x: '23.096943',
    y: '41.447368',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '22250',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНА РИКСА',
    nameEn: 'DOLNA RIKSA',
    municipality: 'МОНТАНА',
    municipalityEn: 'MONTANA',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3452',
    addressNomenclature: '0',
    x: '23.017563',
    y: '43.546808',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '22263',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНА СЕКИРНА',
    nameEn: 'DOLNA SEKIRNA',
    municipality: 'БРЕЗНИК',
    municipalityEn: 'BREZNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2397',
    addressNomenclature: '0',
    x: '22.723715',
    y: '42.671849',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '22277',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНА СТУДЕНА',
    nameEn: 'DOLNA STUDENA',
    municipality: 'ЦЕНОВО',
    municipalityEn: 'TSENOVO',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7123',
    addressNomenclature: '0',
    x: '25.692749',
    y: '43.526961',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '22280',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНА ХУБАВКА',
    nameEn: 'DOLNA HUBAVKA',
    municipality: 'ОМУРТАГ',
    municipalityEn: 'OMURTAG',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7931',
    addressNomenclature: '0',
    x: '26.35776',
    y: '43.140854',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '22304',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНИ БОГРОВ',
    nameEn: 'DOLNI BOGROV',
    municipality: 'СТОЛИЧНА',
    municipalityEn: 'STOLICHNA',
    region: 'СОФИЯ (СТОЛИЦА)',
    regionEn: 'SOFIA (STOLITSA)',
    postCode: '1855',
    addressNomenclature: '2',
    x: '23.492162',
    y: '42.69925',
    servingDays: '1111100',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '22318',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНИ БОШНЯК',
    nameEn: 'DOLNI BOSHNYAK',
    municipality: 'ВИДИН',
    municipalityEn: 'VIDIN',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3782',
    addressNomenclature: '0',
    x: '22.615208',
    y: '44.004344',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '22321',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНИ ВАДИН',
    nameEn: 'DOLNI VADIN',
    municipality: 'ОРЯХОВО',
    municipalityEn: 'ORYAHOVO',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3344',
    addressNomenclature: '0',
    x: '24.276505',
    y: '43.689506',
    servingDays: '1010100',
    servingOfficeId: '64',
    servingHubOfficeId: '20',
  },
  {
    id: '22335',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНИ ВИТ',
    nameEn: 'DOLNI VIT',
    municipality: 'ГУЛЯНЦИ',
    municipalityEn: 'GULYANTSI',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5969',
    addressNomenclature: '0',
    x: '24.757154',
    y: '43.662474',
    servingDays: '0101000',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '22352',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНИ ВЪРПИЩА',
    nameEn: 'DOLNI VARPISHTA',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5380',
    addressNomenclature: '0',
    x: '25.428803',
    y: '42.935649',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '22375',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНИ ГЛАВАНАК',
    nameEn: 'DOLNI GLAVANAK',
    municipality: 'МАДЖАРОВО',
    municipalityEn: 'MADZHAROVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6483',
    addressNomenclature: '0',
    x: '25.824535',
    y: '41.68229',
    servingDays: '0101000',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '22383',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНИ ДАМЯНОВЦИ',
    nameEn: 'DOLNI DAMYANOVTSI',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5036',
    addressNomenclature: '0',
    x: '25.607228',
    y: '42.885577',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '22397',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНИ ДРАГОЙЧА',
    nameEn: 'DOLNI DRAGOYCHA',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5380',
    addressNomenclature: '0',
    x: '25.472337',
    y: '42.950429',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '22407',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ДОЛНИ ДЪБНИК',
    nameEn: 'DOLNI DABNIK',
    municipality: 'ДОЛНИ ДЪБНИК',
    municipalityEn: 'DOLNI DABNIK',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5870',
    addressNomenclature: '2',
    x: '24.440844',
    y: '43.408638',
    servingDays: '1111100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '22410',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНИ КОРИТЕН',
    nameEn: 'DOLNI KORITEN',
    municipality: 'ТРЕКЛЯНО',
    municipalityEn: 'TREKLYANO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2558',
    addressNomenclature: '0',
    x: '22.589109',
    y: '42.486217',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '22424',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНИ ЛОМ',
    nameEn: 'DOLNI LOM',
    municipality: 'ЧУПРЕНЕ',
    municipalityEn: 'CHUPRENE',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3958',
    addressNomenclature: '0',
    x: '22.778798',
    y: '43.507453',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '22438',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНИ ЛУКОВИТ',
    nameEn: 'DOLNI LUKOVIT',
    municipality: 'ИСКЪР',
    municipalityEn: 'ISKAR',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5867',
    addressNomenclature: '0',
    x: '24.215391',
    y: '43.514651',
    servingDays: '0101000',
    servingOfficeId: '59',
    servingHubOfficeId: '20',
  },
  {
    id: '22441',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНИ МАРЕНЦИ',
    nameEn: 'DOLNI MARENTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5360',
    addressNomenclature: '0',
    x: '25.417279',
    y: '42.830192',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '22455',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНИ МАРЯН',
    nameEn: 'DOLNI MARYAN',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5084',
    addressNomenclature: '0',
    x: '25.949558',
    y: '42.941295',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '22469',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНИ ОКОЛ',
    nameEn: 'DOLNI OKOL',
    municipality: 'САМОКОВ',
    municipalityEn: 'SAMOKOV',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2028',
    addressNomenclature: '0',
    x: '23.494729',
    y: '42.49012',
    servingDays: '0101000',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '22472',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНИ ПАСАРЕЛ',
    nameEn: 'DOLNI PASAREL',
    municipality: 'СТОЛИЧНА',
    municipalityEn: 'STOLICHNA',
    region: 'СОФИЯ (СТОЛИЦА)',
    regionEn: 'SOFIA (STOLITSA)',
    postCode: '1165',
    addressNomenclature: '2',
    x: '23.498461',
    y: '42.541258',
    servingDays: '1010100',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '22486',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНИ РАДКОВЦИ',
    nameEn: 'DOLNI RADKOVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5360',
    addressNomenclature: '0',
    x: '25.428992',
    y: '42.831829',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '22490',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНИ РАКОВЕЦ',
    nameEn: 'DOLNI RAKOVETS',
    municipality: 'РАДОМИР',
    municipalityEn: 'RADOMIR',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2413',
    addressNomenclature: '0',
    x: '23.005308',
    y: '42.473793',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '22506',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНИ РОМАНЦИ',
    nameEn: 'DOLNI ROMANTSI',
    municipality: 'БРЕЗНИК',
    municipalityEn: 'BREZNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2367',
    addressNomenclature: '0',
    x: '22.909625',
    y: '42.763314',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '22513',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНИ ТАНЧЕВЦИ',
    nameEn: 'DOLNI TANCHEVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5097',
    addressNomenclature: '0',
    x: '25.897686',
    y: '42.890431',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '22527',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНИ ТОМЧЕВЦИ',
    nameEn: 'DOLNI TOMCHEVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5350',
    addressNomenclature: '0',
    x: '25.442887',
    y: '42.900028',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '22530',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНИ ЦИБЪР',
    nameEn: 'DOLNI TSIBAR',
    municipality: 'ВЪЛЧЕДРЪМ',
    municipalityEn: 'VALCHEDRAM',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3642',
    addressNomenclature: '0',
    x: '23.512239',
    y: '43.813725',
    servingDays: '0101000',
    servingOfficeId: '831',
    servingHubOfficeId: '20',
  },
  {
    id: '21912',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ДОЛНИ ЧИФЛИК',
    nameEn: 'DOLNI CHIFLIK',
    municipality: 'ДОЛНИ ЧИФЛИК',
    municipalityEn: 'DOLNI CHIFLIK',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9120',
    addressNomenclature: '2',
    x: '27.718534',
    y: '42.993149',
    servingDays: '1111110',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '22544',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНИ ЮРУЦИ',
    nameEn: 'DOLNI YURUTSI',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6954',
    addressNomenclature: '0',
    x: '25.92502',
    y: '41.360704',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '22558',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНО АБЛАНОВО',
    nameEn: 'DOLNO ABLANOVO',
    municipality: 'РУСЕ',
    municipalityEn: 'RUSE',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7053',
    addressNomenclature: '0',
    x: '26.138107',
    y: '43.839524',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '22561',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНО БЕЛЕВО',
    nameEn: 'DOLNO BELEVO',
    municipality: 'ДИМИТРОВГРАД',
    municipalityEn: 'DIMITROVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6422',
    addressNomenclature: '0',
    x: '25.713809',
    y: '42.074023',
    servingDays: '0101000',
    servingOfficeId: '42',
    servingHubOfficeId: '5',
  },
  {
    id: '3736',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНО БЕЛОТИНЦИ',
    nameEn: 'DOLNO BELOTINTSI',
    municipality: 'МОНТАНА',
    municipalityEn: 'MONTANA',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3437',
    addressNomenclature: '0',
    x: '23.283557',
    y: '43.441033',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '22575',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНО БОТЕВО',
    nameEn: 'DOLNO BOTEVO',
    municipality: 'СТАМБОЛОВО',
    municipalityEn: 'STAMBOLOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6393',
    addressNomenclature: '0',
    x: '25.712483',
    y: '41.745979',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '22589',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНО ВОЙВОДИНО',
    nameEn: 'DOLNO VOYVODINO',
    municipality: 'ХАСКОВО',
    municipalityEn: 'HASKOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6389',
    addressNomenclature: '0',
    x: '25.567971',
    y: '41.80292',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '22592',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНО ВЪРШИЛО',
    nameEn: 'DOLNO VARSHILO',
    municipality: 'СЕПТЕМВРИ',
    municipalityEn: 'SEPTEMVRI',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4498',
    addressNomenclature: '0',
    x: '23.98564',
    y: '42.329201',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '24949',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНО ГОЛЕМАНЦИ',
    nameEn: 'DOLNO GOLEMANTSI',
    municipality: 'ХАСКОВО',
    municipalityEn: 'HASKOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6358',
    addressNomenclature: '0',
    x: '25.500041',
    y: '41.789502',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '22602',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНО ДРАГЛИЩЕ',
    nameEn: 'DOLNO DRAGLISHTE',
    municipality: 'РАЗЛОГ',
    municipalityEn: 'RAZLOG',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2798',
    addressNomenclature: '0',
    x: '23.514473',
    y: '41.933612',
    servingDays: '1111100',
    servingOfficeId: '125',
    servingHubOfficeId: '13',
  },
  {
    id: '22616',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНО ДРЯНОВО',
    nameEn: 'DOLNO DRYANOVO',
    municipality: 'ГЪРМЕН',
    municipalityEn: 'GARMEN',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2944',
    addressNomenclature: '0',
    x: '23.897666',
    y: '41.587079',
    servingDays: '1111100',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '22633',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНО ИЗВОРОВО',
    nameEn: 'DOLNO IZVOROVO',
    municipality: 'КАЗАНЛЪК',
    municipalityEn: 'KAZANLAK',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6183',
    addressNomenclature: '0',
    x: '25.459702',
    y: '42.657511',
    servingDays: '0101000',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '22647',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНО КАМАРЦИ',
    nameEn: 'DOLNO KAMARTSI',
    municipality: 'ГОРНА МАЛИНА',
    municipalityEn: 'GORNA MALINA',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2137',
    addressNomenclature: '0',
    x: '23.85779',
    y: '42.71364',
    servingDays: '0101000',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '22650',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНО КОБИЛЕ',
    nameEn: 'DOLNO KOBILE',
    municipality: 'ТРЕКЛЯНО',
    municipalityEn: 'TREKLYANO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2556',
    addressNomenclature: '0',
    x: '22.53319',
    y: '42.549098',
    servingDays: '0101000',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '22664',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНО КОЗАРЕВО',
    nameEn: 'DOLNO KOZAREVO',
    municipality: 'ОМУРТАГ',
    municipalityEn: 'OMURTAG',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7926',
    addressNomenclature: '0',
    x: '26.506093',
    y: '43.085308',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '22678',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНО КЪПИНОВО',
    nameEn: 'DOLNO KAPINOVO',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6870',
    addressNomenclature: '0',
    x: '25.280061',
    y: '41.292581',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '22695',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНО ЛИНЕВО',
    nameEn: 'DOLNO LINEVO',
    municipality: 'ЛОМ',
    municipalityEn: 'LOM',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3605',
    addressNomenclature: '0',
    x: '23.325519',
    y: '43.842888',
    servingDays: '0101000',
    servingOfficeId: '831',
    servingHubOfficeId: '20',
  },
  {
    id: '22705',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНО ЛУКОВО',
    nameEn: 'DOLNO LUKOVO',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6586',
    addressNomenclature: '0',
    x: '26.076426',
    y: '41.374453',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '22719',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНО НОВКОВО',
    nameEn: 'DOLNO NOVKOVO',
    municipality: 'ОМУРТАГ',
    municipalityEn: 'OMURTAG',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7938',
    addressNomenclature: '0',
    x: '26.568636',
    y: '43.093244',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '22722',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНО НОВО СЕЛО',
    nameEn: 'DOLNO NOVO SELO',
    municipality: 'ДРАГОМАН',
    municipalityEn: 'DRAGOMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2212',
    addressNomenclature: '0',
    x: '22.802943',
    y: '42.969957',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '22736',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНО НОВО СЕЛО',
    nameEn: 'DOLNO NOVO SELO',
    municipality: 'БРАТЯ ДАСКАЛОВИ',
    municipalityEn: 'BRATYA DASKALOVI',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6226',
    addressNomenclature: '0',
    x: '25.238036',
    y: '42.420746',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '22747',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНО ОЗИРОВО',
    nameEn: 'DOLNO OZIROVO',
    municipality: 'ВЪРШЕЦ',
    municipalityEn: 'VARSHETS',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3538',
    addressNomenclature: '0',
    x: '23.353205',
    y: '43.239709',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '22753',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНО ОСЕНОВО',
    nameEn: 'DOLNO OSENOVO',
    municipality: 'СИМИТЛИ',
    municipalityEn: 'SIMITLI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2757',
    addressNomenclature: '0',
    x: '23.240669',
    y: '41.954487',
    servingDays: '1010100',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '22770',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНО ПОЛЕ',
    nameEn: 'DOLNO POLE',
    municipality: 'СТАМБОЛОВО',
    municipalityEn: 'STAMBOLOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6382',
    addressNomenclature: '0',
    x: '25.74888',
    y: '41.661203',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '22784',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНО ПРАХОВО',
    nameEn: 'DOLNO PRAHOVO',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6779',
    addressNomenclature: '0',
    x: '25.178268',
    y: '41.651569',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '22798',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНО РЯХОВО',
    nameEn: 'DOLNO RYAHOVO',
    municipality: 'ГЛАВИНИЦА',
    municipalityEn: 'GLAVINITSA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7624',
    addressNomenclature: '0',
    x: '26.793383',
    y: '44.061642',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '22808',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНО САХРАНЕ',
    nameEn: 'DOLNO SAHRANE',
    municipality: 'ПАВЕЛ БАНЯ',
    municipalityEn: 'PAVEL BANYA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6161',
    addressNomenclature: '0',
    x: '25.232987',
    y: '42.633167',
    servingDays: '1111100',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '22811',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНО СЕЛО',
    nameEn: 'DOLNO SELO',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2567',
    addressNomenclature: '0',
    x: '22.497857',
    y: '42.298952',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '22842',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНО СПАНЧЕВО',
    nameEn: 'DOLNO SPANCHEVO',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2869',
    addressNomenclature: '0',
    x: '23.380534',
    y: '41.421306',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '22856',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНО СЪДИЕВО',
    nameEn: 'DOLNO SADIEVO',
    municipality: 'МАДЖАРОВО',
    municipalityEn: 'MADZHAROVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6455',
    addressNomenclature: '0',
    x: '25.742869',
    y: '41.709562',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '22862',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНО УЙНО',
    nameEn: 'DOLNO UYNO',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2560',
    addressNomenclature: '0',
    x: '22.574288',
    y: '42.416118',
    servingDays: '0101000',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '22873',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНО ЦЕРОВЕНЕ',
    nameEn: 'DOLNO TSEROVENE',
    municipality: 'ЯКИМОВО',
    municipalityEn: 'YAKIMOVO',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3639',
    addressNomenclature: '0',
    x: '23.245045',
    y: '43.590741',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '22887',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНО ЧЕРКОВИЩЕ',
    nameEn: 'DOLNO CHERKOVISHTE',
    municipality: 'СТАМБОЛОВО',
    municipalityEn: 'STAMBOLOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6381',
    addressNomenclature: '0',
    x: '25.739781',
    y: '41.638795',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '22890',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНО ШИВАЧЕВО',
    nameEn: 'DOLNO SHIVACHEVO',
    municipality: 'ЗЛАТАРИЦА',
    municipalityEn: 'ZLATARITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5090',
    addressNomenclature: '0',
    x: '25.993177',
    y: '43.048319',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '22914',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНО ЯБЪЛКОВО',
    nameEn: 'DOLNO YABALKOVO',
    municipality: 'СРЕДЕЦ',
    municipalityEn: 'SREDETS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8342',
    addressNomenclature: '0',
    x: '27.114382',
    y: '42.135132',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '22825',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНОСЕЛЦИ',
    nameEn: 'DOLNOSELTSI',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6573',
    addressNomenclature: '0',
    x: '25.989799',
    y: '41.602812',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '22839',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЛНОСЛАВ',
    nameEn: 'DOLNOSLAV',
    municipality: 'АСЕНОВГРАД',
    municipalityEn: 'ASENOVGRAD',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4254',
    addressNomenclature: '0',
    x: '24.982258',
    y: '41.93223',
    servingDays: '0101000',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '22928',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОМИЩЕ',
    nameEn: 'DOMISHTE',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6887',
    addressNomenclature: '0',
    x: '25.374362',
    y: '41.356755',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '22931',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОМЛЯН',
    nameEn: 'DOMLYAN',
    municipality: 'КАРЛОВО',
    municipalityEn: 'KARLOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4357',
    addressNomenclature: '0',
    x: '24.912353',
    y: '42.534614',
    servingDays: '1010100',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '22945',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОНДУКОВО',
    nameEn: 'DONDUKOVO',
    municipality: 'БРУСАРЦИ',
    municipalityEn: 'BRUSARTSI',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3659',
    addressNomenclature: '0',
    x: '23.101461',
    y: '43.711808',
    servingDays: '1010100',
    servingOfficeId: '831',
    servingHubOfficeId: '20',
  },
  {
    id: '22959',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОНИНО',
    nameEn: 'DONINO',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5342',
    addressNomenclature: '0',
    x: '25.373939',
    y: '42.910256',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '22962',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОНКИНО',
    nameEn: 'DONKINO',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5360',
    addressNomenclature: '0',
    x: '25.502984',
    y: '42.814364',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '22976',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОНКОВЦИ',
    nameEn: 'DONKOVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5081',
    addressNomenclature: '0',
    x: '25.804213',
    y: '42.920154',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '22988',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОНЧЕВО',
    nameEn: 'DONCHEVO',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9354',
    addressNomenclature: '0',
    x: '27.768712',
    y: '43.52075',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '22993',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОНЧОВЦИ',
    nameEn: 'DONCHOVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5351',
    addressNomenclature: '0',
    x: '25.448995',
    y: '42.860221',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '23008',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОРКОВО',
    nameEn: 'DORKOVO',
    municipality: 'РАКИТОВО',
    municipalityEn: 'RAKITOVO',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4641',
    addressNomenclature: '0',
    x: '24.130207',
    y: '42.045121',
    servingDays: '1111100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '23011',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОСИТЕЕВО',
    nameEn: 'DOSITEEVO',
    municipality: 'ХАРМАНЛИ',
    municipalityEn: 'HARMANLI',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6472',
    addressNomenclature: '0',
    x: '26.021213',
    y: '41.911126',
    servingDays: '1010100',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '23025',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ДОСПАТ',
    nameEn: 'DOSPAT',
    municipality: 'ДОСПАТ',
    municipalityEn: 'DOSPAT',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4831',
    addressNomenclature: '0',
    x: '24.15978',
    y: '41.646249',
    servingDays: '1111100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '23039',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОСПЕЙ',
    nameEn: 'DOSPEY',
    municipality: 'САМОКОВ',
    municipalityEn: 'SAMOKOV',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2012',
    addressNomenclature: '0',
    x: '23.515957',
    y: '42.314302',
    servingDays: '0101000',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '23042',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДОЧА',
    nameEn: 'DOCHA',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5380',
    addressNomenclature: '0',
    x: '25.452507',
    y: '42.942333',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '23056',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАБИШНА',
    nameEn: 'DRABISHNA',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6577',
    addressNomenclature: '0',
    x: '26.157326',
    y: '41.501235',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '23060',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАГАНА',
    nameEn: 'DRAGANA',
    municipality: 'УГЪРЧИН',
    municipalityEn: 'UGARCHIN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5524',
    addressNomenclature: '0',
    x: '24.397885',
    y: '43.173555',
    servingDays: '1010100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '23073',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАГАНИЦА',
    nameEn: 'DRAGANITSA',
    municipality: 'ВЪРШЕЦ',
    municipalityEn: 'VARSHETS',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3534',
    addressNomenclature: '0',
    x: '23.247349',
    y: '43.239704',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '23087',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАГАНОВЕЦ',
    nameEn: 'DRAGANOVETS',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7773',
    addressNomenclature: '0',
    x: '26.671643',
    y: '43.090554',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '23090',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАГАНОВО',
    nameEn: 'DRAGANOVO',
    municipality: 'БУРГАС',
    municipalityEn: 'BURGAS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8114',
    addressNomenclature: '0',
    x: '27.441485',
    y: '42.696088',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '23100',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАГАНОВО',
    nameEn: 'DRAGANOVO',
    municipality: 'ГОРНА ОРЯХОВИЦА',
    municipalityEn: 'GORNA ORYAHOVITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5137',
    addressNomenclature: '0',
    x: '25.744287',
    y: '43.218637',
    servingDays: '1111100',
    servingOfficeId: '46',
    servingHubOfficeId: '11',
  },
  {
    id: '23114',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАГАНОВО',
    nameEn: 'DRAGANOVO',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6714',
    addressNomenclature: '0',
    x: '25.370006',
    y: '41.7521',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '23128',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАГАНОВО',
    nameEn: 'DRAGANOVO',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9349',
    addressNomenclature: '0',
    x: '27.783662',
    y: '43.472673',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '23131',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАГАНОВЦИ',
    nameEn: 'DRAGANOVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5094',
    addressNomenclature: '0',
    x: '25.785256',
    y: '42.876693',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '23159',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАГАНОВЦИ',
    nameEn: 'DRAGANOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5332',
    addressNomenclature: '0',
    x: '25.184328',
    y: '42.935707',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '23162',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАГАНОСКОВЦИ',
    nameEn: 'DRAGANOSKOVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5081',
    addressNomenclature: '0',
    x: '25.829832',
    y: '42.909435',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '23176',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАГАНЦИ',
    nameEn: 'DRAGANTSI',
    municipality: 'КАРНОБАТ',
    municipalityEn: 'KARNOBAT',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8489',
    addressNomenclature: '0',
    x: '26.983',
    y: '42.569252',
    servingDays: '1010100',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '23185',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАГАНЧЕТАТА',
    nameEn: 'DRAGANCHETATA',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5335',
    addressNomenclature: '0',
    x: '25.409242',
    y: '42.849522',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '23193',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАГАШ ВОЙВОДА',
    nameEn: 'DRAGASH VOYVODA',
    municipality: 'НИКОПОЛ',
    municipalityEn: 'NIKOPOL',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5952',
    addressNomenclature: '0',
    x: '24.957353',
    y: '43.676215',
    servingDays: '0101000',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '23203',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАГИЕВЦИ',
    nameEn: 'DRAGIEVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5332',
    addressNomenclature: '0',
    x: '25.173251',
    y: '42.926405',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '23217',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАГИЖЕВО',
    nameEn: 'DRAGIZHEVO',
    municipality: 'ЛЯСКОВЕЦ',
    municipalityEn: 'LYASKOVETS',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5145',
    addressNomenclature: '0',
    x: '25.740832',
    y: '43.068219',
    servingDays: '1111100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '23220',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАГИЙЦИ',
    nameEn: 'DRAGIYTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5094',
    addressNomenclature: '0',
    x: '25.784924',
    y: '42.857765',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '23234',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАГИНОВО',
    nameEn: 'DRAGINOVO',
    municipality: 'ВЕЛИНГРАД',
    municipalityEn: 'VELINGRAD',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4642',
    addressNomenclature: '0',
    x: '24.010744',
    y: '42.065551',
    servingDays: '1111100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '23251',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАГИЧЕВО',
    nameEn: 'DRAGICHEVO',
    municipality: 'ПЕРНИК',
    municipalityEn: 'PERNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2351',
    addressNomenclature: '0',
    x: '23.13908',
    y: '42.604264',
    servingDays: '1111100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '23265',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАГНЕВЦИ',
    nameEn: 'DRAGNEVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5097',
    addressNomenclature: '0',
    x: '25.868597',
    y: '42.855559',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '23279',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАГНЕВЦИ',
    nameEn: 'DRAGNEVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5360',
    addressNomenclature: '0',
    x: '25.518208',
    y: '42.811869',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '23282',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАГОВИЩИЦА',
    nameEn: 'DRAGOVISHTITSA',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2564',
    addressNomenclature: '0',
    x: '22.650676',
    y: '42.371131',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '23296',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАГОВИЩИЦА',
    nameEn: 'DRAGOVISHTITSA',
    municipality: 'КОСТИНБРОД',
    municipalityEn: 'KOSTINBROD',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2237',
    addressNomenclature: '0',
    x: '23.222366',
    y: '42.848163',
    servingDays: '1111100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '23306',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАГОВО',
    nameEn: 'DRAGOVO',
    municipality: 'КАРНОБАТ',
    municipalityEn: 'KARNOBAT',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8437',
    addressNomenclature: '0',
    x: '27.105774',
    y: '42.629412',
    servingDays: '1010100',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '23323',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАГОДАН',
    nameEn: 'DRAGODAN',
    municipality: 'КОЧЕРИНОВО',
    municipalityEn: 'KOCHERINOVO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2625',
    addressNomenclature: '0',
    x: '23.018949',
    y: '42.117707',
    servingDays: '1111110',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '23337',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАГОДАНОВО',
    nameEn: 'DRAGODANOVO',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8888',
    addressNomenclature: '0',
    x: '26.566206',
    y: '42.632891',
    servingDays: '1010100',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '23340',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАГОЕВО',
    nameEn: 'DRAGOEVO',
    municipality: 'ВЕЛИКИ ПРЕСЛАВ',
    municipalityEn: 'VELIKI PRESLAV',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9860',
    addressNomenclature: '0',
    x: '26.869273',
    y: '43.12117',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '23354',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАГОИЛ',
    nameEn: 'DRAGOIL',
    municipality: 'ДРАГОМАН',
    municipalityEn: 'DRAGOMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2208',
    addressNomenclature: '0',
    x: '22.882243',
    y: '42.942489',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '23385',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАГОЙНОВО',
    nameEn: 'DRAGOYNOVO',
    municipality: 'ПЪРВОМАЙ',
    municipalityEn: 'PARVOMAY',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4293',
    addressNomenclature: '0',
    x: '25.231634',
    y: '41.993899',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '23399',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАГОЙЧИНЦИ',
    nameEn: 'DRAGOYCHINTSI',
    municipality: 'ТРЕКЛЯНО',
    municipalityEn: 'TREKLYANO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2554',
    addressNomenclature: '0',
    x: '22.495117',
    y: '42.621133',
    servingDays: '0101000',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '23409',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ДРАГОМАН',
    nameEn: 'DRAGOMAN',
    municipality: 'ДРАГОМАН',
    municipalityEn: 'DRAGOMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2210',
    addressNomenclature: '2',
    x: '22.931659',
    y: '42.9298',
    servingDays: '1111100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '23412',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАГОМАНИ',
    nameEn: 'DRAGOMANI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5343',
    addressNomenclature: '0',
    x: '25.39705',
    y: '42.937284',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '23426',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАГОМИР',
    nameEn: 'DRAGOMIR',
    municipality: 'СЪЕДИНЕНИЕ',
    municipalityEn: 'SAEDINENIE',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4184',
    addressNomenclature: '0',
    x: '24.470194',
    y: '42.370115',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '23431',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАГОМИРОВО',
    nameEn: 'DRAGOMIROVO',
    municipality: 'СВИЩОВ',
    municipalityEn: 'SVISHTOV',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5285',
    addressNomenclature: '0',
    x: '25.248519',
    y: '43.515704',
    servingDays: '0101000',
    servingOfficeId: '250',
    servingHubOfficeId: '11',
  },
  {
    id: '23443',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАГОМИРОВО',
    nameEn: 'DRAGOMIROVO',
    municipality: 'РАДОМИР',
    municipalityEn: 'RADOMIR',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2428',
    addressNomenclature: '0',
    x: '22.845068',
    y: '42.394799',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '24921',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАГОМЪЖ',
    nameEn: 'DRAGOMAZH',
    municipality: 'ИСПЕРИХ',
    municipalityEn: 'ISPERIH',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7419',
    addressNomenclature: '0',
    x: '26.782787',
    y: '43.713392',
    servingDays: '1010100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '23457',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАГОР',
    nameEn: 'DRAGOR',
    municipality: 'ПАЗАРДЖИК',
    municipalityEn: 'PAZARDZHIK',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4406',
    addressNomenclature: '0',
    x: '24.30102',
    y: '42.22859',
    servingDays: '1111110',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '23460',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАГОСТИН',
    nameEn: 'DRAGOSTIN',
    municipality: 'ГОЦЕ ДЕЛЧЕВ',
    municipalityEn: 'GOTSE DELCHEV',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '',
    addressNomenclature: '0',
    x: '23.697964',
    y: '41.574032',
    servingDays: '0101000',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '23474',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАГОТИНЦИ',
    nameEn: 'DRAGOTINTSI',
    municipality: 'СЛИВНИЦА',
    municipalityEn: 'SLIVNITSA',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2218',
    addressNomenclature: '0',
    x: '22.897908',
    y: '42.858314',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '23488',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАГУШ',
    nameEn: 'DRAGUSH',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2875',
    addressNomenclature: '0',
    x: '23.09259',
    y: '41.51703',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '23491',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАГУШИНОВО',
    nameEn: 'DRAGUSHINOVO',
    municipality: 'САМОКОВ',
    municipalityEn: 'SAMOKOV',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2008',
    addressNomenclature: '0',
    x: '23.558943',
    y: '42.36887',
    servingDays: '1010100',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '23501',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАЖЕВО',
    nameEn: 'DRAZHEVO',
    municipality: 'ТУНДЖА',
    municipalityEn: 'TUNDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8634',
    addressNomenclature: '0',
    x: '26.448617',
    y: '42.536975',
    servingDays: '1010100',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '23515',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАЖИНЦИ',
    nameEn: 'DRAZHINTSI',
    municipality: 'РУЖИНЦИ',
    municipalityEn: 'RUZHINTSI',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3929',
    addressNomenclature: '0',
    x: '22.875039',
    y: '43.632737',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '23529',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАКА',
    nameEn: 'DRAKA',
    municipality: 'СРЕДЕЦ',
    municipalityEn: 'SREDETS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8324',
    addressNomenclature: '0',
    x: '27.019871',
    y: '42.363129',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '23532',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАКАТА',
    nameEn: 'DRAKATA',
    municipality: 'СТРУМЯНИ',
    municipalityEn: 'STRUMYANI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2829',
    addressNomenclature: '0',
    x: '23.20649',
    y: '41.608341',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '23546',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАЛФА',
    nameEn: 'DRALFA',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7782',
    addressNomenclature: '0',
    x: '26.435981',
    y: '43.34033',
    servingDays: '1111100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '23557',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАМА',
    nameEn: 'DRAMA',
    municipality: 'ТУНДЖА',
    municipalityEn: 'TUNDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8651',
    addressNomenclature: '0',
    x: '26.435644',
    y: '42.239731',
    servingDays: '0101000',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '23563',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАНГОВО',
    nameEn: 'DRANGOVO',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2859',
    addressNomenclature: '0',
    x: '23.291821',
    y: '41.416449',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '23577',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАНГОВО',
    nameEn: 'DRANGOVO',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6860',
    addressNomenclature: '0',
    x: '25.243447',
    y: '41.33324',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '23580',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАНГОВО',
    nameEn: 'DRANGOVO',
    municipality: 'БРЕЗОВО',
    municipalityEn: 'BREZOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4164',
    addressNomenclature: '0',
    x: '25.005925',
    y: '42.371091',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '23594',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАНДАРИТЕ',
    nameEn: 'DRANDARITE',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5363',
    addressNomenclature: '0',
    x: '25.606425',
    y: '42.856088',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '23604',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАЧЕВО',
    nameEn: 'DRACHEVO',
    municipality: 'СРЕДЕЦ',
    municipalityEn: 'SREDETS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8319',
    addressNomenclature: '0',
    x: '27.251369',
    y: '42.341817',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '23618',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАШАН',
    nameEn: 'DRASHAN',
    municipality: 'БЯЛА СЛАТИНА',
    municipalityEn: 'BYALA SLATINA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3267',
    addressNomenclature: '0',
    x: '23.915607',
    y: '43.255591',
    servingDays: '0101000',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '23621',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРАШКОВА ПОЛЯНА',
    nameEn: 'DRASHKOVA POLYANA',
    municipality: 'АПРИЛЦИ',
    municipalityEn: 'APRILTSI',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5648',
    addressNomenclature: '0',
    x: '24.803813',
    y: '42.901128',
    servingDays: '1010100',
    servingOfficeId: '44',
    servingHubOfficeId: '11',
  },
  {
    id: '23635',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРЕАТИН',
    nameEn: 'DREATIN',
    municipality: 'ДРАГОМАН',
    municipalityEn: 'DRAGOMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2213',
    addressNomenclature: '0',
    x: '22.790111',
    y: '42.881355',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '23649',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРЕН',
    nameEn: 'DREN',
    municipality: 'РАДОМИР',
    municipalityEn: 'RADOMIR',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2430',
    addressNomenclature: '0',
    x: '23.159732',
    y: '42.409011',
    servingDays: '0101000',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '23652',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРЕНКОВО',
    nameEn: 'DRENKOVO',
    municipality: 'БЛАГОЕВГРАД',
    municipalityEn: 'BLAGOEVGRAD',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2739',
    addressNomenclature: '0',
    x: '22.945021',
    y: '42.013949',
    servingDays: '1111110',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '23666',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРЕНОВ',
    nameEn: 'DRENOV',
    municipality: 'ЛОВЕЧ',
    municipalityEn: 'LOVECH',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5562',
    addressNomenclature: '0',
    x: '24.847771',
    y: '43.283696',
    servingDays: '0101000',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '23672',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРЕНОВЕЦ',
    nameEn: 'DRENOVETS',
    municipality: 'РУЖИНЦИ',
    municipalityEn: 'RUZHINTSI',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3920',
    addressNomenclature: '0',
    x: '22.975734',
    y: '43.691838',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '23683',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРЕНОВИЦА',
    nameEn: 'DRENOVITSA',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2895',
    addressNomenclature: '0',
    x: '23.073575',
    y: '41.416243',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '23697',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРЕНОВО',
    nameEn: 'DRENOVO',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2876',
    addressNomenclature: '0',
    x: '23.154944',
    y: '41.510334',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '23707',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРЕНОВО',
    nameEn: 'DRENOVO',
    municipality: 'КОСТИНБРОД',
    municipalityEn: 'KOSTINBROD',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2236',
    addressNomenclature: '0',
    x: '23.181918',
    y: '42.949862',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '23710',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРЕНТА',
    nameEn: 'DRENTA',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5095',
    addressNomenclature: '0',
    x: '25.78804',
    y: '42.822498',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '23724',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРЕНЦИ',
    nameEn: 'DRENTSI',
    municipality: 'ВЕНЕЦ',
    municipalityEn: 'VENETS',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9788',
    addressNomenclature: '0',
    x: '26.925399',
    y: '43.496279',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '23738',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРИНОВО',
    nameEn: 'DRINOVO',
    municipality: 'ПОПОВО',
    municipalityEn: 'POPOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7834',
    addressNomenclature: '0',
    x: '26.338236',
    y: '43.382216',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '23741',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРИПЧЕВО',
    nameEn: 'DRIPCHEVO',
    municipality: 'ХАРМАНЛИ',
    municipalityEn: 'HARMANLI',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6477',
    addressNomenclature: '0',
    x: '26.214763',
    y: '41.977884',
    servingDays: '0101000',
    servingOfficeId: '48',
    servingHubOfficeId: '5',
  },
  {
    id: '23755',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРОПЛА',
    nameEn: 'DROPLA',
    municipality: 'РУЕН',
    municipalityEn: 'RUEN',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8563',
    addressNomenclature: '0',
    x: '27.198404',
    y: '42.910293',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '23769',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРОПЛА',
    nameEn: 'DROPLA',
    municipality: 'БАЛЧИК',
    municipalityEn: 'BALCHIK',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9641',
    addressNomenclature: '0',
    x: '28.079585',
    y: '43.575613',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '24832',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРУГАН',
    nameEn: 'DRUGAN',
    municipality: 'РАДОМИР',
    municipalityEn: 'RADOMIR',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2410',
    addressNomenclature: '0',
    x: '23.067049',
    y: '42.475173',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '23786',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРУЖБА',
    nameEn: 'DRUZHBA',
    municipality: 'ВИДИН',
    municipalityEn: 'VIDIN',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3730',
    addressNomenclature: '0',
    x: '22.717939',
    y: '44.001998',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '23798',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРУЖЕВО',
    nameEn: 'DRUZHEVO',
    municipality: 'СВОГЕ',
    municipalityEn: 'SVOGE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2281',
    addressNomenclature: '0',
    x: '23.356305',
    y: '43.135775',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '23803',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРУЖИНЦИ',
    nameEn: 'DRUZHINTSI',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6885',
    addressNomenclature: '0',
    x: '25.340166',
    y: '41.327048',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '23813',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРУМЕВО',
    nameEn: 'DRUMEVO',
    municipality: 'ШУМЕН',
    municipalityEn: 'SHUMEN',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9815',
    addressNomenclature: '0',
    x: '27.158085',
    y: '43.140525',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '23827',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРУМОХАР',
    nameEn: 'DRUMOHAR',
    municipality: 'НЕВЕСТИНО',
    municipalityEn: 'NEVESTINO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2596',
    addressNomenclature: '0',
    x: '22.871659',
    y: '42.205608',
    servingDays: '1010100',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '23830',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРУМЧЕ',
    nameEn: 'DRUMCHE',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6820',
    addressNomenclature: '0',
    x: '25.493655',
    y: '41.504087',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '23844',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРЪМША',
    nameEn: 'DRAMSHA',
    municipality: 'КОСТИНБРОД',
    municipalityEn: 'KOSTINBROD',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2238',
    addressNomenclature: '0',
    x: '23.195915',
    y: '42.932862',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '23858',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРЪНДАР',
    nameEn: 'DRANDAR',
    municipality: 'СУВОРОВО',
    municipalityEn: 'SUVOROVO',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9173',
    addressNomenclature: '0',
    x: '27.630836',
    y: '43.361884',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '23875',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРЯНКА',
    nameEn: 'DRYANKA',
    municipality: 'БАНИТЕ',
    municipalityEn: 'BANITE',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4941',
    addressNomenclature: '0',
    x: '25.009323',
    y: '41.602217',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '23889',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРЯНКОВЕЦ',
    nameEn: 'DRYANKOVETS',
    municipality: 'АЙТОС',
    municipalityEn: 'AYTOS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8524',
    addressNomenclature: '0',
    x: '27.37306',
    y: '42.687795',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '23892',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРЯНОВА ГЛАВА',
    nameEn: 'DRYANOVA GLAVA',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6865',
    addressNomenclature: '0',
    x: '25.301909',
    y: '41.375304',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '23902',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРЯНОВЕЦ',
    nameEn: 'DRYANOVETS',
    municipality: 'РАЗГРАД',
    municipalityEn: 'RAZGRAD',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7268',
    addressNomenclature: '0',
    x: '26.378235',
    y: '43.598839',
    servingDays: '0101000',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '23916',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРЯНОВЕЦ',
    nameEn: 'DRYANOVETS',
    municipality: 'БЯЛА',
    municipalityEn: 'BYALA',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7122',
    addressNomenclature: '0',
    x: '25.859463',
    y: '43.387904',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '23929',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРЯНОВЕЦ',
    nameEn: 'DRYANOVETS',
    municipality: 'ЧЕПЕЛАРЕ',
    municipalityEn: 'CHEPELARE',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4856',
    addressNomenclature: '0',
    x: '24.696959',
    y: '41.766913',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '23933',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРЯНОВЕЦ',
    nameEn: 'DRYANOVETS',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9392',
    addressNomenclature: '0',
    x: '27.556313',
    y: '43.716553',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '23947',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ДРЯНОВО',
    nameEn: 'DRYANOVO',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5370',
    addressNomenclature: '2',
    x: '25.477106',
    y: '42.979018',
    servingDays: '1111110',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '23950',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРЯНОВО',
    nameEn: 'DRYANOVO',
    municipality: 'ЛЪКИ',
    municipalityEn: 'LAKI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4241',
    addressNomenclature: '0',
    x: '24.787472',
    y: '41.796329',
    servingDays: '0101000',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '23964',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРЯНОВО',
    nameEn: 'DRYANOVO',
    municipality: 'СИМЕОНОВГРАД',
    municipalityEn: 'SIMEONOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6493',
    addressNomenclature: '0',
    x: '25.936007',
    y: '42.08145',
    servingDays: '0101000',
    servingOfficeId: '42',
    servingHubOfficeId: '5',
  },
  {
    id: '23978',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРЯНОВО',
    nameEn: 'DRYANOVO',
    municipality: 'ТУНДЖА',
    municipalityEn: 'TUNDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8652',
    addressNomenclature: '0',
    x: '26.510404',
    y: '42.245571',
    servingDays: '0101000',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '23981',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРЯНСКА',
    nameEn: 'DRYANSKA',
    municipality: 'ТРОЯН',
    municipalityEn: 'TROYAN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '',
    addressNomenclature: '0',
    x: '24.742362',
    y: '42.887592',
    servingDays: '0101000',
    servingOfficeId: '44',
    servingHubOfficeId: '11',
  },
  {
    id: '23995',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДРЯНЪТ',
    nameEn: 'DRYANAT',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '',
    addressNomenclature: '0',
    x: '25.044173',
    y: '42.832962',
    servingDays: '1010100',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '24000',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДУВАНЛИИ',
    nameEn: 'DUVANLII',
    municipality: 'КАЛОЯНОВО',
    municipalityEn: 'KALOYANOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4174',
    addressNomenclature: '0',
    x: '24.722975',
    y: '42.369914',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '24027',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДУКОВЦИ',
    nameEn: 'DUKOVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5094',
    addressNomenclature: '0',
    x: '25.836825',
    y: '42.879629',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '24030',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ДУЛОВО',
    nameEn: 'DULOVO',
    municipality: 'ДУЛОВО',
    municipalityEn: 'DULOVO',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7650',
    addressNomenclature: '2',
    x: '27.139602',
    y: '43.821983',
    servingDays: '1111100',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '24854',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДУМНИЦИ',
    nameEn: 'DUMNITSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5345',
    addressNomenclature: '0',
    x: '25.292801',
    y: '42.906662',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '24044',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДУНАВЕЦ',
    nameEn: 'DUNAVETS',
    municipality: 'ТУТРАКАН',
    municipalityEn: 'TUTRAKAN',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7623',
    addressNomenclature: '0',
    x: '26.745544',
    y: '44.067901',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '24058',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДУНАВЦИ',
    nameEn: 'DUNAVTSI',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5041',
    addressNomenclature: '0',
    x: '25.619359',
    y: '42.927481',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '24061',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ДУНАВЦИ',
    nameEn: 'DUNAVTSI',
    municipality: 'ВИДИН',
    municipalityEn: 'VIDIN',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3740',
    addressNomenclature: '2',
    x: '22.813327',
    y: '43.913344',
    servingDays: '1111100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '24075',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДУНАВЦИ',
    nameEn: 'DUNAVTSI',
    municipality: 'КАЗАНЛЪК',
    municipalityEn: 'KAZANLAK',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6145',
    addressNomenclature: '0',
    x: '25.29495',
    y: '42.65759',
    servingDays: '1111100',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '24089',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДУНЕВО',
    nameEn: 'DUNEVO',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4723',
    addressNomenclature: '0',
    x: '24.732232',
    y: '41.579365',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '24952',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДУНЯ',
    nameEn: 'DUNYA',
    municipality: 'НЕДЕЛИНО',
    municipalityEn: 'NEDELINO',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4990',
    addressNomenclature: '0',
    x: '25.036062',
    y: '41.458156',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '68789',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ДУПНИЦА',
    nameEn: 'DUPNITSA',
    municipality: 'ДУПНИЦА',
    municipalityEn: 'DUPNITSA',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2600',
    addressNomenclature: '2',
    x: '23.115307',
    y: '42.264302',
    servingDays: '1111110',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '24102',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДУРАНКУЛАК',
    nameEn: 'DURANKULAK',
    municipality: 'ШАБЛА',
    municipalityEn: 'SHABLA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9670',
    addressNomenclature: '0',
    x: '28.527477',
    y: '43.691988',
    servingDays: '1111100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '24126',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДУРОВЦИ',
    nameEn: 'DUROVTSI',
    municipality: 'ЗЛАТАРИЦА',
    municipalityEn: 'ZLATARITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5089',
    addressNomenclature: '0',
    x: '25.989024',
    y: '43.029832',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '24133',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДУРЧА',
    nameEn: 'DURCHA',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5380',
    addressNomenclature: '0',
    x: '25.491793',
    y: '42.920328',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '24150',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДУХОВЕЦ',
    nameEn: 'DUHOVETS',
    municipality: 'ИСПЕРИХ',
    municipalityEn: 'ISPERIH',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7435',
    addressNomenclature: '0',
    x: '27.001234',
    y: '43.668665',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '24164',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДУШАНЦИ',
    nameEn: 'DUSHANTSI',
    municipality: 'ПИРДОП',
    municipalityEn: 'PIRDOP',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2083',
    addressNomenclature: '0',
    x: '24.262213',
    y: '42.696769',
    servingDays: '0101000',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '24178',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДУШЕВО',
    nameEn: 'DUSHEVO',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5455',
    addressNomenclature: '0',
    x: '25.024678',
    y: '42.965416',
    servingDays: '1010100',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '24181',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДУШЕВСКИ КОЛИБИ',
    nameEn: 'DUSHEVSKI KOLIBI',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5455',
    addressNomenclature: '0',
    x: '25.01132',
    y: '42.909693',
    servingDays: '1010100',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '24195',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДУШИНКОВО',
    nameEn: 'DUSHINKOVO',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6846',
    addressNomenclature: '0',
    x: '25.338015',
    y: '41.480187',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '24205',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДУШИНЦИ',
    nameEn: 'DUSHINTSI',
    municipality: 'БРЕЗНИК',
    municipalityEn: 'BREZNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2398',
    addressNomenclature: '0',
    x: '22.669916',
    y: '42.705489',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '24219',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДУШКА',
    nameEn: 'DUSHKA',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6714',
    addressNomenclature: '0',
    x: '25.194432',
    y: '41.888209',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '24236',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪБЕН',
    nameEn: 'DABEN',
    municipality: 'ЛУКОВИТ',
    municipalityEn: 'LUKOVIT',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5787',
    addressNomenclature: '0',
    x: '24.215135',
    y: '43.131126',
    servingDays: '1010100',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '24241',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪБЕНЕ',
    nameEn: 'DABENE',
    municipality: 'КАРЛОВО',
    municipalityEn: 'KARLOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4349',
    addressNomenclature: '0',
    x: '24.765948',
    y: '42.598003',
    servingDays: '1010100',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '24253',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪБНИК',
    nameEn: 'DABNIK',
    municipality: 'ПОМОРИЕ',
    municipalityEn: 'POMORIE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8213',
    addressNomenclature: '0',
    x: '27.505626',
    y: '42.686697',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '24267',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪБНИЦА',
    nameEn: 'DABNITSA',
    municipality: 'ГЪРМЕН',
    municipalityEn: 'GARMEN',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2940',
    addressNomenclature: '0',
    x: '23.833345',
    y: '41.566106',
    servingDays: '1111100',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '24270',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪБОВА',
    nameEn: 'DABOVA',
    municipality: 'КОТЕЛ',
    municipalityEn: 'KOTEL',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8996',
    addressNomenclature: '0',
    x: '26.241667',
    y: '42.969849',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '24284',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪБОВА',
    nameEn: 'DABOVA',
    municipality: 'РУДОЗЕМ',
    municipalityEn: 'RUDOZEM',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4969',
    addressNomenclature: '0',
    x: '24.85093',
    y: '41.531805',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '24298',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪБОВА МАХАЛА',
    nameEn: 'DABOVA MAHALA',
    municipality: 'БРУСАРЦИ',
    municipalityEn: 'BRUSARTSI',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3674',
    addressNomenclature: '0',
    x: '23.017069',
    y: '43.682971',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '24308',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪБОВАН',
    nameEn: 'DABOVAN',
    municipality: 'ГУЛЯНЦИ',
    municipalityEn: 'GULYANTSI',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5973',
    addressNomenclature: '0',
    x: '24.608839',
    y: '43.724652',
    servingDays: '0101000',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '24311',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪБОВЕЦ',
    nameEn: 'DABOVETS',
    municipality: 'ЛЮБИМЕЦ',
    municipalityEn: 'LYUBIMETS',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6587',
    addressNomenclature: '0',
    x: '25.992822',
    y: '41.667701',
    servingDays: '1010100',
    servingOfficeId: '48',
    servingHubOfficeId: '5',
  },
  {
    id: '24339',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪБОВИК',
    nameEn: 'DABOVIK',
    municipality: 'ГЕНЕРАЛ ТОШЕВО',
    municipalityEn: 'GENERAL TOSHEVO',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9551',
    addressNomenclature: '0',
    x: '27.957619',
    y: '43.687645',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '83137',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪБОВИЦА',
    nameEn: 'DABOVITSA',
    municipality: 'СУНГУРЛАРЕ',
    municipalityEn: 'SUNGURLARE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8449',
    addressNomenclature: '0',
    x: '26.629024',
    y: '42.808569',
    servingDays: '0101000',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '24342',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪБОВО',
    nameEn: 'DABOVO',
    municipality: 'МЪГЛИЖ',
    municipalityEn: 'MAGLIZH',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6178',
    addressNomenclature: '0',
    x: '25.636464',
    y: '42.595627',
    servingDays: '1111100',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '24356',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪБОВО',
    nameEn: 'DABOVO',
    municipality: 'БОЛЯРОВО',
    municipalityEn: 'BOLYAROVO',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8736',
    addressNomenclature: '0',
    x: '26.778995',
    y: '42.200249',
    servingDays: '0101000',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '24367',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪБРАВА',
    nameEn: 'DABRAVA',
    municipality: 'БЛАГОЕВГРАД',
    municipalityEn: 'BLAGOEVGRAD',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2723',
    addressNomenclature: '0',
    x: '23.107449',
    y: '42.053266',
    servingDays: '1111110',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '24373',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪБРАВА',
    nameEn: 'DABRAVA',
    municipality: 'ЛОВЕЧ',
    municipalityEn: 'LOVECH',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5546',
    addressNomenclature: '0',
    x: '24.820037',
    y: '43.063308',
    servingDays: '0101000',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '24387',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪБРАВА',
    nameEn: 'DABRAVA',
    municipality: 'БАЛЧИК',
    municipalityEn: 'BALCHIK',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9638',
    addressNomenclature: '0',
    x: '28.092477',
    y: '43.540538',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '24390',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪБРАВАТА',
    nameEn: 'DABRAVATA',
    municipality: 'ЯБЛАНИЦА',
    municipalityEn: 'YABLANITSA',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5765',
    addressNomenclature: '0',
    x: '24.031068',
    y: '43.081242',
    servingDays: '0101000',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '24400',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪБРАВИНО',
    nameEn: 'DABRAVINO',
    municipality: 'АВРЕН',
    municipalityEn: 'AVREN',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9126',
    addressNomenclature: '0',
    x: '27.597562',
    y: '43.053231',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '24414',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪБРАВИТЕ',
    nameEn: 'DABRAVITE',
    municipality: 'БЕЛОВО',
    municipalityEn: 'BELOVO',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4472',
    addressNomenclature: '0',
    x: '24.019668',
    y: '42.225506',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '24428',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪБРАВИЦА',
    nameEn: 'DABRAVITSA',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7997',
    addressNomenclature: '0',
    x: '26.148529',
    y: '43.084569',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '24431',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪБРАВКА',
    nameEn: 'DABRAVKA',
    municipality: 'БЕЛОГРАДЧИК',
    municipalityEn: 'BELOGRADCHIK',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3916',
    addressNomenclature: '0',
    x: '22.622363',
    y: '43.638841',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '24445',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪЖДИНО',
    nameEn: 'DAZHDINO',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6642',
    addressNomenclature: '0',
    x: '25.365418',
    y: '41.582366',
    servingDays: '1111100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '24459',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪЖДОВНИК',
    nameEn: 'DAZHDOVNIK',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6917',
    addressNomenclature: '0',
    x: '25.678277',
    y: '41.433112',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '24462',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪЖДОВНИЦА',
    nameEn: 'DAZHDOVNITSA',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6747',
    addressNomenclature: '0',
    x: '25.294698',
    y: '41.678033',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '24476',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪЛБОК ДОЛ',
    nameEn: 'DALBOK DOL',
    municipality: 'ТРОЯН',
    municipalityEn: 'TROYAN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5668',
    addressNomenclature: '0',
    x: '24.651272',
    y: '42.963341',
    servingDays: '0101000',
    servingOfficeId: '44',
    servingHubOfficeId: '11',
  },
  {
    id: '24493',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪЛБОК ИЗВОР',
    nameEn: 'DALBOK IZVOR',
    municipality: 'ПЪРВОМАЙ',
    municipalityEn: 'PARVOMAY',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4280',
    addressNomenclature: '0',
    x: '25.10604',
    y: '42.026437',
    servingDays: '1010100',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '24482',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪЛБОКИ',
    nameEn: 'DALBOKI',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6060',
    addressNomenclature: '0',
    x: '25.774809',
    y: '42.47897',
    servingDays: '1111110',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '24503',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪЛГА ЛИВАДА',
    nameEn: 'DALGA LIVADA',
    municipality: 'ТЕТЕВЕН',
    municipalityEn: 'TETEVEN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '',
    addressNomenclature: '0',
    x: '24.145748',
    y: '42.952816',
    servingDays: '0101000',
    servingOfficeId: '88',
    servingHubOfficeId: '2',
  },
  {
    id: '24517',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪЛГА ЛУКА',
    nameEn: 'DALGA LUKA',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2492',
    addressNomenclature: '0',
    x: '22.552493',
    y: '42.642255',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '24520',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪЛГАЧ',
    nameEn: 'DALGACH',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7742',
    addressNomenclature: '0',
    x: '26.724104',
    y: '43.216735',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '24534',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪЛГИ ДЕЛ',
    nameEn: 'DALGI DEL',
    municipality: 'ГЕОРГИ ДАМЯНОВО',
    municipalityEn: 'GEORGI DAMYANOVO',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3475',
    addressNomenclature: '0',
    x: '22.959188',
    y: '43.270941',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '24548',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪЛГИ ПРИПЕК',
    nameEn: 'DALGI PRIPEK',
    municipality: 'ЗЛАТАРИЦА',
    municipalityEn: 'ZLATARITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5088',
    addressNomenclature: '0',
    x: '26.048907',
    y: '43.007901',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '24579',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪЛГО ПОЛЕ',
    nameEn: 'DALGO POLE',
    municipality: 'ДИМОВО',
    municipalityEn: 'DIMOVO',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3756',
    addressNomenclature: '0',
    x: '22.778809',
    y: '43.695643',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '24582',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪЛГО ПОЛЕ',
    nameEn: 'DALGO POLE',
    municipality: 'КАЛОЯНОВО',
    municipalityEn: 'KALOYANOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4195',
    addressNomenclature: '0',
    x: '24.770361',
    y: '42.318882',
    servingDays: '0101000',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '24551',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪЛГОДЕЛЦИ',
    nameEn: 'DALGODELTSI',
    municipality: 'ЯКИМОВО',
    municipalityEn: 'YAKIMOVO',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3638',
    addressNomenclature: '0',
    x: '23.279155',
    y: '43.605355',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '24565',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ДЪЛГОПОЛ',
    nameEn: 'DALGOPOL',
    municipality: 'ДЪЛГОПОЛ',
    municipalityEn: 'DALGOPOL',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9250',
    addressNomenclature: '2',
    x: '27.343123',
    y: '43.052851',
    servingDays: '1111100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '24596',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪНГОВО',
    nameEn: 'DANGOVO',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6637',
    addressNomenclature: '0',
    x: '25.337859',
    y: '41.576661',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '24606',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪРВАРИ',
    nameEn: 'DARVARI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5350',
    addressNomenclature: '0',
    x: '25.54718',
    y: '42.854632',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '24623',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪРЖАВА',
    nameEn: 'DARZHAVA',
    municipality: 'ЧИРПАН',
    municipalityEn: 'CHIRPAN',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6217',
    addressNomenclature: '0',
    x: '25.419665',
    y: '42.177265',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '24637',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪРЖАВЕН',
    nameEn: 'DARZHAVEN',
    municipality: 'МЪГЛИЖ',
    municipalityEn: 'MAGLIZH',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6188',
    addressNomenclature: '0',
    x: '25.563183',
    y: '42.727522',
    servingDays: '1111110',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '24640',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪРЖАНИЦА',
    nameEn: 'DARZHANITSA',
    municipality: 'ДИМОВО',
    municipalityEn: 'DIMOVO',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3747',
    addressNomenclature: '0',
    x: '22.845161',
    y: '43.810166',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '24654',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪРЛЕВЦИ',
    nameEn: 'DARLEVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5070',
    addressNomenclature: '0',
    x: '25.883223',
    y: '42.967984',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '24668',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪРМАНЦИ',
    nameEn: 'DARMANTSI',
    municipality: 'МЕЗДРА',
    municipalityEn: 'MEZDRA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3129',
    addressNomenclature: '0',
    x: '23.720677',
    y: '43.126384',
    servingDays: '0101000',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '24671',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪСКАРИТЕ',
    nameEn: 'DASKARITE',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5363',
    addressNomenclature: '0',
    x: '25.601012',
    y: '42.84067',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '24685',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪСКОТ',
    nameEn: 'DASKOT',
    municipality: 'ПАВЛИКЕНИ',
    municipalityEn: 'PAVLIKENI',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5223',
    addressNomenclature: '0',
    x: '25.399874',
    y: '43.246497',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '24699',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЪСКОТНА',
    nameEn: 'DASKOTNA',
    municipality: 'РУЕН',
    municipalityEn: 'RUEN',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8561',
    addressNomenclature: '0',
    x: '27.193168',
    y: '42.87834',
    servingDays: '1111100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '24712',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЮЛЕВО',
    nameEn: 'DYULEVO',
    municipality: 'СРЕДЕЦ',
    municipalityEn: 'SREDETS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8330',
    addressNomenclature: '0',
    x: '27.162717',
    y: '42.394536',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '24726',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЮЛЕВО',
    nameEn: 'DYULEVO',
    municipality: 'СТРЕЛЧА',
    municipalityEn: 'STRELCHA',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4529',
    addressNomenclature: '0',
    x: '24.378171',
    y: '42.450858',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '24739',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЮЛИНО',
    nameEn: 'DYULINO',
    municipality: 'БЯЛА',
    municipalityEn: 'BYALA',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9108',
    addressNomenclature: '0',
    x: '27.72549',
    y: '42.860114',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '24743',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЮЛИЦА',
    nameEn: 'DYULITSA',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6876',
    addressNomenclature: '0',
    x: '25.314153',
    y: '41.390389',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '24757',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЮЛЯ',
    nameEn: 'DYULYA',
    municipality: 'РУЕН',
    municipalityEn: 'RUEN',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8571',
    addressNomenclature: '0',
    x: '27.125004',
    y: '42.913786',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '98260',
    countryId: '100',
    mainSiteId: '0',
    type: 'в.с.',
    typeEn: 'v.s.',
    name: 'ДЮНИ',
    nameEn: 'DYUNI',
    municipality: 'СОЗОПОЛ',
    municipalityEn: 'SOZOPOL',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8133',
    addressNomenclature: '0',
    x: '27.708468',
    y: '42.370214',
    servingDays: '1111110',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '24760',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЯДОВО',
    nameEn: 'DYADOVO',
    municipality: 'НОВА ЗАГОРА',
    municipalityEn: 'NOVA ZAGORA',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8918',
    addressNomenclature: '0',
    x: '26.018245',
    y: '42.435472',
    servingDays: '0101000',
    servingOfficeId: '56',
    servingHubOfficeId: '5',
  },
  {
    id: '24774',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЯДОВСКО',
    nameEn: 'DYADOVSKO',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6705',
    addressNomenclature: '0',
    x: '25.294323',
    y: '41.76661',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '24788',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЯДОВЦИ',
    nameEn: 'DYADOVTSI',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6764',
    addressNomenclature: '0',
    x: '25.139067',
    y: '41.60017',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '24791',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЯКОВО',
    nameEn: 'DYAKOVO',
    municipality: 'ДУПНИЦА',
    municipalityEn: 'DUPNITSA',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2651',
    addressNomenclature: '0',
    x: '23.083',
    y: '42.33564',
    servingDays: '0101000',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '24815',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЯЛЪК',
    nameEn: 'DYALAK',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '',
    addressNomenclature: '0',
    x: '24.976184',
    y: '42.91222',
    servingDays: '1010100',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '24829',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ДЯНКОВО',
    nameEn: 'DYANKOVO',
    municipality: 'РАЗГРАД',
    municipalityEn: 'RAZGRAD',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7270',
    addressNomenclature: '0',
    x: '26.597669',
    y: '43.621566',
    servingDays: '1111100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '27019',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕВЛОГИЕВО',
    nameEn: 'EVLOGIEVO',
    municipality: 'НИКОПОЛ',
    municipalityEn: 'NIKOPOL',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5965',
    addressNomenclature: '0',
    x: '24.876712',
    y: '43.627827',
    servingDays: '0101000',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '27022',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕВРЕНОЗОВО',
    nameEn: 'EVRENOZOVO',
    municipality: 'МАЛКО ТЪРНОВО',
    municipalityEn: 'MALKO TARNOVO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8172',
    addressNomenclature: '0',
    x: '27.34301',
    y: '42.132614',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '27036',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕГРЕК',
    nameEn: 'EGREK',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6931',
    addressNomenclature: '0',
    x: '25.621252',
    y: '41.330539',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '27043',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕГЪЛНИЦА',
    nameEn: 'EGALNITSA',
    municipality: 'КОВАЧЕВЦИ',
    municipalityEn: 'KOVACHEVTSI',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2429',
    addressNomenclature: '0',
    x: '22.855793',
    y: '42.473715',
    servingDays: '1111100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '27067',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕДИНАКОВЦИ',
    nameEn: 'EDINAKOVTSI',
    municipality: 'ХИТРИНО',
    municipalityEn: 'HITRINO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9792',
    addressNomenclature: '0',
    x: '26.815307',
    y: '43.420314',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '27070',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕДРЕВО',
    nameEn: 'EDREVO',
    municipality: 'НИКОЛАЕВО',
    municipalityEn: 'NIKOLAEVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6173',
    addressNomenclature: '0',
    x: '25.813608',
    y: '42.588395',
    servingDays: '0101000',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '27680',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕДРИНО',
    nameEn: 'EDRINO',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6909',
    addressNomenclature: '0',
    x: '25.66516',
    y: '41.45241',
    servingDays: '1111100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '27084',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕЗДИМИРЦИ',
    nameEn: 'EZDIMIRTSI',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2464',
    addressNomenclature: '0',
    x: '22.690345',
    y: '42.811461',
    servingDays: '1111100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '27108',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕЗЕРЕЦ',
    nameEn: 'EZERETS',
    municipality: 'ШАБЛА',
    municipalityEn: 'SHABLA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9687',
    addressNomenclature: '0',
    x: '28.530448',
    y: '43.59249',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '27111',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕЗЕРО',
    nameEn: 'EZERO',
    municipality: 'НОВА ЗАГОРА',
    municipalityEn: 'NOVA ZAGORA',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8919',
    addressNomenclature: '0',
    x: '26.020358',
    y: '42.464375',
    servingDays: '0101000',
    servingOfficeId: '56',
    servingHubOfficeId: '5',
  },
  {
    id: '27125',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕЗЕРОВО',
    nameEn: 'EZEROVO',
    municipality: 'БЕЛОСЛАВ',
    municipalityEn: 'BELOSLAV',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9168',
    addressNomenclature: '0',
    x: '27.767124',
    y: '43.201823',
    servingDays: '1111110',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '27139',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕЗЕРОВО',
    nameEn: 'EZEROVO',
    municipality: 'ПЪРВОМАЙ',
    municipalityEn: 'PARVOMAY',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4288',
    addressNomenclature: '0',
    x: '25.29659',
    y: '42.017773',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '27677',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕЗЕРОТО',
    nameEn: 'EZEROTO',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5309',
    addressNomenclature: '0',
    x: '25.361861',
    y: '42.764071',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '27156',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕЗЕРЧЕ',
    nameEn: 'EZERCHE',
    municipality: 'ЦАР КАЛОЯН',
    municipalityEn: 'TSAR KALOYAN',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7274',
    addressNomenclature: '0',
    x: '26.307362',
    y: '43.555878',
    servingDays: '0101000',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '27169',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕКЗАРХ АНТИМОВО',
    nameEn: 'EKZARH ANTIMOVO',
    municipality: 'КАРНОБАТ',
    municipalityEn: 'KARNOBAT',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8490',
    addressNomenclature: '0',
    x: '26.980861',
    y: '42.525044',
    servingDays: '1010100',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '27173',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕКЗАРХ ЙОСИФ',
    nameEn: 'EKZARH YOSIF',
    municipality: 'БОРОВО',
    municipalityEn: 'BOROVO',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7171',
    addressNomenclature: '0',
    x: '25.787076',
    y: '43.615708',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '27190',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ЕЛЕНА',
    nameEn: 'ELENA',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5070',
    addressNomenclature: '2',
    x: '25.878692',
    y: '42.929815',
    servingDays: '1111100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '27200',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕЛЕНА',
    nameEn: 'ELENA',
    municipality: 'ХАСКОВО',
    municipalityEn: 'HASKOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6399',
    addressNomenclature: '0',
    x: '25.793279',
    y: '41.841828',
    servingDays: '0101000',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '27214',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕЛЕНИНО',
    nameEn: 'ELENINO',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6047',
    addressNomenclature: '0',
    x: '25.604956',
    y: '42.367147',
    servingDays: '1111110',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '94054',
    countryId: '100',
    mainSiteId: '0',
    type: 'в.с.',
    typeEn: 'v.s.',
    name: 'ЕЛЕНИТЕ',
    nameEn: 'ELENITE',
    municipality: 'НЕСЕБЪР',
    municipalityEn: 'NESEBAR',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '',
    addressNomenclature: '0',
    x: '27.810124',
    y: '42.704436',
    servingDays: '1111110',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '41157',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕЛЕНКА',
    nameEn: 'ELENKA',
    municipality: 'НЕДЕЛИНО',
    municipalityEn: 'NEDELINO',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4990',
    addressNomenclature: '0',
    x: '25.112712',
    y: '41.464664',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '27228',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕЛЕНОВ ДОЛ',
    nameEn: 'ELENOV DOL',
    municipality: 'СВОГЕ',
    municipalityEn: 'SVOGE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2275',
    addressNomenclature: '0',
    x: '23.493817',
    y: '43.034043',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '27231',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕЛЕНОВО',
    nameEn: 'ELENOVO',
    municipality: 'БЛАГОЕВГРАД',
    municipalityEn: 'BLAGOEVGRAD',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2726',
    addressNomenclature: '0',
    x: '23.130364',
    y: '42.001543',
    servingDays: '1111110',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '27245',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕЛЕНОВО',
    nameEn: 'ELENOVO',
    municipality: 'НОВА ЗАГОРА',
    municipalityEn: 'NOVA ZAGORA',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8943',
    addressNomenclature: '0',
    x: '26.144813',
    y: '42.385575',
    servingDays: '0101000',
    servingOfficeId: '56',
    servingHubOfficeId: '5',
  },
  {
    id: '27259',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕЛЕНОВО',
    nameEn: 'ELENOVO',
    municipality: 'ПОПОВО',
    municipalityEn: 'POPOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7837',
    addressNomenclature: '0',
    x: '26.400151',
    y: '43.412282',
    servingDays: '0101000',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '27262',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕЛЕНСКА',
    nameEn: 'ELENSKA',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4731',
    addressNomenclature: '0',
    x: '24.8785',
    y: '41.573556',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '27276',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕЛЕНЦИТЕ',
    nameEn: 'ELENTSITE',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5388',
    addressNomenclature: '0',
    x: '25.445815',
    y: '43.032434',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '27293',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕЛЕШНИЦА',
    nameEn: 'ELESHNITSA',
    municipality: 'РАЗЛОГ',
    municipalityEn: 'RAZLOG',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2782',
    addressNomenclature: '0',
    x: '23.621244',
    y: '41.86645',
    servingDays: '1111100',
    servingOfficeId: '125',
    servingHubOfficeId: '13',
  },
  {
    id: '34120',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕЛЕШНИЦА',
    nameEn: 'ELESHNITSA',
    municipality: 'ЕЛИН ПЕЛИН',
    municipalityEn: 'ELIN PELIN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2125',
    addressNomenclature: '0',
    x: '23.626439',
    y: '42.743363',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '27303',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ЕЛИН ПЕЛИН',
    nameEn: 'ELIN PELIN',
    municipality: 'ЕЛИН ПЕЛИН',
    municipalityEn: 'ELIN PELIN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2100',
    addressNomenclature: '2',
    x: '23.601841',
    y: '42.669161',
    servingDays: '1111110',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '27317',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕЛИСЕЙНА',
    nameEn: 'ELISEYNA',
    municipality: 'МЕЗДРА',
    municipalityEn: 'MEZDRA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3160',
    addressNomenclature: '0',
    x: '23.485634',
    y: '43.075804',
    servingDays: '1010100',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '27334',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕЛОВ ДОЛ',
    nameEn: 'ELOV DOL',
    municipality: 'БОТЕВГРАД',
    municipalityEn: 'BOTEVGRAD',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2159',
    addressNomenclature: '0',
    x: '23.790765',
    y: '43.042707',
    servingDays: '0101000',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '27320',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕЛОВДОЛ',
    nameEn: 'ELOVDOL',
    municipality: 'ЗЕМЕН',
    municipalityEn: 'ZEMEN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2434',
    addressNomenclature: '0',
    x: '22.742443',
    y: '42.621049',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '27348',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕЛОВИЦА',
    nameEn: 'ELOVITSA',
    municipality: 'ГЕОРГИ ДАМЯНОВО',
    municipalityEn: 'GEORGI DAMYANOVO',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3471',
    addressNomenclature: '0',
    x: '22.989119',
    y: '43.33462',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '27351',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕЛОВИЦА',
    nameEn: 'ELOVITSA',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2489',
    addressNomenclature: '0',
    x: '22.561166',
    y: '42.744481',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '27365',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕЛХОВЕЦ',
    nameEn: 'ELHOVETS',
    municipality: 'РУДОЗЕМ',
    municipalityEn: 'RUDOZEM',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4970',
    addressNomenclature: '0',
    x: '24.801152',
    y: '41.444612',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '27379',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕЛХОВО',
    nameEn: 'ELHOVO',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6064',
    addressNomenclature: '0',
    x: '25.435184',
    y: '42.383414',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '27382',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ЕЛХОВО',
    nameEn: 'ELHOVO',
    municipality: 'ЕЛХОВО',
    municipalityEn: 'ELHOVO',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8700',
    addressNomenclature: '2',
    x: '26.569402',
    y: '42.17202',
    servingDays: '1111110',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '48163',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕЛХОВО',
    nameEn: 'ELHOVO',
    municipality: 'НИКОЛАЕВО',
    municipalityEn: 'NIKOLAEVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6174',
    addressNomenclature: '0',
    x: '25.745425',
    y: '42.575729',
    servingDays: '0101000',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '27406',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕЛШИЦА',
    nameEn: 'ELSHITSA',
    municipality: 'ПАНАГЮРИЩЕ',
    municipalityEn: 'PANAGYURISHTE',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4538',
    addressNomenclature: '0',
    x: '24.218542',
    y: '42.368918',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '27415',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕЛЬОВО',
    nameEn: 'ELYOVO',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4783',
    addressNomenclature: '0',
    x: '24.720442',
    y: '41.445938',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '27423',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕМЕН',
    nameEn: 'EMEN',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5065',
    addressNomenclature: '0',
    x: '25.356939',
    y: '43.134515',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '27454',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕМОНА',
    nameEn: 'EMONA',
    municipality: 'НЕСЕБЪР',
    municipalityEn: 'NESEBAR',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8252',
    addressNomenclature: '0',
    x: '27.885635',
    y: '42.714875',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '27485',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕНЕВ РЪТ',
    nameEn: 'ENEV RAT',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5469',
    addressNomenclature: '0',
    x: '25.039601',
    y: '42.913897',
    servingDays: '1010100',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '27468',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕНЕВО',
    nameEn: 'ENEVO',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9389',
    addressNomenclature: '0',
    x: '27.526816',
    y: '43.637818',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '27471',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕНЕВО',
    nameEn: 'ENEVO',
    municipality: 'НОВИ ПАЗАР',
    municipalityEn: 'NOVI PAZAR',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9919',
    addressNomenclature: '0',
    x: '27.218688',
    y: '43.319034',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '27499',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕНИНА',
    nameEn: 'ENINA',
    municipality: 'КАЗАНЛЪК',
    municipalityEn: 'KAZANLAK',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6142',
    addressNomenclature: '0',
    x: '25.409128',
    y: '42.667504',
    servingDays: '1111100',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '27509',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕНИЦА',
    nameEn: 'ENITSA',
    municipality: 'КНЕЖА',
    municipalityEn: 'KNEZHA',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5837',
    addressNomenclature: '0',
    x: '24.063348',
    y: '43.380071',
    servingDays: '1010100',
    servingOfficeId: '59',
    servingHubOfficeId: '20',
  },
  {
    id: '27512',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕНЧЕЦ',
    nameEn: 'ENCHETS',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6735',
    addressNomenclature: '0',
    x: '25.328813',
    y: '41.652647',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '27526',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕНЧОВЦИ',
    nameEn: 'ENCHOVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5360',
    addressNomenclature: '0',
    x: '25.436941',
    y: '42.822473',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '27543',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕНЬОВЧЕ',
    nameEn: 'ENYOVCHE',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6788',
    addressNomenclature: '0',
    x: '25.042381',
    y: '41.517251',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '27557',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕРДЕН',
    nameEn: 'ERDEN',
    municipality: 'БОЙЧИНОВЦИ',
    municipalityEn: 'BOYCHINOVTSI',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3431',
    addressNomenclature: '0',
    x: '23.289172',
    y: '43.483789',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '27574',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕРЕМИЯ',
    nameEn: 'EREMIA',
    municipality: 'НЕВЕСТИНО',
    municipalityEn: 'NEVESTINO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2585',
    addressNomenclature: '0',
    x: '22.84127',
    y: '42.206861',
    servingDays: '1010100',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '27588',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕРМА РЕКА',
    nameEn: 'ERMA REKA',
    municipality: 'ЗЛАТОГРАД',
    municipalityEn: 'ZLATOGRAD',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4997',
    addressNomenclature: '0',
    x: '24.966071',
    y: '41.413019',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '27591',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕРОВЕТЕ',
    nameEn: 'EROVETE',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6865',
    addressNomenclature: '0',
    x: '25.28265',
    y: '41.369489',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '27601',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕРУЛ',
    nameEn: 'ERUL',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2486',
    addressNomenclature: '0',
    x: '22.66313',
    y: '42.737326',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '27615',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕСЕН',
    nameEn: 'ESEN',
    municipality: 'СУНГУРЛАРЕ',
    municipalityEn: 'SUNGURLARE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8459',
    addressNomenclature: '0',
    x: '26.780572',
    y: '42.826296',
    servingDays: '1010100',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '27629',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕСЕНИЦА',
    nameEn: 'ESENITSA',
    municipality: 'ВЪЛЧИ ДОЛ',
    municipalityEn: 'VALCHI DOL',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9289',
    addressNomenclature: '0',
    x: '27.394316',
    y: '43.440474',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '27632',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ЕТРОПОЛЕ',
    nameEn: 'ETROPOLE',
    municipality: 'ЕТРОПОЛЕ',
    municipalityEn: 'ETROPOLE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2180',
    addressNomenclature: '2',
    x: '24.000838',
    y: '42.838161',
    servingDays: '1111100',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '27656',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕФРЕЙТОР БАКАЛОВО',
    nameEn: 'EFREYTOR BAKALOVO',
    municipality: 'КРУШАРИ',
    municipalityEn: 'KRUSHARI',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9422',
    addressNomenclature: '0',
    x: '27.606802',
    y: '43.83408',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '27663',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЕФРЕМ',
    nameEn: 'EFREM',
    municipality: 'МАДЖАРОВО',
    municipalityEn: 'MADZHAROVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6476',
    addressNomenclature: '0',
    x: '25.915016',
    y: '41.712497',
    servingDays: '0101000',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '29012',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖАБЛЯНО',
    nameEn: 'ZHABLYANO',
    municipality: 'ЗЕМЕН',
    municipalityEn: 'ZEMEN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2442',
    addressNomenclature: '0',
    x: '22.789157',
    y: '42.492711',
    servingDays: '1111100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '29026',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖАБОКРЪТ',
    nameEn: 'ZHABOKRAT',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2545',
    addressNomenclature: '0',
    x: '22.75884',
    y: '42.301316',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '29035',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖЕГЛАРЦИ',
    nameEn: 'ZHEGLARTSI',
    municipality: 'ТЕРВЕЛ',
    municipalityEn: 'TERVEL',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9492',
    addressNomenclature: '0',
    x: '27.408722',
    y: '43.660493',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '29043',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖЕГЛИЦА',
    nameEn: 'ZHEGLITSA',
    municipality: 'ВИДИН',
    municipalityEn: 'VIDIN',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3738',
    addressNomenclature: '0',
    x: '22.798213',
    y: '43.880083',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '29060',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖЕДНА',
    nameEn: 'ZHEDNA',
    municipality: 'РАДОМИР',
    municipalityEn: 'RADOMIR',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2423',
    addressNomenclature: '0',
    x: '22.960313',
    y: '42.423915',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '29074',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖЕЛЕЗАРИ',
    nameEn: 'ZHELEZARI',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6594',
    addressNomenclature: '0',
    x: '25.997755',
    y: '41.414403',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '29091',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖЕЛЕЗАРЦИ',
    nameEn: 'ZHELEZARTSI',
    municipality: 'СТРАЖИЦА',
    municipalityEn: 'STRAZHITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5164',
    addressNomenclature: '0',
    x: '26.068824',
    y: '43.144171',
    servingDays: '1010100',
    servingOfficeId: '46',
    servingHubOfficeId: '11',
  },
  {
    id: '29101',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖЕЛЕЗИНО',
    nameEn: 'ZHELEZINO',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6595',
    addressNomenclature: '0',
    x: '25.950218',
    y: '41.475972',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '29115',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖЕЛЕЗНА',
    nameEn: 'ZHELEZNA',
    municipality: 'ЧИПРОВЦИ',
    municipalityEn: 'CHIPROVTSI',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3461',
    addressNomenclature: '0',
    x: '22.915685',
    y: '43.382671',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '29129',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖЕЛЕЗНИК',
    nameEn: 'ZHELEZNIK',
    municipality: 'КАРНОБАТ',
    municipalityEn: 'KARNOBAT',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8488',
    addressNomenclature: '0',
    x: '26.903588',
    y: '42.571218',
    servingDays: '1010100',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '29132',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖЕЛЕЗНИК',
    nameEn: 'ZHELEZNIK',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6711',
    addressNomenclature: '0',
    x: '25.352272',
    y: '41.743165',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '29146',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖЕЛЕЗНИЦА',
    nameEn: 'ZHELEZNITSA',
    municipality: 'СИМИТЛИ',
    municipalityEn: 'SIMITLI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2732',
    addressNomenclature: '0',
    x: '23.105917',
    y: '41.92536',
    servingDays: '1111110',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '29150',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖЕЛЕЗНИЦА',
    nameEn: 'ZHELEZNITSA',
    municipality: 'СТОЛИЧНА',
    municipalityEn: 'STOLICHNA',
    region: 'СОФИЯ (СТОЛИЦА)',
    regionEn: 'SOFIA (STOLITSA)',
    postCode: '1475',
    addressNomenclature: '2',
    x: '23.365433',
    y: '42.535364',
    servingDays: '1111100',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '29163',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖЕЛЕН',
    nameEn: 'ZHELEN',
    municipality: 'СВОГЕ',
    municipalityEn: 'SVOGE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2267',
    addressNomenclature: '0',
    x: '23.37502',
    y: '42.988333',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '29177',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖЕЛЪД',
    nameEn: 'ZHELAD',
    municipality: 'СМЯДОВО',
    municipalityEn: 'SMYADOVO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9826',
    addressNomenclature: '0',
    x: '27.173033',
    y: '43.049411',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '29180',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖЕЛЪДОВО',
    nameEn: 'ZHELADOVO',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6855',
    addressNomenclature: '0',
    x: '25.213987',
    y: '41.44671',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '29194',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖЕЛЮ ВОЙВОДА',
    nameEn: 'ZHELYU VOYVODA',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8860',
    addressNomenclature: '0',
    x: '26.482684',
    y: '42.594128',
    servingDays: '1010100',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '29204',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖЕЛЯВА',
    nameEn: 'ZHELYAVA',
    municipality: 'СТОЛИЧНА',
    municipalityEn: 'STOLICHNA',
    region: 'СОФИЯ (СТОЛИЦА)',
    regionEn: 'SOFIA (STOLITSA)',
    postCode: '1807',
    addressNomenclature: '2',
    x: '23.60636',
    y: '42.746214',
    servingDays: '1111100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '29218',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖЕЛЯЗКОВЕЦ',
    nameEn: 'ZHELYAZKOVETS',
    municipality: 'САМУИЛ',
    municipalityEn: 'SAMUIL',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7455',
    addressNomenclature: '0',
    x: '26.699018',
    y: '43.512236',
    servingDays: '1010100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '29235',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖЕЛЯЗНО',
    nameEn: 'ZHELYAZNO',
    municipality: 'МАРИЦА',
    municipalityEn: 'MARITSA',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4136',
    addressNomenclature: '0',
    x: '24.782187',
    y: '42.215606',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '29249',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖЕЛЯЗОВО',
    nameEn: 'ZHELYAZOVO',
    municipality: 'КАМЕНО',
    municipalityEn: 'KAMENO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8106',
    addressNomenclature: '0',
    x: '27.138434',
    y: '42.480464',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '29252',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖЕНДА',
    nameEn: 'ZHENDA',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6693',
    addressNomenclature: '0',
    x: '25.180991',
    y: '41.777101',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '29266',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖЕРАВИНО',
    nameEn: 'ZHERAVINO',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2567',
    addressNomenclature: '0',
    x: '22.386231',
    y: '42.318884',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '29276',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖЕРАВИЦА',
    nameEn: 'ZHERAVITSA',
    municipality: 'ТРОЯН',
    municipalityEn: 'TROYAN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '',
    addressNomenclature: '0',
    x: '24.738939',
    y: '42.821184',
    servingDays: '1010100',
    servingOfficeId: '44',
    servingHubOfficeId: '11',
  },
  {
    id: '29283',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖЕРАВНА',
    nameEn: 'ZHERAVNA',
    municipality: 'КОТЕЛ',
    municipalityEn: 'KOTEL',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8988',
    addressNomenclature: '0',
    x: '26.457452',
    y: '42.833767',
    servingDays: '0101000',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '29297',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖЕРГОВЕЦ',
    nameEn: 'ZHERGOVETS',
    municipality: 'ГУРКОВО',
    municipalityEn: 'GURKOVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6190',
    addressNomenclature: '0',
    x: '25.697132',
    y: '42.660511',
    servingDays: '0101000',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '29310',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖЕРНОВ',
    nameEn: 'ZHERNOV',
    municipality: 'НИКОПОЛ',
    municipalityEn: 'NIKOPOL',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5964',
    addressNomenclature: '0',
    x: '24.863175',
    y: '43.655389',
    servingDays: '0101000',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '29324',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖИВКО',
    nameEn: 'ZHIVKO',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5344',
    addressNomenclature: '0',
    x: '25.257846',
    y: '42.942824',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '29338',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖИВКОВО',
    nameEn: 'ZHIVKOVO',
    municipality: 'ИХТИМАН',
    municipalityEn: 'IHTIMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2065',
    addressNomenclature: '0',
    x: '23.754768',
    y: '42.449715',
    servingDays: '1010100',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '29341',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖИВКОВО',
    nameEn: 'ZHIVKOVO',
    municipality: 'ХИТРИНО',
    municipalityEn: 'HITRINO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9794',
    addressNomenclature: '0',
    x: '27.022316',
    y: '43.442058',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '29369',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖИДОВ ДОЛ',
    nameEn: 'ZHIDOV DOL',
    municipality: 'ТРОЯН',
    municipalityEn: 'TROYAN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '',
    addressNomenclature: '0',
    x: '24.805129',
    y: '42.778875',
    servingDays: '1010100',
    servingOfficeId: '44',
    servingHubOfficeId: '11',
  },
  {
    id: '29372',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖИЖЕВО',
    nameEn: 'ZHIZHEVO',
    municipality: 'САТОВЧА',
    municipalityEn: 'SATOVCHA',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2956',
    addressNomenclature: '0',
    x: '24.030399',
    y: '41.550736',
    servingDays: '1010100',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '29386',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖИЛЕНЦИ',
    nameEn: 'ZHILENTSI',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2573',
    addressNomenclature: '0',
    x: '22.639721',
    y: '42.267309',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '29391',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖИЛИНО',
    nameEn: 'ZHILINO',
    municipality: 'НОВИ ПАЗАР',
    municipalityEn: 'NOVI PAZAR',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9929',
    addressNomenclature: '0',
    x: '27.172801',
    y: '43.430211',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '29407',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖИНЗИФОВО',
    nameEn: 'ZHINZIFOVO',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6654',
    addressNomenclature: '0',
    x: '25.485972',
    y: '41.664359',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '29413',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖИТАРНИК',
    nameEn: 'ZHITARNIK',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6636',
    addressNomenclature: '0',
    x: '25.286855',
    y: '41.567652',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '29430',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖИТЕН',
    nameEn: 'ZHITEN',
    municipality: 'СТОЛИЧНА',
    municipalityEn: 'STOLICHNA',
    region: 'СОФИЯ (СТОЛИЦА)',
    regionEn: 'SOFIA (STOLITSA)',
    postCode: '1211',
    addressNomenclature: '2',
    x: '23.250913',
    y: '42.818528',
    servingDays: '1111100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '29444',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖИТЕН',
    nameEn: 'ZHITEN',
    municipality: 'ГЕНЕРАЛ ТОШЕВО',
    municipalityEn: 'GENERAL TOSHEVO',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9555',
    addressNomenclature: '0',
    x: '27.894223',
    y: '43.814665',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '29458',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖИТНИЦА',
    nameEn: 'ZHITNITSA',
    municipality: 'ПРОВАДИЯ',
    municipalityEn: 'PROVADIA',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9278',
    addressNomenclature: '0',
    x: '27.524464',
    y: '43.12621',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '29461',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖИТНИЦА',
    nameEn: 'ZHITNITSA',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6708',
    addressNomenclature: '0',
    x: '25.313997',
    y: '41.754685',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '29475',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖИТНИЦА',
    nameEn: 'ZHITNITSA',
    municipality: 'КАЛОЯНОВО',
    municipalityEn: 'KALOYANOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4172',
    addressNomenclature: '0',
    x: '24.696332',
    y: '42.385129',
    servingDays: '1111100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '29489',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖИТНИЦА',
    nameEn: 'ZHITNITSA',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9424',
    addressNomenclature: '0',
    x: '27.638415',
    y: '43.735487',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '29492',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖИТОСВЯТ',
    nameEn: 'ZHITOSVYAT',
    municipality: 'КАРНОБАТ',
    municipalityEn: 'KARNOBAT',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8475',
    addressNomenclature: '0',
    x: '26.978113',
    y: '42.462458',
    servingDays: '1010100',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '29622',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖИТУША',
    nameEn: 'ZHITUSHA',
    municipality: 'РАДОМИР',
    municipalityEn: 'RADOMIR',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2432',
    addressNomenclature: '0',
    x: '22.936509',
    y: '42.415913',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '29502',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖРЕБЕВО',
    nameEn: 'ZHREBEVO',
    municipality: 'ДЕВИН',
    municipalityEn: 'DEVIN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4827',
    addressNomenclature: '0',
    x: '24.416994',
    y: '41.566174',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '29516',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖРЕБИНО',
    nameEn: 'ZHREBINO',
    municipality: 'ЕЛХОВО',
    municipalityEn: 'ELHOVO',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8733',
    addressNomenclature: '0',
    x: '26.70669',
    y: '42.244529',
    servingDays: '0101000',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '29522',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖРЕБИЧКО',
    nameEn: 'ZHREBICHKO',
    municipality: 'БРАЦИГОВО',
    municipalityEn: 'BRATSIGOVO',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4585',
    addressNomenclature: '0',
    x: '24.428923',
    y: '42.02664',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '29533',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖЪЛТ БРЯГ',
    nameEn: 'ZHALT BRYAG',
    municipality: 'ТВЪРДИЦА',
    municipalityEn: 'TVARDITSA',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8894',
    addressNomenclature: '0',
    x: '26.114122',
    y: '42.740258',
    servingDays: '0101000',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '29581',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖЪЛТ КАМЪК',
    nameEn: 'ZHALT KAMAK',
    municipality: 'АСЕНОВГРАД',
    municipalityEn: 'ASENOVGRAD',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4298',
    addressNomenclature: '0',
    x: '25.133606',
    y: '41.851214',
    servingDays: '0101000',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '29547',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖЪЛТЕШ',
    nameEn: 'ZHALTESH',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5335',
    addressNomenclature: '0',
    x: '25.38696',
    y: '42.856627',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '29550',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖЪЛТИ БРЯГ',
    nameEn: 'ZHALTI BRYAG',
    municipality: 'СТАМБОЛОВО',
    municipalityEn: 'STAMBOLOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6368',
    addressNomenclature: '0',
    x: '25.624481',
    y: '41.804039',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '29564',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖЪЛТИ РИД',
    nameEn: 'ZHALTI RID',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6846',
    addressNomenclature: '0',
    x: '25.334652',
    y: '41.462925',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '29619',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖЪЛТИКА',
    nameEn: 'ZHALTIKA',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6842',
    addressNomenclature: '0',
    x: '25.200119',
    y: '41.512728',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '29595',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖЪЛТОПОП',
    nameEn: 'ZHALTOPOP',
    municipality: 'ГУРКОВО',
    municipalityEn: 'GURKOVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6195',
    addressNomenclature: '0',
    x: '25.711647',
    y: '42.667739',
    servingDays: '0101000',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '29605',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЖЪЛТУША',
    nameEn: 'ZHALTUSHA',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6789',
    addressNomenclature: '0',
    x: '25.050427',
    y: '41.532824',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '30017',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗАБЕЛ',
    nameEn: 'ZABEL',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2469',
    addressNomenclature: '0',
    x: '22.584296',
    y: '42.833462',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '30020',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗАБЕРНОВО',
    nameEn: 'ZABERNOVO',
    municipality: 'МАЛКО ТЪРНОВО',
    municipalityEn: 'MALKO TARNOVO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8168',
    addressNomenclature: '0',
    x: '27.562424',
    y: '42.102073',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '30034',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗАБЪРДО',
    nameEn: 'ZABARDO',
    municipality: 'ЧЕПЕЛАРЕ',
    municipalityEn: 'CHEPELARE',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4872',
    addressNomenclature: '0',
    x: '24.590643',
    y: '41.784256',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '30048',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗАВАЛА',
    nameEn: 'ZAVALA',
    municipality: 'БРЕЗНИК',
    municipalityEn: 'BREZNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2374',
    addressNomenclature: '0',
    x: '22.800971',
    y: '42.825013',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '30051',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗАВЕТ',
    nameEn: 'ZAVET',
    municipality: 'СУНГУРЛАРЕ',
    municipalityEn: 'SUNGURLARE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8432',
    addressNomenclature: '0',
    x: '27.062728',
    y: '42.836258',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '30065',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ЗАВЕТ',
    nameEn: 'ZAVET',
    municipality: 'ЗАВЕТ',
    municipalityEn: 'ZAVET',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7330',
    addressNomenclature: '2',
    x: '26.671857',
    y: '43.759546',
    servingDays: '1111100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '30079',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗАВЕТНО',
    nameEn: 'ZAVETNO',
    municipality: 'ПОПОВО',
    municipalityEn: 'POPOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7881',
    addressNomenclature: '0',
    x: '26.338045',
    y: '43.33',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '30082',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗАВИДОВЦИ',
    nameEn: 'ZAVIDOVTSI',
    municipality: 'СВОГЕ',
    municipalityEn: 'SVOGE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2288',
    addressNomenclature: '0',
    x: '23.17526',
    y: '42.982566',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '30096',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗАВОЙ',
    nameEn: 'ZAVOY',
    municipality: 'ТУНДЖА',
    municipalityEn: 'TUNDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8633',
    addressNomenclature: '0',
    x: '26.527164',
    y: '42.56234',
    servingDays: '1010100',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '30106',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗАВОЯ',
    nameEn: 'ZAVOYA',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6885',
    addressNomenclature: '0',
    x: '25.34781',
    y: '41.335996',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '30119',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗАГОРЕ',
    nameEn: 'ZAGORE',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6063',
    addressNomenclature: '0',
    x: '25.671605',
    y: '42.351047',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '30137',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗАГОРИЧЕ',
    nameEn: 'ZAGORICHE',
    municipality: 'КАОЛИНОВО',
    municipalityEn: 'KAOLINOVO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9966',
    addressNomenclature: '0',
    x: '27.080438',
    y: '43.699281',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '30140',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗАГОРСКИ',
    nameEn: 'ZAGORSKI',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6865',
    addressNomenclature: '0',
    x: '25.230283',
    y: '41.408284',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '30154',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗАГОРСКО',
    nameEn: 'ZAGORSKO',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6817',
    addressNomenclature: '0',
    x: '25.379643',
    y: '41.486031',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '30168',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗАГОРЦИ',
    nameEn: 'ZAGORTSI',
    municipality: 'СРЕДЕЦ',
    municipalityEn: 'SREDETS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8321',
    addressNomenclature: '0',
    x: '27.05806',
    y: '42.394191',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '30171',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗАГОРЦИ',
    nameEn: 'ZAGORTSI',
    municipality: 'НОВА ЗАГОРА',
    municipalityEn: 'NOVA ZAGORA',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8939',
    addressNomenclature: '0',
    x: '25.948878',
    y: '42.433794',
    servingDays: '1010100',
    servingOfficeId: '56',
    servingHubOfficeId: '5',
  },
  {
    id: '30185',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗАГОРЦИ',
    nameEn: 'ZAGORTSI',
    municipality: 'КРУШАРИ',
    municipalityEn: 'KRUSHARI',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9413',
    addressNomenclature: '0',
    x: '27.785656',
    y: '43.783339',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '30199',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗАГРАЖДЕН',
    nameEn: 'ZAGRAZHDEN',
    municipality: 'ГУЛЯНЦИ',
    municipalityEn: 'GULYANTSI',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5974',
    addressNomenclature: '0',
    x: '24.561561',
    y: '43.744323',
    servingDays: '0101000',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '30209',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗАГРАЖДЕН',
    nameEn: 'ZAGRAZHDEN',
    municipality: 'БАНИТЕ',
    municipalityEn: 'BANITE',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4950',
    addressNomenclature: '0',
    x: '24.994143',
    y: '41.719495',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '30212',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗАДРУГА',
    nameEn: 'ZADRUGA',
    municipality: 'КУБРАТ',
    municipalityEn: 'KUBRAT',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7317',
    addressNomenclature: '0',
    x: '26.499781',
    y: '43.849407',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '30226',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗАЕВИТЕ',
    nameEn: 'ZAEVITE',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4710',
    addressNomenclature: '0',
    x: '24.571212',
    y: '41.674391',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '30234',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗАИМЧЕВО',
    nameEn: 'ZAIMCHEVO',
    municipality: 'РУЕН',
    municipalityEn: 'RUEN',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8564',
    addressNomenclature: '0',
    x: '27.30366',
    y: '42.894936',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '30243',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗАЙЧАР',
    nameEn: 'ZAYCHAR',
    municipality: 'РУЕН',
    municipalityEn: 'RUEN',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8557',
    addressNomenclature: '0',
    x: '27.170753',
    y: '42.778477',
    servingDays: '1111100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '30257',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗАЙЧАРИ',
    nameEn: 'ZAYCHARI',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8843',
    addressNomenclature: '0',
    x: '26.196081',
    y: '42.923168',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '30260',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗАЙЧИНО',
    nameEn: 'ZAYCHINO',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6749',
    addressNomenclature: '0',
    x: '25.331732',
    y: '41.697487',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '30274',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗАЙЧИНО ОРЕШЕ',
    nameEn: 'ZAYCHINO ORESHE',
    municipality: 'НОВИ ПАЗАР',
    municipalityEn: 'NOVI PAZAR',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9918',
    addressNomenclature: '0',
    x: '27.269589',
    y: '43.312036',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '30291',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗАМФИР',
    nameEn: 'ZAMFIR',
    municipality: 'ЛОМ',
    municipalityEn: 'LOM',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3644',
    addressNomenclature: '0',
    x: '23.248874',
    y: '43.784831',
    servingDays: '1111100',
    servingOfficeId: '831',
    servingHubOfficeId: '20',
  },
  {
    id: '30301',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗАМФИРОВО',
    nameEn: 'ZAMFIROVO',
    municipality: 'БЕРКОВИЦА',
    municipalityEn: 'BERKOVITSA',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3530',
    addressNomenclature: '0',
    x: '23.242163',
    y: '43.29706',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '30315',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗАНОГА',
    nameEn: 'ZANOGA',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '',
    addressNomenclature: '0',
    x: '23.060093',
    y: '41.527925',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '30329',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗАНОГЕ',
    nameEn: 'ZANOGE',
    municipality: 'СВОГЕ',
    municipalityEn: 'SVOGE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2283',
    addressNomenclature: '0',
    x: '23.32032',
    y: '43.068639',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '30332',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗАРАЕВО',
    nameEn: 'ZARAEVO',
    municipality: 'ПОПОВО',
    municipalityEn: 'POPOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7832',
    addressNomenclature: '0',
    x: '26.260819',
    y: '43.43529',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '31406',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗАРИЦА',
    nameEn: 'ZARITSA',
    municipality: 'ГЛАВИНИЦА',
    municipalityEn: 'GLAVINITSA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7689',
    addressNomenclature: '0',
    x: '26.947167',
    y: '43.837304',
    servingDays: '1010100',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '30346',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗАРНИК',
    nameEn: 'ZARNIK',
    municipality: 'КАЙНАРДЖА',
    municipalityEn: 'KAYNARDZHA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7552',
    addressNomenclature: '0',
    x: '27.542525',
    y: '43.973598',
    servingDays: '0101000',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '30350',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗАСЕЛЕ',
    nameEn: 'ZASELE',
    municipality: 'СВОГЕ',
    municipalityEn: 'SVOGE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2284',
    addressNomenclature: '0',
    x: '23.329734',
    y: '43.039092',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '30363',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗАСМЯНО',
    nameEn: 'ZASMYANO',
    municipality: 'АКСАКОВО',
    municipalityEn: 'AKSAKOVO',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9141',
    addressNomenclature: '0',
    x: '27.687132',
    y: '43.389216',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '30377',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗАФИРОВО',
    nameEn: 'ZAFIROVO',
    municipality: 'ГЛАВИНИЦА',
    municipalityEn: 'GLAVINITSA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7610',
    addressNomenclature: '0',
    x: '26.823573',
    y: '44.000211',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '30394',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗАХАРИ СТОЯНОВО',
    nameEn: 'ZAHARI STOYANOVO',
    municipality: 'ШАБЛА',
    municipalityEn: 'SHABLA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9671',
    addressNomenclature: '0',
    x: '28.456061',
    y: '43.693722',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '30404',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗАХАРИ СТОЯНОВО',
    nameEn: 'ZAHARI STOYANOVO',
    municipality: 'ПОПОВО',
    municipalityEn: 'POPOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7842',
    addressNomenclature: '0',
    x: '26.248393',
    y: '43.532912',
    servingDays: '0101000',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '30418',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗАЯ',
    nameEn: 'ZAYA',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5397',
    addressNomenclature: '0',
    x: '25.514201',
    y: '42.998719',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '30421',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗВЕГОР',
    nameEn: 'ZVEGOR',
    municipality: 'ХИТРИНО',
    municipalityEn: 'HITRINO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9791',
    addressNomenclature: '0',
    x: '26.807952',
    y: '43.395862',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '30435',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗВЕЗДА',
    nameEn: 'ZVEZDA',
    municipality: 'РУЕН',
    municipalityEn: 'RUEN',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8572',
    addressNomenclature: '0',
    x: '27.094989',
    y: '42.91604',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '30449',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗВЕЗДА',
    nameEn: 'ZVEZDA',
    municipality: 'ПОПОВО',
    municipalityEn: 'POPOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7878',
    addressNomenclature: '0',
    x: '26.238143',
    y: '43.279341',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '30452',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗВЕЗДЕЛ',
    nameEn: 'ZVEZDEL',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6820',
    addressNomenclature: '0',
    x: '25.521126',
    y: '41.470899',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '30466',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗВЕЗДЕЛИНА',
    nameEn: 'ZVEZDELINA',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6653',
    addressNomenclature: '0',
    x: '25.484311',
    y: '41.643813',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '30475',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗВЕЗДЕН',
    nameEn: 'ZVEZDEN',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6658',
    addressNomenclature: '0',
    x: '25.431847',
    y: '41.675454',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '30483',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗВЕЗДЕЦ',
    nameEn: 'ZVEZDETS',
    municipality: 'МАЛКО ТЪРНОВО',
    municipalityEn: 'MALKO TARNOVO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8170',
    addressNomenclature: '0',
    x: '27.417469',
    y: '42.10936',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '30497',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗВЕЗДИЦА',
    nameEn: 'ZVEZDITSA',
    municipality: 'ВАРНА',
    municipalityEn: 'VARNA',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9103',
    addressNomenclature: '0',
    x: '27.839863',
    y: '43.154511',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '31416',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗВЕЗДИЦА',
    nameEn: 'ZVEZDITSA',
    municipality: 'ОМУРТАГ',
    municipalityEn: 'OMURTAG',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7945',
    addressNomenclature: '0',
    x: '26.575746',
    y: '43.008257',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '30507',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗВЕНИМИР',
    nameEn: 'ZVENIMIR',
    municipality: 'ГЛАВИНИЦА',
    municipalityEn: 'GLAVINITSA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7644',
    addressNomenclature: '0',
    x: '26.923613',
    y: '43.825799',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '30510',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗВЕРИНО',
    nameEn: 'ZVERINO',
    municipality: 'МЕЗДРА',
    municipalityEn: 'MEZDRA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3170',
    addressNomenclature: '0',
    x: '23.564439',
    y: '43.079428',
    servingDays: '1010100',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '30524',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗВИНИЦА',
    nameEn: 'ZVINITSA',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6668',
    addressNomenclature: '0',
    x: '25.57657',
    y: '41.745564',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '30538',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗВЪНАРКА',
    nameEn: 'ZVANARKA',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6920',
    addressNomenclature: '0',
    x: '25.628654',
    y: '41.44243',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '30541',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗВЪНАРЦИ',
    nameEn: 'ZVANARTSI',
    municipality: 'КУБРАТ',
    municipalityEn: 'KUBRAT',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7333',
    addressNomenclature: '0',
    x: '26.45922',
    y: '43.915037',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '30555',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗВЪНЕЦ',
    nameEn: 'ZVANETS',
    municipality: 'ВЪЛЧИ ДОЛ',
    municipalityEn: 'VALCHI DOL',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9142',
    addressNomenclature: '0',
    x: '27.596168',
    y: '43.493522',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '30569',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗВЪНИКА',
    nameEn: 'ZVANIKA',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6732',
    addressNomenclature: '0',
    x: '25.280326',
    y: '41.651016',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '30572',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗВЪНИЧЕВО',
    nameEn: 'ZVANICHEVO',
    municipality: 'ПАЗАРДЖИК',
    municipalityEn: 'PAZARDZHIK',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4415',
    addressNomenclature: '0',
    x: '24.250734',
    y: '42.19041',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '30586',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗВЪНЧЕ',
    nameEn: 'ZVANCHE',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6744',
    addressNomenclature: '0',
    x: '25.286611',
    y: '41.714978',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '30590',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗГАЛЕВО',
    nameEn: 'ZGALEVO',
    municipality: 'ПОРДИМ',
    municipalityEn: 'PORDIM',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5897',
    addressNomenclature: '0',
    x: '24.800984',
    y: '43.375769',
    servingDays: '0101000',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '30606',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗГОРИГРАД',
    nameEn: 'ZGORIGRAD',
    municipality: 'ВРАЦА',
    municipalityEn: 'VRATSA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3042',
    addressNomenclature: '0',
    x: '23.516272',
    y: '43.179603',
    servingDays: '1010100',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '30613',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗГУРОВО',
    nameEn: 'ZGUROVO',
    municipality: 'НЕВЕСТИНО',
    municipalityEn: 'NEVESTINO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2592',
    addressNomenclature: '0',
    x: '22.781222',
    y: '42.220881',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '30627',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗДРАВЕЦ',
    nameEn: 'ZDRAVETS',
    municipality: 'АВРЕН',
    municipalityEn: 'AVREN',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9137',
    addressNomenclature: '0',
    x: '27.739528',
    y: '43.12583',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '30630',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗДРАВЕЦ',
    nameEn: 'ZDRAVETS',
    municipality: 'САМУИЛ',
    municipalityEn: 'SAMUIL',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7437',
    addressNomenclature: '0',
    x: '26.905937',
    y: '43.637304',
    servingDays: '1010100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '30644',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗДРАВЕЦ',
    nameEn: 'ZDRAVETS',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7769',
    addressNomenclature: '0',
    x: '26.490251',
    y: '43.285254',
    servingDays: '1111100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '30658',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗДРАВЕЦ',
    nameEn: 'ZDRAVETS',
    municipality: 'ДИМИТРОВГРАД',
    municipalityEn: 'DIMITROVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6435',
    addressNomenclature: '0',
    x: '25.571058',
    y: '42.141565',
    servingDays: '1010100',
    servingOfficeId: '42',
    servingHubOfficeId: '5',
  },
  {
    id: '31423',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗДРАВЕЦ',
    nameEn: 'ZDRAVETS',
    municipality: 'ЛЪКИ',
    municipalityEn: 'LAKI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4241',
    addressNomenclature: '0',
    x: '24.79718',
    y: '41.744039',
    servingDays: '0101000',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '30661',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗДРАВКОВЕЦ',
    nameEn: 'ZDRAVKOVETS',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5344',
    addressNomenclature: '0',
    x: '25.241692',
    y: '42.960813',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '30675',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗДРАВЧЕЦ',
    nameEn: 'ZDRAVCHETS',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6861',
    addressNomenclature: '0',
    x: '25.195208',
    y: '41.398786',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '30689',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗЕБИЛ',
    nameEn: 'ZEBIL',
    municipality: 'ГЛАВИНИЦА',
    municipalityEn: 'GLAVINITSA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7641',
    addressNomenclature: '0',
    x: '26.881842',
    y: '43.813544',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '30692',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗЕЛЕНА МОРАВА',
    nameEn: 'ZELENA MORAVA',
    municipality: 'ОМУРТАГ',
    municipalityEn: 'OMURTAG',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7954',
    addressNomenclature: '0',
    x: '26.402848',
    y: '43.040181',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '30702',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗЕЛЕНДОЛ',
    nameEn: 'ZELENDOL',
    municipality: 'БЛАГОЕВГРАД',
    municipalityEn: 'BLAGOEVGRAD',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2745',
    addressNomenclature: '0',
    x: '23.030784',
    y: '42.008878',
    servingDays: '1111110',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '30716',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗЕЛЕНИГРАД',
    nameEn: 'ZELENIGRAD',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2478',
    addressNomenclature: '0',
    x: '22.560627',
    y: '42.836964',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '30721',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗЕЛЕНИК',
    nameEn: 'ZELENIK',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5099',
    addressNomenclature: '0',
    x: '25.999377',
    y: '42.846327',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '30733',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗЕЛЕНИКА',
    nameEn: 'ZELENIKA',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5351',
    addressNomenclature: '0',
    x: '25.468269',
    y: '42.870606',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '30747',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗЕЛЕНИКОВО',
    nameEn: 'ZELENIKOVO',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6740',
    addressNomenclature: '0',
    x: '25.326685',
    y: '41.662611',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '30750',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗЕЛЕНИКОВО',
    nameEn: 'ZELENIKOVO',
    municipality: 'БРЕЗОВО',
    municipalityEn: 'BREZOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4155',
    addressNomenclature: '0',
    x: '25.081446',
    y: '42.406923',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '30764',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗЕЛЕНО ДЪРВО',
    nameEn: 'ZELENO DARVO',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5304',
    addressNomenclature: '0',
    x: '25.270028',
    y: '42.796501',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '30778',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ЗЕМЕН',
    nameEn: 'ZEMEN',
    municipality: 'ЗЕМЕН',
    municipalityEn: 'ZEMEN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2440',
    addressNomenclature: '2',
    x: '22.746154',
    y: '42.475114',
    servingDays: '1111100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '30781',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗЕМЕНЦИ',
    nameEn: 'ZEMENTSI',
    municipality: 'КРУШАРИ',
    municipalityEn: 'KRUSHARI',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9411',
    addressNomenclature: '0',
    x: '27.778754',
    y: '43.800153',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '30795',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗЕМЛЕН',
    nameEn: 'ZEMLEN',
    municipality: 'РАДНЕВО',
    municipalityEn: 'RADNEVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6266',
    addressNomenclature: '0',
    x: '25.776982',
    y: '42.306298',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '30805',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗЕТЬОВО',
    nameEn: 'ZETYOVO',
    municipality: 'АЙТОС',
    municipalityEn: 'AYTOS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8517',
    addressNomenclature: '0',
    x: '27.226688',
    y: '42.757691',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '30819',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗЕТЬОВО',
    nameEn: 'ZETYOVO',
    municipality: 'ЧИРПАН',
    municipalityEn: 'CHIRPAN',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6220',
    addressNomenclature: '0',
    x: '25.36559',
    y: '42.146716',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '30822',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗИДАРОВО',
    nameEn: 'ZIDAROVO',
    municipality: 'СОЗОПОЛ',
    municipalityEn: 'SOZOPOL',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8160',
    addressNomenclature: '0',
    x: '27.401995',
    y: '42.327197',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '30836',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗИДАРЦИ',
    nameEn: 'ZIDARTSI',
    municipality: 'ПЕРНИК',
    municipalityEn: 'PERNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2356',
    addressNomenclature: '0',
    x: '22.956652',
    y: '42.647792',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '30847',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗИМЕВИЦА',
    nameEn: 'ZIMEVITSA',
    municipality: 'СВОГЕ',
    municipalityEn: 'SVOGE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2282',
    addressNomenclature: '0',
    x: '23.292036',
    y: '43.048605',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '30853',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗИМЕН',
    nameEn: 'ZIMEN',
    municipality: 'КАРНОБАТ',
    municipalityEn: 'KARNOBAT',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8428',
    addressNomenclature: '0',
    x: '27.036432',
    y: '42.735953',
    servingDays: '1010100',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '30867',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗИМЗЕЛЕН',
    nameEn: 'ZIMZELEN',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6627',
    addressNomenclature: '0',
    x: '25.398764',
    y: '41.658831',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '30870',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗИМНИЦА',
    nameEn: 'ZIMNITSA',
    municipality: 'МЪГЛИЖ',
    municipalityEn: 'MAGLIZH',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6177',
    addressNomenclature: '0',
    x: '25.68189',
    y: '42.585029',
    servingDays: '0101000',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '30884',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗИМНИЦА',
    nameEn: 'ZIMNITSA',
    municipality: 'КРУШАРИ',
    municipalityEn: 'KRUSHARI',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9421',
    addressNomenclature: '0',
    x: '27.620581',
    y: '43.864293',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '30898',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗИМНИЦА',
    nameEn: 'ZIMNITSA',
    municipality: 'СТРАЛДЖА',
    municipalityEn: 'STRALDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8690',
    addressNomenclature: '0',
    x: '26.605442',
    y: '42.580335',
    servingDays: '1010100',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '30908',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗИМОВИНА',
    nameEn: 'ZIMOVINA',
    municipality: 'СТАМБОЛОВО',
    municipalityEn: 'STAMBOLOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6396',
    addressNomenclature: '0',
    x: '25.662472',
    y: '41.705391',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '30911',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗИМОРНИЦА',
    nameEn: 'ZIMORNITSA',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6965',
    addressNomenclature: '0',
    x: '25.488332',
    y: '41.361615',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '30942',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗЛАТАР',
    nameEn: 'ZLATAR',
    municipality: 'ВЕЛИКИ ПРЕСЛАВ',
    municipalityEn: 'VELIKI PRESLAV',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9880',
    addressNomenclature: '0',
    x: '26.955714',
    y: '43.108596',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '30956',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗЛАТАРИ',
    nameEn: 'ZLATARI',
    municipality: 'ТУНДЖА',
    municipalityEn: 'TUNDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8641',
    addressNomenclature: '0',
    x: '26.209211',
    y: '42.392391',
    servingDays: '0101000',
    servingOfficeId: '56',
    servingHubOfficeId: '5',
  },
  {
    id: '14521',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗЛАТАРИЦА',
    nameEn: 'ZLATARITSA',
    municipality: 'БЕЛИЦА',
    municipalityEn: 'BELITSA',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2783',
    addressNomenclature: '0',
    x: '23.755545',
    y: '41.922281',
    servingDays: '1010100',
    servingOfficeId: '125',
    servingHubOfficeId: '13',
  },
  {
    id: '30962',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ЗЛАТАРИЦА',
    nameEn: 'ZLATARITSA',
    municipality: 'ЗЛАТАРИЦА',
    municipalityEn: 'ZLATARITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5090',
    addressNomenclature: '2',
    x: '25.892175',
    y: '43.040394',
    servingDays: '1111100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '30973',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗЛАТЕВЦИ',
    nameEn: 'ZLATEVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5345',
    addressNomenclature: '0',
    x: '25.247349',
    y: '42.920627',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '30990',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗЛАТИ ВОЙВОДА',
    nameEn: 'ZLATI VOYVODA',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8875',
    addressNomenclature: '0',
    x: '26.192247',
    y: '42.588275',
    servingDays: '0101000',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '31005',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗЛАТИНА',
    nameEn: 'ZLATINA',
    municipality: 'ПРОВАДИЯ',
    municipalityEn: 'PROVADIA',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9218',
    addressNomenclature: '0',
    x: '27.417444',
    y: '43.236133',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '31019',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗЛАТИНИЦА',
    nameEn: 'ZLATINITSA',
    municipality: 'БОЛЯРОВО',
    municipalityEn: 'BOLYAROVO',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8746',
    addressNomenclature: '0',
    x: '26.704373',
    y: '42.155001',
    servingDays: '1010100',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '31022',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗЛАТИРЪТ',
    nameEn: 'ZLATIRAT',
    municipality: 'ГУРКОВО',
    municipalityEn: 'GURKOVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6197',
    addressNomenclature: '0',
    x: '25.708261',
    y: '42.740642',
    servingDays: '0101000',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '31036',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗЛАТИТРАП',
    nameEn: 'ZLATITRAP',
    municipality: 'РОДОПИ',
    municipalityEn: 'RODOPI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4212',
    addressNomenclature: '0',
    x: '24.63844',
    y: '42.116079',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '31044',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ЗЛАТИЦА',
    nameEn: 'ZLATITSA',
    municipality: 'ЗЛАТИЦА',
    municipalityEn: 'ZLATITSA',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2080',
    addressNomenclature: '2',
    x: '24.13894',
    y: '42.713336',
    servingDays: '1111110',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '31053',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗЛАТИЯ',
    nameEn: 'ZLATIA',
    municipality: 'ВЪЛЧЕДРЪМ',
    municipalityEn: 'VALCHEDRAM',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3630',
    addressNomenclature: '0',
    x: '23.498539',
    y: '43.77105',
    servingDays: '0101000',
    servingOfficeId: '831',
    servingHubOfficeId: '20',
  },
  {
    id: '31067',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗЛАТИЯ',
    nameEn: 'ZLATIA',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9385',
    addressNomenclature: '0',
    x: '27.686388',
    y: '43.586077',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '31070',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗЛАТНА ЛИВАДА',
    nameEn: 'ZLATNA LIVADA',
    municipality: 'ЧИРПАН',
    municipalityEn: 'CHIRPAN',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6221',
    addressNomenclature: '0',
    x: '25.429767',
    y: '42.113575',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '31084',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗЛАТНА НИВА',
    nameEn: 'ZLATNA NIVA',
    municipality: 'КАСПИЧАН',
    municipalityEn: 'KASPICHAN',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9944',
    addressNomenclature: '0',
    x: '27.069383',
    y: '43.381424',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '31098',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗЛАТНА ПАНЕГА',
    nameEn: 'ZLATNA PANEGA',
    municipality: 'ЯБЛАНИЦА',
    municipalityEn: 'YABLANITSA',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5760',
    addressNomenclature: '0',
    x: '24.150571',
    y: '43.091772',
    servingDays: '1111100',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '94015',
    countryId: '100',
    mainSiteId: '0',
    type: 'к.к.',
    typeEn: 'k.k.',
    name: 'ЗЛАТНИ ПЯСЪЦИ',
    nameEn: 'ZLATNI PYASATSI',
    municipality: 'ВАРНА',
    municipalityEn: 'VARNA',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9007',
    addressNomenclature: '0',
    x: '28.037343',
    y: '43.287876',
    servingDays: '1111110',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '31108',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗЛАТОВРЪХ',
    nameEn: 'ZLATOVRAH',
    municipality: 'АСЕНОВГРАД',
    municipalityEn: 'ASENOVGRAD',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4264',
    addressNomenclature: '0',
    x: '25.007441',
    y: '42.011344',
    servingDays: '1010100',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '31111',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ЗЛАТОГРАД',
    nameEn: 'ZLATOGRAD',
    municipality: 'ЗЛАТОГРАД',
    municipalityEn: 'ZLATOGRAD',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4980',
    addressNomenclature: '0',
    x: '25.094713',
    y: '41.380483',
    servingDays: '1111100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '31125',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗЛАТОКЛАС',
    nameEn: 'ZLATOKLAS',
    municipality: 'ДУЛОВО',
    municipalityEn: 'DULOVO',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7694',
    addressNomenclature: '0',
    x: '27.053649',
    y: '43.91933',
    servingDays: '0101000',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '31139',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗЛАТОЛИСТ',
    nameEn: 'ZLATOLIST',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2830',
    addressNomenclature: '0',
    x: '23.421728',
    y: '41.495094',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '31142',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗЛАТОЛИСТ',
    nameEn: 'ZLATOLIST',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6915',
    addressNomenclature: '0',
    x: '25.620337',
    y: '41.515946',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '31156',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗЛАТОПОЛЕ',
    nameEn: 'ZLATOPOLE',
    municipality: 'ДИМИТРОВГРАД',
    municipalityEn: 'DIMITROVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6421',
    addressNomenclature: '0',
    x: '25.721434',
    y: '42.040204',
    servingDays: '1111100',
    servingOfficeId: '42',
    servingHubOfficeId: '5',
  },
  {
    id: '31160',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗЛАТОСЕЛ',
    nameEn: 'ZLATOSEL',
    municipality: 'БРЕЗОВО',
    municipalityEn: 'BREZOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4162',
    addressNomenclature: '0',
    x: '25.014357',
    y: '42.413696',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '31173',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗЛАТОУСТОВО',
    nameEn: 'ZLATOUSTOVO',
    municipality: 'МАДЖАРОВО',
    municipalityEn: 'MADZHAROVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6484',
    addressNomenclature: '0',
    x: '25.798611',
    y: '41.711365',
    servingDays: '0101000',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '31187',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗЛАТУША',
    nameEn: 'ZLATUSHA',
    municipality: 'БОЖУРИЩЕ',
    municipalityEn: 'BOZHURISHTE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2225',
    addressNomenclature: '0',
    x: '23.049193',
    y: '42.764228',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '31190',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗЛИДОЛ',
    nameEn: 'ZLIDOL',
    municipality: 'МЕЗДРА',
    municipalityEn: 'MEZDRA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3161',
    addressNomenclature: '0',
    x: '23.518064',
    y: '43.077372',
    servingDays: '1010100',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '31200',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗЛОГОШ',
    nameEn: 'ZLOGOSH',
    municipality: 'ТРЕКЛЯНО',
    municipalityEn: 'TREKLYANO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2558',
    addressNomenclature: '0',
    x: '22.640226',
    y: '42.456661',
    servingDays: '0101000',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '31214',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗЛОКУЧЕНЕ',
    nameEn: 'ZLOKUCHENE',
    municipality: 'СЕПТЕМВРИ',
    municipalityEn: 'SEPTEMVRI',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4491',
    addressNomenclature: '0',
    x: '24.165809',
    y: '42.216824',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '31228',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗЛОКУЧЕНЕ',
    nameEn: 'ZLOKUCHENE',
    municipality: 'САМОКОВ',
    municipalityEn: 'SAMOKOV',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2009',
    addressNomenclature: '0',
    x: '23.555535',
    y: '42.392355',
    servingDays: '0101000',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '31245',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗМЕЕВО',
    nameEn: 'ZMEEVO',
    municipality: 'БАЛЧИК',
    municipalityEn: 'BALCHIK',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9643',
    addressNomenclature: '0',
    x: '28.048705',
    y: '43.606333',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '31259',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗМЕИЦА',
    nameEn: 'ZMEITSA',
    municipality: 'ДОСПАТ',
    municipalityEn: 'DOSPAT',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4834',
    addressNomenclature: '0',
    x: '24.247451',
    y: '41.640977',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '31262',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗМЕЙНО',
    nameEn: 'ZMEYNO',
    municipality: 'ОМУРТАГ',
    municipalityEn: 'OMURTAG',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7945',
    addressNomenclature: '0',
    x: '26.341603',
    y: '43.060995',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '31276',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗМЕЙОВО',
    nameEn: 'ZMEYOVO',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6059',
    addressNomenclature: '0',
    x: '25.615894',
    y: '42.499866',
    servingDays: '1111110',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '31285',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗМИЕВО',
    nameEn: 'ZMIEVO',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4777',
    addressNomenclature: '0',
    x: '24.772115',
    y: '41.492717',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '31293',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗНАМЕНОСЕЦ',
    nameEn: 'ZNAMENOSETS',
    municipality: 'РАДНЕВО',
    municipalityEn: 'RADNEVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6272',
    addressNomenclature: '0',
    x: '25.900479',
    y: '42.277703',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '31303',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗОГРАФ',
    nameEn: 'ZOGRAF',
    municipality: 'ГЕНЕРАЛ ТОШЕВО',
    municipalityEn: 'GENERAL TOSHEVO',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9553',
    addressNomenclature: '0',
    x: '27.929442',
    y: '43.745867',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '31317',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗОЙЧЕНЕ',
    nameEn: 'ZOYCHENE',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2882',
    addressNomenclature: '0',
    x: '23.017556',
    y: '41.456624',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '31320',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗОРЕНИШКИ ДОЛ',
    nameEn: 'ZORENISHKI DOL',
    municipality: 'ТЕТЕВЕН',
    municipalityEn: 'TETEVEN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '',
    addressNomenclature: '0',
    x: '24.140972',
    y: '43.010078',
    servingDays: '0101000',
    servingOfficeId: '88',
    servingHubOfficeId: '2',
  },
  {
    id: '20910',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗОРНИЦА',
    nameEn: 'ZORNITSA',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2821',
    addressNomenclature: '0',
    x: '23.365846',
    y: '41.488664',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '31334',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗОРНИЦА',
    nameEn: 'ZORNITSA',
    municipality: 'АКСАКОВО',
    municipalityEn: 'AKSAKOVO',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9156',
    addressNomenclature: '0',
    x: '27.731406',
    y: '43.329732',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '31348',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗОРНИЦА',
    nameEn: 'ZORNITSA',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6650',
    addressNomenclature: '0',
    x: '25.512917',
    y: '41.617876',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '31351',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗОРНИЦА',
    nameEn: 'ZORNITSA',
    municipality: 'ЧЕПЕЛАРЕ',
    municipalityEn: 'CHEPELARE',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4857',
    addressNomenclature: '0',
    x: '24.666601',
    y: '41.76521',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '31365',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗОРНИЦА',
    nameEn: 'ZORNITSA',
    municipality: 'ХАСКОВО',
    municipalityEn: 'HASKOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6367',
    addressNomenclature: '0',
    x: '25.442478',
    y: '41.807255',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '31379',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗОРНИЦА',
    nameEn: 'ZORNITSA',
    municipality: 'СРЕДЕЦ',
    municipalityEn: 'SREDETS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8313',
    addressNomenclature: '0',
    x: '26.930948',
    y: '42.384838',
    servingDays: '0101000',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '31396',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЗЪРНЕВО',
    nameEn: 'ZARNEVO',
    municipality: 'ТЕРВЕЛ',
    municipalityEn: 'TERVEL',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9491',
    addressNomenclature: '0',
    x: '27.302927',
    y: '43.630896',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '32010',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИВАЙЛО',
    nameEn: 'IVAYLO',
    municipality: 'ПАЗАРДЖИК',
    municipalityEn: 'PAZARDZHIK',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4407',
    addressNomenclature: '0',
    x: '24.329898',
    y: '42.224369',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '32024',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ИВАЙЛОВГРАД',
    nameEn: 'IVAYLOVGRAD',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6570',
    addressNomenclature: '2',
    x: '26.127415',
    y: '41.526212',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '32038',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИВАН ВАЗОВО',
    nameEn: 'IVAN VAZOVO',
    municipality: 'КАЛОЯНОВО',
    municipalityEn: 'KALOYANOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4206',
    addressNomenclature: '0',
    x: '24.788884',
    y: '42.467472',
    servingDays: '0101000',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '32041',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИВАН ДИМОВ',
    nameEn: 'IVAN DIMOV',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5363',
    addressNomenclature: '0',
    x: '25.59285',
    y: '42.848463',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '32192',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИВАН ШИШМАНОВО',
    nameEn: 'IVAN SHISHMANOVO',
    municipality: 'ЗАВЕТ',
    municipalityEn: 'ZAVET',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7342',
    addressNomenclature: '0',
    x: '26.76842',
    y: '43.794144',
    servingDays: '0101000',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '32055',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИВАНИВАНОВЦИ',
    nameEn: 'IVANIVANOVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5084',
    addressNomenclature: '0',
    x: '25.944672',
    y: '42.960157',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '32069',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИВАНИЛИ',
    nameEn: 'IVANILI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5300',
    addressNomenclature: '0',
    x: '25.302297',
    y: '42.948436',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '32072',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИВАНКОВЦИ',
    nameEn: 'IVANKOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5340',
    addressNomenclature: '0',
    x: '25.374196',
    y: '42.897063',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '32086',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИВАНОВО',
    nameEn: 'IVANOVO',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2875',
    addressNomenclature: '0',
    x: '23.068117',
    y: '41.532946',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '32095',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИВАНОВО',
    nameEn: 'IVANOVO',
    municipality: 'ИВАНОВО',
    municipalityEn: 'IVANOVO',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7088',
    addressNomenclature: '0',
    x: '25.954604',
    y: '43.68661',
    servingDays: '1111100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '32100',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИВАНОВО',
    nameEn: 'IVANOVO',
    municipality: 'ХАРМАНЛИ',
    municipalityEn: 'HARMANLI',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6465',
    addressNomenclature: '0',
    x: '25.877345',
    y: '41.850537',
    servingDays: '0101000',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '32113',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИВАНОВО',
    nameEn: 'IVANOVO',
    municipality: 'ВЪРБИЦА',
    municipalityEn: 'VARBITSA',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9865',
    addressNomenclature: '0',
    x: '26.722688',
    y: '43.094989',
    servingDays: '1111100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '32929',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИВАНОВО',
    nameEn: 'IVANOVO',
    municipality: 'РУДОЗЕМ',
    municipalityEn: 'RUDOZEM',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4918',
    addressNomenclature: '0',
    x: '24.778102',
    y: '41.46797',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '32127',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИВАНОВЦИ',
    nameEn: 'IVANOVTSI',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5035',
    addressNomenclature: '0',
    x: '25.654751',
    y: '42.817944',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '32130',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИВАНОВЦИ',
    nameEn: 'IVANOVTSI',
    municipality: 'ВИДИН',
    municipalityEn: 'VIDIN',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3739',
    addressNomenclature: '0',
    x: '22.761903',
    y: '43.862958',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '32144',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИВАНОВЦИ',
    nameEn: 'IVANOVTSI',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2562',
    addressNomenclature: '0',
    x: '22.514041',
    y: '42.366929',
    servingDays: '0101000',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '32158',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИВАНСКИ',
    nameEn: 'IVANSKI',
    municipality: 'ШУМЕН',
    municipalityEn: 'SHUMEN',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9810',
    addressNomenclature: '0',
    x: '27.032391',
    y: '43.141146',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '32161',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИВАНЦИ',
    nameEn: 'IVANTSI',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6628',
    addressNomenclature: '0',
    x: '25.396689',
    y: '41.672652',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '32175',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИВАНЧА',
    nameEn: 'IVANCHA',
    municipality: 'ПОЛСКИ ТРЪМБЕШ',
    municipalityEn: 'POLSKI TRAMBESH',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5194',
    addressNomenclature: '0',
    x: '25.549453',
    y: '43.33928',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '32189',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИВАНЧА',
    nameEn: 'IVANCHA',
    municipality: 'ПОПОВО',
    municipalityEn: 'POPOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7859',
    addressNomenclature: '0',
    x: '26.181799',
    y: '43.21979',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '32202',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИВАНШНИЦА',
    nameEn: 'IVANSHNITSA',
    municipality: 'ТРОЯН',
    municipalityEn: 'TROYAN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '',
    addressNomenclature: '0',
    x: '24.802201',
    y: '42.842188',
    servingDays: '1010100',
    servingOfficeId: '44',
    servingHubOfficeId: '11',
  },
  {
    id: '32216',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИВАНЯНЕ',
    nameEn: 'IVANYANE',
    municipality: 'СТОЛИЧНА',
    municipalityEn: 'STOLICHNA',
    region: 'СОФИЯ (СТОЛИЦА)',
    regionEn: 'SOFIA (STOLITSA)',
    postCode: '1393',
    addressNomenclature: '2',
    x: '23.181581',
    y: '42.706927',
    servingDays: '1111100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '32226',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИГАНОВО',
    nameEn: 'IGANOVO',
    municipality: 'КАРЛОВО',
    municipalityEn: 'KARLOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4334',
    addressNomenclature: '0',
    x: '24.680559',
    y: '42.677395',
    servingDays: '0101000',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '32233',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИГЛИКА',
    nameEn: 'IGLIKA',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5343',
    addressNomenclature: '0',
    x: '25.384446',
    y: '42.959463',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '32250',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИГЛИКА',
    nameEn: 'IGLIKA',
    municipality: 'ХИТРИНО',
    municipalityEn: 'HITRINO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9797',
    addressNomenclature: '0',
    x: '27.002599',
    y: '43.465355',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '32264',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИГЛИКА',
    nameEn: 'IGLIKA',
    municipality: 'БОЛЯРОВО',
    municipalityEn: 'BOLYAROVO',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8745',
    addressNomenclature: '0',
    x: '26.773779',
    y: '42.04229',
    servingDays: '1010100',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '32278',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ИГНАТИЕВО',
    nameEn: 'IGNATIEVO',
    municipality: 'АКСАКОВО',
    municipalityEn: 'AKSAKOVO',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9143',
    addressNomenclature: '2',
    x: '27.775677',
    y: '43.250084',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '32281',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИГНАТИЦА',
    nameEn: 'IGNATITSA',
    municipality: 'МЕЗДРА',
    municipalityEn: 'MEZDRA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3167',
    addressNomenclature: '0',
    x: '23.591576',
    y: '43.067794',
    servingDays: '1010100',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '32295',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИГНАТОВО',
    nameEn: 'IGNATOVO',
    municipality: 'ВЪЛЧЕДРЪМ',
    municipalityEn: 'VALCHEDRAM',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3631',
    addressNomenclature: '0',
    x: '23.487123',
    y: '43.781521',
    servingDays: '0101000',
    servingOfficeId: '831',
    servingHubOfficeId: '20',
  },
  {
    id: '32305',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИГНАТОВЦИ',
    nameEn: 'IGNATOVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5098',
    addressNomenclature: '0',
    x: '25.965991',
    y: '42.851191',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '32319',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИГНАТОВЦИ',
    nameEn: 'IGNATOVTSI',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5370',
    addressNomenclature: '0',
    x: '25.527035',
    y: '42.93365',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '32322',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИГРАЛИЩЕ',
    nameEn: 'IGRALISHTE',
    municipality: 'СТРУМЯНИ',
    municipalityEn: 'STRUMYANI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2848',
    addressNomenclature: '0',
    x: '23.124695',
    y: '41.566487',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '32336',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИДИЛЕВО',
    nameEn: 'IDILEVO',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5422',
    addressNomenclature: '0',
    x: '25.25268',
    y: '43.0374',
    servingDays: '0101000',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '32341',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИЗБЕГЛИИ',
    nameEn: 'IZBEGLII',
    municipality: 'АСЕНОВГРАД',
    municipalityEn: 'ASENOVGRAD',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4269',
    addressNomenclature: '0',
    x: '25.004923',
    y: '42.043188',
    servingDays: '1010100',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '32353',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИЗБУЛ',
    nameEn: 'IZBUL',
    municipality: 'НОВИ ПАЗАР',
    municipalityEn: 'NOVI PAZAR',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9959',
    addressNomenclature: '0',
    x: '27.112362',
    y: '43.437634',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '32367',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИЗВОР',
    nameEn: 'IZVOR',
    municipality: 'БУРГАС',
    municipalityEn: 'BURGAS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8153',
    addressNomenclature: '0',
    x: '27.461346',
    y: '42.353408',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '32370',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИЗВОР',
    nameEn: 'IZVOR',
    municipality: 'ДИМОВО',
    municipalityEn: 'DIMOVO',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3742',
    addressNomenclature: '0',
    x: '22.80504',
    y: '43.749998',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '32384',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИЗВОР',
    nameEn: 'IZVOR',
    municipality: 'РАДОМИР',
    municipalityEn: 'RADOMIR',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2415',
    addressNomenclature: '0',
    x: '22.896582',
    y: '42.432317',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '32408',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИЗВОР',
    nameEn: 'IZVOR',
    municipality: 'РОДОПИ',
    municipalityEn: 'RODOPI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4116',
    addressNomenclature: '0',
    x: '24.676458',
    y: '42.035622',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '32411',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИЗВОР',
    nameEn: 'IZVOR',
    municipality: 'СЛИВНИЦА',
    municipalityEn: 'SLIVNITSA',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2219',
    addressNomenclature: '0',
    x: '22.946013',
    y: '42.871075',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '32439',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИЗВОР МАХАЛА',
    nameEn: 'IZVOR MAHALA',
    municipality: 'КУЛА',
    municipalityEn: 'KULA',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3821',
    addressNomenclature: '0',
    x: '22.417739',
    y: '43.869075',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '32425',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИЗВОРИЩЕ',
    nameEn: 'IZVORISHTE',
    municipality: 'БУРГАС',
    municipalityEn: 'BURGAS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8116',
    addressNomenclature: '0',
    x: '27.442313',
    y: '42.656915',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '32442',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИЗВОРНИК',
    nameEn: 'IZVORNIK',
    municipality: 'ВЪЛЧИ ДОЛ',
    municipalityEn: 'VALCHI DOL',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9297',
    addressNomenclature: '0',
    x: '27.35976',
    y: '43.467797',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '32456',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИЗВОРОВО',
    nameEn: 'IZVOROVO',
    municipality: 'ЧИРПАН',
    municipalityEn: 'CHIRPAN',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6242',
    addressNomenclature: '0',
    x: '25.316781',
    y: '42.29677',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '32467',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИЗВОРОВО',
    nameEn: 'IZVOROVO',
    municipality: 'ГЕНЕРАЛ ТОШЕВО',
    municipalityEn: 'GENERAL TOSHEVO',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9538',
    addressNomenclature: '0',
    x: '27.931412',
    y: '43.816353',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '32473',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИЗВОРОВО',
    nameEn: 'IZVOROVO',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7950',
    addressNomenclature: '0',
    x: '26.293883',
    y: '43.116572',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '32487',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИЗВОРОВО',
    nameEn: 'IZVOROVO',
    municipality: 'ХАРМАНЛИ',
    municipalityEn: 'HARMANLI',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6461',
    addressNomenclature: '0',
    x: '26.140854',
    y: '41.963022',
    servingDays: '1010100',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '32932',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИЗВОРОВО',
    nameEn: 'IZVOROVO',
    municipality: 'АСЕНОВГРАД',
    municipalityEn: 'ASENOVGRAD',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '',
    addressNomenclature: '0',
    x: '25.031481',
    y: '41.835704',
    servingDays: '0101000',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '32490',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИЗВОРСКО',
    nameEn: 'IZVORSKO',
    municipality: 'АКСАКОВО',
    municipalityEn: 'AKSAKOVO',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9154',
    addressNomenclature: '0',
    x: '27.769908',
    y: '43.294742',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '32500',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИЗВОРЧЕ',
    nameEn: 'IZVORCHE',
    municipality: 'ЛОВЕЧ',
    municipalityEn: 'LOVECH',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5536',
    addressNomenclature: '0',
    x: '24.661028',
    y: '43.103987',
    servingDays: '1010100',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '134',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИЗГРЕВ',
    nameEn: 'IZGREV',
    municipality: 'БЛАГОЕВГРАД',
    municipalityEn: 'BLAGOEVGRAD',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2711',
    addressNomenclature: '0',
    x: '23.110218',
    y: '41.990223',
    servingDays: '1111110',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '32514',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИЗГРЕВ',
    nameEn: 'IZGREV',
    municipality: 'ЦАРЕВО',
    municipalityEn: 'TSAREVO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8273',
    addressNomenclature: '0',
    x: '27.802429',
    y: '42.145772',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '32528',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИЗГРЕВ',
    nameEn: 'IZGREV',
    municipality: 'СУВОРОВО',
    municipalityEn: 'SUVOROVO',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9176',
    addressNomenclature: '0',
    x: '27.696082',
    y: '43.296456',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '32531',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИЗГРЕВ',
    nameEn: 'IZGREV',
    municipality: 'ЛЕВСКИ',
    municipalityEn: 'LEVSKI',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5924',
    addressNomenclature: '0',
    x: '25.04759',
    y: '43.468772',
    servingDays: '1010100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '32545',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИЗГРЕВ',
    nameEn: 'IZGREV',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8847',
    addressNomenclature: '0',
    x: '26.108589',
    y: '42.886724',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '32559',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИЗГРЕВ',
    nameEn: 'IZGREV',
    municipality: 'НЕДЕЛИНО',
    municipalityEn: 'NEDELINO',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4990',
    addressNomenclature: '0',
    x: '25.103652',
    y: '41.492709',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '32562',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИЗГРЕВ',
    nameEn: 'IZGREV',
    municipality: 'ВЕНЕЦ',
    municipalityEn: 'VENETS',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9754',
    addressNomenclature: '0',
    x: '26.993992',
    y: '43.592437',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '32576',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИЗГРЕВ',
    nameEn: 'IZGREV',
    municipality: 'ЕЛХОВО',
    municipalityEn: 'ELHOVO',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8717',
    addressNomenclature: '0',
    x: '26.523194',
    y: '42.169212',
    servingDays: '0101000',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '32582',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИЗТОЧНИК',
    nameEn: 'IZTOCHNIK',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5335',
    addressNomenclature: '0',
    x: '25.387884',
    y: '42.873795',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '32593',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИЛАКОВ РЪТ',
    nameEn: 'ILAKOV RAT',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5082',
    addressNomenclature: '0',
    x: '25.936283',
    y: '42.893584',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '32617',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИЛЕВЦИ',
    nameEn: 'ILEVTSI',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5045',
    addressNomenclature: '0',
    x: '25.680492',
    y: '42.866274',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '32620',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИЛИЙНО',
    nameEn: 'ILIYNO',
    municipality: 'ОМУРТАГ',
    municipalityEn: 'OMURTAG',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7948',
    addressNomenclature: '0',
    x: '26.321453',
    y: '43.090861',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '32634',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИЛИЙСКО',
    nameEn: 'ILIYSKO',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6845',
    addressNomenclature: '0',
    x: '25.33549',
    y: '41.453332',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '32648',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИЛИНДЕН',
    nameEn: 'ILINDEN',
    municipality: 'ХАДЖИДИМОВО',
    municipalityEn: 'HADZHIDIMOVO',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2929',
    addressNomenclature: '0',
    x: '23.800055',
    y: '41.464347',
    servingDays: '1010100',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '32651',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИЛИНДЕН',
    nameEn: 'ILINDEN',
    municipality: 'МИРКОВО',
    municipalityEn: 'MIRKOVO',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2072',
    addressNomenclature: '0',
    x: '23.941686',
    y: '42.580899',
    servingDays: '0101000',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '32665',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИЛИНДЕНЦИ',
    nameEn: 'ILINDENTSI',
    municipality: 'СТРУМЯНИ',
    municipalityEn: 'STRUMYANI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2833',
    addressNomenclature: '0',
    x: '23.235095',
    y: '41.651433',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '32679',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИЛИНИЦА',
    nameEn: 'ILINITSA',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6745',
    addressNomenclature: '0',
    x: '25.254795',
    y: '41.711168',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '32682',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИЛИЮВЦИ',
    nameEn: 'ILIYUVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5093',
    addressNomenclature: '0',
    x: '25.726916',
    y: '42.910823',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '32696',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИЛИЯ',
    nameEn: 'ILIA',
    municipality: 'НЕВЕСТИНО',
    municipalityEn: 'NEVESTINO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2581',
    addressNomenclature: '0',
    x: '22.812231',
    y: '42.073163',
    servingDays: '1010100',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '32706',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИЛИЯ БЛЪСКОВО',
    nameEn: 'ILIA BLASKOVO',
    municipality: 'ШУМЕН',
    municipalityEn: 'SHUMEN',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9813',
    addressNomenclature: '0',
    x: '27.041357',
    y: '43.227077',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '32723',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИМРЕНЧЕВО',
    nameEn: 'IMRENCHEVO',
    municipality: 'ВЕЛИКИ ПРЕСЛАВ',
    municipalityEn: 'VELIKI PRESLAV',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9877',
    addressNomenclature: '0',
    x: '26.748786',
    y: '43.182136',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '32737',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИНДЖЕ ВОЙВОДА',
    nameEn: 'INDZHE VOYVODA',
    municipality: 'СОЗОПОЛ',
    municipalityEn: 'SOZOPOL',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8149',
    addressNomenclature: '0',
    x: '27.415463',
    y: '42.22301',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '32754',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИНОВО',
    nameEn: 'INOVO',
    municipality: 'ВИДИН',
    municipalityEn: 'VIDIN',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3763',
    addressNomenclature: '0',
    x: '22.834869',
    y: '44.0316',
    servingDays: '1111100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '32768',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИРЕЧЕК',
    nameEn: 'IRECHEK',
    municipality: 'КАВАРНА',
    municipalityEn: 'KAVARNA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9666',
    addressNomenclature: '0',
    x: '28.364091',
    y: '43.521939',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '32771',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИРЕЧЕКОВО',
    nameEn: 'IRECHEKOVO',
    municipality: 'СТРАЛДЖА',
    municipalityEn: 'STRALDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8697',
    addressNomenclature: '0',
    x: '26.72628',
    y: '42.487577',
    servingDays: '0101000',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '32785',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИРИНЕЦИ',
    nameEn: 'IRINETSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5367',
    addressNomenclature: '0',
    x: '25.578247',
    y: '42.817918',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '32799',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИРНИК',
    nameEn: 'IRNIK',
    municipality: 'СИТОВО',
    municipalityEn: 'SITOVO',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7532',
    addressNomenclature: '0',
    x: '26.982479',
    y: '43.940955',
    servingDays: '1010100',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '32809',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИСКРА',
    nameEn: 'ISKRA',
    municipality: 'КАРНОБАТ',
    municipalityEn: 'KARNOBAT',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8481',
    addressNomenclature: '0',
    x: '26.877411',
    y: '42.681988',
    servingDays: '0101000',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '32812',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИСКРА',
    nameEn: 'ISKRA',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5380',
    addressNomenclature: '0',
    x: '25.472173',
    y: '42.939485',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '32826',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИСКРА',
    nameEn: 'ISKRA',
    municipality: 'ПЪРВОМАЙ',
    municipalityEn: 'PARVOMAY',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4297',
    addressNomenclature: '0',
    x: '25.132981',
    y: '41.93037',
    servingDays: '0101000',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '32839',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИСКРА',
    nameEn: 'ISKRA',
    municipality: 'СИТОВО',
    municipalityEn: 'SITOVO',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7580',
    addressNomenclature: '0',
    x: '26.964588',
    y: '43.997599',
    servingDays: '1010100',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '32946',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИСКРА',
    nameEn: 'ISKRA',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6781',
    addressNomenclature: '0',
    x: '25.162475',
    y: '41.628376',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '32843',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИСКРЕЦ',
    nameEn: 'ISKRETS',
    municipality: 'СВОГЕ',
    municipalityEn: 'SVOGE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2290',
    addressNomenclature: '0',
    x: '23.250138',
    y: '42.987205',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '32857',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИСКРИЦА',
    nameEn: 'ISKRITSA',
    municipality: 'ГЪЛЪБОВО',
    municipalityEn: 'GALABOVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6293',
    addressNomenclature: '0',
    x: '26.044593',
    y: '42.134106',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '14888',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИСКЪР',
    nameEn: 'ISKAR',
    municipality: 'ГУЛЯНЦИ',
    municipalityEn: 'GULYANTSI',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5972',
    addressNomenclature: '0',
    x: '24.449351',
    y: '43.670947',
    servingDays: '0101000',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '32860',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИСКЪР',
    nameEn: 'ISKAR',
    municipality: 'ВЪЛЧИ ДОЛ',
    municipalityEn: 'VALCHI DOL',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9285',
    addressNomenclature: '0',
    x: '27.475915',
    y: '43.390346',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '55782',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ИСКЪР',
    nameEn: 'ISKAR',
    municipality: 'ИСКЪР',
    municipalityEn: 'ISKAR',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5868',
    addressNomenclature: '2',
    x: '24.263388',
    y: '43.45441',
    servingDays: '1111100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '32874',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ИСПЕРИХ',
    nameEn: 'ISPERIH',
    municipality: 'ИСПЕРИХ',
    municipalityEn: 'ISPERIH',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7400',
    addressNomenclature: '2',
    x: '26.82843',
    y: '43.71953',
    servingDays: '1111100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '32888',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИСПЕРИХОВО',
    nameEn: 'ISPERIHOVO',
    municipality: 'БРАЦИГОВО',
    municipalityEn: 'BRATSIGOVO',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4588',
    addressNomenclature: '0',
    x: '24.429078',
    y: '42.085325',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '32891',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИСЬОВЦИ',
    nameEn: 'ISYOVTSI',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4767',
    addressNomenclature: '0',
    x: '24.6499',
    y: '41.498763',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '32901',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ИХТИМАН',
    nameEn: 'IHTIMAN',
    municipality: 'ИХТИМАН',
    municipalityEn: 'IHTIMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2050',
    addressNomenclature: '2',
    x: '23.816325',
    y: '42.437733',
    servingDays: '1111100',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '32915',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ИЧЕРА',
    nameEn: 'ICHERA',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8849',
    addressNomenclature: '0',
    x: '26.450631',
    y: '42.765038',
    servingDays: '0101000',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '34014',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЙЕРУСАЛИМОВО',
    nameEn: 'YERUSALIMOVO',
    municipality: 'ЛЮБИМЕЦ',
    municipalityEn: 'LYUBIMETS',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6551',
    addressNomenclature: '0',
    x: '26.102339',
    y: '41.894098',
    servingDays: '1010100',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '34028',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЙОАКИМ ГРУЕВО',
    nameEn: 'YOAKIM GRUEVO',
    municipality: 'СТАМБОЛИЙСКИ',
    municipalityEn: 'STAMBOLIYSKI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4219',
    addressNomenclature: '0',
    x: '24.565204',
    y: '42.118623',
    servingDays: '1111100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '34045',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЙОВКОВО',
    nameEn: 'YOVKOVO',
    municipality: 'ГЕНЕРАЛ ТОШЕВО',
    municipalityEn: 'GENERAL TOSHEVO',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9531',
    addressNomenclature: '0',
    x: '28.140382',
    y: '43.776209',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '34062',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЙОВОВЦИ',
    nameEn: 'YOVOVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5360',
    addressNomenclature: '0',
    x: '25.445485',
    y: '42.803336',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '34076',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЙОВЧЕВЦИ',
    nameEn: 'YOVCHEVTSI',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5035',
    addressNomenclature: '0',
    x: '25.609453',
    y: '42.890851',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '34093',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЙОГЛАВ',
    nameEn: 'YOGLAV',
    municipality: 'ЛОВЕЧ',
    municipalityEn: 'LOVECH',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5553',
    addressNomenclature: '0',
    x: '24.824189',
    y: '43.208436',
    servingDays: '0101000',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '34103',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЙОНКОВО',
    nameEn: 'YONKOVO',
    municipality: 'ИСПЕРИХ',
    municipalityEn: 'ISPERIH',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7450',
    addressNomenclature: '0',
    x: '26.703558',
    y: '43.628537',
    servingDays: '0101000',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '34117',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЙОНЧОВО',
    nameEn: 'YONCHOVO',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6724',
    addressNomenclature: '0',
    x: '25.205669',
    y: '41.847417',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '34134',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЙОРДАНОВО',
    nameEn: 'YORDANOVO',
    municipality: 'СИЛИСТРА',
    municipalityEn: 'SILISTRA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7569',
    addressNomenclature: '0',
    x: '27.105025',
    y: '43.987087',
    servingDays: '1010100',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '35028',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАБИЛЕ',
    nameEn: 'KABILE',
    municipality: 'ТУНДЖА',
    municipalityEn: 'TUNDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8629',
    addressNomenclature: '0',
    x: '26.479059',
    y: '42.528692',
    servingDays: '1111100',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '35033',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'КАБЛЕШКОВО',
    nameEn: 'KABLESHKOVO',
    municipality: 'ПОМОРИЕ',
    municipalityEn: 'POMORIE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8210',
    addressNomenclature: '2',
    x: '27.571018',
    y: '42.658351',
    servingDays: '1111110',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '35047',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАБЛЕШКОВО',
    nameEn: 'KABLESHKOVO',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6703',
    addressNomenclature: '0',
    x: '25.343161',
    y: '41.751079',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '35050',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАБЛЕШКОВО',
    nameEn: 'KABLESHKOVO',
    municipality: 'ТЕРВЕЛ',
    municipalityEn: 'TERVEL',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9488',
    addressNomenclature: '0',
    x: '27.30954',
    y: '43.751608',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '35064',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'КАВАРНА',
    nameEn: 'KAVARNA',
    municipality: 'КАВАРНА',
    municipalityEn: 'KAVARNA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9650',
    addressNomenclature: '2',
    x: '28.339219',
    y: '43.435977',
    servingDays: '1111110',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '35078',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАВЛАК',
    nameEn: 'KAVLAK',
    municipality: 'СТРАЖИЦА',
    municipalityEn: 'STRAZHITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5163',
    addressNomenclature: '0',
    x: '26.066852',
    y: '43.1726',
    servingDays: '1010100',
    servingOfficeId: '46',
    servingHubOfficeId: '11',
  },
  {
    id: '35081',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАВРАКИРОВО',
    nameEn: 'KAVRAKIROVO',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2889',
    addressNomenclature: '0',
    x: '23.167564',
    y: '41.435661',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '35095',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАДИЕВО',
    nameEn: 'KADIEVO',
    municipality: 'РОДОПИ',
    municipalityEn: 'RODOPI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4213',
    addressNomenclature: '0',
    x: '24.607271',
    y: '42.131694',
    servingDays: '0101000',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '35119',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАДРОВИЦА',
    nameEn: 'KADROVITSA',
    municipality: 'НЕВЕСТИНО',
    municipalityEn: 'NEVESTINO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2593',
    addressNomenclature: '0',
    x: '22.745254',
    y: '42.202645',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '35143',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЗАК',
    nameEn: 'KAZAK',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6590',
    addressNomenclature: '0',
    x: '25.878633',
    y: '41.410115',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '35153',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЗАНКА',
    nameEn: 'KAZANKA',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6062',
    addressNomenclature: '0',
    x: '25.420484',
    y: '42.462297',
    servingDays: '1111110',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '35167',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'КАЗАНЛЪК',
    nameEn: 'KAZANLAK',
    municipality: 'КАЗАНЛЪК',
    municipalityEn: 'KAZANLAK',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6100',
    addressNomenclature: '1',
    x: '25.393052',
    y: '42.618694',
    servingDays: '1111110',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '35184',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЗАЦИТЕ',
    nameEn: 'KAZATSITE',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6850',
    addressNomenclature: '0',
    x: '25.27645',
    y: '41.477016',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '35198',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЗАЧЕВО',
    nameEn: 'KAZACHEVO',
    municipality: 'ЛОВЕЧ',
    municipalityEn: 'LOVECH',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5548',
    addressNomenclature: '0',
    x: '24.749261',
    y: '43.080428',
    servingDays: '0101000',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '35208',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЗАШКА РЕКА',
    nameEn: 'KAZASHKA REKA',
    municipality: 'АВРЕН',
    municipalityEn: 'AVREN',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9125',
    addressNomenclature: '0',
    x: '27.630694',
    y: '43.088171',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '35211',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЗАШКО',
    nameEn: 'KAZASHKO',
    municipality: 'ВАРНА',
    municipalityEn: 'VARNA',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9140',
    addressNomenclature: '0',
    x: '27.821684',
    y: '43.198055',
    servingDays: '1111110',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '35225',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЗИМИР',
    nameEn: 'KAZIMIR',
    municipality: 'СИЛИСТРА',
    municipalityEn: 'SILISTRA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7574',
    addressNomenclature: '0',
    x: '27.204259',
    y: '43.994819',
    servingDays: '1010100',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '35239',
    countryId: '100',
    mainSiteId: '68134',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЗИЧЕНЕ',
    nameEn: 'KAZICHENE',
    municipality: 'СТОЛИЧНА',
    municipalityEn: 'STOLICHNA',
    region: 'СОФИЯ (СТОЛИЦА)',
    regionEn: 'SOFIA (STOLITSA)',
    postCode: '1532',
    addressNomenclature: '2',
    x: '23.465659',
    y: '42.659041',
    servingDays: '1111110',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '35242',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЙНАРДЖА',
    nameEn: 'KAYNARDZHA',
    municipality: 'КАЙНАРДЖА',
    municipalityEn: 'KAYNARDZHA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7550',
    addressNomenclature: '0',
    x: '27.506481',
    y: '43.992962',
    servingDays: '0101000',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '35269',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЛАЙДЖИЕВО',
    nameEn: 'KALAYDZHIEVO',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6927',
    addressNomenclature: '0',
    x: '25.754349',
    y: '41.410257',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '35273',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЛАЙДЖИИ',
    nameEn: 'KALAYDZHII',
    municipality: 'ЗЛАТАРИЦА',
    municipalityEn: 'ZLATARITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5096',
    addressNomenclature: '0',
    x: '26.015209',
    y: '43.042717',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '35290',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЛЕЙЦА',
    nameEn: 'KALEYTSA',
    municipality: 'ТРОЯН',
    municipalityEn: 'TROYAN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5667',
    addressNomenclature: '0',
    x: '24.671199',
    y: '42.927229',
    servingDays: '0101000',
    servingOfficeId: '44',
    servingHubOfficeId: '11',
  },
  {
    id: '35300',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЛЕКОВЕЦ',
    nameEn: 'KALEKOVETS',
    municipality: 'МАРИЦА',
    municipalityEn: 'MARITSA',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4147',
    addressNomenclature: '0',
    x: '24.823823',
    y: '42.236385',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '35314',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЛЕН',
    nameEn: 'KALEN',
    municipality: 'МЕЗДРА',
    municipalityEn: 'MEZDRA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3151',
    addressNomenclature: '0',
    x: '23.775191',
    y: '43.211001',
    servingDays: '0101000',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '35328',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЛЕНИК',
    nameEn: 'KALENIK',
    municipality: 'ВИДИН',
    municipalityEn: 'VIDIN',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3729',
    addressNomenclature: '0',
    x: '22.663774',
    y: '44.003014',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '35331',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЛЕНИК',
    nameEn: 'KALENIK',
    municipality: 'УГЪРЧИН',
    municipalityEn: 'UGARCHIN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5575',
    addressNomenclature: '0',
    x: '24.51097',
    y: '43.157635',
    servingDays: '1010100',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '35345',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЛЕНОВЦИ',
    nameEn: 'KALENOVTSI',
    municipality: 'ГОДЕЧ',
    municipalityEn: 'GODECH',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2253',
    addressNomenclature: '0',
    x: '22.958424',
    y: '42.988172',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '35362',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЛИМАНЦИ',
    nameEn: 'KALIMANTSI',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2832',
    addressNomenclature: '0',
    x: '23.476334',
    y: '41.460403',
    servingDays: '1010100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '35376',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЛИМАНЦИ',
    nameEn: 'KALIMANTSI',
    municipality: 'СУВОРОВО',
    municipalityEn: 'SUVOROVO',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9177',
    addressNomenclature: '0',
    x: '27.72225',
    y: '43.27759',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '35384',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЛИНА',
    nameEn: 'KALINA',
    municipality: 'БРЕГОВО',
    municipalityEn: 'BREGOVO',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3788',
    addressNomenclature: '0',
    x: '22.763194',
    y: '44.06776',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '35393',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЛИНА',
    nameEn: 'KALINA',
    municipality: 'ГЕНЕРАЛ ТОШЕВО',
    municipalityEn: 'GENERAL TOSHEVO',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9544',
    addressNomenclature: '0',
    x: '28.205867',
    y: '43.629883',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '35403',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЛИНКА',
    nameEn: 'KALINKA',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6623',
    addressNomenclature: '0',
    x: '25.362451',
    y: '41.676874',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '35417',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЛИНО',
    nameEn: 'KALINO',
    municipality: 'ХИТРИНО',
    municipalityEn: 'HITRINO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9795',
    addressNomenclature: '0',
    x: '26.980234',
    y: '43.472697',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '41143',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЛИПЕТРОВО',
    nameEn: 'KALIPETROVO',
    municipality: 'СИЛИСТРА',
    municipalityEn: 'SILISTRA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7539',
    addressNomenclature: '0',
    x: '27.242459',
    y: '44.076073',
    servingDays: '1111110',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '35420',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЛИТИНОВО',
    nameEn: 'KALITINOVO',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6049',
    addressNomenclature: '0',
    x: '25.737932',
    y: '42.424244',
    servingDays: '1111110',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '35434',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЛИЩЕ',
    nameEn: 'KALISHTE',
    municipality: 'КОВАЧЕВЦИ',
    municipalityEn: 'KOVACHEVTSI',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2431',
    addressNomenclature: '0',
    x: '22.832877',
    y: '42.490678',
    servingDays: '1111100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '35448',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЛНИЩЕ',
    nameEn: 'KALNISHTE',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7960',
    addressNomenclature: '0',
    x: '26.205804',
    y: '43.034252',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '35451',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЛОВО',
    nameEn: 'KALOVO',
    municipality: 'МАЛКО ТЪРНОВО',
    municipalityEn: 'MALKO TARNOVO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8169',
    addressNomenclature: '0',
    x: '27.534588',
    y: '42.12656',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '35465',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЛОМЕН',
    nameEn: 'KALOMEN',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5388',
    addressNomenclature: '0',
    x: '25.427759000000002',
    y: '43.032469',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '35479',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЛОТИНА',
    nameEn: 'KALOTINA',
    municipality: 'ДРАГОМАН',
    municipalityEn: 'DRAGOMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2212',
    addressNomenclature: '0',
    x: '22.867756',
    y: '42.993902',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '35482',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЛОТИНЦИ',
    nameEn: 'KALOTINTSI',
    municipality: 'ЗЕМЕН',
    municipalityEn: 'ZEMEN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2444',
    addressNomenclature: '0',
    x: '22.714104',
    y: '42.536203',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '35496',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'КАЛОФЕР',
    nameEn: 'KALOFER',
    municipality: 'КАРЛОВО',
    municipalityEn: 'KARLOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4370',
    addressNomenclature: '2',
    x: '24.974715',
    y: '42.611476',
    servingDays: '1111100',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '35506',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЛОЯН',
    nameEn: 'KALOYAN',
    municipality: 'ВЪЛЧИ ДОЛ',
    municipalityEn: 'VALCHI DOL',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9288',
    addressNomenclature: '0',
    x: '27.408067',
    y: '43.431018',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '35515',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЛОЯНОВЕЦ',
    nameEn: 'KALOYANOVETS',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6070',
    addressNomenclature: '0',
    x: '25.576865',
    y: '42.306926',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '35523',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЛОЯНОВО',
    nameEn: 'KALOYANOVO',
    municipality: 'КАЛОЯНОВО',
    municipalityEn: 'KALOYANOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4173',
    addressNomenclature: '0',
    x: '24.731428',
    y: '42.354253',
    servingDays: '1111100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '35537',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЛОЯНОВО',
    nameEn: 'KALOYANOVO',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8881',
    addressNomenclature: '0',
    x: '26.462065',
    y: '42.656456',
    servingDays: '1111110',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '35540',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЛОЯНЦИ',
    nameEn: 'KALOYANTSI',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6675',
    addressNomenclature: '0',
    x: '25.55165',
    y: '41.667052',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '35554',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЛУГЕРЕНЕ',
    nameEn: 'KALUGERENE',
    municipality: 'ГЛАВИНИЦА',
    municipalityEn: 'GLAVINITSA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7631',
    addressNomenclature: '0',
    x: '26.863241',
    y: '43.914531',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '35571',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЛУГЕРОВО',
    nameEn: 'KALUGEROVO',
    municipality: 'ЛЕСИЧОВО',
    municipalityEn: 'LESICHOVO',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4462',
    addressNomenclature: '0',
    x: '24.170087',
    y: '42.326168',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '35585',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЛУГЕРОВО',
    nameEn: 'KALUGEROVO',
    municipality: 'ПРАВЕЦ',
    municipalityEn: 'PRAVETS',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2169',
    addressNomenclature: '0',
    x: '23.894778',
    y: '42.990379',
    servingDays: '1010100',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '35599',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЛУГЕРОВО',
    nameEn: 'KALUGEROVO',
    municipality: 'СИМЕОНОВГРАД',
    municipalityEn: 'SIMEONOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6495',
    addressNomenclature: '0',
    x: '25.866305',
    y: '42.089967',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '35609',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЛЧЕВО',
    nameEn: 'KALCHEVO',
    municipality: 'ТУНДЖА',
    municipalityEn: 'TUNDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8677',
    addressNomenclature: '0',
    x: '26.604919',
    y: '42.428815',
    servingDays: '1010100',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '35626',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЛЧОВЦИ',
    nameEn: 'KALCHOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5340',
    addressNomenclature: '0',
    x: '25.393187',
    y: '42.886232',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '35630',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАМБЕЛЕВЦИ',
    nameEn: 'KAMBELEVTSI',
    municipality: 'ДРАГОМАН',
    municipalityEn: 'DRAGOMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2215',
    addressNomenclature: '0',
    x: '22.88093',
    y: '42.893959',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '35643',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАМБУРОВО',
    nameEn: 'KAMBUROVO',
    municipality: 'ОМУРТАГ',
    municipalityEn: 'OMURTAG',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7930',
    addressNomenclature: '0',
    x: '26.337013',
    y: '43.109176',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '35657',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАМЕН',
    nameEn: 'KAMEN',
    municipality: 'СТРАЖИЦА',
    municipalityEn: 'STRAZHITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5160',
    addressNomenclature: '0',
    x: '25.926978',
    y: '43.312692',
    servingDays: '1010100',
    servingOfficeId: '46',
    servingHubOfficeId: '11',
  },
  {
    id: '35660',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАМЕН',
    nameEn: 'KAMEN',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8882',
    addressNomenclature: '0',
    x: '26.419721',
    y: '42.603024',
    servingDays: '1010100',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '35674',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАМЕН',
    nameEn: 'KAMEN',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9425',
    addressNomenclature: '0',
    x: '27.71038',
    y: '43.717187',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '35746',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАМЕН БРЯГ',
    nameEn: 'KAMEN BRYAG',
    municipality: 'КАВАРНА',
    municipalityEn: 'KAVARNA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9661',
    addressNomenclature: '0',
    x: '28.550571',
    y: '43.455747',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '35756',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАМЕН ВРЪХ',
    nameEn: 'KAMEN VRAH',
    municipality: 'БОЛЯРОВО',
    municipalityEn: 'BOLYAROVO',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8728',
    addressNomenclature: '0',
    x: '26.791695',
    y: '42.255752',
    servingDays: '0101000',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '35777',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАМЕН ДЯЛ',
    nameEn: 'KAMEN DYAL',
    municipality: 'ДЪЛГОПОЛ',
    municipalityEn: 'DALGOPOL',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9253',
    addressNomenclature: '0',
    x: '27.263167',
    y: '43.02828',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '35688',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАМЕНА',
    nameEn: 'KAMENA',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2878',
    addressNomenclature: '0',
    x: '23.070714',
    y: '41.364052',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '35691',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАМЕНАР',
    nameEn: 'KAMENAR',
    municipality: 'ПОМОРИЕ',
    municipalityEn: 'POMORIE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8215',
    addressNomenclature: '0',
    x: '27.572751',
    y: '42.625456',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '35701',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАМЕНАР',
    nameEn: 'KAMENAR',
    municipality: 'ВАРНА',
    municipalityEn: 'VARNA',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9102',
    addressNomenclature: '0',
    x: '27.908553',
    y: '43.248263',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '35715',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАМЕНАР',
    nameEn: 'KAMENAR',
    municipality: 'ЛОЗНИЦА',
    municipalityEn: 'LOZNITSA',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7247',
    addressNomenclature: '0',
    x: '26.631711',
    y: '43.467504',
    servingDays: '0101000',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '35729',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАМЕНАРИ',
    nameEn: 'KAMENARI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5083',
    addressNomenclature: '0',
    x: '26.056596',
    y: '42.906886',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '35732',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАМЕНАРЦИ',
    nameEn: 'KAMENARTSI',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6729',
    addressNomenclature: '0',
    x: '25.294853',
    y: '41.622696',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '3258',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАМЕНЕЦ',
    nameEn: 'KAMENETS',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6800',
    addressNomenclature: '0',
    x: '25.408454',
    y: '41.471724',
    servingDays: '1111110',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '35780',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАМЕНЕЦ',
    nameEn: 'KAMENETS',
    municipality: 'ПОРДИМ',
    municipalityEn: 'PORDIM',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5866',
    addressNomenclature: '0',
    x: '25.004279',
    y: '43.340281',
    servingDays: '0101000',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '35794',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАМЕНЕЦ',
    nameEn: 'KAMENETS',
    municipality: 'СТРАЛДЖА',
    municipalityEn: 'STRALDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8655',
    addressNomenclature: '0',
    x: '26.737084',
    y: '42.338491',
    servingDays: '0101000',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '35804',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАМЕНИК',
    nameEn: 'KAMENIK',
    municipality: 'БОБОШЕВО',
    municipalityEn: 'BOBOSHEVO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2677',
    addressNomenclature: '0',
    x: '23.018915',
    y: '42.217549',
    servingDays: '0101000',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '35818',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАМЕНИЦА',
    nameEn: 'KAMENITSA',
    municipality: 'СТРУМЯНИ',
    municipalityEn: 'STRUMYANI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2828',
    addressNomenclature: '0',
    x: '23.160211',
    y: '41.650356',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '35821',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАМЕНИЦА',
    nameEn: 'KAMENITSA',
    municipality: 'МИРКОВО',
    municipalityEn: 'MIRKOVO',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2072',
    addressNomenclature: '0',
    x: '23.931171',
    y: '42.590949',
    servingDays: '0101000',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '35835',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАМЕНИЧКА СКАКАВИЦА',
    nameEn: 'KAMENICHKA SKAKAVITSA',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2572',
    addressNomenclature: '0',
    x: '22.501287',
    y: '42.234956',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '35849',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАМЕНКА',
    nameEn: 'KAMENKA',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6918',
    addressNomenclature: '0',
    x: '25.680853',
    y: '41.47487',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '35866',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАМЕННА РЕКА',
    nameEn: 'KAMENNA REKA',
    municipality: 'ТОПОЛОВГРАД',
    municipalityEn: 'TOPOLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6546',
    addressNomenclature: '0',
    x: '26.269508',
    y: '42.169665',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '35871',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАМЕННА РИКСА',
    nameEn: 'KAMENNA RIKSA',
    municipality: 'ГЕОРГИ ДАМЯНОВО',
    municipalityEn: 'GEORGI DAMYANOVO',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3479',
    addressNomenclature: '0',
    x: '23.036007',
    y: '43.455623',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '35883',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'КАМЕНО',
    nameEn: 'KAMENO',
    municipality: 'КАМЕНО',
    municipalityEn: 'KAMENO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8120',
    addressNomenclature: '2',
    x: '27.295356',
    y: '42.572832',
    servingDays: '1111110',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '35910',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАМЕНО ПОЛЕ',
    nameEn: 'KAMENO POLE',
    municipality: 'РОМАН',
    municipalityEn: 'ROMAN',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3150',
    addressNomenclature: '0',
    x: '23.893505',
    y: '43.225465',
    servingDays: '0101000',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '35897',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАМЕНОВО',
    nameEn: 'KAMENOVO',
    municipality: 'КУБРАТ',
    municipalityEn: 'KUBRAT',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7343',
    addressNomenclature: '0',
    x: '26.472314',
    y: '43.719286',
    servingDays: '1010100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '35907',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАМЕНОВО',
    nameEn: 'KAMENOVO',
    municipality: 'НОВА ЗАГОРА',
    municipalityEn: 'NOVA ZAGORA',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8928',
    addressNomenclature: '0',
    x: '26.101336',
    y: '42.542351',
    servingDays: '1010100',
    servingOfficeId: '56',
    servingHubOfficeId: '5',
  },
  {
    id: '35924',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАМЕНСКО',
    nameEn: 'KAMENSKO',
    municipality: 'СУНГУРЛАРЕ',
    municipalityEn: 'SUNGURLARE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '',
    addressNomenclature: '0',
    x: '26.804076',
    y: '42.910881',
    servingDays: '0101000',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '35938',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАМЕНЦИ',
    nameEn: 'KAMENTSI',
    municipality: 'КАЙНАРДЖА',
    municipalityEn: 'KAYNARDZHA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7565',
    addressNomenclature: '0',
    x: '27.486975',
    y: '43.867547',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '35955',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАМЕНЯК',
    nameEn: 'KAMENYAK',
    municipality: 'РУЕН',
    municipalityEn: 'RUEN',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8578',
    addressNomenclature: '0',
    x: '27.298927',
    y: '42.867356',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '35969',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАМЕНЯК',
    nameEn: 'KAMENYAK',
    municipality: 'ХИТРИНО',
    municipalityEn: 'HITRINO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9783',
    addressNomenclature: '0',
    x: '26.967884',
    y: '43.404401',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '35972',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАМЕНЯНЕ',
    nameEn: 'KAMENYANE',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6850',
    addressNomenclature: '0',
    x: '25.295229',
    y: '41.538481',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '35986',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАМЕЩИЦА',
    nameEn: 'KAMESHTITSA',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5333',
    addressNomenclature: '0',
    x: '25.134642',
    y: '42.907633',
    servingDays: '1010100',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '35997',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАМИЛСКИ ДОЛ',
    nameEn: 'KAMILSKI DOL',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6571',
    addressNomenclature: '0',
    x: '26.054805',
    y: '41.599226',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '36004',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАМЧИЯ',
    nameEn: 'KAMCHIA',
    municipality: 'СУНГУРЛАРЕ',
    municipalityEn: 'SUNGURLARE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8434',
    addressNomenclature: '0',
    x: '26.970791',
    y: '42.886414',
    servingDays: '0101000',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '92061',
    countryId: '100',
    mainSiteId: '0',
    type: 'к.',
    typeEn: 'k.',
    name: 'КАМЧИЯ',
    nameEn: 'KAMCHIA',
    municipality: 'АВРЕН',
    municipalityEn: 'AVREN',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9130',
    addressNomenclature: '0',
    x: '27.885456',
    y: '43.02271',
    servingDays: '1111100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '36021',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАНДИЛКА',
    nameEn: 'KANDILKA',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6937',
    addressNomenclature: '0',
    x: '25.585836',
    y: '41.417973',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '36035',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАНДОВИ',
    nameEn: 'KANDOVI',
    municipality: 'ВЕЛИНГРАД',
    municipalityEn: 'VELINGRAD',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4631',
    addressNomenclature: '0',
    x: '23.829354',
    y: '42.023577',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '36049',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАНИЦ',
    nameEn: 'KANITS',
    municipality: 'БОЙНИЦА',
    municipalityEn: 'BOYNITSA',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3843',
    addressNomenclature: '0',
    x: '22.587449',
    y: '44.022598',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '36052',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАНТАРИ',
    nameEn: 'KANTARI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5094',
    addressNomenclature: '0',
    x: '25.805459',
    y: '42.866182',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '36066',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАНЯК',
    nameEn: 'KANYAK',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6695',
    addressNomenclature: '0',
    x: '25.282462',
    y: '41.755302',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '36079',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'КАОЛИНОВО',
    nameEn: 'KAOLINOVO',
    municipality: 'КАОЛИНОВО',
    municipalityEn: 'KAOLINOVO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9960',
    addressNomenclature: '2',
    x: '27.092303',
    y: '43.616976',
    servingDays: '1111100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '36083',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАПАТОВО',
    nameEn: 'KAPATOVO',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2872',
    addressNomenclature: '0',
    x: '23.358798',
    y: '41.463987',
    servingDays: '1111110',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '36110',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАПИТАН АНДРЕЕВО',
    nameEn: 'KAPITAN ANDREEVO',
    municipality: 'СВИЛЕНГРАД',
    municipalityEn: 'SVILENGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6530',
    addressNomenclature: '0',
    x: '26.320606',
    y: '41.720871',
    servingDays: '1010100',
    servingOfficeId: '48',
    servingHubOfficeId: '5',
  },
  {
    id: '36124',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАПИТАН ДИМИТРИЕВО',
    nameEn: 'KAPITAN DIMITRIEVO',
    municipality: 'ПЕЩЕРА',
    municipalityEn: 'PESHTERA',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4589',
    addressNomenclature: '0',
    x: '24.330123',
    y: '42.103002',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '36138',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАПИТАН ДИМИТРОВО',
    nameEn: 'KAPITAN DIMITROVO',
    municipality: 'КРУШАРИ',
    municipalityEn: 'KRUSHARI',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9408',
    addressNomenclature: '0',
    x: '27.692183',
    y: '43.954129',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '36155',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАПИТАН ПЕТКО',
    nameEn: 'KAPITAN PETKO',
    municipality: 'ВЕНЕЦ',
    municipalityEn: 'VENETS',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9770',
    addressNomenclature: '0',
    x: '26.874409',
    y: '43.490607',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '24013',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАПИТАН ПЕТКО ВОЙВОДА',
    nameEn: 'KAPITAN PETKO VOYVODA',
    municipality: 'ТОПОЛОВГРАД',
    municipalityEn: 'TOPOLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6561',
    addressNomenclature: '0',
    x: '26.422671',
    y: '42.071615',
    servingDays: '0101000',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '36141',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАПИТАНОВЦИ',
    nameEn: 'KAPITANOVTSI',
    municipality: 'ВИДИН',
    municipalityEn: 'VIDIN',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3768',
    addressNomenclature: '0',
    x: '22.877141',
    y: '44.034815',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '36169',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАПИЩЕ',
    nameEn: 'KAPISHTE',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7988',
    addressNomenclature: '0',
    x: '26.202848',
    y: '43.063251',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '36333',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАРА МИХАЛ',
    nameEn: 'KARA MIHAL',
    municipality: 'САМУИЛ',
    municipalityEn: 'SAMUIL',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7453',
    addressNomenclature: '0',
    x: '26.835029',
    y: '43.593465',
    servingDays: '1010100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '36172',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАРАБУНАР',
    nameEn: 'KARABUNAR',
    municipality: 'СЕПТЕМВРИ',
    municipalityEn: 'SEPTEMVRI',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4484',
    addressNomenclature: '0',
    x: '24.171506',
    y: '42.262191',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '36186',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАРАВЕЛОВО',
    nameEn: 'KARAVELOVO',
    municipality: 'КАРЛОВО',
    municipalityEn: 'KARLOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4350',
    addressNomenclature: '0',
    x: '24.655478',
    y: '42.62622',
    servingDays: '1010100',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '36194',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАРАВЕЛОВО',
    nameEn: 'KARAVELOVO',
    municipality: 'НИКОЛА КОЗЛЕВО',
    municipalityEn: 'NIKOLA KOZLEVO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9942',
    addressNomenclature: '0',
    x: '27.304344',
    y: '43.532711',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '36200',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАРАВЕЛОВО',
    nameEn: 'KARAVELOVO',
    municipality: 'ТУНДЖА',
    municipalityEn: 'TUNDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8647',
    addressNomenclature: '0',
    x: '26.63291',
    y: '42.309559',
    servingDays: '0101000',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '36213',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАРАВЕЛЬОВО',
    nameEn: 'KARAVELYOVO',
    municipality: 'РУЕН',
    municipalityEn: 'RUEN',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8553',
    addressNomenclature: '0',
    x: '27.150647',
    y: '42.927154',
    servingDays: '1111100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '36227',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАРАГЕОРГИЕВО',
    nameEn: 'KARAGEORGIEVO',
    municipality: 'АЙТОС',
    municipalityEn: 'AYTOS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8543',
    addressNomenclature: '0',
    x: '27.186043',
    y: '42.735146',
    servingDays: '1111100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '36230',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАРАДЖАЛОВО',
    nameEn: 'KARADZHALOVO',
    municipality: 'ПЪРВОМАЙ',
    municipalityEn: 'PARVOMAY',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4283',
    addressNomenclature: '0',
    x: '25.318735',
    y: '42.094527',
    servingDays: '0101000',
    servingOfficeId: '42',
    servingHubOfficeId: '5',
  },
  {
    id: '36244',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАРАДЖОВО',
    nameEn: 'KARADZHOVO',
    municipality: 'САДОВО',
    municipalityEn: 'SADOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4117',
    addressNomenclature: '0',
    x: '24.904528',
    y: '42.0969',
    servingDays: '0101010',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '36261',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАРАИВАНЦА',
    nameEn: 'KARAIVANTSA',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5386',
    addressNomenclature: '0',
    x: '25.405333',
    y: '42.983702',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '36275',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАРАИВАНЦИ',
    nameEn: 'KARAIVANTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5095',
    addressNomenclature: '0',
    x: '25.807175',
    y: '42.805103',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '36289',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАРАИСЕН',
    nameEn: 'KARAISEN',
    municipality: 'ПАВЛИКЕНИ',
    municipalityEn: 'PAVLIKENI',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5230',
    addressNomenclature: '0',
    x: '25.346214',
    y: '43.378162',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '36292',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАРАЛИ',
    nameEn: 'KARALI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5343',
    addressNomenclature: '0',
    x: '25.391397',
    y: '42.949357',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '36302',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАРАМАНИТЕ',
    nameEn: 'KARAMANITE',
    municipality: 'ВЪЛЧИ ДОЛ',
    municipalityEn: 'VALCHI DOL',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9299',
    addressNomenclature: '0',
    x: '27.478184',
    y: '43.486767',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '36316',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАРАМАНОВО',
    nameEn: 'KARAMANOVO',
    municipality: 'ЦЕНОВО',
    municipalityEn: 'TSENOVO',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7130',
    addressNomenclature: '0',
    x: '25.527265',
    y: '43.549525',
    servingDays: '1010100',
    servingOfficeId: '250',
    servingHubOfficeId: '11',
  },
  {
    id: '36325',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАРАМАНЦИ',
    nameEn: 'KARAMANTSI',
    municipality: 'МИНЕРАЛНИ БАНИ',
    municipalityEn: 'MINERALNI BANI',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6372',
    addressNomenclature: '0',
    x: '25.329455',
    y: '41.834753',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '36347',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАРАМИЧЕВЦИ',
    nameEn: 'KARAMICHEVTSI',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5471',
    addressNomenclature: '0',
    x: '25.070555',
    y: '42.894742',
    servingDays: '1010100',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '36350',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАРАМФИЛ',
    nameEn: 'KARAMFIL',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6826',
    addressNomenclature: '0',
    x: '25.560995',
    y: '41.477477',
    servingDays: '1111100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '36364',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАРАН ВЪРБОВКА',
    nameEn: 'KARAN VARBOVKA',
    municipality: 'ДВЕ МОГИЛИ',
    municipalityEn: 'DVE MOGILI',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7163',
    addressNomenclature: '0',
    x: '25.994468',
    y: '43.5008',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '92062',
    countryId: '100',
    mainSiteId: '0',
    type: 'к.м.',
    typeEn: 'k.m.',
    name: 'КАРАНДИЛА',
    nameEn: 'KARANDILA',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8839',
    addressNomenclature: '0',
    x: '26.372823',
    y: '42.723003',
    servingDays: '0101000',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '36378',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАРАНДИЛИ',
    nameEn: 'KARANDILI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5094',
    addressNomenclature: '0',
    x: '25.826107',
    y: '42.879536',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '36381',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАРАНОВО',
    nameEn: 'KARANOVO',
    municipality: 'АЙТОС',
    municipalityEn: 'AYTOS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8532',
    addressNomenclature: '0',
    x: '27.193043',
    y: '42.643728',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '36395',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАРАНОВО',
    nameEn: 'KARANOVO',
    municipality: 'НОВА ЗАГОРА',
    municipalityEn: 'NOVA ZAGORA',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8934',
    addressNomenclature: '0',
    x: '25.913952',
    y: '42.514225',
    servingDays: '1010100',
    servingOfficeId: '56',
    servingHubOfficeId: '5',
  },
  {
    id: '36405',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАРАНЦИ',
    nameEn: 'KARANTSI',
    municipality: 'ПОЛСКИ ТРЪМБЕШ',
    municipalityEn: 'POLSKI TRAMBESH',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5188',
    addressNomenclature: '0',
    x: '25.681353',
    y: '43.387179',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '36419',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАРАПЕЛИТ',
    nameEn: 'KARAPELIT',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9390',
    addressNomenclature: '0',
    x: '27.5693',
    y: '43.648186',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '36422',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАРАПОЛЦИ',
    nameEn: 'KARAPOLTSI',
    municipality: 'ЕЛИН ПЕЛИН',
    municipalityEn: 'ELIN PELIN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2118',
    addressNomenclature: '0',
    x: '23.701183',
    y: '42.618636',
    servingDays: '1010100',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '36436',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАРАШ',
    nameEn: 'KARASH',
    municipality: 'РОМАН',
    municipalityEn: 'ROMAN',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3136',
    addressNomenclature: '0',
    x: '23.917312',
    y: '43.096094',
    servingDays: '0101000',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '36440',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАРБИНЦИ',
    nameEn: 'KARBINTSI',
    municipality: 'ДИМОВО',
    municipalityEn: 'DIMOVO',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3923',
    addressNomenclature: '0',
    x: '22.840717',
    y: '43.676024',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '36453',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАРВУНА',
    nameEn: 'KARVUNA',
    municipality: 'БАЛЧИК',
    municipalityEn: 'BALCHIK',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9619',
    addressNomenclature: '0',
    x: '28.02399',
    y: '43.474199',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '36467',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАРДАМ',
    nameEn: 'KARDAM',
    municipality: 'ГЕНЕРАЛ ТОШЕВО',
    municipalityEn: 'GENERAL TOSHEVO',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9530',
    addressNomenclature: '0',
    x: '28.10759',
    y: '43.75387',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '36470',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАРДАМ',
    nameEn: 'KARDAM',
    municipality: 'ПОПОВО',
    municipalityEn: 'POPOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7830',
    addressNomenclature: '0',
    x: '26.286396',
    y: '43.375517',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '36484',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАРЛИЕВО',
    nameEn: 'KARLIEVO',
    municipality: 'ЗЛАТИЦА',
    municipalityEn: 'ZLATITSA',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2088',
    addressNomenclature: '0',
    x: '24.10614',
    y: '42.684904',
    servingDays: '0101000',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '36498',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'КАРЛОВО',
    nameEn: 'KARLOVO',
    municipality: 'КАРЛОВО',
    municipalityEn: 'KARLOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4300',
    addressNomenclature: '1',
    x: '24.808209',
    y: '42.642033',
    servingDays: '1111110',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '36508',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАРЛОВСКО',
    nameEn: 'KARLOVSKO',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6572',
    addressNomenclature: '0',
    x: '25.929667',
    y: '41.527881',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '36511',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАРЛУКОВО',
    nameEn: 'KARLUKOVO',
    municipality: 'ЛУКОВИТ',
    municipalityEn: 'LUKOVIT',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5782',
    addressNomenclature: '0',
    x: '24.066622',
    y: '43.165315',
    servingDays: '0101000',
    servingOfficeId: '59',
    servingHubOfficeId: '20',
  },
  {
    id: '36525',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'КАРНОБАТ',
    nameEn: 'KARNOBAT',
    municipality: 'КАРНОБАТ',
    municipalityEn: 'KARNOBAT',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8400',
    addressNomenclature: '2',
    x: '26.976065',
    y: '42.650635',
    servingDays: '1111110',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '36556',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАСАПСКО',
    nameEn: 'KASAPSKO',
    municipality: 'МАДАН',
    municipalityEn: 'MADAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4921',
    addressNomenclature: '0',
    x: '24.999585',
    y: '41.591962',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '36566',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАСИЛАГ',
    nameEn: 'KASILAG',
    municipality: 'РАДОМИР',
    municipalityEn: 'RADOMIR',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2424',
    addressNomenclature: '0',
    x: '22.974008',
    y: '42.421401',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '36573',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАСНАКОВО',
    nameEn: 'KASNAKOVO',
    municipality: 'ДИМИТРОВГРАД',
    municipalityEn: 'DIMITROVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6439',
    addressNomenclature: '0',
    x: '25.490272',
    y: '42.012712',
    servingDays: '1010100',
    servingOfficeId: '42',
    servingHubOfficeId: '5',
  },
  {
    id: '36587',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'КАСПИЧАН',
    nameEn: 'KASPICHAN',
    municipality: 'КАСПИЧАН',
    municipalityEn: 'KASPICHAN',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9930',
    addressNomenclature: '2',
    x: '27.159169',
    y: '43.314954',
    servingDays: '1111100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '36590',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАСПИЧАН',
    nameEn: 'KASPICHAN',
    municipality: 'КАСПИЧАН',
    municipalityEn: 'KASPICHAN',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9931',
    addressNomenclature: '0',
    x: '27.180375',
    y: '43.296282',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '36600',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАСТЕЛ',
    nameEn: 'KASTEL',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5472',
    addressNomenclature: '0',
    x: '25.106069',
    y: '42.897178',
    servingDays: '1010100',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '36614',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАТЕРИЦА',
    nameEn: 'KATERITSA',
    municipality: 'ПОРДИМ',
    municipalityEn: 'PORDIM',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5894',
    addressNomenclature: '0',
    x: '24.91911',
    y: '43.309926',
    servingDays: '0101000',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '36628',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАТРАНДЖИИ',
    nameEn: 'KATRANDZHII',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5392',
    addressNomenclature: '0',
    x: '25.518399',
    y: '43.015529',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '36631',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАТРАНИЦА',
    nameEn: 'KATRANITSA',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4739',
    addressNomenclature: '0',
    x: '24.902644',
    y: '41.58385',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '36659',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАТРИЩЕ',
    nameEn: 'KATRISHTE',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2537',
    addressNomenclature: '0',
    x: '22.83133',
    y: '42.289597',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '36662',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАТУНЕЦ',
    nameEn: 'KATUNETS',
    municipality: 'УГЪРЧИН',
    municipalityEn: 'UGARCHIN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5569',
    addressNomenclature: '0',
    x: '24.502849',
    y: '43.204651',
    servingDays: '1010100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '36676',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАТУНИЦА',
    nameEn: 'KATUNITSA',
    municipality: 'САДОВО',
    municipalityEn: 'SADOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4120',
    addressNomenclature: '0',
    x: '24.87252',
    y: '42.100293',
    servingDays: '0101010',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '36681',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАТУНИЩЕ',
    nameEn: 'KATUNISHTE',
    municipality: 'КОТЕЛ',
    municipalityEn: 'KOTEL',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8991',
    addressNomenclature: '0',
    x: '26.510154',
    y: '42.806024',
    servingDays: '0101000',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '36693',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАТУНЦИ',
    nameEn: 'KATUNTSI',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2830',
    addressNomenclature: '0',
    x: '23.429908',
    y: '41.444178',
    servingDays: '1010100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '36703',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЦЕЛОВО',
    nameEn: 'KATSELOVO',
    municipality: 'ДВЕ МОГИЛИ',
    municipalityEn: 'DVE MOGILI',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7160',
    addressNomenclature: '0',
    x: '26.064797',
    y: '43.534591',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '36717',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЧУЛКА',
    nameEn: 'KACHULKA',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6907',
    addressNomenclature: '0',
    x: '25.688789',
    y: '41.497337',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '36720',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАШЕНЦИ',
    nameEn: 'KASHENTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5351',
    addressNomenclature: '0',
    x: '25.436367',
    y: '42.849048',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '36734',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАШИНА',
    nameEn: 'KASHINA',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2820',
    addressNomenclature: '0',
    x: '23.468776',
    y: '41.546963',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '36748',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КАЯЛОБА',
    nameEn: 'KAYALOBA',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6865',
    addressNomenclature: '0',
    x: '25.193502',
    y: '41.360471',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '36751',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЕРЕКА',
    nameEn: 'KEREKA',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5388',
    addressNomenclature: '0',
    x: '25.467964',
    y: '43.033086',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '36765',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЕРЕНИТЕ',
    nameEn: 'KERENITE',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5365',
    addressNomenclature: '0',
    x: '25.507449',
    y: '42.895851',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '36779',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'КЕРМЕН',
    nameEn: 'KERMEN',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8870',
    addressNomenclature: '2',
    x: '26.256361',
    y: '42.502377',
    servingDays: '1010100',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '36782',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЕСАРЕВО',
    nameEn: 'KESAREVO',
    municipality: 'СТРАЖИЦА',
    municipalityEn: 'STRAZHITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5161',
    addressNomenclature: '0',
    x: '25.967629',
    y: '43.152749',
    servingDays: '1010100',
    servingOfficeId: '46',
    servingHubOfficeId: '11',
  },
  {
    id: '36796',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЕСТЕН',
    nameEn: 'KESTEN',
    municipality: 'ДЕВИН',
    municipalityEn: 'DEVIN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4828',
    addressNomenclature: '0',
    x: '24.43525',
    y: '41.553422',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '36806',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЕСТЕНОВО',
    nameEn: 'KESTENOVO',
    municipality: 'ОМУРТАГ',
    municipalityEn: 'OMURTAG',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7973',
    addressNomenclature: '0',
    x: '26.550963',
    y: '43.106415',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '36823',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КИЕВЦИ',
    nameEn: 'KIEVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5345',
    addressNomenclature: '0',
    x: '25.288015',
    y: '42.899647',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '36837',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'КИЛИФАРЕВО',
    nameEn: 'KILIFAREVO',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5050',
    addressNomenclature: '0',
    x: '25.620511',
    y: '42.994276',
    servingDays: '1111100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '36854',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КИПИЛОВО',
    nameEn: 'KIPILOVO',
    municipality: 'КОТЕЛ',
    municipalityEn: 'KOTEL',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8998',
    addressNomenclature: '0',
    x: '26.232875',
    y: '42.903081',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '36868',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КИПРА',
    nameEn: 'KIPRA',
    municipality: 'ДЕВНЯ',
    municipalityEn: 'DEVNYA',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9169',
    addressNomenclature: '0',
    x: '27.621761',
    y: '43.260671',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '36871',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КИРЕВЦИ',
    nameEn: 'KIREVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5094',
    addressNomenclature: '0',
    x: '25.814021',
    y: '42.856744',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '36885',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КИРЕЕВО',
    nameEn: 'KIREEVO',
    municipality: 'МАКРЕШ',
    municipalityEn: 'MAKRESH',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3823',
    addressNomenclature: '0',
    x: '22.394626',
    y: '43.794214',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '36899',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КИРИЛОВО',
    nameEn: 'KIRILOVO',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6064',
    addressNomenclature: '0',
    x: '25.518581',
    y: '42.374347',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '36909',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КИРИЛОВО',
    nameEn: 'KIRILOVO',
    municipality: 'ЕЛХОВО',
    municipalityEn: 'ELHOVO',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8741',
    addressNomenclature: '0',
    x: '26.61522',
    y: '42.222221',
    servingDays: '0101000',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '36926',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КИРКОВО',
    nameEn: 'KIRKOVO',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6884',
    addressNomenclature: '2',
    x: '25.365249',
    y: '41.328168',
    servingDays: '1111100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '36938',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КИРОВО',
    nameEn: 'KIROVO',
    municipality: 'СРЕДЕЦ',
    municipalityEn: 'SREDETS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8344',
    addressNomenclature: '0',
    x: '27.188502',
    y: '42.157578',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '36943',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КИРЧЕВО',
    nameEn: 'KIRCHEVO',
    municipality: 'УГЪРЧИН',
    municipalityEn: 'UGARCHIN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5521',
    addressNomenclature: '0',
    x: '24.362553',
    y: '42.992587',
    servingDays: '1010100',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '36957',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КИСЕЛЕВО',
    nameEn: 'KISELEVO',
    municipality: 'БРУСАРЦИ',
    municipalityEn: 'BRUSARTSI',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3675',
    addressNomenclature: '0',
    x: '22.987051',
    y: '43.654068',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '36960',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КИСЕЛИЦА',
    nameEn: 'KISELITSA',
    municipality: 'ТРЕКЛЯНО',
    municipalityEn: 'TREKLYANO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2556',
    addressNomenclature: '0',
    x: '22.511193',
    y: '42.547149',
    servingDays: '0101000',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '36988',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КИСЕЛКОВЦИ',
    nameEn: 'KISELKOVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5360',
    addressNomenclature: '0',
    x: '25.448447',
    y: '42.826715',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '36974',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КИСЕЛЧОВО',
    nameEn: 'KISELCHOVO',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4765',
    addressNomenclature: '0',
    x: '24.593078',
    y: '41.53286',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '36991',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КИСИЙЦИТЕ',
    nameEn: 'KISIYTSITE',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5365',
    addressNomenclature: '0',
    x: '25.504183',
    y: '42.884444',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '37006',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КИСЬОВЦИ',
    nameEn: 'KISYOVTSI',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5035',
    addressNomenclature: '0',
    x: '25.622274',
    y: '42.852692',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '37010',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КИТАНЧЕВО',
    nameEn: 'KITANCHEVO',
    municipality: 'ИСПЕРИХ',
    municipalityEn: 'ISPERIH',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7430',
    addressNomenclature: '0',
    x: '26.89447',
    y: '43.73121',
    servingDays: '1010100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '37023',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'КИТЕН',
    nameEn: 'KITEN',
    municipality: 'ПРИМОРСКО',
    municipalityEn: 'PRIMORSKO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8183',
    addressNomenclature: '2',
    x: '27.772709',
    y: '42.234464',
    servingDays: '1111110',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '37037',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КИТЕН',
    nameEn: 'KITEN',
    municipality: 'ПРОВАДИЯ',
    municipalityEn: 'PROVADIA',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9241',
    addressNomenclature: '0',
    x: '27.31279',
    y: '43.085888',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '37040',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КИТИНО',
    nameEn: 'KITINO',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7993',
    addressNomenclature: '0',
    x: '26.374556',
    y: '43.175859',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '37054',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КИТКА',
    nameEn: 'KITKA',
    municipality: 'РУЕН',
    municipalityEn: 'RUEN',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8558',
    addressNomenclature: '0',
    x: '27.190932',
    y: '42.841402',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '37068',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КИТКА',
    nameEn: 'KITKA',
    municipality: 'АВРЕН',
    municipalityEn: 'AVREN',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9127',
    addressNomenclature: '0',
    x: '27.775572',
    y: '43.053322',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '37071',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КИТНА',
    nameEn: 'KITNA',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6868',
    addressNomenclature: '0',
    x: '25.237328',
    y: '41.372593',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '37085',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КИТНИЦА',
    nameEn: 'KITNITSA',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6791',
    addressNomenclature: '0',
    x: '25.167163',
    y: '41.679511',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '37099',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КИЧЕВО',
    nameEn: 'KICHEVO',
    municipality: 'АКСАКОВО',
    municipalityEn: 'AKSAKOVO',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9151',
    addressNomenclature: '0',
    x: '27.961517',
    y: '43.272468',
    servingDays: '1111110',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '37109',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КИЧЕНИЦА',
    nameEn: 'KICHENITSA',
    municipality: 'РАЗГРАД',
    municipalityEn: 'RAZGRAD',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7243',
    addressNomenclature: '0',
    x: '26.507523',
    y: '43.603921',
    servingDays: '1010100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '37126',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЛАДЕНЕЦ',
    nameEn: 'KLADENETS',
    municipality: 'СТАМБОЛОВО',
    municipalityEn: 'STAMBOLOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6395',
    addressNomenclature: '0',
    x: '25.675208',
    y: '41.737795',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '37135',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЛАДЕНЕЦ',
    nameEn: 'KLADENETS',
    municipality: 'ШУМЕН',
    municipalityEn: 'SHUMEN',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9816',
    addressNomenclature: '0',
    x: '27.165899',
    y: '43.157823',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '37143',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЛАДЕНЦИ',
    nameEn: 'KLADENTSI',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2895',
    addressNomenclature: '0',
    x: '23.041181',
    y: '41.422613',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '37157',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЛАДЕНЦИ',
    nameEn: 'KLADENTSI',
    municipality: 'ТЕРВЕЛ',
    municipalityEn: 'TERVEL',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9497',
    addressNomenclature: '0',
    x: '27.244881',
    y: '43.711296',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '37160',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЛАДНИ ДЯЛ',
    nameEn: 'KLADNI DYAL',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5046',
    addressNomenclature: '0',
    x: '25.694635',
    y: '42.858826',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '37174',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЛАДНИЦА',
    nameEn: 'KLADNITSA',
    municipality: 'ПЕРНИК',
    municipalityEn: 'PERNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2347',
    addressNomenclature: '0',
    x: '23.183098',
    y: '42.56306',
    servingDays: '1111100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '37188',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЛАДОРУБ',
    nameEn: 'KLADORUB',
    municipality: 'ДИМОВО',
    municipalityEn: 'DIMOVO',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3758',
    addressNomenclature: '0',
    x: '22.651175',
    y: '43.716586',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '41232',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЛЕНОВИК',
    nameEn: 'KLENOVIK',
    municipality: 'РАДОМИР',
    municipalityEn: 'RADOMIR',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2421',
    addressNomenclature: '0',
    x: '22.923051',
    y: '42.421913',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '37191',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЛЕПАЛО',
    nameEn: 'KLEPALO',
    municipality: 'СТРУМЯНИ',
    municipalityEn: 'STRUMYANI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2837',
    addressNomenclature: '0',
    x: '22.973213',
    y: '41.622545',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '37201',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЛИКАЧ',
    nameEn: 'KLIKACH',
    municipality: 'КАРНОБАТ',
    municipalityEn: 'KARNOBAT',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8427',
    addressNomenclature: '0',
    x: '27.059073',
    y: '42.704918',
    servingDays: '1010100',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '37215',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЛИМАШ',
    nameEn: 'KLIMASH',
    municipality: 'СУНГУРЛАРЕ',
    municipalityEn: 'SUNGURLARE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8442',
    addressNomenclature: '0',
    x: '26.904131',
    y: '42.779151',
    servingDays: '0101000',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '37229',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЛИМЕНТ',
    nameEn: 'KLIMENT',
    municipality: 'КАРЛОВО',
    municipalityEn: 'KARLOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4348',
    addressNomenclature: '0',
    x: '24.686005',
    y: '42.598075',
    servingDays: '1010100',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '37232',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЛИМЕНТ',
    nameEn: 'KLIMENT',
    municipality: 'КАОЛИНОВО',
    municipalityEn: 'KAOLINOVO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9970',
    addressNomenclature: '0',
    x: '27.005843',
    y: '43.624894',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '37246',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЛИМЕНТОВО',
    nameEn: 'KLIMENTOVO',
    municipality: 'АКСАКОВО',
    municipalityEn: 'AKSAKOVO',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9150',
    addressNomenclature: '0',
    x: '27.948085',
    y: '43.341938',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '41246',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЛИМЕНТОВО',
    nameEn: 'KLIMENTOVO',
    municipality: 'ПОЛСКИ ТРЪМБЕШ',
    municipalityEn: 'POLSKI TRAMBESH',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5183',
    addressNomenclature: '0',
    x: '25.603283',
    y: '43.362603',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '37263',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЛИСУРА',
    nameEn: 'KLISURA',
    municipality: 'БЛАГОЕВГРАД',
    municipalityEn: 'BLAGOEVGRAD',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2738',
    addressNomenclature: '0',
    x: '22.913594',
    y: '41.998059',
    servingDays: '1111110',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '37277',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'КЛИСУРА',
    nameEn: 'KLISURA',
    municipality: 'КАРЛОВО',
    municipalityEn: 'KARLOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4341',
    addressNomenclature: '2',
    x: '24.449757',
    y: '42.691524',
    servingDays: '0101000',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '37280',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЛИСУРА',
    nameEn: 'KLISURA',
    municipality: 'СТОЛИЧНА',
    municipalityEn: 'STOLICHNA',
    region: 'СОФИЯ (СТОЛИЦА)',
    regionEn: 'SOFIA (STOLITSA)',
    postCode: '1320',
    addressNomenclature: '2',
    x: '23.08363',
    y: '42.719345',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '37294',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЛИСУРА',
    nameEn: 'KLISURA',
    municipality: 'САМОКОВ',
    municipalityEn: 'SAMOKOV',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2026',
    addressNomenclature: '0',
    x: '23.3646',
    y: '42.329975',
    servingDays: '0101000',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '37304',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЛИСУРИЦА',
    nameEn: 'KLISURITSA',
    municipality: 'МОНТАНА',
    municipalityEn: 'MONTANA',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3483',
    addressNomenclature: '0',
    x: '23.039397',
    y: '43.518493',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '37318',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.ман.',
    typeEn: 's.man.',
    name: 'КЛИСУРСКИ МАНАСТИР',
    nameEn: 'KLISURSKI MANASTIR',
    municipality: 'ВЪРШЕЦ',
    municipalityEn: 'VARSHETS',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3543',
    addressNomenclature: '0',
    x: '23.204943',
    y: '43.182737',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '37321',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЛОКОТНИЦА',
    nameEn: 'KLOKOTNITSA',
    municipality: 'ХАСКОВО',
    municipalityEn: 'HASKOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6310',
    addressNomenclature: '0',
    x: '25.494458',
    y: '41.993105',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '37335',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЛЪШКА РЕКА',
    nameEn: 'KLASHKA REKA',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5035',
    addressNomenclature: '0',
    x: '25.616604',
    y: '42.813458',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '37349',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЛЮЧ',
    nameEn: 'KLYUCH',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2899',
    addressNomenclature: '0',
    x: '23.019767',
    y: '41.362945',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '37352',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КМЕТОВЦИ',
    nameEn: 'KMETOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5340',
    addressNomenclature: '0',
    x: '25.392541',
    y: '42.900207',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '37366',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КМЕТЧЕТА',
    nameEn: 'KMETCHETA',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5343',
    addressNomenclature: '0',
    x: '25.380336',
    y: '42.941762',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '37376',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'КНЕЖА',
    nameEn: 'KNEZHA',
    municipality: 'КНЕЖА',
    municipalityEn: 'KNEZHA',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5835',
    addressNomenclature: '2',
    x: '24.080615',
    y: '43.493071',
    servingDays: '1111100',
    servingOfficeId: '59',
    servingHubOfficeId: '20',
  },
  {
    id: '37383',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КНИЖОВНИК',
    nameEn: 'KNIZHOVNIK',
    municipality: 'ХАСКОВО',
    municipalityEn: 'HASKOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6390',
    addressNomenclature: '0',
    x: '25.607785',
    y: '41.825834',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '37397',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КНЯЖЕВА МАХАЛА',
    nameEn: 'KNYAZHEVA MAHALA',
    municipality: 'БРУСАРЦИ',
    municipalityEn: 'BRUSARTSI',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3677',
    addressNomenclature: '0',
    x: '23.050716',
    y: '43.689612',
    servingDays: '1010100',
    servingOfficeId: '831',
    servingHubOfficeId: '20',
  },
  {
    id: '37407',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КНЯЖЕВО',
    nameEn: 'KNYAZHEVO',
    municipality: 'ТОПОЛОВГРАД',
    municipalityEn: 'TOPOLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6566',
    addressNomenclature: '0',
    x: '26.502073',
    y: '42.109605',
    servingDays: '1010100',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '61791',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КНЯЖЕВСКО',
    nameEn: 'KNYAZHEVSKO',
    municipality: 'ОПАН',
    municipalityEn: 'OPAN',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6077',
    addressNomenclature: '0',
    x: '25.792692',
    y: '42.212146',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '37410',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОБИЛИНО',
    nameEn: 'KOBILINO',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6593',
    addressNomenclature: '0',
    x: '26.000057',
    y: '41.503995',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '37424',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОБИЛЯК',
    nameEn: 'KOBILYAK',
    municipality: 'БОЙЧИНОВЦИ',
    municipalityEn: 'BOYCHINOVTSI',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3442',
    addressNomenclature: '0',
    x: '23.450112',
    y: '43.52941',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '37438',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОБИЛЯНЕ',
    nameEn: 'KOBILYANE',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6734',
    addressNomenclature: '0',
    x: '25.236466',
    y: '41.597426',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '37455',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОВАЧ',
    nameEn: 'KOVACH',
    municipality: 'РАДНЕВО',
    municipalityEn: 'RADNEVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6272',
    addressNomenclature: '0',
    x: '25.841369',
    y: '42.294293',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '37469',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОВАЧЕВЕЦ',
    nameEn: 'KOVACHEVETS',
    municipality: 'ПОПОВО',
    municipalityEn: 'POPOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7852',
    addressNomenclature: '0',
    x: '26.099369',
    y: '43.363325',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '37472',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОВАЧЕВИЦА',
    nameEn: 'KOVACHEVITSA',
    municipality: 'ГЪРМЕН',
    municipalityEn: 'GARMEN',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2969',
    addressNomenclature: '0',
    x: '23.82696',
    y: '41.685338',
    servingDays: '0101000',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '37486',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОВАЧЕВО',
    nameEn: 'KOVACHEVO',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2824',
    addressNomenclature: '0',
    x: '23.48275',
    y: '41.499018',
    servingDays: '1010100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '37491',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОВАЧЕВО',
    nameEn: 'KOVACHEVO',
    municipality: 'СЕПТЕМВРИ',
    municipalityEn: 'SEPTEMVRI',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4477',
    addressNomenclature: '0',
    x: '24.198131',
    y: '42.204624',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '37507',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОВАЧЕВО',
    nameEn: 'KOVACHEVO',
    municipality: 'РАДНЕВО',
    municipalityEn: 'RADNEVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6264',
    addressNomenclature: '0',
    x: '26.070029',
    y: '42.239356',
    servingDays: '1111110',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '37513',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОВАЧЕВЦИ',
    nameEn: 'KOVACHEVTSI',
    municipality: 'КОВАЧЕВЦИ',
    municipalityEn: 'KOVACHEVTSI',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2450',
    addressNomenclature: '0',
    x: '22.817483',
    y: '42.556876',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '37527',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОВАЧЕВЦИ',
    nameEn: 'KOVACHEVTSI',
    municipality: 'САМОКОВ',
    municipalityEn: 'SAMOKOV',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2023',
    addressNomenclature: '0',
    x: '23.32901',
    y: '42.445928',
    servingDays: '0101000',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '37530',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОВАЧИТЕ',
    nameEn: 'KOVACHITE',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8874',
    addressNomenclature: '0',
    x: '26.226304',
    y: '42.574242',
    servingDays: '1010100',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '37544',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОВАЧИЦА',
    nameEn: 'KOVACHITSA',
    municipality: 'ЛОМ',
    municipalityEn: 'LOM',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3643',
    addressNomenclature: '0',
    x: '23.362639',
    y: '43.800573',
    servingDays: '0101000',
    servingOfficeId: '831',
    servingHubOfficeId: '20',
  },
  {
    id: '37558',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОВИЛ',
    nameEn: 'KOVIL',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6913',
    addressNomenclature: '0',
    x: '25.663971',
    y: '41.510401',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '37561',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЕВЦИ',
    nameEn: 'KOEVTSI',
    municipality: 'СУХИНДОЛ',
    municipalityEn: 'SUHINDOL',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5241',
    addressNomenclature: '0',
    x: '25.113308',
    y: '43.156352',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '37575',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЕВЦИ',
    nameEn: 'KOEVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5365',
    addressNomenclature: '0',
    x: '25.575209',
    y: '42.884471',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '37592',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЖАРИ',
    nameEn: 'KOZHARI',
    municipality: 'БОРИНО',
    municipalityEn: 'BORINO',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4813',
    addressNomenclature: '0',
    x: '24.338723',
    y: '41.543334',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '37602',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЖИНЦИ',
    nameEn: 'KOZHINTSI',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2487',
    addressNomenclature: '0',
    x: '22.623027',
    y: '42.744772',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '37616',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЖЛЮВЦИ',
    nameEn: 'KOZHLYUVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5097',
    addressNomenclature: '0',
    x: '25.895242',
    y: '42.86035',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '37622',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЖУХАРЦИ',
    nameEn: 'KOZHUHARTSI',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6957',
    addressNomenclature: '0',
    x: '25.755151',
    y: '41.594641',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '37633',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЗАНОВО',
    nameEn: 'KOZANOVO',
    municipality: 'АСЕНОВГРАД',
    municipalityEn: 'ASENOVGRAD',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4248',
    addressNomenclature: '0',
    x: '24.968937',
    y: '42.019615',
    servingDays: '1010100',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '37647',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЗАР БЕЛЕНЕ',
    nameEn: 'KOZAR BELENE',
    municipality: 'ЛЕВСКИ',
    municipalityEn: 'LEVSKI',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5915',
    addressNomenclature: '0',
    x: '25.156891',
    y: '43.409169',
    servingDays: '1010100',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '37650',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЗАРЕ',
    nameEn: 'KOZARE',
    municipality: 'КАРНОБАТ',
    municipalityEn: 'KARNOBAT',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8493',
    addressNomenclature: '0',
    x: '27.047972',
    y: '42.60477',
    servingDays: '1010100',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '37664',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЗАРЕВЕЦ',
    nameEn: 'KOZAREVETS',
    municipality: 'ЛЯСКОВЕЦ',
    municipalityEn: 'LYASKOVETS',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5148',
    addressNomenclature: '0',
    x: '25.823279',
    y: '43.131894',
    servingDays: '1010100',
    servingOfficeId: '46',
    servingHubOfficeId: '11',
  },
  {
    id: '37678',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЗАРЕВЕЦ',
    nameEn: 'KOZAREVETS',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6070',
    addressNomenclature: '0',
    x: '25.576362',
    y: '42.220999',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '37681',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЗАРЕВО',
    nameEn: 'KOZAREVO',
    municipality: 'ТУНДЖА',
    municipalityEn: 'TUNDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8674',
    addressNomenclature: '0',
    x: '26.594587',
    y: '42.45449',
    servingDays: '0101000',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '37695',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЗАРКА',
    nameEn: 'KOZARKA',
    municipality: 'НЕДЕЛИНО',
    municipalityEn: 'NEDELINO',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4983',
    addressNomenclature: '0',
    x: '25.139917',
    y: '41.456363',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '37705',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЗАРСКО',
    nameEn: 'KOZARSKO',
    municipality: 'БРАЦИГОВО',
    municipalityEn: 'BRATSIGOVO',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4584',
    addressNomenclature: '0',
    x: '24.42196',
    y: '42.059407',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '37722',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЗИ РОГ',
    nameEn: 'KOZI ROG',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5344',
    addressNomenclature: '0',
    x: '25.267489',
    y: '42.956352',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '37736',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЗИЦА',
    nameEn: 'KOZITSA',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6848',
    addressNomenclature: '0',
    x: '25.277625',
    y: '41.450846',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '37748',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЗИЦА',
    nameEn: 'KOZITSA',
    municipality: 'ПОПОВО',
    municipalityEn: 'POPOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7833',
    addressNomenclature: '0',
    x: '26.283329',
    y: '43.443712',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '37753',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЗИЧИНО',
    nameEn: 'KOZICHINO',
    municipality: 'ПОМОРИЕ',
    municipalityEn: 'POMORIE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8224',
    addressNomenclature: '0',
    x: '27.573294',
    y: '42.838373',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '37767',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЗЛЕВО',
    nameEn: 'KOZLEVO',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6865',
    addressNomenclature: '0',
    x: '25.237146',
    y: '41.415509',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '37770',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЗЛЕЦ',
    nameEn: 'KOZLETS',
    municipality: 'ХАСКОВО',
    municipalityEn: 'HASKOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6371',
    addressNomenclature: '0',
    x: '25.465508',
    y: '41.830688',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '37784',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЗЛОВЕЦ',
    nameEn: 'KOZLOVETS',
    municipality: 'СВИЩОВ',
    municipalityEn: 'SVISHTOV',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5290',
    addressNomenclature: '0',
    x: '25.36294',
    y: '43.507829',
    servingDays: '1010100',
    servingOfficeId: '250',
    servingHubOfficeId: '11',
  },
  {
    id: '37798',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'КОЗЛОДУЙ',
    nameEn: 'KOZLODUY',
    municipality: 'КОЗЛОДУЙ',
    municipalityEn: 'KOZLODUY',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3320',
    addressNomenclature: '2',
    x: '23.732107',
    y: '43.775681',
    servingDays: '1111110',
    servingOfficeId: '64',
    servingHubOfficeId: '20',
  },
  {
    id: '37808',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЗЛОДУЙЦИ',
    nameEn: 'KOZLODUYTSI',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9387',
    addressNomenclature: '0',
    x: '27.741684',
    y: '43.630295',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '37811',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЗМА ПРЕЗВИТЕР',
    nameEn: 'KOZMA PREZVITER',
    municipality: 'ОМУРТАГ',
    municipalityEn: 'OMURTAG',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7913',
    addressNomenclature: '0',
    x: '26.459487',
    y: '43.150076',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '37825',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЗНИЦА',
    nameEn: 'KOZNITSA',
    municipality: 'НЕСЕБЪР',
    municipalityEn: 'NESEBAR',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8255',
    addressNomenclature: '0',
    x: '27.676081',
    y: '42.877591',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '37842',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЗЯ РЕКА',
    nameEn: 'KOZYA REKA',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5080',
    addressNomenclature: '0',
    x: '26.048107',
    y: '42.955674',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '37839',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЗЯК',
    nameEn: 'KOZYAK',
    municipality: 'ДУЛОВО',
    municipalityEn: 'DULOVO',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7673',
    addressNomenclature: '0',
    x: '27.199097',
    y: '43.795733',
    servingDays: '0101000',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '37856',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОИЛОВЦИ',
    nameEn: 'KOILOVTSI',
    municipality: 'ПЛЕВЕН',
    municipalityEn: 'PLEVEN',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5842',
    addressNomenclature: '0',
    x: '24.759897',
    y: '43.483584',
    servingDays: '0101000',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '37863',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'КОЙНАРЕ',
    nameEn: 'KOYNARE',
    municipality: 'ЧЕРВЕН БРЯГ',
    municipalityEn: 'CHERVEN BRYAG',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5986',
    addressNomenclature: '0',
    x: '24.143577',
    y: '43.355699',
    servingDays: '1111100',
    servingOfficeId: '59',
    servingHubOfficeId: '20',
  },
  {
    id: '37890',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЙЧОВЦИ',
    nameEn: 'KOYCHOVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5350',
    addressNomenclature: '0',
    x: '25.487464',
    y: '42.854632',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '37914',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОКАЛЯНЕ',
    nameEn: 'KOKALYANE',
    municipality: 'СТОЛИЧНА',
    municipalityEn: 'STOLICHNA',
    region: 'СОФИЯ (СТОЛИЦА)',
    regionEn: 'SOFIA (STOLITSA)',
    postCode: '1191',
    addressNomenclature: '2',
    x: '23.420791',
    y: '42.581795',
    servingDays: '1111100',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '37928',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОКИЧЕ',
    nameEn: 'KOKICHE',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6676',
    addressNomenclature: '0',
    x: '25.569432',
    y: '41.692124',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '37945',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОКОРОВО',
    nameEn: 'KOKOROVO',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4725',
    addressNomenclature: '0',
    x: '24.808686',
    y: '41.598434',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '37959',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОКОРЦИ',
    nameEn: 'KOKORTSI',
    municipality: 'РУДОЗЕМ',
    municipalityEn: 'RUDOZEM',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4971',
    addressNomenclature: '0',
    x: '24.823641',
    y: '41.522386',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '37962',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОКОШАНЕ',
    nameEn: 'KOKOSHANE',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6733',
    addressNomenclature: '0',
    x: '25.270475',
    y: '41.612229',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '37976',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЛАРИ',
    nameEn: 'KOLARI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5084',
    addressNomenclature: '0',
    x: '25.982159',
    y: '42.882827',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '37989',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЛАРОВО',
    nameEn: 'KOLAROVO',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2880',
    addressNomenclature: '0',
    x: '23.108621',
    y: '41.363929',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '37993',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЛАРОВО',
    nameEn: 'KOLAROVO',
    municipality: 'ГЛАВИНИЦА',
    municipalityEn: 'GLAVINITSA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7613',
    addressNomenclature: '0',
    x: '26.882747',
    y: '44.021704',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '38008',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЛАРОВО',
    nameEn: 'KOLAROVO',
    municipality: 'РАДНЕВО',
    municipalityEn: 'RADNEVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6271',
    addressNomenclature: '0',
    x: '25.716734',
    y: '42.334594',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '38011',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЛАРОВО',
    nameEn: 'KOLAROVO',
    municipality: 'ХАРМАНЛИ',
    municipalityEn: 'HARMANLI',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6460',
    addressNomenclature: '0',
    x: '26.022171',
    y: '41.969291',
    servingDays: '1010100',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '38025',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЛАРЦИ',
    nameEn: 'KOLARTSI',
    municipality: 'ТЕРВЕЛ',
    municipalityEn: 'TERVEL',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9481',
    addressNomenclature: '0',
    x: '27.540493',
    y: '43.842874',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '38039',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЛЕНА',
    nameEn: 'KOLENA',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6050',
    addressNomenclature: '0',
    x: '25.722379',
    y: '42.478718',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '38042',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЛЕЦ',
    nameEn: 'KOLETS',
    municipality: 'МИНЕРАЛНИ БАНИ',
    municipalityEn: 'MINERALNI BANI',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6376',
    addressNomenclature: '0',
    x: '25.342884',
    y: '41.85483',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '38056',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЛИБИТЕ',
    nameEn: 'KOLIBITE',
    municipality: 'СТРУМЯНИ',
    municipalityEn: 'STRUMYANI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2838',
    addressNomenclature: '0',
    x: '23.027678',
    y: '41.583012',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '38060',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЛИШОВЦИ',
    nameEn: 'KOLISHOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5343',
    addressNomenclature: '0',
    x: '25.408486',
    y: '42.950798',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '38073',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЛОБЪР',
    nameEn: 'KOLOBAR',
    municipality: 'ДУЛОВО',
    municipalityEn: 'DULOVO',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7675',
    addressNomenclature: '0',
    x: '27.191369',
    y: '43.774224',
    servingDays: '0101000',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '38090',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЛЮ ГАНЕВ',
    nameEn: 'KOLYU GANEV',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5360',
    addressNomenclature: '0',
    x: '25.503555',
    y: '42.824279',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '38100',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЛЮ МАРИНОВО',
    nameEn: 'KOLYU MARINOVO',
    municipality: 'БРАТЯ ДАСКАЛОВИ',
    municipalityEn: 'BRATYA DASKALOVI',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6237',
    addressNomenclature: '0',
    x: '25.159749',
    y: '42.396386',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '38114',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОМАРЕВО',
    nameEn: 'KOMAREVO',
    municipality: 'ПРОВАДИЯ',
    municipalityEn: 'PROVADIA',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9269',
    addressNomenclature: '0',
    x: '27.391037',
    y: '43.116191',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '38128',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОМАРЕВО',
    nameEn: 'KOMAREVO',
    municipality: 'БЯЛА СЛАТИНА',
    municipalityEn: 'BYALA SLATINA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3247',
    addressNomenclature: '0',
    x: '23.865337',
    y: '43.379175',
    servingDays: '1010100',
    servingOfficeId: '59',
    servingHubOfficeId: '20',
  },
  {
    id: '38131',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОМАРЕВО',
    nameEn: 'KOMAREVO',
    municipality: 'БЕРКОВИЦА',
    municipalityEn: 'BERKOVITSA',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3516',
    addressNomenclature: '0',
    x: '23.167505',
    y: '43.280358',
    servingDays: '1111100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '38145',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОМАРЕВО',
    nameEn: 'KOMAREVO',
    municipality: 'ДОЛНА МИТРОПОЛИЯ',
    municipalityEn: 'DOLNA MITROPOLIA',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5853',
    addressNomenclature: '0',
    x: '24.630473',
    y: '43.585319',
    servingDays: '1010100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '38159',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОМОЩИЦА',
    nameEn: 'KOMOSHTITSA',
    municipality: 'ЯКИМОВО',
    municipalityEn: 'YAKIMOVO',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3648',
    addressNomenclature: '0',
    x: '23.310046',
    y: '43.718981',
    servingDays: '0101000',
    servingOfficeId: '831',
    servingHubOfficeId: '20',
  },
  {
    id: '38162',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОМУНАРИ',
    nameEn: 'KOMUNARI',
    municipality: 'ДЪЛГОПОЛ',
    municipalityEn: 'DALGOPOL',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9254',
    addressNomenclature: '0',
    x: '27.292404',
    y: '43.011123',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '38176',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОМУНИГА',
    nameEn: 'KOMUNIGA',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6690',
    addressNomenclature: '0',
    x: '25.225198',
    y: '41.797944',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '38186',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОМЩИЦА',
    nameEn: 'KOMSHTITSA',
    municipality: 'ГОДЕЧ',
    municipalityEn: 'GODECH',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2255',
    addressNomenclature: '0',
    x: '22.993154',
    y: '43.092867',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '38193',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОНАК',
    nameEn: 'KONAK',
    municipality: 'ПОПОВО',
    municipalityEn: 'POPOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7868',
    addressNomenclature: '0',
    x: '26.279665',
    y: '43.218484',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '38203',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОНАРЕ',
    nameEn: 'KONARE',
    municipality: 'ГУРКОВО',
    municipalityEn: 'GURKOVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6189',
    addressNomenclature: '0',
    x: '25.831373',
    y: '42.684815',
    servingDays: '0101000',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '38217',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОНАРЕ',
    nameEn: 'KONARE',
    municipality: 'ГЕНЕРАЛ ТОШЕВО',
    municipalityEn: 'GENERAL TOSHEVO',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9548',
    addressNomenclature: '0',
    x: '28.230493',
    y: '43.587119',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '38220',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОНАРСКО',
    nameEn: 'KONARSKO',
    municipality: 'ЯКОРУДА',
    municipalityEn: 'YAKORUDA',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2792',
    addressNomenclature: '0',
    x: '23.722775',
    y: '42.016565',
    servingDays: '1010100',
    servingOfficeId: '125',
    servingHubOfficeId: '13',
  },
  {
    id: '38234',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОНАРСКОТО',
    nameEn: 'KONARSKOTO',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5364',
    addressNomenclature: '0',
    x: '25.62072',
    y: '42.759898',
    servingDays: '0101000',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '38248',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОНДОВО',
    nameEn: 'KONDOVO',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6593',
    addressNomenclature: '0',
    x: '26.015674',
    y: '41.432968',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '38251',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОНДОЛОВО',
    nameEn: 'KONDOLOVO',
    municipality: 'ЦАРЕВО',
    municipalityEn: 'TSAREVO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8275',
    addressNomenclature: '0',
    x: '27.681058',
    y: '42.093344',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '38265',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОНДОФРЕЙ',
    nameEn: 'KONDOFREY',
    municipality: 'РАДОМИР',
    municipalityEn: 'RADOMIR',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2422',
    addressNomenclature: '0',
    x: '23.006143',
    y: '42.427174',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '38279',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОНЕВЕЦ',
    nameEn: 'KONEVETS',
    municipality: 'ТУНДЖА',
    municipalityEn: 'TUNDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8623',
    addressNomenclature: '0',
    x: '26.56751',
    y: '42.271383',
    servingDays: '0101000',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '38282',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОНЕВО',
    nameEn: 'KONEVO',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6661',
    addressNomenclature: '0',
    x: '25.608714',
    y: '41.673497',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '38296',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОНЕВО',
    nameEn: 'KONEVO',
    municipality: 'ИСПЕРИХ',
    municipalityEn: 'ISPERIH',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7442',
    addressNomenclature: '0',
    x: '26.879721',
    y: '43.777192',
    servingDays: '1010100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '38306',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОНЕВО',
    nameEn: 'KONEVO',
    municipality: 'ВЪРБИЦА',
    municipalityEn: 'VARBITSA',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9887',
    addressNomenclature: '0',
    x: '26.676018',
    y: '43.060128',
    servingDays: '1111100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '38317',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОННИЦИ',
    nameEn: 'KONNITSI',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6588',
    addressNomenclature: '0',
    x: '25.874491',
    y: '41.490807',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '38323',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОНОП',
    nameEn: 'KONOP',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7995',
    addressNomenclature: '0',
    x: '26.338733',
    y: '43.195413',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '41126',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОНСКА',
    nameEn: 'KONSKA',
    municipality: 'БРЕЗНИК',
    municipalityEn: 'BREZNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2379',
    addressNomenclature: '0',
    x: '22.845867',
    y: '42.732709',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '38337',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОНСТАНТИН',
    nameEn: 'KONSTANTIN',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5080',
    addressNomenclature: '0',
    x: '26.066117',
    y: '42.947189',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '38340',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОНСТАНТИНОВЕЦ',
    nameEn: 'KONSTANTINOVETS',
    municipality: 'РАДНЕВО',
    municipalityEn: 'RADNEVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6267',
    addressNomenclature: '0',
    x: '25.890463',
    y: '42.222518',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '38354',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОНСТАНТИНОВО',
    nameEn: 'KONSTANTINOVO',
    municipality: 'ВАРНА',
    municipalityEn: 'VARNA',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9180',
    addressNomenclature: '0',
    x: '27.780643',
    y: '43.162653',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '38368',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОНСТАНТИНОВО',
    nameEn: 'KONSTANTINOVO',
    municipality: 'СИМЕОНОВГРАД',
    municipalityEn: 'SIMEONOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6494',
    addressNomenclature: '0',
    x: '25.793006',
    y: '41.993835',
    servingDays: '0101000',
    servingOfficeId: '42',
    servingHubOfficeId: '5',
  },
  {
    id: '52279',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОНСТАНТИНОВО',
    nameEn: 'KONSTANTINOVO',
    municipality: 'КАМЕНО',
    municipalityEn: 'KAMENO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8129',
    addressNomenclature: '0',
    x: '27.315331',
    y: '42.409747',
    servingDays: '0101010',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '38371',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОНТИЛ',
    nameEn: 'KONTIL',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6841',
    addressNomenclature: '0',
    x: '25.18448',
    y: '41.503691',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '38385',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОНУШ',
    nameEn: 'KONUSH',
    municipality: 'АСЕНОВГРАД',
    municipalityEn: 'ASENOVGRAD',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4268',
    addressNomenclature: '0',
    x: '25.035948',
    y: '42.067037',
    servingDays: '1010100',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '38399',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОНУШ',
    nameEn: 'KONUSH',
    municipality: 'ХАСКОВО',
    municipalityEn: 'HASKOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6370',
    addressNomenclature: '0',
    x: '25.515683',
    y: '41.860889',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '38409',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОНЧЕ',
    nameEn: 'KONCHE',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6828',
    addressNomenclature: '0',
    x: '25.537068',
    y: '41.444712',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '41188',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОНЬОВЕЦ',
    nameEn: 'KONYOVETS',
    municipality: 'ШУМЕН',
    municipalityEn: 'SHUMEN',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9748',
    addressNomenclature: '0',
    x: '26.992467',
    y: '43.363457',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '38426',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОНЬОВО',
    nameEn: 'KONYOVO',
    municipality: 'НОВА ЗАГОРА',
    municipalityEn: 'NOVA ZAGORA',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8920',
    addressNomenclature: '0',
    x: '26.163754',
    y: '42.522717',
    servingDays: '1010100',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '38432',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОНЯВО',
    nameEn: 'KONYAVO',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2540',
    addressNomenclature: '0',
    x: '22.784439',
    y: '42.323286',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '38460',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОПАНИЦА',
    nameEn: 'KOPANITSA',
    municipality: 'РАДОМИР',
    municipalityEn: 'RADOMIR',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2409',
    addressNomenclature: '0',
    x: '22.934226',
    y: '42.570697',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '38474',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОПИЛОВЦИ',
    nameEn: 'KOPILOVTSI',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2543',
    addressNomenclature: '0',
    x: '22.736611',
    y: '42.330257',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '38488',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОПИЛОВЦИ',
    nameEn: 'KOPILOVTSI',
    municipality: 'ГЕОРГИ ДАМЯНОВО',
    municipalityEn: 'GEORGI DAMYANOVO',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3467',
    addressNomenclature: '0',
    x: '22.915003',
    y: '43.330833',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '38491',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОПИТНИК',
    nameEn: 'KOPITNIK',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6698',
    addressNomenclature: '0',
    x: '25.20962',
    y: '41.778756',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '38501',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОПРЕЦ',
    nameEn: 'KOPRETS',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7775',
    addressNomenclature: '0',
    x: '26.608715',
    y: '43.138088',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '38515',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОПРИВА',
    nameEn: 'KOPRIVA',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2567',
    addressNomenclature: '0',
    x: '22.418281',
    y: '42.286018',
    servingDays: '0101000',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '38529',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОПРИВЕЦ',
    nameEn: 'KOPRIVETS',
    municipality: 'БЯЛА',
    municipalityEn: 'BYALA',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7120',
    addressNomenclature: '0',
    x: '25.8793',
    y: '43.409399',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '38532',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОПРИВЛЕН',
    nameEn: 'KOPRIVLEN',
    municipality: 'ХАДЖИДИМОВО',
    municipalityEn: 'HADZHIDIMOVO',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2921',
    addressNomenclature: '0',
    x: '23.795418',
    y: '41.519946',
    servingDays: '1111100',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '38558',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'КОПРИВЩИЦА',
    nameEn: 'KOPRIVSHTITSA',
    municipality: 'КОПРИВЩИЦА',
    municipalityEn: 'KOPRIVSHTITSA',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2077',
    addressNomenclature: '2',
    x: '24.360185',
    y: '42.636161',
    servingDays: '1010100',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '38563',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОПРИНКА',
    nameEn: 'KOPRINKA',
    municipality: 'КАЗАНЛЪК',
    municipalityEn: 'KAZANLAK',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6137',
    addressNomenclature: '0',
    x: '25.330888',
    y: '42.632297',
    servingDays: '1111110',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '38577',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОПЧЕЛИИТЕ',
    nameEn: 'KOPCHELIITE',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5342',
    addressNomenclature: '0',
    x: '25.370776',
    y: '42.904203',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '38580',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОРЕН',
    nameEn: 'KOREN',
    municipality: 'ХАСКОВО',
    municipalityEn: 'HASKOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6392',
    addressNomenclature: '0',
    x: '25.673444',
    y: '41.822823',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '38594',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОРИИТЕ',
    nameEn: 'KORIITE',
    municipality: 'МАДАН',
    municipalityEn: 'MADAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4921',
    addressNomenclature: '0',
    x: '24.942701',
    y: '41.555611',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '38604',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОРИТАТА',
    nameEn: 'KORITATA',
    municipality: 'РУДОЗЕМ',
    municipalityEn: 'RUDOZEM',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4976',
    addressNomenclature: '0',
    x: '24.784855',
    y: '41.409264',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '38618',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОРИТЕН',
    nameEn: 'KORITEN',
    municipality: 'КРУШАРИ',
    municipalityEn: 'KRUSHARI',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9403',
    addressNomenclature: '0',
    x: '27.756224',
    y: '43.926037',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '38621',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОРИЯТА',
    nameEn: 'KORIYATA',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5455',
    addressNomenclature: '0',
    x: '25.029709',
    y: '42.913691',
    servingDays: '1010100',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '38635',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОРКИНА',
    nameEn: 'KORKINA',
    municipality: 'БОБОВ ДОЛ',
    municipalityEn: 'BOBOV DOL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2688',
    addressNomenclature: '0',
    x: '22.955716',
    y: '42.318823',
    servingDays: '1010100',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '38652',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОРМЯНСКО',
    nameEn: 'KORMYANSKO',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5433',
    addressNomenclature: '0',
    x: '25.034531',
    y: '43.056926',
    servingDays: '1111110',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '38666',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОРНИЦА',
    nameEn: 'KORNITSA',
    municipality: 'ГОЦЕ ДЕЛЧЕВ',
    municipalityEn: 'GOTSE DELCHEV',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2970',
    addressNomenclature: '0',
    x: '23.675325',
    y: '41.641435',
    servingDays: '1111100',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '38683',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОРТЕН',
    nameEn: 'KORTEN',
    municipality: 'НОВА ЗАГОРА',
    municipalityEn: 'NOVA ZAGORA',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8930',
    addressNomenclature: '0',
    x: '25.996323',
    y: '42.5446',
    servingDays: '1111100',
    servingOfficeId: '56',
    servingHubOfficeId: '5',
  },
  {
    id: '38697',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОС',
    nameEn: 'KOS',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6820',
    addressNomenclature: '0',
    x: '25.524331',
    y: '41.525305',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '38707',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОСАРА',
    nameEn: 'KOSARA',
    municipality: 'ГЛАВИНИЦА',
    municipalityEn: 'GLAVINITSA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7612',
    addressNomenclature: '0',
    x: '26.80836',
    y: '43.963387',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '38710',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОСАРКА',
    nameEn: 'KOSARKA',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5392',
    addressNomenclature: '0',
    x: '25.552368',
    y: '43.013622',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '38724',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОСАЧА',
    nameEn: 'KOSACHA',
    municipality: 'КОВАЧЕВЦИ',
    municipalityEn: 'KOVACHEVTSI',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2448',
    addressNomenclature: '0',
    x: '22.846803',
    y: '42.562824',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '38738',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОСЕВЦИ',
    nameEn: 'KOSEVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5095',
    addressNomenclature: '0',
    x: '25.804493',
    y: '42.849012',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '38741',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОСИЛКА',
    nameEn: 'KOSILKA',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5380',
    addressNomenclature: '0',
    x: '25.480235',
    y: '42.930154',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '38769',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОСОВЕЦ',
    nameEn: 'KOSOVETS',
    municipality: 'ПОМОРИЕ',
    municipalityEn: 'POMORIE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8223',
    addressNomenclature: '0',
    x: '27.439337',
    y: '42.75502',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '38772',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОСОВО',
    nameEn: 'KOSOVO',
    municipality: 'БРЕГОВО',
    municipalityEn: 'BREGOVO',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3794',
    addressNomenclature: '0',
    x: '22.632932',
    y: '44.0985',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '38786',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОСОВО',
    nameEn: 'KOSOVO',
    municipality: 'ТРЕКЛЯНО',
    municipalityEn: 'TREKLYANO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2557',
    addressNomenclature: '0',
    x: '22.580727',
    y: '42.596339',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '38799',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОСОВО',
    nameEn: 'KOSOVO',
    municipality: 'АСЕНОВГРАД',
    municipalityEn: 'ASENOVGRAD',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4240',
    addressNomenclature: '0',
    x: '24.70175',
    y: '41.91159',
    servingDays: '0101000',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '38804',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОСОВО',
    nameEn: 'KOSOVO',
    municipality: 'КАСПИЧАН',
    municipalityEn: 'KASPICHAN',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9974',
    addressNomenclature: '0',
    x: '27.261389',
    y: '43.20817',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '38858',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОСТА ПЕРЧЕВО',
    nameEn: 'KOSTA PERCHEVO',
    municipality: 'КУЛА',
    municipalityEn: 'KULA',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3833',
    addressNomenclature: '0',
    x: '22.590827',
    y: '43.853351',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '38813',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОСТАДИНИТЕ',
    nameEn: 'KOSTADINITE',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5301',
    addressNomenclature: '0',
    x: '25.362575',
    y: '42.827285',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '41191',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОСТАДИНКИНО',
    nameEn: 'KOSTADINKINO',
    municipality: 'ИХТИМАН',
    municipalityEn: 'IHTIMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2060',
    addressNomenclature: '0',
    x: '23.663155',
    y: '42.536039',
    servingDays: '1010100',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '38830',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОСТАНДЕНЕЦ',
    nameEn: 'KOSTANDENETS',
    municipality: 'ЦАР КАЛОЯН',
    municipalityEn: 'TSAR KALOYAN',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7273',
    addressNomenclature: '0',
    x: '26.194484',
    y: '43.567929',
    servingDays: '0101000',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '38844',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'КОСТАНДОВО',
    nameEn: 'KOSTANDOVO',
    municipality: 'РАКИТОВО',
    municipalityEn: 'RAKITOVO',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4644',
    addressNomenclature: '0',
    x: '24.113009',
    y: '42.023525',
    servingDays: '1111100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '38861',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОСТЕЛ',
    nameEn: 'KOSTEL',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5099',
    addressNomenclature: '0',
    x: '25.995167',
    y: '42.888092',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '38875',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОСТЕЛЕВО',
    nameEn: 'KOSTELEVO',
    municipality: 'ВРАЦА',
    municipalityEn: 'VRATSA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3047',
    addressNomenclature: '0',
    x: '23.611412',
    y: '43.214249',
    servingDays: '1010100',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '38889',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОСТЕН',
    nameEn: 'KOSTEN',
    municipality: 'СУНГУРЛАРЕ',
    municipalityEn: 'SUNGURLARE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8441',
    addressNomenclature: '0',
    x: '26.954759',
    y: '42.782513',
    servingDays: '0101000',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '38892',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОСТЕНА РЕКА',
    nameEn: 'KOSTENA REKA',
    municipality: 'ШУМЕН',
    municipalityEn: 'SHUMEN',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9806',
    addressNomenclature: '0',
    x: '27.142652',
    y: '43.181221',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '38902',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'КОСТЕНЕЦ',
    nameEn: 'KOSTENETS',
    municipality: 'КОСТЕНЕЦ',
    municipalityEn: 'KOSTENETS',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2030',
    addressNomenclature: '2',
    x: '23.858291',
    y: '42.310692',
    servingDays: '1111100',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '38916',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОСТЕНЕЦ',
    nameEn: 'KOSTENETS',
    municipality: 'КОСТЕНЕЦ',
    municipalityEn: 'KOSTENETS',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2042',
    addressNomenclature: '2',
    x: '23.823079',
    y: '42.264497',
    servingDays: '1111100',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '38920',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОСТЕНКОВЦИ',
    nameEn: 'KOSTENKOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5343',
    addressNomenclature: '0',
    x: '25.39803',
    y: '42.951406',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '38933',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОСТЕНЦИ',
    nameEn: 'KOSTENTSI',
    municipality: 'БЕРКОВИЦА',
    municipalityEn: 'BERKOVITSA',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3518',
    addressNomenclature: '0',
    x: '23.121516',
    y: '43.284087',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '38947',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОСТИ',
    nameEn: 'KOSTI',
    municipality: 'ЦАРЕВО',
    municipalityEn: 'TSAREVO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8283',
    addressNomenclature: '0',
    x: '27.778704',
    y: '42.059331',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '38950',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОСТИЕВО',
    nameEn: 'KOSTIEVO',
    municipality: 'МАРИЦА',
    municipalityEn: 'MARITSA',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4205',
    addressNomenclature: '0',
    x: '24.623773',
    y: '42.178024',
    servingDays: '1111100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '38964',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОСТИЛКОВО',
    nameEn: 'KOSTILKOVO',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6593',
    addressNomenclature: '0',
    x: '26.04846',
    y: '41.419462',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '38978',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'КОСТИНБРОД',
    nameEn: 'KOSTINBROD',
    municipality: 'КОСТИНБРОД',
    municipalityEn: 'KOSTINBROD',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2230',
    addressNomenclature: '2',
    x: '23.216793',
    y: '42.810132',
    servingDays: '1111110',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '38981',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОСТИНО',
    nameEn: 'KOSTINO',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6743',
    addressNomenclature: '0',
    x: '25.300836',
    y: '41.701218',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '38995',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОСТИЧОВЦИ',
    nameEn: 'KOSTICHOVTSI',
    municipality: 'ДИМОВО',
    municipalityEn: 'DIMOVO',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3755',
    addressNomenclature: '0',
    x: '22.779507',
    y: '43.715131',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '39001',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОСТУР',
    nameEn: 'KOSTUR',
    municipality: 'СВИЛЕНГРАД',
    municipalityEn: 'SVILENGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6533',
    addressNomenclature: '0',
    x: '26.28657',
    y: '41.97332',
    servingDays: '0101000',
    servingOfficeId: '48',
    servingHubOfficeId: '5',
  },
  {
    id: '39013',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОСТУРИНО',
    nameEn: 'KOSTURINO',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6884',
    addressNomenclature: '0',
    x: '25.301174',
    y: '41.349845',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '39027',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОСТУРИНЦИ',
    nameEn: 'KOSTURINTSI',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2489',
    addressNomenclature: '0',
    x: '22.576454',
    y: '42.764957',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '39030',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'КОТЕЛ',
    nameEn: 'KOTEL',
    municipality: 'КОТЕЛ',
    municipalityEn: 'KOTEL',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8970',
    addressNomenclature: '2',
    x: '26.447121',
    y: '42.887982',
    servingDays: '1111100',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '39044',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОТЕНОВЦИ',
    nameEn: 'KOTENOVTSI',
    municipality: 'БЕРКОВИЦА',
    municipalityEn: 'BERKOVITSA',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3522',
    addressNomenclature: '0',
    x: '23.07282',
    y: '43.287057',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '39058',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОТЛАРИ',
    nameEn: 'KOTLARI',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6957',
    addressNomenclature: '0',
    x: '25.744591',
    y: '41.621474',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '39061',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОТЛЕНЦИ',
    nameEn: 'KOTLENTSI',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9374',
    addressNomenclature: '0',
    x: '27.917729',
    y: '43.570879',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '39075',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОТУЦИ',
    nameEn: 'KOTUTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5097',
    addressNomenclature: '0',
    x: '25.901256',
    y: '42.867023',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '39089',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЧАН',
    nameEn: 'KOCHAN',
    municipality: 'САТОВЧА',
    municipalityEn: 'SATOVCHA',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2955',
    addressNomenclature: '0',
    x: '24.027699',
    y: '41.586903',
    servingDays: '1111100',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '39092',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЧАНИ',
    nameEn: 'KOCHANI',
    municipality: 'НЕДЕЛИНО',
    municipalityEn: 'NEDELINO',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4999',
    addressNomenclature: '0',
    x: '25.127157',
    y: '41.469275',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '39102',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЧЕВО',
    nameEn: 'KOCHEVO',
    municipality: 'САДОВО',
    municipalityEn: 'SADOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4119',
    addressNomenclature: '0',
    x: '24.924388',
    y: '42.101263',
    servingDays: '0101010',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '39116',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'КОЧЕРИНОВО',
    nameEn: 'KOCHERINOVO',
    municipality: 'КОЧЕРИНОВО',
    municipalityEn: 'KOCHERINOVO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2640',
    addressNomenclature: '2',
    x: '23.05647',
    y: '42.085784',
    servingDays: '1111100',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '39127',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЧМАР',
    nameEn: 'KOCHMAR',
    municipality: 'ТЕРВЕЛ',
    municipalityEn: 'TERVEL',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9477',
    addressNomenclature: '0',
    x: '27.455818',
    y: '43.690591',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '39133',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОЧОВО',
    nameEn: 'KOCHOVO',
    municipality: 'ВЕЛИКИ ПРЕСЛАВ',
    municipalityEn: 'VELIKI PRESLAV',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9862',
    addressNomenclature: '0',
    x: '26.800701',
    y: '43.22006',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '39147',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОШАВА',
    nameEn: 'KOSHAVA',
    municipality: 'ВИДИН',
    municipalityEn: 'VIDIN',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3771',
    addressNomenclature: '0',
    x: '23.030783',
    y: '44.071636',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '39150',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОШАРЕВО',
    nameEn: 'KOSHAREVO',
    municipality: 'БРЕЗНИК',
    municipalityEn: 'BREZNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2390',
    addressNomenclature: '0',
    x: '22.772663',
    y: '42.672584',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '41251',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОШАРИТЕ',
    nameEn: 'KOSHARITE',
    municipality: 'РАДОМИР',
    municipalityEn: 'RADOMIR',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2453',
    addressNomenclature: '0',
    x: '22.953316',
    y: '42.489191',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '39164',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОШАРИЦА',
    nameEn: 'KOSHARITSA',
    municipality: 'НЕСЕБЪР',
    municipalityEn: 'NESEBAR',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8253',
    addressNomenclature: '2',
    x: '27.695091',
    y: '42.751793',
    servingDays: '1111110',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '39178',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОШАРНА',
    nameEn: 'KOSHARNA',
    municipality: 'СЛИВО ПОЛЕ',
    municipalityEn: 'SLIVO POLE',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7062',
    addressNomenclature: '0',
    x: '26.29804',
    y: '43.882771',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '39181',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОШНИЦА',
    nameEn: 'KOSHNITSA',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4778',
    addressNomenclature: '0',
    x: '24.678995',
    y: '41.514908',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '39195',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОШНИЧАРИ',
    nameEn: 'KOSHNICHARI',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7783',
    addressNomenclature: '0',
    x: '26.393525',
    y: '43.274637',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '39205',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КОШОВ',
    nameEn: 'KOSHOV',
    municipality: 'ИВАНОВО',
    municipalityEn: 'IVANOVO',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7087',
    addressNomenclature: '0',
    x: '25.9927',
    y: '43.658534',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '39222',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРАВИНО',
    nameEn: 'KRAVINO',
    municipality: 'ОПАН',
    municipalityEn: 'OPAN',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6075',
    addressNomenclature: '0',
    x: '25.755086',
    y: '42.222499',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '39236',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРАВОДЕР',
    nameEn: 'KRAVODER',
    municipality: 'КРИВОДОЛ',
    municipalityEn: 'KRIVODOL',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3048',
    addressNomenclature: '0',
    x: '23.410216',
    y: '43.303486',
    servingDays: '0101000',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '39242',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРАГУЛЕВО',
    nameEn: 'KRAGULEVO',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9418',
    addressNomenclature: '0',
    x: '27.658287',
    y: '43.80181',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '39267',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРАЕВО',
    nameEn: 'KRAEVO',
    municipality: 'БОТЕВГРАД',
    municipalityEn: 'BOTEVGRAD',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2149',
    addressNomenclature: '0',
    x: '23.607336',
    y: '42.982108',
    servingDays: '0101000',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '39270',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРАИЩЕ',
    nameEn: 'KRAISHTE',
    municipality: 'БЕЛИЦА',
    municipalityEn: 'BELITSA',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2781',
    addressNomenclature: '0',
    x: '23.585224',
    y: '41.912859',
    servingDays: '1111100',
    servingOfficeId: '125',
    servingHubOfficeId: '13',
  },
  {
    id: '39284',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРАИЩЕ',
    nameEn: 'KRAISHTE',
    municipality: 'ГЕНЕРАЛ ТОШЕВО',
    municipalityEn: 'GENERAL TOSHEVO',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9535',
    addressNomenclature: '0',
    x: '27.981248',
    y: '43.851113',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '39298',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРАЙГОРЦИ',
    nameEn: 'KRAYGORTSI',
    municipality: 'ВЪРБИЦА',
    municipalityEn: 'VARBITSA',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9875',
    addressNomenclature: '0',
    x: '26.579372',
    y: '42.973528',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '39311',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРАЙНА',
    nameEn: 'KRAYNA',
    municipality: 'МАДАН',
    municipalityEn: 'MADAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4900',
    addressNomenclature: '0',
    x: '24.918317',
    y: '41.481325',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '41277',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРАЙНА',
    nameEn: 'KRAYNA',
    municipality: 'НЕДЕЛИНО',
    municipalityEn: 'NEDELINO',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4990',
    addressNomenclature: '0',
    x: '25.131298',
    y: '41.434681',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '39325',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРАЙНИ ДОЛ',
    nameEn: 'KRAYNI DOL',
    municipality: 'ДУПНИЦА',
    municipalityEn: 'DUPNITSA',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2649',
    addressNomenclature: '0',
    x: '23.087791',
    y: '42.197677',
    servingDays: '1111110',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '39339',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРАЙНИЦИ',
    nameEn: 'KRAYNITSI',
    municipality: 'ДУПНИЦА',
    municipalityEn: 'DUPNITSA',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2633',
    addressNomenclature: '0',
    x: '23.201217',
    y: '42.319859',
    servingDays: '1111110',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '39342',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРАЙНО СЕЛО',
    nameEn: 'KRAYNO SELO',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6739',
    addressNomenclature: '0',
    x: '25.341414',
    y: '41.642927',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '39356',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРАЙНОВО',
    nameEn: 'KRAYNOVO',
    municipality: 'БОЛЯРОВО',
    municipalityEn: 'BOLYAROVO',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8752',
    addressNomenclature: '0',
    x: '26.861027',
    y: '42.004077',
    servingDays: '1010100',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '39368',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРАЙПОЛЕ',
    nameEn: 'KRAYPOLE',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7997',
    addressNomenclature: '0',
    x: '26.106595',
    y: '43.066901',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '39373',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРАКРА',
    nameEn: 'KRAKRA',
    municipality: 'ВЪЛЧИ ДОЛ',
    municipalityEn: 'VALCHI DOL',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9287',
    addressNomenclature: '0',
    x: '27.478565',
    y: '43.417014',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '39387',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРАЛЕВ ДОЛ',
    nameEn: 'KRALEV DOL',
    municipality: 'ПЕРНИК',
    municipalityEn: 'PERNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2349',
    addressNomenclature: '0',
    x: '23.093689',
    y: '42.560468',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '39390',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРАЛЕВО',
    nameEn: 'KRALEVO',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7770',
    addressNomenclature: '0',
    x: '26.702958',
    y: '43.190242',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '39400',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРАЛЕВО',
    nameEn: 'KRALEVO',
    municipality: 'СТАМБОЛОВО',
    municipalityEn: 'STAMBOLOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6362',
    addressNomenclature: '0',
    x: '25.677706',
    y: '41.781307',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '39428',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРАЛИ МАРКО',
    nameEn: 'KRALI MARKO',
    municipality: 'ПАЗАРДЖИК',
    municipalityEn: 'PAZARDZHIK',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4452',
    addressNomenclature: '0',
    x: '24.401324',
    y: '42.256033',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '39431',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРАМОЛИН',
    nameEn: 'KRAMOLIN',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5429',
    addressNomenclature: '0',
    x: '25.078151',
    y: '43.139252',
    servingDays: '0101000',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '39445',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРАН',
    nameEn: 'KRAN',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6898',
    addressNomenclature: '0',
    x: '25.491911',
    y: '41.340115',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '39459',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРАНЕВО',
    nameEn: 'KRANEVO',
    municipality: 'БАЛЧИК',
    municipalityEn: 'BALCHIK',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9649',
    addressNomenclature: '2',
    x: '28.053805',
    y: '43.341849',
    servingDays: '1111110',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '39462',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРАНОВО',
    nameEn: 'KRANOVO',
    municipality: 'КАЙНАРДЖА',
    municipalityEn: 'KAYNARDZHA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7549',
    addressNomenclature: '0',
    x: '27.631611',
    y: '44.006991',
    servingDays: '0101000',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '39483',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРАПЕЦ',
    nameEn: 'KRAPETS',
    municipality: 'МЕЗДРА',
    municipalityEn: 'MEZDRA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3149',
    addressNomenclature: '0',
    x: '23.653196',
    y: '43.194453',
    servingDays: '1010100',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '39493',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРАПЕЦ',
    nameEn: 'KRAPETS',
    municipality: 'ШАБЛА',
    municipalityEn: 'SHABLA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9674',
    addressNomenclature: '0',
    x: '28.566596',
    y: '43.625007',
    servingDays: '1111100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '39503',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРАПЧЕНЕ',
    nameEn: 'KRAPCHENE',
    municipality: 'МОНТАНА',
    municipalityEn: 'MONTANA',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3434',
    addressNomenclature: '0',
    x: '23.302306',
    y: '43.385376',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '39517',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРАСАВА',
    nameEn: 'KRASAVA',
    municipality: 'БРЕЗНИК',
    municipalityEn: 'BREZNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2370',
    addressNomenclature: '0',
    x: '22.870189',
    y: '42.795413',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '39520',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРАСЕН',
    nameEn: 'KRASEN',
    municipality: 'ИВАНОВО',
    municipalityEn: 'IVANOVO',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7075',
    addressNomenclature: '0',
    x: '25.980104',
    y: '43.739029',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '39534',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРАСЕН',
    nameEn: 'KRASEN',
    municipality: 'ГЕНЕРАЛ ТОШЕВО',
    municipalityEn: 'GENERAL TOSHEVO',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9534',
    addressNomenclature: '0',
    x: '27.92664',
    y: '43.842312',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '39548',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРАСЕН ДОЛ',
    nameEn: 'KRASEN DOL',
    municipality: 'НИКОЛА КОЗЛЕВО',
    municipalityEn: 'NIKOLA KOZLEVO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9935',
    addressNomenclature: '0',
    x: '27.267845',
    y: '43.503885',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '39551',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРАСИМИР',
    nameEn: 'KRASIMIR',
    municipality: 'ДЪЛГОПОЛ',
    municipalityEn: 'DALGOPOL',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9258',
    addressNomenclature: '0',
    x: '27.305601',
    y: '43.034972',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '39565',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРАСИНО',
    nameEn: 'KRASINO',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6962',
    addressNomenclature: '0',
    x: '25.681872',
    y: '41.568884',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '39582',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРАСНО ГРАДИЩЕ',
    nameEn: 'KRASNO GRADISHTE',
    municipality: 'СУХИНДОЛ',
    municipalityEn: 'SUHINDOL',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5242',
    addressNomenclature: '0',
    x: '25.228575',
    y: '43.182233',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '39579',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРАСНОВО',
    nameEn: 'KRASNOVO',
    municipality: 'ХИСАРЯ',
    municipalityEn: 'HISARYA',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4178',
    addressNomenclature: '0',
    x: '24.482914',
    y: '42.467556',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '39596',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРАСНОСЕЛЦИ',
    nameEn: 'KRASNOSELTSI',
    municipality: 'ОМУРТАГ',
    municipalityEn: 'OMURTAG',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7925',
    addressNomenclature: '0',
    x: '26.481373',
    y: '43.088577',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '39606',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРАЧИМИР',
    nameEn: 'KRACHIMIR',
    municipality: 'БЕЛОГРАДЧИК',
    municipalityEn: 'BELOGRADCHIK',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3964',
    addressNomenclature: '0',
    x: '22.557106',
    y: '43.583782',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '39614',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРЕМЕН',
    nameEn: 'KREMEN',
    municipality: 'БАНСКО',
    municipalityEn: 'BANSKO',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2771',
    addressNomenclature: '0',
    x: '23.640703',
    y: '41.745012',
    servingDays: '0101000',
    servingOfficeId: '125',
    servingHubOfficeId: '13',
  },
  {
    id: '41174',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРЕМЕН',
    nameEn: 'KREMEN',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6870',
    addressNomenclature: '0',
    x: '25.331475',
    y: '41.292069',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '39623',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРЕМЕНА',
    nameEn: 'KREMENA',
    municipality: 'БАЛЧИК',
    municipalityEn: 'BALCHIK',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9639',
    addressNomenclature: '0',
    x: '28.128518',
    y: '43.551013',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '39637',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРЕМЕНЕ',
    nameEn: 'KREMENE',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4755',
    addressNomenclature: '0',
    x: '24.628175',
    y: '41.537934',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '80131',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРЕМЕНЕЦ',
    nameEn: 'KREMENETS',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6822',
    addressNomenclature: '0',
    x: '25.488189',
    y: '41.46601',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '39640',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРЕМЕНИК',
    nameEn: 'KREMENIK',
    municipality: 'ДУПНИЦА',
    municipalityEn: 'DUPNITSA',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2653',
    addressNomenclature: '0',
    x: '23.069082',
    y: '42.364163',
    servingDays: '0101000',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '39668',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРЕПОСТ',
    nameEn: 'KREPOST',
    municipality: 'ДИМИТРОВГРАД',
    municipalityEn: 'DIMITROVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6410',
    addressNomenclature: '0',
    x: '25.589967',
    y: '42.008083',
    servingDays: '1010100',
    servingOfficeId: '42',
    servingHubOfficeId: '5',
  },
  {
    id: '39671',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРЕПЧА',
    nameEn: 'KREPCHA',
    municipality: 'ОПАКА',
    municipalityEn: 'OPAKA',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7845',
    addressNomenclature: '0',
    x: '26.13687',
    y: '43.481333',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '39685',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРЕСЛЮВЦИ',
    nameEn: 'KRESLYUVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5367',
    addressNomenclature: '0',
    x: '25.531397',
    y: '42.799722',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '14492',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'КРЕСНА',
    nameEn: 'KRESNA',
    municipality: 'КРЕСНА',
    municipalityEn: 'KRESNA',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2840',
    addressNomenclature: '2',
    x: '23.160802',
    y: '41.720171',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '39709',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРЕТА',
    nameEn: 'KRETA',
    municipality: 'МЕЗДРА',
    municipalityEn: 'MEZDRA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3127',
    addressNomenclature: '0',
    x: '23.698985',
    y: '43.123935',
    servingDays: '1010100',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '39712',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРЕТА',
    nameEn: 'KRETA',
    municipality: 'ГУЛЯНЦИ',
    municipalityEn: 'GULYANTSI',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5961',
    addressNomenclature: '0',
    x: '24.674391',
    y: '43.599716',
    servingDays: '1010100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '39726',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРИБУЛ',
    nameEn: 'KRIBUL',
    municipality: 'САТОВЧА',
    municipalityEn: 'SATOVCHA',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2951',
    addressNomenclature: '0',
    x: '23.946871',
    y: '41.572387',
    servingDays: '0101000',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '39730',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРИВА БАРА',
    nameEn: 'KRIVA BARA',
    municipality: 'КОЗЛОДУЙ',
    municipalityEn: 'KOZLODUY',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3328',
    addressNomenclature: '0',
    x: '23.709676',
    y: '43.63754',
    servingDays: '0101000',
    servingOfficeId: '64',
    servingHubOfficeId: '20',
  },
  {
    id: '39743',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРИВА БАРА',
    nameEn: 'KRIVA BARA',
    municipality: 'БРУСАРЦИ',
    municipalityEn: 'BRUSARTSI',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3669',
    addressNomenclature: '0',
    x: '23.079531',
    y: '43.692014',
    servingDays: '1010100',
    servingOfficeId: '831',
    servingHubOfficeId: '20',
  },
  {
    id: '39757',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРИВА КРУША',
    nameEn: 'KRIVA KRUSHA',
    municipality: 'НОВА ЗАГОРА',
    municipalityEn: 'NOVA ZAGORA',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8936',
    addressNomenclature: '0',
    x: '25.883077',
    y: '42.543731',
    servingDays: '1010100',
    servingOfficeId: '56',
    servingHubOfficeId: '5',
  },
  {
    id: '39760',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРИВА РЕКА',
    nameEn: 'KRIVA REKA',
    municipality: 'НИКОЛА КОЗЛЕВО',
    municipalityEn: 'NIKOLA KOZLEVO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9938',
    addressNomenclature: '0',
    x: '27.139961',
    y: '43.503231',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '39788',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРИВИНА',
    nameEn: 'KRIVINA',
    municipality: 'ЦЕНОВО',
    municipalityEn: 'TSENOVO',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7132',
    addressNomenclature: '0',
    x: '25.585514',
    y: '43.626202',
    servingDays: '1010100',
    servingOfficeId: '250',
    servingHubOfficeId: '11',
  },
  {
    id: '39791',
    countryId: '100',
    mainSiteId: '68134',
    type: 'с.',
    typeEn: 's.',
    name: 'КРИВИНА',
    nameEn: 'KRIVINA',
    municipality: 'СТОЛИЧНА',
    municipalityEn: 'STOLICHNA',
    region: 'СОФИЯ (СТОЛИЦА)',
    regionEn: 'SOFIA (STOLITSA)',
    postCode: '1588',
    addressNomenclature: '2',
    x: '23.464777',
    y: '42.67916',
    servingDays: '1111100',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '39801',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРИВИНИ',
    nameEn: 'KRIVINI',
    municipality: 'ДОЛНИ ЧИФЛИК',
    municipalityEn: 'DOLNI CHIFLIK',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9123',
    addressNomenclature: '0',
    x: '27.671344',
    y: '42.951571',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '39815',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРИВИЦА',
    nameEn: 'KRIVITSA',
    municipality: 'САМУИЛ',
    municipalityEn: 'SAMUIL',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7456',
    addressNomenclature: '0',
    x: '26.787821',
    y: '43.513555',
    servingDays: '1010100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '39829',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРИВНЯ',
    nameEn: 'KRIVNYA',
    municipality: 'ПРОВАДИЯ',
    municipalityEn: 'PROVADIA',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9217',
    addressNomenclature: '0',
    x: '27.397008',
    y: '43.19511',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '39832',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРИВНЯ',
    nameEn: 'KRIVNYA',
    municipality: 'ВЕТОВО',
    municipalityEn: 'VETOVO',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7037',
    addressNomenclature: '0',
    x: '26.3323',
    y: '43.650563',
    servingDays: '0101000',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '39863',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРИВО ПОЛЕ',
    nameEn: 'KRIVO POLE',
    municipality: 'ХАСКОВО',
    municipalityEn: 'HASKOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6387',
    addressNomenclature: '0',
    x: '25.711373',
    y: '41.840104',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '39846',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'КРИВОДОЛ',
    nameEn: 'KRIVODOL',
    municipality: 'КРИВОДОЛ',
    municipalityEn: 'KRIVODOL',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3060',
    addressNomenclature: '2',
    x: '23.479883',
    y: '43.374292',
    servingDays: '1111100',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '39855',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРИВОНОС',
    nameEn: 'KRIVONOS',
    municipality: 'БРЕЗНИК',
    municipalityEn: 'BREZNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2386',
    addressNomenclature: '0',
    x: '22.755961',
    y: '42.739682',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '39877',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРИЛАТИЦА',
    nameEn: 'KRILATITSA',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6884',
    addressNomenclature: '0',
    x: '25.343805',
    y: '41.355203',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '39880',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРИЛЮВЦИ',
    nameEn: 'KRILYUVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5097',
    addressNomenclature: '0',
    x: '25.893727',
    y: '42.874035',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '39894',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРИН',
    nameEn: 'KRIN',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6671',
    addressNomenclature: '0',
    x: '25.549524',
    y: '41.744363',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '39918',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРИСЛОВО',
    nameEn: 'KRISLOVO',
    municipality: 'МАРИЦА',
    municipalityEn: 'MARITSA',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4148',
    addressNomenclature: '0',
    x: '24.794517',
    y: '42.241158',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '39921',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'КРИЧИМ',
    nameEn: 'KRICHIM',
    municipality: 'КРИЧИМ',
    municipalityEn: 'KRICHIM',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4220',
    addressNomenclature: '2',
    x: '24.466843',
    y: '42.043672',
    servingDays: '1111110',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '39935',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРОМИДОВО',
    nameEn: 'KROMIDOVO',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2871',
    addressNomenclature: '0',
    x: '23.364402',
    y: '41.458017',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '39949',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРОЯЧ',
    nameEn: 'KROYACH',
    municipality: 'ЛОЗНИЦА',
    municipalityEn: 'LOZNITSA',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7278',
    addressNomenclature: '0',
    x: '26.661095',
    y: '43.385832',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '39952',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРОЯЧЕВО',
    nameEn: 'KROYACHEVO',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6766',
    addressNomenclature: '0',
    x: '25.143902',
    y: '41.613402',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '39966',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРУМ',
    nameEn: 'KRUM',
    municipality: 'ДИМИТРОВГРАД',
    municipalityEn: 'DIMITROVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6438',
    addressNomenclature: '0',
    x: '25.503576',
    y: '42.05826',
    servingDays: '0101000',
    servingOfficeId: '42',
    servingHubOfficeId: '5',
  },
  {
    id: '39970',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'КРУМОВГРАД',
    nameEn: 'KRUMOVGRAD',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6900',
    addressNomenclature: '2',
    x: '25.65508',
    y: '41.471572',
    servingDays: '1111100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '39983',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРУМОВО',
    nameEn: 'KRUMOVO',
    municipality: 'АКСАКОВО',
    municipalityEn: 'AKSAKOVO',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9149',
    addressNomenclature: '0',
    x: '27.784483',
    y: '43.390209',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '39997',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРУМОВО',
    nameEn: 'KRUMOVO',
    municipality: 'КОЧЕРИНОВО',
    municipalityEn: 'KOCHERINOVO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2627',
    addressNomenclature: '0',
    x: '23.0219',
    y: '42.077828',
    servingDays: '1111100',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '40004',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРУМОВО',
    nameEn: 'KRUMOVO',
    municipality: 'РОДОПИ',
    municipalityEn: 'RODOPI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4112',
    addressNomenclature: '0',
    x: '24.820872',
    y: '42.085723',
    servingDays: '1111110',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '40018',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРУМОВО',
    nameEn: 'KRUMOVO',
    municipality: 'ТУНДЖА',
    municipalityEn: 'TUNDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8649',
    addressNomenclature: '0',
    x: '26.404687',
    y: '42.272054',
    servingDays: '1010100',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '40021',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРУМОВО ГРАДИЩЕ',
    nameEn: 'KRUMOVO GRADISHTE',
    municipality: 'КАРНОБАТ',
    municipalityEn: 'KARNOBAT',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8487',
    addressNomenclature: '0',
    x: '26.928144',
    y: '42.610906',
    servingDays: '1010100',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '40035',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРУМЧЕВЦИ',
    nameEn: 'KRUMCHEVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5080',
    addressNomenclature: '0',
    x: '26.113456',
    y: '42.927483',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '40049',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРУПЕН',
    nameEn: 'KRUPEN',
    municipality: 'КАВАРНА',
    municipalityEn: 'KAVARNA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9667',
    addressNomenclature: '0',
    x: '28.26362',
    y: '43.552429',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '40052',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРУПНИК',
    nameEn: 'KRUPNIK',
    municipality: 'СИМИТЛИ',
    municipalityEn: 'SIMITLI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2740',
    addressNomenclature: '0',
    x: '23.121486',
    y: '41.847',
    servingDays: '1111100',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '40066',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРУША',
    nameEn: 'KRUSHA',
    municipality: 'АВРЕН',
    municipalityEn: 'AVREN',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9121',
    addressNomenclature: '0',
    x: '27.753685',
    y: '43.030906',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '40070',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРУША',
    nameEn: 'KRUSHA',
    municipality: 'ДРАГОМАН',
    municipalityEn: 'DRAGOMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2213',
    addressNomenclature: '0',
    x: '22.776644',
    y: '42.895879',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '40083',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРУШАРЕ',
    nameEn: 'KRUSHARE',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8877',
    addressNomenclature: '0',
    x: '26.373852',
    y: '42.563204',
    servingDays: '1010100',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '40097',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРУШАРИ',
    nameEn: 'KRUSHARI',
    municipality: 'КРУШАРИ',
    municipalityEn: 'KRUSHARI',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9410',
    addressNomenclature: '0',
    x: '27.756125',
    y: '43.816356',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '40110',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРУШЕВ ДОЛ',
    nameEn: 'KRUSHEV DOL',
    municipality: 'МАДАН',
    municipalityEn: 'MADAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4919',
    addressNomenclature: '0',
    x: '24.939081',
    y: '41.444398',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '40124',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРУШЕВЕЦ',
    nameEn: 'KRUSHEVETS',
    municipality: 'СОЗОПОЛ',
    municipalityEn: 'SOZOPOL',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8148',
    addressNomenclature: '0',
    x: '27.486',
    y: '42.273413',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '40138',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРУШЕВО',
    nameEn: 'KRUSHEVO',
    municipality: 'ГЪРМЕН',
    municipalityEn: 'GARMEN',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2945',
    addressNomenclature: '0',
    x: '23.876982',
    y: '41.601084',
    servingDays: '0101000',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '40141',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРУШЕВО',
    nameEn: 'KRUSHEVO',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5427',
    addressNomenclature: '0',
    x: '25.150897',
    y: '43.055343',
    servingDays: '0101000',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '40155',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРУШЕВО',
    nameEn: 'KRUSHEVO',
    municipality: 'ПЪРВОМАЙ',
    municipalityEn: 'PARVOMAY',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4291',
    addressNomenclature: '0',
    x: '25.230529',
    y: '42.139269',
    servingDays: '1010100',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '40169',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРУШЕВСКА',
    nameEn: 'KRUSHEVSKA',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6744',
    addressNomenclature: '0',
    x: '25.297656',
    y: '41.706167',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '40172',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРУШЕТО',
    nameEn: 'KRUSHETO',
    municipality: 'ГОРНА ОРЯХОВИЦА',
    municipalityEn: 'GORNA ORYAHOVITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5134',
    addressNomenclature: '0',
    x: '25.674989',
    y: '43.24136',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '40186',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРУШКА',
    nameEn: 'KRUSHKA',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6743',
    addressNomenclature: '0',
    x: '25.274012',
    y: '41.703166',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '40195',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРУШОВЕНЕ',
    nameEn: 'KRUSHOVENE',
    municipality: 'ДОЛНА МИТРОПОЛИЯ',
    municipalityEn: 'DOLNA MITROPOLIA',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5860',
    addressNomenclature: '0',
    x: '24.399623',
    y: '43.643479',
    servingDays: '0101000',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '40200',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРУШОВИЦА',
    nameEn: 'KRUSHOVITSA',
    municipality: 'МИЗИЯ',
    municipalityEn: 'MIZIA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3349',
    addressNomenclature: '0',
    x: '23.849152',
    y: '43.625125',
    servingDays: '1010100',
    servingOfficeId: '64',
    servingHubOfficeId: '20',
  },
  {
    id: '40213',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРУШОВИЦА',
    nameEn: 'KRUSHOVITSA',
    municipality: 'ДОЛНИ ДЪБНИК',
    municipalityEn: 'DOLNI DABNIK',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5881',
    addressNomenclature: '0',
    x: '24.415263',
    y: '43.348994',
    servingDays: '1010100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '40227',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРУШОВИЦА',
    nameEn: 'KRUSHOVITSA',
    municipality: 'ЕЛИН ПЕЛИН',
    municipalityEn: 'ELIN PELIN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2112',
    addressNomenclature: '0',
    x: '23.637906',
    y: '42.560725',
    servingDays: '1010100',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '40230',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРУШОВО',
    nameEn: 'KRUSHOVO',
    municipality: 'КАРНОБАТ',
    municipalityEn: 'KARNOBAT',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8482',
    addressNomenclature: '0',
    x: '27.056515',
    y: '42.543455',
    servingDays: '1010100',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '40244',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРУШОВО',
    nameEn: 'KRUSHOVO',
    municipality: 'ЛЪКИ',
    municipalityEn: 'LAKI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4241',
    addressNomenclature: '0',
    x: '24.869608',
    y: '41.772563',
    servingDays: '0101000',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '40258',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРУШОЛАК',
    nameEn: 'KRUSHOLAK',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7962',
    addressNomenclature: '0',
    x: '26.188447',
    y: '43.039383',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '40261',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРУШУНА',
    nameEn: 'KRUSHUNA',
    municipality: 'ЛЕТНИЦА',
    municipalityEn: 'LETNITSA',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5561',
    addressNomenclature: '0',
    x: '25.03495',
    y: '43.253943',
    servingDays: '0101000',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '40275',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРЪВЕНИК',
    nameEn: 'KRAVENIK',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5460',
    addressNomenclature: '0',
    x: '25.009207',
    y: '42.846994',
    servingDays: '1010100',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '40289',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРЪКОЖАБЕНЕ',
    nameEn: 'KRAKOZHABENE',
    municipality: 'ТЕТЕВЕН',
    municipalityEn: 'TETEVEN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '',
    addressNomenclature: '0',
    x: '24.126311',
    y: '42.944778',
    servingDays: '0101000',
    servingOfficeId: '88',
    servingHubOfficeId: '2',
  },
  {
    id: '40292',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'КРЪН',
    nameEn: 'KRAN',
    municipality: 'КАЗАНЛЪК',
    municipalityEn: 'KAZANLAK',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6140',
    addressNomenclature: '0',
    x: '25.379638',
    y: '42.666793',
    servingDays: '1111100',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '40302',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРЪНДЖИЛИЦА',
    nameEn: 'KRANDZHILITSA',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2875',
    addressNomenclature: '0',
    x: '23.056176',
    y: '41.540274',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '40316',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРЪНЧА',
    nameEn: 'KRANCHA',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5370',
    addressNomenclature: '0',
    x: '25.481946',
    y: '43.001334',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '41136',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРЪСТАВА',
    nameEn: 'KRASTAVA',
    municipality: 'ВЕЛИНГРАД',
    municipalityEn: 'VELINGRAD',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4647',
    addressNomenclature: '0',
    x: '23.820347',
    y: '41.961194',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '40326',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРЪСТАТИЦА',
    nameEn: 'KRASTATITSA',
    municipality: 'БАНИТЕ',
    municipalityEn: 'BANITE',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4741',
    addressNomenclature: '0',
    x: '24.958481',
    y: '41.639573',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '40333',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРЪСТЕВИЧ',
    nameEn: 'KRASTEVICH',
    municipality: 'ХИСАРЯ',
    municipalityEn: 'HISARYA',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4177',
    addressNomenclature: '0',
    x: '24.434102',
    y: '42.482021',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '40350',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРЪСТЕНЯЦИТЕ',
    nameEn: 'KRASTENYATSITE',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5350',
    addressNomenclature: '0',
    x: '25.462587',
    y: '42.894173',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '40364',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРЪСТЕЦ',
    nameEn: 'KRASTETS',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5364',
    addressNomenclature: '0',
    x: '25.554272',
    y: '42.7672',
    servingDays: '0101000',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '40378',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРЪСТИЛЦИ',
    nameEn: 'KRASTILTSI',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2805',
    addressNomenclature: '0',
    x: '23.203813',
    y: '41.54983',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '40381',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРЪСТИНА',
    nameEn: 'KRASTINA',
    municipality: 'КАМЕНО',
    municipalityEn: 'KAMENO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8121',
    addressNomenclature: '0',
    x: '27.237552',
    y: '42.58073',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '40395',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КРЪШНО',
    nameEn: 'KRASHNO',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7786',
    addressNomenclature: '0',
    x: '26.433755',
    y: '43.359661',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '40419',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КУБАДИН',
    nameEn: 'KUBADIN',
    municipality: 'СРЕДЕЦ',
    municipalityEn: 'SREDETS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8335',
    addressNomenclature: '0',
    x: '26.947512',
    y: '42.305981',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '40422',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'КУБРАТ',
    nameEn: 'KUBRAT',
    municipality: 'КУБРАТ',
    municipalityEn: 'KUBRAT',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7300',
    addressNomenclature: '2',
    x: '26.500061',
    y: '43.79649',
    servingDays: '1111100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '40436',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КУБРАТОВО',
    nameEn: 'KUBRATOVO',
    municipality: 'СТОЛИЧНА',
    municipalityEn: 'STOLICHNA',
    region: 'СОФИЯ (СТОЛИЦА)',
    regionEn: 'SOFIA (STOLITSA)',
    postCode: '1257',
    addressNomenclature: '2',
    x: '23.358984',
    y: '42.772774',
    servingDays: '1111100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '40441',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КУДЕЛИН',
    nameEn: 'KUDELIN',
    municipality: 'БРЕГОВО',
    municipalityEn: 'BREGOVO',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3798',
    addressNomenclature: '0',
    x: '22.669428',
    y: '44.193064',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '40453',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КУЗЬОВО',
    nameEn: 'KUZYOVO',
    municipality: 'БЕЛИЦА',
    municipalityEn: 'BELITSA',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2788',
    addressNomenclature: '0',
    x: '23.655541',
    y: '41.938745',
    servingDays: '1010100',
    servingOfficeId: '125',
    servingHubOfficeId: '13',
  },
  {
    id: '40467',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'КУКЛЕН',
    nameEn: 'KUKLEN',
    municipality: 'КУКЛЕН',
    municipalityEn: 'KUKLEN',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4101',
    addressNomenclature: '2',
    x: '24.787805',
    y: '42.034303',
    servingDays: '1111110',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '40470',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КУКЛЯ',
    nameEn: 'KUKLYA',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5370',
    addressNomenclature: '0',
    x: '25.531362',
    y: '42.925352',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '40484',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КУКОРЕВО',
    nameEn: 'KUKOREVO',
    municipality: 'ТУНДЖА',
    municipalityEn: 'TUNDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8673',
    addressNomenclature: '0',
    x: '26.533074',
    y: '42.447716',
    servingDays: '1111110',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '40498',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КУКУВИЦА',
    nameEn: 'KUKUVITSA',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4710',
    addressNomenclature: '0',
    x: '24.615401',
    y: '41.701181',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '40508',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КУКУРАХЦЕВО',
    nameEn: 'KUKURAHTSEVO',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2882',
    addressNomenclature: '0',
    x: '22.99634',
    y: '41.469238',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '40511',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КУКУРЯК',
    nameEn: 'KUKURYAK',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6898',
    addressNomenclature: '0',
    x: '25.478883',
    y: '41.333046',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '40525',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'КУЛА',
    nameEn: 'KULA',
    municipality: 'КУЛА',
    municipalityEn: 'KULA',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3800',
    addressNomenclature: '2',
    x: '22.52134',
    y: '43.887489',
    servingDays: '1111100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '40539',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КУЛАТА',
    nameEn: 'KULATA',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2868',
    addressNomenclature: '0',
    x: '23.363423',
    y: '41.389256',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '40573',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КУЛИНА ВОДА',
    nameEn: 'KULINA VODA',
    municipality: 'БЕЛЕНЕ',
    municipalityEn: 'BELENE',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5937',
    addressNomenclature: '0',
    x: '25.03282',
    y: '43.586874',
    servingDays: '0101000',
    servingOfficeId: '108',
    servingHubOfficeId: '11',
  },
  {
    id: '40587',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КУМАНИТЕ',
    nameEn: 'KUMANITE',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5380',
    addressNomenclature: '0',
    x: '25.454102',
    y: '42.906787',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '40590',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КУМАНОВО',
    nameEn: 'KUMANOVO',
    municipality: 'АКСАКОВО',
    municipalityEn: 'AKSAKOVO',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9153',
    addressNomenclature: '0',
    x: '27.938972',
    y: '43.271112',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '40628',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КУНДЕВО',
    nameEn: 'KUNDEVO',
    municipality: 'НЕДЕЛИНО',
    municipalityEn: 'NEDELINO',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4990',
    addressNomenclature: '0',
    x: '25.097784',
    y: '41.510462',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '40645',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КУНИНО',
    nameEn: 'KUNINO',
    municipality: 'РОМАН',
    municipalityEn: 'ROMAN',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3140',
    addressNomenclature: '0',
    x: '23.996887',
    y: '43.187782',
    servingDays: '0101000',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '40659',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КУПЕН',
    nameEn: 'KUPEN',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5463',
    addressNomenclature: '0',
    x: '25.117708',
    y: '42.850376',
    servingDays: '1010100',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '40662',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КУПЕН',
    nameEn: 'KUPEN',
    municipality: 'МАДАН',
    municipalityEn: 'MADAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4921',
    addressNomenclature: '0',
    x: '24.976935',
    y: '41.606707',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '40676',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КУПЦИТЕ',
    nameEn: 'KUPTSITE',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6850',
    addressNomenclature: '0',
    x: '25.291923',
    y: '41.55271',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '40693',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КУРНОВО',
    nameEn: 'KURNOVO',
    municipality: 'РОМАН',
    municipalityEn: 'ROMAN',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3138',
    addressNomenclature: '0',
    x: '23.850246',
    y: '43.074525',
    servingDays: '0101000',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '40703',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КУРТОВО',
    nameEn: 'KURTOVO',
    municipality: 'КАРЛОВО',
    municipalityEn: 'KARLOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4369',
    addressNomenclature: '0',
    x: '24.905859',
    y: '42.591242',
    servingDays: '1010100',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '40717',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КУРТОВО КОНАРЕ',
    nameEn: 'KURTOVO KONARE',
    municipality: 'СТАМБОЛИЙСКИ',
    municipalityEn: 'STAMBOLIYSKI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4223',
    addressNomenclature: '0',
    x: '24.496943',
    y: '42.092865',
    servingDays: '1111100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '40748',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КУТЕЛА',
    nameEn: 'KUTELA',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4752',
    addressNomenclature: '0',
    x: '24.83009',
    y: '41.657271',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '40751',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КУТЛОВИЦА',
    nameEn: 'KUTLOVITSA',
    municipality: 'АЛФАТАР',
    municipalityEn: 'ALFATAR',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7558',
    addressNomenclature: '0',
    x: '27.37027',
    y: '43.88603',
    servingDays: '0101000',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '41263',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КУТОВО',
    nameEn: 'KUTOVO',
    municipality: 'ВИДИН',
    municipalityEn: 'VIDIN',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3767',
    addressNomenclature: '0',
    x: '22.964238',
    y: '44.029543',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '40765',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КУТУГЕРЦИ',
    nameEn: 'KUTUGERTSI',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2567',
    addressNomenclature: '0',
    x: '22.486343',
    y: '42.324222',
    servingDays: '0101000',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '40779',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КУЦАРОВЦИ',
    nameEn: 'KUTSAROVTSI',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5042',
    addressNomenclature: '0',
    x: '25.722716',
    y: '42.942351',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '40782',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КУЦИНА',
    nameEn: 'KUTSINA',
    municipality: 'ПОЛСКИ ТРЪМБЕШ',
    municipalityEn: 'POLSKI TRAMBESH',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5189',
    addressNomenclature: '0',
    x: '25.643408',
    y: '43.265289',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '40796',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КУЦОВО',
    nameEn: 'KUTSOVO',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6697',
    addressNomenclature: '0',
    x: '25.220225',
    y: '41.764639',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '40806',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КУШЛА',
    nameEn: 'KUSHLA',
    municipality: 'ЗЛАТОГРАД',
    municipalityEn: 'ZLATOGRAD',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4986',
    addressNomenclature: '0',
    x: '25.16491',
    y: '41.320063',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '40823',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЪКЛИЦА',
    nameEn: 'KAKLITSA',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6927',
    addressNomenclature: '0',
    x: '25.635277',
    y: '41.396652',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '40837',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЪКРИНА',
    nameEn: 'KAKRINA',
    municipality: 'ЛОВЕЧ',
    municipalityEn: 'LOVECH',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5544',
    addressNomenclature: '0',
    x: '24.888016',
    y: '43.12364',
    servingDays: '0101000',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '40840',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЪЛНОВО',
    nameEn: 'KALNOVO',
    municipality: 'СМЯДОВО',
    municipalityEn: 'SMYADOVO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9821',
    addressNomenclature: '0',
    x: '27.040382',
    y: '43.096633',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '40868',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЪНЧЕВО',
    nameEn: 'KANCHEVO',
    municipality: 'КАЗАНЛЪК',
    municipalityEn: 'KAZANLAK',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6164',
    addressNomenclature: '0',
    x: '25.449693',
    y: '42.543863',
    servingDays: '0101000',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '40871',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЪПИНЕЦ',
    nameEn: 'KAPINETS',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7997',
    addressNomenclature: '0',
    x: '26.144771',
    y: '43.066319',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '36107',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЪПИНОВО',
    nameEn: 'KAPINOVO',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5043',
    addressNomenclature: '0',
    x: '25.786435',
    y: '43.016832',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '40885',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЪПИНОВО',
    nameEn: 'KAPINOVO',
    municipality: 'ГЕНЕРАЛ ТОШЕВО',
    municipalityEn: 'GENERAL TOSHEVO',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9528',
    addressNomenclature: '0',
    x: '27.988559',
    y: '43.748176',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '40899',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЪПИНОВЦИ',
    nameEn: 'KAPINOVTSI',
    municipality: 'ИСПЕРИХ',
    municipalityEn: 'ISPERIH',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7434',
    addressNomenclature: '0',
    x: '26.910367',
    y: '43.684105',
    servingDays: '1010100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '40909',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'КЪРДЖАЛИ',
    nameEn: 'KARDZHALI',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6600',
    addressNomenclature: '1',
    x: '25.376249',
    y: '41.638955',
    servingDays: '1111110',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '40926',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЪРЛАНОВО',
    nameEn: 'KARLANOVO',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2820',
    addressNomenclature: '0',
    x: '23.417358',
    y: '41.544259',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '40912',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЪРНАЛОВО',
    nameEn: 'KARNALOVO',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2860',
    addressNomenclature: '0',
    x: '23.220253',
    y: '41.463282',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '40939',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЪРНАРЕ',
    nameEn: 'KARNARE',
    municipality: 'КАРЛОВО',
    municipalityEn: 'KARLOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4337',
    addressNomenclature: '0',
    x: '24.632984',
    y: '42.70119',
    servingDays: '1111100',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '40943',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЪРПАЧЕВО',
    nameEn: 'KARPACHEVO',
    municipality: 'ЛЕТНИЦА',
    municipalityEn: 'LETNITSA',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5558',
    addressNomenclature: '0',
    x: '25.005622',
    y: '43.223431',
    servingDays: '0101000',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '40957',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЪРПЕЛЕВО',
    nameEn: 'KARPELEVO',
    municipality: 'СТРУМЯНИ',
    municipalityEn: 'STRUMYANI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2825',
    addressNomenclature: '0',
    x: '23.118561',
    y: '41.643159',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '40960',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЪРТИПЪНЯ',
    nameEn: 'KARTIPANYA',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5380',
    addressNomenclature: '0',
    x: '25.467019',
    y: '42.927484',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '40974',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЪРТОЖАБЕНЕ',
    nameEn: 'KARTOZHABENE',
    municipality: 'ПЛЕВЕН',
    municipalityEn: 'PLEVEN',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5883',
    addressNomenclature: '0',
    x: '24.531752',
    y: '43.359455',
    servingDays: '1010100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '40988',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЪРЧОВСКО',
    nameEn: 'KARCHOVSKO',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6873',
    addressNomenclature: '0',
    x: '25.352162',
    y: '41.411472',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '40991',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЪРШАЛЕВО',
    nameEn: 'KARSHALEVO',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2563',
    addressNomenclature: '0',
    x: '22.548792',
    y: '42.359362',
    servingDays: '0101000',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '41006',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЪСАК',
    nameEn: 'KASAK',
    municipality: 'ДОСПАТ',
    municipalityEn: 'DOSPAT',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4832',
    addressNomenclature: '0',
    x: '24.208204',
    y: '41.634071',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '41010',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЪТИНА',
    nameEn: 'KATINA',
    municipality: 'СТОЛИЧНА',
    municipalityEn: 'STOLICHNA',
    region: 'СОФИЯ (СТОЛИЦА)',
    regionEn: 'SOFIA (STOLITSA)',
    postCode: '1276',
    addressNomenclature: '2',
    x: '23.321904',
    y: '42.843679',
    servingDays: '1111100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '41037',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЪШИН',
    nameEn: 'KASHIN',
    municipality: 'ПЛЕВЕН',
    municipalityEn: 'PLEVEN',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5885',
    addressNomenclature: '0',
    x: '24.583629',
    y: '43.37518',
    servingDays: '0101000',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '41040',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЪШЛЕ',
    nameEn: 'KASHLE',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2492',
    addressNomenclature: '0',
    x: '22.493977',
    y: '42.706962',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '41054',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЬОЛМЕН',
    nameEn: 'KYOLMEN',
    municipality: 'ВЪРБИЦА',
    municipalityEn: 'VARBITSA',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9888',
    addressNomenclature: '0',
    x: '26.677912',
    y: '43.038313',
    servingDays: '1111100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '41085',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЬОСЕВО',
    nameEn: 'KYOSEVO',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6728',
    addressNomenclature: '0',
    x: '25.304753',
    y: '41.606562',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '41099',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЬОСЕВЦИ',
    nameEn: 'KYOSEVTSI',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7951',
    addressNomenclature: '0',
    x: '26.265235',
    y: '43.107125',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '41109',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'КЮЛЕВЧА',
    nameEn: 'KYULEVCHA',
    municipality: 'КАСПИЧАН',
    municipalityEn: 'KASPICHAN',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9972',
    addressNomenclature: '0',
    x: '27.110911',
    y: '43.256083',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '41112',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'КЮСТЕНДИЛ',
    nameEn: 'KYUSTENDIL',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2500',
    addressNomenclature: '2',
    x: '22.695067',
    y: '42.281123',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '43013',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛАГЕРИТЕ',
    nameEn: 'LAGERITE',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5035',
    addressNomenclature: '0',
    x: '25.667247',
    y: '42.805469',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '43027',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛАГОШЕВЦИ',
    nameEn: 'LAGOSHEVTSI',
    municipality: 'ДИМОВО',
    municipalityEn: 'DIMOVO',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3743',
    addressNomenclature: '0',
    x: '22.776515',
    y: '43.774076',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '43030',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛАДАРЕВО',
    nameEn: 'LADAREVO',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2811',
    addressNomenclature: '0',
    x: '23.343131',
    y: '41.550991',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '43044',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛАЗАРОВО',
    nameEn: 'LAZAROVO',
    municipality: 'КНЕЖА',
    municipalityEn: 'KNEZHA',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5836',
    addressNomenclature: '0',
    x: '24.089307',
    y: '43.417975',
    servingDays: '1010100',
    servingOfficeId: '59',
    servingHubOfficeId: '20',
  },
  {
    id: '43061',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛАЗАРЦИ',
    nameEn: 'LAZARTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5097',
    addressNomenclature: '0',
    x: '25.9102',
    y: '42.885661',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '43089',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛАКАРЕВО',
    nameEn: 'LAKAREVO',
    municipality: 'ТРОЯН',
    municipalityEn: 'TROYAN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '',
    addressNomenclature: '0',
    x: '24.87034',
    y: '42.931351',
    servingDays: '1010100',
    servingOfficeId: '44',
    servingHubOfficeId: '11',
  },
  {
    id: '43092',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛАКАТНИК',
    nameEn: 'LAKATNIK',
    municipality: 'СВОГЕ',
    municipalityEn: 'SVOGE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2273',
    addressNomenclature: '0',
    x: '23.408222',
    y: '43.052785',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '43102',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛАЛЕ',
    nameEn: 'LALE',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6823',
    addressNomenclature: '0',
    x: '25.548287',
    y: '41.519617',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '43116',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛАЛКОВО',
    nameEn: 'LALKOVO',
    municipality: 'ЕЛХОВО',
    municipalityEn: 'ELHOVO',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8749',
    addressNomenclature: '0',
    x: '26.718579',
    y: '42.075892',
    servingDays: '1010100',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '43128',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛАМБУХ',
    nameEn: 'LAMBUH',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6583',
    addressNomenclature: '0',
    x: '26.116447',
    y: '41.589526',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '43147',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛАСКАР',
    nameEn: 'LASKAR',
    municipality: 'ПЛЕВЕН',
    municipalityEn: 'PLEVEN',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5876',
    addressNomenclature: '0',
    x: '24.602222',
    y: '43.279205',
    servingDays: '0101000',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '43150',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛАСКАРЕВО',
    nameEn: 'LASKAREVO',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2811',
    addressNomenclature: '0',
    x: '23.333441',
    y: '41.542258',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '43164',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛАТИНКА',
    nameEn: 'LATINKA',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6767',
    addressNomenclature: '0',
    x: '25.105065',
    y: '41.632629',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '43178',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕБЕД',
    nameEn: 'LEBED',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6859',
    addressNomenclature: '0',
    x: '25.202123',
    y: '41.467463',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '43181',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕБНИЦА',
    nameEn: 'LEBNITSA',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2806',
    addressNomenclature: '0',
    x: '23.241249',
    y: '41.522361',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '43195',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕВА РЕКА',
    nameEn: 'LEVA REKA',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2494',
    addressNomenclature: '0',
    x: '22.576723',
    y: '42.66797',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '44906',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕВИЩЕ',
    nameEn: 'LEVISHTE',
    municipality: 'СВОГЕ',
    municipalityEn: 'SVOGE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2276',
    addressNomenclature: '0',
    x: '23.464199',
    y: '43.088459',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '43205',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕВКА',
    nameEn: 'LEVKA',
    municipality: 'СВИЛЕНГРАД',
    municipalityEn: 'SVILENGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6540',
    addressNomenclature: '0',
    x: '26.267598',
    y: '41.866947',
    servingDays: '0101000',
    servingOfficeId: '48',
    servingHubOfficeId: '5',
  },
  {
    id: '43219',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕВОЧЕВО',
    nameEn: 'LEVOCHEVO',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4743',
    addressNomenclature: '0',
    x: '24.740114',
    y: '41.608015',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '22681',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕВСКИ',
    nameEn: 'LEVSKI',
    municipality: 'ПАНАГЮРИЩЕ',
    municipalityEn: 'PANAGYURISHTE',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4535',
    addressNomenclature: '0',
    x: '24.281528',
    y: '42.36467',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '43222',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕВСКИ',
    nameEn: 'LEVSKI',
    municipality: 'СУВОРОВО',
    municipalityEn: 'SUVOROVO',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9171',
    addressNomenclature: '0',
    x: '27.666417',
    y: '43.315346',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '43236',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ЛЕВСКИ',
    nameEn: 'LEVSKI',
    municipality: 'ЛЕВСКИ',
    municipalityEn: 'LEVSKI',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5900',
    addressNomenclature: '2',
    x: '25.143409',
    y: '43.361518',
    servingDays: '1111100',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '43243',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕВУНОВО',
    nameEn: 'LEVUNOVO',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2817',
    addressNomenclature: '0',
    x: '23.306463',
    y: '41.486404',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '44896',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕВЦИ',
    nameEn: 'LEVTSI',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6781',
    addressNomenclature: '0',
    x: '25.192193',
    y: '41.639434',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '43253',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕДЕНИК',
    nameEn: 'LEDENIK',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5049',
    addressNomenclature: '0',
    x: '25.546773',
    y: '43.080817',
    servingDays: '1111110',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '43267',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕЛИНЦИ',
    nameEn: 'LELINTSI',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2588',
    addressNomenclature: '0',
    x: '22.745652',
    y: '42.233719',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '43270',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕНИЩЕ',
    nameEn: 'LENISHTE',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6783',
    addressNomenclature: '0',
    x: '25.203268',
    y: '41.572145',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '43284',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕНКОВО',
    nameEn: 'LENKOVO',
    municipality: 'ГУЛЯНЦИ',
    municipalityEn: 'GULYANTSI',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5967',
    addressNomenclature: '0',
    x: '24.711076',
    y: '43.579327',
    servingDays: '1010100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '43298',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕНОВО',
    nameEn: 'LENOVO',
    municipality: 'АСЕНОВГРАД',
    municipalityEn: 'ASENOVGRAD',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4261',
    addressNomenclature: '0',
    x: '25.081387',
    y: '41.947645',
    servingDays: '0101000',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '43308',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕНСКО',
    nameEn: 'LENSKO',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6595',
    addressNomenclature: '0',
    x: '25.964586',
    y: '41.436466',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '43311',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕПИЦА',
    nameEn: 'LEPITSA',
    municipality: 'ЧЕРВЕН БРЯГ',
    municipalityEn: 'CHERVEN BRYAG',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5987',
    addressNomenclature: '0',
    x: '24.031927',
    y: '43.326564',
    servingDays: '1010100',
    servingOfficeId: '59',
    servingHubOfficeId: '20',
  },
  {
    id: '43325',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕСИДРЕН',
    nameEn: 'LESIDREN',
    municipality: 'УГЪРЧИН',
    municipalityEn: 'UGARCHIN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5520',
    addressNomenclature: '0',
    x: '24.40416',
    y: '42.974698',
    servingDays: '1010100',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '43339',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕСИЧАРКА',
    nameEn: 'LESICHARKA',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5343',
    addressNomenclature: '0',
    x: '25.400237',
    y: '42.943798',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '43342',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕСИЧЕ',
    nameEn: 'LESICHE',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5085',
    addressNomenclature: '0',
    x: '26.13328',
    y: '42.939638',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '43356',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕСИЧЕРИ',
    nameEn: 'LESICHERI',
    municipality: 'ПАВЛИКЕНИ',
    municipalityEn: 'PAVLIKENI',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5222',
    addressNomenclature: '0',
    x: '25.420995',
    y: '43.217773',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '43369',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕСИЧОВО',
    nameEn: 'LESICHOVO',
    municipality: 'ЛЕСИЧОВО',
    municipalityEn: 'LESICHOVO',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4463',
    addressNomenclature: '0',
    x: '24.11177',
    y: '42.355593',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '43373',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕСКА',
    nameEn: 'LESKA',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2547',
    addressNomenclature: '0',
    x: '22.531699',
    y: '42.311291',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '43387',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕСКА',
    nameEn: 'LESKA',
    municipality: 'МАДАН',
    municipalityEn: 'MADAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4928',
    addressNomenclature: '0',
    x: '24.961383',
    y: '41.541927',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '43390',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕСКОВДОЛ',
    nameEn: 'LESKOVDOL',
    municipality: 'СВОГЕ',
    municipalityEn: 'SVOGE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2268',
    addressNomenclature: '0',
    x: '23.437136',
    y: '42.977386',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '43400',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕСКОВЕЦ',
    nameEn: 'LESKOVETS',
    municipality: 'ОРЯХОВО',
    municipalityEn: 'ORYAHOVO',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3341',
    addressNomenclature: '0',
    x: '24.01441',
    y: '43.708259',
    servingDays: '1010100',
    servingOfficeId: '64',
    servingHubOfficeId: '20',
  },
  {
    id: '43414',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕСКОВЕЦ',
    nameEn: 'LESKOVETS',
    municipality: 'БЕРКОВИЦА',
    municipalityEn: 'BERKOVITSA',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3523',
    addressNomenclature: '0',
    x: '23.076524',
    y: '43.317929',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '43428',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕСКОВЕЦ',
    nameEn: 'LESKOVETS',
    municipality: 'ПЕРНИК',
    municipalityEn: 'PERNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2342',
    addressNomenclature: '0',
    x: '22.914183',
    y: '42.592262',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '43445',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕСНОВО',
    nameEn: 'LESNOVO',
    municipality: 'ЕЛИН ПЕЛИН',
    municipalityEn: 'ELIN PELIN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2119',
    addressNomenclature: '0',
    x: '23.640223',
    y: '42.64309',
    servingDays: '1010100',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '43459',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕСОВО',
    nameEn: 'LESOVO',
    municipality: 'ЕЛХОВО',
    municipalityEn: 'ELHOVO',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8754',
    addressNomenclature: '0',
    x: '26.570532',
    y: '41.993461',
    servingDays: '1010100',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '43462',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕСУРА',
    nameEn: 'LESURA',
    municipality: 'КРИВОДОЛ',
    municipalityEn: 'KRIVODOL',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3073',
    addressNomenclature: '0',
    x: '23.562414',
    y: '43.462775',
    servingDays: '0101000',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '43476',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ЛЕТНИЦА',
    nameEn: 'LETNITSA',
    municipality: 'ЛЕТНИЦА',
    municipalityEn: 'LETNITSA',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5570',
    addressNomenclature: '2',
    x: '25.055754',
    y: '43.307818',
    servingDays: '1111100',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '43484',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕТНИЦА',
    nameEn: 'LETNITSA',
    municipality: 'ДРАГОМАН',
    municipalityEn: 'DRAGOMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2207',
    addressNomenclature: '0',
    x: '22.929653',
    y: '42.956902',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '43493',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕТОВНИК',
    nameEn: 'LETOVNIK',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6810',
    addressNomenclature: '0',
    x: '25.41855',
    y: '41.588562',
    servingDays: '1111110',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '43503',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕХОВО',
    nameEn: 'LEHOVO',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2816',
    addressNomenclature: '0',
    x: '23.487149',
    y: '41.410614',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '43517',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕХЧЕВО',
    nameEn: 'LEHCHEVO',
    municipality: 'БОЙЧИНОВЦИ',
    municipalityEn: 'BOYCHINOVTSI',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3445',
    addressNomenclature: '0',
    x: '23.534143',
    y: '43.532106',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '43520',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕШКО',
    nameEn: 'LESHKO',
    municipality: 'БЛАГОЕВГРАД',
    municipalityEn: 'BLAGOEVGRAD',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2749',
    addressNomenclature: '0',
    x: '22.975048',
    y: '41.932399',
    servingDays: '1111110',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '43534',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕШКО ПРЕСОИ',
    nameEn: 'LESHKO PRESOI',
    municipality: 'ТРОЯН',
    municipalityEn: 'TROYAN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '',
    addressNomenclature: '0',
    x: '24.79944',
    y: '42.809564',
    servingDays: '1010100',
    servingOfficeId: '44',
    servingHubOfficeId: '11',
  },
  {
    id: '43548',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕШНИКОВО',
    nameEn: 'LESHNIKOVO',
    municipality: 'ХАРМАНЛИ',
    municipalityEn: 'HARMANLI',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6466',
    addressNomenclature: '0',
    x: '25.911243',
    y: '41.843573',
    servingDays: '0101000',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '43551',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕШНИКОВЦИ',
    nameEn: 'LESHNIKOVTSI',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2481',
    addressNomenclature: '0',
    x: '22.536643',
    y: '42.779901',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '43565',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕШНИЦА',
    nameEn: 'LESHNITSA',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2808',
    addressNomenclature: '0',
    x: '23.287785',
    y: '41.534313',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '43579',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕШНИЦА',
    nameEn: 'LESHNITSA',
    municipality: 'ЛОВЕЧ',
    municipalityEn: 'LOVECH',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5578',
    addressNomenclature: '0',
    x: '24.69574',
    y: '43.006737',
    servingDays: '1010100',
    servingOfficeId: '44',
    servingHubOfficeId: '11',
  },
  {
    id: '43582',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕЩАК',
    nameEn: 'LESHTAK',
    municipality: 'МАДАН',
    municipalityEn: 'MADAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4929',
    addressNomenclature: '0',
    x: '24.933514',
    y: '41.544247',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '43596',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕЩАРКА',
    nameEn: 'LESHTARKA',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6967',
    addressNomenclature: '0',
    x: '25.544986',
    y: '41.384208',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '43606',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕЩЕН',
    nameEn: 'LESHTEN',
    municipality: 'ГЪРМЕН',
    municipalityEn: 'GARMEN',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2962',
    addressNomenclature: '0',
    x: '23.828769',
    y: '41.636984',
    servingDays: '0101000',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '43615',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЕЯРОВО',
    nameEn: 'LEYAROVO',
    municipality: 'СТРАЛДЖА',
    municipalityEn: 'STRALDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8656',
    addressNomenclature: '0',
    x: '26.761421',
    y: '42.300825',
    servingDays: '0101000',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '43623',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛИВАДА',
    nameEn: 'LIVADA',
    municipality: 'КАМЕНО',
    municipalityEn: 'KAMENO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8107',
    addressNomenclature: '0',
    x: '27.22069',
    y: '42.446112',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '43637',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛИВАДЕ',
    nameEn: 'LIVADE',
    municipality: 'МАДАН',
    municipalityEn: 'MADAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4921',
    addressNomenclature: '0',
    x: '24.960834',
    y: '41.576176',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '43654',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛИК',
    nameEn: 'LIK',
    municipality: 'МЕЗДРА',
    municipalityEn: 'MEZDRA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3168',
    addressNomenclature: '0',
    x: '23.766357',
    y: '43.09336',
    servingDays: '0101000',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '43668',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛИЛЕКОВО',
    nameEn: 'LILEKOVO',
    municipality: 'ЧЕПЕЛАРЕ',
    municipalityEn: 'CHEPELARE',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4858',
    addressNomenclature: '0',
    x: '24.723598',
    y: '41.785479',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '43671',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛИЛКОВО',
    nameEn: 'LILKOVO',
    municipality: 'РОДОПИ',
    municipalityEn: 'RODOPI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4105',
    addressNomenclature: '0',
    x: '24.586838',
    y: '41.911169',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '43685',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛИЛЯК',
    nameEn: 'LILYAK',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7760',
    addressNomenclature: '0',
    x: '26.479001',
    y: '43.229649',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '43699',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛИЛЯНОВО',
    nameEn: 'LILYANOVO',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2809',
    addressNomenclature: '0',
    x: '23.318661',
    y: '41.61778',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '43709',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛИЛЯЧ',
    nameEn: 'LILYACH',
    municipality: 'НЕВЕСТИНО',
    municipalityEn: 'NEVESTINO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2597',
    addressNomenclature: '0',
    x: '22.865374',
    y: '42.291493',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '43712',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛИЛЯЧЕ',
    nameEn: 'LILYACHE',
    municipality: 'ВРАЦА',
    municipalityEn: 'VRATSA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3051',
    addressNomenclature: '0',
    x: '23.522001',
    y: '43.321542',
    servingDays: '0101000',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '43726',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛИМЕЦ',
    nameEn: 'LIMETS',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6936',
    addressNomenclature: '0',
    x: '25.627008',
    y: '41.374155',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '43730',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛИПЕН',
    nameEn: 'LIPEN',
    municipality: 'МОНТАНА',
    municipalityEn: 'MONTANA',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3489',
    addressNomenclature: '0',
    x: '23.388018',
    y: '43.38986',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '43743',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛИПЕЦ',
    nameEn: 'LIPETS',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4784',
    addressNomenclature: '0',
    x: '24.733055',
    y: '41.482985',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '43757',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛИПИНЦИ',
    nameEn: 'LIPINTSI',
    municipality: 'ДРАГОМАН',
    municipalityEn: 'DRAGOMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2212',
    addressNomenclature: '0',
    x: '22.866887',
    y: '43.011658',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '43760',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛИПНИК',
    nameEn: 'LIPNIK',
    municipality: 'РАЗГРАД',
    municipalityEn: 'RAZGRAD',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7242',
    addressNomenclature: '0',
    x: '26.53069',
    y: '43.588833',
    servingDays: '1010100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '43774',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛИПНИЦА',
    nameEn: 'LIPNITSA',
    municipality: 'МИЗИЯ',
    municipalityEn: 'MIZIA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3352',
    addressNomenclature: '0',
    x: '23.818132',
    y: '43.591401',
    servingDays: '1010100',
    servingOfficeId: '64',
    servingHubOfficeId: '20',
  },
  {
    id: '43788',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛИПНИЦА',
    nameEn: 'LIPNITSA',
    municipality: 'БОТЕВГРАД',
    municipalityEn: 'BOTEVGRAD',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2158',
    addressNomenclature: '0',
    x: '23.755647',
    y: '43.02938',
    servingDays: '0101000',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '43815',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛИСЕЦ',
    nameEn: 'LISETS',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2547',
    addressNomenclature: '0',
    x: '22.564717',
    y: '42.3086',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '43829',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛИСЕЦ',
    nameEn: 'LISETS',
    municipality: 'ЛОВЕЧ',
    municipalityEn: 'LOVECH',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5568',
    addressNomenclature: '0',
    x: '24.658043',
    y: '43.184634',
    servingDays: '0101000',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '43832',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛИСЕЦ',
    nameEn: 'LISETS',
    municipality: 'САМОКОВ',
    municipalityEn: 'SAMOKOV',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2023',
    addressNomenclature: '0',
    x: '23.2314',
    y: '42.422379',
    servingDays: '1111110',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '43846',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛИСИ ВРЪХ',
    nameEn: 'LISI VRAH',
    municipality: 'КАОЛИНОВО',
    municipalityEn: 'KAOLINOVO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9951',
    addressNomenclature: '0',
    x: '27.097604',
    y: '43.501378',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '43856',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛИСИЦИТЕ',
    nameEn: 'LISITSITE',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6645',
    addressNomenclature: '0',
    x: '25.451485',
    y: '41.6114',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '43863',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛИСИЯ',
    nameEn: 'LISIA',
    municipality: 'БЛАГОЕВГРАД',
    municipalityEn: 'BLAGOEVGRAD',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2743',
    addressNomenclature: '0',
    x: '22.953705',
    y: '42.048793',
    servingDays: '1111110',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '43877',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛИСОВО',
    nameEn: 'LISOVO',
    municipality: 'СВИЛЕНГРАД',
    municipalityEn: 'SVILENGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6541',
    addressNomenclature: '0',
    x: '26.315595',
    y: '41.942309',
    servingDays: '0101000',
    servingOfficeId: '48',
    servingHubOfficeId: '5',
  },
  {
    id: '43880',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛИСТЕЦ',
    nameEn: 'LISTETS',
    municipality: 'РУЕН',
    municipalityEn: 'RUEN',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8573',
    addressNomenclature: '0',
    x: '27.131949',
    y: '42.866205',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '43894',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛИСТЕЦ',
    nameEn: 'LISTETS',
    municipality: 'ГЛАВИНИЦА',
    municipalityEn: 'GLAVINITSA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7642',
    addressNomenclature: '0',
    x: '26.875264',
    y: '43.839923',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '43904',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛИТАКОВО',
    nameEn: 'LITAKOVO',
    municipality: 'БОТЕВГРАД',
    municipalityEn: 'BOTEVGRAD',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2144',
    addressNomenclature: '0',
    x: '23.674178',
    y: '42.952509',
    servingDays: '0101000',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '43918',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛОБОШ',
    nameEn: 'LOBOSH',
    municipality: 'КОВАЧЕВЦИ',
    municipalityEn: 'KOVACHEVTSI',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2433',
    addressNomenclature: '0',
    x: '22.81601',
    y: '42.503328',
    servingDays: '1111100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '43921',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛОВЕЦ',
    nameEn: 'LOVETS',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6070',
    addressNomenclature: '0',
    x: '25.603368',
    y: '42.257709',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '43935',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛОВЕЦ',
    nameEn: 'LOVETS',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7741',
    addressNomenclature: '0',
    x: '26.691687',
    y: '43.261619',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '43949',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛОВЕЦ',
    nameEn: 'LOVETS',
    municipality: 'ВЪРБИЦА',
    municipalityEn: 'VARBITSA',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9869',
    addressNomenclature: '0',
    x: '26.710488',
    y: '43.057212',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '43952',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ЛОВЕЧ',
    nameEn: 'LOVECH',
    municipality: 'ЛОВЕЧ',
    municipalityEn: 'LOVECH',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5500',
    addressNomenclature: '1',
    x: '24.716267',
    y: '43.13679',
    servingDays: '1111110',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '43966',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛОВНИДОЛ',
    nameEn: 'LOVNIDOL',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5423',
    addressNomenclature: '0',
    x: '25.249008',
    y: '42.996895',
    servingDays: '0101000',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '44937',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛОВСКО',
    nameEn: 'LOVSKO',
    municipality: 'ЛОЗНИЦА',
    municipalityEn: 'LOZNITSA',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7291',
    addressNomenclature: '0',
    x: '26.621212',
    y: '43.394755',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '43983',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛОВЦИ',
    nameEn: 'LOVTSI',
    municipality: 'МАДАН',
    municipalityEn: 'MADAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4908',
    addressNomenclature: '0',
    x: '24.928534',
    y: '41.516435',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '43997',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛОВЧАНЦИ',
    nameEn: 'LOVCHANTSI',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9430',
    addressNomenclature: '0',
    x: '27.648842',
    y: '43.655781',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '68792',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛОГОДАЖ',
    nameEn: 'LOGODAZH',
    municipality: 'БЛАГОЕВГРАД',
    municipalityEn: 'BLAGOEVGRAD',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2737',
    addressNomenclature: '0',
    x: '22.937859',
    y: '41.993541',
    servingDays: '1111110',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '44015',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛОЗА',
    nameEn: 'LOZA',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5345',
    addressNomenclature: '0',
    x: '25.240799',
    y: '42.924716',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '44029',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛОЗАРЕВО',
    nameEn: 'LOZAREVO',
    municipality: 'СУНГУРЛАРЕ',
    municipalityEn: 'SUNGURLARE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8440',
    addressNomenclature: '0',
    x: '26.877562',
    y: '42.770825',
    servingDays: '0101000',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '44032',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛОЗЕВО',
    nameEn: 'LOZEVO',
    municipality: 'ШУМЕН',
    municipalityEn: 'SHUMEN',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9746',
    addressNomenclature: '0',
    x: '26.877025',
    y: '43.297988',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '44046',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛОЗЕН',
    nameEn: 'LOZEN',
    municipality: 'СТРАЖИЦА',
    municipalityEn: 'STRAZHITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5172',
    addressNomenclature: '0',
    x: '25.870075',
    y: '43.323807',
    servingDays: '1010100',
    servingOfficeId: '46',
    servingHubOfficeId: '11',
  },
  {
    id: '44053',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛОЗЕН',
    nameEn: 'LOZEN',
    municipality: 'СЕПТЕМВРИ',
    municipalityEn: 'SEPTEMVRI',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4489',
    addressNomenclature: '0',
    x: '24.177541',
    y: '42.186952',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '44063',
    countryId: '100',
    mainSiteId: '68134',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛОЗЕН',
    nameEn: 'LOZEN',
    municipality: 'СТОЛИЧНА',
    municipalityEn: 'STOLICHNA',
    region: 'СОФИЯ (СТОЛИЦА)',
    regionEn: 'SOFIA (STOLITSA)',
    postCode: '1151',
    addressNomenclature: '2',
    x: '23.486601',
    y: '42.60169',
    servingDays: '1111110',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '44077',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛОЗЕН',
    nameEn: 'LOZEN',
    municipality: 'ЛЮБИМЕЦ',
    municipalityEn: 'LYUBIMETS',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6552',
    addressNomenclature: '0',
    x: '26.027482',
    y: '41.802896',
    servingDays: '1010100',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '63149',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛОЗЕН',
    nameEn: 'LOZEN',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6062',
    addressNomenclature: '0',
    x: '25.433013',
    y: '42.414744',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '44080',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛОЗЕНГРАДЦИ',
    nameEn: 'LOZENGRADTSI',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6894',
    addressNomenclature: '0',
    x: '25.399108',
    y: '41.294704',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '44094',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛОЗЕНЕЦ',
    nameEn: 'LOZENETS',
    municipality: 'ЦАРЕВО',
    municipalityEn: 'TSAREVO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8277',
    addressNomenclature: '0',
    x: '27.806211',
    y: '42.20869',
    servingDays: '1111110',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '44104',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛОЗЕНЕЦ',
    nameEn: 'LOZENETS',
    municipality: 'КРУШАРИ',
    municipalityEn: 'KRUSHARI',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9414',
    addressNomenclature: '0',
    x: '27.723383',
    y: '43.760041',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '44118',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛОЗЕНЕЦ',
    nameEn: 'LOZENETS',
    municipality: 'СТРАЛДЖА',
    municipalityEn: 'STRALDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8688',
    addressNomenclature: '0',
    x: '26.709396',
    y: '42.636293',
    servingDays: '1111100',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '44121',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛОЗЕНИЦА',
    nameEn: 'LOZENITSA',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2821',
    addressNomenclature: '0',
    x: '23.372547',
    y: '41.504357',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '44135',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛОЗЕТО',
    nameEn: 'LOZETO',
    municipality: 'ТЕТЕВЕН',
    municipalityEn: 'TETEVEN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '',
    addressNomenclature: '0',
    x: '24.177487',
    y: '43.034897',
    servingDays: '0101000',
    servingOfficeId: '88',
    servingHubOfficeId: '2',
  },
  {
    id: '44149',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛОЗИЦА',
    nameEn: 'LOZITSA',
    municipality: 'СУНГУРЛАРЕ',
    municipalityEn: 'SUNGURLARE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8466',
    addressNomenclature: '0',
    x: '26.779278',
    y: '42.728763',
    servingDays: '0101000',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '44152',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛОЗИЦА',
    nameEn: 'LOZITSA',
    municipality: 'НИКОПОЛ',
    municipalityEn: 'NIKOPOL',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5954',
    addressNomenclature: '0',
    x: '25.005646',
    y: '43.606676',
    servingDays: '0101000',
    servingOfficeId: '108',
    servingHubOfficeId: '11',
  },
  {
    id: '44166',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ЛОЗНИЦА',
    nameEn: 'LOZNITSA',
    municipality: 'ЛОЗНИЦА',
    municipalityEn: 'LOZNITSA',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7290',
    addressNomenclature: '2',
    x: '26.598731',
    y: '43.371482',
    servingDays: '1111100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '44179',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛОЗНИЦА',
    nameEn: 'LOZNITSA',
    municipality: 'ГЕНЕРАЛ ТОШЕВО',
    municipalityEn: 'GENERAL TOSHEVO',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9541',
    addressNomenclature: '0',
    x: '27.919939',
    y: '43.966136',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '44183',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛОЗНО',
    nameEn: 'LOZNO',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2507',
    addressNomenclature: '0',
    x: '22.654798',
    y: '42.293902',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '44210',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛОКВАТА',
    nameEn: 'LOKVATA',
    municipality: 'БОБОВ ДОЛ',
    municipalityEn: 'BOBOV DOL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2656',
    addressNomenclature: '0',
    x: '22.956631',
    y: '42.230907',
    servingDays: '1010100',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '44224',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛОКОРСКО',
    nameEn: 'LOKORSKO',
    municipality: 'СТОЛИЧНА',
    municipalityEn: 'STOLICHNA',
    region: 'СОФИЯ (СТОЛИЦА)',
    regionEn: 'SOFIA (STOLITSA)',
    postCode: '1513',
    addressNomenclature: '2',
    x: '23.446301',
    y: '42.794383',
    servingDays: '1111100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '44238',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ЛОМ',
    nameEn: 'LOM',
    municipality: 'ЛОМ',
    municipalityEn: 'LOM',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3600',
    addressNomenclature: '2',
    x: '23.238017',
    y: '43.821008',
    servingDays: '1111110',
    servingOfficeId: '831',
    servingHubOfficeId: '20',
  },
  {
    id: '5133',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛОМ ЧЕРКОВНА',
    nameEn: 'LOM CHERKOVNA',
    municipality: 'БЯЛА',
    municipalityEn: 'BYALA',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7135',
    addressNomenclature: '0',
    x: '25.970683',
    y: '43.343346',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '44241',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛОМЕЦ',
    nameEn: 'LOMETS',
    municipality: 'ТРОЯН',
    municipalityEn: 'TROYAN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5669',
    addressNomenclature: '0',
    x: '24.64814',
    y: '42.992052',
    servingDays: '0101000',
    servingOfficeId: '44',
    servingHubOfficeId: '11',
  },
  {
    id: '44255',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛОМНИЦА',
    nameEn: 'LOMNITSA',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2562',
    addressNomenclature: '0',
    x: '22.546927',
    y: '42.376365',
    servingDays: '0101000',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '44269',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛОМНИЦА',
    nameEn: 'LOMNITSA',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2465',
    addressNomenclature: '0',
    x: '22.634898',
    y: '42.867763',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '44272',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛОМНИЦА',
    nameEn: 'LOMNITSA',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9397',
    addressNomenclature: '0',
    x: '27.860919',
    y: '43.686631',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '44286',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛОМЦИ',
    nameEn: 'LOMTSI',
    municipality: 'ПОПОВО',
    municipalityEn: 'POPOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7838',
    addressNomenclature: '0',
    x: '26.352772',
    y: '43.428399',
    servingDays: '0101000',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '44294',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛОПУШНА',
    nameEn: 'LOPUSHNA',
    municipality: 'ДЪЛГОПОЛ',
    municipalityEn: 'DALGOPOL',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9237',
    addressNomenclature: '0',
    x: '27.182594',
    y: '43.002633',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '44300',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛОПУШНЯ',
    nameEn: 'LOPUSHNYA',
    municipality: 'ГОДЕЧ',
    municipalityEn: 'GODECH',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2252',
    addressNomenclature: '0',
    x: '23.061938',
    y: '42.981294',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '44313',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛОПЯН',
    nameEn: 'LOPYAN',
    municipality: 'ЕТРОПОЛЕ',
    municipalityEn: 'ETROPOLE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2193',
    addressNomenclature: '0',
    x: '24.076643',
    y: '42.873207',
    servingDays: '0101000',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '24116',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛУДОГОРЦИ',
    nameEn: 'LUDOGORTSI',
    municipality: 'ИСПЕРИХ',
    municipalityEn: 'ISPERIH',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7445',
    addressNomenclature: '0',
    x: '26.766453',
    y: '43.644225',
    servingDays: '1010100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '44327',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ЛУКОВИТ',
    nameEn: 'LUKOVIT',
    municipality: 'ЛУКОВИТ',
    municipalityEn: 'LUKOVIT',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5770',
    addressNomenclature: '2',
    x: '24.164393',
    y: '43.203544',
    servingDays: '1111100',
    servingOfficeId: '59',
    servingHubOfficeId: '20',
  },
  {
    id: '44330',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛУКОВО',
    nameEn: 'LUKOVO',
    municipality: 'СВОГЕ',
    municipalityEn: 'SVOGE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2296',
    addressNomenclature: '0',
    x: '23.391684',
    y: '42.882949',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '44344',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛУЛИЧКА',
    nameEn: 'LULICHKA',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6921',
    addressNomenclature: '0',
    x: '25.602685',
    y: '41.46845',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '44358',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЪВИНО',
    nameEn: 'LAVINO',
    municipality: 'ИСПЕРИХ',
    municipalityEn: 'ISPERIH',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7439',
    addressNomenclature: '0',
    x: '26.859042',
    y: '43.68782',
    servingDays: '1010100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '44361',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЪВОВО',
    nameEn: 'LAVOVO',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6638',
    addressNomenclature: '0',
    x: '25.339078',
    y: '41.567752',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '44389',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЪГА',
    nameEn: 'LAGA',
    municipality: 'ЕТРОПОЛЕ',
    municipalityEn: 'ETROPOLE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2182',
    addressNomenclature: '0',
    x: '24.025263',
    y: '42.884351',
    servingDays: '0101000',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '44416',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЪЖНИЦА',
    nameEn: 'LAZHNITSA',
    municipality: 'ГОЦЕ ДЕЛЧЕВ',
    municipalityEn: 'GOTSE DELCHEV',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2971',
    addressNomenclature: '0',
    x: '23.675904',
    y: '41.619006',
    servingDays: '1111100',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '44425',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЪКА',
    nameEn: 'LAKA',
    municipality: 'ПОМОРИЕ',
    municipalityEn: 'POMORIE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8216',
    addressNomenclature: '0',
    x: '27.53545',
    y: '42.624615',
    servingDays: '1111110',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '44433',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЪКА',
    nameEn: 'LAKA',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4726',
    addressNomenclature: '0',
    x: '24.823326',
    y: '41.564795',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '44447',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЪКАВИЦА',
    nameEn: 'LAKAVITSA',
    municipality: 'ЛЪКИ',
    municipalityEn: 'LAKI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4241',
    addressNomenclature: '0',
    x: '24.849405',
    y: '41.794924',
    servingDays: '0101000',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '44464',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЪКИ',
    nameEn: 'LAKI',
    municipality: 'ХАДЖИДИМОВО',
    municipalityEn: 'HADZHIDIMOVO',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2928',
    addressNomenclature: '0',
    x: '23.719889',
    y: '41.467643',
    servingDays: '1010100',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '44478',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ЛЪКИ',
    nameEn: 'LAKI',
    municipality: 'ЛЪКИ',
    municipalityEn: 'LAKI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4241',
    addressNomenclature: '2',
    x: '24.830845',
    y: '41.832994',
    servingDays: '0101000',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '44481',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЮБЕН',
    nameEn: 'LYUBEN',
    municipality: 'СЪЕДИНЕНИЕ',
    municipalityEn: 'SAEDINENIE',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4188',
    addressNomenclature: '0',
    x: '24.593504',
    y: '42.369672',
    servingDays: '0101000',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '44495',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЮБЕН',
    nameEn: 'LYUBEN',
    municipality: 'СИТОВО',
    municipalityEn: 'SITOVO',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7534',
    addressNomenclature: '0',
    x: '27.044851',
    y: '43.941479',
    servingDays: '1010100',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '44519',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЮБЕН КАРАВЕЛОВО',
    nameEn: 'LYUBEN KARAVELOVO',
    municipality: 'АКСАКОВО',
    municipalityEn: 'AKSAKOVO',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9148',
    addressNomenclature: '0',
    x: '27.774069',
    y: '43.343651',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '44505',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЮБЕНЕЦ',
    nameEn: 'LYUBENETS',
    municipality: 'НОВА ЗАГОРА',
    municipalityEn: 'NOVA ZAGORA',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8949',
    addressNomenclature: '0',
    x: '25.950101',
    y: '42.371313',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '44522',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЮБЕНОВА МАХАЛА',
    nameEn: 'LYUBENOVA MAHALA',
    municipality: 'НОВА ЗАГОРА',
    municipalityEn: 'NOVA ZAGORA',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8947',
    addressNomenclature: '0',
    x: '25.963539',
    y: '42.351646',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '44536',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЮБЕНОВО',
    nameEn: 'LYUBENOVO',
    municipality: 'НИКОПОЛ',
    municipalityEn: 'NIKOPOL',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5955',
    addressNomenclature: '0',
    x: '24.94067',
    y: '43.612875',
    servingDays: '0101000',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '44540',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЮБЕНОВО',
    nameEn: 'LYUBENOVO',
    municipality: 'РАДНЕВО',
    municipalityEn: 'RADNEVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6267',
    addressNomenclature: '0',
    x: '25.917197',
    y: '42.195516',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '44553',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЮБЕНОВО',
    nameEn: 'LYUBENOVO',
    municipality: 'ХАСКОВО',
    municipalityEn: 'HASKOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6342',
    addressNomenclature: '0',
    x: '25.741608',
    y: '41.89645',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '44567',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЮБЕНЦИ',
    nameEn: 'LYUBENTSI',
    municipality: 'СТРАЖИЦА',
    municipalityEn: 'STRAZHITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5176',
    addressNomenclature: '0',
    x: '26.100716',
    y: '43.208211',
    servingDays: '1010100',
    servingOfficeId: '46',
    servingHubOfficeId: '11',
  },
  {
    id: '44570',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ЛЮБИМЕЦ',
    nameEn: 'LYUBIMETS',
    municipality: 'ЛЮБИМЕЦ',
    municipalityEn: 'LYUBIMETS',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6550',
    addressNomenclature: '2',
    x: '26.078719',
    y: '41.844318',
    servingDays: '1111100',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '44584',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЮБИНО',
    nameEn: 'LYUBINO',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6796',
    addressNomenclature: '0',
    x: '25.116814',
    y: '41.664526',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '44598',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЮБИЧЕВО',
    nameEn: 'LYUBICHEVO',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7980',
    addressNomenclature: '0',
    x: '26.274551',
    y: '43.17196',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '44608',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЮБЛЕН',
    nameEn: 'LYUBLEN',
    municipality: 'ОПАКА',
    municipalityEn: 'OPAKA',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7843',
    addressNomenclature: '0',
    x: '26.201396',
    y: '43.508165',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '44611',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЮБНИЦА',
    nameEn: 'LYUBNITSA',
    municipality: 'ИХТИМАН',
    municipalityEn: 'IHTIMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2061',
    addressNomenclature: '0',
    x: '23.970815',
    y: '42.402379',
    servingDays: '0101000',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '44625',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЮБОВИЩЕ',
    nameEn: 'LYUBOVISHTE',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2820',
    addressNomenclature: '0',
    x: '23.442546',
    y: '41.527662',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '44639',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЮБОВКА',
    nameEn: 'LYUBOVKA',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2811',
    addressNomenclature: '0',
    x: '23.363165',
    y: '41.577793',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '44642',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЮБЧА',
    nameEn: 'LYUBCHA',
    municipality: 'ДОСПАТ',
    municipalityEn: 'DOSPAT',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4833',
    addressNomenclature: '0',
    x: '24.099781',
    y: '41.618994',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '44656',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЮЛИН',
    nameEn: 'LYULIN',
    municipality: 'ПЕРНИК',
    municipalityEn: 'PERNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2352',
    addressNomenclature: '0',
    x: '23.086229',
    y: '42.64791',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '44666',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЮЛИН',
    nameEn: 'LYULIN',
    municipality: 'СТРАЛДЖА',
    municipalityEn: 'STRALDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8685',
    addressNomenclature: '0',
    x: '26.798984',
    y: '42.436872',
    servingDays: '0101000',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '44673',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЮЛКА',
    nameEn: 'LYULKA',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4785',
    addressNomenclature: '0',
    x: '24.693493',
    y: '41.463604',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '44687',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЮЛЯК',
    nameEn: 'LYULYAK',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6050',
    addressNomenclature: '0',
    x: '25.674012',
    y: '42.50917',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '44690',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЮЛЯКОВО',
    nameEn: 'LYULYAKOVO',
    municipality: 'РУЕН',
    municipalityEn: 'RUEN',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8570',
    addressNomenclature: '0',
    x: '27.078861',
    y: '42.873462',
    servingDays: '1111100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '44700',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЮЛЯКОВО',
    nameEn: 'LYULYAKOVO',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6684',
    addressNomenclature: '0',
    x: '25.445345',
    y: '41.734802',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '44714',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЮЛЯКОВО',
    nameEn: 'LYULYAKOVO',
    municipality: 'ГЕНЕРАЛ ТОШЕВО',
    municipalityEn: 'GENERAL TOSHEVO',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9525',
    addressNomenclature: '0',
    x: '28.088254',
    y: '43.680009',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '92064',
    countryId: '100',
    mainSiteId: '0',
    type: 'к.м.',
    typeEn: 'k.m.',
    name: 'ЛЮЛЯЦИТЕ',
    nameEn: 'LYULYATSITE',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5307',
    addressNomenclature: '0',
    x: '25.189301',
    y: '42.863478',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '44728',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЮТАДЖИК',
    nameEn: 'LYUTADZHIK',
    municipality: 'ВРАЦА',
    municipalityEn: 'VRATSA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3038',
    addressNomenclature: '0',
    x: '23.414328',
    y: '43.196224',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '44745',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЮТИБРОД',
    nameEn: 'LYUTIBROD',
    municipality: 'МЕЗДРА',
    municipalityEn: 'MEZDRA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3159',
    addressNomenclature: '0',
    x: '23.626676',
    y: '43.10678',
    servingDays: '1010100',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '44759',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЮТИДОЛ',
    nameEn: 'LYUTIDOL',
    municipality: 'МЕЗДРА',
    municipalityEn: 'MEZDRA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3165',
    addressNomenclature: '0',
    x: '23.684895',
    y: '43.059144',
    servingDays: '1010100',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '44762',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЮТОВО',
    nameEn: 'LYUTOVO',
    municipality: 'БЕЛИЦА',
    municipalityEn: 'BELITSA',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2791',
    addressNomenclature: '0',
    x: '23.632068',
    y: '41.945887',
    servingDays: '1010100',
    servingOfficeId: '125',
    servingHubOfficeId: '13',
  },
  {
    id: '44776',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЯВА РЕКА',
    nameEn: 'LYAVA REKA',
    municipality: 'ГУРКОВО',
    municipalityEn: 'GURKOVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6198',
    addressNomenclature: '0',
    x: '25.677487',
    y: '42.730673',
    servingDays: '0101000',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '44781',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЯЛИНЦИ',
    nameEn: 'LYALINTSI',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2474',
    addressNomenclature: '0',
    x: '22.759211',
    y: '42.771912',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '44793',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ЛЯСКОВЕЦ',
    nameEn: 'LYASKOVETS',
    municipality: 'ЛЯСКОВЕЦ',
    municipalityEn: 'LYASKOVETS',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5140',
    addressNomenclature: '1',
    x: '25.7162',
    y: '43.10519',
    servingDays: '1111110',
    servingOfficeId: '46',
    servingHubOfficeId: '11',
  },
  {
    id: '44803',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЯСКОВЕЦ',
    nameEn: 'LYASKOVETS',
    municipality: 'СТАМБОЛОВО',
    municipalityEn: 'STAMBOLOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6397',
    addressNomenclature: '0',
    x: '25.697653',
    y: '41.71262',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '43431',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЯСКОВО',
    nameEn: 'LYASKOVO',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9428',
    addressNomenclature: '0',
    x: '27.684982',
    y: '43.71622',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '44817',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЯСКОВО',
    nameEn: 'LYASKOVO',
    municipality: 'АЙТОС',
    municipalityEn: 'AYTOS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8518',
    addressNomenclature: '0',
    x: '27.287371',
    y: '42.72682',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '44820',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЯСКОВО',
    nameEn: 'LYASKOVO',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6667',
    addressNomenclature: '0',
    x: '25.317773',
    y: '41.811241',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '44834',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЯСКОВО',
    nameEn: 'LYASKOVO',
    municipality: 'АСЕНОВГРАД',
    municipalityEn: 'ASENOVGRAD',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4238',
    addressNomenclature: '0',
    x: '24.823274',
    y: '41.994377',
    servingDays: '1010000',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '44848',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЯСКОВО',
    nameEn: 'LYASKOVO',
    municipality: 'ДЕВИН',
    municipalityEn: 'DEVIN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4818',
    addressNomenclature: '0',
    x: '24.489828',
    y: '41.789912',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '44851',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЯСКОВО',
    nameEn: 'LYASKOVO',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6045',
    addressNomenclature: '0',
    x: '25.492723',
    y: '42.401482',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '44865',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЯТНО',
    nameEn: 'LYATNO',
    municipality: 'КАОЛИНОВО',
    municipalityEn: 'KAOLINOVO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9948',
    addressNomenclature: '0',
    x: '27.107558',
    y: '43.549585',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '44879',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЯХОВО',
    nameEn: 'LYAHOVO',
    municipality: 'ПАЗАРДЖИК',
    municipalityEn: 'PAZARDZHIK',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4416',
    addressNomenclature: '0',
    x: '24.230546',
    y: '42.152972',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '44882',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЛЯХОВО',
    nameEn: 'LYAHOVO',
    municipality: 'БАЛЧИК',
    municipalityEn: 'BALCHIK',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9617',
    addressNomenclature: '0',
    x: '28.0419',
    y: '43.428313',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '46019',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАГАРДЖИЦА',
    nameEn: 'MAGARDZHITSA',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4710',
    addressNomenclature: '0',
    x: '24.562263',
    y: '41.654462',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '46036',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАДАН',
    nameEn: 'MADAN',
    municipality: 'БОЙЧИНОВЦИ',
    municipalityEn: 'BOYCHINOVTSI',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3448',
    addressNomenclature: '0',
    x: '23.446157',
    y: '43.578717',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '46045',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'МАДАН',
    nameEn: 'MADAN',
    municipality: 'МАДАН',
    municipalityEn: 'MADAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4900',
    addressNomenclature: '2',
    x: '24.9402',
    y: '41.499167',
    servingDays: '1111100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '46053',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАДАРА',
    nameEn: 'MADARA',
    municipality: 'ШУМЕН',
    municipalityEn: 'SHUMEN',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9971',
    addressNomenclature: '0',
    x: '27.097648',
    y: '43.274695',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '46067',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАДЖАРЕ',
    nameEn: 'MADZHARE',
    municipality: 'САМОКОВ',
    municipalityEn: 'SAMOKOV',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2022',
    addressNomenclature: '0',
    x: '23.496896',
    y: '42.266554',
    servingDays: '0101000',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '46070',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАДЖАРИ',
    nameEn: 'MADZHARI',
    municipality: 'СТАМБОЛОВО',
    municipalityEn: 'STAMBOLOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6383',
    addressNomenclature: '0',
    x: '25.705384',
    y: '41.66984',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '46084',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'МАДЖАРОВО',
    nameEn: 'MADZHAROVO',
    municipality: 'МАДЖАРОВО',
    municipalityEn: 'MADZHAROVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6480',
    addressNomenclature: '2',
    x: '25.860654',
    y: '41.632924',
    servingDays: '0101000',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '46098',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАДЖЕРИТО',
    nameEn: 'MADZHERITO',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6063',
    addressNomenclature: '0',
    x: '25.644524',
    y: '42.359712',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '46108',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЗАРАЧЕВО',
    nameEn: 'MAZARACHEVO',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2549',
    addressNomenclature: '0',
    x: '22.625489',
    y: '42.349514',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '46111',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЙОР УЗУНОВО',
    nameEn: 'MAYOR UZUNOVO',
    municipality: 'ВИДИН',
    municipalityEn: 'VIDIN',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3777',
    addressNomenclature: '0',
    x: '22.824959',
    y: '44.073132',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '46125',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЙСКО',
    nameEn: 'MAYSKO',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5085',
    addressNomenclature: '0',
    x: '26.148471',
    y: '42.928364',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '46139',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЙСТОРОВО',
    nameEn: 'MAYSTOROVO',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6675',
    addressNomenclature: '0',
    x: '25.580544',
    y: '41.691663',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '46156',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАК',
    nameEn: 'MAK',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6784',
    addressNomenclature: '0',
    x: '25.199039',
    y: '41.529713',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '46190',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАКАРИОПОЛСКО',
    nameEn: 'MAKARIOPOLSKO',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7750',
    addressNomenclature: '0',
    x: '26.675093',
    y: '43.339057',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '46214',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАКЕДОНЦИ',
    nameEn: 'MAKEDONTSI',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6640',
    addressNomenclature: '0',
    x: '25.356641',
    y: '41.586918',
    servingDays: '1111100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '46228',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАКОВО',
    nameEn: 'MAKOVO',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7785',
    addressNomenclature: '0',
    x: '26.456047',
    y: '43.369814',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '46231',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАКОЦЕВО',
    nameEn: 'MAKOTSEVO',
    municipality: 'ГОРНА МАЛИНА',
    municipalityEn: 'GORNA MALINA',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2124',
    addressNomenclature: '0',
    x: '23.796815',
    y: '42.693154',
    servingDays: '0101000',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '46245',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАКРЕШ',
    nameEn: 'MAKRESH',
    municipality: 'МАКРЕШ',
    municipalityEn: 'MAKRESH',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3850',
    addressNomenclature: '0',
    x: '22.663125',
    y: '43.768223',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '46259',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛА РАКОВИЦА',
    nameEn: 'MALA RAKOVITSA',
    municipality: 'БОЖУРИЩЕ',
    municipalityEn: 'BOZHURISHTE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2225',
    addressNomenclature: '0',
    x: '23.050979',
    y: '42.732253',
    servingDays: '1111100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '46262',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛА ФУЧА',
    nameEn: 'MALA FUCHA',
    municipality: 'БОБОВ ДОЛ',
    municipalityEn: 'BOBOV DOL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2669',
    addressNomenclature: '0',
    x: '22.949481',
    y: '42.357174',
    servingDays: '1010100',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '46276',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛА ЦЪРКВА',
    nameEn: 'MALA TSARKVA',
    municipality: 'САМОКОВ',
    municipalityEn: 'SAMOKOV',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2021',
    addressNomenclature: '0',
    x: '23.511561',
    y: '42.26501',
    servingDays: '0101000',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '46286',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛЕВО',
    nameEn: 'MALEVO',
    municipality: 'ЧЕПЕЛАРЕ',
    municipalityEn: 'CHEPELARE',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4891',
    addressNomenclature: '0',
    x: '24.654659',
    y: '41.864087',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '46293',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛЕВО',
    nameEn: 'MALEVO',
    municipality: 'ХАСКОВО',
    municipalityEn: 'HASKOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6392',
    addressNomenclature: '0',
    x: '25.629924',
    y: '41.858473',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '46303',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛЕНОВО',
    nameEn: 'MALENOVO',
    municipality: 'СТРАЛДЖА',
    municipalityEn: 'STRALDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8689',
    addressNomenclature: '0',
    x: '26.779969',
    y: '42.559875',
    servingDays: '1010100',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '46317',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛИ ВЪРБОВНИК',
    nameEn: 'MALI VARBOVNIK',
    municipality: 'БОБОВ ДОЛ',
    municipalityEn: 'BOBOV DOL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2691',
    addressNomenclature: '0',
    x: '22.98921',
    y: '42.24759',
    servingDays: '1010100',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '46320',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛИ ДРЕНОВЕЦ',
    nameEn: 'MALI DRENOVETS',
    municipality: 'ДИМОВО',
    municipalityEn: 'DIMOVO',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3749',
    addressNomenclature: '0',
    x: '22.862064',
    y: '43.783482',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '46334',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛИ ИЗВОР',
    nameEn: 'MALI IZVOR',
    municipality: 'ТЕРВЕЛ',
    municipalityEn: 'TERVEL',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9474',
    addressNomenclature: '0',
    x: '27.468174',
    y: '43.793264',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '46348',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛИНА',
    nameEn: 'MALINA',
    municipality: 'СРЕДЕЦ',
    municipalityEn: 'SREDETS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8327',
    addressNomenclature: '0',
    x: '26.999856',
    y: '42.416712',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '46351',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛИНА',
    nameEn: 'MALINA',
    municipality: 'ГЕНЕРАЛ ТОШЕВО',
    municipalityEn: 'GENERAL TOSHEVO',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9559',
    addressNomenclature: '0',
    x: '28.078825',
    y: '43.637593',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '46365',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛИНИ',
    nameEn: 'MALINI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5335',
    addressNomenclature: '0',
    x: '25.374894',
    y: '42.878002',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '46396',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛИНОВО',
    nameEn: 'MALINOVO',
    municipality: 'ЛОВЕЧ',
    municipalityEn: 'LOVECH',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5582',
    addressNomenclature: '0',
    x: '24.884335',
    y: '43.039216',
    servingDays: '0101000',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '46406',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛКА АРДА',
    nameEn: 'MALKA ARDA',
    municipality: 'БАНИТЕ',
    municipalityEn: 'BANITE',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4742',
    addressNomenclature: '0',
    x: '24.933102',
    y: '41.619752',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '46417',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛКА ВЕРЕЯ',
    nameEn: 'MALKA VEREYA',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6045',
    addressNomenclature: '0',
    x: '25.542237',
    y: '42.401495',
    servingDays: '1111110',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '46437',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛКА ЖЕЛЯЗНА',
    nameEn: 'MALKA ZHELYAZNA',
    municipality: 'ТЕТЕВЕН',
    municipalityEn: 'TETEVEN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5729',
    addressNomenclature: '0',
    x: '24.319953',
    y: '43.006623',
    servingDays: '1010100',
    servingOfficeId: '88',
    servingHubOfficeId: '2',
  },
  {
    id: '46440',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛКА ПОЛЯНА',
    nameEn: 'MALKA POLYANA',
    municipality: 'АЙТОС',
    municipalityEn: 'AYTOS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8519',
    addressNomenclature: '0',
    x: '27.239556',
    y: '42.63822',
    servingDays: '1111100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '46454',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛКА СМОЛНИЦА',
    nameEn: 'MALKA SMOLNITSA',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9386',
    addressNomenclature: '0',
    x: '27.729064',
    y: '43.60538',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '46468',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛКА ЧЕРКОВНА',
    nameEn: 'MALKA CHERKOVNA',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7997',
    addressNomenclature: '0',
    x: '26.18377',
    y: '43.091207',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '46471',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛКА ЧИНКА',
    nameEn: 'MALKA CHINKA',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6941',
    addressNomenclature: '0',
    x: '25.52303',
    y: '41.399491',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '46485',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛКИ БЪЛГАРЕНИ',
    nameEn: 'MALKI BALGARENI',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5370',
    addressNomenclature: '0',
    x: '25.508624',
    y: '42.951603',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '46499',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛКИ ВОДЕН',
    nameEn: 'MALKI VODEN',
    municipality: 'МАДЖАРОВО',
    municipalityEn: 'MADZHAROVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6479',
    addressNomenclature: '0',
    x: '25.951659',
    y: '41.669888',
    servingDays: '1010100',
    servingOfficeId: '48',
    servingHubOfficeId: '5',
  },
  {
    id: '46509',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛКИ ВЪРШЕЦ',
    nameEn: 'MALKI VARSHETS',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5432',
    addressNomenclature: '0',
    x: '24.975579',
    y: '43.093463',
    servingDays: '0101000',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '49641',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛКИ ИСКЪР',
    nameEn: 'MALKI ISKAR',
    municipality: 'ЕТРОПОЛЕ',
    municipalityEn: 'ETROPOLE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2184',
    addressNomenclature: '0',
    x: '24.0779',
    y: '42.921239',
    servingDays: '0101000',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '46512',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛКИ СТАНЧОВЦИ',
    nameEn: 'MALKI STANCHOVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5365',
    addressNomenclature: '0',
    x: '25.538492',
    y: '42.900302',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '46526',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛКИ ЦАЛИМ',
    nameEn: 'MALKI TSALIM',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2800',
    addressNomenclature: '0',
    x: '23.34557',
    y: '41.602622',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '46532',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛКИ ЧИФЛИК',
    nameEn: 'MALKI CHIFLIK',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5028',
    addressNomenclature: '0',
    x: '25.674965',
    y: '43.06544',
    servingDays: '1111100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '46543',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛКО АСЕНОВО',
    nameEn: 'MALKO ASENOVO',
    municipality: 'ДИМИТРОВГРАД',
    municipalityEn: 'DIMITROVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6425',
    addressNomenclature: '0',
    x: '25.681854',
    y: '42.111723',
    servingDays: '1010000',
    servingOfficeId: '42',
    servingHubOfficeId: '5',
  },
  {
    id: '46557',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛКО БРЯГОВО',
    nameEn: 'MALKO BRYAGOVO',
    municipality: 'МАДЖАРОВО',
    municipalityEn: 'MADZHAROVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6476',
    addressNomenclature: '0',
    x: '25.891778',
    y: '41.694093',
    servingDays: '0101000',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '46560',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛКО ВРАНОВО',
    nameEn: 'MALKO VRANOVO',
    municipality: 'СЛИВО ПОЛЕ',
    municipalityEn: 'SLIVO POLE',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7067',
    addressNomenclature: '0',
    x: '26.327906',
    y: '43.931489',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '46574',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛКО ГРАДИЩЕ',
    nameEn: 'MALKO GRADISHTE',
    municipality: 'ЛЮБИМЕЦ',
    municipalityEn: 'LYUBIMETS',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6558',
    addressNomenclature: '0',
    x: '25.991867',
    y: '41.760176',
    servingDays: '1010100',
    servingOfficeId: '48',
    servingHubOfficeId: '5',
  },
  {
    id: '46588',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛКО ДРЯНОВО',
    nameEn: 'MALKO DRYANOVO',
    municipality: 'БРАТЯ ДАСКАЛОВИ',
    municipalityEn: 'BRATYA DASKALOVI',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6227',
    addressNomenclature: '0',
    x: '25.279722',
    y: '42.420145',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '50512',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛКО ЙОНКОВО',
    nameEn: 'MALKO YONKOVO',
    municipality: 'ИСПЕРИХ',
    municipalityEn: 'ISPERIH',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7460',
    addressNomenclature: '0',
    x: '26.723396',
    y: '43.624518',
    servingDays: '0101000',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '46591',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛКО КАДИЕВО',
    nameEn: 'MALKO KADIEVO',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6052',
    addressNomenclature: '0',
    x: '25.703268',
    y: '42.37235',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '46601',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛКО КАМЕНЯНЕ',
    nameEn: 'MALKO KAMENYANE',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6927',
    addressNomenclature: '0',
    x: '25.67735',
    y: '41.404668',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '46615',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛКО КИРИЛОВО',
    nameEn: 'MALKO KIRILOVO',
    municipality: 'ЕЛХОВО',
    municipalityEn: 'ELHOVO',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8756',
    addressNomenclature: '0',
    x: '26.615765',
    y: '42.032313',
    servingDays: '1010100',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '46629',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛКО КРУШЕВО',
    nameEn: 'MALKO KRUSHEVO',
    municipality: 'БАНИТЕ',
    municipalityEn: 'BANITE',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4951',
    addressNomenclature: '0',
    x: '25.070194',
    y: '41.688903',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '46632',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛКО ПОПОВО',
    nameEn: 'MALKO POPOVO',
    municipality: 'МАДЖАРОВО',
    municipalityEn: 'MADZHAROVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6482',
    addressNomenclature: '0',
    x: '25.905207',
    y: '41.604258',
    servingDays: '0101000',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '46646',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛКО СЕЛО',
    nameEn: 'MALKO SELO',
    municipality: 'КОТЕЛ',
    municipalityEn: 'KOTEL',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8981',
    addressNomenclature: '0',
    x: '26.508257',
    y: '42.979501',
    servingDays: '1010100',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '46658',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛКО ТРЪНОВО',
    nameEn: 'MALKO TRANOVO',
    municipality: 'ЧИРПАН',
    municipalityEn: 'CHIRPAN',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6228',
    addressNomenclature: '0',
    x: '25.461909',
    y: '42.263182',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '46663',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'МАЛКО ТЪРНОВО',
    nameEn: 'MALKO TARNOVO',
    municipality: 'МАЛКО ТЪРНОВО',
    municipalityEn: 'MALKO TARNOVO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8162',
    addressNomenclature: '2',
    x: '27.525877',
    y: '41.979109',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '46694',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛКО ЧОЧОВЕНИ',
    nameEn: 'MALKO CHOCHOVENI',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8857',
    addressNomenclature: '0',
    x: '26.203471',
    y: '42.651873',
    servingDays: '0101000',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '46704',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛКО ШАРКОВО',
    nameEn: 'MALKO SHARKOVO',
    municipality: 'БОЛЯРОВО',
    municipalityEn: 'BOLYAROVO',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8751',
    addressNomenclature: '0',
    x: '26.838075',
    y: '42.101577',
    servingDays: '1010100',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '46680',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛКОЧ',
    nameEn: 'MALKOCH',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6898',
    addressNomenclature: '0',
    x: '25.535315',
    y: '41.342014',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '46721',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛО БУЧИНО',
    nameEn: 'MALO BUCHINO',
    municipality: 'СТОЛИЧНА',
    municipalityEn: 'STOLICHNA',
    region: 'СОФИЯ (СТОЛИЦА)',
    regionEn: 'SOFIA (STOLITSA)',
    postCode: '1362',
    addressNomenclature: '2',
    x: '23.162186',
    y: '42.682698',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '46749',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛО КОНАРЕ',
    nameEn: 'MALO KONARE',
    municipality: 'ПАЗАРДЖИК',
    municipalityEn: 'PAZARDZHIK',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4450',
    addressNomenclature: '0',
    x: '24.431207',
    y: '42.199026',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '46752',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛО КРУШЕВО',
    nameEn: 'MALO KRUSHEVO',
    municipality: 'ХИСАРЯ',
    municipalityEn: 'HISARYA',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4183',
    addressNomenclature: '0',
    x: '24.490511',
    y: '42.417634',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '46766',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛО МАЛОВО',
    nameEn: 'MALO MALOVO',
    municipality: 'ДРАГОМАН',
    municipalityEn: 'DRAGOMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2205',
    addressNomenclature: '0',
    x: '23.047784',
    y: '42.949494',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '46807',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛО ПЕЩЕНЕ',
    nameEn: 'MALO PESHTENE',
    municipality: 'ВРАЦА',
    municipalityEn: 'VRATSA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3078',
    addressNomenclature: '0',
    x: '23.741661',
    y: '43.351166',
    servingDays: '1010100',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '46824',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛО СЕЛО',
    nameEn: 'MALO SELO',
    municipality: 'БОБОВ ДОЛ',
    municipalityEn: 'BOBOV DOL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2636',
    addressNomenclature: '0',
    x: '23.041754',
    y: '42.311687',
    servingDays: '1010100',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '46735',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛОГРАДЕЦ',
    nameEn: 'MALOGRADETS',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7978',
    addressNomenclature: '0',
    x: '26.166052',
    y: '43.112864',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '46773',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛОМИР',
    nameEn: 'MALOMIR',
    municipality: 'ВЪРБИЦА',
    municipalityEn: 'VARBITSA',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9871',
    addressNomenclature: '0',
    x: '26.715032',
    y: '43.024449',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '46783',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛОМИР',
    nameEn: 'MALOMIR',
    municipality: 'ТУНДЖА',
    municipalityEn: 'TUNDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8650',
    addressNomenclature: '0',
    x: '26.517793',
    y: '42.281737',
    servingDays: '0101000',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '46797',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛОМИРОВО',
    nameEn: 'MALOMIROVO',
    municipality: 'ЕЛХОВО',
    municipalityEn: 'ELHOVO',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8737',
    addressNomenclature: '0',
    x: '26.641835',
    y: '42.142704',
    servingDays: '1010100',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '46810',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛОРАД',
    nameEn: 'MALORAD',
    municipality: 'БОРОВАН',
    municipalityEn: 'BOROVAN',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3255',
    addressNomenclature: '0',
    x: '23.686585',
    y: '43.490685',
    servingDays: '1010100',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '49655',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛУША',
    nameEn: 'MALUSHA',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5304',
    addressNomenclature: '0',
    x: '25.291223',
    y: '42.810264',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '46841',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛЧИКА',
    nameEn: 'MALCHIKA',
    municipality: 'ЛЕВСКИ',
    municipalityEn: 'LEVSKI',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5917',
    addressNomenclature: '0',
    x: '25.109421',
    y: '43.413484',
    servingDays: '1010100',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '46855',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛЧОВЦИ',
    nameEn: 'MALCHOVTSI',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5050',
    addressNomenclature: '0',
    x: '25.644014',
    y: '42.962279',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '46869',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛЧОВЦИ',
    nameEn: 'MALCHOVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5367',
    addressNomenclature: '0',
    x: '25.549924',
    y: '42.844284',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '46872',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛЪК ДЕВЕСИЛ',
    nameEn: 'MALAK DEVESIL',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6935',
    addressNomenclature: '0',
    x: '25.658154',
    y: '41.365237',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '49998',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛЪК ДОЛ',
    nameEn: 'MALAK DOL',
    municipality: 'БРАТЯ ДАСКАЛОВИ',
    municipalityEn: 'BRATYA DASKALOVI',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6245',
    addressNomenclature: '0',
    x: '25.214597',
    y: '42.336096',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '46886',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛЪК ИЗВОР',
    nameEn: 'MALAK IZVOR',
    municipality: 'ЯБЛАНИЦА',
    municipalityEn: 'YABLANITSA',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5734',
    addressNomenclature: '0',
    x: '24.125497',
    y: '42.98407',
    servingDays: '1010100',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '46899',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛЪК ИЗВОР',
    nameEn: 'MALAK IZVOR',
    municipality: 'СТАМБОЛОВО',
    municipalityEn: 'STAMBOLOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6394',
    addressNomenclature: '0',
    x: '25.726383',
    y: '41.789979',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '46904',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛЪК МАНАСТИР',
    nameEn: 'MALAK MANASTIR',
    municipality: 'ЕЛХОВО',
    municipalityEn: 'ELHOVO',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8742',
    addressNomenclature: '0',
    x: '26.414131',
    y: '42.198186',
    servingDays: '1010100',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '46913',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛЪК ПОРОВЕЦ',
    nameEn: 'MALAK POROVETS',
    municipality: 'ИСПЕРИХ',
    municipalityEn: 'ISPERIH',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7429',
    addressNomenclature: '0',
    x: '26.752112',
    y: '43.714625',
    servingDays: '1010100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '46927',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛЪК ПРЕСЛАВЕЦ',
    nameEn: 'MALAK PRESLAVETS',
    municipality: 'ГЛАВИНИЦА',
    municipalityEn: 'GLAVINITSA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7620',
    addressNomenclature: '0',
    x: '26.840868',
    y: '44.067043',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '46944',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЛЪК ЧАРДАК',
    nameEn: 'MALAK CHARDAK',
    municipality: 'СЪЕДИНЕНИЕ',
    municipalityEn: 'SAEDINENIE',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4194',
    addressNomenclature: '0',
    x: '24.634595',
    y: '42.286797',
    servingDays: '0101000',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '46958',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАМАРЧЕВО',
    nameEn: 'MAMARCHEVO',
    municipality: 'БОЛЯРОВО',
    municipalityEn: 'BOLYAROVO',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8743',
    addressNomenclature: '0',
    x: '26.778558',
    y: '42.119017',
    servingDays: '1010100',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '46961',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАНАСЕЛСКА РЕКА',
    nameEn: 'MANASELSKA REKA',
    municipality: 'ПРАВЕЦ',
    municipalityEn: 'PRAVETS',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2168',
    addressNomenclature: '0',
    x: '24.017127',
    y: '43.041856',
    servingDays: '1010100',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '46975',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАНАСТИР',
    nameEn: 'MANASTIR',
    municipality: 'ПРОВАДИЯ',
    municipalityEn: 'PROVADIA',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9224',
    addressNomenclature: '0',
    x: '27.518302',
    y: '43.177571',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '46989',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАНАСТИР',
    nameEn: 'MANASTIR',
    municipality: 'ЛЪКИ',
    municipalityEn: 'LAKI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4245',
    addressNomenclature: '0',
    x: '24.858612',
    y: '41.724143',
    servingDays: '0101000',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '46992',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАНАСТИР',
    nameEn: 'MANASTIR',
    municipality: 'ХАСКОВО',
    municipalityEn: 'HASKOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6390',
    addressNomenclature: '0',
    x: '25.581651',
    y: '41.860283',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '47007',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАНАСТИРИЦА',
    nameEn: 'MANASTIRITSA',
    municipality: 'ПОПОВО',
    municipalityEn: 'POPOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7856',
    addressNomenclature: '0',
    x: '26.072714',
    y: '43.254544',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '47010',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАНАСТИРИЩЕ',
    nameEn: 'MANASTIRISHTE',
    municipality: 'ХАЙРЕДИН',
    municipalityEn: 'HAYREDIN',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3354',
    addressNomenclature: '0',
    x: '23.64806',
    y: '43.575002',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '47024',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАНАСТИРИЩЕ',
    nameEn: 'MANASTIRISHTE',
    municipality: 'СВОГЕ',
    municipalityEn: 'SVOGE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2288',
    addressNomenclature: '0',
    x: '23.142078',
    y: '43.003232',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '47038',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАНАСТИРСКО',
    nameEn: 'MANASTIRSKO',
    municipality: 'ЛОЗНИЦА',
    municipalityEn: 'LOZNITSA',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7285',
    addressNomenclature: '0',
    x: '26.546878',
    y: '43.421676',
    servingDays: '0101000',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '47041',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАНАСТИРЦИ',
    nameEn: 'MANASTIRTSI',
    municipality: 'ЛОЗНИЦА',
    municipalityEn: 'LOZNITSA',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7292',
    addressNomenclature: '0',
    x: '26.636322',
    y: '43.352452',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '47055',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАНДРА',
    nameEn: 'MANDRA',
    municipality: 'ХАСКОВО',
    municipalityEn: 'HASKOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6357',
    addressNomenclature: '0',
    x: '25.505729',
    y: '41.817622',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '47069',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАНДРИЦА',
    nameEn: 'MANDRITSA',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6585',
    addressNomenclature: '0',
    x: '26.133613',
    y: '41.392449',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '47072',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАНЕВЦИ',
    nameEn: 'MANEVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5363',
    addressNomenclature: '0',
    x: '25.555229',
    y: '42.866527',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '47086',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАНОЛЕ',
    nameEn: 'MANOLE',
    municipality: 'МАРИЦА',
    municipalityEn: 'MARITSA',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4137',
    addressNomenclature: '0',
    x: '24.933553',
    y: '42.188034',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '47096',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАНОЛИЧ',
    nameEn: 'MANOLICH',
    municipality: 'СУНГУРЛАРЕ',
    municipalityEn: 'SUNGURLARE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8453',
    addressNomenclature: '0',
    x: '26.801731',
    y: '42.869722',
    servingDays: '1010100',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '47101',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАНОЛОВО',
    nameEn: 'MANOLOVO',
    municipality: 'ПАВЕЛ БАНЯ',
    municipalityEn: 'PAVEL BANYA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6152',
    addressNomenclature: '0',
    x: '25.066663',
    y: '42.62561',
    servingDays: '1010100',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '47113',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАНОЛСКО КОНАРЕ',
    nameEn: 'MANOLSKO KONARE',
    municipality: 'МАРИЦА',
    municipalityEn: 'MARITSA',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4138',
    addressNomenclature: '0',
    x: '24.954127',
    y: '42.207522',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '47127',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАНОЯ',
    nameEn: 'MANOYA',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5392',
    addressNomenclature: '0',
    x: '25.565688',
    y: '43.011306',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '47130',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАНУШЕВЦИ',
    nameEn: 'MANUSHEVTSI',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7967',
    addressNomenclature: '0',
    x: '26.234231',
    y: '42.978997',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '47144',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАНЧЕВО',
    nameEn: 'MANCHEVO',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6800',
    addressNomenclature: '0',
    x: '25.448212',
    y: '41.541849',
    servingDays: '1111110',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '47158',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАРАФЕЛЦИ',
    nameEn: 'MARAFELTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5070',
    addressNomenclature: '0',
    x: '25.845357',
    y: '42.943678',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '47161',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАРАШ',
    nameEn: 'MARASH',
    municipality: 'ШУМЕН',
    municipalityEn: 'SHUMEN',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9745',
    addressNomenclature: '0',
    x: '26.951095',
    y: '43.204267',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '47175',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАРГОВЦИ',
    nameEn: 'MARGOVTSI',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5035',
    addressNomenclature: '0',
    x: '25.667961',
    y: '42.84918',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '47189',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАРИКОСТИНОВО',
    nameEn: 'MARIKOSTINOVO',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2870',
    addressNomenclature: '0',
    x: '23.338255',
    y: '41.431358',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '47202',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАРИНКА',
    nameEn: 'MARINKA',
    municipality: 'БУРГАС',
    municipalityEn: 'BURGAS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8154',
    addressNomenclature: '2',
    x: '27.482141',
    y: '42.401516',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '47247',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАРИНО ПОЛЕ',
    nameEn: 'MARINO POLE',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2867',
    addressNomenclature: '0',
    x: '23.348802',
    y: '41.413583',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '47250',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАРИНО ПОЛЕ',
    nameEn: 'MARINO POLE',
    municipality: 'КАРЛОВО',
    municipalityEn: 'KARLOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4365',
    addressNomenclature: '0',
    x: '24.864948',
    y: '42.587212',
    servingDays: '1010100',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '47227',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАРИНОВЦИ',
    nameEn: 'MARINOVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5097',
    addressNomenclature: '0',
    x: '25.864398',
    y: '42.865764',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '47233',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАРИНОВЦИ',
    nameEn: 'MARINOVTSI',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '',
    addressNomenclature: '0',
    x: '24.90937',
    y: '42.919944',
    servingDays: '1010100',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '47264',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАРИЦА',
    nameEn: 'MARITSA',
    municipality: 'САМОКОВ',
    municipalityEn: 'SAMOKOV',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2044',
    addressNomenclature: '0',
    x: '23.706811',
    y: '42.307409',
    servingDays: '1010100',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '47295',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАРКОВО',
    nameEn: 'MARKOVO',
    municipality: 'РОДОПИ',
    municipalityEn: 'RODOPI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4108',
    addressNomenclature: '0',
    x: '24.703709',
    y: '42.062558',
    servingDays: '1111100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '47305',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАРКОВО',
    nameEn: 'MARKOVO',
    municipality: 'БРАТЯ ДАСКАЛОВИ',
    municipalityEn: 'BRATYA DASKALOVI',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6247',
    addressNomenclature: '0',
    x: '25.222308',
    y: '42.350545',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '47319',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАРКОВО',
    nameEn: 'MARKOVO',
    municipality: 'КАСПИЧАН',
    municipalityEn: 'KASPICHAN',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9973',
    addressNomenclature: '0',
    x: '27.228054',
    y: '43.201084',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '47322',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАРКОВО РАВНИЩЕ',
    nameEn: 'MARKOVO RAVNISHTE',
    municipality: 'РОМАН',
    municipalityEn: 'ROMAN',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3133',
    addressNomenclature: '0',
    x: '23.939921',
    y: '43.036135',
    servingDays: '1010100',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '47336',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'МАРТЕН',
    nameEn: 'MARTEN',
    municipality: 'РУСЕ',
    municipalityEn: 'RUSE',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7058',
    addressNomenclature: '2',
    x: '26.084181',
    y: '43.920023',
    servingDays: '1111100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '47342',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАРТИНО',
    nameEn: 'MARTINO',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6745',
    addressNomenclature: '0',
    x: '25.267533',
    y: '41.718989',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '47353',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАРТИНОВО',
    nameEn: 'MARTINOVO',
    municipality: 'ЧИПРОВЦИ',
    municipalityEn: 'CHIPROVTSI',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3459',
    addressNomenclature: '0',
    x: '22.834814',
    y: '43.401326',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '47370',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАРУЛЕВО',
    nameEn: 'MARULEVO',
    municipality: 'БЛАГОЕВГРАД',
    municipalityEn: 'BLAGOEVGRAD',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2725',
    addressNomenclature: '0',
    x: '23.158995',
    y: '41.995533',
    servingDays: '1111110',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '47384',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАРУЦЕКОВЦИ',
    nameEn: 'MARUTSEKOVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5360',
    addressNomenclature: '0',
    x: '25.418762',
    y: '42.814657',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '47408',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАРЧЕВО',
    nameEn: 'MARCHEVO',
    municipality: 'ГЪРМЕН',
    municipalityEn: 'GARMEN',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2949',
    addressNomenclature: '0',
    x: '23.795853',
    y: '41.607903',
    servingDays: '1111100',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '47411',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАРЧИНО',
    nameEn: 'MARCHINO',
    municipality: 'ПОПОВО',
    municipalityEn: 'POPOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7869',
    addressNomenclature: '0',
    x: '26.338232',
    y: '43.260403',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '47425',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАРЯН',
    nameEn: 'MARYAN',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5084',
    addressNomenclature: '0',
    x: '25.969911',
    y: '42.930544',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '47439',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАСЛАРЕВО',
    nameEn: 'MASLAREVO',
    municipality: 'ПОЛСКИ ТРЪМБЕШ',
    municipalityEn: 'POLSKI TRAMBESH',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5198',
    addressNomenclature: '0',
    x: '25.465543',
    y: '43.415236',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '47442',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАСЛИНОВО',
    nameEn: 'MASLINOVO',
    municipality: 'ХАСКОВО',
    municipalityEn: 'HASKOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6388',
    addressNomenclature: '0',
    x: '25.534396',
    y: '41.768008',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '47456',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАТЕШОВЦИ',
    nameEn: 'MATESHOVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5351',
    addressNomenclature: '0',
    x: '25.451546',
    y: '42.868521',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '47468',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАТОЧИНА',
    nameEn: 'MATOCHINA',
    municipality: 'СВИЛЕНГРАД',
    municipalityEn: 'SVILENGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6535',
    addressNomenclature: '0',
    x: '26.541961',
    y: '41.850225',
    servingDays: '0101000',
    servingOfficeId: '48',
    servingHubOfficeId: '5',
  },
  {
    id: '47473',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАХАЛАТА',
    nameEn: 'MAHALATA',
    municipality: 'СТРУМЯНИ',
    municipalityEn: 'STRUMYANI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2849',
    addressNomenclature: '0',
    x: '23.095094',
    y: '41.578912',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '47487',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАХАЛНИЦИ',
    nameEn: 'MAHALNITSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5081',
    addressNomenclature: '0',
    x: '25.819381',
    y: '42.928193',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '47500',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МАЦА',
    nameEn: 'MATSA',
    municipality: 'РАДНЕВО',
    municipalityEn: 'RADNEVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6276',
    addressNomenclature: '0',
    x: '26.165079',
    y: '42.213667',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '47528',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЕДВЕН',
    nameEn: 'MEDVEN',
    municipality: 'КОТЕЛ',
    municipalityEn: 'KOTEL',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8987',
    addressNomenclature: '0',
    x: '26.55797',
    y: '42.842238',
    servingDays: '0101000',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '47531',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЕДЕВЦИ',
    nameEn: 'MEDEVTSI',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6865',
    addressNomenclature: '0',
    x: '25.299249',
    y: '41.383347',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '47545',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЕДЕН БУК',
    nameEn: 'MEDEN BUK',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6598',
    addressNomenclature: '0',
    x: '26.035018',
    y: '41.376161',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '47562',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЕДЕН КЛАДЕНЕЦ',
    nameEn: 'MEDEN KLADENETS',
    municipality: 'ТУНДЖА',
    municipalityEn: 'TUNDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8639',
    addressNomenclature: '0',
    x: '26.322739',
    y: '42.32543',
    servingDays: '0101000',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '47559',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЕДЕНИ ПОЛЯНИ',
    nameEn: 'MEDENI POLYANI',
    municipality: 'СЪРНИЦА',
    municipalityEn: 'SARNITSA',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4637',
    addressNomenclature: '0',
    x: '23.804452',
    y: '41.858276',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '47583',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЕДЕШЕВЦИ',
    nameEn: 'MEDESHEVTSI',
    municipality: 'ГРАМАДА',
    municipalityEn: 'GRAMADA',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3845',
    addressNomenclature: '0',
    x: '22.642573',
    y: '43.886515',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '47593',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЕДКОВЕЦ',
    nameEn: 'MEDKOVETS',
    municipality: 'МЕДКОВЕЦ',
    municipalityEn: 'MEDKOVETS',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3670',
    addressNomenclature: '2',
    x: '23.169872',
    y: '43.624411',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '47603',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЕДНИКАРОВО',
    nameEn: 'MEDNIKAROVO',
    municipality: 'ГЪЛЪБОВО',
    municipalityEn: 'GALABOVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6294',
    addressNomenclature: '0',
    x: '26.006004',
    y: '42.124722',
    servingDays: '1111110',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '47617',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЕДОВЕНЕ',
    nameEn: 'MEDOVENE',
    municipality: 'КУБРАТ',
    municipalityEn: 'KUBRAT',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7319',
    addressNomenclature: '0',
    x: '26.528691',
    y: '43.773251',
    servingDays: '0101000',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '47620',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЕДОВЕЦ',
    nameEn: 'MEDOVETS',
    municipality: 'ДЪЛГОПОЛ',
    municipalityEn: 'DALGOPOL',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9238',
    addressNomenclature: '0',
    x: '27.135116',
    y: '43.005958',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '47634',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЕДОВИНА',
    nameEn: 'MEDOVINA',
    municipality: 'ПОПОВО',
    municipalityEn: 'POPOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7863',
    addressNomenclature: '0',
    x: '26.175332',
    y: '43.324575',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '47648',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЕДОВНИЦА',
    nameEn: 'MEDOVNITSA',
    municipality: 'ДИМОВО',
    municipalityEn: 'DIMOVO',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3947',
    addressNomenclature: '0',
    x: '22.783767',
    y: '43.654931',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '47651',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЕДОВО',
    nameEn: 'MEDOVO',
    municipality: 'ПОМОРИЕ',
    municipalityEn: 'POMORIE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8214',
    addressNomenclature: '0',
    x: '27.570783',
    y: '42.695683',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '47665',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЕДОВО',
    nameEn: 'MEDOVO',
    municipality: 'БРАТЯ ДАСКАЛОВИ',
    municipalityEn: 'BRATYA DASKALOVI',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6235',
    addressNomenclature: '0',
    x: '25.201149',
    y: '42.372019',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '47679',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЕДОВО',
    nameEn: 'MEDOVO',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9388',
    addressNomenclature: '0',
    x: '27.533873',
    y: '43.608899',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '47682',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЕЖДА',
    nameEn: 'MEZHDA',
    municipality: 'ТУНДЖА',
    municipalityEn: 'TUNDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8644',
    addressNomenclature: '0',
    x: '26.255631',
    y: '42.340598',
    servingDays: '0101000',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '47696',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЕЖДЕН',
    nameEn: 'MEZHDEN',
    municipality: 'ДУЛОВО',
    municipalityEn: 'DULOVO',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7672',
    addressNomenclature: '0',
    x: '27.203663',
    y: '43.813228',
    servingDays: '0101000',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '47706',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЕЖДЕНИ',
    nameEn: 'MEZHDENI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5343',
    addressNomenclature: '0',
    x: '25.33959',
    y: '42.955083',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '94085',
    countryId: '100',
    mainSiteId: '0',
    type: 'к.',
    typeEn: 'k.',
    name: 'МЕЖДУНАРОДЕН МЛАДЕЖКИ ЦЕНТЪР-ПРИМОРСКО',
    nameEn: 'MEZHDUNARODEN MLADEZHKI TSENTAR-PRIMORSKO',
    municipality: 'ПРИМОРСКО',
    municipalityEn: 'PRIMORSKO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '',
    addressNomenclature: '0',
    x: '27.750929',
    y: '42.250354',
    servingDays: '1111110',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '47714',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'МЕЗДРА',
    nameEn: 'MEZDRA',
    municipality: 'МЕЗДРА',
    municipalityEn: 'MEZDRA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3100',
    addressNomenclature: '2',
    x: '23.707465',
    y: '43.142495',
    servingDays: '1111100',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '47723',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЕЗДРЕЯ',
    nameEn: 'MEZDREYA',
    municipality: 'БЕРКОВИЦА',
    municipalityEn: 'BERKOVITSA',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3515',
    addressNomenclature: '0',
    x: '23.18822',
    y: '43.242824',
    servingDays: '1111100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '47737',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЕЗЕК',
    nameEn: 'MEZEK',
    municipality: 'СВИЛЕНГРАД',
    municipalityEn: 'SVILENGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6521',
    addressNomenclature: '0',
    x: '26.089769',
    y: '41.738998',
    servingDays: '1111100',
    servingOfficeId: '48',
    servingHubOfficeId: '5',
  },
  {
    id: '47754',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'МЕЛНИК',
    nameEn: 'MELNIK',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2820',
    addressNomenclature: '0',
    x: '23.390978',
    y: '41.522813',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '47768',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЕЛНИЦА',
    nameEn: 'MELNITSA',
    municipality: 'ЕЛХОВО',
    municipalityEn: 'ELHOVO',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8755',
    addressNomenclature: '0',
    x: '26.565628',
    y: '42.046912',
    servingDays: '1010100',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '47771',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЕЛЯНЕ',
    nameEn: 'MELYANE',
    municipality: 'ГЕОРГИ ДАМЯНОВО',
    municipalityEn: 'GEORGI DAMYANOVO',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3472',
    addressNomenclature: '0',
    x: '23.017449',
    y: '43.360662',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '47785',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЕНГИШЕВО',
    nameEn: 'MENGISHEVO',
    municipality: 'ВЪРБИЦА',
    municipalityEn: 'VARBITSA',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9885',
    addressNomenclature: '0',
    x: '26.637961',
    y: '43.036086',
    servingDays: '1111100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '47799',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЕНДОВО',
    nameEn: 'MENDOVO',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2893',
    addressNomenclature: '0',
    x: '23.117419',
    y: '41.420994',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '47812',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЕНЕНКЬОВО',
    nameEn: 'MENENKYOVO',
    municipality: 'БЕЛОВО',
    municipalityEn: 'BELOVO',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4482',
    addressNomenclature: '0',
    x: '24.057823',
    y: '42.229354',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '47826',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЕРДАНЯ',
    nameEn: 'MERDANYA',
    municipality: 'ЛЯСКОВЕЦ',
    municipalityEn: 'LYASKOVETS',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5147',
    addressNomenclature: '0',
    x: '25.784186',
    y: '43.057024',
    servingDays: '1111100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '47843',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'МЕРИЧЛЕРИ',
    nameEn: 'MERICHLERI',
    municipality: 'ДИМИТРОВГРАД',
    municipalityEn: 'DIMITROVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6430',
    addressNomenclature: '0',
    x: '25.499251',
    y: '42.132906',
    servingDays: '1010100',
    servingOfficeId: '42',
    servingHubOfficeId: '5',
  },
  {
    id: '47857',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЕСТА',
    nameEn: 'MESTA',
    municipality: 'БАНСКО',
    municipalityEn: 'BANSKO',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2772',
    addressNomenclature: '0',
    x: '23.671507',
    y: '41.766307',
    servingDays: '0101000',
    servingOfficeId: '125',
    servingHubOfficeId: '13',
  },
  {
    id: '47860',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЕТЛИКА',
    nameEn: 'METLIKA',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6938',
    addressNomenclature: '0',
    x: '25.551152',
    y: '41.426877',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '47874',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЕТЛИЧИНА',
    nameEn: 'METLICHINA',
    municipality: 'ВЪЛЧИ ДОЛ',
    municipalityEn: 'VALCHI DOL',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9291',
    addressNomenclature: '0',
    x: '27.510776',
    y: '43.473482',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '47888',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЕТЛИЧИНА',
    nameEn: 'METLICHINA',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6873',
    addressNomenclature: '0',
    x: '25.343607',
    y: '41.406336',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '47891',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЕТЛИЧКА',
    nameEn: 'METLICHKA',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6880',
    addressNomenclature: '0',
    x: '25.437251',
    y: '41.431511',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '47901',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЕТОДИЕВО',
    nameEn: 'METODIEVO',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9375',
    addressNomenclature: '0',
    x: '27.976538',
    y: '43.616522',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '47915',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЕТОДИЕВО',
    nameEn: 'METODIEVO',
    municipality: 'ВЪРБИЦА',
    municipalityEn: 'VARBITSA',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9867',
    addressNomenclature: '0',
    x: '26.688435',
    y: '43.119655',
    servingDays: '1111100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '47929',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЕТОХИЯ',
    nameEn: 'METOHIA',
    municipality: 'ТРЕКЛЯНО',
    municipalityEn: 'TREKLYANO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2556',
    addressNomenclature: '0',
    x: '22.485863',
    y: '42.573922',
    servingDays: '0101000',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '47963',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЕЧКА',
    nameEn: 'MECHKA',
    municipality: 'ПЛЕВЕН',
    municipalityEn: 'PLEVEN',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5843',
    addressNomenclature: '0',
    x: '24.802563',
    y: '43.522601',
    servingDays: '0101000',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '47977',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЕЧКА',
    nameEn: 'MECHKA',
    municipality: 'ИВАНОВО',
    municipalityEn: 'IVANOVO',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7091',
    addressNomenclature: '0',
    x: '25.830812',
    y: '43.707325',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '47980',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЕЧКАРЕВО',
    nameEn: 'MECHKAREVO',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8868',
    addressNomenclature: '0',
    x: '26.277033',
    y: '42.593644',
    servingDays: '1010100',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '47994',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЕЧКОВИЦА',
    nameEn: 'MECHKOVITSA',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5307',
    addressNomenclature: '0',
    x: '25.244756',
    y: '42.846117',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '48009',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЕЧКОВЦИ',
    nameEn: 'MECHKOVTSI',
    municipality: 'ИХТИМАН',
    municipalityEn: 'IHTIMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2060',
    addressNomenclature: '0',
    x: '23.760328',
    y: '42.559228',
    servingDays: '0101000',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '48012',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЕЧКУЛ',
    nameEn: 'MECHKUL',
    municipality: 'СИМИТЛИ',
    municipalityEn: 'SIMITLI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2754',
    addressNomenclature: '0',
    x: '23.199941',
    y: '41.824595',
    servingDays: '1111110',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '48026',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЕЧОВО',
    nameEn: 'MECHOVO',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7997',
    addressNomenclature: '0',
    x: '26.090583',
    y: '43.058292',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '48037',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЕЩИЦА',
    nameEn: 'MESHTITSA',
    municipality: 'ПЕРНИК',
    municipalityEn: 'PERNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2353',
    addressNomenclature: '0',
    x: '23.001332',
    y: '42.666414',
    servingDays: '1111100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '48043',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'МИЗИЯ',
    nameEn: 'MIZIA',
    municipality: 'МИЗИЯ',
    municipalityEn: 'MIZIA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3330',
    addressNomenclature: '2',
    x: '23.854675',
    y: '43.691564',
    servingDays: '1111100',
    servingOfficeId: '64',
    servingHubOfficeId: '20',
  },
  {
    id: '48057',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИЙКОВЦИ',
    nameEn: 'MIYKOVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5098',
    addressNomenclature: '0',
    x: '25.945946',
    y: '42.846198',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '48060',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИКРЕ',
    nameEn: 'MIKRE',
    municipality: 'УГЪРЧИН',
    municipalityEn: 'UGARCHIN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5572',
    addressNomenclature: '0',
    x: '24.522109',
    y: '43.033693',
    servingDays: '1010100',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '49686',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИКРЕВО',
    nameEn: 'MIKREVO',
    municipality: 'СТРУМЯНИ',
    municipalityEn: 'STRUMYANI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2826',
    addressNomenclature: '0',
    x: '23.19757',
    y: '41.624547',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '48074',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИЛАДИНОВО',
    nameEn: 'MILADINOVO',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6660',
    addressNomenclature: '0',
    x: '25.607309',
    y: '41.702705',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '48088',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИЛАДИНОВЦИ',
    nameEn: 'MILADINOVTSI',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9431',
    addressNomenclature: '0',
    x: '27.63288',
    y: '43.633132',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '48091',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИЛАДИНОВЦИ',
    nameEn: 'MILADINOVTSI',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7787',
    addressNomenclature: '0',
    x: '26.481912',
    y: '43.399743',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '48101',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИЛАДИНОВЦИ',
    nameEn: 'MILADINOVTSI',
    municipality: 'ТУНДЖА',
    municipalityEn: 'TUNDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8657',
    addressNomenclature: '0',
    x: '26.344369',
    y: '42.259858',
    servingDays: '1010100',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '48129',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИЛАНОВО',
    nameEn: 'MILANOVO',
    municipality: 'СВОГЕ',
    municipalityEn: 'SVOGE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2280',
    addressNomenclature: '0',
    x: '23.392602',
    y: '43.115313',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '48132',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИЛАНОВО',
    nameEn: 'MILANOVO',
    municipality: 'ВЕЛИКИ ПРЕСЛАВ',
    municipalityEn: 'VELIKI PRESLAV',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9859',
    addressNomenclature: '0',
    x: '26.870729',
    y: '43.177986',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '48146',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИЛЕ',
    nameEn: 'MILE',
    municipality: 'МАДАН',
    municipalityEn: 'MADAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4921',
    addressNomenclature: '0',
    x: '24.991616',
    y: '41.584707',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '48152',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИЛЕВО',
    nameEn: 'MILEVO',
    municipality: 'САДОВО',
    municipalityEn: 'SADOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4131',
    addressNomenclature: '0',
    x: '25.094351',
    y: '42.150339',
    servingDays: '1010100',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '48177',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИЛЕВЦИ',
    nameEn: 'MILEVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5350',
    addressNomenclature: '0',
    x: '25.516217',
    y: '42.851654',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '48180',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИЛЕНЧА',
    nameEn: 'MILENCHA',
    municipality: 'ТРОЯН',
    municipalityEn: 'TROYAN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '',
    addressNomenclature: '0',
    x: '24.782249',
    y: '42.860349',
    servingDays: '1010100',
    servingOfficeId: '44',
    servingHubOfficeId: '11',
  },
  {
    id: '48194',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИЛИНО',
    nameEn: 'MILINO',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7984',
    addressNomenclature: '0',
    x: '26.262041',
    y: '43.052114',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '48204',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИЛКОВИЦА',
    nameEn: 'MILKOVITSA',
    municipality: 'ГУЛЯНЦИ',
    municipalityEn: 'GULYANTSI',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5968',
    addressNomenclature: '0',
    x: '24.742696',
    y: '43.633153',
    servingDays: '1010100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '48218',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИЛКОВО',
    nameEn: 'MILKOVO',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4779',
    addressNomenclature: '0',
    x: '24.682029',
    y: '41.519802',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '48235',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИЛКОВЦИ',
    nameEn: 'MILKOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5345',
    addressNomenclature: '0',
    x: '25.243016',
    y: '42.912509',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '48249',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИЛКЬОВЦИ',
    nameEn: 'MILKYOVTSI',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2475',
    addressNomenclature: '0',
    x: '22.698537',
    y: '42.754593',
    servingDays: '1111100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '48252',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИЛОСЛАВЦИ',
    nameEn: 'MILOSLAVTSI',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2478',
    addressNomenclature: '0',
    x: '22.534588',
    y: '42.834123',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '48266',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИЛЧИНА ЛЪКА',
    nameEn: 'MILCHINA LAKA',
    municipality: 'ГРАМАДА',
    municipalityEn: 'GRAMADA',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3846',
    addressNomenclature: '0',
    x: '22.687586',
    y: '43.887429',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '48278',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИНДЯ',
    nameEn: 'MINDYA',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5044',
    addressNomenclature: '0',
    x: '25.839992',
    y: '43.017304',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '48283',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИНЕВЦИ',
    nameEn: 'MINEVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5083',
    addressNomenclature: '0',
    x: '26.015601',
    y: '42.925314',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '48297',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИНЕРАЛНИ БАНИ',
    nameEn: 'MINERALNI BANI',
    municipality: 'МИНЕРАЛНИ БАНИ',
    municipalityEn: 'MINERALNI BANI',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6343',
    addressNomenclature: '2',
    x: '25.350204',
    y: '41.937401',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '48307',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИНЗУХАР',
    nameEn: 'MINZUHAR',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6665',
    addressNomenclature: '0',
    x: '25.402247',
    y: '41.772038',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '48324',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИРКОВО',
    nameEn: 'MIRKOVO',
    municipality: 'МИРКОВО',
    municipalityEn: 'MIRKOVO',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2086',
    addressNomenclature: '2',
    x: '23.991005',
    y: '42.694579',
    servingDays: '1111100',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '48338',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИРОВЕЦ',
    nameEn: 'MIROVETS',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7751',
    addressNomenclature: '0',
    x: '26.692827',
    y: '43.375665',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '48341',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИРОВО',
    nameEn: 'MIROVO',
    municipality: 'СТРАЖИЦА',
    municipalityEn: 'STRAZHITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5158',
    addressNomenclature: '0',
    x: '26.051536',
    y: '43.239343',
    servingDays: '0101000',
    servingOfficeId: '46',
    servingHubOfficeId: '11',
  },
  {
    id: '48369',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИРОВО',
    nameEn: 'MIROVO',
    municipality: 'ИХТИМАН',
    municipalityEn: 'IHTIMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2059',
    addressNomenclature: '0',
    x: '23.875305',
    y: '42.383082',
    servingDays: '1010100',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '48372',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИРОВО',
    nameEn: 'MIROVO',
    municipality: 'БРАТЯ ДАСКАЛОВИ',
    municipalityEn: 'BRATYA DASKALOVI',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6255',
    addressNomenclature: '0',
    x: '25.140543',
    y: '42.172901',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '48386',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИРОВЦИ',
    nameEn: 'MIROVTSI',
    municipality: 'НОВИ ПАЗАР',
    municipalityEn: 'NOVI PAZAR',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9921',
    addressNomenclature: '0',
    x: '27.23726',
    y: '43.449679',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '48393',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИРОВЯНЕ',
    nameEn: 'MIROVYANE',
    municipality: 'СТОЛИЧНА',
    municipalityEn: 'STOLICHNA',
    region: 'СОФИЯ (СТОЛИЦА)',
    regionEn: 'SOFIA (STOLITSA)',
    postCode: '1289',
    addressNomenclature: '2',
    x: '23.303353',
    y: '42.790955',
    servingDays: '1111100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '48409',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИРОЛЮБОВО',
    nameEn: 'MIROLYUBOVO',
    municipality: 'БУРГАС',
    municipalityEn: 'BURGAS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8119',
    addressNomenclature: '0',
    x: '27.363609',
    y: '42.644758',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '48430',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИРЧОВЦИ',
    nameEn: 'MIRCHOVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5094',
    addressNomenclature: '0',
    x: '25.818308',
    y: '42.885084',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '48444',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИРЯНЦИ',
    nameEn: 'MIRYANTSI',
    municipality: 'ПАЗАРДЖИК',
    municipalityEn: 'PAZARDZHIK',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4443',
    addressNomenclature: '0',
    x: '24.36456',
    y: '42.159201',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '48458',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИТИНО',
    nameEn: 'MITINO',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2857',
    addressNomenclature: '0',
    x: '23.253725',
    y: '41.430683',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '48461',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИТОВСКА',
    nameEn: 'MITOVSKA',
    municipality: 'МАДАН',
    municipalityEn: 'MADAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4909',
    addressNomenclature: '0',
    x: '24.965391',
    y: '41.51141',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '48475',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИТРОВЦИ',
    nameEn: 'MITROVTSI',
    municipality: 'ЧИПРОВЦИ',
    municipalityEn: 'CHIPROVTSI',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3464',
    addressNomenclature: '0',
    x: '22.935371',
    y: '43.440808',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '48492',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИХАЙЛОВО',
    nameEn: 'MIHAYLOVO',
    municipality: 'ХАЙРЕДИН',
    municipalityEn: 'HAYREDIN',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3355',
    addressNomenclature: '0',
    x: '23.597195',
    y: '43.578224',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '48502',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИХАЙЛОВО',
    nameEn: 'MIHAYLOVO',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6051',
    addressNomenclature: '0',
    x: '25.527052',
    y: '42.249395',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '48516',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИХАЙЛОВЦИ',
    nameEn: 'MIHAYLOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5347',
    addressNomenclature: '0',
    x: '25.183524',
    y: '42.895898',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '48524',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИХАЛИЧ',
    nameEn: 'MIHALICH',
    municipality: 'ВЪЛЧИ ДОЛ',
    municipalityEn: 'VALCHI DOL',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9284',
    addressNomenclature: '0',
    x: '27.459782',
    y: '43.404707',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '48533',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИХАЛИЧ',
    nameEn: 'MIHALICH',
    municipality: 'СВИЛЕНГРАД',
    municipalityEn: 'SVILENGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6537',
    addressNomenclature: '0',
    x: '26.417772',
    y: '41.856013',
    servingDays: '0101000',
    servingOfficeId: '48',
    servingHubOfficeId: '5',
  },
  {
    id: '48547',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИХАЛКОВО',
    nameEn: 'MIHALKOVO',
    municipality: 'ДЕВИН',
    municipalityEn: 'DEVIN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4820',
    addressNomenclature: '0',
    x: '24.426372',
    y: '41.846549',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '48550',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИХАЛЦИ',
    nameEn: 'MIHALTSI',
    municipality: 'ПАВЛИКЕНИ',
    municipalityEn: 'PAVLIKENI',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5248',
    addressNomenclature: '0',
    x: '25.359098',
    y: '43.18975',
    servingDays: '1111110',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '48564',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИХИЛЦИ',
    nameEn: 'MIHILTSI',
    municipality: 'ХИСАРЯ',
    municipalityEn: 'HISARYA',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4361',
    addressNomenclature: '0',
    x: '24.80106',
    y: '42.523836',
    servingDays: '1010100',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '48578',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИХНЕВО',
    nameEn: 'MIHNEVO',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2862',
    addressNomenclature: '0',
    x: '23.192762',
    y: '41.45762',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '48581',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИХОВЦИ',
    nameEn: 'MIHOVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5365',
    addressNomenclature: '0',
    x: '25.587888',
    y: '42.876095',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '48605',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИЧКОВЦИ',
    nameEn: 'MICHKOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5343',
    addressNomenclature: '0',
    x: '25.344175',
    y: '42.934387',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '48622',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИШЕВСКО',
    nameEn: 'MISHEVSKO',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6840',
    addressNomenclature: '0',
    x: '25.240347',
    y: '41.526702',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '48636',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИШЕМОРКОВ ХАН',
    nameEn: 'MISHEMORKOV HAN',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5035',
    addressNomenclature: '0',
    x: '25.649298',
    y: '42.816412',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '48640',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МИШКАРЕТЕ',
    nameEn: 'MISHKARETE',
    municipality: 'ЯБЛАНИЦА',
    municipalityEn: 'YABLANITSA',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '',
    addressNomenclature: '0',
    x: '23.977994',
    y: '43.101915',
    servingDays: '1010100',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '48653',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЛАДА ГВАРДИЯ',
    nameEn: 'MLADA GVARDIA',
    municipality: 'ВЕТРИНО',
    municipalityEn: 'VETRINO',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9228',
    addressNomenclature: '0',
    x: '27.365398',
    y: '43.289947',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '48667',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЛАДЕЖКО',
    nameEn: 'MLADEZHKO',
    municipality: 'МАЛКО ТЪРНОВО',
    municipalityEn: 'MALKO TARNOVO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8173',
    addressNomenclature: '0',
    x: '27.369666',
    y: '42.152823',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '48670',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЛАДЕН',
    nameEn: 'MLADEN',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5428',
    addressNomenclature: '0',
    x: '25.189556',
    y: '43.090128',
    servingDays: '0101000',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '48698',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЛАДИНОВО',
    nameEn: 'MLADINOVO',
    municipality: 'СВИЛЕНГРАД',
    municipalityEn: 'SVILENGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6531',
    addressNomenclature: '0',
    x: '26.232187',
    y: '41.938748',
    servingDays: '0101000',
    servingOfficeId: '48',
    servingHubOfficeId: '5',
  },
  {
    id: '48708',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЛАДОВО',
    nameEn: 'MLADOVO',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8886',
    addressNomenclature: '0',
    x: '26.211041',
    y: '42.529042',
    servingDays: '1010100',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '48711',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЛАМОЛОВО',
    nameEn: 'MLAMOLOVO',
    municipality: 'БОБОВ ДОЛ',
    municipalityEn: 'BOBOV DOL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2654',
    addressNomenclature: '0',
    x: '23.02513',
    y: '42.344024',
    servingDays: '1010100',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '48725',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЛЕКАРЕВО',
    nameEn: 'MLEKAREVO',
    municipality: 'НОВА ЗАГОРА',
    municipalityEn: 'NOVA ZAGORA',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8960',
    addressNomenclature: '0',
    x: '26.088144',
    y: '42.356739',
    servingDays: '1111100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '48742',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЛЕЧЕВО',
    nameEn: 'MLECHEVO',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5441',
    addressNomenclature: '0',
    x: '24.914422',
    y: '42.915849',
    servingDays: '1010100',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '48756',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЛЕЧИНО',
    nameEn: 'MLECHINO',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6781',
    addressNomenclature: '0',
    x: '25.173421',
    y: '41.625031',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '48765',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МОГИЛА',
    nameEn: 'MOGILA',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6052',
    addressNomenclature: '0',
    x: '25.718858',
    y: '42.389704',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '48773',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МОГИЛА',
    nameEn: 'MOGILA',
    municipality: 'КАСПИЧАН',
    municipalityEn: 'KASPICHAN',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9932',
    addressNomenclature: '0',
    x: '27.19914',
    y: '43.278324',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '48787',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МОГИЛА',
    nameEn: 'MOGILA',
    municipality: 'ТУНДЖА',
    municipalityEn: 'TUNDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8627',
    addressNomenclature: '0',
    x: '26.596871',
    y: '42.501566',
    servingDays: '1010100',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '48790',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МОГИЛЕЦ',
    nameEn: 'MOGILETS',
    municipality: 'ОМУРТАГ',
    municipalityEn: 'OMURTAG',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7943',
    addressNomenclature: '0',
    x: '26.519713',
    y: '43.022949',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '30380',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МОГИЛИНО',
    nameEn: 'MOGILINO',
    municipality: 'ДВЕ МОГИЛИ',
    municipalityEn: 'DVE MOGILI',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7165',
    addressNomenclature: '0',
    x: '25.976991',
    y: '43.444273',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '48800',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МОГИЛИТЕ',
    nameEn: 'MOGILITE',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5350',
    addressNomenclature: '0',
    x: '25.488479',
    y: '42.843101',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '48814',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МОГИЛИЦА',
    nameEn: 'MOGILITSA',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4761',
    addressNomenclature: '0',
    x: '24.635193',
    y: '41.495737',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '48828',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МОГИЛИЩЕ',
    nameEn: 'MOGILISHTE',
    municipality: 'КАВАРНА',
    municipalityEn: 'KAVARNA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9665',
    addressNomenclature: '0',
    x: '28.34265',
    y: '43.487851',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '48831',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МОГИЛОВО',
    nameEn: 'MOGILOVO',
    municipality: 'ЧИРПАН',
    municipalityEn: 'CHIRPAN',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6239',
    addressNomenclature: '0',
    x: '25.398956',
    y: '42.34146',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '48845',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МОГИЛЯНЕ',
    nameEn: 'MOGILYANE',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6867',
    addressNomenclature: '0',
    x: '25.230843',
    y: '41.370555',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '68151',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МОДРЕН',
    nameEn: 'MODREN',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '',
    addressNomenclature: '0',
    x: '25.251054',
    y: '41.545915',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '45',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МОКРЕН',
    nameEn: 'MOKREN',
    municipality: 'КОТЕЛ',
    municipalityEn: 'KOTEL',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8994',
    addressNomenclature: '0',
    x: '26.63201',
    y: '42.739735',
    servingDays: '1010100',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '48859',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МОКРЕШ',
    nameEn: 'MOKRESH',
    municipality: 'ВЪЛЧЕДРЪМ',
    municipalityEn: 'VALCHEDRAM',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3647',
    addressNomenclature: '0',
    x: '23.39571',
    y: '43.750702',
    servingDays: '0101000',
    servingOfficeId: '831',
    servingHubOfficeId: '20',
  },
  {
    id: '48862',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МОКРЕШ',
    nameEn: 'MOKRESH',
    municipality: 'ВЕЛИКИ ПРЕСЛАВ',
    municipalityEn: 'VELIKI PRESLAV',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9861',
    addressNomenclature: '0',
    x: '26.889262',
    y: '43.140388',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '48876',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МОКРИЩЕ',
    nameEn: 'MOKRISHTE',
    municipality: 'ПАЗАРДЖИК',
    municipalityEn: 'PAZARDZHIK',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4408',
    addressNomenclature: '0',
    x: '24.293756',
    y: '42.176407',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '50245',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'МОМИН ПРОХОД',
    nameEn: 'MOMIN PROHOD',
    municipality: 'КОСТЕНЕЦ',
    municipalityEn: 'KOSTENETS',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2035',
    addressNomenclature: '2',
    x: '23.870553',
    y: '42.327359',
    servingDays: '1010100',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '48951',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МОМИН СБОР',
    nameEn: 'MOMIN SBOR',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5067',
    addressNomenclature: '0',
    x: '25.489874',
    y: '43.096696',
    servingDays: '1111110',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '48903',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МОМИНА КЛИСУРА',
    nameEn: 'MOMINA KLISURA',
    municipality: 'БЕЛОВО',
    municipalityEn: 'BELOVO',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4468',
    addressNomenclature: '0',
    x: '23.978797',
    y: '42.227246',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '48917',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МОМИНА СЪЛЗА',
    nameEn: 'MOMINA SALZA',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6830',
    addressNomenclature: '0',
    x: '25.526698',
    y: '41.59485',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '29221',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МОМИНА ЦЪРКВА',
    nameEn: 'MOMINA TSARKVA',
    municipality: 'СРЕДЕЦ',
    municipalityEn: 'SREDETS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8320',
    addressNomenclature: '0',
    x: '27.014386',
    y: '42.142289',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '48920',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МОМИНО',
    nameEn: 'MOMINO',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7706',
    addressNomenclature: '0',
    x: '26.475372',
    y: '43.265782',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '48934',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МОМИНО',
    nameEn: 'MOMINO',
    municipality: 'ХАСКОВО',
    municipalityEn: 'HASKOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6387',
    addressNomenclature: '0',
    x: '25.728339',
    y: '41.852029',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '48948',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МОМИНО СЕЛО',
    nameEn: 'MOMINO SELO',
    municipality: 'РАКОВСКИ',
    municipalityEn: 'RAKOVSKI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4143',
    addressNomenclature: '0',
    x: '24.891854',
    y: '42.309805',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '48965',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МОМИНСКО',
    nameEn: 'MOMINSKO',
    municipality: 'САДОВО',
    municipalityEn: 'SADOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4118',
    addressNomenclature: '0',
    x: '24.914146',
    y: '42.073522',
    servingDays: '1010100',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '48979',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МОМКОВО',
    nameEn: 'MOMKOVO',
    municipality: 'СВИЛЕНГРАД',
    municipalityEn: 'SVILENGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6542',
    addressNomenclature: '0',
    x: '26.14856',
    y: '41.846792',
    servingDays: '1010100',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '48996',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'МОМЧИЛГРАД',
    nameEn: 'MOMCHILGRAD',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6800',
    addressNomenclature: '2',
    x: '25.409261',
    y: '41.527418',
    servingDays: '1111110',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '49000',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МОМЧИЛОВО',
    nameEn: 'MOMCHILOVO',
    municipality: 'ВЕТРИНО',
    municipalityEn: 'VETRINO',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9221',
    addressNomenclature: '0',
    x: '27.423',
    y: '43.365151',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '49014',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МОМЧИЛОВЦИ',
    nameEn: 'MOMCHILOVTSI',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4750',
    addressNomenclature: '0',
    x: '24.774532',
    y: '41.656164',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '48489',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'МОНТАНА',
    nameEn: 'MONTANA',
    municipality: 'МОНТАНА',
    municipalityEn: 'MONTANA',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3400',
    addressNomenclature: '2',
    x: '23.224242',
    y: '43.405487',
    servingDays: '1111110',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '49028',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МОРАВА',
    nameEn: 'MORAVA',
    municipality: 'СВИЩОВ',
    municipalityEn: 'SVISHTOV',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5291',
    addressNomenclature: '0',
    x: '25.212203',
    y: '43.451883',
    servingDays: '0101000',
    servingOfficeId: '250',
    servingHubOfficeId: '11',
  },
  {
    id: '49031',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МОРАВИЦА',
    nameEn: 'MORAVITSA',
    municipality: 'МЕЗДРА',
    municipalityEn: 'MEZDRA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3145',
    addressNomenclature: '0',
    x: '23.659263',
    y: '43.149527',
    servingDays: '1010100',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '49045',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МОРАВИЦА',
    nameEn: 'MORAVITSA',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7974',
    addressNomenclature: '0',
    x: '26.117013',
    y: '43.161539',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '49059',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МОРАВКА',
    nameEn: 'MORAVKA',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7992',
    addressNomenclature: '0',
    x: '26.334578',
    y: '43.178398',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '49062',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МОРОВЕЦИТЕ',
    nameEn: 'MOROVETSITE',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5304',
    addressNomenclature: '0',
    x: '25.246875',
    y: '42.833317',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '49093',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МОРТАГОНОВО',
    nameEn: 'MORTAGONOVO',
    municipality: 'РАЗГРАД',
    municipalityEn: 'RAZGRAD',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7248',
    addressNomenclature: '0',
    x: '26.647603',
    y: '43.526383',
    servingDays: '1010100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '49103',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МОРЯНЦИ',
    nameEn: 'MORYANTSI',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6959',
    addressNomenclature: '0',
    x: '25.697677',
    y: '41.588821',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '49117',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МОСКОВЕЦ',
    nameEn: 'MOSKOVETS',
    municipality: 'КАРЛОВО',
    municipalityEn: 'KARLOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4333',
    addressNomenclature: '0',
    x: '24.694118',
    y: '42.654758',
    servingDays: '1010100',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '49432',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МОСОМИЩЕ',
    nameEn: 'MOSOMISHTE',
    municipality: 'ГОЦЕ ДЕЛЧЕВ',
    municipalityEn: 'GOTSE DELCHEV',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2920',
    addressNomenclature: '0',
    x: '23.747576',
    y: '41.553113',
    servingDays: '1111100',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '49120',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МОСТ',
    nameEn: 'MOST',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6670',
    addressNomenclature: '0',
    x: '25.520131',
    y: '41.736575',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '49148',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МОСТИЧ',
    nameEn: 'MOSTICH',
    municipality: 'ВЕЛИКИ ПРЕСЛАВ',
    municipalityEn: 'VELIKI PRESLAV',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9878',
    addressNomenclature: '0',
    x: '26.764011',
    y: '43.189562',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '49151',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МОСТОВО',
    nameEn: 'MOSTOVO',
    municipality: 'АСЕНОВГРАД',
    municipalityEn: 'ASENOVGRAD',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4257',
    addressNomenclature: '0',
    x: '24.937059',
    y: '41.849703',
    servingDays: '0101000',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '49165',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МОЧУРЕ',
    nameEn: 'MOCHURE',
    municipality: 'РУДОЗЕМ',
    municipalityEn: 'RUDOZEM',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4978',
    addressNomenclature: '0',
    x: '24.76384',
    y: '41.405243',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '49179',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МОЩАНЕЦ',
    nameEn: 'MOSHTANETS',
    municipality: 'БЛАГОЕВГРАД',
    municipalityEn: 'BLAGOEVGRAD',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2729',
    addressNomenclature: '0',
    x: '23.072347',
    y: '41.966954',
    servingDays: '1111110',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '49182',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МРАКЕТИНЦИ',
    nameEn: 'MRAKETINTSI',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2477',
    addressNomenclature: '0',
    x: '22.74377',
    y: '42.81145',
    servingDays: '1111100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '49196',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МРАМОР',
    nameEn: 'MRAMOR',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2487',
    addressNomenclature: '0',
    x: '22.611515',
    y: '42.751942',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '49206',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МРАМОР',
    nameEn: 'MRAMOR',
    municipality: 'СТОЛИЧНА',
    municipalityEn: 'STOLICHNA',
    region: 'СОФИЯ (СТОЛИЦА)',
    regionEn: 'SOFIA (STOLITSA)',
    postCode: '1261',
    addressNomenclature: '2',
    x: '23.27373',
    y: '42.784669',
    servingDays: '1111100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '49219',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МРАМОР',
    nameEn: 'MRAMOR',
    municipality: 'ТОПОЛОВГРАД',
    municipalityEn: 'TOPOLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6562',
    addressNomenclature: '0',
    x: '26.399231',
    y: '42.04768',
    servingDays: '0101000',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '49223',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МРАМОРЕН',
    nameEn: 'MRAMOREN',
    municipality: 'ВРАЦА',
    municipalityEn: 'VRATSA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3054',
    addressNomenclature: '0',
    x: '23.670218',
    y: '43.296967',
    servingDays: '1010100',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '49237',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МРАХОРИ',
    nameEn: 'MRAHORI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5345',
    addressNomenclature: '0',
    x: '25.271143',
    y: '42.914223',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '49240',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МРАЧЕНИК',
    nameEn: 'MRACHENIK',
    municipality: 'КАРЛОВО',
    municipalityEn: 'KARLOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4362',
    addressNomenclature: '0',
    x: '24.962773',
    y: '42.49821',
    servingDays: '1010100',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '49254',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МРЕЖИЧКО',
    nameEn: 'MREZHICHKO',
    municipality: 'РУЕН',
    municipalityEn: 'RUEN',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8538',
    addressNomenclature: '0',
    x: '27.40994',
    y: '42.863245',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '49268',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МРЕЖИЧКО',
    nameEn: 'MREZHICHKO',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6845',
    addressNomenclature: '0',
    x: '25.269351',
    y: '41.463378',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '49271',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МРЪЗЕЦИ',
    nameEn: 'MRAZETSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5367',
    addressNomenclature: '0',
    x: '25.550743',
    y: '42.791647',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '49285',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МУГЛА',
    nameEn: 'MUGLA',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4716',
    addressNomenclature: '0',
    x: '24.493164',
    y: '41.616496',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '49299',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МУЗГА',
    nameEn: 'MUZGA',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5333',
    addressNomenclature: '0',
    x: '25.145244',
    y: '42.897522',
    servingDays: '1010100',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '49309',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МУЛДАВА',
    nameEn: 'MULDAVA',
    municipality: 'АСЕНОВГРАД',
    municipalityEn: 'ASENOVGRAD',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4249',
    addressNomenclature: '0',
    x: '24.941292',
    y: '41.987266',
    servingDays: '1010100',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '49326',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МУРГА',
    nameEn: 'MURGA',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6725',
    addressNomenclature: '0',
    x: '25.134951',
    y: '41.813409',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '49334',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МУРГАШ',
    nameEn: 'MURGASH',
    municipality: 'ГОДЕЧ',
    municipalityEn: 'GODECH',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2247',
    addressNomenclature: '0',
    x: '23.002325',
    y: '42.986337',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '49343',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МУРГОВО',
    nameEn: 'MURGOVO',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6679',
    addressNomenclature: '0',
    x: '25.480995',
    y: '41.704068',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '49357',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МУРЕНО',
    nameEn: 'MURENO',
    municipality: 'ЗЕМЕН',
    municipalityEn: 'ZEMEN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2437',
    addressNomenclature: '0',
    x: '22.719831',
    y: '42.626823',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '49360',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МУРСАЛЕВО',
    nameEn: 'MURSALEVO',
    municipality: 'КОЧЕРИНОВО',
    municipalityEn: 'KOCHERINOVO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2623',
    addressNomenclature: '0',
    x: '23.038567',
    y: '42.118942',
    servingDays: '1111100',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '49374',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МУРТИНЦИ',
    nameEn: 'MURTINTSI',
    municipality: 'БРЕЗНИК',
    municipalityEn: 'BREZNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2382',
    addressNomenclature: '0',
    x: '22.8331',
    y: '42.774129',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '49388',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МУСАЧЕВО',
    nameEn: 'MUSACHEVO',
    municipality: 'ЕЛИН ПЕЛИН',
    municipalityEn: 'ELIN PELIN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2139',
    addressNomenclature: '0',
    x: '23.568464',
    y: '42.68145',
    servingDays: '1111100',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '49391',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МУСАЧЕВО',
    nameEn: 'MUSACHEVO',
    municipality: 'ГЪЛЪБОВО',
    municipalityEn: 'GALABOVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6284',
    addressNomenclature: '0',
    x: '25.828906',
    y: '42.107113',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '49401',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МУСЕВО',
    nameEn: 'MUSEVO',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6774',
    addressNomenclature: '0',
    x: '25.017463',
    y: '41.569393',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '49415',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МУСЕЛИЕВО',
    nameEn: 'MUSELIEVO',
    municipality: 'НИКОПОЛ',
    municipalityEn: 'NIKOPOL',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5963',
    addressNomenclature: '0',
    x: '24.852284',
    y: '43.636991',
    servingDays: '0101000',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '49429',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МУСИНА',
    nameEn: 'MUSINA',
    municipality: 'ПАВЛИКЕНИ',
    municipalityEn: 'PAVLIKENI',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5247',
    addressNomenclature: '0',
    x: '25.427407',
    y: '43.155887',
    servingDays: '1111110',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '49446',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МУСТРАК',
    nameEn: 'MUSTRAK',
    municipality: 'СВИЛЕНГРАД',
    municipalityEn: 'SVILENGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6527',
    addressNomenclature: '0',
    x: '26.304937',
    y: '41.865055',
    servingDays: '0101000',
    servingOfficeId: '48',
    servingHubOfficeId: '5',
  },
  {
    id: '49450',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МУХОВО',
    nameEn: 'MUHOVO',
    municipality: 'ИХТИМАН',
    municipalityEn: 'IHTIMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2061',
    addressNomenclature: '0',
    x: '23.995886',
    y: '42.421248',
    servingDays: '0101000',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '49463',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МУЦЯ',
    nameEn: 'MUTSYA',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5370',
    addressNomenclature: '0',
    x: '25.501577',
    y: '42.959221',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '49477',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЪГЛЕН',
    nameEn: 'MAGLEN',
    municipality: 'АЙТОС',
    municipalityEn: 'AYTOS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8523',
    addressNomenclature: '0',
    x: '27.370218',
    y: '42.72728',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '49480',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЪГЛЕНЕ',
    nameEn: 'MAGLENE',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6865',
    addressNomenclature: '0',
    x: '25.252159',
    y: '41.409837',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '49494',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'МЪГЛИЖ',
    nameEn: 'MAGLIZH',
    municipality: 'МЪГЛИЖ',
    municipalityEn: 'MAGLIZH',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6180',
    addressNomenclature: '2',
    x: '25.54752',
    y: '42.606776',
    servingDays: '1111100',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '49504',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЪГЛИЩА',
    nameEn: 'MAGLISHTA',
    municipality: 'МАДАН',
    municipalityEn: 'MADAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4910',
    addressNomenclature: '0',
    x: '24.91217',
    y: '41.504373',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '49518',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЪДРЕВО',
    nameEn: 'MADREVO',
    municipality: 'КУБРАТ',
    municipalityEn: 'KUBRAT',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7323',
    addressNomenclature: '0',
    x: '26.561463',
    y: '43.873698',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '49521',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЪДРЕЦ',
    nameEn: 'MADRETS',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6674',
    addressNomenclature: '0',
    x: '25.518392',
    y: '41.692069',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '49535',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЪДРЕЦ',
    nameEn: 'MADRETS',
    municipality: 'ГЪЛЪБОВО',
    municipalityEn: 'GALABOVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6292',
    addressNomenclature: '0',
    x: '26.086729',
    y: '42.131101',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '49549',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЪДРИНО',
    nameEn: 'MADRINO',
    municipality: 'КАРНОБАТ',
    municipalityEn: 'KARNOBAT',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8444',
    addressNomenclature: '0',
    x: '26.90417',
    y: '42.708646',
    servingDays: '0101000',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '49566',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЪРВОДОЛ',
    nameEn: 'MARVODOL',
    municipality: 'НЕВЕСТИНО',
    municipalityEn: 'NEVESTINO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2595',
    addressNomenclature: '0',
    x: '22.908416',
    y: '42.231097',
    servingDays: '1010100',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '49575',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЪРЗЯН',
    nameEn: 'MARZYAN',
    municipality: 'ЗЛАТОГРАД',
    municipalityEn: 'ZLATOGRAD',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4996',
    addressNomenclature: '0',
    x: '24.936345',
    y: '41.418235',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '49583',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЪРТВИНАТА',
    nameEn: 'MARTVINATA',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5094',
    addressNomenclature: '0',
    x: '25.786778',
    y: '42.888653',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '49597',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЪРЧАЕВО',
    nameEn: 'MARCHAEVO',
    municipality: 'СТОЛИЧНА',
    municipalityEn: 'STOLICHNA',
    region: 'СОФИЯ (СТОЛИЦА)',
    regionEn: 'SOFIA (STOLITSA)',
    postCode: '1696',
    addressNomenclature: '2',
    x: '23.168421',
    y: '42.601081',
    servingDays: '1111100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '49607',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЪРЧЕВО',
    nameEn: 'MARCHEVO',
    municipality: 'БОЙЧИНОВЦИ',
    municipalityEn: 'BOYCHINOVTSI',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3443',
    addressNomenclature: '0',
    x: '23.348181',
    y: '43.514269',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '49624',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'МЪТЕНИЦА',
    nameEn: 'MATENITSA',
    municipality: 'ХИСАРЯ',
    municipalityEn: 'HISARYA',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4176',
    addressNomenclature: '0',
    x: '24.61239',
    y: '42.507846',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '51010',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НАВЪСЕН',
    nameEn: 'NAVASEN',
    municipality: 'СИМЕОНОВГРАД',
    municipalityEn: 'SIMEONOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6497',
    addressNomenclature: '0',
    x: '25.883728',
    y: '42.10017',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '51024',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НАДАРЕВО',
    nameEn: 'NADAREVO',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7740',
    addressNomenclature: '0',
    x: '26.754863',
    y: '43.238461',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '51038',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НАДАРЦИ',
    nameEn: 'NADARTSI',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4758',
    addressNomenclature: '0',
    x: '24.62354',
    y: '41.527217',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '51041',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НАДЕЖДЕН',
    nameEn: 'NADEZHDEN',
    municipality: 'ХАРМАНЛИ',
    municipalityEn: 'HARMANLI',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6459',
    addressNomenclature: '0',
    x: '25.927472',
    y: '41.882468',
    servingDays: '1010100',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '51069',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НАЙДЕН ГЕРОВО',
    nameEn: 'NAYDEN GEROVO',
    municipality: 'СЪЕДИНЕНИЕ',
    municipalityEn: 'SAEDINENIE',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4185',
    addressNomenclature: '0',
    x: '24.490689',
    y: '42.341772',
    servingDays: '0101000',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '51072',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НАЙДЕНОВО',
    nameEn: 'NAYDENOVO',
    municipality: 'БРАТЯ ДАСКАЛОВИ',
    municipalityEn: 'BRATYA DASKALOVI',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6224',
    addressNomenclature: '0',
    x: '25.261182',
    y: '42.379592',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '51086',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НАНЕ',
    nameEn: 'NANE',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6800',
    addressNomenclature: '0',
    x: '25.461669',
    y: '41.430035',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '51097',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НАНОВИЦА',
    nameEn: 'NANOVITSA',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6830',
    addressNomenclature: '0',
    x: '25.568589',
    y: '41.550602',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '51127',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НАРЕЧЕНСКИ БАНИ',
    nameEn: 'NARECHENSKI BANI',
    municipality: 'АСЕНОВГРАД',
    municipalityEn: 'ASENOVGRAD',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4239',
    addressNomenclature: '0',
    x: '24.74817',
    y: '41.900682',
    servingDays: '0101000',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '51130',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НАСАЛЕВЦИ',
    nameEn: 'NASALEVTSI',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2478',
    addressNomenclature: '0',
    x: '22.506144',
    y: '42.823227',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '51158',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НАУМ',
    nameEn: 'NAUM',
    municipality: 'КАОЛИНОВО',
    municipalityEn: 'KAOLINOVO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9962',
    addressNomenclature: '0',
    x: '27.018268',
    y: '43.608142',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '51161',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НАУЧЕНЕ',
    nameEn: 'NAUCHENE',
    municipality: 'НОВА ЗАГОРА',
    municipalityEn: 'NOVA ZAGORA',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8929',
    addressNomenclature: '0',
    x: '26.08945',
    y: '42.564771',
    servingDays: '1010100',
    servingOfficeId: '56',
    servingHubOfficeId: '5',
  },
  {
    id: '51175',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НАЦОВЦИ',
    nameEn: 'NATSOVTSI',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5055',
    addressNomenclature: '0',
    x: '25.621762',
    y: '42.967828',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '51189',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НАЧЕВО',
    nameEn: 'NACHEVO',
    municipality: 'ДРАГОМАН',
    municipalityEn: 'DRAGOMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2213',
    addressNomenclature: '0',
    x: '22.821806',
    y: '42.880281',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '51192',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НЕБЕСКА',
    nameEn: 'NEBESKA',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6693',
    addressNomenclature: '0',
    x: '25.189302',
    y: '41.773148',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '35136',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НЕВЕСТИНО',
    nameEn: 'NEVESTINO',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6722',
    addressNomenclature: '0',
    x: '25.373867',
    y: '41.672144',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '51202',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НЕВЕСТИНО',
    nameEn: 'NEVESTINO',
    municipality: 'КАРНОБАТ',
    municipalityEn: 'KARNOBAT',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8431',
    addressNomenclature: '0',
    x: '26.942636',
    y: '42.730594',
    servingDays: '0101000',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '51216',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НЕВЕСТИНО',
    nameEn: 'NEVESTINO',
    municipality: 'НЕВЕСТИНО',
    municipalityEn: 'NEVESTINO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2595',
    addressNomenclature: '0',
    x: '22.849268',
    y: '42.254208',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '51233',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НЕВША',
    nameEn: 'NEVSHA',
    municipality: 'ВЕТРИНО',
    municipalityEn: 'VETRINO',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9229',
    addressNomenclature: '0',
    x: '27.29942',
    y: '43.27118',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '51250',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НЕГОВАН',
    nameEn: 'NEGOVAN',
    municipality: 'СТОЛИЧНА',
    municipalityEn: 'STOLICHNA',
    region: 'СОФИЯ (СТОЛИЦА)',
    regionEn: 'SOFIA (STOLITSA)',
    postCode: '1222',
    addressNomenclature: '2',
    x: '23.404574',
    y: '42.769212',
    servingDays: '1111100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '51264',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НЕГОВАНОВЦИ',
    nameEn: 'NEGOVANOVTSI',
    municipality: 'НОВО СЕЛО',
    municipalityEn: 'NOVO SELO',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3774',
    addressNomenclature: '0',
    x: '22.861493',
    y: '44.091773',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '51278',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НЕГОВАНЦИ',
    nameEn: 'NEGOVANTSI',
    municipality: 'РАДОМИР',
    municipalityEn: 'RADOMIR',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2417',
    addressNomenclature: '0',
    x: '22.949821',
    y: '42.456586',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '51281',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НЕГУШЕВО',
    nameEn: 'NEGUSHEVO',
    municipality: 'ГОРНА МАЛИНА',
    municipalityEn: 'GORNA MALINA',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2123',
    addressNomenclature: '0',
    x: '23.706673',
    y: '42.726464',
    servingDays: '0101000',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '51295',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НЕДАН',
    nameEn: 'NEDAN',
    municipality: 'ПАВЛИКЕНИ',
    municipalityEn: 'PAVLIKENI',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5231',
    addressNomenclature: '0',
    x: '25.267143',
    y: '43.303603',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '51305',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НЕДЕЛЕВО',
    nameEn: 'NEDELEVO',
    municipality: 'СЪЕДИНЕНИЕ',
    municipalityEn: 'SAEDINENIE',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4187',
    addressNomenclature: '0',
    x: '24.613656',
    y: '42.345905',
    servingDays: '0101000',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '51319',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'НЕДЕЛИНО',
    nameEn: 'NEDELINO',
    municipality: 'НЕДЕЛИНО',
    municipalityEn: 'NEDELINO',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4990',
    addressNomenclature: '2',
    x: '25.076953',
    y: '41.459749',
    servingDays: '1111100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '51322',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НЕДЕЛИЩЕ',
    nameEn: 'NEDELISHTE',
    municipality: 'ДРАГОМАН',
    municipalityEn: 'DRAGOMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2213',
    addressNomenclature: '0',
    x: '22.80535',
    y: '42.892245',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '51336',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НЕДЕЛКОВА ГРАЩИЦА',
    nameEn: 'NEDELKOVA GRASHTITSA',
    municipality: 'НЕВЕСТИНО',
    municipalityEn: 'NEVESTINO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2594',
    addressNomenclature: '0',
    x: '22.801002',
    y: '42.237247',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '51343',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НЕДЕЛКОВО',
    nameEn: 'NEDELKOVO',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2477',
    addressNomenclature: '0',
    x: '22.786049',
    y: '42.797539',
    servingDays: '1111100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '51353',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НЕДОКЛАН',
    nameEn: 'NEDOKLAN',
    municipality: 'РАЗГРАД',
    municipalityEn: 'RAZGRAD',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7251',
    addressNomenclature: '0',
    x: '26.598365',
    y: '43.548222',
    servingDays: '0101000',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '51367',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НЕДЯЛКОВЦИ',
    nameEn: 'NEDYALKOVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5070',
    addressNomenclature: '0',
    x: '25.836611',
    y: '42.963422',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '51370',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НЕДЯЛКОВЦИ',
    nameEn: 'NEDYALKOVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5350',
    addressNomenclature: '0',
    x: '25.487212',
    y: '42.893477',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '51384',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НЕДЯЛСКО',
    nameEn: 'NEDYALSKO',
    municipality: 'СТРАЛДЖА',
    municipalityEn: 'STRALDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8684',
    addressNomenclature: '0',
    x: '26.823743',
    y: '42.462684',
    servingDays: '0101000',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '51398',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НЕЙКОВО',
    nameEn: 'NEYKOVO',
    municipality: 'КОТЕЛ',
    municipalityEn: 'KOTEL',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8993',
    addressNomenclature: '0',
    x: '26.368123',
    y: '42.810018',
    servingDays: '0101000',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '51408',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НЕЙКОВО',
    nameEn: 'NEYKOVO',
    municipality: 'КАВАРНА',
    municipalityEn: 'KAVARNA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9692',
    addressNomenclature: '0',
    x: '28.425929',
    y: '43.608632',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '51411',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НЕЙКЬОВЕЦ',
    nameEn: 'NEYKYOVETS',
    municipality: 'ИХТИМАН',
    municipalityEn: 'IHTIMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2061',
    addressNomenclature: '0',
    x: '24.035036',
    y: '42.431697',
    servingDays: '0101000',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '51439',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НЕЙЧОВЦИ',
    nameEn: 'NEYCHOVTSI',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5380',
    addressNomenclature: '0',
    x: '25.447613',
    y: '42.949365',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '51442',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НЕНКОВО',
    nameEn: 'NENKOVO',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6611',
    addressNomenclature: '0',
    x: '25.212987',
    y: '41.739469',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '51456',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НЕНОВО',
    nameEn: 'NENOVO',
    municipality: 'ПРОВАДИЯ',
    municipalityEn: 'PROVADIA',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9234',
    addressNomenclature: '0',
    x: '27.283802',
    y: '43.222655',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '51469',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НЕНОВЦИ',
    nameEn: 'NENOVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5367',
    addressNomenclature: '0',
    x: '25.538202',
    y: '42.838537',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '51473',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НЕОФИТ БОЗВЕЛИЕВО',
    nameEn: 'NEOFIT BOZVELIEVO',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6831',
    addressNomenclature: '0',
    x: '25.562641',
    y: '41.531357',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '51487',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НЕОФИТ РИЛСКИ',
    nameEn: 'NEOFIT RILSKI',
    municipality: 'ВЕТРИНО',
    municipalityEn: 'VETRINO',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9223',
    addressNomenclature: '0',
    x: '27.516159',
    y: '43.276206',
    servingDays: '1111100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '51490',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НЕПРАЗНЕНЦИ',
    nameEn: 'NEPRAZNENTSI',
    municipality: 'БРЕЗНИК',
    municipalityEn: 'BREZNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2396',
    addressNomenclature: '0',
    x: '22.844992',
    y: '42.671038',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '51500',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'НЕСЕБЪР',
    nameEn: 'NESEBAR',
    municipality: 'НЕСЕБЪР',
    municipalityEn: 'NESEBAR',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8230',
    addressNomenclature: '1',
    x: '27.725834',
    y: '42.659522',
    servingDays: '1111110',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '51514',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НЕСЛА',
    nameEn: 'NESLA',
    municipality: 'ДРАГОМАН',
    municipalityEn: 'DRAGOMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2213',
    addressNomenclature: '0',
    x: '22.806273',
    y: '42.916301',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '51528',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НЕФЕЛА',
    nameEn: 'NEFELA',
    municipality: 'ВРАЦА',
    municipalityEn: 'VRATSA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3039',
    addressNomenclature: '0',
    x: '23.514123',
    y: '43.251071',
    servingDays: '1111100',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '51531',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НЕШЕВЦИ',
    nameEn: 'NESHEVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5097',
    addressNomenclature: '0',
    x: '25.908846',
    y: '42.87366',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '51559',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НИВЯНИН',
    nameEn: 'NIVYANIN',
    municipality: 'БОРОВАН',
    municipalityEn: 'BOROVAN',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3241',
    addressNomenclature: '0',
    x: '23.800755',
    y: '43.377318',
    servingDays: '0101000',
    servingOfficeId: '59',
    servingHubOfficeId: '20',
  },
  {
    id: '51562',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НИКАЧКОВЦИ',
    nameEn: 'NIKACHKOVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5365',
    addressNomenclature: '0',
    x: '25.590841',
    y: '42.873784',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '51651',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НИКОЛА КОЗЛЕВО',
    nameEn: 'NIKOLA KOZLEVO',
    municipality: 'НИКОЛА КОЗЛЕВО',
    municipalityEn: 'NIKOLA KOZLEVO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9955',
    addressNomenclature: '0',
    x: '27.228253',
    y: '43.572122',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '51584',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НИКОЛАЕВКА',
    nameEn: 'NIKOLAEVKA',
    municipality: 'СУВОРОВО',
    municipalityEn: 'SUVOROVO',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9172',
    addressNomenclature: '0',
    x: '27.674578',
    y: '43.354518',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '51593',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НИКОЛАЕВО',
    nameEn: 'NIKOLAEVO',
    municipality: 'СТРАЖИЦА',
    municipalityEn: 'STRAZHITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5168',
    addressNomenclature: '0',
    x: '25.979007',
    y: '43.299101',
    servingDays: '0101000',
    servingOfficeId: '46',
    servingHubOfficeId: '11',
  },
  {
    id: '51603',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НИКОЛАЕВО',
    nameEn: 'NIKOLAEVO',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5351',
    addressNomenclature: '0',
    x: '25.431628',
    y: '42.861174',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '51617',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НИКОЛАЕВО',
    nameEn: 'NIKOLAEVO',
    municipality: 'РАДОМИР',
    municipalityEn: 'RADOMIR',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2406',
    addressNomenclature: '0',
    x: '22.934425',
    y: '42.552878',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '51620',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НИКОЛАЕВО',
    nameEn: 'NIKOLAEVO',
    municipality: 'ПЛЕВЕН',
    municipalityEn: 'PLEVEN',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5874',
    addressNomenclature: '0',
    x: '24.604209',
    y: '43.256251',
    servingDays: '0101000',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '51634',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НИКОЛАЕВО',
    nameEn: 'NIKOLAEVO',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8873',
    addressNomenclature: '0',
    x: '26.267802',
    y: '42.541104',
    servingDays: '1010100',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '51648',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'НИКОЛАЕВО',
    nameEn: 'NIKOLAEVO',
    municipality: 'НИКОЛАЕВО',
    municipalityEn: 'NIKOLAEVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6190',
    addressNomenclature: '2',
    x: '25.79433',
    y: '42.63105',
    servingDays: '1111100',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '52400',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НИКОЛИЧЕВЦИ',
    nameEn: 'NIKOLICHEVTSI',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2599',
    addressNomenclature: '0',
    x: '22.713312',
    y: '42.323161',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '51665',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НИКОЛОВО',
    nameEn: 'NIKOLOVO',
    municipality: 'МОНТАНА',
    municipalityEn: 'MONTANA',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3429',
    addressNomenclature: '0',
    x: '23.2744',
    y: '43.420751',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '51679',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НИКОЛОВО',
    nameEn: 'NIKOLOVO',
    municipality: 'РУСЕ',
    municipalityEn: 'RUSE',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7057',
    addressNomenclature: '0',
    x: '26.102192',
    y: '43.862973',
    servingDays: '1111100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '51682',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НИКОЛОВО',
    nameEn: 'NIKOLOVO',
    municipality: 'ХАСКОВО',
    municipalityEn: 'HASKOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6364',
    addressNomenclature: '0',
    x: '25.409965',
    y: '41.82884',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '51696',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НИКОЛОВЦИ',
    nameEn: 'NIKOLOVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5070',
    addressNomenclature: '0',
    x: '25.848821',
    y: '42.961979',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '51706',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НИКОЛЧОВЦИ',
    nameEn: 'NIKOLCHOVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5098',
    addressNomenclature: '0',
    x: '25.914787',
    y: '42.849782',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '51715',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НИКОЛЧОВЦИ',
    nameEn: 'NIKOLCHOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5345',
    addressNomenclature: '0',
    x: '25.232165',
    y: '42.883367',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '51723',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'НИКОПОЛ',
    nameEn: 'NIKOPOL',
    municipality: 'НИКОПОЛ',
    municipalityEn: 'NIKOPOL',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5940',
    addressNomenclature: '2',
    x: '24.895207',
    y: '43.70531',
    servingDays: '1111100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '51737',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НИКУДИН',
    nameEn: 'NIKUDIN',
    municipality: 'СТРУМЯНИ',
    municipalityEn: 'STRUMYANI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2839',
    addressNomenclature: '0',
    x: '23.043501',
    y: '41.566149',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '51740',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НИКЮП',
    nameEn: 'NIKYUP',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5068',
    addressNomenclature: '0',
    x: '25.588401',
    y: '43.243411',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '51768',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НИСОВО',
    nameEn: 'NISOVO',
    municipality: 'ИВАНОВО',
    municipalityEn: 'IVANOVO',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7079',
    addressNomenclature: '0',
    x: '26.067616',
    y: '43.651468',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '51771',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НИЧОВЦИ',
    nameEn: 'NICHOVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5097',
    addressNomenclature: '0',
    x: '25.905118',
    y: '42.899352',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '51785',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВА БЯЛА РЕКА',
    nameEn: 'NOVA BYALA REKA',
    municipality: 'ВЪРБИЦА',
    municipalityEn: 'VARBITSA',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9883',
    addressNomenclature: '0',
    x: '26.734019',
    y: '42.999141',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '51799',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВА ВЪРБОВКА',
    nameEn: 'NOVA VARBOVKA',
    municipality: 'СТРАЖИЦА',
    municipalityEn: 'STRAZHITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5169',
    addressNomenclature: '0',
    x: '25.923907',
    y: '43.339037',
    servingDays: '1010100',
    servingOfficeId: '46',
    servingHubOfficeId: '11',
  },
  {
    id: '51809',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'НОВА ЗАГОРА',
    nameEn: 'NOVA ZAGORA',
    municipality: 'НОВА ЗАГОРА',
    municipalityEn: 'NOVA ZAGORA',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8900',
    addressNomenclature: '2',
    x: '26.012751',
    y: '42.493328',
    servingDays: '1111110',
    servingOfficeId: '56',
    servingHubOfficeId: '5',
  },
  {
    id: '51812',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВА КАМЕНА',
    nameEn: 'NOVA KAMENA',
    municipality: 'ТЕРВЕЛ',
    municipalityEn: 'TERVEL',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9493',
    addressNomenclature: '0',
    x: '27.292298',
    y: '43.703312',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '51857',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВА ЛИВАДА',
    nameEn: 'NOVA LIVADA',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6595',
    addressNomenclature: '0',
    x: '25.935747',
    y: '41.494558',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '51860',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВА ЛОВЧА',
    nameEn: 'NOVA LOVCHA',
    municipality: 'ХАДЖИДИМОВО',
    municipalityEn: 'HADZHIDIMOVO',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2925',
    addressNomenclature: '0',
    x: '23.720719',
    y: '41.420433',
    servingDays: '1010100',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '51874',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВА МАХАЛА',
    nameEn: 'NOVA MAHALA',
    municipality: 'БАТАК',
    municipalityEn: 'BATAK',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4581',
    addressNomenclature: '0',
    x: '24.267416',
    y: '41.934582',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '51888',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВА МАХАЛА',
    nameEn: 'NOVA MAHALA',
    municipality: 'НИКОЛАЕВО',
    municipalityEn: 'NIKOLAEVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6191',
    addressNomenclature: '0',
    x: '25.774197',
    y: '42.622732',
    servingDays: '1010100',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '51891',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВА НАДЕЖДА',
    nameEn: 'NOVA NADEZHDA',
    municipality: 'ХАСКОВО',
    municipalityEn: 'HASKOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6330',
    addressNomenclature: '0',
    x: '25.721778',
    y: '42.017026',
    servingDays: '0101000',
    servingOfficeId: '42',
    servingHubOfficeId: '5',
  },
  {
    id: '51901',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВА ПОПИНА',
    nameEn: 'NOVA POPINA',
    municipality: 'СИТОВО',
    municipalityEn: 'SITOVO',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7582',
    addressNomenclature: '0',
    x: '26.955284',
    y: '44.027125',
    servingDays: '1010100',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '51956',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВА ЧЕРНА',
    nameEn: 'NOVA CHERNA',
    municipality: 'ТУТРАКАН',
    municipalityEn: 'TUTRAKAN',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7645',
    addressNomenclature: '0',
    x: '26.465463',
    y: '43.993268',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '51963',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВА ШИПКА',
    nameEn: 'NOVA SHIPKA',
    municipality: 'ДОЛНИ ЧИФЛИК',
    municipalityEn: 'DOLNI CHIFLIK',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9116',
    addressNomenclature: '0',
    x: '27.523277',
    y: '43.056455',
    servingDays: '1111100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '51826',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВАКОВО',
    nameEn: 'NOVAKOVO',
    municipality: 'АКСАКОВО',
    municipalityEn: 'AKSAKOVO',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9149',
    addressNomenclature: '0',
    x: '27.829',
    y: '43.355109',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '51830',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВАКОВО',
    nameEn: 'NOVAKOVO',
    municipality: 'АСЕНОВГРАД',
    municipalityEn: 'ASENOVGRAD',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4262',
    addressNomenclature: '0',
    x: '25.066123',
    y: '41.892611',
    servingDays: '0101000',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '51843',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВАКОВЦИ',
    nameEn: 'NOVAKOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5332',
    addressNomenclature: '0',
    x: '25.197766',
    y: '42.919341',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '51929',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВАЧЕВО',
    nameEn: 'NOVACHEVO',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8853',
    addressNomenclature: '0',
    x: '26.156934',
    y: '42.749822',
    servingDays: '0101000',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '51932',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВАЧЕНЕ',
    nameEn: 'NOVACHENE',
    municipality: 'НИКОПОЛ',
    municipalityEn: 'NIKOPOL',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5950',
    addressNomenclature: '0',
    x: '24.931989',
    y: '43.553093',
    servingDays: '1010100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '51946',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВАЧЕНЕ',
    nameEn: 'NOVACHENE',
    municipality: 'БОТЕВГРАД',
    municipalityEn: 'BOTEVGRAD',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2147',
    addressNomenclature: '0',
    x: '23.725772',
    y: '42.976867',
    servingDays: '0101000',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '51977',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВГРАД',
    nameEn: 'NOVGRAD',
    municipality: 'ЦЕНОВО',
    municipalityEn: 'TSENOVO',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7133',
    addressNomenclature: '0',
    x: '25.586109',
    y: '43.605672',
    servingDays: '1010100',
    servingOfficeId: '250',
    servingHubOfficeId: '11',
  },
  {
    id: '32398',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВИ ИЗВОР',
    nameEn: 'NOVI IZVOR',
    municipality: 'АСЕНОВГРАД',
    municipalityEn: 'ASENOVGRAD',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4265',
    addressNomenclature: '0',
    x: '25.052294',
    y: '41.975529',
    servingDays: '1010100',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '357',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'НОВИ ИСКЪР',
    nameEn: 'NOVI ISKAR',
    municipality: 'СТОЛИЧНА',
    municipalityEn: 'STOLICHNA',
    region: 'СОФИЯ (СТОЛИЦА)',
    regionEn: 'SOFIA (STOLITSA)',
    postCode: '1280',
    addressNomenclature: '2',
    x: '23.352629',
    y: '42.816421',
    servingDays: '1111110',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '51994',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВИ ПАЗАР',
    nameEn: 'NOVI PAZAR',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6710',
    addressNomenclature: '0',
    x: '25.345955',
    y: '41.752786',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '52009',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'НОВИ ПАЗАР',
    nameEn: 'NOVI PAZAR',
    municipality: 'НОВИ ПАЗАР',
    municipalityEn: 'NOVI PAZAR',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9900',
    addressNomenclature: '2',
    x: '27.195551',
    y: '43.346798',
    servingDays: '1111100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '52012',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВИ ХАН',
    nameEn: 'NOVI HAN',
    municipality: 'ЕЛИН ПЕЛИН',
    municipalityEn: 'ELIN PELIN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2110',
    addressNomenclature: '2',
    x: '23.592636',
    y: '42.605684',
    servingDays: '1111110',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '52026',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВИ ЧИФЛИК',
    nameEn: 'NOVI CHIFLIK',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2531',
    addressNomenclature: '0',
    x: '22.806048',
    y: '42.26695',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '52038',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВО БОТЕВО',
    nameEn: 'NOVO BOTEVO',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9356',
    addressNomenclature: '0',
    x: '27.712052',
    y: '43.482117',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '52043',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВО БЪРДО',
    nameEn: 'NOVO BARDO',
    municipality: 'ДРАГОМАН',
    municipalityEn: 'DRAGOMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2212',
    addressNomenclature: '0',
    x: '22.844705',
    y: '42.969732',
    servingDays: '1111100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '52060',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВО ГРАДИЩЕ',
    nameEn: 'NOVO GRADISHTE',
    municipality: 'СТРАЖИЦА',
    municipalityEn: 'STRAZHITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5175',
    addressNomenclature: '0',
    x: '26.058782',
    y: '43.21154',
    servingDays: '0101000',
    servingOfficeId: '46',
    servingHubOfficeId: '11',
  },
  {
    id: '52074',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВО ДЕЛЧЕВО',
    nameEn: 'NOVO DELCHEVO',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2814',
    addressNomenclature: '0',
    x: '23.285003',
    y: '41.504445',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '52088',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВО ЖЕЛЕЗАРЕ',
    nameEn: 'NOVO ZHELEZARE',
    municipality: 'ХИСАРЯ',
    municipalityEn: 'HISARYA',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4167',
    addressNomenclature: '0',
    x: '24.663774',
    y: '42.421198',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '52091',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВО КОНОМЛАДИ',
    nameEn: 'NOVO KONOMLADI',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2874',
    addressNomenclature: '0',
    x: '23.331516',
    y: '41.455008',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '52101',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВО ЛЕСКИ',
    nameEn: 'NOVO LESKI',
    municipality: 'ХАДЖИДИМОВО',
    municipalityEn: 'HADZHIDIMOVO',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2923',
    addressNomenclature: '0',
    x: '23.781912',
    y: '41.53414',
    servingDays: '1111100',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '52115',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВО ОРЯХОВО',
    nameEn: 'NOVO ORYAHOVO',
    municipality: 'ДОЛНИ ЧИФЛИК',
    municipalityEn: 'DOLNI CHIFLIK',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9113',
    addressNomenclature: '0',
    x: '27.838919',
    y: '42.987047',
    servingDays: '0101010',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '52129',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВО ПАНИЧАРЕВО',
    nameEn: 'NOVO PANICHAREVO',
    municipality: 'ПРИМОРСКО',
    municipalityEn: 'PRIMORSKO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8152',
    addressNomenclature: '0',
    x: '27.555699',
    y: '42.286408',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '52177',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВО СЕЛО',
    nameEn: 'NOVO SELO',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5069',
    addressNomenclature: '0',
    x: '25.36416',
    y: '43.077937',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '52180',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВО СЕЛО',
    nameEn: 'NOVO SELO',
    municipality: 'НОВО СЕЛО',
    municipalityEn: 'NOVO SELO',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3784',
    addressNomenclature: '0',
    x: '22.786124',
    y: '44.158763',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '52194',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВО СЕЛО',
    nameEn: 'NOVO SELO',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2534',
    addressNomenclature: '0',
    x: '22.679465',
    y: '42.193672',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '52221',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВО СЕЛО',
    nameEn: 'NOVO SELO',
    municipality: 'СТАМБОЛИЙСКИ',
    municipalityEn: 'STAMBOLIYSKI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4221',
    addressNomenclature: '0',
    x: '24.483515',
    y: '42.104343',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '52235',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВО СЕЛО',
    nameEn: 'NOVO SELO',
    municipality: 'РУСЕ',
    municipalityEn: 'RUSE',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7052',
    addressNomenclature: '0',
    x: '26.18754',
    y: '43.803269',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '52249',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВО СЕЛО',
    nameEn: 'NOVO SELO',
    municipality: 'САМОКОВ',
    municipalityEn: 'SAMOKOV',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2016',
    addressNomenclature: '0',
    x: '23.634169',
    y: '42.392864',
    servingDays: '0101000',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '52252',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВО СЕЛО',
    nameEn: 'NOVO SELO',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6062',
    addressNomenclature: '0',
    x: '25.505219',
    y: '42.469433',
    servingDays: '1111110',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '52307',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВО ХОДЖОВО',
    nameEn: 'NOVO HODZHOVO',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2831',
    addressNomenclature: '0',
    x: '23.400018',
    y: '41.407591',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '52310',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВО ЯНКОВО',
    nameEn: 'NOVO YANKOVO',
    municipality: 'СМЯДОВО',
    municipalityEn: 'SMYADOVO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9825',
    addressNomenclature: '0',
    x: '27.170759',
    y: '43.066677',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '52057',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВОГОРЦИ',
    nameEn: 'NOVOGORTSI',
    municipality: 'ЗЛАТАРИЦА',
    municipalityEn: 'ZLATARITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5088',
    addressNomenclature: '0',
    x: '26.093078',
    y: '43.022039',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '52132',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВОСЕЛ',
    nameEn: 'NOVOSEL',
    municipality: 'ШУМЕН',
    municipalityEn: 'SHUMEN',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9807',
    addressNomenclature: '0',
    x: '26.812301',
    y: '43.287621',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '52146',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВОСЕЛЕЦ',
    nameEn: 'NOVOSELETS',
    municipality: 'НОВА ЗАГОРА',
    municipalityEn: 'NOVA ZAGORA',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8958',
    addressNomenclature: '0',
    x: '26.090251',
    y: '42.297696',
    servingDays: '1111100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '52153',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВОСЕЛИЩЕ',
    nameEn: 'NOVOSELISHTE',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6697',
    addressNomenclature: '0',
    x: '25.245747',
    y: '41.777694',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '52283',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВОСЕЛЦИ',
    nameEn: 'NOVOSELTSI',
    municipality: 'ВИДИН',
    municipalityEn: 'VIDIN',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3725',
    addressNomenclature: '0',
    x: '22.821484',
    y: '43.984021',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '52297',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОВОСЕЛЯНЕ',
    nameEn: 'NOVOSELYANE',
    municipality: 'БОБОВ ДОЛ',
    municipalityEn: 'BOBOV DOL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2689',
    addressNomenclature: '0',
    x: '22.974176',
    y: '42.321846',
    servingDays: '1010100',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '52324',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОЕВЦИ',
    nameEn: 'NOEVTSI',
    municipality: 'БРЕЗНИК',
    municipalityEn: 'BREZNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2394',
    addressNomenclature: '0',
    x: '22.872452',
    y: '42.677626',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '52338',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОЖАРЕВО',
    nameEn: 'NOZHAREVO',
    municipality: 'ГЛАВИНИЦА',
    municipalityEn: 'GLAVINITSA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7615',
    addressNomenclature: '0',
    x: '26.885469',
    y: '43.918232',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '52341',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОЖАРОВО',
    nameEn: 'NOZHAROVO',
    municipality: 'САМУИЛ',
    municipalityEn: 'SAMUIL',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7441',
    addressNomenclature: '0',
    x: '26.833449',
    y: '43.628929',
    servingDays: '1010100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '52355',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОЖЕРИТЕ',
    nameEn: 'NOZHERITE',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5360',
    addressNomenclature: '0',
    x: '25.460565',
    y: '42.811325',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '52369',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОСЕИТЕ',
    nameEn: 'NOSEITE',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5360',
    addressNomenclature: '0',
    x: '25.447405',
    y: '42.815555',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '52372',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НОЧЕВО',
    nameEn: 'NOCHEVO',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6726',
    addressNomenclature: '0',
    x: '25.192069',
    y: '41.876707',
    servingDays: '1010100',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '52386',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'НЮШКОВЦИ',
    nameEn: 'NYUSHKOVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5097',
    addressNomenclature: '0',
    x: '25.899114',
    y: '42.898084',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '53014',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОБЕДИНЕНИЕ',
    nameEn: 'OBEDINENIE',
    municipality: 'ПОЛСКИ ТРЪМБЕШ',
    municipalityEn: 'POLSKI TRAMBESH',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5195',
    addressNomenclature: '0',
    x: '25.494095',
    y: '43.336567',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '53031',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОБЕЛ',
    nameEn: 'OBEL',
    municipality: 'БЛАГОЕВГРАД',
    municipalityEn: 'BLAGOEVGRAD',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2738',
    addressNomenclature: '0',
    x: '22.913147',
    y: '41.970823',
    servingDays: '1111110',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '53045',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ОБЗОР',
    nameEn: 'OBZOR',
    municipality: 'НЕСЕБЪР',
    municipalityEn: 'NESEBAR',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8250',
    addressNomenclature: '2',
    x: '27.879809',
    y: '42.817692',
    servingDays: '1111110',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '53059',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОБИДИМ',
    nameEn: 'OBIDIM',
    municipality: 'БАНСКО',
    municipalityEn: 'BANSKO',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2776',
    addressNomenclature: '0',
    x: '23.640673',
    y: '41.787757',
    servingDays: '0101000',
    servingOfficeId: '125',
    servingHubOfficeId: '13',
  },
  {
    id: '53062',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОБИТЕЛ',
    nameEn: 'OBITEL',
    municipality: 'ОМУРТАГ',
    municipalityEn: 'OMURTAG',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7920',
    addressNomenclature: '0',
    x: '26.562722',
    y: '43.068057',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '53076',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОБИЧНИК',
    nameEn: 'OBICHNIK',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6800',
    addressNomenclature: '0',
    x: '25.48124',
    y: '41.497066',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '53089',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОБНОВА',
    nameEn: 'OBNOVA',
    municipality: 'ЛЕВСКИ',
    municipalityEn: 'LEVSKI',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5922',
    addressNomenclature: '0',
    x: '24.985885',
    y: '43.438687',
    servingDays: '1010100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '53093',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОБОРИЩЕ',
    nameEn: 'OBORISHTE',
    municipality: 'ВЪЛЧИ ДОЛ',
    municipalityEn: 'VALCHI DOL',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9147',
    addressNomenclature: '0',
    x: '27.661167',
    y: '43.447137',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '53103',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОБОРИЩЕ',
    nameEn: 'OBORISHTE',
    municipality: 'ПАНАГЮРИЩЕ',
    municipalityEn: 'PANAGYURISHTE',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4522',
    addressNomenclature: '0',
    x: '24.076981',
    y: '42.515633',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '53117',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОБРЕТЕНИК',
    nameEn: 'OBRETENIK',
    municipality: 'БОРОВО',
    municipalityEn: 'BOROVO',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7170',
    addressNomenclature: '0',
    x: '25.809845',
    y: '43.580099',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '53120',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОБРОЧИЩЕ',
    nameEn: 'OBROCHISHTE',
    municipality: 'БАЛЧИК',
    municipalityEn: 'BALCHIK',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9630',
    addressNomenclature: '0',
    x: '28.047413',
    y: '43.401895',
    servingDays: '1111110',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '53134',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОБРУЧИЩЕ',
    nameEn: 'OBRUCHISHTE',
    municipality: 'ГЪЛЪБОВО',
    municipalityEn: 'GALABOVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6290',
    addressNomenclature: '0',
    x: '25.932418',
    y: '42.14432',
    servingDays: '1111100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '53148',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОВЕН',
    nameEn: 'OVEN',
    municipality: 'ДУЛОВО',
    municipalityEn: 'DULOVO',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7671',
    addressNomenclature: '0',
    x: '27.071966',
    y: '43.802776',
    servingDays: '1010100',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '53151',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОВОЩАРЦИ',
    nameEn: 'OVOSHTARTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5342',
    addressNomenclature: '0',
    x: '25.385676',
    y: '42.884986',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '53165',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОВОЩНА',
    nameEn: 'OVOSHTNA',
    municipality: 'ЗЛАТАРИЦА',
    municipalityEn: 'ZLATARITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5088',
    addressNomenclature: '0',
    x: '26.062361',
    y: '42.995438',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '53179',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОВОЩНИК',
    nameEn: 'OVOSHTNIK',
    municipality: 'КАЗАНЛЪК',
    municipalityEn: 'KAZANLAK',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6138',
    addressNomenclature: '0',
    x: '25.447253',
    y: '42.577399',
    servingDays: '1111110',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '53196',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОВЧА МОГИЛА',
    nameEn: 'OVCHA MOGILA',
    municipality: 'СВИЩОВ',
    municipalityEn: 'SVISHTOV',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5295',
    addressNomenclature: '0',
    x: '25.256809',
    y: '43.435236',
    servingDays: '0101000',
    servingOfficeId: '250',
    servingHubOfficeId: '11',
  },
  {
    id: '53182',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОВЧАГА',
    nameEn: 'OVCHAGA',
    municipality: 'ПРОВАДИЯ',
    municipalityEn: 'PROVADIA',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9233',
    addressNomenclature: '0',
    x: '27.339945',
    y: '43.165098',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '53206',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОВЧАРИ',
    nameEn: 'OVCHARI',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6922',
    addressNomenclature: '0',
    x: '25.649299',
    y: '41.45153',
    servingDays: '1111100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '53210',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОВЧАРОВО',
    nameEn: 'OVCHAROVO',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9395',
    addressNomenclature: '0',
    x: '27.8424',
    y: '43.700513',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '53223',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОВЧАРОВО',
    nameEn: 'OVCHAROVO',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7747',
    addressNomenclature: '0',
    x: '26.650954',
    y: '43.183251',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '53237',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОВЧАРОВО',
    nameEn: 'OVCHAROVO',
    municipality: 'ХАРМАНЛИ',
    municipalityEn: 'HARMANLI',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6468',
    addressNomenclature: '0',
    x: '26.001624',
    y: '42.010208',
    servingDays: '1010100',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '53240',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОВЧАРОВО',
    nameEn: 'OVCHAROVO',
    municipality: 'ШУМЕН',
    municipalityEn: 'SHUMEN',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9805',
    addressNomenclature: '0',
    x: '27.095761',
    y: '43.20617',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '53254',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОВЧАРЦИ',
    nameEn: 'OVCHARTSI',
    municipality: 'САПАРЕВА БАНЯ',
    municipalityEn: 'SAPAREVA BANYA',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2647',
    addressNomenclature: '0',
    x: '23.224286',
    y: '42.275444',
    servingDays: '1111110',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '53268',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОВЧАРЦИ',
    nameEn: 'OVCHARTSI',
    municipality: 'РАДНЕВО',
    municipalityEn: 'RADNEVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6264',
    addressNomenclature: '0',
    x: '26.05653',
    y: '42.209056',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '53271',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОВЧЕВО',
    nameEn: 'OVCHEVO',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6846',
    addressNomenclature: '0',
    x: '25.305665',
    y: '41.455232',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '53285',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОВЧЕПОЛЦИ',
    nameEn: 'OVCHEPOLTSI',
    municipality: 'ПАЗАРДЖИК',
    municipalityEn: 'PAZARDZHIK',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4448',
    addressNomenclature: '0',
    x: '24.400561',
    y: '42.335294',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '53299',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОВЧИ КЛАДЕНЕЦ',
    nameEn: 'OVCHI KLADENETS',
    municipality: 'ТУНДЖА',
    municipalityEn: 'TUNDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8660',
    addressNomenclature: '0',
    x: '26.213968',
    y: '42.242995',
    servingDays: '0101000',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '53309',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОГЛЕД',
    nameEn: 'OGLED',
    municipality: 'РУДОЗЕМ',
    municipalityEn: 'RUDOZEM',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4972',
    addressNomenclature: '0',
    x: '24.831595',
    y: '41.444236',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '53312',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОГНЕН',
    nameEn: 'OGNEN',
    municipality: 'КАРНОБАТ',
    municipalityEn: 'KARNOBAT',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8467',
    addressNomenclature: '0',
    x: '26.8196',
    y: '42.694156',
    servingDays: '0101000',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '53326',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОГНЯНОВО',
    nameEn: 'OGNYANOVO',
    municipality: 'ГЪРМЕН',
    municipalityEn: 'GARMEN',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2947',
    addressNomenclature: '0',
    x: '23.787524',
    y: '41.611743',
    servingDays: '1111100',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '53335',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОГНЯНОВО',
    nameEn: 'OGNYANOVO',
    municipality: 'ПАЗАРДЖИК',
    municipalityEn: 'PAZARDZHIK',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4417',
    addressNomenclature: '0',
    x: '24.413863',
    y: '42.147486',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '53343',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОГНЯНОВО',
    nameEn: 'OGNYANOVO',
    municipality: 'ЕЛИН ПЕЛИН',
    municipalityEn: 'ELIN PELIN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2117',
    addressNomenclature: '0',
    x: '23.734903',
    y: '42.617503',
    servingDays: '1010100',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '53357',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОГНЯНОВО',
    nameEn: 'OGNYANOVO',
    municipality: 'КРУШАРИ',
    municipalityEn: 'KRUSHARI',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9423',
    addressNomenclature: '0',
    x: '27.664503',
    y: '43.903668',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '53374',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОГОЯ',
    nameEn: 'OGOYA',
    municipality: 'СВОГЕ',
    municipalityEn: 'SVOGE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2297',
    addressNomenclature: '0',
    x: '23.523335',
    y: '42.906216',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '53388',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОГРАДНА',
    nameEn: 'OGRADNA',
    municipality: 'НЕДЕЛИНО',
    municipalityEn: 'NEDELINO',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4990',
    addressNomenclature: '0',
    x: '25.033011',
    y: '41.505543',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '53391',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОГРАЖДЕН',
    nameEn: 'OGRAZHDEN',
    municipality: 'ГЕНЕРАЛ ТОШЕВО',
    municipalityEn: 'GENERAL TOSHEVO',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9529',
    addressNomenclature: '0',
    x: '28.100698',
    y: '43.784646',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '53401',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОДОРОВЦИ',
    nameEn: 'ODOROVTSI',
    municipality: 'БРУСАРЦИ',
    municipalityEn: 'BRUSARTSI',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3679',
    addressNomenclature: '0',
    x: '22.961959',
    y: '43.576177',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '53415',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОДРАНИЦА',
    nameEn: 'ODRANITSA',
    municipality: 'ЗЕМЕН',
    municipalityEn: 'ZEMEN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2408',
    addressNomenclature: '0',
    x: '22.64548',
    y: '42.640957',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '53429',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОДРИНЦИ',
    nameEn: 'ODRINTSI',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6584',
    addressNomenclature: '0',
    x: '26.139689',
    y: '41.434748',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '53432',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОДРИНЦИ',
    nameEn: 'ODRINTSI',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9357',
    addressNomenclature: '0',
    x: '27.704687',
    y: '43.535181',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '53446',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОДЪРНЕ',
    nameEn: 'ODARNE',
    municipality: 'ПОРДИМ',
    municipalityEn: 'PORDIM',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5893',
    addressNomenclature: '0',
    x: '24.934371',
    y: '43.340724',
    servingDays: '0101000',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '53450',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОДЪРЦИ',
    nameEn: 'ODARTSI',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9362',
    addressNomenclature: '0',
    x: '27.96305',
    y: '43.440255',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '53463',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОЗЪРНОВЦИ',
    nameEn: 'OZARNOVTSI',
    municipality: 'БРЕЗНИК',
    municipalityEn: 'BREZNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2373',
    addressNomenclature: '0',
    x: '22.891071',
    y: '42.815089',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '53477',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОКОЛИИТЕ',
    nameEn: 'OKOLIITE',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5350',
    addressNomenclature: '0',
    x: '25.475226',
    y: '42.88945',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '53480',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОКОП',
    nameEn: 'OKOP',
    municipality: 'ТУНДЖА',
    municipalityEn: 'TUNDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8669',
    addressNomenclature: '0',
    x: '26.541019',
    y: '42.410477',
    servingDays: '1111100',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '53494',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОКОРШ',
    nameEn: 'OKORSH',
    municipality: 'ДУЛОВО',
    municipalityEn: 'DULOVO',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7680',
    addressNomenclature: '0',
    x: '27.018916',
    y: '43.747913',
    servingDays: '1010100',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '53504',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОМАН',
    nameEn: 'OMAN',
    municipality: 'БОЛЯРОВО',
    municipalityEn: 'BOLYAROVO',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8727',
    addressNomenclature: '0',
    x: '26.925462',
    y: '42.267111',
    servingDays: '0101000',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '53518',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОМАРЧЕВО',
    nameEn: 'OMARCHEVO',
    municipality: 'НОВА ЗАГОРА',
    municipalityEn: 'NOVA ZAGORA',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8921',
    addressNomenclature: '0',
    x: '26.144844',
    y: '42.45791',
    servingDays: '0101000',
    servingOfficeId: '56',
    servingHubOfficeId: '5',
  },
  {
    id: '53521',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОМАРЧЕВО',
    nameEn: 'OMARCHEVO',
    municipality: 'КАОЛИНОВО',
    municipalityEn: 'KAOLINOVO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9949',
    addressNomenclature: '0',
    x: '27.051165',
    y: '43.557752',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '53535',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ОМУРТАГ',
    nameEn: 'OMURTAG',
    municipality: 'ОМУРТАГ',
    municipalityEn: 'OMURTAG',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7900',
    addressNomenclature: '2',
    x: '26.419842',
    y: '43.106854',
    servingDays: '1111100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '53549',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОНОГУР',
    nameEn: 'ONOGUR',
    municipality: 'ТЕРВЕЛ',
    municipalityEn: 'TERVEL',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9486',
    addressNomenclature: '0',
    x: '27.586141',
    y: '43.821128',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '53552',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ОПАКА',
    nameEn: 'OPAKA',
    municipality: 'ОПАКА',
    municipalityEn: 'OPAKA',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7840',
    addressNomenclature: '2',
    x: '26.165252',
    y: '43.443712',
    servingDays: '1111100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '53576',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОПАН',
    nameEn: 'OPAN',
    municipality: 'ОПАН',
    municipalityEn: 'OPAN',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6078',
    addressNomenclature: '0',
    x: '25.687841',
    y: '42.211458',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '53583',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОПАНЕЦ',
    nameEn: 'OPANETS',
    municipality: 'ПЛЕВЕН',
    municipalityEn: 'PLEVEN',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5851',
    addressNomenclature: '0',
    x: '24.577243',
    y: '43.446324',
    servingDays: '1111100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '53597',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОПАНЕЦ',
    nameEn: 'OPANETS',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9347',
    addressNomenclature: '0',
    x: '27.78002',
    y: '43.492115',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '53607',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОПИЦВЕТ',
    nameEn: 'OPITSVET',
    municipality: 'КОСТИНБРОД',
    municipalityEn: 'KOSTINBROD',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2235',
    addressNomenclature: '0',
    x: '23.097045',
    y: '42.866666',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '53610',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОПЛЕТНЯ',
    nameEn: 'OPLETNYA',
    municipality: 'СВОГЕ',
    municipalityEn: 'SVOGE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2276',
    addressNomenclature: '0',
    x: '23.446378',
    y: '43.09918',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '53624',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОПЪЛЧЕНЕЦ',
    nameEn: 'OPALCHENETS',
    municipality: 'БРАТЯ ДАСКАЛОВИ',
    municipalityEn: 'BRATYA DASKALOVI',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6254',
    addressNomenclature: '0',
    x: '25.12361',
    y: '42.206958',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '53638',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОПЪЛЧЕНСКО',
    nameEn: 'OPALCHENSKO',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6641',
    addressNomenclature: '0',
    x: '25.358905',
    y: '41.601805',
    servingDays: '1111100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '53641',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОРАЧ',
    nameEn: 'ORACH',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7977',
    addressNomenclature: '0',
    x: '26.197064',
    y: '43.111306',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '54821',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОРЕХ',
    nameEn: 'OREH',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6920',
    addressNomenclature: '0',
    x: '25.604311',
    y: '41.44478',
    servingDays: '1111100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '53655',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОРЕХОВИЦА',
    nameEn: 'OREHOVITSA',
    municipality: 'ДОЛНА МИТРОПОЛИЯ',
    municipalityEn: 'DOLNA MITROPOLIA',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5859',
    addressNomenclature: '0',
    x: '24.383107',
    y: '43.587594',
    servingDays: '1010100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '53669',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОРЕХОВО',
    nameEn: 'OREHOVO',
    municipality: 'ЧЕПЕЛАРЕ',
    municipalityEn: 'CHEPELARE',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4877',
    addressNomenclature: '0',
    x: '24.614317',
    y: '41.869632',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '53672',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОРЕШ',
    nameEn: 'ORESH',
    municipality: 'СВИЩОВ',
    municipalityEn: 'SVISHTOV',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5280',
    addressNomenclature: '0',
    x: '25.242409',
    y: '43.577639',
    servingDays: '1010100',
    servingOfficeId: '108',
    servingHubOfficeId: '11',
  },
  {
    id: '53691',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОРЕШАК',
    nameEn: 'ORESHAK',
    municipality: 'АКСАКОВО',
    municipalityEn: 'AKSAKOVO',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9151',
    addressNomenclature: '0',
    x: '27.91298',
    y: '43.301687',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '53707',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОРЕШАК',
    nameEn: 'ORESHAK',
    municipality: 'ТРОЯН',
    municipalityEn: 'TROYAN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5630',
    addressNomenclature: '0',
    x: '24.767962',
    y: '42.884045',
    servingDays: '1111100',
    servingOfficeId: '44',
    servingHubOfficeId: '11',
  },
  {
    id: '53713',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОРЕШАРИ',
    nameEn: 'ORESHARI',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6929',
    addressNomenclature: '0',
    x: '25.73796',
    y: '41.598429',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '53727',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОРЕШЕ',
    nameEn: 'ORESHE',
    municipality: 'ГЪРМЕН',
    municipalityEn: 'GARMEN',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2943',
    addressNomenclature: '0',
    x: '23.881547',
    y: '41.623576',
    servingDays: '0101000',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '53730',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОРЕШЕНЕ',
    nameEn: 'ORESHENE',
    municipality: 'ЯБЛАНИЦА',
    municipalityEn: 'YABLANITSA',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5763',
    addressNomenclature: '0',
    x: '24.07724',
    y: '43.095115',
    servingDays: '0101000',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '53744',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОРЕШЕНЕ',
    nameEn: 'ORESHENE',
    municipality: 'ДУЛОВО',
    municipalityEn: 'DULOVO',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7684',
    addressNomenclature: '0',
    x: '26.9305',
    y: '43.785469',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '53758',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОРЕШЕЦ',
    nameEn: 'ORESHETS',
    municipality: 'ДИМОВО',
    municipalityEn: 'DIMOVO',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3945',
    addressNomenclature: '0',
    x: '22.732273',
    y: '43.636328',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '53761',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОРЕШЕЦ',
    nameEn: 'ORESHETS',
    municipality: 'АСЕНОВГРАД',
    municipalityEn: 'ASENOVGRAD',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4255',
    addressNomenclature: '0',
    x: '24.941777',
    y: '41.887979',
    servingDays: '0101000',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '53775',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОРЕШЕЦ',
    nameEn: 'ORESHETS',
    municipality: 'ХАРМАНЛИ',
    municipalityEn: 'HARMANLI',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6475',
    addressNomenclature: '0',
    x: '25.907631',
    y: '41.764915',
    servingDays: '0101000',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '53792',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОРЕШЕЦ',
    nameEn: 'ORESHETS',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4727',
    addressNomenclature: '0',
    x: '24.794934',
    y: '41.60764',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '53789',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОРЕШИНО',
    nameEn: 'ORESHINO',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6579',
    addressNomenclature: '0',
    x: '26.09266',
    y: '41.456547',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '53802',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОРЕШНИК',
    nameEn: 'ORESHNIK',
    municipality: 'ТОПОЛОВГРАД',
    municipalityEn: 'TOPOLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6561',
    addressNomenclature: '0',
    x: '26.370346',
    y: '42.070927',
    servingDays: '0101000',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '53816',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОРЕШНИЦА',
    nameEn: 'ORESHNITSA',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6655',
    addressNomenclature: '0',
    x: '25.453189',
    y: '41.68672',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '53822',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОРИЗАРЕ',
    nameEn: 'ORIZARE',
    municipality: 'НЕСЕБЪР',
    municipalityEn: 'NESEBAR',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8248',
    addressNomenclature: '0',
    x: '27.619536',
    y: '42.751087',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '53833',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОРИЗАРИ',
    nameEn: 'ORIZARI',
    municipality: 'РОДОПИ',
    municipalityEn: 'RODOPI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4214',
    addressNomenclature: '0',
    x: '24.638321',
    y: '42.149024',
    servingDays: '0101000',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '53847',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОРИЗАРИ',
    nameEn: 'ORIZARI',
    municipality: 'ТВЪРДИЦА',
    municipalityEn: 'TVARDITSA',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8893',
    addressNomenclature: '0',
    x: '25.946393',
    y: '42.660802',
    servingDays: '1010100',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '53850',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОРИЗОВО',
    nameEn: 'ORIZOVO',
    municipality: 'БРАТЯ ДАСКАЛОВИ',
    municipalityEn: 'BRATYA DASKALOVI',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6253',
    addressNomenclature: '0',
    x: '25.170018',
    y: '42.209015',
    servingDays: '1111110',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '53984',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОРЛИНЦИ',
    nameEn: 'ORLINTSI',
    municipality: 'СРЕДЕЦ',
    municipalityEn: 'SREDETS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8325',
    addressNomenclature: '0',
    x: '27.032942',
    y: '42.43318',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '53878',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОРЛИЦА',
    nameEn: 'ORLITSA',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6892',
    addressNomenclature: '0',
    x: '25.404996',
    y: '41.315175',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '53895',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОРЛОВ ДОЛ',
    nameEn: 'ORLOV DOL',
    municipality: 'ТОПОЛОВГРАД',
    municipalityEn: 'TOPOLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6545',
    addressNomenclature: '0',
    x: '26.184694',
    y: '42.117555',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '53881',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОРЛОВА МОГИЛА',
    nameEn: 'ORLOVA MOGILA',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9359',
    addressNomenclature: '0',
    x: '27.605216',
    y: '43.547358',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '53905',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОРЛОВЕЦ',
    nameEn: 'ORLOVETS',
    municipality: 'ПОЛСКИ ТРЪМБЕШ',
    municipalityEn: 'POLSKI TRAMBESH',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5187',
    addressNomenclature: '0',
    x: '25.743841',
    y: '43.362781',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '53919',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОРЛОВО',
    nameEn: 'ORLOVO',
    municipality: 'КОТЕЛ',
    municipalityEn: 'KOTEL',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8985',
    addressNomenclature: '0',
    x: '26.465949',
    y: '42.989306',
    servingDays: '1010100',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '53936',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОРЛОВО',
    nameEn: 'ORLOVO',
    municipality: 'ХАСКОВО',
    municipalityEn: 'HASKOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6354',
    addressNomenclature: '0',
    x: '25.54152',
    y: '41.828122',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '53948',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОРЛОВЦИ',
    nameEn: 'ORLOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5342',
    addressNomenclature: '0',
    x: '25.36767',
    y: '42.887173',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '53953',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОРЛЯК',
    nameEn: 'ORLYAK',
    municipality: 'ТЕРВЕЛ',
    municipalityEn: 'TERVEL',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9490',
    addressNomenclature: '0',
    x: '27.377171',
    y: '43.634654',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '53967',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОРЛЯНЕ',
    nameEn: 'ORLYANE',
    municipality: 'УГЪРЧИН',
    municipalityEn: 'UGARCHIN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5577',
    addressNomenclature: '0',
    x: '24.507382',
    y: '43.133973',
    servingDays: '1010100',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '53970',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОРСОЯ',
    nameEn: 'ORSOYA',
    municipality: 'ЛОМ',
    municipalityEn: 'LOM',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3665',
    addressNomenclature: '0',
    x: '23.097211',
    y: '43.779031',
    servingDays: '1010100',
    servingOfficeId: '831',
    servingHubOfficeId: '20',
  },
  {
    id: '53998',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОРЦЕВО',
    nameEn: 'ORTSEVO',
    municipality: 'БЕЛИЦА',
    municipalityEn: 'BELITSA',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2785',
    addressNomenclature: '0',
    x: '23.730129',
    y: '41.96338',
    servingDays: '1010100',
    servingOfficeId: '125',
    servingHubOfficeId: '13',
  },
  {
    id: '54002',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОРЯХОВЕЦ',
    nameEn: 'ORYAHOVETS',
    municipality: 'БАНИТЕ',
    municipalityEn: 'BANITE',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4942',
    addressNomenclature: '0',
    x: '24.986034',
    y: '41.619212',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '54016',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОРЯХОВИЦА',
    nameEn: 'ORYAHOVITSA',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6061',
    addressNomenclature: '0',
    x: '25.811142',
    y: '42.485958',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '54020',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ОРЯХОВО',
    nameEn: 'ORYAHOVO',
    municipality: 'ОРЯХОВО',
    municipalityEn: 'ORYAHOVO',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3300',
    addressNomenclature: '2',
    x: '23.961427',
    y: '43.73497',
    servingDays: '1111100',
    servingOfficeId: '64',
    servingHubOfficeId: '20',
  },
  {
    id: '54033',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОРЯХОВО',
    nameEn: 'ORYAHOVO',
    municipality: 'ЛЮБИМЕЦ',
    municipalityEn: 'LYUBIMETS',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6555',
    addressNomenclature: '0',
    x: '26.158144',
    y: '41.920614',
    servingDays: '1010100',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '54047',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОСЕЛНА',
    nameEn: 'OSELNA',
    municipality: 'МЕЗДРА',
    municipalityEn: 'MEZDRA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3171',
    addressNomenclature: '0',
    x: '23.546555',
    y: '43.068109',
    servingDays: '1010100',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '54050',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОСЕЛНА',
    nameEn: 'OSELNA',
    municipality: 'ЕТРОПОЛЕ',
    municipalityEn: 'ETROPOLE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2186',
    addressNomenclature: '0',
    x: '24.152142',
    y: '42.921204',
    servingDays: '0101000',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '54064',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОСЕН',
    nameEn: 'OSEN',
    municipality: 'КРИВОДОЛ',
    municipalityEn: 'KRIVODOL',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3059',
    addressNomenclature: '0',
    x: '23.56512',
    y: '43.388246',
    servingDays: '0101000',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '54078',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОСЕН',
    nameEn: 'OSEN',
    municipality: 'ГЛАВИНИЦА',
    municipalityEn: 'GLAVINITSA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7634',
    addressNomenclature: '0',
    x: '26.771303',
    y: '43.888962',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '54081',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОСЕН',
    nameEn: 'OSEN',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7746',
    addressNomenclature: '0',
    x: '26.70822',
    y: '43.249158',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '54095',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОСЕНАРИТЕ',
    nameEn: 'OSENARITE',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5037',
    addressNomenclature: '0',
    x: '25.701277',
    y: '42.831026',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '54105',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОСЕНЕЦ',
    nameEn: 'OSENETS',
    municipality: 'РАЗГРАД',
    municipalityEn: 'RAZGRAD',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7267',
    addressNomenclature: '0',
    x: '26.395682',
    y: '43.560634',
    servingDays: '1111100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '54119',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОСЕНОВЕЦ',
    nameEn: 'OSENOVETS',
    municipality: 'ВЕНЕЦ',
    municipalityEn: 'VENETS',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9753',
    addressNomenclature: '0',
    x: '26.942541',
    y: '43.571206',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '54122',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОСЕНОВЛАГ',
    nameEn: 'OSENOVLAG',
    municipality: 'СВОГЕ',
    municipalityEn: 'SVOGE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2291',
    addressNomenclature: '0',
    x: '23.536398',
    y: '42.967855',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '54136',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОСЕНОВО',
    nameEn: 'OSENOVO',
    municipality: 'БАНСКО',
    municipalityEn: 'BANSKO',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2774',
    addressNomenclature: '0',
    x: '23.725427',
    y: '41.778028',
    servingDays: '0101000',
    servingOfficeId: '125',
    servingHubOfficeId: '13',
  },
  {
    id: '54145',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОСЕНОВО',
    nameEn: 'OSENOVO',
    municipality: 'АКСАКОВО',
    municipalityEn: 'AKSAKOVO',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9155',
    addressNomenclature: '0',
    x: '28.007459',
    y: '43.323007',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '54153',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОСЕТЕНОВО',
    nameEn: 'OSETENOVO',
    municipality: 'ПАВЕЛ БАНЯ',
    municipalityEn: 'PAVEL BANYA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6153',
    addressNomenclature: '0',
    x: '25.032304',
    y: '42.600765',
    servingDays: '1010100',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '54170',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОСИКОВИЦА',
    nameEn: 'OSIKOVITSA',
    municipality: 'ПРАВЕЦ',
    municipalityEn: 'PRAVETS',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2164',
    addressNomenclature: '0',
    x: '24.008817',
    y: '42.93898',
    servingDays: '0101000',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '54184',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОСИКОВО',
    nameEn: 'OSIKOVO',
    municipality: 'ГЪРМЕН',
    municipalityEn: 'GARMEN',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2965',
    addressNomenclature: '0',
    x: '23.779172',
    y: '41.697763',
    servingDays: '1010100',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '54198',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОСИКОВО',
    nameEn: 'OSIKOVO',
    municipality: 'ДЕВИН',
    municipalityEn: 'DEVIN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4819',
    addressNomenclature: '0',
    x: '24.48118',
    y: '41.903532',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '54208',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОСИКОВО',
    nameEn: 'OSIKOVO',
    municipality: 'ПОПОВО',
    municipalityEn: 'POPOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7862',
    addressNomenclature: '0',
    x: '25.995364',
    y: '43.381813',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '54211',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОСИКОВСКА ЛАКАВИЦА',
    nameEn: 'OSIKOVSKA LAKAVITSA',
    municipality: 'ПРАВЕЦ',
    municipalityEn: 'PRAVETS',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2165',
    addressNomenclature: '0',
    x: '23.951829',
    y: '42.93736',
    servingDays: '0101000',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '54225',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОСИНА',
    nameEn: 'OSINA',
    municipality: 'САТОВЧА',
    municipalityEn: 'SATOVCHA',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2953',
    addressNomenclature: '0',
    x: '24.080285',
    y: '41.633874',
    servingDays: '1010100',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '54242',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОСЛАРКА',
    nameEn: 'OSLARKA',
    municipality: 'ЧИРПАН',
    municipalityEn: 'CHIRPAN',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6216',
    addressNomenclature: '0',
    x: '25.504371',
    y: '42.19694',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '54256',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОСЛЕН КРИВОДОЛ',
    nameEn: 'OSLEN KRIVODOL',
    municipality: 'МЕЗДРА',
    municipalityEn: 'MEZDRA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3169',
    addressNomenclature: '0',
    x: '23.790845',
    y: '43.109547',
    servingDays: '0101000',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '54273',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОСМАР',
    nameEn: 'OSMAR',
    municipality: 'ВЕЛИКИ ПРЕСЛАВ',
    municipalityEn: 'VELIKI PRESLAV',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9879',
    addressNomenclature: '0',
    x: '26.851335',
    y: '43.220542',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '54287',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОСОИЦА',
    nameEn: 'OSOITSA',
    municipality: 'ГОРНА МАЛИНА',
    municipalityEn: 'GORNA MALINA',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2121',
    addressNomenclature: '0',
    x: '23.753375',
    y: '42.742649',
    servingDays: '0101000',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '54300',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОСТРА МОГИЛА',
    nameEn: 'OSTRA MOGILA',
    municipality: 'КОТЕЛ',
    municipalityEn: 'KOTEL',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8983',
    addressNomenclature: '0',
    x: '26.390096',
    y: '43.007129',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '54314',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОСТРА МОГИЛА',
    nameEn: 'OSTRA MOGILA',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6062',
    addressNomenclature: '0',
    x: '25.468432',
    y: '42.462642',
    servingDays: '1111110',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '54345',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОСТРЕЦ',
    nameEn: 'OSTRETS',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7743',
    addressNomenclature: '0',
    x: '26.730945',
    y: '43.261908',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '54359',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОСТРИ ПАЗЛАК',
    nameEn: 'OSTRI PAZLAK',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4732',
    addressNomenclature: '0',
    x: '24.833962',
    y: '41.559317',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '54362',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОСТРИЦА',
    nameEn: 'OSTRITSA',
    municipality: 'ДВЕ МОГИЛИ',
    municipalityEn: 'DVE MOGILI',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7161',
    addressNomenclature: '0',
    x: '25.969022',
    y: '43.534742',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '54376',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОСТРИЦА',
    nameEn: 'OSTRITSA',
    municipality: 'ЧЕПЕЛАРЕ',
    municipalityEn: 'CHEPELARE',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4859',
    addressNomenclature: '0',
    x: '24.725252',
    y: '41.7756',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '54386',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОСТРОВ',
    nameEn: 'OSTROV',
    municipality: 'ОРЯХОВО',
    municipalityEn: 'ORYAHOVO',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3340',
    addressNomenclature: '0',
    x: '24.138402',
    y: '43.678091',
    servingDays: '1010100',
    servingOfficeId: '64',
    servingHubOfficeId: '20',
  },
  {
    id: '54393',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОСТРОВЕЦ',
    nameEn: 'OSTROVETS',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6881',
    addressNomenclature: '0',
    x: '25.382865',
    y: '41.395723',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '54403',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОСТРОВИЦА',
    nameEn: 'OSTROVITSA',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6644',
    addressNomenclature: '0',
    x: '25.414999',
    y: '41.615664',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '54417',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОСТРОВО',
    nameEn: 'OSTROVO',
    municipality: 'ЗАВЕТ',
    municipalityEn: 'ZAVET',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7326',
    addressNomenclature: '0',
    x: '26.606954',
    y: '43.685322',
    servingDays: '1111100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '54420',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОСТРОВЧЕ',
    nameEn: 'OSTROVCHE',
    municipality: 'РАЗГРАД',
    municipalityEn: 'RAZGRAD',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7238',
    addressNomenclature: '0',
    x: '26.503917',
    y: '43.439212',
    servingDays: '0101000',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '54434',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОСТРОКАПЦИ',
    nameEn: 'OSTROKAPTSI',
    municipality: 'ДИМОВО',
    municipalityEn: 'DIMOVO',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3757',
    addressNomenclature: '0',
    x: '22.685485',
    y: '43.7249',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '54448',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОСТЪР КАМЪК',
    nameEn: 'OSTAR KAMAK',
    municipality: 'ХАРМАНЛИ',
    municipalityEn: 'HARMANLI',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6457',
    addressNomenclature: '0',
    x: '25.852292',
    y: '41.888179',
    servingDays: '0101000',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '54451',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОТЕЦ КИРИЛОВО',
    nameEn: 'OTETS KIRILOVO',
    municipality: 'БРЕЗОВО',
    municipalityEn: 'BREZOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4163',
    addressNomenclature: '0',
    x: '24.996084',
    y: '42.341808',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '54465',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОТЕЦ ПАИСИЕВО',
    nameEn: 'OTETS PAISIEVO',
    municipality: 'КАЛОЯНОВО',
    municipalityEn: 'KALOYANOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4207',
    addressNomenclature: '0',
    x: '24.894269',
    y: '42.455541',
    servingDays: '0101000',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '54479',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОХЛЮВЕЦ',
    nameEn: 'OHLYUVETS',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6624',
    addressNomenclature: '0',
    x: '25.344464',
    y: '41.687504',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '54482',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОХОДЕН',
    nameEn: 'OHODEN',
    municipality: 'ВРАЦА',
    municipalityEn: 'VRATSA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3079',
    addressNomenclature: '0',
    x: '23.713797',
    y: '43.364694',
    servingDays: '1010100',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '54496',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОХРИД',
    nameEn: 'OHRID',
    municipality: 'БОЙЧИНОВЦИ',
    municipalityEn: 'BOYCHINOVTSI',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3438',
    addressNomenclature: '0',
    x: '23.38035',
    y: '43.455166',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '54506',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОЧИНДОЛ',
    nameEn: 'OCHINDOL',
    municipality: 'МЕЗДРА',
    municipalityEn: 'MEZDRA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3162',
    addressNomenclature: '0',
    x: '23.478408',
    y: '43.10272',
    servingDays: '1010100',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '54554',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОЧУША',
    nameEn: 'OCHUSHA',
    municipality: 'КОСТЕНЕЦ',
    municipalityEn: 'KOSTENETS',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2049',
    addressNomenclature: '0',
    x: '23.725342',
    y: '42.387846',
    servingDays: '1010100',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '54517',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОШАНЕ',
    nameEn: 'OSHANE',
    municipality: 'БЕЛОГРАДЧИК',
    municipalityEn: 'BELOGRADCHIK',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3937',
    addressNomenclature: '0',
    x: '22.55023',
    y: '43.690842',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '54523',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОШАНИТЕ',
    nameEn: 'OSHANITE',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5350',
    addressNomenclature: '0',
    x: '25.536459',
    y: '42.855238',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '54537',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ОЩАВА',
    nameEn: 'OSHTAVA',
    municipality: 'КРЕСНА',
    municipalityEn: 'KRESNA',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2847',
    addressNomenclature: '0',
    x: '23.220252',
    y: '41.787463',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '55018',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАВЕЛ',
    nameEn: 'PAVEL',
    municipality: 'ПОЛСКИ ТРЪМБЕШ',
    municipalityEn: 'POLSKI TRAMBESH',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5197',
    addressNomenclature: '0',
    x: '25.537293',
    y: '43.469131',
    servingDays: '1010100',
    servingOfficeId: '250',
    servingHubOfficeId: '11',
  },
  {
    id: '55021',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ПАВЕЛ БАНЯ',
    nameEn: 'PAVEL BANYA',
    municipality: 'ПАВЕЛ БАНЯ',
    municipalityEn: 'PAVEL BANYA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6155',
    addressNomenclature: '2',
    x: '25.207483',
    y: '42.596303',
    servingDays: '1111100',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '55035',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАВЕЛСКО',
    nameEn: 'PAVELSKO',
    municipality: 'ЧЕПЕЛАРЕ',
    municipalityEn: 'CHEPELARE',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4889',
    addressNomenclature: '0',
    x: '24.704972',
    y: '41.862492',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '55049',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАВЛЕВЦИ',
    nameEn: 'PAVLEVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5365',
    addressNomenclature: '0',
    x: '25.590707',
    y: '42.879733',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '55052',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ПАВЛИКЕНИ',
    nameEn: 'PAVLIKENI',
    municipality: 'ПАВЛИКЕНИ',
    municipalityEn: 'PAVLIKENI',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5200',
    addressNomenclature: '2',
    x: '25.305803',
    y: '43.240977',
    servingDays: '1111110',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '55070',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАВОЛЧЕ',
    nameEn: 'PAVOLCHE',
    municipality: 'ВРАЦА',
    municipalityEn: 'VRATSA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3044',
    addressNomenclature: '0',
    x: '23.600762',
    y: '43.157972',
    servingDays: '1010100',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '55083',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАДАЛА',
    nameEn: 'PADALA',
    municipality: 'РИЛА',
    municipalityEn: 'RILA',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2632',
    addressNomenclature: '0',
    x: '23.191693',
    y: '42.143627',
    servingDays: '1111110',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '55097',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАДАЛО',
    nameEn: 'PADALO',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6960',
    addressNomenclature: '0',
    x: '25.769106',
    y: '41.563002',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '55107',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАДЕШ',
    nameEn: 'PADESH',
    municipality: 'БЛАГОЕВГРАД',
    municipalityEn: 'BLAGOEVGRAD',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2747',
    addressNomenclature: '0',
    x: '23.001801',
    y: '41.931629',
    servingDays: '1111110',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '55110',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАДИНА',
    nameEn: 'PADINA',
    municipality: 'ДЕВНЯ',
    municipalityEn: 'DEVNYA',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9136',
    addressNomenclature: '0',
    x: '27.573934',
    y: '43.16091',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '55124',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАДИНА',
    nameEn: 'PADINA',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6771',
    addressNomenclature: '0',
    x: '25.06754',
    y: '41.508651',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '55138',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАДИНА',
    nameEn: 'PADINA',
    municipality: 'ГЛАВИНИЦА',
    municipalityEn: 'GLAVINITSA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7617',
    addressNomenclature: '0',
    x: '26.912412',
    y: '43.903734',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '55141',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАДИНЕ',
    nameEn: 'PADINE',
    municipality: 'ЗЕМЕН',
    municipalityEn: 'ZEMEN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2438',
    addressNomenclature: '0',
    x: '22.726437',
    y: '42.642101',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '55155',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ПАЗАРДЖИК',
    nameEn: 'PAZARDZHIK',
    municipality: 'ПАЗАРДЖИК',
    municipalityEn: 'PAZARDZHIK',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4400',
    addressNomenclature: '1',
    x: '24.336296',
    y: '42.190396',
    servingDays: '1111110',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '55169',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАЗАРЦИ',
    nameEn: 'PAZARTSI',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6829',
    addressNomenclature: '0',
    x: '25.526259',
    y: '41.458256',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '55186',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАИСИЕВО',
    nameEn: 'PAISIEVO',
    municipality: 'ДУЛОВО',
    municipalityEn: 'DULOVO',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7681',
    addressNomenclature: '0',
    x: '26.954105',
    y: '43.785408',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '55196',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАИСИЙ',
    nameEn: 'PAISIY',
    municipality: 'ГОРНА ОРЯХОВИЦА',
    municipalityEn: 'GORNA ORYAHOVITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5136',
    addressNomenclature: '0',
    x: '25.828609',
    y: '43.309098',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '55201',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАЙДУШКО',
    nameEn: 'PAYDUSHKO',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7767',
    addressNomenclature: '0',
    x: '26.459372',
    y: '43.193431',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '55213',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАЛАМАРЦА',
    nameEn: 'PALAMARTSA',
    municipality: 'ПОПОВО',
    municipalityEn: 'POPOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7850',
    addressNomenclature: '0',
    x: '26.160197',
    y: '43.390219',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '55227',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАЛАТ',
    nameEn: 'PALAT',
    municipality: 'СТРУМЯНИ',
    municipalityEn: 'STRUMYANI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2829',
    addressNomenclature: '0',
    x: '23.190524',
    y: '41.592028',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '59330',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАЛАТИК',
    nameEn: 'PALATIK',
    municipality: 'БЕЛИЦА',
    municipalityEn: 'BELITSA',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2782',
    addressNomenclature: '0',
    x: '23.669568',
    y: '41.910703',
    servingDays: '1010100',
    servingOfficeId: '125',
    servingHubOfficeId: '13',
  },
  {
    id: '55230',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАЛАТОВО',
    nameEn: 'PALATOVO',
    municipality: 'ДУПНИЦА',
    municipalityEn: 'DUPNITSA',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2681',
    addressNomenclature: '0',
    x: '23.031977',
    y: '42.254938',
    servingDays: '0101000',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '55244',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАЛАУЗОВО',
    nameEn: 'PALAUZOVO',
    municipality: 'СТРАЛДЖА',
    municipalityEn: 'STRALDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8698',
    addressNomenclature: '0',
    x: '26.74393',
    y: '42.530611',
    servingDays: '0101000',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '55258',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАЛИЛУЛА',
    nameEn: 'PALILULA',
    municipality: 'БОЙЧИНОВЦИ',
    municipalityEn: 'BOYCHINOVTSI',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3439',
    addressNomenclature: '0',
    x: '23.388333',
    y: '43.436413',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '55261',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАЛИЦИ',
    nameEn: 'PALITSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5083',
    addressNomenclature: '0',
    x: '26.002183',
    y: '42.919884',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '55275',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАМИДОВО',
    nameEn: 'PAMIDOVO',
    municipality: 'ЛЕСИЧОВО',
    municipalityEn: 'LESICHOVO',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4459',
    addressNomenclature: '0',
    x: '24.215542',
    y: '42.276907',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '98230',
    countryId: '100',
    mainSiteId: '0',
    type: 'к.к.',
    typeEn: 'k.k.',
    name: 'ПАМПОРОВО',
    nameEn: 'PAMPOROVO',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4870',
    addressNomenclature: '0',
    x: '24.695559',
    y: '41.650386',
    servingDays: '1111110',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '55289',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАМУКЧИИ',
    nameEn: 'PAMUKCHII',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6058',
    addressNomenclature: '0',
    x: '25.647113',
    y: '42.32184',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '55292',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАМУКЧИИ',
    nameEn: 'PAMUKCHII',
    municipality: 'НОВИ ПАЗАР',
    municipalityEn: 'NOVI PAZAR',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9934',
    addressNomenclature: '0',
    x: '27.217663',
    y: '43.397548',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '55302',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ПАНАГЮРИЩЕ',
    nameEn: 'PANAGYURISHTE',
    municipality: 'ПАНАГЮРИЩЕ',
    municipalityEn: 'PANAGYURISHTE',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4500',
    addressNomenclature: '2',
    x: '24.180243',
    y: '42.502624',
    servingDays: '1111110',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '59375',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАНАГЮРСКИ КОЛОНИИ',
    nameEn: 'PANAGYURSKI KOLONII',
    municipality: 'ПАНАГЮРИЩЕ',
    municipalityEn: 'PANAGYURISHTE',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4520',
    addressNomenclature: '0',
    x: '24.198476',
    y: '42.583338',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '55316',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАНАЙОТ ВОЛОВО',
    nameEn: 'PANAYOT VOLOVO',
    municipality: 'ШУМЕН',
    municipalityEn: 'SHUMEN',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9802',
    addressNomenclature: '0',
    x: '26.916074',
    y: '43.342743',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '55327',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАНАЙОТ ХИТОВО',
    nameEn: 'PANAYOT HITOVO',
    municipality: 'ОМУРТАГ',
    municipalityEn: 'OMURTAG',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7929',
    addressNomenclature: '0',
    x: '26.334929',
    y: '43.137228',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '55333',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАНАРЕТОВЦИ',
    nameEn: 'PANARETOVTSI',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8867',
    addressNomenclature: '0',
    x: '26.301582',
    y: '42.56429',
    servingDays: '1111110',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '55350',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАНИЦОВО',
    nameEn: 'PANITSOVO',
    municipality: 'НЕСЕБЪР',
    municipalityEn: 'NESEBAR',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8254',
    addressNomenclature: '0',
    x: '27.662961',
    y: '42.851702',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '55364',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАНИЧАРЕВО',
    nameEn: 'PANICHAREVO',
    municipality: 'БОБОВ ДОЛ',
    municipalityEn: 'BOBOV DOL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2685',
    addressNomenclature: '0',
    x: '22.992383',
    y: '42.28314',
    servingDays: '1010100',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '22767',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАНИЧЕРЕВО',
    nameEn: 'PANICHEREVO',
    municipality: 'ГУРКОВО',
    municipalityEn: 'GURKOVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6172',
    addressNomenclature: '0',
    x: '25.85008',
    y: '42.603209',
    servingDays: '0101000',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '55378',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАНИЧЕРИ',
    nameEn: 'PANICHERI',
    municipality: 'ХИСАРЯ',
    municipalityEn: 'HISARYA',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4179',
    addressNomenclature: '0',
    x: '24.618086',
    y: '42.459165',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '55381',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАНИЧИНО',
    nameEn: 'PANICHINO',
    municipality: 'ОМУРТАГ',
    municipalityEn: 'OMURTAG',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7928',
    addressNomenclature: '0',
    x: '26.454743',
    y: '43.08569',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '59358',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАНИЧИЩЕ',
    nameEn: 'PANICHISHTE',
    municipality: 'САПАРЕВА БАНЯ',
    municipalityEn: 'SAPAREVA BANYA',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2650',
    addressNomenclature: '0',
    x: '23.298066',
    y: '42.264534',
    servingDays: '0101000',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '55395',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАНИЧКОВО',
    nameEn: 'PANICHKOVO',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6723',
    addressNomenclature: '0',
    x: '25.157092',
    y: '41.846893',
    servingDays: '0101000',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '55405',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАНОВЦИ',
    nameEn: 'PANOVTSI',
    municipality: 'ИХТИМАН',
    municipalityEn: 'IHTIMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2060',
    addressNomenclature: '0',
    x: '23.777483',
    y: '42.538921',
    servingDays: '0101000',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '55419',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАНЧАРЕВО',
    nameEn: 'PANCHAREVO',
    municipality: 'СТОЛИЧНА',
    municipalityEn: 'STOLICHNA',
    region: 'СОФИЯ (СТОЛИЦА)',
    regionEn: 'SOFIA (STOLITSA)',
    postCode: '1137',
    addressNomenclature: '2',
    x: '23.410247',
    y: '42.593722',
    servingDays: '1111110',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '55422',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАНЧЕВО',
    nameEn: 'PANCHEVO',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6657',
    addressNomenclature: '0',
    x: '25.415419',
    y: '41.653631',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '55436',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАПРАТ',
    nameEn: 'PAPRAT',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6851',
    addressNomenclature: '0',
    x: '25.280819',
    y: '41.515321',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '55442',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАПРАТЛИВА',
    nameEn: 'PAPRATLIVA',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5094',
    addressNomenclature: '0',
    x: '25.849099',
    y: '42.8493',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '55453',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАРАМУН',
    nameEn: 'PARAMUN',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2474',
    addressNomenclature: '0',
    x: '22.745852',
    y: '42.791846',
    servingDays: '1111100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '55467',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАРИЛ',
    nameEn: 'PARIL',
    municipality: 'ХАДЖИДИМОВО',
    municipalityEn: 'HADZHIDIMOVO',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2926',
    addressNomenclature: '0',
    x: '23.681215',
    y: '41.434209',
    servingDays: '1010100',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '55470',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАРТИЗАНИ',
    nameEn: 'PARTIZANI',
    municipality: 'ДЪЛГОПОЛ',
    municipalityEn: 'DALGOPOL',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9245',
    addressNomenclature: '0',
    x: '27.247188',
    y: '43.01146',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '55484',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАРТИЗАНИН',
    nameEn: 'PARTIZANIN',
    municipality: 'БРАТЯ ДАСКАЛОВИ',
    municipalityEn: 'BRATYA DASKALOVI',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6218',
    addressNomenclature: '0',
    x: '25.246939',
    y: '42.248129',
    servingDays: '1111110',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '55498',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАРЧОВЦИ',
    nameEn: 'PARCHOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5344',
    addressNomenclature: '0',
    x: '25.297852',
    y: '42.947934',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '55508',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАСКАЛЕВЕЦ',
    nameEn: 'PASKALEVETS',
    municipality: 'ПАВЛИКЕНИ',
    municipalityEn: 'PAVLIKENI',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5224',
    addressNomenclature: '0',
    x: '25.485779',
    y: '43.251704',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '55511',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАСКАЛЕВО',
    nameEn: 'PASKALEVO',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9370',
    addressNomenclature: '0',
    x: '27.83142',
    y: '43.639113',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '55525',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАСПАЛ',
    nameEn: 'PASPAL',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6776',
    addressNomenclature: '0',
    x: '25.029266',
    y: '41.559802',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '55539',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАСТРА',
    nameEn: 'PASTRA',
    municipality: 'РИЛА',
    municipalityEn: 'RILA',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2631',
    addressNomenclature: '0',
    x: '23.222867',
    y: '42.127381',
    servingDays: '0101000',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '55542',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАСТУХ',
    nameEn: 'PASTUH',
    municipality: 'НЕВЕСТИНО',
    municipalityEn: 'NEVESTINO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2595',
    addressNomenclature: '0',
    x: '22.902707',
    y: '42.204752',
    servingDays: '1010100',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '55556',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАТАЛЕНИЦА',
    nameEn: 'PATALENITSA',
    municipality: 'ПАЗАРДЖИК',
    municipalityEn: 'PAZARDZHIK',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4418',
    addressNomenclature: '0',
    x: '24.20306',
    y: '42.125528',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '55568',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАТИЦА',
    nameEn: 'PATITSA',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6710',
    addressNomenclature: '0',
    x: '25.366114',
    y: '41.731195',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '55573',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАТРЕШ',
    nameEn: 'PATRESH',
    municipality: 'ПАВЛИКЕНИ',
    municipalityEn: 'PAVLIKENI',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5225',
    addressNomenclature: '0',
    x: '25.355378',
    y: '43.292096',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '55587',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАТРЕШКО',
    nameEn: 'PATRESHKO',
    municipality: 'ТРОЯН',
    municipalityEn: 'TROYAN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5631',
    addressNomenclature: '0',
    x: '24.773582',
    y: '42.91964',
    servingDays: '0101000',
    servingOfficeId: '44',
    servingHubOfficeId: '11',
  },
  {
    id: '55590',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАТРИАРХ ЕВТИМОВО',
    nameEn: 'PATRIARH EVTIMOVO',
    municipality: 'АСЕНОВГРАД',
    municipalityEn: 'ASENOVGRAD',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4267',
    addressNomenclature: '0',
    x: '25.052556',
    y: '42.012332',
    servingDays: '1010100',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '55600',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАУНОВО',
    nameEn: 'PAUNOVO',
    municipality: 'ИХТИМАН',
    municipalityEn: 'IHTIMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2069',
    addressNomenclature: '0',
    x: '23.711512',
    y: '42.517096',
    servingDays: '1010100',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '55614',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАШИНЦИ',
    nameEn: 'PASHINTSI',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6969',
    addressNomenclature: '0',
    x: '25.509524',
    y: '41.35303',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '55628',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАШКУЛ',
    nameEn: 'PASHKUL',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6572',
    addressNomenclature: '0',
    x: '25.888553',
    y: '41.52101',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '55631',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАШОВИ',
    nameEn: 'PASHOVI',
    municipality: 'ВЕЛИНГРАД',
    municipalityEn: 'VELINGRAD',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4632',
    addressNomenclature: '0',
    x: '23.828609',
    y: '42.044402',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '55645',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПАШОВО',
    nameEn: 'PASHOVO',
    municipality: 'СВИЛЕНГРАД',
    municipalityEn: 'SVILENGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6529',
    addressNomenclature: '0',
    x: '26.37593',
    y: '41.827146',
    servingDays: '0101000',
    servingOfficeId: '48',
    servingHubOfficeId: '5',
  },
  {
    id: '55662',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕВЕЦ',
    nameEn: 'PEVETS',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7771',
    addressNomenclature: '0',
    x: '26.68361',
    y: '43.211901',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '55676',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕВЦИТЕ',
    nameEn: 'PEVTSITE',
    municipality: 'КАРЛОВО',
    municipalityEn: 'KARLOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4335',
    addressNomenclature: '0',
    x: '24.65896',
    y: '42.674902',
    servingDays: '0101000',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '55693',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕЙКОВЦИ',
    nameEn: 'PEYKOVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5095',
    addressNomenclature: '0',
    x: '25.802977',
    y: '42.827281',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '55703',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕЙНА',
    nameEn: 'PEYNA',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5370',
    addressNomenclature: '0',
    x: '25.428441',
    y: '43.002797',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '55717',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕЙОВЦИ',
    nameEn: 'PEYOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5345',
    addressNomenclature: '0',
    x: '25.235349',
    y: '42.883763',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '55720',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕЙЧИНОВО',
    nameEn: 'PEYCHINOVO',
    municipality: 'БЯЛА',
    municipalityEn: 'BYALA',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7127',
    addressNomenclature: '0',
    x: '25.598808',
    y: '43.444856',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '55734',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕЛАТИКОВО',
    nameEn: 'PELATIKOVO',
    municipality: 'НЕВЕСТИНО',
    municipalityEn: 'NEVESTINO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2582',
    addressNomenclature: '0',
    x: '22.774753',
    y: '42.177513',
    servingDays: '1010100',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '55751',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕЛИН',
    nameEn: 'PELIN',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6948',
    addressNomenclature: '0',
    x: '25.731631',
    y: '41.46751',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '55765',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕЛИШАТ',
    nameEn: 'PELISHAT',
    municipality: 'ПЛЕВЕН',
    municipalityEn: 'PLEVEN',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5895',
    addressNomenclature: '0',
    x: '24.796405',
    y: '43.347468',
    servingDays: '0101000',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '55796',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕНКОВЦИ',
    nameEn: 'PENKOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5333',
    addressNomenclature: '0',
    x: '25.125622',
    y: '42.90616',
    servingDays: '1010100',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '55814',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕНКЬОВЦИ',
    nameEn: 'PENKYOVTSI',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2499',
    addressNomenclature: '0',
    x: '22.616605',
    y: '42.620879',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '55823',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕНЬОВО',
    nameEn: 'PENYOVO',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6741',
    addressNomenclature: '0',
    x: '25.310747',
    y: '41.676026',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '55837',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕПЕЛИНА',
    nameEn: 'PEPELINA',
    municipality: 'ДВЕ МОГИЛИ',
    municipalityEn: 'DVE MOGILI',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7158',
    addressNomenclature: '0',
    x: '25.935653',
    y: '43.587328',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '55840',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕПЕЛИЩЕ',
    nameEn: 'PEPELISHTE',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6643',
    addressNomenclature: '0',
    x: '25.381018',
    y: '41.585455',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '55868',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕРИЛОВЕЦ',
    nameEn: 'PERILOVETS',
    municipality: 'БОЙНИЦА',
    municipalityEn: 'BOYNITSA',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3841',
    addressNomenclature: '0',
    x: '22.541562',
    y: '43.992827',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '55871',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ПЕРНИК',
    nameEn: 'PERNIK',
    municipality: 'ПЕРНИК',
    municipalityEn: 'PERNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2300',
    addressNomenclature: '1',
    x: '23.045513',
    y: '42.603132',
    servingDays: '1111110',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '55885',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕРПЕРЕК',
    nameEn: 'PERPEREK',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6673',
    addressNomenclature: '0',
    x: '25.544327',
    y: '41.684874',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '55899',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕРУНИКА',
    nameEn: 'PERUNIKA',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6949',
    addressNomenclature: '0',
    x: '25.741955',
    y: '41.476305',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '55909',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ПЕРУЩИЦА',
    nameEn: 'PERUSHTITSA',
    municipality: 'ПЕРУЩИЦА',
    municipalityEn: 'PERUSHTITSA',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4225',
    addressNomenclature: '2',
    x: '24.546844',
    y: '42.057668',
    servingDays: '1111110',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '55912',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕСНОПОЙ',
    nameEn: 'PESNOPOY',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6798',
    addressNomenclature: '0',
    x: '25.123608',
    y: '41.699693',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '55926',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕСНОПОЙ',
    nameEn: 'PESNOPOY',
    municipality: 'КАЛОЯНОВО',
    municipalityEn: 'KALOYANOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4208',
    addressNomenclature: '0',
    x: '24.807285',
    y: '42.486803',
    servingDays: '1010100',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '55930',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕСОЧНИЦА',
    nameEn: 'PESOCHNITSA',
    municipality: 'БЕРКОВИЦА',
    municipalityEn: 'BERKOVITSA',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3519',
    addressNomenclature: '0',
    x: '23.204991',
    y: '43.252709',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '55988',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕТ КЛАДЕНЦИ',
    nameEn: 'PET KLADENTSI',
    municipality: 'БЯЛА',
    municipalityEn: 'BYALA',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7119',
    addressNomenclature: '0',
    x: '25.807814',
    y: '43.45063',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '56068',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕТ МОГИЛИ',
    nameEn: 'PET MOGILI',
    municipality: 'НОВА ЗАГОРА',
    municipalityEn: 'NOVA ZAGORA',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8953',
    addressNomenclature: '0',
    x: '26.059182',
    y: '42.311899',
    servingDays: '1111100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '56071',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕТ МОГИЛИ',
    nameEn: 'PET MOGILI',
    municipality: 'НИКОЛА КОЗЛЕВО',
    municipalityEn: 'NIKOLA KOZLEVO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9940',
    addressNomenclature: '0',
    x: '27.349606',
    y: '43.563577',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '55960',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕТЕЛОВО',
    nameEn: 'PETELOVO',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6666',
    addressNomenclature: '0',
    x: '25.36215',
    y: '41.824623',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '65471',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕТКО КАРАВЕЛОВО',
    nameEn: 'PETKO KARAVELOVO',
    municipality: 'ПОЛСКИ ТРЪМБЕШ',
    municipalityEn: 'POLSKI TRAMBESH',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5184',
    addressNomenclature: '0',
    x: '25.649862',
    y: '43.301577',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '56037',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕТКО СЛАВЕЙКОВ',
    nameEn: 'PETKO SLAVEYKOV',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5434',
    addressNomenclature: '0',
    x: '24.971863',
    y: '43.056347',
    servingDays: '0101000',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '55991',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕТКОВО',
    nameEn: 'PETKOVO',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4740',
    addressNomenclature: '0',
    x: '24.893893',
    y: '41.623174',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '56006',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕТКОВО',
    nameEn: 'PETKOVO',
    municipality: 'ЕЛИН ПЕЛИН',
    municipalityEn: 'ELIN PELIN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2111',
    addressNomenclature: '0',
    x: '23.651648',
    y: '42.669845',
    servingDays: '1010100',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '56011',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕТКОВЦИ',
    nameEn: 'PETKOVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5084',
    addressNomenclature: '0',
    x: '25.9877',
    y: '42.944941',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '56023',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕТКОВЦИ',
    nameEn: 'PETKOVTSI',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5370',
    addressNomenclature: '0',
    x: '25.527508',
    y: '42.93774',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '56040',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕТЛЕШКОВО',
    nameEn: 'PETLESHKOVO',
    municipality: 'ГЕНЕРАЛ ТОШЕВО',
    municipalityEn: 'GENERAL TOSHEVO',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9521',
    addressNomenclature: '0',
    x: '28.031245',
    y: '43.652865',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '56054',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕТЛИНО',
    nameEn: 'PETLINO',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6634',
    addressNomenclature: '0',
    x: '25.349742',
    y: '41.607519',
    servingDays: '1111100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '56085',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕТОКЛАДЕНЦИ',
    nameEn: 'PETOKLADENTSI',
    municipality: 'БЕЛЕНЕ',
    municipalityEn: 'BELENE',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5933',
    addressNomenclature: '0',
    x: '25.115729',
    y: '43.523134',
    servingDays: '1010100',
    servingOfficeId: '108',
    servingHubOfficeId: '11',
  },
  {
    id: '56099',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕТРЕВЕНЕ',
    nameEn: 'PETREVENE',
    municipality: 'ЛУКОВИТ',
    municipalityEn: 'LUKOVIT',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5784',
    addressNomenclature: '0',
    x: '24.148088',
    y: '43.157165',
    servingDays: '0101000',
    servingOfficeId: '59',
    servingHubOfficeId: '20',
  },
  {
    id: '56109',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕТРЕЛИК',
    nameEn: 'PETRELIK',
    municipality: 'ХАДЖИДИМОВО',
    municipalityEn: 'HADZHIDIMOVO',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2936',
    addressNomenclature: '0',
    x: '23.864068',
    y: '41.486275',
    servingDays: '1010100',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '56112',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕТРИНО',
    nameEn: 'PETRINO',
    municipality: 'ОМУРТАГ',
    municipalityEn: 'OMURTAG',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7956',
    addressNomenclature: '0',
    x: '26.459474',
    y: '43.050756',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '56126',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ПЕТРИЧ',
    nameEn: 'PETRICH',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2850',
    addressNomenclature: '1',
    x: '23.206857',
    y: '41.398129',
    servingDays: '1111110',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '56137',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕТРИЧ',
    nameEn: 'PETRICH',
    municipality: 'ЗЛАТИЦА',
    municipalityEn: 'ZLATITSA',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2085',
    addressNomenclature: '0',
    x: '24.008602',
    y: '42.600218',
    servingDays: '0101000',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '56143',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕТРОВ ДОЛ',
    nameEn: 'PETROV DOL',
    municipality: 'ПРОВАДИЯ',
    municipalityEn: 'PROVADIA',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9225',
    addressNomenclature: '0',
    x: '27.460952',
    y: '43.231963',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '56157',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕТРОВ ДОЛ',
    nameEn: 'PETROV DOL',
    municipality: 'МАДАН',
    municipalityEn: 'MADAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4911',
    addressNomenclature: '0',
    x: '24.992213',
    y: '41.475614',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '56174',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕТРОВО',
    nameEn: 'PETROVO',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2815',
    addressNomenclature: '0',
    x: '23.504585',
    y: '41.437807',
    servingDays: '1010100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '56188',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕТРОВО',
    nameEn: 'PETROVO',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6058',
    addressNomenclature: '0',
    x: '25.638423',
    y: '42.292789',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '56191',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕТРОВЦИ',
    nameEn: 'PETROVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5347',
    addressNomenclature: '0',
    x: '25.235421',
    y: '42.923617',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '56201',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕТЪРНИЦА',
    nameEn: 'PETARNITSA',
    municipality: 'ДОЛНИ ДЪБНИК',
    municipalityEn: 'DOLNI DABNIK',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5878',
    addressNomenclature: '0',
    x: '24.473494',
    y: '43.327231',
    servingDays: '1010100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '56215',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕТЪРЧ',
    nameEn: 'PETARCH',
    municipality: 'КОСТИНБРОД',
    municipalityEn: 'KOSTINBROD',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2234',
    addressNomenclature: '0',
    x: '23.148013',
    y: '42.84369',
    servingDays: '1111100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '56229',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕЦОВЦИ',
    nameEn: 'PETSOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5345',
    addressNomenclature: '0',
    x: '25.261432',
    y: '42.91334',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '56232',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕЧЕНИЦА',
    nameEn: 'PECHENITSA',
    municipality: 'ИСПЕРИХ',
    municipalityEn: 'ISPERIH',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7428',
    addressNomenclature: '0',
    x: '26.934933',
    y: '43.705317',
    servingDays: '1010100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '56246',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕЧИНСКА',
    nameEn: 'PECHINSKA',
    municipality: 'МАДАН',
    municipalityEn: 'MADAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4912',
    addressNomenclature: '0',
    x: '24.968101',
    y: '41.46929',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '56252',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕШАКОВО',
    nameEn: 'PESHAKOVO',
    municipality: 'ВИДИН',
    municipalityEn: 'VIDIN',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3733',
    addressNomenclature: '0',
    x: '22.734642',
    y: '43.990305',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '56277',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ПЕЩЕРА',
    nameEn: 'PESHTERA',
    municipality: 'ПЕЩЕРА',
    municipalityEn: 'PESHTERA',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4550',
    addressNomenclature: '2',
    x: '24.303315',
    y: '42.034391',
    servingDays: '1111110',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '56280',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕЩЕРА',
    nameEn: 'PESHTERA',
    municipality: 'ЗЕМЕН',
    municipalityEn: 'ZEMEN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2443',
    addressNomenclature: '0',
    x: '22.751554',
    y: '42.505274',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '56294',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕЩЕРА',
    nameEn: 'PESHTERA',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4754',
    addressNomenclature: '0',
    x: '24.695682',
    y: '41.543876',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '56318',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕЩЕРНА',
    nameEn: 'PESHTERNA',
    municipality: 'ЛУКОВИТ',
    municipalityEn: 'LUKOVIT',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5788',
    addressNomenclature: '0',
    x: '24.249155',
    y: '43.078291',
    servingDays: '1010100',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '56321',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЕЩЕРСКО',
    nameEn: 'PESHTERSKO',
    municipality: 'АЙТОС',
    municipalityEn: 'AYTOS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8525',
    addressNomenclature: '0',
    x: '27.347131',
    y: '42.743243',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '56349',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПИПЕРЕВО',
    nameEn: 'PIPEREVO',
    municipality: 'ДУПНИЦА',
    municipalityEn: 'DUPNITSA',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2618',
    addressNomenclature: '0',
    x: '23.111503',
    y: '42.285971',
    servingDays: '1111110',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '56352',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПИПЕРИЦА',
    nameEn: 'PIPERITSA',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2831',
    addressNomenclature: '0',
    x: '23.437799',
    y: '41.410921',
    servingDays: '1010100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '56378',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПИПЕРКОВ ЧИФЛИК',
    nameEn: 'PIPERKOV CHIFLIK',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2508',
    addressNomenclature: '0',
    x: '22.733798',
    y: '42.279059',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '56366',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПИПЕРКОВО',
    nameEn: 'PIPERKOVO',
    municipality: 'ЦЕНОВО',
    municipalityEn: 'TSENOVO',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7129',
    addressNomenclature: '0',
    x: '25.58196',
    y: '43.539089',
    servingDays: '1010100',
    servingOfficeId: '250',
    servingHubOfficeId: '11',
  },
  {
    id: '56383',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПИРАМИДАТА',
    nameEn: 'PIRAMIDATA',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5035',
    addressNomenclature: '0',
    x: '25.639698',
    y: '42.822866',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '56397',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПИРГОВО',
    nameEn: 'PIRGOVO',
    municipality: 'ИВАНОВО',
    municipalityEn: 'IVANOVO',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7090',
    addressNomenclature: '0',
    x: '25.857369',
    y: '43.732861',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '56407',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ПИРДОП',
    nameEn: 'PIRDOP',
    municipality: 'ПИРДОП',
    municipalityEn: 'PIRDOP',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2070',
    addressNomenclature: '2',
    x: '24.174304',
    y: '42.701666',
    servingDays: '1111110',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '56410',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПИРИН',
    nameEn: 'PIRIN',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2823',
    addressNomenclature: '0',
    x: '23.559642',
    y: '41.544358',
    servingDays: '1010100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '56424',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПИРИНЕЦ',
    nameEn: 'PIRINETS',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7976',
    addressNomenclature: '0',
    x: '26.186253',
    y: '43.207569',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '56438',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПИРНЕ',
    nameEn: 'PIRNE',
    municipality: 'АЙТОС',
    municipalityEn: 'AYTOS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8528',
    addressNomenclature: '0',
    x: '27.19657',
    y: '42.677867',
    servingDays: '1111100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '56441',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПИСАНЕЦ',
    nameEn: 'PISANETS',
    municipality: 'ВЕТОВО',
    municipalityEn: 'VETOVO',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7081',
    addressNomenclature: '0',
    x: '26.165172',
    y: '43.671523',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '56455',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПИСАНИЦА',
    nameEn: 'PISANITSA',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4744',
    addressNomenclature: '0',
    x: '24.751588',
    y: '41.593062',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '56472',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПИСАРЕВО',
    nameEn: 'PISAREVO',
    municipality: 'ГОРНА ОРЯХОВИЦА',
    municipalityEn: 'GORNA ORYAHOVITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5127',
    addressNomenclature: '0',
    x: '25.789132',
    y: '43.15539',
    servingDays: '0101000',
    servingOfficeId: '46',
    servingHubOfficeId: '11',
  },
  {
    id: '56486',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПИСАРЕВО',
    nameEn: 'PISAREVO',
    municipality: 'НОВИ ПАЗАР',
    municipalityEn: 'NOVI PAZAR',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9927',
    addressNomenclature: '0',
    x: '27.308328',
    y: '43.486257',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '56493',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПИСАРОВО',
    nameEn: 'PISAROVO',
    municipality: 'ИСКЪР',
    municipalityEn: 'ISKAR',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5871',
    addressNomenclature: '0',
    x: '24.275976',
    y: '43.414916',
    servingDays: '1010100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '56509',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПИСАРОВО',
    nameEn: 'PISAROVO',
    municipality: 'ГЕНЕРАЛ ТОШЕВО',
    municipalityEn: 'GENERAL TOSHEVO',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9527',
    addressNomenclature: '0',
    x: '28.104033',
    y: '43.699345',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '56513',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПИСМЕНОВО',
    nameEn: 'PISMENOVO',
    municipality: 'ПРИМОРСКО',
    municipalityEn: 'PRIMORSKO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8182',
    addressNomenclature: '0',
    x: '27.673439',
    y: '42.217412',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '56527',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПИТОВО',
    nameEn: 'PITOVO',
    municipality: 'НОВА ЗАГОРА',
    municipalityEn: 'NOVA ZAGORA',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8942',
    addressNomenclature: '0',
    x: '26.191761',
    y: '42.426697',
    servingDays: '0101000',
    servingOfficeId: '56',
    servingHubOfficeId: '5',
  },
  {
    id: '56544',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПИШУРКА',
    nameEn: 'PISHURKA',
    municipality: 'МЕДКОВЕЦ',
    municipalityEn: 'MEDKOVETS',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3662',
    addressNomenclature: '0',
    x: '23.253872',
    y: '43.651594',
    servingDays: '0101000',
    servingOfficeId: '831',
    servingHubOfficeId: '20',
  },
  {
    id: '56558',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПИЩАНЕ',
    nameEn: 'PISHTANE',
    municipality: 'СЛИВНИЦА',
    municipalityEn: 'SLIVNITSA',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2218',
    addressNomenclature: '0',
    x: '22.862942',
    y: '42.824127',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '56561',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПИЩИГОВО',
    nameEn: 'PISHTIGOVO',
    municipality: 'ПАЗАРДЖИК',
    municipalityEn: 'PAZARDZHIK',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4451',
    addressNomenclature: '0',
    x: '24.425207',
    y: '42.251058',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '56575',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПИЯВЕЦ',
    nameEn: 'PIYAVETS',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6813',
    addressNomenclature: '0',
    x: '25.478272',
    y: '41.484235',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '56589',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЛАЗИЩЕ',
    nameEn: 'PLAZISHTE',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6850',
    addressNomenclature: '0',
    x: '25.332937',
    y: '41.525878',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '56602',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЛАКОВО',
    nameEn: 'PLAKOVO',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5042',
    addressNomenclature: '0',
    x: '25.681264',
    y: '42.979342',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '56616',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЛАКУДЕР',
    nameEn: 'PLAKUDER',
    municipality: 'ВИДИН',
    municipalityEn: 'VIDIN',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3781',
    addressNomenclature: '0',
    x: '22.742902',
    y: '44.051032',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '56624',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЛАНА',
    nameEn: 'PLANA',
    municipality: 'СТОЛИЧНА',
    municipalityEn: 'STOLICHNA',
    region: 'СОФИЯ (СТОЛИЦА)',
    regionEn: 'SOFIA (STOLITSA)',
    postCode: '1475',
    addressNomenclature: '0',
    x: '23.408729',
    y: '42.487528',
    servingDays: '0101000',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '56633',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЛАНИНЕЦ',
    nameEn: 'PLANINETS',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6599',
    addressNomenclature: '0',
    x: '25.921291',
    y: '41.500813',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '56647',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЛАНИНИЦА',
    nameEn: 'PLANINITSA',
    municipality: 'РУЕН',
    municipalityEn: 'RUEN',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8565',
    addressNomenclature: '0',
    x: '27.156464',
    y: '42.891275',
    servingDays: '1111100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '56650',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЛАНИНИЦА',
    nameEn: 'PLANINITSA',
    municipality: 'ПЕРНИК',
    municipalityEn: 'PERNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2340',
    addressNomenclature: '0',
    x: '22.879367',
    y: '42.600076',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '56664',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЛАНИНОВО',
    nameEn: 'PLANINOVO',
    municipality: 'ТОПОЛОВГРАД',
    municipalityEn: 'TOPOLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6560',
    addressNomenclature: '0',
    x: '26.375499',
    y: '41.956937',
    servingDays: '0101000',
    servingOfficeId: '48',
    servingHubOfficeId: '5',
  },
  {
    id: '59313',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЛАНИНСКО',
    nameEn: 'PLANINSKO',
    municipality: 'БАНИТЕ',
    municipalityEn: 'BANITE',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4940',
    addressNomenclature: '0',
    x: '24.993039',
    y: '41.755285',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '56678',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЛАНИНЦИ',
    nameEn: 'PLANINTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5367',
    addressNomenclature: '0',
    x: '25.534018',
    y: '42.842908',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '56681',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЛАНИНЦИ',
    nameEn: 'PLANINTSI',
    municipality: 'МАДАН',
    municipalityEn: 'MADAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4921',
    addressNomenclature: '0',
    x: '24.968021',
    y: '41.580305',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '56695',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЛАЧИДОЛ',
    nameEn: 'PLACHIDOL',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9365',
    addressNomenclature: '0',
    x: '27.88296',
    y: '43.54004',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '56705',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЛАЧКА',
    nameEn: 'PLACHKA',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5370',
    addressNomenclature: '0',
    x: '25.428807',
    y: '43.018062',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '56719',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ПЛАЧКОВЦИ',
    nameEn: 'PLACHKOVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5360',
    addressNomenclature: '2',
    x: '25.48138',
    y: '42.824388',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '56722',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ПЛЕВЕН',
    nameEn: 'PLEVEN',
    municipality: 'ПЛЕВЕН',
    municipalityEn: 'PLEVEN',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5800',
    addressNomenclature: '1',
    x: '24.608327',
    y: '43.41841',
    servingDays: '1111110',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '55748',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЛЕВУН',
    nameEn: 'PLEVUN',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6593',
    addressNomenclature: '0',
    x: '26.019644',
    y: '41.455998',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '56736',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЛЕНИМИР',
    nameEn: 'PLENIMIR',
    municipality: 'ГЕНЕРАЛ ТОШЕВО',
    municipalityEn: 'GENERAL TOSHEVO',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9522',
    addressNomenclature: '0',
    x: '27.982283',
    y: '43.655038',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '56740',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЛЕТЕНА',
    nameEn: 'PLETENA',
    municipality: 'САТОВЧА',
    municipalityEn: 'SATOVCHA',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2954',
    addressNomenclature: '0',
    x: '23.956331',
    y: '41.638366',
    servingDays: '1111100',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '56753',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЛЕШИВЕЦ',
    nameEn: 'PLESHIVETS',
    municipality: 'РУЖИНЦИ',
    municipalityEn: 'RUZHINTSI',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3931',
    addressNomenclature: '0',
    x: '22.829139',
    y: '43.59429',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '56767',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЛЕШИНЦИ',
    nameEn: 'PLESHINTSI',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6800',
    addressNomenclature: '0',
    x: '25.469556',
    y: '41.536067',
    servingDays: '1111110',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '56770',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ПЛИСКА',
    nameEn: 'PLISKA',
    municipality: 'КАСПИЧАН',
    municipalityEn: 'KASPICHAN',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9920',
    addressNomenclature: '0',
    x: '27.125412',
    y: '43.362015',
    servingDays: '1111100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '56784',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ПЛОВДИВ',
    nameEn: 'PLOVDIV',
    municipality: 'ПЛОВДИВ',
    municipalityEn: 'PLOVDIV',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4000',
    addressNomenclature: '1',
    x: '24.751459',
    y: '42.143543',
    servingDays: '1111110',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '56798',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЛОВДИВЦИ',
    nameEn: 'PLOVDIVTSI',
    municipality: 'РУДОЗЕМ',
    municipalityEn: 'RUDOZEM',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4979',
    addressNomenclature: '0',
    x: '24.80074',
    y: '41.410578',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '56808',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЛОВКА',
    nameEn: 'PLOVKA',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6890',
    addressNomenclature: '0',
    x: '25.472609',
    y: '41.402831',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '56811',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЛОДОВИТОВО',
    nameEn: 'PLODOVITOVO',
    municipality: 'БРАТЯ ДАСКАЛОВИ',
    municipalityEn: 'BRATYA DASKALOVI',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6257',
    addressNomenclature: '0',
    x: '25.209096',
    y: '42.177255',
    servingDays: '1111100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '56825',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЛОСКА МОГИЛА',
    nameEn: 'PLOSKA MOGILA',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6055',
    addressNomenclature: '0',
    x: '25.818126',
    y: '42.429303',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '56839',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЛОСКИ',
    nameEn: 'PLOSKI',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2810',
    addressNomenclature: '0',
    x: '23.266871',
    y: '41.645046',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '56842',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЛЪЗИЩЕ',
    nameEn: 'PLAZISHTE',
    municipality: 'МИРКОВО',
    municipalityEn: 'MIRKOVO',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2072',
    addressNomenclature: '0',
    x: '23.953612',
    y: '42.580468',
    servingDays: '0101000',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '56856',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЛЪСТИНА',
    nameEn: 'PLASTINA',
    municipality: 'ОМУРТАГ',
    municipalityEn: 'OMURTAG',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7934',
    addressNomenclature: '0',
    x: '26.620223',
    y: '43.06113',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '21083',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОБЕДА',
    nameEn: 'POBEDA',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9372',
    addressNomenclature: '0',
    x: '27.898288',
    y: '43.592625',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '56865',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОБЕДА',
    nameEn: 'POBEDA',
    municipality: 'ДОЛНА МИТРОПОЛИЯ',
    municipalityEn: 'DOLNA MITROPOLIA',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5873',
    addressNomenclature: '0',
    x: '24.548948',
    y: '43.502051',
    servingDays: '1010100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '56873',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОБЕДА',
    nameEn: 'POBEDA',
    municipality: 'ТУНДЖА',
    municipalityEn: 'TUNDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8679',
    addressNomenclature: '0',
    x: '26.645319',
    y: '42.424823',
    servingDays: '1010100',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '56887',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОБИТ КАМЪК',
    nameEn: 'POBIT KAMAK',
    municipality: 'ТРЕКЛЯНО',
    municipalityEn: 'TREKLYANO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2554',
    addressNomenclature: '0',
    x: '22.455917',
    y: '42.598156',
    servingDays: '0101000',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '56890',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОБИТ КАМЪК',
    nameEn: 'POBIT KAMAK',
    municipality: 'РАЗГРАД',
    municipalityEn: 'RAZGRAD',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7262',
    addressNomenclature: '0',
    x: '26.543183',
    y: '43.668167',
    servingDays: '1111100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '59224',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОБИТ КАМЪК',
    nameEn: 'POBIT KAMAK',
    municipality: 'СЪРНИЦА',
    municipalityEn: 'SARNITSA',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4638',
    addressNomenclature: '0',
    x: '23.885641',
    y: '41.824485',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '56900',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОБЪК',
    nameEn: 'POBAK',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5365',
    addressNomenclature: '0',
    x: '25.612923',
    y: '42.906663',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '56914',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОВАЛИРЪЖ',
    nameEn: 'POVALIRAZH',
    municipality: 'СЛИВНИЦА',
    municipalityEn: 'SLIVNITSA',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2218',
    addressNomenclature: '0',
    x: '22.893701',
    y: '42.835733',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '56928',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОВЕТ',
    nameEn: 'POVET',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6630',
    addressNomenclature: '0',
    x: '25.401784',
    y: '41.651576',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '56945',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОДАЙВА',
    nameEn: 'PODAYVA',
    municipality: 'ИСПЕРИХ',
    municipalityEn: 'ISPERIH',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7431',
    addressNomenclature: '0',
    x: '26.91973',
    y: '43.672996',
    servingDays: '0101000',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '56959',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОДВИС',
    nameEn: 'PODVIS',
    municipality: 'СУНГУРЛАРЕ',
    municipalityEn: 'SUNGURLARE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8450',
    addressNomenclature: '0',
    x: '26.845706',
    y: '42.838929',
    servingDays: '0101000',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '56962',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОДВИС',
    nameEn: 'PODVIS',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4733',
    addressNomenclature: '0',
    x: '24.832826',
    y: '41.568535',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '56976',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОДВРЪХ',
    nameEn: 'PODVRAH',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6850',
    addressNomenclature: '0',
    x: '25.337483',
    y: '41.498561',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '56980',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОДГОРЕ',
    nameEn: 'PODGORE',
    municipality: 'МАКРЕШ',
    municipalityEn: 'MAKRESH',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3852',
    addressNomenclature: '0',
    x: '22.489528',
    y: '43.750365',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '15967',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОДГОРЕЦ',
    nameEn: 'PODGORETS',
    municipality: 'РУЕН',
    municipalityEn: 'RUEN',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8535',
    addressNomenclature: '0',
    x: '27.402772',
    y: '42.836061',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '56993',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОДГОРИЕ',
    nameEn: 'PODGORIE',
    municipality: 'КОСТЕНЕЦ',
    municipalityEn: 'KOSTENETS',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2049',
    addressNomenclature: '0',
    x: '23.769699',
    y: '42.375155',
    servingDays: '1010100',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '57008',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОДГОРИЦА',
    nameEn: 'PODGORITSA',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7768',
    addressNomenclature: '0',
    x: '26.470137',
    y: '43.3078',
    servingDays: '1111100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '57011',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОДГУМЕР',
    nameEn: 'PODGUMER',
    municipality: 'СТОЛИЧНА',
    municipalityEn: 'STOLICHNA',
    region: 'СОФИЯ (СТОЛИЦА)',
    regionEn: 'SOFIA (STOLITSA)',
    postCode: '1221',
    addressNomenclature: '2',
    x: '23.401676',
    y: '42.801267',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '57025',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОДЕМ',
    nameEn: 'PODEM',
    municipality: 'ДОЛНА МИТРОПОЛИЯ',
    municipalityEn: 'DOLNA MITROPOLIA',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5852',
    addressNomenclature: '0',
    x: '24.589466',
    y: '43.540998',
    servingDays: '1010100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '57039',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОДКОВА',
    nameEn: 'PODKOVA',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6880',
    addressNomenclature: '0',
    x: '25.39773',
    y: '41.398201',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '57042',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОДКРЕПА',
    nameEn: 'PODKREPA',
    municipality: 'ХАСКОВО',
    municipalityEn: 'HASKOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6312',
    addressNomenclature: '0',
    x: '25.679614',
    y: '41.918677',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '57056',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОДЛЕС',
    nameEn: 'PODLES',
    municipality: 'ГЛАВИНИЦА',
    municipalityEn: 'GLAVINITSA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7632',
    addressNomenclature: '0',
    x: '26.850449',
    y: '43.865053',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '57062',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОДРУМЧЕ',
    nameEn: 'PODRUMCHE',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6945',
    addressNomenclature: '0',
    x: '25.725771',
    y: '41.4234',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '57073',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОДСЛОН',
    nameEn: 'PODSLON',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6057',
    addressNomenclature: '0',
    x: '25.869566',
    y: '42.472873',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '57087',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОДСЛОН',
    nameEn: 'PODSLON',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9391',
    addressNomenclature: '0',
    x: '27.513907',
    y: '43.672527',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '57090',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОЖАРЕВО',
    nameEn: 'POZHAREVO',
    municipality: 'ТУТРАКАН',
    municipalityEn: 'TUTRAKAN',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7622',
    addressNomenclature: '0',
    x: '26.705915',
    y: '44.051961',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '57100',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОЖАРЕВО',
    nameEn: 'POZHAREVO',
    municipality: 'БОЖУРИЩЕ',
    municipalityEn: 'BOZHURISHTE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2229',
    addressNomenclature: '0',
    x: '23.132278',
    y: '42.754365',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '57114',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОЖЕРНИК',
    nameEn: 'POZHERNIK',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5041',
    addressNomenclature: '0',
    x: '25.632858',
    y: '42.944525',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '57128',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОИБРЕНЕ',
    nameEn: 'POIBRENE',
    municipality: 'ПАНАГЮРИЩЕ',
    municipalityEn: 'PANAGYURISHTE',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4524',
    addressNomenclature: '0',
    x: '24.002615',
    y: '42.503555',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '57131',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОКРАЙНА',
    nameEn: 'POKRAYNA',
    municipality: 'ВИДИН',
    municipalityEn: 'VIDIN',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3764',
    addressNomenclature: '0',
    x: '22.902831',
    y: '44.03223',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '57145',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОКРОВАН',
    nameEn: 'POKROVAN',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6575',
    addressNomenclature: '0',
    x: '26.04986',
    y: '41.546018',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '57159',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОКРОВНИК',
    nameEn: 'POKROVNIK',
    municipality: 'БЛАГОЕВГРАД',
    municipalityEn: 'BLAGOEVGRAD',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2708',
    addressNomenclature: '0',
    x: '23.054697',
    y: '41.983494',
    servingDays: '1111110',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '57162',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОЛЕНА',
    nameEn: 'POLENA',
    municipality: 'СИМИТЛИ',
    municipalityEn: 'SIMITLI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2758',
    addressNomenclature: '0',
    x: '23.106873',
    y: '41.846455',
    servingDays: '1111100',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '57176',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОЛЕНИЦА',
    nameEn: 'POLENITSA',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2804',
    addressNomenclature: '0',
    x: '23.295617',
    y: '41.566031',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '57188',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОЛЕТИНЦИ',
    nameEn: 'POLETINTSI',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2561',
    addressNomenclature: '0',
    x: '22.621988',
    y: '42.429756',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '57193',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОЛЕТКОВЦИ',
    nameEn: 'POLETKOVTSI',
    municipality: 'КУЛА',
    municipalityEn: 'KULA',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3818',
    addressNomenclature: '0',
    x: '22.519821',
    y: '43.86025',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '57203',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОЛЕТО',
    nameEn: 'POLETO',
    municipality: 'СИМИТЛИ',
    municipalityEn: 'SIMITLI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2751',
    addressNomenclature: '0',
    x: '23.147403',
    y: '41.861008',
    servingDays: '1111100',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '57217',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОЛИКРАИЩЕ',
    nameEn: 'POLIKRAISHTE',
    municipality: 'ГОРНА ОРЯХОВИЦА',
    municipalityEn: 'GORNA ORYAHOVITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5138',
    addressNomenclature: '0',
    x: '25.620149',
    y: '43.178406',
    servingDays: '1111100',
    servingOfficeId: '46',
    servingHubOfficeId: '11',
  },
  {
    id: '57234',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОЛКОВНИК ДЯКОВО',
    nameEn: 'POLKOVNIK DYAKOVO',
    municipality: 'КРУШАРИ',
    municipalityEn: 'KRUSHARI',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9401',
    addressNomenclature: '0',
    x: '27.788855',
    y: '43.846106',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '57248',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОЛКОВНИК ЖЕЛЯЗОВО',
    nameEn: 'POLKOVNIK ZHELYAZOVO',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6909',
    addressNomenclature: '0',
    x: '25.673428',
    y: '41.465945',
    servingDays: '1111100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '68103',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОЛКОВНИК ИВАНОВО',
    nameEn: 'POLKOVNIK IVANOVO',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9432',
    addressNomenclature: '0',
    x: '27.627517',
    y: '43.682368',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '57251',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОЛКОВНИК ЛАМБРИНОВО',
    nameEn: 'POLKOVNIK LAMBRINOVO',
    municipality: 'СИЛИСТРА',
    municipalityEn: 'SILISTRA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7579',
    addressNomenclature: '0',
    x: '27.165642',
    y: '44.048216',
    servingDays: '1010100',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '59402',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОЛКОВНИК МИНКОВО',
    nameEn: 'POLKOVNIK MINKOVO',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9372',
    addressNomenclature: '0',
    x: '27.897954',
    y: '43.579313',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '57265',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОЛКОВНИК САВОВО',
    nameEn: 'POLKOVNIK SAVOVO',
    municipality: 'ТЕРВЕЛ',
    municipalityEn: 'TERVEL',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9471',
    addressNomenclature: '0',
    x: '27.402803',
    y: '43.710154',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '57279',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОЛКОВНИК СВЕЩАРОВО',
    nameEn: 'POLKOVNIK SVESHTAROVO',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9378',
    addressNomenclature: '0',
    x: '27.935963',
    y: '43.552643',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '57282',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОЛКОВНИК СЕРАФИМОВО',
    nameEn: 'POLKOVNIK SERAFIMOVO',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4794',
    addressNomenclature: '0',
    x: '24.779604',
    y: '41.529193',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '57296',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОЛКОВНИК ТАСЛАКОВО',
    nameEn: 'POLKOVNIK TASLAKOVO',
    municipality: 'ДУЛОВО',
    municipalityEn: 'DULOVO',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7677',
    addressNomenclature: '0',
    x: '27.213777',
    y: '43.779509',
    servingDays: '0101000',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '57306',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОЛКОВНИК ЧОЛАКОВО',
    nameEn: 'POLKOVNIK CHOLAKOVO',
    municipality: 'КАЙНАРДЖА',
    municipalityEn: 'KAYNARDZHA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7553',
    addressNomenclature: '0',
    x: '27.553205',
    y: '43.937658',
    servingDays: '0101000',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '57319',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОЛСКА СКАКАВИЦА',
    nameEn: 'POLSKA SKAKAVITSA',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2551',
    addressNomenclature: '0',
    x: '22.675572',
    y: '42.403006',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '57323',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОЛСКИ ГРАДЕЦ',
    nameEn: 'POLSKI GRADETS',
    municipality: 'РАДНЕВО',
    municipalityEn: 'RADNEVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6276',
    addressNomenclature: '0',
    x: '26.110951',
    y: '42.189449',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '57337',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОЛСКИ ИЗВОР',
    nameEn: 'POLSKI IZVOR',
    municipality: 'КАМЕНО',
    municipalityEn: 'KAMENO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8123',
    addressNomenclature: '0',
    x: '27.290189',
    y: '42.450014',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '57340',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОЛСКИ СЕНОВЕЦ',
    nameEn: 'POLSKI SENOVETS',
    municipality: 'ПОЛСКИ ТРЪМБЕШ',
    municipalityEn: 'POLSKI TRAMBESH',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5193',
    addressNomenclature: '0',
    x: '25.604673',
    y: '43.301971',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '57354',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ПОЛСКИ ТРЪМБЕШ',
    nameEn: 'POLSKI TRAMBESH',
    municipality: 'ПОЛСКИ ТРЪМБЕШ',
    municipalityEn: 'POLSKI TRAMBESH',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5180',
    addressNomenclature: '2',
    x: '25.631915',
    y: '43.367594',
    servingDays: '1111100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '57368',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОЛСКО КОСОВО',
    nameEn: 'POLSKO KOSOVO',
    municipality: 'БЯЛА',
    municipalityEn: 'BYALA',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7131',
    addressNomenclature: '0',
    x: '25.656904',
    y: '43.425232',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '57371',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОЛСКО ПЪДАРЕВО',
    nameEn: 'POLSKO PADAREVO',
    municipality: 'НОВА ЗАГОРА',
    municipalityEn: 'NOVA ZAGORA',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8937',
    addressNomenclature: '0',
    x: '26.086896',
    y: '42.461214',
    servingDays: '0101000',
    servingOfficeId: '56',
    servingHubOfficeId: '5',
  },
  {
    id: '57385',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОЛЯНА',
    nameEn: 'POLYANA',
    municipality: 'СИТОВО',
    municipalityEn: 'SITOVO',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7584',
    addressNomenclature: '0',
    x: '26.980298',
    y: '44.059447',
    servingDays: '1010100',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '57399',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОЛЯНА',
    nameEn: 'POLYANA',
    municipality: 'РУДОЗЕМ',
    municipalityEn: 'RUDOZEM',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4977',
    addressNomenclature: '0',
    x: '24.747592',
    y: '41.430366',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '57409',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОЛЯНА',
    nameEn: 'POLYANA',
    municipality: 'СТРАЛДЖА',
    municipalityEn: 'STRALDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8654',
    addressNomenclature: '0',
    x: '26.820988',
    y: '42.303065',
    servingDays: '0101000',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '57412',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОЛЯНЕЦ',
    nameEn: 'POLYANETS',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6847',
    addressNomenclature: '0',
    x: '25.345034',
    y: '41.480869',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '57426',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОЛЯНОВО',
    nameEn: 'POLYANOVO',
    municipality: 'АЙТОС',
    municipalityEn: 'AYTOS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8544',
    addressNomenclature: '0',
    x: '27.176764',
    y: '42.716278',
    servingDays: '1111100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '57434',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОЛЯНОВО',
    nameEn: 'POLYANOVO',
    municipality: 'ХАРМАНЛИ',
    municipalityEn: 'HARMANLI',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6464',
    addressNomenclature: '0',
    x: '25.819331',
    y: '41.969524',
    servingDays: '0101000',
    servingOfficeId: '42',
    servingHubOfficeId: '5',
  },
  {
    id: '57443',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОЛЯНЦИ',
    nameEn: 'POLYANTSI',
    municipality: 'ИХТИМАН',
    municipalityEn: 'IHTIMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2068',
    addressNomenclature: '0',
    x: '23.735644',
    y: '42.42235',
    servingDays: '1010100',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '57457',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОЛЯЦИТЕ',
    nameEn: 'POLYATSITE',
    municipality: 'ДЪЛГОПОЛ',
    municipalityEn: 'DALGOPOL',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9239',
    addressNomenclature: '0',
    x: '27.122054',
    y: '42.983929',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '57474',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОМЕЖДИН',
    nameEn: 'POMEZHDIN',
    municipality: 'ГЕОРГИ ДАМЯНОВО',
    municipalityEn: 'GEORGI DAMYANOVO',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3476',
    addressNomenclature: '0',
    x: '22.988188',
    y: '43.378481',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '57488',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОМЕН',
    nameEn: 'POMEN',
    municipality: 'ДВЕ МОГИЛИ',
    municipalityEn: 'DVE MOGILI',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7164',
    addressNomenclature: '0',
    x: '25.981494',
    y: '43.465904',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '57491',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ПОМОРИЕ',
    nameEn: 'POMORIE',
    municipality: 'ПОМОРИЕ',
    municipalityEn: 'POMORIE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8200',
    addressNomenclature: '2',
    x: '27.63818',
    y: '42.559717',
    servingDays: '1111110',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '57501',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОМОЩИЦА',
    nameEn: 'POMOSHTITSA',
    municipality: 'ПОПОВО',
    municipalityEn: 'POPOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7839',
    addressNomenclature: '0',
    x: '26.334358',
    y: '43.471401',
    servingDays: '0101000',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '57515',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОМОЩНИК',
    nameEn: 'POMOSHTNIK',
    municipality: 'ГЪЛЪБОВО',
    municipalityEn: 'GALABOVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6297',
    addressNomenclature: '0',
    x: '26.049855',
    y: '42.050363',
    servingDays: '1010100',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '57529',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОНОР',
    nameEn: 'PONOR',
    municipality: 'КОСТИНБРОД',
    municipalityEn: 'KOSTINBROD',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2236',
    addressNomenclature: '0',
    x: '23.13824',
    y: '42.923423',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '57532',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОПАРИ',
    nameEn: 'POPARI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5343',
    addressNomenclature: '0',
    x: '25.340278',
    y: '42.920274',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '57546',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОПГЕРГЕВЦИ',
    nameEn: 'POPGERGEVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5365',
    addressNomenclature: '0',
    x: '25.563717',
    y: '42.883134',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '57550',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОПГРИГОРОВО',
    nameEn: 'POPGRIGOROVO',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9377',
    addressNomenclature: '0',
    x: '27.933678',
    y: '43.523563',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '57563',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОПГРУЕВО',
    nameEn: 'POPGRUEVO',
    municipality: 'ТЕРВЕЛ',
    municipalityEn: 'TERVEL',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9478',
    addressNomenclature: '0',
    x: '27.480971',
    y: '43.742286',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '57577',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОПИНА',
    nameEn: 'POPINA',
    municipality: 'СИТОВО',
    municipalityEn: 'SITOVO',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7590',
    addressNomenclature: '0',
    x: '26.951211',
    y: '44.121051',
    servingDays: '1010100',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '57580',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОПИНЦИ',
    nameEn: 'POPINTSI',
    municipality: 'ПАНАГЮРИЩЕ',
    municipalityEn: 'PANAGYURISHTE',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4528',
    addressNomenclature: '0',
    x: '24.276404',
    y: '42.422964',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '57594',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОПИЦА',
    nameEn: 'POPITSA',
    municipality: 'БЯЛА СЛАТИНА',
    municipalityEn: 'BYALA SLATINA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3244',
    addressNomenclature: '0',
    x: '23.956106',
    y: '43.428084',
    servingDays: '0101000',
    servingOfficeId: '59',
    servingHubOfficeId: '20',
  },
  {
    id: '57604',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОПКРАЛЕВО',
    nameEn: 'POPKRALEVO',
    municipality: 'СИЛИСТРА',
    municipalityEn: 'SILISTRA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7545',
    addressNomenclature: '0',
    x: '27.373094',
    y: '43.982614',
    servingDays: '0101000',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '57618',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОПОВЕЦ',
    nameEn: 'POPOVETS',
    municipality: 'СТАМБОЛОВО',
    municipalityEn: 'STAMBOLOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6398',
    addressNomenclature: '0',
    x: '25.63858',
    y: '41.70512',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '57621',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОПОВИЦА',
    nameEn: 'POPOVITSA',
    municipality: 'САДОВО',
    municipalityEn: 'SADOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4127',
    addressNomenclature: '0',
    x: '25.054584',
    y: '42.136354',
    servingDays: '1010100',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '57635',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОПОВИЧ',
    nameEn: 'POPOVICH',
    municipality: 'БЯЛА',
    municipalityEn: 'BYALA',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9104',
    addressNomenclature: '0',
    x: '27.793861',
    y: '42.834133',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '57649',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ПОПОВО',
    nameEn: 'POPOVO',
    municipality: 'ПОПОВО',
    municipalityEn: 'POPOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7800',
    addressNomenclature: '2',
    x: '26.22701',
    y: '43.34913',
    servingDays: '1111110',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '57652',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОПОВО',
    nameEn: 'POPOVO',
    municipality: 'БОЛЯРОВО',
    municipalityEn: 'BOLYAROVO',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8735',
    addressNomenclature: '0',
    x: '26.733987',
    y: '42.207445',
    servingDays: '0101000',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '57666',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОПОВЦИ',
    nameEn: 'POPOVTSI',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5035',
    addressNomenclature: '0',
    x: '25.687491',
    y: '42.884828',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '57675',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОПОВЦИ',
    nameEn: 'POPOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5345',
    addressNomenclature: '0',
    x: '25.275018',
    y: '42.900394',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '57683',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОПОВЦИ',
    nameEn: 'POPOVTSI',
    municipality: 'ИХТИМАН',
    municipalityEn: 'IHTIMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2060',
    addressNomenclature: '0',
    x: '23.690669',
    y: '42.530661',
    servingDays: '1010100',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '57697',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОПОВЯНЕ',
    nameEn: 'POPOVYANE',
    municipality: 'САМОКОВ',
    municipalityEn: 'SAMOKOV',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2014',
    addressNomenclature: '0',
    x: '23.349918',
    y: '42.424012',
    servingDays: '0101000',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '57707',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОПРАЙКОВЦИ',
    nameEn: 'POPRAYKOVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5365',
    addressNomenclature: '0',
    x: '25.542522',
    y: '42.896189',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '57710',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОПРЕЛКА',
    nameEn: 'POPRELKA',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4759',
    addressNomenclature: '0',
    x: '24.63678',
    y: '41.535637',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '57724',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОПРУСАНОВО',
    nameEn: 'POPRUSANOVO',
    municipality: 'КАЙНАРДЖА',
    municipalityEn: 'KAYNARDZHA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7564',
    addressNomenclature: '0',
    x: '27.419448',
    y: '43.900935',
    servingDays: '0101000',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '57738',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОПРУСЕВЦИ',
    nameEn: 'POPRUSEVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5084',
    addressNomenclature: '0',
    x: '25.978911',
    y: '42.941184',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '57741',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОПСКА',
    nameEn: 'POPSKA',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5084',
    addressNomenclature: '0',
    x: '25.997751',
    y: '42.961889',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '57755',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОПСКА',
    nameEn: 'POPSKA',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5466',
    addressNomenclature: '0',
    x: '25.068972',
    y: '42.880541',
    servingDays: '1010100',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '57769',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОПСКО',
    nameEn: 'POPSKO',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6580',
    addressNomenclature: '0',
    x: '25.850444',
    y: '41.552079',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '57772',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ПОРДИМ',
    nameEn: 'PORDIM',
    municipality: 'ПОРДИМ',
    municipalityEn: 'PORDIM',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5898',
    addressNomenclature: '2',
    x: '24.847148',
    y: '43.376927',
    servingDays: '0101000',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '59416',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОРОИЩЕ',
    nameEn: 'POROISHTE',
    municipality: 'РАЗГРАД',
    municipalityEn: 'RAZGRAD',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7218',
    addressNomenclature: '0',
    x: '26.578579',
    y: '43.488727',
    servingDays: '0101000',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '57790',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОРОЙ',
    nameEn: 'POROY',
    municipality: 'ПОМОРИЕ',
    municipalityEn: 'POMORIE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8221',
    addressNomenclature: '0',
    x: '27.547485',
    y: '42.750882',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '57806',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОРОЙНА',
    nameEn: 'POROYNA',
    municipality: 'ПЪРВОМАЙ',
    municipalityEn: 'PARVOMAY',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4281',
    addressNomenclature: '0',
    x: '25.136997',
    y: '42.039222',
    servingDays: '1010100',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '57813',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОРОЙНО',
    nameEn: 'POROYNO',
    municipality: 'ДУЛОВО',
    municipalityEn: 'DULOVO',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7658',
    addressNomenclature: '0',
    x: '27.118951',
    y: '43.843567',
    servingDays: '1010100',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '57827',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОРОЙНО',
    nameEn: 'POROYNO',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7952',
    addressNomenclature: '0',
    x: '26.234528',
    y: '43.118188',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '57830',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОРОМИНОВО',
    nameEn: 'POROMINOVO',
    municipality: 'КОЧЕРИНОВО',
    municipalityEn: 'KOCHERINOVO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2639',
    addressNomenclature: '0',
    x: '23.087335',
    y: '42.080755',
    servingDays: '1111100',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '57844',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОРТИТОВЦИ',
    nameEn: 'PORTITOVTSI',
    municipality: 'БОЙЧИНОВЦИ',
    municipalityEn: 'BOYCHINOVTSI',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3432',
    addressNomenclature: '0',
    x: '23.364725',
    y: '43.493704',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '57858',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОРУЧИК КЪРДЖИЕВО',
    nameEn: 'PORUCHIK KARDZHIEVO',
    municipality: 'КРУШАРИ',
    municipalityEn: 'KRUSHARI',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9409',
    addressNomenclature: '0',
    x: '27.801641',
    y: '43.943401',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '57861',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОРУЧИК ЧУНЧЕВО',
    nameEn: 'PORUCHIK CHUNCHEVO',
    municipality: 'КАВАРНА',
    municipalityEn: 'KAVARNA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9658',
    addressNomenclature: '0',
    x: '28.471065',
    y: '43.485462',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '57875',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОСАБИНА',
    nameEn: 'POSABINA',
    municipality: 'ПОПОВО',
    municipalityEn: 'POPOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7853',
    addressNomenclature: '0',
    x: '26.121746',
    y: '43.326608',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '57889',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОСЕВ',
    nameEn: 'POSEV',
    municipality: 'КАЙНАРДЖА',
    municipalityEn: 'KAYNARDZHA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7567',
    addressNomenclature: '0',
    x: '27.423698',
    y: '43.867778',
    servingDays: '0101000',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '57892',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОСТНИК',
    nameEn: 'POSTNIK',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6833',
    addressNomenclature: '0',
    x: '25.562357',
    y: '41.580594',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '59327',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОТОК',
    nameEn: 'POTOK',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5309',
    addressNomenclature: '0',
    x: '25.365373',
    y: '42.769877',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '57916',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОТОКА',
    nameEn: 'POTOKA',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4768',
    addressNomenclature: '0',
    x: '24.605503',
    y: '41.471719',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '57921',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОТОП',
    nameEn: 'POTOP',
    municipality: 'ЕЛИН ПЕЛИН',
    municipalityEn: 'ELIN PELIN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2127',
    addressNomenclature: '0',
    x: '23.66284',
    y: '42.748001',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '57933',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОТОЧАРКА',
    nameEn: 'POTOCHARKA',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6963',
    addressNomenclature: '0',
    x: '25.675729',
    y: '41.608172',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '57947',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОТОЧЕ',
    nameEn: 'POTOCHE',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6850',
    addressNomenclature: '0',
    x: '25.316186',
    y: '41.543722',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '57950',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОТОЧНИЦА',
    nameEn: 'POTOCHNITSA',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6960',
    addressNomenclature: '0',
    x: '25.683669',
    y: '41.611668',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '57964',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПОЦЪРНЕНЦИ',
    nameEn: 'POTSARNENTSI',
    municipality: 'РАДОМИР',
    municipalityEn: 'RADOMIR',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2455',
    addressNomenclature: '0',
    x: '22.890549',
    y: '42.492161',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '57981',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРАВДА',
    nameEn: 'PRAVDA',
    municipality: 'ГОРНА ОРЯХОВИЦА',
    municipalityEn: 'GORNA ORYAHOVITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5125',
    addressNomenclature: '0',
    x: '25.682736',
    y: '43.159227',
    servingDays: '1010100',
    servingOfficeId: '46',
    servingHubOfficeId: '11',
  },
  {
    id: '57995',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРАВДА',
    nameEn: 'PRAVDA',
    municipality: 'ДУЛОВО',
    municipalityEn: 'DULOVO',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7690',
    addressNomenclature: '0',
    x: '26.999202',
    y: '43.87426',
    servingDays: '0101000',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '58003',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРАВДИНО',
    nameEn: 'PRAVDINO',
    municipality: 'СТРАЛДЖА',
    municipalityEn: 'STRALDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8695',
    addressNomenclature: '0',
    x: '26.9284',
    y: '42.418691',
    servingDays: '0101000',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '58013',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРАВДОЛЮБ',
    nameEn: 'PRAVDOLYUB',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6765',
    addressNomenclature: '0',
    x: '25.145352',
    y: '41.596701',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '58027',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРАВЕНЦИ',
    nameEn: 'PRAVENTSI',
    municipality: 'НОВИ ПАЗАР',
    municipalityEn: 'NOVI PAZAR',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9945',
    addressNomenclature: '0',
    x: '27.074898',
    y: '43.431826',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '58030',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ПРАВЕЦ',
    nameEn: 'PRAVETS',
    municipality: 'ПРАВЕЦ',
    municipalityEn: 'PRAVETS',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2161',
    addressNomenclature: '2',
    x: '23.91904',
    y: '42.894412',
    servingDays: '1111100',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '58044',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРАВЕШКА ЛАКАВИЦА',
    nameEn: 'PRAVESHKA LAKAVITSA',
    municipality: 'ПРАВЕЦ',
    municipalityEn: 'PRAVETS',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2170',
    addressNomenclature: '0',
    x: '23.898351',
    y: '42.952557',
    servingDays: '1010100',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '58058',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРАВИЩЕ',
    nameEn: 'PRAVISHTE',
    municipality: 'СЪЕДИНЕНИЕ',
    municipalityEn: 'SAEDINENIE',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4191',
    addressNomenclature: '0',
    x: '24.558244',
    y: '42.335308',
    servingDays: '0101000',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '58061',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРАВО БЪРДО',
    nameEn: 'PRAVO BARDO',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2894',
    addressNomenclature: '0',
    x: '23.083957',
    y: '41.438302',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '58075',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРАВОСЛАВ',
    nameEn: 'PRAVOSLAV',
    municipality: 'БРАТЯ ДАСКАЛОВИ',
    municipalityEn: 'BRATYA DASKALOVI',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6248',
    addressNomenclature: '0',
    x: '25.147834',
    y: '42.341268',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '58089',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРАВОСЛАВЕН',
    nameEn: 'PRAVOSLAVEN',
    municipality: 'ПЪРВОМАЙ',
    municipalityEn: 'PARVOMAY',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4287',
    addressNomenclature: '0',
    x: '25.237912',
    y: '42.041802',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '58092',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРАУЖДА',
    nameEn: 'PRAUZHDA',
    municipality: 'БЕЛОГРАДЧИК',
    municipalityEn: 'BELOGRADCHIK',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3917',
    addressNomenclature: '0',
    x: '22.600837',
    y: '43.603869',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '58102',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРАХАЛИ',
    nameEn: 'PRAHALI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5345',
    addressNomenclature: '0',
    x: '25.278052',
    y: '42.911726',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '58116',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРЕВАЛА',
    nameEn: 'PREVALA',
    municipality: 'ЧИПРОВЦИ',
    municipalityEn: 'CHIPROVTSI',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3457',
    addressNomenclature: '0',
    x: '22.859101',
    y: '43.468596',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '58129',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРЕКОЛНИЦА',
    nameEn: 'PREKOLNITSA',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2571',
    addressNomenclature: '0',
    x: '22.478369',
    y: '42.273858',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '58133',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРЕКРЪСТЕ',
    nameEn: 'PREKRASTE',
    municipality: 'ДРАГОМАН',
    municipalityEn: 'DRAGOMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2206',
    addressNomenclature: '0',
    x: '22.929568',
    y: '42.974634',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '58147',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРЕЛЕЗ',
    nameEn: 'PRELEZ',
    municipality: 'ЗАВЕТ',
    municipalityEn: 'ZAVET',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7325',
    addressNomenclature: '0',
    x: '26.691943',
    y: '43.816983',
    servingDays: '0101000',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '58150',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРЕЛОМ',
    nameEn: 'PRELOM',
    municipality: 'ЛОВЕЧ',
    municipalityEn: 'LOVECH',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5545',
    addressNomenclature: '0',
    x: '24.844243',
    y: '43.076018',
    servingDays: '0101000',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '51055',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРЕОБРАЖЕНЦИ',
    nameEn: 'PREOBRAZHENTSI',
    municipality: 'РУЕН',
    municipalityEn: 'RUEN',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8541',
    addressNomenclature: '0',
    x: '27.333674',
    y: '42.796925',
    servingDays: '1111100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '58178',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРЕСЕКА',
    nameEn: 'PRESEKA',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6862',
    addressNomenclature: '0',
    x: '25.181432',
    y: '41.387984',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '58181',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРЕСЕЛЕНЦИ',
    nameEn: 'PRESELENTSI',
    municipality: 'ГЕНЕРАЛ ТОШЕВО',
    municipalityEn: 'GENERAL TOSHEVO',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9558',
    addressNomenclature: '0',
    x: '28.133831',
    y: '43.643427',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '58195',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРЕСЕЛЕЦ',
    nameEn: 'PRESELETS',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7772',
    addressNomenclature: '0',
    x: '26.650722',
    y: '43.114604',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '58205',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРЕСЕЛКА',
    nameEn: 'PRESELKA',
    municipality: 'НОВИ ПАЗАР',
    municipalityEn: 'NOVI PAZAR',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9922',
    addressNomenclature: '0',
    x: '27.282691',
    y: '43.433957',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '58219',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРЕСИЯН',
    nameEn: 'PRESIYAN',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7762',
    addressNomenclature: '0',
    x: '26.384849',
    y: '43.219535',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '58236',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРЕСЛАВЕН',
    nameEn: 'PRESLAVEN',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6048',
    addressNomenclature: '0',
    x: '25.742703',
    y: '42.408097',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '58244',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРЕСЛАВЕЦ',
    nameEn: 'PRESLAVETS',
    municipality: 'ХАРМАНЛИ',
    municipalityEn: 'HARMANLI',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6454',
    addressNomenclature: '0',
    x: '25.880684',
    y: '41.97825',
    servingDays: '1010100',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '58253',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРЕСЛАВЦИ',
    nameEn: 'PRESLAVTSI',
    municipality: 'ТУТРАКАН',
    municipalityEn: 'TUTRAKAN',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7635',
    addressNomenclature: '0',
    x: '26.701764',
    y: '43.926466',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '59344',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРЕСОКА',
    nameEn: 'PRESOKA',
    municipality: 'ЗЛАТОГРАД',
    municipalityEn: 'ZLATOGRAD',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4987',
    addressNomenclature: '0',
    x: '25.086863',
    y: '41.424471',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '58267',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРЕСПА',
    nameEn: 'PRESPA',
    municipality: 'МИРКОВО',
    municipalityEn: 'MIRKOVO',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2072',
    addressNomenclature: '0',
    x: '23.951637',
    y: '42.589161',
    servingDays: '0101000',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '58270',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРЕСПА',
    nameEn: 'PRESPA',
    municipality: 'БАЛЧИК',
    municipalityEn: 'BALCHIK',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9635',
    addressNomenclature: '0',
    x: '28.03538',
    y: '43.556129',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '58284',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРЕСТОЙ',
    nameEn: 'PRESTOY',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5350',
    addressNomenclature: '0',
    x: '25.507827',
    y: '42.843908',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '58298',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРЕСЯК',
    nameEn: 'PRESYAK',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7754',
    addressNomenclature: '0',
    x: '26.740701',
    y: '43.389178',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '58308',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРЕСЯКА',
    nameEn: 'PRESYAKA',
    municipality: 'ЛОВЕЧ',
    municipalityEn: 'LOVECH',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5542',
    addressNomenclature: '0',
    x: '24.77297',
    y: '43.154875',
    servingDays: '0101000',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '58311',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРИБОЙ',
    nameEn: 'PRIBOY',
    municipality: 'РАДОМИР',
    municipalityEn: 'RADOMIR',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2452',
    addressNomenclature: '0',
    x: '22.918019',
    y: '42.497447',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '58325',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРИЛЕП',
    nameEn: 'PRILEP',
    municipality: 'СУНГУРЛАРЕ',
    municipalityEn: 'SUNGURLARE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8445',
    addressNomenclature: '0',
    x: '26.930734',
    y: '42.850241',
    servingDays: '0101000',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '58339',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРИЛЕП',
    nameEn: 'PRILEP',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9352',
    addressNomenclature: '0',
    x: '27.910587',
    y: '43.400539',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '58342',
    countryId: '100',
    mainSiteId: '40909',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРИЛЕПЦИ',
    nameEn: 'PRILEPTSI',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6609',
    addressNomenclature: '0',
    x: '25.34737',
    y: '41.627019',
    servingDays: '1111110',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '58356',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ПРИМОРСКО',
    nameEn: 'PRIMORSKO',
    municipality: 'ПРИМОРСКО',
    municipalityEn: 'PRIMORSKO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8180',
    addressNomenclature: '2',
    x: '27.755223',
    y: '42.266647',
    servingDays: '1111110',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '58360',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРИМОРЦИ',
    nameEn: 'PRIMORTSI',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9376',
    addressNomenclature: '0',
    x: '27.905936',
    y: '43.551714',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '46930',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРИПЕК',
    nameEn: 'PRIPEK',
    municipality: 'РУЕН',
    municipalityEn: 'RUEN',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8537',
    addressNomenclature: '0',
    x: '27.396275',
    y: '42.849755',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '58373',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРИПЕК',
    nameEn: 'PRIPEK',
    municipality: 'АКСАКОВО',
    municipalityEn: 'AKSAKOVO',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9146',
    addressNomenclature: '0',
    x: '27.736009',
    y: '43.254253',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '58387',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРИПЕК',
    nameEn: 'PRIPEK',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6858',
    addressNomenclature: '0',
    x: '25.183947',
    y: '41.462323',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '58400',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРИСАД',
    nameEn: 'PRISAD',
    municipality: 'СОЗОПОЛ',
    municipalityEn: 'SOZOPOL',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8161',
    addressNomenclature: '0',
    x: '27.382012',
    y: '42.375551',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '58414',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРИСАД',
    nameEn: 'PRISAD',
    municipality: 'ГЕНЕРАЛ ТОШЕВО',
    municipalityEn: 'GENERAL TOSHEVO',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9524',
    addressNomenclature: '0',
    x: '28.028664',
    y: '43.671108',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '58428',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРИСАДЕЦ',
    nameEn: 'PRISADETS',
    municipality: 'ТОПОЛОВГРАД',
    municipalityEn: 'TOPOLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6564',
    addressNomenclature: '0',
    x: '26.534647',
    y: '41.896529',
    servingDays: '0101000',
    servingOfficeId: '48',
    servingHubOfficeId: '5',
  },
  {
    id: '58431',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРИСЕЛЦИ',
    nameEn: 'PRISELTSI',
    municipality: 'НЕСЕБЪР',
    municipalityEn: 'NESEBAR',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8251',
    addressNomenclature: '0',
    x: '27.797225',
    y: '42.824334',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '58445',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРИСЕЛЦИ',
    nameEn: 'PRISELTSI',
    municipality: 'АВРЕН',
    municipalityEn: 'AVREN',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9131',
    addressNomenclature: '0',
    x: '27.829049',
    y: '43.109506',
    servingDays: '1111100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '58459',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРИСОВО',
    nameEn: 'PRISOVO',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5033',
    addressNomenclature: '0',
    x: '25.654957',
    y: '43.046922',
    servingDays: '1111100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '58476',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРИСОЙНА',
    nameEn: 'PRISOYNA',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7969',
    addressNomenclature: '0',
    x: '26.248614',
    y: '43.170834',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '58485',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРИСТОЕ',
    nameEn: 'PRISTOE',
    municipality: 'КАОЛИНОВО',
    municipalityEn: 'KAOLINOVO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9965',
    addressNomenclature: '0',
    x: '27.075112',
    y: '43.650446',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '58493',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРОБУДА',
    nameEn: 'PROBUDA',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7748',
    addressNomenclature: '0',
    x: '26.647545',
    y: '43.283191',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '58503',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ПРОВАДИЯ',
    nameEn: 'PROVADIA',
    municipality: 'ПРОВАДИЯ',
    municipalityEn: 'PROVADIA',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9200',
    addressNomenclature: '2',
    x: '27.441403',
    y: '43.172583',
    servingDays: '1111110',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '58517',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРОГЛЕД',
    nameEn: 'PROGLED',
    municipality: 'ЧЕПЕЛАРЕ',
    municipalityEn: 'CHEPELARE',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4871',
    addressNomenclature: '0',
    x: '24.714135',
    y: '41.677127',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '59361',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРОГРЕС',
    nameEn: 'PROGRES',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6819',
    addressNomenclature: '0',
    x: '25.407879',
    y: '41.495984',
    servingDays: '1111110',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '58520',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРОДАНОВЦИ',
    nameEn: 'PRODANOVTSI',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5045',
    addressNomenclature: '0',
    x: '25.673456',
    y: '42.868917',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '58548',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРОДАНОВЦИ',
    nameEn: 'PRODANOVTSI',
    municipality: 'САМОКОВ',
    municipalityEn: 'SAMOKOV',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2007',
    addressNomenclature: '0',
    x: '23.52323',
    y: '42.345863',
    servingDays: '1111100',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '59300',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРОДАНОВЦИ',
    nameEn: 'PRODANOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5307',
    addressNomenclature: '0',
    x: '25.285263',
    y: '42.877869',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '58551',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРОДАНЧА',
    nameEn: 'PRODANCHA',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2471',
    addressNomenclature: '0',
    x: '22.754055',
    y: '42.872635',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '58579',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРОЛАЗ',
    nameEn: 'PROLAZ',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7739',
    addressNomenclature: '0',
    x: '26.500624',
    y: '43.174584',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '58582',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРОЛАЗНИЦА',
    nameEn: 'PROLAZNITSA',
    municipality: 'БЕЛОГРАДЧИК',
    municipalityEn: 'BELOGRADCHIK',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3913',
    addressNomenclature: '0',
    x: '22.653624',
    y: '43.582321',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '58596',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРОЛЕЗ',
    nameEn: 'PROLEZ',
    municipality: 'ШАБЛА',
    municipalityEn: 'SHABLA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9685',
    addressNomenclature: '0',
    x: '28.454548',
    y: '43.577083',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '58606',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРОЛЕША',
    nameEn: 'PROLESHA',
    municipality: 'БОЖУРИЩЕ',
    municipalityEn: 'BOZHURISHTE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2228',
    addressNomenclature: '0',
    x: '23.150721',
    y: '42.778465',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '58616',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРОЛОМ',
    nameEn: 'PROLOM',
    municipality: 'КАРЛОВО',
    municipalityEn: 'KARLOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4358',
    addressNomenclature: '0',
    x: '24.878834',
    y: '42.518441',
    servingDays: '0101000',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '58623',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРОПАСТ',
    nameEn: 'PROPAST',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6632',
    addressNomenclature: '0',
    x: '25.39393',
    y: '41.640323',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '58637',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРОСЕНА',
    nameEn: 'PROSENA',
    municipality: 'РУСЕ',
    municipalityEn: 'RUSE',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7055',
    addressNomenclature: '0',
    x: '26.172822',
    y: '43.865212',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '58640',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРОСЕНИК',
    nameEn: 'PROSENIK',
    municipality: 'РУЕН',
    municipalityEn: 'RUEN',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8548',
    addressNomenclature: '0',
    x: '27.451252',
    y: '42.79824',
    servingDays: '1111100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '58654',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРОСЕЧЕН',
    nameEn: 'PROSECHEN',
    municipality: 'СУВОРОВО',
    municipalityEn: 'SUVOROVO',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9175',
    addressNomenclature: '0',
    x: '27.64114',
    y: '43.294259',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '58668',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРОСТОРНО',
    nameEn: 'PROSTORNO',
    municipality: 'РАЗГРАД',
    municipalityEn: 'RAZGRAD',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7263',
    addressNomenclature: '0',
    x: '26.482298',
    y: '43.64647',
    servingDays: '1010100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '58671',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРОТОПОПИНЦИ',
    nameEn: 'PROTOPOPINTSI',
    municipality: 'ЧУПРЕНЕ',
    municipalityEn: 'CHUPRENE',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3957',
    addressNomenclature: '0',
    x: '22.749685',
    y: '43.56129',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '58685',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРОФЕСОР ЗЛАТАРСКИ',
    nameEn: 'PROFESOR ZLATARSKI',
    municipality: 'ТЕРВЕЛ',
    municipalityEn: 'TERVEL',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9487',
    addressNomenclature: '0',
    x: '27.329102',
    y: '43.769687',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '58699',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРОФЕСОР ИШИРКОВО',
    nameEn: 'PROFESOR ISHIRKOVO',
    municipality: 'СИЛИСТРА',
    municipalityEn: 'SILISTRA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7572',
    addressNomenclature: '0',
    x: '27.163254',
    y: '43.996414',
    servingDays: '1010100',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '58709',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРОХЛАДА',
    nameEn: 'PROHLADA',
    municipality: 'ДУЛОВО',
    municipalityEn: 'DULOVO',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7668',
    addressNomenclature: '0',
    x: '27.130183',
    y: '43.698806',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '58712',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРОХОД',
    nameEn: 'PROHOD',
    municipality: 'СРЕДЕЦ',
    municipalityEn: 'SREDETS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8336',
    addressNomenclature: '0',
    x: '27.060446',
    y: '42.331289',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '58726',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРОХОРОВО',
    nameEn: 'PROHOROVO',
    municipality: 'НОВА ЗАГОРА',
    municipalityEn: 'NOVA ZAGORA',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8944',
    addressNomenclature: '0',
    x: '26.206328',
    y: '42.353266',
    servingDays: '0101000',
    servingOfficeId: '56',
    servingHubOfficeId: '5',
  },
  {
    id: '58731',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРЯПОРЕЦ',
    nameEn: 'PRYAPORETS',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6716',
    addressNomenclature: '0',
    x: '25.355125',
    y: '41.754967',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '58743',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПРЯПОРЕЦ',
    nameEn: 'PRYAPORETS',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6062',
    addressNomenclature: '0',
    x: '25.536899',
    y: '42.464472',
    servingDays: '1111110',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '58757',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПТИЧАР',
    nameEn: 'PTICHAR',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6811',
    addressNomenclature: '0',
    x: '25.374654',
    y: '41.458815',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '58774',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПТИЧЕВО',
    nameEn: 'PTICHEVO',
    municipality: 'ОМУРТАГ',
    municipalityEn: 'OMURTAG',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7946',
    addressNomenclature: '0',
    x: '26.284364',
    y: '43.04455',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '58788',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПУДРИЯ',
    nameEn: 'PUDRIA',
    municipality: 'КРИВОДОЛ',
    municipalityEn: 'KRIVODOL',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3049',
    addressNomenclature: '0',
    x: '23.424063',
    y: '43.321865',
    servingDays: '0101000',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '58791',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПУШЕВО',
    nameEn: 'PUSHEVO',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5062',
    addressNomenclature: '0',
    x: '25.475302',
    y: '43.07054',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '58801',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЧЕЛА',
    nameEn: 'PCHELA',
    municipality: 'ЕЛХОВО',
    municipalityEn: 'ELHOVO',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8718',
    addressNomenclature: '0',
    x: '26.471608',
    y: '42.18284',
    servingDays: '0101000',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '58815',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЧЕЛАРИ',
    nameEn: 'PCHELARI',
    municipality: 'СТАМБОЛОВО',
    municipalityEn: 'STAMBOLOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6385',
    addressNomenclature: '0',
    x: '25.686262',
    y: '41.6433',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '58829',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЧЕЛАРОВО',
    nameEn: 'PCHELAROVO',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6664',
    addressNomenclature: '0',
    x: '25.364198',
    y: '41.785863',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '58832',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЧЕЛАРОВО',
    nameEn: 'PCHELAROVO',
    municipality: 'ГЕНЕРАЛ ТОШЕВО',
    municipalityEn: 'GENERAL TOSHEVO',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9550',
    addressNomenclature: '0',
    x: '27.918425',
    y: '43.726656',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '58857',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЧЕЛИН',
    nameEn: 'PCHELIN',
    municipality: 'СУНГУРЛАРЕ',
    municipalityEn: 'SUNGURLARE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8456',
    addressNomenclature: '0',
    x: '26.774467',
    y: '42.883767',
    servingDays: '1010100',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '58863',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЧЕЛИН',
    nameEn: 'PCHELIN',
    municipality: 'КОСТЕНЕЦ',
    municipalityEn: 'KOSTENETS',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2048',
    addressNomenclature: '0',
    x: '23.806896',
    y: '42.347983',
    servingDays: '1010100',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '58877',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЧЕЛИНА',
    nameEn: 'PCHELINA',
    municipality: 'САМУИЛ',
    municipalityEn: 'SAMUIL',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7438',
    addressNomenclature: '0',
    x: '26.936978',
    y: '43.610118',
    servingDays: '1010100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '58880',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЧЕЛИНО',
    nameEn: 'PCHELINO',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9363',
    addressNomenclature: '0',
    x: '27.921631',
    y: '43.494657',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '58894',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЧЕЛИНОВО',
    nameEn: 'PCHELINOVO',
    municipality: 'ГУРКОВО',
    municipalityEn: 'GURKOVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6196',
    addressNomenclature: '0',
    x: '25.716476',
    y: '42.717127',
    servingDays: '0101000',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '58918',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЧЕЛИЩЕ',
    nameEn: 'PCHELISHTE',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5048',
    addressNomenclature: '0',
    x: '25.718236',
    y: '43.036833',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '58921',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЧЕЛНИК',
    nameEn: 'PCHELNIK',
    municipality: 'ДОЛНИ ЧИФЛИК',
    municipalityEn: 'DOLNI CHIFLIK',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9118',
    addressNomenclature: '0',
    x: '27.6693',
    y: '43.013037',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '58935',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЧЕЛНИК',
    nameEn: 'PCHELNIK',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9427',
    addressNomenclature: '0',
    x: '27.612772',
    y: '43.770656',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '58949',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЧЕЛНО',
    nameEn: 'PCHELNO',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7987',
    addressNomenclature: '0',
    x: '26.230838',
    y: '43.081548',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '58952',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПШЕНИЧЕВО',
    nameEn: 'PSHENICHEVO',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6268',
    addressNomenclature: '0',
    x: '25.871731',
    y: '42.37239',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '58966',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЪДАРЕВО',
    nameEn: 'PADAREVO',
    municipality: 'КОТЕЛ',
    municipalityEn: 'KOTEL',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8992',
    addressNomenclature: '0',
    x: '26.668658',
    y: '42.728463',
    servingDays: '0101000',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '58972',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЪДАРИНО',
    nameEn: 'PADARINO',
    municipality: 'ОМУРТАГ',
    municipalityEn: 'OMURTAG',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7959',
    addressNomenclature: '0',
    x: '26.441755',
    y: '43.021769',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '58983',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЪДАРСКО',
    nameEn: 'PADARSKO',
    municipality: 'БРЕЗОВО',
    municipalityEn: 'BREZOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4158',
    addressNomenclature: '0',
    x: '24.892083',
    y: '42.346782',
    servingDays: '0101000',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '58997',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЪДАРЦИ',
    nameEn: 'PADARTSI',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6746',
    addressNomenclature: '0',
    x: '25.258784',
    y: '41.681535',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '59015',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЪНЧЕВО',
    nameEn: 'PANCHEVO',
    municipality: 'СРЕДЕЦ',
    municipalityEn: 'SREDETS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8317',
    addressNomenclature: '0',
    x: '27.155266',
    y: '42.276799',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '59029',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЪРВАН',
    nameEn: 'PARVAN',
    municipality: 'ОМУРТАГ',
    municipalityEn: 'OMURTAG',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7958',
    addressNomenclature: '0',
    x: '26.444678',
    y: '43.039086',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '59032',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЪРВЕНЕЦ',
    nameEn: 'PARVENETS',
    municipality: 'РОДОПИ',
    municipalityEn: 'RODOPI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4110',
    addressNomenclature: '0',
    x: '24.662952',
    y: '42.074544',
    servingDays: '1111110',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '59046',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЪРВЕНЕЦ',
    nameEn: 'PARVENETS',
    municipality: 'СТРАЛДЖА',
    municipalityEn: 'STRALDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8694',
    addressNomenclature: '0',
    x: '26.849959',
    y: '42.430323',
    servingDays: '0101000',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '59054',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЪРВЕНЦИ',
    nameEn: 'PARVENTSI',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6865',
    addressNomenclature: '0',
    x: '25.228432',
    y: '41.420804',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '59063',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЪРВИЦА',
    nameEn: 'PARVITSA',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6882',
    addressNomenclature: '0',
    x: '25.365098',
    y: '41.374732',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '59077',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЪРВОМАЙ',
    nameEn: 'PARVOMAY',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2890',
    addressNomenclature: '0',
    x: '23.135221',
    y: '41.403543',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '59080',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ПЪРВОМАЙ',
    nameEn: 'PARVOMAY',
    municipality: 'ПЪРВОМАЙ',
    municipalityEn: 'PARVOMAY',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4270',
    addressNomenclature: '2',
    x: '25.219901',
    y: '42.101888',
    servingDays: '1111110',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '59094',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЪРВОМАЙЦИ',
    nameEn: 'PARVOMAYTSI',
    municipality: 'ГОРНА ОРЯХОВИЦА',
    municipalityEn: 'GORNA ORYAHOVITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5139',
    addressNomenclature: '2',
    x: '25.65391',
    y: '43.152875',
    servingDays: '1111110',
    servingOfficeId: '46',
    servingHubOfficeId: '11',
  },
  {
    id: '59104',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЪРЖИГРАХ',
    nameEn: 'PARZHIGRAH',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5365',
    addressNomenclature: '0',
    x: '25.529756',
    y: '42.900444',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '59118',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЪРЛИЧЕВО',
    nameEn: 'PARLICHEVO',
    municipality: 'БЕРКОВИЦА',
    municipalityEn: 'BERKOVITSA',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3531',
    addressNomenclature: '0',
    x: '23.30347',
    y: '43.286124',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '59121',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЪРОВЦИ',
    nameEn: 'PAROVTSI',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5052',
    addressNomenclature: '0',
    x: '25.600848',
    y: '42.953459',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '59135',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЪРТЕВЦИ',
    nameEn: 'PARTEVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5307',
    addressNomenclature: '0',
    x: '25.242092',
    y: '42.84863',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '59152',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЪРША',
    nameEn: 'PARSHA',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5380',
    addressNomenclature: '0',
    x: '25.484035',
    y: '42.949111',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '59166',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЪСТРЕН',
    nameEn: 'PASTREN',
    municipality: 'ОПАН',
    municipalityEn: 'OPAN',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6075',
    addressNomenclature: '0',
    x: '25.750874',
    y: '42.248804',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '59170',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЪСТРОВО',
    nameEn: 'PASTROVO',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6064',
    addressNomenclature: '0',
    x: '25.354615',
    y: '42.434924',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '59183',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЪСТРОГОР',
    nameEn: 'PASTROGOR',
    municipality: 'СВИЛЕНГРАД',
    municipalityEn: 'SVILENGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6519',
    addressNomenclature: '0',
    x: '26.19699',
    y: '41.83882',
    servingDays: '0101000',
    servingOfficeId: '48',
    servingHubOfficeId: '5',
  },
  {
    id: '59197',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЪСТРООК',
    nameEn: 'PASTROOK',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6597',
    addressNomenclature: '0',
    x: '25.93878',
    y: '41.46512',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '59207',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЪТНИКОВО',
    nameEn: 'PATNIKOVO',
    municipality: 'СТАМБОЛОВО',
    municipalityEn: 'STAMBOLOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6379',
    addressNomenclature: '0',
    x: '25.652651',
    y: '41.683434',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '59210',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ПЯСЪЧЕВО',
    nameEn: 'PYASACHEVO',
    municipality: 'СИМЕОНОВГРАД',
    municipalityEn: 'SIMEONOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6496',
    addressNomenclature: '0',
    x: '25.863253',
    y: '42.107365',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '61011',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАБИША',
    nameEn: 'RABISHA',
    municipality: 'БЕЛОГРАДЧИК',
    municipalityEn: 'BELOGRADCHIK',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3938',
    addressNomenclature: '0',
    x: '22.600973',
    y: '43.718214',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '61025',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАБОВО',
    nameEn: 'RABOVO',
    municipality: 'СТАМБОЛОВО',
    municipalityEn: 'STAMBOLOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6384',
    addressNomenclature: '0',
    x: '25.665722',
    y: '41.622511',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '61039',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАБРОВО',
    nameEn: 'RABROVO',
    municipality: 'БОЙНИЦА',
    municipalityEn: 'BOYNITSA',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3842',
    addressNomenclature: '0',
    x: '22.568686',
    y: '44.018724',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '61042',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАВАДИНОВО',
    nameEn: 'RAVADINOVO',
    municipality: 'СОЗОПОЛ',
    municipalityEn: 'SOZOPOL',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8146',
    addressNomenclature: '0',
    x: '27.666883',
    y: '42.383078',
    servingDays: '1111110',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '61056',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАВДА',
    nameEn: 'RAVDA',
    municipality: 'НЕСЕБЪР',
    municipalityEn: 'NESEBAR',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8238',
    addressNomenclature: '2',
    x: '27.677881',
    y: '42.643929',
    servingDays: '1111110',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '61063',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАВЕН',
    nameEn: 'RAVEN',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6834',
    addressNomenclature: '0',
    x: '25.492749',
    y: '41.545035',
    servingDays: '1111110',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '61073',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАВНА',
    nameEn: 'RAVNA',
    municipality: 'ПРОВАДИЯ',
    municipalityEn: 'PROVADIA',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9235',
    addressNomenclature: '0',
    x: '27.34796',
    y: '43.216911',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '61090',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАВНА',
    nameEn: 'RAVNA',
    municipality: 'ЧИПРОВЦИ',
    municipalityEn: 'CHIPROVTSI',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3473',
    addressNomenclature: '0',
    x: '22.960304',
    y: '43.387607',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '61100',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАВНА',
    nameEn: 'RAVNA',
    municipality: 'ГОДЕЧ',
    municipalityEn: 'GODECH',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2249',
    addressNomenclature: '0',
    x: '23.012881',
    y: '43.04706',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '61114',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАВНА ГОРА',
    nameEn: 'RAVNA GORA',
    municipality: 'СОЗОПОЛ',
    municipalityEn: 'SOZOPOL',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8151',
    addressNomenclature: '0',
    x: '27.54077',
    y: '42.363381',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '61128',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАВНА ГОРА',
    nameEn: 'RAVNA GORA',
    municipality: 'АВРЕН',
    municipalityEn: 'AVREN',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9124',
    addressNomenclature: '0',
    x: '27.80648',
    y: '43.056731',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '61131',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАВНА ГОРА',
    nameEn: 'RAVNA GORA',
    municipality: 'СВИЛЕНГРАД',
    municipalityEn: 'SVILENGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6526',
    addressNomenclature: '0',
    x: '26.344799',
    y: '41.868225',
    servingDays: '0101000',
    servingOfficeId: '48',
    servingHubOfficeId: '5',
  },
  {
    id: '61145',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАВНЕЦ',
    nameEn: 'RAVNETS',
    municipality: 'БУРГАС',
    municipalityEn: 'BURGAS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8117',
    addressNomenclature: '0',
    x: '27.240465',
    y: '42.514544',
    servingDays: '1111100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '61159',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАВНЕЦ',
    nameEn: 'RAVNETS',
    municipality: 'ГЕНЕРАЛ ТОШЕВО',
    municipalityEn: 'GENERAL TOSHEVO',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9523',
    addressNomenclature: '0',
    x: '27.986966',
    y: '43.681831',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '61162',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАВНИЛ',
    nameEn: 'RAVNIL',
    municipality: 'МАДАН',
    municipalityEn: 'MADAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4931',
    addressNomenclature: '0',
    x: '24.974878',
    y: '41.53064',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '61193',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАВНИНАТА',
    nameEn: 'RAVNINATA',
    municipality: 'РУДОЗЕМ',
    municipalityEn: 'RUDOZEM',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4781',
    addressNomenclature: '0',
    x: '24.785106',
    y: '41.480092',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '61176',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАВНИЩА',
    nameEn: 'RAVNISHTA',
    municipality: 'МАДАН',
    municipalityEn: 'MADAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4955',
    addressNomenclature: '0',
    x: '24.90939',
    y: '41.543932',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '61189',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАВНИЩЕ',
    nameEn: 'RAVNISHTE',
    municipality: 'ПРАВЕЦ',
    municipalityEn: 'PRAVETS',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2167',
    addressNomenclature: '0',
    x: '24.05089',
    y: '42.997604',
    servingDays: '1010100',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '61203',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАВНО',
    nameEn: 'RAVNO',
    municipality: 'КУБРАТ',
    municipalityEn: 'KUBRAT',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7344',
    addressNomenclature: '0',
    x: '26.457246',
    y: '43.704314',
    servingDays: '1010100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '61234',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАВНО НИВИЩЕ',
    nameEn: 'RAVNO NIVISHTE',
    municipality: 'МАДАН',
    municipalityEn: 'MADAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4913',
    addressNomenclature: '0',
    x: '24.957365',
    y: '41.494835',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '61248',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАВНО ПОЛЕ',
    nameEn: 'RAVNO POLE',
    municipality: 'ЕЛИН ПЕЛИН',
    municipalityEn: 'ELIN PELIN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2129',
    addressNomenclature: '0',
    x: '23.526327',
    y: '42.667471',
    servingDays: '1111100',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '61251',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАВНО СЕЛО',
    nameEn: 'RAVNO SELO',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7965',
    addressNomenclature: '0',
    x: '26.197587',
    y: '42.986125',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '61217',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАВНОВО',
    nameEn: 'RAVNOVO',
    municipality: 'ЗЛАТАРИЦА',
    municipalityEn: 'ZLATARITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5089',
    addressNomenclature: '0',
    x: '25.925225',
    y: '43.032743',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '61220',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАВНОГОР',
    nameEn: 'RAVNOGOR',
    municipality: 'БРАЦИГОВО',
    municipalityEn: 'BRATSIGOVO',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4582',
    addressNomenclature: '0',
    x: '24.36788',
    y: '41.951189',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '61265',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАДАН ВОЙВОДА',
    nameEn: 'RADAN VOYVODA',
    municipality: 'ВЪЛЧИ ДОЛ',
    municipalityEn: 'VALCHI DOL',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9294',
    addressNomenclature: '0',
    x: '27.392598',
    y: '43.543807',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '61279',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАДАНОВО',
    nameEn: 'RADANOVO',
    municipality: 'ПОЛСКИ ТРЪМБЕШ',
    municipalityEn: 'POLSKI TRAMBESH',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5185',
    addressNomenclature: '0',
    x: '25.636413',
    y: '43.341557',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '61296',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАДАНЧЕТО',
    nameEn: 'RADANCHETO',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5370',
    addressNomenclature: '0',
    x: '25.504138',
    y: '42.943006',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '61306',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАДЕВО',
    nameEn: 'RADEVO',
    municipality: 'АКСАКОВО',
    municipalityEn: 'AKSAKOVO',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9149',
    addressNomenclature: '0',
    x: '27.730956',
    y: '43.409986',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '61310',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАДЕВО',
    nameEn: 'RADEVO',
    municipality: 'НОВА ЗАГОРА',
    municipalityEn: 'NOVA ZAGORA',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8917',
    addressNomenclature: '0',
    x: '26.059166',
    y: '42.415042',
    servingDays: '0101000',
    servingOfficeId: '56',
    servingHubOfficeId: '5',
  },
  {
    id: '61323',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАДЕВЦИ',
    nameEn: 'RADEVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5360',
    addressNomenclature: '0',
    x: '25.498092',
    y: '42.803783',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '61340',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАДЕЦКИ',
    nameEn: 'RADETSKI',
    municipality: 'НОВА ЗАГОРА',
    municipalityEn: 'NOVA ZAGORA',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8959',
    addressNomenclature: '0',
    x: '26.116649',
    y: '42.283299',
    servingDays: '1111100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '61354',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАДИБОШ',
    nameEn: 'RADIBOSH',
    municipality: 'РАДОМИР',
    municipalityEn: 'RADOMIR',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2447',
    addressNomenclature: '0',
    x: '22.861954',
    y: '42.552389',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '61368',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАДИЕВО',
    nameEn: 'RADIEVO',
    municipality: 'ДИМИТРОВГРАД',
    municipalityEn: 'DIMITROVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6427',
    addressNomenclature: '0',
    x: '25.624441',
    y: '42.095701',
    servingDays: '1010100',
    servingOfficeId: '42',
    servingHubOfficeId: '5',
  },
  {
    id: '61371',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАДИЛОВО',
    nameEn: 'RADILOVO',
    municipality: 'ПЕЩЕРА',
    municipalityEn: 'PESHTERA',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4590',
    addressNomenclature: '0',
    x: '24.307764',
    y: '42.080331',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '61385',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАДИНГРАД',
    nameEn: 'RADINGRAD',
    municipality: 'РАЗГРАД',
    municipalityEn: 'RAZGRAD',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7245',
    addressNomenclature: '0',
    x: '26.599199',
    y: '43.526633',
    servingDays: '0101000',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '61409',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАДИНО',
    nameEn: 'RADINO',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5360',
    addressNomenclature: '0',
    x: '25.445037',
    y: '42.836938',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '61412',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАДИНОВО',
    nameEn: 'RADINOVO',
    municipality: 'МАРИЦА',
    municipalityEn: 'MARITSA',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4202',
    addressNomenclature: '0',
    x: '24.638366',
    y: '42.193145',
    servingDays: '1111110',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '61426',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАДИШЕВО',
    nameEn: 'RADISHEVO',
    municipality: 'ПЛЕВЕН',
    municipalityEn: 'PLEVEN',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5838',
    addressNomenclature: '0',
    x: '24.66859',
    y: '43.375078',
    servingDays: '0101000',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '61443',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАДКО ДИМИТРИЕВО',
    nameEn: 'RADKO DIMITRIEVO',
    municipality: 'ШУМЕН',
    municipalityEn: 'SHUMEN',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9808',
    addressNomenclature: '0',
    x: '27.005078',
    y: '43.183632',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '61435',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАДКОВЦИ',
    nameEn: 'RADKOVTSI',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5041',
    addressNomenclature: '0',
    x: '25.615707',
    y: '42.925553',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '61457',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАДЛОВЦИ',
    nameEn: 'RADLOVTSI',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2548',
    addressNomenclature: '0',
    x: '22.635269',
    y: '42.31429',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '61460',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'РАДНЕВО',
    nameEn: 'RADNEVO',
    municipality: 'РАДНЕВО',
    municipalityEn: 'RADNEVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6260',
    addressNomenclature: '2',
    x: '25.933395',
    y: '42.289274',
    servingDays: '1111110',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '61488',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАДОВЕНЕ',
    nameEn: 'RADOVENE',
    municipality: 'РОМАН',
    municipalityEn: 'ROMAN',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3144',
    addressNomenclature: '0',
    x: '23.960924',
    y: '43.159048',
    servingDays: '0101000',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '61491',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАДОВЕЦ',
    nameEn: 'RADOVETS',
    municipality: 'ТОПОЛОВГРАД',
    municipalityEn: 'TOPOLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6564',
    addressNomenclature: '0',
    x: '26.492376',
    y: '41.931484',
    servingDays: '0101000',
    servingOfficeId: '48',
    servingHubOfficeId: '5',
  },
  {
    id: '61501',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАДОВО',
    nameEn: 'RADOVO',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2488',
    addressNomenclature: '0',
    x: '22.590399',
    y: '42.799612',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '61515',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАДОВЦИ',
    nameEn: 'RADOVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5084',
    addressNomenclature: '0',
    x: '25.938758',
    y: '42.937074',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '61529',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАДОВЦИ',
    nameEn: 'RADOVTSI',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5380',
    addressNomenclature: '0',
    x: '25.511007',
    y: '42.919787',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '61532',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАДОЕВСКОТО',
    nameEn: 'RADOEVSKOTO',
    municipality: 'ТРОЯН',
    municipalityEn: 'TROYAN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '',
    addressNomenclature: '0',
    x: '24.766534',
    y: '42.864135',
    servingDays: '1010100',
    servingOfficeId: '44',
    servingHubOfficeId: '11',
  },
  {
    id: '61546',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАДОЕВЦИ',
    nameEn: 'RADOEVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5367',
    addressNomenclature: '0',
    x: '25.548829',
    y: '42.83801',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '61563',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАДОЙНОВО',
    nameEn: 'RADOYNOVO',
    municipality: 'СРЕДЕЦ',
    municipalityEn: 'SREDETS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8323',
    addressNomenclature: '0',
    x: '26.985527',
    y: '42.376821',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '61577',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'РАДОМИР',
    nameEn: 'RADOMIR',
    municipality: 'РАДОМИР',
    municipalityEn: 'RADOMIR',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2400',
    addressNomenclature: '2',
    x: '22.961261',
    y: '42.543991',
    servingDays: '1111100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '61580',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАДОМИРЦИ',
    nameEn: 'RADOMIRTSI',
    municipality: 'ЧЕРВЕН БРЯГ',
    municipalityEn: 'CHERVEN BRYAG',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5997',
    addressNomenclature: '0',
    x: '24.193788',
    y: '43.248561',
    servingDays: '1010100',
    servingOfficeId: '59',
    servingHubOfficeId: '20',
  },
  {
    id: '61594',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАДОТИНА',
    nameEn: 'RADOTINA',
    municipality: 'БОТЕВГРАД',
    municipalityEn: 'BOTEVGRAD',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2148',
    addressNomenclature: '0',
    x: '23.641948',
    y: '42.99285',
    servingDays: '0101000',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '61604',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАДУИЛ',
    nameEn: 'RADUIL',
    municipality: 'САМОКОВ',
    municipalityEn: 'SAMOKOV',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2043',
    addressNomenclature: '0',
    x: '23.688573',
    y: '42.285898',
    servingDays: '1111100',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '61618',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАДУЙ',
    nameEn: 'RADUY',
    municipality: 'ПЕРНИК',
    municipalityEn: 'PERNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2359',
    addressNomenclature: '0',
    x: '23.02522',
    y: '42.730946',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '61621',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАДУЛОВЦИ',
    nameEn: 'RADULOVTSI',
    municipality: 'СЛИВНИЦА',
    municipalityEn: 'SLIVNITSA',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2222',
    addressNomenclature: '0',
    x: '22.932882',
    y: '42.829271',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '61635',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАДУНЦИ',
    nameEn: 'RADUNTSI',
    municipality: 'МЪГЛИЖ',
    municipalityEn: 'MAGLIZH',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6185',
    addressNomenclature: '0',
    x: '25.596297',
    y: '42.680516',
    servingDays: '0101000',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '69523',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАДЮВЕНЕ',
    nameEn: 'RADYUVENE',
    municipality: 'ЛОВЕЧ',
    municipalityEn: 'LOVECH',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5540',
    addressNomenclature: '0',
    x: '24.603236',
    y: '43.123632',
    servingDays: '1010100',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '61649',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАЕВЦИ',
    nameEn: 'RAEVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5363',
    addressNomenclature: '0',
    x: '25.600752',
    y: '42.867525',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '63536',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАЖДАВИЦА',
    nameEn: 'RAZHDAVITSA',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2550',
    addressNomenclature: '0',
    x: '22.709017',
    y: '42.386981',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '61652',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАЗБОИЩЕ',
    nameEn: 'RAZBOISHTE',
    municipality: 'ГОДЕЧ',
    municipalityEn: 'GODECH',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2253',
    addressNomenclature: '0',
    x: '22.956615',
    y: '43.012817',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '61666',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАЗБОЙНА',
    nameEn: 'RAZBOYNA',
    municipality: 'РУЕН',
    municipalityEn: 'RUEN',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8531',
    addressNomenclature: '0',
    x: '27.391825',
    y: '42.809007',
    servingDays: '1111100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '61676',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАЗБОЙНА',
    nameEn: 'RAZBOYNA',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7707',
    addressNomenclature: '0',
    x: '26.560485',
    y: '43.22159',
    servingDays: '1111110',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '61697',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАЗВИГОРОВО',
    nameEn: 'RAZVIGOROVO',
    municipality: 'ХИТРИНО',
    municipalityEn: 'HITRINO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9790',
    addressNomenclature: '0',
    x: '26.839672',
    y: '43.396626',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '61707',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАЗГРАД',
    nameEn: 'RAZGRAD',
    municipality: 'ВЪЛЧЕДРЪМ',
    municipalityEn: 'VALCHEDRAM',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3633',
    addressNomenclature: '0',
    x: '23.492291',
    y: '43.749348',
    servingDays: '0101000',
    servingOfficeId: '831',
    servingHubOfficeId: '20',
  },
  {
    id: '61710',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'РАЗГРАД',
    nameEn: 'RAZGRAD',
    municipality: 'РАЗГРАД',
    municipalityEn: 'RAZGRAD',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7200',
    addressNomenclature: '2',
    x: '26.527075',
    y: '43.525119',
    servingDays: '1111110',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '61724',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАЗДЕЛ',
    nameEn: 'RAZDEL',
    municipality: 'ДУЛОВО',
    municipalityEn: 'DULOVO',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7659',
    addressNomenclature: '0',
    x: '27.124416',
    y: '43.785154',
    servingDays: '0101000',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '61738',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАЗДЕЛ',
    nameEn: 'RAZDEL',
    municipality: 'ЕЛХОВО',
    municipalityEn: 'ELHOVO',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8747',
    addressNomenclature: '0',
    x: '26.652968',
    y: '42.077375',
    servingDays: '1010100',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '61741',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАЗДЕЛНА',
    nameEn: 'RAZDELNA',
    municipality: 'БЕЛОСЛАВ',
    municipalityEn: 'BELOSLAV',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9181',
    addressNomenclature: '0',
    x: '27.634895',
    y: '43.16293',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '61755',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАЗДЕЛНА',
    nameEn: 'RAZDELNA',
    municipality: 'ГЪЛЪБОВО',
    municipalityEn: 'GALABOVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6283',
    addressNomenclature: '0',
    x: '25.77685',
    y: '42.17701',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '61769',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАЗДЕЛЦИ',
    nameEn: 'RAZDELTSI',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7989',
    addressNomenclature: '0',
    x: '26.224917',
    y: '43.199876',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '61772',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАЗДОЛ',
    nameEn: 'RAZDOL',
    municipality: 'СТРУМЯНИ',
    municipalityEn: 'STRUMYANI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2835',
    addressNomenclature: '0',
    x: '23.008546',
    y: '41.616041',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '61807',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАЗЛИВ',
    nameEn: 'RAZLIV',
    municipality: 'ПРАВЕЦ',
    municipalityEn: 'PRAVETS',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2162',
    addressNomenclature: '0',
    x: '23.873042',
    y: '42.912796',
    servingDays: '1111100',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '61813',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'РАЗЛОГ',
    nameEn: 'RAZLOG',
    municipality: 'РАЗЛОГ',
    municipalityEn: 'RAZLOG',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2760',
    addressNomenclature: '2',
    x: '23.467786',
    y: '41.886386',
    servingDays: '1111110',
    servingOfficeId: '125',
    servingHubOfficeId: '13',
  },
  {
    id: '61830',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАЗСОХА',
    nameEn: 'RAZSOHA',
    municipality: 'ЗЛАТАРИЦА',
    municipalityEn: 'ZLATARITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5089',
    addressNomenclature: '0',
    x: '25.973274',
    y: '43.024903',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '61844',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАЙКОВА МОГИЛА',
    nameEn: 'RAYKOVA MOGILA',
    municipality: 'СВИЛЕНГРАД',
    municipalityEn: 'SVILENGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6525',
    addressNomenclature: '0',
    x: '26.301345',
    y: '41.820862',
    servingDays: '0101000',
    servingOfficeId: '48',
    servingHubOfficeId: '5',
  },
  {
    id: '61861',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАЙКОВЦИ',
    nameEn: 'RAYKOVTSI',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5039',
    addressNomenclature: '0',
    x: '25.62999',
    y: '42.832471',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '61875',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАЙНИНО',
    nameEn: 'RAYNINO',
    municipality: 'ИСПЕРИХ',
    municipalityEn: 'ISPERIH',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7421',
    addressNomenclature: '0',
    x: '26.817182',
    y: '43.808157',
    servingDays: '0101000',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '61889',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАЙНОВО',
    nameEn: 'RAYNOVO',
    municipality: 'ДИМИТРОВГРАД',
    municipalityEn: 'DIMITROVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6423',
    addressNomenclature: '0',
    x: '25.745442',
    y: '42.018162',
    servingDays: '1111100',
    servingOfficeId: '42',
    servingHubOfficeId: '5',
  },
  {
    id: '61892',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАЙНОВЦИ',
    nameEn: 'RAYNOVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5094',
    addressNomenclature: '0',
    x: '25.854334',
    y: '42.877634',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '61902',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАЙНОВЦИ',
    nameEn: 'RAYNOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5347',
    addressNomenclature: '0',
    x: '25.199718',
    y: '42.902803',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '61916',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАЙНУШКОВЦИ',
    nameEn: 'RAYNUSHKOVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5363',
    addressNomenclature: '0',
    x: '25.558406',
    y: '42.862898',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '61922',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАЙОВО',
    nameEn: 'RAYOVO',
    municipality: 'САМОКОВ',
    municipalityEn: 'SAMOKOV',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2017',
    addressNomenclature: '0',
    x: '23.496712',
    y: '42.373582',
    servingDays: '1111100',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '61933',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАКЕВО',
    nameEn: 'RAKEVO',
    municipality: 'КРИВОДОЛ',
    municipalityEn: 'KRIVODOL',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3067',
    addressNomenclature: '0',
    x: '23.438762',
    y: '43.419472',
    servingDays: '0101000',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '61947',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАКИЛОВЦИ',
    nameEn: 'RAKILOVTSI',
    municipality: 'КОВАЧЕВЦИ',
    municipalityEn: 'KOVACHEVTSI',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2451',
    addressNomenclature: '0',
    x: '22.806147',
    y: '42.544795',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '61950',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАКИТА',
    nameEn: 'RAKITA',
    municipality: 'ЧЕРВЕН БРЯГ',
    municipalityEn: 'CHERVEN BRYAG',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5998',
    addressNomenclature: '0',
    x: '24.271743',
    y: '43.269964',
    servingDays: '1010100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '61964',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАКИТА',
    nameEn: 'RAKITA',
    municipality: 'СЛИВНИЦА',
    municipalityEn: 'SLIVNITSA',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2223',
    addressNomenclature: '0',
    x: '22.932077',
    y: '42.788346',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '61978',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАКИТНА',
    nameEn: 'RAKITNA',
    municipality: 'СИМИТЛИ',
    municipalityEn: 'SIMITLI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2753',
    addressNomenclature: '0',
    x: '23.195815',
    y: '41.846907',
    servingDays: '1111110',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '61995',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАКИТНИЦА',
    nameEn: 'RAKITNITSA',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6067',
    addressNomenclature: '0',
    x: '25.511951',
    y: '42.336856',
    servingDays: '1111100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '63700',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАКИТНИЦА',
    nameEn: 'RAKITNITSA',
    municipality: 'БРЕГОВО',
    municipalityEn: 'BREGOVO',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3791',
    addressNomenclature: '0',
    x: '22.648506',
    y: '44.121853',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '62004',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'РАКИТОВО',
    nameEn: 'RAKITOVO',
    municipality: 'РАКИТОВО',
    municipalityEn: 'RAKITOVO',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4640',
    addressNomenclature: '2',
    x: '24.086836',
    y: '41.989402',
    servingDays: '1111100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '62013',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАКЛИНОВО',
    nameEn: 'RAKLINOVO',
    municipality: 'АЙТОС',
    municipalityEn: 'AYTOS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8547',
    addressNomenclature: '0',
    x: '27.050923',
    y: '42.763801',
    servingDays: '1010100',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '62027',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАКЛИЦА',
    nameEn: 'RAKLITSA',
    municipality: 'КАРНОБАТ',
    municipalityEn: 'KARNOBAT',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8439',
    addressNomenclature: '0',
    x: '27.008253',
    y: '42.775283',
    servingDays: '0101000',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '62044',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАКОВИЦА',
    nameEn: 'RAKOVITSA',
    municipality: 'МАКРЕШ',
    municipalityEn: 'MAKRESH',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3820',
    addressNomenclature: '0',
    x: '22.471116',
    y: '43.766309',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '62058',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАКОВО',
    nameEn: 'RAKOVO',
    municipality: 'НЕВЕСТИНО',
    municipalityEn: 'NEVESTINO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2577',
    addressNomenclature: '0',
    x: '22.716634',
    y: '42.084195',
    servingDays: '1010100',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '62061',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАКОВО',
    nameEn: 'RAKOVO',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8884',
    addressNomenclature: '0',
    x: '26.305483',
    y: '42.798709',
    servingDays: '0101000',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '62075',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'РАКОВСКИ',
    nameEn: 'RAKOVSKI',
    municipality: 'РАКОВСКИ',
    municipalityEn: 'RAKOVSKI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4150',
    addressNomenclature: '2',
    x: '24.964832',
    y: '42.290275',
    servingDays: '1111110',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '62089',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАКОВСКИ',
    nameEn: 'RAKOVSKI',
    municipality: 'РАЗГРАД',
    municipalityEn: 'RAZGRAD',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7264',
    addressNomenclature: '0',
    x: '26.464066',
    y: '43.601084',
    servingDays: '1010100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '62092',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАКОВСКИ',
    nameEn: 'RAKOVSKI',
    municipality: 'КАВАРНА',
    municipalityEn: 'KAVARNA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9659',
    addressNomenclature: '0',
    x: '28.40944',
    y: '43.48566',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '62102',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАКОВСКОВО',
    nameEn: 'RAKOVSKOVO',
    municipality: 'НЕСЕБЪР',
    municipalityEn: 'NESEBAR',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8257',
    addressNomenclature: '0',
    x: '27.735528',
    y: '42.834427',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '62116',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАЛЕВО',
    nameEn: 'RALEVO',
    municipality: 'ПЛЕВЕН',
    municipalityEn: 'PLEVEN',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5875',
    addressNomenclature: '0',
    x: '24.602893',
    y: '43.299199',
    servingDays: '0101000',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '62120',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАЛЕВЦИ',
    nameEn: 'RALEVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5367',
    addressNomenclature: '0',
    x: '25.566221',
    y: '42.828353',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '62133',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАЛИНОВЦИ',
    nameEn: 'RALINOVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5098',
    addressNomenclature: '0',
    x: '25.96189',
    y: '42.850333',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '62147',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАЛИЦА',
    nameEn: 'RALITSA',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6820',
    addressNomenclature: '0',
    x: '25.497893',
    y: '41.444307',
    servingDays: '1111110',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '62164',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАЛИЦА',
    nameEn: 'RALITSA',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7759',
    addressNomenclature: '0',
    x: '26.516288',
    y: '43.306178',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '62178',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАЛИЧЕВО',
    nameEn: 'RALICHEVO',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6965',
    addressNomenclature: '0',
    x: '25.500716',
    y: '41.385303',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '62181',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАНЕНЦИ',
    nameEn: 'RANENTSI',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2569',
    addressNomenclature: '0',
    x: '22.528562',
    y: '42.252394',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '62195',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАНИ ЛИСТ',
    nameEn: 'RANI LIST',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6720',
    addressNomenclature: '0',
    x: '25.396379',
    y: '41.691243',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '62205',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАНИ ЛУГ',
    nameEn: 'RANI LUG',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2484',
    addressNomenclature: '0',
    x: '22.491937',
    y: '42.823307',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '62219',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАСНИК',
    nameEn: 'RASNIK',
    municipality: 'ПЕРНИК',
    municipalityEn: 'PERNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2357',
    addressNomenclature: '0',
    x: '23.000899',
    y: '42.7043',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '62222',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАСОВО',
    nameEn: 'RASOVO',
    municipality: 'МЕДКОВЕЦ',
    municipalityEn: 'MEDKOVETS',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3660',
    addressNomenclature: '0',
    x: '23.236191',
    y: '43.699703',
    servingDays: '0101000',
    servingOfficeId: '831',
    servingHubOfficeId: '20',
  },
  {
    id: '62236',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАСТНИК',
    nameEn: 'RASTNIK',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6865',
    addressNomenclature: '0',
    x: '25.28012',
    y: '41.395747',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '62245',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАХОВЦИ',
    nameEn: 'RAHOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5343',
    addressNomenclature: '0',
    x: '25.386783',
    y: '42.92722',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '62253',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАЧЕВЦИ',
    nameEn: 'RACHEVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5345',
    addressNomenclature: '0',
    x: '25.266162',
    y: '42.884404',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '62267',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАЧОВЦИ',
    nameEn: 'RACHOVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5350',
    addressNomenclature: '0',
    x: '25.467981',
    y: '42.898607',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '62270',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАШЕВЦИ',
    nameEn: 'RASHEVTSI',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5035',
    addressNomenclature: '0',
    x: '25.614697',
    y: '42.864371',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '62284',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАШКА ГРАЩИЦА',
    nameEn: 'RASHKA GRASHTITSA',
    municipality: 'НЕВЕСТИНО',
    municipalityEn: 'NEVESTINO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2578',
    addressNomenclature: '0',
    x: '22.77878',
    y: '42.208006',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '62298',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАШКОВО',
    nameEn: 'RASHKOVO',
    municipality: 'БОТЕВГРАД',
    municipalityEn: 'BOTEVGRAD',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2152',
    addressNomenclature: '0',
    x: '23.619674',
    y: '43.009039',
    servingDays: '0101000',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '62308',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАШОВИТЕ',
    nameEn: 'RASHOVITE',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5350',
    addressNomenclature: '0',
    x: '25.479365',
    y: '42.852173',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '62311',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАШОВИЦА',
    nameEn: 'RASHOVITSA',
    municipality: 'БЕРКОВИЦА',
    municipalityEn: 'BERKOVITSA',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3532',
    addressNomenclature: '0',
    x: '23.236722',
    y: '43.266987',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '62325',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАЮВЦИ',
    nameEn: 'RAYUVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5093',
    addressNomenclature: '0',
    x: '25.750417',
    y: '42.929938',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '62339',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАЯНОВЦИ',
    nameEn: 'RAYANOVTSI',
    municipality: 'БЕЛОГРАДЧИК',
    municipalityEn: 'BELOGRADCHIK',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3935',
    addressNomenclature: '0',
    x: '22.523719',
    y: '43.703058',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '62342',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАЯНОВЦИ',
    nameEn: 'RAYANOVTSI',
    municipality: 'ДРАГОМАН',
    municipalityEn: 'DRAGOMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2204',
    addressNomenclature: '0',
    x: '23.026955',
    y: '42.944359',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '62356',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РАЯНЦИ',
    nameEn: 'RAYANTSI',
    municipality: 'ЗЕМЕН',
    municipalityEn: 'ZEMEN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2446',
    addressNomenclature: '0',
    x: '22.677792',
    y: '42.534285',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '62360',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РЕБРЕВЦИ',
    nameEn: 'REBREVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5087',
    addressNomenclature: '0',
    x: '25.768392',
    y: '42.90516',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '62373',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РЕБРО',
    nameEn: 'REBRO',
    municipality: 'БРЕЗНИК',
    municipalityEn: 'BREZNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2384',
    addressNomenclature: '0',
    x: '22.782107',
    y: '42.750411',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '62387',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РЕБРОВО',
    nameEn: 'REBROVO',
    municipality: 'СВОГЕ',
    municipalityEn: 'SVOGE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2294',
    addressNomenclature: '0',
    x: '23.384818',
    y: '42.891258',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '62390',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РЕБЪРКОВО',
    nameEn: 'REBARKOVO',
    municipality: 'МЕЗДРА',
    municipalityEn: 'MEZDRA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3158',
    addressNomenclature: '0',
    x: '23.680652',
    y: '43.112437',
    servingDays: '1010100',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '62400',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РЕДЕШКОВЦИ',
    nameEn: 'REDESHKOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5340',
    addressNomenclature: '0',
    x: '25.405787',
    y: '42.894215',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '62414',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РЕДИНА',
    nameEn: 'REDINA',
    municipality: 'СВОГЕ',
    municipalityEn: 'SVOGE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2269',
    addressNomenclature: '0',
    x: '23.377357',
    y: '42.947276',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '17796',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РЕЖАНЦИ',
    nameEn: 'REZHANTSI',
    municipality: 'БРЕЗНИК',
    municipalityEn: 'BREZNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2380',
    addressNomenclature: '0',
    x: '22.850325',
    y: '42.720157',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '62428',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РЕЖИНЦИ',
    nameEn: 'REZHINTSI',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2562',
    addressNomenclature: '0',
    x: '22.553933',
    y: '42.398648',
    servingDays: '0101000',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '62431',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РЕЗАЧ',
    nameEn: 'REZACH',
    municipality: 'ЗЛАТАРИЦА',
    municipalityEn: 'ZLATARITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5088',
    addressNomenclature: '0',
    x: '26.023954',
    y: '43.023633',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '62445',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РЕЗБАРЦИ',
    nameEn: 'REZBARTSI',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6649',
    addressNomenclature: '0',
    x: '25.359725',
    y: '41.620105',
    servingDays: '1111100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '62459',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РЕЗОВО',
    nameEn: 'REZOVO',
    municipality: 'ЦАРЕВО',
    municipalityEn: 'TSAREVO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8281',
    addressNomenclature: '0',
    x: '28.02994',
    y: '41.984528',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '62462',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РЕКА',
    nameEn: 'REKA',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4734',
    addressNomenclature: '0',
    x: '24.856324',
    y: '41.591807',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '62476',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РЕКИЧКА',
    nameEn: 'REKICHKA',
    municipality: 'ЗЛАТАРИЦА',
    municipalityEn: 'ZLATARITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5088',
    addressNomenclature: '0',
    x: '26.04654',
    y: '43.016834',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '62486',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РЕЛЬОВО',
    nameEn: 'RELYOVO',
    municipality: 'САМОКОВ',
    municipalityEn: 'SAMOKOV',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2018',
    addressNomenclature: '0',
    x: '23.44786',
    y: '42.371307',
    servingDays: '0101000',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '62493',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РЕПЛЯНА',
    nameEn: 'REPLYANA',
    municipality: 'ЧУПРЕНЕ',
    municipalityEn: 'CHUPRENE',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3954',
    addressNomenclature: '0',
    x: '22.734563',
    y: '43.51603',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '62503',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РЕСЕЛЕЦ',
    nameEn: 'RESELETS',
    municipality: 'ЧЕРВЕН БРЯГ',
    municipalityEn: 'CHERVEN BRYAG',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5993',
    addressNomenclature: '0',
    x: '24.038308',
    y: '43.242855',
    servingDays: '1010100',
    servingOfficeId: '59',
    servingHubOfficeId: '20',
  },
  {
    id: '62517',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РЕСЕН',
    nameEn: 'RESEN',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5060',
    addressNomenclature: '0',
    x: '25.557772',
    y: '43.198243',
    servingDays: '1111100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '62520',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РЕСИЛОВО',
    nameEn: 'RESILOVO',
    municipality: 'САПАРЕВА БАНЯ',
    municipalityEn: 'SAPAREVA BANYA',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2621',
    addressNomenclature: '0',
    x: '23.192129',
    y: '42.271591',
    servingDays: '1111110',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '62534',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РЕЧАНИ',
    nameEn: 'RECHANI',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4793',
    addressNomenclature: '0',
    x: '24.633026',
    y: '41.453334',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '62548',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РЕЧИЦА',
    nameEn: 'RECHITSA',
    municipality: 'РУЕН',
    municipalityEn: 'RUEN',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8567',
    addressNomenclature: '0',
    x: '27.241876',
    y: '42.883388',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '62565',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РЕЯНОВЦИ',
    nameEn: 'REYANOVTSI',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2483',
    addressNomenclature: '0',
    x: '22.486929',
    y: '42.783317',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '62579',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РИБАРИЦА',
    nameEn: 'RIBARITSA',
    municipality: 'ТЕТЕВЕН',
    municipalityEn: 'TETEVEN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5720',
    addressNomenclature: '0',
    x: '24.357713',
    y: '42.849114',
    servingDays: '1111100',
    servingOfficeId: '88',
    servingHubOfficeId: '2',
  },
  {
    id: '62582',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РИБАРИЦА',
    nameEn: 'RIBARITSA',
    municipality: 'ЕТРОПОЛЕ',
    municipalityEn: 'ETROPOLE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2192',
    addressNomenclature: '0',
    x: '24.031509',
    y: '42.835259',
    servingDays: '0101000',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '54260',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РИБАРЦИ',
    nameEn: 'RIBARTSI',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6790',
    addressNomenclature: '0',
    x: '25.245395',
    y: '41.666865',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '62596',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РИБЕН',
    nameEn: 'RIBEN',
    municipality: 'ДОЛНА МИТРОПОЛИЯ',
    municipalityEn: 'DOLNA MITROPOLIA',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5847',
    addressNomenclature: '0',
    x: '24.628609',
    y: '43.529437',
    servingDays: '1010100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '62606',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РИБЕН ДОЛ',
    nameEn: 'RIBEN DOL',
    municipality: 'БАНИТЕ',
    municipalityEn: 'BANITE',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4952',
    addressNomenclature: '0',
    x: '25.028273',
    y: '41.745229',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '62617',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РИБИНО',
    nameEn: 'RIBINO',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6939',
    addressNomenclature: '0',
    x: '25.540903',
    y: '41.411387',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '62623',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РИБНИК',
    nameEn: 'RIBNIK',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2864',
    addressNomenclature: '0',
    x: '23.254156',
    y: '41.49055',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '62637',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РИБНИЦА',
    nameEn: 'RIBNITSA',
    municipality: 'РУДОЗЕМ',
    municipalityEn: 'RUDOZEM',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4973',
    addressNomenclature: '0',
    x: '24.87997',
    y: '41.4622',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '62640',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РИБНОВО',
    nameEn: 'RIBNOVO',
    municipality: 'ГЪРМЕН',
    municipalityEn: 'GARMEN',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2967',
    addressNomenclature: '0',
    x: '23.762382',
    y: '41.718805',
    servingDays: '1010100',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '62654',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РИДИНО',
    nameEn: 'RIDINO',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6849',
    addressNomenclature: '0',
    x: '25.291473',
    y: '41.447808',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '62668',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РИДОВО',
    nameEn: 'RIDOVO',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6742',
    addressNomenclature: '0',
    x: '25.287485',
    y: '41.669153',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '62671',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'РИЛА',
    nameEn: 'RILA',
    municipality: 'РИЛА',
    municipalityEn: 'RILA',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2630',
    addressNomenclature: '2',
    x: '23.128932',
    y: '42.123215',
    servingDays: '1111100',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '62685',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.ман.',
    typeEn: 's.man.',
    name: 'РИЛСКИ МАНАСТИР',
    nameEn: 'RILSKI MANASTIR',
    municipality: 'РИЛА',
    municipalityEn: 'RILA',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2643',
    addressNomenclature: '0',
    x: '23.339036',
    y: '42.133951',
    servingDays: '0101000',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '62699',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РИЛЦИ',
    nameEn: 'RILTSI',
    municipality: 'БЛАГОЕВГРАД',
    municipalityEn: 'BLAGOEVGRAD',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2710',
    addressNomenclature: '0',
    x: '23.06498',
    y: '42.040455',
    servingDays: '1111100',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '62712',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РИСИМАНОВО',
    nameEn: 'RISIMANOVO',
    municipality: 'РАДНЕВО',
    municipalityEn: 'RADNEVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6274',
    addressNomenclature: '0',
    x: '25.927083',
    y: '42.234587',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '62726',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РИТЯ',
    nameEn: 'RITYA',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5370',
    addressNomenclature: '0',
    x: '25.421091',
    y: '42.990736',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '62732',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РИШ',
    nameEn: 'RISH',
    municipality: 'СМЯДОВО',
    municipalityEn: 'SMYADOVO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9830',
    addressNomenclature: '0',
    x: '26.922524',
    y: '42.977979',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '62757',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РОБОВО',
    nameEn: 'ROBOVO',
    municipality: 'ТУНДЖА',
    municipalityEn: 'TUNDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8664',
    addressNomenclature: '0',
    x: '26.693191',
    y: '42.32047',
    servingDays: '0101000',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '62760',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РОВИНА',
    nameEn: 'ROVINA',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4736',
    addressNomenclature: '0',
    x: '24.846191',
    y: '41.562296',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '62774',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РОГАЧ',
    nameEn: 'ROGACH',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6916',
    addressNomenclature: '0',
    x: '25.699552',
    y: '41.454675',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '62788',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РОГАЧЕВО',
    nameEn: 'ROGACHEVO',
    municipality: 'БАЛЧИК',
    municipalityEn: 'BALCHIK',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9632',
    addressNomenclature: '0',
    x: '28.02431',
    y: '43.37979',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '62791',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РОГЛЕЦ',
    nameEn: 'ROGLETS',
    municipality: 'РУЖИНЦИ',
    municipalityEn: 'RUZHINTSI',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3962',
    addressNomenclature: '0',
    x: '22.934372',
    y: '43.66948',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '62801',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РОГОЗАРИ',
    nameEn: 'ROGOZARI',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6845',
    addressNomenclature: '0',
    x: '25.347019',
    y: '41.462653',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '62815',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РОГОЗЕН',
    nameEn: 'ROGOZEN',
    municipality: 'ХАЙРЕДИН',
    municipalityEn: 'HAYREDIN',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3360',
    addressNomenclature: '0',
    x: '23.706597',
    y: '43.519387',
    servingDays: '1010100',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '62829',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РОГОЗИНА',
    nameEn: 'ROGOZINA',
    municipality: 'ГЕНЕРАЛ ТОШЕВО',
    municipalityEn: 'GENERAL TOSHEVO',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9560',
    addressNomenclature: '0',
    x: '28.232252',
    y: '43.72154',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '62832',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РОГОЗИНОВО',
    nameEn: 'ROGOZINOVO',
    municipality: 'ХАРМАНЛИ',
    municipalityEn: 'HARMANLI',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6469',
    addressNomenclature: '0',
    x: '25.967006',
    y: '41.921407',
    servingDays: '1010100',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '62846',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РОГОЗЧЕ',
    nameEn: 'ROGOZCHE',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6845',
    addressNomenclature: '0',
    x: '25.293783',
    y: '41.463243',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '62858',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РОГОШ',
    nameEn: 'ROGOSH',
    municipality: 'МАРИЦА',
    municipalityEn: 'MARITSA',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4140',
    addressNomenclature: '0',
    x: '24.864634',
    y: '42.181827',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '62863',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РОГУЛЯТ',
    nameEn: 'ROGULYAT',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '',
    addressNomenclature: '0',
    x: '24.929988',
    y: '42.927339',
    servingDays: '1010100',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '62877',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РОДИНА',
    nameEn: 'RODINA',
    municipality: 'ЗЛАТАРИЦА',
    municipalityEn: 'ZLATARITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5092',
    addressNomenclature: '0',
    x: '25.881547',
    y: '43.084642',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '62880',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РОДОПИ',
    nameEn: 'RODOPI',
    municipality: 'ХАСКОВО',
    municipalityEn: 'HASKOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6356',
    addressNomenclature: '0',
    x: '25.757572',
    y: '41.921414',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '62894',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РОДОПСКО',
    nameEn: 'RODOPSKO',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6762',
    addressNomenclature: '0',
    x: '25.098049',
    y: '41.588607',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '62904',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РОЖДЕН',
    nameEn: 'ROZHDEN',
    municipality: 'РУЕН',
    municipalityEn: 'RUEN',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8539',
    addressNomenclature: '0',
    x: '27.448092',
    y: '42.867085',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '63714',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РОЖДЕНСКО',
    nameEn: 'ROZHDENSKO',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6841',
    addressNomenclature: '0',
    x: '25.176609',
    y: '41.510317',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '62918',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РОЖЕН',
    nameEn: 'ROZHEN',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2820',
    addressNomenclature: '0',
    x: '23.433711',
    y: '41.534238',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '62921',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РОЗА',
    nameEn: 'ROZA',
    municipality: 'ТУНДЖА',
    municipalityEn: 'TUNDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8630',
    addressNomenclature: '0',
    x: '26.412999',
    y: '42.399249',
    servingDays: '1010100',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '62935',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РОЗИНО',
    nameEn: 'ROZINO',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6596',
    addressNomenclature: '0',
    x: '25.900726',
    y: '41.455161',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '62949',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РОЗИНО',
    nameEn: 'ROZINO',
    municipality: 'КАРЛОВО',
    municipalityEn: 'KARLOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4340',
    addressNomenclature: '0',
    x: '24.54671',
    y: '42.712618',
    servingDays: '1111110',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '62966',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РОЗОВЕЦ',
    nameEn: 'ROZOVETS',
    municipality: 'БРЕЗОВО',
    municipalityEn: 'BREZOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4154',
    addressNomenclature: '0',
    x: '25.115339',
    y: '42.462049',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '62973',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РОЗОВО',
    nameEn: 'ROZOVO',
    municipality: 'БРАЦИГОВО',
    municipalityEn: 'BRATSIGOVO',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4583',
    addressNomenclature: '0',
    x: '24.373382',
    y: '42.00867',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '62983',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РОЗОВО',
    nameEn: 'ROZOVO',
    municipality: 'КАЗАНЛЪК',
    municipalityEn: 'KAZANLAK',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6163',
    addressNomenclature: '0',
    x: '25.414997',
    y: '42.560758',
    servingDays: '1111110',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '62997',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'РОМАН',
    nameEn: 'ROMAN',
    municipality: 'РОМАН',
    municipalityEn: 'ROMAN',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3130',
    addressNomenclature: '2',
    x: '23.925366',
    y: '43.141339',
    servingDays: '1111100',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '63001',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РОПОТ',
    nameEn: 'ROPOT',
    municipality: 'ГОДЕЧ',
    municipalityEn: 'GODECH',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2254',
    addressNomenclature: '0',
    x: '22.983056',
    y: '43.038867',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '63029',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РОСЕН',
    nameEn: 'ROSEN',
    municipality: 'СОЗОПОЛ',
    municipalityEn: 'SOZOPOL',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8150',
    addressNomenclature: '0',
    x: '27.56576',
    y: '42.385542',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '63032',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РОСЕН',
    nameEn: 'ROSEN',
    municipality: 'ПАЗАРДЖИК',
    municipalityEn: 'PAZARDZHIK',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4421',
    addressNomenclature: '0',
    x: '24.364394',
    y: '42.316267',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '63046',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РОСЕН',
    nameEn: 'ROSEN',
    municipality: 'ГЕНЕРАЛ ТОШЕВО',
    municipalityEn: 'GENERAL TOSHEVO',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9537',
    addressNomenclature: '0',
    x: '27.960192',
    y: '43.849379',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '63055',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РОСЕНОВО',
    nameEn: 'ROSENOVO',
    municipality: 'СРЕДЕЦ',
    municipalityEn: 'SREDETS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8318',
    addressNomenclature: '0',
    x: '27.228109',
    y: '42.291319',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '63063',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РОСЕНОВО',
    nameEn: 'ROSENOVO',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9369',
    addressNomenclature: '0',
    x: '27.782478',
    y: '43.663454',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '63077',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РОСИНА',
    nameEn: 'ROSINA',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7784',
    addressNomenclature: '0',
    x: '26.411458',
    y: '43.310142',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '63080',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РОСИЦА',
    nameEn: 'ROSITSA',
    municipality: 'ПАВЛИКЕНИ',
    municipalityEn: 'PAVLIKENI',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5221',
    addressNomenclature: '0',
    x: '25.25802',
    y: '43.195954',
    servingDays: '1111100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '63094',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РОСИЦА',
    nameEn: 'ROSITSA',
    municipality: 'ГЕНЕРАЛ ТОШЕВО',
    municipalityEn: 'GENERAL TOSHEVO',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9540',
    addressNomenclature: '0',
    x: '27.900936',
    y: '43.938361',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '63104',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РОСИЦА',
    nameEn: 'ROSITSA',
    municipality: 'ОМУРТАГ',
    municipalityEn: 'OMURTAG',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7933',
    addressNomenclature: '0',
    x: '26.295334',
    y: '43.141459',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '63118',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РОСНО',
    nameEn: 'ROSNO',
    municipality: 'ЗЛАТАРИЦА',
    municipalityEn: 'ZLATARITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5091',
    addressNomenclature: '0',
    x: '25.937488',
    y: '43.054083',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '63121',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РОСОМАН',
    nameEn: 'ROSOMAN',
    municipality: 'БОЖУРИЩЕ',
    municipalityEn: 'BOZHURISHTE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2225',
    addressNomenclature: '0',
    x: '23.077472',
    y: '42.757575',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '63687',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РОХЛЕВА',
    nameEn: 'ROHLEVA',
    municipality: 'ВЕЛИНГРАД',
    municipalityEn: 'VELINGRAD',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4653',
    addressNomenclature: '0',
    x: '23.804693',
    y: '42.016712',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '63135',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РОЯК',
    nameEn: 'ROYAK',
    municipality: 'ДЪЛГОПОЛ',
    municipalityEn: 'DALGOPOL',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9252',
    addressNomenclature: '0',
    x: '27.38146',
    y: '43.085565',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '63152',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РУДАРЦИ',
    nameEn: 'RUDARTSI',
    municipality: 'ПЕРНИК',
    municipalityEn: 'PERNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2343',
    addressNomenclature: '0',
    x: '23.165682',
    y: '42.582628',
    servingDays: '1111100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '63166',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РУДИНА',
    nameEn: 'RUDINA',
    municipality: 'РУЕН',
    municipalityEn: 'RUEN',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8536',
    addressNomenclature: '0',
    x: '27.427211',
    y: '42.844942',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '63170',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РУДИНА',
    nameEn: 'RUDINA',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6659',
    addressNomenclature: '0',
    x: '25.60585',
    y: '41.718219',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '63183',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РУДНИК',
    nameEn: 'RUDNIK',
    municipality: 'БУРГАС',
    municipalityEn: 'BURGAS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8112',
    addressNomenclature: '2',
    x: '27.48717',
    y: '42.626401',
    servingDays: '1111110',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '63197',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РУДНИК',
    nameEn: 'RUDNIK',
    municipality: 'ДОЛНИ ЧИФЛИК',
    municipalityEn: 'DOLNI CHIFLIK',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9105',
    addressNomenclature: '0',
    x: '27.769989',
    y: '42.953117',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '63207',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'РУДОЗЕМ',
    nameEn: 'RUDOZEM',
    municipality: 'РУДОЗЕМ',
    municipalityEn: 'RUDOZEM',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4960',
    addressNomenclature: '2',
    x: '24.848048',
    y: '41.485545',
    servingDays: '1111100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '63210',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РУЕВЦИ',
    nameEn: 'RUEVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5367',
    addressNomenclature: '0',
    x: '25.538498',
    y: '42.811505',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '63224',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РУЕН',
    nameEn: 'RUEN',
    municipality: 'РУЕН',
    municipalityEn: 'RUEN',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8540',
    addressNomenclature: '2',
    x: '27.282236',
    y: '42.799787',
    servingDays: '1111100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '63238',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РУЕН',
    nameEn: 'RUEN',
    municipality: 'КУКЛЕН',
    municipalityEn: 'KUKLEN',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4102',
    addressNomenclature: '0',
    x: '24.807074',
    y: '42.014901',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '63241',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РУЕЦ',
    nameEn: 'RUETS',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7744',
    addressNomenclature: '0',
    x: '26.62438',
    y: '43.214035',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '63255',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РУЖИНЦИ',
    nameEn: 'RUZHINTSI',
    municipality: 'РУЖИНЦИ',
    municipalityEn: 'RUZHINTSI',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3930',
    addressNomenclature: '2',
    x: '22.830982',
    y: '43.623221',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '63269',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РУЖИЦА',
    nameEn: 'RUZHITSA',
    municipality: 'НИКОЛА КОЗЛЕВО',
    municipalityEn: 'NIKOLA KOZLEVO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9954',
    addressNomenclature: '0',
    x: '27.16489',
    y: '43.552508',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '63272',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РУЖИЦА',
    nameEn: 'RUZHITSA',
    municipality: 'БОЛЯРОВО',
    municipalityEn: 'BOLYAROVO',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8722',
    addressNomenclature: '0',
    x: '26.878361',
    y: '42.151999',
    servingDays: '0101000',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '63286',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РУЙНО',
    nameEn: 'RUYNO',
    municipality: 'ДУЛОВО',
    municipalityEn: 'DULOVO',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7692',
    addressNomenclature: '0',
    x: '27.0163',
    y: '43.829373',
    servingDays: '0101000',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '63296',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РУЙЧОВЦИ',
    nameEn: 'RUYCHOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5304',
    addressNomenclature: '0',
    x: '25.239651',
    y: '42.825742',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '63301',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РУМАНЯ',
    nameEn: 'RUMANYA',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6057',
    addressNomenclature: '0',
    x: '25.870533000000002',
    y: '42.504003',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '63313',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РУМЕЛИЯ',
    nameEn: 'RUMELIA',
    municipality: 'МАДЖАРОВО',
    municipalityEn: 'MADZHAROVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6453',
    addressNomenclature: '0',
    x: '25.785538',
    y: '41.674289',
    servingDays: '0101000',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '63327',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РУМЯНЦЕВО',
    nameEn: 'RUMYANTSEVO',
    municipality: 'ЛУКОВИТ',
    municipalityEn: 'LUKOVIT',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5785',
    addressNomenclature: '0',
    x: '24.153875',
    y: '43.133628',
    servingDays: '0101000',
    servingOfficeId: '59',
    servingHubOfficeId: '20',
  },
  {
    id: '63330',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РУНЯ',
    nameEn: 'RUNYA',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5397',
    addressNomenclature: '0',
    x: '25.543386',
    y: '42.979413',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '49312',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РУПИТЕ',
    nameEn: 'RUPITE',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2863',
    addressNomenclature: '0',
    x: '23.241514',
    y: '41.441651',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '63344',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РУПКИТЕ',
    nameEn: 'RUPKITE',
    municipality: 'ЧИРПАН',
    municipalityEn: 'CHIRPAN',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6251',
    addressNomenclature: '0',
    x: '25.352105',
    y: '42.246084',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '63358',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РУПЦИ',
    nameEn: 'RUPTSI',
    municipality: 'ВИДИН',
    municipalityEn: 'VIDIN',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3726',
    addressNomenclature: '0',
    x: '22.804026',
    y: '44.004452',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '63361',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РУПЦИ',
    nameEn: 'RUPTSI',
    municipality: 'ЧЕРВЕН БРЯГ',
    municipalityEn: 'CHERVEN BRYAG',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5994',
    addressNomenclature: '0',
    x: '24.14536',
    y: '43.272245',
    servingDays: '1010100',
    servingOfficeId: '59',
    servingHubOfficeId: '20',
  },
  {
    id: '63375',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РУПЧА',
    nameEn: 'RUPCHA',
    municipality: 'РУЕН',
    municipalityEn: 'RUEN',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8574',
    addressNomenclature: '0',
    x: '27.089445',
    y: '42.889541',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '63392',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РУСАЛИНА',
    nameEn: 'RUSALINA',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6695',
    addressNomenclature: '0',
    x: '25.229941',
    y: '41.762827',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '92069',
    countryId: '100',
    mainSiteId: '0',
    type: 'в.с.',
    typeEn: 'v.s.',
    name: 'РУСАЛКА',
    nameEn: 'RUSALKA',
    municipality: 'КАВАРНА',
    municipalityEn: 'KAVARNA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9695',
    addressNomenclature: '0',
    x: '28.516324',
    y: '43.417155',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '63402',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РУСАЛСКО',
    nameEn: 'RUSALSKO',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6795',
    addressNomenclature: '0',
    x: '25.141014',
    y: '41.706499',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '63416',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РУСАЛЯ',
    nameEn: 'RUSALYA',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5057',
    addressNomenclature: '0',
    x: '25.456678',
    y: '43.167722',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '63427',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'РУСЕ',
    nameEn: 'RUSE',
    municipality: 'РУСЕ',
    municipalityEn: 'RUSE',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7000',
    addressNomenclature: '1',
    x: '25.96025',
    y: '43.845995',
    servingDays: '1111110',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '63447',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РУСИНОВЦИ',
    nameEn: 'RUSINOVTSI',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5380',
    addressNomenclature: '0',
    x: '25.515624',
    y: '42.915401',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '63450',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РУСКА БЕЛА',
    nameEn: 'RUSKA BELA',
    municipality: 'МЕЗДРА',
    municipalityEn: 'MEZDRA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3148',
    addressNomenclature: '0',
    x: '23.652037',
    y: '43.164336',
    servingDays: '1010100',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '63464',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РУСКОВЦИ',
    nameEn: 'RUSKOVTSI',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5035',
    addressNomenclature: '0',
    x: '25.638934',
    y: '42.837315',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '63478',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РУСОКАСТРО',
    nameEn: 'RUSOKASTRO',
    municipality: 'КАМЕНО',
    municipalityEn: 'KAMENO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8105',
    addressNomenclature: '0',
    x: '27.186417',
    y: '42.465665',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '63481',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РУСТАН',
    nameEn: 'RUSTAN',
    municipality: 'МАДАН',
    municipalityEn: 'MADAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4956',
    addressNomenclature: '0',
    x: '24.87287',
    y: '41.527851',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '63495',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РУХОВЦИ',
    nameEn: 'RUHOVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5079',
    addressNomenclature: '0',
    x: '25.938631',
    y: '42.912791',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '63505',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РУЧЕЙ',
    nameEn: 'RUCHEY',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6928',
    addressNomenclature: '0',
    x: '25.603262',
    y: '41.381521',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '63519',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РЪЖАВЕЦ',
    nameEn: 'RAZHAVETS',
    municipality: 'БРЕЗНИК',
    municipalityEn: 'BREZNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2389',
    addressNomenclature: '0',
    x: '22.728486',
    y: '42.711881',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '63522',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РЪЖАНА',
    nameEn: 'RAZHANA',
    municipality: 'ИХТИМАН',
    municipalityEn: 'IHTIMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2060',
    addressNomenclature: '0',
    x: '23.758029',
    y: '42.529719',
    servingDays: '1010100',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '63542',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РЪЖДАК',
    nameEn: 'RAZHDAK',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2856',
    addressNomenclature: '0',
    x: '23.2405',
    y: '41.400714',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '63553',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РЪЖЕВО',
    nameEn: 'RAZHEVO',
    municipality: 'КАЛОЯНОВО',
    municipalityEn: 'KALOYANOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4169',
    addressNomenclature: '0',
    x: '24.799102',
    y: '42.370035',
    servingDays: '0101000',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '63567',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РЪЖЕВО КОНАРЕ',
    nameEn: 'RAZHEVO KONARE',
    municipality: 'КАЛОЯНОВО',
    municipalityEn: 'KALOYANOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4170',
    addressNomenclature: '0',
    x: '24.80603',
    y: '42.337392',
    servingDays: '0101000',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '63570',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РЪЖЕНА',
    nameEn: 'RAZHENA',
    municipality: 'КАЗАНЛЪК',
    municipalityEn: 'KAZANLAK',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6171',
    addressNomenclature: '0',
    x: '25.495643',
    y: '42.533974',
    servingDays: '0101000',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '63584',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РЪЖЕНОВО',
    nameEn: 'RAZHENOVO',
    municipality: 'МАДЖАРОВО',
    municipalityEn: 'MADZHAROVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6456',
    addressNomenclature: '0',
    x: '25.761131',
    y: '41.681966',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '63598',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РЪЖИЦА',
    nameEn: 'RAZHITSA',
    municipality: 'РУЕН',
    municipalityEn: 'RUEN',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8530',
    addressNomenclature: '0',
    x: '27.395918',
    y: '42.782684',
    servingDays: '1111100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '63608',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РЪСОВО',
    nameEn: 'RASOVO',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2568',
    addressNomenclature: '0',
    x: '22.540041',
    y: '42.269286',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '63611',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РЪТ',
    nameEn: 'RAT',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6850',
    addressNomenclature: '0',
    x: '25.321378',
    y: '41.551749',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '63639',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РЪТЛИНА',
    nameEn: 'RATLINA',
    municipality: 'ОМУРТАГ',
    municipalityEn: 'OMURTAG',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7935',
    addressNomenclature: '0',
    x: '26.606671',
    y: '43.073954',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '63690',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РЯЗКОВЦИ',
    nameEn: 'RYAZKOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5345',
    addressNomenclature: '0',
    x: '25.302842',
    y: '42.912386',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '63668',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РЯХОВО',
    nameEn: 'RYAHOVO',
    municipality: 'СЛИВО ПОЛЕ',
    municipalityEn: 'SLIVO POLE',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7070',
    addressNomenclature: '0',
    x: '26.246142',
    y: '43.98827',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '63673',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'РЯХОВЦИТЕ',
    nameEn: 'RYAHOVTSITE',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5440',
    addressNomenclature: '0',
    x: '25.00914',
    y: '43.023699',
    servingDays: '1111100',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '65019',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'САВА',
    nameEn: 'SAVA',
    municipality: 'ДЪЛГОПОЛ',
    municipalityEn: 'DALGOPOL',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9251',
    addressNomenclature: '0',
    x: '27.379282',
    y: '43.03106',
    servingDays: '1111100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '65022',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'САВИН',
    nameEn: 'SAVIN',
    municipality: 'КУБРАТ',
    municipalityEn: 'KUBRAT',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7318',
    addressNomenclature: '0',
    x: '26.547116',
    y: '43.809126',
    servingDays: '1010100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '65036',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'САВИНО',
    nameEn: 'SAVINO',
    municipality: 'ТУНДЖА',
    municipalityEn: 'TUNDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8643',
    addressNomenclature: '0',
    x: '26.285772',
    y: '42.337433',
    servingDays: '0101000',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '65047',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'САВОЙСКИ',
    nameEn: 'SAVOYSKI',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2576',
    addressNomenclature: '0',
    x: '22.641761',
    y: '42.157542',
    servingDays: '1010100',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '65067',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'САДИНА',
    nameEn: 'SADINA',
    municipality: 'ПОПОВО',
    municipalityEn: 'POPOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7841',
    addressNomenclature: '0',
    x: '26.28916',
    y: '43.483437',
    servingDays: '0101000',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '65070',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'САДОВЕЦ',
    nameEn: 'SADOVETS',
    municipality: 'ДОЛНИ ДЪБНИК',
    municipalityEn: 'DOLNI DABNIK',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5896',
    addressNomenclature: '0',
    x: '24.351038',
    y: '43.307321',
    servingDays: '1010100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '65084',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'САДОВИК',
    nameEn: 'SADOVIK',
    municipality: 'БРЕЗНИК',
    municipalityEn: 'BREZNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2387',
    addressNomenclature: '0',
    x: '22.810203',
    y: '42.71171',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '65098',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'САДОВИЦА',
    nameEn: 'SADOVITSA',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6815',
    addressNomenclature: '0',
    x: '25.383285',
    y: '41.506289',
    servingDays: '1111110',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '65108',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'САДОВО',
    nameEn: 'SADOVO',
    municipality: 'ХАДЖИДИМОВО',
    municipalityEn: 'HADZHIDIMOVO',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2922',
    addressNomenclature: '0',
    x: '23.823447',
    y: '41.50344',
    servingDays: '0101000',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '65111',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'САДОВО',
    nameEn: 'SADOVO',
    municipality: 'СУНГУРЛАРЕ',
    municipalityEn: 'SUNGURLARE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8463',
    addressNomenclature: '0',
    x: '26.636409',
    y: '42.853922',
    servingDays: '1010100',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '65125',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'САДОВО',
    nameEn: 'SADOVO',
    municipality: 'АВРЕН',
    municipalityEn: 'AVREN',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9139',
    addressNomenclature: '0',
    x: '27.716841',
    y: '43.064378',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '65139',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'САДОВО',
    nameEn: 'SADOVO',
    municipality: 'САДОВО',
    municipalityEn: 'SADOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4122',
    addressNomenclature: '2',
    x: '24.938511',
    y: '42.13038',
    servingDays: '0101010',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '65142',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'САЖДЕНИК',
    nameEn: 'SAZHDENIK',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2576',
    addressNomenclature: '0',
    x: '22.575783',
    y: '42.131841',
    servingDays: '1010100',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '65156',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'САКАРЦИ',
    nameEn: 'SAKARTSI',
    municipality: 'ТОПОЛОВГРАД',
    municipalityEn: 'TOPOLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6568',
    addressNomenclature: '0',
    x: '26.293756',
    y: '42.046176',
    servingDays: '1010100',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '65162',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'САЛАСУКА',
    nameEn: 'SALASUKA',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5397',
    addressNomenclature: '0',
    x: '25.529339',
    y: '42.999081',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '65173',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'САЛАШ',
    nameEn: 'SALASH',
    municipality: 'БЕЛОГРАДЧИК',
    municipalityEn: 'BELOGRADCHIK',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3933',
    addressNomenclature: '0',
    x: '22.525789',
    y: '43.613912',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '65187',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'САЛМАНОВО',
    nameEn: 'SALMANOVO',
    municipality: 'ШУМЕН',
    municipalityEn: 'SHUMEN',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9817',
    addressNomenclature: '0',
    x: '26.976382',
    y: '43.169967',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '65190',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'САМОВИЛА',
    nameEn: 'SAMOVILA',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6942',
    addressNomenclature: '0',
    x: '25.487153',
    y: '41.408262',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '65200',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'САМОВОДЕНЕ',
    nameEn: 'SAMOVODENE',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5040',
    addressNomenclature: '0',
    x: '25.610367',
    y: '43.140546',
    servingDays: '1111110',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '65214',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'САМОДИВА',
    nameEn: 'SAMODIVA',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6878',
    addressNomenclature: '0',
    x: '25.33378',
    y: '41.435688',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '65228',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'САМОКИТКА',
    nameEn: 'SAMOKITKA',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6880',
    addressNomenclature: '0',
    x: '25.445284',
    y: '41.402002',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '65231',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'САМОКОВ',
    nameEn: 'SAMOKOV',
    municipality: 'САМОКОВ',
    municipalityEn: 'SAMOKOV',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2000',
    addressNomenclature: '2',
    x: '23.552886',
    y: '42.336913',
    servingDays: '1111110',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '65245',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'САМОРАНОВО',
    nameEn: 'SAMORANOVO',
    municipality: 'ДУПНИЦА',
    municipalityEn: 'DUPNITSA',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2620',
    addressNomenclature: '0',
    x: '23.165253',
    y: '42.262061',
    servingDays: '1111110',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '65259',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'САМОТИНО',
    nameEn: 'SAMOTINO',
    municipality: 'БЯЛА',
    municipalityEn: 'BYALA',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9101',
    addressNomenclature: '0',
    x: '27.861833',
    y: '42.940464',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '65262',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'САМСИИТЕ',
    nameEn: 'SAMSIITE',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5035',
    addressNomenclature: '0',
    x: '25.632896',
    y: '42.928777',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '65276',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'САМУИЛ',
    nameEn: 'SAMUIL',
    municipality: 'САМУИЛ',
    municipalityEn: 'SAMUIL',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7454',
    addressNomenclature: '0',
    x: '26.73906',
    y: '43.510181',
    servingDays: '1111100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '65288',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'САМУИЛОВА КРЕПОСТ',
    nameEn: 'SAMUILOVA KREPOST',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2895',
    addressNomenclature: '0',
    x: '23.029157',
    y: '41.393778',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '374',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'САМУИЛОВО',
    nameEn: 'SAMUILOVO',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9384',
    addressNomenclature: '0',
    x: '27.639883',
    y: '43.584554',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '65293',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'САМУИЛОВО',
    nameEn: 'SAMUILOVO',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2879',
    addressNomenclature: '0',
    x: '23.088393',
    y: '41.372482',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '65303',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'САМУИЛОВО',
    nameEn: 'SAMUILOVO',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8865',
    addressNomenclature: '0',
    x: '26.324038',
    y: '42.591259',
    servingDays: '1111110',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '65317',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'САМУИЛОВО',
    nameEn: 'SAMUILOVO',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6051',
    addressNomenclature: '0',
    x: '25.516174',
    y: '42.216338',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '65351',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'САН СТЕФАНО',
    nameEn: 'SAN STEFANO',
    municipality: 'КАРНОБАТ',
    municipalityEn: 'KARNOBAT',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8484',
    addressNomenclature: '0',
    x: '26.878847',
    y: '42.519824',
    servingDays: '1010100',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '65320',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'САНАДИНОВО',
    nameEn: 'SANADINOVO',
    municipality: 'НИКОПОЛ',
    municipalityEn: 'NIKOPOL',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5949',
    addressNomenclature: '0',
    x: '25.004841',
    y: '43.538347',
    servingDays: '1010100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '65334',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'САНДАНСКИ',
    nameEn: 'SANDANSKI',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2800',
    addressNomenclature: '1',
    x: '23.277715',
    y: '41.561187',
    servingDays: '1111110',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '65348',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'САНДРОВО',
    nameEn: 'SANDROVO',
    municipality: 'РУСЕ',
    municipalityEn: 'RUSE',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7059',
    addressNomenclature: '0',
    x: '26.113723',
    y: '43.934704',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '65365',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'САПАРЕВА БАНЯ',
    nameEn: 'SAPAREVA BANYA',
    municipality: 'САПАРЕВА БАНЯ',
    municipalityEn: 'SAPAREVA BANYA',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2650',
    addressNomenclature: '2',
    x: '23.261795',
    y: '42.288449',
    servingDays: '1111110',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '65379',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'САПАРЕВО',
    nameEn: 'SAPAREVO',
    municipality: 'САПАРЕВА БАНЯ',
    municipalityEn: 'SAPAREVA BANYA',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2645',
    addressNomenclature: '0',
    x: '23.293542',
    y: '42.301717',
    servingDays: '1111110',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '65396',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'САРАЕВО',
    nameEn: 'SARAEVO',
    municipality: 'МИЗИЯ',
    municipalityEn: 'MIZIA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3333',
    addressNomenclature: '0',
    x: '23.858414',
    y: '43.716079',
    servingDays: '1010100',
    servingOfficeId: '64',
    servingHubOfficeId: '20',
  },
  {
    id: '65406',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'САРАНСКО',
    nameEn: 'SARANSKO',
    municipality: 'СТРАЛДЖА',
    municipalityEn: 'STRALDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8659',
    addressNomenclature: '0',
    x: '26.710369',
    y: '42.362397',
    servingDays: '0101000',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '65419',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'САРАНЦИ',
    nameEn: 'SARANTSI',
    municipality: 'ГОРНА МАЛИНА',
    municipalityEn: 'GORNA MALINA',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2120',
    addressNomenclature: '0',
    x: '23.77514',
    y: '42.723797',
    servingDays: '0101000',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '65437',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'САРАЯ',
    nameEn: 'SARAYA',
    municipality: 'ПАЗАРДЖИК',
    municipalityEn: 'PAZARDZHIK',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4447',
    addressNomenclature: '0',
    x: '24.32144',
    y: '42.24902',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '65440',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'САТОВЧА',
    nameEn: 'SATOVCHA',
    municipality: 'САТОВЧА',
    municipalityEn: 'SATOVCHA',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2950',
    addressNomenclature: '0',
    x: '23.9769',
    y: '41.619141',
    servingDays: '1111100',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '65454',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СБОР',
    nameEn: 'SBOR',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6961',
    addressNomenclature: '0',
    x: '25.746673',
    y: '41.546733',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '65468',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СБОР',
    nameEn: 'SBOR',
    municipality: 'ПАЗАРДЖИК',
    municipalityEn: 'PAZARDZHIK',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4449',
    addressNomenclature: '0',
    x: '24.295922',
    y: '42.339812',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '65485',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СБОРИНО',
    nameEn: 'SBORINO',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6595',
    addressNomenclature: '0',
    x: '25.907719',
    y: '41.516089',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '65499',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СБОРИЩЕ',
    nameEn: 'SBORISHTE',
    municipality: 'ТВЪРДИЦА',
    municipalityEn: 'TVARDITSA',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8899',
    addressNomenclature: '0',
    x: '25.973668',
    y: '42.682018',
    servingDays: '0101000',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '92070',
    countryId: '100',
    mainSiteId: '0',
    type: 'к.',
    typeEn: 'k.',
    name: 'СВ. КОНСТАНТИН',
    nameEn: 'SV. KONSTANTIN',
    municipality: 'ПЕЩЕРА',
    municipalityEn: 'PESHTERA',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4577',
    addressNomenclature: '0',
    x: '24.228418',
    y: '42.02845',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '94040',
    countryId: '100',
    mainSiteId: '0',
    type: 'к.к.',
    typeEn: 'k.k.',
    name: 'СВ. СВ. КОНСТАНТИН И ЕЛЕНА',
    nameEn: 'SV. SV. KONSTANTIN I ELENA',
    municipality: 'ВАРНА',
    municipalityEn: 'VARNA',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9006',
    addressNomenclature: '0',
    x: '28.007645',
    y: '43.231498',
    servingDays: '1111110',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '65509',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СВАЛЕНИК',
    nameEn: 'SVALENIK',
    municipality: 'ИВАНОВО',
    municipalityEn: 'IVANOVO',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7082',
    addressNomenclature: '0',
    x: '26.134469',
    y: '43.584602',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '65512',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СВАТБАРЕ',
    nameEn: 'SVATBARE',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6675',
    addressNomenclature: '0',
    x: '25.543933',
    y: '41.672146',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '65526',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СВЕЖЕН',
    nameEn: 'SVEZHEN',
    municipality: 'БРЕЗОВО',
    municipalityEn: 'BREZOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4304',
    addressNomenclature: '0',
    x: '25.026606',
    y: '42.504902',
    servingDays: '1010100',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '65534',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СВЕТА ПЕТКА',
    nameEn: 'SVETA PETKA',
    municipality: 'ВЕЛИНГРАД',
    municipalityEn: 'VELINGRAD',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4620',
    addressNomenclature: '0',
    x: '23.875875',
    y: '42.038674',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '11538',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'СВЕТИ ВЛАС',
    nameEn: 'SVETI VLAS',
    municipality: 'НЕСЕБЪР',
    municipalityEn: 'NESEBAR',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8256',
    addressNomenclature: '2',
    x: '27.758278',
    y: '42.713481',
    servingDays: '1111110',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '65543',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СВЕТИ НИКОЛА',
    nameEn: 'SVETI NIKOLA',
    municipality: 'КАВАРНА',
    municipalityEn: 'KAVARNA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9662',
    addressNomenclature: '0',
    x: '28.49271',
    y: '43.435026',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '35016',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СВЕТЛЕН',
    nameEn: 'SVETLEN',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6880',
    addressNomenclature: '0',
    x: '25.420514',
    y: '41.422277',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '65557',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СВЕТЛЕН',
    nameEn: 'SVETLEN',
    municipality: 'ПОПОВО',
    municipalityEn: 'POPOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7877',
    addressNomenclature: '0',
    x: '26.272958',
    y: '43.307375',
    servingDays: '1111100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '65560',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СВЕТЛИНА',
    nameEn: 'SVETLINA',
    municipality: 'СРЕДЕЦ',
    municipalityEn: 'SREDETS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8322',
    addressNomenclature: '0',
    x: '27.103849',
    y: '42.372068',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '65574',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СВЕТЛИНА',
    nameEn: 'SVETLINA',
    municipality: 'ДИМИТРОВГРАД',
    municipalityEn: 'DIMITROVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6420',
    addressNomenclature: '0',
    x: '25.3797',
    y: '42.004058',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '65588',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СВЕТЛИНА',
    nameEn: 'SVETLINA',
    municipality: 'ТОПОЛОВГРАД',
    municipalityEn: 'TOPOLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6547',
    addressNomenclature: '0',
    x: '26.183477',
    y: '42.156698',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '65591',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СВЕТЛЯ',
    nameEn: 'SVETLYA',
    municipality: 'КОВАЧЕВЦИ',
    municipalityEn: 'KOVACHEVTSI',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2449',
    addressNomenclature: '0',
    x: '22.799029',
    y: '42.593337',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '65601',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СВЕТОВРАЧЕНЕ',
    nameEn: 'SVETOVRACHENE',
    municipality: 'СТОЛИЧНА',
    municipalityEn: 'STOLICHNA',
    region: 'СОФИЯ (СТОЛИЦА)',
    regionEn: 'SOFIA (STOLITSA)',
    postCode: '1252',
    addressNomenclature: '2',
    x: '23.38755',
    y: '42.782652',
    servingDays: '1111100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '65615',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СВЕТОСЛАВ',
    nameEn: 'SVETOSLAV',
    municipality: 'КАЙНАРДЖА',
    municipalityEn: 'KAYNARDZHA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7554',
    addressNomenclature: '0',
    x: '27.510026',
    y: '43.953758',
    servingDays: '0101000',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '65629',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СВЕТОСЛАВ',
    nameEn: 'SVETOSLAV',
    municipality: 'СТАМБОЛОВО',
    municipalityEn: 'STAMBOLOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6378',
    addressNomenclature: '0',
    x: '25.644038',
    y: '41.652351',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '65632',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СВЕТОСЛАВЦИ',
    nameEn: 'SVETOSLAVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5080',
    addressNomenclature: '0',
    x: '26.079672',
    y: '42.916786',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '65646',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СВЕТУЛКА',
    nameEn: 'SVETULKA',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6768',
    addressNomenclature: '0',
    x: '25.100573',
    y: '41.564374',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '65650',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СВЕЩАРИ',
    nameEn: 'SVESHTARI',
    municipality: 'ИСПЕРИХ',
    municipalityEn: 'ISPERIH',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7423',
    addressNomenclature: '0',
    x: '26.785923',
    y: '43.752293',
    servingDays: '0101000',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '65663',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СВИДНЯ',
    nameEn: 'SVIDNYA',
    municipality: 'СВОГЕ',
    municipalityEn: 'SVOGE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2266',
    addressNomenclature: '0',
    x: '23.295221',
    y: '42.968991',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '65677',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'СВИЛЕНГРАД',
    nameEn: 'SVILENGRAD',
    municipality: 'СВИЛЕНГРАД',
    municipalityEn: 'SVILENGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6500',
    addressNomenclature: '2',
    x: '26.206474',
    y: '41.768406',
    servingDays: '1111110',
    servingOfficeId: '48',
    servingHubOfficeId: '5',
  },
  {
    id: '65680',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СВИНАРСКИ ДОЛ',
    nameEn: 'SVINARSKI DOL',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5300',
    addressNomenclature: '0',
    x: '25.286123',
    y: '42.926352',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '65704',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СВИРАЧИ',
    nameEn: 'SVIRACHI',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6578',
    addressNomenclature: '0',
    x: '26.11678',
    y: '41.477801',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '65721',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СВИРКОВО',
    nameEn: 'SVIRKOVO',
    municipality: 'СИМЕОНОВГРАД',
    municipalityEn: 'SIMEONOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6499',
    addressNomenclature: '0',
    x: '25.919943',
    y: '42.029292',
    servingDays: '0101000',
    servingOfficeId: '42',
    servingHubOfficeId: '5',
  },
  {
    id: '65735',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СВИРЦИ',
    nameEn: 'SVIRTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5367',
    addressNomenclature: '0',
    x: '25.565951',
    y: '42.82198',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '65752',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СВИРЧОВО',
    nameEn: 'SVIRCHOVO',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7960',
    addressNomenclature: '0',
    x: '26.221691',
    y: '43.024368',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '65766',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'СВИЩОВ',
    nameEn: 'SVISHTOV',
    municipality: 'СВИЩОВ',
    municipalityEn: 'SVISHTOV',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5250',
    addressNomenclature: '2',
    x: '25.349816',
    y: '43.615983',
    servingDays: '1111110',
    servingOfficeId: '250',
    servingHubOfficeId: '11',
  },
  {
    id: '65783',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СВОБОДА',
    nameEn: 'SVOBODA',
    municipality: 'КАМЕНО',
    municipalityEn: 'KAMENO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8103',
    addressNomenclature: '0',
    x: '27.317391',
    y: '42.570492',
    servingDays: '1111110',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '65797',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СВОБОДА',
    nameEn: 'SVOBODA',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6800',
    addressNomenclature: '0',
    x: '25.418776',
    y: '41.575226',
    servingDays: '1111110',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '65807',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СВОБОДА',
    nameEn: 'SVOBODA',
    municipality: 'СТРЕЛЧА',
    municipalityEn: 'STRELCHA',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4532',
    addressNomenclature: '0',
    x: '24.336941',
    y: '42.39442',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '65810',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СВОБОДА',
    nameEn: 'SVOBODA',
    municipality: 'ЧИРПАН',
    municipalityEn: 'CHIRPAN',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6230',
    addressNomenclature: '0',
    x: '25.425292',
    y: '42.229703',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '65824',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СВОБОДА',
    nameEn: 'SVOBODA',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9398',
    addressNomenclature: '0',
    x: '27.8152',
    y: '43.749232',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '65838',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СВОБОДЕН',
    nameEn: 'SVOBODEN',
    municipality: 'РАДНЕВО',
    municipalityEn: 'RADNEVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6278',
    addressNomenclature: '0',
    x: '25.815196',
    y: '42.248599',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '65841',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СВОБОДИНОВО',
    nameEn: 'SVOBODINOVO',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6706',
    addressNomenclature: '0',
    x: '25.335417',
    y: '41.720689',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '65855',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СВОБОДИЦА',
    nameEn: 'SVOBODITSA',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7997',
    addressNomenclature: '0',
    x: '26.184608',
    y: '43.076853',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '65869',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'СВОГЕ',
    nameEn: 'SVOGE',
    municipality: 'СВОГЕ',
    municipalityEn: 'SVOGE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2260',
    addressNomenclature: '2',
    x: '23.352822',
    y: '42.959756',
    servingDays: '1111100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '65872',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СВОДЕ',
    nameEn: 'SVODE',
    municipality: 'ПРАВЕЦ',
    municipalityEn: 'PRAVETS',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2171',
    addressNomenclature: '0',
    x: '23.877537',
    y: '43.015947',
    servingDays: '1010100',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '65886',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕВАР',
    nameEn: 'SEVAR',
    municipality: 'КУБРАТ',
    municipalityEn: 'KUBRAT',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7332',
    addressNomenclature: '0',
    x: '26.590043',
    y: '43.833255',
    servingDays: '1010100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '65890',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕВДАЛИНА',
    nameEn: 'SEVDALINA',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6745',
    addressNomenclature: '0',
    x: '25.331045',
    y: '41.709834',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '65906',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕВЕРНЯК',
    nameEn: 'SEVERNYAK',
    municipality: 'КРУШАРИ',
    municipalityEn: 'KRUSHARI',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9405',
    addressNomenclature: '0',
    x: '27.785077',
    y: '43.947757',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '65913',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕВЕРЦИ',
    nameEn: 'SEVERTSI',
    municipality: 'КРУШАРИ',
    municipalityEn: 'KRUSHARI',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9415',
    addressNomenclature: '0',
    x: '27.697152',
    y: '43.798859',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '65927',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'СЕВЛИЕВО',
    nameEn: 'SEVLIEVO',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5400',
    addressNomenclature: '2',
    x: '25.092432',
    y: '43.028661',
    servingDays: '1111110',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '65930',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕДЕЛЕЦ',
    nameEn: 'SEDELETS',
    municipality: 'СТРУМЯНИ',
    municipalityEn: 'STRUMYANI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2834',
    addressNomenclature: '0',
    x: '23.095616',
    y: '41.588343',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '65944',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕДЕФЧЕ',
    nameEn: 'SEDEFCHE',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6827',
    addressNomenclature: '0',
    x: '25.531009',
    y: '41.425798',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '65958',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕДЛАРЕВО',
    nameEn: 'SEDLAREVO',
    municipality: 'КОТЕЛ',
    municipalityEn: 'KOTEL',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8994',
    addressNomenclature: '0',
    x: '26.584087',
    y: '42.688996',
    servingDays: '1010100',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '65961',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕДЛАРИ',
    nameEn: 'SEDLARI',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6800',
    addressNomenclature: '0',
    x: '25.379725',
    y: '41.520967',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '70771',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕДЛАРЦИ',
    nameEn: 'SEDLARTSI',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6789',
    addressNomenclature: '0',
    x: '25.042893',
    y: '41.543218',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '65975',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕДЛОВИНА',
    nameEn: 'SEDLOVINA',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6621',
    addressNomenclature: '0',
    x: '25.426029',
    y: '41.632803',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '65989',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕДЯНКОВЦИ',
    nameEn: 'SEDYANKOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5300',
    addressNomenclature: '0',
    x: '25.302685',
    y: '42.925586',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '65992',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕЙДОЛ',
    nameEn: 'SEYDOL',
    municipality: 'ЛОЗНИЦА',
    municipalityEn: 'LOZNITSA',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7282',
    addressNomenclature: '0',
    x: '26.51043',
    y: '43.402255',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '66007',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕЙКОВЦИ',
    nameEn: 'SEYKOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5344',
    addressNomenclature: '0',
    x: '25.273764',
    y: '42.948782',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '66010',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕЙМЕНИТЕ',
    nameEn: 'SEYMENITE',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5041',
    addressNomenclature: '0',
    x: '25.598431',
    y: '42.82328',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '66024',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕКИРКА',
    nameEn: 'SEKIRKA',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6880',
    addressNomenclature: '0',
    x: '25.43668',
    y: '41.392181',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '66038',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕКУЛОВО',
    nameEn: 'SEKULOVO',
    municipality: 'ДУЛОВО',
    municipalityEn: 'DULOVO',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7665',
    addressNomenclature: '0',
    x: '27.161469',
    y: '43.699264',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '70723',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕЛАНОВЦИ',
    nameEn: 'SELANOVTSI',
    municipality: 'ОРЯХОВО',
    municipalityEn: 'ORYAHOVO',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3301',
    addressNomenclature: '0',
    x: '24.006175',
    y: '43.673829',
    servingDays: '1111100',
    servingOfficeId: '64',
    servingHubOfficeId: '20',
  },
  {
    id: '66041',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕЛИМИНОВО',
    nameEn: 'SELIMINOVO',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8862',
    addressNomenclature: '0',
    x: '26.152112',
    y: '42.647091',
    servingDays: '0101000',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '66055',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕЛИЩЕ',
    nameEn: 'SELISHTE',
    municipality: 'БЛАГОЕВГРАД',
    municipalityEn: 'BLAGOEVGRAD',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2744',
    addressNomenclature: '0',
    x: '23.000802',
    y: '41.998328',
    servingDays: '1111110',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '66069',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕЛИЩЕ',
    nameEn: 'SELISHTE',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4735',
    addressNomenclature: '0',
    x: '24.86356',
    y: '41.579635',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '70799',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕЛИЩЕ',
    nameEn: 'SELISHTE',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5462',
    addressNomenclature: '0',
    x: '25.054169',
    y: '42.854338',
    servingDays: '1010100',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '66072',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕЛИЩЕН ДОЛ',
    nameEn: 'SELISHTEN DOL',
    municipality: 'ПЕРНИК',
    municipalityEn: 'PERNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2377',
    addressNomenclature: '0',
    x: '22.912247',
    y: '42.655206',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '66086',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕЛСКА ПОЛЯНА',
    nameEn: 'SELSKA POLYANA',
    municipality: 'МАДЖАРОВО',
    municipalityEn: 'MADZHAROVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6487',
    addressNomenclature: '0',
    x: '25.835687',
    y: '41.709819',
    servingDays: '0101000',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '66098',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕЛЦЕ',
    nameEn: 'SELTSE',
    municipality: 'ТРОЯН',
    municipalityEn: 'TROYAN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '',
    addressNomenclature: '0',
    x: '24.880234',
    y: '42.924637',
    servingDays: '1010100',
    servingOfficeId: '44',
    servingHubOfficeId: '11',
  },
  {
    id: '66103',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕЛЦЕ',
    nameEn: 'SELTSE',
    municipality: 'МЪГЛИЖ',
    municipalityEn: 'MAGLIZH',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6184',
    addressNomenclature: '0',
    x: '25.53703',
    y: '42.685454',
    servingDays: '0101000',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '66113',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕЛЦЕ',
    nameEn: 'SELTSE',
    municipality: 'КАВАРНА',
    municipalityEn: 'KAVARNA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9655',
    addressNomenclature: '0',
    x: '28.290844',
    y: '43.453666',
    servingDays: '1111100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '66127',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕЛЦИ',
    nameEn: 'SELTSI',
    municipality: 'САДОВО',
    municipalityEn: 'SADOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4129',
    addressNomenclature: '0',
    x: '25.040848',
    y: '42.127988',
    servingDays: '1010100',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '66130',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕЛЧА',
    nameEn: 'SELCHA',
    municipality: 'ДЕВИН',
    municipalityEn: 'DEVIN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4814',
    addressNomenclature: '0',
    x: '24.387413',
    y: '41.839668',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '66144',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕЛЯНИН',
    nameEn: 'SELYANIN',
    municipality: 'ИХТИМАН',
    municipalityEn: 'IHTIMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2060',
    addressNomenclature: '0',
    x: '23.724462',
    y: '42.570523',
    servingDays: '0101000',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '66158',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕМЕРДЖИЕВО',
    nameEn: 'SEMERDZHIEVO',
    municipality: 'РУСЕ',
    municipalityEn: 'RUSE',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7072',
    addressNomenclature: '0',
    x: '26.168122',
    y: '43.768922',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '66161',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕМЕРДЖИИТЕ',
    nameEn: 'SEMERDZHIITE',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5340',
    addressNomenclature: '0',
    x: '25.391906',
    y: '42.894057',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '66175',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕМЕРЦИ',
    nameEn: 'SEMERTSI',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7970',
    addressNomenclature: '0',
    x: '26.17419',
    y: '43.170843',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '66189',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕМКОВЦИ',
    nameEn: 'SEMKOVTSI',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5035',
    addressNomenclature: '0',
    x: '25.652337',
    y: '42.838364',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '66202',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕМЧИНОВО',
    nameEn: 'SEMCHINOVO',
    municipality: 'СЕПТЕМВРИ',
    municipalityEn: 'SEPTEMVRI',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4474',
    addressNomenclature: '0',
    x: '24.097229',
    y: '42.178231',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '66216',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕННИК',
    nameEn: 'SENNIK',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5450',
    addressNomenclature: '0',
    x: '25.03564',
    y: '42.988922',
    servingDays: '1010100',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '66229',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'СЕНОВО',
    nameEn: 'SENOVO',
    municipality: 'ВЕТОВО',
    municipalityEn: 'VETOVO',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7038',
    addressNomenclature: '0',
    x: '26.363013',
    y: '43.647248',
    servingDays: '1111100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '66233',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕНОКЛАС',
    nameEn: 'SENOKLAS',
    municipality: 'МАДЖАРОВО',
    municipalityEn: 'MADZHAROVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6481',
    addressNomenclature: '0',
    x: '25.951036',
    y: '41.612493',
    servingDays: '0101000',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '66247',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕНОКОС',
    nameEn: 'SENOKOS',
    municipality: 'СИМИТЛИ',
    municipalityEn: 'SIMITLI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2752',
    addressNomenclature: '0',
    x: '23.228899',
    y: '41.832434',
    servingDays: '1111110',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '66250',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕНОКОС',
    nameEn: 'SENOKOS',
    municipality: 'БАЛЧИК',
    municipalityEn: 'BALCHIK',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9634',
    addressNomenclature: '0',
    x: '28.025241',
    y: '43.525266',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '70785',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕНЦЕ',
    nameEn: 'SENTSE',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6800',
    addressNomenclature: '0',
    x: '25.462856',
    y: '41.450012',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '66264',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'СЕПТЕМВРИ',
    nameEn: 'SEPTEMVRI',
    municipality: 'СЕПТЕМВРИ',
    municipalityEn: 'SEPTEMVRI',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4490',
    addressNomenclature: '2',
    x: '24.125011',
    y: '42.216436',
    servingDays: '1111100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '16184',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕПТЕМВРИЙЦИ',
    nameEn: 'SEPTEMVRIYTSI',
    municipality: 'ВЪЛЧЕДРЪМ',
    municipalityEn: 'VALCHEDRAM',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3637',
    addressNomenclature: '0',
    x: '23.511452',
    y: '43.612875',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '66278',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕПТЕМВРИЙЦИ',
    nameEn: 'SEPTEMVRIYTSI',
    municipality: 'ДИМОВО',
    municipalityEn: 'DIMOVO',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3796',
    addressNomenclature: '0',
    x: '22.94614',
    y: '43.76251',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '66281',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕПТЕМВРИЙЦИ',
    nameEn: 'SEPTEMVRIYTSI',
    municipality: 'КАВАРНА',
    municipalityEn: 'KAVARNA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9693',
    addressNomenclature: '0',
    x: '28.362628',
    y: '43.60832',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '66295',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕСЛАВ',
    nameEn: 'SESLAV',
    municipality: 'КУБРАТ',
    municipalityEn: 'KUBRAT',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7341',
    addressNomenclature: '0',
    x: '26.374165',
    y: '43.865999',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '66319',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕСТРИМО',
    nameEn: 'SESTRIMO',
    municipality: 'БЕЛОВО',
    municipalityEn: 'BELOVO',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4469',
    addressNomenclature: '0',
    x: '23.92141',
    y: '42.216602',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '66336',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕСТРИНСКО',
    nameEn: 'SESTRINSKO',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6660',
    addressNomenclature: '0',
    x: '25.623684',
    y: '41.715347',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '66344',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕЧЕН КАМЪК',
    nameEn: 'SECHEN KAMAK',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5360',
    addressNomenclature: '0',
    x: '25.456982',
    y: '42.819045',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '66353',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЕЧИЩЕ',
    nameEn: 'SECHISHTE',
    municipality: 'НОВИ ПАЗАР',
    municipalityEn: 'NOVI PAZAR',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9923',
    addressNomenclature: '0',
    x: '27.267611',
    y: '43.407981',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '66398',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СИВ КЛАДЕНЕЦ',
    nameEn: 'SIV KLADENETS',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6585',
    addressNomenclature: '0',
    x: '26.157916',
    y: '41.409834',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '66370',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СИВА РЕКА',
    nameEn: 'SIVA REKA',
    municipality: 'СВИЛЕНГРАД',
    municipalityEn: 'SVILENGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6522',
    addressNomenclature: '0',
    x: '26.07682',
    y: '41.779257',
    servingDays: '1010100',
    servingOfficeId: '48',
    servingHubOfficeId: '5',
  },
  {
    id: '66384',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СИВИНО',
    nameEn: 'SIVINO',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4780',
    addressNomenclature: '0',
    x: '24.690224',
    y: '41.449666',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '66408',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СИГМЕН',
    nameEn: 'SIGMEN',
    municipality: 'КАРНОБАТ',
    municipalityEn: 'KARNOBAT',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8438',
    addressNomenclature: '0',
    x: '26.972586',
    y: '42.707612',
    servingDays: '0101000',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '66411',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СИЛЕН',
    nameEn: 'SILEN',
    municipality: 'СТАМБОЛОВО',
    municipalityEn: 'STAMBOLOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6380',
    addressNomenclature: '0',
    x: '25.711408',
    y: '41.683309',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '66425',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'СИЛИСТРА',
    nameEn: 'SILISTRA',
    municipality: 'СИЛИСТРА',
    municipalityEn: 'SILISTRA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7500',
    addressNomenclature: '1',
    x: '27.266524',
    y: '44.114539',
    servingDays: '1111110',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '47278',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'СИМЕОНОВГРАД',
    nameEn: 'SIMEONOVGRAD',
    municipality: 'СИМЕОНОВГРАД',
    municipalityEn: 'SIMEONOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6490',
    addressNomenclature: '2',
    x: '25.834494',
    y: '42.032345',
    servingDays: '1111100',
    servingOfficeId: '42',
    servingHubOfficeId: '5',
  },
  {
    id: '66439',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СИМЕОНОВЕЦ',
    nameEn: 'SIMEONOVETS',
    municipality: 'СЕПТЕМВРИ',
    municipalityEn: 'SEPTEMVRI',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4475',
    addressNomenclature: '0',
    x: '24.10615',
    y: '42.17169',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '66456',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СИМЕОНОВО',
    nameEn: 'SIMEONOVO',
    municipality: 'ТУНДЖА',
    municipalityEn: 'TUNDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8665',
    addressNomenclature: '0',
    x: '26.638642',
    y: '42.377038',
    servingDays: '0101000',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '66460',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'СИМИТЛИ',
    nameEn: 'SIMITLI',
    municipality: 'СИМИТЛИ',
    municipalityEn: 'SIMITLI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2730',
    addressNomenclature: '2',
    x: '23.11308',
    y: '41.892646',
    servingDays: '1111110',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '66473',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СИНАГОВЦИ',
    nameEn: 'SINAGOVTSI',
    municipality: 'ВИДИН',
    municipalityEn: 'VIDIN',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3737',
    addressNomenclature: '0',
    x: '22.76425',
    y: '43.898256',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '66487',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СИНАПОВО',
    nameEn: 'SINAPOVO',
    municipality: 'ТОПОЛОВГРАД',
    municipalityEn: 'TOPOLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6567',
    addressNomenclature: '0',
    x: '26.465308',
    y: '42.121921',
    servingDays: '1010100',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '66490',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СИНДЕЛ',
    nameEn: 'SINDEL',
    municipality: 'АВРЕН',
    municipalityEn: 'AVREN',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9125',
    addressNomenclature: '0',
    x: '27.605259',
    y: '43.110165',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '66500',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СИНДЕЛЦИ',
    nameEn: 'SINDELTSI',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6825',
    addressNomenclature: '0',
    x: '25.573805',
    y: '41.464459',
    servingDays: '1111100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '66528',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СИНЕМОРЕЦ',
    nameEn: 'SINEMORETS',
    municipality: 'ЦАРЕВО',
    municipalityEn: 'TSAREVO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8279',
    addressNomenclature: '0',
    x: '27.976097',
    y: '42.060683',
    servingDays: '1111100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '66531',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СИНИ ВИР',
    nameEn: 'SINI VIR',
    municipality: 'КАОЛИНОВО',
    municipalityEn: 'KAOLINOVO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9950',
    addressNomenclature: '0',
    x: '27.052682',
    y: '43.539071',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '70737',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СИНИ ВРЪХ',
    nameEn: 'SINI VRAH',
    municipality: 'АСЕНОВГРАД',
    municipalityEn: 'ASENOVGRAD',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4236',
    addressNomenclature: '0',
    x: '24.980266',
    y: '41.8498',
    servingDays: '0101000',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '80296',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СИНИ РИД',
    nameEn: 'SINI RID',
    municipality: 'РУЕН',
    municipalityEn: 'RUEN',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8534',
    addressNomenclature: '0',
    x: '27.432978',
    y: '42.823563',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '66545',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СИНИГЕР',
    nameEn: 'SINIGER',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6956',
    addressNomenclature: '0',
    x: '25.761968',
    y: '41.38656',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '66559',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СИНИТОВО',
    nameEn: 'SINITOVO',
    municipality: 'ПАЗАРДЖИК',
    municipalityEn: 'PAZARDZHIK',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4442',
    addressNomenclature: '0',
    x: '24.375349',
    y: '42.148688',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '66562',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СИНЧЕЦ',
    nameEn: 'SINCHETS',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6785',
    addressNomenclature: '0',
    x: '25.178981',
    y: '41.556463',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '66576',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СИНЬО БЪРДО',
    nameEn: 'SINYO BARDO',
    municipality: 'РОМАН',
    municipalityEn: 'ROMAN',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3139',
    addressNomenclature: '0',
    x: '23.829317',
    y: '43.109195',
    servingDays: '0101000',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '66585',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СИНЬО КАМЕНЕ',
    nameEn: 'SINYO KAMENE',
    municipality: 'СРЕДЕЦ',
    municipalityEn: 'SREDETS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8332',
    addressNomenclature: '0',
    x: '26.982815',
    y: '42.25889',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '66593',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СИНЯ ВОДА',
    nameEn: 'SINYA VODA',
    municipality: 'ЛОЗНИЦА',
    municipalityEn: 'LOZNITSA',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7287',
    addressNomenclature: '0',
    x: '26.56882',
    y: '43.405464',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '66603',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СИПЕЙ',
    nameEn: 'SIPEY',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6629',
    addressNomenclature: '0',
    x: '25.387673',
    y: '41.645285',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '70809',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СИПЕЦ',
    nameEn: 'SIPETS',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6840',
    addressNomenclature: '0',
    x: '25.25608',
    y: '41.516792',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '66617',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СИРАКОВО',
    nameEn: 'SIRAKOVO',
    municipality: 'БОРОВАН',
    municipalityEn: 'BOROVAN',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3254',
    addressNomenclature: '0',
    x: '23.759738',
    y: '43.516565',
    servingDays: '1010100',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '66620',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СИРАКОВО',
    nameEn: 'SIRAKOVO',
    municipality: 'ГЕНЕРАЛ ТОШЕВО',
    municipalityEn: 'GENERAL TOSHEVO',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9564',
    addressNomenclature: '0',
    x: '28.270502',
    y: '43.653274',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '66634',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СИРАКОВО',
    nameEn: 'SIRAKOVO',
    municipality: 'МИНЕРАЛНИ БАНИ',
    municipalityEn: 'MINERALNI BANI',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6359',
    addressNomenclature: '0',
    x: '25.370993',
    y: '41.885569',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '66648',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СИРИЩНИК',
    nameEn: 'SIRISHTNIK',
    municipality: 'КОВАЧЕВЦИ',
    municipalityEn: 'KOVACHEVTSI',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2450',
    addressNomenclature: '0',
    x: '22.80779',
    y: '42.56852',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '66651',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СИТОВО',
    nameEn: 'SITOVO',
    municipality: 'РОДОПИ',
    municipalityEn: 'RODOPI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4104',
    addressNomenclature: '0',
    x: '24.615238',
    y: '41.926552',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '66665',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СИТОВО',
    nameEn: 'SITOVO',
    municipality: 'СИТОВО',
    municipalityEn: 'SITOVO',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7583',
    addressNomenclature: '2',
    x: '27.022917',
    y: '44.025235',
    servingDays: '1010100',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '66679',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СИТОВО',
    nameEn: 'SITOVO',
    municipality: 'БОЛЯРОВО',
    municipalityEn: 'BOLYAROVO',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8744',
    addressNomenclature: '0',
    x: '26.768829',
    y: '42.061993',
    servingDays: '1010100',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '66682',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СКАЛА',
    nameEn: 'SKALA',
    municipality: 'СУНГУРЛАРЕ',
    municipalityEn: 'SUNGURLARE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8462',
    addressNomenclature: '0',
    x: '26.685625',
    y: '42.772157',
    servingDays: '0101000',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '66696',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СКАЛА',
    nameEn: 'SKALA',
    municipality: 'ДУЛОВО',
    municipalityEn: 'DULOVO',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7667',
    addressNomenclature: '0',
    x: '27.191273',
    y: '43.689041',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '66706',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СКАЛАК',
    nameEn: 'SKALAK',
    municipality: 'РУЕН',
    municipalityEn: 'RUEN',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8575',
    addressNomenclature: '0',
    x: '27.08845',
    y: '42.806363',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '66716',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СКАЛАК',
    nameEn: 'SKALAK',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6923',
    addressNomenclature: '0',
    x: '25.635104',
    y: '41.434075',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '66723',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СКАЛИНА',
    nameEn: 'SKALINA',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6846',
    addressNomenclature: '0',
    x: '25.308437',
    y: '41.466647',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '66737',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СКАЛИЦА',
    nameEn: 'SKALITSA',
    municipality: 'ТУНДЖА',
    municipalityEn: 'TUNDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8645',
    addressNomenclature: '0',
    x: '26.254612',
    y: '42.266099',
    servingDays: '0101000',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '66740',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СКАЛИЩЕ',
    nameEn: 'SKALISHTE',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6652',
    addressNomenclature: '0',
    x: '25.462296',
    y: '41.659351',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '66754',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СКАЛНА ГЛАВА',
    nameEn: 'SKALNA GLAVA',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6748',
    addressNomenclature: '0',
    x: '25.308935',
    y: '41.688618',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '66768',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СКАЛСКО',
    nameEn: 'SKALSKO',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5386',
    addressNomenclature: '0',
    x: '25.376667',
    y: '42.974107',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '66771',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СКАНДАЛОТО',
    nameEn: 'SKANDALOTO',
    municipality: 'АПРИЛЦИ',
    municipalityEn: 'APRILTSI',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5644',
    addressNomenclature: '0',
    x: '24.866443',
    y: '42.879287',
    servingDays: '1010100',
    servingOfficeId: '44',
    servingHubOfficeId: '11',
  },
  {
    id: '66785',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СКЛАВЕ',
    nameEn: 'SKLAVE',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2811',
    addressNomenclature: '0',
    x: '23.31721',
    y: '41.520095',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '66799',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СКОБЕЛЕВО',
    nameEn: 'SKOBELEVO',
    municipality: 'ЛОВЕЧ',
    municipalityEn: 'LOVECH',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5508',
    addressNomenclature: '0',
    x: '24.65813',
    y: '43.155608',
    servingDays: '1010100',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '66809',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СКОБЕЛЕВО',
    nameEn: 'SKOBELEVO',
    municipality: 'РОДОПИ',
    municipalityEn: 'RODOPI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4227',
    addressNomenclature: '0',
    x: '24.537943',
    y: '41.996305',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '66812',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СКОБЕЛЕВО',
    nameEn: 'SKOBELEVO',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8871',
    addressNomenclature: '0',
    x: '26.310325',
    y: '42.487929',
    servingDays: '1010100',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '66826',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СКОБЕЛЕВО',
    nameEn: 'SKOBELEVO',
    municipality: 'ПАВЕЛ БАНЯ',
    municipalityEn: 'PAVEL BANYA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6148',
    addressNomenclature: '0',
    x: '25.196388',
    y: '42.678689',
    servingDays: '1111100',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '66831',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СКОБЕЛЕВО',
    nameEn: 'SKOBELEVO',
    municipality: 'ДИМИТРОВГРАД',
    municipalityEn: 'DIMITROVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6429',
    addressNomenclature: '0',
    x: '25.365256',
    y: '42.095683',
    servingDays: '0101000',
    servingOfficeId: '42',
    servingHubOfficeId: '5',
  },
  {
    id: '66843',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СКОМЛЯ',
    nameEn: 'SKOMLYA',
    municipality: 'ДИМОВО',
    municipalityEn: 'DIMOVO',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3946',
    addressNomenclature: '0',
    x: '22.76549',
    y: '43.680719',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '66857',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СКОРЦИТЕ',
    nameEn: 'SKORTSITE',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5350',
    addressNomenclature: '0',
    x: '25.459067',
    y: '42.89608',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '66860',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СКРАВЕНА',
    nameEn: 'SKRAVENA',
    municipality: 'БОТЕВГРАД',
    municipalityEn: 'BOTEVGRAD',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2150',
    addressNomenclature: '0',
    x: '23.765697',
    y: '42.950188',
    servingDays: '0101000',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '66874',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СКРЕБАТНО',
    nameEn: 'SKREBATNO',
    municipality: 'ГЪРМЕН',
    municipalityEn: 'GARMEN',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2964',
    addressNomenclature: '0',
    x: '23.797106',
    y: '41.660049',
    servingDays: '1010100',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '66888',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СКРИНО',
    nameEn: 'SKRINO',
    municipality: 'БОБОШЕВО',
    municipalityEn: 'BOBOSHEVO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2663',
    addressNomenclature: '0',
    x: '22.955706',
    y: '42.181411',
    servingDays: '1111110',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '66891',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СКРИНЯНО',
    nameEn: 'SKRINYANO',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2599',
    addressNomenclature: '0',
    x: '22.698525',
    y: '42.318156',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '66901',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СКРЪТ',
    nameEn: 'SKRAT',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2897',
    addressNomenclature: '0',
    x: '22.995814',
    y: '41.365191',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '66915',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СКУТАРЕ',
    nameEn: 'SKUTARE',
    municipality: 'МАРИЦА',
    municipalityEn: 'MARITSA',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4134',
    addressNomenclature: '0',
    x: '24.840901',
    y: '42.186928',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '66929',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СКЪРБИНО',
    nameEn: 'SKARBINO',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6720',
    addressNomenclature: '0',
    x: '25.385475',
    y: '41.665087',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '66932',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛАВЕЕВО',
    nameEn: 'SLAVEEVO',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6576',
    addressNomenclature: '0',
    x: '26.163866',
    y: '41.539636',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '66946',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛАВЕЕВО',
    nameEn: 'SLAVEEVO',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9366',
    addressNomenclature: '0',
    x: '27.914893',
    y: '43.47701',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '66963',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛАВЕЙКОВО',
    nameEn: 'SLAVEYKOVO',
    municipality: 'ПРОВАДИЯ',
    municipalityEn: 'PROVADIA',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9247',
    addressNomenclature: '0',
    x: '27.238587',
    y: '43.111568',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '66977',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛАВЕЙКОВО',
    nameEn: 'SLAVEYKOVO',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5391',
    addressNomenclature: '0',
    x: '25.359589',
    y: '42.994891',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '66980',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛАВЕЙКОВО',
    nameEn: 'SLAVEYKOVO',
    municipality: 'ЕЛХОВО',
    municipalityEn: 'ELHOVO',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8738',
    addressNomenclature: '0',
    x: '26.697019',
    y: '42.121222',
    servingDays: '1010100',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '66957',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛАВЕЙНО',
    nameEn: 'SLAVEYNO',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4747',
    addressNomenclature: '0',
    x: '24.863095',
    y: '41.631072',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '67009',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛАВОВИЦА',
    nameEn: 'SLAVOVITSA',
    municipality: 'СЕПТЕМВРИ',
    municipalityEn: 'SEPTEMVRI',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4496',
    addressNomenclature: '0',
    x: '24.034745',
    y: '42.322375',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '67012',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛАВОВИЦА',
    nameEn: 'SLAVOVITSA',
    municipality: 'ДОЛНА МИТРОПОЛИЯ',
    municipalityEn: 'DOLNA MITROPOLIA',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5858',
    addressNomenclature: '0',
    x: '24.450045',
    y: '43.616534',
    servingDays: '0101000',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '67043',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛАВОТИН',
    nameEn: 'SLAVOTIN',
    municipality: 'МОНТАНА',
    municipalityEn: 'MONTANA',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3451',
    addressNomenclature: '0',
    x: '23.067869',
    y: '43.561125',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '67057',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛАВЩИЦА',
    nameEn: 'SLAVSHTITSA',
    municipality: 'УГЪРЧИН',
    municipalityEn: 'UGARCHIN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5522',
    addressNomenclature: '0',
    x: '24.34198',
    y: '43.054466',
    servingDays: '1010100',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '67060',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛАВЯНИ',
    nameEn: 'SLAVYANI',
    municipality: 'ЛОВЕЧ',
    municipalityEn: 'LOVECH',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5565',
    addressNomenclature: '0',
    x: '24.683144',
    y: '43.222414',
    servingDays: '0101000',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '67074',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛАВЯНИН',
    nameEn: 'SLAVYANIN',
    municipality: 'БРАТЯ ДАСКАЛОВИ',
    municipalityEn: 'BRATYA DASKALOVI',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6236',
    addressNomenclature: '0',
    x: '25.18946',
    y: '42.439309',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '67088',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'СЛАВЯНОВО',
    nameEn: 'SLAVYANOVO',
    municipality: 'ПЛЕВЕН',
    municipalityEn: 'PLEVEN',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5840',
    addressNomenclature: '2',
    x: '24.872197',
    y: '43.465194',
    servingDays: '1111100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '67091',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛАВЯНОВО',
    nameEn: 'SLAVYANOVO',
    municipality: 'ПОПОВО',
    municipalityEn: 'POPOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7860',
    addressNomenclature: '0',
    x: '26.180493',
    y: '43.290672',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '67101',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛАВЯНОВО',
    nameEn: 'SLAVYANOVO',
    municipality: 'ХАРМАНЛИ',
    municipalityEn: 'HARMANLI',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6473',
    addressNomenclature: '0',
    x: '25.780364',
    y: '41.807919',
    servingDays: '0101000',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '67115',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛАВЯНЦИ',
    nameEn: 'SLAVYANTSI',
    municipality: 'СУНГУРЛАРЕ',
    municipalityEn: 'SUNGURLARE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8460',
    addressNomenclature: '0',
    x: '26.728539',
    y: '42.764323',
    servingDays: '1111100',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '67129',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛАДКА ВОДА',
    nameEn: 'SLADKA VODA',
    municipality: 'ДЪЛГОПОЛ',
    municipalityEn: 'DALGOPOL',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9257',
    addressNomenclature: '0',
    x: '27.239684',
    y: '43.057657',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '67132',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛАДКОДУМ',
    nameEn: 'SLADKODUM',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6960',
    addressNomenclature: '0',
    x: '25.71941',
    y: '41.57976',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '67146',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛАДУН',
    nameEn: 'SLADUN',
    municipality: 'СВИЛЕНГРАД',
    municipalityEn: 'SVILENGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6534',
    addressNomenclature: '0',
    x: '26.458626',
    y: '41.852034',
    servingDays: '0101000',
    servingOfficeId: '48',
    servingHubOfficeId: '5',
  },
  {
    id: '67154',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛАДЪК КЛАДЕНЕЦ',
    nameEn: 'SLADAK KLADENETS',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6064',
    addressNomenclature: '0',
    x: '25.387931',
    y: '42.405486',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '67163',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛАКОВЦИ',
    nameEn: 'SLAKOVTSI',
    municipality: 'БРЕЗНИК',
    municipalityEn: 'BREZNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2369',
    addressNomenclature: '0',
    x: '22.912048',
    y: '42.69095',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '67177',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛАМИНО',
    nameEn: 'SLAMINO',
    municipality: 'ТУНДЖА',
    municipalityEn: 'TUNDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8663',
    addressNomenclature: '0',
    x: '26.667565',
    y: '42.33199',
    servingDays: '0101000',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '67180',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛАНА БАРА',
    nameEn: 'SLANA BARA',
    municipality: 'ВИДИН',
    municipalityEn: 'VIDIN',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3728',
    addressNomenclature: '0',
    x: '22.806023',
    y: '43.969753',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '67194',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛАНОТРЪН',
    nameEn: 'SLANOTRAN',
    municipality: 'ВИДИН',
    municipalityEn: 'VIDIN',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3769',
    addressNomenclature: '0',
    x: '23.014633',
    y: '44.04522',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '67204',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛАТИНА',
    nameEn: 'SLATINA',
    municipality: 'ЯБЛАНИЦА',
    municipalityEn: 'YABLANITSA',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '',
    addressNomenclature: '0',
    x: '23.974119',
    y: '43.091396',
    servingDays: '1010100',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '67218',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛАТИНА',
    nameEn: 'SLATINA',
    municipality: 'ЛОВЕЧ',
    municipalityEn: 'LOVECH',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5564',
    addressNomenclature: '0',
    x: '24.73076',
    y: '43.253946',
    servingDays: '0101000',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '67221',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛАТИНА',
    nameEn: 'SLATINA',
    municipality: 'БЕРКОВИЦА',
    municipalityEn: 'BERKOVITSA',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3535',
    addressNomenclature: '0',
    x: '23.220666',
    y: '43.216007',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '67235',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛАТИНА',
    nameEn: 'SLATINA',
    municipality: 'КАРЛОВО',
    municipalityEn: 'KARLOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4342',
    addressNomenclature: '0',
    x: '24.579859',
    y: '42.681536',
    servingDays: '0101000',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '67249',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛАТИНА',
    nameEn: 'SLATINA',
    municipality: 'СИТОВО',
    municipalityEn: 'SITOVO',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7585',
    addressNomenclature: '0',
    x: '27.040434',
    y: '43.992715',
    servingDays: '1010100',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '67252',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛАТИНО',
    nameEn: 'SLATINO',
    municipality: 'БОБОШЕВО',
    municipalityEn: 'BOBOSHEVO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2642',
    addressNomenclature: '0',
    x: '23.055575',
    y: '42.161214',
    servingDays: '1111100',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '67266',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛАТИНО',
    nameEn: 'SLATINO',
    municipality: 'КОВАЧЕВЦИ',
    municipalityEn: 'KOVACHEVTSI',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2449',
    addressNomenclature: '0',
    x: '22.841395',
    y: '42.592169',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '67270',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛАЩЕН',
    nameEn: 'SLASHTEN',
    municipality: 'САТОВЧА',
    municipalityEn: 'SATOVCHA',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2937',
    addressNomenclature: '0',
    x: '24.018203',
    y: '41.497639',
    servingDays: '1111100',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '67283',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛИВАК',
    nameEn: 'SLIVAK',
    municipality: 'ХИТРИНО',
    municipalityEn: 'HITRINO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9784',
    addressNomenclature: '0',
    x: '26.939785',
    y: '43.446943',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '67297',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛИВАРКА',
    nameEn: 'SLIVARKA',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6919',
    addressNomenclature: '0',
    x: '25.707437',
    y: '41.453829',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '67307',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛИВАРОВО',
    nameEn: 'SLIVAROVO',
    municipality: 'МАЛКО ТЪРНОВО',
    municipalityEn: 'MALKO TARNOVO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8164',
    addressNomenclature: '0',
    x: '27.664661',
    y: '41.968166',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '67310',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛИВАТА',
    nameEn: 'SLIVATA',
    municipality: 'ЛОМ',
    municipalityEn: 'LOM',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3668',
    addressNomenclature: '0',
    x: '23.03685',
    y: '43.76445',
    servingDays: '1010100',
    servingOfficeId: '831',
    servingHubOfficeId: '20',
  },
  {
    id: '67324',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛИВЕК',
    nameEn: 'SLIVEK',
    municipality: 'ЛОВЕЧ',
    municipalityEn: 'LOVECH',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5537',
    addressNomenclature: '0',
    x: '24.750053',
    y: '43.108987',
    servingDays: '0101000',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '67338',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'СЛИВЕН',
    nameEn: 'SLIVEN',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8800',
    addressNomenclature: '1',
    x: '26.311499',
    y: '42.679487',
    servingDays: '1111110',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '95042',
    countryId: '100',
    mainSiteId: '0',
    type: 'к.',
    typeEn: 'k.',
    name: 'СЛИВЕНСКИ МИНЕРАЛНИ БАНИ',
    nameEn: 'SLIVENSKI MINERALNI BANI',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8840',
    addressNomenclature: '0',
    x: '26.231509',
    y: '42.611593',
    servingDays: '1111110',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '70812',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛИВЕНЦИ',
    nameEn: 'SLIVENTSI',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9395',
    addressNomenclature: '0',
    x: '27.864247',
    y: '43.713472',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '67341',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛИВИТО',
    nameEn: 'SLIVITO',
    municipality: 'МЪГЛИЖ',
    municipalityEn: 'MAGLIZH',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6176',
    addressNomenclature: '0',
    x: '25.657471',
    y: '42.665852',
    servingDays: '0101000',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '67355',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛИВКА',
    nameEn: 'SLIVKA',
    municipality: 'БАНИТЕ',
    municipalityEn: 'BANITE',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4943',
    addressNomenclature: '0',
    x: '25.054361',
    y: '41.65129',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '67369',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛИВНИЦА',
    nameEn: 'SLIVNITSA',
    municipality: 'КРЕСНА',
    municipalityEn: 'KRESNA',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2844',
    addressNomenclature: '0',
    x: '23.165143',
    y: '41.692301',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '67372',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'СЛИВНИЦА',
    nameEn: 'SLIVNITSA',
    municipality: 'СЛИВНИЦА',
    municipalityEn: 'SLIVNITSA',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2200',
    addressNomenclature: '2',
    x: '23.038312',
    y: '42.851542',
    servingDays: '1111100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '67444',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'СЛИВО ПОЛЕ',
    nameEn: 'SLIVO POLE',
    municipality: 'СЛИВО ПОЛЕ',
    municipalityEn: 'SLIVO POLE',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7060',
    addressNomenclature: '2',
    x: '26.204191',
    y: '43.947205',
    servingDays: '1111100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '67386',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛИВОВИК',
    nameEn: 'SLIVOVIK',
    municipality: 'МЕДКОВЕЦ',
    municipalityEn: 'MEDKOVETS',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3664',
    addressNomenclature: '0',
    x: '23.116133',
    y: '43.596749',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '67395',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛИВОВИЦА',
    nameEn: 'SLIVOVITSA',
    municipality: 'ЗЛАТАРИЦА',
    municipalityEn: 'ZLATARITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5076',
    addressNomenclature: '0',
    x: '25.99608',
    y: '43.084992',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '67400',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛИВОВНИК',
    nameEn: 'SLIVOVNIK',
    municipality: 'БЕЛОГРАДЧИК',
    municipalityEn: 'BELOGRADCHIK',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3915',
    addressNomenclature: '0',
    x: '22.643071',
    y: '43.678022',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '67413',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛИВОВО',
    nameEn: 'SLIVOVO',
    municipality: 'СРЕДЕЦ',
    municipalityEn: 'SREDETS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8331',
    addressNomenclature: '0',
    x: '27.015318',
    y: '42.233634',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '67427',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛИВОВО',
    nameEn: 'SLIVOVO',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5367',
    addressNomenclature: '0',
    x: '25.534099',
    y: '42.837565',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '67430',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛИВОВО',
    nameEn: 'SLIVOVO',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4710',
    addressNomenclature: '0',
    x: '24.551928',
    y: '41.698616',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '67458',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛИШОВЦИ',
    nameEn: 'SLISHOVTSI',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2484',
    addressNomenclature: '0',
    x: '22.470202',
    y: '42.808512',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '67461',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛОКОЩИЦА',
    nameEn: 'SLOKOSHTITSA',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2533',
    addressNomenclature: '0',
    x: '22.701095',
    y: '42.263568',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '67475',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛОМЕР',
    nameEn: 'SLOMER',
    municipality: 'ПАВЛИКЕНИ',
    municipalityEn: 'PAVLIKENI',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5229',
    addressNomenclature: '0',
    x: '25.302827',
    y: '43.36469',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '98212',
    countryId: '100',
    mainSiteId: '0',
    type: 'к.к.',
    typeEn: 'k.k.',
    name: 'СЛЪНЧЕВ БРЯГ',
    nameEn: 'SLANCHEV BRYAG',
    municipality: 'НЕСЕБЪР',
    municipalityEn: 'NESEBAR',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8240',
    addressNomenclature: '1',
    x: '27.705819',
    y: '42.687767',
    servingDays: '1111110',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '67489',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛЪНЧЕВО',
    nameEn: 'SLANCHEVO',
    municipality: 'АКСАКОВО',
    municipalityEn: 'AKSAKOVO',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9144',
    addressNomenclature: '0',
    x: '27.707329',
    y: '43.250744',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '67492',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛЪНЧОВЕЦ',
    nameEn: 'SLANCHOVETS',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7963',
    addressNomenclature: '0',
    x: '26.152224',
    y: '43.030293',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '67502',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЛЪНЧОГЛЕД',
    nameEn: 'SLANCHOGLED',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6850',
    addressNomenclature: '0',
    x: '25.359768',
    y: '41.491776',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '67516',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СМИЛЕЦ',
    nameEn: 'SMILETS',
    municipality: 'СТРЕЛЧА',
    municipalityEn: 'STRELCHA',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4531',
    addressNomenclature: '0',
    x: '24.377155',
    y: '42.408175',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '67526',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СМИЛЕЦ',
    nameEn: 'SMILETS',
    municipality: 'СИЛИСТРА',
    municipalityEn: 'SILISTRA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7577',
    addressNomenclature: '0',
    x: '27.146652',
    y: '44.029204',
    servingDays: '1010100',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '67533',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СМИЛОВЦИ',
    nameEn: 'SMILOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5333',
    addressNomenclature: '0',
    x: '25.180963',
    y: '42.908843',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '67547',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СМИЛЯН',
    nameEn: 'SMILYAN',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4770',
    addressNomenclature: '0',
    x: '24.726827',
    y: '41.506215',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '67550',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СМИН',
    nameEn: 'SMIN',
    municipality: 'ШАБЛА',
    municipalityEn: 'SHABLA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9679',
    addressNomenclature: '0',
    x: '28.472053',
    y: '43.660427',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '67564',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СМИРНЕНСКИ',
    nameEn: 'SMIRNENSKI',
    municipality: 'БРУСАРЦИ',
    municipalityEn: 'BRUSARTSI',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3663',
    addressNomenclature: '0',
    x: '23.027404',
    y: '43.625668',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '67578',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СМИРНЕНСКИ',
    nameEn: 'SMIRNENSKI',
    municipality: 'ВЕТОВО',
    municipalityEn: 'VETOVO',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7074',
    addressNomenclature: '0',
    x: '26.298744',
    y: '43.76612',
    servingDays: '1111100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '67581',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СМИРНЕНЦИ',
    nameEn: 'SMIRNENTSI',
    municipality: 'ХАРМАНЛИ',
    municipalityEn: 'HARMANLI',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6467',
    addressNomenclature: '0',
    x: '25.876371',
    y: '41.805246',
    servingDays: '0101000',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '67595',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СМИРОВ ДОЛ',
    nameEn: 'SMIROV DOL',
    municipality: 'ЗЕМЕН',
    municipalityEn: 'ZEMEN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2456',
    addressNomenclature: '0',
    x: '22.727475',
    y: '42.560979',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '32603',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СМОЛЕВО',
    nameEn: 'SMOLEVO',
    municipality: 'ЯКОРУДА',
    municipalityEn: 'YAKORUDA',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2794',
    addressNomenclature: '0',
    x: '23.763766',
    y: '42.015957',
    servingDays: '1010100',
    servingOfficeId: '125',
    servingHubOfficeId: '13',
  },
  {
    id: '67619',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СМОЛИЧАНО',
    nameEn: 'SMOLICHANO',
    municipality: 'НЕВЕСТИНО',
    municipalityEn: 'NEVESTINO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2583',
    addressNomenclature: '0',
    x: '22.795594',
    y: '42.151027',
    servingDays: '1010100',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '72080',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СМОЛНИК',
    nameEn: 'SMOLNIK',
    municipality: 'КАРНОБАТ',
    municipalityEn: 'KARNOBAT',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8491',
    addressNomenclature: '0',
    x: '26.92769',
    y: '42.529704',
    servingDays: '1010100',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '67622',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СМОЛНИЦА',
    nameEn: 'SMOLNITSA',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9383',
    addressNomenclature: '0',
    x: '27.709963',
    y: '43.628764',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '67636',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СМОЛСКО',
    nameEn: 'SMOLSKO',
    municipality: 'МИРКОВО',
    municipalityEn: 'MIRKOVO',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2073',
    addressNomenclature: '0',
    x: '23.94432',
    y: '42.639276',
    servingDays: '0101000',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '67641',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СМОЛЧА',
    nameEn: 'SMOLCHA',
    municipality: 'ГОДЕЧ',
    municipalityEn: 'GODECH',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2255',
    addressNomenclature: '0',
    x: '22.980547',
    y: '43.077379',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '67653',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'СМОЛЯН',
    nameEn: 'SMOLYAN',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4700',
    addressNomenclature: '1',
    x: '24.680767',
    y: '41.583472',
    servingDays: '1111110',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '67667',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СМОЛЯНОВЦИ',
    nameEn: 'SMOLYANOVTSI',
    municipality: 'МОНТАНА',
    municipalityEn: 'MONTANA',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3450',
    addressNomenclature: '0',
    x: '22.98394',
    y: '43.498606',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '67670',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СМОЧАН',
    nameEn: 'SMOCHAN',
    municipality: 'ЛОВЕЧ',
    municipalityEn: 'LOVECH',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5547',
    addressNomenclature: '0',
    x: '24.800223',
    y: '43.183296',
    servingDays: '0101000',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '67698',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СМОЧЕВО',
    nameEn: 'SMOCHEVO',
    municipality: 'РИЛА',
    municipalityEn: 'RILA',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2641',
    addressNomenclature: '0',
    x: '23.091551',
    y: '42.146705',
    servingDays: '1111100',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '67708',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'СМЯДОВО',
    nameEn: 'SMYADOVO',
    municipality: 'СМЯДОВО',
    municipalityEn: 'SMYADOVO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9820',
    addressNomenclature: '0',
    x: '27.017596',
    y: '43.064223',
    servingDays: '1111100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '67725',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СНЕЖА',
    nameEn: 'SNEZHA',
    municipality: 'РУЕН',
    municipalityEn: 'RUEN',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8568',
    addressNomenclature: '0',
    x: '27.272455',
    y: '42.895777',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '67739',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СНЕЖИНА',
    nameEn: 'SNEZHINA',
    municipality: 'ПРОВАДИЯ',
    municipalityEn: 'PROVADIA',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9244',
    addressNomenclature: '0',
    x: '27.265184',
    y: '43.164296',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '67742',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СНЕЖИНКА',
    nameEn: 'SNEZHINKA',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6741',
    addressNomenclature: '0',
    x: '25.283811',
    y: '41.668764',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '67756',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СНОП',
    nameEn: 'SNOP',
    municipality: 'ГЕНЕРАЛ ТОШЕВО',
    municipalityEn: 'GENERAL TOSHEVO',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9557',
    addressNomenclature: '0',
    x: '27.880419',
    y: '43.796802',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '67767',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СНЯГОВО',
    nameEn: 'SNYAGOVO',
    municipality: 'РУЕН',
    municipalityEn: 'RUEN',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8579',
    addressNomenclature: '0',
    x: '27.277737',
    y: '42.846339',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '67773',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СНЯГОВО',
    nameEn: 'SNYAGOVO',
    municipality: 'ГЕНЕРАЛ ТОШЕВО',
    municipalityEn: 'GENERAL TOSHEVO',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9533',
    addressNomenclature: '0',
    x: '28.062418',
    y: '43.752698',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '67787',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СОВАТА',
    nameEn: 'SOVATA',
    municipality: 'СВИЩОВ',
    municipalityEn: 'SVISHTOV',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5283',
    addressNomenclature: '0',
    x: '25.449896',
    y: '43.548899',
    servingDays: '1010100',
    servingOfficeId: '250',
    servingHubOfficeId: '11',
  },
  {
    id: '67790',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СОВОЛЯНО',
    nameEn: 'SOVOLYANO',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2547',
    addressNomenclature: '0',
    x: '22.654796',
    y: '42.333847',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '67800',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'СОЗОПОЛ',
    nameEn: 'SOZOPOL',
    municipality: 'СОЗОПОЛ',
    municipalityEn: 'SOZOPOL',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8130',
    addressNomenclature: '2',
    x: '27.696294',
    y: '42.417316',
    servingDays: '1111110',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '67828',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СОКОЛ',
    nameEn: 'SOKOL',
    municipality: 'ГЛАВИНИЦА',
    municipalityEn: 'GLAVINITSA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7621',
    addressNomenclature: '0',
    x: '26.86238',
    y: '43.960311',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '67831',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СОКОЛ',
    nameEn: 'SOKOL',
    municipality: 'НОВА ЗАГОРА',
    municipalityEn: 'NOVA ZAGORA',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8945',
    addressNomenclature: '0',
    x: '26.095048',
    y: '42.40563',
    servingDays: '0101000',
    servingOfficeId: '56',
    servingHubOfficeId: '5',
  },
  {
    id: '67845',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СОКОЛАРЕ',
    nameEn: 'SOKOLARE',
    municipality: 'БЯЛА СЛАТИНА',
    municipalityEn: 'BYALA SLATINA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3258',
    addressNomenclature: '0',
    x: '23.867282',
    y: '43.432107',
    servingDays: '1010100',
    servingOfficeId: '59',
    servingHubOfficeId: '20',
  },
  {
    id: '67859',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СОКОЛАРЦИ',
    nameEn: 'SOKOLARTSI',
    municipality: 'КОТЕЛ',
    municipalityEn: 'KOTEL',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8984',
    addressNomenclature: '0',
    x: '26.511096',
    y: '43.000369',
    servingDays: '1010100',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '67862',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СОКОЛЕНЦИ',
    nameEn: 'SOKOLENTSI',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6595',
    addressNomenclature: '0',
    x: '25.916207',
    y: '41.484148',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '67876',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СОКОЛЕЦ',
    nameEn: 'SOKOLETS',
    municipality: 'РУЕН',
    municipalityEn: 'RUEN',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8554',
    addressNomenclature: '0',
    x: '27.179267',
    y: '42.942226',
    servingDays: '1111100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '67882',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СОКОЛИНО',
    nameEn: 'SOKOLINO',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6812',
    addressNomenclature: '0',
    x: '25.431449',
    y: '41.513135',
    servingDays: '1111110',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '67893',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СОКОЛИТЕ',
    nameEn: 'SOKOLITE',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6694',
    addressNomenclature: '0',
    x: '25.165938',
    y: '41.776078',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '67903',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СОКОЛИЦА',
    nameEn: 'SOKOLITSA',
    municipality: 'КАРЛОВО',
    municipalityEn: 'KARLOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4367',
    addressNomenclature: '0',
    x: '24.84814',
    y: '42.58506',
    servingDays: '1010100',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '67917',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СОКОЛНИК',
    nameEn: 'SOKOLNIK',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9351',
    addressNomenclature: '0',
    x: '27.884338',
    y: '43.446141',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '67920',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СОКОЛОВО',
    nameEn: 'SOKOLOVO',
    municipality: 'КАРНОБАТ',
    municipalityEn: 'KARNOBAT',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8435',
    addressNomenclature: '0',
    x: '27.071932',
    y: '42.659236',
    servingDays: '1010100',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '67934',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СОКОЛОВО',
    nameEn: 'SOKOLOVO',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5392',
    addressNomenclature: '0',
    x: '25.529242',
    y: '43.01931',
    servingDays: '1111100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '67948',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СОКОЛОВО',
    nameEn: 'SOKOLOVO',
    municipality: 'ЛОВЕЧ',
    municipalityEn: 'LOVECH',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5538',
    addressNomenclature: '0',
    x: '24.622245',
    y: '43.070774',
    servingDays: '1010100',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '67951',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СОКОЛОВО',
    nameEn: 'SOKOLOVO',
    municipality: 'БАЛЧИК',
    municipalityEn: 'BALCHIK',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9640',
    addressNomenclature: '0',
    x: '28.104569',
    y: '43.4714',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '67965',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СОКОЛОВЦИ',
    nameEn: 'SOKOLOVTSI',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4751',
    addressNomenclature: '0',
    x: '24.75844',
    y: '41.654018',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '67979',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СОКОЛСКО',
    nameEn: 'SOKOLSKO',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6648',
    addressNomenclature: '0',
    x: '25.442621',
    y: '41.610844',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '67982',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СОКОЛЯНЕ',
    nameEn: 'SOKOLYANE',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6688',
    addressNomenclature: '0',
    x: '25.437945',
    y: '41.751621',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '67996',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СОЛАРИ',
    nameEn: 'SOLARI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5300',
    addressNomenclature: '0',
    x: '25.312662',
    y: '42.941105',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '68000',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СОЛИЩА',
    nameEn: 'SOLISHTA',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4711',
    addressNomenclature: '0',
    x: '24.592425',
    y: '41.651247',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '68014',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СОЛИЩЕ',
    nameEn: 'SOLISHTE',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6721',
    addressNomenclature: '0',
    x: '25.387903',
    y: '41.669557',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '68028',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СОЛНИК',
    nameEn: 'SOLNIK',
    municipality: 'ДОЛНИ ЧИФЛИК',
    municipalityEn: 'DOLNI CHIFLIK',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9122',
    addressNomenclature: '0',
    x: '27.65248',
    y: '42.921942',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '68045',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СОМОВИТ',
    nameEn: 'SOMOVIT',
    municipality: 'ГУЛЯНЦИ',
    municipalityEn: 'GULYANTSI',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5959',
    addressNomenclature: '0',
    x: '24.775688',
    y: '43.68981',
    servingDays: '0101000',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '68059',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СОПИЦА',
    nameEn: 'SOPITSA',
    municipality: 'БРЕЗНИК',
    municipalityEn: 'BREZNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2378',
    addressNomenclature: '0',
    x: '22.923314',
    y: '42.677706',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '68062',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СОПОВО',
    nameEn: 'SOPOVO',
    municipality: 'БОБОШЕВО',
    municipalityEn: 'BOBOSHEVO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2664',
    addressNomenclature: '0',
    x: '23.024243',
    y: '42.174397',
    servingDays: '1111110',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '68076',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СОПОТ',
    nameEn: 'SOPOT',
    municipality: 'УГЪРЧИН',
    municipalityEn: 'UGARCHIN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5571',
    addressNomenclature: '0',
    x: '24.421912',
    y: '43.026089',
    servingDays: '1010100',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '68080',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'СОПОТ',
    nameEn: 'SOPOT',
    municipality: 'СОПОТ',
    municipalityEn: 'SOPOT',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4330',
    addressNomenclature: '2',
    x: '24.756775',
    y: '42.653572',
    servingDays: '1111110',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '68093',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СОПОТОТ',
    nameEn: 'SOPOTOT',
    municipality: 'РУДОЗЕМ',
    municipalityEn: 'RUDOZEM',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4974',
    addressNomenclature: '0',
    x: '24.85638',
    y: '41.463376',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '68117',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СОТИРЯ',
    nameEn: 'SOTIRYA',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8859',
    addressNomenclature: '0',
    x: '26.403936',
    y: '42.690804',
    servingDays: '0101000',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '68120',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СОФИЙЦИ',
    nameEn: 'SOFIYTSI',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6850',
    addressNomenclature: '0',
    x: '25.361598',
    y: '41.534785',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '68134',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'СОФИЯ',
    nameEn: 'SOFIA',
    municipality: 'СТОЛИЧНА',
    municipalityEn: 'STOLICHNA',
    region: 'СОФИЯ (СТОЛИЦА)',
    regionEn: 'SOFIA (STOLITSA)',
    postCode: '1000',
    addressNomenclature: '1',
    x: '23.320819',
    y: '42.696813',
    servingDays: '1111110',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '68148',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СОФРОНИЕВО',
    nameEn: 'SOFRONIEVO',
    municipality: 'МИЗИЯ',
    municipalityEn: 'MIZIA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3348',
    addressNomenclature: '0',
    x: '23.786688',
    y: '43.63646',
    servingDays: '1010100',
    servingOfficeId: '64',
    servingHubOfficeId: '20',
  },
  {
    id: '68165',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СПАНЦИ',
    nameEn: 'SPANTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5344',
    addressNomenclature: '0',
    x: '25.296608',
    y: '42.952346',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '68179',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СПАНЧЕВЦИ',
    nameEn: 'SPANCHEVTSI',
    municipality: 'ВЪРШЕЦ',
    municipalityEn: 'VARSHETS',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3546',
    addressNomenclature: '0',
    x: '23.238283',
    y: '43.187317',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '68182',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СПАСОВО',
    nameEn: 'SPASOVO',
    municipality: 'ЧИРПАН',
    municipalityEn: 'CHIRPAN',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6249',
    addressNomenclature: '0',
    x: '25.323327',
    y: '42.261299',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '68196',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СПАСОВО',
    nameEn: 'SPASOVO',
    municipality: 'ГЕНЕРАЛ ТОШЕВО',
    municipalityEn: 'GENERAL TOSHEVO',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9561',
    addressNomenclature: '0',
    x: '28.301011',
    y: '43.702257',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '68210',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СПАСОВЦИ',
    nameEn: 'SPASOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5340',
    addressNomenclature: '0',
    x: '25.383745',
    y: '42.890169',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '68223',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СПАТОВО',
    nameEn: 'SPATOVO',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2811',
    addressNomenclature: '0',
    x: '23.32482',
    y: '41.50786',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '68237',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СПАХИЕВО',
    nameEn: 'SPAHIEVO',
    municipality: 'МИНЕРАЛНИ БАНИ',
    municipalityEn: 'MINERALNI BANI',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6346',
    addressNomenclature: '0',
    x: '25.329754',
    y: '41.897054',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '68240',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СПОЛУКА',
    nameEn: 'SPOLUKA',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6799',
    addressNomenclature: '0',
    x: '25.148323',
    y: '41.694127',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '68254',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СРАЦИМИР',
    nameEn: 'SRATSIMIR',
    municipality: 'СИЛИСТРА',
    municipalityEn: 'SILISTRA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7541',
    addressNomenclature: '0',
    x: '27.347319',
    y: '44.029231',
    servingDays: '0101000',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '68268',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СРАЦИМИРОВО',
    nameEn: 'SRATSIMIROVO',
    municipality: 'ГРАМАДА',
    municipalityEn: 'GRAMADA',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3847',
    addressNomenclature: '0',
    x: '22.735154',
    y: '43.829372',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '68271',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СРЕБРИНОВО',
    nameEn: 'SREBRINOVO',
    municipality: 'ПАНАГЮРИЩЕ',
    municipalityEn: 'PANAGYURISHTE',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4525',
    addressNomenclature: '0',
    x: '24.008865',
    y: '42.538778',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '68299',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СРЕБЪРНА',
    nameEn: 'SREBARNA',
    municipality: 'СИЛИСТРА',
    municipalityEn: 'SILISTRA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7587',
    addressNomenclature: '0',
    x: '27.06404',
    y: '44.093939',
    servingDays: '1010100',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '17974',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'СРЕДЕЦ',
    nameEn: 'SREDETS',
    municipality: 'СРЕДЕЦ',
    municipalityEn: 'SREDETS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8300',
    addressNomenclature: '2',
    x: '27.177966',
    y: '42.348374',
    servingDays: '1111110',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '68309',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СРЕДЕЦ',
    nameEn: 'SREDETS',
    municipality: 'НЕДЕЛИНО',
    municipalityEn: 'NEDELINO',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4998',
    addressNomenclature: '0',
    x: '25.121784',
    y: '41.492843',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '68312',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СРЕДЕЦ',
    nameEn: 'SREDETS',
    municipality: 'ОПАН',
    municipalityEn: 'OPAN',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6066',
    addressNomenclature: '0',
    x: '25.670024',
    y: '42.247876',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '68326',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СРЕДИНА',
    nameEn: 'SREDINA',
    municipality: 'ГЕНЕРАЛ ТОШЕВО',
    municipalityEn: 'GENERAL TOSHEVO',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9546',
    addressNomenclature: '0',
    x: '28.237248',
    y: '43.624386',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '68336',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СРЕДИНКА',
    nameEn: 'SREDINKA',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6738',
    addressNomenclature: '0',
    x: '25.341317',
    y: '41.651251',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '68357',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СРЕДИЩЕ',
    nameEn: 'SREDISHTE',
    municipality: 'КАЙНАРДЖА',
    municipalityEn: 'KAYNARDZHA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7560',
    addressNomenclature: '0',
    x: '27.486131',
    y: '43.901308',
    servingDays: '0101000',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '68388',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СРЕДКОВЕЦ',
    nameEn: 'SREDKOVETS',
    municipality: 'КАОЛИНОВО',
    municipalityEn: 'KAOLINOVO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9968',
    addressNomenclature: '0',
    x: '27.035929',
    y: '43.685392',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '68391',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СРЕДНА',
    nameEn: 'SREDNA',
    municipality: 'ГОЦЕ ДЕЛЧЕВ',
    municipalityEn: 'GOTSE DELCHEV',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '',
    addressNomenclature: '0',
    x: '23.684254',
    y: '41.580704',
    servingDays: '0101000',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '68401',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СРЕДНА МАХАЛА',
    nameEn: 'SREDNA MAHALA',
    municipality: 'РУЕН',
    municipalityEn: 'RUEN',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8581',
    addressNomenclature: '0',
    x: '27.329064',
    y: '42.841958',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '68415',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СРЕДНЕВО',
    nameEn: 'SREDNEVO',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6717',
    addressNomenclature: '0',
    x: '25.354468',
    y: '41.759266',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '68429',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СРЕДНИ КОЛИБИ',
    nameEn: 'SREDNI KOLIBI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5093',
    addressNomenclature: '0',
    x: '25.740511',
    y: '42.918908',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '68432',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СРЕДНИ РЪТ',
    nameEn: 'SREDNI RAT',
    municipality: 'РОМАН',
    municipalityEn: 'ROMAN',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3137',
    addressNomenclature: '0',
    x: '23.905516',
    y: '43.057811',
    servingDays: '0101000',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '68463',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СРЕДНО ГРАДИЩЕ',
    nameEn: 'SREDNO GRADISHTE',
    municipality: 'ЧИРПАН',
    municipalityEn: 'CHIRPAN',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6241',
    addressNomenclature: '0',
    x: '25.294165',
    y: '42.306724',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '68480',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СРЕДНО СЕЛО',
    nameEn: 'SREDNO SELO',
    municipality: 'ВЕТРИНО',
    municipalityEn: 'VETRINO',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9222',
    addressNomenclature: '0',
    x: '27.428783',
    y: '43.342414',
    servingDays: '1111100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '68494',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СРЕДНО СЕЛО',
    nameEn: 'SREDNO SELO',
    municipality: 'ЗЛАТАРИЦА',
    municipalityEn: 'ZLATARITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5088',
    addressNomenclature: '0',
    x: '26.06014',
    y: '43.019156',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '68446',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СРЕДНОГОРОВО',
    nameEn: 'SREDNOGOROVO',
    municipality: 'КАЗАНЛЪК',
    municipalityEn: 'KAZANLAK',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6166',
    addressNomenclature: '0',
    x: '25.330463',
    y: '42.53272',
    servingDays: '0101000',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '68451',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СРЕДНОГОРЦИ',
    nameEn: 'SREDNOGORTSI',
    municipality: 'МАДАН',
    municipalityEn: 'MADAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4954',
    addressNomenclature: '0',
    x: '24.908179',
    y: '41.532131',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '68504',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СРЕДНЯ',
    nameEn: 'SREDNYA',
    municipality: 'ШУМЕН',
    municipalityEn: 'SHUMEN',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9798',
    addressNomenclature: '0',
    x: '26.798143',
    y: '43.302468',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '68518',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СРЕДОГРИВ',
    nameEn: 'SREDOGRIV',
    municipality: 'ЧУПРЕНЕ',
    municipalityEn: 'CHUPRENE',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3959',
    addressNomenclature: '0',
    x: '22.783084',
    y: '43.548579',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '68521',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СРЕДОК',
    nameEn: 'SREDOK',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4798',
    addressNomenclature: '0',
    x: '24.608228',
    y: '41.45752',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '68535',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СРЕДОРЕК',
    nameEn: 'SREDOREK',
    municipality: 'ТРЕКЛЯНО',
    municipalityEn: 'TREKLYANO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2555',
    addressNomenclature: '0',
    x: '22.551357',
    y: '42.559925',
    servingDays: '0101000',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '68549',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СРЕДОРЕК',
    nameEn: 'SREDOREK',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8845',
    addressNomenclature: '0',
    x: '26.152661',
    y: '42.872588',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '68552',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СРЕДОСЕЛЦИ',
    nameEn: 'SREDOSELTSI',
    municipality: 'ИСПЕРИХ',
    municipalityEn: 'ISPERIH',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7433',
    addressNomenclature: '0',
    x: '26.913139',
    y: '43.695646',
    servingDays: '1010100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '68566',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СРЕДСКА',
    nameEn: 'SREDSKA',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6693',
    addressNomenclature: '0',
    x: '25.202074',
    y: '41.77885',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '68577',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СРЕДСКО',
    nameEn: 'SREDSKO',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6890',
    addressNomenclature: '0',
    x: '25.471751',
    y: '41.357676',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '68583',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СРЕМ',
    nameEn: 'SREM',
    municipality: 'ТОПОЛОВГРАД',
    municipalityEn: 'TOPOLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6565',
    addressNomenclature: '0',
    x: '26.482418',
    y: '42.050131',
    servingDays: '1010100',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '68597',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СРЪНСКО',
    nameEn: 'SRANSKO',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6775',
    addressNomenclature: '0',
    x: '25.096044',
    y: '41.543059',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '68607',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАВЕРЦИ',
    nameEn: 'STAVERTSI',
    municipality: 'ДОЛНА МИТРОПОЛИЯ',
    municipalityEn: 'DOLNA MITROPOLIA',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5863',
    addressNomenclature: '0',
    x: '24.278167',
    y: '43.572151',
    servingDays: '1010100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '68610',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАЕВЦИ',
    nameEn: 'STAEVTSI',
    municipality: 'ШАБЛА',
    municipalityEn: 'SHABLA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9673',
    addressNomenclature: '0',
    x: '28.494447',
    y: '43.691713',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '68624',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАЙНОВЦИ',
    nameEn: 'STAYNOVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5350',
    addressNomenclature: '0',
    x: '25.491667',
    y: '42.899755',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '68638',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАЙЧИН ДОЛ',
    nameEn: 'STAYCHIN DOL',
    municipality: 'МАДАН',
    municipalityEn: 'MADAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4931',
    addressNomenclature: '0',
    x: '24.990422',
    y: '41.565734',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '68641',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАЙЧОВЦИ',
    nameEn: 'STAYCHOVTSI',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2489',
    addressNomenclature: '0',
    x: '22.539839',
    y: '42.761087',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '68655',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАКЕВЦИ',
    nameEn: 'STAKEVTSI',
    municipality: 'БЕЛОГРАДЧИК',
    municipalityEn: 'BELOGRADCHIK',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3963',
    addressNomenclature: '0',
    x: '22.565165',
    y: '43.535909',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '68669',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАЛЕВО',
    nameEn: 'STALEVO',
    municipality: 'ДИМИТРОВГРАД',
    municipalityEn: 'DIMITROVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6433',
    addressNomenclature: '0',
    x: '25.393611',
    y: '42.080045',
    servingDays: '0101000',
    servingOfficeId: '42',
    servingHubOfficeId: '5',
  },
  {
    id: '68672',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАЛИЙСКА МАХАЛА',
    nameEn: 'STALIYSKA MAHALA',
    municipality: 'ЛОМ',
    municipalityEn: 'LOM',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3657',
    addressNomenclature: '0',
    x: '23.166553',
    y: '43.739632',
    servingDays: '1010100',
    servingOfficeId: '831',
    servingHubOfficeId: '20',
  },
  {
    id: '51980',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'СТАМБОЛИЙСКИ',
    nameEn: 'STAMBOLIYSKI',
    municipality: 'СТАМБОЛИЙСКИ',
    municipalityEn: 'STAMBOLIYSKI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4210',
    addressNomenclature: '2',
    x: '24.537172',
    y: '42.136188',
    servingDays: '1111110',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '68692',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАМБОЛИЙСКИ',
    nameEn: 'STAMBOLIYSKI',
    municipality: 'ХАСКОВО',
    municipalityEn: 'HASKOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6311',
    addressNomenclature: '0',
    x: '25.632918',
    y: '41.908411',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '4830',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАМБОЛОВО',
    nameEn: 'STAMBOLOVO',
    municipality: 'ИХТИМАН',
    municipalityEn: 'IHTIMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2069',
    addressNomenclature: '0',
    x: '23.813194',
    y: '42.402217',
    servingDays: '1010100',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '68708',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАМБОЛОВО',
    nameEn: 'STAMBOLOVO',
    municipality: 'ПАВЛИКЕНИ',
    municipalityEn: 'PAVLIKENI',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5249',
    addressNomenclature: '0',
    x: '25.367026',
    y: '43.211344',
    servingDays: '1111100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '68713',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАМБОЛОВО',
    nameEn: 'STAMBOLOVO',
    municipality: 'СЛИВО ПОЛЕ',
    municipalityEn: 'SLIVO POLE',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7063',
    addressNomenclature: '0',
    x: '26.299752',
    y: '43.898403',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '68727',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАМБОЛОВО',
    nameEn: 'STAMBOLOVO',
    municipality: 'СТАМБОЛОВО',
    municipalityEn: 'STAMBOLOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6362',
    addressNomenclature: '0',
    x: '25.642863',
    y: '41.77632',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '68744',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАН',
    nameEn: 'STAN',
    municipality: 'НОВИ ПАЗАР',
    municipalityEn: 'NOVI PAZAR',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9917',
    addressNomenclature: '0',
    x: '27.254898',
    y: '43.343344',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '68758',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАНЕВО',
    nameEn: 'STANEVO',
    municipality: 'ЛОМ',
    municipalityEn: 'LOM',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3641',
    addressNomenclature: '0',
    x: '23.445029',
    y: '43.822962',
    servingDays: '0101000',
    servingOfficeId: '831',
    servingHubOfficeId: '20',
  },
  {
    id: '68761',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАНЕЦ',
    nameEn: 'STANETS',
    municipality: 'ОМУРТАГ',
    municipalityEn: 'OMURTAG',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7915',
    addressNomenclature: '0',
    x: '26.383848',
    y: '43.078465',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '68775',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАНИНЦИ',
    nameEn: 'STANINTSI',
    municipality: 'ГОДЕЧ',
    municipalityEn: 'GODECH',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2254',
    addressNomenclature: '0',
    x: '22.918904',
    y: '43.051848',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '68802',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАНОВЕЦ',
    nameEn: 'STANOVETS',
    municipality: 'ХИТРИНО',
    municipalityEn: 'HITRINO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9952',
    addressNomenclature: '0',
    x: '27.038926',
    y: '43.496796',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '68816',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАНЧА',
    nameEn: 'STANCHA',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5380',
    addressNomenclature: '0',
    x: '25.473724',
    y: '42.929943',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '68823',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАНЧОВ ХАН',
    nameEn: 'STANCHOV HAN',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5367',
    addressNomenclature: '0',
    x: '25.560676',
    y: '42.814726',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '68833',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАНЬОВЦИ',
    nameEn: 'STANYOVTSI',
    municipality: 'БРЕЗНИК',
    municipalityEn: 'BREZNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2391',
    addressNomenclature: '0',
    x: '22.732199',
    y: '42.688453',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '68847',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАНЯНЦИ',
    nameEn: 'STANYANTSI',
    municipality: 'ВЪРБИЦА',
    municipalityEn: 'VARBITSA',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9872',
    addressNomenclature: '0',
    x: '26.635615',
    y: '43.005071',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '69122',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАР ЧИТАК',
    nameEn: 'STAR CHITAK',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6792',
    addressNomenclature: '0',
    x: '25.23261',
    y: '41.668758',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '68850',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'СТАРА ЗАГОРА',
    nameEn: 'STARA ZAGORA',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6000',
    addressNomenclature: '1',
    x: '25.634572',
    y: '42.425572',
    servingDays: '1111110',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '39699',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАРА КРЕСНА',
    nameEn: 'STARA KRESNA',
    municipality: 'КРЕСНА',
    municipalityEn: 'KRESNA',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2845',
    addressNomenclature: '0',
    x: '23.180593',
    y: '41.789474',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '68864',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАРА РЕКА',
    nameEn: 'STARA REKA',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8841',
    addressNomenclature: '0',
    x: '26.183775',
    y: '42.878811',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '68878',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАРА РЕКА',
    nameEn: 'STARA REKA',
    municipality: 'ТУНДЖА',
    municipalityEn: 'TUNDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8675',
    addressNomenclature: '0',
    x: '26.564402',
    y: '42.467322',
    servingDays: '1111110',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '68881',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАРА РЕЧКА',
    nameEn: 'STARA RECHKA',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7999',
    addressNomenclature: '0',
    x: '26.075903',
    y: '43.086993',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '68895',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАРЕЙШИНО',
    nameEn: 'STAREYSHINO',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6880',
    addressNomenclature: '0',
    x: '25.397135',
    y: '41.418481',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '68936',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАРИ ЧАЛ',
    nameEn: 'STARI CHAL',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6964',
    addressNomenclature: '0',
    x: '25.647746',
    y: '41.586678',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '68919',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАРИЛКОВЦИ',
    nameEn: 'STARILKOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5343',
    addressNomenclature: '0',
    x: '25.337175',
    y: '42.941719',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '68967',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАРО ЖЕЛЕЗАРЕ',
    nameEn: 'STARO ZHELEZARE',
    municipality: 'ХИСАРЯ',
    municipalityEn: 'HISARYA',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4165',
    addressNomenclature: '0',
    x: '24.65008',
    y: '42.44557',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '68984',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАРО МЯСТО',
    nameEn: 'STARO MYASTO',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6741',
    addressNomenclature: '0',
    x: '25.297976',
    y: '41.664074',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '68998',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАРО ОРЯХОВО',
    nameEn: 'STARO ORYAHOVO',
    municipality: 'ДОЛНИ ЧИФЛИК',
    municipalityEn: 'DOLNI CHIFLIK',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9110',
    addressNomenclature: '0',
    x: '27.794592',
    y: '42.990094',
    servingDays: '1111110',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '69047',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАРО СЕЛИЩЕ',
    nameEn: 'STARO SELISHTE',
    municipality: 'ИСПЕРИХ',
    municipalityEn: 'ISPERIH',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7447',
    addressNomenclature: '0',
    x: '26.747022',
    y: '43.654381',
    servingDays: '1010100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '69050',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАРО СЕЛО',
    nameEn: 'STARO SELO',
    municipality: 'МЕЗДРА',
    municipalityEn: 'MEZDRA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3142',
    addressNomenclature: '0',
    x: '23.83431',
    y: '43.158492',
    servingDays: '0101000',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '69064',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАРО СЕЛО',
    nameEn: 'STARO SELO',
    municipality: 'РАДОМИР',
    municipalityEn: 'RADOMIR',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2412',
    addressNomenclature: '0',
    x: '23.106996',
    y: '42.482643',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '69078',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАРО СЕЛО',
    nameEn: 'STARO SELO',
    municipality: 'ТУТРАКАН',
    municipalityEn: 'TUTRAKAN',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7637',
    addressNomenclature: '0',
    x: '26.541879',
    y: '43.987797',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '69081',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАРО СЕЛО',
    nameEn: 'STARO SELO',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8876',
    addressNomenclature: '0',
    x: '26.148654',
    y: '42.58032',
    servingDays: '0101000',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '70500',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАРО СЕЛО',
    nameEn: 'STARO SELO',
    municipality: 'ТРОЯН',
    municipalityEn: 'TROYAN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5671',
    addressNomenclature: '0',
    x: '24.521495',
    y: '42.984517',
    servingDays: '0101000',
    servingOfficeId: '44',
    servingHubOfficeId: '11',
  },
  {
    id: '68953',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАРОВО',
    nameEn: 'STAROVO',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6877',
    addressNomenclature: '0',
    x: '25.313796',
    y: '41.417337',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '68970',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАРОЗАГОРСКИ БАНИ',
    nameEn: 'STAROZAGORSKI BANI',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6062',
    addressNomenclature: '0',
    x: '25.493345',
    y: '42.450038',
    servingDays: '1111110',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '69002',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАРОПАТИЦА',
    nameEn: 'STAROPATITSA',
    municipality: 'КУЛА',
    municipalityEn: 'KULA',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3822',
    addressNomenclature: '0',
    x: '22.493828',
    y: '43.831233',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '69016',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАРОСЕЛ',
    nameEn: 'STAROSEL',
    municipality: 'ХИСАРЯ',
    municipalityEn: 'HISARYA',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4175',
    addressNomenclature: '0',
    x: '24.570206',
    y: '42.485923',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '69020',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАРОСЕЛЕЦ',
    nameEn: 'STAROSELETS',
    municipality: 'ПРОВАДИЯ',
    municipalityEn: 'PROVADIA',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9226',
    addressNomenclature: '0',
    x: '27.489408',
    y: '43.229951',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '69095',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАРОСЕЛЦИ',
    nameEn: 'STAROSELTSI',
    municipality: 'ИСКЪР',
    municipalityEn: 'ISKAR',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5865',
    addressNomenclature: '0',
    x: '24.277897',
    y: '43.538358',
    servingDays: '1010100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '69105',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАРЦЕВО',
    nameEn: 'STARTSEVO',
    municipality: 'ЗЛАТОГРАД',
    municipalityEn: 'ZLATOGRAD',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4987',
    addressNomenclature: '0',
    x: '25.053837',
    y: '41.429981',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '69119',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАРЧЕВО',
    nameEn: 'STARCHEVO',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2861',
    addressNomenclature: '0',
    x: '23.233403',
    y: '41.472637',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '69136',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТАРЧИЩЕ',
    nameEn: 'STARCHISHTE',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7963',
    addressNomenclature: '0',
    x: '26.175559',
    y: '43.018978',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '69146',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТЕВРЕК',
    nameEn: 'STEVREK',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7960',
    addressNomenclature: '0',
    x: '26.171987',
    y: '42.993103',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '69153',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТЕЖЕРОВО',
    nameEn: 'STEZHEROVO',
    municipality: 'ЛЕВСКИ',
    municipalityEn: 'LEVSKI',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5920',
    addressNomenclature: '0',
    x: '25.149307',
    y: '43.501161',
    servingDays: '1010100',
    servingOfficeId: '108',
    servingHubOfficeId: '11',
  },
  {
    id: '69167',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТЕНСКО',
    nameEn: 'STENSKO',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2566',
    addressNomenclature: '0',
    x: '22.681908',
    y: '42.371478',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '69170',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТЕФАН КАРАДЖА',
    nameEn: 'STEFAN KARADZHA',
    municipality: 'ВЪЛЧИ ДОЛ',
    municipalityEn: 'VALCHI DOL',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9293',
    addressNomenclature: '0',
    x: '27.411027',
    y: '43.496463',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '69184',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТЕФАН КАРАДЖА',
    nameEn: 'STEFAN KARADZHA',
    municipality: 'ГЛАВИНИЦА',
    municipalityEn: 'GLAVINITSA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7633',
    addressNomenclature: '0',
    x: '26.728474',
    y: '43.903888',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '69198',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТЕФАН КАРАДЖА',
    nameEn: 'STEFAN KARADZHA',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9371',
    addressNomenclature: '0',
    x: '27.878949',
    y: '43.620349',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '69208',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТЕФАН КАРАДЖОВО',
    nameEn: 'STEFAN KARADZHOVO',
    municipality: 'БОЛЯРОВО',
    municipalityEn: 'BOLYAROVO',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8725',
    addressNomenclature: '0',
    x: '26.832171',
    y: '42.213891',
    servingDays: '0101000',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '17467',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТЕФАН СТАМБОЛОВО',
    nameEn: 'STEFAN STAMBOLOVO',
    municipality: 'ПОЛСКИ ТРЪМБЕШ',
    municipalityEn: 'POLSKI TRAMBESH',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5192',
    addressNomenclature: '0',
    x: '25.559992',
    y: '43.274',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '69211',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТЕФАНОВО',
    nameEn: 'STEFANOVO',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5335',
    addressNomenclature: '0',
    x: '25.373878',
    y: '42.867944',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '69225',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТЕФАНОВО',
    nameEn: 'STEFANOVO',
    municipality: 'ЛОВЕЧ',
    municipalityEn: 'LOVECH',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5581',
    addressNomenclature: '0',
    x: '24.837735',
    y: '43.02678',
    servingDays: '0101000',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '69239',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТЕФАНОВО',
    nameEn: 'STEFANOVO',
    municipality: 'РАДОМИР',
    municipalityEn: 'RADOMIR',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2414',
    addressNomenclature: '0',
    x: '23.023899',
    y: '42.484413',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '69242',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТЕФАНОВО',
    nameEn: 'STEFANOVO',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9360',
    addressNomenclature: '0',
    x: '27.858294',
    y: '43.493189',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '69256',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТИКЪЛ',
    nameEn: 'STIKAL',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4712',
    addressNomenclature: '0',
    x: '24.580927',
    y: '41.640165',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '69261',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТОБ',
    nameEn: 'STOB',
    municipality: 'КОЧЕРИНОВО',
    municipalityEn: 'KOCHERINOVO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2638',
    addressNomenclature: '0',
    x: '23.103285',
    y: '42.096033',
    servingDays: '1111100',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '69273',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТОЕВО',
    nameEn: 'STOEVO',
    municipality: 'АСЕНОВГРАД',
    municipalityEn: 'ASENOVGRAD',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4266',
    addressNomenclature: '0',
    x: '24.994752',
    y: '41.981175',
    servingDays: '1010100',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '69287',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТОЕВЦИ',
    nameEn: 'STOEVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5347',
    addressNomenclature: '0',
    x: '25.206752',
    y: '42.893442',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '69290',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТОЖА',
    nameEn: 'STOZHA',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2800',
    addressNomenclature: '0',
    x: '23.32221',
    y: '41.61802',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '69300',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТОЖЕР',
    nameEn: 'STOZHER',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9350',
    addressNomenclature: '0',
    x: '27.823502',
    y: '43.434161',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '69314',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТОИЛ ВОЙВОДА',
    nameEn: 'STOIL VOYVODA',
    municipality: 'НОВА ЗАГОРА',
    municipalityEn: 'NOVA ZAGORA',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8940',
    addressNomenclature: '0',
    x: '25.985227',
    y: '42.462035',
    servingDays: '1010100',
    servingOfficeId: '56',
    servingHubOfficeId: '5',
  },
  {
    id: '69328',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТОИЛОВО',
    nameEn: 'STOILOVO',
    municipality: 'МАЛКО ТЪРНОВО',
    municipalityEn: 'MALKO TARNOVO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8165',
    addressNomenclature: '0',
    x: '27.519997',
    y: '42.039795',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '69345',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТОЙКИТЕ',
    nameEn: 'STOYKITE',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4715',
    addressNomenclature: '0',
    x: '24.635739',
    y: '41.653962',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '69359',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТОЙКОВО',
    nameEn: 'STOYKOVO',
    municipality: 'ХАСКОВО',
    municipalityEn: 'HASKOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6341',
    addressNomenclature: '0',
    x: '25.723912',
    y: '41.92397',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '70768',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТОЙКОВЦИ',
    nameEn: 'STOYKOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5301',
    addressNomenclature: '0',
    x: '25.353452',
    y: '42.8634',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '69376',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТОЙНОВО',
    nameEn: 'STOYNOVO',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7953',
    addressNomenclature: '0',
    x: '26.227515',
    y: '43.1027',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '69387',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТОЙНОВСКОТО',
    nameEn: 'STOYNOVSKOTO',
    municipality: 'ТРОЯН',
    municipalityEn: 'TROYAN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '',
    addressNomenclature: '0',
    x: '24.771368',
    y: '42.819951',
    servingDays: '1010100',
    servingOfficeId: '44',
    servingHubOfficeId: '11',
  },
  {
    id: '69393',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТОЙЧЕВЦИ',
    nameEn: 'STOYCHEVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5081',
    addressNomenclature: '0',
    x: '25.829789',
    y: '42.924943',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '69403',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТОЙЧОВЦИ',
    nameEn: 'STOYCHOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5343',
    addressNomenclature: '0',
    x: '25.412848',
    y: '42.939489',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '69417',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТОКИТЕ',
    nameEn: 'STOKITE',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5462',
    addressNomenclature: '0',
    x: '25.072006',
    y: '42.863329',
    servingDays: '1010100',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '69420',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТОЛЕТОВО',
    nameEn: 'STOLETOVO',
    municipality: 'КАРЛОВО',
    municipalityEn: 'KARLOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4338',
    addressNomenclature: '0',
    x: '24.619642',
    y: '42.688003',
    servingDays: '0101000',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '69434',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТОЛЕТОВО',
    nameEn: 'STOLETOVO',
    municipality: 'ОПАН',
    municipalityEn: 'OPAN',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6054',
    addressNomenclature: '0',
    x: '25.749977',
    y: '42.134192',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '69448',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТОЛНИК',
    nameEn: 'STOLNIK',
    municipality: 'ЕЛИН ПЕЛИН',
    municipalityEn: 'ELIN PELIN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2134',
    addressNomenclature: '0',
    x: '23.627107',
    y: '42.712888',
    servingDays: '1010100',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '69451',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТОЛЪТ',
    nameEn: 'STOLAT',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5452',
    addressNomenclature: '0',
    x: '24.980459',
    y: '42.931949',
    servingDays: '1010100',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '69465',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТОМАНЕВО',
    nameEn: 'STOMANEVO',
    municipality: 'ДЕВИН',
    municipalityEn: 'DEVIN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4822',
    addressNomenclature: '0',
    x: '24.422262',
    y: '41.808375',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '69479',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТОМАНЕЦИТЕ',
    nameEn: 'STOMANETSITE',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5304',
    addressNomenclature: '0',
    x: '25.291411',
    y: '42.802375',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '69482',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТОМАНЦИ',
    nameEn: 'STOMANTSI',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6878',
    addressNomenclature: '0',
    x: '25.29032',
    y: '41.419474',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '69496',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТОЯН ЗАИМОВО',
    nameEn: 'STOYAN ZAIMOVO',
    municipality: 'ЧИРПАН',
    municipalityEn: 'CHIRPAN',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6243',
    addressNomenclature: '0',
    x: '25.352042',
    y: '42.343931',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '69506',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТОЯН МИХАЙЛОВСКИ',
    nameEn: 'STOYAN MIHAYLOVSKI',
    municipality: 'НОВИ ПАЗАР',
    municipalityEn: 'NOVI PAZAR',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9928',
    addressNomenclature: '0',
    x: '27.190235',
    y: '43.411608',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '69518',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТОЯНОВО',
    nameEn: 'STOYANOVO',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6773',
    addressNomenclature: '0',
    x: '25.033142',
    y: '41.572768',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '69537',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТОЯНОВО',
    nameEn: 'STOYANOVO',
    municipality: 'ВЪРШЕЦ',
    municipalityEn: 'VARSHETS',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3539',
    addressNomenclature: '0',
    x: '23.340828',
    y: '43.254072',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '69540',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТОЯНОВЦИ',
    nameEn: 'STOYANOVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5084',
    addressNomenclature: '0',
    x: '25.999652',
    y: '43.004291',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '69554',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТОЯНОВЦИ',
    nameEn: 'STOYANOVTSI',
    municipality: 'РОМАН',
    municipalityEn: 'ROMAN',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3134',
    addressNomenclature: '0',
    x: '23.999156',
    y: '43.123449',
    servingDays: '0101000',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '69571',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТРАДАЛОВО',
    nameEn: 'STRADALOVO',
    municipality: 'НЕВЕСТИНО',
    municipalityEn: 'NEVESTINO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2584',
    addressNomenclature: '0',
    x: '22.757138',
    y: '42.126701',
    servingDays: '1010100',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '69585',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТРАЖА',
    nameEn: 'STRAZHA',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4726',
    addressNomenclature: '0',
    x: '24.792178',
    y: '41.595821',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '69599',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТРАЖА',
    nameEn: 'STRAZHA',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7738',
    addressNomenclature: '0',
    x: '26.566033',
    y: '43.195449',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '69609',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТРАЖАТА',
    nameEn: 'STRAZHATA',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5365',
    addressNomenclature: '0',
    x: '25.58544',
    y: '42.903923',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '69612',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТРАЖЕВЦИ',
    nameEn: 'STRAZHEVTSI',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6731',
    addressNomenclature: '0',
    x: '25.250713',
    y: '41.619398',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '70860',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТРАЖЕЦ',
    nameEn: 'STRAZHETS',
    municipality: 'РАЗГРАД',
    municipalityEn: 'RAZGRAD',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7217',
    addressNomenclature: '0',
    x: '26.509927',
    y: '43.553673',
    servingDays: '1111110',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '81743',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТРАЖЕЦ',
    nameEn: 'STRAZHETS',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6954',
    addressNomenclature: '0',
    x: '25.875645',
    y: '41.374576',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '69633',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'СТРАЖИЦА',
    nameEn: 'STRAZHITSA',
    municipality: 'СТРАЖИЦА',
    municipalityEn: 'STRAZHITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5150',
    addressNomenclature: '2',
    x: '25.96039',
    y: '43.226674',
    servingDays: '1111100',
    servingOfficeId: '46',
    servingHubOfficeId: '11',
  },
  {
    id: '69643',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТРАЖИЦА',
    nameEn: 'STRAZHITSA',
    municipality: 'БАЛЧИК',
    municipalityEn: 'BALCHIK',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9615',
    addressNomenclature: '0',
    x: '28.101026',
    y: '43.445022',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '69657',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТРАЖНИЦА',
    nameEn: 'STRAZHNITSA',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6710',
    addressNomenclature: '0',
    x: '25.354421',
    y: '41.712455',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '69660',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'СТРАЛДЖА',
    nameEn: 'STRALDZHA',
    municipality: 'СТРАЛДЖА',
    municipalityEn: 'STRALDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8680',
    addressNomenclature: '2',
    x: '26.691377',
    y: '42.597402',
    servingDays: '1111100',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '69674',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТРАНДЖА',
    nameEn: 'STRANDZHA',
    municipality: 'БОЛЯРОВО',
    municipalityEn: 'BOLYAROVO',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8758',
    addressNomenclature: '0',
    x: '27.003018',
    y: '42.062649',
    servingDays: '1010100',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '69688',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТРАНДЖЕВО',
    nameEn: 'STRANDZHEVO',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6929',
    addressNomenclature: '0',
    x: '25.766682',
    y: '41.639202',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '69691',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТРАНСКО',
    nameEn: 'STRANSKO',
    municipality: 'ДИМИТРОВГРАД',
    municipalityEn: 'DIMITROVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6428',
    addressNomenclature: '0',
    x: '25.602134',
    y: '42.165472',
    servingDays: '1010100',
    servingOfficeId: '42',
    servingHubOfficeId: '5',
  },
  {
    id: '69701',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТРАХИЛ',
    nameEn: 'STRAHIL',
    municipality: 'ВЪЛЧИ ДОЛ',
    municipalityEn: 'VALCHI DOL',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9142',
    addressNomenclature: '0',
    x: '27.611619',
    y: '43.514231',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '69715',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТРАХИЛ ВОЙВОДА',
    nameEn: 'STRAHIL VOYVODA',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6660',
    addressNomenclature: '0',
    x: '25.636953',
    y: '41.686368',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '69729',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТРАХИЛИЦА',
    nameEn: 'STRAHILITSA',
    municipality: 'ВЕНЕЦ',
    municipalityEn: 'VENETS',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9774',
    addressNomenclature: '0',
    x: '26.914596',
    y: '43.474859',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '69732',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТРАХИЛОВО',
    nameEn: 'STRAHILOVO',
    municipality: 'ПОЛСКИ ТРЪМБЕШ',
    municipalityEn: 'POLSKI TRAMBESH',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5190',
    addressNomenclature: '0',
    x: '25.565566',
    y: '43.407463',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '69746',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТРАЦИН',
    nameEn: 'STRATSIN',
    municipality: 'ПОМОРИЕ',
    municipalityEn: 'POMORIE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8222',
    addressNomenclature: '0',
    x: '27.472505',
    y: '42.773415',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '69759',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТРАШИМИР',
    nameEn: 'STRASHIMIR',
    municipality: 'ЗЛАТОГРАД',
    municipalityEn: 'ZLATOGRAD',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4988',
    addressNomenclature: '0',
    x: '24.984382',
    y: '41.439847',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '69763',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТРАШИМИРОВО',
    nameEn: 'STRASHIMIROVO',
    municipality: 'БЕЛОСЛАВ',
    municipalityEn: 'BELOSLAV',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9179',
    addressNomenclature: '0',
    x: '27.735237',
    y: '43.195402',
    servingDays: '1111110',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '69777',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТРЕЗИМИРОВЦИ',
    nameEn: 'STREZIMIROVTSI',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2482',
    addressNomenclature: '0',
    x: '22.451676',
    y: '42.799231',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '69780',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТРЕЛЕЦ',
    nameEn: 'STRELETS',
    municipality: 'ГОРНА ОРЯХОВИЦА',
    municipalityEn: 'GORNA ORYAHOVITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5133',
    addressNomenclature: '0',
    x: '25.766614',
    y: '43.306177',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '69794',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТРЕЛЕЦ',
    nameEn: 'STRELETS',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6058',
    addressNomenclature: '0',
    x: '25.743439',
    y: '42.295597',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '69804',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТРЕЛКОВО',
    nameEn: 'STRELKOVO',
    municipality: 'КАЙНАРДЖА',
    municipalityEn: 'KAYNARDZHA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7562',
    addressNomenclature: '0',
    x: '27.439235',
    y: '43.936679',
    servingDays: '0101000',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '69818',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТРЕЛЦИ',
    nameEn: 'STRELTSI',
    municipality: 'БРЕЗОВО',
    municipalityEn: 'BREZOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4152',
    addressNomenclature: '0',
    x: '24.91259',
    y: '42.396967',
    servingDays: '0101000',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '69821',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТРЕЛЦИ',
    nameEn: 'STRELTSI',
    municipality: 'КОТЕЛ',
    municipalityEn: 'KOTEL',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8995',
    addressNomenclature: '0',
    x: '26.284171',
    y: '42.94757',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '69835',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'СТРЕЛЧА',
    nameEn: 'STRELCHA',
    municipality: 'СТРЕЛЧА',
    municipalityEn: 'STRELCHA',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4530',
    addressNomenclature: '2',
    x: '24.321545',
    y: '42.503367',
    servingDays: '1111100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '69849',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТРЕМОВО',
    nameEn: 'STREMOVO',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6683',
    addressNomenclature: '0',
    x: '25.40623',
    y: '41.738065',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '69852',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТРЕМЦИ',
    nameEn: 'STREMTSI',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6680',
    addressNomenclature: '0',
    x: '25.42227',
    y: '41.728784',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '69866',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТРИЖБА',
    nameEn: 'STRIZHBA',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6899',
    addressNomenclature: '0',
    x: '25.44802',
    y: '41.305394',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '69874',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТРОЕВО',
    nameEn: 'STROEVO',
    municipality: 'МАРИЦА',
    municipalityEn: 'MARITSA',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4197',
    addressNomenclature: '0',
    x: '24.687996',
    y: '42.236837',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '69883',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТРОЙНО',
    nameEn: 'STROYNO',
    municipality: 'ЕЛХОВО',
    municipalityEn: 'ELHOVO',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8732',
    addressNomenclature: '0',
    x: '26.678825',
    y: '42.277236',
    servingDays: '0101000',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '69897',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТРОЙНОВЦИ',
    nameEn: 'STROYNOVTSI',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7964',
    addressNomenclature: '0',
    x: '26.205037',
    y: '42.982214',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '69907',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТРУГЪТ',
    nameEn: 'STRUGAT',
    municipality: 'ТРОЯН',
    municipalityEn: 'TROYAN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '',
    addressNomenclature: '0',
    x: '24.778662',
    y: '42.854463',
    servingDays: '1010100',
    servingOfficeId: '44',
    servingHubOfficeId: '11',
  },
  {
    id: '69910',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТРУИНДОЛ',
    nameEn: 'STRUINDOL',
    municipality: 'БЕЛОГРАДЧИК',
    municipalityEn: 'BELOGRADCHIK',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3919',
    addressNomenclature: '0',
    x: '22.581591',
    y: '43.673902',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '69924',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТРУИНО',
    nameEn: 'STRUINO',
    municipality: 'ШУМЕН',
    municipalityEn: 'SHUMEN',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9804',
    addressNomenclature: '0',
    x: '26.851375',
    y: '43.367859',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '69938',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТРУМА',
    nameEn: 'STRUMA',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2806',
    addressNomenclature: '0',
    x: '23.238396',
    y: '41.536938',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '69941',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТРУМЕШНИЦА',
    nameEn: 'STRUMESHNITSA',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2895',
    addressNomenclature: '0',
    x: '23.043403',
    y: '41.396224',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '69969',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТРУМЯНИ',
    nameEn: 'STRUMYANI',
    municipality: 'СТРУМЯНИ',
    municipalityEn: 'STRUMYANI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2825',
    addressNomenclature: '0',
    x: '23.202485',
    y: '41.638346',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '69972',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТРУПЕЦ',
    nameEn: 'STRUPETS',
    municipality: 'РОМАН',
    municipalityEn: 'ROMAN',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3132',
    addressNomenclature: '0',
    x: '23.89471',
    y: '43.126515',
    servingDays: '0101000',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '69986',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТРУПЕЦ',
    nameEn: 'STRUPETS',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8856',
    addressNomenclature: '0',
    x: '26.111589',
    y: '42.614975',
    servingDays: '0101000',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '69990',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТРУЯ',
    nameEn: 'STRUYA',
    municipality: 'РУЕН',
    municipalityEn: 'RUEN',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8555',
    addressNomenclature: '0',
    x: '27.265347',
    y: '42.960849',
    servingDays: '1111100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '70007',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТРЪМЦИ',
    nameEn: 'STRAMTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5365',
    addressNomenclature: '0',
    x: '25.576709',
    y: '42.891564',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '70010',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТРЯМА',
    nameEn: 'STRYAMA',
    municipality: 'РАКОВСКИ',
    municipalityEn: 'RAKOVSKI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4142',
    addressNomenclature: '0',
    x: '24.881911',
    y: '42.26074',
    servingDays: '1111100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '70024',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТУБЕЛ',
    nameEn: 'STUBEL',
    municipality: 'МОНТАНА',
    municipalityEn: 'MONTANA',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3490',
    addressNomenclature: '0',
    x: '23.351803',
    y: '43.388727',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '70086',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТУДЕН ИЗВОР',
    nameEn: 'STUDEN IZVOR',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2488',
    addressNomenclature: '0',
    x: '22.596912',
    y: '42.792127',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '70104',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТУДЕН КЛАДЕНЕЦ',
    nameEn: 'STUDEN KLADENETS',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6958',
    addressNomenclature: '0',
    x: '25.640963',
    y: '41.606944',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '70038',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТУДЕНА',
    nameEn: 'STUDENA',
    municipality: 'ПЕРНИК',
    municipalityEn: 'PERNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2344',
    addressNomenclature: '0',
    x: '23.120873',
    y: '42.544539',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '70041',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТУДЕНА',
    nameEn: 'STUDENA',
    municipality: 'МАДАН',
    municipalityEn: 'MADAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4932',
    addressNomenclature: '0',
    x: '24.94842',
    y: '41.577188',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '70055',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТУДЕНА',
    nameEn: 'STUDENA',
    municipality: 'СВИЛЕНГРАД',
    municipalityEn: 'SVILENGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6538',
    addressNomenclature: '0',
    x: '26.402699',
    y: '41.916682',
    servingDays: '0101000',
    servingOfficeId: '48',
    servingHubOfficeId: '5',
  },
  {
    id: '70069',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТУДЕНЕЦ',
    nameEn: 'STUDENETS',
    municipality: 'ЛОЗНИЦА',
    municipalityEn: 'LOZNITSA',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7279',
    addressNomenclature: '0',
    x: '26.609444',
    y: '43.425757',
    servingDays: '0101000',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '70072',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТУДЕНЕЦ',
    nameEn: 'STUDENETS',
    municipality: 'ЧЕПЕЛАРЕ',
    municipalityEn: 'CHEPELARE',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4878',
    addressNomenclature: '0',
    x: '24.655003',
    y: '41.819596',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '70099',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТУДЕНИЦА',
    nameEn: 'STUDENITSA',
    municipality: 'ХИТРИНО',
    municipalityEn: 'HITRINO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9768',
    addressNomenclature: '0',
    x: '26.750765',
    y: '43.450437',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '70113',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТУДЕНО БУЧЕ',
    nameEn: 'STUDENO BUCHE',
    municipality: 'МОНТАНА',
    municipalityEn: 'MONTANA',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3480',
    addressNomenclature: '0',
    x: '23.191412',
    y: '43.457631',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '70127',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТЪРГЕЛ',
    nameEn: 'STARGEL',
    municipality: 'ГОРНА МАЛИНА',
    municipalityEn: 'GORNA MALINA',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2135',
    addressNomenclature: '0',
    x: '23.867151',
    y: '42.750201',
    servingDays: '0101000',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '70130',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТЪРМЕН',
    nameEn: 'STARMEN',
    municipality: 'БЯЛА',
    municipalityEn: 'BYALA',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7124',
    addressNomenclature: '0',
    x: '25.692238',
    y: '43.481733',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '70158',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СТЪРНИЦА',
    nameEn: 'STARNITSA',
    municipality: 'БАНИТЕ',
    municipalityEn: 'BANITE',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4957',
    addressNomenclature: '0',
    x: '24.928002',
    y: '41.664845',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '70175',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'СУВОРОВО',
    nameEn: 'SUVOROVO',
    municipality: 'СУВОРОВО',
    municipalityEn: 'SUVOROVO',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9170',
    addressNomenclature: '2',
    x: '27.592431',
    y: '43.32832',
    servingDays: '1111100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '70189',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СУГАРЕВО',
    nameEn: 'SUGAREVO',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2820',
    addressNomenclature: '0',
    x: '23.427183',
    y: '41.559613',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '70202',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СУЛИЦА',
    nameEn: 'SULITSA',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6062',
    addressNomenclature: '0',
    x: '25.479909',
    y: '42.436866',
    servingDays: '1111110',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '70216',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СУЛТАНИ',
    nameEn: 'SULTANI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5094',
    addressNomenclature: '0',
    x: '25.828872',
    y: '42.872809',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '70233',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СУМЕР',
    nameEn: 'SUMER',
    municipality: 'МОНТАНА',
    municipalityEn: 'MONTANA',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3491',
    addressNomenclature: '0',
    x: '23.330185',
    y: '43.333684',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '70247',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'СУНГУРЛАРЕ',
    nameEn: 'SUNGURLARE',
    municipality: 'СУНГУРЛАРЕ',
    municipalityEn: 'SUNGURLARE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8470',
    addressNomenclature: '2',
    x: '26.773863',
    y: '42.762423',
    servingDays: '1111100',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '70250',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СУСАМ',
    nameEn: 'SUSAM',
    municipality: 'МИНЕРАЛНИ БАНИ',
    municipalityEn: 'MINERALNI BANI',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6352',
    addressNomenclature: '0',
    x: '25.346378',
    y: '41.962953',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '70264',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СУХА РЕКА',
    nameEn: 'SUHA REKA',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5045',
    addressNomenclature: '0',
    x: '25.703392',
    y: '42.887326',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '70278',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СУХА РЕКА',
    nameEn: 'SUHA REKA',
    municipality: 'ВЕЛИКИ ПРЕСЛАВ',
    municipalityEn: 'VELIKI PRESLAV',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9881',
    addressNomenclature: '0',
    x: '26.942886',
    y: '43.131568',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '70281',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СУХАЧЕ',
    nameEn: 'SUHACHE',
    municipality: 'ЧЕРВЕН БРЯГ',
    municipalityEn: 'CHERVEN BRYAG',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5988',
    addressNomenclature: '0',
    x: '24.009355',
    y: '43.312375',
    servingDays: '1010100',
    servingOfficeId: '59',
    servingHubOfficeId: '20',
  },
  {
    id: '70295',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'СУХИНДОЛ',
    nameEn: 'SUHINDOL',
    municipality: 'СУХИНДОЛ',
    municipalityEn: 'SUHINDOL',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5240',
    addressNomenclature: '2',
    x: '25.165089',
    y: '43.18499',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '70319',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СУХОВО',
    nameEn: 'SUHOVO',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6794',
    addressNomenclature: '0',
    x: '25.260183',
    y: '41.665408',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '70322',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СУХОДОЛ',
    nameEn: 'SUHODOL',
    municipality: 'СРЕДЕЦ',
    municipalityEn: 'SREDETS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8328',
    addressNomenclature: '0',
    x: '27.081156',
    y: '42.438868',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '70336',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СУХОДОЛ',
    nameEn: 'SUHODOL',
    municipality: 'ГЛАВИНИЦА',
    municipalityEn: 'GLAVINITSA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7618',
    addressNomenclature: '0',
    x: '26.913494',
    y: '43.972062',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '70353',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СУХОЗЕМ',
    nameEn: 'SUHOZEM',
    municipality: 'КАЛОЯНОВО',
    municipalityEn: 'KALOYANOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4209',
    addressNomenclature: '0',
    x: '24.865493',
    y: '42.414952',
    servingDays: '0101000',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '70367',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СУХОЛОЕВЦИ',
    nameEn: 'SUHOLOEVTSI',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5380',
    addressNomenclature: '0',
    x: '25.524854',
    y: '42.912192',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '70370',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СУХОСТРЕЛ',
    nameEn: 'SUHOSTREL',
    municipality: 'СИМИТЛИ',
    municipalityEn: 'SIMITLI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2736',
    addressNomenclature: '0',
    x: '22.962939',
    y: '41.83038',
    servingDays: '1111110',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '70384',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СУШЕВО',
    nameEn: 'SUSHEVO',
    municipality: 'ЗАВЕТ',
    municipalityEn: 'ZAVET',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7324',
    addressNomenclature: '0',
    x: '26.631302',
    y: '43.814275',
    servingDays: '1010100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '70398',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СУШИНА',
    nameEn: 'SUSHINA',
    municipality: 'ВЪРБИЦА',
    municipalityEn: 'VARBITSA',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9868',
    addressNomenclature: '0',
    x: '26.761906',
    y: '43.069924',
    servingDays: '1111100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '35105',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СУШИЦА',
    nameEn: 'SUSHITSA',
    municipality: 'СИМИТЛИ',
    municipalityEn: 'SIMITLI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2759',
    addressNomenclature: '0',
    x: '23.058364',
    y: '41.817127',
    servingDays: '1111110',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '70408',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СУШИЦА',
    nameEn: 'SUSHITSA',
    municipality: 'СТРАЖИЦА',
    municipalityEn: 'STRAZHITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5177',
    addressNomenclature: '0',
    x: '25.824754',
    y: '43.259252',
    servingDays: '1010100',
    servingOfficeId: '46',
    servingHubOfficeId: '11',
  },
  {
    id: '70411',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СУШИЦА',
    nameEn: 'SUSHITSA',
    municipality: 'ТРЕКЛЯНО',
    municipalityEn: 'TREKLYANO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2558',
    addressNomenclature: '0',
    x: '22.645907',
    y: '42.486049',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '70439',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЪБКОВЦИ',
    nameEn: 'SABKOVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5099',
    addressNomenclature: '0',
    x: '25.988487',
    y: '42.881335',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '70442',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЪБОТКОВЦИ',
    nameEn: 'SABOTKOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5340',
    addressNomenclature: '0',
    x: '25.409863',
    y: '42.902884',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '70456',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЪБРАНО',
    nameEn: 'SABRANO',
    municipality: 'НОВА ЗАГОРА',
    municipalityEn: 'NOVA ZAGORA',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8941',
    addressNomenclature: '0',
    x: '25.898259',
    y: '42.43097',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '70473',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЪДИЕВО',
    nameEn: 'SADIEVO',
    municipality: 'АЙТОС',
    municipalityEn: 'AYTOS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8522',
    addressNomenclature: '0',
    x: '27.320483',
    y: '42.663904',
    servingDays: '1111110',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '70487',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЪДИЕВО',
    nameEn: 'SADIEVO',
    municipality: 'НОВА ЗАГОРА',
    municipalityEn: 'NOVA ZAGORA',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8923',
    addressNomenclature: '0',
    x: '26.078664',
    y: '42.52672',
    servingDays: '1010100',
    servingOfficeId: '56',
    servingHubOfficeId: '5',
  },
  {
    id: '70490',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЪДИЙСКО ПОЛЕ',
    nameEn: 'SADIYSKO POLE',
    municipality: 'НОВА ЗАГОРА',
    municipalityEn: 'NOVA ZAGORA',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8915',
    addressNomenclature: '0',
    x: '26.050707',
    y: '42.562012',
    servingDays: '1010100',
    servingOfficeId: '56',
    servingHubOfficeId: '5',
  },
  {
    id: '70514',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЪЕДИНЕНИЕ',
    nameEn: 'SAEDINENIE',
    municipality: 'СУНГУРЛАРЕ',
    municipalityEn: 'SUNGURLARE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8430',
    addressNomenclature: '0',
    x: '27.025611',
    y: '42.874984',
    servingDays: '1111100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '70528',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'СЪЕДИНЕНИЕ',
    nameEn: 'SAEDINENIE',
    municipality: 'СЪЕДИНЕНИЕ',
    municipalityEn: 'SAEDINENIE',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4190',
    addressNomenclature: '2',
    x: '24.542168',
    y: '42.268089',
    servingDays: '1111110',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '70531',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЪЕДИНЕНИЕ',
    nameEn: 'SAEDINENIE',
    municipality: 'БРАТЯ ДАСКАЛОВИ',
    municipalityEn: 'BRATYA DASKALOVI',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6234',
    addressNomenclature: '0',
    x: '25.307218',
    y: '42.371958',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '70545',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЪЕДИНЕНИЕ',
    nameEn: 'SAEDINENIE',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7779',
    addressNomenclature: '0',
    x: '26.622062',
    y: '43.317227',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '70559',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЪРНАК',
    nameEn: 'SARNAK',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6924',
    addressNomenclature: '0',
    x: '25.612201',
    y: '41.432078',
    servingDays: '1111100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '70562',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЪРНЕВЕЦ',
    nameEn: 'SARNEVETS',
    municipality: 'БРАТЯ ДАСКАЛОВИ',
    municipalityEn: 'BRATYA DASKALOVI',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6232',
    addressNomenclature: '0',
    x: '25.341958',
    y: '42.407055',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '70576',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЪРНЕВО',
    nameEn: 'SARNEVO',
    municipality: 'КАРНОБАТ',
    municipalityEn: 'KARNOBAT',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8479',
    addressNomenclature: '0',
    x: '27.0931',
    y: '42.475307',
    servingDays: '0101010',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '70586',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЪРНЕВО',
    nameEn: 'SARNEVO',
    municipality: 'РАДНЕВО',
    municipalityEn: 'RADNEVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6268',
    addressNomenclature: '0',
    x: '25.838876',
    y: '42.355989',
    servingDays: '1111100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '70593',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЪРНЕГОР',
    nameEn: 'SARNEGOR',
    municipality: 'БРЕЗОВО',
    municipalityEn: 'BREZOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4146',
    addressNomenclature: '0',
    x: '24.937123',
    y: '42.458706',
    servingDays: '0101000',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '70603',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЪРНЕНЦИ',
    nameEn: 'SARNENTSI',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5035',
    addressNomenclature: '0',
    x: '25.629204',
    y: '42.857696',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '70617',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЪРНЕЦ',
    nameEn: 'SARNETS',
    municipality: 'ТЕРВЕЛ',
    municipalityEn: 'TERVEL',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9482',
    addressNomenclature: '0',
    x: '27.500471',
    y: '43.815468',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '70620',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЪРНИНО',
    nameEn: 'SARNINO',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4799',
    addressNomenclature: '0',
    x: '24.700554',
    y: '41.44895',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '70634',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЪРНИНО',
    nameEn: 'SARNINO',
    municipality: 'ГЕНЕРАЛ ТОШЕВО',
    municipalityEn: 'GENERAL TOSHEVO',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9562',
    addressNomenclature: '0',
    x: '28.288461',
    y: '43.66872',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '70648',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'СЪРНИЦА',
    nameEn: 'SARNITSA',
    municipality: 'СЪРНИЦА',
    municipalityEn: 'SARNITSA',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4633',
    addressNomenclature: '0',
    x: '24.021814',
    y: '41.738657',
    servingDays: '1111100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '70651',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЪРНИЦА',
    nameEn: 'SARNITSA',
    municipality: 'МИНЕРАЛНИ БАНИ',
    municipalityEn: 'MINERALNI BANI',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6361',
    addressNomenclature: '0',
    x: '25.290027',
    y: '41.865592',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '70665',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЪРПОВО',
    nameEn: 'SARPOVO',
    municipality: 'СИЛИСТРА',
    municipalityEn: 'SILISTRA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7578',
    addressNomenclature: '0',
    x: '27.09166',
    y: '44.015715',
    servingDays: '1010100',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '70679',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЪРЦЕВО',
    nameEn: 'SARTSEVO',
    municipality: 'ТВЪРДИЦА',
    municipalityEn: 'TVARDITSA',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8896',
    addressNomenclature: '0',
    x: '26.066625',
    y: '42.606475',
    servingDays: '0101000',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '70682',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЯНОВО',
    nameEn: 'SYANOVO',
    municipality: 'ТУТРАКАН',
    municipalityEn: 'TUTRAKAN',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7647',
    addressNomenclature: '0',
    x: '26.501603',
    y: '43.944441',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '70696',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЯРОВЦИ',
    nameEn: 'SYAROVTSI',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5380',
    addressNomenclature: '0',
    x: '25.467407',
    y: '42.935283',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '70706',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'СЯРЦИ',
    nameEn: 'SYARTSI',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6800',
    addressNomenclature: '0',
    x: '25.345466',
    y: '41.554224',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '72014',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТАБАН',
    nameEn: 'TABAN',
    municipality: 'ДРАГОМАН',
    municipalityEn: 'DRAGOMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2215',
    addressNomenclature: '0',
    x: '22.891822',
    y: '42.88288',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '72028',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТАБАЧКА',
    nameEn: 'TABACHKA',
    municipality: 'ИВАНОВО',
    municipalityEn: 'IVANOVO',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7085',
    addressNomenclature: '0',
    x: '25.972824',
    y: '43.610633',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '72031',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТАБАШКА',
    nameEn: 'TABASHKA',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5461',
    addressNomenclature: '0',
    x: '25.001903',
    y: '42.830942',
    servingDays: '1010100',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '72045',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТАВАЛИЧЕВО',
    nameEn: 'TAVALICHEVO',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2538',
    addressNomenclature: '0',
    x: '22.852596',
    y: '42.315563',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '72062',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТАЙМИЩЕ',
    nameEn: 'TAYMISHTE',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7983',
    addressNomenclature: '0',
    x: '26.257823',
    y: '43.076309',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '72076',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТАМАРИНО',
    nameEn: 'TAMARINO',
    municipality: 'СТРАЛДЖА',
    municipalityEn: 'STRALDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8658',
    addressNomenclature: '0',
    x: '26.744778',
    y: '42.378486',
    servingDays: '0101000',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '72093',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТАТАРЕВО',
    nameEn: 'TATAREVO',
    municipality: 'ПЪРВОМАЙ',
    municipalityEn: 'PARVOMAY',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4282',
    addressNomenclature: '0',
    x: '25.126306',
    y: '42.082999',
    servingDays: '1010100',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '72103',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТАТАРЕВО',
    nameEn: 'TATAREVO',
    municipality: 'МИНЕРАЛНИ БАНИ',
    municipalityEn: 'MINERALNI BANI',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6344',
    addressNomenclature: '0',
    x: '25.398914',
    y: '41.946248',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '72117',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТАТАРИ',
    nameEn: 'TATARI',
    municipality: 'БЕЛЕНЕ',
    municipalityEn: 'BELENE',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5934',
    addressNomenclature: '0',
    x: '25.167862',
    y: '43.56258',
    servingDays: '1010100',
    servingOfficeId: '108',
    servingHubOfficeId: '11',
  },
  {
    id: '72120',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТАТКОВО',
    nameEn: 'TATKOVO',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6668',
    addressNomenclature: '0',
    x: '25.578618',
    y: '41.730093',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '72134',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТАТУЛ',
    nameEn: 'TATUL',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6835',
    addressNomenclature: '0',
    x: '25.533876',
    y: '41.547544',
    servingDays: '1111110',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '72148',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТВЪРДИНЦИ',
    nameEn: 'TVARDINTSI',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7755',
    addressNomenclature: '0',
    x: '26.773363',
    y: '43.384769',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '72151',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТВЪРДИЦА',
    nameEn: 'TVARDITSA',
    municipality: 'БУРГАС',
    municipalityEn: 'BURGAS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8155',
    addressNomenclature: '0',
    x: '27.455522',
    y: '42.407936',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '72165',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ТВЪРДИЦА',
    nameEn: 'TVARDITSA',
    municipality: 'ТВЪРДИЦА',
    municipalityEn: 'TVARDITSA',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8890',
    addressNomenclature: '2',
    x: '25.897836',
    y: '42.701395',
    servingDays: '1111100',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '72179',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТВЪРДИЦА',
    nameEn: 'TVARDITSA',
    municipality: 'ШАБЛА',
    municipalityEn: 'SHABLA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9675',
    addressNomenclature: '0',
    x: '28.460346',
    y: '43.61357',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '72182',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЕКЕТО',
    nameEn: 'TEKETO',
    municipality: 'ХАСКОВО',
    municipalityEn: 'HASKOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6370',
    addressNomenclature: '0',
    x: '25.466576',
    y: '41.853583',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '72196',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЕЛЕРИГ',
    nameEn: 'TELERIG',
    municipality: 'КРУШАРИ',
    municipalityEn: 'KRUSHARI',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9420',
    addressNomenclature: '0',
    x: '27.670072',
    y: '43.84391',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '72206',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЕЛИШ',
    nameEn: 'TELISH',
    municipality: 'ЧЕРВЕН БРЯГ',
    municipalityEn: 'CHERVEN BRYAG',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5990',
    addressNomenclature: '0',
    x: '24.261185',
    y: '43.326823',
    servingDays: '1010100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '72211',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЕЛЧАРКА',
    nameEn: 'TELCHARKA',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6850',
    addressNomenclature: '0',
    x: '25.275166',
    y: '41.488678',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '47809',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЕМЕНУГА',
    nameEn: 'TEMENUGA',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6759',
    addressNomenclature: '0',
    x: '25.165795',
    y: '41.521414',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '72237',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЕМЕНУГА',
    nameEn: 'TEMENUGA',
    municipality: 'СТРАЖИЦА',
    municipalityEn: 'STRAZHITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5165',
    addressNomenclature: '0',
    x: '26.006169',
    y: '43.108191',
    servingDays: '1010100',
    servingOfficeId: '46',
    servingHubOfficeId: '11',
  },
  {
    id: '72240',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЕНЕВО',
    nameEn: 'TENEVO',
    municipality: 'ТУНДЖА',
    municipalityEn: 'TUNDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8672',
    addressNomenclature: '0',
    x: '26.529854',
    y: '42.347245',
    servingDays: '1111110',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '72254',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЕПАВА',
    nameEn: 'TEPAVA',
    municipality: 'ЛОВЕЧ',
    municipalityEn: 'LOVECH',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5554',
    addressNomenclature: '0',
    x: '24.894501',
    y: '43.174092',
    servingDays: '0101000',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '72268',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЕПЛЕН',
    nameEn: 'TEPLEN',
    municipality: 'ХАДЖИДИМОВО',
    municipalityEn: 'HADZHIDIMOVO',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2935',
    addressNomenclature: '0',
    x: '23.937183',
    y: '41.485134',
    servingDays: '1010100',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '72271',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ТЕРВЕЛ',
    nameEn: 'TERVEL',
    municipality: 'ТЕРВЕЛ',
    municipalityEn: 'TERVEL',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9450',
    addressNomenclature: '2',
    x: '27.406474',
    y: '43.748578',
    servingDays: '1111100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '72285',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЕРВЕЛ',
    nameEn: 'TERVEL',
    municipality: 'ХИТРИНО',
    municipalityEn: 'HITRINO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9769',
    addressNomenclature: '0',
    x: '26.758296',
    y: '43.42748',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '72312',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЕРЗИИТЕ',
    nameEn: 'TERZIITE',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5035',
    addressNomenclature: '0',
    x: '25.675023',
    y: '42.842791',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '72299',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЕРЗИЙСКО',
    nameEn: 'TERZIYSKO',
    municipality: 'СУНГУРЛАРЕ',
    municipalityEn: 'SUNGURLARE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8465',
    addressNomenclature: '0',
    x: '26.763306',
    y: '42.680617',
    servingDays: '0101000',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '72309',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЕРЗИЙСКО',
    nameEn: 'TERZIYSKO',
    municipality: 'ТРОЯН',
    municipalityEn: 'TROYAN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5665',
    addressNomenclature: '0',
    x: '24.608523',
    y: '42.892152',
    servingDays: '1010100',
    servingOfficeId: '44',
    servingHubOfficeId: '11',
  },
  {
    id: '72337',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЕРТЕР',
    nameEn: 'TERTER',
    municipality: 'КУБРАТ',
    municipalityEn: 'KUBRAT',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7322',
    addressNomenclature: '0',
    x: '26.557564',
    y: '43.887985',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '72343',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ТЕТЕВЕН',
    nameEn: 'TETEVEN',
    municipality: 'ТЕТЕВЕН',
    municipalityEn: 'TETEVEN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5700',
    addressNomenclature: '2',
    x: '24.264525',
    y: '42.917565',
    servingDays: '1111110',
    servingOfficeId: '88',
    servingHubOfficeId: '2',
  },
  {
    id: '72357',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЕТОВО',
    nameEn: 'TETOVO',
    municipality: 'РУСЕ',
    municipalityEn: 'RUSE',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7044',
    addressNomenclature: '0',
    x: '26.288757',
    y: '43.817171',
    servingDays: '1111100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '72360',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЕШЕЛ',
    nameEn: 'TESHEL',
    municipality: 'ДЕВИН',
    municipalityEn: 'DEVIN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4810',
    addressNomenclature: '0',
    x: '24.351818',
    y: '41.672741',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '72374',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЕШОВО',
    nameEn: 'TESHOVO',
    municipality: 'ХАДЖИДИМОВО',
    municipalityEn: 'HADZHIDIMOVO',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2927',
    addressNomenclature: '0',
    x: '23.692892',
    y: '41.466426',
    servingDays: '1010100',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '72391',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТИКАЛЕ',
    nameEn: 'TIKALE',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4729',
    addressNomenclature: '0',
    x: '24.829901',
    y: '41.600701',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '72401',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТИМАРЕВО',
    nameEn: 'TIMAREVO',
    municipality: 'ХИТРИНО',
    municipalityEn: 'HITRINO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9785',
    addressNomenclature: '0',
    x: '26.890083',
    y: '43.401904',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '72429',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТИНТЯВА',
    nameEn: 'TINTYAVA',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6955',
    addressNomenclature: '0',
    x: '25.786684',
    y: '41.423311',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '72432',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТИПЧЕНИЦА',
    nameEn: 'TIPCHENITSA',
    municipality: 'МЕЗДРА',
    municipalityEn: 'MEZDRA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3164',
    addressNomenclature: '0',
    x: '23.709328',
    y: '43.065475',
    servingDays: '1010100',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '72446',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТИТЕВЦИ',
    nameEn: 'TITEVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5070',
    addressNomenclature: '0',
    x: '25.839256',
    y: '42.941412',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '72452',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТИХОВЕЦ',
    nameEn: 'TIHOVETS',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7997',
    addressNomenclature: '0',
    x: '26.170512',
    y: '43.067717',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '72463',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТИХОМИР',
    nameEn: 'TIHOMIR',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6898',
    addressNomenclature: '0',
    x: '25.498963',
    y: '41.307446',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '72477',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТИХОМИРОВО',
    nameEn: 'TIHOMIROVO',
    municipality: 'РАДНЕВО',
    municipalityEn: 'RADNEVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6279',
    addressNomenclature: '0',
    x: '25.857298',
    y: '42.267689',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '72480',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТИЧА',
    nameEn: 'TICHA',
    municipality: 'КОТЕЛ',
    municipalityEn: 'KOTEL',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8980',
    addressNomenclature: '0',
    x: '26.438564',
    y: '42.970627',
    servingDays: '1010100',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '72494',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТИШАНОВО',
    nameEn: 'TISHANOVO',
    municipality: 'НЕВЕСТИНО',
    municipalityEn: 'NEVESTINO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2591',
    addressNomenclature: '0',
    x: '22.814365',
    y: '42.100327',
    servingDays: '1010100',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '72504',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТИШЕВИЦА',
    nameEn: 'TISHEVITSA',
    municipality: 'ВРАЦА',
    municipalityEn: 'VRATSA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3055',
    addressNomenclature: '0',
    x: '23.753803',
    y: '43.26514',
    servingDays: '1010100',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '72518',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТИЯНОВЦИ',
    nameEn: 'TIYANOVTSI',
    municipality: 'БРЕГОВО',
    municipalityEn: 'BREGOVO',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3787',
    addressNomenclature: '0',
    x: '22.72943',
    y: '44.078162',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '72521',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЛАЧЕНЕ',
    nameEn: 'TLACHENE',
    municipality: 'БЯЛА СЛАТИНА',
    municipalityEn: 'BYALA SLATINA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3243',
    addressNomenclature: '0',
    x: '23.84741',
    y: '43.342194',
    servingDays: '0101000',
    servingOfficeId: '59',
    servingHubOfficeId: '20',
  },
  {
    id: '72549',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТОДОР ИКОНОМОВО',
    nameEn: 'TODOR IKONOMOVO',
    municipality: 'КАОЛИНОВО',
    municipalityEn: 'KAOLINOVO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9964',
    addressNomenclature: '0',
    x: '27.156823',
    y: '43.637224',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '72535',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТОДОРЕЦИТЕ',
    nameEn: 'TODORETSITE',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5350',
    addressNomenclature: '0',
    x: '25.477716',
    y: '42.904081',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '72552',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТОДОРИЧЕНЕ',
    nameEn: 'TODORICHENE',
    municipality: 'ЛУКОВИТ',
    municipalityEn: 'LUKOVIT',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5781',
    addressNomenclature: '0',
    x: '24.178546',
    y: '43.164437',
    servingDays: '1010100',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '72566',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТОДОРОВО',
    nameEn: 'TODOROVO',
    municipality: 'ПЛЕВЕН',
    municipalityEn: 'PLEVEN',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5887',
    addressNomenclature: '0',
    x: '24.603063',
    y: '43.339782',
    servingDays: '0101000',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '72578',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТОДОРОВО',
    nameEn: 'TODOROVO',
    municipality: 'ИСПЕРИХ',
    municipalityEn: 'ISPERIH',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7425',
    addressNomenclature: '0',
    x: '26.930214',
    y: '43.728434',
    servingDays: '1010100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '72583',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТОДОРОВЦИ',
    nameEn: 'TODOROVTSI',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5045',
    addressNomenclature: '0',
    x: '25.70789',
    y: '42.876332',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '72597',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТОДОРОВЦИ',
    nameEn: 'TODOROVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5301',
    addressNomenclature: '0',
    x: '25.347745',
    y: '42.833543',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '81637',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТОДОРЧЕТА',
    nameEn: 'TODORCHETA',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5343',
    addressNomenclature: '0',
    x: '25.394564',
    y: '42.924615',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '72607',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТОДЮВЦИ',
    nameEn: 'TODYUVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5094',
    addressNomenclature: '0',
    x: '25.832915',
    y: '42.867266',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '72610',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТОКАЧКА',
    nameEn: 'TOKACHKA',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6965',
    addressNomenclature: '0',
    x: '25.524753',
    y: '41.382042',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '72638',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТОЛОВИЦА',
    nameEn: 'TOLOVITSA',
    municipality: 'МАКРЕШ',
    municipalityEn: 'MAKRESH',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3854',
    addressNomenclature: '0',
    x: '22.579722',
    y: '43.75268',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '72641',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТОМБЕТО',
    nameEn: 'TOMBETO',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5080',
    addressNomenclature: '0',
    x: '26.119798',
    y: '42.952606',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '72655',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТОМПСЪН',
    nameEn: 'TOMPSAN',
    municipality: 'СВОГЕ',
    municipalityEn: 'SVOGE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2285',
    addressNomenclature: '0',
    x: '23.3833',
    y: '42.928864',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '72669',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТОМЧЕВЦИ',
    nameEn: 'TOMCHEVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5365',
    addressNomenclature: '0',
    x: '25.516794',
    y: '42.906497',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '72672',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТОНСКО ДАБЕ',
    nameEn: 'TONSKO DABE',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2875',
    addressNomenclature: '0',
    x: '23.130739',
    y: '41.501954',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '72686',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТОПИЛИЩА',
    nameEn: 'TOPILISHTA',
    municipality: 'ТЕТЕВЕН',
    municipalityEn: 'TETEVEN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '',
    addressNomenclature: '0',
    x: '24.146263',
    y: '42.994608',
    servingDays: '0101000',
    servingOfficeId: '88',
    servingHubOfficeId: '2',
  },
  {
    id: '72693',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТОПОЛА',
    nameEn: 'TOPOLA',
    municipality: 'КАВАРНА',
    municipalityEn: 'KAVARNA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9654',
    addressNomenclature: '0',
    x: '28.265588',
    y: '43.420348',
    servingDays: '1111100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '72709',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТОПОЛИ',
    nameEn: 'TOPOLI',
    municipality: 'ВАРНА',
    municipalityEn: 'VARNA',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9140',
    addressNomenclature: '0',
    x: '27.821794',
    y: '43.218643',
    servingDays: '1111110',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '72713',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТОПОЛИ ДОЛ',
    nameEn: 'TOPOLI DOL',
    municipality: 'ПАЗАРДЖИК',
    municipalityEn: 'PAZARDZHIK',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4454',
    addressNomenclature: '0',
    x: '24.457863',
    y: '42.335144',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '72727',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТОПОЛИЦА',
    nameEn: 'TOPOLITSA',
    municipality: 'АЙТОС',
    municipalityEn: 'AYTOS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8549',
    addressNomenclature: '0',
    x: '27.123518',
    y: '42.735884',
    servingDays: '1111100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '72730',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТОПОЛКА',
    nameEn: 'TOPOLKA',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6968',
    addressNomenclature: '0',
    x: '25.555668',
    y: '41.391216',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '72744',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТОПОЛНИЦА',
    nameEn: 'TOPOLNITSA',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2858',
    addressNomenclature: '0',
    x: '23.322571',
    y: '41.406349',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '72758',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТОПОЛНИЦА',
    nameEn: 'TOPOLNITSA',
    municipality: 'ДУПНИЦА',
    municipalityEn: 'DUPNITSA',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2619',
    addressNomenclature: '0',
    x: '23.131746',
    y: '42.35356',
    servingDays: '0101000',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '72761',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ТОПОЛОВГРАД',
    nameEn: 'TOPOLOVGRAD',
    municipality: 'ТОПОЛОВГРАД',
    municipalityEn: 'TOPOLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6560',
    addressNomenclature: '2',
    x: '26.33319',
    y: '42.085581',
    servingDays: '1111100',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '72775',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТОПОЛОВЕЦ',
    nameEn: 'TOPOLOVETS',
    municipality: 'КУЛА',
    municipalityEn: 'KULA',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3828',
    addressNomenclature: '0',
    x: '22.6212',
    y: '43.985727',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '73924',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТОПОЛОВЕЦ',
    nameEn: 'TOPOLOVETS',
    municipality: 'РУЖИНЦИ',
    municipalityEn: 'RUZHINTSI',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3936',
    addressNomenclature: '0',
    x: '23.010803',
    y: '43.697551',
    servingDays: '1010100',
    servingOfficeId: '831',
    servingHubOfficeId: '20',
  },
  {
    id: '72789',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТОПОЛОВО',
    nameEn: 'TOPOLOVO',
    municipality: 'АСЕНОВГРАД',
    municipalityEn: 'ASENOVGRAD',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4260',
    addressNomenclature: '0',
    x: '25.005299',
    y: '41.902331',
    servingDays: '0101000',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '72792',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТОПОЛОВО',
    nameEn: 'TOPOLOVO',
    municipality: 'МАДЖАРОВО',
    municipalityEn: 'MADZHAROVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6452',
    addressNomenclature: '0',
    x: '25.770352',
    y: '41.678542',
    servingDays: '0101000',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '72802',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТОПОЛЧАНЕ',
    nameEn: 'TOPOLCHANE',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6728',
    addressNomenclature: '0',
    x: '25.315316',
    y: '41.624944',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '72816',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТОПОЛЧАНЕ',
    nameEn: 'TOPOLCHANE',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8880',
    addressNomenclature: '2',
    x: '26.427975',
    y: '42.652567',
    servingDays: '0101000',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '72824',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТОПОЛЯНЕ',
    nameEn: 'TOPOLYANE',
    municipality: 'РАДНЕВО',
    municipalityEn: 'RADNEVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6277',
    addressNomenclature: '0',
    x: '25.872982',
    y: '42.298656',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '72833',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТОПУЗЕВО',
    nameEn: 'TOPUZEVO',
    municipality: 'КОТЕЛ',
    municipalityEn: 'KOTEL',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8986',
    addressNomenclature: '0',
    x: '26.497552',
    y: '42.994447',
    servingDays: '1010100',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '72847',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТОПУЗИ',
    nameEn: 'TOPUZI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5098',
    addressNomenclature: '0',
    x: '25.920723',
    y: '42.842361',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '72850',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТОПЧИИ',
    nameEn: 'TOPCHII',
    municipality: 'РАЗГРАД',
    municipalityEn: 'RAZGRAD',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7261',
    addressNomenclature: '0',
    x: '26.508991',
    y: '43.670237',
    servingDays: '1010100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '72864',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТОПЧИЙСКО',
    nameEn: 'TOPCHIYSKO',
    municipality: 'РУЕН',
    municipalityEn: 'RUEN',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8582',
    addressNomenclature: '0',
    x: '27.34432',
    y: '42.851504',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '72878',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТОРБАЛЪЖИТЕ',
    nameEn: 'TORBALAZHITE',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5335',
    addressNomenclature: '0',
    x: '25.418354',
    y: '42.859756',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '43058',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТОРОС',
    nameEn: 'TOROS',
    municipality: 'ЛУКОВИТ',
    municipalityEn: 'LUKOVIT',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5790',
    addressNomenclature: '0',
    x: '24.278612',
    y: '43.09687',
    servingDays: '1010100',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '72881',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТОТЛЕБЕН',
    nameEn: 'TOTLEBEN',
    municipality: 'ПОРДИМ',
    municipalityEn: 'PORDIM',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5899',
    addressNomenclature: '0',
    x: '24.909123',
    y: '43.439343',
    servingDays: '1010100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '72895',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТОЧИЛАРИ',
    nameEn: 'TOCHILARI',
    municipality: 'КУБРАТ',
    municipalityEn: 'KUBRAT',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7316',
    addressNomenclature: '0',
    x: '26.474204',
    y: '43.846071',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '72919',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТОШЕВЦИ',
    nameEn: 'TOSHEVTSI',
    municipality: 'ГРАМАДА',
    municipalityEn: 'GRAMADA',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3825',
    addressNomenclature: '0',
    x: '22.625372',
    y: '43.81466',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '72922',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТРАВЕ',
    nameEn: 'TRAVE',
    municipality: 'БАНИТЕ',
    municipalityEn: 'BANITE',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4933',
    addressNomenclature: '0',
    x: '25.009947',
    y: '41.648493',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '72936',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТРАВНИК',
    nameEn: 'TRAVNIK',
    municipality: 'КАВАРНА',
    municipalityEn: 'KAVARNA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9694',
    addressNomenclature: '0',
    x: '28.275099',
    y: '43.595236',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '72940',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТРАЙКОВО',
    nameEn: 'TRAYKOVO',
    municipality: 'ЛОМ',
    municipalityEn: 'LOM',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3645',
    addressNomenclature: '0',
    x: '23.204513',
    y: '43.749618',
    servingDays: '1010100',
    servingOfficeId: '831',
    servingHubOfficeId: '20',
  },
  {
    id: '72953',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТРАКИЕЦ',
    nameEn: 'TRAKIETS',
    municipality: 'ХАСКОВО',
    municipalityEn: 'HASKOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6369',
    addressNomenclature: '0',
    x: '25.45292',
    y: '41.86743',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '72967',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТРАКИЙЦИ',
    nameEn: 'TRAKIYTSI',
    municipality: 'СРЕДЕЦ',
    municipalityEn: 'SREDETS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8348',
    addressNomenclature: '0',
    x: '27.250782',
    y: '42.165805',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '72970',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТРАКИЯ',
    nameEn: 'TRAKIA',
    municipality: 'ОПАН',
    municipalityEn: 'OPAN',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6074',
    addressNomenclature: '0',
    x: '25.639973',
    y: '42.197941',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '72998',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТРАПЕСКОВЦИ',
    nameEn: 'TRAPESKOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5349',
    addressNomenclature: '0',
    x: '25.419403',
    y: '42.882268',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '73002',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТРАПИЩЕ',
    nameEn: 'TRAPISHTE',
    municipality: 'ЛОЗНИЦА',
    municipalityEn: 'LOZNITSA',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7281',
    addressNomenclature: '0',
    x: '26.536297',
    y: '43.377231',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '73016',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТРАПОКЛОВО',
    nameEn: 'TRAPOKLOVO',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8889',
    addressNomenclature: '0',
    x: '26.593753',
    y: '42.645578',
    servingDays: '1010100',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '73033',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТРЕБИЩЕ',
    nameEn: 'TREBISHTE',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4737',
    addressNomenclature: '0',
    x: '24.900588',
    y: '41.563732',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '73047',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТРЕКЛЯНО',
    nameEn: 'TREKLYANO',
    municipality: 'ТРЕКЛЯНО',
    municipalityEn: 'TREKLYANO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2557',
    addressNomenclature: '0',
    x: '22.598038',
    y: '42.551003',
    servingDays: '0101000',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '73050',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТРЕМ',
    nameEn: 'TREM',
    municipality: 'ХИТРИНО',
    municipalityEn: 'HITRINO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9764',
    addressNomenclature: '0',
    x: '26.807319',
    y: '43.446824',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '73078',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТРЕСКАВЕЦ',
    nameEn: 'TRESKAVETS',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7994',
    addressNomenclature: '0',
    x: '26.320934',
    y: '43.164798',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '73119',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТРИ КЛАДЕНЦИ',
    nameEn: 'TRI KLADENTSI',
    municipality: 'ВРАЦА',
    municipalityEn: 'VRATSA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3072',
    addressNomenclature: '0',
    x: '23.594347',
    y: '43.444455',
    servingDays: '0101000',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '73136',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТРИ МОГИЛИ',
    nameEn: 'TRI MOGILI',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6682',
    addressNomenclature: '0',
    x: '25.400643',
    y: '41.739311',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '73147',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТРИ МОГИЛИ',
    nameEn: 'TRI MOGILI',
    municipality: 'АСЕНОВГРАД',
    municipalityEn: 'ASENOVGRAD',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4263',
    addressNomenclature: '0',
    x: '25.06377',
    y: '41.82314',
    servingDays: '0101000',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '73081',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТРИВОДИЦИ',
    nameEn: 'TRIVODITSI',
    municipality: 'СТАМБОЛИЙСКИ',
    municipalityEn: 'STAMBOLIYSKI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4222',
    addressNomenclature: '0',
    x: '24.466492',
    y: '42.134239',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '73095',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТРИГОРЦИ',
    nameEn: 'TRIGORTSI',
    municipality: 'БАЛЧИК',
    municipalityEn: 'BALCHIK',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9645',
    addressNomenclature: '0',
    x: '28.197375',
    y: '43.535019',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '73105',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТРИГРАД',
    nameEn: 'TRIGRAD',
    municipality: 'ДЕВИН',
    municipalityEn: 'DEVIN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4825',
    addressNomenclature: '0',
    x: '24.379009',
    y: '41.598616',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '73122',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТРИЛИСТНИК',
    nameEn: 'TRILISTNIK',
    municipality: 'МАРИЦА',
    municipalityEn: 'MARITSA',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4141',
    addressNomenclature: '0',
    x: '24.873011',
    y: '42.20384',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '73167',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТРИФОНОВО',
    nameEn: 'TRIFONOVO',
    municipality: 'МОНТАНА',
    municipalityEn: 'MONTANA',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3435',
    addressNomenclature: '0',
    x: '23.310425',
    y: '43.371459',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '73170',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТРОИЦА',
    nameEn: 'TROITSA',
    municipality: 'ВЕЛИКИ ПРЕСЛАВ',
    municipalityEn: 'VELIKI PRESLAV',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9864',
    addressNomenclature: '0',
    x: '26.871474',
    y: '43.217596',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '73184',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТРОСКОВО',
    nameEn: 'TROSKOVO',
    municipality: 'СИМИТЛИ',
    municipalityEn: 'SIMITLI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2733',
    addressNomenclature: '0',
    x: '23.04689',
    y: '41.87003',
    servingDays: '1111110',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '73198',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ТРОЯН',
    nameEn: 'TROYAN',
    municipality: 'ТРОЯН',
    municipalityEn: 'TROYAN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5600',
    addressNomenclature: '2',
    x: '24.710508',
    y: '42.891494',
    servingDays: '1111110',
    servingOfficeId: '44',
    servingHubOfficeId: '11',
  },
  {
    id: '73208',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТРОЯН',
    nameEn: 'TROYAN',
    municipality: 'СИМЕОНОВГРАД',
    municipalityEn: 'SIMEONOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6491',
    addressNomenclature: '0',
    x: '25.906623',
    y: '42.061653',
    servingDays: '0101000',
    servingOfficeId: '42',
    servingHubOfficeId: '5',
  },
  {
    id: '73211',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТРОЯНОВО',
    nameEn: 'TROYANOVO',
    municipality: 'КАМЕНО',
    municipalityEn: 'KAMENO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8108',
    addressNomenclature: '0',
    x: '27.14497',
    y: '42.554694',
    servingDays: '1111100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '73225',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТРОЯНОВО',
    nameEn: 'TROYANOVO',
    municipality: 'РАДНЕВО',
    municipalityEn: 'RADNEVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6270',
    addressNomenclature: '0',
    x: '25.953048',
    y: '42.19754',
    servingDays: '1111110',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '73242',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТРУД',
    nameEn: 'TRUD',
    municipality: 'МАРИЦА',
    municipalityEn: 'MARITSA',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4199',
    addressNomenclature: '0',
    x: '24.724754',
    y: '42.230969',
    servingDays: '1111110',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '73256',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТРУДОВЕЦ',
    nameEn: 'TRUDOVETS',
    municipality: 'БОТЕВГРАД',
    municipalityEn: 'BOTEVGRAD',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2160',
    addressNomenclature: '0',
    x: '23.837338',
    y: '42.914733',
    servingDays: '1111100',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '73262',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТРЪБАЧ',
    nameEn: 'TRABACH',
    municipality: 'ЛОЗНИЦА',
    municipalityEn: 'LOZNITSA',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7283',
    addressNomenclature: '0',
    x: '26.496053',
    y: '43.377979',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '73273',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ТРЪН',
    nameEn: 'TRAN',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2460',
    addressNomenclature: '0',
    x: '22.652596',
    y: '42.834751',
    servingDays: '1111100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '73287',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТРЪНАК',
    nameEn: 'TRANAK',
    municipality: 'РУЕН',
    municipalityEn: 'RUEN',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8550',
    addressNomenclature: '0',
    x: '27.211732',
    y: '42.944174',
    servingDays: '1111100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '73290',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТРЪНИТО',
    nameEn: 'TRANITO',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5307',
    addressNomenclature: '0',
    x: '25.23566',
    y: '42.867529',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '73300',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТРЪНИЦА',
    nameEn: 'TRANITSA',
    municipality: 'НОВИ ПАЗАР',
    municipalityEn: 'NOVI PAZAR',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9925',
    addressNomenclature: '0',
    x: '27.319769',
    y: '43.461057',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '73314',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТРЪНКОВО',
    nameEn: 'TRANKOVO',
    municipality: 'РАДНЕВО',
    municipalityEn: 'RADNEVO',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6266',
    addressNomenclature: '0',
    x: '25.810645',
    y: '42.289814',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '73328',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТРЪНКОВО',
    nameEn: 'TRANKOVO',
    municipality: 'ЕЛХОВО',
    municipalityEn: 'ELHOVO',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8719',
    addressNomenclature: '0',
    x: '26.551608',
    y: '42.224099',
    servingDays: '0101000',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '73331',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТРЪНКОВЦИ',
    nameEn: 'TRANKOVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5097',
    addressNomenclature: '0',
    x: '25.918767',
    y: '42.879705',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '73345',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТРЪНЧОВИЦА',
    nameEn: 'TRANCHOVITSA',
    municipality: 'ЛЕВСКИ',
    municipalityEn: 'LEVSKI',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5925',
    addressNomenclature: '0',
    x: '24.99684',
    y: '43.485834',
    servingDays: '1010100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '73359',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ТРЪСТЕНИК',
    nameEn: 'TRASTENIK',
    municipality: 'ДОЛНА МИТРОПОЛИЯ',
    municipalityEn: 'DOLNA MITROPOLIA',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5857',
    addressNomenclature: '0',
    x: '24.471607',
    y: '43.520652',
    servingDays: '1111100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '73362',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТРЪСТЕНИК',
    nameEn: 'TRASTENIK',
    municipality: 'ИВАНОВО',
    municipalityEn: 'IVANOVO',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7092',
    addressNomenclature: '0',
    x: '25.857558',
    y: '43.664395',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '73376',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТРЪСТИКА',
    nameEn: 'TRASTIKA',
    municipality: 'ПОПОВО',
    municipalityEn: 'POPOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7835',
    addressNomenclature: '0',
    x: '26.412169',
    y: '43.428857',
    servingDays: '0101000',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '73388',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТРЪСТИКОВО',
    nameEn: 'TRASTIKOVO',
    municipality: 'КАМЕНО',
    municipalityEn: 'KAMENO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8128',
    addressNomenclature: '0',
    x: '27.261902',
    y: '42.427263',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '73393',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТРЪСТИКОВО',
    nameEn: 'TRASTIKOVO',
    municipality: 'АВРЕН',
    municipalityEn: 'AVREN',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9134',
    addressNomenclature: '0',
    x: '27.594229',
    y: '43.144687',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '73403',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ТРЯВНА',
    nameEn: 'TRYAVNA',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5350',
    addressNomenclature: '2',
    x: '25.491912',
    y: '42.866101',
    servingDays: '1111110',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '73417',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТУДЕН',
    nameEn: 'TUDEN',
    municipality: 'ГОДЕЧ',
    municipalityEn: 'GODECH',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2253',
    addressNomenclature: '0',
    x: '22.992836',
    y: '43.009',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '92076',
    countryId: '100',
    mainSiteId: '0',
    type: 'к.',
    typeEn: 'k.',
    name: 'ТУЗЛАТА',
    nameEn: 'TUZLATA',
    municipality: 'БАЛЧИК',
    municipalityEn: 'BALCHIK',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9633',
    addressNomenclature: '0',
    x: '28.223687',
    y: '43.402366',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '73420',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТУЛОВО',
    nameEn: 'TULOVO',
    municipality: 'МЪГЛИЖ',
    municipalityEn: 'MAGLIZH',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6170',
    addressNomenclature: '0',
    x: '25.549893',
    y: '42.574034',
    servingDays: '0101000',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '73434',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТУМБАЛОВО',
    nameEn: 'TUMBALOVO',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5464',
    addressNomenclature: '0',
    x: '25.094389',
    y: '42.864448',
    servingDays: '1010100',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '73448',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТУМБЕВЦИ',
    nameEn: 'TUMBEVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5083',
    addressNomenclature: '0',
    x: '26.003425',
    y: '42.909323',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '73451',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТУРИЯ',
    nameEn: 'TURIA',
    municipality: 'ПАВЕЛ БАНЯ',
    municipalityEn: 'PAVEL BANYA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6157',
    addressNomenclature: '0',
    x: '25.185358',
    y: '42.572364',
    servingDays: '1010100',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '73465',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТУРКИНЧА',
    nameEn: 'TURKINCHA',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5388',
    addressNomenclature: '0',
    x: '25.484795',
    y: '43.017916',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '73479',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТУРОКОВЦИ',
    nameEn: 'TUROKOVTSI',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2468',
    addressNomenclature: '0',
    x: '22.604634',
    y: '42.823465',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '73482',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТУРЯН',
    nameEn: 'TURYAN',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4753',
    addressNomenclature: '0',
    x: '24.67488',
    y: '41.535487',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '73496',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ТУТРАКАН',
    nameEn: 'TUTRAKAN',
    municipality: 'ТУТРАКАН',
    municipalityEn: 'TUTRAKAN',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7600',
    addressNomenclature: '2',
    x: '26.616049',
    y: '44.046753',
    servingDays: '1111100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '73506',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТУТРАКАНЦИ',
    nameEn: 'TUTRAKANTSI',
    municipality: 'ПРОВАДИЯ',
    municipalityEn: 'PROVADIA',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9275',
    addressNomenclature: '0',
    x: '27.439657',
    y: '43.118935',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '73519',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТУХОВИЩА',
    nameEn: 'TUHOVISHTA',
    municipality: 'САТОВЧА',
    municipalityEn: 'SATOVCHA',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2939',
    addressNomenclature: '0',
    x: '24.04463',
    y: '41.498524',
    servingDays: '1111100',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '73523',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТУЧЕНИЦА',
    nameEn: 'TUCHENITSA',
    municipality: 'ПЛЕВЕН',
    municipalityEn: 'PLEVEN',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5892',
    addressNomenclature: '0',
    x: '24.69419',
    y: '43.34047',
    servingDays: '0101000',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '73537',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТУШОВИЦА',
    nameEn: 'TUSHOVITSA',
    municipality: 'ВЪРБИЦА',
    municipalityEn: 'VARBITSA',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9884',
    addressNomenclature: '0',
    x: '26.807342',
    y: '43.005727',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '73540',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЪЖА',
    nameEn: 'TAZHA',
    municipality: 'ПАВЕЛ БАНЯ',
    municipalityEn: 'PAVEL BANYA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6160',
    addressNomenclature: '0',
    x: '25.083465',
    y: '42.650475',
    servingDays: '1010100',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '73554',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЪКАЧ',
    nameEn: 'TAKACH',
    municipality: 'КАОЛИНОВО',
    municipalityEn: 'KAOLINOVO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9953',
    addressNomenclature: '0',
    x: '27.035247',
    y: '43.59132',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '73883',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЪНКА БАРА',
    nameEn: 'TANKA BARA',
    municipality: 'НЕДЕЛИНО',
    municipalityEn: 'NEDELINO',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4983',
    addressNomenclature: '0',
    x: '25.145854',
    y: '41.473558',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '73568',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЪНКИ РЪТ',
    nameEn: 'TANKI RAT',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5084',
    addressNomenclature: '0',
    x: '25.99518',
    y: '42.995221',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '73571',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЪНКОВО',
    nameEn: 'TANKOVO',
    municipality: 'НЕСЕБЪР',
    municipalityEn: 'NESEBAR',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8237',
    addressNomenclature: '0',
    x: '27.649648',
    y: '42.694389',
    servingDays: '1111100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '73585',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЪНКОВО',
    nameEn: 'TANKOVO',
    municipality: 'СТАМБОЛОВО',
    municipalityEn: 'STAMBOLOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6391',
    addressNomenclature: '0',
    x: '25.773504',
    y: '41.74282',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '73599',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЪНКОТО',
    nameEn: 'TANKOTO',
    municipality: 'МАДАН',
    municipalityEn: 'MADAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4914',
    addressNomenclature: '0',
    x: '24.96219',
    y: '41.502202',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '73609',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЪПЧИЛЕЩОВО',
    nameEn: 'TAPCHILESHTOVO',
    municipality: 'ОМУРТАГ',
    municipalityEn: 'OMURTAG',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7919',
    addressNomenclature: '0',
    x: '26.462453',
    y: '43.121756',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '73612',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЪРГОВИЩЕ',
    nameEn: 'TARGOVISHTE',
    municipality: 'ЧУПРЕНЕ',
    municipalityEn: 'CHUPRENE',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3953',
    addressNomenclature: '0',
    x: '22.703115',
    y: '43.532705',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '73626',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ТЪРГОВИЩЕ',
    nameEn: 'TARGOVISHTE',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7700',
    addressNomenclature: '1',
    x: '26.572074',
    y: '43.241386',
    servingDays: '1111110',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '73634',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЪРКАШЕНИ',
    nameEn: 'TARKASHENI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5070',
    addressNomenclature: '0',
    x: '25.864164',
    y: '42.959144',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '73897',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЪРНА',
    nameEn: 'TARNA',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6778',
    addressNomenclature: '0',
    x: '25.174818',
    y: '41.665714',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '73643',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЪРНАВА',
    nameEn: 'TARNAVA',
    municipality: 'БЯЛА СЛАТИНА',
    municipalityEn: 'BYALA SLATINA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3252',
    addressNomenclature: '0',
    x: '23.882237',
    y: '43.502736',
    servingDays: '1010100',
    servingOfficeId: '59',
    servingHubOfficeId: '20',
  },
  {
    id: '73657',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЪРНАВА',
    nameEn: 'TARNAVA',
    municipality: 'ТУНДЖА',
    municipalityEn: 'TUNDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8699',
    addressNomenclature: '0',
    x: '26.680814',
    y: '42.473203',
    servingDays: '0101000',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '73660',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЪРНАК',
    nameEn: 'TARNAK',
    municipality: 'БЯЛА СЛАТИНА',
    municipalityEn: 'BYALA SLATINA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3249',
    addressNomenclature: '0',
    x: '24.023703',
    y: '43.444806',
    servingDays: '1010100',
    servingOfficeId: '59',
    servingHubOfficeId: '20',
  },
  {
    id: '73674',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЪРНЕНЕ',
    nameEn: 'TARNENE',
    municipality: 'ПЛЕВЕН',
    municipalityEn: 'PLEVEN',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5882',
    addressNomenclature: '0',
    x: '24.508008',
    y: '43.385533',
    servingDays: '1010100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '73688',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЪРНИЧЕНИ',
    nameEn: 'TARNICHENI',
    municipality: 'ПАВЕЛ БАНЯ',
    municipalityEn: 'PAVEL BANYA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6158',
    addressNomenclature: '0',
    x: '25.114508',
    y: '42.620584',
    servingDays: '1010100',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '73691',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЪРНОВЛАГ',
    nameEn: 'TARNOVLAG',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2532',
    addressNomenclature: '0',
    x: '22.782459',
    y: '42.278024',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '73701',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЪРНОВЦА',
    nameEn: 'TARNOVTSA',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7774',
    addressNomenclature: '0',
    x: '26.630025',
    y: '43.135958',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '73715',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЪРНОВЦИ',
    nameEn: 'TARNOVTSI',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6850',
    addressNomenclature: '0',
    x: '25.242279',
    y: '41.502293',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '73729',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЪРНОВЦИ',
    nameEn: 'TARNOVTSI',
    municipality: 'ТУТРАКАН',
    municipalityEn: 'TUTRAKAN',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7629',
    addressNomenclature: '0',
    x: '26.717126',
    y: '43.99313',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '73732',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЪРНОСЛИВКА',
    nameEn: 'TARNOSLIVKA',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6763',
    addressNomenclature: '0',
    x: '25.084971',
    y: '41.58662',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '73746',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЪРНЯНЕ',
    nameEn: 'TARNYANE',
    municipality: 'ВИДИН',
    municipalityEn: 'VIDIN',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3741',
    addressNomenclature: '0',
    x: '22.797941',
    y: '43.922008',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '73750',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЪРСИНО',
    nameEn: 'TARSINO',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2586',
    addressNomenclature: '0',
    x: '22.739908',
    y: '42.217046',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '73763',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЪРХОВО',
    nameEn: 'TARHOVO',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5423',
    addressNomenclature: '0',
    x: '25.242762',
    y: '42.982013',
    servingDays: '0101000',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '73777',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЪРЪН',
    nameEn: 'TARAN',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4730',
    addressNomenclature: '0',
    x: '24.874242',
    y: '41.556138',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '73780',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЮЛЕНОВО',
    nameEn: 'TYULENOVO',
    municipality: 'ШАБЛА',
    municipalityEn: 'SHABLA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9684',
    addressNomenclature: '0',
    x: '28.577818',
    y: '43.494229',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '73794',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЮРКМЕН',
    nameEn: 'TYURKMEN',
    municipality: 'БРЕЗОВО',
    municipalityEn: 'BREZOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4157',
    addressNomenclature: '0',
    x: '25.128781',
    y: '42.276381',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '73804',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЮТЮНЧЕ',
    nameEn: 'TYUTYUNCHE',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6850',
    addressNomenclature: '0',
    x: '25.27747',
    y: '41.505094',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '73818',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЯНЕВО',
    nameEn: 'TYANEVO',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9429',
    addressNomenclature: '0',
    x: '27.6327',
    y: '43.724133',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '73821',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ТЯНЕВО',
    nameEn: 'TYANEVO',
    municipality: 'СИМЕОНОВГРАД',
    municipalityEn: 'SIMEONOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6492',
    addressNomenclature: '0',
    x: '25.986447',
    y: '42.053826',
    servingDays: '0101000',
    servingOfficeId: '42',
    servingHubOfficeId: '5',
  },
  {
    id: '75013',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'УГЛЕДНО',
    nameEn: 'UGLEDNO',
    municipality: 'ОМУРТАГ',
    municipalityEn: 'OMURTAG',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7936',
    addressNomenclature: '0',
    x: '26.541455',
    y: '43.092687',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '75023',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'УГЛЯРЦИ',
    nameEn: 'UGLYARTSI',
    municipality: 'РАДОМИР',
    municipalityEn: 'RADOMIR',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2418',
    addressNomenclature: '0',
    x: '22.892339',
    y: '42.387446',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '75037',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'УГОРЕЛЕЦ',
    nameEn: 'UGORELETS',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5465',
    addressNomenclature: '0',
    x: '25.100948',
    y: '42.825379',
    servingDays: '1010100',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '75040',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'УГОРЯЛКОВЦИ',
    nameEn: 'UGORYALKOVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5082',
    addressNomenclature: '0',
    x: '25.93446',
    y: '42.883649',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '75054',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'УГЪРЧИН',
    nameEn: 'UGARCHIN',
    municipality: 'УГЪРЧИН',
    municipalityEn: 'UGARCHIN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5580',
    addressNomenclature: '2',
    x: '24.418076',
    y: '43.099644',
    servingDays: '1010100',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '75068',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'УЗОВО',
    nameEn: 'UZOVO',
    municipality: 'ГЕНЕРАЛ ТОШЕВО',
    municipalityEn: 'GENERAL TOSHEVO',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9554',
    addressNomenclature: '0',
    x: '27.910841',
    y: '43.769428',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '75085',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'УЗУНДЖОВО',
    nameEn: 'UZUNDZHOVO',
    municipality: 'ХАСКОВО',
    municipalityEn: 'HASKOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6350',
    addressNomenclature: '0',
    x: '25.656275',
    y: '41.964424',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '75099',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'УЗУНИТЕ',
    nameEn: 'UZUNITE',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5343',
    addressNomenclature: '0',
    x: '25.345213',
    y: '42.947235',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '75254',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'УЗУНОВО',
    nameEn: 'UZUNOVO',
    municipality: 'АСЕНОВГРАД',
    municipalityEn: 'ASENOVGRAD',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4237',
    addressNomenclature: '0',
    x: '25.099177',
    y: '41.842428',
    servingDays: '0101000',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '75112',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'УМАРЕВЦИ',
    nameEn: 'UMAREVTSI',
    municipality: 'ЛОВЕЧ',
    municipalityEn: 'LOVECH',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5541',
    addressNomenclature: '0',
    x: '24.783523',
    y: '43.193919',
    servingDays: '0101000',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '75139',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'УРВАТА',
    nameEn: 'URVATA',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5365',
    addressNomenclature: '0',
    x: '25.565246',
    y: '42.895108',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '75143',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'УРОВЕНЕ',
    nameEn: 'UROVENE',
    municipality: 'КРИВОДОЛ',
    municipalityEn: 'KRIVODOL',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3064',
    addressNomenclature: '0',
    x: '23.408934',
    y: '43.373165',
    servingDays: '0101000',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '75157',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'УРУЧЕВЦИ',
    nameEn: 'URUCHEVTSI',
    municipality: 'МАДАН',
    municipalityEn: 'MADAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4936',
    addressNomenclature: '0',
    x: '25.024517',
    y: '41.517852',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '75174',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'УСОЙКА',
    nameEn: 'USOYKA',
    municipality: 'БОБОШЕВО',
    municipalityEn: 'BOBOSHEVO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2675',
    addressNomenclature: '0',
    x: '23.059807',
    y: '42.192272',
    servingDays: '0101000',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '75188',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'УСТИНА',
    nameEn: 'USTINA',
    municipality: 'РОДОПИ',
    municipalityEn: 'RODOPI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4228',
    addressNomenclature: '0',
    x: '24.522345',
    y: '42.043023',
    servingDays: '1111100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '75191',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'УСТРЕМ',
    nameEn: 'USTREM',
    municipality: 'ТОПОЛОВГРАД',
    municipalityEn: 'TOPOLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6563',
    addressNomenclature: '0',
    x: '26.462898',
    y: '42.020992',
    servingDays: '0101000',
    servingOfficeId: '48',
    servingHubOfficeId: '5',
  },
  {
    id: '75201',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'УСТРЕН',
    nameEn: 'USTREN',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6853',
    addressNomenclature: '0',
    x: '25.226617',
    y: '41.459477',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '75215',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'УХЛОВИЦА',
    nameEn: 'UHLOVITSA',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4769',
    addressNomenclature: '0',
    x: '24.655642',
    y: '41.510275',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '75229',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'УШЕВЦИ',
    nameEn: 'USHEVTSI',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5035',
    addressNomenclature: '0',
    x: '25.662718',
    y: '42.846846',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '75232',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'УШИ',
    nameEn: 'USHI',
    municipality: 'ТРЕКЛЯНО',
    municipalityEn: 'TREKLYANO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2557',
    addressNomenclature: '0',
    x: '22.561094',
    y: '42.524746',
    servingDays: '0101000',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '75246',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'УШИНЦИ',
    nameEn: 'USHINTSI',
    municipality: 'РАЗГРАД',
    municipalityEn: 'RAZGRAD',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7244',
    addressNomenclature: '0',
    x: '26.601631',
    y: '43.49853',
    servingDays: '0101000',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '76011',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ФАБРИКА',
    nameEn: 'FABRIKA',
    municipality: 'ЗЛАТОГРАД',
    municipalityEn: 'ZLATOGRAD',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4992',
    addressNomenclature: '0',
    x: '24.994208',
    y: '41.442213',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '76025',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ФАЗАНОВО',
    nameEn: 'FAZANOVO',
    municipality: 'ЦАРЕВО',
    municipalityEn: 'TSAREVO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8272',
    addressNomenclature: '0',
    x: '27.720783',
    y: '42.200656',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '76039',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ФАКИЯ',
    nameEn: 'FAKIA',
    municipality: 'СРЕДЕЦ',
    municipalityEn: 'SREDETS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8340',
    addressNomenclature: '0',
    x: '27.086823',
    y: '42.187937',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '76042',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ФАТОВО',
    nameEn: 'FATOVO',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4797',
    addressNomenclature: '0',
    x: '24.780803',
    y: '41.54269',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '76064',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ФЕЛДФЕБЕЛ ДЯНКОВО',
    nameEn: 'FELDFEBEL DYANKOVO',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9394',
    addressNomenclature: '0',
    x: '27.532476',
    y: '43.71328',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '76073',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ФИЛАРЕТОВО',
    nameEn: 'FILARETOVO',
    municipality: 'КОТЕЛ',
    municipalityEn: 'KOTEL',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8982',
    addressNomenclature: '0',
    x: '26.494632',
    y: '42.979633',
    servingDays: '1010100',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '76090',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ФИЛИПОВО',
    nameEn: 'FILIPOVO',
    municipality: 'БАНСКО',
    municipalityEn: 'BANSKO',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2775',
    addressNomenclature: '0',
    x: '23.686836',
    y: '41.764191',
    servingDays: '0101000',
    servingOfficeId: '125',
    servingHubOfficeId: '13',
  },
  {
    id: '76100',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ФИЛИПОВО',
    nameEn: 'FILIPOVO',
    municipality: 'ТОПОЛОВГРАД',
    municipalityEn: 'TOPOLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6564',
    addressNomenclature: '0',
    x: '26.515917',
    y: '41.914399',
    servingDays: '0101000',
    servingOfficeId: '48',
    servingHubOfficeId: '5',
  },
  {
    id: '76114',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ФИЛИПОВЦИ',
    nameEn: 'FILIPOVTSI',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2472',
    addressNomenclature: '0',
    x: '22.726144',
    y: '42.819789',
    servingDays: '1111100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '76145',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ФЛОРЕНТИН',
    nameEn: 'FLORENTIN',
    municipality: 'НОВО СЕЛО',
    municipalityEn: 'NOVO SELO',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3778',
    addressNomenclature: '0',
    x: '22.849689',
    y: '44.134935',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '76159',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ФОТИНОВО',
    nameEn: 'FOTINOVO',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6873',
    addressNomenclature: '0',
    x: '25.352498',
    y: '41.386765',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '76162',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ФОТИНОВО',
    nameEn: 'FOTINOVO',
    municipality: 'БАТАК',
    municipalityEn: 'BATAK',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4571',
    addressNomenclature: '0',
    x: '24.351823',
    y: '41.882951',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '76180',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ФРОЛОШ',
    nameEn: 'FROLOSH',
    municipality: 'КОЧЕРИНОВО',
    municipalityEn: 'KOCHERINOVO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2682',
    addressNomenclature: '0',
    x: '22.921632',
    y: '42.131701',
    servingDays: '1111110',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '76193',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ФУРЕН',
    nameEn: 'FUREN',
    municipality: 'КРИВОДОЛ',
    municipalityEn: 'KRIVODOL',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3075',
    addressNomenclature: '0',
    x: '23.595891',
    y: '43.510753',
    servingDays: '0101000',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '76203',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ФЪРГОВО',
    nameEn: 'FARGOVO',
    municipality: 'САТОВЧА',
    municipalityEn: 'SATOVCHA',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2952',
    addressNomenclature: '0',
    x: '23.997421',
    y: '41.576565',
    servingDays: '1010100',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '76217',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ФЪРГОВЦИ',
    nameEn: 'FARGOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5301',
    addressNomenclature: '0',
    x: '25.384365',
    y: '42.831644',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '76220',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ФЪРЕВЦИ',
    nameEn: 'FAREVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5365',
    addressNomenclature: '0',
    x: '25.589422',
    y: '42.889153',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '76234',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ФЪРТУНИ',
    nameEn: 'FARTUNI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5367',
    addressNomenclature: '0',
    x: '25.542881',
    y: '42.835603',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '77044',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХАДЖИ ДИМИТЪР',
    nameEn: 'HADZHI DIMITAR',
    municipality: 'КАВАРНА',
    municipalityEn: 'KAVARNA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9657',
    addressNomenclature: '0',
    x: '28.444394',
    y: '43.473441',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '77013',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХАДЖИДИМИТРОВО',
    nameEn: 'HADZHIDIMITROVO',
    municipality: 'СВИЩОВ',
    municipalityEn: 'SVISHTOV',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5287',
    addressNomenclature: '0',
    x: '25.45099',
    y: '43.518614',
    servingDays: '1010100',
    servingOfficeId: '250',
    servingHubOfficeId: '11',
  },
  {
    id: '77027',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХАДЖИДИМИТРОВО',
    nameEn: 'HADZHIDIMITROVO',
    municipality: 'КАЗАНЛЪК',
    municipalityEn: 'KAZANLAK',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6141',
    addressNomenclature: '0',
    x: '25.351344',
    y: '42.671555',
    servingDays: '1111100',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '77030',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХАДЖИДИМИТРОВО',
    nameEn: 'HADZHIDIMITROVO',
    municipality: 'ТУНДЖА',
    municipalityEn: 'TUNDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8635',
    addressNomenclature: '0',
    x: '26.406584',
    y: '42.526709',
    servingDays: '1010100',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '77058',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ХАДЖИДИМОВО',
    nameEn: 'HADZHIDIMOVO',
    municipality: 'ХАДЖИДИМОВО',
    municipalityEn: 'HADZHIDIMOVO',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2933',
    addressNomenclature: '0',
    x: '23.854891',
    y: '41.514605',
    servingDays: '1111100',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '77061',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХАДЖИЕВО',
    nameEn: 'HADZHIEVO',
    municipality: 'ПАЗАРДЖИК',
    municipalityEn: 'PAZARDZHIK',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4441',
    addressNomenclature: '0',
    x: '24.455509',
    y: '42.142438',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '77089',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХАДЖИИТЕ',
    nameEn: 'HADZHIITE',
    municipality: 'КАРНОБАТ',
    municipalityEn: 'KARNOBAT',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8492',
    addressNomenclature: '0',
    x: '27.080563',
    y: '42.608785',
    servingDays: '1010100',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '77092',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХАДЖИЙСКО',
    nameEn: 'HADZHIYSKO',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6875',
    addressNomenclature: '0',
    x: '25.339187',
    y: '41.396045',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '77102',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХАЙРЕДИН',
    nameEn: 'HAYREDIN',
    municipality: 'ХАЙРЕДИН',
    municipalityEn: 'HAYREDIN',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3357',
    addressNomenclature: '0',
    x: '23.663668',
    y: '43.602237',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '77120',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХАЛВАДЖИЙСКО',
    nameEn: 'HALVADZHIYSKO',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7997',
    addressNomenclature: '0',
    x: '26.154899',
    y: '43.095184',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '77133',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХАЛОВСКИ КОЛИБИ',
    nameEn: 'HALOVSKI KOLIBI',
    municipality: 'БОЙНИЦА',
    municipalityEn: 'BOYNITSA',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '',
    addressNomenclature: '0',
    x: '22.422982',
    y: '43.978619',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '68730',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХАН АСПАРУХОВО',
    nameEn: 'HAN ASPARUHOVO',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6055',
    addressNomenclature: '0',
    x: '25.845356',
    y: '42.439309',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '78210',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХАН КРУМ',
    nameEn: 'HAN KRUM',
    municipality: 'ВЕЛИКИ ПРЕСЛАВ',
    municipalityEn: 'VELIKI PRESLAV',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9863',
    addressNomenclature: '0',
    x: '26.889659',
    y: '43.207508',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '77150',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХАНОВО',
    nameEn: 'HANOVO',
    municipality: 'ТУНДЖА',
    municipalityEn: 'TUNDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8668',
    addressNomenclature: '0',
    x: '26.510398',
    y: '42.390764',
    servingDays: '1010100',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '77164',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХАРАЧЕРИТЕ',
    nameEn: 'HARACHERITE',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5340',
    addressNomenclature: '0',
    x: '25.405755',
    y: '42.91612',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '77178',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХАРВАЛОВЦИ',
    nameEn: 'HARVALOVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5093',
    addressNomenclature: '0',
    x: '25.736232',
    y: '42.948014',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '77181',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ХАРМАНЛИ',
    nameEn: 'HARMANLI',
    municipality: 'ХАРМАНЛИ',
    municipalityEn: 'HARMANLI',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6450',
    addressNomenclature: '2',
    x: '25.910993',
    y: '41.923117',
    servingDays: '1111110',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '77195',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ХАСКОВО',
    nameEn: 'HASKOVO',
    municipality: 'ХАСКОВО',
    municipalityEn: 'HASKOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6300',
    addressNomenclature: '1',
    x: '25.55545',
    y: '41.93459',
    servingDays: '1111110',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '77205',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХАСОВИЦА',
    nameEn: 'HASOVITSA',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4746',
    addressNomenclature: '0',
    x: '24.753768',
    y: '41.582779',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '77219',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХВОЙНА',
    nameEn: 'HVOYNA',
    municipality: 'ЧЕПЕЛАРЕ',
    municipalityEn: 'CHEPELARE',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4890',
    addressNomenclature: '0',
    x: '24.690918',
    y: '41.870591',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '77222',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХВОСТЯНЕ',
    nameEn: 'HVOSTYANE',
    municipality: 'ГЪРМЕН',
    municipalityEn: 'GARMEN',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2942',
    addressNomenclature: '0',
    x: '23.847699',
    y: '41.545541',
    servingDays: '1111100',
    servingOfficeId: '130',
    servingHubOfficeId: '13',
  },
  {
    id: '77236',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХВЪРЧИЛ',
    nameEn: 'HVARCHIL',
    municipality: 'МИРКОВО',
    municipalityEn: 'MIRKOVO',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2072',
    addressNomenclature: '0',
    x: '23.930709',
    y: '42.603968',
    servingDays: '0101000',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '77246',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХЕРАКОВО',
    nameEn: 'HERAKOVO',
    municipality: 'БОЖУРИЩЕ',
    municipalityEn: 'BOZHURISHTE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2211',
    addressNomenclature: '0',
    x: '23.120955',
    y: '42.781939',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '77253',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХИРЕВО',
    nameEn: 'HIREVO',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5451',
    addressNomenclature: '0',
    x: '24.985833',
    y: '42.995786',
    servingDays: '1010100',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '77267',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХИСАР',
    nameEn: 'HISAR',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6914',
    addressNomenclature: '0',
    x: '25.648048',
    y: '41.531668',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '77270',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ХИСАРЯ',
    nameEn: 'HISARYA',
    municipality: 'ХИСАРЯ',
    municipalityEn: 'HISARYA',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4180',
    addressNomenclature: '2',
    x: '24.703274',
    y: '42.502469',
    servingDays: '1111110',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '77284',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХИТОВО',
    nameEn: 'HITOVO',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9433',
    addressNomenclature: '0',
    x: '27.558637',
    y: '43.768957',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '14516',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХИТРИНО',
    nameEn: 'HITRINO',
    municipality: 'ХИТРИНО',
    municipalityEn: 'HITRINO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9780',
    addressNomenclature: '0',
    x: '26.91458',
    y: '43.425701',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '77311',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХЛЕВЕНЕ',
    nameEn: 'HLEVENE',
    municipality: 'ЛОВЕЧ',
    municipalityEn: 'LOVECH',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5579',
    addressNomenclature: '0',
    x: '24.706315',
    y: '43.089598',
    servingDays: '0101000',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '77325',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХЛЯБОВО',
    nameEn: 'HLYABOVO',
    municipality: 'ТОПОЛОВГРАД',
    municipalityEn: 'TOPOLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6568',
    addressNomenclature: '0',
    x: '26.254247',
    y: '42.05983',
    servingDays: '1010100',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '77339',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХОДЖОВЦИ',
    nameEn: 'HODZHOVTSI',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6745',
    addressNomenclature: '0',
    x: '25.262457',
    y: '41.727455',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '77342',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХОТАНЦА',
    nameEn: 'HOTANTSA',
    municipality: 'РУСЕ',
    municipalityEn: 'RUSE',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7054',
    addressNomenclature: '0',
    x: '26.22861',
    y: '43.838691',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '77356',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХОТНИЦА',
    nameEn: 'HOTNITSA',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5058',
    addressNomenclature: '0',
    x: '25.543374',
    y: '43.153292',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '77361',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХОТОВО',
    nameEn: 'HOTOVO',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2821',
    addressNomenclature: '0',
    x: '23.335596',
    y: '41.499355',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '77373',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХРАБРИНО',
    nameEn: 'HRABRINO',
    municipality: 'РОДОПИ',
    municipalityEn: 'RODOPI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4111',
    addressNomenclature: '0',
    x: '24.647042',
    y: '42.045617',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '77387',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХРАБРОВО',
    nameEn: 'HRABROVO',
    municipality: 'ПРОВАДИЯ',
    municipalityEn: 'PROVADIA',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9242',
    addressNomenclature: '0',
    x: '27.384248',
    y: '43.136737',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '77390',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХРАБРОВО',
    nameEn: 'HRABROVO',
    municipality: 'БАЛЧИК',
    municipalityEn: 'BALCHIK',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9618',
    addressNomenclature: '0',
    x: '28.010149',
    y: '43.445029',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '77400',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХРАБЪРСКО',
    nameEn: 'HRABARSKO',
    municipality: 'БОЖУРИЩЕ',
    municipalityEn: 'BOZHURISHTE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2224',
    addressNomenclature: '0',
    x: '23.05676',
    y: '42.804578',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '77414',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХРАСНА',
    nameEn: 'HRASNA',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2824',
    addressNomenclature: '0',
    x: '23.534963',
    y: '41.489008',
    servingDays: '1010100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '77428',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХРАСТОВО',
    nameEn: 'HRASTOVO',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6929',
    addressNomenclature: '0',
    x: '25.61603',
    y: '41.392322',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '77431',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХРИСТИЯНОВО',
    nameEn: 'HRISTIYANOVO',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6072',
    addressNomenclature: '0',
    x: '25.588119',
    y: '42.348652',
    servingDays: '1111110',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '77462',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХРИСТО ДАНОВО',
    nameEn: 'HRISTO DANOVO',
    municipality: 'КАРЛОВО',
    municipalityEn: 'KARLOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4339',
    addressNomenclature: '0',
    x: '24.600326',
    y: '42.726592',
    servingDays: '0101000',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '77445',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХРИСТОВЦИ',
    nameEn: 'HRISTOVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5070',
    addressNomenclature: '0',
    x: '25.921875',
    y: '42.904963',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '77459',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХРИСТОВЦИ',
    nameEn: 'HRISTOVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5365',
    addressNomenclature: '0',
    x: '25.523626',
    y: '42.881471',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '77476',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХРИЩЕНИ',
    nameEn: 'HRISHTENI',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6050',
    addressNomenclature: '0',
    x: '25.705998',
    y: '42.457245',
    servingDays: '1111110',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '77487',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХРОМИЦА',
    nameEn: 'HROMITSA',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6782',
    addressNomenclature: '0',
    x: '25.211081',
    y: '41.618205',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '77493',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХУБАВЕНЕ',
    nameEn: 'HUBAVENE',
    municipality: 'РОМАН',
    municipalityEn: 'ROMAN',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3135',
    addressNomenclature: '0',
    x: '23.937727',
    y: '43.105092',
    servingDays: '0101000',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '77517',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХУМА',
    nameEn: 'HUMA',
    municipality: 'САМУИЛ',
    municipalityEn: 'SAMUIL',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7457',
    addressNomenclature: '0',
    x: '26.733337',
    y: '43.465932',
    servingDays: '1010100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '77520',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХУХЛА',
    nameEn: 'HUHLA',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6571',
    addressNomenclature: '0',
    x: '26.101489',
    y: '41.565009',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '77534',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХЪНЕВЦИ',
    nameEn: 'HANEVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5082',
    addressNomenclature: '0',
    x: '25.917247',
    y: '42.881705',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '77548',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХЪРЛЕЦ',
    nameEn: 'HARLETS',
    municipality: 'КОЗЛОДУЙ',
    municipalityEn: 'KOZLODUY',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3329',
    addressNomenclature: '0',
    x: '23.824981',
    y: '43.711251',
    servingDays: '1111100',
    servingOfficeId: '64',
    servingHubOfficeId: '20',
  },
  {
    id: '77565',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХЪРСОВО',
    nameEn: 'HARSOVO',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2819',
    addressNomenclature: '0',
    x: '23.383694',
    y: '41.461202',
    servingDays: '1010100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '77579',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХЪРСОВО',
    nameEn: 'HARSOVO',
    municipality: 'САМУИЛ',
    municipalityEn: 'SAMUIL',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7451',
    addressNomenclature: '0',
    x: '26.769109',
    y: '43.562282',
    servingDays: '1010100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '77582',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ХЪРСОВО',
    nameEn: 'HARSOVO',
    municipality: 'НИКОЛА КОЗЛЕВО',
    municipalityEn: 'NIKOLA KOZLEVO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9937',
    addressNomenclature: '0',
    x: '27.191034',
    y: '43.519483',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '78015',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦАКОНИЦА',
    nameEn: 'TSAKONITSA',
    municipality: 'МЕЗДРА',
    municipalityEn: 'MEZDRA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3152',
    addressNomenclature: '0',
    x: '23.819699',
    y: '43.228522',
    servingDays: '0101000',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '78029',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦАЛАПИЦА',
    nameEn: 'TSALAPITSA',
    municipality: 'РОДОПИ',
    municipalityEn: 'RODOPI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4218',
    addressNomenclature: '0',
    x: '24.568529',
    y: '42.184251',
    servingDays: '1111100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '78032',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦАНИ ГИНЧЕВО',
    nameEn: 'TSANI GINCHEVO',
    municipality: 'НИКОЛА КОЗЛЕВО',
    municipalityEn: 'NIKOLA KOZLEVO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9957',
    addressNomenclature: '0',
    x: '27.283987',
    y: '43.61297',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '78046',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦАПАРЕВО',
    nameEn: 'TSAPAREVO',
    municipality: 'СТРУМЯНИ',
    municipalityEn: 'STRUMYANI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2834',
    addressNomenclature: '0',
    x: '23.099508',
    y: '41.626859',
    servingDays: '1111100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '78056',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦАР АСЕН',
    nameEn: 'TSAR ASEN',
    municipality: 'ПАЗАРДЖИК',
    municipalityEn: 'PAZARDZHIK',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4422',
    addressNomenclature: '0',
    x: '24.345828',
    y: '42.361758',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '78063',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦАР АСЕН',
    nameEn: 'TSAR ASEN',
    municipality: 'АЛФАТАР',
    municipalityEn: 'ALFATAR',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7536',
    addressNomenclature: '0',
    x: '27.254278',
    y: '43.873467',
    servingDays: '0101000',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '78077',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦАР АСЕН',
    nameEn: 'TSAR ASEN',
    municipality: 'ПОПОВО',
    municipalityEn: 'POPOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7849',
    addressNomenclature: '0',
    x: '26.01822',
    y: '43.420629',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '77308',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ЦАР КАЛОЯН',
    nameEn: 'TSAR KALOYAN',
    municipality: 'ЦАР КАЛОЯН',
    municipalityEn: 'TSAR KALOYAN',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7280',
    addressNomenclature: '2',
    x: '26.239016',
    y: '43.607078',
    servingDays: '1111100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '78207',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦАР КАЛОЯН',
    nameEn: 'TSAR KALOYAN',
    municipality: 'КУКЛЕН',
    municipalityEn: 'KUKLEN',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4126',
    addressNomenclature: '0',
    x: '24.702866',
    y: '42.022041',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '78224',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦАР ПЕТРОВО',
    nameEn: 'TSAR PETROVO',
    municipality: 'КУЛА',
    municipalityEn: 'KULA',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3827',
    addressNomenclature: '0',
    x: '22.633659',
    y: '43.956739',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '78238',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦАР САМУИЛ',
    nameEn: 'TSAR SAMUIL',
    municipality: 'ТУТРАКАН',
    municipalityEn: 'TUTRAKAN',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7640',
    addressNomenclature: '0',
    x: '26.404527',
    y: '43.980772',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '66442',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦАР СИМЕОНОВО',
    nameEn: 'TSAR SIMEONOVO',
    municipality: 'ВИДИН',
    municipalityEn: 'VIDIN',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3745',
    addressNomenclature: '0',
    x: '22.84151',
    y: '43.854071',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '83363',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦАР ШИШМАНОВО',
    nameEn: 'TSAR SHISHMANOVO',
    municipality: 'МАКРЕШ',
    municipalityEn: 'MAKRESH',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3853',
    addressNomenclature: '0',
    x: '22.559954',
    y: '43.766212',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '78080',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦАРАЦОВО',
    nameEn: 'TSARATSOVO',
    municipality: 'МАРИЦА',
    municipalityEn: 'MARITSA',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4204',
    addressNomenclature: '0',
    x: '24.688772',
    y: '42.199225',
    servingDays: '1111110',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '78104',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦАРЕВ БРОД',
    nameEn: 'TSAREV BROD',
    municipality: 'ШУМЕН',
    municipalityEn: 'SHUMEN',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9747',
    addressNomenclature: '0',
    x: '27.014825',
    y: '43.329404',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '78118',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦАРЕВ ДОЛ',
    nameEn: 'TSAREV DOL',
    municipality: 'ТУТРАКАН',
    municipalityEn: 'TUTRAKAN',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7627',
    addressNomenclature: '0',
    x: '26.731521',
    y: '43.964589',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '12677',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦАРЕВА ЛИВАДА',
    nameEn: 'TSAREVA LIVADA',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5380',
    addressNomenclature: '0',
    x: '25.455002',
    y: '42.936467',
    servingDays: '1111100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '78094',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦАРЕВА ПОЛЯНА',
    nameEn: 'TSAREVA POLYANA',
    municipality: 'СТАМБОЛОВО',
    municipalityEn: 'STAMBOLOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6363',
    addressNomenclature: '0',
    x: '25.602222',
    y: '41.783215',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '78121',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦАРЕВЕЦ',
    nameEn: 'TSAREVETS',
    municipality: 'СВИЩОВ',
    municipalityEn: 'SVISHTOV',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5282',
    addressNomenclature: '0',
    x: '25.364162',
    y: '43.585728',
    servingDays: '1010100',
    servingOfficeId: '250',
    servingHubOfficeId: '11',
  },
  {
    id: '78135',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦАРЕВЕЦ',
    nameEn: 'TSAREVETS',
    municipality: 'МЕЗДРА',
    municipalityEn: 'MEZDRA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3141',
    addressNomenclature: '0',
    x: '23.801382',
    y: '43.160966',
    servingDays: '0101000',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '78149',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦАРЕВЕЦ',
    nameEn: 'TSAREVETS',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6622',
    addressNomenclature: '0',
    x: '25.350758',
    y: '41.671422',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '78152',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦАРЕВЕЦ',
    nameEn: 'TSAREVETS',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9367',
    addressNomenclature: '0',
    x: '27.899039',
    y: '43.644275',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '48619',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ЦАРЕВО',
    nameEn: 'TSAREVO',
    municipality: 'ЦАРЕВО',
    municipalityEn: 'TSAREVO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8260',
    addressNomenclature: '2',
    x: '27.848341',
    y: '42.170174',
    servingDays: '1111110',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '70220',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦАРЕВЦИ',
    nameEn: 'TSAREVTSI',
    municipality: 'АВРЕН',
    municipalityEn: 'AVREN',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9129',
    addressNomenclature: '0',
    x: '27.571911',
    y: '43.091973',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '78166',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦАРЕВЦИ',
    nameEn: 'TSAREVTSI',
    municipality: 'ОМУРТАГ',
    municipalityEn: 'OMURTAG',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7932',
    addressNomenclature: '0',
    x: '26.341848',
    y: '43.095929',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '78171',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦАРИМИР',
    nameEn: 'TSARIMIR',
    municipality: 'СЪЕДИНЕНИЕ',
    municipalityEn: 'SAEDINENIE',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4192',
    addressNomenclature: '0',
    x: '24.672544',
    y: '42.325294',
    servingDays: '0101000',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '78183',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦАРИНО',
    nameEn: 'TSARINO',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6893',
    addressNomenclature: '0',
    x: '25.43516',
    y: '41.328154',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '78197',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦАРИЧИНА',
    nameEn: 'TSARICHINA',
    municipality: 'КОСТИНБРОД',
    municipalityEn: 'KOSTINBROD',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2236',
    addressNomenclature: '0',
    x: '23.239302',
    y: '42.890625',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '48982',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦАРИЧИНО',
    nameEn: 'TSARICHINO',
    municipality: 'БАЛЧИК',
    municipalityEn: 'BALCHIK',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9614',
    addressNomenclature: '0',
    x: '28.179436',
    y: '43.456203',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '80203',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦАРСКИ ИЗВОР',
    nameEn: 'TSARSKI IZVOR',
    municipality: 'СТРАЖИЦА',
    municipalityEn: 'STRAZHITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5174',
    addressNomenclature: '0',
    x: '25.900048',
    y: '43.237277',
    servingDays: '1010100',
    servingOfficeId: '46',
    servingHubOfficeId: '11',
  },
  {
    id: '78241',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦАЦАРОВЦИ',
    nameEn: 'TSATSAROVTSI',
    municipality: 'ЗЛАТОГРАД',
    municipalityEn: 'ZLATOGRAD',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4992',
    addressNomenclature: '0',
    x: '24.996935',
    y: '41.445763',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '78255',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦАЦАРОВЦИ',
    nameEn: 'TSATSAROVTSI',
    municipality: 'ДРАГОМАН',
    municipalityEn: 'DRAGOMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2220',
    addressNomenclature: '0',
    x: '22.865066',
    y: '42.864436',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '78269',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦВЕКЛЮВЦИ',
    nameEn: 'TSVEKLYUVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5094',
    addressNomenclature: '0',
    x: '25.802047',
    y: '42.873613',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '78272',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦВЕТИНО',
    nameEn: 'TSVETINO',
    municipality: 'ВЕЛИНГРАД',
    municipalityEn: 'VELINGRAD',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4630',
    addressNomenclature: '0',
    x: '23.840437',
    y: '41.99218',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '78286',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦВЕТКОВА БАРА',
    nameEn: 'TSVETKOVA BARA',
    municipality: 'БЕРКОВИЦА',
    municipalityEn: 'BERKOVITSA',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3542',
    addressNomenclature: '0',
    x: '23.196349',
    y: '43.190335',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '78297',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦВЕТНИЦА',
    nameEn: 'TSVETNITSA',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7763',
    addressNomenclature: '0',
    x: '26.398437',
    y: '43.200088',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '78302',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦВЯТКОВЦИ',
    nameEn: 'TSVYATKOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5340',
    addressNomenclature: '0',
    x: '25.420593',
    y: '42.898002',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '78313',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦВЯТОВО',
    nameEn: 'TSVYATOVO',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6850',
    addressNomenclature: '0',
    x: '25.282141',
    y: '41.538204',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '78327',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦЕГРИЛОВЦИ',
    nameEn: 'TSEGRILOVTSI',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2479',
    addressNomenclature: '0',
    x: '22.509962',
    y: '42.783216',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '78330',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦЕЛИНА',
    nameEn: 'TSELINA',
    municipality: 'ЧИРПАН',
    municipalityEn: 'CHIRPAN',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6223',
    addressNomenclature: '0',
    x: '25.441823',
    y: '42.127667',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '78344',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦЕНИНО',
    nameEn: 'TSENINO',
    municipality: 'НОВА ЗАГОРА',
    municipalityEn: 'NOVA ZAGORA',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8916',
    addressNomenclature: '0',
    x: '26.037817',
    y: '42.538679',
    servingDays: '1010100',
    servingOfficeId: '56',
    servingHubOfficeId: '5',
  },
  {
    id: '78358',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦЕНОВИЧ',
    nameEn: 'TSENOVICH',
    municipality: 'СИЛИСТРА',
    municipalityEn: 'SILISTRA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7575',
    addressNomenclature: '0',
    x: '27.237751',
    y: '44.032052',
    servingDays: '1010100',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '78361',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦЕНОВО',
    nameEn: 'TSENOVO',
    municipality: 'ЦЕНОВО',
    municipalityEn: 'TSENOVO',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7139',
    addressNomenclature: '0',
    x: '25.655315',
    y: '43.5378',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '78375',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦЕНОВО',
    nameEn: 'TSENOVO',
    municipality: 'ЧИРПАН',
    municipalityEn: 'CHIRPAN',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6222',
    addressNomenclature: '0',
    x: '25.395659',
    y: '42.161629',
    servingDays: '1111110',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '78389',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦЕПЕРАНИТЕ',
    nameEn: 'TSEPERANITE',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5035',
    addressNomenclature: '0',
    x: '25.609822',
    y: '42.823941',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '78392',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦЕРЕТЕЛЕВО',
    nameEn: 'TSERETELEVO',
    municipality: 'СЪЕДИНЕНИЕ',
    municipalityEn: 'SAEDINENIE',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4189',
    addressNomenclature: '0',
    x: '24.628398',
    y: '42.349655',
    servingDays: '0101000',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '78402',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦЕРЕЦЕЛ',
    nameEn: 'TSERETSEL',
    municipality: 'СВОГЕ',
    municipalityEn: 'SVOGE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2286',
    addressNomenclature: '0',
    x: '23.33354',
    y: '42.913944',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '78416',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦЕРКОВСКИ',
    nameEn: 'TSERKOVSKI',
    municipality: 'КАРНОБАТ',
    municipalityEn: 'KARNOBAT',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8472',
    addressNomenclature: '0',
    x: '26.874116',
    y: '42.610739',
    servingDays: '1111100',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '78428',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦЕРОВА КОРИЯ',
    nameEn: 'TSEROVA KORIA',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5047',
    addressNomenclature: '0',
    x: '25.742911',
    y: '43.034487',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '78433',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦЕРОВЕЦ',
    nameEn: 'TSEROVETS',
    municipality: 'ИВАНОВО',
    municipalityEn: 'IVANOVO',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7083',
    addressNomenclature: '0',
    x: '26.149777',
    y: '43.536314',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '78447',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦЕРОВИЦА',
    nameEn: 'TSEROVITSA',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2567',
    addressNomenclature: '0',
    x: '22.532532',
    y: '42.350527',
    servingDays: '0101000',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '78450',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦЕРОВИЩЕ',
    nameEn: 'TSEROVISHTE',
    municipality: 'ОМУРТАГ',
    municipalityEn: 'OMURTAG',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7922',
    addressNomenclature: '0',
    x: '26.586522',
    y: '43.051089',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '78464',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦЕРОВО',
    nameEn: 'TSEROVO',
    municipality: 'БЛАГОЕВГРАД',
    municipalityEn: 'BLAGOEVGRAD',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2722',
    addressNomenclature: '0',
    x: '23.12806',
    y: '41.958939',
    servingDays: '0101000',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '78478',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦЕРОВО',
    nameEn: 'TSEROVO',
    municipality: 'ЛЕСИЧОВО',
    municipalityEn: 'LESICHOVO',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4465',
    addressNomenclature: '0',
    x: '24.052668',
    y: '42.357669',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '78481',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦЕРОВО',
    nameEn: 'TSEROVO',
    municipality: 'СВОГЕ',
    municipalityEn: 'SVOGE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2287',
    addressNomenclature: '0',
    x: '23.347132',
    y: '43.004678',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '94112',
    countryId: '100',
    mainSiteId: '0',
    type: 'к.',
    typeEn: 'k.',
    name: 'ЦИГОВ ЧАРК',
    nameEn: 'TSIGOV CHARK',
    municipality: 'БАТАК',
    municipalityEn: 'BATAK',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '',
    addressNomenclature: '0',
    x: '24.142968',
    y: '41.952539',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '78495',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦИКЛОВО',
    nameEn: 'TSIKLOVO',
    municipality: 'БОБОШЕВО',
    municipalityEn: 'BOBOSHEVO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2666',
    addressNomenclature: '0',
    x: '22.993278',
    y: '42.205927',
    servingDays: '0101000',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '78505',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦИРКА',
    nameEn: 'TSIRKA',
    municipality: 'МАДАН',
    municipalityEn: 'MADAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4939',
    addressNomenclature: '0',
    x: '24.989001',
    y: '41.548491',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '78519',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦОНЕВО',
    nameEn: 'TSONEVO',
    municipality: 'ДЪЛГОПОЛ',
    municipalityEn: 'DALGOPOL',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9261',
    addressNomenclature: '0',
    x: '27.436813',
    y: '43.033446',
    servingDays: '1111100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '78536',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦОНКОВЦИ',
    nameEn: 'TSONKOVTSI',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5035',
    addressNomenclature: '0',
    x: '25.661288',
    y: '42.835358',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '78543',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦРЕШНОВО',
    nameEn: 'TSRESHNOVO',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2567',
    addressNomenclature: '0',
    x: '22.445439',
    y: '42.322719',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '78553',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦРЪКЛЕВЦИ',
    nameEn: 'TSRAKLEVTSI',
    municipality: 'ДРАГОМАН',
    municipalityEn: 'DRAGOMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2205',
    addressNomenclature: '0',
    x: '23.104665',
    y: '42.952349',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '78567',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦРЪНЧА',
    nameEn: 'TSRANCHA',
    municipality: 'ДОСПАТ',
    municipalityEn: 'DOSPAT',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4837',
    addressNomenclature: '0',
    x: '24.103738',
    y: '41.567161',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '78570',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦРЪНЧА',
    nameEn: 'TSRANCHA',
    municipality: 'ПАЗАРДЖИК',
    municipalityEn: 'PAZARDZHIK',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4419',
    addressNomenclature: '0',
    x: '24.226594',
    y: '42.111558',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '78584',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦЪРВАРИЦА',
    nameEn: 'TSARVARITSA',
    municipality: 'НЕВЕСТИНО',
    municipalityEn: 'NEVESTINO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2580',
    addressNomenclature: '0',
    x: '22.837894',
    y: '42.073761',
    servingDays: '1010100',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '78598',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦЪРВЕНА ЯБЪЛКА',
    nameEn: 'TSARVENA YABALKA',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2576',
    addressNomenclature: '0',
    x: '22.615459',
    y: '42.156313',
    servingDays: '1010100',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '78608',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦЪРВЕНДОЛ',
    nameEn: 'TSARVENDOL',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2567',
    addressNomenclature: '0',
    x: '22.457521',
    y: '42.280117',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '78611',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦЪРВЕНЯНО',
    nameEn: 'TSARVENYANO',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2542',
    addressNomenclature: '0',
    x: '22.795106',
    y: '42.353204',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '78625',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦЪРВИЩЕ',
    nameEn: 'TSARVISHTE',
    municipality: 'КОЧЕРИНОВО',
    municipalityEn: 'KOCHERINOVO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2624',
    addressNomenclature: '0',
    x: '22.967854',
    y: '42.135989',
    servingDays: '1111110',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '78639',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦЪРКВА',
    nameEn: 'TSARKVA',
    municipality: 'БАЛЧИК',
    municipalityEn: 'BALCHIK',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9631',
    addressNomenclature: '0',
    x: '28.016191',
    y: '43.414804',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '78642',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦЪРКВИЦА',
    nameEn: 'TSARKVITSA',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6844',
    addressNomenclature: '0',
    x: '25.208',
    y: '41.54313',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '78656',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦЪРКВИЦА',
    nameEn: 'TSARKVITSA',
    municipality: 'НИКОЛА КОЗЛЕВО',
    municipalityEn: 'NIKOLA KOZLEVO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9939',
    addressNomenclature: '0',
    x: '27.180911',
    y: '43.479151',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '78669',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЦЪРКВИЩЕ',
    nameEn: 'TSARKVISHTE',
    municipality: 'ЗЛАТИЦА',
    municipalityEn: 'ZLATITSA',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2080',
    addressNomenclature: '0',
    x: '24.11043',
    y: '42.724552',
    servingDays: '1010100',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '80011',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧАВДАР',
    nameEn: 'CHAVDAR',
    municipality: 'ЧАВДАР',
    municipalityEn: 'CHAVDAR',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2084',
    addressNomenclature: '2',
    x: '24.055822',
    y: '42.659872',
    servingDays: '1111100',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '80025',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧАВДАР',
    nameEn: 'CHAVDAR',
    municipality: 'ДОСПАТ',
    municipalityEn: 'DOSPAT',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4829',
    addressNomenclature: '0',
    x: '24.221886',
    y: '41.580377',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '80039',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧАВДАРЦИ',
    nameEn: 'CHAVDARTSI',
    municipality: 'ЛОВЕЧ',
    municipalityEn: 'LOVECH',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5559',
    addressNomenclature: '0',
    x: '24.995032',
    y: '43.252546',
    servingDays: '0101000',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '80042',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧАВДАРЦИ',
    nameEn: 'CHAVDARTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5084',
    addressNomenclature: '0',
    x: '25.972769',
    y: '42.977252',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '80056',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧАВЕИ',
    nameEn: 'CHAVEI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5345',
    addressNomenclature: '0',
    x: '25.255293',
    y: '42.911251',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '80065',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧАВКА',
    nameEn: 'CHAVKA',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6880',
    addressNomenclature: '0',
    x: '25.455593',
    y: '41.4104',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '92083',
    countryId: '100',
    mainSiteId: '0',
    type: 'к.',
    typeEn: 'k.',
    name: 'ЧАИРА',
    nameEn: 'CHAIRA',
    municipality: 'БЕЛОВО',
    municipalityEn: 'BELOVO',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4476',
    addressNomenclature: '0',
    x: '23.87257',
    y: '42.169065',
    servingDays: '1111100',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '80073',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧАЙКА',
    nameEn: 'CHAYKA',
    municipality: 'ПРОВАДИЯ',
    municipalityEn: 'PROVADIA',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9277',
    addressNomenclature: '0',
    x: '27.427911',
    y: '43.081358',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '80087',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧАЙКА',
    nameEn: 'CHAYKA',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6820',
    addressNomenclature: '0',
    x: '25.56258',
    y: '41.502579',
    servingDays: '1111100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '92090',
    countryId: '100',
    mainSiteId: '0',
    type: 'к.к.',
    typeEn: 'k.k.',
    name: 'ЧАЙКА',
    nameEn: 'CHAYKA',
    municipality: 'ВАРНА',
    municipalityEn: 'VARNA',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9007',
    addressNomenclature: '0',
    x: '28.027882',
    y: '43.261542',
    servingDays: '1111100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '80090',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧАКАЛАРОВО',
    nameEn: 'CHAKALAROVO',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6870',
    addressNomenclature: '0',
    x: '25.304757',
    y: '41.296112',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '80100',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧАКАЛИ',
    nameEn: 'CHAKALI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5099',
    addressNomenclature: '0',
    x: '25.982656',
    y: '42.915678',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '80114',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧАКАЛИТЕ',
    nameEn: 'CHAKALITE',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5365',
    addressNomenclature: '0',
    x: '25.540107',
    y: '42.887996',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '80128',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧАКАЛЦИ',
    nameEn: 'CHAKALTSI',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6850',
    addressNomenclature: '0',
    x: '25.326391',
    y: '41.493989',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '80145',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧАЛ',
    nameEn: 'CHAL',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6947',
    addressNomenclature: '0',
    x: '25.759284',
    y: '41.506761',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '80159',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧАЛА',
    nameEn: 'CHALA',
    municipality: 'БОРИНО',
    municipalityEn: 'BORINO',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4836',
    addressNomenclature: '0',
    x: '24.302904',
    y: '41.631392',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '80162',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧАЛЪКОВИ',
    nameEn: 'CHALAKOVI',
    municipality: 'РАКОВСКИ',
    municipalityEn: 'RAKOVSKI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4123',
    addressNomenclature: '0',
    x: '25.011624',
    y: '42.161177',
    servingDays: '0101000',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '80193',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧАМЛА',
    nameEn: 'CHAMLA',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4716',
    addressNomenclature: '0',
    x: '24.45366',
    y: '41.622657',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '80217',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧАРГАН',
    nameEn: 'CHARGAN',
    municipality: 'ТУНДЖА',
    municipalityEn: 'TUNDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8628',
    addressNomenclature: '0',
    x: '26.619531',
    y: '42.481922',
    servingDays: '1010100',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '80220',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧАРДА',
    nameEn: 'CHARDA',
    municipality: 'СТРАЛДЖА',
    municipalityEn: 'STRALDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8692',
    addressNomenclature: '0',
    x: '26.622775',
    y: '42.542126',
    servingDays: '1010100',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '81904',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧАРКОВО',
    nameEn: 'CHARKOVO',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5309',
    addressNomenclature: '0',
    x: '25.362419',
    y: '42.789066',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '80248',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕГАНЦИ',
    nameEn: 'CHEGANTSI',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6744',
    addressNomenclature: '0',
    x: '25.294777',
    y: '41.714289',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '80251',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕКАНЕЦ',
    nameEn: 'CHEKANETS',
    municipality: 'НЕВЕСТИНО',
    municipalityEn: 'NEVESTINO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2577',
    addressNomenclature: '0',
    x: '22.650763',
    y: '42.102885',
    servingDays: '1010100',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '80265',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕКАНЕЦ',
    nameEn: 'CHEKANETS',
    municipality: 'ДРАГОМАН',
    municipalityEn: 'DRAGOMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2220',
    addressNomenclature: '0',
    x: '22.806056',
    y: '42.857347',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '80279',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕКАНЦИ',
    nameEn: 'CHEKANTSI',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7961',
    addressNomenclature: '0',
    x: '26.168877',
    y: '42.963353',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '80282',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕКАНЧЕВО',
    nameEn: 'CHEKANCHEVO',
    municipality: 'ГОРНА МАЛИНА',
    municipalityEn: 'GORNA MALINA',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2122',
    addressNomenclature: '0',
    x: '23.770983',
    y: '42.70353',
    servingDays: '0101000',
    servingOfficeId: '2',
    servingHubOfficeId: '2',
  },
  {
    id: '80306',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕЛНИК',
    nameEn: 'CHELNIK',
    municipality: 'ТУНДЖА',
    municipalityEn: 'TUNDZHA',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8678',
    addressNomenclature: '0',
    x: '26.68506',
    y: '42.398091',
    servingDays: '1010100',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '80311',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕЛОПЕК',
    nameEn: 'CHELOPEK',
    municipality: 'ВРАЦА',
    municipalityEn: 'VRATSA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3043',
    addressNomenclature: '0',
    x: '23.6085',
    y: '43.132576',
    servingDays: '1010100',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '80323',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕЛОПЕЧ',
    nameEn: 'CHELOPECH',
    municipality: 'ЧЕЛОПЕЧ',
    municipalityEn: 'CHELOPECH',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2087',
    addressNomenclature: '2',
    x: '24.085769',
    y: '42.698166',
    servingDays: '1111110',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '80340',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕЛОПЕЧЕНЕ',
    nameEn: 'CHELOPECHENE',
    municipality: 'КАВАРНА',
    municipalityEn: 'KAVARNA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9664',
    addressNomenclature: '0',
    x: '28.31282',
    y: '43.549541',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '80354',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕЛЮСТНИЦА',
    nameEn: 'CHELYUSTNITSA',
    municipality: 'ЧИПРОВЦИ',
    municipalityEn: 'CHIPROVTSI',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3465',
    addressNomenclature: '0',
    x: '23.004102',
    y: '43.435967',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '80368',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕМИШ',
    nameEn: 'CHEMISH',
    municipality: 'ГЕОРГИ ДАМЯНОВО',
    municipalityEn: 'GEORGI DAMYANOVO',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3477',
    addressNomenclature: '0',
    x: '23.056902',
    y: '43.435027',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '80371',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ЧЕПЕЛАРЕ',
    nameEn: 'CHEPELARE',
    municipality: 'ЧЕПЕЛАРЕ',
    municipalityEn: 'CHEPELARE',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4850',
    addressNomenclature: '2',
    x: '24.685009',
    y: '41.725891',
    servingDays: '1111100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '80385',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕПИНО',
    nameEn: 'CHEPINO',
    municipality: 'КОВАЧЕВЦИ',
    municipalityEn: 'KOVACHEVTSI',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2436',
    addressNomenclature: '0',
    x: '22.787182',
    y: '42.616476',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '80399',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕПИНЦИ',
    nameEn: 'CHEPINTSI',
    municipality: 'РУДОЗЕМ',
    municipalityEn: 'RUDOZEM',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4958',
    addressNomenclature: '0',
    x: '24.871388',
    y: '41.431109',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '80409',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕПИНЦИ',
    nameEn: 'CHEPINTSI',
    municipality: 'СТОЛИЧНА',
    municipalityEn: 'STOLICHNA',
    region: 'СОФИЯ (СТОЛИЦА)',
    regionEn: 'SOFIA (STOLITSA)',
    postCode: '1554',
    addressNomenclature: '2',
    x: '23.427096',
    y: '42.759372',
    servingDays: '1111100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '80412',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕПЛЕТЕН',
    nameEn: 'CHEPLETEN',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4786',
    addressNomenclature: '0',
    x: '24.756795',
    y: '41.460649',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '80426',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕПЪРЛИНЦИ',
    nameEn: 'CHEPARLINTSI',
    municipality: 'ДРАГОМАН',
    municipalityEn: 'DRAGOMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2212',
    addressNomenclature: '0',
    x: '22.906312',
    y: '43.01873',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '80437',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРВЕН',
    nameEn: 'CHERVEN',
    municipality: 'АСЕНОВГРАД',
    municipalityEn: 'ASENOVGRAD',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4252',
    addressNomenclature: '0',
    x: '24.956221',
    y: '41.949272',
    servingDays: '0101000',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '80443',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРВЕН',
    nameEn: 'CHERVEN',
    municipality: 'ИВАНОВО',
    municipalityEn: 'IVANOVO',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7084',
    addressNomenclature: '0',
    x: '26.023087',
    y: '43.614097',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '80491',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРВЕН БРЕГ',
    nameEn: 'CHERVEN BREG',
    municipality: 'ДУПНИЦА',
    municipalityEn: 'DUPNITSA',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2629',
    addressNomenclature: '0',
    x: '23.175616',
    y: '42.310673',
    servingDays: '1111110',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '80501',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ЧЕРВЕН БРЯГ',
    nameEn: 'CHERVEN BRYAG',
    municipality: 'ЧЕРВЕН БРЯГ',
    municipalityEn: 'CHERVEN BRYAG',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5980',
    addressNomenclature: '2',
    x: '24.083301',
    y: '43.280635',
    servingDays: '1111100',
    servingOfficeId: '59',
    servingHubOfficeId: '20',
  },
  {
    id: '80457',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРВЕНА',
    nameEn: 'CHERVENA',
    municipality: 'СВИЩОВ',
    municipalityEn: 'SVISHTOV',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5293',
    addressNomenclature: '0',
    x: '25.228362',
    y: '43.383865',
    servingDays: '0101000',
    servingOfficeId: '25',
    servingHubOfficeId: '11',
  },
  {
    id: '80460',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРВЕНА ВОДА',
    nameEn: 'CHERVENA VODA',
    municipality: 'РУСЕ',
    municipalityEn: 'RUSE',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7051',
    addressNomenclature: '0',
    x: '26.101044',
    y: '43.806983',
    servingDays: '1111100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '81918',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРВЕНА ЛОКВА',
    nameEn: 'CHERVENA LOKVA',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5311',
    addressNomenclature: '0',
    x: '25.346284',
    y: '42.799648',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '80488',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРВЕНА МОГИЛА',
    nameEn: 'CHERVENA MOGILA',
    municipality: 'РАДОМИР',
    municipalityEn: 'RADOMIR',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2408',
    addressNomenclature: '0',
    x: '23.002949',
    y: '42.516348',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '81921',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРВЕНА СКАЛА',
    nameEn: 'CHERVENA SKALA',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6781',
    addressNomenclature: '0',
    x: '25.184349',
    y: '41.613453',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '80474',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРВЕНАКОВО',
    nameEn: 'CHERVENAKOVO',
    municipality: 'ТВЪРДИЦА',
    municipalityEn: 'TVARDITSA',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8896',
    addressNomenclature: '0',
    x: '26.040318',
    y: '42.622039',
    servingDays: '0101000',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '80515',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРВЕНКОВЦИ',
    nameEn: 'CHERVENKOVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5070',
    addressNomenclature: '0',
    x: '25.834499',
    y: '42.946029',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '80529',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРВЕНЦИ',
    nameEn: 'CHERVENTSI',
    municipality: 'ВЪЛЧИ ДОЛ',
    municipalityEn: 'VALCHI DOL',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9298',
    addressNomenclature: '0',
    x: '27.44807',
    y: '43.523812',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '80532',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРГАНОВО',
    nameEn: 'CHERGANOVO',
    municipality: 'КАЗАНЛЪК',
    municipalityEn: 'KAZANLAK',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6139',
    addressNomenclature: '0',
    x: '25.470273',
    y: '42.585633',
    servingDays: '1111100',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '80546',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРЕНЧА',
    nameEn: 'CHERENCHA',
    municipality: 'ШУМЕН',
    municipalityEn: 'SHUMEN',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9799',
    addressNomenclature: '0',
    x: '26.778634',
    y: '43.309031',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '80552',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРЕПОВО',
    nameEn: 'CHEREPOVO',
    municipality: 'ХАРМАНЛИ',
    municipalityEn: 'HARMANLI',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6462',
    addressNomenclature: '0',
    x: '26.145938',
    y: '42.00509',
    servingDays: '1010100',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '80563',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРЕША',
    nameEn: 'CHERESHA',
    municipality: 'РУЕН',
    municipalityEn: 'RUEN',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8577',
    addressNomenclature: '0',
    x: '27.13235',
    y: '42.814559',
    servingDays: '1111100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '80594',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРЕШИЦА',
    nameEn: 'CHERESHITSA',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6672',
    addressNomenclature: '0',
    x: '25.504095',
    y: '41.750023',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '81935',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРЕШКА',
    nameEn: 'CHERESHKA',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6841',
    addressNomenclature: '0',
    x: '25.189271',
    y: '41.488302',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '80604',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРЕШКИТЕ',
    nameEn: 'CHERESHKITE',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4771',
    addressNomenclature: '0',
    x: '24.5896',
    y: '41.503573',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '80618',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРЕШНИЦА',
    nameEn: 'CHERESHNITSA',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2824',
    addressNomenclature: '0',
    x: '23.458227',
    y: '41.494871',
    servingDays: '1010100',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '80635',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРЕШОВИЦА',
    nameEn: 'CHERESHOVITSA',
    municipality: 'БЕРКОВИЦА',
    municipalityEn: 'BERKOVITSA',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3524',
    addressNomenclature: '0',
    x: '23.039648',
    y: '43.260553',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '80649',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРЕШОВО',
    nameEn: 'CHERESHOVO',
    municipality: 'БЕЛИЦА',
    municipalityEn: 'BELITSA',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2787',
    addressNomenclature: '0',
    x: '23.708215',
    y: '41.948938',
    servingDays: '1010100',
    servingOfficeId: '125',
    servingHubOfficeId: '13',
  },
  {
    id: '80652',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРЕШОВО',
    nameEn: 'CHERESHOVO',
    municipality: 'СЛИВО ПОЛЕ',
    municipalityEn: 'SLIVO POLE',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7066',
    addressNomenclature: '0',
    x: '26.352472',
    y: '43.90253',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '80666',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРЕШОВО',
    nameEn: 'CHERESHOVO',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4772',
    addressNomenclature: '0',
    x: '24.641143',
    y: '41.516924',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '80678',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРЕШОВСКА РЕКА',
    nameEn: 'CHERESHOVSKA REKA',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4773',
    addressNomenclature: '0',
    x: '24.633979',
    y: '41.516562',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '80683',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРКАСКИ',
    nameEn: 'CHERKASKI',
    municipality: 'ВЪРШЕЦ',
    municipalityEn: 'VARSHETS',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3533',
    addressNomenclature: '0',
    x: '23.28844',
    y: '43.252916',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '80697',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРКОВИЦА',
    nameEn: 'CHERKOVITSA',
    municipality: 'НИКОПОЛ',
    municipalityEn: 'NIKOPOL',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5951',
    addressNomenclature: '0',
    x: '24.834333',
    y: '43.702542',
    servingDays: '0101000',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '80707',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРКОВИЩЕ',
    nameEn: 'CHERKOVISHTE',
    municipality: 'МИРКОВО',
    municipalityEn: 'MIRKOVO',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2072',
    addressNomenclature: '0',
    x: '23.917695',
    y: '42.599411',
    servingDays: '0101000',
    servingOfficeId: '304',
    servingHubOfficeId: '1',
  },
  {
    id: '80710',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРКОВНА',
    nameEn: 'CHERKOVNA',
    municipality: 'ПРОВАДИЯ',
    municipalityEn: 'PROVADIA',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9232',
    addressNomenclature: '0',
    x: '27.32923',
    y: '43.195405',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '80724',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРКОВНА',
    nameEn: 'CHERKOVNA',
    municipality: 'РАЗГРАД',
    municipalityEn: 'RAZGRAD',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7252',
    addressNomenclature: '0',
    x: '26.64793',
    y: '43.605867',
    servingDays: '0101000',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '80738',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРКОВНА',
    nameEn: 'CHERKOVNA',
    municipality: 'ДУЛОВО',
    municipalityEn: 'DULOVO',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7656',
    addressNomenclature: '0',
    x: '27.178115',
    y: '43.843056',
    servingDays: '0101000',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '80741',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРКОВНА',
    nameEn: 'CHERKOVNA',
    municipality: 'ТЪРГОВИЩЕ',
    municipalityEn: 'TARGOVISHTE',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7781',
    addressNomenclature: '0',
    x: '26.56984',
    y: '43.147547',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '80755',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРКОВО',
    nameEn: 'CHERKOVO',
    municipality: 'КАРНОБАТ',
    municipalityEn: 'KARNOBAT',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8478',
    addressNomenclature: '0',
    x: '27.045956',
    y: '42.481645',
    servingDays: '0101010',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '80769',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНА',
    nameEn: 'CHERNA',
    municipality: 'ДОБРИЧ-СЕЛСКА',
    municipalityEn: 'DOBRICH-SELSKA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9417',
    addressNomenclature: '0',
    x: '27.668871',
    y: '43.76089',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '80772',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНА',
    nameEn: 'CHERNA',
    municipality: 'ХИТРИНО',
    municipalityEn: 'HITRINO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9773',
    addressNomenclature: '0',
    x: '26.88318',
    y: '43.463277',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '80786',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНА ВОДА',
    nameEn: 'CHERNA VODA',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7966',
    addressNomenclature: '0',
    x: '26.21477',
    y: '42.971905',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '80793',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНА ГОРА',
    nameEn: 'CHERNA GORA',
    municipality: 'БРАТЯ ДАСКАЛОВИ',
    municipalityEn: 'BRATYA DASKALOVI',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6258',
    addressNomenclature: '0',
    x: '25.225743',
    y: '42.216111',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '81952',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНА ГОРА',
    nameEn: 'CHERNA GORA',
    municipality: 'ПЕРНИК',
    municipalityEn: 'PERNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2340',
    addressNomenclature: '0',
    x: '22.936045',
    y: '42.602181',
    servingDays: '1111100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '81850',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНА МЕСТА',
    nameEn: 'CHERNA MESTA',
    municipality: 'ЯКОРУДА',
    municipalityEn: 'YAKORUDA',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2789',
    addressNomenclature: '0',
    x: '23.728585',
    y: '42.053747',
    servingDays: '1010100',
    servingOfficeId: '125',
    servingHubOfficeId: '13',
  },
  {
    id: '80813',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНА МОГИЛА',
    nameEn: 'CHERNA MOGILA',
    municipality: 'АЙТОС',
    municipalityEn: 'AYTOS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8527',
    addressNomenclature: '0',
    x: '27.398198',
    y: '42.749272',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '80827',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНА МОГИЛА',
    nameEn: 'CHERNA MOGILA',
    municipality: 'ХАРМАНЛИ',
    municipalityEn: 'HARMANLI',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6471',
    addressNomenclature: '0',
    x: '25.946844',
    y: '41.808543',
    servingDays: '1010100',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '80830',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНА НИВА',
    nameEn: 'CHERNA NIVA',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6692',
    addressNomenclature: '0',
    x: '25.265111',
    y: '41.779478',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '80844',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНА СКАЛА',
    nameEn: 'CHERNA SKALA',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6626',
    addressNomenclature: '0',
    x: '25.361872',
    y: '41.69827',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '80861',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНЕВО',
    nameEn: 'CHERNEVO',
    municipality: 'СУВОРОВО',
    municipalityEn: 'SUVOROVO',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9174',
    addressNomenclature: '0',
    x: '27.602254',
    y: '43.292497',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '80889',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНЕВЦИ',
    nameEn: 'CHERNEVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5349',
    addressNomenclature: '0',
    x: '25.411228',
    y: '42.88824',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '80892',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНИ БРЯГ',
    nameEn: 'CHERNI BRYAG',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7973',
    addressNomenclature: '0',
    x: '26.084745',
    y: '43.134605',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '80902',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНИ ВИТ',
    nameEn: 'CHERNI VIT',
    municipality: 'ТЕТЕВЕН',
    municipalityEn: 'TETEVEN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5725',
    addressNomenclature: '0',
    x: '24.194236',
    y: '42.881555',
    servingDays: '1010100',
    servingOfficeId: '88',
    servingHubOfficeId: '2',
  },
  {
    id: '80916',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНИ ВРЪХ',
    nameEn: 'CHERNI VRAH',
    municipality: 'КАМЕНО',
    municipalityEn: 'KAMENO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8122',
    addressNomenclature: '0',
    x: '27.340307',
    y: '42.445696',
    servingDays: '1111110',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '80933',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНИ ВРЪХ',
    nameEn: 'CHERNI VRAH',
    municipality: 'ВЪЛЧЕДРЪМ',
    municipalityEn: 'VALCHEDRAM',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3649',
    addressNomenclature: '0',
    x: '23.410973',
    y: '43.649556',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '80950',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНИ ВРЪХ',
    nameEn: 'CHERNI VRAH',
    municipality: 'СМЯДОВО',
    municipalityEn: 'SMYADOVO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9827',
    addressNomenclature: '0',
    x: '27.197115',
    y: '43.046214',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '80978',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНИ ДЯЛ',
    nameEn: 'CHERNI DYAL',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5084',
    addressNomenclature: '0',
    x: '26.013598',
    y: '42.968805',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '80981',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНИ ОСЪМ',
    nameEn: 'CHERNI OSAM',
    municipality: 'ТРОЯН',
    municipalityEn: 'TROYAN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5620',
    addressNomenclature: '0',
    x: '24.769381',
    y: '42.845973',
    servingDays: '1010100',
    servingOfficeId: '44',
    servingHubOfficeId: '11',
  },
  {
    id: '80995',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНИ РИД',
    nameEn: 'CHERNI RID',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6598',
    addressNomenclature: '0',
    x: '25.972266',
    y: '41.514049',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '80964',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНИГОВО',
    nameEn: 'CHERNIGOVO',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6778',
    addressNomenclature: '0',
    x: '25.170725',
    y: '41.659418',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '81966',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНИК',
    nameEn: 'CHERNIK',
    municipality: 'ДУЛОВО',
    municipalityEn: 'DULOVO',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7651',
    addressNomenclature: '0',
    x: '27.118273',
    y: '43.810267',
    servingDays: '1111100',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '81006',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНИЦА',
    nameEn: 'CHERNITSA',
    municipality: 'СУНГУРЛАРЕ',
    municipalityEn: 'SUNGURLARE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8468',
    addressNomenclature: '0',
    x: '26.825152',
    y: '42.771589',
    servingDays: '1010100',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '81013',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНИЧЕ',
    nameEn: 'CHERNICHE',
    municipality: 'СИМИТЛИ',
    municipalityEn: 'SIMITLI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2741',
    addressNomenclature: '0',
    x: '23.127437',
    y: '41.859478',
    servingDays: '1111100',
    servingOfficeId: '13',
    servingHubOfficeId: '13',
  },
  {
    id: '81027',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНИЧЕВО',
    nameEn: 'CHERNICHEVO',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6954',
    addressNomenclature: '0',
    x: '25.774912',
    y: '41.348755',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '81030',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНИЧЕВО',
    nameEn: 'CHERNICHEVO',
    municipality: 'ХИСАРЯ',
    municipalityEn: 'HISARYA',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4181',
    addressNomenclature: '0',
    x: '24.708975',
    y: '42.442709',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '81044',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНИЧИНО',
    nameEn: 'CHERNICHINO',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6581',
    addressNomenclature: '0',
    x: '25.845571',
    y: '41.593756',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '81247',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНО ПОЛЕ',
    nameEn: 'CHERNO POLE',
    municipality: 'РУЖИНЦИ',
    municipalityEn: 'RUZHINTSI',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3922',
    addressNomenclature: '0',
    x: '22.920026',
    y: '43.611374',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '81058',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНОВРЪХ',
    nameEn: 'CHERNOVRAH',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5365',
    addressNomenclature: '0',
    x: '25.518764',
    y: '42.887455',
    servingDays: '1010100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '81061',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНОГЛАВЦИ',
    nameEn: 'CHERNOGLAVTSI',
    municipality: 'ВЕНЕЦ',
    municipalityEn: 'VENETS',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9778',
    addressNomenclature: '0',
    x: '27.005903',
    y: '43.540093',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '81075',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНОГОР',
    nameEn: 'CHERNOGOR',
    municipality: 'ГЛАВИНИЦА',
    municipalityEn: 'GLAVINITSA',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7614',
    addressNomenclature: '0',
    x: '26.745227',
    y: '43.939996',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '81089',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНОГОРОВО',
    nameEn: 'CHERNOGOROVO',
    municipality: 'ПАЗАРДЖИК',
    municipalityEn: 'PAZARDZHIK',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4455',
    addressNomenclature: '0',
    x: '24.400064',
    y: '42.272124',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '81092',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНОГОРОВО',
    nameEn: 'CHERNOGOROVO',
    municipality: 'ДИМИТРОВГРАД',
    municipalityEn: 'DIMITROVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6411',
    addressNomenclature: '0',
    x: '25.651639',
    y: '42.029065',
    servingDays: '0101000',
    servingOfficeId: '42',
    servingHubOfficeId: '5',
  },
  {
    id: '81102',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНОГРАД',
    nameEn: 'CHERNOGRAD',
    municipality: 'АЙТОС',
    municipalityEn: 'AYTOS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8545',
    addressNomenclature: '0',
    x: '27.097538',
    y: '42.713325',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '81116',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНОДЪБ',
    nameEn: 'CHERNODAB',
    municipality: 'СВИЛЕНГРАД',
    municipalityEn: 'SVILENGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6524',
    addressNomenclature: '0',
    x: '26.278515',
    y: '41.764031',
    servingDays: '1010100',
    servingOfficeId: '48',
    servingHubOfficeId: '5',
  },
  {
    id: '81121',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНОЗЕМ',
    nameEn: 'CHERNOZEM',
    municipality: 'ЕЛХОВО',
    municipalityEn: 'ELHOVO',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8739',
    addressNomenclature: '0',
    x: '26.60735',
    y: '42.112694',
    servingDays: '1010100',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '81133',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНОЗЕМЕН',
    nameEn: 'CHERNOZEMEN',
    municipality: 'КАЛОЯНОВО',
    municipalityEn: 'KALOYANOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4168',
    addressNomenclature: '0',
    x: '24.78309',
    y: '42.376946',
    servingDays: '0101000',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '81147',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНОКАПЦИ',
    nameEn: 'CHERNOKAPTSI',
    municipality: 'ОМУРТАГ',
    municipalityEn: 'OMURTAG',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7921',
    addressNomenclature: '0',
    x: '26.528127',
    y: '43.063855',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '81150',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНОЛИК',
    nameEn: 'CHERNOLIK',
    municipality: 'ДУЛОВО',
    municipalityEn: 'DULOVO',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7693',
    addressNomenclature: '0',
    x: '27.084761',
    y: '43.883051',
    servingDays: '0101000',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '81178',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ЧЕРНОМОРЕЦ',
    nameEn: 'CHERNOMORETS',
    municipality: 'СОЗОПОЛ',
    municipalityEn: 'SOZOPOL',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8142',
    addressNomenclature: '2',
    x: '27.638703',
    y: '42.44437',
    servingDays: '1111110',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '81181',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНОМОРЦИ',
    nameEn: 'CHERNOMORTSI',
    municipality: 'ШАБЛА',
    municipalityEn: 'SHABLA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9678',
    addressNomenclature: '0',
    x: '28.443857',
    y: '43.670896',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '81195',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНООК',
    nameEn: 'CHERNOOK',
    municipality: 'ПРОВАДИЯ',
    municipalityEn: 'PROVADIA',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9259',
    addressNomenclature: '0',
    x: '27.210467',
    y: '43.134186',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '81205',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНООКИ',
    nameEn: 'CHERNOOKI',
    municipality: 'КРУМОВГРАД',
    municipalityEn: 'KRUMOVGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6946',
    addressNomenclature: '0',
    x: '25.766702',
    y: '41.437762',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '81219',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНООКОВО',
    nameEn: 'CHERNOOKOVO',
    municipality: 'ГЕНЕРАЛ ТОШЕВО',
    municipalityEn: 'GENERAL TOSHEVO',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9532',
    addressNomenclature: '0',
    x: '28.168843',
    y: '43.71011',
    servingDays: '1010100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '81222',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНООКОВО',
    nameEn: 'CHERNOOKOVO',
    municipality: 'ВЪРБИЦА',
    municipalityEn: 'VARBITSA',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9873',
    addressNomenclature: '0',
    x: '26.596706',
    y: '42.984108',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '81236',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНООЧЕНЕ',
    nameEn: 'CHERNOOCHENE',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6701',
    addressNomenclature: '2',
    x: '25.350385',
    y: '41.759546',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '81253',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНЬОВО',
    nameEn: 'CHERNYOVO',
    municipality: 'ИХТИМАН',
    municipalityEn: 'IHTIMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2067',
    addressNomenclature: '0',
    x: '23.762424',
    y: '42.413073',
    servingDays: '1010100',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '81267',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕРНЬОВЦИ',
    nameEn: 'CHERNYOVTSI',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6670',
    addressNomenclature: '0',
    x: '25.529921',
    y: '41.727428',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '81270',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕСТИМЕНСКО',
    nameEn: 'CHESTIMENSKO',
    municipality: 'ТЕРВЕЛ',
    municipalityEn: 'TERVEL',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9469',
    addressNomenclature: '0',
    x: '27.347605',
    y: '43.779055',
    servingDays: '0101000',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '81284',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕТИРЦИ',
    nameEn: 'CHETIRTSI',
    municipality: 'НЕВЕСТИНО',
    municipalityEn: 'NEVESTINO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2598',
    addressNomenclature: '0',
    x: '22.876652',
    y: '42.241792',
    servingDays: '1010100',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '81298',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕТРОКА',
    nameEn: 'CHETROKA',
    municipality: 'ЛЪКИ',
    municipalityEn: 'LAKI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '',
    addressNomenclature: '0',
    x: '24.870684',
    y: '41.792914',
    servingDays: '0101000',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '81308',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕХЛАРЕ',
    nameEn: 'CHEHLARE',
    municipality: 'БРЕЗОВО',
    municipalityEn: 'BREZOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4159',
    addressNomenclature: '0',
    x: '25.158383',
    y: '42.420099',
    servingDays: '0101000',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '81325',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕШЛЯНЦИ',
    nameEn: 'CHESHLYANTSI',
    municipality: 'ТРЕКЛЯНО',
    municipalityEn: 'TREKLYANO',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2556',
    addressNomenclature: '0',
    x: '22.530401',
    y: '42.574096',
    servingDays: '0101000',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '81339',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕШМА',
    nameEn: 'CHESHMA',
    municipality: 'ЗЛАТАРИЦА',
    municipalityEn: 'ZLATARITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5077',
    addressNomenclature: '0',
    x: '26.029223',
    y: '43.088263',
    servingDays: '1010100',
    servingOfficeId: '46',
    servingHubOfficeId: '11',
  },
  {
    id: '81342',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧЕШНЕГИРОВО',
    nameEn: 'CHESHNEGIROVO',
    municipality: 'САДОВО',
    municipalityEn: 'SADOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4121',
    addressNomenclature: '0',
    x: '24.970445',
    y: '42.125768',
    servingDays: '0101010',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '81356',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧИБАОВЦИ',
    nameEn: 'CHIBAOVTSI',
    municipality: 'КОСТИНБРОД',
    municipalityEn: 'KOSTINBROD',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2236',
    addressNomenclature: '0',
    x: '23.239601',
    y: '42.93455',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '81362',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧИЛИК',
    nameEn: 'CHILIK',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6625',
    addressNomenclature: '0',
    x: '25.353517',
    y: '41.696299',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '81373',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧИЛНОВ',
    nameEn: 'CHILNOV',
    municipality: 'ДВЕ МОГИЛИ',
    municipalityEn: 'DVE MOGILI',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7162',
    addressNomenclature: '0',
    x: '25.919633',
    y: '43.521894',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '81387',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧИНТУЛОВО',
    nameEn: 'CHINTULOVO',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8858',
    addressNomenclature: '0',
    x: '26.237718',
    y: '42.645689',
    servingDays: '0101000',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '81390',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ЧИПРОВЦИ',
    nameEn: 'CHIPROVTSI',
    municipality: 'ЧИПРОВЦИ',
    municipalityEn: 'CHIPROVTSI',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3460',
    addressNomenclature: '2',
    x: '22.880774',
    y: '43.384539',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '81400',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧИРЕН',
    nameEn: 'CHIREN',
    municipality: 'ВРАЦА',
    municipalityEn: 'VRATSA',
    region: 'ВРАЦА',
    regionEn: 'VRATSA',
    postCode: '3050',
    addressNomenclature: '0',
    x: '23.579297',
    y: '43.330115',
    servingDays: '0101000',
    servingOfficeId: '20',
    servingHubOfficeId: '20',
  },
  {
    id: '81414',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ЧИРПАН',
    nameEn: 'CHIRPAN',
    municipality: 'ЧИРПАН',
    municipalityEn: 'CHIRPAN',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6200',
    addressNomenclature: '2',
    x: '25.325558',
    y: '42.198617',
    servingDays: '1111110',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '81428',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧИСТОВО',
    nameEn: 'CHISTOVO',
    municipality: 'ЗЛАТАРИЦА',
    municipalityEn: 'ZLATARITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5088',
    addressNomenclature: '0',
    x: '26.074506',
    y: '43.03169',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '81431',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧИТАКОВЦИ',
    nameEn: 'CHITAKOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5300',
    addressNomenclature: '0',
    x: '25.286253',
    y: '42.934519',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '81459',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧИФЛИК',
    nameEn: 'CHIFLIK',
    municipality: 'БЕЛОГРАДЧИК',
    municipalityEn: 'BELOGRADCHIK',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3912',
    addressNomenclature: '0',
    x: '22.643754',
    y: '43.599993',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '81462',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧИФЛИК',
    nameEn: 'CHIFLIK',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6677',
    addressNomenclature: '0',
    x: '25.499215',
    y: '41.68502',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '81476',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧИФЛИК',
    nameEn: 'CHIFLIK',
    municipality: 'ТРОЯН',
    municipalityEn: 'TROYAN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5661',
    addressNomenclature: '0',
    x: '24.585761',
    y: '42.835083',
    servingDays: '1010100',
    servingOfficeId: '44',
    servingHubOfficeId: '11',
  },
  {
    id: '81493',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧИЧЕВО',
    nameEn: 'CHICHEVO',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6869',
    addressNomenclature: '0',
    x: '25.277716',
    y: '41.347231',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '81503',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧИЧИЛ',
    nameEn: 'CHICHIL',
    municipality: 'КУЛА',
    municipalityEn: 'KULA',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3819',
    addressNomenclature: '0',
    x: '22.611073',
    y: '43.901909',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '81975',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧОБА',
    nameEn: 'CHOBA',
    municipality: 'БРЕЗОВО',
    municipalityEn: 'BREZOVO',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4161',
    addressNomenclature: '0',
    x: '25.091911',
    y: '42.314509',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '81517',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧОБАНКА',
    nameEn: 'CHOBANKA',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6836',
    addressNomenclature: '0',
    x: '25.485156',
    y: '41.538689',
    servingDays: '1111110',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '81520',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧОКМАНОВО',
    nameEn: 'CHOKMANOVO',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4760',
    addressNomenclature: '0',
    x: '24.730484',
    y: '41.535864',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '81534',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧОКОБА',
    nameEn: 'CHOKOBA',
    municipality: 'СЛИВЕН',
    municipalityEn: 'SLIVEN',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8887',
    addressNomenclature: '0',
    x: '26.358767',
    y: '42.518429',
    servingDays: '1010100',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '81863',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧОЛАКОВА',
    nameEn: 'CHOLAKOVA',
    municipality: 'ВЕЛИНГРАД',
    municipalityEn: 'VELINGRAD',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4636',
    addressNomenclature: '0',
    x: '23.793689',
    y: '41.97839',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '81548',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧОМАКОВО',
    nameEn: 'CHOMAKOVO',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6800',
    addressNomenclature: '0',
    x: '25.460871',
    y: '41.57784',
    servingDays: '1111110',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '81551',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧОМАКОВЦИ',
    nameEn: 'CHOMAKOVTSI',
    municipality: 'ЧЕРВЕН БРЯГ',
    municipalityEn: 'CHERVEN BRYAG',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5989',
    addressNomenclature: '0',
    x: '24.066794',
    y: '43.330374',
    servingDays: '1111100',
    servingOfficeId: '59',
    servingHubOfficeId: '20',
  },
  {
    id: '81565',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧОРБАДЖИЙСКО',
    nameEn: 'CHORBADZHIYSKO',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6890',
    addressNomenclature: '0',
    x: '25.402972',
    y: '41.372071',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '81579',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧОРУЛ',
    nameEn: 'CHORUL',
    municipality: 'ДРАГОМАН',
    municipalityEn: 'DRAGOMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2215',
    addressNomenclature: '0',
    x: '22.864781',
    y: '42.917287',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '81582',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧУБРА',
    nameEn: 'CHUBRA',
    municipality: 'СУНГУРЛАРЕ',
    municipalityEn: 'SUNGURLARE',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8461',
    addressNomenclature: '0',
    x: '26.705563',
    y: '42.755219',
    servingDays: '0101000',
    servingOfficeId: '28',
    servingHubOfficeId: '5',
  },
  {
    id: '81596',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧУБРИКА',
    nameEn: 'CHUBRIKA',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6786',
    addressNomenclature: '0',
    x: '25.202202',
    y: '41.585414',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '81606',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧУДИНЦИ',
    nameEn: 'CHUDINTSI',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2562',
    addressNomenclature: '0',
    x: '22.503505',
    y: '42.387952',
    servingDays: '0101000',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '81619',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧУДОМИР',
    nameEn: 'CHUDOMIR',
    municipality: 'ЛОЗНИЦА',
    municipalityEn: 'LOZNITSA',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7284',
    addressNomenclature: '0',
    x: '26.512868',
    y: '43.360291',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '81623',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧУЙПЕТЛОВО',
    nameEn: 'CHUYPETLOVO',
    municipality: 'ПЕРНИК',
    municipalityEn: 'PERNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2346',
    addressNomenclature: '0',
    x: '23.242623',
    y: '42.517168',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '81640',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧУКАРКА',
    nameEn: 'CHUKARKA',
    municipality: 'АЙТОС',
    municipalityEn: 'AYTOS',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8529',
    addressNomenclature: '0',
    x: '27.116663',
    y: '42.648742',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '81654',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧУКАРОВО',
    nameEn: 'CHUKAROVO',
    municipality: 'ТОПОЛОВГРАД',
    municipalityEn: 'TOPOLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6560',
    addressNomenclature: '0',
    x: '26.387593',
    y: '42.147162',
    servingDays: '1010100',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '81668',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧУКАТА',
    nameEn: 'CHUKATA',
    municipality: 'ЗЛАТАРИЦА',
    municipalityEn: 'ZLATARITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5088',
    addressNomenclature: '0',
    x: '26.078412',
    y: '42.998524',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '81671',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧУКАТА',
    nameEn: 'CHUKATA',
    municipality: 'ЛЪКИ',
    municipalityEn: 'LAKI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '',
    addressNomenclature: '0',
    x: '24.831006',
    y: '41.815134',
    servingDays: '0101000',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '81685',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧУКИЛИТЕ',
    nameEn: 'CHUKILITE',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5304',
    addressNomenclature: '0',
    x: '25.277756',
    y: '42.844204',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '81699',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧУКОВЕЗЕР',
    nameEn: 'CHUKOVEZER',
    municipality: 'ДРАГОМАН',
    municipalityEn: 'DRAGOMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2209',
    addressNomenclature: '0',
    x: '22.903922',
    y: '42.905044',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '81709',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧУКОВЕЦ',
    nameEn: 'CHUKOVETS',
    municipality: 'РАДОМИР',
    municipalityEn: 'RADOMIR',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2425',
    addressNomenclature: '0',
    x: '23.026131',
    y: '42.417923',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '81712',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧУКОВЕЦ',
    nameEn: 'CHUKOVETS',
    municipality: 'АЛФАТАР',
    municipalityEn: 'ALFATAR',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7537',
    addressNomenclature: '0',
    x: '27.250632',
    y: '43.848465',
    servingDays: '0101000',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '81726',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧУКОВО',
    nameEn: 'CHUKOVO',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5370',
    addressNomenclature: '0',
    x: '25.409177',
    y: '43.017156',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '81734',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧУКОВО',
    nameEn: 'CHUKOVO',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6818',
    addressNomenclature: '0',
    x: '25.434309',
    y: '41.489443',
    servingDays: '1111110',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '81757',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧУПРЕНЕ',
    nameEn: 'CHUPRENE',
    municipality: 'ЧУПРЕНЕ',
    municipalityEn: 'CHUPRENE',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3950',
    addressNomenclature: '0',
    x: '22.667057',
    y: '43.519452',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '81760',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧУРЕК',
    nameEn: 'CHUREK',
    municipality: 'ЕЛИН ПЕЛИН',
    municipalityEn: 'ELIN PELIN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2126',
    addressNomenclature: '0',
    x: '23.716924',
    y: '42.772405',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '81774',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧУРЕН',
    nameEn: 'CHUREN',
    municipality: 'РОДОПИ',
    municipalityEn: 'RODOPI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4226',
    addressNomenclature: '0',
    x: '24.527022',
    y: '41.953861',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '81788',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧУРИЛОВО',
    nameEn: 'CHURILOVO',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2885',
    addressNomenclature: '0',
    x: '23.001889',
    y: '41.460756',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '81791',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧУРИЧЕНИ',
    nameEn: 'CHURICHENI',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2891',
    addressNomenclature: '0',
    x: '23.126114',
    y: '41.455458',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '81801',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧУРКА',
    nameEn: 'CHURKA',
    municipality: 'МАДАН',
    municipalityEn: 'MADAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4915',
    addressNomenclature: '0',
    x: '24.921389',
    y: '41.515827',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '81815',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧУРУКОВО',
    nameEn: 'CHURUKOVO',
    municipality: 'ДЕВИН',
    municipalityEn: 'DEVIN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4823',
    addressNomenclature: '0',
    x: '24.505153',
    y: '41.859678',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '81829',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧУЧУЛИГА',
    nameEn: 'CHUCHULIGA',
    municipality: 'ИВАЙЛОВГРАД',
    municipalityEn: 'IVAYLOVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6572',
    addressNomenclature: '0',
    x: '25.916645',
    y: '41.550695',
    servingDays: '1010100',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '81832',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧУЧУЛИГОВО',
    nameEn: 'CHUCHULIGOVO',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2865',
    addressNomenclature: '0',
    x: '23.352752',
    y: '41.400741',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '31841',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЧУЧУР',
    nameEn: 'CHUCHUR',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4796',
    addressNomenclature: '0',
    x: '24.818961',
    y: '41.544651',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '83017',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ШАБЛА',
    nameEn: 'SHABLA',
    municipality: 'ШАБЛА',
    municipalityEn: 'SHABLA',
    region: 'ДОБРИЧ',
    regionEn: 'DOBRICH',
    postCode: '9680',
    addressNomenclature: '2',
    x: '28.531119',
    y: '43.535504',
    servingDays: '1111100',
    servingOfficeId: '128',
    servingHubOfficeId: '4',
  },
  {
    id: '83020',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ШАНОВО',
    nameEn: 'SHANOVO',
    municipality: 'МЪГЛИЖ',
    municipalityEn: 'MAGLIZH',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6169',
    addressNomenclature: '0',
    x: '25.634174',
    y: '42.559107',
    servingDays: '1010100',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '83034',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ШАРАНИ',
    nameEn: 'SHARANI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5300',
    addressNomenclature: '0',
    x: '25.32616',
    y: '42.925226',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '83048',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ШАРЕНСКА',
    nameEn: 'SHARENSKA',
    municipality: 'МАДАН',
    municipalityEn: 'MADAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4916',
    addressNomenclature: '0',
    x: '24.911022',
    y: '41.491958',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '83051',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ШАРКОВО',
    nameEn: 'SHARKOVO',
    municipality: 'БОЛЯРОВО',
    municipalityEn: 'BOLYAROVO',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8753',
    addressNomenclature: '0',
    x: '26.817972',
    y: '42.052116',
    servingDays: '1010100',
    servingOfficeId: '73',
    servingHubOfficeId: '5',
  },
  {
    id: '83082',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ШАТРОВО',
    nameEn: 'SHATROVO',
    municipality: 'БОБОВ ДОЛ',
    municipalityEn: 'BOBOV DOL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2684',
    addressNomenclature: '0',
    x: '22.976229',
    y: '42.293019',
    servingDays: '1010100',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '83106',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ШЕЙНОВО',
    nameEn: 'SHEYNOVO',
    municipality: 'КАЗАНЛЪК',
    municipalityEn: 'KAZANLAK',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6144',
    addressNomenclature: '0',
    x: '25.318839',
    y: '42.685602',
    servingDays: '1111100',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '83586',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ШЕМШЕВО',
    nameEn: 'SHEMSHEVO',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5027',
    addressNomenclature: '0',
    x: '25.556093',
    y: '43.071226',
    servingDays: '1111110',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '83123',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ШЕРЕМЕТЯ',
    nameEn: 'SHEREMETYA',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5034',
    addressNomenclature: '0',
    x: '25.692043',
    y: '43.077354',
    servingDays: '1111110',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '83154',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ШИВАРОВО',
    nameEn: 'SHIVAROVO',
    municipality: 'РУЕН',
    municipalityEn: 'RUEN',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8559',
    addressNomenclature: '0',
    x: '27.177911',
    y: '42.84008',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '15944',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ШИВАЧЕВО',
    nameEn: 'SHIVACHEVO',
    municipality: 'ТВЪРДИЦА',
    municipalityEn: 'TVARDITSA',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8895',
    addressNomenclature: '2',
    x: '26.028428',
    y: '42.686486',
    servingDays: '1111100',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '83168',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ШИЛИВЕРИ',
    nameEn: 'SHILIVERI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5094',
    addressNomenclature: '0',
    x: '25.795677',
    y: '42.859',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '83171',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ШИЛКОВЦИ',
    nameEn: 'SHILKOVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5087',
    addressNomenclature: '0',
    x: '25.775008',
    y: '42.926459',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '83185',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ШИПИКОВА МАХАЛА',
    nameEn: 'SHIPIKOVA MAHALA',
    municipality: 'БОЙНИЦА',
    municipalityEn: 'BOYNITSA',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3836',
    addressNomenclature: '0',
    x: '22.471136',
    y: '44.009739',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '83199',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ШИПКА',
    nameEn: 'SHIPKA',
    municipality: 'КАЗАНЛЪК',
    municipalityEn: 'KAZANLAK',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6150',
    addressNomenclature: '0',
    x: '25.333184',
    y: '42.712699',
    servingDays: '1111100',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '83209',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ШИПКОВИЦА',
    nameEn: 'SHIPKOVITSA',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2493',
    addressNomenclature: '0',
    x: '22.513437',
    y: '42.65231',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '83212',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ШИПКОВО',
    nameEn: 'SHIPKOVO',
    municipality: 'ТРОЯН',
    municipalityEn: 'TROYAN',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5663',
    addressNomenclature: '0',
    x: '24.554765',
    y: '42.878289',
    servingDays: '1010100',
    servingOfficeId: '44',
    servingHubOfficeId: '11',
  },
  {
    id: '83226',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ШИПОК',
    nameEn: 'SHIPOK',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6865',
    addressNomenclature: '0',
    x: '25.219585',
    y: '41.431282',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '83239',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ШИПОТ',
    nameEn: 'SHIPOT',
    municipality: 'ДИМОВО',
    municipalityEn: 'DIMOVO',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3744',
    addressNomenclature: '0',
    x: '22.793813',
    y: '43.731837',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '83243',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ШИПОЧАНЕ',
    nameEn: 'SHIPOCHANE',
    municipality: 'САМОКОВ',
    municipalityEn: 'SAMOKOV',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2015',
    addressNomenclature: '0',
    x: '23.612942',
    y: '42.346493',
    servingDays: '0101000',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '83257',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ШИПОЧАНО',
    nameEn: 'SHIPOCHANO',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2552',
    addressNomenclature: '0',
    x: '22.72789',
    y: '42.368844',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '83260',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ШИПЧЕНИТЕ',
    nameEn: 'SHIPCHENITE',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5344',
    addressNomenclature: '0',
    x: '25.27391',
    y: '42.940397',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '83274',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ШИРОКА ЛЪКА',
    nameEn: 'SHIROKA LAKA',
    municipality: 'СМОЛЯН',
    municipalityEn: 'SMOLYAN',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4710',
    addressNomenclature: '0',
    x: '24.581008',
    y: '41.6802',
    servingDays: '0101000',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '83288',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ШИРОКА ПОЛЯНА',
    nameEn: 'SHIROKA POLYANA',
    municipality: 'ХАСКОВО',
    municipalityEn: 'HASKOVO',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6365',
    addressNomenclature: '0',
    x: '25.409377',
    y: '41.813879',
    servingDays: '0101000',
    servingOfficeId: '6',
    servingHubOfficeId: '5',
  },
  {
    id: '83291',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ШИРОКИ ДОЛ',
    nameEn: 'SHIROKI DOL',
    municipality: 'САМОКОВ',
    municipalityEn: 'SAMOKOV',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2013',
    addressNomenclature: '0',
    x: '23.522838',
    y: '42.389036',
    servingDays: '0101000',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '83315',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ШИРОКО ПОЛЕ',
    nameEn: 'SHIROKO POLE',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6650',
    addressNomenclature: '0',
    x: '25.466114',
    y: '41.635871',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '83301',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ШИРОКОВО',
    nameEn: 'SHIROKOVO',
    municipality: 'ДВЕ МОГИЛИ',
    municipalityEn: 'DVE MOGILI',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7159',
    addressNomenclature: '0',
    x: '25.94523',
    y: '43.553789',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '83329',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ШИШЕНЦИ',
    nameEn: 'SHISHENTSI',
    municipality: 'БОЙНИЦА',
    municipalityEn: 'BOYNITSA',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3834',
    addressNomenclature: '0',
    x: '22.478591',
    y: '43.982647',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '83332',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ШИШКОВИЦА',
    nameEn: 'SHISHKOVITSA',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7971',
    addressNomenclature: '0',
    x: '26.143766',
    y: '43.128104',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '83354',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ШИШКОВЦИ',
    nameEn: 'SHISHKOVTSI',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2553',
    addressNomenclature: '0',
    x: '22.718767',
    y: '42.348553',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '83377',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ШИШМАНОВО',
    nameEn: 'SHISHMANOVO',
    municipality: 'ХАРМАНЛИ',
    municipalityEn: 'HARMANLI',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6458',
    addressNomenclature: '0',
    x: '25.925802',
    y: '41.982279',
    servingDays: '1010100',
    servingOfficeId: '47',
    servingHubOfficeId: '5',
  },
  {
    id: '83380',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ШИШМАНЦИ',
    nameEn: 'SHISHMANTSI',
    municipality: 'РАКОВСКИ',
    municipalityEn: 'RAKOVSKI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4133',
    addressNomenclature: '0',
    x: '24.997618',
    y: '42.223098',
    servingDays: '0101000',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '83394',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ШИЯКОВО',
    nameEn: 'SHIYAKOVO',
    municipality: 'ГУЛЯНЦИ',
    municipalityEn: 'GULYANTSI',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5962',
    addressNomenclature: '0',
    x: '24.707413',
    y: '43.606624',
    servingDays: '1010100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '83404',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ШКОРПИЛОВЦИ',
    nameEn: 'SHKORPILOVTSI',
    municipality: 'ДОЛНИ ЧИФЛИК',
    municipalityEn: 'DOLNI CHIFLIK',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9112',
    addressNomenclature: '0',
    x: '27.8778',
    y: '42.963144',
    servingDays: '1111100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '83418',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ШОДЕКОВЦИ',
    nameEn: 'SHODEKOVTSI',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5053',
    addressNomenclature: '0',
    x: '25.578072',
    y: '42.930923',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '83435',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ШОПИТЕ',
    nameEn: 'SHOPITE',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5459',
    addressNomenclature: '0',
    x: '24.986463',
    y: '42.84904',
    servingDays: '1010100',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '83449',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ШОПЦИ',
    nameEn: 'SHOPTSI',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6883',
    addressNomenclature: '0',
    x: '25.379988',
    y: '41.38217',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '83452',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ШУБЕЦИ',
    nameEn: 'SHUBETSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5097',
    addressNomenclature: '0',
    x: '25.872433',
    y: '42.87979',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '83466',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ШУМА',
    nameEn: 'SHUMA',
    municipality: 'ГОДЕЧ',
    municipalityEn: 'GODECH',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2252',
    addressNomenclature: '0',
    x: '23.090361',
    y: '42.99378',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '83497',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ШУМАТА',
    nameEn: 'SHUMATA',
    municipality: 'СЕВЛИЕВО',
    municipalityEn: 'SEVLIEVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5468',
    addressNomenclature: '0',
    x: '25.073968',
    y: '42.921345',
    servingDays: '1010100',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '83510',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ШУМЕН',
    nameEn: 'SHUMEN',
    municipality: 'ШУМЕН',
    municipalityEn: 'SHUMEN',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9700',
    addressNomenclature: '1',
    x: '26.930134',
    y: '43.274006',
    servingDays: '1111110',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '83524',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ШУМЕНЦИ',
    nameEn: 'SHUMENTSI',
    municipality: 'ТУТРАКАН',
    municipalityEn: 'TUTRAKAN',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7639',
    addressNomenclature: '0',
    x: '26.602343',
    y: '43.962616',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '83538',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ШУМНАТИЦА',
    nameEn: 'SHUMNATITSA',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6888',
    addressNomenclature: '0',
    x: '25.371746',
    y: '41.300973',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '83555',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ШУШНЯ',
    nameEn: 'SHUSHNYA',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5380',
    addressNomenclature: '0',
    x: '25.509215',
    y: '42.926226',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '84019',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЩЕРНА',
    nameEn: 'SHTERNA',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6842',
    addressNomenclature: '0',
    x: '25.215719',
    y: '41.510665',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '84022',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЩИПСКО',
    nameEn: 'SHTIPSKO',
    municipality: 'ВЪЛЧИ ДОЛ',
    municipalityEn: 'VALCHI DOL',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9283',
    addressNomenclature: '0',
    x: '27.528015',
    y: '43.376518',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '84036',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЩИТ',
    nameEn: 'SHTIT',
    municipality: 'СВИЛЕНГРАД',
    municipalityEn: 'SVILENGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6528',
    addressNomenclature: '0',
    x: '26.364436',
    y: '41.826284',
    servingDays: '0101000',
    servingOfficeId: '48',
    servingHubOfficeId: '5',
  },
  {
    id: '84049',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЩРЪКЛЕВО',
    nameEn: 'SHTRAKLEVO',
    municipality: 'ИВАНОВО',
    municipalityEn: 'IVANOVO',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7078',
    addressNomenclature: '0',
    x: '26.047877',
    y: '43.71454',
    servingDays: '1111100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '84067',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЩЪРКОВО',
    nameEn: 'SHTARKOVO',
    municipality: 'ЛЕСИЧОВО',
    municipalityEn: 'LESICHOVO',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4461',
    addressNomenclature: '0',
    x: '24.235573',
    y: '42.301632',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '85010',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЪГЛЕН',
    nameEn: 'AGLEN',
    municipality: 'ЛУКОВИТ',
    municipalityEn: 'LUKOVIT',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5791',
    addressNomenclature: '0',
    x: '24.314943',
    y: '43.201151',
    servingDays: '1010100',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '86012',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЮГОВО',
    nameEn: 'YUGOVO',
    municipality: 'ЛЪКИ',
    municipalityEn: 'LAKI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4241',
    addressNomenclature: '0',
    x: '24.800163',
    y: '41.882402',
    servingDays: '0101000',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '86026',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЮДЕЛНИК',
    nameEn: 'YUDELNIK',
    municipality: 'СЛИВО ПОЛЕ',
    municipalityEn: 'SLIVO POLE',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7061',
    addressNomenclature: '0',
    x: '26.2534',
    y: '43.858809',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '86043',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЮЛИЕВО',
    nameEn: 'YULIEVO',
    municipality: 'МЪГЛИЖ',
    municipalityEn: 'MAGLIZH',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6168',
    addressNomenclature: '0',
    x: '25.606669',
    y: '42.56581',
    servingDays: '1010100',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '86057',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЮНАК',
    nameEn: 'YUNAK',
    municipality: 'АВРЕН',
    municipalityEn: 'AVREN',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9138',
    addressNomenclature: '0',
    x: '27.611034',
    y: '43.079238',
    servingDays: '1010100',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '86060',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЮНАЦИ',
    nameEn: 'YUNATSI',
    municipality: 'МОМЧИЛГРАД',
    municipalityEn: 'MOMCHILGRAD',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6800',
    addressNomenclature: '0',
    x: '25.443229',
    y: '41.465004',
    servingDays: '1111110',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '86074',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЮНАЦИТЕ',
    nameEn: 'YUNATSITE',
    municipality: 'ПАЗАРДЖИК',
    municipalityEn: 'PAZARDZHIK',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4420',
    addressNomenclature: '0',
    x: '24.272047',
    y: '42.242018',
    servingDays: '0101000',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '86115',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЮНДОЛА',
    nameEn: 'YUNDOLA',
    municipality: 'ВЕЛИНГРАД',
    municipalityEn: 'VELINGRAD',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '4629',
    addressNomenclature: '0',
    x: '23.850486',
    y: '42.061604',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '86088',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЮНЕЦ',
    nameEn: 'YUNETS',
    municipality: 'ДОЛНИ ЧИФЛИК',
    municipalityEn: 'DOLNI CHIFLIK',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9109',
    addressNomenclature: '0',
    x: '27.763298',
    y: '42.930042',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '86091',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЮПЕР',
    nameEn: 'YUPER',
    municipality: 'КУБРАТ',
    municipalityEn: 'KUBRAT',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7338',
    addressNomenclature: '0',
    x: '26.400375',
    y: '43.912041',
    servingDays: '1010100',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '86101',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЮРУКОВО',
    nameEn: 'YURUKOVO',
    municipality: 'ЯКОРУДА',
    municipalityEn: 'YAKORUDA',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2796',
    addressNomenclature: '0',
    x: '23.616127',
    y: '41.97289',
    servingDays: '1111100',
    servingOfficeId: '125',
    servingHubOfficeId: '13',
  },
  {
    id: '87014',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ЯБЛАНИЦА',
    nameEn: 'YABLANITSA',
    municipality: 'ЯБЛАНИЦА',
    municipalityEn: 'YABLANITSA',
    region: 'ЛОВЕЧ',
    regionEn: 'LOVECH',
    postCode: '5750',
    addressNomenclature: '2',
    x: '24.100276',
    y: '43.024911',
    servingDays: '1111100',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '87028',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯБЛАНИЦА',
    nameEn: 'YABLANITSA',
    municipality: 'СВОГЕ',
    municipalityEn: 'SVOGE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2298',
    addressNomenclature: '0',
    x: '23.554737',
    y: '42.856441',
    servingDays: '1010100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '87031',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯБЛАНОВО',
    nameEn: 'YABLANOVO',
    municipality: 'КОТЕЛ',
    municipalityEn: 'KOTEL',
    region: 'СЛИВЕН',
    regionEn: 'SLIVEN',
    postCode: '8989',
    addressNomenclature: '0',
    x: '26.549897',
    y: '42.985721',
    servingDays: '1010100',
    servingOfficeId: '7',
    servingHubOfficeId: '5',
  },
  {
    id: '87059',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯБЪЛКОВЕЦ',
    nameEn: 'YABALKOVETS',
    municipality: 'АРДИНО',
    municipalityEn: 'ARDINO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6787',
    addressNomenclature: '0',
    x: '25.190196',
    y: '41.604302',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '87062',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯБЪЛКОВО',
    nameEn: 'YABALKOVO',
    municipality: 'КЮСТЕНДИЛ',
    municipalityEn: 'KYUSTENDIL',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2544',
    addressNomenclature: '0',
    x: '22.737635',
    y: '42.305974',
    servingDays: '1111110',
    servingOfficeId: '23',
    servingHubOfficeId: '142',
  },
  {
    id: '87076',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯБЪЛКОВО',
    nameEn: 'YABALKOVO',
    municipality: 'ДИМИТРОВГРАД',
    municipalityEn: 'DIMITROVGRAD',
    region: 'ХАСКОВО',
    regionEn: 'HASKOVO',
    postCode: '6440',
    addressNomenclature: '0',
    x: '25.440338',
    y: '42.069683',
    servingDays: '0101000',
    servingOfficeId: '42',
    servingHubOfficeId: '5',
  },
  {
    id: '87081',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯБЪЛКОВЦИ',
    nameEn: 'YABALKOVTSI',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5350',
    addressNomenclature: '0',
    x: '25.529956',
    y: '42.856229',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '87093',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯБЪЛЧЕВО',
    nameEn: 'YABALCHEVO',
    municipality: 'РУЕН',
    municipalityEn: 'RUEN',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8542',
    addressNomenclature: '0',
    x: '27.253969',
    y: '42.794577',
    servingDays: '1111100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '87103',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯБЪЛЧЕНИ',
    nameEn: 'YABALCHENI',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6715',
    addressNomenclature: '0',
    x: '25.334262',
    y: '41.762703',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '87117',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯВОР',
    nameEn: 'YAVOR',
    municipality: 'ТРЯВНА',
    municipalityEn: 'TRYAVNA',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5350',
    addressNomenclature: '0',
    x: '25.525166',
    y: '42.852565',
    servingDays: '0101000',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '87120',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯВОРЕЦ',
    nameEn: 'YAVORETS',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5334',
    addressNomenclature: '0',
    x: '25.158047',
    y: '42.961357',
    servingDays: '1010100',
    servingOfficeId: '33',
    servingHubOfficeId: '11',
  },
  {
    id: '87134',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯВОРНИЦА',
    nameEn: 'YAVORNITSA',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2896',
    addressNomenclature: '0',
    x: '23.051035',
    y: '41.36772',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '87148',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯВОРОВЕЦ',
    nameEn: 'YAVOROVETS',
    municipality: 'МЪГЛИЖ',
    municipalityEn: 'MAGLIZH',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6179',
    addressNomenclature: '0',
    x: '25.6318',
    y: '42.663238',
    servingDays: '0101000',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '87165',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯВОРОВО',
    nameEn: 'YAVOROVO',
    municipality: 'ЧЕРНООЧЕНЕ',
    municipalityEn: 'CHERNOOCHENE',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6696',
    addressNomenclature: '0',
    x: '25.275722',
    y: '41.738709',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '87182',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯВОРОВО',
    nameEn: 'YAVOROVO',
    municipality: 'СТАРА ЗАГОРА',
    municipalityEn: 'STARA ZAGORA',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6240',
    addressNomenclature: '0',
    x: '25.449072',
    y: '42.32502',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '87179',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯВРОВО',
    nameEn: 'YAVROVO',
    municipality: 'КУКЛЕН',
    municipalityEn: 'KUKLEN',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4258',
    addressNomenclature: '0',
    x: '24.797596',
    y: '41.996556',
    servingDays: '1010000',
    servingOfficeId: '17',
    servingHubOfficeId: '1',
  },
  {
    id: '87206',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯГНИЛО',
    nameEn: 'YAGNILO',
    municipality: 'ВЕТРИНО',
    municipalityEn: 'VETRINO',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9231',
    addressNomenclature: '0',
    x: '27.325026',
    y: '43.381257',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '87212',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯГОДА',
    nameEn: 'YAGODA',
    municipality: 'МЪГЛИЖ',
    municipalityEn: 'MAGLIZH',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6167',
    addressNomenclature: '0',
    x: '25.569091',
    y: '42.541906',
    servingDays: '1111100',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '87223',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯГОДИНА',
    nameEn: 'YAGODINA',
    municipality: 'БОРИНО',
    municipalityEn: 'BORINO',
    region: 'СМОЛЯН',
    regionEn: 'SMOLYAN',
    postCode: '4835',
    addressNomenclature: '0',
    x: '24.351428',
    y: '41.634355',
    servingDays: '1010100',
    servingOfficeId: '147',
    servingHubOfficeId: '1',
  },
  {
    id: '87237',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯГОДОВО',
    nameEn: 'YAGODOVO',
    municipality: 'БЕРКОВИЦА',
    municipalityEn: 'BERKOVITSA',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3537',
    addressNomenclature: '0',
    x: '23.185968',
    y: '43.218086',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '87240',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯГОДОВО',
    nameEn: 'YAGODOVO',
    municipality: 'РОДОПИ',
    municipalityEn: 'RODOPI',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4113',
    addressNomenclature: '0',
    x: '24.850112',
    y: '42.110407',
    servingDays: '1111110',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '87254',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯЗДАЧ',
    nameEn: 'YAZDACH',
    municipality: 'ЧИРПАН',
    municipalityEn: 'CHIRPAN',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6229',
    addressNomenclature: '0',
    x: '25.481958',
    y: '42.286269',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '87271',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯЗОВЕЦ',
    nameEn: 'YAZOVETS',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7991',
    addressNomenclature: '0',
    x: '26.223794',
    y: '43.172588',
    servingDays: '0101000',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '95039',
    countryId: '100',
    mainSiteId: '0',
    type: 'к.',
    typeEn: 'k.',
    name: 'ЯЗОВИР БАТАК',
    nameEn: 'YAZOVIR BATAK',
    municipality: 'БАТАК',
    municipalityEn: 'BATAK',
    region: 'ПАЗАРДЖИК',
    regionEn: 'PAZARDZHIK',
    postCode: '',
    addressNomenclature: '0',
    x: '24.174019',
    y: '41.937047',
    servingDays: '1010100',
    servingOfficeId: '184',
    servingHubOfficeId: '1',
  },
  {
    id: '87285',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯКИМ ГРУЕВО',
    nameEn: 'YAKIM GRUEVO',
    municipality: 'ИСПЕРИХ',
    municipalityEn: 'ISPERIH',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7443',
    addressNomenclature: '0',
    x: '26.867981',
    y: '43.756266',
    servingDays: '1010100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '87299',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯКИМОВО',
    nameEn: 'YAKIMOVO',
    municipality: 'ЯКИМОВО',
    municipalityEn: 'YAKIMOVO',
    region: 'МОНТАНА',
    regionEn: 'MONTANA',
    postCode: '3640',
    addressNomenclature: '2',
    x: '23.363295',
    y: '43.634869',
    servingDays: '0101000',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '87309',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯКОВИЦА',
    nameEn: 'YAKOVITSA',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6870',
    addressNomenclature: '0',
    x: '25.274879',
    y: '41.318275',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '87312',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯКОВО',
    nameEn: 'YAKOVO',
    municipality: 'ПЕТРИЧ',
    municipalityEn: 'PETRICH',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2875',
    addressNomenclature: '0',
    x: '23.140253',
    y: '41.515017',
    servingDays: '1111100',
    servingOfficeId: '139',
    servingHubOfficeId: '13',
  },
  {
    id: '87326',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯКОВЦИ',
    nameEn: 'YAKOVTSI',
    municipality: 'ЕЛЕНА',
    municipalityEn: 'ELENA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5081',
    addressNomenclature: '0',
    x: '25.816098',
    y: '42.932179',
    servingDays: '0101000',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '87338',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ЯКОРУДА',
    nameEn: 'YAKORUDA',
    municipality: 'ЯКОРУДА',
    municipalityEn: 'YAKORUDA',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2790',
    addressNomenclature: '2',
    x: '23.670067',
    y: '42.018596',
    servingDays: '1111100',
    servingOfficeId: '125',
    servingHubOfficeId: '13',
  },
  {
    id: '87343',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯЛБОТИНА',
    nameEn: 'YALBOTINA',
    municipality: 'ДРАГОМАН',
    municipalityEn: 'DRAGOMAN',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2220',
    addressNomenclature: '0',
    x: '22.833819',
    y: '42.872974',
    servingDays: '0101000',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '87360',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯЛОВО',
    nameEn: 'YALOVO',
    municipality: 'ВЕЛИКО ТЪРНОВО',
    municipalityEn: 'VELIKO TARNOVO',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5056',
    addressNomenclature: '0',
    x: '25.575633',
    y: '42.971682',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '87374',
    countryId: '100',
    mainSiteId: '0',
    type: 'гр.',
    typeEn: 'gr.',
    name: 'ЯМБОЛ',
    nameEn: 'YAMBOL',
    municipality: 'ЯМБОЛ',
    municipalityEn: 'YAMBOL',
    region: 'ЯМБОЛ',
    regionEn: 'YAMBOL',
    postCode: '8600',
    addressNomenclature: '1',
    x: '26.510837',
    y: '42.487542',
    servingDays: '1111110',
    servingOfficeId: '93',
    servingHubOfficeId: '5',
  },
  {
    id: '87388',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯМИНО',
    nameEn: 'YAMINO',
    municipality: 'ДЖЕБЕЛ',
    municipalityEn: 'DZHEBEL',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6854',
    addressNomenclature: '0',
    x: '25.239354',
    y: '41.457735',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '87391',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯМНА',
    nameEn: 'YAMNA',
    municipality: 'ЕТРОПОЛЕ',
    municipalityEn: 'ETROPOLE',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2183',
    addressNomenclature: '0',
    x: '24.113411',
    y: '42.830763',
    servingDays: '0101000',
    servingOfficeId: '141',
    servingHubOfficeId: '2',
  },
  {
    id: '87401',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯНА',
    nameEn: 'YANA',
    municipality: 'СТОЛИЧНА',
    municipalityEn: 'STOLICHNA',
    region: 'СОФИЯ (СТОЛИЦА)',
    regionEn: 'SOFIA (STOLITSA)',
    postCode: '1805',
    addressNomenclature: '2',
    x: '23.557838',
    y: '42.730633',
    servingDays: '1111100',
    servingOfficeId: '142',
    servingHubOfficeId: '142',
  },
  {
    id: '87415',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯНИНО',
    nameEn: 'YANINO',
    municipality: 'КИРКОВО',
    municipalityEn: 'KIRKOVO',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6865',
    addressNomenclature: '0',
    x: '25.2885',
    y: '41.388046',
    servingDays: '1010100',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '87429',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯНКОВО',
    nameEn: 'YANKOVO',
    municipality: 'СМЯДОВО',
    municipalityEn: 'SMYADOVO',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9823',
    addressNomenclature: '0',
    x: '27.123863',
    y: '43.076989',
    servingDays: '1010100',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '87432',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯНКОВЦИ',
    nameEn: 'YANKOVTSI',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5345',
    addressNomenclature: '0',
    x: '25.239457',
    y: '42.906485',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '87446',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯНОВО',
    nameEn: 'YANOVO',
    municipality: 'САНДАНСКИ',
    municipalityEn: 'SANDANSKI',
    region: 'БЛАГОЕВГРАД',
    regionEn: 'BLAGOEVGRAD',
    postCode: '2816',
    addressNomenclature: '0',
    x: '23.488071',
    y: '41.437884',
    servingDays: '1111110',
    servingOfficeId: '116',
    servingHubOfficeId: '13',
  },
  {
    id: '87453',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯНТРА',
    nameEn: 'YANTRA',
    municipality: 'ГОРНА ОРЯХОВИЦА',
    municipalityEn: 'GORNA ORYAHOVITSA',
    region: 'ВЕЛИКО ТЪРНОВО',
    regionEn: 'VELIKO TARNOVO',
    postCode: '5135',
    addressNomenclature: '0',
    x: '25.676121',
    y: '43.205778',
    servingDays: '0101000',
    servingOfficeId: '46',
    servingHubOfficeId: '11',
  },
  {
    id: '87463',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯНТРА',
    nameEn: 'YANTRA',
    municipality: 'ДРЯНОВО',
    municipalityEn: 'DRYANOVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5374',
    addressNomenclature: '0',
    x: '25.321345',
    y: '42.978095',
    servingDays: '1010100',
    servingOfficeId: '11',
    servingHubOfficeId: '11',
  },
  {
    id: '87477',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯНЬОВЕЦ',
    nameEn: 'YANYOVETS',
    municipality: 'ДИМОВО',
    municipalityEn: 'DIMOVO',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3949',
    addressNomenclature: '0',
    x: '22.763563',
    y: '43.605415',
    servingDays: '1010100',
    servingOfficeId: '60',
    servingHubOfficeId: '20',
  },
  {
    id: '87480',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯРДЖИЛОВЦИ',
    nameEn: 'YARDZHILOVTSI',
    municipality: 'ПЕРНИК',
    municipalityEn: 'PERNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2355',
    addressNomenclature: '0',
    x: '22.940803',
    y: '42.633966',
    servingDays: '1111100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '87494',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯРЕБИЦА',
    nameEn: 'YAREBITSA',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6732',
    addressNomenclature: '0',
    x: '25.258837',
    y: '41.61027',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '87504',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯРЕБИЦА',
    nameEn: 'YAREBITSA',
    municipality: 'ДУЛОВО',
    municipalityEn: 'DULOVO',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7691',
    addressNomenclature: '0',
    x: '27.008274',
    y: '43.842125',
    servingDays: '0101000',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '87518',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯРЕБИЧНА',
    nameEn: 'YAREBICHNA',
    municipality: 'АКСАКОВО',
    municipalityEn: 'AKSAKOVO',
    region: 'ВАРНА',
    regionEn: 'VARNA',
    postCode: '9158',
    addressNomenclature: '0',
    x: '27.873161',
    y: '43.299421',
    servingDays: '0101000',
    servingOfficeId: '4',
    servingHubOfficeId: '4',
  },
  {
    id: '87521',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯРЕБИЧНО',
    nameEn: 'YAREBICHNO',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7998',
    addressNomenclature: '0',
    x: '26.11997',
    y: '43.097563',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '87535',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯРЕБКОВИЦА',
    nameEn: 'YAREBKOVITSA',
    municipality: 'САМОКОВ',
    municipalityEn: 'SAMOKOV',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2023',
    addressNomenclature: '0',
    x: '23.239272',
    y: '42.38582',
    servingDays: '1111110',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
  {
    id: '87549',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯРЛОВИЦА',
    nameEn: 'YARLOVITSA',
    municipality: 'ДИМОВО',
    municipalityEn: 'DIMOVO',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3799',
    addressNomenclature: '0',
    x: '22.900565',
    y: '43.745982',
    servingDays: '0101000',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '87552',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯРЛОВО',
    nameEn: 'YARLOVO',
    municipality: 'САМОКОВ',
    municipalityEn: 'SAMOKOV',
    region: 'СОФИЯ',
    regionEn: 'SOFIA',
    postCode: '2029',
    addressNomenclature: '0',
    x: '23.280273',
    y: '42.46796',
    servingDays: '0101000',
    servingOfficeId: '71',
    servingHubOfficeId: '2',
  },
  {
    id: '87566',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯРЛОВЦИ',
    nameEn: 'YARLOVTSI',
    municipality: 'ТРЪН',
    municipalityEn: 'TRAN',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2481',
    addressNomenclature: '0',
    x: '22.553456',
    y: '42.80592',
    servingDays: '0101000',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '87579',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯРОСЛАВЦИ',
    nameEn: 'YAROSLAVTSI',
    municipality: 'БРЕЗНИК',
    municipalityEn: 'BREZNIK',
    region: 'ПЕРНИК',
    regionEn: 'PERNIK',
    postCode: '2376',
    addressNomenclature: '0',
    x: '22.822778',
    y: '42.813335',
    servingDays: '1010100',
    servingOfficeId: '127',
    servingHubOfficeId: '142',
  },
  {
    id: '87583',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯСЕН',
    nameEn: 'YASEN',
    municipality: 'НОВО СЕЛО',
    municipalityEn: 'NOVO SELO',
    region: 'ВИДИН',
    regionEn: 'VIDIN',
    postCode: '3775',
    addressNomenclature: '0',
    x: '22.879337',
    y: '44.117451',
    servingDays: '1010100',
    servingOfficeId: '113',
    servingHubOfficeId: '20',
  },
  {
    id: '87597',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯСЕН',
    nameEn: 'YASEN',
    municipality: 'ПЛЕВЕН',
    municipalityEn: 'PLEVEN',
    region: 'ПЛЕВЕН',
    regionEn: 'PLEVEN',
    postCode: '5850',
    addressNomenclature: '0',
    x: '24.522812',
    y: '43.430695',
    servingDays: '1111110',
    servingOfficeId: '12',
    servingHubOfficeId: '11',
  },
  {
    id: '87607',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯСЕНИТЕ',
    nameEn: 'YASENITE',
    municipality: 'ГАБРОВО',
    municipalityEn: 'GABROVO',
    region: 'ГАБРОВО',
    regionEn: 'GABROVO',
    postCode: '5335',
    addressNomenclature: '0',
    x: '25.387337',
    y: '42.868693',
    servingDays: '1111100',
    servingOfficeId: '15',
    servingHubOfficeId: '11',
  },
  {
    id: '87610',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯСЕНКОВО',
    nameEn: 'YASENKOVO',
    municipality: 'ВЕНЕЦ',
    municipalityEn: 'VENETS',
    region: 'ШУМЕН',
    regionEn: 'SHUMEN',
    postCode: '9777',
    addressNomenclature: '0',
    x: '26.836294',
    y: '43.547595',
    servingDays: '0101000',
    servingOfficeId: '8',
    servingHubOfficeId: '11',
  },
  {
    id: '87624',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯСЕНОВЕЦ',
    nameEn: 'YASENOVETS',
    municipality: 'РАЗГРАД',
    municipalityEn: 'RAZGRAD',
    region: 'РАЗГРАД',
    regionEn: 'RAZGRAD',
    postCode: '7250',
    addressNomenclature: '0',
    x: '26.62829',
    y: '43.568789',
    servingDays: '1111100',
    servingOfficeId: '120',
    servingHubOfficeId: '10',
  },
  {
    id: '87638',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯСЕНОВО',
    nameEn: 'YASENOVO',
    municipality: 'РУЕН',
    municipalityEn: 'RUEN',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8569',
    addressNomenclature: '0',
    x: '27.235718',
    y: '42.896867',
    servingDays: '1010100',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '87641',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯСЕНОВО',
    nameEn: 'YASENOVO',
    municipality: 'КАЗАНЛЪК',
    municipalityEn: 'KAZANLAK',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6147',
    addressNomenclature: '0',
    x: '25.250183',
    y: '42.688023',
    servingDays: '1010100',
    servingOfficeId: '21',
    servingHubOfficeId: '5',
  },
  {
    id: '87655',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯСНА ПОЛЯНА',
    nameEn: 'YASNA POLYANA',
    municipality: 'ПРИМОРСКО',
    municipalityEn: 'PRIMORSKO',
    region: 'БУРГАС',
    regionEn: 'BURGAS',
    postCode: '8147',
    addressNomenclature: '0',
    x: '27.613703',
    y: '42.280624',
    servingDays: '0101000',
    servingOfficeId: '3',
    servingHubOfficeId: '3',
  },
  {
    id: '87669',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯСНО ПОЛЕ',
    nameEn: 'YASNO POLE',
    municipality: 'МАРИЦА',
    municipalityEn: 'MARITSA',
    region: 'ПЛОВДИВ',
    regionEn: 'PLOVDIV',
    postCode: '4139',
    addressNomenclature: '0',
    x: '24.93883',
    y: '42.231837',
    servingDays: '1010100',
    servingOfficeId: '1',
    servingHubOfficeId: '1',
  },
  {
    id: '87672',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯСТРЕБ',
    nameEn: 'YASTREB',
    municipality: 'КЪРДЖАЛИ',
    municipalityEn: 'KARDZHALI',
    region: 'КЪРДЖАЛИ',
    regionEn: 'KARDZHALI',
    postCode: '6687',
    addressNomenclature: '0',
    x: '25.407144',
    y: '41.750843',
    servingDays: '0101000',
    servingOfficeId: '32',
    servingHubOfficeId: '5',
  },
  {
    id: '87686',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯСТРЕБИНО',
    nameEn: 'YASTREBINO',
    municipality: 'АНТОНОВО',
    municipalityEn: 'ANTONOVO',
    region: 'ТЪРГОВИЩЕ',
    regionEn: 'TARGOVISHTE',
    postCode: '7975',
    addressNomenclature: '0',
    x: '26.23298',
    y: '43.152217',
    servingDays: '1010100',
    servingOfficeId: '16',
    servingHubOfficeId: '11',
  },
  {
    id: '87694',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯСТРЕБНА',
    nameEn: 'YASTREBNA',
    municipality: 'СИТОВО',
    municipalityEn: 'SITOVO',
    region: 'СИЛИСТРА',
    regionEn: 'SILISTRA',
    postCode: '7664',
    addressNomenclature: '0',
    x: '27.073032',
    y: '43.971004',
    servingDays: '1010100',
    servingOfficeId: '24',
    servingHubOfficeId: '10',
  },
  {
    id: '87700',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯСТРЕБОВО',
    nameEn: 'YASTREBOVO',
    municipality: 'РУСЕ',
    municipalityEn: 'RUSE',
    region: 'РУСЕ',
    regionEn: 'RUSE',
    postCode: '7073',
    addressNomenclature: '0',
    x: '26.12651',
    y: '43.757037',
    servingDays: '0101000',
    servingOfficeId: '10',
    servingHubOfficeId: '10',
  },
  {
    id: '87713',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯСТРЕБОВО',
    nameEn: 'YASTREBOVO',
    municipality: 'ОПАН',
    municipalityEn: 'OPAN',
    region: 'СТАРА ЗАГОРА',
    regionEn: 'STARA ZAGORA',
    postCode: '6056',
    addressNomenclature: '0',
    x: '25.666501',
    y: '42.283102',
    servingDays: '1010100',
    servingOfficeId: '5',
    servingHubOfficeId: '5',
  },
  {
    id: '87727',
    countryId: '100',
    mainSiteId: '0',
    type: 'с.',
    typeEn: 's.',
    name: 'ЯХИНОВО',
    nameEn: 'YAHINOVO',
    municipality: 'ДУПНИЦА',
    municipalityEn: 'DUPNITSA',
    region: 'КЮСТЕНДИЛ',
    regionEn: 'KYUSTENDIL',
    postCode: '2622',
    addressNomenclature: '2',
    x: '23.138538',
    y: '42.292429',
    servingDays: '1111110',
    servingOfficeId: '118',
    servingHubOfficeId: '142',
  },
];

export default bgSites;
