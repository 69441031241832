export const getAddressLabel = (data) => {
    
    const isSpeedyOffice = data.deliveryPlace === 'speedyOffice';
    let addressString = '';

    if(isSpeedyOffice) {

        const speedyAddressObj = data.speedyOffice?.address;
        addressString += 'Speedy Office, ';
        if(speedyAddressObj?.fullAddressString){
            addressString += speedyAddressObj?.fullAddressString;
        }

    }else{
        
        if(data.streetName?.name && data.streetNumber){
            addressString += (data.streetName?.name + ' ' + data.streetNumber + ', ')
        }

        if(data.complex){
            addressString += data.complex.name
             if(data.blockNumber){
                addressString += ` ${data.blockNumber}`
             }
            addressString += ', '
        }

        if(data.entranceNumber){
            addressString += `vh ${data.entranceNumber}, `
        }

        if(data.apartmentNo){
            addressString += `ap. ${data.apartmentNo}, `
        }

        if(data.site){
            addressString += (data.site.name + ' ')
        }

        if(data.country){
            addressString += data.country.name
        }
    }

    return addressString
}
