const options = [
  {
    key: '0-105 Zero Cent Cinq',
    label: '0-105 Zero Cent Cinq',
  },
  {
    key: '0039 Italy',
    label: '0039 Italy',
  },
  {
    key: '0c',
    label: '0c',
  },
  {
    key: '1 Furst',
    label: '1 Furst',
  },
  {
    key: '1 Madison',
    label: '1 Madison',
  },
  {
    key: '1.2.3. Paris',
    label: '1.2.3. Paris',
  },
  {
    key: '1.4.3 Story By Line Up',
    label: '1.4.3 Story By Line Up',
  },
  {
    key: '1.State',
    label: '1.State',
  },
  {
    key: '1.State',
    label: '1.State',
  },
  {
    key: '10 Corse',
    label: '10 Corse',
  },
  {
    key: '10 Days',
    label: '10 Days',
  },
  {
    key: '10 Feet',
    label: '10 Feet',
  },
  {
    key: '10 Line',
    label: '10 Line',
  },
  {
    key: '10.Deep',
    label: '10.Deep',
  },
  {
    key: '100 C Boiling Point',
    label: '100 C Boiling Point',
  },
  {
    key: '10000 Ft Above Sea Level',
    label: '10000 Ft Above Sea Level',
  },
  {
    key: '101 Idees',
    label: '101 Idees',
  },
  {
    key: '101 Idees Free Style For You',
    label: '101 Idees Free Style For You',
  },
  {
    key: '109 F',
    label: '109 F',
  },
  {
    key: '10x',
    label: '10x',
  },
  {
    key: '11 Degrees',
    label: '11 Degrees',
  },
  {
    key: '11.1.Tylho',
    label: '11.1.Tylho',
  },
  {
    key: '12 13',
    label: '12 13',
  },
  {
    key: '12 Ans',
    label: '12 Ans',
  },
  {
    key: '12 Pm By Mon Ami',
    label: '12 Pm By Mon Ami',
  },
  {
    key: '12 | Midnight',
    label: '12 | Midnight',
  },
  {
    key: '12ami',
    label: '12ami',
  },
  {
    key: '12k',
    label: '12k',
  },
  {
    key: '14th & Union',
    label: '14th & Union',
  },
  {
    key: '157 Angelology',
    label: '157 Angelology',
  },
  {
    key: '157 Funk',
    label: '157 Funk',
  },
  {
    key: '159 Ma',
    label: '159 Ma',
  },
  {
    key: '17 & Co',
    label: '17 & Co',
  },
  {
    key: '17& Co.',
    label: '17& Co.',
  },
  {
    key: '172 Grams.',
    label: '172 Grams.',
  },
  {
    key: '18 Centimeters',
    label: '18 Centimeters',
  },
  {
    key: '18 Collectioneighteen',
    label: '18 Collectioneighteen',
  },
  {
    key: '1826 Jeans',
    label: '1826 Jeans',
  },
  {
    key: '19 Cooper',
    label: '19 Cooper',
  },
  {
    key: '19 Ninety:One',
    label: '19 Ninety:One',
  },
  {
    key: '1904 By Burton',
    label: '1904 By Burton',
  },
  {
    key: '191 Unlimited',
    label: '191 Unlimited',
  },
  {
    key: '1970 Seventy',
    label: '1970 Seventy',
  },
  {
    key: '1975 Dimension',
    label: '1975 Dimension',
  },
  {
    key: '19911ng',
    label: '19911ng',
  },
  {
    key: '19xx',
    label: '19xx',
  },
  {
    key: '1d',
    label: '1d',
  },
  {
    key: '1st Kiss',
    label: '1st Kiss',
  },
  {
    key: '1st Kiss Girls',
    label: '1st Kiss Girls',
  },
  {
    key: '1state',
    label: '1state',
  },
  {
    key: '1to3',
    label: '1to3',
  },
  {
    key: '2 Bizzy',
    label: '2 Bizzy',
  },
  {
    key: '2 Bottle',
    label: '2 Bottle',
  },
  {
    key: '2 Cute',
    label: '2 Cute',
  },
  {
    key: '2 Elles',
    label: '2 Elles',
  },
  {
    key: '2 Other Noties',
    label: '2 Other Noties',
  },
  {
    key: '2 Two',
    label: '2 Two',
  },
  {
    key: '2-Biz',
    label: '2-Biz',
  },
  {
    key: '2-Bizzy',
    label: '2-Bizzy',
  },
  {
    key: '2-Bizzy Basic',
    label: '2-Bizzy Basic',
  },
  {
    key: '2-Hip By Wrapper',
    label: '2-Hip By Wrapper',
  },
  {
    key: '2.1 Denim',
    label: '2.1 Denim',
  },
  {
    key: '2.Big',
    label: '2.Big',
  },
  {
    key: '2.Biz',
    label: '2.Biz',
  },
  {
    key: '2.Bizzy',
    label: '2.Bizzy',
  },
  {
    key: '20 Ans Men',
    label: '20 Ans Men',
  },
  {
    key: '20 Below',
    label: '20 Below',
  },
  {
    key: '20 Jeans',
    label: '20 Jeans',
  },
  {
    key: '20 Tween',
    label: '20 Tween',
  },
  {
    key: '2016 By Parasuco',
    label: '2016 By Parasuco',
  },
  {
    key: '21 Denim',
    label: '21 Denim',
  },
  {
    key: '21 Men',
    label: '21 Men',
  },
  {
    key: '2117 Of Sweden',
    label: '2117 Of Sweden',
  },
  {
    key: '212 Capsize',
    label: '212 Capsize',
  },
  {
    key: '212 Collection',
    label: '212 Collection',
  },
  {
    key: '212 Heavy Weight',
    label: '212 Heavy Weight',
  },
  {
    key: '212ny',
    label: '212ny',
  },
  {
    key: '22 Octobre',
    label: '22 Octobre',
  },
  {
    key: '23 Love Street',
    label: '23 Love Street',
  },
  {
    key: '23 Rd St',
    label: '23 Rd St',
  },
  {
    key: '24 Colours',
    label: '24 Colours',
  },
  {
    key: '24 Hours',
    label: '24 Hours',
  },
  {
    key: '24 Outdoor',
    label: '24 Outdoor',
  },
  {
    key: '24 Seven',
    label: '24 Seven',
  },
  {
    key: '24/7 Authentic Denim',
    label: '24/7 Authentic Denim',
  },
  {
    key: '2469 By Kram',
    label: '2469 By Kram',
  },
  {
    key: '24k Label By K Too',
    label: '24k Label By K Too',
  },
  {
    key: '26 Road',
    label: '26 Road',
  },
  {
    key: '27 Quai De Seine',
    label: '27 Quai De Seine',
  },
  {
    key: '27b',
    label: '27b',
  },
  {
    key: '2b Bebe',
    label: '2b Bebe',
  },
  {
    key: '2b Together',
    label: '2b Together',
  },
  {
    key: '2bella',
    label: '2bella',
  },
  {
    key: '2blind2c',
    label: '2blind2c',
  },
  {
    key: '2hearts',
    label: '2hearts',
  },
  {
    key: '2hearts',
    label: '2hearts',
  },
  {
    key: '2k Sport',
    label: '2k Sport',
  },
  {
    key: '2k4...',
    label: '2k4...',
  },
  {
    key: '2lired',
    label: '2lired',
  },
  {
    key: '2love Tony Cohen',
    label: '2love Tony Cohen',
  },
  {
    key: '2luv Plus',
    label: '2luv Plus',
  },
  {
    key: '2nd Day',
    label: '2nd Day',
  },
  {
    key: '2nd Floor',
    label: '2nd Floor',
  },
  {
    key: '2nd One',
    label: '2nd One',
  },
  {
    key: '2nd Precinct',
    label: '2nd Precinct',
  },
  {
    key: '2nd Skin',
    label: '2nd Skin',
  },
  {
    key: '2ndsign',
    label: '2ndsign',
  },
  {
    key: '2sable',
    label: '2sable',
  },
  {
    key: '2special',
    label: '2special',
  },
  {
    key: '2w Paris',
    label: '2w Paris',
  },
  {
    key: '2xu',
    label: '2xu',
  },
  {
    key: '2y Premium',
    label: '2y Premium',
  },
  {
    key: '2you',
    label: '2you',
  },
  {
    key: '3 Big Notes',
    label: '3 Big Notes',
  },
  {
    key: '3 Concepts',
    label: '3 Concepts',
  },
  {
    key: '3 Fortyinc.',
    label: '3 Fortyinc.',
  },
  {
    key: '3 Sisters',
    label: '3 Sisters',
  },
  {
    key: '3 Suisses Collection',
    label: '3 Suisses Collection',
  },
  {
    key: '3 Suisses Le Chouchou',
    label: '3 Suisses Le Chouchou',
  },
  {
    key: '3-6-T',
    label: '3-6-T',
  },
  {
    key: '32 Bar Blues',
    label: '32 Bar Blues',
  },
  {
    key: '32 Degrees',
    label: '32 Degrees',
  },
  {
    key: '32 Degrees Heat',
    label: '32 Degrees Heat',
  },
  {
    key: '33 Degrees',
    label: '33 Degrees',
  },
  {
    key: '34 Heritage',
    label: '34 Heritage',
  },
  {
    key: '36 Degrees',
    label: '36 Degrees',
  },
  {
    key: '36 Point 5',
    label: '36 Point 5',
  },
  {
    key: '360 Cashmere',
    label: '360 Cashmere',
  },
  {
    key: '365 + 1',
    label: '365 + 1',
  },
  {
    key: '365 Sunshine',
    label: '365 Sunshine',
  },
  {
    key: '39 Degrees',
    label: '39 Degrees',
  },
  {
    key: '3am Forever',
    label: '3am Forever',
  },
  {
    key: '3d Knight',
    label: '3d Knight',
  },
  {
    key: '3gs',
    label: '3gs',
  },
  {
    key: '3pommes',
    label: '3pommes',
  },
  {
    key: '3rd Floor Studio',
    label: '3rd Floor Studio',
  },
  {
    key: '3sixteen',
    label: '3sixteen',
  },
  {
    key: '3suisses Collection',
    label: '3suisses Collection',
  },
  {
    key: '3way Poncho',
    label: '3way Poncho',
  },
  {
    key: '3x1',
    label: '3x1',
  },
  {
    key: '3y Team',
    label: '3y Team',
  },
  {
    key: '4 Code',
    label: '4 Code',
  },
  {
    key: '4 Fashion Victims',
    label: '4 Fashion Victims',
  },
  {
    key: '4 Love',
    label: '4 Love',
  },
  {
    key: '4 Site',
    label: '4 Site',
  },
  {
    key: '4 Sports',
    label: '4 Sports',
  },
  {
    key: '4 Wards',
    label: '4 Wards',
  },
  {
    key: '4 Yoga',
    label: '4 Yoga',
  },
  {
    key: '4 You',
    label: '4 You',
  },
  {
    key: '4-Season',
    label: '4-Season',
  },
  {
    key: '4-U',
    label: '4-U',
  },
  {
    key: '4/5/6 Fashion Concept',
    label: '4/5/6 Fashion Concept',
  },
  {
    key: '40weft',
    label: '40weft',
  },
  {
    key: '41 Hawthorn',
    label: '41 Hawthorn',
  },
  {
    key: '42nd Street',
    label: '42nd Street',
  },
  {
    key: '42pops',
    label: '42pops',
  },
  {
    key: '44 Mm',
    label: '44 Mm',
  },
  {
    key: '45 One Of...',
    label: '45 One Of...',
  },
  {
    key: '46 Nord',
    label: '46 Nord',
  },
  {
    key: '47 Brand',
    label: '47 Brand',
  },
  {
    key: '49winters',
    label: '49winters',
  },
  {
    key: '4d',
    label: '4d',
  },
  {
    key: '4f',
    label: '4f',
  },
  {
    key: '4funky Flavours',
    label: '4funky Flavours',
  },
  {
    key: '4her',
    label: '4her',
  },
  {
    key: '4her By Carl Banks',
    label: '4her By Carl Banks',
  },
  {
    key: '4knits',
    label: '4knits',
  },
  {
    key: '4p`S By Men',
    label: '4p`S By Men',
  },
  {
    key: '4th Reckless',
    label: '4th Reckless',
  },
  {
    key: '4x4',
    label: '4x4',
  },
  {
    key: '5 7 2009',
    label: '5 7 2009',
  },
  {
    key: '5 Parrots',
    label: '5 Parrots',
  },
  {
    key: '5 Seconds Of Summer',
    label: '5 Seconds Of Summer',
  },
  {
    key: '5 Swiss',
    label: '5 Swiss',
  },
  {
    key: '5.11 Tactical',
    label: '5.11 Tactical',
  },
  {
    key: '500 Maison Cinqcent',
    label: '500 Maison Cinqcent',
  },
  {
    key: '52 Weekends',
    label: '52 Weekends',
  },
  {
    key: '525 America',
    label: '525 America',
  },
  {
    key: '55 Dsl',
    label: '55 Dsl',
  },
  {
    key: '55 Stage',
    label: '55 Stage',
  },
  {
    key: '555 Los Angeles',
    label: '555 Los Angeles',
  },
  {
    key: '56 Red Jeans',
    label: '56 Red Jeans',
  },
  {
    key: '57 By Sissy - Boy',
    label: '57 By Sissy - Boy',
  },
  {
    key: '5c',
    label: '5c',
  },
  {
    key: '5cm',
    label: '5cm',
  },
  {
    key: '5ive Jungle & Co.',
    label: '5ive Jungle & Co.',
  },
  {
    key: '5preview',
    label: '5preview',
  },
  {
    key: '5siss',
    label: '5siss',
  },
  {
    key: '5th & Love',
    label: '5th & Love',
  },
  {
    key: '5th Avenue',
    label: '5th Avenue',
  },
  {
    key: '5th Concept',
    label: '5th Concept',
  },
  {
    key: '5th Culture',
    label: '5th Culture',
  },
  {
    key: '5th&Ocean',
    label: '5th&Ocean',
  },
  {
    key: '6 Degrees',
    label: '6 Degrees',
  },
  {
    key: '6&8',
    label: '6&8',
  },
  {
    key: '66 North Iceland',
    label: '66 North Iceland',
  },
  {
    key: '66girls',
    label: '66girls',
  },
  {
    key: '69 Level',
    label: '69 Level',
  },
  {
    key: '6ixty 8ight',
    label: '6ixty 8ight',
  },
  {
    key: '6th&Ln',
    label: '6th&Ln',
  },
  {
    key: '6`Am',
    label: '6`Am',
  },
  {
    key: '7 A.M Enfant',
    label: '7 A.M Enfant',
  },
  {
    key: '7 Camicie',
    label: '7 Camicie',
  },
  {
    key: '7 Cm',
    label: '7 Cm',
  },
  {
    key: '7 Diamonds',
    label: '7 Diamonds',
  },
  {
    key: '7 Eleven',
    label: '7 Eleven',
  },
  {
    key: '7 For All Mankind',
    label: '7 For All Mankind',
  },
  {
    key: '7 Rayne',
    label: '7 Rayne',
  },
  {
    key: '7 Seasons',
    label: '7 Seasons',
  },
  {
    key: '725 Originals',
    label: '725 Originals',
  },
  {
    key: '75 Faubourg',
    label: '75 Faubourg',
  },
  {
    key: '77 City',
    label: '77 City',
  },
  {
    key: '77 Kids By American Eagle',
    label: '77 Kids By American Eagle',
  },
  {
    key: '77 Kids By American Eagle',
    label: '77 Kids By American Eagle',
  },
  {
    key: '77th Flea',
    label: '77th Flea',
  },
  {
    key: '7:Am',
    label: '7:Am',
  },
  {
    key: '7days',
    label: '7days',
  },
  {
    key: '7diamonds',
    label: '7diamonds',
  },
  {
    key: '7q & 8b',
    label: '7q & 8b',
  },
  {
    key: '7upply',
    label: '7upply',
  },
  {
    key: '7xcollection',
    label: '7xcollection',
  },
  {
    key: '8 Knit - Ted',
    label: '8 Knit - Ted',
  },
  {
    key: '8 Knit-Ted',
    label: '8 Knit-Ted',
  },
  {
    key: '8 Of Hearts',
    label: '8 Of Hearts',
  },
  {
    key: '8 Rod Butterflies',
    label: '8 Rod Butterflies',
  },
  {
    key: '8055 605',
    label: '8055 605',
  },
  {
    key: '81 Hour',
    label: '81 Hour',
  },
  {
    key: '82 Days',
    label: '82 Days',
  },
  {
    key: '82 Zero',
    label: '82 Zero',
  },
  {
    key: '8848 Altitude',
    label: '8848 Altitude',
  },
  {
    key: '888 Poker',
    label: '888 Poker',
  },
  {
    key: '89th & Madison',
    label: '89th & Madison',
  },
  {
    key: '89th And Madison',
    label: '89th And Madison',
  },
  {
    key: '8mm',
    label: '8mm',
  },
  {
    key: '8seconds',
    label: '8seconds',
  },
  {
    key: '8th Avenue',
    label: '8th Avenue',
  },
  {
    key: '8th Dstrkt',
    label: '8th Dstrkt',
  },
  {
    key: '9 Richter',
    label: '9 Richter',
  },
  {
    key: '9&Co',
    label: '9&Co',
  },
  {
    key: '9-H15 Stcl',
    label: '9-H15 Stcl',
  },
  {
    key: '9.8 Jens',
    label: '9.8 Jens',
  },
  {
    key: '90 Degree By Reflex',
    label: '90 Degree By Reflex',
  },
  {
    key: '90.E Grad',
    label: '90.E Grad',
  },
  {
    key: '93 One Of...',
    label: '93 One Of...',
  },
  {
    key: '98-86',
    label: '98-86',
  },
  {
    key: '98-86',
    label: '98-86',
  },
  {
    key: '99 Jane Street',
    label: '99 Jane Street',
  },
  {
    key: '9fashion',
    label: '9fashion',
  },
  {
    key: '9fashion',
    label: '9fashion',
  },
  {
    key: '9k Clothing Co',
    label: '9k Clothing Co',
  },
  {
    key: '9th Avenue',
    label: '9th Avenue',
  },
  {
    key: 'Amisu',
    label: 'Amisu',
  },
  {
    key: 'Atmosphere',
    label: 'Atmosphere',
  },
  {
    key: 'Asos',
    label: 'Asos',
  },
  {
    key: 'Anna Field',
    label: 'Anna Field',
  },
  {
    key: 'Adidas',
    label: 'Adidas',
  },
  {
    key: 'Alexander Wang For H&M',
    label: 'Alexander Wang For H&M',
  },
  {
    key: 'Angelo Litrico',
    label: 'Angelo Litrico',
  },
  {
    key: 'Ann Taylor',
    label: 'Ann Taylor',
  },
  {
    key: 'Abercrombie & Fitch',
    label: 'Abercrombie & Fitch',
  },
  {
    key: 'Apricot',
    label: 'Apricot',
  },
  {
    key: 'American Eagle',
    label: 'American Eagle',
  },
  {
    key: 'Alcott',
    label: 'Alcott',
  },
  {
    key: 'Ally',
    label: 'Ally',
  },
  {
    key: 'Adidas Originals',
    label: 'Adidas Originals',
  },
  {
    key: 'Apart',
    label: 'Apart',
  },
  {
    key: 'Almatrichi',
    label: 'Almatrichi',
  },
  {
    key: 'Anne Klein',
    label: 'Anne Klein',
  },
  {
    key: 'Aldo',
    label: 'Aldo',
  },
  {
    key: 'Autograph',
    label: 'Autograph',
  },
  {
    key: 'Ann Christine',
    label: 'Ann Christine',
  },
  {
    key: 'Asics',
    label: 'Asics',
  },
  {
    key: 'Active By Tchibo',
    label: 'Active By Tchibo',
  },
  {
    key: 'Arizona',
    label: 'Arizona',
  },
  {
    key: 'Aeropostale',
    label: 'Aeropostale',
  },
  {
    key: 'Antony Morato',
    label: 'Antony Morato',
  },
  {
    key: 'Arrow',
    label: 'Arrow',
  },
  {
    key: 'A.N.A.',
    label: 'A.N.A.',
  },
  {
    key: 'Atlas For Men',
    label: 'Atlas For Men',
  },
  {
    key: 'Alba Moda',
    label: 'Alba Moda',
  },
  {
    key: 'Aniston',
    label: 'Aniston',
  },
  {
    key: 'Avant Premiere',
    label: 'Avant Premiere',
  },
  {
    key: 'Ajc',
    label: 'Ajc',
  },
  {
    key: 'Ardene',
    label: 'Ardene',
  },
  {
    key: 'Astrapahl',
    label: 'Astrapahl',
  },
  {
    key: 'Atelier Gardeur',
    label: 'Atelier Gardeur',
  },
  {
    key: 'Ambiance Apparel',
    label: 'Ambiance Apparel',
  },
  {
    key: 'Alfani',
    label: 'Alfani',
  },
  {
    key: 'Ashley Brooke',
    label: 'Ashley Brooke',
  },
  {
    key: 'Adagio',
    label: 'Adagio',
  },
  {
    key: 'Atlas For Women',
    label: 'Atlas For Women',
  },
  {
    key: 'Armani Exchange',
    label: 'Armani Exchange',
  },
  {
    key: 'Alia',
    label: 'Alia',
  },
  {
    key: 'Alberto',
    label: 'Alberto',
  },
  {
    key: 'Apostrophe',
    label: 'Apostrophe',
  },
  {
    key: 'Apanage',
    label: 'Apanage',
  },
  {
    key: 'Athletic Works',
    label: 'Athletic Works',
  },
  {
    key: 'Ambiance',
    label: 'Ambiance',
  },
  {
    key: 'Armand Ventilo',
    label: 'Armand Ventilo',
  },
  {
    key: 'Anany',
    label: 'Anany',
  },
  {
    key: 'Apriori',
    label: 'Apriori',
  },
  {
    key: 'American Vintage',
    label: 'American Vintage',
  },
  {
    key: 'Armani Jeans',
    label: 'Armani Jeans',
  },
  {
    key: 'Ax Paris',
    label: 'Ax Paris',
  },
  {
    key: 'Abercrombie Kids',
    label: 'Abercrombie Kids',
  },
  {
    key: 'Ahlens',
    label: 'Ahlens',
  },
  {
    key: 'Artigli',
    label: 'Artigli',
  },
  {
    key: 'Anel',
    label: 'Anel',
  },
  {
    key: 'Alfred Dunner',
    label: 'Alfred Dunner',
  },
  {
    key: 'Axara',
    label: 'Axara',
  },
  {
    key: 'Atmos & Here',
    label: 'Atmos & Here',
  },
  {
    key: 'Avocado',
    label: 'Avocado',
  },
  {
    key: 'Arena',
    label: 'Arena',
  },
  {
    key: 'Absorba',
    label: 'Absorba',
  },
  {
    key: 'Alpinetek',
    label: 'Alpinetek',
  },
  {
    key: 'Adrianna Papell',
    label: 'Adrianna Papell',
  },
  {
    key: 'American Apparel',
    label: 'American Apparel',
  },
  {
    key: 'Athletech',
    label: 'Athletech',
  },
  {
    key: 'Arctix',
    label: 'Arctix',
  },
  {
    key: 'Angels',
    label: 'Angels',
  },
  {
    key: 'Airfield',
    label: 'Airfield',
  },
  {
    key: 'Allsaints',
    label: 'Allsaints',
  },
  {
    key: 'Atrium',
    label: 'Atrium',
  },
  {
    key: 'Ab Studio',
    label: 'Ab Studio',
  },
  {
    key: 'Amy Vermont',
    label: 'Amy Vermont',
  },
  {
    key: 'All About Eve',
    label: 'All About Eve',
  },
  {
    key: 'A.W.Dunmore',
    label: 'A.W.Dunmore',
  },
  {
    key: "An'ge",
    label: "An'ge",
  },
  {
    key: 'Armani Collezioni',
    label: 'Armani Collezioni',
  },
  {
    key: 'Alpha Industries',
    label: 'Alpha Industries',
  },
  {
    key: 'Almost Famous',
    label: 'Almost Famous',
  },
  {
    key: 'Azzaro',
    label: 'Azzaro',
  },
  {
    key: 'Angie',
    label: 'Angie',
  },
  {
    key: 'Authentic',
    label: 'Authentic',
  },
  {
    key: 'Ada Gatti',
    label: 'Ada Gatti',
  },
  {
    key: 'Arden B.',
    label: 'Arden B.',
  },
  {
    key: 'Asos Petite',
    label: 'Asos Petite',
  },
  {
    key: 'Ana Alcazar',
    label: 'Ana Alcazar',
  },
  {
    key: 'Adilisk',
    label: 'Adilisk',
  },
  {
    key: 'Authentic Style',
    label: 'Authentic Style',
  },
  {
    key: 'Armata Di Mare',
    label: 'Armata Di Mare',
  },
  {
    key: 'Avanti',
    label: 'Avanti',
  },
  {
    key: 'Andy Warhol By Pepe Jeans',
    label: 'Andy Warhol By Pepe Jeans',
  },
  {
    key: 'Atelier Gs',
    label: 'Atelier Gs',
  },
  {
    key: 'Armand Thiery',
    label: 'Armand Thiery',
  },
  {
    key: 'Alya',
    label: 'Alya',
  },
  {
    key: 'Allison Brittney',
    label: 'Allison Brittney',
  },
  {
    key: 'Aventures Des Toiles',
    label: 'Aventures Des Toiles',
  },
  {
    key: 'Amy & Ivy',
    label: 'Amy & Ivy',
  },
  {
    key: 'Ariston S',
    label: 'Ariston S',
  },
  {
    key: 'Adidas Neo',
    label: 'Adidas Neo',
  },
  {
    key: 'A.Byer',
    label: 'A.Byer',
  },
  {
    key: 'Aqua',
    label: 'Aqua',
  },
  {
    key: 'Acne',
    label: 'Acne',
  },
  {
    key: 'Alfredo Pauly',
    label: 'Alfredo Pauly',
  },
  {
    key: 'Allyson',
    label: 'Allyson',
  },
  {
    key: 'Adolfo Dominguez',
    label: 'Adolfo Dominguez',
  },
  {
    key: 'Aerie',
    label: 'Aerie',
  },
  {
    key: 'Alpine Pro',
    label: 'Alpine Pro',
  },
  {
    key: 'Awama',
    label: 'Awama',
  },
  {
    key: 'Alannah Hill',
    label: 'Alannah Hill',
  },
  {
    key: 'A Wear',
    label: 'A Wear',
  },
  {
    key: 'Andrews',
    label: 'Andrews',
  },
  {
    key: 'Andrea',
    label: 'Andrea',
  },
  {
    key: 'Attrattivo',
    label: 'Attrattivo',
  },
  {
    key: 'A-K-R-I-S-',
    label: 'A-K-R-I-S-',
  },
  {
    key: 'Anna Scott',
    label: 'Anna Scott',
  },
  {
    key: 'Abacus',
    label: 'Abacus',
  },
  {
    key: 'Alive',
    label: 'Alive',
  },
  {
    key: 'Atos Lombardini',
    label: 'Atos Lombardini',
  },
  {
    key: 'Armedangels',
    label: 'Armedangels',
  },
  {
    key: 'Armani Junior',
    label: 'Armani Junior',
  },
  {
    key: 'Ac',
    label: 'Ac',
  },
  {
    key: 'Air Jordan Nike',
    label: 'Air Jordan Nike',
  },
  {
    key: 'Anthology',
    label: 'Anthology',
  },
  {
    key: 'Ara',
    label: 'Ara',
  },
  {
    key: 'Andrew Marc',
    label: 'Andrew Marc',
  },
  {
    key: 'Ane Mone',
    label: 'Ane Mone',
  },
  {
    key: 'Alice Bizous',
    label: 'Alice Bizous',
  },
  {
    key: 'Ariston',
    label: 'Ariston',
  },
  {
    key: 'Accessorize',
    label: 'Accessorize',
  },
  {
    key: "Anson's",
    label: "Anson's",
  },
  {
    key: 'Alma En Pena',
    label: 'Alma En Pena',
  },
  {
    key: 'Ava & Viv',
    label: 'Ava & Viv',
  },
  {
    key: 'Ambra',
    label: 'Ambra',
  },
  {
    key: 'Alice & You',
    label: 'Alice & You',
  },
  {
    key: 'Animale',
    label: 'Animale',
  },
  {
    key: 'Active Wear',
    label: 'Active Wear',
  },
  {
    key: 'Aidan Mattox',
    label: 'Aidan Mattox',
  },
  {
    key: 'Alpha',
    label: 'Alpha',
  },
  {
    key: 'Alexia',
    label: 'Alexia',
  },
  {
    key: 'Arma',
    label: 'Arma',
  },
  {
    key: 'Abound',
    label: 'Abound',
  },
  {
    key: 'Alberto Guardiani',
    label: 'Alberto Guardiani',
  },
  {
    key: 'Ash',
    label: 'Ash',
  },
  {
    key: 'Ae',
    label: 'Ae',
  },
  {
    key: 'Azaka',
    label: 'Azaka',
  },
  {
    key: 'Antilia Femme',
    label: 'Antilia Femme',
  },
  {
    key: 'Acne Studios',
    label: 'Acne Studios',
  },
  {
    key: 'Allison Daley',
    label: 'Allison Daley',
  },
  {
    key: 'Active',
    label: 'Active',
  },
  {
    key: 'Ali & Kris',
    label: 'Ali & Kris',
  },
  {
    key: 'Ana Sousa',
    label: 'Ana Sousa',
  },
  {
    key: 'Anna',
    label: 'Anna',
  },
  {
    key: 'Allen B.',
    label: 'Allen B.',
  },
  {
    key: 'Amalfi',
    label: 'Amalfi',
  },
  {
    key: 'Aniye By',
    label: 'Aniye By',
  },
  {
    key: 'Alyssa',
    label: 'Alyssa',
  },
  {
    key: 'Another Woman',
    label: 'Another Woman',
  },
  {
    key: 'Aigle',
    label: 'Aigle',
  },
  {
    key: 'Adrienne Vittadini',
    label: 'Adrienne Vittadini',
  },
  {
    key: 'Art Class',
    label: 'Art Class',
  },
  {
    key: 'Augusta',
    label: 'Augusta',
  },
  {
    key: 'Amanda Smith',
    label: 'Amanda Smith',
  },
  {
    key: 'Albert Nipon',
    label: 'Albert Nipon',
  },
  {
    key: 'Aquila',
    label: 'Aquila',
  },
  {
    key: 'Alberto Makali',
    label: 'Alberto Makali',
  },
  {
    key: 'America Today',
    label: 'America Today',
  },
  {
    key: 'Anna Justper',
    label: 'Anna Justper',
  },
  {
    key: 'Anthropologie',
    label: 'Anthropologie',
  },
  {
    key: 'Alphabet',
    label: 'Alphabet',
  },
  {
    key: 'Anna Montana',
    label: 'Anna Montana',
  },
  {
    key: 'Alyn Paige',
    label: 'Alyn Paige',
  },
  {
    key: 'Akropol',
    label: 'Akropol',
  },
  {
    key: 'Ambika',
    label: 'Ambika',
  },
  {
    key: 'Active Basic Usa',
    label: 'Active Basic Usa',
  },
  {
    key: 'Adidas By Stella Mccartney',
    label: 'Adidas By Stella Mccartney',
  },
  {
    key: 'Amaranto',
    label: 'Amaranto',
  },
  {
    key: 'Arnold Palmer',
    label: 'Arnold Palmer',
  },
  {
    key: "Altar'd State",
    label: "Altar'd State",
  },
  {
    key: 'Anouk',
    label: 'Anouk',
  },
  {
    key: 'Articles Of Society',
    label: 'Articles Of Society',
  },
  {
    key: 'Anerkjendt',
    label: 'Anerkjendt',
  },
  {
    key: 'Atlantic Bay',
    label: 'Atlantic Bay',
  },
  {
    key: 'Aaiko',
    label: 'Aaiko',
  },
  {
    key: 'Austin Reed',
    label: 'Austin Reed',
  },
  {
    key: 'Angelica',
    label: 'Angelica',
  },
  {
    key: 'Anthea Crawford',
    label: 'Anthea Crawford',
  },
  {
    key: 'Allison Taylor',
    label: 'Allison Taylor',
  },
  {
    key: 'Adolfo',
    label: 'Adolfo',
  },
  {
    key: 'Alorna',
    label: 'Alorna',
  },
  {
    key: 'Anna Riska',
    label: 'Anna Riska',
  },
  {
    key: 'Anima Bella',
    label: 'Anima Bella',
  },
  {
    key: 'Alexander Mcqueen',
    label: 'Alexander Mcqueen',
  },
  {
    key: 'Accessoires',
    label: 'Accessoires',
  },
  {
    key: 'American Living',
    label: 'American Living',
  },
  {
    key: 'Avalanche',
    label: 'Avalanche',
  },
  {
    key: 'Amma Rachel',
    label: 'Amma Rachel',
  },
  {
    key: 'American Dream',
    label: 'American Dream',
  },
  {
    key: 'Antik Batik',
    label: 'Antik Batik',
  },
  {
    key: 'Amy Gee',
    label: 'Amy Gee',
  },
  {
    key: 'Amichi',
    label: 'Amichi',
  },
  {
    key: 'Ann Summers',
    label: 'Ann Summers',
  },
  {
    key: 'Ancora',
    label: 'Ancora',
  },
  {
    key: 'Alain Manoukian',
    label: 'Alain Manoukian',
  },
  {
    key: 'Alessi',
    label: 'Alessi',
  },
  {
    key: 'Active&Co',
    label: 'Active&Co',
  },
  {
    key: 'Akac Ecou',
    label: 'Akac Ecou',
  },
  {
    key: 'Alice + Olivia',
    label: 'Alice + Olivia',
  },
  {
    key: 'Aggi',
    label: 'Aggi',
  },
  {
    key: 'Anny N',
    label: 'Anny N',
  },
  {
    key: 'Anna Rossi',
    label: 'Anna Rossi',
  },
  {
    key: 'Amy Byer',
    label: 'Amy Byer',
  },
  {
    key: 'Aberdeen',
    label: 'Aberdeen',
  },
  {
    key: 'Anna K',
    label: 'Anna K',
  },
  {
    key: 'Arket',
    label: 'Arket',
  },
  {
    key: 'Artime',
    label: 'Artime',
  },
  {
    key: 'Affinites By Armand Thiery',
    label: 'Affinites By Armand Thiery',
  },
  {
    key: 'Annarita N',
    label: 'Annarita N',
  },
  {
    key: 'A Line',
    label: 'A Line',
  },
  {
    key: 'Antler',
    label: 'Antler',
  },
  {
    key: 'Aiki',
    label: 'Aiki',
  },
  {
    key: 'Atlant',
    label: 'Atlant',
  },
  {
    key: 'Adele Fado',
    label: 'Adele Fado',
  },
  {
    key: 'Acerbis',
    label: 'Acerbis',
  },
  {
    key: 'Alice&You',
    label: 'Alice&You',
  },
  {
    key: 'Anne L.',
    label: 'Anne L.',
  },
  {
    key: 'Andre',
    label: 'Andre',
  },
  {
    key: 'Amazing',
    label: 'Amazing',
  },
  {
    key: 'A.Joy Collection',
    label: 'A.Joy Collection',
  },
  {
    key: 'Aridza Bross',
    label: 'Aridza Bross',
  },
  {
    key: 'Ariella',
    label: 'Ariella',
  },
  {
    key: 'Alessa W.',
    label: 'Alessa W.',
  },
  {
    key: 'Agenda',
    label: 'Agenda',
  },
  {
    key: 'Arnie Says',
    label: 'Arnie Says',
  },
  {
    key: 'A Feeling Of Joy',
    label: 'A Feeling Of Joy',
  },
  {
    key: 'Amor & Psyche',
    label: 'Amor & Psyche',
  },
  {
    key: 'Agathe Velmont',
    label: 'Agathe Velmont',
  },
  {
    key: 'Altinyildiz',
    label: 'Altinyildiz',
  },
  {
    key: 'Abrams',
    label: 'Abrams',
  },
  {
    key: 'Adrexx',
    label: 'Adrexx',
  },
  {
    key: 'Afibel',
    label: 'Afibel',
  },
  {
    key: 'Andy & Lucy',
    label: 'Andy & Lucy',
  },
  {
    key: "Appel's",
    label: "Appel's",
  },
  {
    key: "A'gaci",
    label: "A'gaci",
  },
  {
    key: 'Absolutely',
    label: 'Absolutely',
  },
  {
    key: 'American Rag',
    label: 'American Rag',
  },
  {
    key: 'Angela Davis',
    label: 'Angela Davis',
  },
  {
    key: 'Accessories',
    label: 'Accessories',
  },
  {
    key: 'April Evil',
    label: 'April Evil',
  },
  {
    key: 'Angelo Marani',
    label: 'Angelo Marani',
  },
  {
    key: 'Amoena',
    label: 'Amoena',
  },
  {
    key: 'Apple',
    label: 'Apple',
  },
  {
    key: 'Antonelle',
    label: 'Antonelle',
  },
  {
    key: 'Annex',
    label: 'Annex',
  },
  {
    key: 'Ali&Kris',
    label: 'Ali&Kris',
  },
  {
    key: 'Amaryllis',
    label: 'Amaryllis',
  },
  {
    key: 'Alexander',
    label: 'Alexander',
  },
  {
    key: 'A.P.C.',
    label: 'A.P.C.',
  },
  {
    key: 'Angel',
    label: 'Angel',
  },
  {
    key: 'Angels Never Die',
    label: 'Angels Never Die',
  },
  {
    key: 'Akoz',
    label: 'Akoz',
  },
  {
    key: "Ad'oro",
    label: "Ad'oro",
  },
  {
    key: 'Ascari',
    label: 'Ascari',
  },
  {
    key: 'A.T.Two',
    label: 'A.T.Two',
  },
  {
    key: 'Amour Louise',
    label: 'Amour Louise',
  },
  {
    key: 'A',
    label: 'A',
  },
  {
    key: 'Apple Bottoms',
    label: 'Apple Bottoms',
  },
  {
    key: 'Americanino',
    label: 'Americanino',
  },
  {
    key: 'Anne Weyburn',
    label: 'Anne Weyburn',
  },
  {
    key: 'Adpt.',
    label: 'Adpt.',
  },
  {
    key: 'Ad Lib',
    label: 'Ad Lib',
  },
  {
    key: 'Amanda',
    label: 'Amanda',
  },
  {
    key: 'Allude',
    label: 'Allude',
  },
  {
    key: 'Autumn Magic',
    label: 'Autumn Magic',
  },
  {
    key: 'Asos Maternity',
    label: 'Asos Maternity',
  },
  {
    key: 'Amelie & Amelie',
    label: 'Amelie & Amelie',
  },
  {
    key: 'Annette Gortz',
    label: 'Annette Gortz',
  },
  {
    key: 'Autre Ton',
    label: 'Autre Ton',
  },
  {
    key: 'Aspire',
    label: 'Aspire',
  },
  {
    key: 'Ambition',
    label: 'Ambition',
  },
  {
    key: 'Anvil',
    label: 'Anvil',
  },
  {
    key: 'Avenue Foch',
    label: 'Avenue Foch',
  },
  {
    key: 'Arte',
    label: 'Arte',
  },
  {
    key: 'Antonio Derrico',
    label: 'Antonio Derrico',
  },
  {
    key: 'Accanto',
    label: 'Accanto',
  },
  {
    key: 'Angel Devil',
    label: 'Angel Devil',
  },
  {
    key: 'Acevog',
    label: 'Acevog',
  },
  {
    key: 'Admiral',
    label: 'Admiral',
  },
  {
    key: 'Anonima',
    label: 'Anonima',
  },
  {
    key: 'Artigiano',
    label: 'Artigiano',
  },
  {
    key: 'Atwardson',
    label: 'Atwardson',
  },
  {
    key: 'Add',
    label: 'Add',
  },
  {
    key: 'Avirex',
    label: 'Avirex',
  },
  {
    key: 'Ace',
    label: 'Ace',
  },
  {
    key: 'Alyx',
    label: 'Alyx',
  },
  {
    key: 'Ange',
    label: 'Ange',
  },
  {
    key: 'Aeronautica Militare',
    label: 'Aeronautica Militare',
  },
  {
    key: 'American Bazi',
    label: 'American Bazi',
  },
  {
    key: 'Areline',
    label: 'Areline',
  },
  {
    key: 'Amica',
    label: 'Amica',
  },
  {
    key: 'Australian',
    label: 'Australian',
  },
  {
    key: 'Ark&Co',
    label: 'Ark&Co',
  },
  {
    key: 'A-Style',
    label: 'A-Style',
  },
  {
    key: 'Artisan Ny',
    label: 'Artisan Ny',
  },
  {
    key: 'Angelico',
    label: 'Angelico',
  },
  {
    key: 'Authentic Denim',
    label: 'Authentic Denim',
  },
  {
    key: 'Anoname',
    label: 'Anoname',
  },
  {
    key: 'Amadeus',
    label: 'Amadeus',
  },
  {
    key: 'Antica Sartoria',
    label: 'Antica Sartoria',
  },
  {
    key: 'Amparo',
    label: 'Amparo',
  },
  {
    key: 'Ann Llewellyn Esq.',
    label: 'Ann Llewellyn Esq.',
  },
  {
    key: 'Amalia',
    label: 'Amalia',
  },
  {
    key: 'Angel Paris',
    label: 'Angel Paris',
  },
  {
    key: 'Aida Barni',
    label: 'Aida Barni',
  },
  {
    key: 'Altuzarra',
    label: 'Altuzarra',
  },
  {
    key: 'Appeal',
    label: 'Appeal',
  },
  {
    key: 'Anybody',
    label: 'Anybody',
  },
  {
    key: 'Allsport Of Austria',
    label: 'Allsport Of Austria',
  },
  {
    key: 'Artisti Italiani',
    label: 'Artisti Italiani',
  },
  {
    key: 'Alexandre',
    label: 'Alexandre',
  },
  {
    key: 'Adc',
    label: 'Adc',
  },
  {
    key: 'Anna Aura',
    label: 'Anna Aura',
  },
  {
    key: 'Amour',
    label: 'Amour',
  },
  {
    key: 'Altea',
    label: 'Altea',
  },
  {
    key: 'Andrew Christian',
    label: 'Andrew Christian',
  },
  {
    key: 'Absolute Angel',
    label: 'Absolute Angel',
  },
  {
    key: 'Amina Rubinacci',
    label: 'Amina Rubinacci',
  },
  {
    key: 'Angelique',
    label: 'Angelique',
  },
  {
    key: 'Aino',
    label: 'Aino',
  },
  {
    key: 'Agnes',
    label: 'Agnes',
  },
  {
    key: 'Alex Athletics',
    label: 'Alex Athletics',
  },
  {
    key: 'Aspesi',
    label: 'Aspesi',
  },
  {
    key: 'Apropos',
    label: 'Apropos',
  },
  {
    key: 'Asg',
    label: 'Asg',
  },
  {
    key: 'Alexander Julian',
    label: 'Alexander Julian',
  },
  {
    key: 'Antthony',
    label: 'Antthony',
  },
  {
    key: 'Amlitionfly',
    label: 'Amlitionfly',
  },
  {
    key: 'Alibi',
    label: 'Alibi',
  },
  {
    key: 'Allison',
    label: 'Allison',
  },
  {
    key: 'Atention',
    label: 'Atention',
  },
  {
    key: 'Ativo',
    label: 'Ativo',
  },
  {
    key: 'Anastacia By S.Oliver',
    label: 'Anastacia By S.Oliver',
  },
  {
    key: 'Authentic Wear',
    label: 'Authentic Wear',
  },
  {
    key: 'Amazing Queen',
    label: 'Amazing Queen',
  },
  {
    key: 'Adessa',
    label: 'Adessa',
  },
  {
    key: 'Avance',
    label: 'Avance',
  },
  {
    key: 'Armor-Lux',
    label: 'Armor-Lux',
  },
  {
    key: 'Armando',
    label: 'Armando',
  },
  {
    key: 'Arkitect.',
    label: 'Arkitect.',
  },
  {
    key: 'Awear',
    label: 'Awear',
  },
  {
    key: 'Ascot Sport',
    label: 'Ascot Sport',
  },
  {
    key: 'Alprausch',
    label: 'Alprausch',
  },
  {
    key: 'Anisette',
    label: 'Anisette',
  },
  {
    key: 'Acquaverde',
    label: 'Acquaverde',
  },
  {
    key: 'Asphalt',
    label: 'Asphalt',
  },
  {
    key: 'Annalisa J.M.',
    label: 'Annalisa J.M.',
  },
  {
    key: 'Absolut Joy',
    label: 'Absolut Joy',
  },
  {
    key: 'Anne Sofie',
    label: 'Anne Sofie',
  },
  {
    key: 'Ashley',
    label: 'Ashley',
  },
  {
    key: 'Aura',
    label: 'Aura',
  },
  {
    key: "Adam's",
    label: "Adam's",
  },
  {
    key: 'Anna Biagini',
    label: 'Anna Biagini',
  },
  {
    key: 'Alexander Wang',
    label: 'Alexander Wang',
  },
  {
    key: 'Amaro',
    label: 'Amaro',
  },
  {
    key: 'Anonyme',
    label: 'Anonyme',
  },
  {
    key: 'Aniss',
    label: 'Aniss',
  },
  {
    key: 'Angeltye',
    label: 'Angeltye',
  },
  {
    key: 'Amber',
    label: 'Amber',
  },
  {
    key: 'Angel Of Style',
    label: 'Angel Of Style',
  },
  {
    key: 'Alliance',
    label: 'Alliance',
  },
  {
    key: 'Arcade',
    label: 'Arcade',
  },
  {
    key: 'Annie G.',
    label: 'Annie G.',
  },
  {
    key: 'Anyplace',
    label: 'Anyplace',
  },
  {
    key: 'Anne Weyburn By La Redoute',
    label: 'Anne Weyburn By La Redoute',
  },
  {
    key: 'Aglini',
    label: 'Aglini',
  },
  {
    key: 'Alpinestars By Denise Focil',
    label: 'Alpinestars By Denise Focil',
  },
  {
    key: 'Acciaio',
    label: 'Acciaio',
  },
  {
    key: 'Amelia',
    label: 'Amelia',
  },
  {
    key: 'A.S. 98',
    label: 'A.S. 98',
  },
  {
    key: 'Appletree Boutique',
    label: 'Appletree Boutique',
  },
  {
    key: 'Anna Sui',
    label: 'Anna Sui',
  },
  {
    key: 'Arquette',
    label: 'Arquette',
  },
  {
    key: 'Avantgarde',
    label: 'Avantgarde',
  },
  {
    key: 'Ambition Fly',
    label: 'Ambition Fly',
  },
  {
    key: 'Ap',
    label: 'Ap',
  },
  {
    key: 'Agnes B.',
    label: 'Agnes B.',
  },
  {
    key: 'Aboriginal',
    label: 'Aboriginal',
  },
  {
    key: 'Autonomy',
    label: 'Autonomy',
  },
  {
    key: 'Alternative',
    label: 'Alternative',
  },
  {
    key: 'Anna Holtblad',
    label: 'Anna Holtblad',
  },
  {
    key: 'Andrea Mare',
    label: 'Andrea Mare',
  },
  {
    key: 'Auluna',
    label: 'Auluna',
  },
  {
    key: 'Alveare',
    label: 'Alveare',
  },
  {
    key: 'Angelie',
    label: 'Angelie',
  },
  {
    key: 'Ambiente',
    label: 'Ambiente',
  },
  {
    key: 'Att',
    label: 'Att',
  },
  {
    key: 'Arcitect',
    label: 'Arcitect',
  },
  {
    key: 'Ariat',
    label: 'Ariat',
  },
  {
    key: 'Aerin',
    label: 'Aerin',
  },
  {
    key: 'Anges',
    label: 'Anges',
  },
  {
    key: 'Alain Delon',
    label: 'Alain Delon',
  },
  {
    key: 'Amy Childs',
    label: 'Amy Childs',
  },
  {
    key: 'Axes Femme',
    label: 'Axes Femme',
  },
  {
    key: 'Anne Brooks',
    label: 'Anne Brooks',
  },
  {
    key: 'Authentic & Individual',
    label: 'Authentic & Individual',
  },
  {
    key: 'Aplx',
    label: 'Aplx',
  },
  {
    key: 'Anne',
    label: 'Anne',
  },
  {
    key: 'Alinghi',
    label: 'Alinghi',
  },
  {
    key: 'Anna Molinari',
    label: 'Anna Molinari',
  },
  {
    key: 'Afeil',
    label: 'Afeil',
  },
  {
    key: 'Anyone',
    label: 'Anyone',
  },
  {
    key: 'Armani',
    label: 'Armani',
  },
  {
    key: 'Annarita N',
    label: 'Annarita N',
  },
  {
    key: 'Autumn Cashmere',
    label: 'Autumn Cashmere',
  },
  {
    key: 'Apollo',
    label: 'Apollo',
  },
  {
    key: 'Animal',
    label: 'Animal',
  },
  {
    key: 'Alfred Angelo',
    label: 'Alfred Angelo',
  },
  {
    key: 'Arthur Galan',
    label: 'Arthur Galan',
  },
  {
    key: 'Annalee + Hope',
    label: 'Annalee + Hope',
  },
  {
    key: 'Alberta Ferretti',
    label: 'Alberta Ferretti',
  },
  {
    key: 'Aero',
    label: 'Aero',
  },
  {
    key: 'Amici',
    label: 'Amici',
  },
  {
    key: 'Ahead',
    label: 'Ahead',
  },
  {
    key: 'Amnesia',
    label: 'Amnesia',
  },
  {
    key: 'Absolute',
    label: 'Absolute',
  },
  {
    key: 'Atlas Design',
    label: 'Atlas Design',
  },
  {
    key: 'As Know As',
    label: 'As Know As',
  },
  {
    key: 'Ahlemeyer',
    label: 'Ahlemeyer',
  },
  {
    key: 'Alberto Fabiani',
    label: 'Alberto Fabiani',
  },
  {
    key: 'Allcott',
    label: 'Allcott',
  },
  {
    key: 'Alysi',
    label: 'Alysi',
  },
  {
    key: 'Adelly',
    label: 'Adelly',
  },
  {
    key: 'Annabelle',
    label: 'Annabelle',
  },
  {
    key: 'Alvo',
    label: 'Alvo',
  },
  {
    key: 'Aga',
    label: 'Aga',
  },
  {
    key: 'Ad',
    label: 'Ad',
  },
  {
    key: 'Alberto Zago',
    label: 'Alberto Zago',
  },
  {
    key: 'American Retro',
    label: 'American Retro',
  },
  {
    key: 'Argentovivo',
    label: 'Argentovivo',
  },
  {
    key: 'Alice Mccall',
    label: 'Alice Mccall',
  },
  {
    key: 'Ariana',
    label: 'Ariana',
  },
  {
    key: 'Acemi',
    label: 'Acemi',
  },
  {
    key: 'Atone',
    label: 'Atone',
  },
  {
    key: 'Alea',
    label: 'Alea',
  },
  {
    key: 'Ai-Pai',
    label: 'Ai-Pai',
  },
  {
    key: 'Alvina',
    label: 'Alvina',
  },
  {
    key: 'Alma',
    label: 'Alma',
  },
  {
    key: 'Anmol',
    label: 'Anmol',
  },
  {
    key: 'Anky',
    label: 'Anky',
  },
  {
    key: "Amy's",
    label: "Amy's",
  },
  {
    key: 'Adrian Hammond',
    label: 'Adrian Hammond',
  },
  {
    key: 'Addict',
    label: 'Addict',
  },
  {
    key: 'Ams',
    label: 'Ams',
  },
  {
    key: 'Anis',
    label: 'Anis',
  },
  {
    key: 'Amore',
    label: 'Amore',
  },
  {
    key: 'Anchor Blue',
    label: 'Anchor Blue',
  },
  {
    key: 'Axiome',
    label: 'Axiome',
  },
  {
    key: 'Al Paradise',
    label: 'Al Paradise',
  },
  {
    key: 'Aigner',
    label: 'Aigner',
  },
  {
    key: 'American Exchange',
    label: 'American Exchange',
  },
  {
    key: 'Amurs & Superb',
    label: 'Amurs & Superb',
  },
  {
    key: 'Ama',
    label: 'Ama',
  },
  {
    key: 'Amanda Uprichard',
    label: 'Amanda Uprichard',
  },
  {
    key: 'Anatomy',
    label: 'Anatomy',
  },
  {
    key: 'Arido',
    label: 'Arido',
  },
  {
    key: 'Authentic Vintage',
    label: 'Authentic Vintage',
  },
  {
    key: 'Angelle',
    label: 'Angelle',
  },
  {
    key: 'Anne De Lancay',
    label: 'Anne De Lancay',
  },
  {
    key: 'Apawwa',
    label: 'Apawwa',
  },
  {
    key: 'Anacleto',
    label: 'Anacleto',
  },
  {
    key: 'American Hawk',
    label: 'American Hawk',
  },
  {
    key: 'Alain',
    label: 'Alain',
  },
  {
    key: 'Arqueonautas',
    label: 'Arqueonautas',
  },
  {
    key: 'Art Sac',
    label: 'Art Sac',
  },
  {
    key: 'Ampelmann',
    label: 'Ampelmann',
  },
  {
    key: 'Am',
    label: 'Am',
  },
  {
    key: 'About-U',
    label: 'About-U',
  },
  {
    key: 'Ast',
    label: 'Ast',
  },
  {
    key: 'Alberto Cardinali',
    label: 'Alberto Cardinali',
  },
  {
    key: 'Acsn',
    label: 'Acsn',
  },
  {
    key: 'Alyssa Paris',
    label: 'Alyssa Paris',
  },
  {
    key: 'After Hours',
    label: 'After Hours',
  },
  {
    key: 'Arezzo',
    label: 'Arezzo',
  },
  {
    key: 'Alta Moda',
    label: 'Alta Moda',
  },
  {
    key: 'Alouette',
    label: 'Alouette',
  },
  {
    key: 'A +',
    label: 'A +',
  },
  {
    key: 'American T-Shirt',
    label: 'American T-Shirt',
  },
  {
    key: 'Ashwi',
    label: 'Ashwi',
  },
  {
    key: 'Adelsten',
    label: 'Adelsten',
  },
  {
    key: 'Adoro',
    label: 'Adoro',
  },
  {
    key: 'Aviatrix',
    label: 'Aviatrix',
  },
  {
    key: 'All Day',
    label: 'All Day',
  },
  {
    key: 'Appenzeller',
    label: 'Appenzeller',
  },
  {
    key: 'Andi',
    label: 'Andi',
  },
  {
    key: 'Alain Weiz',
    label: 'Alain Weiz',
  },
  {
    key: 'Assos',
    label: 'Assos',
  },
  {
    key: 'Avant Garde',
    label: 'Avant Garde',
  },
  {
    key: 'Authentic Apparel',
    label: 'Authentic Apparel',
  },
  {
    key: 'Actif',
    label: 'Actif',
  },
  {
    key: 'Angelo Muroni',
    label: 'Angelo Muroni',
  },
  {
    key: 'Alexo',
    label: 'Alexo',
  },
  {
    key: 'Appelrath-Cupper',
    label: 'Appelrath-Cupper',
  },
  {
    key: 'Acidella',
    label: 'Acidella',
  },
  {
    key: 'Alberto Bini',
    label: 'Alberto Bini',
  },
  {
    key: 'Areco',
    label: 'Areco',
  },
  {
    key: 'Anm',
    label: 'Anm',
  },
  {
    key: 'Agatha',
    label: 'Agatha',
  },
  {
    key: 'Aislinn',
    label: 'Aislinn',
  },
  {
    key: 'Anzi Besson',
    label: 'Anzi Besson',
  },
  {
    key: 'Adeline',
    label: 'Adeline',
  },
  {
    key: 'Aliuja',
    label: 'Aliuja',
  },
  {
    key: 'Avant',
    label: 'Avant',
  },
  {
    key: 'Asia',
    label: 'Asia',
  },
  {
    key: 'Alice',
    label: 'Alice',
  },
  {
    key: 'Aiko',
    label: 'Aiko',
  },
  {
    key: 'Antonino Philosophy',
    label: 'Antonino Philosophy',
  },
  {
    key: 'Amundsen',
    label: 'Amundsen',
  },
  {
    key: 'Arturo Calle',
    label: 'Arturo Calle',
  },
  {
    key: 'Als',
    label: 'Als',
  },
  {
    key: 'Amor, Trust & Truth',
    label: 'Amor, Trust & Truth',
  },
  {
    key: 'Aphorism',
    label: 'Aphorism',
  },
  {
    key: 'Arda Ruse',
    label: 'Arda Ruse',
  },
  {
    key: 'Adler',
    label: 'Adler',
  },
  {
    key: 'Akela Key',
    label: 'Akela Key',
  },
  {
    key: 'Addicted',
    label: 'Addicted',
  },
  {
    key: 'Akusawa',
    label: 'Akusawa',
  },
  {
    key: 'Asylum',
    label: 'Asylum',
  },
  {
    key: 'Allegra K',
    label: 'Allegra K',
  },
  {
    key: 'Ashley Ann',
    label: 'Ashley Ann',
  },
  {
    key: 'Anteprima',
    label: 'Anteprima',
  },
  {
    key: 'Ab Soul',
    label: 'Ab Soul',
  },
  {
    key: 'Athletic Dept',
    label: 'Athletic Dept',
  },
  {
    key: 'Aqua Guard',
    label: 'Aqua Guard',
  },
  {
    key: 'Angel Fashions',
    label: 'Angel Fashions',
  },
  {
    key: 'American Freshman',
    label: 'American Freshman',
  },
  {
    key: 'Alcw',
    label: 'Alcw',
  },
  {
    key: 'Always Jeans',
    label: 'Always Jeans',
  },
  {
    key: 'Alpha By Massimo Rebecchi',
    label: 'Alpha By Massimo Rebecchi',
  },
  {
    key: 'Assima',
    label: 'Assima',
  },
  {
    key: 'Alessandro',
    label: 'Alessandro',
  },
  {
    key: 'Ak Collection',
    label: 'Ak Collection',
  },
  {
    key: 'Anya Madsen',
    label: 'Anya Madsen',
  },
  {
    key: 'Acode',
    label: 'Acode',
  },
  {
    key: 'Andrew James',
    label: 'Andrew James',
  },
  {
    key: 'Amsterdam Cowboys',
    label: 'Amsterdam Cowboys',
  },
  {
    key: 'Adelheid',
    label: 'Adelheid',
  },
  {
    key: 'Airy',
    label: 'Airy',
  },
  {
    key: 'Anzoni',
    label: 'Anzoni',
  },
  {
    key: 'Alberto Bruni',
    label: 'Alberto Bruni',
  },
  {
    key: 'Artistic Taste',
    label: 'Artistic Taste',
  },
  {
    key: 'Alison',
    label: 'Alison',
  },
  {
    key: 'Anjida',
    label: 'Anjida',
  },
  {
    key: 'Albright',
    label: 'Albright',
  },
  {
    key: 'Amn',
    label: 'Amn',
  },
  {
    key: 'A Postcard From Brighton',
    label: 'A Postcard From Brighton',
  },
  {
    key: 'Antoni&Alison',
    label: 'Antoni&Alison',
  },
  {
    key: 'Angl',
    label: 'Angl',
  },
  {
    key: 'Academica',
    label: 'Academica',
  },
  {
    key: 'Art And Soul',
    label: 'Art And Soul',
  },
  {
    key: 'American Princess',
    label: 'American Princess',
  },
  {
    key: 'Aquascutum',
    label: 'Aquascutum',
  },
  {
    key: 'Ampere',
    label: 'Ampere',
  },
  {
    key: 'Abm',
    label: 'Abm',
  },
  {
    key: 'Ambria',
    label: 'Ambria',
  },
  {
    key: 'A-Team',
    label: 'A-Team',
  },
  {
    key: 'Abitare',
    label: 'Abitare',
  },
  {
    key: 'Affliction',
    label: 'Affliction',
  },
  {
    key: 'Ajay By Liu Jo',
    label: 'Ajay By Liu Jo',
  },
  {
    key: 'Aquarelle',
    label: 'Aquarelle',
  },
  {
    key: 'Activ',
    label: 'Activ',
  },
  {
    key: 'Adamsville',
    label: 'Adamsville',
  },
  {
    key: 'Aquarium',
    label: 'Aquarium',
  },
  {
    key: 'Amy',
    label: 'Amy',
  },
  {
    key: 'Aspen',
    label: 'Aspen',
  },
  {
    key: 'Apex',
    label: 'Apex',
  },
  {
    key: 'American',
    label: 'American',
  },
  {
    key: 'Alicia Jeans',
    label: 'Alicia Jeans',
  },
  {
    key: 'Azbro',
    label: 'Azbro',
  },
  {
    key: 'Antom',
    label: 'Antom',
  },
  {
    key: 'Arda',
    label: 'Arda',
  },
  {
    key: 'Algo',
    label: 'Algo',
  },
  {
    key: 'A&H',
    label: 'A&H',
  },
  {
    key: 'Andrea & Renato',
    label: 'Andrea & Renato',
  },
  {
    key: 'Adara',
    label: 'Adara',
  },
  {
    key: 'Audrey Brooke',
    label: 'Audrey Brooke',
  },
  {
    key: 'Anna Rachele',
    label: 'Anna Rachele',
  },
  {
    key: 'Andrea Viccaro',
    label: 'Andrea Viccaro',
  },
  {
    key: 'Articles',
    label: 'Articles',
  },
  {
    key: 'Ackermann',
    label: 'Ackermann',
  },
  {
    key: 'Andiamo',
    label: 'Andiamo',
  },
  {
    key: 'Alain Figaret',
    label: 'Alain Figaret',
  },
  {
    key: 'Ammann',
    label: 'Ammann',
  },
  {
    key: 'Avin',
    label: 'Avin',
  },
  {
    key: 'Asus',
    label: 'Asus',
  },
  {
    key: 'A&A',
    label: 'A&A',
  },
  {
    key: 'Authentics',
    label: 'Authentics',
  },
  {
    key: 'Amanda Lane',
    label: 'Amanda Lane',
  },
  {
    key: 'Aker',
    label: 'Aker',
  },
  {
    key: 'Alegria',
    label: 'Alegria',
  },
  {
    key: 'Anni Rolfi',
    label: 'Anni Rolfi',
  },
  {
    key: 'Angela & William',
    label: 'Angela & William',
  },
  {
    key: 'Ad Woman',
    label: 'Ad Woman',
  },
  {
    key: "A'reve",
    label: "A'reve",
  },
  {
    key: 'Accestar',
    label: 'Accestar',
  },
  {
    key: 'Amaranta',
    label: 'Amaranta',
  },
  {
    key: 'Alexander Krist',
    label: 'Alexander Krist',
  },
  {
    key: 'Amethist',
    label: 'Amethist',
  },
  {
    key: 'Amara',
    label: 'Amara',
  },
  {
    key: 'Aquamarine',
    label: 'Aquamarine',
  },
  {
    key: 'Absolu',
    label: 'Absolu',
  },
  {
    key: 'Aboki',
    label: 'Aboki',
  },
  {
    key: 'Anatopik',
    label: 'Anatopik',
  },
  {
    key: 'Ambrose & Jackson',
    label: 'Ambrose & Jackson',
  },
  {
    key: 'Alberto Giordani',
    label: 'Alberto Giordani',
  },
  {
    key: 'Adventure & Travel',
    label: 'Adventure & Travel',
  },
  {
    key: 'A+ Ellen',
    label: 'A+ Ellen',
  },
  {
    key: 'Active Sport',
    label: 'Active Sport',
  },
  {
    key: 'Attractic',
    label: 'Attractic',
  },
  {
    key: 'Adsl',
    label: 'Adsl',
  },
  {
    key: 'Arctic Quest',
    label: 'Arctic Quest',
  },
  {
    key: 'Amity',
    label: 'Amity',
  },
  {
    key: 'Ais - Denim Laundry',
    label: 'Ais - Denim Laundry',
  },
  {
    key: 'Anne Cole',
    label: 'Anne Cole',
  },
  {
    key: 'All That Jazz',
    label: 'All That Jazz',
  },
  {
    key: 'Artistry In Motion',
    label: 'Artistry In Motion',
  },
  {
    key: 'Ava Christine',
    label: 'Ava Christine',
  },
  {
    key: 'Anykey',
    label: 'Anykey',
  },
  {
    key: 'Adamo',
    label: 'Adamo',
  },
  {
    key: 'Active Touch',
    label: 'Active Touch',
  },
  {
    key: 'Antonio Fusco',
    label: 'Antonio Fusco',
  },
  {
    key: 'Another Project',
    label: 'Another Project',
  },
  {
    key: 'Authentic & Original',
    label: 'Authentic & Original',
  },
  {
    key: 'Angeleye',
    label: 'Angeleye',
  },
  {
    key: 'Allegri',
    label: 'Allegri',
  },
  {
    key: 'Ambrose',
    label: 'Ambrose',
  },
  {
    key: 'Ale',
    label: 'Ale',
  },
  {
    key: 'Another Story',
    label: 'Another Story',
  },
  {
    key: 'Akkriti',
    label: 'Akkriti',
  },
  {
    key: 'Actuel',
    label: 'Actuel',
  },
  {
    key: 'Amelie Reveur',
    label: 'Amelie Reveur',
  },
  {
    key: 'Alexara',
    label: 'Alexara',
  },
  {
    key: 'Aida',
    label: 'Aida',
  },
  {
    key: 'Alpaca Camargo',
    label: 'Alpaca Camargo',
  },
  {
    key: 'Ashwood',
    label: 'Ashwood',
  },
  {
    key: 'Adina',
    label: 'Adina',
  },
  {
    key: 'Adiprene By Adidas',
    label: 'Adiprene By Adidas',
  },
  {
    key: 'Angel Eye',
    label: 'Angel Eye',
  },
  {
    key: 'Arrogant Cat',
    label: 'Arrogant Cat',
  },
  {
    key: 'Authentic Casual',
    label: 'Authentic Casual',
  },
  {
    key: 'Andy',
    label: 'Andy',
  },
  {
    key: 'Approved',
    label: 'Approved',
  },
  {
    key: 'Angel Nina',
    label: 'Angel Nina',
  },
  {
    key: 'Ashley By 26 International',
    label: 'Ashley By 26 International',
  },
  {
    key: 'Annamoda Piu',
    label: 'Annamoda Piu',
  },
  {
    key: 'Adelina By Scheiter',
    label: 'Adelina By Scheiter',
  },
  {
    key: 'A&J',
    label: 'A&J',
  },
  {
    key: 'Antonello Serio',
    label: 'Antonello Serio',
  },
  {
    key: 'Alexon',
    label: 'Alexon',
  },
  {
    key: 'Ames',
    label: 'Ames',
  },
  {
    key: 'Avance Creation',
    label: 'Avance Creation',
  },
  {
    key: 'Artisan De Luxe',
    label: 'Artisan De Luxe',
  },
  {
    key: 'Adventure Line',
    label: 'Adventure Line',
  },
  {
    key: 'Apiosal',
    label: 'Apiosal',
  },
  {
    key: 'Alkis',
    label: 'Alkis',
  },
  {
    key: 'Aphrodite',
    label: 'Aphrodite',
  },
  {
    key: 'Allen Allen',
    label: 'Allen Allen',
  },
  {
    key: 'Astore',
    label: 'Astore',
  },
  {
    key: 'Arrested',
    label: 'Arrested',
  },
  {
    key: 'Anne Marie',
    label: 'Anne Marie',
  },
  {
    key: 'Aerfan',
    label: 'Aerfan',
  },
  {
    key: 'Amanita',
    label: 'Amanita',
  },
  {
    key: 'Alexandre Dupres',
    label: 'Alexandre Dupres',
  },
  {
    key: 'Ambig',
    label: 'Ambig',
  },
  {
    key: 'Added Dimensions',
    label: 'Added Dimensions',
  },
  {
    key: 'Ando',
    label: 'Ando',
  },
  {
    key: 'Abbey Dirk',
    label: 'Abbey Dirk',
  },
  {
    key: 'Antartic',
    label: 'Antartic',
  },
  {
    key: 'Angelika',
    label: 'Angelika',
  },
  {
    key: 'Alpinestars',
    label: 'Alpinestars',
  },
  {
    key: 'Aws',
    label: 'Aws',
  },
  {
    key: 'Agnes Flo',
    label: 'Agnes Flo',
  },
  {
    key: 'Area Sixty Two',
    label: 'Area Sixty Two',
  },
  {
    key: 'American Tourister',
    label: 'American Tourister',
  },
  {
    key: 'Affair',
    label: 'Affair',
  },
  {
    key: 'Absolument Tendance',
    label: 'Absolument Tendance',
  },
  {
    key: 'Adyes',
    label: 'Adyes',
  },
  {
    key: 'Azuri',
    label: 'Azuri',
  },
  {
    key: 'Authentic American Heritage',
    label: 'Authentic American Heritage',
  },
  {
    key: 'Atigoua',
    label: 'Atigoua',
  },
  {
    key: 'Adams Kids',
    label: 'Adams Kids',
  },
  {
    key: 'Alex Stevens',
    label: 'Alex Stevens',
  },
  {
    key: 'Aussiebum',
    label: 'Aussiebum',
  },
  {
    key: 'Andrea Fenzi',
    label: 'Andrea Fenzi',
  },
  {
    key: 'Adit',
    label: 'Adit',
  },
  {
    key: 'Analucy',
    label: 'Analucy',
  },
  {
    key: 'Absolutely & Faith',
    label: 'Absolutely & Faith',
  },
  {
    key: 'Authentic Sport',
    label: 'Authentic Sport',
  },
  {
    key: 'Angelle Milan',
    label: 'Angelle Milan',
  },
  {
    key: 'Atelier Creation',
    label: 'Atelier Creation',
  },
  {
    key: 'Amerigo Vespucci',
    label: 'Amerigo Vespucci',
  },
  {
    key: 'Aruba',
    label: 'Aruba',
  },
  {
    key: 'Audrey 3 + 1',
    label: 'Audrey 3 + 1',
  },
  {
    key: 'Aco',
    label: 'Aco',
  },
  {
    key: 'Assign',
    label: 'Assign',
  },
  {
    key: 'Atlantic',
    label: 'Atlantic',
  },
  {
    key: 'All Dressed Up',
    label: 'All Dressed Up',
  },
  {
    key: 'Aden + Anais',
    label: 'Aden + Anais',
  },
  {
    key: 'Alt. B',
    label: 'Alt. B',
  },
  {
    key: 'Anycase',
    label: 'Anycase',
  },
  {
    key: 'Andrea Borsani',
    label: 'Andrea Borsani',
  },
  {
    key: 'Aster',
    label: 'Aster',
  },
  {
    key: 'Ava Woman',
    label: 'Ava Woman',
  },
  {
    key: 'Angel Never Die',
    label: 'Angel Never Die',
  },
  {
    key: 'Anka Sport',
    label: 'Anka Sport',
  },
  {
    key: 'Artic North',
    label: 'Artic North',
  },
  {
    key: 'Artengo',
    label: 'Artengo',
  },
  {
    key: 'Alessandro Reale',
    label: 'Alessandro Reale',
  },
  {
    key: 'Allison Morgan',
    label: 'Allison Morgan',
  },
  {
    key: 'Anne Brooks Petite',
    label: 'Anne Brooks Petite',
  },
  {
    key: 'Anderwood',
    label: 'Anderwood',
  },
  {
    key: 'Amici Di Moda',
    label: 'Amici Di Moda',
  },
  {
    key: 'Aigle Actimum',
    label: 'Aigle Actimum',
  },
  {
    key: 'Athletic Training',
    label: 'Athletic Training',
  },
  {
    key: 'Athletic Knit',
    label: 'Athletic Knit',
  },
  {
    key: 'Angelo',
    label: 'Angelo',
  },
  {
    key: 'Anis White',
    label: 'Anis White',
  },
  {
    key: 'Angy Six',
    label: 'Angy Six',
  },
  {
    key: 'Andy Warhol',
    label: 'Andy Warhol',
  },
  {
    key: 'Amy Coe',
    label: 'Amy Coe',
  },
  {
    key: 'Alexandra Bartlett',
    label: 'Alexandra Bartlett',
  },
  {
    key: 'Ayor',
    label: 'Ayor',
  },
  {
    key: 'Amplifield',
    label: 'Amplifield',
  },
  {
    key: 'Appraisal',
    label: 'Appraisal',
  },
  {
    key: 'Andro',
    label: 'Andro',
  },
  {
    key: 'Angulus',
    label: 'Angulus',
  },
  {
    key: 'Aurelia Couture',
    label: 'Aurelia Couture',
  },
  {
    key: 'Armand Basi',
    label: 'Armand Basi',
  },
  {
    key: 'Airforce',
    label: 'Airforce',
  },
  {
    key: 'Angora',
    label: 'Angora',
  },
  {
    key: 'Airness',
    label: 'Airness',
  },
  {
    key: 'Aldo Colitti',
    label: 'Aldo Colitti',
  },
  {
    key: 'Anne Smith',
    label: 'Anne Smith',
  },
  {
    key: 'Aytex',
    label: 'Aytex',
  },
  {
    key: 'Arell',
    label: 'Arell',
  },
  {
    key: 'Argentina',
    label: 'Argentina',
  },
  {
    key: 'Adax',
    label: 'Adax',
  },
  {
    key: 'American Style',
    label: 'American Style',
  },
  {
    key: 'Almanegra Jeans',
    label: 'Almanegra Jeans',
  },
  {
    key: 'Abbacino',
    label: 'Abbacino',
  },
  {
    key: 'Alferano',
    label: 'Alferano',
  },
  {
    key: 'Altamont Apparel',
    label: 'Altamont Apparel',
  },
  {
    key: 'Anthesis',
    label: 'Anthesis',
  },
  {
    key: 'Adressing Woman',
    label: 'Adressing Woman',
  },
  {
    key: 'Arcadia',
    label: 'Arcadia',
  },
  {
    key: 'Asnes',
    label: 'Asnes',
  },
  {
    key: 'Antarctica',
    label: 'Antarctica',
  },
  {
    key: 'A-Line Raw',
    label: 'A-Line Raw',
  },
  {
    key: 'Anna Blue',
    label: 'Anna Blue',
  },
  {
    key: 'Astrolabio',
    label: 'Astrolabio',
  },
  {
    key: 'A Bathing Ape',
    label: 'A Bathing Ape',
  },
  {
    key: 'Alise',
    label: 'Alise',
  },
  {
    key: 'Azzurro',
    label: 'Azzurro',
  },
  {
    key: 'Aimee',
    label: 'Aimee',
  },
  {
    key: 'Agnes B.',
    label: 'Agnes B.',
  },
  {
    key: "Arc'teryx",
    label: "Arc'teryx",
  },
  {
    key: 'Arise',
    label: 'Arise',
  },
  {
    key: 'Amber Krystal',
    label: 'Amber Krystal',
  },
  {
    key: 'A&D',
    label: 'A&D',
  },
  {
    key: 'Aircool',
    label: 'Aircool',
  },
  {
    key: 'Abitificio',
    label: 'Abitificio',
  },
  {
    key: 'Aiba',
    label: 'Aiba',
  },
  {
    key: 'Altra',
    label: 'Altra',
  },
  {
    key: 'Alchemist',
    label: 'Alchemist',
  },
  {
    key: 'Alanic',
    label: 'Alanic',
  },
  {
    key: 'Aran Crafts',
    label: 'Aran Crafts',
  },
  {
    key: 'Antonio Miro',
    label: 'Antonio Miro',
  },
  {
    key: 'Amelie',
    label: 'Amelie',
  },
  {
    key: 'Arrival',
    label: 'Arrival',
  },
  {
    key: 'Aisha',
    label: 'Aisha',
  },
  {
    key: 'All Stars',
    label: 'All Stars',
  },
  {
    key: 'Athlet',
    label: 'Athlet',
  },
  {
    key: 'Always Advancing',
    label: 'Always Advancing',
  },
  {
    key: 'Arc`Teryx',
    label: 'Arc`Teryx',
  },
  {
    key: 'A Dong Silk',
    label: 'A Dong Silk',
  },
  {
    key: 'Alstyle Apparel',
    label: 'Alstyle Apparel',
  },
  {
    key: 'Atticus',
    label: 'Atticus',
  },
  {
    key: 'Animo',
    label: 'Animo',
  },
  {
    key: 'Asole & Bottoni',
    label: 'Asole & Bottoni',
  },
  {
    key: 'Analog Clothing',
    label: 'Analog Clothing',
  },
  {
    key: 'Antioch',
    label: 'Antioch',
  },
  {
    key: 'Alex Evenings',
    label: 'Alex Evenings',
  },
  {
    key: 'Abacab',
    label: 'Abacab',
  },
  {
    key: 'Amphora',
    label: 'Amphora',
  },
  {
    key: 'Acce Star Jeans',
    label: 'Acce Star Jeans',
  },
  {
    key: 'Anyse',
    label: 'Anyse',
  },
  {
    key: 'Aprico',
    label: 'Aprico',
  },
  {
    key: 'Avispada',
    label: 'Avispada',
  },
  {
    key: 'Amanda Wakeley',
    label: 'Amanda Wakeley',
  },
  {
    key: 'Adamus',
    label: 'Adamus',
  },
  {
    key: 'Alan Red',
    label: 'Alan Red',
  },
  {
    key: 'Alythea',
    label: 'Alythea',
  },
  {
    key: 'Ann Harvey',
    label: 'Ann Harvey',
  },
  {
    key: 'Amigos',
    label: 'Amigos',
  },
  {
    key: 'Avalon',
    label: 'Avalon',
  },
  {
    key: 'Annabel',
    label: 'Annabel',
  },
  {
    key: 'Akemi+Kin',
    label: 'Akemi+Kin',
  },
  {
    key: 'Apepazza',
    label: 'Apepazza',
  },
  {
    key: 'Agnona',
    label: 'Agnona',
  },
  {
    key: 'Andersen & Lauth',
    label: 'Andersen & Lauth',
  },
  {
    key: 'Abr',
    label: 'Abr',
  },
  {
    key: 'Azure',
    label: 'Azure',
  },
  {
    key: 'Anita',
    label: 'Anita',
  },
  {
    key: 'Atmark',
    label: 'Atmark',
  },
  {
    key: 'Adventure',
    label: 'Adventure',
  },
  {
    key: 'Addax',
    label: 'Addax',
  },
  {
    key: 'Albion',
    label: 'Albion',
  },
  {
    key: 'Adigia',
    label: 'Adigia',
  },
  {
    key: 'Anna Smith',
    label: 'Anna Smith',
  },
  {
    key: 'Antonio Bacci',
    label: 'Antonio Bacci',
  },
  {
    key: 'Avelar',
    label: 'Avelar',
  },
  {
    key: 'Adidas Respect Me',
    label: 'Adidas Respect Me',
  },
  {
    key: 'Apple Blossum',
    label: 'Apple Blossum',
  },
  {
    key: 'Alex & Co.',
    label: 'Alex & Co.',
  },
  {
    key: 'Arlene Phillips',
    label: 'Arlene Phillips',
  },
  {
    key: 'Aziza Zina',
    label: 'Aziza Zina',
  },
  {
    key: 'Ann Tuil',
    label: 'Ann Tuil',
  },
  {
    key: 'Atmos Fashion',
    label: 'Atmos Fashion',
  },
  {
    key: 'Aem Kei',
    label: 'Aem Kei',
  },
  {
    key: 'Adjl',
    label: 'Adjl',
  },
  {
    key: 'Astars',
    label: 'Astars',
  },
  {
    key: 'Ashild',
    label: 'Ashild',
  },
  {
    key: 'Army Of Me',
    label: 'Army Of Me',
  },
  {
    key: 'Amy`S',
    label: 'Amy`S',
  },
  {
    key: 'Accent',
    label: 'Accent',
  },
  {
    key: 'Aqua Blues',
    label: 'Aqua Blues',
  },
  {
    key: 'A La Carte',
    label: 'A La Carte',
  },
  {
    key: 'Active Gear',
    label: 'Active Gear',
  },
  {
    key: 'Amuse',
    label: 'Amuse',
  },
  {
    key: 'Ashford',
    label: 'Ashford',
  },
  {
    key: 'A&R',
    label: 'A&R',
  },
  {
    key: 'Andre Maurice',
    label: 'Andre Maurice',
  },
  {
    key: 'Absolut By Zebra',
    label: 'Absolut By Zebra',
  },
  {
    key: 'Alkena',
    label: 'Alkena',
  },
  {
    key: 'Anatomic&Co',
    label: 'Anatomic&Co',
  },
  {
    key: 'A.B.S By Allen Schwartz',
    label: 'A.B.S By Allen Schwartz',
  },
  {
    key: 'All Sport',
    label: 'All Sport',
  },
  {
    key: 'Akwa',
    label: 'Akwa',
  },
  {
    key: 'Audimas',
    label: 'Audimas',
  },
  {
    key: 'Alex Line',
    label: 'Alex Line',
  },
  {
    key: 'Alaska',
    label: 'Alaska',
  },
  {
    key: 'Aramis',
    label: 'Aramis',
  },
  {
    key: 'Agolde',
    label: 'Agolde',
  },
  {
    key: 'Alpine Design',
    label: 'Alpine Design',
  },
  {
    key: 'Amour By Love',
    label: 'Amour By Love',
  },
  {
    key: 'Amplified',
    label: 'Amplified',
  },
  {
    key: 'Angel Biba',
    label: 'Angel Biba',
  },
  {
    key: 'Aliston',
    label: 'Aliston',
  },
  {
    key: 'Alex Marie',
    label: 'Alex Marie',
  },
  {
    key: 'Abro',
    label: 'Abro',
  },
  {
    key: 'Ascia',
    label: 'Ascia',
  },
  {
    key: 'Annabella Mode',
    label: 'Annabella Mode',
  },
  {
    key: 'Aesse',
    label: 'Aesse',
  },
  {
    key: 'Anna Menotti',
    label: 'Anna Menotti',
  },
  {
    key: 'Amber Sun',
    label: 'Amber Sun',
  },
  {
    key: 'Angel Rossi',
    label: 'Angel Rossi',
  },
  {
    key: 'Antea',
    label: 'Antea',
  },
  {
    key: 'Alvin`S Island',
    label: 'Alvin`S Island',
  },
  {
    key: 'Aula Magna',
    label: 'Aula Magna',
  },
  {
    key: 'Adventuridge',
    label: 'Adventuridge',
  },
  {
    key: 'Addison',
    label: 'Addison',
  },
  {
    key: 'Ariya Jeans',
    label: 'Ariya Jeans',
  },
  {
    key: 'Alcantara',
    label: 'Alcantara',
  },
  {
    key: 'Aqua Tex',
    label: 'Aqua Tex',
  },
  {
    key: 'Arctic Storm',
    label: 'Arctic Storm',
  },
  {
    key: 'Adam',
    label: 'Adam',
  },
  {
    key: 'Advantage',
    label: 'Advantage',
  },
  {
    key: 'Alust',
    label: 'Alust',
  },
  {
    key: 'Anne Fontaine',
    label: 'Anne Fontaine',
  },
  {
    key: 'Antargo',
    label: 'Antargo',
  },
  {
    key: 'Alchemy',
    label: 'Alchemy',
  },
  {
    key: 'Age',
    label: 'Age',
  },
  {
    key: 'Aris',
    label: 'Aris',
  },
  {
    key: 'Abandon',
    label: 'Abandon',
  },
  {
    key: 'After Dark',
    label: 'After Dark',
  },
  {
    key: 'Ann Demeulemeester',
    label: 'Ann Demeulemeester',
  },
  {
    key: 'Acc',
    label: 'Acc',
  },
  {
    key: 'Aldinova',
    label: 'Aldinova',
  },
  {
    key: 'Anima',
    label: 'Anima',
  },
  {
    key: 'Axis',
    label: 'Axis',
  },
  {
    key: 'Art Shoes',
    label: 'Art Shoes',
  },
  {
    key: 'Atlantis',
    label: 'Atlantis',
  },
  {
    key: 'Angry Birds',
    label: 'Angry Birds',
  },
  {
    key: 'Alessandro Magno',
    label: 'Alessandro Magno',
  },
  {
    key: 'Adini',
    label: 'Adini',
  },
  {
    key: 'Anapurna',
    label: 'Anapurna',
  },
  {
    key: 'American Outfitters',
    label: 'American Outfitters',
  },
  {
    key: 'Astuces',
    label: 'Astuces',
  },
  {
    key: 'Adda',
    label: 'Adda',
  },
  {
    key: 'Anna E. Luca',
    label: 'Anna E. Luca',
  },
  {
    key: 'Andiata',
    label: 'Andiata',
  },
  {
    key: 'Acacia',
    label: 'Acacia',
  },
  {
    key: 'Anaxi',
    label: 'Anaxi',
  },
  {
    key: 'Alviero Martini',
    label: 'Alviero Martini',
  },
  {
    key: 'Alpine',
    label: 'Alpine',
  },
  {
    key: 'Amari',
    label: 'Amari',
  },
  {
    key: 'Anything',
    label: 'Anything',
  },
  {
    key: 'Amazing Grace',
    label: 'Amazing Grace',
  },
  {
    key: "Aldo Martin's",
    label: "Aldo Martin's",
  },
  {
    key: 'Art To Be',
    label: 'Art To Be',
  },
  {
    key: "Assassin's Creed",
    label: "Assassin's Creed",
  },
  {
    key: 'Antonella',
    label: 'Antonella',
  },
  {
    key: 'Alberto Fermani',
    label: 'Alberto Fermani',
  },
  {
    key: 'Alan Paine',
    label: 'Alan Paine',
  },
  {
    key: 'Alexi Andriotti',
    label: 'Alexi Andriotti',
  },
  {
    key: 'American Widgeon',
    label: 'American Widgeon',
  },
  {
    key: 'Anti-Flirt',
    label: 'Anti-Flirt',
  },
  {
    key: 'Ambee',
    label: 'Ambee',
  },
  {
    key: 'Anna Scholz',
    label: 'Anna Scholz',
  },
  {
    key: 'Alc Diamond Woman',
    label: 'Alc Diamond Woman',
  },
  {
    key: 'Apsara',
    label: 'Apsara',
  },
  {
    key: 'Alta Qualita',
    label: 'Alta Qualita',
  },
  {
    key: 'Anna Kaci',
    label: 'Anna Kaci',
  },
  {
    key: 'Austar',
    label: 'Austar',
  },
  {
    key: 'American Dissidence Natural',
    label: 'American Dissidence Natural',
  },
  {
    key: 'Atelier Scotch',
    label: 'Atelier Scotch',
  },
  {
    key: 'Alessandro Bonciolini',
    label: 'Alessandro Bonciolini',
  },
  {
    key: 'Aks',
    label: 'Aks',
  },
  {
    key: 'Amantis',
    label: 'Amantis',
  },
  {
    key: 'Alessandro Salvatore',
    label: 'Alessandro Salvatore',
  },
  {
    key: 'Absolut',
    label: 'Absolut',
  },
  {
    key: 'Anything',
    label: 'Anything',
  },
  {
    key: 'Always Jeans',
    label: 'Always Jeans',
  },
  {
    key: 'Angel Paris',
    label: 'Angel Paris',
  },
  {
    key: 'Adamus',
    label: 'Adamus',
  },
  {
    key: 'Boohoo',
    label: 'Boohoo',
  },
  {
    key: 'Bonita',
    label: 'Bonita',
  },
  {
    key: 'Bershka',
    label: 'Bershka',
  },
  {
    key: 'Banana Republic',
    label: 'Banana Republic',
  },
  {
    key: 'Blue Motion',
    label: 'Blue Motion',
  },
  {
    key: 'Billabong',
    label: 'Billabong',
  },
  {
    key: 'Betty Barclay',
    label: 'Betty Barclay',
  },
  {
    key: 'Bik Bok',
    label: 'Bik Bok',
  },
  {
    key: 'Bpc Bonprix Collection',
    label: 'Bpc Bonprix Collection',
  },
  {
    key: 'Biaggini',
    label: 'Biaggini',
  },
  {
    key: 'Birkenstock',
    label: 'Birkenstock',
  },
  {
    key: 'Blind Date',
    label: 'Blind Date',
  },
  {
    key: 'Body Flirt',
    label: 'Body Flirt',
  },
  {
    key: 'Best Mountain',
    label: 'Best Mountain',
  },
  {
    key: 'Burton Of London',
    label: 'Burton Of London',
  },
  {
    key: 'Bexleys',
    label: 'Bexleys',
  },
  {
    key: 'Belair',
    label: 'Belair',
  },
  {
    key: 'Bata',
    label: 'Bata',
  },
  {
    key: 'Biba',
    label: 'Biba',
  },
  {
    key: 'Brax',
    label: 'Brax',
  },
  {
    key: 'Bcbg Max Azria',
    label: 'Bcbg Max Azria',
  },
  {
    key: 'Balsamik',
    label: 'Balsamik',
  },
  {
    key: 'Bandits Du Monde',
    label: 'Bandits Du Monde',
  },
  {
    key: 'B.Young',
    label: 'B.Young',
  },
  {
    key: 'Bardot',
    label: 'Bardot',
  },
  {
    key: 'Brave Soul',
    label: 'Brave Soul',
  },
  {
    key: 'Buffalo',
    label: 'Buffalo',
  },
  {
    key: 'Basler',
    label: 'Basler',
  },
  {
    key: 'Bianca',
    label: 'Bianca',
  },
  {
    key: 'Broadway',
    label: 'Broadway',
  },
  {
    key: 'Best Connections',
    label: 'Best Connections',
  },
  {
    key: 'Blue Seven',
    label: 'Blue Seven',
  },
  {
    key: 'Bandolera',
    label: 'Bandolera',
  },
  {
    key: 'Bonds',
    label: 'Bonds',
  },
  {
    key: 'Bebe',
    label: 'Bebe',
  },
  {
    key: 'Bugatti',
    label: 'Bugatti',
  },
  {
    key: 'Be You',
    label: 'Be You',
  },
  {
    key: 'Banana Moon',
    label: 'Banana Moon',
  },
  {
    key: 'Boss',
    label: 'Boss',
  },
  {
    key: 'Being Casual',
    label: 'Being Casual',
  },
  {
    key: 'Barbara Lebek',
    label: 'Barbara Lebek',
  },
  {
    key: 'By Malene Birger',
    label: 'By Malene Birger',
  },
  {
    key: 'Bench',
    label: 'Bench',
  },
  {
    key: 'Betty & Co',
    label: 'Betty & Co',
  },
  {
    key: 'B.C. Best Connections',
    label: 'B.C. Best Connections',
  },
  {
    key: 'Ben Sherman',
    label: 'Ben Sherman',
  },
  {
    key: 'Blend',
    label: 'Blend',
  },
  {
    key: "Bon'a Parte",
    label: "Bon'a Parte",
  },
  {
    key: 'Bcbgeneration',
    label: 'Bcbgeneration',
  },
  {
    key: 'Bruno Banani',
    label: 'Bruno Banani',
  },
  {
    key: "Boysen's",
    label: "Boysen's",
  },
  {
    key: 'Belly Button',
    label: 'Belly Button',
  },
  {
    key: 'Brooks Brothers',
    label: 'Brooks Brothers',
  },
  {
    key: 'Bruuns Bazaar',
    label: 'Bruuns Bazaar',
  },
  {
    key: 'Boss Orange',
    label: 'Boss Orange',
  },
  {
    key: 'Bogner',
    label: 'Bogner',
  },
  {
    key: 'Burton',
    label: 'Burton',
  },
  {
    key: 'Benvenuto',
    label: 'Benvenuto',
  },
  {
    key: 'B.You',
    label: 'B.You',
  },
  {
    key: 'Brandtex',
    label: 'Brandtex',
  },
  {
    key: 'Bossini',
    label: 'Bossini',
  },
  {
    key: 'Blancheporte',
    label: 'Blancheporte',
  },
  {
    key: 'Blacky Dress',
    label: 'Blacky Dress',
  },
  {
    key: 'Bel Air',
    label: 'Bel Air',
  },
  {
    key: 'Blue Illusion',
    label: 'Blue Illusion',
  },
  {
    key: 'Blend She',
    label: 'Blend She',
  },
  {
    key: 'Blanco',
    label: 'Blanco',
  },
  {
    key: 'Billie & Blossom',
    label: 'Billie & Blossom',
  },
  {
    key: 'Bewear',
    label: 'Bewear',
  },
  {
    key: 'Beach Time',
    label: 'Beach Time',
  },
  {
    key: 'Basefield',
    label: 'Basefield',
  },
  {
    key: 'Bcx',
    label: 'Bcx',
  },
  {
    key: 'Barisal',
    label: 'Barisal',
  },
  {
    key: 'Batistini',
    label: 'Batistini',
  },
  {
    key: 'Billieblush',
    label: 'Billieblush',
  },
  {
    key: 'Batman',
    label: 'Batman',
  },
  {
    key: 'Bhs',
    label: 'Bhs',
  },
  {
    key: 'Bella Ragazza',
    label: 'Bella Ragazza',
  },
  {
    key: 'Baumler',
    label: 'Baumler',
  },
  {
    key: 'Bertoni',
    label: 'Bertoni',
  },
  {
    key: 'Basic Line',
    label: 'Basic Line',
  },
  {
    key: 'Beloved',
    label: 'Beloved',
  },
  {
    key: 'Bimba Y Lola',
    label: 'Bimba Y Lola',
  },
  {
    key: 'Bruno Saint Hilaire',
    label: 'Bruno Saint Hilaire',
  },
  {
    key: 'Bay',
    label: 'Bay',
  },
  {
    key: 'Bianca Nygard',
    label: 'Bianca Nygard',
  },
  {
    key: 'Batik',
    label: 'Batik',
  },
  {
    key: 'Barbara Schwarzer',
    label: 'Barbara Schwarzer',
  },
  {
    key: 'Barbour',
    label: 'Barbour',
  },
  {
    key: 'Bitte Kai Rand',
    label: 'Bitte Kai Rand',
  },
  {
    key: 'Bisou Bisou',
    label: 'Bisou Bisou',
  },
  {
    key: 'Best Emilie',
    label: 'Best Emilie',
  },
  {
    key: 'Birger Et Mikkelsen',
    label: 'Birger Et Mikkelsen',
  },
  {
    key: 'Big Star',
    label: 'Big Star',
  },
  {
    key: 'Baum Und Pferdgarten',
    label: 'Baum Und Pferdgarten',
  },
  {
    key: 'Bel&Bo',
    label: 'Bel&Bo',
  },
  {
    key: 'Background',
    label: 'Background',
  },
  {
    key: 'Babaton',
    label: 'Babaton',
  },
  {
    key: 'Blaumax',
    label: 'Blaumax',
  },
  {
    key: 'Bikkembergs',
    label: 'Bikkembergs',
  },
  {
    key: 'Betty Basics',
    label: 'Betty Basics',
  },
  {
    key: 'Bleu Blanc Rouge',
    label: 'Bleu Blanc Rouge',
  },
  {
    key: 'Bobeau',
    label: 'Bobeau',
  },
  {
    key: 'Boden',
    label: 'Boden',
  },
  {
    key: 'Barutti',
    label: 'Barutti',
  },
  {
    key: 'Bullboxer',
    label: 'Bullboxer',
  },
  {
    key: 'B&C Collection',
    label: 'B&C Collection',
  },
  {
    key: 'Bar Iii',
    label: 'Bar Iii',
  },
  {
    key: 'Boston Proper',
    label: 'Boston Proper',
  },
  {
    key: 'Be Beau',
    label: 'Be Beau',
  },
  {
    key: 'Bellissima',
    label: 'Bellissima',
  },
  {
    key: 'Brax Golf',
    label: 'Brax Golf',
  },
  {
    key: 'Blazer',
    label: 'Blazer',
  },
  {
    key: 'Battibaleno',
    label: 'Battibaleno',
  },
  {
    key: 'Bianco',
    label: 'Bianco',
  },
  {
    key: 'Belowzero',
    label: 'Belowzero',
  },
  {
    key: 'Birgitte Herskind',
    label: 'Birgitte Herskind',
  },
  {
    key: 'Big Tramp',
    label: 'Big Tramp',
  },
  {
    key: 'Breakaway',
    label: 'Breakaway',
  },
  {
    key: 'Berto Lucci',
    label: 'Berto Lucci',
  },
  {
    key: 'Bauhaus',
    label: 'Bauhaus',
  },
  {
    key: 'Bally',
    label: 'Bally',
  },
  {
    key: 'Braccialini',
    label: 'Braccialini',
  },
  {
    key: 'Bestimi',
    label: 'Bestimi',
  },
  {
    key: 'Best',
    label: 'Best',
  },
  {
    key: 'Bibi Lou',
    label: 'Bibi Lou',
  },
  {
    key: 'Bird Keepers',
    label: 'Bird Keepers',
  },
  {
    key: 'Barkins',
    label: 'Barkins',
  },
  {
    key: 'Blue Bird',
    label: 'Blue Bird',
  },
  {
    key: 'Bobo Zander',
    label: 'Bobo Zander',
  },
  {
    key: 'Backtee',
    label: 'Backtee',
  },
  {
    key: 'Bisou Bisou By Michele Bohbot',
    label: 'Bisou Bisou By Michele Bohbot',
  },
  {
    key: 'Blossom',
    label: 'Blossom',
  },
  {
    key: 'Bogie',
    label: 'Bogie',
  },
  {
    key: 'Boss Green',
    label: 'Boss Green',
  },
  {
    key: 'Blue',
    label: 'Blue',
  },
  {
    key: 'Baby Phat',
    label: 'Baby Phat',
  },
  {
    key: 'Baldessarini',
    label: 'Baldessarini',
  },
  {
    key: 'Blue Monkey',
    label: 'Blue Monkey',
  },
  {
    key: 'Belle Women',
    label: 'Belle Women',
  },
  {
    key: 'Brave + True',
    label: 'Brave + True',
  },
  {
    key: 'Basic Line By Casual Wear',
    label: 'Basic Line By Casual Wear',
  },
  {
    key: 'British Passport',
    label: 'British Passport',
  },
  {
    key: 'Bill Blass',
    label: 'Bill Blass',
  },
  {
    key: 'Blugirl Folies',
    label: 'Blugirl Folies',
  },
  {
    key: 'B.Curved',
    label: 'B.Curved',
  },
  {
    key: 'Belstaff',
    label: 'Belstaff',
  },
  {
    key: 'Blue Effect',
    label: 'Blue Effect',
  },
  {
    key: 'Blue Star',
    label: 'Blue Star',
  },
  {
    key: 'Babista',
    label: 'Babista',
  },
  {
    key: 'Bamboo',
    label: 'Bamboo',
  },
  {
    key: 'Bomboogie',
    label: 'Bomboogie',
  },
  {
    key: 'Betsey Johnson',
    label: 'Betsey Johnson',
  },
  {
    key: 'Brooks',
    label: 'Brooks',
  },
  {
    key: 'Bm',
    label: 'Bm',
  },
  {
    key: 'B Young',
    label: 'B Young',
  },
  {
    key: 'Belle Surprise',
    label: 'Belle Surprise',
  },
  {
    key: 'Brookshire',
    label: 'Brookshire',
  },
  {
    key: 'Blvd',
    label: 'Blvd',
  },
  {
    key: 'Baldinini',
    label: 'Baldinini',
  },
  {
    key: 'Brooker',
    label: 'Brooker',
  },
  {
    key: 'Be Edgy',
    label: 'Be Edgy',
  },
  {
    key: 'Bonfire',
    label: 'Bonfire',
  },
  {
    key: 'Be Only',
    label: 'Be Only',
  },
  {
    key: 'Bebe Plus',
    label: 'Bebe Plus',
  },
  {
    key: 'Benedikte Utzon',
    label: 'Benedikte Utzon',
  },
  {
    key: 'Blu',
    label: 'Blu',
  },
  {
    key: 'Bergson',
    label: 'Bergson',
  },
  {
    key: 'Bella',
    label: 'Bella',
  },
  {
    key: 'Big Bear',
    label: 'Big Bear',
  },
  {
    key: 'B.U.M. Equipment',
    label: 'B.U.M. Equipment',
  },
  {
    key: 'Balmain',
    label: 'Balmain',
  },
  {
    key: 'Bizzbee',
    label: 'Bizzbee',
  },
  {
    key: 'Boggi',
    label: 'Boggi',
  },
  {
    key: 'Bardehle',
    label: 'Bardehle',
  },
  {
    key: 'Beldona',
    label: 'Beldona',
  },
  {
    key: 'Bell & Barnett',
    label: 'Bell & Barnett',
  },
  {
    key: 'Bonelli',
    label: 'Bonelli',
  },
  {
    key: 'Burberry',
    label: 'Burberry',
  },
  {
    key: 'Bloom',
    label: 'Bloom',
  },
  {
    key: 'Bondelid',
    label: 'Bondelid',
  },
  {
    key: 'Blk',
    label: 'Blk',
  },
  {
    key: 'Bottega',
    label: 'Bottega',
  },
  {
    key: 'Barcelona',
    label: 'Barcelona',
  },
  {
    key: 'Basic Collection',
    label: 'Basic Collection',
  },
  {
    key: 'Baleno',
    label: 'Baleno',
  },
  {
    key: 'Blackberrys',
    label: 'Blackberrys',
  },
  {
    key: 'Beate Heymann',
    label: 'Beate Heymann',
  },
  {
    key: 'Belle By Oasis',
    label: 'Belle By Oasis',
  },
  {
    key: 'Blu Heaven',
    label: 'Blu Heaven',
  },
  {
    key: 'Ben Green',
    label: 'Ben Green',
  },
  {
    key: 'Bluoltre',
    label: 'Bluoltre',
  },
  {
    key: 'Bernardi',
    label: 'Bernardi',
  },
  {
    key: 'Bergans Of Norway',
    label: 'Bergans Of Norway',
  },
  {
    key: 'Bien Bleu',
    label: 'Bien Bleu',
  },
  {
    key: 'Bauer',
    label: 'Bauer',
  },
  {
    key: 'By&By',
    label: 'By&By',
  },
  {
    key: 'Be Cool',
    label: 'Be Cool',
  },
  {
    key: 'Be Bop',
    label: 'Be Bop',
  },
  {
    key: 'Botinelli',
    label: 'Botinelli',
  },
  {
    key: 'Bronx',
    label: 'Bronx',
  },
  {
    key: 'Blend Of America',
    label: 'Blend Of America',
  },
  {
    key: 'Bagatt',
    label: 'Bagatt',
  },
  {
    key: 'Better Rich',
    label: 'Better Rich',
  },
  {
    key: 'Blue Steel',
    label: 'Blue Steel',
  },
  {
    key: 'Basics',
    label: 'Basics',
  },
  {
    key: 'Bedo',
    label: 'Bedo',
  },
  {
    key: 'Blue Nature',
    label: 'Blue Nature',
  },
  {
    key: 'Bellucci',
    label: 'Bellucci',
  },
  {
    key: 'B-Soul',
    label: 'B-Soul',
  },
  {
    key: 'Blackout',
    label: 'Blackout',
  },
  {
    key: 'Blanknyc',
    label: 'Blanknyc',
  },
  {
    key: 'Backstage',
    label: 'Backstage',
  },
  {
    key: 'Bellerose',
    label: 'Bellerose',
  },
  {
    key: 'Blauer',
    label: 'Blauer',
  },
  {
    key: 'Blue Rags',
    label: 'Blue Rags',
  },
  {
    key: 'Bruno',
    label: 'Bruno',
  },
  {
    key: 'Babyface',
    label: 'Babyface',
  },
  {
    key: 'Boxfresh',
    label: 'Boxfresh',
  },
  {
    key: 'Beautees',
    label: 'Beautees',
  },
  {
    key: 'Blue Joint',
    label: 'Blue Joint',
  },
  {
    key: 'Be Jealous',
    label: 'Be Jealous',
  },
  {
    key: 'Bogner Jeans',
    label: 'Bogner Jeans',
  },
  {
    key: 'Badoo',
    label: 'Badoo',
  },
  {
    key: 'Bernd Berger',
    label: 'Bernd Berger',
  },
  {
    key: 'Baxleys',
    label: 'Baxleys',
  },
  {
    key: 'Bandolino',
    label: 'Bandolino',
  },
  {
    key: 'Bit & Bridle',
    label: 'Bit & Bridle',
  },
  {
    key: 'Bande Originale',
    label: 'Bande Originale',
  },
  {
    key: 'Bimbus',
    label: 'Bimbus',
  },
  {
    key: 'Bizzy',
    label: 'Bizzy',
  },
  {
    key: 'Bogner Fire+Ice',
    label: 'Bogner Fire+Ice',
  },
  {
    key: 'Bonobos',
    label: 'Bonobos',
  },
  {
    key: 'Bree',
    label: 'Bree',
  },
  {
    key: 'Berri Sports',
    label: 'Berri Sports',
  },
  {
    key: 'Blue Collection',
    label: 'Blue Collection',
  },
  {
    key: 'Blumarine',
    label: 'Blumarine',
  },
  {
    key: 'Bellfield',
    label: 'Bellfield',
  },
  {
    key: 'Berenice',
    label: 'Berenice',
  },
  {
    key: 'Belfe',
    label: 'Belfe',
  },
  {
    key: 'Barts',
    label: 'Barts',
  },
  {
    key: 'B Casual',
    label: 'B Casual',
  },
  {
    key: 'Babolat',
    label: 'Babolat',
  },
  {
    key: 'Byblos',
    label: 'Byblos',
  },
  {
    key: 'Basecamp',
    label: 'Basecamp',
  },
  {
    key: 'Basixx',
    label: 'Basixx',
  },
  {
    key: 'Blonde No.8',
    label: 'Blonde No.8',
  },
  {
    key: 'Bagutta',
    label: 'Bagutta',
  },
  {
    key: 'Badila',
    label: 'Badila',
  },
  {
    key: 'Ballantyne',
    label: 'Ballantyne',
  },
  {
    key: 'Bbg',
    label: 'Bbg',
  },
  {
    key: 'Beach Life',
    label: 'Beach Life',
  },
  {
    key: 'Beutler Swiss Design',
    label: 'Beutler Swiss Design',
  },
  {
    key: 'Blue Dog',
    label: 'Blue Dog',
  },
  {
    key: 'Bcbg Max Azria Runway',
    label: 'Bcbg Max Azria Runway',
  },
  {
    key: 'Blunauta',
    label: 'Blunauta',
  },
  {
    key: 'Baby Doll',
    label: 'Baby Doll',
  },
  {
    key: 'Bloch',
    label: 'Bloch',
  },
  {
    key: 'Blue Berry',
    label: 'Blue Berry',
  },
  {
    key: 'Biaggio Jeans',
    label: 'Biaggio Jeans',
  },
  {
    key: 'Basil',
    label: 'Basil',
  },
  {
    key: 'Big Blue',
    label: 'Big Blue',
  },
  {
    key: 'Blue Vanilla',
    label: 'Blue Vanilla',
  },
  {
    key: 'Bonmarche',
    label: 'Bonmarche',
  },
  {
    key: 'Badgley Mischka',
    label: 'Badgley Mischka',
  },
  {
    key: 'Beyond',
    label: 'Beyond',
  },
  {
    key: 'Bleyle',
    label: 'Bleyle',
  },
  {
    key: 'B Wear',
    label: 'B Wear',
  },
  {
    key: "Barred's",
    label: "Barred's",
  },
  {
    key: 'Bill Cost',
    label: 'Bill Cost',
  },
  {
    key: 'Bonpoint',
    label: 'Bonpoint',
  },
  {
    key: 'Baci & Abbracci',
    label: 'Baci & Abbracci',
  },
  {
    key: 'Banned',
    label: 'Banned',
  },
  {
    key: 'B&B',
    label: 'B&B',
  },
  {
    key: 'Best Choice',
    label: 'Best Choice',
  },
  {
    key: 'Ba&Sh',
    label: 'Ba&Sh',
  },
  {
    key: 'Bitter Sweet',
    label: 'Bitter Sweet',
  },
  {
    key: 'Black Box',
    label: 'Black Box',
  },
  {
    key: 'Bernhi Mode',
    label: 'Bernhi Mode',
  },
  {
    key: 'Barfota',
    label: 'Barfota',
  },
  {
    key: 'Beck & Hersey',
    label: 'Beck & Hersey',
  },
  {
    key: 'Betty Boop',
    label: 'Betty Boop',
  },
  {
    key: 'Bote',
    label: 'Bote',
  },
  {
    key: 'Betts',
    label: 'Betts',
  },
  {
    key: 'Bt Jeans',
    label: 'Bt Jeans',
  },
  {
    key: 'Bisbee',
    label: 'Bisbee',
  },
  {
    key: 'Baby Angel',
    label: 'Baby Angel',
  },
  {
    key: 'Bewley & Ritch',
    label: 'Bewley & Ritch',
  },
  {
    key: 'Blue & Sea',
    label: 'Blue & Sea',
  },
  {
    key: 'Blu Deise',
    label: 'Blu Deise',
  },
  {
    key: 'Bordo',
    label: 'Bordo',
  },
  {
    key: 'B Twin',
    label: 'B Twin',
  },
  {
    key: 'Betty Blue',
    label: 'Betty Blue',
  },
  {
    key: 'B2',
    label: 'B2',
  },
  {
    key: 'Blink',
    label: 'Blink',
  },
  {
    key: 'Blanchelle',
    label: 'Blanchelle',
  },
  {
    key: 'Bloomings',
    label: 'Bloomings',
  },
  {
    key: 'Bruno Magli',
    label: 'Bruno Magli',
  },
  {
    key: 'Bj Jeans',
    label: 'Bj Jeans',
  },
  {
    key: 'Bijou Brigitte',
    label: 'Bijou Brigitte',
  },
  {
    key: 'B.B.G.',
    label: 'B.B.G.',
  },
  {
    key: 'Bleu Marine',
    label: 'Bleu Marine',
  },
  {
    key: 'Bleecker & Mercer',
    label: 'Bleecker & Mercer',
  },
  {
    key: 'Blue Day`S',
    label: 'Blue Day`S',
  },
  {
    key: 'Baby Walz',
    label: 'Baby Walz',
  },
  {
    key: 'Basic Concept',
    label: 'Basic Concept',
  },
  {
    key: 'B.I.Y.A.C.D.A',
    label: 'B.I.Y.A.C.D.A',
  },
  {
    key: 'Bassini',
    label: 'Bassini',
  },
  {
    key: 'Borsalino',
    label: 'Borsalino',
  },
  {
    key: 'Blooming Marvellous',
    label: 'Blooming Marvellous',
  },
  {
    key: 'Big',
    label: 'Big',
  },
  {
    key: 'Bayern Munchen',
    label: 'Bayern Munchen',
  },
  {
    key: 'Becca By Rebecca Virtue',
    label: 'Becca By Rebecca Virtue',
  },
  {
    key: 'Bernini',
    label: 'Bernini',
  },
  {
    key: 'Black Wolf',
    label: 'Black Wolf',
  },
  {
    key: 'Be Tween',
    label: 'Be Tween',
  },
  {
    key: 'Baxter & Wells',
    label: 'Baxter & Wells',
  },
  {
    key: 'Brunello Cucinelli',
    label: 'Brunello Cucinelli',
  },
  {
    key: 'Blue Moon',
    label: 'Blue Moon',
  },
  {
    key: 'Blonde+Blonde',
    label: 'Blonde+Blonde',
  },
  {
    key: 'Blue 84',
    label: 'Blue 84',
  },
  {
    key: 'Bagoraz',
    label: 'Bagoraz',
  },
  {
    key: 'Biwang',
    label: 'Biwang',
  },
  {
    key: 'Bariano',
    label: 'Bariano',
  },
  {
    key: 'B.D. Baggies',
    label: 'B.D. Baggies',
  },
  {
    key: 'Balance',
    label: 'Balance',
  },
  {
    key: 'Best Style',
    label: 'Best Style',
  },
  {
    key: 'Bk British Knights',
    label: 'Bk British Knights',
  },
  {
    key: 'Blue Inc',
    label: 'Blue Inc',
  },
  {
    key: 'Bmw',
    label: 'Bmw',
  },
  {
    key: 'Bert Pulitzer',
    label: 'Bert Pulitzer',
  },
  {
    key: 'Blue Sand',
    label: 'Blue Sand',
  },
  {
    key: 'Broken Threads',
    label: 'Broken Threads',
  },
  {
    key: 'Bon Worth',
    label: 'Bon Worth',
  },
  {
    key: 'Bruno Manetti',
    label: 'Bruno Manetti',
  },
  {
    key: 'Borbonese',
    label: 'Borbonese',
  },
  {
    key: 'Bbc',
    label: 'Bbc',
  },
  {
    key: 'Beulah',
    label: 'Beulah',
  },
  {
    key: 'Bratan',
    label: 'Bratan',
  },
  {
    key: 'Baluoke',
    label: 'Baluoke',
  },
  {
    key: 'Balloon',
    label: 'Balloon',
  },
  {
    key: 'Blue Queen',
    label: 'Blue Queen',
  },
  {
    key: 'Bohemia',
    label: 'Bohemia',
  },
  {
    key: 'Billy London',
    label: 'Billy London',
  },
  {
    key: 'Biaggi',
    label: 'Biaggi',
  },
  {
    key: 'Bell',
    label: 'Bell',
  },
  {
    key: 'Best Time',
    label: 'Best Time',
  },
  {
    key: 'Belmondo',
    label: 'Belmondo',
  },
  {
    key: 'Blond',
    label: 'Blond',
  },
  {
    key: 'B.L.E.U.',
    label: 'B.L.E.U.',
  },
  {
    key: 'Blue Stone',
    label: 'Blue Stone',
  },
  {
    key: 'Bm Collection',
    label: 'Bm Collection',
  },
  {
    key: 'Bijenkorf',
    label: 'Bijenkorf',
  },
  {
    key: 'Blanc Du Nil',
    label: 'Blanc Du Nil',
  },
  {
    key: 'Berghaus',
    label: 'Berghaus',
  },
  {
    key: 'Body Talk',
    label: 'Body Talk',
  },
  {
    key: 'Benger',
    label: 'Benger',
  },
  {
    key: 'Bermuda Sands',
    label: 'Bermuda Sands',
  },
  {
    key: 'Baronia',
    label: 'Baronia',
  },
  {
    key: 'Butler And Webb',
    label: 'Butler And Webb',
  },
  {
    key: 'Black Premium By Emp Clothing',
    label: 'Black Premium By Emp Clothing',
  },
  {
    key: 'Blugirl Blumarine',
    label: 'Blugirl Blumarine',
  },
  {
    key: 'Bad Boy',
    label: 'Bad Boy',
  },
  {
    key: 'Blue Diamond',
    label: 'Blue Diamond',
  },
  {
    key: 'Blanco Jeans',
    label: 'Blanco Jeans',
  },
  {
    key: 'Beta Brand',
    label: 'Beta Brand',
  },
  {
    key: 'Baltimore League',
    label: 'Baltimore League',
  },
  {
    key: 'Blanc Noir',
    label: 'Blanc Noir',
  },
  {
    key: 'Barbara Lebek',
    label: 'Barbara Lebek',
  },
  {
    key: 'Brigitte Bardot X La Redoute',
    label: 'Brigitte Bardot X La Redoute',
  },
  {
    key: 'Blue Box',
    label: 'Blue Box',
  },
  {
    key: 'Blasting',
    label: 'Blasting',
  },
  {
    key: 'Burlington',
    label: 'Burlington',
  },
  {
    key: 'Buff',
    label: 'Buff',
  },
  {
    key: 'Blue Zoo',
    label: 'Blue Zoo',
  },
  {
    key: 'Bisou D`Eve',
    label: 'Bisou D`Eve',
  },
  {
    key: 'Baf Woman',
    label: 'Baf Woman',
  },
  {
    key: 'B&K',
    label: 'B&K',
  },
  {
    key: 'Bramante',
    label: 'Bramante',
  },
  {
    key: 'Baba Design',
    label: 'Baba Design',
  },
  {
    key: 'Baby Club',
    label: 'Baby Club',
  },
  {
    key: 'Brook Taverner',
    label: 'Brook Taverner',
  },
  {
    key: 'Body Zone',
    label: 'Body Zone',
  },
  {
    key: 'Bouny Baby',
    label: 'Bouny Baby',
  },
  {
    key: 'Bar',
    label: 'Bar',
  },
  {
    key: 'Brett & Sons',
    label: 'Brett & Sons',
  },
  {
    key: 'Bellino',
    label: 'Bellino',
  },
  {
    key: 'Blu El',
    label: 'Blu El',
  },
  {
    key: 'Bluhmod',
    label: 'Bluhmod',
  },
  {
    key: 'By Groth',
    label: 'By Groth',
  },
  {
    key: 'Bulgari',
    label: 'Bulgari',
  },
  {
    key: 'Boho Australia',
    label: 'Boho Australia',
  },
  {
    key: 'Berkeley',
    label: 'Berkeley',
  },
  {
    key: 'Blue Mont',
    label: 'Blue Mont',
  },
  {
    key: 'Blue Side',
    label: 'Blue Side',
  },
  {
    key: 'Baba Yaga',
    label: 'Baba Yaga',
  },
  {
    key: 'Blue One',
    label: 'Blue One',
  },
  {
    key: 'Basile',
    label: 'Basile',
  },
  {
    key: 'Blog By Gemo',
    label: 'Blog By Gemo',
  },
  {
    key: 'B-Street',
    label: 'B-Street',
  },
  {
    key: 'Breeze Girls',
    label: 'Breeze Girls',
  },
  {
    key: 'Bwny',
    label: 'Bwny',
  },
  {
    key: 'Brunate',
    label: 'Brunate',
  },
  {
    key: 'Biston',
    label: 'Biston',
  },
  {
    key: 'Belcci',
    label: 'Belcci',
  },
  {
    key: 'Blondix',
    label: 'Blondix',
  },
  {
    key: 'Bianchi Milano',
    label: 'Bianchi Milano',
  },
  {
    key: 'By Fe',
    label: 'By Fe',
  },
  {
    key: 'B',
    label: 'B',
  },
  {
    key: 'Bluenotes',
    label: 'Bluenotes',
  },
  {
    key: 'Belle Epoque',
    label: 'Belle Epoque',
  },
  {
    key: 'Blanco Tricot',
    label: 'Blanco Tricot',
  },
  {
    key: 'Blazek',
    label: 'Blazek',
  },
  {
    key: 'Bytimo',
    label: 'Bytimo',
  },
  {
    key: 'Balenciaga',
    label: 'Balenciaga',
  },
  {
    key: 'Bongenie Grieder',
    label: 'Bongenie Grieder',
  },
  {
    key: 'Black Rainn',
    label: 'Black Rainn',
  },
  {
    key: 'Benvenuti',
    label: 'Benvenuti',
  },
  {
    key: 'B+Ab',
    label: 'B+Ab',
  },
  {
    key: 'Beach',
    label: 'Beach',
  },
  {
    key: 'B. Bocelli',
    label: 'B. Bocelli',
  },
  {
    key: 'Black Kaviar',
    label: 'Black Kaviar',
  },
  {
    key: 'Broken Arrow',
    label: 'Broken Arrow',
  },
  {
    key: "Blue Willi's",
    label: "Blue Willi's",
  },
  {
    key: 'Beechers Brook',
    label: 'Beechers Brook',
  },
  {
    key: 'Basic Edition',
    label: 'Basic Edition',
  },
  {
    key: 'Biscuit',
    label: 'Biscuit',
  },
  {
    key: 'Bailly Diehl',
    label: 'Bailly Diehl',
  },
  {
    key: 'Belle Femme',
    label: 'Belle Femme',
  },
  {
    key: 'Bloose',
    label: 'Bloose',
  },
  {
    key: 'Beatrice',
    label: 'Beatrice',
  },
  {
    key: 'Bleu Bonheur',
    label: 'Bleu Bonheur',
  },
  {
    key: 'Blurr',
    label: 'Blurr',
  },
  {
    key: 'Black&Brown',
    label: 'Black&Brown',
  },
  {
    key: 'B-Wear',
    label: 'B-Wear',
  },
  {
    key: 'Blue Flame',
    label: 'Blue Flame',
  },
  {
    key: 'Bleu De Sym',
    label: 'Bleu De Sym',
  },
  {
    key: 'Banjara',
    label: 'Banjara',
  },
  {
    key: 'Becksondergaard',
    label: 'Becksondergaard',
  },
  {
    key: 'Bruno Premi',
    label: 'Bruno Premi',
  },
  {
    key: 'Bison',
    label: 'Bison',
  },
  {
    key: 'Bric-A-Brac',
    label: 'Bric-A-Brac',
  },
  {
    key: 'Berge',
    label: 'Berge',
  },
  {
    key: 'Baretti',
    label: 'Baretti',
  },
  {
    key: 'Bianco Ghiaccio',
    label: 'Bianco Ghiaccio',
  },
  {
    key: 'Blues Club',
    label: 'Blues Club',
  },
  {
    key: 'Black&Fish',
    label: 'Black&Fish',
  },
  {
    key: 'Brasi & Brasi',
    label: 'Brasi & Brasi',
  },
  {
    key: 'Belfe&Belfe',
    label: 'Belfe&Belfe',
  },
  {
    key: 'Bickley + Mitchell',
    label: 'Bickley + Mitchell',
  },
  {
    key: 'Big Fashion',
    label: 'Big Fashion',
  },
  {
    key: 'Belman',
    label: 'Belman',
  },
  {
    key: 'Blue Chameleon',
    label: 'Blue Chameleon',
  },
  {
    key: 'Berlin',
    label: 'Berlin',
  },
  {
    key: 'B. Angel',
    label: 'B. Angel',
  },
  {
    key: 'Blue Asphalt',
    label: 'Blue Asphalt',
  },
  {
    key: 'Blue Point',
    label: 'Blue Point',
  },
  {
    key: 'Bon Bebe',
    label: 'Bon Bebe',
  },
  {
    key: 'Be Happy',
    label: 'Be Happy',
  },
  {
    key: 'Bleu123',
    label: 'Bleu123',
  },
  {
    key: 'Borgonovo',
    label: 'Borgonovo',
  },
  {
    key: 'Bellini',
    label: 'Bellini',
  },
  {
    key: 'Best Way',
    label: 'Best Way',
  },
  {
    key: 'Big Fun',
    label: 'Big Fun',
  },
  {
    key: 'Banana',
    label: 'Banana',
  },
  {
    key: 'B.M.',
    label: 'B.M.',
  },
  {
    key: 'Beverly Hills',
    label: 'Beverly Hills',
  },
  {
    key: 'Barbara Farber',
    label: 'Barbara Farber',
  },
  {
    key: 'Blue Sea',
    label: 'Blue Sea',
  },
  {
    key: 'Birk',
    label: 'Birk',
  },
  {
    key: 'B&S',
    label: 'B&S',
  },
  {
    key: 'Barbara',
    label: 'Barbara',
  },
  {
    key: 'Big Boyz',
    label: 'Big Boyz',
  },
  {
    key: 'Big Father',
    label: 'Big Father',
  },
  {
    key: 'Bottega Veneta',
    label: 'Bottega Veneta',
  },
  {
    key: 'Bella Donna',
    label: 'Bella Donna',
  },
  {
    key: 'Body Style',
    label: 'Body Style',
  },
  {
    key: 'Barbara Hulanicki',
    label: 'Barbara Hulanicki',
  },
  {
    key: 'Basic Trend',
    label: 'Basic Trend',
  },
  {
    key: 'Be Fit Be You.',
    label: 'Be Fit Be You.',
  },
  {
    key: 'B. Moss',
    label: 'B. Moss',
  },
  {
    key: 'B&K Style',
    label: 'B&K Style',
  },
  {
    key: 'Blue Eyes',
    label: 'Blue Eyes',
  },
  {
    key: 'Best Fashion',
    label: 'Best Fashion',
  },
  {
    key: 'Boxercraft',
    label: 'Boxercraft',
  },
  {
    key: 'Basset',
    label: 'Basset',
  },
  {
    key: 'Boho Style',
    label: 'Boho Style',
  },
  {
    key: 'Baishenggt',
    label: 'Baishenggt',
  },
  {
    key: 'Beautiful Diamonds',
    label: 'Beautiful Diamonds',
  },
  {
    key: 'B.B',
    label: 'B.B',
  },
  {
    key: 'Biggi M',
    label: 'Biggi M',
  },
  {
    key: "Bric's",
    label: "Bric's",
  },
  {
    key: 'Big Authorized',
    label: 'Big Authorized',
  },
  {
    key: 'Basic Life',
    label: 'Basic Life',
  },
  {
    key: 'Blue Monday',
    label: 'Blue Monday',
  },
  {
    key: 'Boombah',
    label: 'Boombah',
  },
  {
    key: 'Bluemex',
    label: 'Bluemex',
  },
  {
    key: 'Best In Town',
    label: 'Best In Town',
  },
  {
    key: 'Bonnie Baby',
    label: 'Bonnie Baby',
  },
  {
    key: 'Beaumont Amsterdam',
    label: 'Beaumont Amsterdam',
  },
  {
    key: 'Bitching And Junkfood',
    label: 'Bitching And Junkfood',
  },
  {
    key: 'Bethel',
    label: 'Bethel',
  },
  {
    key: 'Between Me & You',
    label: 'Between Me & You',
  },
  {
    key: 'Bugsy',
    label: 'Bugsy',
  },
  {
    key: 'Babymel',
    label: 'Babymel',
  },
  {
    key: 'Betsy & Adam',
    label: 'Betsy & Adam',
  },
  {
    key: 'Bck & Hrsy',
    label: 'Bck & Hrsy',
  },
  {
    key: 'Bitsiani',
    label: 'Bitsiani',
  },
  {
    key: 'Bambino',
    label: 'Bambino',
  },
  {
    key: 'Balmain By H&M',
    label: 'Balmain By H&M',
  },
  {
    key: 'Blue White',
    label: 'Blue White',
  },
  {
    key: 'Body Touch',
    label: 'Body Touch',
  },
  {
    key: 'Best Quality By Tcm',
    label: 'Best Quality By Tcm',
  },
  {
    key: 'Bianki',
    label: 'Bianki',
  },
  {
    key: 'Blue Deise',
    label: 'Blue Deise',
  },
  {
    key: 'Blackground',
    label: 'Blackground',
  },
  {
    key: 'Barocco',
    label: 'Barocco',
  },
  {
    key: 'Body Star',
    label: 'Body Star',
  },
  {
    key: 'Baby',
    label: 'Baby',
  },
  {
    key: 'Becker',
    label: 'Becker',
  },
  {
    key: 'B Three',
    label: 'B Three',
  },
  {
    key: 'Brams Paris',
    label: 'Brams Paris',
  },
  {
    key: 'Berydale',
    label: 'Berydale',
  },
  {
    key: 'Bella Blue',
    label: 'Bella Blue',
  },
  {
    key: 'Blue Wear',
    label: 'Blue Wear',
  },
  {
    key: 'Baxter',
    label: 'Baxter',
  },
  {
    key: 'Be Styled',
    label: 'Be Styled',
  },
  {
    key: 'Blue Love',
    label: 'Blue Love',
  },
  {
    key: 'Bella Dahl',
    label: 'Bella Dahl',
  },
  {
    key: 'Barone',
    label: 'Barone',
  },
  {
    key: 'Bp Studio',
    label: 'Bp Studio',
  },
  {
    key: 'Bella Jones',
    label: 'Bella Jones',
  },
  {
    key: "Burt's Bees Baby",
    label: "Burt's Bees Baby",
  },
  {
    key: 'Blue Wave',
    label: 'Blue Wave',
  },
  {
    key: 'Bagutti',
    label: 'Bagutti',
  },
  {
    key: 'Bisgaard',
    label: 'Bisgaard',
  },
  {
    key: 'Blue Jade',
    label: 'Blue Jade',
  },
  {
    key: 'Beesty',
    label: 'Beesty',
  },
  {
    key: 'Birkhahn',
    label: 'Birkhahn',
  },
  {
    key: 'Best Copine',
    label: 'Best Copine',
  },
  {
    key: 'B&G',
    label: 'B&G',
  },
  {
    key: 'Barbara Speer',
    label: 'Barbara Speer',
  },
  {
    key: 'Baty Fashion',
    label: 'Baty Fashion',
  },
  {
    key: 'Bub2b',
    label: 'Bub2b',
  },
  {
    key: 'Babyliss',
    label: 'Babyliss',
  },
  {
    key: 'Bob Der Bar',
    label: 'Bob Der Bar',
  },
  {
    key: 'Big Nur Zurich',
    label: 'Big Nur Zurich',
  },
  {
    key: 'Bodyzone By Kappahi',
    label: 'Bodyzone By Kappahi',
  },
  {
    key: 'Bottega Artigiana',
    label: 'Bottega Artigiana',
  },
  {
    key: 'Bizzarro',
    label: 'Bizzarro',
  },
  {
    key: 'Belsac',
    label: 'Belsac',
  },
  {
    key: 'Blue Rose',
    label: 'Blue Rose',
  },
  {
    key: 'Berti',
    label: 'Berti',
  },
  {
    key: 'Bike',
    label: 'Bike',
  },
  {
    key: 'Benhao',
    label: 'Benhao',
  },
  {
    key: 'Billy Reid',
    label: 'Billy Reid',
  },
  {
    key: 'Bergans',
    label: 'Bergans',
  },
  {
    key: 'Blue Oxford',
    label: 'Blue Oxford',
  },
  {
    key: 'Bh Jeans',
    label: 'Bh Jeans',
  },
  {
    key: 'Blu Byblos',
    label: 'Blu Byblos',
  },
  {
    key: 'Blue Spice',
    label: 'Blue Spice',
  },
  {
    key: 'Baldino',
    label: 'Baldino',
  },
  {
    key: 'Bahia',
    label: 'Bahia',
  },
  {
    key: 'B&W',
    label: 'B&W',
  },
  {
    key: 'Bloome',
    label: 'Bloome',
  },
  {
    key: 'Bangjiuji',
    label: 'Bangjiuji',
  },
  {
    key: 'Bland Date',
    label: 'Bland Date',
  },
  {
    key: 'Basik',
    label: 'Basik',
  },
  {
    key: "Boy Sen's",
    label: "Boy Sen's",
  },
  {
    key: 'Backgammon',
    label: 'Backgammon',
  },
  {
    key: 'Backswing',
    label: 'Backswing',
  },
  {
    key: 'Blu Sand',
    label: 'Blu Sand',
  },
  {
    key: 'Bear',
    label: 'Bear',
  },
  {
    key: 'Big Dart',
    label: 'Big Dart',
  },
  {
    key: 'Baxmen',
    label: 'Baxmen',
  },
  {
    key: 'Bellandi',
    label: 'Bellandi',
  },
  {
    key: 'Blue Image',
    label: 'Blue Image',
  },
  {
    key: 'Ben-Lex',
    label: 'Ben-Lex',
  },
  {
    key: 'Bee - Q Club',
    label: 'Bee - Q Club',
  },
  {
    key: 'Bongenie Grieder',
    label: 'Bongenie Grieder',
  },
  {
    key: 'Barka',
    label: 'Barka',
  },
  {
    key: 'Bakito',
    label: 'Bakito',
  },
  {
    key: 'Bergstein',
    label: 'Bergstein',
  },
  {
    key: 'Bing, Harris & Co',
    label: 'Bing, Harris & Co',
  },
  {
    key: 'Basic One',
    label: 'Basic One',
  },
  {
    key: 'Benino',
    label: 'Benino',
  },
  {
    key: 'Blaklader',
    label: 'Blaklader',
  },
  {
    key: 'Bootlegger',
    label: 'Bootlegger',
  },
  {
    key: 'B&J',
    label: 'B&J',
  },
  {
    key: 'Bellina Sanz',
    label: 'Bellina Sanz',
  },
  {
    key: 'Blacky',
    label: 'Blacky',
  },
  {
    key: 'Brian Dales',
    label: 'Brian Dales',
  },
  {
    key: 'Blu Planet',
    label: 'Blu Planet',
  },
  {
    key: 'Braun Buffel',
    label: 'Braun Buffel',
  },
  {
    key: 'By Zoe',
    label: 'By Zoe',
  },
  {
    key: 'Blue Ridge',
    label: 'Blue Ridge',
  },
  {
    key: 'Barbie',
    label: 'Barbie',
  },
  {
    key: 'Boglioli',
    label: 'Boglioli',
  },
  {
    key: 'B.B.Girl',
    label: 'B.B.Girl',
  },
  {
    key: 'Bachata',
    label: 'Bachata',
  },
  {
    key: 'Bad+Mad',
    label: 'Bad+Mad',
  },
  {
    key: 'Bettina Sanz',
    label: 'Bettina Sanz',
  },
  {
    key: 'Blanc Bleu',
    label: 'Blanc Bleu',
  },
  {
    key: 'Blanca Style',
    label: 'Blanca Style',
  },
  {
    key: 'Bon Marche',
    label: 'Bon Marche',
  },
  {
    key: 'Baby Starters',
    label: 'Baby Starters',
  },
  {
    key: 'Black Rivet',
    label: 'Black Rivet',
  },
  {
    key: 'Blues',
    label: 'Blues',
  },
  {
    key: 'Blue Space',
    label: 'Blue Space',
  },
  {
    key: 'Bama',
    label: 'Bama',
  },
  {
    key: 'Burberrys',
    label: 'Burberrys',
  },
  {
    key: 'Bi Pai',
    label: 'Bi Pai',
  },
  {
    key: 'Bariba',
    label: 'Bariba',
  },
  {
    key: 'Best Off',
    label: 'Best Off',
  },
  {
    key: 'Botta',
    label: 'Botta',
  },
  {
    key: 'Betty Jackson',
    label: 'Betty Jackson',
  },
  {
    key: 'Barnett & Barnett',
    label: 'Barnett & Barnett',
  },
  {
    key: 'Bailo',
    label: 'Bailo',
  },
  {
    key: 'Bijoux Terner',
    label: 'Bijoux Terner',
  },
  {
    key: "Bike O'bello",
    label: "Bike O'bello",
  },
  {
    key: 'Bill Levkoff',
    label: 'Bill Levkoff',
  },
  {
    key: 'Belen',
    label: 'Belen',
  },
  {
    key: 'Bikini',
    label: 'Bikini',
  },
  {
    key: 'Bar',
    label: 'Bar',
  },
  {
    key: 'Bs Fashion',
    label: 'Bs Fashion',
  },
  {
    key: 'Bad Boys',
    label: 'Bad Boys',
  },
  {
    key: 'Blend Date',
    label: 'Blend Date',
  },
  {
    key: 'Bertone',
    label: 'Bertone',
  },
  {
    key: 'Beverly Drive',
    label: 'Beverly Drive',
  },
  {
    key: 'Berek',
    label: 'Berek',
  },
  {
    key: 'Blu Kids',
    label: 'Blu Kids',
  },
  {
    key: 'Bec',
    label: 'Bec',
  },
  {
    key: 'Black Bead',
    label: 'Black Bead',
  },
  {
    key: 'Barba',
    label: 'Barba',
  },
  {
    key: 'Blue Petrol',
    label: 'Blue Petrol',
  },
  {
    key: 'Bartlett & Walker',
    label: 'Bartlett & Walker',
  },
  {
    key: 'Biltema',
    label: 'Biltema',
  },
  {
    key: 'Blueberi',
    label: 'Blueberi',
  },
  {
    key: 'Beauty Women',
    label: 'Beauty Women',
  },
  {
    key: 'Bold Sugar',
    label: 'Bold Sugar',
  },
  {
    key: 'B.Yu',
    label: 'B.Yu',
  },
  {
    key: "Bernie's",
    label: "Bernie's",
  },
  {
    key: 'Black Friday',
    label: 'Black Friday',
  },
  {
    key: 'Blackstone',
    label: 'Blackstone',
  },
  {
    key: 'Barbarella',
    label: 'Barbarella',
  },
  {
    key: 'Belcade',
    label: 'Belcade',
  },
  {
    key: 'Boston Babe',
    label: 'Boston Babe',
  },
  {
    key: 'B-Men',
    label: 'B-Men',
  },
  {
    key: 'Bludeise',
    label: 'Bludeise',
  },
  {
    key: 'Bavac',
    label: 'Bavac',
  },
  {
    key: 'B&C',
    label: 'B&C',
  },
  {
    key: 'Be Estado Puro',
    label: 'Be Estado Puro',
  },
  {
    key: 'Brenda Zaro',
    label: 'Brenda Zaro',
  },
  {
    key: 'Bader',
    label: 'Bader',
  },
  {
    key: 'Beach To Bar',
    label: 'Beach To Bar',
  },
  {
    key: 'Best Of Basic',
    label: 'Best Of Basic',
  },
  {
    key: 'Blaf Collection',
    label: 'Blaf Collection',
  },
  {
    key: 'Beco',
    label: 'Beco',
  },
  {
    key: 'By Clara',
    label: 'By Clara',
  },
  {
    key: 'Blue Life',
    label: 'Blue Life',
  },
  {
    key: 'B Yond',
    label: 'B Yond',
  },
  {
    key: 'Bellerose',
    label: 'Bellerose',
  },
  {
    key: 'Bernhard',
    label: 'Bernhard',
  },
  {
    key: "Bisou's Project",
    label: "Bisou's Project",
  },
  {
    key: "Baker's",
    label: "Baker's",
  },
  {
    key: 'Bastard',
    label: 'Bastard',
  },
  {
    key: 'Bisa',
    label: 'Bisa',
  },
  {
    key: 'Body Fashion',
    label: 'Body Fashion',
  },
  {
    key: 'Biacci',
    label: 'Biacci',
  },
  {
    key: 'Billcee',
    label: 'Billcee',
  },
  {
    key: 'Blue Blood',
    label: 'Blue Blood',
  },
  {
    key: 'Bodytec',
    label: 'Bodytec',
  },
  {
    key: 'Bombi Bitt',
    label: 'Bombi Bitt',
  },
  {
    key: 'Blue Black',
    label: 'Blue Black',
  },
  {
    key: 'Breeze',
    label: 'Breeze',
  },
  {
    key: 'Biting',
    label: 'Biting',
  },
  {
    key: 'Boost Up',
    label: 'Boost Up',
  },
  {
    key: 'Baffo',
    label: 'Baffo',
  },
  {
    key: 'Black Chocoolate',
    label: 'Black Chocoolate',
  },
  {
    key: 'Bleeker & Mc Dougal',
    label: 'Bleeker & Mc Dougal',
  },
  {
    key: 'Be Board',
    label: 'Be Board',
  },
  {
    key: 'Bicycle Holidays',
    label: 'Bicycle Holidays',
  },
  {
    key: 'Blanc Nature',
    label: 'Blanc Nature',
  },
  {
    key: 'Blue Fashion',
    label: 'Blue Fashion',
  },
  {
    key: 'Birba',
    label: 'Birba',
  },
  {
    key: 'Beautiful',
    label: 'Beautiful',
  },
  {
    key: 'B.Casual',
    label: 'B.Casual',
  },
  {
    key: 'Bettie Page',
    label: 'Bettie Page',
  },
  {
    key: 'Balabanoff Style',
    label: 'Balabanoff Style',
  },
  {
    key: 'B.S Jeans',
    label: 'B.S Jeans',
  },
  {
    key: 'Beka',
    label: 'Beka',
  },
  {
    key: 'Basic Jeans',
    label: 'Basic Jeans',
  },
  {
    key: 'Blue Jeans',
    label: 'Blue Jeans',
  },
  {
    key: 'Bianca Nero',
    label: 'Bianca Nero',
  },
  {
    key: 'Believe',
    label: 'Believe',
  },
  {
    key: 'Beinn',
    label: 'Beinn',
  },
  {
    key: 'Back',
    label: 'Back',
  },
  {
    key: 'Benbarton',
    label: 'Benbarton',
  },
  {
    key: 'Boris Becker For Tchibo',
    label: 'Boris Becker For Tchibo',
  },
  {
    key: 'Babiesrus',
    label: 'Babiesrus',
  },
  {
    key: 'Blowfly',
    label: 'Blowfly',
  },
  {
    key: 'Body Wear',
    label: 'Body Wear',
  },
  {
    key: "Barney's New York",
    label: "Barney's New York",
  },
  {
    key: 'Bare Traps',
    label: 'Bare Traps',
  },
  {
    key: 'Basic Wear',
    label: 'Basic Wear',
  },
  {
    key: 'Bfly',
    label: 'Bfly',
  },
  {
    key: 'Brioni',
    label: 'Brioni',
  },
  {
    key: 'Blue Arkedia',
    label: 'Blue Arkedia',
  },
  {
    key: 'Bcbg Attitude',
    label: 'Bcbg Attitude',
  },
  {
    key: 'Bekken & Strom',
    label: 'Bekken & Strom',
  },
  {
    key: 'B&L',
    label: 'B&L',
  },
  {
    key: 'Bluefields',
    label: 'Bluefields',
  },
  {
    key: 'Bolle',
    label: 'Bolle',
  },
  {
    key: 'Bella Rosso',
    label: 'Bella Rosso',
  },
  {
    key: 'Beluomo',
    label: 'Beluomo',
  },
  {
    key: 'Benzi',
    label: 'Benzi',
  },
  {
    key: 'Blue Magic',
    label: 'Blue Magic',
  },
  {
    key: 'Bob Mackie',
    label: 'Bob Mackie',
  },
  {
    key: 'Boheme',
    label: 'Boheme',
  },
  {
    key: 'Brigitte Bailey',
    label: 'Brigitte Bailey',
  },
  {
    key: 'Born Concept',
    label: 'Born Concept',
  },
  {
    key: 'Bari Jay',
    label: 'Bari Jay',
  },
  {
    key: 'Blk Dnm',
    label: 'Blk Dnm',
  },
  {
    key: 'Basement',
    label: 'Basement',
  },
  {
    key: 'Beauty Angel',
    label: 'Beauty Angel',
  },
  {
    key: 'Be Active',
    label: 'Be Active',
  },
  {
    key: 'Billionaire',
    label: 'Billionaire',
  },
  {
    key: 'Biemme',
    label: 'Biemme',
  },
  {
    key: 'Beach Rays',
    label: 'Beach Rays',
  },
  {
    key: 'Benson',
    label: 'Benson',
  },
  {
    key: 'Bristol & Bull',
    label: 'Bristol & Bull',
  },
  {
    key: 'Blue Nerissa',
    label: 'Blue Nerissa',
  },
  {
    key: 'Blue Rebel',
    label: 'Blue Rebel',
  },
  {
    key: 'Bernardo Bossi',
    label: 'Bernardo Bossi',
  },
  {
    key: 'Baby Online Dress',
    label: 'Baby Online Dress',
  },
  {
    key: 'Blue Velvet',
    label: 'Blue Velvet',
  },
  {
    key: 'Basic Design',
    label: 'Basic Design',
  },
  {
    key: 'Bush',
    label: 'Bush',
  },
  {
    key: 'Blu Di Blu',
    label: 'Blu Di Blu',
  },
  {
    key: 'Big Chic',
    label: 'Big Chic',
  },
  {
    key: 'Bicycles',
    label: 'Bicycles',
  },
  {
    key: 'Best Company',
    label: 'Best Company',
  },
  {
    key: 'Black Orchid',
    label: 'Black Orchid',
  },
  {
    key: 'Blondie Nites By Linda Bernell',
    label: 'Blondie Nites By Linda Bernell',
  },
  {
    key: 'Big Spade',
    label: 'Big Spade',
  },
  {
    key: 'Bakkaboe',
    label: 'Bakkaboe',
  },
  {
    key: 'B&Co',
    label: 'B&Co',
  },
  {
    key: 'Baxx',
    label: 'Baxx',
  },
  {
    key: 'Beautiful Woman',
    label: 'Beautiful Woman',
  },
  {
    key: 'Bacardi',
    label: 'Bacardi',
  },
  {
    key: 'Bb Up',
    label: 'Bb Up',
  },
  {
    key: 'Blue M',
    label: 'Blue M',
  },
  {
    key: 'Ben Smith',
    label: 'Ben Smith',
  },
  {
    key: 'Body Sport',
    label: 'Body Sport',
  },
  {
    key: 'Baraschi',
    label: 'Baraschi',
  },
  {
    key: 'Blue Bonheur',
    label: 'Blue Bonheur',
  },
  {
    key: 'Bb Couture',
    label: 'Bb Couture',
  },
  {
    key: 'Buzz Cuts',
    label: 'Buzz Cuts',
  },
  {
    key: 'Blue Industry',
    label: 'Blue Industry',
  },
  {
    key: 'Blue Water',
    label: 'Blue Water',
  },
  {
    key: 'Bobby Jones',
    label: 'Bobby Jones',
  },
  {
    key: 'Body Style By Tcm',
    label: 'Body Style By Tcm',
  },
  {
    key: 'Beige',
    label: 'Beige',
  },
  {
    key: 'Browning.',
    label: 'Browning.',
  },
  {
    key: 'Beach Line',
    label: 'Beach Line',
  },
  {
    key: 'Beta',
    label: 'Beta',
  },
  {
    key: 'Beautiful Shoes',
    label: 'Beautiful Shoes',
  },
  {
    key: 'Beach Wave',
    label: 'Beach Wave',
  },
  {
    key: 'Bam Bam',
    label: 'Bam Bam',
  },
  {
    key: 'Benter',
    label: 'Benter',
  },
  {
    key: 'B Soul',
    label: 'B Soul',
  },
  {
    key: 'Barker',
    label: 'Barker',
  },
  {
    key: 'Be Natural By Jana',
    label: 'Be Natural By Jana',
  },
  {
    key: 'Beach & Snow',
    label: 'Beach & Snow',
  },
  {
    key: 'Blue Ocean',
    label: 'Blue Ocean',
  },
  {
    key: 'Brian & Barry',
    label: 'Brian & Barry',
  },
  {
    key: 'Bachrach',
    label: 'Bachrach',
  },
  {
    key: 'Bio Racer',
    label: 'Bio Racer',
  },
  {
    key: 'Beach To Beach',
    label: 'Beach To Beach',
  },
  {
    key: 'Bao Lin Niao',
    label: 'Bao Lin Niao',
  },
  {
    key: 'Batida',
    label: 'Batida',
  },
  {
    key: 'Bianco Gear',
    label: 'Bianco Gear',
  },
  {
    key: 'Bella Roma',
    label: 'Bella Roma',
  },
  {
    key: 'Belmonte',
    label: 'Belmonte',
  },
  {
    key: 'Bermudes',
    label: 'Bermudes',
  },
  {
    key: 'Biz',
    label: 'Biz',
  },
  {
    key: 'Blue Print',
    label: 'Blue Print',
  },
  {
    key: 'Bailey 44',
    label: 'Bailey 44',
  },
  {
    key: 'Billie',
    label: 'Billie',
  },
  {
    key: 'Baracuta',
    label: 'Baracuta',
  },
  {
    key: 'Blue Line',
    label: 'Blue Line',
  },
  {
    key: 'Bags By Bags Paco',
    label: 'Bags By Bags Paco',
  },
  {
    key: 'Bcbg Paris',
    label: 'Bcbg Paris',
  },
  {
    key: 'B.Makowsky',
    label: 'B.Makowsky',
  },
  {
    key: 'Boho Chic',
    label: 'Boho Chic',
  },
  {
    key: 'B.Fashion',
    label: 'B.Fashion',
  },
  {
    key: 'B.Smart',
    label: 'B.Smart',
  },
  {
    key: 'Beltona',
    label: 'Beltona',
  },
  {
    key: 'Be Exclusive',
    label: 'Be Exclusive',
  },
  {
    key: 'Barracuda',
    label: 'Barracuda',
  },
  {
    key: 'B.Weij',
    label: 'B.Weij',
  },
  {
    key: 'Betty Goodman',
    label: 'Betty Goodman',
  },
  {
    key: 'Bonny',
    label: 'Bonny',
  },
  {
    key: 'Beagles',
    label: 'Beagles',
  },
  {
    key: 'Be Fashion Victim',
    label: 'Be Fashion Victim',
  },
  {
    key: 'Bafix Confort',
    label: 'Bafix Confort',
  },
  {
    key: 'Bbonline Dress',
    label: 'Bbonline Dress',
  },
  {
    key: 'Beatrice. B',
    label: 'Beatrice. B',
  },
  {
    key: 'Bgm',
    label: 'Bgm',
  },
  {
    key: 'Bj',
    label: 'Bj',
  },
  {
    key: 'B&A',
    label: 'B&A',
  },
  {
    key: 'Bcg',
    label: 'Bcg',
  },
  {
    key: 'Betsy & Adam By Linda Bernell',
    label: 'Betsy & Adam By Linda Bernell',
  },
  {
    key: "B'twin",
    label: "B'twin",
  },
  {
    key: 'Blendy',
    label: 'Blendy',
  },
  {
    key: 'Biscote Jeans',
    label: 'Biscote Jeans',
  },
  {
    key: 'Bella Berry',
    label: 'Bella Berry',
  },
  {
    key: 'Birge H',
    label: 'Birge H',
  },
  {
    key: 'Bling',
    label: 'Bling',
  },
  {
    key: 'Ben Hao',
    label: 'Ben Hao',
  },
  {
    key: 'Body Comfi',
    label: 'Body Comfi',
  },
  {
    key: 'Blakes',
    label: 'Blakes',
  },
  {
    key: 'Bonnie Jean',
    label: 'Bonnie Jean',
  },
  {
    key: 'Baby Line',
    label: 'Baby Line',
  },
  {
    key: 'Besomeone',
    label: 'Besomeone',
  },
  {
    key: 'Bai Amour',
    label: 'Bai Amour',
  },
  {
    key: 'Blue Fire Jeans',
    label: 'Blue Fire Jeans',
  },
  {
    key: 'Bellali',
    label: 'Bellali',
  },
  {
    key: 'B.C.F',
    label: 'B.C.F',
  },
  {
    key: 'Blancmatt',
    label: 'Blancmatt',
  },
  {
    key: 'Blue Max',
    label: 'Blue Max',
  },
  {
    key: 'British Indigo',
    label: 'British Indigo',
  },
  {
    key: 'Bertie',
    label: 'Bertie',
  },
  {
    key: 'Buse',
    label: 'Buse',
  },
  {
    key: 'Basic Style',
    label: 'Basic Style',
  },
  {
    key: 'Birna',
    label: 'Birna',
  },
  {
    key: 'Blau Blood',
    label: 'Blau Blood',
  },
  {
    key: 'Bode',
    label: 'Bode',
  },
  {
    key: 'Bills Khakis',
    label: 'Bills Khakis',
  },
  {
    key: 'Beck',
    label: 'Beck',
  },
  {
    key: 'Belika',
    label: 'Belika',
  },
  {
    key: 'Black Lily',
    label: 'Black Lily',
  },
  {
    key: 'Blozek',
    label: 'Blozek',
  },
  {
    key: 'Blue Lemon',
    label: 'Blue Lemon',
  },
  {
    key: 'Blue Vision',
    label: 'Blue Vision',
  },
  {
    key: 'Bandolli Jeans',
    label: 'Bandolli Jeans',
  },
  {
    key: 'Bonbons',
    label: 'Bonbons',
  },
  {
    key: 'Better Bodies',
    label: 'Better Bodies',
  },
  {
    key: 'Bella Moda',
    label: 'Bella Moda',
  },
  {
    key: 'Barbara Bui',
    label: 'Barbara Bui',
  },
  {
    key: "Bout' Chou",
    label: "Bout' Chou",
  },
  {
    key: 'Bipai',
    label: 'Bipai',
  },
  {
    key: 'Bay Studio',
    label: 'Bay Studio',
  },
  {
    key: 'Bag Street',
    label: 'Bag Street',
  },
  {
    key: 'Big Fit',
    label: 'Big Fit',
  },
  {
    key: 'Barrie',
    label: 'Barrie',
  },
  {
    key: 'Bagarda',
    label: 'Bagarda',
  },
  {
    key: 'Bad Girl',
    label: 'Bad Girl',
  },
  {
    key: 'B.C. Clothing',
    label: 'B.C. Clothing',
  },
  {
    key: 'Beechfield',
    label: 'Beechfield',
  },
  {
    key: 'Beretta',
    label: 'Beretta',
  },
  {
    key: 'Blaque Label',
    label: 'Blaque Label',
  },
  {
    key: 'Bm Casual',
    label: 'Bm Casual',
  },
  {
    key: 'Banna',
    label: 'Banna',
  },
  {
    key: 'Brigitte Von Boch',
    label: 'Brigitte Von Boch',
  },
  {
    key: 'Belkin',
    label: 'Belkin',
  },
  {
    key: 'Bls',
    label: 'Bls',
  },
  {
    key: 'Blizzard',
    label: 'Blizzard',
  },
  {
    key: 'Blue Deep',
    label: 'Blue Deep',
  },
  {
    key: 'Be Nice',
    label: 'Be Nice',
  },
  {
    key: 'Bee Dees',
    label: 'Bee Dees',
  },
  {
    key: 'By Mi',
    label: 'By Mi',
  },
  {
    key: 'Blue Family',
    label: 'Blue Family',
  },
  {
    key: 'Bighet',
    label: 'Bighet',
  },
  {
    key: 'Belle Poque',
    label: 'Belle Poque',
  },
  {
    key: 'Bike Tec',
    label: 'Bike Tec',
  },
  {
    key: 'Back To School',
    label: 'Back To School',
  },
  {
    key: 'Baroni',
    label: 'Baroni',
  },
  {
    key: 'Bianco',
    label: 'Bianco',
  },
  {
    key: 'Ben Stone',
    label: 'Ben Stone',
  },
  {
    key: 'Bernwalt',
    label: 'Bernwalt',
  },
  {
    key: 'Baxy Boy',
    label: 'Baxy Boy',
  },
  {
    key: 'Bh',
    label: 'Bh',
  },
  {
    key: 'Bambolita',
    label: 'Bambolita',
  },
  {
    key: 'Betseyville',
    label: 'Betseyville',
  },
  {
    key: 'B Sharp',
    label: 'B Sharp',
  },
  {
    key: 'Big Buddha',
    label: 'Big Buddha',
  },
  {
    key: 'Bzr Bruuns Bazaar',
    label: 'Bzr Bruuns Bazaar',
  },
  {
    key: 'Become',
    label: 'Become',
  },
  {
    key: 'Birgitta',
    label: 'Birgitta',
  },
  {
    key: 'Bombers',
    label: 'Bombers',
  },
  {
    key: 'Berkley Jensen',
    label: 'Berkley Jensen',
  },
  {
    key: 'Barratts',
    label: 'Barratts',
  },
  {
    key: 'Clockhouse',
    label: 'Clockhouse',
  },
  {
    key: 'Cotton On',
    label: 'Cotton On',
  },
  {
    key: 'Comma,',
    label: 'Comma,',
  },
  {
    key: 'Camaieu',
    label: 'Camaieu',
  },
  {
    key: 'Canda',
    label: 'Canda',
  },
  {
    key: 'Colours Of The World',
    label: 'Colours Of The World',
  },
  {
    key: 'Chicoree',
    label: 'Chicoree',
  },
  {
    key: 'Christian Berg',
    label: 'Christian Berg',
  },
  {
    key: 'Charles Vogele',
    label: 'Charles Vogele',
  },
  {
    key: 'C&A',
    label: 'C&A',
  },
  {
    key: 'Cecil',
    label: 'Cecil',
  },
  {
    key: 'Crane',
    label: 'Crane',
  },
  {
    key: 'Calvin Klein',
    label: 'Calvin Klein',
  },
  {
    key: 'Cropp',
    label: 'Cropp',
  },
  {
    key: 'Cache Cache',
    label: 'Cache Cache',
  },
  {
    key: 'Castaner',
    label: 'Castaner',
  },
  {
    key: 'Cop.Copine',
    label: 'Cop.Copine',
  },
  {
    key: 'Country Road',
    label: 'Country Road',
  },
  {
    key: 'Core By Jack & Jones',
    label: 'Core By Jack & Jones',
  },
  {
    key: 'Champion',
    label: 'Champion',
  },
  {
    key: 'Charlotte Russe',
    label: 'Charlotte Russe',
  },
  {
    key: 'Cubus',
    label: 'Cubus',
  },
  {
    key: 'Converse',
    label: 'Converse',
  },
  {
    key: 'Crivit',
    label: 'Crivit',
  },
  {
    key: 'Cream',
    label: 'Cream',
  },
  {
    key: 'Connor',
    label: 'Connor',
  },
  {
    key: 'Cherokee',
    label: 'Cherokee',
  },
  {
    key: 'Columbia',
    label: 'Columbia',
  },
  {
    key: 'Cos',
    label: 'Cos',
  },
  {
    key: "Chico's",
    label: "Chico's",
  },
  {
    key: 'Caroline K Morgan',
    label: 'Caroline K Morgan',
  },
  {
    key: 'Calvin Klein Jeans',
    label: 'Calvin Klein Jeans',
  },
  {
    key: 'Chacok',
    label: 'Chacok',
  },
  {
    key: 'Calliope',
    label: 'Calliope',
  },
  {
    key: 'Cyrillus',
    label: 'Cyrillus',
  },
  {
    key: 'Celio',
    label: 'Celio',
  },
  {
    key: 'Chi Chi',
    label: 'Chi Chi',
  },
  {
    key: 'City Chic',
    label: 'City Chic',
  },
  {
    key: 'Caroll',
    label: 'Caroll',
  },
  {
    key: 'Crazy World',
    label: 'Crazy World',
  },
  {
    key: 'Cinque',
    label: 'Cinque',
  },
  {
    key: 'Casa Blanca',
    label: 'Casa Blanca',
  },
  {
    key: 'Cambio',
    label: 'Cambio',
  },
  {
    key: 'Chattawak',
    label: 'Chattawak',
  },
  {
    key: 'Culture',
    label: 'Culture',
  },
  {
    key: 'Cleo',
    label: 'Cleo',
  },
  {
    key: 'Coast',
    label: 'Coast',
  },
  {
    key: 'Cacharel',
    label: 'Cacharel',
  },
  {
    key: 'Club L',
    label: 'Club L',
  },
  {
    key: 'Chicco',
    label: 'Chicco',
  },
  {
    key: 'Cortefiel',
    label: 'Cortefiel',
  },
  {
    key: 'Camicissima',
    label: 'Camicissima',
  },
  {
    key: 'Carnet De Vol',
    label: 'Carnet De Vol',
  },
  {
    key: 'Comptoir Des Cotonniers',
    label: 'Comptoir Des Cotonniers',
  },
  {
    key: 'Cue',
    label: 'Cue',
  },
  {
    key: 'Chris Line',
    label: 'Chris Line',
  },
  {
    key: 'Capsule',
    label: 'Capsule',
  },
  {
    key: "Candie's",
    label: "Candie's",
  },
  {
    key: 'Closet London',
    label: 'Closet London',
  },
  {
    key: 'Cat & Jack',
    label: 'Cat & Jack',
  },
  {
    key: 'Charles Tyrwhitt',
    label: 'Charles Tyrwhitt',
  },
  {
    key: 'Chiemsee',
    label: 'Chiemsee',
  },
  {
    key: 'Club Monaco',
    label: 'Club Monaco',
  },
  {
    key: 'Cafènoir',
    label: 'Cafènoir',
  },
  {
    key: 'Clarks',
    label: 'Clarks',
  },
  {
    key: 'Closed',
    label: 'Closed',
  },
  {
    key: 'Cars Jeans',
    label: 'Cars Jeans',
  },
  {
    key: 'Cipo & Baxx',
    label: 'Cipo & Baxx',
  },
  {
    key: 'Camel Active',
    label: 'Camel Active',
  },
  {
    key: 'Chantelle',
    label: 'Chantelle',
  },
  {
    key: 'Colmar',
    label: 'Colmar',
  },
  {
    key: 'Carhartt',
    label: 'Carhartt',
  },
  {
    key: 'Carolyn Taylor',
    label: 'Carolyn Taylor',
  },
  {
    key: 'Catwalk',
    label: 'Catwalk',
  },
  {
    key: 'C.Comberti',
    label: 'C.Comberti',
  },
  {
    key: 'Cheer',
    label: 'Cheer',
  },
  {
    key: 'Coline',
    label: 'Coline',
  },
  {
    key: 'Conbipel',
    label: 'Conbipel',
  },
  {
    key: 'Catwalk Junkie',
    label: 'Catwalk Junkie',
  },
  {
    key: 'Cynthia Rowley',
    label: 'Cynthia Rowley',
  },
  {
    key: 'Chillin',
    label: 'Chillin',
  },
  {
    key: 'Chillytime',
    label: 'Chillytime',
  },
  {
    key: 'Crash One',
    label: 'Crash One',
  },
  {
    key: 'Cooper St',
    label: 'Cooper St',
  },
  {
    key: 'Custo Barcelona',
    label: 'Custo Barcelona',
  },
  {
    key: 'Cha Cha',
    label: 'Cha Cha',
  },
  {
    key: 'Camp David',
    label: 'Camp David',
  },
  {
    key: 'Capasca',
    label: 'Capasca',
  },
  {
    key: 'Campus',
    label: 'Campus',
  },
  {
    key: 'Casa Moda',
    label: 'Casa Moda',
  },
  {
    key: 'Creeks',
    label: 'Creeks',
  },
  {
    key: 'Cotton Club',
    label: 'Cotton Club',
  },
  {
    key: 'Caramelo',
    label: 'Caramelo',
  },
  {
    key: 'Creation L',
    label: 'Creation L',
  },
  {
    key: 'Cabi',
    label: 'Cabi',
  },
  {
    key: 'Calzedonia',
    label: 'Calzedonia',
  },
  {
    key: 'Cache',
    label: 'Cache',
  },
  {
    key: 'Cecilia Classics',
    label: 'Cecilia Classics',
  },
  {
    key: 'Cabrini',
    label: 'Cabrini',
  },
  {
    key: 'Cool Cat',
    label: 'Cool Cat',
  },
  {
    key: 'Caroline Morgan',
    label: 'Caroline Morgan',
  },
  {
    key: 'Carl Gross',
    label: 'Carl Gross',
  },
  {
    key: 'Colors Of California',
    label: 'Colors Of California',
  },
  {
    key: 'Creme Fraiche',
    label: 'Creme Fraiche',
  },
  {
    key: 'Claudia Strater',
    label: 'Claudia Strater',
  },
  {
    key: "C'm",
    label: "C'm",
  },
  {
    key: 'Calida',
    label: 'Calida',
  },
  {
    key: 'Cc Fashion',
    label: 'Cc Fashion',
  },
  {
    key: 'Circuit',
    label: 'Circuit',
  },
  {
    key: 'Casual Clothes Company',
    label: 'Casual Clothes Company',
  },
  {
    key: 'Camper',
    label: 'Camper',
  },
  {
    key: 'Custommade',
    label: 'Custommade',
  },
  {
    key: 'Claudie Pierlot',
    label: 'Claudie Pierlot',
  },
  {
    key: 'Cfl Colors For Life',
    label: 'Cfl Colors For Life',
  },
  {
    key: 'Chilli',
    label: 'Chilli',
  },
  {
    key: 'Crosshatch',
    label: 'Crosshatch',
  },
  {
    key: 'Castro',
    label: 'Castro',
  },
  {
    key: 'Clique',
    label: 'Clique',
  },
  {
    key: 'Cristina Gavioli',
    label: 'Cristina Gavioli',
  },
  {
    key: 'Carole Little',
    label: 'Carole Little',
  },
  {
    key: 'Crafted',
    label: 'Crafted',
  },
  {
    key: 'Chervo',
    label: 'Chervo',
  },
  {
    key: 'Caractere',
    label: 'Caractere',
  },
  {
    key: 'Casual Clothing',
    label: 'Casual Clothing',
  },
  {
    key: 'Cato',
    label: 'Cato',
  },
  {
    key: 'Cpm Collection',
    label: 'Cpm Collection',
  },
  {
    key: 'Cyrus',
    label: 'Cyrus',
  },
  {
    key: 'Cameo Rose',
    label: 'Cameo Rose',
  },
  {
    key: 'Candy',
    label: 'Candy',
  },
  {
    key: 'Cellbes',
    label: 'Cellbes',
  },
  {
    key: 'Commander',
    label: 'Commander',
  },
  {
    key: 'Candies',
    label: 'Candies',
  },
  {
    key: 'Coster Copenhagen.',
    label: 'Coster Copenhagen.',
  },
  {
    key: 'Coach',
    label: 'Coach',
  },
  {
    key: 'Candy Couture',
    label: 'Candy Couture',
  },
  {
    key: 'Crisca',
    label: 'Crisca',
  },
  {
    key: 'Charles & Keith',
    label: 'Charles & Keith',
  },
  {
    key: 'Cerruti 1881',
    label: 'Cerruti 1881',
  },
  {
    key: 'Cars',
    label: 'Cars',
  },
  {
    key: 'Choise',
    label: 'Choise',
  },
  {
    key: 'Ccdk',
    label: 'Ccdk',
  },
  {
    key: 'Cassidi',
    label: 'Cassidi',
  },
  {
    key: 'Cuddl Duds',
    label: 'Cuddl Duds',
  },
  {
    key: 'C/Meo Collective',
    label: 'C/Meo Collective',
  },
  {
    key: 'Camaieu',
    label: 'Camaieu',
  },
  {
    key: 'Carla F.',
    label: 'Carla F.',
  },
  {
    key: 'Carla Du Nord',
    label: 'Carla Du Nord',
  },
  {
    key: 'Cable & Gauge',
    label: 'Cable & Gauge',
  },
  {
    key: 'Carrera',
    label: 'Carrera',
  },
  {
    key: 'Cyell',
    label: 'Cyell',
  },
  {
    key: 'Celsius',
    label: 'Celsius',
  },
  {
    key: 'Chicc',
    label: 'Chicc',
  },
  {
    key: 'Connected',
    label: 'Connected',
  },
  {
    key: "Co'couture",
    label: "Co'couture",
  },
  {
    key: 'Craghoppers',
    label: 'Craghoppers',
  },
  {
    key: 'Carna By',
    label: 'Carna By',
  },
  {
    key: 'Catimini',
    label: 'Catimini',
  },
  {
    key: 'Centigrade',
    label: 'Centigrade',
  },
  {
    key: 'Cedric Charlier X La Redoute',
    label: 'Cedric Charlier X La Redoute',
  },
  {
    key: 'Camomilla',
    label: 'Camomilla',
  },
  {
    key: 'Carry',
    label: 'Carry',
  },
  {
    key: 'Cedar Wood State',
    label: 'Cedar Wood State',
  },
  {
    key: 'Celestino',
    label: 'Celestino',
  },
  {
    key: 'Cremieux',
    label: 'Cremieux',
  },
  {
    key: 'Carole Hochman',
    label: 'Carole Hochman',
  },
  {
    key: 'Cafe Coton',
    label: 'Cafe Coton',
  },
  {
    key: 'Canali',
    label: 'Canali',
  },
  {
    key: 'Canada House',
    label: 'Canada House',
  },
  {
    key: 'Chalice',
    label: 'Chalice',
  },
  {
    key: 'Catherine Malandrino',
    label: 'Catherine Malandrino',
  },
  {
    key: 'Colours & Sons',
    label: 'Colours & Sons',
  },
  {
    key: 'Chevignon',
    label: 'Chevignon',
  },
  {
    key: "Club D'amingo",
    label: "Club D'amingo",
  },
  {
    key: 'Cecilie Copenhagen',
    label: 'Cecilie Copenhagen',
  },
  {
    key: 'Catamaran',
    label: 'Catamaran',
  },
  {
    key: 'Compagnia Italiana',
    label: 'Compagnia Italiana',
  },
  {
    key: 'Carven',
    label: 'Carven',
  },
  {
    key: 'Charbell',
    label: 'Charbell',
  },
  {
    key: 'C.A.R.O',
    label: 'C.A.R.O',
  },
  {
    key: 'Cosmos',
    label: 'Cosmos',
  },
  {
    key: 'Copenhagen Luxe',
    label: 'Copenhagen Luxe',
  },
  {
    key: 'Cassani',
    label: 'Cassani',
  },
  {
    key: 'Chloé',
    label: 'Chloé',
  },
  {
    key: 'Cloud Chaser',
    label: 'Cloud Chaser',
  },
  {
    key: 'Cotton Emporium',
    label: 'Cotton Emporium',
  },
  {
    key: 'Citizens Of Humanity',
    label: 'Citizens Of Humanity',
  },
  {
    key: 'Container',
    label: 'Container',
  },
  {
    key: 'Cool Water',
    label: 'Cool Water',
  },
  {
    key: 'Concept K',
    label: 'Concept K',
  },
  {
    key: 'Carlo Colucci',
    label: 'Carlo Colucci',
  },
  {
    key: 'Canyon',
    label: 'Canyon',
  },
  {
    key: 'Chillaz',
    label: 'Chillaz',
  },
  {
    key: 'Cutter & Buck',
    label: 'Cutter & Buck',
  },
  {
    key: 'C&S',
    label: 'C&S',
  },
  {
    key: 'Cavaliere',
    label: 'Cavaliere',
  },
  {
    key: "Campus By Marc O'polo",
    label: "Campus By Marc O'polo",
  },
  {
    key: 'Cassidi Boutique',
    label: 'Cassidi Boutique',
  },
  {
    key: 'Cole Haan',
    label: 'Cole Haan',
  },
  {
    key: 'C.Ro',
    label: 'C.Ro',
  },
  {
    key: 'Camel',
    label: 'Camel',
  },
  {
    key: 'Christ',
    label: 'Christ',
  },
  {
    key: 'Coop',
    label: 'Coop',
  },
  {
    key: 'Cashmere',
    label: 'Cashmere',
  },
  {
    key: 'Comptoir Des Créations',
    label: 'Comptoir Des Créations',
  },
  {
    key: 'Clarina Collection',
    label: 'Clarina Collection',
  },
  {
    key: 'Carling',
    label: 'Carling',
  },
  {
    key: 'Caramel',
    label: 'Caramel',
  },
  {
    key: 'Carmen',
    label: 'Carmen',
  },
  {
    key: 'Carolina K Morgan',
    label: 'Carolina K Morgan',
  },
  {
    key: 'Call It Spring',
    label: 'Call It Spring',
  },
  {
    key: 'Chantall',
    label: 'Chantall',
  },
  {
    key: 'Crazy Horse',
    label: 'Crazy Horse',
  },
  {
    key: 'Claire.Dk',
    label: 'Claire.Dk',
  },
  {
    key: 'Carla',
    label: 'Carla',
  },
  {
    key: 'Caribbean Joe',
    label: 'Caribbean Joe',
  },
  {
    key: 'Concept Uk',
    label: 'Concept Uk',
  },
  {
    key: 'Ccg Perfect',
    label: 'Ccg Perfect',
  },
  {
    key: 'Canterbury',
    label: 'Canterbury',
  },
  {
    key: 'Caroline Biss',
    label: 'Caroline Biss',
  },
  {
    key: 'Cc',
    label: 'Cc',
  },
  {
    key: 'Cathrine Hammel',
    label: 'Cathrine Hammel',
  },
  {
    key: 'Cinzia Rocca',
    label: 'Cinzia Rocca',
  },
  {
    key: 'Campri',
    label: 'Campri',
  },
  {
    key: 'Cool Girl',
    label: 'Cool Girl',
  },
  {
    key: 'Crosby',
    label: 'Crosby',
  },
  {
    key: 'Cox Swain',
    label: 'Cox Swain',
  },
  {
    key: 'C.I.C.',
    label: 'C.I.C.',
  },
  {
    key: "C'n'c' Costume National",
    label: "C'n'c' Costume National",
  },
  {
    key: 'Casablanca',
    label: 'Casablanca',
  },
  {
    key: 'Cerruti',
    label: 'Cerruti',
  },
  {
    key: 'Chic Et Jeune',
    label: 'Chic Et Jeune',
  },
  {
    key: 'Carnet',
    label: 'Carnet',
  },
  {
    key: 'Camaro',
    label: 'Camaro',
  },
  {
    key: 'Charlise',
    label: 'Charlise',
  },
  {
    key: 'Casall',
    label: 'Casall',
  },
  {
    key: 'Clements Ribeiro',
    label: 'Clements Ribeiro',
  },
  {
    key: 'Clara Vitti',
    label: 'Clara Vitti',
  },
  {
    key: 'Casual By Gemo',
    label: 'Casual By Gemo',
  },
  {
    key: 'Carducci',
    label: 'Carducci',
  },
  {
    key: 'Charlie Jade',
    label: 'Charlie Jade',
  },
  {
    key: 'Caddis Fly',
    label: 'Caddis Fly',
  },
  {
    key: 'Calamar',
    label: 'Calamar',
  },
  {
    key: 'Clayeux',
    label: 'Clayeux',
  },
  {
    key: 'Cox',
    label: 'Cox',
  },
  {
    key: 'Conte Of Florence',
    label: 'Conte Of Florence',
  },
  {
    key: 'Celyn B.',
    label: 'Celyn B.',
  },
  {
    key: 'Cycle',
    label: 'Cycle',
  },
  {
    key: 'Carla Carini',
    label: 'Carla Carini',
  },
  {
    key: 'Caprice',
    label: 'Caprice',
  },
  {
    key: 'Cybele',
    label: 'Cybele',
  },
  {
    key: 'Canyon River',
    label: 'Canyon River',
  },
  {
    key: 'Catullus',
    label: 'Catullus',
  },
  {
    key: 'C&C',
    label: 'C&C',
  },
  {
    key: 'Cibyll',
    label: 'Cibyll',
  },
  {
    key: 'Capsize',
    label: 'Capsize',
  },
  {
    key: 'Chadwicks',
    label: 'Chadwicks',
  },
  {
    key: 'Chaloc',
    label: 'Chaloc',
  },
  {
    key: 'Carlisle',
    label: 'Carlisle',
  },
  {
    key: 'Casual Land',
    label: 'Casual Land',
  },
  {
    key: 'Cult',
    label: 'Cult',
  },
  {
    key: 'Coccinelle',
    label: 'Coccinelle',
  },
  {
    key: 'Caramella Fashion',
    label: 'Caramella Fashion',
  },
  {
    key: 'Colonial',
    label: 'Colonial',
  },
  {
    key: 'Caren Pfleger',
    label: 'Caren Pfleger',
  },
  {
    key: 'Capricio',
    label: 'Capricio',
  },
  {
    key: 'Caprisa',
    label: 'Caprisa',
  },
  {
    key: 'Caspar David',
    label: 'Caspar David',
  },
  {
    key: 'Casual',
    label: 'Casual',
  },
  {
    key: 'Carlo Cecci',
    label: 'Carlo Cecci',
  },
  {
    key: 'Cygnus',
    label: 'Cygnus',
  },
  {
    key: 'C.M.D',
    label: 'C.M.D',
  },
  {
    key: 'Cowboysbag',
    label: 'Cowboysbag',
  },
  {
    key: 'Cherry Lane',
    label: 'Cherry Lane',
  },
  {
    key: 'City Walk',
    label: 'City Walk',
  },
  {
    key: 'California',
    label: 'California',
  },
  {
    key: 'Clement',
    label: 'Clement',
  },
  {
    key: 'Calinkalin',
    label: 'Calinkalin',
  },
  {
    key: 'Cabrelli',
    label: 'Cabrelli',
  },
  {
    key: 'Charlie Paige',
    label: 'Charlie Paige',
  },
  {
    key: 'Chicwish',
    label: 'Chicwish',
  },
  {
    key: 'Cite',
    label: 'Cite',
  },
  {
    key: 'Canvas',
    label: 'Canvas',
  },
  {
    key: 'Colcci',
    label: 'Colcci',
  },
  {
    key: 'Caliban',
    label: 'Caliban',
  },
  {
    key: 'Classics',
    label: 'Classics',
  },
  {
    key: 'Current/Elliott',
    label: 'Current/Elliott',
  },
  {
    key: 'Conbipel',
    label: 'Conbipel',
  },
  {
    key: 'C.A.&E.C.',
    label: 'C.A.&E.C.',
  },
  {
    key: 'Celina',
    label: 'Celina',
  },
  {
    key: 'Celebrity Pink',
    label: 'Celebrity Pink',
  },
  {
    key: 'Catch One',
    label: 'Catch One',
  },
  {
    key: 'Carisma',
    label: 'Carisma',
  },
  {
    key: 'Cinnamon',
    label: 'Cinnamon',
  },
  {
    key: 'Capri Collection',
    label: 'Capri Collection',
  },
  {
    key: 'Crea Concept',
    label: 'Crea Concept',
  },
  {
    key: 'Ciso',
    label: 'Ciso',
  },
  {
    key: 'Cyf',
    label: 'Cyf',
  },
  {
    key: 'Ciao Milano',
    label: 'Ciao Milano',
  },
  {
    key: 'Coogi',
    label: 'Coogi',
  },
  {
    key: 'Central Park West',
    label: 'Central Park West',
  },
  {
    key: 'Cc&Dd',
    label: 'Cc&Dd',
  },
  {
    key: 'Cutie',
    label: 'Cutie',
  },
  {
    key: 'Cloudveil',
    label: 'Cloudveil',
  },
  {
    key: 'Cellini Collection',
    label: 'Cellini Collection',
  },
  {
    key: 'Classic Tricot',
    label: 'Classic Tricot',
  },
  {
    key: 'Carvela',
    label: 'Carvela',
  },
  {
    key: 'Carla Ferroni',
    label: 'Carla Ferroni',
  },
  {
    key: 'C-In-C',
    label: 'C-In-C',
  },
  {
    key: 'Charanga',
    label: 'Charanga',
  },
  {
    key: 'Celine',
    label: 'Celine',
  },
  {
    key: 'Calore',
    label: 'Calore',
  },
  {
    key: 'Cristina Nichita',
    label: 'Cristina Nichita',
  },
  {
    key: 'Corleone',
    label: 'Corleone',
  },
  {
    key: 'Comfort',
    label: 'Comfort',
  },
  {
    key: 'Chilli Pepper',
    label: 'Chilli Pepper',
  },
  {
    key: 'Corneliani',
    label: 'Corneliani',
  },
  {
    key: 'Charlotte',
    label: 'Charlotte',
  },
  {
    key: 'Camp',
    label: 'Camp',
  },
  {
    key: 'Cernold Palmer',
    label: 'Cernold Palmer',
  },
  {
    key: 'Cappopera',
    label: 'Cappopera',
  },
  {
    key: 'Claudia Gil',
    label: 'Claudia Gil',
  },
  {
    key: 'Cameo',
    label: 'Cameo',
  },
  {
    key: 'Cecilia Benetti',
    label: 'Cecilia Benetti',
  },
  {
    key: 'Cejon',
    label: 'Cejon',
  },
  {
    key: 'Cut Loose',
    label: 'Cut Loose',
  },
  {
    key: 'Cachet',
    label: 'Cachet',
  },
  {
    key: 'Carina Ricci',
    label: 'Carina Ricci',
  },
  {
    key: 'Coccapani',
    label: 'Coccapani',
  },
  {
    key: 'Camera',
    label: 'Camera',
  },
  {
    key: 'C-F 2',
    label: 'C-F 2',
  },
  {
    key: 'Casual Woman',
    label: 'Casual Woman',
  },
  {
    key: 'Carmen Marc Valvo',
    label: 'Carmen Marc Valvo',
  },
  {
    key: 'Carminn',
    label: 'Carminn',
  },
  {
    key: 'Charget',
    label: 'Charget',
  },
  {
    key: 'Cristian Lay',
    label: 'Cristian Lay',
  },
  {
    key: 'Capelli',
    label: 'Capelli',
  },
  {
    key: 'C.W.K. Fashion',
    label: 'C.W.K. Fashion',
  },
  {
    key: 'Cordon',
    label: 'Cordon',
  },
  {
    key: 'Cloanice',
    label: 'Cloanice',
  },
  {
    key: 'Celaia',
    label: 'Celaia',
  },
  {
    key: 'Cichlid',
    label: 'Cichlid',
  },
  {
    key: 'Carla Degen',
    label: 'Carla Degen',
  },
  {
    key: 'C-Girl',
    label: 'C-Girl',
  },
  {
    key: 'Carolina Herrera',
    label: 'Carolina Herrera',
  },
  {
    key: 'Comeor',
    label: 'Comeor',
  },
  {
    key: 'Catch Me...',
    label: 'Catch Me...',
  },
  {
    key: 'Chi',
    label: 'Chi',
  },
  {
    key: 'Carla Giannini',
    label: 'Carla Giannini',
  },
  {
    key: 'Caribbean Queen',
    label: 'Caribbean Queen',
  },
  {
    key: "C'magnifique",
    label: "C'magnifique",
  },
  {
    key: "C'kenza",
    label: "C'kenza",
  },
  {
    key: 'Casual Collection',
    label: 'Casual Collection',
  },
  {
    key: 'Crombie',
    label: 'Crombie',
  },
  {
    key: 'Ccm',
    label: 'Ccm',
  },
  {
    key: 'Chapter Club',
    label: 'Chapter Club',
  },
  {
    key: 'Caribbean',
    label: 'Caribbean',
  },
  {
    key: 'Candice Cooper',
    label: 'Candice Cooper',
  },
  {
    key: 'Colours Of Africa By Tcm',
    label: 'Colours Of Africa By Tcm',
  },
  {
    key: 'Colmar Originals',
    label: 'Colmar Originals',
  },
  {
    key: 'Caterina',
    label: 'Caterina',
  },
  {
    key: 'Circa',
    label: 'Circa',
  },
  {
    key: 'Coin 1804',
    label: 'Coin 1804',
  },
  {
    key: 'C-Brand',
    label: 'C-Brand',
  },
  {
    key: 'Cavita',
    label: 'Cavita',
  },
  {
    key: 'Calania',
    label: 'Calania',
  },
  {
    key: 'Clowse',
    label: 'Clowse',
  },
  {
    key: 'Carla Faustini',
    label: 'Carla Faustini',
  },
  {
    key: 'Cypress',
    label: 'Cypress',
  },
  {
    key: 'Cat',
    label: 'Cat',
  },
  {
    key: 'Conscions Collection',
    label: 'Conscions Collection',
  },
  {
    key: 'Copacabana',
    label: 'Copacabana',
  },
  {
    key: 'Cromia',
    label: 'Cromia',
  },
  {
    key: 'Chic Lady',
    label: 'Chic Lady',
  },
  {
    key: 'Century',
    label: 'Century',
  },
  {
    key: 'C.C.D.K',
    label: 'C.C.D.K',
  },
  {
    key: 'Charlize',
    label: 'Charlize',
  },
  {
    key: 'Cynthia Steffe',
    label: 'Cynthia Steffe',
  },
  {
    key: 'Case Logic',
    label: 'Case Logic',
  },
  {
    key: "C'fait Pour Vous",
    label: "C'fait Pour Vous",
  },
  {
    key: 'Comme Du Vrai',
    label: 'Comme Du Vrai',
  },
  {
    key: 'Carla Ferreri',
    label: 'Carla Ferreri',
  },
  {
    key: 'Camouflage Ar And J.',
    label: 'Camouflage Ar And J.',
  },
  {
    key: 'Cappuccino',
    label: 'Cappuccino',
  },
  {
    key: 'Contemporaine',
    label: 'Contemporaine',
  },
  {
    key: 'Cathy Daniels',
    label: 'Cathy Daniels',
  },
  {
    key: 'Cecilia',
    label: 'Cecilia',
  },
  {
    key: 'Credo',
    label: 'Credo',
  },
  {
    key: 'Cink Me',
    label: 'Cink Me',
  },
  {
    key: 'Conrad C',
    label: 'Conrad C',
  },
  {
    key: 'Chic',
    label: 'Chic',
  },
  {
    key: 'Censured',
    label: 'Censured',
  },
  {
    key: 'Campagnolo',
    label: 'Campagnolo',
  },
  {
    key: 'Christian Audigier',
    label: 'Christian Audigier',
  },
  {
    key: 'Casual Essentials',
    label: 'Casual Essentials',
  },
  {
    key: 'Caprice De Fille',
    label: 'Caprice De Fille',
  },
  {
    key: 'Chic & Jeune',
    label: 'Chic & Jeune',
  },
  {
    key: 'Carla C',
    label: 'Carla C',
  },
  {
    key: 'Calabria',
    label: 'Calabria',
  },
  {
    key: 'Cappellini By Peserico',
    label: 'Cappellini By Peserico',
  },
  {
    key: 'Chillipop',
    label: 'Chillipop',
  },
  {
    key: 'Cristhelen B.',
    label: 'Cristhelen B.',
  },
  {
    key: 'Canadian Classics',
    label: 'Canadian Classics',
  },
  {
    key: 'Carlo',
    label: 'Carlo',
  },
  {
    key: 'Catania',
    label: 'Catania',
  },
  {
    key: 'Carolin Vanity',
    label: 'Carolin Vanity',
  },
  {
    key: 'Carolina Cavour',
    label: 'Carolina Cavour',
  },
  {
    key: 'C.D.C.',
    label: 'C.D.C.',
  },
  {
    key: 'Cotélac',
    label: 'Cotélac',
  },
  {
    key: 'Christian Laurier',
    label: 'Christian Laurier',
  },
  {
    key: 'Casual X',
    label: 'Casual X',
  },
  {
    key: 'Carnival Kids',
    label: 'Carnival Kids',
  },
  {
    key: 'Change',
    label: 'Change',
  },
  {
    key: 'Cara',
    label: 'Cara',
  },
  {
    key: 'Cora Kemperman',
    label: 'Cora Kemperman',
  },
  {
    key: 'Closet Blu',
    label: 'Closet Blu',
  },
  {
    key: 'Chic Fille',
    label: 'Chic Fille',
  },
  {
    key: 'Candida',
    label: 'Candida',
  },
  {
    key: 'Cactus',
    label: 'Cactus',
  },
  {
    key: 'Cavallaro Napoli',
    label: 'Cavallaro Napoli',
  },
  {
    key: 'Cc Double O',
    label: 'Cc Double O',
  },
  {
    key: 'Cannella',
    label: 'Cannella',
  },
  {
    key: 'Cougar',
    label: 'Cougar',
  },
  {
    key: 'Coofandy',
    label: 'Coofandy',
  },
  {
    key: 'C`Arla Du Nord',
    label: 'C`Arla Du Nord',
  },
  {
    key: 'Charlie Brown',
    label: 'Charlie Brown',
  },
  {
    key: 'Christie Brooks',
    label: 'Christie Brooks',
  },
  {
    key: 'Canard',
    label: 'Canard',
  },
  {
    key: 'Christian Dior',
    label: 'Christian Dior',
  },
  {
    key: 'Cafone',
    label: 'Cafone',
  },
  {
    key: 'Casamia',
    label: 'Casamia',
  },
  {
    key: 'Corso Como',
    label: 'Corso Como',
  },
  {
    key: 'Cabin',
    label: 'Cabin',
  },
  {
    key: 'Cloche',
    label: 'Cloche',
  },
  {
    key: 'Covered By Bandolera',
    label: 'Covered By Bandolera',
  },
  {
    key: 'Cavori',
    label: 'Cavori',
  },
  {
    key: 'C.O.L.Z.A.',
    label: 'C.O.L.Z.A.',
  },
  {
    key: 'Capt. Scott',
    label: 'Capt. Scott',
  },
  {
    key: 'C.Luce',
    label: 'C.Luce',
  },
  {
    key: 'Cabo',
    label: 'Cabo',
  },
  {
    key: 'Charlotte Halton',
    label: 'Charlotte Halton',
  },
  {
    key: 'Cajun',
    label: 'Cajun',
  },
  {
    key: 'Casual By Halens',
    label: 'Casual By Halens',
  },
  {
    key: 'Canetti',
    label: 'Canetti',
  },
  {
    key: 'Cubavera',
    label: 'Cubavera',
  },
  {
    key: 'Catherine Andre',
    label: 'Catherine Andre',
  },
  {
    key: 'Carla Kops',
    label: 'Carla Kops',
  },
  {
    key: 'Capito',
    label: 'Capito',
  },
  {
    key: 'Casual Trend',
    label: 'Casual Trend',
  },
  {
    key: 'Crazy Shirts',
    label: 'Crazy Shirts',
  },
  {
    key: 'Collistar',
    label: 'Collistar',
  },
  {
    key: 'Croft',
    label: 'Croft',
  },
  {
    key: 'Christina Gavioli',
    label: 'Christina Gavioli',
  },
  {
    key: 'C.O.C',
    label: 'C.O.C',
  },
  {
    key: 'Creation Stummer',
    label: 'Creation Stummer',
  },
  {
    key: 'Cute Well',
    label: 'Cute Well',
  },
  {
    key: 'Clara Barson',
    label: 'Clara Barson',
  },
  {
    key: 'Capri Style',
    label: 'Capri Style',
  },
  {
    key: 'Calvin',
    label: 'Calvin',
  },
  {
    key: 'Charles And A Half',
    label: 'Charles And A Half',
  },
  {
    key: 'Camaleonte',
    label: 'Camaleonte',
  },
  {
    key: 'Cx',
    label: 'Cx',
  },
  {
    key: 'Cashmere E Seta',
    label: 'Cashmere E Seta',
  },
  {
    key: 'Canada Goose',
    label: 'Canada Goose',
  },
  {
    key: 'Condici',
    label: 'Condici',
  },
  {
    key: 'Carry Allen',
    label: 'Carry Allen',
  },
  {
    key: 'Calvin Cooper',
    label: 'Calvin Cooper',
  },
  {
    key: 'C&H',
    label: 'C&H',
  },
  {
    key: 'Carve',
    label: 'Carve',
  },
  {
    key: 'Cable Car',
    label: 'Cable Car',
  },
  {
    key: 'Cherry Stix',
    label: 'Cherry Stix',
  },
  {
    key: 'Catmandoo',
    label: 'Catmandoo',
  },
  {
    key: 'Classic Fashion',
    label: 'Classic Fashion',
  },
  {
    key: 'Chester Barrie',
    label: 'Chester Barrie',
  },
  {
    key: 'Cake Walk',
    label: 'Cake Walk',
  },
  {
    key: 'Colebrook & Co.',
    label: 'Colebrook & Co.',
  },
  {
    key: 'Caraway',
    label: 'Caraway',
  },
  {
    key: 'Castella',
    label: 'Castella',
  },
  {
    key: 'Creme De Silk',
    label: 'Creme De Silk',
  },
  {
    key: 'Crews',
    label: 'Crews',
  },
  {
    key: 'Chicago',
    label: 'Chicago',
  },
  {
    key: 'Chic Dress',
    label: 'Chic Dress',
  },
  {
    key: 'Cd Jeans',
    label: 'Cd Jeans',
  },
  {
    key: 'Campari',
    label: 'Campari',
  },
  {
    key: 'Castello',
    label: 'Castello',
  },
  {
    key: 'Commander',
    label: 'Commander',
  },
  {
    key: 'Code',
    label: 'Code',
  },
  {
    key: 'Camps',
    label: 'Camps',
  },
  {
    key: 'Cjc',
    label: 'Cjc',
  },
  {
    key: 'Cbrl',
    label: 'Cbrl',
  },
  {
    key: 'Catbalou',
    label: 'Catbalou',
  },
  {
    key: 'Cs Sport',
    label: 'Cs Sport',
  },
  {
    key: 'Carla Conti',
    label: 'Carla Conti',
  },
  {
    key: 'Cbc',
    label: 'Cbc',
  },
  {
    key: 'Carra',
    label: 'Carra',
  },
  {
    key: 'Clementijn',
    label: 'Clementijn',
  },
  {
    key: 'Carla G.',
    label: 'Carla G.',
  },
  {
    key: 'Chica Booti',
    label: 'Chica Booti',
  },
  {
    key: 'Coofit',
    label: 'Coofit',
  },
  {
    key: 'Celia Birtwell',
    label: 'Celia Birtwell',
  },
  {
    key: 'Clarks Originals',
    label: 'Clarks Originals',
  },
  {
    key: 'Chiara Forthi',
    label: 'Chiara Forthi',
  },
  {
    key: 'Cerrer',
    label: 'Cerrer',
  },
  {
    key: 'Chiara Ambra',
    label: 'Chiara Ambra',
  },
  {
    key: 'Cactus Clone',
    label: 'Cactus Clone',
  },
  {
    key: 'Csbl',
    label: 'Csbl',
  },
  {
    key: 'Chiemgau',
    label: 'Chiemgau',
  },
  {
    key: 'Cristina Effe',
    label: 'Cristina Effe',
  },
  {
    key: 'Chantal Rosner',
    label: 'Chantal Rosner',
  },
  {
    key: 'Covert Overt',
    label: 'Covert Overt',
  },
  {
    key: 'Chic Girl',
    label: 'Chic Girl',
  },
  {
    key: 'Cross & Winsor',
    label: 'Cross & Winsor',
  },
  {
    key: 'Circus',
    label: 'Circus',
  },
  {
    key: 'Cult Edition',
    label: 'Cult Edition',
  },
  {
    key: 'Classic',
    label: 'Classic',
  },
  {
    key: 'C.A.M.',
    label: 'C.A.M.',
  },
  {
    key: 'Carinii',
    label: 'Carinii',
  },
  {
    key: 'Clifford & Willis',
    label: 'Clifford & Willis',
  },
  {
    key: 'Chic Chic',
    label: 'Chic Chic',
  },
  {
    key: 'Coast+Weber+Ahaus',
    label: 'Coast+Weber+Ahaus',
  },
  {
    key: 'Caramasov',
    label: 'Caramasov',
  },
  {
    key: 'Cc Resorts',
    label: 'Cc Resorts',
  },
  {
    key: 'Cantarelli',
    label: 'Cantarelli',
  },
  {
    key: 'Camargue',
    label: 'Camargue',
  },
  {
    key: 'Cotton Ginny',
    label: 'Cotton Ginny',
  },
  {
    key: 'Cannondale',
    label: 'Cannondale',
  },
  {
    key: 'Charlie Allen',
    label: 'Charlie Allen',
  },
  {
    key: 'Caparol',
    label: 'Caparol',
  },
  {
    key: 'Calvin Klein Collection',
    label: 'Calvin Klein Collection',
  },
  {
    key: 'Coveted',
    label: 'Coveted',
  },
  {
    key: 'Carbrini',
    label: 'Carbrini',
  },
  {
    key: 'Clark Ross',
    label: 'Clark Ross',
  },
  {
    key: 'Cam',
    label: 'Cam',
  },
  {
    key: 'Classiques Entier',
    label: 'Classiques Entier',
  },
  {
    key: 'Casuals',
    label: 'Casuals',
  },
  {
    key: 'Cloth & Stone',
    label: 'Cloth & Stone',
  },
  {
    key: 'Calypso',
    label: 'Calypso',
  },
  {
    key: 'Caitlyn',
    label: 'Caitlyn',
  },
  {
    key: 'Coccara',
    label: 'Coccara',
  },
  {
    key: 'C.F.K',
    label: 'C.F.K',
  },
  {
    key: 'Compagnia Della Moda',
    label: 'Compagnia Della Moda',
  },
  {
    key: 'Carryland',
    label: 'Carryland',
  },
  {
    key: "Cent's",
    label: "Cent's",
  },
  {
    key: 'Cama',
    label: 'Cama',
  },
  {
    key: 'Charles',
    label: 'Charles',
  },
  {
    key: 'Chie Mihara',
    label: 'Chie Mihara',
  },
  {
    key: 'Cyberdog',
    label: 'Cyberdog',
  },
  {
    key: 'Cashmere - Feeling',
    label: 'Cashmere - Feeling',
  },
  {
    key: 'Casual Cx',
    label: 'Casual Cx',
  },
  {
    key: 'Cashmere Feel',
    label: 'Cashmere Feel',
  },
  {
    key: 'Ch',
    label: 'Ch',
  },
  {
    key: "C'bon C'bon",
    label: "C'bon C'bon",
  },
  {
    key: 'Cc Petite',
    label: 'Cc Petite',
  },
  {
    key: 'C&L',
    label: 'C&L',
  },
  {
    key: 'Club Xprs',
    label: 'Club Xprs',
  },
  {
    key: 'Cashmere Collection',
    label: 'Cashmere Collection',
  },
  {
    key: 'Chilong',
    label: 'Chilong',
  },
  {
    key: 'C.Matteo',
    label: 'C.Matteo',
  },
  {
    key: 'Chalet',
    label: 'Chalet',
  },
  {
    key: 'Cedric Charlier',
    label: 'Cedric Charlier',
  },
  {
    key: 'Carole Richard',
    label: 'Carole Richard',
  },
  {
    key: 'Cotton',
    label: 'Cotton',
  },
  {
    key: 'Cassera',
    label: 'Cassera',
  },
  {
    key: 'Carolina Boix',
    label: 'Carolina Boix',
  },
  {
    key: 'Comme Des Garcons',
    label: 'Comme Des Garcons',
  },
  {
    key: 'Clips',
    label: 'Clips',
  },
  {
    key: 'C.M.P.',
    label: 'C.M.P.',
  },
  {
    key: 'Casucci',
    label: 'Casucci',
  },
  {
    key: 'Corena',
    label: 'Corena',
  },
  {
    key: 'Cristiano Fissore',
    label: 'Cristiano Fissore',
  },
  {
    key: 'Cardinal',
    label: 'Cardinal',
  },
  {
    key: 'Cyprea',
    label: 'Cyprea',
  },
  {
    key: 'Cara Lotti',
    label: 'Cara Lotti',
  },
  {
    key: 'Classics & More',
    label: 'Classics & More',
  },
  {
    key: 'Camille',
    label: 'Camille',
  },
  {
    key: 'Casual Comfort',
    label: 'Casual Comfort',
  },
  {
    key: 'Caipirinha',
    label: 'Caipirinha',
  },
  {
    key: 'Casual Select',
    label: 'Casual Select',
  },
  {
    key: 'Chili',
    label: 'Chili',
  },
  {
    key: 'Cashott',
    label: 'Cashott',
  },
  {
    key: 'Cuckoo',
    label: 'Cuckoo',
  },
  {
    key: 'Casual Release',
    label: 'Casual Release',
  },
  {
    key: 'Caporiccio',
    label: 'Caporiccio',
  },
  {
    key: 'Citron',
    label: 'Citron',
  },
  {
    key: 'Charles Wilson',
    label: 'Charles Wilson',
  },
  {
    key: 'Cushen',
    label: 'Cushen',
  },
  {
    key: 'Cyc',
    label: 'Cyc',
  },
  {
    key: 'Chivas',
    label: 'Chivas',
  },
  {
    key: 'Carla B.',
    label: 'Carla B.',
  },
  {
    key: 'Cashmink',
    label: 'Cashmink',
  },
  {
    key: 'Cesca',
    label: 'Cesca',
  },
  {
    key: 'Chb',
    label: 'Chb',
  },
  {
    key: 'Colin Stuart',
    label: 'Colin Stuart',
  },
  {
    key: 'Cdm',
    label: 'Cdm',
  },
  {
    key: 'Crfc',
    label: 'Crfc',
  },
  {
    key: 'Camilla Thulin',
    label: 'Camilla Thulin',
  },
  {
    key: 'Collection By George',
    label: 'Collection By George',
  },
  {
    key: 'Carlo Comberti',
    label: 'Carlo Comberti',
  },
  {
    key: 'Cinema Donna',
    label: 'Cinema Donna',
  },
  {
    key: 'Camelot',
    label: 'Camelot',
  },
  {
    key: 'Chic By Cellbes',
    label: 'Chic By Cellbes',
  },
  {
    key: 'Charles Robertson',
    label: 'Charles Robertson',
  },
  {
    key: 'Cdc Fashion',
    label: 'Cdc Fashion',
  },
  {
    key: 'Corey Lynn Calter',
    label: 'Corey Lynn Calter',
  },
  {
    key: 'Cassidy',
    label: 'Cassidy',
  },
  {
    key: "C'est Parts",
    label: "C'est Parts",
  },
  {
    key: 'Cloudstyle',
    label: 'Cloudstyle',
  },
  {
    key: 'Carmakoma',
    label: 'Carmakoma',
  },
  {
    key: 'Casual Style',
    label: 'Casual Style',
  },
  {
    key: 'Cristina Rej',
    label: 'Cristina Rej',
  },
  {
    key: 'Care Label',
    label: 'Care Label',
  },
  {
    key: 'Creative Recreation',
    label: 'Creative Recreation',
  },
  {
    key: 'Casual Man',
    label: 'Casual Man',
  },
  {
    key: 'Cero Jeans',
    label: 'Cero Jeans',
  },
  {
    key: "Carla D'angeli",
    label: "Carla D'angeli",
  },
  {
    key: 'Chams Denim',
    label: 'Chams Denim',
  },
  {
    key: 'Chill Factor',
    label: 'Chill Factor',
  },
  {
    key: 'Cavalier',
    label: 'Cavalier',
  },
  {
    key: 'Christian Lacroix',
    label: 'Christian Lacroix',
  },
  {
    key: 'Cecil Mcbee',
    label: 'Cecil Mcbee',
  },
  {
    key: 'Capstan Bay',
    label: 'Capstan Bay',
  },
  {
    key: 'Cotton Republic',
    label: 'Cotton Republic',
  },
  {
    key: 'Castello Donna',
    label: 'Castello Donna',
  },
  {
    key: 'Carlo Pignatelli',
    label: 'Carlo Pignatelli',
  },
  {
    key: 'Cycle Jeans',
    label: 'Cycle Jeans',
  },
  {
    key: 'Classic Sport',
    label: 'Classic Sport',
  },
  {
    key: 'Chillnorway',
    label: 'Chillnorway',
  },
  {
    key: 'Collezione Alessandro',
    label: 'Collezione Alessandro',
  },
  {
    key: 'C.C',
    label: 'C.C',
  },
  {
    key: 'Canto',
    label: 'Canto',
  },
  {
    key: 'Chico Clothing',
    label: 'Chico Clothing',
  },
  {
    key: 'Casttelli',
    label: 'Casttelli',
  },
  {
    key: 'Cby',
    label: 'Cby',
  },
  {
    key: 'Clover Canyon',
    label: 'Clover Canyon',
  },
  {
    key: 'Charles Jourdan',
    label: 'Charles Jourdan',
  },
  {
    key: 'Chicca',
    label: 'Chicca',
  },
  {
    key: 'Cape Horn',
    label: 'Cape Horn',
  },
  {
    key: 'Candy Crush',
    label: 'Candy Crush',
  },
  {
    key: 'Carverace',
    label: 'Carverace',
  },
  {
    key: 'Changes By Together',
    label: 'Changes By Together',
  },
  {
    key: 'Cape Juby',
    label: 'Cape Juby',
  },
  {
    key: 'Claudio Conti',
    label: 'Claudio Conti',
  },
  {
    key: 'Cashmere & Wool',
    label: 'Cashmere & Wool',
  },
  {
    key: 'Carite',
    label: 'Carite',
  },
  {
    key: 'Champro Sports',
    label: 'Champro Sports',
  },
  {
    key: 'Cashmere Blend',
    label: 'Cashmere Blend',
  },
  {
    key: 'City Sport',
    label: 'City Sport',
  },
  {
    key: 'C.M.P.55',
    label: 'C.M.P.55',
  },
  {
    key: 'Claudia Schiffer',
    label: 'Claudia Schiffer',
  },
  {
    key: 'Casadei',
    label: 'Casadei',
  },
  {
    key: 'Cjf',
    label: 'Cjf',
  },
  {
    key: 'Caleidos',
    label: 'Caleidos',
  },
  {
    key: 'Cadet Rousselle',
    label: 'Cadet Rousselle',
  },
  {
    key: 'Cailina',
    label: 'Cailina',
  },
  {
    key: 'Cool Running',
    label: 'Cool Running',
  },
  {
    key: 'Caan',
    label: 'Caan',
  },
  {
    key: 'Chic A Boon',
    label: 'Chic A Boon',
  },
  {
    key: 'Cmyk',
    label: 'Cmyk',
  },
  {
    key: "Cassis Cote D'azur",
    label: "Cassis Cote D'azur",
  },
  {
    key: 'Cabano',
    label: 'Cabano',
  },
  {
    key: 'Caliban Rue De Mathieu Edition',
    label: 'Caliban Rue De Mathieu Edition',
  },
  {
    key: 'Cali',
    label: 'Cali',
  },
  {
    key: 'Cafe Theatre',
    label: 'Cafe Theatre',
  },
  {
    key: 'Chokolate',
    label: 'Chokolate',
  },
  {
    key: 'Christopher Blue',
    label: 'Christopher Blue',
  },
  {
    key: 'Callaway Golf',
    label: 'Callaway Golf',
  },
  {
    key: 'Calla',
    label: 'Calla',
  },
  {
    key: 'Cbmm',
    label: 'Cbmm',
  },
  {
    key: 'Couchel',
    label: 'Couchel',
  },
  {
    key: 'Caterpillar',
    label: 'Caterpillar',
  },
  {
    key: 'Cullen',
    label: 'Cullen',
  },
  {
    key: 'Campomaggi',
    label: 'Campomaggi',
  },
  {
    key: 'Carletti',
    label: 'Carletti',
  },
  {
    key: 'Cesare Paciotti',
    label: 'Cesare Paciotti',
  },
  {
    key: 'C-Ro',
    label: 'C-Ro',
  },
  {
    key: 'C&M',
    label: 'C&M',
  },
  {
    key: 'C&J',
    label: 'C&J',
  },
  {
    key: 'Coccodrillo',
    label: 'Coccodrillo',
  },
  {
    key: 'Cose Cosi',
    label: 'Cose Cosi',
  },
  {
    key: 'Cruciani',
    label: 'Cruciani',
  },
  {
    key: 'Comfort Colors',
    label: 'Comfort Colors',
  },
  {
    key: 'Comfort Fashion',
    label: 'Comfort Fashion',
  },
  {
    key: 'Christian Troelstrup',
    label: 'Christian Troelstrup',
  },
  {
    key: 'Cas 8',
    label: 'Cas 8',
  },
  {
    key: 'Charisma',
    label: 'Charisma',
  },
  {
    key: "C'est Moi",
    label: "C'est Moi",
  },
  {
    key: 'Crew Clothing Co.',
    label: 'Crew Clothing Co.',
  },
  {
    key: 'Colette',
    label: 'Colette',
  },
  {
    key: 'Conto Bene',
    label: 'Conto Bene',
  },
  {
    key: 'Circle Of Gentlemen',
    label: 'Circle Of Gentlemen',
  },
  {
    key: 'Cats',
    label: 'Cats',
  },
  {
    key: 'Costume National',
    label: 'Costume National',
  },
  {
    key: 'Cane & Cane',
    label: 'Cane & Cane',
  },
  {
    key: 'Center Coat',
    label: 'Center Coat',
  },
  {
    key: 'Carla Carla',
    label: 'Carla Carla',
  },
  {
    key: 'Carbone',
    label: 'Carbone',
  },
  {
    key: 'Christina',
    label: 'Christina',
  },
  {
    key: 'Camilla',
    label: 'Camilla',
  },
  {
    key: 'Chiara Bini',
    label: 'Chiara Bini',
  },
  {
    key: 'Comfitts',
    label: 'Comfitts',
  },
  {
    key: 'Carpisa',
    label: 'Carpisa',
  },
  {
    key: 'Carlo Gaggioni',
    label: 'Carlo Gaggioni',
  },
  {
    key: 'C&D',
    label: 'C&D',
  },
  {
    key: 'City Fellaz',
    label: 'City Fellaz',
  },
  {
    key: 'Capelli New York',
    label: 'Capelli New York',
  },
  {
    key: 'C.O.V.E.R',
    label: 'C.O.V.E.R',
  },
  {
    key: 'Chanel',
    label: 'Chanel',
  },
  {
    key: 'Crowncap',
    label: 'Crowncap',
  },
  {
    key: 'Carell Thomas',
    label: 'Carell Thomas',
  },
  {
    key: 'Chillouts',
    label: 'Chillouts',
  },
  {
    key: 'Chalc',
    label: 'Chalc',
  },
  {
    key: 'Clarins',
    label: 'Clarins',
  },
  {
    key: 'Chicaprie',
    label: 'Chicaprie',
  },
  {
    key: 'C/Fit',
    label: 'C/Fit',
  },
  {
    key: 'Caretec',
    label: 'Caretec',
  },
  {
    key: 'Cat Style',
    label: 'Cat Style',
  },
  {
    key: 'Cbn',
    label: 'Cbn',
  },
  {
    key: 'Chiara',
    label: 'Chiara',
  },
  {
    key: 'Casual Corner',
    label: 'Casual Corner',
  },
  {
    key: "Claire's",
    label: "Claire's",
  },
  {
    key: 'Call Of Duty',
    label: 'Call Of Duty',
  },
  {
    key: 'Conti',
    label: 'Conti',
  },
  {
    key: 'Casual & Co',
    label: 'Casual & Co',
  },
  {
    key: 'Classic Collection',
    label: 'Classic Collection',
  },
  {
    key: 'Clu',
    label: 'Clu',
  },
  {
    key: 'Cutting Edge',
    label: 'Cutting Edge',
  },
  {
    key: 'Cashmere Et Soie',
    label: 'Cashmere Et Soie',
  },
  {
    key: 'Claudio Ferrici',
    label: 'Claudio Ferrici',
  },
  {
    key: 'Clwr',
    label: 'Clwr',
  },
  {
    key: 'Chickster',
    label: 'Chickster',
  },
  {
    key: 'Criss Cross',
    label: 'Criss Cross',
  },
  {
    key: 'Char',
    label: 'Char',
  },
  {
    key: 'Camicetta Snob',
    label: 'Camicetta Snob',
  },
  {
    key: 'Cantarana',
    label: 'Cantarana',
  },
  {
    key: 'Clic !',
    label: 'Clic !',
  },
  {
    key: 'C&Z',
    label: 'C&Z',
  },
  {
    key: 'Contony',
    label: 'Contony',
  },
  {
    key: 'C.P. Company',
    label: 'C.P. Company',
  },
  {
    key: 'Coastal',
    label: 'Coastal',
  },
  {
    key: 'Cnc Costume National',
    label: 'Cnc Costume National',
  },
  {
    key: 'Can Do',
    label: 'Can Do',
  },
  {
    key: 'Cachou',
    label: 'Cachou',
  },
  {
    key: 'Calvipiel',
    label: 'Calvipiel',
  },
  {
    key: 'Caren Sport',
    label: 'Caren Sport',
  },
  {
    key: 'Caroline',
    label: 'Caroline',
  },
  {
    key: 'Carol Rose',
    label: 'Carol Rose',
  },
  {
    key: 'Claude Gerard',
    label: 'Claude Gerard',
  },
  {
    key: 'Charivari',
    label: 'Charivari',
  },
  {
    key: 'Challenger',
    label: 'Challenger',
  },
  {
    key: 'Charles Muller',
    label: 'Charles Muller',
  },
  {
    key: 'Comme Ca Ism',
    label: 'Comme Ca Ism',
  },
  {
    key: 'Cs Outdoor',
    label: 'Cs Outdoor',
  },
  {
    key: 'Coincidence & Chance',
    label: 'Coincidence & Chance',
  },
  {
    key: 'Calw',
    label: 'Calw',
  },
  {
    key: 'Clc',
    label: 'Clc',
  },
  {
    key: 'Charlior',
    label: 'Charlior',
  },
  {
    key: 'Campia Moda',
    label: 'Campia Moda',
  },
  {
    key: 'Casual Gear',
    label: 'Casual Gear',
  },
  {
    key: 'Cachemire',
    label: 'Cachemire',
  },
  {
    key: 'Christian Siriano',
    label: 'Christian Siriano',
  },
  {
    key: 'Calando',
    label: 'Calando',
  },
  {
    key: 'Cbk',
    label: 'Cbk',
  },
  {
    key: 'C&U',
    label: 'C&U',
  },
  {
    key: 'Comfort Plus',
    label: 'Comfort Plus',
  },
  {
    key: 'Chesterfield',
    label: 'Chesterfield',
  },
  {
    key: 'Capezio',
    label: 'Capezio',
  },
  {
    key: 'Carlo Manzoni',
    label: 'Carlo Manzoni',
  },
  {
    key: 'Cividini',
    label: 'Cividini',
  },
  {
    key: 'Cushion Walk',
    label: 'Cushion Walk',
  },
  {
    key: 'Co2',
    label: 'Co2',
  },
  {
    key: 'Capo',
    label: 'Capo',
  },
  {
    key: 'Celebry Tees',
    label: 'Celebry Tees',
  },
  {
    key: 'Celcius',
    label: 'Celcius',
  },
  {
    key: 'Cello',
    label: 'Cello',
  },
  {
    key: 'Chikiwi',
    label: 'Chikiwi',
  },
  {
    key: 'Cabaneli',
    label: 'Cabaneli',
  },
  {
    key: 'Carnoustie',
    label: 'Carnoustie',
  },
  {
    key: 'Chic & Mit',
    label: 'Chic & Mit',
  },
  {
    key: 'Casual Club',
    label: 'Casual Club',
  },
  {
    key: 'Chief',
    label: 'Chief',
  },
  {
    key: 'Claude Vigo',
    label: 'Claude Vigo',
  },
  {
    key: 'Celeste By Lematex',
    label: 'Celeste By Lematex',
  },
  {
    key: 'Cs Active',
    label: 'Cs Active',
  },
  {
    key: 'Casual Collections By Debenhams',
    label: 'Casual Collections By Debenhams',
  },
  {
    key: 'Charlie Joe',
    label: 'Charlie Joe',
  },
  {
    key: 'California Westcoast',
    label: 'California Westcoast',
  },
  {
    key: 'Clocolor',
    label: 'Clocolor',
  },
  {
    key: 'Cast Iron',
    label: 'Cast Iron',
  },
  {
    key: 'Como N 1',
    label: 'Como N 1',
  },
  {
    key: 'Coco Cabana',
    label: 'Coco Cabana',
  },
  {
    key: 'C&X',
    label: 'C&X',
  },
  {
    key: 'Clay',
    label: 'Clay',
  },
  {
    key: 'Cc Fontana',
    label: 'Cc Fontana',
  },
  {
    key: 'Cassiopee',
    label: 'Cassiopee',
  },
  {
    key: 'Crown & Ivy',
    label: 'Crown & Ivy',
  },
  {
    key: 'Carla Fasini',
    label: 'Carla Fasini',
  },
  {
    key: 'Casa Lee',
    label: 'Casa Lee',
  },
  {
    key: 'Charlotte Sparre',
    label: 'Charlotte Sparre',
  },
  {
    key: 'Canadians',
    label: 'Canadians',
  },
  {
    key: 'Carre Rouge',
    label: 'Carre Rouge',
  },
  {
    key: 'Candy Flowers',
    label: 'Candy Flowers',
  },
  {
    key: 'Calibrate',
    label: 'Calibrate',
  },
  {
    key: 'Carolina',
    label: 'Carolina',
  },
  {
    key: 'Cold Method',
    label: 'Cold Method',
  },
  {
    key: 'Cps',
    label: 'Cps',
  },
  {
    key: 'Cgl Sports',
    label: 'Cgl Sports',
  },
  {
    key: 'Charlotte M',
    label: 'Charlotte M',
  },
  {
    key: 'Castelli',
    label: 'Castelli',
  },
  {
    key: 'Child Face',
    label: 'Child Face',
  },
  {
    key: 'Cruel Girl',
    label: 'Cruel Girl',
  },
  {
    key: 'Cdv',
    label: 'Cdv',
  },
  {
    key: 'Christian Louboutin',
    label: 'Christian Louboutin',
  },
  {
    key: 'Chaps By Ralph Lauren',
    label: 'Chaps By Ralph Lauren',
  },
  {
    key: 'Chic Star',
    label: 'Chic Star',
  },
  {
    key: 'Calyx',
    label: 'Calyx',
  },
  {
    key: 'Carabou',
    label: 'Carabou',
  },
  {
    key: 'Casmani',
    label: 'Casmani',
  },
  {
    key: 'Calia By Carrie Underwood',
    label: 'Calia By Carrie Underwood',
  },
  {
    key: 'Cfk',
    label: 'Cfk',
  },
  {
    key: 'Cyberg Wear',
    label: 'Cyberg Wear',
  },
  {
    key: 'Canon',
    label: 'Canon',
  },
  {
    key: 'Cellophane',
    label: 'Cellophane',
  },
  {
    key: 'Colebrook',
    label: 'Colebrook',
  },
  {
    key: 'Cacao',
    label: 'Cacao',
  },
  {
    key: 'Castiglioni',
    label: 'Castiglioni',
  },
  {
    key: 'Cartier',
    label: 'Cartier',
  },
  {
    key: 'Cheroy',
    label: 'Cheroy',
  },
  {
    key: 'Carroll Reed',
    label: 'Carroll Reed',
  },
  {
    key: 'Cristina Mazzonetto',
    label: 'Cristina Mazzonetto',
  },
  {
    key: 'Cashmasoft',
    label: 'Cashmasoft',
  },
  {
    key: 'Chacha',
    label: 'Chacha',
  },
  {
    key: 'Cqcoquetton',
    label: 'Cqcoquetton',
  },
  {
    key: 'Class Roberto Cavalli',
    label: 'Class Roberto Cavalli',
  },
  {
    key: 'Desigual',
    label: 'Desigual',
  },
  {
    key: 'Dorothy Perkins',
    label: 'Dorothy Perkins',
  },
  {
    key: 'Daniel Hechter',
    label: 'Daniel Hechter',
  },
  {
    key: 'Diesel',
    label: 'Diesel',
  },
  {
    key: 'Dotti',
    label: 'Dotti',
  },
  {
    key: 'Dakine',
    label: 'Dakine',
  },
  {
    key: 'Drykorn For Beautiful People',
    label: 'Drykorn For Beautiful People',
  },
  {
    key: 'Dogo',
    label: 'Dogo',
  },
  {
    key: 'Disney',
    label: 'Disney',
  },
  {
    key: 'Dockers',
    label: 'Dockers',
  },
  {
    key: 'Dondup',
    label: 'Dondup',
  },
  {
    key: 'Dressbarn',
    label: 'Dressbarn',
  },
  {
    key: 'Dkny',
    label: 'Dkny',
  },
  {
    key: 'Decjuba',
    label: 'Decjuba',
  },
  {
    key: 'Damart',
    label: 'Damart',
  },
  {
    key: 'Dynamite',
    label: 'Dynamite',
  },
  {
    key: 'Derek Heart',
    label: 'Derek Heart',
  },
  {
    key: 'Denim&Co.',
    label: 'Denim&Co.',
  },
  {
    key: 'Devernois',
    label: 'Devernois',
  },
  {
    key: 'Diadora',
    label: 'Diadora',
  },
  {
    key: 'Dressmann',
    label: 'Dressmann',
  },
  {
    key: 'Day Birger Et Mikkelsen',
    label: 'Day Birger Et Mikkelsen',
  },
  {
    key: 'Denny Rose',
    label: 'Denny Rose',
  },
  {
    key: 'David Lawrence',
    label: 'David Lawrence',
  },
  {
    key: 'Dsquared2',
    label: 'Dsquared2',
  },
  {
    key: 'Dkny Jeans',
    label: 'Dkny Jeans',
  },
  {
    key: 'Driver',
    label: 'Driver',
  },
  {
    key: 'Derhy',
    label: 'Derhy',
  },
  {
    key: 'De.Corp By Esprit',
    label: 'De.Corp By Esprit',
  },
  {
    key: 'Dc Shoes',
    label: 'Dc Shoes',
  },
  {
    key: 'David Jones',
    label: 'David Jones',
  },
  {
    key: 'Debenhams',
    label: 'Debenhams',
  },
  {
    key: 'Dreimaster',
    label: 'Dreimaster',
  },
  {
    key: 'Delia*S',
    label: 'Delia*S',
  },
  {
    key: 'Designers Remix By Charlotte Eskildsen',
    label: 'Designers Remix By Charlotte Eskildsen',
  },
  {
    key: 'Dstrezzed',
    label: 'Dstrezzed',
  },
  {
    key: 'Danier',
    label: 'Danier',
  },
  {
    key: 'Dalia',
    label: 'Dalia',
  },
  {
    key: 'Days Like This',
    label: 'Days Like This',
  },
  {
    key: 'Designers Remix',
    label: 'Designers Remix',
  },
  {
    key: 'Diana Ferrari',
    label: 'Diana Ferrari',
  },
  {
    key: 'Dranella',
    label: 'Dranella',
  },
  {
    key: 'Deerberg',
    label: 'Deerberg',
  },
  {
    key: 'Diesel Black Gold',
    label: 'Diesel Black Gold',
  },
  {
    key: 'Denham',
    label: 'Denham',
  },
  {
    key: 'Double Zero',
    label: 'Double Zero',
  },
  {
    key: 'Dnn',
    label: 'Dnn',
  },
  {
    key: 'Dorothee Schumacher',
    label: 'Dorothee Schumacher',
  },
  {
    key: 'Do & Be',
    label: 'Do & Be',
  },
  {
    key: 'Dare 2b',
    label: 'Dare 2b',
  },
  {
    key: 'Diane Von Furstenberg',
    label: 'Diane Von Furstenberg',
  },
  {
    key: 'Dolce & Gabbana',
    label: 'Dolce & Gabbana',
  },
  {
    key: 'Dibari',
    label: 'Dibari',
  },
  {
    key: 'Dolce Vita',
    label: 'Dolce Vita',
  },
  {
    key: 'Domyos',
    label: 'Domyos',
  },
  {
    key: 'Duke Of Dunkan',
    label: 'Duke Of Dunkan',
  },
  {
    key: 'Dd-Ii',
    label: 'Dd-Ii',
  },
  {
    key: 'Digel',
    label: 'Digel',
  },
  {
    key: 'Dreamstar',
    label: 'Dreamstar',
  },
  {
    key: 'Despicable Me',
    label: 'Despicable Me',
  },
  {
    key: 'Dino Moda',
    label: 'Dino Moda',
  },
  {
    key: 'Daisy Street',
    label: 'Daisy Street',
  },
  {
    key: "Denizen From Levi's",
    label: "Denizen From Levi's",
  },
  {
    key: 'Dognose',
    label: 'Dognose',
  },
  {
    key: 'Dunnes Stores',
    label: 'Dunnes Stores',
  },
  {
    key: 'Daks',
    label: 'Daks',
  },
  {
    key: 'Danini',
    label: 'Danini',
  },
  {
    key: 'Dateless',
    label: 'Dateless',
  },
  {
    key: 'Dknyc',
    label: 'Dknyc',
  },
  {
    key: 'Donna',
    label: 'Donna',
  },
  {
    key: 'Delmod',
    label: 'Delmod',
  },
  {
    key: 'Diana Gallesi',
    label: 'Diana Gallesi',
  },
  {
    key: 'Donna Karan',
    label: 'Donna Karan',
  },
  {
    key: 'Donna Loka',
    label: 'Donna Loka',
  },
  {
    key: 'Dance & Marvel',
    label: 'Dance & Marvel',
  },
  {
    key: 'Dna',
    label: 'Dna',
  },
  {
    key: 'Daniele Alessandrini',
    label: 'Daniele Alessandrini',
  },
  {
    key: 'Divina',
    label: 'Divina',
  },
  {
    key: 'Dirkje',
    label: 'Dirkje',
  },
  {
    key: 'De Fursac',
    label: 'De Fursac',
  },
  {
    key: 'Double Two',
    label: 'Double Two',
  },
  {
    key: 'Dockers By Gerli',
    label: 'Dockers By Gerli',
  },
  {
    key: 'Daite',
    label: 'Daite',
  },
  {
    key: 'Daily',
    label: 'Daily',
  },
  {
    key: 'Descente',
    label: 'Descente',
  },
  {
    key: 'Del Mare 1911',
    label: 'Del Mare 1911',
  },
  {
    key: 'D.Exterior',
    label: 'D.Exterior',
  },
  {
    key: 'Dirk Bikkembergs',
    label: 'Dirk Bikkembergs',
  },
  {
    key: 'D.Jeans',
    label: 'D.Jeans',
  },
  {
    key: 'Dries Van Noten',
    label: 'Dries Van Noten',
  },
  {
    key: 'Dkny Active',
    label: 'Dkny Active',
  },
  {
    key: 'Dekker',
    label: 'Dekker',
  },
  {
    key: 'Dannii Minogue',
    label: 'Dannii Minogue',
  },
  {
    key: "Dr. Scholl's",
    label: "Dr. Scholl's",
  },
  {
    key: 'Desch',
    label: 'Desch',
  },
  {
    key: 'Des Petits Hauts',
    label: 'Des Petits Hauts',
  },
  {
    key: 'Diesel Kids',
    label: 'Diesel Kids',
  },
  {
    key: "David's Bridal",
    label: "David's Bridal",
  },
  {
    key: 'Dixie',
    label: 'Dixie',
  },
  {
    key: 'Danni Minogue',
    label: 'Danni Minogue',
  },
  {
    key: 'Dimensions',
    label: 'Dimensions',
  },
  {
    key: 'Dqmane',
    label: 'Dqmane',
  },
  {
    key: 'Denim Studio',
    label: 'Denim Studio',
  },
  {
    key: 'Depot 96',
    label: 'Depot 96',
  },
  {
    key: 'Distortion',
    label: 'Distortion',
  },
  {
    key: 'Dash',
    label: 'Dash',
  },
  {
    key: 'Didier Parakian',
    label: 'Didier Parakian',
  },
  {
    key: 'Dreams',
    label: 'Dreams',
  },
  {
    key: 'Dea Divina',
    label: 'Dea Divina',
  },
  {
    key: 'David Warren',
    label: 'David Warren',
  },
  {
    key: 'D.D. Style',
    label: 'D.D. Style',
  },
  {
    key: 'Dream Out Loud By Selena Gomez',
    label: 'Dream Out Loud By Selena Gomez',
  },
  {
    key: 'Deichgraf',
    label: 'Deichgraf',
  },
  {
    key: 'Dunhill',
    label: 'Dunhill',
  },
  {
    key: 'Db Established 1962',
    label: 'Db Established 1962',
  },
  {
    key: 'David Meister',
    label: 'David Meister',
  },
  {
    key: 'Di Roberto',
    label: 'Di Roberto',
  },
  {
    key: 'Dainese',
    label: 'Dainese',
  },
  {
    key: 'Dress V',
    label: 'Dress V',
  },
  {
    key: 'Duscha',
    label: 'Duscha',
  },
  {
    key: 'Damsel In A Dress',
    label: 'Damsel In A Dress',
  },
  {
    key: 'Daniel Cremieux',
    label: 'Daniel Cremieux',
  },
  {
    key: 'Dismero',
    label: 'Dismero',
  },
  {
    key: 'Doma',
    label: 'Doma',
  },
  {
    key: 'Darccy',
    label: 'Darccy',
  },
  {
    key: 'Dolly',
    label: 'Dolly',
  },
  {
    key: 'Dale Of Norway',
    label: 'Dale Of Norway',
  },
  {
    key: 'Dreimaster',
    label: 'Dreimaster',
  },
  {
    key: 'Dr. Martens',
    label: 'Dr. Martens',
  },
  {
    key: 'Duchamp',
    label: 'Duchamp',
  },
  {
    key: "Draper's And Damon's",
    label: "Draper's And Damon's",
  },
  {
    key: 'Didriksons 1913',
    label: 'Didriksons 1913',
  },
  {
    key: 'Dl 1961',
    label: 'Dl 1961',
  },
  {
    key: 'Doris Hartwich',
    label: 'Doris Hartwich',
  },
  {
    key: 'Daniele Fiesoli',
    label: 'Daniele Fiesoli',
  },
  {
    key: 'Dakini',
    label: 'Dakini',
  },
  {
    key: 'Dkny Pure',
    label: 'Dkny Pure',
  },
  {
    key: 'Diana',
    label: 'Diana',
  },
  {
    key: 'Delsey',
    label: 'Delsey',
  },
  {
    key: 'Drago&Rado',
    label: 'Drago&Rado',
  },
  {
    key: 'Dissimio',
    label: 'Dissimio',
  },
  {
    key: 'Dorothee Vogel',
    label: 'Dorothee Vogel',
  },
  {
    key: 'Donar',
    label: 'Donar',
  },
  {
    key: 'Dakfrogs',
    label: 'Dakfrogs',
  },
  {
    key: 'Dessy Collection',
    label: 'Dessy Collection',
  },
  {
    key: 'David Donahue',
    label: 'David Donahue',
  },
  {
    key: 'Diabless',
    label: 'Diabless',
  },
  {
    key: 'Don Gil',
    label: 'Don Gil',
  },
  {
    key: 'Dawn Levy',
    label: 'Dawn Levy',
  },
  {
    key: 'Denim 73',
    label: 'Denim 73',
  },
  {
    key: 'Dear Cashmere',
    label: 'Dear Cashmere',
  },
  {
    key: 'Deviluse',
    label: 'Deviluse',
  },
  {
    key: 'Django & Juliette',
    label: 'Django & Juliette',
  },
  {
    key: 'Duvetica',
    label: 'Duvetica',
  },
  {
    key: 'Donna Carolina',
    label: 'Donna Carolina',
  },
  {
    key: 'David Mayer',
    label: 'David Mayer',
  },
  {
    key: 'Dunlop Golf',
    label: 'Dunlop Golf',
  },
  {
    key: 'Dance',
    label: 'Dance',
  },
  {
    key: 'Doppia Corsia',
    label: 'Doppia Corsia',
  },
  {
    key: 'Dita',
    label: 'Dita',
  },
  {
    key: 'Dirita Life',
    label: 'Dirita Life',
  },
  {
    key: 'Dissima',
    label: 'Dissima',
  },
  {
    key: 'Drop Dead',
    label: 'Drop Dead',
  },
  {
    key: 'Delvaux',
    label: 'Delvaux',
  },
  {
    key: 'David & Young',
    label: 'David & Young',
  },
  {
    key: 'Dissimia',
    label: 'Dissimia',
  },
  {
    key: 'Dr Keller',
    label: 'Dr Keller',
  },
  {
    key: 'D Klick',
    label: 'D Klick',
  },
  {
    key: 'Denim Demon',
    label: 'Denim Demon',
  },
  {
    key: 'De Kuba',
    label: 'De Kuba',
  },
  {
    key: 'Dinsko',
    label: 'Dinsko',
  },
  {
    key: 'Djeannie',
    label: 'Djeannie',
  },
  {
    key: 'Di Fontana',
    label: 'Di Fontana',
  },
  {
    key: 'Des Fashion',
    label: 'Des Fashion',
  },
  {
    key: 'Denim Seventy Three',
    label: 'Denim Seventy Three',
  },
  {
    key: 'Decky',
    label: 'Decky',
  },
  {
    key: 'Della Ciana',
    label: 'Della Ciana',
  },
  {
    key: 'Daniel Springs',
    label: 'Daniel Springs',
  },
  {
    key: 'Delphine Manivet',
    label: 'Delphine Manivet',
  },
  {
    key: 'Dolly And Dotty',
    label: 'Dolly And Dotty',
  },
  {
    key: 'Development',
    label: 'Development',
  },
  {
    key: 'Dom',
    label: 'Dom',
  },
  {
    key: 'Daughters Of Eve',
    label: 'Daughters Of Eve',
  },
  {
    key: 'Dana Kay',
    label: 'Dana Kay',
  },
  {
    key: 'Donald J Pliner',
    label: 'Donald J Pliner',
  },
  {
    key: 'Diverso',
    label: 'Diverso',
  },
  {
    key: "Don't Believe The Hype",
    label: "Don't Believe The Hype",
  },
  {
    key: 'Denis Simachev',
    label: 'Denis Simachev',
  },
  {
    key: 'Daniel Valentin',
    label: 'Daniel Valentin',
  },
  {
    key: 'Decauville',
    label: 'Decauville',
  },
  {
    key: 'Derbe Hamburg',
    label: 'Derbe Hamburg',
  },
  {
    key: 'D.Co',
    label: 'D.Co',
  },
  {
    key: 'Disney By Kenvelo',
    label: 'Disney By Kenvelo',
  },
  {
    key: 'Denyl',
    label: 'Denyl',
  },
  {
    key: 'Dior',
    label: 'Dior',
  },
  {
    key: 'Distiva',
    label: 'Distiva',
  },
  {
    key: 'David Mayer Naman',
    label: 'David Mayer Naman',
  },
  {
    key: 'D&G',
    label: 'D&G',
  },
  {
    key: 'Daniel Benjamin',
    label: 'Daniel Benjamin',
  },
  {
    key: 'Da Vinci',
    label: 'Da Vinci',
  },
  {
    key: 'Dragon',
    label: 'Dragon',
  },
  {
    key: 'Dkode',
    label: 'Dkode',
  },
  {
    key: 'De Laru',
    label: 'De Laru',
  },
  {
    key: 'Donna Di Gio',
    label: 'Donna Di Gio',
  },
  {
    key: 'Dina Be',
    label: 'Dina Be',
  },
  {
    key: 'Dcore',
    label: 'Dcore',
  },
  {
    key: 'Deacon',
    label: 'Deacon',
  },
  {
    key: 'Dolan',
    label: 'Dolan',
  },
  {
    key: 'D&G Junior',
    label: 'D&G Junior',
  },
  {
    key: 'Dr Denim',
    label: 'Dr Denim',
  },
  {
    key: 'Dressystar',
    label: 'Dressystar',
  },
  {
    key: 'Dfb',
    label: 'Dfb',
  },
  {
    key: 'Dc Comics',
    label: 'Dc Comics',
  },
  {
    key: 'Dimensione Danza Sisters',
    label: 'Dimensione Danza Sisters',
  },
  {
    key: 'Doncaster',
    label: 'Doncaster',
  },
  {
    key: "D'she",
    label: "D'she",
  },
  {
    key: 'Dolce & Bella',
    label: 'Dolce & Bella',
  },
  {
    key: 'Di Stefano',
    label: 'Di Stefano',
  },
  {
    key: 'Danniel Brunni',
    label: 'Danniel Brunni',
  },
  {
    key: 'Doll & Frogo',
    label: 'Doll & Frogo',
  },
  {
    key: "Dek'her",
    label: "Dek'her",
  },
  {
    key: 'Denim & Flower',
    label: 'Denim & Flower',
  },
  {
    key: 'Dolce & Rosa',
    label: 'Dolce & Rosa',
  },
  {
    key: 'Dalbello',
    label: 'Dalbello',
  },
  {
    key: 'Dalmine',
    label: 'Dalmine',
  },
  {
    key: 'Daniel Ray',
    label: 'Daniel Ray',
  },
  {
    key: 'Denim Day',
    label: 'Denim Day',
  },
  {
    key: 'Detlev Albers',
    label: 'Detlev Albers',
  },
  {
    key: 'Diesel X Adidas',
    label: 'Diesel X Adidas',
  },
  {
    key: 'David Andersen',
    label: 'David Andersen',
  },
  {
    key: 'Derek Lam',
    label: 'Derek Lam',
  },
  {
    key: 'Danpaisi',
    label: 'Danpaisi',
  },
  {
    key: 'Down To Earth',
    label: 'Down To Earth',
  },
  {
    key: 'Dmj Fashion',
    label: 'Dmj Fashion',
  },
  {
    key: 'Dormeuil',
    label: 'Dormeuil',
  },
  {
    key: 'Donna Enrica',
    label: 'Donna Enrica',
  },
  {
    key: 'Dea Kudibal',
    label: 'Dea Kudibal',
  },
  {
    key: 'Dynam Jeans',
    label: 'Dynam Jeans',
  },
  {
    key: 'Daughters Of The Liberation',
    label: 'Daughters Of The Liberation',
  },
  {
    key: 'Davos Gomma',
    label: 'Davos Gomma',
  },
  {
    key: 'Delicate Love',
    label: 'Delicate Love',
  },
  {
    key: 'Dh3',
    label: 'Dh3',
  },
  {
    key: 'Dorndorf',
    label: 'Dorndorf',
  },
  {
    key: 'Deska E Libera',
    label: 'Deska E Libera',
  },
  {
    key: 'Dolcis',
    label: 'Dolcis',
  },
  {
    key: 'Destroy',
    label: 'Destroy',
  },
  {
    key: 'Deesse',
    label: 'Deesse',
  },
  {
    key: 'Dey',
    label: 'Dey',
  },
  {
    key: 'Dorothy Blue',
    label: 'Dorothy Blue',
  },
  {
    key: 'Dooney & Bourke',
    label: 'Dooney & Bourke',
  },
  {
    key: 'Delicate Man',
    label: 'Delicate Man',
  },
  {
    key: 'Dagmar',
    label: 'Dagmar',
  },
  {
    key: 'Deutscher Fussball-Bund',
    label: 'Deutscher Fussball-Bund',
  },
  {
    key: 'Danielle Nicole',
    label: 'Danielle Nicole',
  },
  {
    key: 'Daris',
    label: 'Daris',
  },
  {
    key: 'Denim Licious',
    label: 'Denim Licious',
  },
  {
    key: 'Deuter',
    label: 'Deuter',
  },
  {
    key: 'D/C Jeans',
    label: 'D/C Jeans',
  },
  {
    key: 'Dvb By Victoria Beckham',
    label: 'Dvb By Victoria Beckham',
  },
  {
    key: 'Dittos',
    label: 'Dittos',
  },
  {
    key: 'Dlynr',
    label: 'Dlynr',
  },
  {
    key: 'Duca Del Cosma',
    label: 'Duca Del Cosma',
  },
  {
    key: 'Esprit',
    label: 'Esprit',
  },
  {
    key: 'Even&Odd',
    label: 'Even&Odd',
  },
  {
    key: 'Edc By Esprit',
    label: 'Edc By Esprit',
  },
  {
    key: 'Esmara',
    label: 'Esmara',
  },
  {
    key: 'Etam',
    label: 'Etam',
  },
  {
    key: 'Express',
    label: 'Express',
  },
  {
    key: 'Eleven Paris',
    label: 'Eleven Paris',
  },
  {
    key: 'Edited',
    label: 'Edited',
  },
  {
    key: 'Emporio Armani',
    label: 'Emporio Armani',
  },
  {
    key: 'Eterna',
    label: 'Eterna',
  },
  {
    key: 'Eddie Bauer',
    label: 'Eddie Bauer',
  },
  {
    key: 'Et Compagnie By Women Dept',
    label: 'Et Compagnie By Women Dept',
  },
  {
    key: 'Evans',
    label: 'Evans',
  },
  {
    key: 'Ellesse',
    label: 'Ellesse',
  },
  {
    key: 'Ellos',
    label: 'Ellos',
  },
  {
    key: 'Erima',
    label: 'Erima',
  },
  {
    key: 'Escada',
    label: 'Escada',
  },
  {
    key: 'Essentials By Tchibo',
    label: 'Essentials By Tchibo',
  },
  {
    key: 'Ellen Amber',
    label: 'Ellen Amber',
  },
  {
    key: 'Ellen Tracy',
    label: 'Ellen Tracy',
  },
  {
    key: 'Essentiel',
    label: 'Essentiel',
  },
  {
    key: 'Expresso',
    label: 'Expresso',
  },
  {
    key: 'Esmara By Heidi Klum',
    label: 'Esmara By Heidi Klum',
  },
  {
    key: 'Express Design Studio',
    label: 'Express Design Studio',
  },
  {
    key: 'Explorer',
    label: 'Explorer',
  },
  {
    key: 'Envie De Fraise',
    label: 'Envie De Fraise',
  },
  {
    key: 'Encadee',
    label: 'Encadee',
  },
  {
    key: 'Escada Sport',
    label: 'Escada Sport',
  },
  {
    key: 'E-Vie',
    label: 'E-Vie',
  },
  {
    key: 'Eight2nine',
    label: 'Eight2nine',
  },
  {
    key: 'Estelle',
    label: 'Estelle',
  },
  {
    key: 'Enjoy',
    label: 'Enjoy',
  },
  {
    key: 'Elle Nor',
    label: 'Elle Nor',
  },
  {
    key: 'Energy',
    label: 'Energy',
  },
  {
    key: 'Energie',
    label: 'Energie',
  },
  {
    key: 'Eugen Klein',
    label: 'Eugen Klein',
  },
  {
    key: 'Eastpak',
    label: 'Eastpak',
  },
  {
    key: 'Esisto',
    label: 'Esisto',
  },
  {
    key: 'Ed Hardy',
    label: 'Ed Hardy',
  },
  {
    key: 'Evelin Brandt',
    label: 'Evelin Brandt',
  },
  {
    key: 'Eileen Fisher',
    label: 'Eileen Fisher',
  },
  {
    key: 'Esprit De Corp',
    label: 'Esprit De Corp',
  },
  {
    key: 'El Naturalista',
    label: 'El Naturalista',
  },
  {
    key: 'Elena Miro',
    label: 'Elena Miro',
  },
  {
    key: 'Erfo',
    label: 'Erfo',
  },
  {
    key: 'En Creme',
    label: 'En Creme',
  },
  {
    key: 'Emily And Fin',
    label: 'Emily And Fin',
  },
  {
    key: 'Edwin',
    label: 'Edwin',
  },
  {
    key: 'Engelbert Strauss',
    label: 'Engelbert Strauss',
  },
  {
    key: 'Ebelieve',
    label: 'Ebelieve',
  },
  {
    key: 'Elegance Paris',
    label: 'Elegance Paris',
  },
  {
    key: 'Elie Tahari',
    label: 'Elie Tahari',
  },
  {
    key: 'Etro',
    label: 'Etro',
  },
  {
    key: 'Emoi By Emonite',
    label: 'Emoi By Emonite',
  },
  {
    key: 'Eve',
    label: 'Eve',
  },
  {
    key: 'Ella Moss',
    label: 'Ella Moss',
  },
  {
    key: 'Eduard Dressler',
    label: 'Eduard Dressler',
  },
  {
    key: 'Extend',
    label: 'Extend',
  },
  {
    key: 'Et Vous',
    label: 'Et Vous',
  },
  {
    key: 'Extyn',
    label: 'Extyn',
  },
  {
    key: 'Elisabetta Franchi',
    label: 'Elisabetta Franchi',
  },
  {
    key: 'East',
    label: 'East',
  },
  {
    key: 'Escada By Margaretha Ley',
    label: 'Escada By Margaretha Ley',
  },
  {
    key: 'Eider',
    label: 'Eider',
  },
  {
    key: 'Ermanno Scervino',
    label: 'Ermanno Scervino',
  },
  {
    key: 'Elements',
    label: 'Elements',
  },
  {
    key: 'Everybody Talks',
    label: 'Everybody Talks',
  },
  {
    key: 'Easy',
    label: 'Easy',
  },
  {
    key: 'Evis',
    label: 'Evis',
  },
  {
    key: 'El Ganso',
    label: 'El Ganso',
  },
  {
    key: 'Emilio Pucci',
    label: 'Emilio Pucci',
  },
  {
    key: 'Evita',
    label: 'Evita',
  },
  {
    key: 'Envy',
    label: 'Envy',
  },
  {
    key: 'Easy Wear',
    label: 'Easy Wear',
  },
  {
    key: 'English Factory',
    label: 'English Factory',
  },
  {
    key: 'Editions',
    label: 'Editions',
  },
  {
    key: 'Elemente Clemente',
    label: 'Elemente Clemente',
  },
  {
    key: 'Exte',
    label: 'Exte',
  },
  {
    key: 'Erich Fend',
    label: 'Erich Fend',
  },
  {
    key: 'Emerald',
    label: 'Emerald',
  },
  {
    key: 'Ever-Pretty',
    label: 'Ever-Pretty',
  },
  {
    key: 'Eastern Mountain Sports',
    label: 'Eastern Mountain Sports',
  },
  {
    key: 'Estee Lauder',
    label: 'Estee Lauder',
  },
  {
    key: 'Egon Von Furstenberg',
    label: 'Egon Von Furstenberg',
  },
  {
    key: 'Elisa Landri',
    label: 'Elisa Landri',
  },
  {
    key: 'Ecru',
    label: 'Ecru',
  },
  {
    key: 'En Focus',
    label: 'En Focus',
  },
  {
    key: 'Einhorn',
    label: 'Einhorn',
  },
  {
    key: 'Elk',
    label: 'Elk',
  },
  {
    key: 'Energie',
    label: 'Energie',
  },
  {
    key: 'Elly M',
    label: 'Elly M',
  },
  {
    key: 'Ermenegildo Zegna',
    label: 'Ermenegildo Zegna',
  },
  {
    key: 'Emanuel Berg',
    label: 'Emanuel Berg',
  },
  {
    key: 'Everwin',
    label: 'Everwin',
  },
  {
    key: 'Elliatt',
    label: 'Elliatt',
  },
  {
    key: 'Eastbay',
    label: 'Eastbay',
  },
  {
    key: 'Ethel Austin',
    label: 'Ethel Austin',
  },
  {
    key: 'Ecowish',
    label: 'Ecowish',
  },
  {
    key: 'Euphoria',
    label: 'Euphoria',
  },
  {
    key: 'Elliott Lauren',
    label: 'Elliott Lauren',
  },
  {
    key: 'Errea',
    label: 'Errea',
  },
  {
    key: 'Euro Moda',
    label: 'Euro Moda',
  },
  {
    key: 'Etcetera',
    label: 'Etcetera',
  },
  {
    key: 'Ellis & Dewey',
    label: 'Ellis & Dewey',
  },
  {
    key: 'Eden & Olivia',
    label: 'Eden & Olivia',
  },
  {
    key: 'Ewm',
    label: 'Ewm',
  },
  {
    key: 'Enza Nucci',
    label: 'Enza Nucci',
  },
  {
    key: 'Edwards',
    label: 'Edwards',
  },
  {
    key: 'Elinette',
    label: 'Elinette',
  },
  {
    key: 'E-Phos',
    label: 'E-Phos',
  },
  {
    key: 'Eva',
    label: 'Eva',
  },
  {
    key: 'Encoded',
    label: 'Encoded',
  },
  {
    key: 'Essentiel Antwerp',
    label: 'Essentiel Antwerp',
  },
  {
    key: 'En Garde',
    label: 'En Garde',
  },
  {
    key: 'Eliza J',
    label: 'Eliza J',
  },
  {
    key: 'Emmett',
    label: 'Emmett',
  },
  {
    key: 'Elvine',
    label: 'Elvine',
  },
  {
    key: 'Enita',
    label: 'Enita',
  },
  {
    key: 'Eva Mendes',
    label: 'Eva Mendes',
  },
  {
    key: 'Elizabeth And James',
    label: 'Elizabeth And James',
  },
  {
    key: 'Emanuel Ungaro',
    label: 'Emanuel Ungaro',
  },
  {
    key: 'Earnest Sewn',
    label: 'Earnest Sewn',
  },
  {
    key: "Ema Blue's",
    label: "Ema Blue's",
  },
  {
    key: 'Elefanten',
    label: 'Elefanten',
  },
  {
    key: 'Ethos',
    label: 'Ethos',
  },
  {
    key: 'E',
    label: 'E',
  },
  {
    key: 'Exact Change',
    label: 'Exact Change',
  },
  {
    key: 'Erin Fetherston',
    label: 'Erin Fetherston',
  },
  {
    key: 'Eva Kyburz',
    label: 'Eva Kyburz',
  },
  {
    key: 'Eva&Claudi',
    label: 'Eva&Claudi',
  },
  {
    key: 'Essentials By Esprit',
    label: 'Essentials By Esprit',
  },
  {
    key: 'Egerie',
    label: 'Egerie',
  },
  {
    key: 'Esti Couture',
    label: 'Esti Couture',
  },
  {
    key: 'Enzo Marconi',
    label: 'Enzo Marconi',
  },
  {
    key: 'Elisa Cavaletti',
    label: 'Elisa Cavaletti',
  },
  {
    key: 'Enigma',
    label: 'Enigma',
  },
  {
    key: 'Emidio Tucci',
    label: 'Emidio Tucci',
  },
  {
    key: 'Emoji',
    label: 'Emoji',
  },
  {
    key: 'Edina Ronay',
    label: 'Edina Ronay',
  },
  {
    key: 'Extra Me',
    label: 'Extra Me',
  },
  {
    key: 'Emerald Sundae',
    label: 'Emerald Sundae',
  },
  {
    key: 'Estradeur',
    label: 'Estradeur',
  },
  {
    key: 'Emoda',
    label: 'Emoda',
  },
  {
    key: 'Exhibit',
    label: 'Exhibit',
  },
  {
    key: 'Eden Park',
    label: 'Eden Park',
  },
  {
    key: 'Edith & Ella',
    label: 'Edith & Ella',
  },
  {
    key: 'Evaroza',
    label: 'Evaroza',
  },
  {
    key: 'Explosi',
    label: 'Explosi',
  },
  {
    key: 'Elise Gug',
    label: 'Elise Gug',
  },
  {
    key: 'Etienne Aigner',
    label: 'Etienne Aigner',
  },
  {
    key: 'Elephant',
    label: 'Elephant',
  },
  {
    key: 'Enrico Coveri',
    label: 'Enrico Coveri',
  },
  {
    key: 'Enzo Mantovani',
    label: 'Enzo Mantovani',
  },
  {
    key: 'Emilio Lucax',
    label: 'Emilio Lucax',
  },
  {
    key: 'Elliott Lucca',
    label: 'Elliott Lucca',
  },
  {
    key: 'Emanuella. Y',
    label: 'Emanuella. Y',
  },
  {
    key: 'Eroke',
    label: 'Eroke',
  },
  {
    key: 'Eliza',
    label: 'Eliza',
  },
  {
    key: 'Edo Popken',
    label: 'Edo Popken',
  },
  {
    key: 'Edwin Wallace',
    label: 'Edwin Wallace',
  },
  {
    key: 'Eva Kayan',
    label: 'Eva Kayan',
  },
  {
    key: 'E&S',
    label: 'E&S',
  },
  {
    key: 'Ej Sikke Lej',
    label: 'Ej Sikke Lej',
  },
  {
    key: 'Eliane Et Lena',
    label: 'Eliane Et Lena',
  },
  {
    key: 'Enisse',
    label: 'Enisse',
  },
  {
    key: 'Eleganci',
    label: 'Eleganci',
  },
  {
    key: 'Ellen Eisemann',
    label: 'Ellen Eisemann',
  },
  {
    key: 'Ezywalkin',
    label: 'Ezywalkin',
  },
  {
    key: 'Espe',
    label: 'Espe',
  },
  {
    key: 'Ecko',
    label: 'Ecko',
  },
  {
    key: 'Eva & Claudi',
    label: 'Eva & Claudi',
  },
  {
    key: 'Eric Bompard',
    label: 'Eric Bompard',
  },
  {
    key: 'Electric Poppy',
    label: 'Electric Poppy',
  },
  {
    key: 'Erny Van Reijmersdal',
    label: 'Erny Van Reijmersdal',
  },
  {
    key: 'Ester Elenora',
    label: 'Ester Elenora',
  },
  {
    key: 'Elysess',
    label: 'Elysess',
  },
  {
    key: 'Eden Rock',
    label: 'Eden Rock',
  },
  {
    key: 'Enelle',
    label: 'Enelle',
  },
  {
    key: 'Elena Eden',
    label: 'Elena Eden',
  },
  {
    key: 'Ellehammer',
    label: 'Ellehammer',
  },
  {
    key: 'Exquily',
    label: 'Exquily',
  },
  {
    key: 'Equus',
    label: 'Equus',
  },
  {
    key: 'Eleganza',
    label: 'Eleganza',
  },
  {
    key: 'Emily West',
    label: 'Emily West',
  },
  {
    key: 'Eres',
    label: 'Eres',
  },
  {
    key: 'Eyas',
    label: 'Eyas',
  },
  {
    key: 'Enter',
    label: 'Enter',
  },
  {
    key: 'Expressions',
    label: 'Expressions',
  },
  {
    key: 'Elina',
    label: 'Elina',
  },
  {
    key: 'Enrico Santini',
    label: 'Enrico Santini',
  },
  {
    key: 'Eucalyptus',
    label: 'Eucalyptus',
  },
  {
    key: 'Erdem X H&M',
    label: 'Erdem X H&M',
  },
  {
    key: 'Elm Design',
    label: 'Elm Design',
  },
  {
    key: 'Escales Paris',
    label: 'Escales Paris',
  },
  {
    key: 'Ebony',
    label: 'Ebony',
  },
  {
    key: 'Eem',
    label: 'Eem',
  },
  {
    key: 'Epilogue By Eva Emanuelsen',
    label: 'Epilogue By Eva Emanuelsen',
  },
  {
    key: 'E...Due',
    label: 'E...Due',
  },
  {
    key: 'Emma Go',
    label: 'Emma Go',
  },
  {
    key: 'Ecole',
    label: 'Ecole',
  },
  {
    key: 'Equinoxe',
    label: 'Equinoxe',
  },
  {
    key: 'Envy Me',
    label: 'Envy Me',
  },
  {
    key: 'Etiqueta Negra',
    label: 'Etiqueta Negra',
  },
  {
    key: 'Exertek',
    label: 'Exertek',
  },
  {
    key: 'Ellen Capellino',
    label: 'Ellen Capellino',
  },
  {
    key: 'Essue',
    label: 'Essue',
  },
  {
    key: 'Emily & Noah',
    label: 'Emily & Noah',
  },
  {
    key: 'Espadrij',
    label: 'Espadrij',
  },
  {
    key: 'Emperial',
    label: 'Emperial',
  },
  {
    key: 'Echtes',
    label: 'Echtes',
  },
  {
    key: 'Evaw Wave',
    label: 'Evaw Wave',
  },
  {
    key: 'Emoi Enplus',
    label: 'Emoi Enplus',
  },
  {
    key: 'Eva Franco',
    label: 'Eva Franco',
  },
  {
    key: 'Ellen Parker',
    label: 'Ellen Parker',
  },
  {
    key: 'Eska',
    label: 'Eska',
  },
  {
    key: 'Earl',
    label: 'Earl',
  },
  {
    key: 'Easy Step',
    label: 'Easy Step',
  },
  {
    key: 'Evanna',
    label: 'Evanna',
  },
  {
    key: 'Ed Hardy By Christian Audigier',
    label: 'Ed Hardy By Christian Audigier',
  },
  {
    key: 'Evenuel',
    label: 'Evenuel',
  },
  {
    key: 'Erika Cavallini',
    label: 'Erika Cavallini',
  },
  {
    key: 'Eunina',
    label: 'Eunina',
  },
  {
    key: 'Eagle',
    label: 'Eagle',
  },
  {
    key: 'Elena Solano',
    label: 'Elena Solano',
  },
  {
    key: 'Ette',
    label: 'Ette',
  },
  {
    key: 'East Lower',
    label: 'East Lower',
  },
  {
    key: 'Eternel',
    label: 'Eternel',
  },
  {
    key: 'Etoile Du Monde',
    label: 'Etoile Du Monde',
  },
  {
    key: 'Emreco',
    label: 'Emreco',
  },
  {
    key: 'Early Days',
    label: 'Early Days',
  },
  {
    key: 'Emile Et Rose',
    label: 'Emile Et Rose',
  },
  {
    key: 'Eshakti',
    label: 'Eshakti',
  },
  {
    key: 'Einstoffen',
    label: 'Einstoffen',
  },
  {
    key: 'Edblad',
    label: 'Edblad',
  },
  {
    key: 'Enfocus Studio',
    label: 'Enfocus Studio',
  },
  {
    key: 'E-Woman',
    label: 'E-Woman',
  },
  {
    key: 'Everybody By Bz Moda',
    label: 'Everybody By Bz Moda',
  },
  {
    key: 'Evisu',
    label: 'Evisu',
  },
  {
    key: 'East Femme',
    label: 'East Femme',
  },
  {
    key: "Eddy's Jackets",
    label: "Eddy's Jackets",
  },
  {
    key: 'Ellie',
    label: 'Ellie',
  },
  {
    key: 'Eyn',
    label: 'Eyn',
  },
  {
    key: 'Ellen Reyes',
    label: 'Ellen Reyes',
  },
  {
    key: 'French Connection',
    label: 'French Connection',
  },
  {
    key: 'Forever 21',
    label: 'Forever 21',
  },
  {
    key: 'Fb Sister',
    label: 'Fb Sister',
  },
  {
    key: 'F&F',
    label: 'F&F',
  },
  {
    key: 'Fashion Union',
    label: 'Fashion Union',
  },
  {
    key: 'Flame',
    label: 'Flame',
  },
  {
    key: 'Faded Glory',
    label: 'Faded Glory',
  },
  {
    key: 'Fishbone',
    label: 'Fishbone',
  },
  {
    key: 'Fransa',
    label: 'Fransa',
  },
  {
    key: 'Firetrap',
    label: 'Firetrap',
  },
  {
    key: 'Free People',
    label: 'Free People',
  },
  {
    key: 'Fashion Nova',
    label: 'Fashion Nova',
  },
  {
    key: 'Friboo',
    label: 'Friboo',
  },
  {
    key: 'Fuchs & Schmitt',
    label: 'Fuchs & Schmitt',
  },
  {
    key: 'Freesoul',
    label: 'Freesoul',
  },
  {
    key: 'Fabiani',
    label: 'Fabiani',
  },
  {
    key: 'Fornarina',
    label: 'Fornarina',
  },
  {
    key: 'Filippa K',
    label: 'Filippa K',
  },
  {
    key: 'Frank Walder',
    label: 'Frank Walder',
  },
  {
    key: 'Fila',
    label: 'Fila',
  },
  {
    key: 'Free Quent',
    label: 'Free Quent',
  },
  {
    key: 'Famous',
    label: 'Famous',
  },
  {
    key: 'Fresh Made',
    label: 'Fresh Made',
  },
  {
    key: 'Flash Lights',
    label: 'Flash Lights',
  },
  {
    key: 'Fever',
    label: 'Fever',
  },
  {
    key: 'Freeman T. Porter',
    label: 'Freeman T. Porter',
  },
  {
    key: 'Fruit Of The Loom',
    label: 'Fruit Of The Loom',
  },
  {
    key: 'Forever Unique',
    label: 'Forever Unique',
  },
  {
    key: 'Free Fusion',
    label: 'Free Fusion',
  },
  {
    key: 'Frieda & Freddies',
    label: 'Frieda & Freddies',
  },
  {
    key: 'Franklin & Marshall',
    label: 'Franklin & Marshall',
  },
  {
    key: 'Fred Sabatier',
    label: 'Fred Sabatier',
  },
  {
    key: 'Franco Callegari',
    label: 'Franco Callegari',
  },
  {
    key: 'Frock And Frill',
    label: 'Frock And Frill',
  },
  {
    key: 'Fossil',
    label: 'Fossil',
  },
  {
    key: 'Floyd By Smith',
    label: 'Floyd By Smith',
  },
  {
    key: 'Feraud',
    label: 'Feraud',
  },
  {
    key: 'Fashion Elle',
    label: 'Fashion Elle',
  },
  {
    key: 'Fredsbruder',
    label: 'Fredsbruder',
  },
  {
    key: 'Fracomina',
    label: 'Fracomina',
  },
  {
    key: 'Fresh Soul',
    label: 'Fresh Soul',
  },
  {
    key: 'Furla',
    label: 'Furla',
  },
  {
    key: 'Fate',
    label: 'Fate',
  },
  {
    key: 'Fenn Wright Manson',
    label: 'Fenn Wright Manson',
  },
  {
    key: 'Fashion Bug',
    label: 'Fashion Bug',
  },
  {
    key: 'Fella Hamilton',
    label: 'Fella Hamilton',
  },
  {
    key: 'Fletcher Jones',
    label: 'Fletcher Jones',
  },
  {
    key: 'Floryday',
    label: 'Floryday',
  },
  {
    key: 'Frapp',
    label: 'Frapp',
  },
  {
    key: 'Fun & Flirt',
    label: 'Fun & Flirt',
  },
  {
    key: 'Flash',
    label: 'Flash',
  },
  {
    key: 'Forvert',
    label: 'Forvert',
  },
  {
    key: 'Frame',
    label: 'Frame',
  },
  {
    key: 'Fifty Five',
    label: 'Fifty Five',
  },
  {
    key: 'Ftc Cashmere',
    label: 'Ftc Cashmere',
  },
  {
    key: 'Fagottino By Oviesse',
    label: 'Fagottino By Oviesse',
  },
  {
    key: 'Formul@',
    label: 'Formul@',
  },
  {
    key: 'Falke',
    label: 'Falke',
  },
  {
    key: 'Firesh',
    label: 'Firesh',
  },
  {
    key: 'Furiezza',
    label: 'Furiezza',
  },
  {
    key: 'Fred Perry',
    label: 'Fred Perry',
  },
  {
    key: 'Fashion Fair',
    label: 'Fashion Fair',
  },
  {
    key: 'Ferre',
    label: 'Ferre',
  },
  {
    key: 'Frank Lyman',
    label: 'Frank Lyman',
  },
  {
    key: 'Frogbox',
    label: 'Frogbox',
  },
  {
    key: 'Frans Molenaar',
    label: 'Frans Molenaar',
  },
  {
    key: 'Faconnable',
    label: 'Faconnable',
  },
  {
    key: 'Forever Young By Chicoree',
    label: 'Forever Young By Chicoree',
  },
  {
    key: 'Fred De La Bretoniere',
    label: 'Fred De La Bretoniere',
  },
  {
    key: 'Flying Tomato',
    label: 'Flying Tomato',
  },
  {
    key: 'Fabrizia',
    label: 'Fabrizia',
  },
  {
    key: 'Fashion Studio',
    label: 'Fashion Studio',
  },
  {
    key: 'Fabiana Filippi',
    label: 'Fabiana Filippi',
  },
  {
    key: 'First And I',
    label: 'First And I',
  },
  {
    key: 'Fcuk',
    label: 'Fcuk',
  },
  {
    key: 'For Love And Lemons',
    label: 'For Love And Lemons',
  },
  {
    key: 'Fanatics',
    label: 'Fanatics',
  },
  {
    key: 'Fred Mello',
    label: 'Fred Mello',
  },
  {
    key: 'Frislid',
    label: 'Frislid',
  },
  {
    key: 'Feylin',
    label: 'Feylin',
  },
  {
    key: 'Fly London',
    label: 'Fly London',
  },
  {
    key: 'Fairly',
    label: 'Fairly',
  },
  {
    key: 'Fendi',
    label: 'Fendi',
  },
  {
    key: 'Ferrari',
    label: 'Ferrari',
  },
  {
    key: 'Full Circle',
    label: 'Full Circle',
  },
  {
    key: 'F.J.J',
    label: 'F.J.J',
  },
  {
    key: 'Fiveunits',
    label: 'Fiveunits',
  },
  {
    key: 'Fiorucci',
    label: 'Fiorucci',
  },
  {
    key: 'Forenza',
    label: 'Forenza',
  },
  {
    key: 'Fionella',
    label: 'Fionella',
  },
  {
    key: 'Frau',
    label: 'Frau',
  },
  {
    key: 'Frime',
    label: 'Frime',
  },
  {
    key: 'Flavio Castellani',
    label: 'Flavio Castellani',
  },
  {
    key: 'Frant',
    label: 'Frant',
  },
  {
    key: 'For Women',
    label: 'For Women',
  },
  {
    key: 'Fashion Club',
    label: 'Fashion Club',
  },
  {
    key: 'Fall Winter Spring Summer',
    label: 'Fall Winter Spring Summer',
  },
  {
    key: 'Flair',
    label: 'Flair',
  },
  {
    key: 'Fat Face',
    label: 'Fat Face',
  },
  {
    key: 'Fashion Safari',
    label: 'Fashion Safari',
  },
  {
    key: 'Freesia',
    label: 'Freesia',
  },
  {
    key: 'Fire + Ice By Bogner',
    label: 'Fire + Ice By Bogner',
  },
  {
    key: 'Fanco',
    label: 'Fanco',
  },
  {
    key: 'Fiorella Rubino',
    label: 'Fiorella Rubino',
  },
  {
    key: 'Faith',
    label: 'Faith',
  },
  {
    key: 'Fashion Wave',
    label: 'Fashion Wave',
  },
  {
    key: 'Fine Collection',
    label: 'Fine Collection',
  },
  {
    key: 'Free Girl',
    label: 'Free Girl',
  },
  {
    key: 'Florsheim',
    label: 'Florsheim',
  },
  {
    key: 'Frankie Morello',
    label: 'Frankie Morello',
  },
  {
    key: 'Fantasy',
    label: 'Fantasy',
  },
  {
    key: 'Formenterra',
    label: 'Formenterra',
  },
  {
    key: 'Fay',
    label: 'Fay',
  },
  {
    key: 'Fratelli',
    label: 'Fratelli',
  },
  {
    key: 'Faith Connexion',
    label: 'Faith Connexion',
  },
  {
    key: "Francesca's",
    label: "Francesca's",
  },
  {
    key: 'Fairway & Greene',
    label: 'Fairway & Greene',
  },
  {
    key: 'Fuego',
    label: 'Fuego',
  },
  {
    key: 'Forever Fashion',
    label: 'Forever Fashion',
  },
  {
    key: 'Fado',
    label: 'Fado',
  },
  {
    key: 'Farhi By Nicole Farhi',
    label: 'Farhi By Nicole Farhi',
  },
  {
    key: 'Fuga Denim',
    label: 'Fuga Denim',
  },
  {
    key: 'Fashion Concept',
    label: 'Fashion Concept',
  },
  {
    key: 'Factory',
    label: 'Factory',
  },
  {
    key: 'Floreiza',
    label: 'Floreiza',
  },
  {
    key: 'Fashion Mia',
    label: 'Fashion Mia',
  },
  {
    key: 'Fiorella',
    label: 'Fiorella',
  },
  {
    key: 'First You',
    label: 'First You',
  },
  {
    key: 'Frye',
    label: 'Frye',
  },
  {
    key: 'Freeheron',
    label: 'Freeheron',
  },
  {
    key: 'Fritzi Aus Preussen',
    label: 'Fritzi Aus Preussen',
  },
  {
    key: 'Funky Staff',
    label: 'Funky Staff',
  },
  {
    key: 'Farrell',
    label: 'Farrell',
  },
  {
    key: 'Faviana',
    label: 'Faviana',
  },
  {
    key: 'Fan Fei',
    label: 'Fan Fei',
  },
  {
    key: 'Fabrizio Lenzi',
    label: 'Fabrizio Lenzi',
  },
  {
    key: 'Ferrari X Puma',
    label: 'Ferrari X Puma',
  },
  {
    key: 'Forever Yours',
    label: 'Forever Yours',
  },
  {
    key: 'Fashion And Style',
    label: 'Fashion And Style',
  },
  {
    key: 'Fanty',
    label: 'Fanty',
  },
  {
    key: 'Freaky Heads',
    label: 'Freaky Heads',
  },
  {
    key: 'Froddo',
    label: 'Froddo',
  },
  {
    key: 'Femme Seven',
    label: 'Femme Seven',
  },
  {
    key: 'Functional',
    label: 'Functional',
  },
  {
    key: 'Fogal',
    label: 'Fogal',
  },
  {
    key: 'Felmini',
    label: 'Felmini',
  },
  {
    key: 'Fun 2 Fun',
    label: 'Fun 2 Fun',
  },
  {
    key: 'Franca',
    label: 'Franca',
  },
  {
    key: 'Fashion Express',
    label: 'Fashion Express',
  },
  {
    key: 'Frankremme',
    label: 'Frankremme',
  },
  {
    key: 'Fendt',
    label: 'Fendt',
  },
  {
    key: 'Fashion Box',
    label: 'Fashion Box',
  },
  {
    key: 'Fashion Hy-Ii',
    label: 'Fashion Hy-Ii',
  },
  {
    key: 'Floris Van Bommel',
    label: 'Floris Van Bommel',
  },
  {
    key: 'Foray',
    label: 'Foray',
  },
  {
    key: 'Fashion Junki',
    label: 'Fashion Junki',
  },
  {
    key: 'Fashion In Love By Wdc',
    label: 'Fashion In Love By Wdc',
  },
  {
    key: 'Fashion Accessories',
    label: 'Fashion Accessories',
  },
  {
    key: 'Fedeli',
    label: 'Fedeli',
  },
  {
    key: 'French Kick',
    label: 'French Kick',
  },
  {
    key: 'Fn Denim',
    label: 'Fn Denim',
  },
  {
    key: 'Filipine Lahoya',
    label: 'Filipine Lahoya',
  },
  {
    key: 'Fashion Plus',
    label: 'Fashion Plus',
  },
  {
    key: 'Fashion Affairs',
    label: 'Fashion Affairs',
  },
  {
    key: 'Foxhole',
    label: 'Foxhole',
  },
  {
    key: 'Fearne',
    label: 'Fearne',
  },
  {
    key: 'Fidan',
    label: 'Fidan',
  },
  {
    key: 'Francesco Biasia',
    label: 'Francesco Biasia',
  },
  {
    key: 'Fraus Holenaar',
    label: 'Fraus Holenaar',
  },
  {
    key: 'Fun & Fun',
    label: 'Fun & Fun',
  },
  {
    key: 'Featuring',
    label: 'Featuring',
  },
  {
    key: 'Fashion Street',
    label: 'Fashion Street',
  },
  {
    key: 'Fashy',
    label: 'Fashy',
  },
  {
    key: 'Fitters',
    label: 'Fitters',
  },
  {
    key: 'Falconeri',
    label: 'Falconeri',
  },
  {
    key: 'Five Seasons',
    label: 'Five Seasons',
  },
  {
    key: 'French Eye',
    label: 'French Eye',
  },
  {
    key: 'Fuzzi',
    label: 'Fuzzi',
  },
  {
    key: 'Frank Thomas',
    label: 'Frank Thomas',
  },
  {
    key: 'Faciba',
    label: 'Faciba',
  },
  {
    key: 'F4 Collection',
    label: 'F4 Collection',
  },
  {
    key: 'Figueroa & Flower',
    label: 'Figueroa & Flower',
  },
  {
    key: 'Friends Of Couture',
    label: 'Friends Of Couture',
  },
  {
    key: 'Finn & Clover',
    label: 'Finn & Clover',
  },
  {
    key: 'Florens',
    label: 'Florens',
  },
  {
    key: 'Fashionista',
    label: 'Fashionista',
  },
  {
    key: 'Flowers For Friends',
    label: 'Flowers For Friends',
  },
  {
    key: 'Fake',
    label: 'Fake',
  },
  {
    key: 'Fisher Price',
    label: 'Fisher Price',
  },
  {
    key: 'Follie Di Garbo',
    label: 'Follie Di Garbo',
  },
  {
    key: 'Fidelity',
    label: 'Fidelity',
  },
  {
    key: 'Fit & More',
    label: 'Fit & More',
  },
  {
    key: 'Feetje',
    label: 'Feetje',
  },
  {
    key: 'Fishbowl',
    label: 'Fishbowl',
  },
  {
    key: 'Four X',
    label: 'Four X',
  },
  {
    key: 'Freemood',
    label: 'Freemood',
  },
  {
    key: 'Francesco Milano',
    label: 'Francesco Milano',
  },
  {
    key: 'Fashion Highlights',
    label: 'Fashion Highlights',
  },
  {
    key: 'Frauenschuh',
    label: 'Frauenschuh',
  },
  {
    key: 'Firstar',
    label: 'Firstar',
  },
  {
    key: 'First Impressions',
    label: 'First Impressions',
  },
  {
    key: 'Frenci&Gio',
    label: 'Frenci&Gio',
  },
  {
    key: 'F By Friis',
    label: 'F By Friis',
  },
  {
    key: 'Field Gear',
    label: 'Field Gear',
  },
  {
    key: 'Fiorelli',
    label: 'Fiorelli',
  },
  {
    key: 'Foil',
    label: 'Foil',
  },
  {
    key: 'Fashion Front',
    label: 'Fashion Front',
  },
  {
    key: 'Fullstop.',
    label: 'Fullstop.',
  },
  {
    key: 'Flapdoodles',
    label: 'Flapdoodles',
  },
  {
    key: 'Feifa',
    label: 'Feifa',
  },
  {
    key: 'Fun & Basics',
    label: 'Fun & Basics',
  },
  {
    key: 'Foursquare',
    label: 'Foursquare',
  },
  {
    key: 'Freshman',
    label: 'Freshman',
  },
  {
    key: 'Facis',
    label: 'Facis',
  },
  {
    key: 'Free State',
    label: 'Free State',
  },
  {
    key: 'Flavia',
    label: 'Flavia',
  },
  {
    key: 'Fashion Classics',
    label: 'Fashion Classics',
  },
  {
    key: 'Florence&Fred',
    label: 'Florence&Fred',
  },
  {
    key: 'F2',
    label: 'F2',
  },
  {
    key: 'Finn Comfort',
    label: 'Finn Comfort',
  },
  {
    key: 'Filippo Raphael',
    label: 'Filippo Raphael',
  },
  {
    key: 'Fashion Pride',
    label: 'Fashion Pride',
  },
  {
    key: 'Foot Therapy',
    label: 'Foot Therapy',
  },
  {
    key: 'Futuro',
    label: 'Futuro',
  },
  {
    key: 'Frankie & Liberty',
    label: 'Frankie & Liberty',
  },
  {
    key: 'Fusalp',
    label: 'Fusalp',
  },
  {
    key: 'Fiorella Di Verdi',
    label: 'Fiorella Di Verdi',
  },
  {
    key: 'Fiore Di Lucia Milano',
    label: 'Fiore Di Lucia Milano',
  },
  {
    key: 'Fjallraven',
    label: 'Fjallraven',
  },
  {
    key: 'Favant',
    label: 'Favant',
  },
  {
    key: 'Felicity & Coco',
    label: 'Felicity & Coco',
  },
  {
    key: 'Funky Shoes',
    label: 'Funky Shoes',
  },
  {
    key: 'Fashion Collection Y_7',
    label: 'Fashion Collection Y_7',
  },
  {
    key: 'Fisignora',
    label: 'Fisignora',
  },
  {
    key: 'Fosco',
    label: 'Fosco',
  },
  {
    key: 'Falls Creek',
    label: 'Falls Creek',
  },
  {
    key: 'Folli Follie',
    label: 'Folli Follie',
  },
  {
    key: 'Forty Four',
    label: 'Forty Four',
  },
  {
    key: 'Fille Des Sables',
    label: 'Fille Des Sables',
  },
  {
    key: 'Fersini',
    label: 'Fersini',
  },
  {
    key: 'Firma Berlin',
    label: 'Firma Berlin',
  },
  {
    key: 'First Option',
    label: 'First Option',
  },
  {
    key: 'Fashion Addict',
    label: 'Fashion Addict',
  },
  {
    key: 'Faith Love Passion',
    label: 'Faith Love Passion',
  },
  {
    key: 'Fratelli Rossetti',
    label: 'Fratelli Rossetti',
  },
  {
    key: 'Guess',
    label: 'Guess',
  },
  {
    key: 'Gerry Weber',
    label: 'Gerry Weber',
  },
  {
    key: 'Gap',
    label: 'Gap',
  },
  {
    key: 'G-Star Raw',
    label: 'G-Star Raw',
  },
  {
    key: 'Glamorous',
    label: 'Glamorous',
  },
  {
    key: 'Gina Tricot',
    label: 'Gina Tricot',
  },
  {
    key: 'George',
    label: 'George',
  },
  {
    key: 'Geox',
    label: 'Geox',
  },
  {
    key: 'Grain De Ble',
    label: 'Grain De Ble',
  },
  {
    key: 'Gina Benotti',
    label: 'Gina Benotti',
  },
  {
    key: 'Gina',
    label: 'Gina',
  },
  {
    key: 'Geographical Norway',
    label: 'Geographical Norway',
  },
  {
    key: 'Girl Xpress',
    label: 'Girl Xpress',
  },
  {
    key: 'Golfino',
    label: 'Golfino',
  },
  {
    key: 'Gant',
    label: 'Gant',
  },
  {
    key: 'Gipsy',
    label: 'Gipsy',
  },
  {
    key: 'Gaudi',
    label: 'Gaudi',
  },
  {
    key: 'Giorgio Di Mare',
    label: 'Giorgio Di Mare',
  },
  {
    key: 'Gioseppo',
    label: 'Gioseppo',
  },
  {
    key: 'Gelco',
    label: 'Gelco',
  },
  {
    key: 'Gina Laura',
    label: 'Gina Laura',
  },
  {
    key: 'Gil Bret',
    label: 'Gil Bret',
  },
  {
    key: 'Gap Baby',
    label: 'Gap Baby',
  },
  {
    key: 'Gas',
    label: 'Gas',
  },
  {
    key: 'Gap Kids',
    label: 'Gap Kids',
  },
  {
    key: 'G.W.',
    label: 'G.W.',
  },
  {
    key: 'Grain De Malice',
    label: 'Grain De Malice',
  },
  {
    key: 'Guess By Marciano',
    label: 'Guess By Marciano',
  },
  {
    key: 'Gestuz',
    label: 'Gestuz',
  },
  {
    key: 'Giorgio',
    label: 'Giorgio',
  },
  {
    key: 'Gabriella Vicenza',
    label: 'Gabriella Vicenza',
  },
  {
    key: 'Giada',
    label: 'Giada',
  },
  {
    key: 'Guido Maria Kretschmer',
    label: 'Guido Maria Kretschmer',
  },
  {
    key: 'Gin Tonic',
    label: 'Gin Tonic',
  },
  {
    key: 'Gerard Darel',
    label: 'Gerard Darel',
  },
  {
    key: 'Green House',
    label: 'Green House',
  },
  {
    key: 'Gola',
    label: 'Gola',
  },
  {
    key: 'Gloria Vanderbilt',
    label: 'Gloria Vanderbilt',
  },
  {
    key: 'G3000',
    label: 'G3000',
  },
  {
    key: 'Gang',
    label: 'Gang',
  },
  {
    key: 'Geisha',
    label: 'Geisha',
  },
  {
    key: 'Gildan',
    label: 'Gildan',
  },
  {
    key: 'Golddigga',
    label: 'Golddigga',
  },
  {
    key: 'Globus',
    label: 'Globus',
  },
  {
    key: 'Gerard Pasquier',
    label: 'Gerard Pasquier',
  },
  {
    key: 'Gaastra',
    label: 'Gaastra',
  },
  {
    key: 'Gorman',
    label: 'Gorman',
  },
  {
    key: 'G.H.Bass&Co.',
    label: 'G.H.Bass&Co.',
  },
  {
    key: 'Great Plains',
    label: 'Great Plains',
  },
  {
    key: 'Georges Rech',
    label: 'Georges Rech',
  },
  {
    key: 'Gabor',
    label: 'Gabor',
  },
  {
    key: 'Gutteridge',
    label: 'Gutteridge',
  },
  {
    key: 'Glamour',
    label: 'Glamour',
  },
  {
    key: 'G 21',
    label: 'G 21',
  },
  {
    key: 'Guy Laroche',
    label: 'Guy Laroche',
  },
  {
    key: 'Goby',
    label: 'Goby',
  },
  {
    key: 'Grace Elements',
    label: 'Grace Elements',
  },
  {
    key: 'Gate',
    label: 'Gate',
  },
  {
    key: 'Gucci',
    label: 'Gucci',
  },
  {
    key: 'Gipsy By Mauritius',
    label: 'Gipsy By Mauritius',
  },
  {
    key: 'Guru',
    label: 'Guru',
  },
  {
    key: 'Get It On By S.Oliver',
    label: 'Get It On By S.Oliver',
  },
  {
    key: 'Giorgia & Johns',
    label: 'Giorgia & Johns',
  },
  {
    key: 'Galvanni',
    label: 'Galvanni',
  },
  {
    key: 'Gnw',
    label: 'Gnw',
  },
  {
    key: 'Ganni',
    label: 'Ganni',
  },
  {
    key: 'Gianni Rodini',
    label: 'Gianni Rodini',
  },
  {
    key: 'Glenfield',
    label: 'Glenfield',
  },
  {
    key: 'George Gina & Lucy',
    label: 'George Gina & Lucy',
  },
  {
    key: 'Gibson',
    label: 'Gibson',
  },
  {
    key: 'Gottex',
    label: 'Gottex',
  },
  {
    key: 'Green Coast',
    label: 'Green Coast',
  },
  {
    key: 'Grace & Mila',
    label: 'Grace & Mila',
  },
  {
    key: 'Glo Weave',
    label: 'Glo Weave',
  },
  {
    key: 'Givoni',
    label: 'Givoni',
  },
  {
    key: 'Golle Haug',
    label: 'Golle Haug',
  },
  {
    key: 'Grayson',
    label: 'Grayson',
  },
  {
    key: 'Gina Jeans',
    label: 'Gina Jeans',
  },
  {
    key: 'Garnet Hill',
    label: 'Garnet Hill',
  },
  {
    key: 'Gc Fontana',
    label: 'Gc Fontana',
  },
  {
    key: 'Gustav',
    label: 'Gustav',
  },
  {
    key: 'G 3000',
    label: 'G 3000',
  },
  {
    key: 'Goldwater Creek',
    label: 'Goldwater Creek',
  },
  {
    key: 'Giorgio Armani',
    label: 'Giorgio Armani',
  },
  {
    key: 'Gran Sasso',
    label: 'Gran Sasso',
  },
  {
    key: 'Gotha',
    label: 'Gotha',
  },
  {
    key: 'Gudrun Sjödén',
    label: 'Gudrun Sjödén',
  },
  {
    key: 'Globus Essentials',
    label: 'Globus Essentials',
  },
  {
    key: 'Graumann',
    label: 'Graumann',
  },
  {
    key: 'Gigue',
    label: 'Gigue',
  },
  {
    key: 'Gilly Hicks',
    label: 'Gilly Hicks',
  },
  {
    key: 'Gai Mattiolo',
    label: 'Gai Mattiolo',
  },
  {
    key: 'Green Village',
    label: 'Green Village',
  },
  {
    key: 'Gaialuna',
    label: 'Gaialuna',
  },
  {
    key: 'Grama',
    label: 'Grama',
  },
  {
    key: 'Gymshark',
    label: 'Gymshark',
  },
  {
    key: 'Greenwoods',
    label: 'Greenwoods',
  },
  {
    key: 'Giani Feroti',
    label: 'Giani Feroti',
  },
  {
    key: 'Giorgio Costa',
    label: 'Giorgio Costa',
  },
  {
    key: 'Guabello',
    label: 'Guabello',
  },
  {
    key: 'Gerry',
    label: 'Gerry',
  },
  {
    key: 'Grosby',
    label: 'Grosby',
  },
  {
    key: 'Georg Stiels',
    label: 'Georg Stiels',
  },
  {
    key: 'Garfield & Marks',
    label: 'Garfield & Marks',
  },
  {
    key: 'Gear',
    label: 'Gear',
  },
  {
    key: 'G.I.G.A. Dx By Killtec',
    label: 'G.I.G.A. Dx By Killtec',
  },
  {
    key: 'Geospirit',
    label: 'Geospirit',
  },
  {
    key: 'Gomakesome',
    label: 'Gomakesome',
  },
  {
    key: 'Graffic',
    label: 'Graffic',
  },
  {
    key: 'Garanimals',
    label: 'Garanimals',
  },
  {
    key: 'Galvin Green',
    label: 'Galvin Green',
  },
  {
    key: 'Gwynedds',
    label: 'Gwynedds',
  },
  {
    key: 'Gattinoni',
    label: 'Gattinoni',
  },
  {
    key: 'Gsus',
    label: 'Gsus',
  },
  {
    key: 'Guara',
    label: 'Guara',
  },
  {
    key: 'Georgiou Studio',
    label: 'Georgiou Studio',
  },
  {
    key: 'Giulia E Tu',
    label: 'Giulia E Tu',
  },
  {
    key: 'Giuseppe Zanotti',
    label: 'Giuseppe Zanotti',
  },
  {
    key: 'Ginette',
    label: 'Ginette',
  },
  {
    key: 'Goldwin',
    label: 'Goldwin',
  },
  {
    key: 'Guga Jeans',
    label: 'Guga Jeans',
  },
  {
    key: 'Gharani Strok',
    label: 'Gharani Strok',
  },
  {
    key: 'Gabina',
    label: 'Gabina',
  },
  {
    key: 'G.I.G.A. Dx',
    label: 'G.I.G.A. Dx',
  },
  {
    key: 'Gazzarrini',
    label: 'Gazzarrini',
  },
  {
    key: 'Gat Rimon',
    label: 'Gat Rimon',
  },
  {
    key: 'Greta',
    label: 'Greta',
  },
  {
    key: 'Geiger',
    label: 'Geiger',
  },
  {
    key: 'Glamorosa',
    label: 'Glamorosa',
  },
  {
    key: 'Gian Vargian',
    label: 'Gian Vargian',
  },
  {
    key: 'Gadea',
    label: 'Gadea',
  },
  {
    key: 'Gianfranco Ferre',
    label: 'Gianfranco Ferre',
  },
  {
    key: 'Gef',
    label: 'Gef',
  },
  {
    key: 'Gianetta',
    label: 'Gianetta',
  },
  {
    key: 'G By Giuliana Rancic',
    label: 'G By Giuliana Rancic',
  },
  {
    key: 'Gok For Tu',
    label: 'Gok For Tu',
  },
  {
    key: 'Gino Lombardi',
    label: 'Gino Lombardi',
  },
  {
    key: 'Gryphon',
    label: 'Gryphon',
  },
  {
    key: 'Grace Karin',
    label: 'Grace Karin',
  },
  {
    key: 'Guy Rover',
    label: 'Guy Rover',
  },
  {
    key: 'Grendha',
    label: 'Grendha',
  },
  {
    key: 'George Simonton',
    label: 'George Simonton',
  },
  {
    key: 'Gore',
    label: 'Gore',
  },
  {
    key: 'Germano Zama',
    label: 'Germano Zama',
  },
  {
    key: 'Gudrun Grenz',
    label: 'Gudrun Grenz',
  },
  {
    key: 'Gianni Chiarini',
    label: 'Gianni Chiarini',
  },
  {
    key: 'Greylin',
    label: 'Greylin',
  },
  {
    key: 'Gaetano Navarra',
    label: 'Gaetano Navarra',
  },
  {
    key: 'Goood Jeans',
    label: 'Goood Jeans',
  },
  {
    key: 'Gortz',
    label: 'Gortz',
  },
  {
    key: 'Gianni Conti',
    label: 'Gianni Conti',
  },
  {
    key: 'Girls From Savoy',
    label: 'Girls From Savoy',
  },
  {
    key: 'Green Leaves',
    label: 'Green Leaves',
  },
  {
    key: 'Great Northwest Indigo',
    label: 'Great Northwest Indigo',
  },
  {
    key: 'Gabriele Shacuse',
    label: 'Gabriele Shacuse',
  },
  {
    key: 'Gold Rush',
    label: 'Gold Rush',
  },
  {
    key: 'Galliano',
    label: 'Galliano',
  },
  {
    key: 'Gat Rimon Pour La Redoute',
    label: 'Gat Rimon Pour La Redoute',
  },
  {
    key: 'Glitz',
    label: 'Glitz',
  },
  {
    key: 'Gi & Jo',
    label: 'Gi & Jo',
  },
  {
    key: 'Giulia Galanti',
    label: 'Giulia Galanti',
  },
  {
    key: 'Gustav Muller',
    label: 'Gustav Muller',
  },
  {
    key: 'Girls Casualwear',
    label: 'Girls Casualwear',
  },
  {
    key: 'Gianfranco Ruffini',
    label: 'Gianfranco Ruffini',
  },
  {
    key: 'Goodie Two Sleeves',
    label: 'Goodie Two Sleeves',
  },
  {
    key: 'Gina Bacconi',
    label: 'Gina Bacconi',
  },
  {
    key: 'Gumtree',
    label: 'Gumtree',
  },
  {
    key: 'Gianmarco Venturi',
    label: 'Gianmarco Venturi',
  },
  {
    key: 'G-Smack',
    label: 'G-Smack',
  },
  {
    key: 'Gino Ventory',
    label: 'Gino Ventory',
  },
  {
    key: 'Goldsign',
    label: 'Goldsign',
  },
  {
    key: 'Goosecraft',
    label: 'Goosecraft',
  },
  {
    key: 'Gossl',
    label: 'Gossl',
  },
  {
    key: 'Grayers',
    label: 'Grayers',
  },
  {
    key: 'Gumuxi',
    label: 'Gumuxi',
  },
  {
    key: 'Green Land',
    label: 'Green Land',
  },
  {
    key: 'Gennie',
    label: 'Gennie',
  },
  {
    key: 'Galeries Lafayette',
    label: 'Galeries Lafayette',
  },
  {
    key: 'Gabriela Juliani',
    label: 'Gabriela Juliani',
  },
  {
    key: 'Gangster Unit',
    label: 'Gangster Unit',
  },
  {
    key: 'Geologic',
    label: 'Geologic',
  },
  {
    key: 'Gian Marco Venturi',
    label: 'Gian Marco Venturi',
  },
  {
    key: 'Giusy',
    label: 'Giusy',
  },
  {
    key: 'Gusti Leder',
    label: 'Gusti Leder',
  },
  {
    key: 'Guglielminotti',
    label: 'Guglielminotti',
  },
  {
    key: 'Goldenim',
    label: 'Goldenim',
  },
  {
    key: 'Greenlayer',
    label: 'Greenlayer',
  },
  {
    key: 'Georg Maier',
    label: 'Georg Maier',
  },
  {
    key: 'Georgede',
    label: 'Georgede',
  },
  {
    key: 'Gina G',
    label: 'Gina G',
  },
  {
    key: 'Gw Classic',
    label: 'Gw Classic',
  },
  {
    key: 'Graham & Spencer',
    label: 'Graham & Spencer',
  },
  {
    key: 'Gennadi Hoppe',
    label: 'Gennadi Hoppe',
  },
  {
    key: 'Gabs',
    label: 'Gabs',
  },
  {
    key: 'Goldix',
    label: 'Goldix',
  },
  {
    key: 'Giorgio Kauten',
    label: 'Giorgio Kauten',
  },
  {
    key: 'Gswd',
    label: 'Gswd',
  },
  {
    key: 'Golf Pointe',
    label: 'Golf Pointe',
  },
  {
    key: 'Guidance',
    label: 'Guidance',
  },
  {
    key: 'Gree',
    label: 'Gree',
  },
  {
    key: 'Guo Dil',
    label: 'Guo Dil',
  },
  {
    key: 'Golden Goose',
    label: 'Golden Goose',
  },
  {
    key: 'Guy Leech',
    label: 'Guy Leech',
  },
  {
    key: 'Gianni Lupo',
    label: 'Gianni Lupo',
  },
  {
    key: 'Gunex',
    label: 'Gunex',
  },
  {
    key: 'Gaia Riccardi',
    label: 'Gaia Riccardi',
  },
  {
    key: 'Groom',
    label: 'Groom',
  },
  {
    key: 'Gabriella Rocha',
    label: 'Gabriella Rocha',
  },
  {
    key: 'Gussaci',
    label: 'Gussaci',
  },
  {
    key: 'Gaiam',
    label: 'Gaiam',
  },
  {
    key: 'Galinel',
    label: 'Galinel',
  },
  {
    key: 'Giovanna Milano',
    label: 'Giovanna Milano',
  },
  {
    key: 'Giambattista Valli',
    label: 'Giambattista Valli',
  },
  {
    key: 'Garb',
    label: 'Garb',
  },
  {
    key: 'Glo',
    label: 'Glo',
  },
  {
    key: 'Gianni Versace',
    label: 'Gianni Versace',
  },
  {
    key: 'Gerber',
    label: 'Gerber',
  },
  {
    key: 'Gold Hawk',
    label: 'Gold Hawk',
  },
  {
    key: 'Gotcha',
    label: 'Gotcha',
  },
  {
    key: 'Gdg Actuel',
    label: 'Gdg Actuel',
  },
  {
    key: 'Gentiluomo',
    label: 'Gentiluomo',
  },
  {
    key: 'Glacier Point',
    label: 'Glacier Point',
  },
  {
    key: 'Goldmud',
    label: 'Goldmud',
  },
  {
    key: 'Gemstone',
    label: 'Gemstone',
  },
  {
    key: 'Giudi',
    label: 'Giudi',
  },
  {
    key: 'Gogo Star Jeans',
    label: 'Gogo Star Jeans',
  },
  {
    key: 'Gina Locardo',
    label: 'Gina Locardo',
  },
  {
    key: 'Gigileer',
    label: 'Gigileer',
  },
  {
    key: "Gf Ferre'",
    label: "Gf Ferre'",
  },
  {
    key: 'Geek Hoodies',
    label: 'Geek Hoodies',
  },
  {
    key: 'Ghd',
    label: 'Ghd',
  },
  {
    key: 'Gabby Skye',
    label: 'Gabby Skye',
  },
  {
    key: 'Goldman',
    label: 'Goldman',
  },
  {
    key: 'Grene',
    label: 'Grene',
  },
  {
    key: 'Green',
    label: 'Green',
  },
  {
    key: 'Guess Mini',
    label: 'Guess Mini',
  },
  {
    key: 'Givenchy',
    label: 'Givenchy',
  },
  {
    key: 'Ginger Fizz',
    label: 'Ginger Fizz',
  },
  {
    key: 'Gems',
    label: 'Gems',
  },
  {
    key: 'Gina Monti',
    label: 'Gina Monti',
  },
  {
    key: 'Go Girl',
    label: 'Go Girl',
  },
  {
    key: 'Gypsies & Moondust',
    label: 'Gypsies & Moondust',
  },
  {
    key: 'Gil Holsters',
    label: 'Gil Holsters',
  },
  {
    key: "Giorgio Sant' Angelo",
    label: "Giorgio Sant' Angelo",
  },
  {
    key: 'H&M',
    label: 'H&M',
  },
  {
    key: 'H&M Divided',
    label: 'H&M Divided',
  },
  {
    key: 'H&M L.O.G.G.',
    label: 'H&M L.O.G.G.',
  },
  {
    key: 'Hugo Boss',
    label: 'Hugo Boss',
  },
  {
    key: 'Hallhuber',
    label: 'Hallhuber',
  },
  {
    key: 'Hollister',
    label: 'Hollister',
  },
  {
    key: 'Here+There',
    label: 'Here+There',
  },
  {
    key: 'Heine',
    label: 'Heine',
  },
  {
    key: 'H&M Conscious Collection',
    label: 'H&M Conscious Collection',
  },
  {
    key: 'Hennes',
    label: 'Hennes',
  },
  {
    key: 'Hirsch',
    label: 'Hirsch',
  },
  {
    key: 'Herrlicher',
    label: 'Herrlicher',
  },
  {
    key: 'Hema',
    label: 'Hema',
  },
  {
    key: 'H&M Mama',
    label: 'H&M Mama',
  },
  {
    key: 'Hummel',
    label: 'Hummel',
  },
  {
    key: 'H&M Sport',
    label: 'H&M Sport',
  },
  {
    key: 'Henry Morell',
    label: 'Henry Morell',
  },
  {
    key: 'Hilfiger Denim',
    label: 'Hilfiger Denim',
  },
  {
    key: 'Holly & Whyte By Lindex',
    label: 'Holly & Whyte By Lindex',
  },
  {
    key: 'Hurley',
    label: 'Hurley',
  },
  {
    key: 'H.I.S',
    label: 'H.I.S',
  },
  {
    key: 'Helly Hansen',
    label: 'Helly Hansen',
  },
  {
    key: 'Hydee By Chicoree',
    label: 'Hydee By Chicoree',
  },
  {
    key: 'Healthtex',
    label: 'Healthtex',
  },
  {
    key: 'Hackett',
    label: 'Hackett',
  },
  {
    key: "H&M B'b",
    label: "H&M B'b",
  },
  {
    key: 'Havaianas',
    label: 'Havaianas',
  },
  {
    key: 'Hessnatur',
    label: 'Hessnatur',
  },
  {
    key: 'Hauber',
    label: 'Hauber',
  },
  {
    key: 'Hudson',
    label: 'Hudson',
  },
  {
    key: "Henry Cotton's",
    label: "Henry Cotton's",
  },
  {
    key: 'Head',
    label: 'Head',
  },
  {
    key: 'Hailys',
    label: 'Hailys',
  },
  {
    key: 'Hippie Rose',
    label: 'Hippie Rose',
  },
  {
    key: 'Hoss Intropia',
    label: 'Hoss Intropia',
  },
  {
    key: 'Hobbs London',
    label: 'Hobbs London',
  },
  {
    key: 'Hajo',
    label: 'Hajo',
  },
  {
    key: 'Helline',
    label: 'Helline',
  },
  {
    key: 'Heart Hips',
    label: 'Heart Hips',
  },
  {
    key: 'Harmony',
    label: 'Harmony',
  },
  {
    key: 'Hogl',
    label: 'Hogl',
  },
  {
    key: 'Hiltl',
    label: 'Hiltl',
  },
  {
    key: 'Ha:Lu',
    label: 'Ha:Lu',
  },
  {
    key: 'Hart Schaffner Marx',
    label: 'Hart Schaffner Marx',
  },
  {
    key: 'H&M By Coachella',
    label: 'H&M By Coachella',
  },
  {
    key: 'Harmont & Blaine',
    label: 'Harmont & Blaine',
  },
  {
    key: 'Henson & Henson',
    label: 'Henson & Henson',
  },
  {
    key: 'Hunter',
    label: 'Hunter',
  },
  {
    key: 'Hamaki-Ho',
    label: 'Hamaki-Ho',
  },
  {
    key: 'Hemisphere',
    label: 'Hemisphere',
  },
  {
    key: 'Haupt',
    label: 'Haupt',
  },
  {
    key: 'Hunkydory',
    label: 'Hunkydory',
  },
  {
    key: 'H&D',
    label: 'H&D',
  },
  {
    key: 'Honey & Beau',
    label: 'Honey & Beau',
  },
  {
    key: 'H Collection',
    label: 'H Collection',
  },
  {
    key: 'Henri Lloyd',
    label: 'Henri Lloyd',
  },
  {
    key: 'Hampton Bays',
    label: 'Hampton Bays',
  },
  {
    key: 'Hathaway',
    label: 'Hathaway',
  },
  {
    key: 'House Of Denim',
    label: 'House Of Denim',
  },
  {
    key: 'Humanoid',
    label: 'Humanoid',
  },
  {
    key: 'Hm-Moden',
    label: 'Hm-Moden',
  },
  {
    key: 'Hogan',
    label: 'Hogan',
  },
  {
    key: 'High',
    label: 'High',
  },
  {
    key: 'Haute Hippie',
    label: 'Haute Hippie',
  },
  {
    key: 'Hmk',
    label: 'Hmk',
  },
  {
    key: 'H2o',
    label: 'H2o',
  },
  {
    key: 'Halti',
    label: 'Halti',
  },
  {
    key: 'Hot Kiss',
    label: 'Hot Kiss',
  },
  {
    key: 'Harris Wharf London',
    label: 'Harris Wharf London',
  },
  {
    key: 'Hard Rock',
    label: 'Hard Rock',
  },
  {
    key: 'H&M Studio',
    label: 'H&M Studio',
  },
  {
    key: 'Hot & Delicious',
    label: 'Hot & Delicious',
  },
  {
    key: 'Halston Heritage',
    label: 'Halston Heritage',
  },
  {
    key: 'Haglofs',
    label: 'Haglofs',
  },
  {
    key: 'Habitual',
    label: 'Habitual',
  },
  {
    key: 'Helmut Lang',
    label: 'Helmut Lang',
  },
  {
    key: 'Highway 1',
    label: 'Highway 1',
  },
  {
    key: 'Honor Gold',
    label: 'Honor Gold',
  },
  {
    key: 'Hd In Paris',
    label: 'Hd In Paris',
  },
  {
    key: 'Hatley',
    label: 'Hatley',
  },
  {
    key: 'Hein Gericke',
    label: 'Hein Gericke',
  },
  {
    key: 'H&H',
    label: 'H&H',
  },
  {
    key: 'Hawes And Curtis',
    label: 'Hawes And Curtis',
  },
  {
    key: 'Harlow',
    label: 'Harlow',
  },
  {
    key: 'Haspel',
    label: 'Haspel',
  },
  {
    key: 'Headquarters',
    label: 'Headquarters',
  },
  {
    key: 'Harvey',
    label: 'Harvey',
  },
  {
    key: 'Holden',
    label: 'Holden',
  },
  {
    key: 'Humberto',
    label: 'Humberto',
  },
  {
    key: 'Happy Time',
    label: 'Happy Time',
  },
  {
    key: 'Honey B Blue',
    label: 'Honey B Blue',
  },
  {
    key: 'Here & There',
    label: 'Here & There',
  },
  {
    key: 'Hale Bob',
    label: 'Hale Bob',
  },
  {
    key: 'Holborn',
    label: 'Holborn',
  },
  {
    key: 'Happy D. By Damart',
    label: 'Happy D. By Damart',
  },
  {
    key: 'Henrik Vibskov',
    label: 'Henrik Vibskov',
  },
  {
    key: 'Hoi - Polloi',
    label: 'Hoi - Polloi',
  },
  {
    key: 'Hawkshead',
    label: 'Hawkshead',
  },
  {
    key: 'Huf',
    label: 'Huf',
  },
  {
    key: 'Honey Winter',
    label: 'Honey Winter',
  },
  {
    key: 'Hanny Deep',
    label: 'Hanny Deep',
  },
  {
    key: 'Haute Monde',
    label: 'Haute Monde',
  },
  {
    key: 'Hard Tail Forever',
    label: 'Hard Tail Forever',
  },
  {
    key: 'Hache',
    label: 'Hache',
  },
  {
    key: 'Horsefeathers',
    label: 'Horsefeathers',
  },
  {
    key: 'Hickory',
    label: 'Hickory',
  },
  {
    key: 'Harrykson',
    label: 'Harrykson',
  },
  {
    key: 'Hannes Roether',
    label: 'Hannes Roether',
  },
  {
    key: 'Hot Spot',
    label: 'Hot Spot',
  },
  {
    key: 'Hot Tempered',
    label: 'Hot Tempered',
  },
  {
    key: 'Head Girl',
    label: 'Head Girl',
  },
  {
    key: 'Herringbone',
    label: 'Herringbone',
  },
  {
    key: 'Hg',
    label: 'Hg',
  },
  {
    key: 'Helene Berman',
    label: 'Helene Berman',
  },
  {
    key: 'Hudson & Rose',
    label: 'Hudson & Rose',
  },
  {
    key: 'H&M Comme Des Garcons',
    label: 'H&M Comme Des Garcons',
  },
  {
    key: 'Hammer Schmidt',
    label: 'Hammer Schmidt',
  },
  {
    key: 'Herschel',
    label: 'Herschel',
  },
  {
    key: 'Hoyvoy',
    label: 'Hoyvoy',
  },
  {
    key: 'Harris Tweed',
    label: 'Harris Tweed',
  },
  {
    key: 'Hudson & Onslow',
    label: 'Hudson & Onslow',
  },
  {
    key: 'Hkm',
    label: 'Hkm',
  },
  {
    key: 'Harvey Who?',
    label: 'Harvey Who?',
  },
  {
    key: 'Herno',
    label: 'Herno',
  },
  {
    key: 'Hofmann',
    label: 'Hofmann',
  },
  {
    key: 'Haute Society',
    label: 'Haute Society',
  },
  {
    key: 'Hi-Pro',
    label: 'Hi-Pro',
  },
  {
    key: 'Hi Buxter',
    label: 'Hi Buxter',
  },
  {
    key: 'Hot Hanger',
    label: 'Hot Hanger',
  },
  {
    key: 'Happy Girls',
    label: 'Happy Girls',
  },
  {
    key: 'Hopenlife',
    label: 'Hopenlife',
  },
  {
    key: 'Hermès',
    label: 'Hermès',
  },
  {
    key: 'H! By Henry Holland',
    label: 'H! By Henry Holland',
  },
  {
    key: 'Horka',
    label: 'Horka',
  },
  {
    key: 'Haani',
    label: 'Haani',
  },
  {
    key: 'Histreet',
    label: 'Histreet',
  },
  {
    key: 'Hooch',
    label: 'Hooch',
  },
  {
    key: 'Hip',
    label: 'Hip',
  },
  {
    key: 'Hmt',
    label: 'Hmt',
  },
  {
    key: 'Hust',
    label: 'Hust',
  },
  {
    key: 'Hawaiian',
    label: 'Hawaiian',
  },
  {
    key: 'Houdini',
    label: 'Houdini',
  },
  {
    key: 'Habbibi',
    label: 'Habbibi',
  },
  {
    key: 'Hybris',
    label: 'Hybris',
  },
  {
    key: 'Hot Cotton By Marc Ware',
    label: 'Hot Cotton By Marc Ware',
  },
  {
    key: 'Hensel Und Mortensen',
    label: 'Hensel Und Mortensen',
  },
  {
    key: 'Happy Fella',
    label: 'Happy Fella',
  },
  {
    key: 'Helmut',
    label: 'Helmut',
  },
  {
    key: 'High Society',
    label: 'High Society',
  },
  {
    key: 'Hartjes',
    label: 'Hartjes',
  },
  {
    key: 'Hugo Boss Sport',
    label: 'Hugo Boss Sport',
  },
  {
    key: 'Homy Ped',
    label: 'Homy Ped',
  },
  {
    key: 'H&M Marimekko Collection',
    label: 'H&M Marimekko Collection',
  },
  {
    key: 'H & B',
    label: 'H & B',
  },
  {
    key: 'Hizz',
    label: 'Hizz',
  },
  {
    key: 'Hudson & Barrow',
    label: 'Hudson & Barrow',
  },
  {
    key: 'Henriette Steffensen',
    label: 'Henriette Steffensen',
  },
  {
    key: 'Head Wear',
    label: 'Head Wear',
  },
  {
    key: 'Howe',
    label: 'Howe',
  },
  {
    key: 'Hkr',
    label: 'Hkr',
  },
  {
    key: 'Hickey- Freeman',
    label: 'Hickey- Freeman',
  },
  {
    key: 'Hollies Stockholm',
    label: 'Hollies Stockholm',
  },
  {
    key: 'Hei - Hei',
    label: 'Hei - Hei',
  },
  {
    key: 'Hobo',
    label: 'Hobo',
  },
  {
    key: 'Hv Society',
    label: 'Hv Society',
  },
  {
    key: 'Helene Blake',
    label: 'Helene Blake',
  },
  {
    key: 'Harley- Davidson',
    label: 'Harley- Davidson',
  },
  {
    key: 'Herluf Design',
    label: 'Herluf Design',
  },
  {
    key: 'Heilan Home',
    label: 'Heilan Home',
  },
  {
    key: 'Harlowe & Graham',
    label: 'Harlowe & Graham',
  },
  {
    key: 'Henri Bendel',
    label: 'Henri Bendel',
  },
  {
    key: "Herzen's Angelegenheit",
    label: "Herzen's Angelegenheit",
  },
  {
    key: 'Hallum',
    label: 'Hallum',
  },
  {
    key: 'Holding Horses',
    label: 'Holding Horses',
  },
  {
    key: 'Heavenly Soles',
    label: 'Heavenly Soles',
  },
  {
    key: 'Hestra',
    label: 'Hestra',
  },
  {
    key: 'Hartstrings',
    label: 'Hartstrings',
  },
  {
    key: 'Hot Soles',
    label: 'Hot Soles',
  },
  {
    key: 'Hawa',
    label: 'Hawa',
  },
  {
    key: 'Herman Geist',
    label: 'Herman Geist',
  },
  {
    key: 'Hardwood',
    label: 'Hardwood',
  },
  {
    key: 'Handsome&Honey',
    label: 'Handsome&Honey',
  },
  {
    key: 'Hatunca',
    label: 'Hatunca',
  },
  {
    key: 'Heartkiss',
    label: 'Heartkiss',
  },
  {
    key: 'Hollywood Milano',
    label: 'Hollywood Milano',
  },
  {
    key: 'Human Scale',
    label: 'Human Scale',
  },
  {
    key: 'Henley',
    label: 'Henley',
  },
  {
    key: 'Hello Kitty',
    label: 'Hello Kitty',
  },
  {
    key: 'Halo',
    label: 'Halo',
  },
  {
    key: 'Hester & Orchard',
    label: 'Hester & Orchard',
  },
  {
    key: 'Homage',
    label: 'Homage',
  },
  {
    key: 'Hindahl & Skudelny',
    label: 'Hindahl & Skudelny',
  },
  {
    key: 'Harstrings',
    label: 'Harstrings',
  },
  {
    key: 'Harrison',
    label: 'Harrison',
  },
  {
    key: 'Ichi',
    label: 'Ichi',
  },
  {
    key: 'Inc International Concepts',
    label: 'Inc International Concepts',
  },
  {
    key: 'Inblu',
    label: 'Inblu',
  },
  {
    key: 'In Wear',
    label: 'In Wear',
  },
  {
    key: 'Ikks',
    label: 'Ikks',
  },
  {
    key: 'Ivy & Oak',
    label: 'Ivy & Oak',
  },
  {
    key: 'Iceberg',
    label: 'Iceberg',
  },
  {
    key: 'Identic',
    label: 'Identic',
  },
  {
    key: 'Icepeak',
    label: 'Icepeak',
  },
  {
    key: 'Inuovo',
    label: 'Inuovo',
  },
  {
    key: 'Infinity',
    label: 'Infinity',
  },
  {
    key: 'Imperial',
    label: 'Imperial',
  },
  {
    key: 'Iz Byer',
    label: 'Iz Byer',
  },
  {
    key: 'Intimissimi',
    label: 'Intimissimi',
  },
  {
    key: 'Indiska',
    label: 'Indiska',
  },
  {
    key: 'Isolde',
    label: 'Isolde',
  },
  {
    key: 'Ice',
    label: 'Ice',
  },
  {
    key: 'Isabell Kristensen',
    label: 'Isabell Kristensen',
  },
  {
    key: 'In Linea',
    label: 'In Linea',
  },
  {
    key: 'Ikks',
    label: 'Ikks',
  },
  {
    key: 'Ilario Ferucci',
    label: 'Ilario Ferucci',
  },
  {
    key: 'Ilse Jacobsen',
    label: 'Ilse Jacobsen',
  },
  {
    key: 'Influence',
    label: 'Influence',
  },
  {
    key: 'Indikah',
    label: 'Indikah',
  },
  {
    key: 'Isabella Rodriguez',
    label: 'Isabella Rodriguez',
  },
  {
    key: 'Iro',
    label: 'Iro',
  },
  {
    key: 'Ipanema',
    label: 'Ipanema',
  },
  {
    key: 'Ivanka Trump',
    label: 'Ivanka Trump',
  },
  {
    key: 'Ici Et Maintenant',
    label: 'Ici Et Maintenant',
  },
  {
    key: 'Ishka',
    label: 'Ishka',
  },
  {
    key: 'Internacionale',
    label: 'Internacionale',
  },
  {
    key: 'Isaac Mizrahi',
    label: 'Isaac Mizrahi',
  },
  {
    key: 'I.E.',
    label: 'I.E.',
  },
  {
    key: 'Indies',
    label: 'Indies',
  },
  {
    key: 'Isabel De Pedro',
    label: 'Isabel De Pedro',
  },
  {
    key: 'Izabel',
    label: 'Izabel',
  },
  {
    key: 'Izabel London',
    label: 'Izabel London',
  },
  {
    key: 'Isabel Marant Etoile',
    label: 'Isabel Marant Etoile',
  },
  {
    key: 'Isabella',
    label: 'Isabella',
  },
  {
    key: 'Isaac Mizrahi For Target',
    label: 'Isaac Mizrahi For Target',
  },
  {
    key: 'Izzue',
    label: 'Izzue',
  },
  {
    key: 'Ivy Park',
    label: 'Ivy Park',
  },
  {
    key: 'Isabel Marant',
    label: 'Isabel Marant',
  },
  {
    key: 'Ice Blue',
    label: 'Ice Blue',
  },
  {
    key: 'Iltokoni',
    label: 'Iltokoni',
  },
  {
    key: 'Iq+ Berlin',
    label: 'Iq+ Berlin',
  },
  {
    key: 'I Medici',
    label: 'I Medici',
  },
  {
    key: 'Ideology',
    label: 'Ideology',
  },
  {
    key: 'Irina Schrotter',
    label: 'Irina Schrotter',
  },
  {
    key: 'In Gear',
    label: 'In Gear',
  },
  {
    key: 'Indian Rose',
    label: 'Indian Rose',
  },
  {
    key: 'Indigo By Marks & Spencer',
    label: 'Indigo By Marks & Spencer',
  },
  {
    key: 'Isabel & Nina',
    label: 'Isabel & Nina',
  },
  {
    key: 'Iventi',
    label: 'Iventi',
  },
  {
    key: 'Ingrid & Isabel',
    label: 'Ingrid & Isabel',
  },
  {
    key: 'Invito',
    label: 'Invito',
  },
  {
    key: 'Innocent',
    label: 'Innocent',
  },
  {
    key: 'Imps&Elfs',
    label: 'Imps&Elfs',
  },
  {
    key: 'Ippon Vintage',
    label: 'Ippon Vintage',
  },
  {
    key: 'Isabella Rhea',
    label: 'Isabella Rhea',
  },
  {
    key: 'Indochino',
    label: 'Indochino',
  },
  {
    key: 'Inspired Hearts',
    label: 'Inspired Hearts',
  },
  {
    key: 'Isabel Marant For H&M',
    label: 'Isabel Marant For H&M',
  },
  {
    key: "I'm Isola Marras",
    label: "I'm Isola Marras",
  },
  {
    key: 'Indigo Rein',
    label: 'Indigo Rein',
  },
  {
    key: "It's So Me",
    label: "It's So Me",
  },
  {
    key: "Id'est",
    label: "Id'est",
  },
  {
    key: 'Ixs',
    label: 'Ixs',
  },
  {
    key: 'Islander',
    label: 'Islander',
  },
  {
    key: 'Icing',
    label: 'Icing',
  },
  {
    key: 'Ivy Oxford',
    label: 'Ivy Oxford',
  },
  {
    key: 'Iris Von Arnim',
    label: 'Iris Von Arnim',
  },
  {
    key: 'Il Passo',
    label: 'Il Passo',
  },
  {
    key: 'Incity',
    label: 'Incity',
  },
  {
    key: 'I Pinco Pallino',
    label: 'I Pinco Pallino',
  },
  {
    key: 'Isabelle',
    label: 'Isabelle',
  },
  {
    key: 'I Blues',
    label: 'I Blues',
  },
  {
    key: 'Issa',
    label: 'Issa',
  },
  {
    key: 'Iturri',
    label: 'Iturri',
  },
  {
    key: 'Iii Lucia',
    label: 'Iii Lucia',
  },
  {
    key: 'Ischiko',
    label: 'Ischiko',
  },
  {
    key: 'Isassy',
    label: 'Isassy',
  },
  {
    key: 'Imagine By Vince Camuto',
    label: 'Imagine By Vince Camuto',
  },
  {
    key: 'Ined',
    label: 'Ined',
  },
  {
    key: 'Innippu',
    label: 'Innippu',
  },
  {
    key: 'Intensity',
    label: 'Intensity',
  },
  {
    key: 'Iclothing',
    label: 'Iclothing',
  },
  {
    key: 'Izzue Collection',
    label: 'Izzue Collection',
  },
  {
    key: 'Ivo Nikkolo',
    label: 'Ivo Nikkolo',
  },
  {
    key: 'Il Migliore',
    label: 'Il Migliore',
  },
  {
    key: 'Icb',
    label: 'Icb',
  },
  {
    key: 'Imaginary Voyage',
    label: 'Imaginary Voyage',
  },
  {
    key: 'Iblues',
    label: 'Iblues',
  },
  {
    key: 'Igi&Co',
    label: 'Igi&Co',
  },
  {
    key: 'Id Wear',
    label: 'Id Wear',
  },
  {
    key: 'Intrigue',
    label: 'Intrigue',
  },
  {
    key: 'Ivan Grundahl',
    label: 'Ivan Grundahl',
  },
  {
    key: 'Inserch',
    label: 'Inserch',
  },
  {
    key: 'Il Camiciaio Del Re',
    label: 'Il Camiciaio Del Re',
  },
  {
    key: 'Iglo + Indi',
    label: 'Iglo + Indi',
  },
  {
    key: 'Icebreaker',
    label: 'Icebreaker',
  },
  {
    key: 'Ingiliz',
    label: 'Ingiliz',
  },
  {
    key: 'Ivanhoe',
    label: 'Ivanhoe',
  },
  {
    key: 'Izi Lady',
    label: 'Izi Lady',
  },
  {
    key: 'Ispiri',
    label: 'Ispiri',
  },
  {
    key: 'I. Scenery',
    label: 'I. Scenery',
  },
  {
    key: 'Ign. Joseph',
    label: 'Ign. Joseph',
  },
  {
    key: 'Iris & Ivy',
    label: 'Iris & Ivy',
  },
  {
    key: 'Indigo Moon',
    label: 'Indigo Moon',
  },
  {
    key: 'Irelandseye',
    label: 'Irelandseye',
  },
  {
    key: 'Iefiel',
    label: 'Iefiel',
  },
  {
    key: 'Ivana Helsinki',
    label: 'Ivana Helsinki',
  },
  {
    key: 'Inaria',
    label: 'Inaria',
  },
  {
    key: 'Isabell Schmitt',
    label: 'Isabell Schmitt',
  },
  {
    key: 'Issey Miyake',
    label: 'Issey Miyake',
  },
  {
    key: 'Inthestyle',
    label: 'Inthestyle',
  },
  {
    key: 'Ice Iceberg',
    label: 'Ice Iceberg',
  },
  {
    key: 'Indigo Blue',
    label: 'Indigo Blue',
  },
  {
    key: 'Izidress',
    label: 'Izidress',
  },
  {
    key: 'Inov-8',
    label: 'Inov-8',
  },
  {
    key: 'Iwc',
    label: 'Iwc',
  },
  {
    key: 'Juicy Couture',
    label: 'Juicy Couture',
  },
  {
    key: 'Jack & Jones',
    label: 'Jack & Jones',
  },
  {
    key: 'Janina',
    label: 'Janina',
  },
  {
    key: 'Jacqueline De Yong',
    label: 'Jacqueline De Yong',
  },
  {
    key: 'Jake*S',
    label: 'Jake*S',
  },
  {
    key: 'Jennyfer',
    label: 'Jennyfer',
  },
  {
    key: "Jus D'orange",
    label: "Jus D'orange",
  },
  {
    key: 'Jones New York',
    label: 'Jones New York',
  },
  {
    key: 'Jacqueline Riu',
    label: 'Jacqueline Riu',
  },
  {
    key: 'J.Crew',
    label: 'J.Crew',
  },
  {
    key: 'Jeanswest',
    label: 'Jeanswest',
  },
  {
    key: 'Jay Jays',
    label: 'Jay Jays',
  },
  {
    key: 'John Baner',
    label: 'John Baner',
  },
  {
    key: 'Jones',
    label: 'Jones',
  },
  {
    key: 'Jennifer Taylor',
    label: 'Jennifer Taylor',
  },
  {
    key: 'Jako',
    label: 'Jako',
  },
  {
    key: 'Jessica',
    label: 'Jessica',
  },
  {
    key: 'Japan Rags By Le Temps Des Cerises',
    label: 'Japan Rags By Le Temps Des Cerises',
  },
  {
    key: 'Joy',
    label: 'Joy',
  },
  {
    key: 'Joop!',
    label: 'Joop!',
  },
  {
    key: 'Jos. A. Bank',
    label: 'Jos. A. Bank',
  },
  {
    key: 'Jack Wolfskin',
    label: 'Jack Wolfskin',
  },
  {
    key: 'Joseph Janard',
    label: 'Joseph Janard',
  },
  {
    key: 'Jeans West',
    label: 'Jeans West',
  },
  {
    key: 'Jacob',
    label: 'Jacob',
  },
  {
    key: 'Jane Norman',
    label: 'Jane Norman',
  },
  {
    key: 'Jessica Simpson',
    label: 'Jessica Simpson',
  },
  {
    key: 'Just Female',
    label: 'Just Female',
  },
  {
    key: 'Just Cavalli',
    label: 'Just Cavalli',
  },
  {
    key: 'J.Jill',
    label: 'J.Jill',
  },
  {
    key: 'Jackpot',
    label: 'Jackpot',
  },
  {
    key: 'Joseph Abboud',
    label: 'Joseph Abboud',
  },
  {
    key: 'Joanna Hope',
    label: 'Joanna Hope',
  },
  {
    key: 'Jensen',
    label: 'Jensen',
  },
  {
    key: 'J.Lindeberg',
    label: 'J.Lindeberg',
  },
  {
    key: 'J Brand',
    label: 'J Brand',
  },
  {
    key: 'Jacques Britt',
    label: 'Jacques Britt',
  },
  {
    key: 'Jsfn',
    label: 'Jsfn',
  },
  {
    key: 'Jupiter',
    label: 'Jupiter',
  },
  {
    key: 'Jean-Louis Scherrer',
    label: 'Jean-Louis Scherrer',
  },
  {
    key: 'Justfab',
    label: 'Justfab',
  },
  {
    key: 'Jockey',
    label: 'Jockey',
  },
  {
    key: 'Jamie Sadock',
    label: 'Jamie Sadock',
  },
  {
    key: 'Jigsaw',
    label: 'Jigsaw',
  },
  {
    key: 'Jjb Benson',
    label: 'Jjb Benson',
  },
  {
    key: 'Joseph',
    label: 'Joseph',
  },
  {
    key: 'Jeffrey Campbell',
    label: 'Jeffrey Campbell',
  },
  {
    key: 'Jeans & Clothes',
    label: 'Jeans & Clothes',
  },
  {
    key: 'Josephine & Co',
    label: 'Josephine & Co',
  },
  {
    key: 'James & Nicholson',
    label: 'James & Nicholson',
  },
  {
    key: 'Jc De Castelbajac',
    label: 'Jc De Castelbajac',
  },
  {
    key: 'Jennifer Lopez',
    label: 'Jennifer Lopez',
  },
  {
    key: 'Javier Simorra',
    label: 'Javier Simorra',
  },
  {
    key: 'Just For You',
    label: 'Just For You',
  },
  {
    key: 'Jilani Collection',
    label: 'Jilani Collection',
  },
  {
    key: 'Jerzees',
    label: 'Jerzees',
  },
  {
    key: 'Joseph Ribkoff',
    label: 'Joseph Ribkoff',
  },
  {
    key: 'Joan Vass',
    label: 'Joan Vass',
  },
  {
    key: 'Jil Sander',
    label: 'Jil Sander',
  },
  {
    key: 'Jaeger',
    label: 'Jaeger',
  },
  {
    key: 'Jacadi',
    label: 'Jacadi',
  },
  {
    key: 'Jeff Banks',
    label: 'Jeff Banks',
  },
  {
    key: 'Jack Morgan',
    label: 'Jack Morgan',
  },
  {
    key: 'Jost',
    label: 'Jost',
  },
  {
    key: 'J.Ferrar',
    label: 'J.Ferrar',
  },
  {
    key: 'Jd Williams',
    label: 'Jd Williams',
  },
  {
    key: 'Jasper Conran',
    label: 'Jasper Conran',
  },
  {
    key: 'Joy Miss',
    label: 'Joy Miss',
  },
  {
    key: 'Jay Jay',
    label: 'Jay Jay',
  },
  {
    key: 'John Richmond',
    label: 'John Richmond',
  },
  {
    key: 'Jinglers',
    label: 'Jinglers',
  },
  {
    key: 'Jobis',
    label: 'Jobis',
  },
  {
    key: 'Jazlyn',
    label: 'Jazlyn',
  },
  {
    key: 'Joie',
    label: 'Joie',
  },
  {
    key: 'John Lewis',
    label: 'John Lewis',
  },
  {
    key: 'Jazlyn',
    label: 'Jazlyn',
  },
  {
    key: 'James Perse',
    label: 'James Perse',
  },
  {
    key: 'Jessica London',
    label: 'Jessica London',
  },
  {
    key: 'Josef Seibel',
    label: 'Josef Seibel',
  },
  {
    key: 'Jotto',
    label: 'Jotto',
  },
  {
    key: 'Jimmy Choo',
    label: 'Jimmy Choo',
  },
  {
    key: 'J. Ferrar',
    label: 'J. Ferrar',
  },
  {
    key: 'Junk De Luxe',
    label: 'Junk De Luxe',
  },
  {
    key: 'Jerome Dahan',
    label: 'Jerome Dahan',
  },
  {
    key: 'Jon Den',
    label: 'Jon Den',
  },
  {
    key: "Jj's House",
    label: "Jj's House",
  },
  {
    key: 'Julie Fagerholt',
    label: 'Julie Fagerholt',
  },
  {
    key: 'Juvia',
    label: 'Juvia',
  },
  {
    key: 'James Lakeland',
    label: 'James Lakeland',
  },
  {
    key: 'Jijil',
    label: 'Jijil',
  },
  {
    key: 'Jacques Vert',
    label: 'Jacques Vert',
  },
  {
    key: 'Joe Taft',
    label: 'Joe Taft',
  },
  {
    key: 'Jumpo',
    label: 'Jumpo',
  },
  {
    key: 'John Varvatos',
    label: 'John Varvatos',
  },
  {
    key: 'Juffrouw Jansen',
    label: 'Juffrouw Jansen',
  },
  {
    key: 'J. Mclaughlin',
    label: 'J. Mclaughlin',
  },
  {
    key: 'Just Design',
    label: 'Just Design',
  },
  {
    key: 'Jean Biani',
    label: 'Jean Biani',
  },
  {
    key: "Jenny's",
    label: "Jenny's",
  },
  {
    key: 'Junkyard',
    label: 'Junkyard',
  },
  {
    key: 'Jascha',
    label: 'Jascha',
  },
  {
    key: 'J.W. Tabacchi',
    label: 'J.W. Tabacchi',
  },
  {
    key: 'John Meyer',
    label: 'John Meyer',
  },
  {
    key: 'Jennie & Marlis',
    label: 'Jennie & Marlis',
  },
  {
    key: 'Jean Bourget',
    label: 'Jean Bourget',
  },
  {
    key: 'Jill Stuart',
    label: 'Jill Stuart',
  },
  {
    key: 'Just Play',
    label: 'Just Play',
  },
  {
    key: 'Jump Apparel',
    label: 'Jump Apparel',
  },
  {
    key: 'Jacamo',
    label: 'Jacamo',
  },
  {
    key: 'Jules',
    label: 'Jules',
  },
  {
    key: 'John Zack',
    label: 'John Zack',
  },
  {
    key: 'Jason Wu',
    label: 'Jason Wu',
  },
  {
    key: 'Jovonnista',
    label: 'Jovonnista',
  },
  {
    key: 'Jovonna',
    label: 'Jovonna',
  },
  {
    key: 'Jessica Mc Clintock',
    label: 'Jessica Mc Clintock',
  },
  {
    key: 'Jean Marc Philippe',
    label: 'Jean Marc Philippe',
  },
  {
    key: 'Jey Cole Man',
    label: 'Jey Cole Man',
  },
  {
    key: 'Jr',
    label: 'Jr',
  },
  {
    key: 'Jument',
    label: 'Jument',
  },
  {
    key: 'Jona Michelle',
    label: 'Jona Michelle',
  },
  {
    key: 'Jean Paul Gaultier For Lindex',
    label: 'Jean Paul Gaultier For Lindex',
  },
  {
    key: 'Joha',
    label: 'Joha',
  },
  {
    key: 'Jeff Gallano',
    label: 'Jeff Gallano',
  },
  {
    key: 'John Blair',
    label: 'John Blair',
  },
  {
    key: 'Josh V',
    label: 'Josh V',
  },
  {
    key: 'John Adams',
    label: 'John Adams',
  },
  {
    key: 'J Lindeberg',
    label: 'J Lindeberg',
  },
  {
    key: 'Jessica Taylor',
    label: 'Jessica Taylor',
  },
  {
    key: 'Jan Vanderstorm',
    label: 'Jan Vanderstorm',
  },
  {
    key: 'Jorya',
    label: 'Jorya',
  },
  {
    key: 'Julius Marlow',
    label: 'Julius Marlow',
  },
  {
    key: 'Joseph Turner',
    label: 'Joseph Turner',
  },
  {
    key: 'James Pringle',
    label: 'James Pringle',
  },
  {
    key: 'Jetlag',
    label: 'Jetlag',
  },
  {
    key: 'Jean Paul Gaultier',
    label: 'Jean Paul Gaultier',
  },
  {
    key: 'Just Mex',
    label: 'Just Mex',
  },
  {
    key: 'Johann Konen',
    label: 'Johann Konen',
  },
  {
    key: 'Juicy Lucy',
    label: 'Juicy Lucy',
  },
  {
    key: 'J Valdi',
    label: 'J Valdi',
  },
  {
    key: 'Julius Hupeden',
    label: 'Julius Hupeden',
  },
  {
    key: 'Julie Brandt',
    label: 'Julie Brandt',
  },
  {
    key: 'Jofit',
    label: 'Jofit',
  },
  {
    key: 'Jack Black',
    label: 'Jack Black',
  },
  {
    key: 'Jodi Kristopher',
    label: 'Jodi Kristopher',
  },
  {
    key: 'Jacob Cohen',
    label: 'Jacob Cohen',
  },
  {
    key: 'Jo Mercer',
    label: 'Jo Mercer',
  },
  {
    key: 'Jones+Jones',
    label: 'Jones+Jones',
  },
  {
    key: 'Jsj Kids',
    label: 'Jsj Kids',
  },
  {
    key: 'Jean Paul Femme',
    label: 'Jean Paul Femme',
  },
  {
    key: 'Justing',
    label: 'Justing',
  },
  {
    key: 'Jimmy Choo For H&M',
    label: 'Jimmy Choo For H&M',
  },
  {
    key: 'Joei',
    label: 'Joei',
  },
  {
    key: 'Jak',
    label: 'Jak',
  },
  {
    key: 'J Shoes',
    label: 'J Shoes',
  },
  {
    key: 'Js Boutique',
    label: 'Js Boutique',
  },
  {
    key: 'Jumelles',
    label: 'Jumelles',
  },
  {
    key: 'Jean Louis Scherrer',
    label: 'Jean Louis Scherrer',
  },
  {
    key: 'Jen Jen House',
    label: 'Jen Jen House',
  },
  {
    key: 'Jeneric',
    label: 'Jeneric',
  },
  {
    key: 'Jaded',
    label: 'Jaded',
  },
  {
    key: 'Jb Martin',
    label: 'Jb Martin',
  },
  {
    key: 'Jet Set',
    label: 'Jet Set',
  },
  {
    key: 'Jennifer & Grace',
    label: 'Jennifer & Grace',
  },
  {
    key: 'Just Bee',
    label: 'Just Bee',
  },
  {
    key: 'Jonathan Saunders',
    label: 'Jonathan Saunders',
  },
  {
    key: 'Jj Authenthic',
    label: 'Jj Authenthic',
  },
  {
    key: 'John Charles',
    label: 'John Charles',
  },
  {
    key: 'Jams World',
    label: 'Jams World',
  },
  {
    key: 'John Smedley',
    label: 'John Smedley',
  },
  {
    key: "Je M'appelle",
    label: "Je M'appelle",
  },
  {
    key: 'J.R. Nites',
    label: 'J.R. Nites',
  },
  {
    key: 'J.Ploenes',
    label: 'J.Ploenes',
  },
  {
    key: 'John Laing',
    label: 'John Laing',
  },
  {
    key: 'Jonathan Strong',
    label: 'Jonathan Strong',
  },
  {
    key: 'Johnny Love',
    label: 'Johnny Love',
  },
  {
    key: 'Jari Zoelou',
    label: 'Jari Zoelou',
  },
  {
    key: 'Jardex',
    label: 'Jardex',
  },
  {
    key: 'Japrag',
    label: 'Japrag',
  },
  {
    key: 'Jim Rickey',
    label: 'Jim Rickey',
  },
  {
    key: 'Jam52',
    label: 'Jam52',
  },
  {
    key: 'Jetset',
    label: 'Jetset',
  },
  {
    key: 'Janique',
    label: 'Janique',
  },
  {
    key: 'Jawbreaker',
    label: 'Jawbreaker',
  },
  {
    key: 'Joules',
    label: 'Joules',
  },
  {
    key: 'Jennika',
    label: 'Jennika',
  },
  {
    key: 'Jonas Nielsen',
    label: 'Jonas Nielsen',
  },
  {
    key: 'Jophy & Co',
    label: 'Jophy & Co',
  },
  {
    key: 'Jovilia',
    label: 'Jovilia',
  },
  {
    key: 'J.Valdi',
    label: 'J.Valdi',
  },
  {
    key: 'Joan & David',
    label: 'Joan & David',
  },
  {
    key: 'John Devin',
    label: 'John Devin',
  },
  {
    key: 'Jane Alexander',
    label: 'Jane Alexander',
  },
  {
    key: "Jillian's Closet",
    label: "Jillian's Closet",
  },
  {
    key: 'Jora Collection',
    label: 'Jora Collection',
  },
  {
    key: 'Junarose',
    label: 'Junarose',
  },
  {
    key: 'Johnstons Of Elgin',
    label: 'Johnstons Of Elgin',
  },
  {
    key: 'Jek & Jo',
    label: 'Jek & Jo',
  },
  {
    key: 'Jing Yi Fashion',
    label: 'Jing Yi Fashion',
  },
  {
    key: 'Jpg By Gaultier',
    label: 'Jpg By Gaultier',
  },
  {
    key: 'Judith&Charles',
    label: 'Judith&Charles',
  },
  {
    key: 'J. Taylor',
    label: 'J. Taylor',
  },
  {
    key: 'John Roberts',
    label: 'John Roberts',
  },
  {
    key: 'Joe Nimble By Bar',
    label: 'Joe Nimble By Bar',
  },
  {
    key: 'John W. Nordstrom',
    label: 'John W. Nordstrom',
  },
  {
    key: 'John Ashfield',
    label: 'John Ashfield',
  },
  {
    key: 'Juno',
    label: 'Juno',
  },
  {
    key: 'Jeantex',
    label: 'Jeantex',
  },
  {
    key: 'Jumbo',
    label: 'Jumbo',
  },
  {
    key: 'Jario',
    label: 'Jario',
  },
  {
    key: 'Jack In A Pack',
    label: 'Jack In A Pack',
  },
  {
    key: 'J.Peterman',
    label: 'J.Peterman',
  },
  {
    key: 'Josef',
    label: 'Josef',
  },
  {
    key: 'Jean Guise By Tanatar',
    label: 'Jean Guise By Tanatar',
  },
  {
    key: 'Jovani',
    label: 'Jovani',
  },
  {
    key: 'Joana & Paola',
    label: 'Joana & Paola',
  },
  {
    key: 'Janne Lindgard',
    label: 'Janne Lindgard',
  },
  {
    key: 'Jeckerson',
    label: 'Jeckerson',
  },
  {
    key: 'Jcm Sports',
    label: 'Jcm Sports',
  },
  {
    key: 'Jan Mayen',
    label: 'Jan Mayen',
  },
  {
    key: "Jeanne D'arc",
    label: "Jeanne D'arc",
  },
  {
    key: 'J2',
    label: 'J2',
  },
  {
    key: 'J.Mclaughlin',
    label: 'J.Mclaughlin',
  },
  {
    key: 'Janina Schreck',
    label: 'Janina Schreck',
  },
  {
    key: 'Joa',
    label: 'Joa',
  },
  {
    key: 'Jdc London',
    label: 'Jdc London',
  },
  {
    key: 'Joanne Mercer',
    label: 'Joanne Mercer',
  },
  {
    key: 'Jott',
    label: 'Jott',
  },
  {
    key: 'John Kaldor',
    label: 'John Kaldor',
  },
  {
    key: 'Johnny Was',
    label: 'Johnny Was',
  },
  {
    key: 'Jumping Beans',
    label: 'Jumping Beans',
  },
  {
    key: 'Jean Paul Gaultier Maille',
    label: 'Jean Paul Gaultier Maille',
  },
  {
    key: 'Jachs Girlfriend',
    label: 'Jachs Girlfriend',
  },
  {
    key: 'Jcm',
    label: 'Jcm',
  },
  {
    key: 'Jimmy Jean',
    label: 'Jimmy Jean',
  },
  {
    key: 'Jonny-Q',
    label: 'Jonny-Q',
  },
  {
    key: 'Janet & Janet',
    label: 'Janet & Janet',
  },
  {
    key: 'Jack Spade',
    label: 'Jack Spade',
  },
  {
    key: 'Jenna & Jessie',
    label: 'Jenna & Jessie',
  },
  {
    key: 'Jilly',
    label: 'Jilly',
  },
  {
    key: 'John Galliano',
    label: 'John Galliano',
  },
  {
    key: 'Jecky Frankino',
    label: 'Jecky Frankino',
  },
  {
    key: 'Katies',
    label: 'Katies',
  },
  {
    key: 'Kookai',
    label: 'Kookai',
  },
  {
    key: 'Kaffe',
    label: 'Kaffe',
  },
  {
    key: 'Killah',
    label: 'Killah',
  },
  {
    key: 'Kappahl',
    label: 'Kappahl',
  },
  {
    key: 'Kiomi',
    label: 'Kiomi',
  },
  {
    key: 'Kenneth Cole',
    label: 'Kenneth Cole',
  },
  {
    key: 'Kingfield',
    label: 'Kingfield',
  },
  {
    key: 'Khujo',
    label: 'Khujo',
  },
  {
    key: 'Kiabi',
    label: 'Kiabi',
  },
  {
    key: 'Kenvelo',
    label: 'Kenvelo',
  },
  {
    key: 'Kappa',
    label: 'Kappa',
  },
  {
    key: 'Kenny S.',
    label: 'Kenny S.',
  },
  {
    key: 'Karen Millen',
    label: 'Karen Millen',
  },
  {
    key: 'Key Largo',
    label: 'Key Largo',
  },
  {
    key: 'Karen By Simonsen',
    label: 'Karen By Simonsen',
  },
  {
    key: 'Karl Lagerfeld',
    label: 'Karl Lagerfeld',
  },
  {
    key: 'Kays',
    label: 'Kays',
  },
  {
    key: 'Kapalua',
    label: 'Kapalua',
  },
  {
    key: 'Kocca',
    label: 'Kocca',
  },
  {
    key: 'Kensol',
    label: 'Kensol',
  },
  {
    key: 'Kendall & Kylie',
    label: 'Kendall & Kylie',
  },
  {
    key: 'Knox Rose',
    label: 'Knox Rose',
  },
  {
    key: 'Kangol',
    label: 'Kangol',
  },
  {
    key: 'Kate Storm',
    label: 'Kate Storm',
  },
  {
    key: 'Krisp',
    label: 'Krisp',
  },
  {
    key: 'Kjus',
    label: 'Kjus',
  },
  {
    key: 'K Woman',
    label: 'K Woman',
  },
  {
    key: 'Katy Perry',
    label: 'Katy Perry',
  },
  {
    key: 'Kauf',
    label: 'Kauf',
  },
  {
    key: 'Kul Ladies',
    label: 'Kul Ladies',
  },
  {
    key: 'K Tec',
    label: 'K Tec',
  },
  {
    key: 'Knowledge Cotton Apparel',
    label: 'Knowledge Cotton Apparel',
  },
  {
    key: 'Kristen Blake',
    label: 'Kristen Blake',
  },
  {
    key: 'Koralline',
    label: 'Koralline',
  },
  {
    key: 'Ks',
    label: 'Ks',
  },
  {
    key: 'Kor@Kor',
    label: 'Kor@Kor',
  },
  {
    key: 'Kitaro',
    label: 'Kitaro',
  },
  {
    key: 'Keepsake',
    label: 'Keepsake',
  },
  {
    key: 'Kardashian Kollection',
    label: 'Kardashian Kollection',
  },
  {
    key: 'Kalenji',
    label: 'Kalenji',
  },
  {
    key: 'Keds',
    label: 'Keds',
  },
  {
    key: 'Kate Spade',
    label: 'Kate Spade',
  },
  {
    key: 'Kennel & Schmenger',
    label: 'Kennel & Schmenger',
  },
  {
    key: 'Kathleen Madden',
    label: 'Kathleen Madden',
  },
  {
    key: 'Karstadt',
    label: 'Karstadt',
  },
  {
    key: 'Kiki & Koko',
    label: 'Kiki & Koko',
  },
  {
    key: 'Klaus Dilkrath',
    label: 'Klaus Dilkrath',
  },
  {
    key: 'Karostar',
    label: 'Karostar',
  },
  {
    key: 'Kenzo',
    label: 'Kenzo',
  },
  {
    key: 'Kariban',
    label: 'Kariban',
  },
  {
    key: 'Kaos',
    label: 'Kaos',
  },
  {
    key: 'Kay Unger',
    label: 'Kay Unger',
  },
  {
    key: 'Knot So Bad',
    label: 'Knot So Bad',
  },
  {
    key: 'Katherine',
    label: 'Katherine',
  },
  {
    key: 'Kafe Stigur',
    label: 'Kafe Stigur',
  },
  {
    key: 'Kenneth Roberts',
    label: 'Kenneth Roberts',
  },
  {
    key: 'Ksubi',
    label: 'Ksubi',
  },
  {
    key: 'Kemper',
    label: 'Kemper',
  },
  {
    key: 'Karen Walker',
    label: 'Karen Walker',
  },
  {
    key: 'Kings Of Indigo',
    label: 'Kings Of Indigo',
  },
  {
    key: 'Kmx',
    label: 'Kmx',
  },
  {
    key: 'Kilmanock',
    label: 'Kilmanock',
  },
  {
    key: 'Kamiseta',
    label: 'Kamiseta',
  },
  {
    key: 'K-Swiss',
    label: 'K-Swiss',
  },
  {
    key: 'Kosara Style',
    label: 'Kosara Style',
  },
  {
    key: 'Kew 159',
    label: 'Kew 159',
  },
  {
    key: 'Klein Basel',
    label: 'Klein Basel',
  },
  {
    key: 'Klass',
    label: 'Klass',
  },
  {
    key: 'Kate & Mallory',
    label: 'Kate & Mallory',
  },
  {
    key: 'Kathie Lee',
    label: 'Kathie Lee',
  },
  {
    key: 'K. Jordan',
    label: 'K. Jordan',
  },
  {
    key: 'Kiddo',
    label: 'Kiddo',
  },
  {
    key: 'Kayhan',
    label: 'Kayhan',
  },
  {
    key: 'Kamiko',
    label: 'Kamiko',
  },
  {
    key: 'Killy',
    label: 'Killy',
  },
  {
    key: 'Kuna',
    label: 'Kuna',
  },
  {
    key: 'Kalamton',
    label: 'Kalamton',
  },
  {
    key: 'Kirsten Krog',
    label: 'Kirsten Krog',
  },
  {
    key: 'Kimbertex',
    label: 'Kimbertex',
  },
  {
    key: 'Kem',
    label: 'Kem',
  },
  {
    key: 'Kaliko',
    label: 'Kaliko',
  },
  {
    key: 'Kilimanjaro',
    label: 'Kilimanjaro',
  },
  {
    key: 'Katherine Barclay',
    label: 'Katherine Barclay',
  },
  {
    key: 'K-Tec',
    label: 'K-Tec',
  },
  {
    key: 'Kikit',
    label: 'Kikit',
  },
  {
    key: 'Karla. Club',
    label: 'Karla. Club',
  },
  {
    key: 'Kama',
    label: 'Kama',
  },
  {
    key: 'K.U.S',
    label: 'K.U.S',
  },
  {
    key: 'Kamalikulture By Norma Kamali',
    label: 'Kamalikulture By Norma Kamali',
  },
  {
    key: 'Katie Todd',
    label: 'Katie Todd',
  },
  {
    key: 'Kanna',
    label: 'Kanna',
  },
  {
    key: 'Kask',
    label: 'Kask',
  },
  {
    key: 'Kancan',
    label: 'Kancan',
  },
  {
    key: 'Kali Orea',
    label: 'Kali Orea',
  },
  {
    key: 'Kesslord',
    label: 'Kesslord',
  },
  {
    key: 'Katharine Hamnett',
    label: 'Katharine Hamnett',
  },
  {
    key: 'Komarov',
    label: 'Komarov',
  },
  {
    key: 'Kids Headquarters',
    label: 'Kids Headquarters',
  },
  {
    key: 'Kiosk',
    label: 'Kiosk',
  },
  {
    key: 'Kokomarina By Antoins Coelenbier',
    label: 'Kokomarina By Antoins Coelenbier',
  },
  {
    key: 'Kirsten Schjonning',
    label: 'Kirsten Schjonning',
  },
  {
    key: 'Km Collections By Milla Bell',
    label: 'Km Collections By Milla Bell',
  },
  {
    key: 'Kenzo Jungle',
    label: 'Kenzo Jungle',
  },
  {
    key: 'Kensigton',
    label: 'Kensigton',
  },
  {
    key: 'Kim & Cami',
    label: 'Kim & Cami',
  },
  {
    key: 'Karma Denim',
    label: 'Karma Denim',
  },
  {
    key: 'Karin Glasmacher',
    label: 'Karin Glasmacher',
  },
  {
    key: 'Kravat',
    label: 'Kravat',
  },
  {
    key: 'Kmd',
    label: 'Kmd',
  },
  {
    key: 'Kira Plastinina',
    label: 'Kira Plastinina',
  },
  {
    key: 'Kristine Vikse',
    label: 'Kristine Vikse',
  },
  {
    key: 'Kmart',
    label: 'Kmart',
  },
  {
    key: 'Karaca',
    label: 'Karaca',
  },
  {
    key: 'Karl Lagerfeld For H&M',
    label: 'Karl Lagerfeld For H&M',
  },
  {
    key: "Kejzar's Cotton Line",
    label: "Kejzar's Cotton Line",
  },
  {
    key: 'Kc Parker',
    label: 'Kc Parker',
  },
  {
    key: 'Kashion',
    label: 'Kashion',
  },
  {
    key: 'Kirna Zabete',
    label: 'Kirna Zabete',
  },
  {
    key: 'Kimbaloo',
    label: 'Kimbaloo',
  },
  {
    key: 'Kejo',
    label: 'Kejo',
  },
  {
    key: 'Klaes En Myras',
    label: 'Klaes En Myras',
  },
  {
    key: 'Karhu',
    label: 'Karhu',
  },
  {
    key: 'Konrad',
    label: 'Konrad',
  },
  {
    key: 'Ksport',
    label: 'Ksport',
  },
  {
    key: 'Kgn',
    label: 'Kgn',
  },
  {
    key: 'Kids Up',
    label: 'Kids Up',
  },
  {
    key: 'Kona',
    label: 'Kona',
  },
  {
    key: 'Kgb',
    label: 'Kgb',
  },
  {
    key: 'K.Swiss',
    label: 'K.Swiss',
  },
  {
    key: 'Koppen',
    label: 'Koppen',
  },
  {
    key: 'K 155',
    label: 'K 155',
  },
  {
    key: 'Krizia',
    label: 'Krizia',
  },
  {
    key: 'Kate Harris',
    label: 'Kate Harris',
  },
  {
    key: 'Kiton',
    label: 'Kiton',
  },
  {
    key: 'Kiliwatch',
    label: 'Kiliwatch',
  },
  {
    key: 'Kubler',
    label: 'Kubler',
  },
  {
    key: 'Kauf Dich Glucklich',
    label: 'Kauf Dich Glucklich',
  },
  {
    key: 'Kangra Cashmere',
    label: 'Kangra Cashmere',
  },
  {
    key: 'Kitchoun',
    label: 'Kitchoun',
  },
  {
    key: 'Kaviar Gauche',
    label: 'Kaviar Gauche',
  },
  {
    key: 'Kent & Curwen',
    label: 'Kent & Curwen',
  },
  {
    key: 'Kaner Tekstil',
    label: 'Kaner Tekstil',
  },
  {
    key: 'Kuyichi',
    label: 'Kuyichi',
  },
  {
    key: 'Kalliste',
    label: 'Kalliste',
  },
  {
    key: 'Koah',
    label: 'Koah',
  },
  {
    key: 'Kihawo',
    label: 'Kihawo',
  },
  {
    key: 'King Kerosin',
    label: 'King Kerosin',
  },
  {
    key: 'Kimi & Co',
    label: 'Kimi & Co',
  },
  {
    key: 'Kaukko',
    label: 'Kaukko',
  },
  {
    key: 'Kiyonna',
    label: 'Kiyonna',
  },
  {
    key: 'Kools',
    label: 'Kools',
  },
  {
    key: 'Kudibal',
    label: 'Kudibal',
  },
  {
    key: 'Kaidal',
    label: 'Kaidal',
  },
  {
    key: 'Keep The Colour',
    label: 'Keep The Colour',
  },
  {
    key: "Kentucky's Western",
    label: "Kentucky's Western",
  },
  {
    key: 'Katvig',
    label: 'Katvig',
  },
  {
    key: 'Kg By Kurt Geiger',
    label: 'Kg By Kurt Geiger',
  },
  {
    key: 'Kathy Van Zeeland',
    label: 'Kathy Van Zeeland',
  },
  {
    key: 'Kenzo By H&M',
    label: 'Kenzo By H&M',
  },
  {
    key: 'Karin Morgan',
    label: 'Karin Morgan',
  },
  {
    key: 'Krizia Jeans',
    label: 'Krizia Jeans',
  },
  {
    key: 'Koral',
    label: 'Koral',
  },
  {
    key: 'Kristina Ti',
    label: 'Kristina Ti',
  },
  {
    key: 'Koh Koh',
    label: 'Koh Koh',
  },
  {
    key: 'K-Ro',
    label: 'K-Ro',
  },
  {
    key: 'Kew',
    label: 'Kew',
  },
  {
    key: 'Kinky Pera',
    label: 'Kinky Pera',
  },
  {
    key: 'Liu Jo',
    label: 'Liu Jo',
  },
  {
    key: 'Loft By Ann Taylor',
    label: 'Loft By Ann Taylor',
  },
  {
    key: 'Lee Cooper',
    label: 'Lee Cooper',
  },
  {
    key: "Levi's",
    label: "Levi's",
  },
  {
    key: 'La Fee Maraboutee',
    label: 'La Fee Maraboutee',
  },
  {
    key: 'Lindex',
    label: 'Lindex',
  },
  {
    key: 'Lascana',
    label: 'Lascana',
  },
  {
    key: 'Lefties',
    label: 'Lefties',
  },
  {
    key: 'Ltb',
    label: 'Ltb',
  },
  {
    key: 'Le Temps Des Cerises',
    label: 'Le Temps Des Cerises',
  },
  {
    key: 'Lace & Beads',
    label: 'Lace & Beads',
  },
  {
    key: 'Lauren Vidal',
    label: 'Lauren Vidal',
  },
  {
    key: 'La Redoute',
    label: 'La Redoute',
  },
  {
    key: 'Lotto',
    label: 'Lotto',
  },
  {
    key: 'Little Mistress',
    label: 'Little Mistress',
  },
  {
    key: 'Liebeskind',
    label: 'Liebeskind',
  },
  {
    key: 'Loft',
    label: 'Loft',
  },
  {
    key: 'Lee',
    label: 'Lee',
  },
  {
    key: 'Lawrence Grey',
    label: 'Lawrence Grey',
  },
  {
    key: 'Lost Ink',
    label: 'Lost Ink',
  },
  {
    key: "Land's End",
    label: "Land's End",
  },
  {
    key: 'Laura Scott',
    label: 'Laura Scott',
  },
  {
    key: 'Livergy',
    label: 'Livergy',
  },
  {
    key: 'Le Chateau',
    label: 'Le Chateau',
  },
  {
    key: 'Lc Waikiki',
    label: 'Lc Waikiki',
  },
  {
    key: 'Lacoste',
    label: 'Lacoste',
  },
  {
    key: 'La Petite Parisienne',
    label: 'La Petite Parisienne',
  },
  {
    key: "Lands' End",
    label: "Lands' End",
  },
  {
    key: 'Love Moschino',
    label: 'Love Moschino',
  },
  {
    key: 'Lipsy London',
    label: 'Lipsy London',
  },
  {
    key: 'Laurel',
    label: 'Laurel',
  },
  {
    key: 'Laura Ashley',
    label: 'Laura Ashley',
  },
  {
    key: 'Lonsdale',
    label: 'Lonsdale',
  },
  {
    key: 'Luisa Cerano',
    label: 'Luisa Cerano',
  },
  {
    key: 'Laura Kent',
    label: 'Laura Kent',
  },
  {
    key: 'Lerros',
    label: 'Lerros',
  },
  {
    key: 'Lola',
    label: 'Lola',
  },
  {
    key: 'Lisa Tossa',
    label: 'Lisa Tossa',
  },
  {
    key: 'Love Triangle',
    label: 'Love Triangle',
  },
  {
    key: 'Lane Bryant',
    label: 'Lane Bryant',
  },
  {
    key: 'Lularoe',
    label: 'Lularoe',
  },
  {
    key: 'Les Petites',
    label: 'Les Petites',
  },
  {
    key: 'Luca & Marc',
    label: 'Luca & Marc',
  },
  {
    key: 'Lcw',
    label: 'Lcw',
  },
  {
    key: 'Lyle & Scott',
    label: 'Lyle & Scott',
  },
  {
    key: 'Lurchi',
    label: 'Lurchi',
  },
  {
    key: 'La Gear',
    label: 'La Gear',
  },
  {
    key: 'Lollipops',
    label: 'Lollipops',
  },
  {
    key: 'Laundry By Shelli Segal',
    label: 'Laundry By Shelli Segal',
  },
  {
    key: 'Lord & Taylor',
    label: 'Lord & Taylor',
  },
  {
    key: 'Luhta',
    label: 'Luhta',
  },
  {
    key: 'Love To Lounge',
    label: 'Love To Lounge',
  },
  {
    key: 'Lh By La Halle',
    label: 'Lh By La Halle',
  },
  {
    key: 'Lazamani',
    label: 'Lazamani',
  },
  {
    key: 'Laura Biagiotti',
    label: 'Laura Biagiotti',
  },
  {
    key: 'Luis Trenker',
    label: 'Luis Trenker',
  },
  {
    key: 'Lemur Caribu',
    label: 'Lemur Caribu',
  },
  {
    key: 'Les Tropeziennes Par M.Belarbi',
    label: 'Les Tropeziennes Par M.Belarbi',
  },
  {
    key: 'Lulumary',
    label: 'Lulumary',
  },
  {
    key: 'Lloyd',
    label: 'Lloyd',
  },
  {
    key: 'Laura Lebek',
    label: 'Laura Lebek',
  },
  {
    key: 'Lucy',
    label: 'Lucy',
  },
  {
    key: 'L.A. Hearts By Pacsun',
    label: 'L.A. Hearts By Pacsun',
  },
  {
    key: 'Laura Jo',
    label: 'Laura Jo',
  },
  {
    key: 'Liberty',
    label: 'Liberty',
  },
  {
    key: 'Lou & Grey',
    label: 'Lou & Grey',
  },
  {
    key: 'Leonardo',
    label: 'Leonardo',
  },
  {
    key: 'Laura Petites',
    label: 'Laura Petites',
  },
  {
    key: 'Lavand.',
    label: 'Lavand.',
  },
  {
    key: 'Lauren Jeans & Co',
    label: 'Lauren Jeans & Co',
  },
  {
    key: 'Leone',
    label: 'Leone',
  },
  {
    key: 'Laura Lindor',
    label: 'Laura Lindor',
  },
  {
    key: 'Linea',
    label: 'Linea',
  },
  {
    key: 'Lyla Lyla',
    label: 'Lyla Lyla',
  },
  {
    key: 'Lasocki',
    label: 'Lasocki',
  },
  {
    key: 'Lemoniade',
    label: 'Lemoniade',
  },
  {
    key: 'Life Time',
    label: 'Life Time',
  },
  {
    key: 'Laltramoda',
    label: 'Laltramoda',
  },
  {
    key: 'Love Fire',
    label: 'Love Fire',
  },
  {
    key: 'La Martina',
    label: 'La Martina',
  },
  {
    key: 'Louis Philippe',
    label: 'Louis Philippe',
  },
  {
    key: 'Liberty Island',
    label: 'Liberty Island',
  },
  {
    key: 'Legea',
    label: 'Legea',
  },
  {
    key: 'Lodenfrey',
    label: 'Lodenfrey',
  },
  {
    key: 'List',
    label: 'List',
  },
  {
    key: 'Linea Primero',
    label: 'Linea Primero',
  },
  {
    key: 'Lisa Campione',
    label: 'Lisa Campione',
  },
  {
    key: 'Louise Orop',
    label: 'Louise Orop',
  },
  {
    key: 'Lola Paltinger',
    label: 'Lola Paltinger',
  },
  {
    key: 'Lisa Rinna',
    label: 'Lisa Rinna',
  },
  {
    key: 'Lineaemme',
    label: 'Lineaemme',
  },
  {
    key: 'Lulu & Rose',
    label: 'Lulu & Rose',
  },
  {
    key: 'Loveriche',
    label: 'Loveriche',
  },
  {
    key: 'Live 4 Truth',
    label: 'Live 4 Truth',
  },
  {
    key: 'Luisa Spagnoli',
    label: 'Luisa Spagnoli',
  },
  {
    key: 'Lorimer Nyc',
    label: 'Lorimer Nyc',
  },
  {
    key: 'Long Tall Sally',
    label: 'Long Tall Sally',
  },
  {
    key: 'Last Kiss',
    label: 'Last Kiss',
  },
  {
    key: 'Lagerfeld',
    label: 'Lagerfeld',
  },
  {
    key: 'Leona Edmiston',
    label: 'Leona Edmiston',
  },
  {
    key: 'Louben',
    label: 'Louben',
  },
  {
    key: 'Lyla & Co',
    label: 'Lyla & Co',
  },
  {
    key: 'Luisa Viola',
    label: 'Luisa Viola',
  },
  {
    key: 'Lafayette 148',
    label: 'Lafayette 148',
  },
  {
    key: 'Ladies Choice',
    label: 'Ladies Choice',
  },
  {
    key: 'Le Lis Blanc',
    label: 'Le Lis Blanc',
  },
  {
    key: 'La Ligna',
    label: 'La Ligna',
  },
  {
    key: 'La Perla',
    label: 'La Perla',
  },
  {
    key: 'Loreak Mendian',
    label: 'Loreak Mendian',
  },
  {
    key: 'Loyalty&Faith',
    label: 'Loyalty&Faith',
  },
  {
    key: 'Louise Fd',
    label: 'Louise Fd',
  },
  {
    key: 'Love Scarlett',
    label: 'Love Scarlett',
  },
  {
    key: 'Le Jean De Marithe+Francois Girbaud',
    label: 'Le Jean De Marithe+Francois Girbaud',
  },
  {
    key: 'Logic',
    label: 'Logic',
  },
  {
    key: 'Lili London',
    label: 'Lili London',
  },
  {
    key: 'Lasagrada',
    label: 'Lasagrada',
  },
  {
    key: 'Luis Steindl',
    label: 'Luis Steindl',
  },
  {
    key: "L'autre Chose",
    label: "L'autre Chose",
  },
  {
    key: 'Lizard',
    label: 'Lizard',
  },
  {
    key: 'L.Credi',
    label: 'L.Credi',
  },
  {
    key: 'Linda Matthews',
    label: 'Linda Matthews',
  },
  {
    key: 'Luciano',
    label: 'Luciano',
  },
  {
    key: 'Lafuma',
    label: 'Lafuma',
  },
  {
    key: 'Luciano Barachini',
    label: 'Luciano Barachini',
  },
  {
    key: 'Lauren Hansen',
    label: 'Lauren Hansen',
  },
  {
    key: 'Liviana Conti',
    label: 'Liviana Conti',
  },
  {
    key: 'Lulus',
    label: 'Lulus',
  },
  {
    key: 'Lmc',
    label: 'Lmc',
  },
  {
    key: 'Losan',
    label: 'Losan',
  },
  {
    key: 'Louis Feraud',
    label: 'Louis Feraud',
  },
  {
    key: 'L.Lambertazzi',
    label: 'L.Lambertazzi',
  },
  {
    key: 'La Redoute Taillissime',
    label: 'La Redoute Taillissime',
  },
  {
    key: 'Le Monde',
    label: 'Le Monde',
  },
  {
    key: 'Lashes',
    label: 'Lashes',
  },
  {
    key: 'Le Lis',
    label: 'Le Lis',
  },
  {
    key: 'Latino',
    label: 'Latino',
  },
  {
    key: 'Love Notes',
    label: 'Love Notes',
  },
  {
    key: 'Liquor & Poker',
    label: 'Liquor & Poker',
  },
  {
    key: 'Lisette L',
    label: 'Lisette L',
  },
  {
    key: 'Les Copains',
    label: 'Les Copains',
  },
  {
    key: 'Lancaster',
    label: 'Lancaster',
  },
  {
    key: 'Lina Tomei',
    label: 'Lina Tomei',
  },
  {
    key: 'Lumberjack',
    label: 'Lumberjack',
  },
  {
    key: 'Leo Guy',
    label: 'Leo Guy',
  },
  {
    key: 'Lemon Jelly',
    label: 'Lemon Jelly',
  },
  {
    key: 'Lazzerini Tiziana',
    label: 'Lazzerini Tiziana',
  },
  {
    key: 'Lexington',
    label: 'Lexington',
  },
  {
    key: 'Luii',
    label: 'Luii',
  },
  {
    key: 'Little Me',
    label: 'Little Me',
  },
  {
    key: 'Lemotion',
    label: 'Lemotion',
  },
  {
    key: 'Levelwear',
    label: 'Levelwear',
  },
  {
    key: 'Lucy Wang',
    label: 'Lucy Wang',
  },
  {
    key: 'Lala Berlin',
    label: 'Lala Berlin',
  },
  {
    key: 'Lover',
    label: 'Lover',
  },
  {
    key: 'Lorenzini',
    label: 'Lorenzini',
  },
  {
    key: 'Life/After/Denim',
    label: 'Life/After/Denim',
  },
  {
    key: 'Libertime',
    label: 'Libertime',
  },
  {
    key: 'Lili & Lala',
    label: 'Lili & Lala',
  },
  {
    key: 'Lagom',
    label: 'Lagom',
  },
  {
    key: 'Little Bitty',
    label: 'Little Bitty',
  },
  {
    key: 'Liebig',
    label: 'Liebig',
  },
  {
    key: 'Lida Baday',
    label: 'Lida Baday',
  },
  {
    key: 'Label Be',
    label: 'Label Be',
  },
  {
    key: 'Leslie Jordan',
    label: 'Leslie Jordan',
  },
  {
    key: 'Liberty Black',
    label: 'Liberty Black',
  },
  {
    key: 'Lani',
    label: 'Lani',
  },
  {
    key: 'Loevenich',
    label: 'Loevenich',
  },
  {
    key: 'Luigi Rossi',
    label: 'Luigi Rossi',
  },
  {
    key: 'Le Complici',
    label: 'Le Complici',
  },
  {
    key: 'Leiber',
    label: 'Leiber',
  },
  {
    key: 'Lady Hagen',
    label: 'Lady Hagen',
  },
  {
    key: 'Lili Gaufrette',
    label: 'Lili Gaufrette',
  },
  {
    key: 'Love & Pebbles',
    label: 'Love & Pebbles',
  },
  {
    key: 'Liquid',
    label: 'Liquid',
  },
  {
    key: 'Lysse',
    label: 'Lysse',
  },
  {
    key: 'L.K.Bennett',
    label: 'L.K.Bennett',
  },
  {
    key: "L'argentina",
    label: "L'argentina",
  },
  {
    key: 'Linea Domani',
    label: 'Linea Domani',
  },
  {
    key: 'Lion Of Porches',
    label: 'Lion Of Porches',
  },
  {
    key: 'Label Lab',
    label: 'Label Lab',
  },
  {
    key: 'Lol Vintage',
    label: 'Lol Vintage',
  },
  {
    key: 'Love Sick',
    label: 'Love Sick',
  },
  {
    key: 'Leathario',
    label: 'Leathario',
  },
  {
    key: 'Leota',
    label: 'Leota',
  },
  {
    key: 'Leidiro',
    label: 'Leidiro',
  },
  {
    key: 'Leoss',
    label: 'Leoss',
  },
  {
    key: 'Leg Avenue',
    label: 'Leg Avenue',
  },
  {
    key: 'Lucci',
    label: 'Lucci',
  },
  {
    key: 'Lorenzo Uomo',
    label: 'Lorenzo Uomo',
  },
  {
    key: 'Loro Piana',
    label: 'Loro Piana',
  },
  {
    key: "L'agence",
    label: "L'agence",
  },
  {
    key: 'Liegelind',
    label: 'Liegelind',
  },
  {
    key: 'Livi Active',
    label: 'Livi Active',
  },
  {
    key: 'Leontine Hagoort',
    label: 'Leontine Hagoort',
  },
  {
    key: 'Leifnotes',
    label: 'Leifnotes',
  },
  {
    key: 'Loft Fashion',
    label: 'Loft Fashion',
  },
  {
    key: 'Lemon Grass',
    label: 'Lemon Grass',
  },
  {
    key: 'Lowa',
    label: 'Lowa',
  },
  {
    key: 'La Mora Feliz',
    label: 'La Mora Feliz',
  },
  {
    key: 'Leith',
    label: 'Leith',
  },
  {
    key: 'Leonhard Heyden',
    label: 'Leonhard Heyden',
  },
  {
    key: 'Line & Dot',
    label: 'Line & Dot',
  },
  {
    key: 'Love Sam',
    label: 'Love Sam',
  },
  {
    key: 'Little Lass',
    label: 'Little Lass',
  },
  {
    key: 'Lounge',
    label: 'Lounge',
  },
  {
    key: 'Louis Garneau',
    label: 'Louis Garneau',
  },
  {
    key: 'Lorenz Bach',
    label: 'Lorenz Bach',
  },
  {
    key: 'Lantis',
    label: 'Lantis',
  },
  {
    key: 'Lulu',
    label: 'Lulu',
  },
  {
    key: 'Lee Baron',
    label: 'Lee Baron',
  },
  {
    key: 'Lamarthe',
    label: 'Lamarthe',
  },
  {
    key: 'Liza Lu',
    label: 'Liza Lu',
  },
  {
    key: 'Luella',
    label: 'Luella',
  },
  {
    key: 'Lothar Weikert',
    label: 'Lothar Weikert',
  },
  {
    key: 'Lucy Love',
    label: 'Lucy Love',
  },
  {
    key: 'Ladystar By Daniela Katzenberger',
    label: 'Ladystar By Daniela Katzenberger',
  },
  {
    key: 'Lea Foscati',
    label: 'Lea Foscati',
  },
  {
    key: 'Loops',
    label: 'Loops',
  },
  {
    key: 'La Donna',
    label: 'La Donna',
  },
  {
    key: 'Lucky & Coco',
    label: 'Lucky & Coco',
  },
  {
    key: 'Loffler',
    label: 'Loffler',
  },
  {
    key: 'Lord Richards',
    label: 'Lord Richards',
  },
  {
    key: 'Lily & Dan',
    label: 'Lily & Dan',
  },
  {
    key: 'Lazzarini',
    label: 'Lazzarini',
  },
  {
    key: 'L.A.M.B.',
    label: 'L.A.M.B.',
  },
  {
    key: 'Loewe',
    label: 'Loewe',
  },
  {
    key: 'Lynn Ritchie',
    label: 'Lynn Ritchie',
  },
  {
    key: 'Lilith',
    label: 'Lilith',
  },
  {
    key: 'Lifestyle By Cushion-Walk',
    label: 'Lifestyle By Cushion-Walk',
  },
  {
    key: 'Linea Cinque',
    label: 'Linea Cinque',
  },
  {
    key: 'Light In The Box',
    label: 'Light In The Box',
  },
  {
    key: 'Lounge Life',
    label: 'Lounge Life',
  },
  {
    key: 'Luciano Berni',
    label: 'Luciano Berni',
  },
  {
    key: 'L.Pucci',
    label: 'L.Pucci',
  },
  {
    key: 'Le Sarte Pettegole',
    label: 'Le Sarte Pettegole',
  },
  {
    key: 'Linksoul',
    label: 'Linksoul',
  },
  {
    key: 'Latina',
    label: 'Latina',
  },
  {
    key: 'Lilly Pulitzer',
    label: 'Lilly Pulitzer',
  },
  {
    key: 'Landmark',
    label: 'Landmark',
  },
  {
    key: 'Lily & Franc',
    label: 'Lily & Franc',
  },
  {
    key: 'Lacrosse',
    label: 'Lacrosse',
  },
  {
    key: 'Lottusse',
    label: 'Lottusse',
  },
  {
    key: 'Long Beach',
    label: 'Long Beach',
  },
  {
    key: 'Lori Michels',
    label: 'Lori Michels',
  },
  {
    key: 'Leonard',
    label: 'Leonard',
  },
  {
    key: 'Lifestride',
    label: 'Lifestride',
  },
  {
    key: 'London Rebel',
    label: 'London Rebel',
  },
  {
    key: 'Lulu Guinness',
    label: 'Lulu Guinness',
  },
  {
    key: 'L.A.B',
    label: 'L.A.B',
  },
  {
    key: 'Loeffler Randall',
    label: 'Loeffler Randall',
  },
  {
    key: 'Lienhard',
    label: 'Lienhard',
  },
  {
    key: 'Label-J',
    label: 'Label-J',
  },
  {
    key: 'Lasserre',
    label: 'Lasserre',
  },
  {
    key: 'Lodi',
    label: 'Lodi',
  },
  {
    key: 'Like A Bird',
    label: 'Like A Bird',
  },
  {
    key: 'Legroom',
    label: 'Legroom',
  },
  {
    key: 'Luca Palazzi',
    label: 'Luca Palazzi',
  },
  {
    key: 'Lady Lol',
    label: 'Lady Lol',
  },
  {
    key: 'Lotus',
    label: 'Lotus',
  },
  {
    key: 'Lara Ethnics',
    label: 'Lara Ethnics',
  },
  {
    key: 'Little One',
    label: 'Little One',
  },
  {
    key: 'Level 99',
    label: 'Level 99',
  },
  {
    key: 'Lancel',
    label: 'Lancel',
  },
  {
    key: 'Levinsky',
    label: 'Levinsky',
  },
  {
    key: "Lea's",
    label: "Lea's",
  },
  {
    key: 'Lower East',
    label: 'Lower East',
  },
  {
    key: 'La Fiorentina',
    label: 'La Fiorentina',
  },
  {
    key: 'Lara Vill',
    label: 'Lara Vill',
  },
  {
    key: 'Le Mirage',
    label: 'Le Mirage',
  },
  {
    key: 'Luca Grossi',
    label: 'Luca Grossi',
  },
  {
    key: 'Lorena Antoniazzi',
    label: 'Lorena Antoniazzi',
  },
  {
    key: 'Lorenzo Lucas',
    label: 'Lorenzo Lucas',
  },
  {
    key: 'Luca Stefani',
    label: 'Luca Stefani',
  },
  {
    key: 'La Salle',
    label: 'La Salle',
  },
  {
    key: 'Loden',
    label: 'Loden',
  },
  {
    key: 'Le Fix',
    label: 'Le Fix',
  },
  {
    key: 'Liuzin',
    label: 'Liuzin',
  },
  {
    key: 'Landleder',
    label: 'Landleder',
  },
  {
    key: 'Liliana',
    label: 'Liliana',
  },
  {
    key: 'Longchamp',
    label: 'Longchamp',
  },
  {
    key: 'Lico',
    label: 'Lico',
  },
  {
    key: 'Lcy',
    label: 'Lcy',
  },
  {
    key: 'Lisse',
    label: 'Lisse',
  },
  {
    key: 'Larose',
    label: 'Larose',
  },
  {
    key: 'Luna Llena',
    label: 'Luna Llena',
  },
  {
    key: 'La Finale',
    label: 'La Finale',
  },
  {
    key: 'Liz&Me',
    label: 'Liz&Me',
  },
  {
    key: 'Lela Rose',
    label: 'Lela Rose',
  },
  {
    key: 'Love Drobe',
    label: 'Love Drobe',
  },
  {
    key: 'Lon & Grey',
    label: 'Lon & Grey',
  },
  {
    key: 'Lee Jeans',
    label: 'Lee Jeans',
  },
  {
    key: 'Love Bite',
    label: 'Love Bite',
  },
  {
    key: 'Laundry Industry',
    label: 'Laundry Industry',
  },
  {
    key: 'Le Tanneur',
    label: 'Le Tanneur',
  },
  {
    key: 'Logo Star',
    label: 'Logo Star',
  },
  {
    key: 'Lea-Gu',
    label: 'Lea-Gu',
  },
  {
    key: 'Lusy',
    label: 'Lusy',
  },
  {
    key: 'Lauren Moshi',
    label: 'Lauren Moshi',
  },
  {
    key: 'Laurence Kazar',
    label: 'Laurence Kazar',
  },
  {
    key: 'Lorenz',
    label: 'Lorenz',
  },
  {
    key: 'Lilly Lilya',
    label: 'Lilly Lilya',
  },
  {
    key: 'Lollipop',
    label: 'Lollipop',
  },
  {
    key: "L'intervalle",
    label: "L'intervalle",
  },
  {
    key: 'Les Bonnes Copines',
    label: 'Les Bonnes Copines',
  },
  {
    key: 'Lowe Alpine',
    label: 'Lowe Alpine',
  },
  {
    key: 'Loudmouth',
    label: 'Loudmouth',
  },
  {
    key: 'Linda Moda',
    label: 'Linda Moda',
  },
  {
    key: 'Little Rebels',
    label: 'Little Rebels',
  },
  {
    key: 'Le Shark',
    label: 'Le Shark',
  },
  {
    key: 'La Bagagerie',
    label: 'La Bagagerie',
  },
  {
    key: 'Lanvin',
    label: 'Lanvin',
  },
  {
    key: 'Luxuar Limited',
    label: 'Luxuar Limited',
  },
  {
    key: 'Linda Damiani',
    label: 'Linda Damiani',
  },
  {
    key: 'Lovestruck',
    label: 'Lovestruck',
  },
  {
    key: 'Louis Vuitton',
    label: 'Louis Vuitton',
  },
  {
    key: 'Liz Lisa',
    label: 'Liz Lisa',
  },
  {
    key: 'La Sportiva',
    label: 'La Sportiva',
  },
  {
    key: 'Love Me Forever',
    label: 'Love Me Forever',
  },
  {
    key: 'Lola Ramona',
    label: 'Lola Ramona',
  },
  {
    key: 'Lin Nature',
    label: 'Lin Nature',
  },
  {
    key: 'Lr Scoop',
    label: 'Lr Scoop',
  },
  {
    key: 'Laris',
    label: 'Laris',
  },
  {
    key: 'Lamberto Losani',
    label: 'Lamberto Losani',
  },
  {
    key: 'La Chemiserie Traditionnelle',
    label: 'La Chemiserie Traditionnelle',
  },
  {
    key: 'Le Caviar',
    label: 'Le Caviar',
  },
  {
    key: 'Mango',
    label: 'Mango',
  },
  {
    key: 'Massimo Dutti',
    label: 'Massimo Dutti',
  },
  {
    key: 'Mint & Berry',
    label: 'Mint & Berry',
  },
  {
    key: 'Millers',
    label: 'Millers',
  },
  {
    key: 'Mexx',
    label: 'Mexx',
  },
  {
    key: 'More & More',
    label: 'More & More',
  },
  {
    key: 'Made In Italy',
    label: 'Made In Italy',
  },
  {
    key: "Marc O'polo",
    label: "Marc O'polo",
  },
  {
    key: 'Miss Selfridge',
    label: 'Miss Selfridge',
  },
  {
    key: 'Miss Sixty',
    label: 'Miss Sixty',
  },
  {
    key: 'Morgan',
    label: 'Morgan',
  },
  {
    key: 'Montego',
    label: 'Montego',
  },
  {
    key: 'Marks & Spencer',
    label: 'Marks & Spencer',
  },
  {
    key: 'Molly Bracken',
    label: 'Molly Bracken',
  },
  {
    key: 'Mascara',
    label: 'Mascara',
  },
  {
    key: 'Mossimo',
    label: 'Mossimo',
  },
  {
    key: 'Mohito',
    label: 'Mohito',
  },
  {
    key: 'Madonna',
    label: 'Madonna',
  },
  {
    key: 'Minimum',
    label: 'Minimum',
  },
  {
    key: 'Motivi',
    label: 'Motivi',
  },
  {
    key: 'Manguun',
    label: 'Manguun',
  },
  {
    key: 'Marc Cain',
    label: 'Marc Cain',
  },
  {
    key: 'Mavi',
    label: 'Mavi',
  },
  {
    key: 'Multiblu',
    label: 'Multiblu',
  },
  {
    key: 'Marc Aurel',
    label: 'Marc Aurel',
  },
  {
    key: 'Manoukian',
    label: 'Manoukian',
  },
  {
    key: 'Michael Kors',
    label: 'Michael Kors',
  },
  {
    key: 'Madeleine',
    label: 'Madeleine',
  },
  {
    key: 'Melrose',
    label: 'Melrose',
  },
  {
    key: 'Mcneal',
    label: 'Mcneal',
  },
  {
    key: 'Maddison',
    label: 'Maddison',
  },
  {
    key: 'Mac',
    label: 'Mac',
  },
  {
    key: 'Mbym',
    label: 'Mbym',
  },
  {
    key: 'Maria Mare',
    label: 'Maria Mare',
  },
  {
    key: 'Max&Co.',
    label: 'Max&Co.',
  },
  {
    key: 'Marciano',
    label: 'Marciano',
  },
  {
    key: 'Max Studio',
    label: 'Max Studio',
  },
  {
    key: 'Michele Boyard',
    label: 'Michele Boyard',
  },
  {
    key: 'Msch',
    label: 'Msch',
  },
  {
    key: 'Murphy & Nye',
    label: 'Murphy & Nye',
  },
  {
    key: 'Maison Scotch',
    label: 'Maison Scotch',
  },
  {
    key: 'Morgan De Toi',
    label: 'Morgan De Toi',
  },
  {
    key: 'Miss Look',
    label: 'Miss Look',
  },
  {
    key: 'Miss Etam',
    label: 'Miss Etam',
  },
  {
    key: 'My Hailys',
    label: 'My Hailys',
  },
  {
    key: 'Maje',
    label: 'Maje',
  },
  {
    key: 'Mr. F',
    label: 'Mr. F',
  },
  {
    key: 'Michael Michael Kors',
    label: 'Michael Michael Kors',
  },
  {
    key: 'Monari',
    label: 'Monari',
  },
  {
    key: 'Mim',
    label: 'Mim',
  },
  {
    key: 'Mustang',
    label: 'Mustang',
  },
  {
    key: 'Ms Mode',
    label: 'Ms Mode',
  },
  {
    key: 'Monsoon',
    label: 'Monsoon',
  },
  {
    key: 'Marella',
    label: 'Marella',
  },
  {
    key: 'Mark Adam',
    label: 'Mark Adam',
  },
  {
    key: 'Mossimo',
    label: 'Mossimo',
  },
  {
    key: 'Miss H.',
    label: 'Miss H.',
  },
  {
    key: 'Mtng',
    label: 'Mtng',
  },
  {
    key: 'Milestone',
    label: 'Milestone',
  },
  {
    key: 'Marco Pecci',
    label: 'Marco Pecci',
  },
  {
    key: 'Moda International',
    label: 'Moda International',
  },
  {
    key: 'Mos Mosh',
    label: 'Mos Mosh',
  },
  {
    key: 'Matinique',
    label: 'Matinique',
  },
  {
    key: 'My Own',
    label: 'My Own',
  },
  {
    key: 'Melvin & Hamilton',
    label: 'Melvin & Hamilton',
  },
  {
    key: 'Mauritius',
    label: 'Mauritius',
  },
  {
    key: 'Mckinley',
    label: 'Mckinley',
  },
  {
    key: 'Marks & Spencer Autograph',
    label: 'Marks & Spencer Autograph',
  },
  {
    key: 'Minnetonka',
    label: 'Minnetonka',
  },
  {
    key: 'Marc Cain Sports',
    label: 'Marc Cain Sports',
  },
  {
    key: 'Maier Sports',
    label: 'Maier Sports',
  },
  {
    key: 'Moves By Minimum',
    label: 'Moves By Minimum',
  },
  {
    key: 'Max Mara',
    label: 'Max Mara',
  },
  {
    key: 'Mado Et Les Autres',
    label: 'Mado Et Les Autres',
  },
  {
    key: 'Minus',
    label: 'Minus',
  },
  {
    key: 'Madewell',
    label: 'Madewell',
  },
  {
    key: 'Marc Jacobs',
    label: 'Marc Jacobs',
  },
  {
    key: 'Marie Lund',
    label: 'Marie Lund',
  },
  {
    key: 'Maui Wowie',
    label: 'Maui Wowie',
  },
  {
    key: 'Mads Norgaard',
    label: 'Mads Norgaard',
  },
  {
    key: 'Mantaray',
    label: 'Mantaray',
  },
  {
    key: 'Mangoon',
    label: 'Mangoon',
  },
  {
    key: 'Malvin',
    label: 'Malvin',
  },
  {
    key: 'Marcona',
    label: 'Marcona',
  },
  {
    key: 'Margittes',
    label: 'Margittes',
  },
  {
    key: 'Mc Gregor',
    label: 'Mc Gregor',
  },
  {
    key: 'Much More',
    label: 'Much More',
  },
  {
    key: 'Maze',
    label: 'Maze',
  },
  {
    key: 'Masai',
    label: 'Masai',
  },
  {
    key: 'Marc New York',
    label: 'Marc New York',
  },
  {
    key: 'Marc Lauge',
    label: 'Marc Lauge',
  },
  {
    key: 'Mandarin',
    label: 'Mandarin',
  },
  {
    key: 'Medicine',
    label: 'Medicine',
  },
  {
    key: 'Miss Anna',
    label: 'Miss Anna',
  },
  {
    key: 'Maxi Blue',
    label: 'Maxi Blue',
  },
  {
    key: 'Miso',
    label: 'Miso',
  },
  {
    key: 'M&Co.',
    label: 'M&Co.',
  },
  {
    key: 'Mirrou',
    label: 'Mirrou',
  },
  {
    key: 'Miss Posh',
    label: 'Miss Posh',
  },
  {
    key: 'Mayoral',
    label: 'Mayoral',
  },
  {
    key: 'Monteau',
    label: 'Monteau',
  },
  {
    key: 'Met',
    label: 'Met',
  },
  {
    key: 'Marco Tozzi',
    label: 'Marco Tozzi',
  },
  {
    key: 'Mammut',
    label: 'Mammut',
  },
  {
    key: 'Marcs',
    label: 'Marcs',
  },
  {
    key: 'Marie Blanc',
    label: 'Marie Blanc',
  },
  {
    key: 'Mogul',
    label: 'Mogul',
  },
  {
    key: 'Moods Of Norway',
    label: 'Moods Of Norway',
  },
  {
    key: 'Marella Sport',
    label: 'Marella Sport',
  },
  {
    key: 'Missoni',
    label: 'Missoni',
  },
  {
    key: 'My Own',
    label: 'My Own',
  },
  {
    key: 'Marni',
    label: 'Marni',
  },
  {
    key: 'Moschino',
    label: 'Moschino',
  },
  {
    key: 'Mia Linea',
    label: 'Mia Linea',
  },
  {
    key: 'Marc By Marc Jacobs',
    label: 'Marc By Marc Jacobs',
  },
  {
    key: 'Me & Me',
    label: 'Me & Me',
  },
  {
    key: 'Must Have',
    label: 'Must Have',
  },
  {
    key: 'Maerz Muenchen',
    label: 'Maerz Muenchen',
  },
  {
    key: 'Melanie Lyne',
    label: 'Melanie Lyne',
  },
  {
    key: 'Madison',
    label: 'Madison',
  },
  {
    key: 'Maxima',
    label: 'Maxima',
  },
  {
    key: 'Motive',
    label: 'Motive',
  },
  {
    key: 'Mexton',
    label: 'Mexton',
  },
  {
    key: 'Mymo',
    label: 'Mymo',
  },
  {
    key: 'Mauro Grifoni',
    label: 'Mauro Grifoni',
  },
  {
    key: 'Manoush',
    label: 'Manoush',
  },
  {
    key: 'Magasin',
    label: 'Magasin',
  },
  {
    key: 'Miley Cyrus & Max Azria',
    label: 'Miley Cyrus & Max Azria',
  },
  {
    key: 'Milano Italy',
    label: 'Milano Italy',
  },
  {
    key: 'Marithe+Francois Girbaud',
    label: 'Marithe+Francois Girbaud',
  },
  {
    key: "Meltin' Pot",
    label: "Meltin' Pot",
  },
  {
    key: 'Manila Grace',
    label: 'Manila Grace',
  },
  {
    key: 'Marco Polo',
    label: 'Marco Polo',
  },
  {
    key: 'Mc Lorene',
    label: 'Mc Lorene',
  },
  {
    key: 'Marmot',
    label: 'Marmot',
  },
  {
    key: 'Margit Brandt',
    label: 'Margit Brandt',
  },
  {
    key: 'Marie Philippe',
    label: 'Marie Philippe',
  },
  {
    key: 'Munthe',
    label: 'Munthe',
  },
  {
    key: 'Monte Carlo',
    label: 'Monte Carlo',
  },
  {
    key: 'Marina Rinaldi',
    label: 'Marina Rinaldi',
  },
  {
    key: 'Mia Moda',
    label: 'Mia Moda',
  },
  {
    key: 'Max Edition',
    label: 'Max Edition',
  },
  {
    key: 'Modeszene',
    label: 'Modeszene',
  },
  {
    key: 'Mellow Yellow',
    label: 'Mellow Yellow',
  },
  {
    key: 'Msgm',
    label: 'Msgm',
  },
  {
    key: 'Munthe Plus Simonsen',
    label: 'Munthe Plus Simonsen',
  },
  {
    key: 'Miss Captain',
    label: 'Miss Captain',
  },
  {
    key: 'Matty M',
    label: 'Matty M',
  },
  {
    key: 'Meters Bonwe',
    label: 'Meters Bonwe',
  },
  {
    key: 'Motor',
    label: 'Motor',
  },
  {
    key: 'Mjus',
    label: 'Mjus',
  },
  {
    key: 'Marc Angelo',
    label: 'Marc Angelo',
  },
  {
    key: 'Miss Swan',
    label: 'Miss Swan',
  },
  {
    key: 'Marina Yachting',
    label: 'Marina Yachting',
  },
  {
    key: 'Milo Coats',
    label: 'Milo Coats',
  },
  {
    key: 'My Size',
    label: 'My Size',
  },
  {
    key: 'Marc Anthony',
    label: 'Marc Anthony',
  },
  {
    key: 'Mountain Warehouse',
    label: 'Mountain Warehouse',
  },
  {
    key: 'Max Volmary',
    label: 'Max Volmary',
  },
  {
    key: 'M By Mosquitos',
    label: 'M By Mosquitos',
  },
  {
    key: 'Mcs',
    label: 'Mcs',
  },
  {
    key: 'Majestic Filatures',
    label: 'Majestic Filatures',
  },
  {
    key: 'Mela Purdie',
    label: 'Mela Purdie',
  },
  {
    key: 'Miss Parisienne',
    label: 'Miss Parisienne',
  },
  {
    key: 'Mitch & Co.',
    label: 'Mitch & Co.',
  },
  {
    key: 'Mabrun',
    label: 'Mabrun',
  },
  {
    key: "Mason's",
    label: "Mason's",
  },
  {
    key: 'Mod Cloth',
    label: 'Mod Cloth',
  },
  {
    key: 'Max And Cleo',
    label: 'Max And Cleo',
  },
  {
    key: 'Menbur',
    label: 'Menbur',
  },
  {
    key: 'M.O.D',
    label: 'M.O.D',
  },
  {
    key: 'Marlboro Classics',
    label: 'Marlboro Classics',
  },
  {
    key: 'Mario Barutti',
    label: 'Mario Barutti',
  },
  {
    key: 'Mps',
    label: 'Mps',
  },
  {
    key: 'Michiko Koshino',
    label: 'Michiko Koshino',
  },
  {
    key: 'Myc',
    label: 'Myc',
  },
  {
    key: 'Mariella Rosati',
    label: 'Mariella Rosati',
  },
  {
    key: 'M.Sara',
    label: 'M.Sara',
  },
  {
    key: 'Miss Bonbon',
    label: 'Miss Bonbon',
  },
  {
    key: 'Mosaic',
    label: 'Mosaic',
  },
  {
    key: 'Moores',
    label: 'Moores',
  },
  {
    key: 'Magaschoni',
    label: 'Magaschoni',
  },
  {
    key: 'Marinello',
    label: 'Marinello',
  },
  {
    key: 'Maurie & Eve',
    label: 'Maurie & Eve',
  },
  {
    key: 'Maryan Mehlhorn',
    label: 'Maryan Mehlhorn',
  },
  {
    key: 'Marie Noir',
    label: 'Marie Noir',
  },
  {
    key: 'Meli Melo',
    label: 'Meli Melo',
  },
  {
    key: "Monica's Jeans",
    label: "Monica's Jeans",
  },
  {
    key: 'Minymo',
    label: 'Minymo',
  },
  {
    key: 'Marled',
    label: 'Marled',
  },
  {
    key: 'Mint Vanilla',
    label: 'Mint Vanilla',
  },
  {
    key: 'Monro',
    label: 'Monro',
  },
  {
    key: 'Mainpol',
    label: 'Mainpol',
  },
  {
    key: 'Mac Douglas',
    label: 'Mac Douglas',
  },
  {
    key: 'Mei Mei',
    label: 'Mei Mei',
  },
  {
    key: 'Missfofo',
    label: 'Missfofo',
  },
  {
    key: 'Miss Anne',
    label: 'Miss Anne',
  },
  {
    key: 'Max Danieli',
    label: 'Max Danieli',
  },
  {
    key: 'Monterey Bay',
    label: 'Monterey Bay',
  },
  {
    key: 'Max Mara Studio',
    label: 'Max Mara Studio',
  },
  {
    key: 'Manas',
    label: 'Manas',
  },
  {
    key: 'Maine New England',
    label: 'Maine New England',
  },
  {
    key: 'Moto',
    label: 'Moto',
  },
  {
    key: 'Match It',
    label: 'Match It',
  },
  {
    key: 'Miss Goodlife',
    label: 'Miss Goodlife',
  },
  {
    key: 'Marville',
    label: 'Marville',
  },
  {
    key: 'Mmx',
    label: 'Mmx',
  },
  {
    key: 'Massimo Rebecchi',
    label: 'Massimo Rebecchi',
  },
  {
    key: 'Morning Mist',
    label: 'Morning Mist',
  },
  {
    key: 'Makai',
    label: 'Makai',
  },
  {
    key: 'Montgomery',
    label: 'Montgomery',
  },
  {
    key: 'Mk One',
    label: 'Mk One',
  },
  {
    key: 'Meaneor',
    label: 'Meaneor',
  },
  {
    key: 'Moschino Cheap And Chic',
    label: 'Moschino Cheap And Chic',
  },
  {
    key: 'Mangano',
    label: 'Mangano',
  },
  {
    key: 'Met In Jeans',
    label: 'Met In Jeans',
  },
  {
    key: 'Melissa Odabash',
    label: 'Melissa Odabash',
  },
  {
    key: 'Melting Stokholm',
    label: 'Melting Stokholm',
  },
  {
    key: 'Minx By Eva Lutz',
    label: 'Minx By Eva Lutz',
  },
  {
    key: 'Mazine',
    label: 'Mazine',
  },
  {
    key: 'Mizumi',
    label: 'Mizumi',
  },
  {
    key: 'Mosquitos',
    label: 'Mosquitos',
  },
  {
    key: 'Mix Your Style',
    label: 'Mix Your Style',
  },
  {
    key: 'Mountain Hardwear',
    label: 'Mountain Hardwear',
  },
  {
    key: 'Miss Grant',
    label: 'Miss Grant',
  },
  {
    key: 'Mother Earth',
    label: 'Mother Earth',
  },
  {
    key: 'Meadow Rue',
    label: 'Meadow Rue',
  },
  {
    key: 'Marie Méro',
    label: 'Marie Méro',
  },
  {
    key: 'Miss Daisy',
    label: 'Miss Daisy',
  },
  {
    key: 'Missoni Lindex',
    label: 'Missoni Lindex',
  },
  {
    key: 'Mandarina Duck',
    label: 'Mandarina Duck',
  },
  {
    key: 'Meine Grosse',
    label: 'Meine Grosse',
  },
  {
    key: 'Modissa',
    label: 'Modissa',
  },
  {
    key: 'Munich Freedom',
    label: 'Munich Freedom',
  },
  {
    key: 'Mat Star',
    label: 'Mat Star',
  },
  {
    key: 'Meraki',
    label: 'Meraki',
  },
  {
    key: 'Masterhand',
    label: 'Masterhand',
  },
  {
    key: 'Mikk-Line',
    label: 'Mikk-Line',
  },
  {
    key: 'Monteli',
    label: 'Monteli',
  },
  {
    key: 'Marikoo',
    label: 'Marikoo',
  },
  {
    key: 'Meng Fei Lu',
    label: 'Meng Fei Lu',
  },
  {
    key: 'Melton',
    label: 'Melton',
  },
  {
    key: 'Moscow',
    label: 'Moscow',
  },
  {
    key: 'Milly',
    label: 'Milly',
  },
  {
    key: 'Mademoiselle R By La Redoute',
    label: 'Mademoiselle R By La Redoute',
  },
  {
    key: 'Miss Kaprisse',
    label: 'Miss Kaprisse',
  },
  {
    key: 'Mariella Burani',
    label: 'Mariella Burani',
  },
  {
    key: 'Mox',
    label: 'Mox',
  },
  {
    key: 'Merci',
    label: 'Merci',
  },
  {
    key: 'Melka',
    label: 'Melka',
  },
  {
    key: 'Mrp',
    label: 'Mrp',
  },
  {
    key: 'Marimekko',
    label: 'Marimekko',
  },
  {
    key: 'Minions',
    label: 'Minions',
  },
  {
    key: 'Michel Klein',
    label: 'Michel Klein',
  },
  {
    key: 'Miro',
    label: 'Miro',
  },
  {
    key: 'Modeva',
    label: 'Modeva',
  },
  {
    key: 'Monica Magni',
    label: 'Monica Magni',
  },
  {
    key: 'Marina Grey',
    label: 'Marina Grey',
  },
  {
    key: 'Marks & Spencer Blue Harbour',
    label: 'Marks & Spencer Blue Harbour',
  },
  {
    key: 'Miken',
    label: 'Miken',
  },
  {
    key: 'Michalsky',
    label: 'Michalsky',
  },
  {
    key: 'Mizar',
    label: 'Mizar',
  },
  {
    key: 'Magic Bride',
    label: 'Magic Bride',
  },
  {
    key: 'Mandarin & Mint',
    label: 'Mandarin & Mint',
  },
  {
    key: 'Maria Intscher',
    label: 'Maria Intscher',
  },
  {
    key: 'Makiyo',
    label: 'Makiyo',
  },
  {
    key: 'Mori Lee By Madeline Gardner',
    label: 'Mori Lee By Madeline Gardner',
  },
  {
    key: 'Moonshine',
    label: 'Moonshine',
  },
  {
    key: 'Maria Grazia Severi',
    label: 'Maria Grazia Severi',
  },
  {
    key: 'Mekstone',
    label: 'Mekstone',
  },
  {
    key: 'Maria Gabrielle',
    label: 'Maria Gabrielle',
  },
  {
    key: 'Molly Jo',
    label: 'Molly Jo',
  },
  {
    key: 'Margaritaville',
    label: 'Margaritaville',
  },
  {
    key: 'Miu Miu',
    label: 'Miu Miu',
  },
  {
    key: 'Moheda',
    label: 'Moheda',
  },
  {
    key: 'Masquenada',
    label: 'Masquenada',
  },
  {
    key: 'Mz Wallace',
    label: 'Mz Wallace',
  },
  {
    key: 'Maglifico Mg',
    label: 'Maglifico Mg',
  },
  {
    key: 'Mango Tree',
    label: 'Mango Tree',
  },
  {
    key: 'Medico',
    label: 'Medico',
  },
  {
    key: 'Matalan',
    label: 'Matalan',
  },
  {
    key: 'Michèle',
    label: 'Michèle',
  },
  {
    key: 'Miss Behave',
    label: 'Miss Behave',
  },
  {
    key: 'Marsh Landing',
    label: 'Marsh Landing',
  },
  {
    key: 'Mer Du Nord',
    label: 'Mer Du Nord',
  },
  {
    key: 'Massimo Alba',
    label: 'Massimo Alba',
  },
  {
    key: 'Mac In A Sac',
    label: 'Mac In A Sac',
  },
  {
    key: 'My Christy',
    label: 'My Christy',
  },
  {
    key: 'Minoti',
    label: 'Minoti',
  },
  {
    key: 'Massimo Zardi',
    label: 'Massimo Zardi',
  },
  {
    key: 'Mandy Evans',
    label: 'Mandy Evans',
  },
  {
    key: 'Midnight Velvet',
    label: 'Midnight Velvet',
  },
  {
    key: 'Marina Hoermanseder',
    label: 'Marina Hoermanseder',
  },
  {
    key: 'Missoni Sport',
    label: 'Missoni Sport',
  },
  {
    key: 'Mes Demoiselles',
    label: 'Mes Demoiselles',
  },
  {
    key: "Member's Mark",
    label: "Member's Mark",
  },
  {
    key: 'Maeva',
    label: 'Maeva',
  },
  {
    key: 'Maya Maya',
    label: 'Maya Maya',
  },
  {
    key: 'Mouth Valley',
    label: 'Mouth Valley',
  },
  {
    key: 'Maison Chic',
    label: 'Maison Chic',
  },
  {
    key: 'M By Madonna',
    label: 'M By Madonna',
  },
  {
    key: 'Marlane',
    label: 'Marlane',
  },
  {
    key: 'Minuet Petite',
    label: 'Minuet Petite',
  },
  {
    key: 'Mugler',
    label: 'Mugler',
  },
  {
    key: 'Mighty Fine',
    label: 'Mighty Fine',
  },
  {
    key: 'Mitchell & Ness',
    label: 'Mitchell & Ness',
  },
  {
    key: 'Margo & Sebastian',
    label: 'Margo & Sebastian',
  },
  {
    key: 'Mint Green',
    label: 'Mint Green',
  },
  {
    key: 'Made In Italia',
    label: 'Made In Italia',
  },
  {
    key: 'Meindl',
    label: 'Meindl',
  },
  {
    key: 'Marypaz',
    label: 'Marypaz',
  },
  {
    key: 'Moda Aliss',
    label: 'Moda Aliss',
  },
  {
    key: 'Monrow',
    label: 'Monrow',
  },
  {
    key: 'Masters',
    label: 'Masters',
  },
  {
    key: 'Marilyn Monroe',
    label: 'Marilyn Monroe',
  },
  {
    key: 'Marks & Kattens',
    label: 'Marks & Kattens',
  },
  {
    key: 'Mis Mash',
    label: 'Mis Mash',
  },
  {
    key: 'Mat De Misaine',
    label: 'Mat De Misaine',
  },
  {
    key: 'Mk',
    label: 'Mk',
  },
  {
    key: 'Moca',
    label: 'Moca',
  },
  {
    key: 'Magnanni',
    label: 'Magnanni',
  },
  {
    key: 'Mr K',
    label: 'Mr K',
  },
  {
    key: 'Maison Martin Margiela',
    label: 'Maison Martin Margiela',
  },
  {
    key: 'Marlies Dekkers',
    label: 'Marlies Dekkers',
  },
  {
    key: 'Misook',
    label: 'Misook',
  },
  {
    key: 'Modern Lux',
    label: 'Modern Lux',
  },
  {
    key: 'Mi Pac',
    label: 'Mi Pac',
  },
  {
    key: 'Mango By Zuhair Murad',
    label: 'Mango By Zuhair Murad',
  },
  {
    key: 'Map To Mars',
    label: 'Map To Mars',
  },
  {
    key: 'Mamut',
    label: 'Mamut',
  },
  {
    key: 'Make Way',
    label: 'Make Way',
  },
  {
    key: 'Mais Il Est Ou Le Soleil',
    label: 'Mais Il Est Ou Le Soleil',
  },
  {
    key: 'Marko',
    label: 'Marko',
  },
  {
    key: 'Mamas & Papas',
    label: 'Mamas & Papas',
  },
  {
    key: 'Matisse',
    label: 'Matisse',
  },
  {
    key: 'Mardou & Dean',
    label: 'Mardou & Dean',
  },
  {
    key: 'Melie Bianco',
    label: 'Melie Bianco',
  },
  {
    key: 'Metradamo',
    label: 'Metradamo',
  },
  {
    key: 'Mother',
    label: 'Mother',
  },
  {
    key: 'Merino Blend',
    label: 'Merino Blend',
  },
  {
    key: 'Madden',
    label: 'Madden',
  },
  {
    key: 'Mark Angelo',
    label: 'Mark Angelo',
  },
  {
    key: 'Mackage',
    label: 'Mackage',
  },
  {
    key: 'Maggie Tang',
    label: 'Maggie Tang',
  },
  {
    key: 'Metal Mulisha',
    label: 'Metal Mulisha',
  },
  {
    key: 'Maria Di Ripabianca',
    label: 'Maria Di Ripabianca',
  },
  {
    key: 'Mondello',
    label: 'Mondello',
  },
  {
    key: 'Marzia',
    label: 'Marzia',
  },
  {
    key: 'Mon Petit',
    label: 'Mon Petit',
  },
  {
    key: 'Musto',
    label: 'Musto',
  },
  {
    key: 'Madame A Paris',
    label: 'Madame A Paris',
  },
  {
    key: 'Maura Styled By Claudia Sträter',
    label: 'Maura Styled By Claudia Sträter',
  },
  {
    key: 'Miss Bisou',
    label: 'Miss Bisou',
  },
  {
    key: 'My Mo',
    label: 'My Mo',
  },
  {
    key: 'Mackintosh New England',
    label: 'Mackintosh New England',
  },
  {
    key: 'Monella Vagabonda',
    label: 'Monella Vagabonda',
  },
  {
    key: 'Mfh',
    label: 'Mfh',
  },
  {
    key: 'Martino Midali',
    label: 'Martino Midali',
  },
  {
    key: 'Mirto',
    label: 'Mirto',
  },
  {
    key: 'Moonar',
    label: 'Moonar',
  },
  {
    key: 'Midali Toujours',
    label: 'Midali Toujours',
  },
  {
    key: 'Monterey Club',
    label: 'Monterey Club',
  },
  {
    key: 'Marc Chantal',
    label: 'Marc Chantal',
  },
  {
    key: 'Michael Antonio Studio',
    label: 'Michael Antonio Studio',
  },
  {
    key: 'Manuel Luciano',
    label: 'Manuel Luciano',
  },
  {
    key: 'Men Of All Nations',
    label: 'Men Of All Nations',
  },
  {
    key: 'Moda Lisa',
    label: 'Moda Lisa',
  },
  {
    key: 'Moshi Moshi Mind',
    label: 'Moshi Moshi Mind',
  },
  {
    key: 'Minx',
    label: 'Minx',
  },
  {
    key: 'Mark Astor',
    label: 'Mark Astor',
  },
  {
    key: 'Mootsies Tootsies',
    label: 'Mootsies Tootsies',
  },
  {
    key: 'Merimar',
    label: 'Merimar',
  },
  {
    key: 'Murano',
    label: 'Murano',
  },
  {
    key: 'Martine Mccutcheon',
    label: 'Martine Mccutcheon',
  },
  {
    key: 'Mary Jane',
    label: 'Mary Jane',
  },
  {
    key: 'Melissa Paige',
    label: 'Melissa Paige',
  },
  {
    key: 'Mediterraneo',
    label: 'Mediterraneo',
  },
  {
    key: 'Mojang',
    label: 'Mojang',
  },
  {
    key: 'More By Kaffe',
    label: 'More By Kaffe',
  },
  {
    key: 'Matthew Williamson',
    label: 'Matthew Williamson',
  },
  {
    key: 'Majestic Athletic',
    label: 'Majestic Athletic',
  },
  {
    key: 'Marco Di Renzo',
    label: 'Marco Di Renzo',
  },
  {
    key: 'Miss Kg',
    label: 'Miss Kg',
  },
  {
    key: 'Mazzini',
    label: 'Mazzini',
  },
  {
    key: 'Mad Kow',
    label: 'Mad Kow',
  },
  {
    key: 'Mini A Ture',
    label: 'Mini A Ture',
  },
  {
    key: 'Montego Bay Club',
    label: 'Montego Bay Club',
  },
  {
    key: 'Marisfrolg',
    label: 'Marisfrolg',
  },
  {
    key: 'Manas Lea Foscati',
    label: 'Manas Lea Foscati',
  },
  {
    key: 'Matt & Nat',
    label: 'Matt & Nat',
  },
  {
    key: 'Mywalit',
    label: 'Mywalit',
  },
  {
    key: 'Marnelli',
    label: 'Marnelli',
  },
  {
    key: 'Montage',
    label: 'Montage',
  },
  {
    key: 'Margaret M',
    label: 'Margaret M',
  },
  {
    key: 'Morgan & Co By Linda Bernell',
    label: 'Morgan & Co By Linda Bernell',
  },
  {
    key: 'Max-K',
    label: 'Max-K',
  },
  {
    key: 'Mimi Dave',
    label: 'Mimi Dave',
  },
  {
    key: 'Moschino Jeans',
    label: 'Moschino Jeans',
  },
  {
    key: 'Mariagrazia Panizzi',
    label: 'Mariagrazia Panizzi',
  },
  {
    key: 'Michele',
    label: 'Michele',
  },
  {
    key: 'Mary Line',
    label: 'Mary Line',
  },
  {
    key: 'Mbt',
    label: 'Mbt',
  },
  {
    key: 'Modalu',
    label: 'Modalu',
  },
  {
    key: 'Martissima',
    label: 'Martissima',
  },
  {
    key: 'Meghan',
    label: 'Meghan',
  },
  {
    key: 'Mercuryduo',
    label: 'Mercuryduo',
  },
  {
    key: 'Million & Women',
    label: 'Million & Women',
  },
  {
    key: 'Mantex',
    label: 'Mantex',
  },
  {
    key: 'Miss Finch',
    label: 'Miss Finch',
  },
  {
    key: 'Minimarket',
    label: 'Minimarket',
  },
  {
    key: 'Multi Fit',
    label: 'Multi Fit',
  },
  {
    key: 'Mini Club',
    label: 'Mini Club',
  },
  {
    key: 'Magee',
    label: 'Magee',
  },
  {
    key: 'Manebo',
    label: 'Manebo',
  },
  {
    key: 'Mumu Home',
    label: 'Mumu Home',
  },
  {
    key: 'Malo',
    label: 'Malo',
  },
  {
    key: 'Matthew Williamson For H&M',
    label: 'Matthew Williamson For H&M',
  },
  {
    key: 'Millau',
    label: 'Millau',
  },
  {
    key: 'Mon Coeur',
    label: 'Mon Coeur',
  },
  {
    key: 'Munro',
    label: 'Munro',
  },
  {
    key: 'Megz',
    label: 'Megz',
  },
  {
    key: 'Meravigliosa',
    label: 'Meravigliosa',
  },
  {
    key: 'Minikidz',
    label: 'Minikidz',
  },
  {
    key: 'Me To You',
    label: 'Me To You',
  },
  {
    key: 'Monn',
    label: 'Monn',
  },
  {
    key: 'Moiselle',
    label: 'Moiselle',
  },
  {
    key: 'Moda In Pelle',
    label: 'Moda In Pelle',
  },
  {
    key: 'Multi Sac',
    label: 'Multi Sac',
  },
  {
    key: 'Marallis',
    label: 'Marallis',
  },
  {
    key: 'Miniwear',
    label: 'Miniwear',
  },
  {
    key: 'Marconi',
    label: 'Marconi',
  },
  {
    key: 'Marla Wynne',
    label: 'Marla Wynne',
  },
  {
    key: 'Mart Visser',
    label: 'Mart Visser',
  },
  {
    key: 'Melanie',
    label: 'Melanie',
  },
  {
    key: 'Modern Amusement',
    label: 'Modern Amusement',
  },
  {
    key: 'Marni For H&M',
    label: 'Marni For H&M',
  },
  {
    key: 'Mustaghata',
    label: 'Mustaghata',
  },
  {
    key: 'Marina Babini',
    label: 'Marina Babini',
  },
  {
    key: 'Madame Elysees',
    label: 'Madame Elysees',
  },
  {
    key: 'Mou',
    label: 'Mou',
  },
  {
    key: 'Megias',
    label: 'Megias',
  },
  {
    key: 'Mimidave',
    label: 'Mimidave',
  },
  {
    key: 'Montagut',
    label: 'Montagut',
  },
  {
    key: 'Marine Layer',
    label: 'Marine Layer',
  },
  {
    key: 'Muchacho',
    label: 'Muchacho',
  },
  {
    key: 'Me',
    label: 'Me',
  },
  {
    key: 'Mark Mcnairy',
    label: 'Mark Mcnairy',
  },
  {
    key: 'Mcm',
    label: 'Mcm',
  },
  {
    key: 'Mishka',
    label: 'Mishka',
  },
  {
    key: 'Mic Bridal',
    label: 'Mic Bridal',
  },
  {
    key: 'Maruti',
    label: 'Maruti',
  },
  {
    key: 'Missoni For Target',
    label: 'Missoni For Target',
  },
  {
    key: 'Mulberry',
    label: 'Mulberry',
  },
  {
    key: 'Mckenzie',
    label: 'Mckenzie',
  },
  {
    key: 'Mannersache By Liebeskind',
    label: 'Mannersache By Liebeskind',
  },
  {
    key: 'Maria Westerlind',
    label: 'Maria Westerlind',
  },
  {
    key: 'Maximo',
    label: 'Maximo',
  },
  {
    key: 'Malien',
    label: 'Malien',
  },
  {
    key: 'My T-Shirt',
    label: 'My T-Shirt',
  },
  {
    key: 'Mara Hoffman',
    label: 'Mara Hoffman',
  },
  {
    key: 'Mellisa',
    label: 'Mellisa',
  },
  {
    key: 'Manon Baptiste',
    label: 'Manon Baptiste',
  },
  {
    key: 'Madam I',
    label: 'Madam I',
  },
  {
    key: 'Marc Fisher',
    label: 'Marc Fisher',
  },
  {
    key: 'Mod Atelier',
    label: 'Mod Atelier',
  },
  {
    key: 'Mc Verdi',
    label: 'Mc Verdi',
  },
  {
    key: 'Maxx New York',
    label: 'Maxx New York',
  },
  {
    key: 'Miss Blumarine',
    label: 'Miss Blumarine',
  },
  {
    key: 'Mes Soeurs Et Moi',
    label: 'Mes Soeurs Et Moi',
  },
  {
    key: 'Mih Jeans',
    label: 'Mih Jeans',
  },
  {
    key: 'Mothwurf',
    label: 'Mothwurf',
  },
  {
    key: 'Mazzonetto',
    label: 'Mazzonetto',
  },
  {
    key: 'Mont Blanc',
    label: 'Mont Blanc',
  },
  {
    key: 'Max & Moi',
    label: 'Max & Moi',
  },
  {
    key: 'Marelbo',
    label: 'Marelbo',
  },
  {
    key: 'Madison Paige',
    label: 'Madison Paige',
  },
  {
    key: 'Matix',
    label: 'Matix',
  },
  {
    key: 'Martha',
    label: 'Martha',
  },
  {
    key: 'Muxxn',
    label: 'Muxxn',
  },
  {
    key: 'Melissa Mccarthy',
    label: 'Melissa Mccarthy',
  },
  {
    key: 'Medici',
    label: 'Medici',
  },
  {
    key: 'Michael Simon',
    label: 'Michael Simon',
  },
  {
    key: 'Mellow Rose',
    label: 'Mellow Rose',
  },
  {
    key: 'Michelangelo',
    label: 'Michelangelo',
  },
  {
    key: 'Morgan Taylor',
    label: 'Morgan Taylor',
  },
  {
    key: 'Mel Richman',
    label: 'Mel Richman',
  },
  {
    key: 'Mila Schon',
    label: 'Mila Schon',
  },
  {
    key: 'Macelton',
    label: 'Macelton',
  },
  {
    key: 'Mura',
    label: 'Mura',
  },
  {
    key: 'Moncler',
    label: 'Moncler',
  },
  {
    key: 'Mollerus',
    label: 'Mollerus',
  },
  {
    key: 'Miss Candyfloss',
    label: 'Miss Candyfloss',
  },
  {
    key: 'Maddins',
    label: 'Maddins',
  },
  {
    key: 'Manuela 13',
    label: 'Manuela 13',
  },
  {
    key: 'Maison Martin Margiela By H&M',
    label: 'Maison Martin Margiela By H&M',
  },
  {
    key: 'Michel Studio',
    label: 'Michel Studio',
  },
  {
    key: 'Mariela',
    label: 'Mariela',
  },
  {
    key: 'Magisculpt',
    label: 'Magisculpt',
  },
  {
    key: 'Maxomorra',
    label: 'Maxomorra',
  },
  {
    key: 'Marmellata',
    label: 'Marmellata',
  },
  {
    key: 'Musette',
    label: 'Musette',
  },
  {
    key: 'Michaelis',
    label: 'Michaelis',
  },
  {
    key: 'Munk',
    label: 'Munk',
  },
  {
    key: 'M.J.M.',
    label: 'M.J.M.',
  },
  {
    key: 'Mia Wish',
    label: 'Mia Wish',
  },
  {
    key: 'Malloy',
    label: 'Malloy',
  },
  {
    key: 'Moulinette Soeurs',
    label: 'Moulinette Soeurs',
  },
  {
    key: 'Modus',
    label: 'Modus',
  },
  {
    key: 'Mermelada De Amor',
    label: 'Mermelada De Amor',
  },
  {
    key: 'Martinique',
    label: 'Martinique',
  },
  {
    key: 'Mavic',
    label: 'Mavic',
  },
  {
    key: 'Moving Comfort',
    label: 'Moving Comfort',
  },
  {
    key: 'Marrakech',
    label: 'Marrakech',
  },
  {
    key: 'Mollini',
    label: 'Mollini',
  },
  {
    key: 'Mountain Force',
    label: 'Mountain Force',
  },
  {
    key: 'Mandy',
    label: 'Mandy',
  },
  {
    key: 'New Look',
    label: 'New Look',
  },
  {
    key: 'Naf Naf',
    label: 'Naf Naf',
  },
  {
    key: 'Nike',
    label: 'Nike',
  },
  {
    key: 'Next',
    label: 'Next',
  },
  {
    key: 'Numph',
    label: 'Numph',
  },
  {
    key: 'Na-Kd',
    label: 'Na-Kd',
  },
  {
    key: 'New York & Company',
    label: 'New York & Company',
  },
  {
    key: 'Nkd',
    label: 'Nkd',
  },
  {
    key: 'New Balance',
    label: 'New Balance',
  },
  {
    key: 'Nine West',
    label: 'Nine West',
  },
  {
    key: 'No Boundaries',
    label: 'No Boundaries',
  },
  {
    key: 'Noa Noa',
    label: 'Noa Noa',
  },
  {
    key: 'North Sails',
    label: 'North Sails',
  },
  {
    key: 'Nile',
    label: 'Nile',
  },
  {
    key: 'Noni B',
    label: 'Noni B',
  },
  {
    key: 'No Name',
    label: 'No Name',
  },
  {
    key: 'Nautica',
    label: 'Nautica',
  },
  {
    key: 'Napapijri',
    label: 'Napapijri',
  },
  {
    key: 'Naketano',
    label: 'Naketano',
  },
  {
    key: 'Navyboot',
    label: 'Navyboot',
  },
  {
    key: 'Nly Eve',
    label: 'Nly Eve',
  },
  {
    key: 'Neo Noir',
    label: 'Neo Noir',
  },
  {
    key: 'Nice Connection',
    label: 'Nice Connection',
  },
  {
    key: 'Nly One',
    label: 'Nly One',
  },
  {
    key: 'Numoco',
    label: 'Numoco',
  },
  {
    key: 'Nuna Lie',
    label: 'Nuna Lie',
  },
  {
    key: 'Nulu By New Look',
    label: 'Nulu By New Look',
  },
  {
    key: 'Nathalie Andersen',
    label: 'Nathalie Andersen',
  },
  {
    key: 'New View',
    label: 'New View',
  },
  {
    key: 'Nicowa',
    label: 'Nicowa',
  },
  {
    key: 'Nickelodeon',
    label: 'Nickelodeon',
  },
  {
    key: 'Niza',
    label: 'Niza',
  },
  {
    key: 'Nudie Jeans Co',
    label: 'Nudie Jeans Co',
  },
  {
    key: 'Nadine H',
    label: 'Nadine H',
  },
  {
    key: 'Nicole Miller',
    label: 'Nicole Miller',
  },
  {
    key: 'Naturaline',
    label: 'Naturaline',
  },
  {
    key: 'Nike Golf',
    label: 'Nike Golf',
  },
  {
    key: 'No Excuse',
    label: 'No Excuse',
  },
  {
    key: 'Niente',
    label: 'Niente',
  },
  {
    key: 'Needle & Thread',
    label: 'Needle & Thread',
  },
  {
    key: 'N.W.D New Woman Design',
    label: 'N.W.D New Woman Design',
  },
  {
    key: 'Nrg',
    label: 'Nrg',
  },
  {
    key: 'Nydj',
    label: 'Nydj',
  },
  {
    key: 'Neighborhood',
    label: 'Neighborhood',
  },
  {
    key: 'Nolita',
    label: 'Nolita',
  },
  {
    key: 'Natural World',
    label: 'Natural World',
  },
  {
    key: 'Next Level Apparel',
    label: 'Next Level Apparel',
  },
  {
    key: 'Nebulus',
    label: 'Nebulus',
  },
  {
    key: 'New Yorker',
    label: 'New Yorker',
  },
  {
    key: 'Nly',
    label: 'Nly',
  },
  {
    key: 'Nike Acg',
    label: 'Nike Acg',
  },
  {
    key: 'New Zealand Auckland',
    label: 'New Zealand Auckland',
  },
  {
    key: 'Nordstrom',
    label: 'Nordstrom',
  },
  {
    key: 'Norah',
    label: 'Norah',
  },
  {
    key: 'Nowadays',
    label: 'Nowadays',
  },
  {
    key: 'New York Laundry',
    label: 'New York Laundry',
  },
  {
    key: 'Nio Nio',
    label: 'Nio Nio',
  },
  {
    key: 'Negative',
    label: 'Negative',
  },
  {
    key: 'Northland Proffesional',
    label: 'Northland Proffesional',
  },
  {
    key: 'Nicole By Nicole Miller',
    label: 'Nicole By Nicole Miller',
  },
  {
    key: 'Nia & Dorado',
    label: 'Nia & Dorado',
  },
  {
    key: 'Nice Collection',
    label: 'Nice Collection',
  },
  {
    key: 'Nic+Zoe',
    label: 'Nic+Zoe',
  },
  {
    key: 'Nike Running',
    label: 'Nike Running',
  },
  {
    key: 'New Penny',
    label: 'New Penny',
  },
  {
    key: 'New Imperial',
    label: 'New Imperial',
  },
  {
    key: 'Nanette Lepore',
    label: 'Nanette Lepore',
  },
  {
    key: 'Nouvelle',
    label: 'Nouvelle',
  },
  {
    key: 'Ninka',
    label: 'Ninka',
  },
  {
    key: 'Ninie',
    label: 'Ninie',
  },
  {
    key: 'Naja Lauf',
    label: 'Naja Lauf',
  },
  {
    key: 'Nice Things Paloma S.',
    label: 'Nice Things Paloma S.',
  },
  {
    key: 'Noose & Monkey',
    label: 'Noose & Monkey',
  },
  {
    key: 'New Sensation',
    label: 'New Sensation',
  },
  {
    key: 'Nu By Staff Woman',
    label: 'Nu By Staff Woman',
  },
  {
    key: 'Nickelson',
    label: 'Nickelson',
  },
  {
    key: 'Nicole Farhi',
    label: 'Nicole Farhi',
  },
  {
    key: 'Nara Camicie',
    label: 'Nara Camicie',
  },
  {
    key: 'Nine & Company',
    label: 'Nine & Company',
  },
  {
    key: 'Nvsco',
    label: 'Nvsco',
  },
  {
    key: 'Nenette',
    label: 'Nenette',
  },
  {
    key: 'Newplay Jeans',
    label: 'Newplay Jeans',
  },
  {
    key: 'Nvsco 2107',
    label: 'Nvsco 2107',
  },
  {
    key: 'Norm',
    label: 'Norm',
  },
  {
    key: 'Nitro',
    label: 'Nitro',
  },
  {
    key: 'Nutshell',
    label: 'Nutshell',
  },
  {
    key: 'Napa Valley',
    label: 'Napa Valley',
  },
  {
    key: 'Noe',
    label: 'Noe',
  },
  {
    key: 'Nightingales',
    label: 'Nightingales',
  },
  {
    key: 'Nanette',
    label: 'Nanette',
  },
  {
    key: 'Nue Notes',
    label: 'Nue Notes',
  },
  {
    key: 'Native',
    label: 'Native',
  },
  {
    key: 'Notify',
    label: 'Notify',
  },
  {
    key: 'Nathalie Chaize',
    label: 'Nathalie Chaize',
  },
  {
    key: 'Navigare',
    label: 'Navigare',
  },
  {
    key: 'Nature Resort',
    label: 'Nature Resort',
  },
  {
    key: 'Noobass',
    label: 'Noobass',
  },
  {
    key: 'New Age',
    label: 'New Age',
  },
  {
    key: 'Noémie. L',
    label: 'Noémie. L',
  },
  {
    key: 'Nordica',
    label: 'Nordica',
  },
  {
    key: 'Nr Rapisardi',
    label: 'Nr Rapisardi',
  },
  {
    key: 'Naj-Oleari',
    label: 'Naj-Oleari',
  },
  {
    key: 'Nly Blush',
    label: 'Nly Blush',
  },
  {
    key: 'Noble Project',
    label: 'Noble Project',
  },
  {
    key: 'Nockstein Trachten',
    label: 'Nockstein Trachten',
  },
  {
    key: 'Now By Stefanel',
    label: 'Now By Stefanel',
  },
  {
    key: 'Niki Livas',
    label: 'Niki Livas',
  },
  {
    key: 'Nightway',
    label: 'Nightway',
  },
  {
    key: 'Navahoo',
    label: 'Navahoo',
  },
  {
    key: 'Nt Fashion',
    label: 'Nt Fashion',
  },
  {
    key: 'Non - Iron',
    label: 'Non - Iron',
  },
  {
    key: 'Newhouse',
    label: 'Newhouse',
  },
  {
    key: 'North Star',
    label: 'North Star',
  },
  {
    key: 'Naked Wardrobe',
    label: 'Naked Wardrobe',
  },
  {
    key: 'Nico Nico',
    label: 'Nico Nico',
  },
  {
    key: 'Nika',
    label: 'Nika',
  },
  {
    key: 'Nouveau Edition',
    label: 'Nouveau Edition',
  },
  {
    key: 'Norm Thompson',
    label: 'Norm Thompson',
  },
  {
    key: 'Nobo',
    label: 'Nobo',
  },
  {
    key: "No Man's Land",
    label: "No Man's Land",
  },
  {
    key: 'Nycc',
    label: 'Nycc',
  },
  {
    key: 'Naif',
    label: 'Naif',
  },
  {
    key: 'Nylon',
    label: 'Nylon',
  },
  {
    key: 'Nino Marini',
    label: 'Nino Marini',
  },
  {
    key: 'Nomadic Traders',
    label: 'Nomadic Traders',
  },
  {
    key: 'Nino Cerruti',
    label: 'Nino Cerruti',
  },
  {
    key: 'Negrete',
    label: 'Negrete',
  },
  {
    key: 'Nightingales',
    label: 'Nightingales',
  },
  {
    key: 'Neosens',
    label: 'Neosens',
  },
  {
    key: 'N-Feel',
    label: 'N-Feel',
  },
  {
    key: 'Nathan',
    label: 'Nathan',
  },
  {
    key: 'Nommo',
    label: 'Nommo',
  },
  {
    key: 'Novara',
    label: 'Novara',
  },
  {
    key: 'Natan',
    label: 'Natan',
  },
  {
    key: 'Nat Nast',
    label: 'Nat Nast',
  },
  {
    key: 'Nesal',
    label: 'Nesal',
  },
  {
    key: 'Nove Marzo',
    label: 'Nove Marzo',
  },
  {
    key: 'Nizzin',
    label: 'Nizzin',
  },
  {
    key: 'Nicholas Reid',
    label: 'Nicholas Reid',
  },
  {
    key: 'Nookie',
    label: 'Nookie',
  },
  {
    key: 'No Stress',
    label: 'No Stress',
  },
  {
    key: 'Note',
    label: 'Note',
  },
  {
    key: 'Nick Jean',
    label: 'Nick Jean',
  },
  {
    key: 'Nly Design',
    label: 'Nly Design',
  },
  {
    key: 'Narciso Rodriguez',
    label: 'Narciso Rodriguez',
  },
  {
    key: 'Nille Philbert',
    label: 'Nille Philbert',
  },
  {
    key: "Nature's Own",
    label: "Nature's Own",
  },
  {
    key: 'Natali Silhouette',
    label: 'Natali Silhouette',
  },
  {
    key: 'Nukutavake',
    label: 'Nukutavake',
  },
  {
    key: 'Nina Ricci',
    label: 'Nina Ricci',
  },
  {
    key: 'Nicholas Millington',
    label: 'Nicholas Millington',
  },
  {
    key: 'New York Industrie',
    label: 'New York Industrie',
  },
  {
    key: 'New Cover',
    label: 'New Cover',
  },
  {
    key: 'Nova',
    label: 'Nova',
  },
  {
    key: 'Nannette',
    label: 'Nannette',
  },
  {
    key: 'Notting Hill',
    label: 'Notting Hill',
  },
  {
    key: 'Nuance',
    label: 'Nuance',
  },
  {
    key: 'Neiman Marcus Cashmere Collection',
    label: 'Neiman Marcus Cashmere Collection',
  },
  {
    key: 'Nelly Trend',
    label: 'Nelly Trend',
  },
  {
    key: 'Nanok',
    label: 'Nanok',
  },
  {
    key: 'Nicole Benisti',
    label: 'Nicole Benisti',
  },
  {
    key: 'No Kidding',
    label: 'No Kidding',
  },
  {
    key: 'Novesta',
    label: 'Novesta',
  },
  {
    key: 'Nicki Minaj For H&M',
    label: 'Nicki Minaj For H&M',
  },
  {
    key: 'Noor',
    label: 'Noor',
  },
  {
    key: 'Nick Jeans',
    label: 'Nick Jeans',
  },
  {
    key: 'Nardelli',
    label: 'Nardelli',
  },
  {
    key: 'Nowhere',
    label: 'Nowhere',
  },
  {
    key: 'Novo',
    label: 'Novo',
  },
  {
    key: 'Nyne',
    label: 'Nyne',
  },
  {
    key: 'Nick Taylor',
    label: 'Nick Taylor',
  },
  {
    key: 'Nelita',
    label: 'Nelita',
  },
  {
    key: 'Nina Piccalino',
    label: 'Nina Piccalino',
  },
  {
    key: 'Nicola Finetti',
    label: 'Nicola Finetti',
  },
  {
    key: 'North River',
    label: 'North River',
  },
  {
    key: 'Nomis',
    label: 'Nomis',
  },
  {
    key: 'Nic & Dom',
    label: 'Nic & Dom',
  },
  {
    key: 'Norgaard',
    label: 'Norgaard',
  },
  {
    key: 'North Style',
    label: 'North Style',
  },
  {
    key: 'Newplay',
    label: 'Newplay',
  },
  {
    key: 'Nike Running',
    label: 'Nike Running',
  },
  {
    key: 'N.O.C.C',
    label: 'N.O.C.C',
  },
  {
    key: 'Need You',
    label: 'Need You',
  },
  {
    key: 'Nygardsanna',
    label: 'Nygardsanna',
  },
  {
    key: 'Nursery Time',
    label: 'Nursery Time',
  },
  {
    key: 'Neslay',
    label: 'Neslay',
  },
  {
    key: 'Nigel Hall',
    label: 'Nigel Hall',
  },
  {
    key: 'Norrona',
    label: 'Norrona',
  },
  {
    key: 'N.W.3',
    label: 'N.W.3',
  },
  {
    key: 'Nagpeople',
    label: 'Nagpeople',
  },
  {
    key: 'Noro',
    label: 'Noro',
  },
  {
    key: 'Naturalife',
    label: 'Naturalife',
  },
  {
    key: 'Nathalie Vleeschouwer',
    label: 'Nathalie Vleeschouwer',
  },
  {
    key: 'Norsewear',
    label: 'Norsewear',
  },
  {
    key: 'Nlw',
    label: 'Nlw',
  },
  {
    key: 'Neil Barrett',
    label: 'Neil Barrett',
  },
  {
    key: 'Nrg',
    label: 'Nrg',
  },
  {
    key: 'Niu',
    label: 'Niu',
  },
  {
    key: 'Nikki Me',
    label: 'Nikki Me',
  },
  {
    key: 'Nientie',
    label: 'Nientie',
  },
  {
    key: '',
    label: '',
  },
  {
    key: 'O',
    label: 'O',
  },
  {
    key: 'Only',
    label: 'Only',
  },
  {
    key: 'Orsay',
    label: 'Orsay',
  },
  {
    key: 'Originals By Jack & Jones',
    label: 'Originals By Jack & Jones',
  },
  {
    key: 'Old Navy',
    label: 'Old Navy',
  },
  {
    key: 'Object',
    label: 'Object',
  },
  {
    key: 'Only & Sons',
    label: 'Only & Sons',
  },
  {
    key: 'Oasis',
    label: 'Oasis',
  },
  {
    key: 'Opus',
    label: 'Opus',
  },
  {
    key: 'Okay',
    label: 'Okay',
  },
  {
    key: 'Orna Farho',
    label: 'Orna Farho',
  },
  {
    key: 'Oviesse',
    label: 'Oviesse',
  },
  {
    key: 'Oysho',
    label: 'Oysho',
  },
  {
    key: 'Oui',
    label: 'Oui',
  },
  {
    key: "O'neill",
    label: "O'neill",
  },
  {
    key: 'Olymp',
    label: 'Olymp',
  },
  {
    key: 'Olsen',
    label: 'Olsen',
  },
  {
    key: 'Odlo',
    label: 'Odlo',
  },
  {
    key: 'One Touch',
    label: 'One Touch',
  },
  {
    key: 'Orwell',
    label: 'Orwell',
  },
  {
    key: 'One Love',
    label: 'One Love',
  },
  {
    key: 'Oakley',
    label: 'Oakley',
  },
  {
    key: 'Oxxy',
    label: 'Oxxy',
  },
  {
    key: 'Otto Kern',
    label: 'Otto Kern',
  },
  {
    key: 'One By One',
    label: 'One By One',
  },
  {
    key: 'Outfitters Nation',
    label: 'Outfitters Nation',
  },
  {
    key: 'One Step',
    label: 'One Step',
  },
  {
    key: 'Oltre',
    label: 'Oltre',
  },
  {
    key: 'Oroton',
    label: 'Oroton',
  },
  {
    key: 'Oakwood',
    label: 'Oakwood',
  },
  {
    key: 'Obaibi',
    label: 'Obaibi',
  },
  {
    key: 'Oxbow',
    label: 'Oxbow',
  },
  {
    key: 'One Two',
    label: 'One Two',
  },
  {
    key: 'Obey',
    label: 'Obey',
  },
  {
    key: 'Oxmo',
    label: 'Oxmo',
  },
  {
    key: 'One Green Elephant',
    label: 'One Green Elephant',
  },
  {
    key: 'Ole By Koton',
    label: 'Ole By Koton',
  },
  {
    key: 'One More Story',
    label: 'One More Story',
  },
  {
    key: "Ottod'ame",
    label: "Ottod'ame",
  },
  {
    key: 'Ouiset',
    label: 'Ouiset',
  },
  {
    key: 'O.Ha',
    label: 'O.Ha',
  },
  {
    key: 'Odd Molly',
    label: 'Odd Molly',
  },
  {
    key: 'Ore10',
    label: 'Ore10',
  },
  {
    key: 'One World',
    label: 'One World',
  },
  {
    key: 'O Bag',
    label: 'O Bag',
  },
  {
    key: 'Olga Berg',
    label: 'Olga Berg',
  },
  {
    key: 'Oska',
    label: 'Oska',
  },
  {
    key: 'Okeysi',
    label: 'Okeysi',
  },
  {
    key: 'Otelia',
    label: 'Otelia',
  },
  {
    key: 'Olivia',
    label: 'Olivia',
  },
  {
    key: 'Oora By M. Pokora',
    label: 'Oora By M. Pokora',
  },
  {
    key: 'Onzie',
    label: 'Onzie',
  },
  {
    key: 'Obermeyer',
    label: 'Obermeyer',
  },
  {
    key: 'Okie Dokie',
    label: 'Okie Dokie',
  },
  {
    key: 'Outdoor',
    label: 'Outdoor',
  },
  {
    key: 'Odermark',
    label: 'Odermark',
  },
  {
    key: 'Obsel',
    label: 'Obsel',
  },
  {
    key: 'Orice Style',
    label: 'Orice Style',
  },
  {
    key: 'Olympia',
    label: 'Olympia',
  },
  {
    key: 'Orientique',
    label: 'Orientique',
  },
  {
    key: 'Oml London',
    label: 'Oml London',
  },
  {
    key: 'Opening Ceremony',
    label: 'Opening Ceremony',
  },
  {
    key: 'Olivia Sky',
    label: 'Olivia Sky',
  },
  {
    key: 'Opinion By Spengler',
    label: 'Opinion By Spengler',
  },
  {
    key: 'Oliver Grant',
    label: 'Oliver Grant',
  },
  {
    key: 'Osop',
    label: 'Osop',
  },
  {
    key: 'Obscure',
    label: 'Obscure',
  },
  {
    key: 'Orlebar Brown',
    label: 'Orlebar Brown',
  },
  {
    key: 'On Parle De Vous',
    label: 'On Parle De Vous',
  },
  {
    key: 'Oliver',
    label: 'Oliver',
  },
  {
    key: 'Ocean & Earth',
    label: 'Ocean & Earth',
  },
  {
    key: 'Ossie Clark',
    label: 'Ossie Clark',
  },
  {
    key: 'Orion',
    label: 'Orion',
  },
  {
    key: 'Oak Hill',
    label: 'Oak Hill',
  },
  {
    key: 'Oleg Cassini',
    label: 'Oleg Cassini',
  },
  {
    key: 'Oliver Spencer',
    label: 'Oliver Spencer',
  },
  {
    key: 'Ocean Drive',
    label: 'Ocean Drive',
  },
  {
    key: 'Old Ridel',
    label: 'Old Ridel',
  },
  {
    key: 'Oliver Jung',
    label: 'Oliver Jung',
  },
  {
    key: 'Office',
    label: 'Office',
  },
  {
    key: 'One X Oneteaspoon',
    label: 'One X Oneteaspoon',
  },
  {
    key: 'Oaks Valley',
    label: 'Oaks Valley',
  },
  {
    key: 'Oeuvre',
    label: 'Oeuvre',
  },
  {
    key: 'Orb',
    label: 'Orb',
  },
  {
    key: 'Oh Mg!',
    label: 'Oh Mg!',
  },
  {
    key: 'Oblique',
    label: 'Oblique',
  },
  {
    key: 'Orjan Andersson',
    label: 'Orjan Andersson',
  },
  {
    key: 'Odeeh',
    label: 'Odeeh',
  },
  {
    key: 'Oscar De La Renta',
    label: 'Oscar De La Renta',
  },
  {
    key: 'Octane',
    label: 'Octane',
  },
  {
    key: 'Oc Sports',
    label: 'Oc Sports',
  },
  {
    key: 'On&On',
    label: 'On&On',
  },
  {
    key: 'Oncu',
    label: 'Oncu',
  },
  {
    key: 'Outlyne',
    label: 'Outlyne',
  },
  {
    key: 'Osman London',
    label: 'Osman London',
  },
  {
    key: 'Oky Coky',
    label: 'Oky Coky',
  },
  {
    key: 'Ohdd',
    label: 'Ohdd',
  },
  {
    key: 'Oas',
    label: 'Oas',
  },
  {
    key: 'Oxeya',
    label: 'Oxeya',
  },
  {
    key: 'Oscorp',
    label: 'Oscorp',
  },
  {
    key: 'Ocho De Oro',
    label: 'Ocho De Oro',
  },
  {
    key: "Odds'n Evens",
    label: "Odds'n Evens",
  },
  {
    key: 'Osprey London',
    label: 'Osprey London',
  },
  {
    key: 'Ortiz & Reed',
    label: 'Ortiz & Reed',
  },
  {
    key: 'Occhi & Belli',
    label: 'Occhi & Belli',
  },
  {
    key: 'Onstage',
    label: 'Onstage',
  },
  {
    key: 'Ontour',
    label: 'Ontour',
  },
  {
    key: 'Our Legacy',
    label: 'Our Legacy',
  },
  {
    key: 'Orion London',
    label: 'Orion London',
  },
  {
    key: 'Oldi',
    label: 'Oldi',
  },
  {
    key: 'Oneteaspoon',
    label: 'Oneteaspoon',
  },
  {
    key: 'On Tour',
    label: 'On Tour',
  },
  {
    key: 'Osiris',
    label: 'Osiris',
  },
  {
    key: 'Others Follow',
    label: 'Others Follow',
  },
  {
    key: 'Old Time Hockey',
    label: 'Old Time Hockey',
  },
  {
    key: 'Olympus',
    label: 'Olympus',
  },
  {
    key: 'Orobianco',
    label: 'Orobianco',
  },
  {
    key: 'Octopus',
    label: 'Octopus',
  },
  {
    key: 'Orlando',
    label: 'Orlando',
  },
  {
    key: 'Ozsoy',
    label: 'Ozsoy',
  },
  {
    key: 'Oilily',
    label: 'Oilily',
  },
  {
    key: 'Orla Kiely',
    label: 'Orla Kiely',
  },
  {
    key: 'Olivia Matthews',
    label: 'Olivia Matthews',
  },
  {
    key: 'Orthopedic Traditional Comfort',
    label: 'Orthopedic Traditional Comfort',
  },
  {
    key: 'Oasis By Foschini',
    label: 'Oasis By Foschini',
  },
  {
    key: 'One Palm',
    label: 'One Palm',
  },
  {
    key: 'Olian',
    label: 'Olian',
  },
  {
    key: 'Odema',
    label: 'Odema',
  },
  {
    key: 'Ovye By Cristina Lucchi',
    label: 'Ovye By Cristina Lucchi',
  },
  {
    key: '',
    label: '',
  },
  {
    key: 'P',
    label: 'P',
  },
  {
    key: 'Pimkie',
    label: 'Pimkie',
  },
  {
    key: 'Promod',
    label: 'Promod',
  },
  {
    key: 'Pull&Bear',
    label: 'Pull&Bear',
  },
  {
    key: 'Pieces',
    label: 'Pieces',
  },
  {
    key: 'Puma',
    label: 'Puma',
  },
  {
    key: 'Pier One',
    label: 'Pier One',
  },
  {
    key: 'Pepe Jeans',
    label: 'Pepe Jeans',
  },
  {
    key: 'Pretty Little Thing',
    label: 'Pretty Little Thing',
  },
  {
    key: 'Pierre Cardin',
    label: 'Pierre Cardin',
  },
  {
    key: 'Polo By Ralph Lauren',
    label: 'Polo By Ralph Lauren',
  },
  {
    key: 'Portmans',
    label: 'Portmans',
  },
  {
    key: 'Patrizia Pepe',
    label: 'Patrizia Pepe',
  },
  {
    key: 'Papaya',
    label: 'Papaya',
  },
  {
    key: 'Place Est. 1989',
    label: 'Place Est. 1989',
  },
  {
    key: 'Premium By Jack & Jones',
    label: 'Premium By Jack & Jones',
  },
  {
    key: 'Peter Hahn',
    label: 'Peter Hahn',
  },
  {
    key: 'Pink Woman',
    label: 'Pink Woman',
  },
  {
    key: 'Pronto',
    label: 'Pronto',
  },
  {
    key: 'Please',
    label: 'Please',
  },
  {
    key: 'Penny Black',
    label: 'Penny Black',
  },
  {
    key: 'Produkt By Jack & Jones',
    label: 'Produkt By Jack & Jones',
  },
  {
    key: 'Pepe Runa',
    label: 'Pepe Runa',
  },
  {
    key: 'Per Una By Marks & Spencer',
    label: 'Per Una By Marks & Spencer',
  },
  {
    key: 'Perry Ellis',
    label: 'Perry Ellis',
  },
  {
    key: 'Passport',
    label: 'Passport',
  },
  {
    key: 'Public',
    label: 'Public',
  },
  {
    key: 'Paprika',
    label: 'Paprika',
  },
  {
    key: 'Peak Performance',
    label: 'Peak Performance',
  },
  {
    key: 'Piazza Italia',
    label: 'Piazza Italia',
  },
  {
    key: 'Peak Mountain',
    label: 'Peak Mountain',
  },
  {
    key: 'Paper Scissors',
    label: 'Paper Scissors',
  },
  {
    key: 'Paradi',
    label: 'Paradi',
  },
  {
    key: 'Ping Pong',
    label: 'Ping Pong',
  },
  {
    key: 'Poetry',
    label: 'Poetry',
  },
  {
    key: 'Pinko',
    label: 'Pinko',
  },
  {
    key: 'Philosophy Blues Original',
    label: 'Philosophy Blues Original',
  },
  {
    key: 'Petit Bateau',
    label: 'Petit Bateau',
  },
  {
    key: 'Protest',
    label: 'Protest',
  },
  {
    key: 'Phase Eight',
    label: 'Phase Eight',
  },
  {
    key: 'Patrizia Dini',
    label: 'Patrizia Dini',
  },
  {
    key: 'Phard',
    label: 'Phard',
  },
  {
    key: 'Paul Smith',
    label: 'Paul Smith',
  },
  {
    key: 'Paul & Shark',
    label: 'Paul & Shark',
  },
  {
    key: 'Pilgrim',
    label: 'Pilgrim',
  },
  {
    key: 'Promiss',
    label: 'Promiss',
  },
  {
    key: 'Playshoes',
    label: 'Playshoes',
  },
  {
    key: 'Principles',
    label: 'Principles',
  },
  {
    key: 'Polo Club',
    label: 'Polo Club',
  },
  {
    key: 'Pietro Filipi',
    label: 'Pietro Filipi',
  },
  {
    key: 'Peacocks',
    label: 'Peacocks',
  },
  {
    key: 'Pepco',
    label: 'Pepco',
  },
  {
    key: 'Philip Russel',
    label: 'Philip Russel',
  },
  {
    key: 'Paul Kehl',
    label: 'Paul Kehl',
  },
  {
    key: 'Pescara',
    label: 'Pescara',
  },
  {
    key: 'Pins And Needles',
    label: 'Pins And Needles',
  },
  {
    key: 'Pronovias',
    label: 'Pronovias',
  },
  {
    key: 'Pascal Morabito',
    label: 'Pascal Morabito',
  },
  {
    key: 'Perri Cutten',
    label: 'Perri Cutten',
  },
  {
    key: 'Pardon',
    label: 'Pardon',
  },
  {
    key: 'Pink Republic',
    label: 'Pink Republic',
  },
  {
    key: 'Pleione',
    label: 'Pleione',
  },
  {
    key: 'Personal Affairs',
    label: 'Personal Affairs',
  },
  {
    key: 'Protection System',
    label: 'Protection System',
  },
  {
    key: 'Patagonia',
    label: 'Patagonia',
  },
  {
    key: 'Pour Moi',
    label: 'Pour Moi',
  },
  {
    key: 'Plusminus By Chiemsee',
    label: 'Plusminus By Chiemsee',
  },
  {
    key: 'Pause Jeans',
    label: 'Pause Jeans',
  },
  {
    key: 'Peppercorn',
    label: 'Peppercorn',
  },
  {
    key: 'Pink Clove',
    label: 'Pink Clove',
  },
  {
    key: 'Peter Kaiser',
    label: 'Peter Kaiser',
  },
  {
    key: 'Planet',
    label: 'Planet',
  },
  {
    key: 'Patrice Breal',
    label: 'Patrice Breal',
  },
  {
    key: 'Polo Jeans Company By Ralph Lauren',
    label: 'Polo Jeans Company By Ralph Lauren',
  },
  {
    key: 'Pompoos Design By Harald Gloockler',
    label: 'Pompoos Design By Harald Gloockler',
  },
  {
    key: 'Princess Vera Wang',
    label: 'Princess Vera Wang',
  },
  {
    key: 'Parasuco',
    label: 'Parasuco',
  },
  {
    key: 'Petite',
    label: 'Petite',
  },
  {
    key: 'Powerzone',
    label: 'Powerzone',
  },
  {
    key: 'Pedro Del Hierro',
    label: 'Pedro Del Hierro',
  },
  {
    key: "Pink By Victoria's Secret",
    label: "Pink By Victoria's Secret",
  },
  {
    key: 'Paul & Joe Sister',
    label: 'Paul & Joe Sister',
  },
  {
    key: 'Paul',
    label: 'Paul',
  },
  {
    key: 'Paoloni',
    label: 'Paoloni',
  },
  {
    key: 'Pataugas',
    label: 'Pataugas',
  },
  {
    key: 'Pink',
    label: 'Pink',
  },
  {
    key: 'Ponti',
    label: 'Ponti',
  },
  {
    key: 'Princesse Tam Tam',
    label: 'Princesse Tam Tam',
  },
  {
    key: 'Playlife',
    label: 'Playlife',
  },
  {
    key: 'Parisian',
    label: 'Parisian',
  },
  {
    key: 'Piazza Sempione',
    label: 'Piazza Sempione',
  },
  {
    key: 'Pretty Ballerinas',
    label: 'Pretty Ballerinas',
  },
  {
    key: 'Paul Costelloe',
    label: 'Paul Costelloe',
  },
  {
    key: 'Pavement',
    label: 'Pavement',
  },
  {
    key: 'Paper Heart',
    label: 'Paper Heart',
  },
  {
    key: 'Public Desire',
    label: 'Public Desire',
  },
  {
    key: 'Prepair',
    label: 'Prepair',
  },
  {
    key: 'Piu & Piu',
    label: 'Piu & Piu',
  },
  {
    key: 'Peuterey',
    label: 'Peuterey',
  },
  {
    key: 'Per Una Speziale',
    label: 'Per Una Speziale',
  },
  {
    key: 'Persona',
    label: 'Persona',
  },
  {
    key: 'Pianura Studio',
    label: 'Pianura Studio',
  },
  {
    key: 'Persona By Marina Rinaldi',
    label: 'Persona By Marina Rinaldi',
  },
  {
    key: 'P.O. Box',
    label: 'P.O. Box',
  },
  {
    key: 'Punto Roma',
    label: 'Punto Roma',
  },
  {
    key: 'Pocopiano',
    label: 'Pocopiano',
  },
  {
    key: 'Pulp',
    label: 'Pulp',
  },
  {
    key: 'Pain De Sucre',
    label: 'Pain De Sucre',
  },
  {
    key: 'Poti Pati',
    label: 'Poti Pati',
  },
  {
    key: 'Paolo Casalini',
    label: 'Paolo Casalini',
  },
  {
    key: 'Pelle Pelle',
    label: 'Pelle Pelle',
  },
  {
    key: 'Pulse',
    label: 'Pulse',
  },
  {
    key: 'Pumpkin Patch',
    label: 'Pumpkin Patch',
  },
  {
    key: 'Peter Nygard',
    label: 'Peter Nygard',
  },
  {
    key: 'Pollini',
    label: 'Pollini',
  },
  {
    key: 'Pura Lopez',
    label: 'Pura Lopez',
  },
  {
    key: 'Phenix',
    label: 'Phenix',
  },
  {
    key: 'Philosophy Di Alberta Ferretti',
    label: 'Philosophy Di Alberta Ferretti',
  },
  {
    key: 'Palomino',
    label: 'Palomino',
  },
  {
    key: 'Pacsun',
    label: 'Pacsun',
  },
  {
    key: 'Paige',
    label: 'Paige',
  },
  {
    key: 'Piattelli',
    label: 'Piattelli',
  },
  {
    key: "People's Market",
    label: "People's Market",
  },
  {
    key: 'Popin',
    label: 'Popin',
  },
  {
    key: 'Privilege',
    label: 'Privilege',
  },
  {
    key: 'Princess Goes Hollywood',
    label: 'Princess Goes Hollywood',
  },
  {
    key: 'Paolo Bocelli',
    label: 'Paolo Bocelli',
  },
  {
    key: 'Pierre Laffitte',
    label: 'Pierre Laffitte',
  },
  {
    key: 'Peter Storm',
    label: 'Peter Storm',
  },
  {
    key: 'Panos Emporio',
    label: 'Panos Emporio',
  },
  {
    key: 'Publish',
    label: 'Publish',
  },
  {
    key: 'Promodoro',
    label: 'Promodoro',
  },
  {
    key: 'Prada',
    label: 'Prada',
  },
  {
    key: 'Pamela Mccoy',
    label: 'Pamela Mccoy',
  },
  {
    key: 'Pearl',
    label: 'Pearl',
  },
  {
    key: 'Papillon',
    label: 'Papillon',
  },
  {
    key: 'Pme Legend',
    label: 'Pme Legend',
  },
  {
    key: 'Paul & Joe',
    label: 'Paul & Joe',
  },
  {
    key: 'Precis Petite',
    label: 'Precis Petite',
  },
  {
    key: 'Priority',
    label: 'Priority',
  },
  {
    key: "Potter's Pot",
    label: "Potter's Pot",
  },
  {
    key: 'Pea In A Pod',
    label: 'Pea In A Pod',
  },
  {
    key: 'Purple',
    label: 'Purple',
  },
  {
    key: 'Paola Ferri',
    label: 'Paola Ferri',
  },
  {
    key: 'Prince & Fox',
    label: 'Prince & Fox',
  },
  {
    key: 'Powderhorn',
    label: 'Powderhorn',
  },
  {
    key: 'Pedro Miralles',
    label: 'Pedro Miralles',
  },
  {
    key: 'Pieszak',
    label: 'Pieszak',
  },
  {
    key: 'Paris Pink',
    label: 'Paris Pink',
  },
  {
    key: 'Poools',
    label: 'Poools',
  },
  {
    key: 'Preis',
    label: 'Preis',
  },
  {
    key: 'Paul Frank',
    label: 'Paul Frank',
  },
  {
    key: 'Petite Collection',
    label: 'Petite Collection',
  },
  {
    key: 'Playlord',
    label: 'Playlord',
  },
  {
    key: 'Pinokkio',
    label: 'Pinokkio',
  },
  {
    key: 'Pep Step',
    label: 'Pep Step',
  },
  {
    key: 'Plein Sud',
    label: 'Plein Sud',
  },
  {
    key: 'Peek Performance',
    label: 'Peek Performance',
  },
  {
    key: 'Penfield',
    label: 'Penfield',
  },
  {
    key: 'Pronti',
    label: 'Pronti',
  },
  {
    key: 'Paola Frani',
    label: 'Paola Frani',
  },
  {
    key: 'Perlina',
    label: 'Perlina',
  },
  {
    key: 'Paule Ka',
    label: 'Paule Ka',
  },
  {
    key: 'Peter Millar',
    label: 'Peter Millar',
  },
  {
    key: 'Polo',
    label: 'Polo',
  },
  {
    key: 'Paolo Tonali',
    label: 'Paolo Tonali',
  },
  {
    key: 'Peruvian Connection',
    label: 'Peruvian Connection',
  },
  {
    key: 'Paco Gil',
    label: 'Paco Gil',
  },
  {
    key: 'P.A.R.O.S.H.',
    label: 'P.A.R.O.S.H.',
  },
  {
    key: 'Paradis Des Innocents',
    label: 'Paradis Des Innocents',
  },
  {
    key: 'Peter O. Mahler',
    label: 'Peter O. Mahler',
  },
  {
    key: 'Pelikamo',
    label: 'Pelikamo',
  },
  {
    key: 'Paul Rosen',
    label: 'Paul Rosen',
  },
  {
    key: 'Pyua',
    label: 'Pyua',
  },
  {
    key: 'Parajumpers',
    label: 'Parajumpers',
  },
  {
    key: 'Parah',
    label: 'Parah',
  },
  {
    key: 'Piper Lane',
    label: 'Piper Lane',
  },
  {
    key: 'Piquadro',
    label: 'Piquadro',
  },
  {
    key: 'Pittarello',
    label: 'Pittarello',
  },
  {
    key: 'Proenza Schouler',
    label: 'Proenza Schouler',
  },
  {
    key: 'Portman',
    label: 'Portman',
  },
  {
    key: 'Pikolinos',
    label: 'Pikolinos',
  },
  {
    key: 'Pep & Co',
    label: 'Pep & Co',
  },
  {
    key: 'Pink Poem',
    label: 'Pink Poem',
  },
  {
    key: 'Painted Threads',
    label: 'Painted Threads',
  },
  {
    key: 'Porsche Design',
    label: 'Porsche Design',
  },
  {
    key: 'Patrons Of Peace',
    label: 'Patrons Of Peace',
  },
  {
    key: 'Paloma Barcelo',
    label: 'Paloma Barcelo',
  },
  {
    key: 'Pendleton',
    label: 'Pendleton',
  },
  {
    key: 'Pineapple',
    label: 'Pineapple',
  },
  {
    key: 'Pringle Of Scotland',
    label: 'Pringle Of Scotland',
  },
  {
    key: 'Px',
    label: 'Px',
  },
  {
    key: 'Pure & Simple',
    label: 'Pure & Simple',
  },
  {
    key: 'Pj Mark',
    label: 'Pj Mark',
  },
  {
    key: 'Parker',
    label: 'Parker',
  },
  {
    key: 'Pennypull',
    label: 'Pennypull',
  },
  {
    key: 'Peppa Pig',
    label: 'Peppa Pig',
  },
  {
    key: 'Pat Calvin',
    label: 'Pat Calvin',
  },
  {
    key: 'Pal Zileri',
    label: 'Pal Zileri',
  },
  {
    key: 'Pomme',
    label: 'Pomme',
  },
  {
    key: 'Paola Prata',
    label: 'Paola Prata',
  },
  {
    key: 'Pablo De Gerard Darel',
    label: 'Pablo De Gerard Darel',
  },
  {
    key: "Pantofola D'oro",
    label: "Pantofola D'oro",
  },
  {
    key: 'Primal',
    label: 'Primal',
  },
  {
    key: 'Pierre Clarence',
    label: 'Pierre Clarence',
  },
  {
    key: 'Pure Oxycen',
    label: 'Pure Oxycen',
  },
  {
    key: 'Project Social T',
    label: 'Project Social T',
  },
  {
    key: 'Paolo Botticelli',
    label: 'Paolo Botticelli',
  },
  {
    key: 'Pullit',
    label: 'Pullit',
  },
  {
    key: 'Project Aj 117',
    label: 'Project Aj 117',
  },
  {
    key: 'Philipp Plein',
    label: 'Philipp Plein',
  },
  {
    key: 'Poppy Lux',
    label: 'Poppy Lux',
  },
  {
    key: 'Peter Luft',
    label: 'Peter Luft',
  },
  {
    key: 'Pink Tartan',
    label: 'Pink Tartan',
  },
  {
    key: 'Purificacion Garcia',
    label: 'Purificacion Garcia',
  },
  {
    key: 'Palablu',
    label: 'Palablu',
  },
  {
    key: 'Polarn O. Pyret',
    label: 'Polarn O. Pyret',
  },
  {
    key: 'Pietro Brunelli',
    label: 'Pietro Brunelli',
  },
  {
    key: 'Paquita',
    label: 'Paquita',
  },
  {
    key: 'Paruchev',
    label: 'Paruchev',
  },
  {
    key: 'Philippe Matignon',
    label: 'Philippe Matignon',
  },
  {
    key: 'Poivre Blanc',
    label: 'Poivre Blanc',
  },
  {
    key: 'Pippa Dee',
    label: 'Pippa Dee',
  },
  {
    key: 'Peter Van Holland',
    label: 'Peter Van Holland',
  },
  {
    key: 'Polly & Esther',
    label: 'Polly & Esther',
  },
  {
    key: 'Polka Dots',
    label: 'Polka Dots',
  },
  {
    key: 'Paper Denim & Cloth',
    label: 'Paper Denim & Cloth',
  },
  {
    key: 'Pixie Daisy',
    label: 'Pixie Daisy',
  },
  {
    key: 'Para Mi',
    label: 'Para Mi',
  },
  {
    key: 'Pamela Henson',
    label: 'Pamela Henson',
  },
  {
    key: 'Papagino',
    label: 'Papagino',
  },
  {
    key: 'Philippe',
    label: 'Philippe',
  },
  {
    key: 'Pure & Natural',
    label: 'Pure & Natural',
  },
  {
    key: 'Peralston',
    label: 'Peralston',
  },
  {
    key: 'Pilini',
    label: 'Pilini',
  },
  {
    key: 'Patricia',
    label: 'Patricia',
  },
  {
    key: 'Perla Donna',
    label: 'Perla Donna',
  },
  {
    key: 'Prune',
    label: 'Prune',
  },
  {
    key: 'Petit Ami',
    label: 'Petit Ami',
  },
  {
    key: 'Paig',
    label: 'Paig',
  },
  {
    key: 'Pippi',
    label: 'Pippi',
  },
  {
    key: 'Padders',
    label: 'Padders',
  },
  {
    key: 'Pacific & Co.',
    label: 'Pacific & Co.',
  },
  {
    key: 'Pierre Robert',
    label: 'Pierre Robert',
  },
  {
    key: 'Pure White',
    label: 'Pure White',
  },
  {
    key: 'Polder Pour La Redoute',
    label: 'Polder Pour La Redoute',
  },
  {
    key: 'Philippe Carat',
    label: 'Philippe Carat',
  },
  {
    key: 'Pod',
    label: 'Pod',
  },
  {
    key: 'Paco Rabanne',
    label: 'Paco Rabanne',
  },
  {
    key: 'Pauw',
    label: 'Pauw',
  },
  {
    key: 'Peanuts By Kenvelo',
    label: 'Peanuts By Kenvelo',
  },
  {
    key: 'Paniz',
    label: 'Paniz',
  },
  {
    key: 'Pudika',
    label: 'Pudika',
  },
  {
    key: 'Phard Diamond',
    label: 'Phard Diamond',
  },
  {
    key: 'Pink Owl',
    label: 'Pink Owl',
  },
  {
    key: 'Porsche Design By Adidas',
    label: 'Porsche Design By Adidas',
  },
  {
    key: 'Pro Mark',
    label: 'Pro Mark',
  },
  {
    key: 'Purple Snow',
    label: 'Purple Snow',
  },
  {
    key: 'Pediconfort',
    label: 'Pediconfort',
  },
  {
    key: 'Progetto',
    label: 'Progetto',
  },
  {
    key: 'Polo Sport',
    label: 'Polo Sport',
  },
  {
    key: 'Patricia Nash',
    label: 'Patricia Nash',
  },
  {
    key: 'P.M.E. By Peserico',
    label: 'P.M.E. By Peserico',
  },
  {
    key: 'Penelope And Monica Cruz For Carpisa',
    label: 'Penelope And Monica Cruz For Carpisa',
  },
  {
    key: 'Paul & Joe Sister By La Redoute',
    label: 'Paul & Joe Sister By La Redoute',
  },
  {
    key: 'Pukka',
    label: 'Pukka',
  },
  {
    key: 'Preen',
    label: 'Preen',
  },
  {
    key: 'Perfect Day For Remix',
    label: 'Perfect Day For Remix',
  },
  {
    key: 'Pacomena',
    label: 'Pacomena',
  },
  {
    key: 'Pink Floyd',
    label: 'Pink Floyd',
  },
  {
    key: 'Patino By Auluna',
    label: 'Patino By Auluna',
  },
  {
    key: 'Pf-Flyers',
    label: 'Pf-Flyers',
  },
  {
    key: 'Pauls Boutique',
    label: 'Pauls Boutique',
  },
  {
    key: 'Pistola',
    label: 'Pistola',
  },
  {
    key: 'Proud',
    label: 'Proud',
  },
  {
    key: 'Pique Polo',
    label: 'Pique Polo',
  },
  {
    key: 'Pedro Garcia',
    label: 'Pedro Garcia',
  },
  {
    key: 'Privately Privileged',
    label: 'Privately Privileged',
  },
  {
    key: 'Petroleum',
    label: 'Petroleum',
  },
  {
    key: 'Peter Pilotto',
    label: 'Peter Pilotto',
  },
  {
    key: 'Pink Olw',
    label: 'Pink Olw',
  },
  {
    key: "Parenti's",
    label: "Parenti's",
  },
  {
    key: 'Paradise Kiss',
    label: 'Paradise Kiss',
  },
  {
    key: 'Polder',
    label: 'Polder',
  },
  {
    key: 'Plain Vanilla',
    label: 'Plain Vanilla',
  },
  {
    key: 'Parkes',
    label: 'Parkes',
  },
  {
    key: 'Pierrette',
    label: 'Pierrette',
  },
  {
    key: 'Poon',
    label: 'Poon',
  },
  {
    key: 'Play Shoes',
    label: 'Play Shoes',
  },
  {
    key: 'Panama Jack',
    label: 'Panama Jack',
  },
  {
    key: 'Pennant',
    label: 'Pennant',
  },
  {
    key: 'Philippe Adec',
    label: 'Philippe Adec',
  },
  {
    key: 'Ppla',
    label: 'Ppla',
  },
  {
    key: 'Pink By Paradox London',
    label: 'Pink By Paradox London',
  },
  {
    key: 'Pascucci',
    label: 'Pascucci',
  },
  {
    key: 'Panzeri',
    label: 'Panzeri',
  },
  {
    key: 'Pasqualini',
    label: 'Pasqualini',
  },
  {
    key: 'Passiontex',
    label: 'Passiontex',
  },
  {
    key: 'Project Aj117',
    label: 'Project Aj117',
  },
  {
    key: 'Pierre',
    label: 'Pierre',
  },
  {
    key: 'Profilo',
    label: 'Profilo',
  },
  {
    key: 'Pill',
    label: 'Pill',
  },
  {
    key: 'Pebble And Stone',
    label: 'Pebble And Stone',
  },
  {
    key: 'Pretty Guide',
    label: 'Pretty Guide',
  },
  {
    key: 'Pinkblush',
    label: 'Pinkblush',
  },
  {
    key: "Paul's Boutique",
    label: "Paul's Boutique",
  },
  {
    key: 'Pierre Martin',
    label: 'Pierre Martin',
  },
  {
    key: 'Pelle P',
    label: 'Pelle P',
  },
  {
    key: 'Paola Bacelli',
    label: 'Paola Bacelli',
  },
  {
    key: 'Patra',
    label: 'Patra',
  },
  {
    key: 'Pocopiano',
    label: 'Pocopiano',
  },
  {
    key: 'Prabal Gurung',
    label: 'Prabal Gurung',
  },
  {
    key: 'Pajar',
    label: 'Pajar',
  },
  {
    key: 'Paul Taylor',
    label: 'Paul Taylor',
  },
  {
    key: 'Pierre Balmain',
    label: 'Pierre Balmain',
  },
  {
    key: 'Pikeur',
    label: 'Pikeur',
  },
  {
    key: 'Pitillos',
    label: 'Pitillos',
  },
  {
    key: 'Philo Vance',
    label: 'Philo Vance',
  },
  {
    key: 'Picard',
    label: 'Picard',
  },
  {
    key: 'Paglie',
    label: 'Paglie',
  },
  {
    key: 'Parish Nation',
    label: 'Parish Nation',
  },
  {
    key: 'Paul Marc',
    label: 'Paul Marc',
  },
  {
    key: 'Per Te By Krizia',
    label: 'Per Te By Krizia',
  },
  {
    key: 'Patrizia Rigotti',
    label: 'Patrizia Rigotti',
  },
  {
    key: 'Pier Antonio Gaspari',
    label: 'Pier Antonio Gaspari',
  },
  {
    key: 'Pim + Larkin',
    label: 'Pim + Larkin',
  },
  {
    key: 'Philippe Roux',
    label: 'Philippe Roux',
  },
  {
    key: 'Pink Clove',
    label: 'Pink Clove',
  },
  {
    key: 'Pronta Moda',
    label: 'Pronta Moda',
  },
  {
    key: 'Primrose',
    label: 'Primrose',
  },
  {
    key: 'Praslin',
    label: 'Praslin',
  },
  {
    key: 'Prps',
    label: 'Prps',
  },
  {
    key: 'Poison Scorpion',
    label: 'Poison Scorpion',
  },
  {
    key: 'Polinesia',
    label: 'Polinesia',
  },
  {
    key: 'Pinkyotto',
    label: 'Pinkyotto',
  },
  {
    key: 'Paladino Jeans',
    label: 'Paladino Jeans',
  },
  {
    key: 'Phil Petter',
    label: 'Phil Petter',
  },
  {
    key: 'Puledro',
    label: 'Puledro',
  },
  {
    key: 'Pont Neuf',
    label: 'Pont Neuf',
  },
  {
    key: 'Paul & Joe Sister Pour La Redoute',
    label: 'Paul & Joe Sister Pour La Redoute',
  },
  {
    key: 'Pelletteria Di Mario',
    label: 'Pelletteria Di Mario',
  },
  {
    key: 'Pools',
    label: 'Pools',
  },
  {
    key: 'Poppy Rose',
    label: 'Poppy Rose',
  },
  {
    key: 'Paul Frederick',
    label: 'Paul Frederick',
  },
  {
    key: 'Per Donna',
    label: 'Per Donna',
  },
  {
    key: 'Patricia Rado',
    label: 'Patricia Rado',
  },
  {
    key: 'Pretty Angel',
    label: 'Pretty Angel',
  },
  {
    key: 'Paolo Pecora Milano',
    label: 'Paolo Pecora Milano',
  },
  {
    key: 'Piacenza',
    label: 'Piacenza',
  },
  {
    key: 'Pierlucci',
    label: 'Pierlucci',
  },
  {
    key: 'Penny Plain',
    label: 'Penny Plain',
  },
  {
    key: 'Pippa & Julie',
    label: 'Pippa & Julie',
  },
  {
    key: 'Ports 1961',
    label: 'Ports 1961',
  },
  {
    key: 'Papalou',
    label: 'Papalou',
  },
  {
    key: 'Piero Guidi',
    label: 'Piero Guidi',
  },
  {
    key: 'Pooja International',
    label: 'Pooja International',
  },
  {
    key: 'Pintodiblu',
    label: 'Pintodiblu',
  },
  {
    key: 'Psalm 23',
    label: 'Psalm 23',
  },
  {
    key: 'Power Girl',
    label: 'Power Girl',
  },
  {
    key: 'Pernilla Wahlgren',
    label: 'Pernilla Wahlgren',
  },
  {
    key: 'Q/S By S.Oliver',
    label: 'Q/S By S.Oliver',
  },
  {
    key: 'Qed London',
    label: 'Qed London',
  },
  {
    key: 'Quiksilver',
    label: 'Quiksilver',
  },
  {
    key: 'Quechua',
    label: 'Quechua',
  },
  {
    key: 'Quiz',
    label: 'Quiz',
  },
  {
    key: 'Qiero!',
    label: 'Qiero!',
  },
  {
    key: 'Quiosque',
    label: 'Quiosque',
  },
  {
    key: 'Quirky Circus By Minkpink',
    label: 'Quirky Circus By Minkpink',
  },
  {
    key: 'Queen',
    label: 'Queen',
  },
  {
    key: 'Q By Kjederqvist',
    label: 'Q By Kjederqvist',
  },
  {
    key: 'Quinze Heures Trente',
    label: 'Quinze Heures Trente',
  },
  {
    key: 'Q1',
    label: 'Q1',
  },
  {
    key: 'Queen Hearts',
    label: 'Queen Hearts',
  },
  {
    key: 'Quickstep',
    label: 'Quickstep',
  },
  {
    key: 'Qloom',
    label: 'Qloom',
  },
  {
    key: 'Qupid',
    label: 'Qupid',
  },
  {
    key: 'Quacker Factory',
    label: 'Quacker Factory',
  },
  {
    key: 'R',
    label: 'R',
  },
  {
    key: 'Reserved',
    label: 'Reserved',
  },
  {
    key: 'Review',
    label: 'Review',
  },
  {
    key: 'River Island',
    label: 'River Island',
  },
  {
    key: 'Rinascimento',
    label: 'Rinascimento',
  },
  {
    key: 'Replay',
    label: 'Replay',
  },
  {
    key: 'Reebok',
    label: 'Reebok',
  },
  {
    key: 'Rainbow',
    label: 'Rainbow',
  },
  {
    key: 'Ralph Lauren',
    label: 'Ralph Lauren',
  },
  {
    key: 'Reitmans',
    label: 'Reitmans',
  },
  {
    key: 'Rue Princesse',
    label: 'Rue Princesse',
  },
  {
    key: 'Roxy',
    label: 'Roxy',
  },
  {
    key: 'Rene Lezard',
    label: 'Rene Lezard',
  },
  {
    key: 'Rich & Royal',
    label: 'Rich & Royal',
  },
  {
    key: 'Regatta',
    label: 'Regatta',
  },
  {
    key: 'Rw & Co.',
    label: 'Rw & Co.',
  },
  {
    key: 'Rodier',
    label: 'Rodier',
  },
  {
    key: 'Royal Class',
    label: 'Royal Class',
  },
  {
    key: 'Rabe',
    label: 'Rabe',
  },
  {
    key: 'Roy Robson',
    label: 'Roy Robson',
  },
  {
    key: 'Rodeo',
    label: 'Rodeo',
  },
  {
    key: 'Red Herring',
    label: 'Red Herring',
  },
  {
    key: 'Rosner',
    label: 'Rosner',
  },
  {
    key: 'Rip Curl',
    label: 'Rip Curl',
  },
  {
    key: 'Rick Cardona',
    label: 'Rick Cardona',
  },
  {
    key: 'Riani',
    label: 'Riani',
  },
  {
    key: 'Rebel',
    label: 'Rebel',
  },
  {
    key: 'Repeat',
    label: 'Repeat',
  },
  {
    key: 'Revamped',
    label: 'Revamped',
  },
  {
    key: 'Russell',
    label: 'Russell',
  },
  {
    key: 'Relish',
    label: 'Relish',
  },
  {
    key: 'Roger David',
    label: 'Roger David',
  },
  {
    key: 'Rosebullet',
    label: 'Rosebullet',
  },
  {
    key: 'Rosemunde',
    label: 'Rosemunde',
  },
  {
    key: 'Reiss',
    label: 'Reiss',
  },
  {
    key: 'R And Be',
    label: 'R And Be',
  },
  {
    key: 'Religion',
    label: 'Religion',
  },
  {
    key: 'Roberto Cavalli',
    label: 'Roberto Cavalli',
  },
  {
    key: 'Rocha.John Rocha',
    label: 'Rocha.John Rocha',
  },
  {
    key: 'Rag & Bone',
    label: 'Rag & Bone',
  },
  {
    key: 'Rutzou',
    label: 'Rutzou',
  },
  {
    key: 'Rut M.Fl.',
    label: 'Rut M.Fl.',
  },
  {
    key: 'R.M.Williams',
    label: 'R.M.Williams',
  },
  {
    key: 'Rumor Boutique',
    label: 'Rumor Boutique',
  },
  {
    key: 'Raffaello Rossi',
    label: 'Raffaello Rossi',
  },
  {
    key: 'Rachel Roy',
    label: 'Rachel Roy',
  },
  {
    key: 'Riccovero',
    label: 'Riccovero',
  },
  {
    key: 'Rains',
    label: 'Rains',
  },
  {
    key: 'River Woods',
    label: 'River Woods',
  },
  {
    key: 'Rodd & Gunn',
    label: 'Rodd & Gunn',
  },
  {
    key: 'Rockport',
    label: 'Rockport',
  },
  {
    key: 'Reverse',
    label: 'Reverse',
  },
  {
    key: 'Ruby Tuesday',
    label: 'Ruby Tuesday',
  },
  {
    key: 'Redial',
    label: 'Redial',
  },
  {
    key: 'Rampage',
    label: 'Rampage',
  },
  {
    key: 'Red Valentino',
    label: 'Red Valentino',
  },
  {
    key: 'Risoe',
    label: 'Risoe',
  },
  {
    key: 'Raid',
    label: 'Raid',
  },
  {
    key: 'Rei',
    label: 'Rei',
  },
  {
    key: 'Reken Maar',
    label: 'Reken Maar',
  },
  {
    key: 'Rubi',
    label: 'Rubi',
  },
  {
    key: 'Roberto Collina',
    label: 'Roberto Collina',
  },
  {
    key: 'Reject',
    label: 'Reject',
  },
  {
    key: "Rock'n Blue",
    label: "Rock'n Blue",
  },
  {
    key: 'Ragazza',
    label: 'Ragazza',
  },
  {
    key: 'Rosita',
    label: 'Rosita',
  },
  {
    key: 'Richmond',
    label: 'Richmond',
  },
  {
    key: 'R',
    label: 'R',
  },
  {
    key: 'Roebuck & Co.',
    label: 'Roebuck & Co.',
  },
  {
    key: 'Rocawear',
    label: 'Rocawear',
  },
  {
    key: 'Rocco Barocco',
    label: 'Rocco Barocco',
  },
  {
    key: 'Rosesand Gold',
    label: 'Rosesand Gold',
  },
  {
    key: 'Robert Friedman',
    label: 'Robert Friedman',
  },
  {
    key: 'Roberta Biagi',
    label: 'Roberta Biagi',
  },
  {
    key: 'Rohnisch',
    label: 'Rohnisch',
  },
  {
    key: 'Rosebud',
    label: 'Rosebud',
  },
  {
    key: 'Refrigiwear',
    label: 'Refrigiwear',
  },
  {
    key: 'Rachel Zoé',
    label: 'Rachel Zoé',
  },
  {
    key: 'Russell Athletic',
    label: 'Russell Athletic',
  },
  {
    key: 'Rossini',
    label: 'Rossini',
  },
  {
    key: 'Ricano',
    label: 'Ricano',
  },
  {
    key: 'Rhodes & Beckett',
    label: 'Rhodes & Beckett',
  },
  {
    key: 'Renato Cavalli',
    label: 'Renato Cavalli',
  },
  {
    key: 'Revers',
    label: 'Revers',
  },
  {
    key: 'Rouhomania',
    label: 'Rouhomania',
  },
  {
    key: 'Rogue',
    label: 'Rogue',
  },
  {
    key: 'Renata Corsi',
    label: 'Renata Corsi',
  },
  {
    key: 'Replay & Sons',
    label: 'Replay & Sons',
  },
  {
    key: 'Roman',
    label: 'Roman',
  },
  {
    key: 'Rich & Famous',
    label: 'Rich & Famous',
  },
  {
    key: 'Rino & Pelle',
    label: 'Rino & Pelle',
  },
  {
    key: 'Ronni Nicole',
    label: 'Ronni Nicole',
  },
  {
    key: 'Review Australia',
    label: 'Review Australia',
  },
  {
    key: 'Rossignol',
    label: 'Rossignol',
  },
  {
    key: 'Rebecca Taylor',
    label: 'Rebecca Taylor',
  },
  {
    key: 'Redstar',
    label: 'Redstar',
  },
  {
    key: 'Rena Lange',
    label: 'Rena Lange',
  },
  {
    key: 'Roncato',
    label: 'Roncato',
  },
  {
    key: 'Royal Republiq',
    label: 'Royal Republiq',
  },
  {
    key: 'Raphaela By Brax',
    label: 'Raphaela By Brax',
  },
  {
    key: 'Romeo Gigli',
    label: 'Romeo Gigli',
  },
  {
    key: 'Rabens Saloner',
    label: 'Rabens Saloner',
  },
  {
    key: 'Rags',
    label: 'Rags',
  },
  {
    key: 'Re:Named',
    label: 'Re:Named',
  },
  {
    key: 'Rebecca Minkoff',
    label: 'Rebecca Minkoff',
  },
  {
    key: 'Rituals',
    label: 'Rituals',
  },
  {
    key: 'Rainbow Collection',
    label: 'Rainbow Collection',
  },
  {
    key: 'Ragged',
    label: 'Ragged',
  },
  {
    key: 'Rivette&Blair',
    label: 'Rivette&Blair',
  },
  {
    key: 'Ricosta',
    label: 'Ricosta',
  },
  {
    key: 'Riccardo Farini',
    label: 'Riccardo Farini',
  },
  {
    key: 'Ruby Rox',
    label: 'Ruby Rox',
  },
  {
    key: 'R 867',
    label: 'R 867',
  },
  {
    key: 'Rhino Republic',
    label: 'Rhino Republic',
  },
  {
    key: 'Regular Denim',
    label: 'Regular Denim',
  },
  {
    key: 'Raffaello Rossi By Schera',
    label: 'Raffaello Rossi By Schera',
  },
  {
    key: 'Rollmann',
    label: 'Rollmann',
  },
  {
    key: 'Redial Luxury',
    label: 'Redial Luxury',
  },
  {
    key: 'Renoma',
    label: 'Renoma',
  },
  {
    key: 'Rene Tyler',
    label: 'Rene Tyler',
  },
  {
    key: 'Ron Chereskin',
    label: 'Ron Chereskin',
  },
  {
    key: 'Rienda',
    label: 'Rienda',
  },
  {
    key: 'Rblz Collection',
    label: 'Rblz Collection',
  },
  {
    key: 'Rumble 59',
    label: 'Rumble 59',
  },
  {
    key: 'Rossodisera',
    label: 'Rossodisera',
  },
  {
    key: 'Ray Ban',
    label: 'Ray Ban',
  },
  {
    key: 'Rei Co Op',
    label: 'Rei Co Op',
  },
  {
    key: 'Roberto Sarto',
    label: 'Roberto Sarto',
  },
  {
    key: 'Ryan Seacrest Distinction',
    label: 'Ryan Seacrest Distinction',
  },
  {
    key: 'Ralph Lauren Sport',
    label: 'Ralph Lauren Sport',
  },
  {
    key: 'Rogers + Rogers',
    label: 'Rogers + Rogers',
  },
  {
    key: 'Rene Derhy',
    label: 'Rene Derhy',
  },
  {
    key: 'Rock Creek',
    label: 'Rock Creek',
  },
  {
    key: 'Ras',
    label: 'Ras',
  },
  {
    key: 'Roulette',
    label: 'Roulette',
  },
  {
    key: 'Romano Shawls',
    label: 'Romano Shawls',
  },
  {
    key: 'Raintex',
    label: 'Raintex',
  },
  {
    key: 'Renzo Metti',
    label: 'Renzo Metti',
  },
  {
    key: 'Renato Balestra',
    label: 'Renato Balestra',
  },
  {
    key: 'Rena Marx',
    label: 'Rena Marx',
  },
  {
    key: 'Richmond Denim',
    label: 'Richmond Denim',
  },
  {
    key: 'Rolf Schulte',
    label: 'Rolf Schulte',
  },
  {
    key: 'Raw Apparel',
    label: 'Raw Apparel',
  },
  {
    key: 'Rothco',
    label: 'Rothco',
  },
  {
    key: 'Robert Rodriguez',
    label: 'Robert Rodriguez',
  },
  {
    key: 'Rosio',
    label: 'Rosio',
  },
  {
    key: 'Regina',
    label: 'Regina',
  },
  {
    key: 'Resort Report',
    label: 'Resort Report',
  },
  {
    key: 'Rope Picnic',
    label: 'Rope Picnic',
  },
  {
    key: 'Royal Classic',
    label: 'Royal Classic',
  },
  {
    key: 'Riviera',
    label: 'Riviera',
  },
  {
    key: 'Ralph Lauren Denim & Supply',
    label: 'Ralph Lauren Denim & Supply',
  },
  {
    key: "Roy Roger's",
    label: "Roy Roger's",
  },
  {
    key: 'Rainforest',
    label: 'Rainforest',
  },
  {
    key: 'Red Blue Denim',
    label: 'Red Blue Denim',
  },
  {
    key: 'Rumours',
    label: 'Rumours',
  },
  {
    key: 'Ritva Falla',
    label: 'Ritva Falla',
  },
  {
    key: 'Rich And Royal',
    label: 'Rich And Royal',
  },
  {
    key: 'Red Kap',
    label: 'Red Kap',
  },
  {
    key: 'Rock Denim',
    label: 'Rock Denim',
  },
  {
    key: 'Radnik',
    label: 'Radnik',
  },
  {
    key: 'Rock A Bye',
    label: 'Rock A Bye',
  },
  {
    key: 'Rebel For Life',
    label: 'Rebel For Life',
  },
  {
    key: 'Rai',
    label: 'Rai',
  },
  {
    key: 'Radii',
    label: 'Radii',
  },
  {
    key: 'Redefine Your Identity',
    label: 'Redefine Your Identity',
  },
  {
    key: 'Randigarutan',
    label: 'Randigarutan',
  },
  {
    key: 'Rough Justice',
    label: 'Rough Justice',
  },
  {
    key: "R'adys",
    label: "R'adys",
  },
  {
    key: 'Richards',
    label: 'Richards',
  },
  {
    key: 'Ruby Rocks',
    label: 'Ruby Rocks',
  },
  {
    key: 'Roeckl',
    label: 'Roeckl',
  },
  {
    key: 'Realtree',
    label: 'Realtree',
  },
  {
    key: 'Roma Concept',
    label: 'Roma Concept',
  },
  {
    key: 'Rockland',
    label: 'Rockland',
  },
  {
    key: 'Reformation',
    label: 'Reformation',
  },
  {
    key: 'Ralph Lauren Golf',
    label: 'Ralph Lauren Golf',
  },
  {
    key: "Radek's",
    label: "Radek's",
  },
  {
    key: 'Redial Diamond',
    label: 'Redial Diamond',
  },
  {
    key: 'Renato Angi',
    label: 'Renato Angi',
  },
  {
    key: 'Russell & Bromley',
    label: 'Russell & Bromley',
  },
  {
    key: 'Review By Sami Slimani',
    label: 'Review By Sami Slimani',
  },
  {
    key: 'Race Marine',
    label: 'Race Marine',
  },
  {
    key: 'Richlu',
    label: 'Richlu',
  },
  {
    key: 'Robert Barakett',
    label: 'Robert Barakett',
  },
  {
    key: 'Runway',
    label: 'Runway',
  },
  {
    key: 'Radley',
    label: 'Radley',
  },
  {
    key: 'Rensix',
    label: 'Rensix',
  },
  {
    key: 'Ro & De',
    label: 'Ro & De',
  },
  {
    key: 'Rok',
    label: 'Rok',
  },
  {
    key: 'Rundholz',
    label: 'Rundholz',
  },
  {
    key: 'Rue Blanche',
    label: 'Rue Blanche',
  },
  {
    key: 'Rayure',
    label: 'Rayure',
  },
  {
    key: 'Rebecca Stella',
    label: 'Rebecca Stella',
  },
  {
    key: 'Rivamonti',
    label: 'Rivamonti',
  },
  {
    key: 'Reitmayer',
    label: 'Reitmayer',
  },
  {
    key: 'Roberta Scarpa',
    label: 'Roberta Scarpa',
  },
  {
    key: 'Reference Point',
    label: 'Reference Point',
  },
  {
    key: 'Rock Revival',
    label: 'Rock Revival',
  },
  {
    key: 'Rudsak',
    label: 'Rudsak',
  },
  {
    key: 'Roiii',
    label: 'Roiii',
  },
  {
    key: 'Renato Nucci',
    label: 'Renato Nucci',
  },
  {
    key: 'Romika',
    label: 'Romika',
  },
  {
    key: 'Rick Owens',
    label: 'Rick Owens',
  },
  {
    key: 'Rock Republic Victoria Beckham',
    label: 'Rock Republic Victoria Beckham',
  },
  {
    key: 'Rowallan',
    label: 'Rowallan',
  },
  {
    key: 'Rockandblue',
    label: 'Rockandblue',
  },
  {
    key: 'Roberta Puccini By Baroni',
    label: 'Roberta Puccini By Baroni',
  },
  {
    key: 'Reny',
    label: 'Reny',
  },
  {
    key: 'Ron Jon',
    label: 'Ron Jon',
  },
  {
    key: 'Radio Days',
    label: 'Radio Days',
  },
  {
    key: 'R95th',
    label: 'R95th',
  },
  {
    key: 'Robert Talbott',
    label: 'Robert Talbott',
  },
  {
    key: 'Rosa & Rose',
    label: 'Rosa & Rose',
  },
  {
    key: 'Rock Eagle',
    label: 'Rock Eagle',
  },
  {
    key: 'Rad',
    label: 'Rad',
  },
  {
    key: 'Red Shoes',
    label: 'Red Shoes',
  },
  {
    key: 'Roberto Santi',
    label: 'Roberto Santi',
  },
  {
    key: 'Riddell',
    label: 'Riddell',
  },
  {
    key: 'Roberto Verino',
    label: 'Roberto Verino',
  },
  {
    key: 'Roda',
    label: 'Roda',
  },
  {
    key: 'Rosso Malaspino',
    label: 'Rosso Malaspino',
  },
  {
    key: 'Rosso Di Sera',
    label: 'Rosso Di Sera',
  },
  {
    key: 'Robert Graham',
    label: 'Robert Graham',
  },
  {
    key: 'Rossana Diva',
    label: 'Rossana Diva',
  },
  {
    key: 'Runika',
    label: 'Runika',
  },
  {
    key: 'Rick',
    label: 'Rick',
  },
  {
    key: 'Rudi & Harald',
    label: 'Rudi & Harald',
  },
  {
    key: 'Rabbit Rabbit Rabbit Designs',
    label: 'Rabbit Rabbit Rabbit Designs',
  },
  {
    key: 'Riversoft',
    label: 'Riversoft',
  },
  {
    key: 'Royal Sunday',
    label: 'Royal Sunday',
  },
  {
    key: 'Rockport By Adidas',
    label: 'Rockport By Adidas',
  },
  {
    key: 'Rohde',
    label: 'Rohde',
  },
  {
    key: 'Rene Rofe',
    label: 'Rene Rofe',
  },
  {
    key: 'Raiski',
    label: 'Raiski',
  },
  {
    key: 'Rabeanco',
    label: 'Rabeanco',
  },
  {
    key: 'Repreve',
    label: 'Repreve',
  },
  {
    key: 'Romagnoli',
    label: 'Romagnoli',
  },
  {
    key: 'Rachel Pally',
    label: 'Rachel Pally',
  },
  {
    key: 'Runners Point Edition',
    label: 'Runners Point Edition',
  },
  {
    key: 'Royal Scot',
    label: 'Royal Scot',
  },
  {
    key: 'Rizzo',
    label: 'Rizzo',
  },
  {
    key: 'Replus',
    label: 'Replus',
  },
  {
    key: 'Rodebjer',
    label: 'Rodebjer',
  },
  {
    key: 'Rikkemai',
    label: 'Rikkemai',
  },
  {
    key: 'Ronit Zilkha',
    label: 'Ronit Zilkha',
  },
  {
    key: 'Remedios',
    label: 'Remedios',
  },
  {
    key: 'Roberto Quaglia',
    label: 'Roberto Quaglia',
  },
  {
    key: 'Rushour',
    label: 'Rushour',
  },
  {
    key: 'Roosenstein Wolke',
    label: 'Roosenstein Wolke',
  },
  {
    key: 'Raphael',
    label: 'Raphael',
  },
  {
    key: 'Ronhill',
    label: 'Ronhill',
  },
  {
    key: 'Rails',
    label: 'Rails',
  },
  {
    key: 'Redman',
    label: 'Redman',
  },
  {
    key: 'Roberta Di Camerino',
    label: 'Roberta Di Camerino',
  },
  {
    key: 'Rory Beca',
    label: 'Rory Beca',
  },
  {
    key: 'Rachel Kate',
    label: 'Rachel Kate',
  },
  {
    key: 'S.Oliver',
    label: 'S.Oliver',
  },
  {
    key: 'Street One',
    label: 'Street One',
  },
  {
    key: 'Stefanel',
    label: 'Stefanel',
  },
  {
    key: 'Springfield',
    label: 'Springfield',
  },
  {
    key: 'Stradivarius',
    label: 'Stradivarius',
  },
  {
    key: 'Sisley',
    label: 'Sisley',
  },
  {
    key: 'Superdry',
    label: 'Superdry',
  },
  {
    key: 'Selected Homme',
    label: 'Selected Homme',
  },
  {
    key: 'Supre',
    label: 'Supre',
  },
  {
    key: 'Swing',
    label: 'Swing',
  },
  {
    key: 'Soya Concept',
    label: 'Soya Concept',
  },
  {
    key: 'Sun68',
    label: 'Sun68',
  },
  {
    key: 'Sisters Point',
    label: 'Sisters Point',
  },
  {
    key: 'Schott',
    label: 'Schott',
  },
  {
    key: 'Slazenger',
    label: 'Slazenger',
  },
  {
    key: 'Saint Tropez',
    label: 'Saint Tropez',
  },
  {
    key: 'Samsoe & Samsoe',
    label: 'Samsoe & Samsoe',
  },
  {
    key: 'Scotch & Soda',
    label: 'Scotch & Soda',
  },
  {
    key: 'Sinequanone',
    label: 'Sinequanone',
  },
  {
    key: 'Silvian Heach',
    label: 'Silvian Heach',
  },
  {
    key: 'Sinsay',
    label: 'Sinsay',
  },
  {
    key: 'Style & Co',
    label: 'Style & Co',
  },
  {
    key: 'Strellson',
    label: 'Strellson',
  },
  {
    key: "St. John's Bay",
    label: "St. John's Bay",
  },
  {
    key: 'Smog',
    label: 'Smog',
  },
  {
    key: 'Steve Madden',
    label: 'Steve Madden',
  },
  {
    key: 'Suzy Shier',
    label: 'Suzy Shier',
  },
  {
    key: 'Skiny',
    label: 'Skiny',
  },
  {
    key: 'Steilmann',
    label: 'Steilmann',
  },
  {
    key: 'Sonoma',
    label: 'Sonoma',
  },
  {
    key: 'Sublevel',
    label: 'Sublevel',
  },
  {
    key: 'Sure',
    label: 'Sure',
  },
  {
    key: 'Sportscraft',
    label: 'Sportscraft',
  },
  {
    key: 'Spyder',
    label: 'Spyder',
  },
  {
    key: 'Sir Raymond Tailor',
    label: 'Sir Raymond Tailor',
  },
  {
    key: 'Studio',
    label: 'Studio',
  },
  {
    key: 'Someday.',
    label: 'Someday.',
  },
  {
    key: 'Sistaglam',
    label: 'Sistaglam',
  },
  {
    key: 'Select',
    label: 'Select',
  },
  {
    key: 'Sandwich_',
    label: 'Sandwich_',
  },
  {
    key: 'Soulcal & Co',
    label: 'Soulcal & Co',
  },
  {
    key: 'Sandro Ferrone',
    label: 'Sandro Ferrone',
  },
  {
    key: 'Set',
    label: 'Set',
  },
  {
    key: 'Smash',
    label: 'Smash',
  },
  {
    key: 'Sand',
    label: 'Sand',
  },
  {
    key: 'Sergio Tacchini',
    label: 'Sergio Tacchini',
  },
  {
    key: 'Saba',
    label: 'Saba',
  },
  {
    key: 'Seductions',
    label: 'Seductions',
  },
  {
    key: 'Steffen Schraut',
    label: 'Steffen Schraut',
  },
  {
    key: 'Schoffel',
    label: 'Schoffel',
  },
  {
    key: 'Sa. Hara',
    label: 'Sa. Hara',
  },
  {
    key: 'Second Female',
    label: 'Second Female',
  },
  {
    key: 'Savage Culture',
    label: 'Savage Culture',
  },
  {
    key: 'Simply Be',
    label: 'Simply Be',
  },
  {
    key: 'Stockh Lm',
    label: 'Stockh Lm',
  },
  {
    key: 'Simply Vera - Vera Wang',
    label: 'Simply Vera - Vera Wang',
  },
  {
    key: 'Schiesser',
    label: 'Schiesser',
  },
  {
    key: 'Sheike',
    label: 'Sheike',
  },
  {
    key: 'Sasch',
    label: 'Sasch',
  },
  {
    key: 'Sutherland',
    label: 'Sutherland',
  },
  {
    key: 'Staccato',
    label: 'Staccato',
  },
  {
    key: 'Second Script',
    label: 'Second Script',
  },
  {
    key: 'Supertrash',
    label: 'Supertrash',
  },
  {
    key: "S'nob By Sans Noblesse",
    label: "S'nob By Sans Noblesse",
  },
  {
    key: 'St.Emile',
    label: 'St.Emile',
  },
  {
    key: 'Stella Forest',
    label: 'Stella Forest',
  },
  {
    key: 'St-Martins',
    label: 'St-Martins',
  },
  {
    key: 'Shamp',
    label: 'Shamp',
  },
  {
    key: 'Strenesse',
    label: 'Strenesse',
  },
  {
    key: 'Sansibar',
    label: 'Sansibar',
  },
  {
    key: 'Sorbino',
    label: 'Sorbino',
  },
  {
    key: 'Sandro',
    label: 'Sandro',
  },
  {
    key: 'Style',
    label: 'Style',
  },
  {
    key: 'Signature',
    label: 'Signature',
  },
  {
    key: 'Selected',
    label: 'Selected',
  },
  {
    key: 'Sweewe',
    label: 'Sweewe',
  },
  {
    key: 'Switcher',
    label: 'Switcher',
  },
  {
    key: 'St. Michael Marks & Spencer',
    label: 'St. Michael Marks & Spencer',
  },
  {
    key: 'Slide Show',
    label: 'Slide Show',
  },
  {
    key: 'Shana',
    label: 'Shana',
  },
  {
    key: 'Showpo',
    label: 'Showpo',
  },
  {
    key: 'Siviglia',
    label: 'Siviglia',
  },
  {
    key: 'She',
    label: 'She',
  },
  {
    key: 'Sisters',
    label: 'Sisters',
  },
  {
    key: 'See By Chloe',
    label: 'See By Chloe',
  },
  {
    key: 'Stella',
    label: 'Stella',
  },
  {
    key: 'Soho',
    label: 'Soho',
  },
  {
    key: 'Sportalm',
    label: 'Sportalm',
  },
  {
    key: 'Sarah Kern',
    label: 'Sarah Kern',
  },
  {
    key: 'Stella Nova',
    label: 'Stella Nova',
  },
  {
    key: 'Seduce',
    label: 'Seduce',
  },
  {
    key: 'Sommermann',
    label: 'Sommermann',
  },
  {
    key: 'Strenesse Blue',
    label: 'Strenesse Blue',
  },
  {
    key: 'Siksilk',
    label: 'Siksilk',
  },
  {
    key: 'Seventy',
    label: 'Seventy',
  },
  {
    key: 'Salewa',
    label: 'Salewa',
  },
  {
    key: 'Sag Harbor',
    label: 'Sag Harbor',
  },
  {
    key: 'Stitch & Soul',
    label: 'Stitch & Soul',
  },
  {
    key: 'Suite Blanco',
    label: 'Suite Blanco',
  },
  {
    key: 'Steps',
    label: 'Steps',
  },
  {
    key: 'Suzy By Suzy Shier',
    label: 'Suzy By Suzy Shier',
  },
  {
    key: 'Sean John',
    label: 'Sean John',
  },
  {
    key: 'Sita Murt',
    label: 'Sita Murt',
  },
  {
    key: 'Sportmax',
    label: 'Sportmax',
  },
  {
    key: 'Soccx',
    label: 'Soccx',
  },
  {
    key: 'Strenesse Gabriele Strehle',
    label: 'Strenesse Gabriele Strehle',
  },
  {
    key: 'Samoon',
    label: 'Samoon',
  },
  {
    key: 'Splendid',
    label: 'Splendid',
  },
  {
    key: 'Salomon',
    label: 'Salomon',
  },
  {
    key: 'Sessun',
    label: 'Sessun',
  },
  {
    key: 'Summum Woman',
    label: 'Summum Woman',
  },
  {
    key: 'South',
    label: 'South',
  },
  {
    key: 'Schneiders',
    label: 'Schneiders',
  },
  {
    key: 'Sergio',
    label: 'Sergio',
  },
  {
    key: 'Star By Julien Macdonald',
    label: 'Star By Julien Macdonald',
  },
  {
    key: 'Suri Frey',
    label: 'Suri Frey',
  },
  {
    key: 'Sherpa',
    label: 'Sherpa',
  },
  {
    key: 'Signum',
    label: 'Signum',
  },
  {
    key: 'Sarar',
    label: 'Sarar',
  },
  {
    key: 'Southern',
    label: 'Southern',
  },
  {
    key: 'Speechless',
    label: 'Speechless',
  },
  {
    key: 'Schumacher',
    label: 'Schumacher',
  },
  {
    key: 'S:Aix',
    label: 'S:Aix',
  },
  {
    key: 'Studio M',
    label: 'Studio M',
  },
  {
    key: 'Saints & Mortals',
    label: 'Saints & Mortals',
  },
  {
    key: 'Stanley Stella',
    label: 'Stanley Stella',
  },
  {
    key: 'Samoon By Gerry Weber',
    label: 'Samoon By Gerry Weber',
  },
  {
    key: 'Smitten',
    label: 'Smitten',
  },
  {
    key: 'Stella Mccartney',
    label: 'Stella Mccartney',
  },
  {
    key: 'Stephanie Mathews',
    label: 'Stephanie Mathews',
  },
  {
    key: 'Selection By Ulla Popken',
    label: 'Selection By Ulla Popken',
  },
  {
    key: 'Sadie',
    label: 'Sadie',
  },
  {
    key: 'Sand Jeans',
    label: 'Sand Jeans',
  },
  {
    key: 'Soon',
    label: 'Soon',
  },
  {
    key: 'Steinbock',
    label: 'Steinbock',
  },
  {
    key: 'Scanlan Theodore',
    label: 'Scanlan Theodore',
  },
  {
    key: 'Soaked',
    label: 'Soaked',
  },
  {
    key: 'Starling',
    label: 'Starling',
  },
  {
    key: 'Spengler',
    label: 'Spengler',
  },
  {
    key: 'Savage',
    label: 'Savage',
  },
  {
    key: 'Sem Per Lei',
    label: 'Sem Per Lei',
  },
  {
    key: 'Samsonite',
    label: 'Samsonite',
  },
  {
    key: 'Stella Belcheva',
    label: 'Stella Belcheva',
  },
  {
    key: 'Shiwi',
    label: 'Shiwi',
  },
  {
    key: 'Sergio Rossi',
    label: 'Sergio Rossi',
  },
  {
    key: 'St. Bernard',
    label: 'St. Bernard',
  },
  {
    key: 'Sergent Major',
    label: 'Sergent Major',
  },
  {
    key: 'Superior',
    label: 'Superior',
  },
  {
    key: 'Sterntaler',
    label: 'Sterntaler',
  },
  {
    key: 'Scooter',
    label: 'Scooter',
  },
  {
    key: 'Softgrey La Redoute',
    label: 'Softgrey La Redoute',
  },
  {
    key: 'Sinty',
    label: 'Sinty',
  },
  {
    key: 'So Soire',
    label: 'So Soire',
  },
  {
    key: 'Sofie Schnoor',
    label: 'Sofie Schnoor',
  },
  {
    key: 'Simplee',
    label: 'Simplee',
  },
  {
    key: 'Stuart Weitzman',
    label: 'Stuart Weitzman',
  },
  {
    key: 'South Pole',
    label: 'South Pole',
  },
  {
    key: 'Storm & Marie',
    label: 'Storm & Marie',
  },
  {
    key: 'Santa Barbara',
    label: 'Santa Barbara',
  },
  {
    key: 'Stoosh',
    label: 'Stoosh',
  },
  {
    key: 'Sixth June',
    label: 'Sixth June',
  },
  {
    key: 'Sorel',
    label: 'Sorel',
  },
  {
    key: 'Sky',
    label: 'Sky',
  },
  {
    key: 'Soft Grey',
    label: 'Soft Grey',
  },
  {
    key: 'Sass & Bide',
    label: 'Sass & Bide',
  },
  {
    key: 'Studio 75',
    label: 'Studio 75',
  },
  {
    key: 'Somewhere',
    label: 'Somewhere',
  },
  {
    key: 'Sambara',
    label: 'Sambara',
  },
  {
    key: 'Speeckless',
    label: 'Speeckless',
  },
  {
    key: 'Seeberger',
    label: 'Seeberger',
  },
  {
    key: 'Stills',
    label: 'Stills',
  },
  {
    key: 'Smith & Jones',
    label: 'Smith & Jones',
  },
  {
    key: 'Soc',
    label: 'Soc',
  },
  {
    key: 'Siena Studio',
    label: 'Siena Studio',
  },
  {
    key: 'Seidel',
    label: 'Seidel',
  },
  {
    key: 'Stella Morgan',
    label: 'Stella Morgan',
  },
  {
    key: 'Sympli',
    label: 'Sympli',
  },
  {
    key: 'Stormtech Performance',
    label: 'Stormtech Performance',
  },
  {
    key: 'Style Butler',
    label: 'Style Butler',
  },
  {
    key: 'Stenstroms',
    label: 'Stenstroms',
  },
  {
    key: 'Sibin Linnebjerg',
    label: 'Sibin Linnebjerg',
  },
  {
    key: 'Simply Chic',
    label: 'Simply Chic',
  },
  {
    key: 'Sexy Woman',
    label: 'Sexy Woman',
  },
  {
    key: 'Sport Tech',
    label: 'Sport Tech',
  },
  {
    key: 'Sallie Sahne',
    label: 'Sallie Sahne',
  },
  {
    key: 'Seaside',
    label: 'Seaside',
  },
  {
    key: 'Saloos',
    label: 'Saloos',
  },
  {
    key: 'Schutz',
    label: 'Schutz',
  },
  {
    key: 'Stacy Adams',
    label: 'Stacy Adams',
  },
  {
    key: 'Sangria',
    label: 'Sangria',
  },
  {
    key: 'Suit',
    label: 'Suit',
  },
  {
    key: 'Sam Edelman',
    label: 'Sam Edelman',
  },
  {
    key: 'Shosho',
    label: 'Shosho',
  },
  {
    key: 'Suitsupply',
    label: 'Suitsupply',
  },
  {
    key: 'Spanx By Sara Blakely',
    label: 'Spanx By Sara Blakely',
  },
  {
    key: 'Sabo Skirt',
    label: 'Sabo Skirt',
  },
  {
    key: 'Soul Cal',
    label: 'Soul Cal',
  },
  {
    key: 'Save The Queen',
    label: 'Save The Queen',
  },
  {
    key: 'Signature Studio',
    label: 'Signature Studio',
  },
  {
    key: 'Sonja Marohn',
    label: 'Sonja Marohn',
  },
  {
    key: 'Skunkfunk',
    label: 'Skunkfunk',
  },
  {
    key: 'Slacks & Co.',
    label: 'Slacks & Co.',
  },
  {
    key: 'Sunichi',
    label: 'Sunichi',
  },
  {
    key: 'Solin',
    label: 'Solin',
  },
  {
    key: 'Scapa',
    label: 'Scapa',
  },
  {
    key: 'Studio Only',
    label: 'Studio Only',
  },
  {
    key: 'Soccs',
    label: 'Soccs',
  },
  {
    key: 'Sonia Fortuna',
    label: 'Sonia Fortuna',
  },
  {
    key: 'Saint James',
    label: 'Saint James',
  },
  {
    key: 'Spirale',
    label: 'Spirale',
  },
  {
    key: 'Stedman',
    label: 'Stedman',
  },
  {
    key: 'Sam & Max',
    label: 'Sam & Max',
  },
  {
    key: 'Sfizio',
    label: 'Sfizio',
  },
  {
    key: 'Studio Y',
    label: 'Studio Y',
  },
  {
    key: 'Straight Up',
    label: 'Straight Up',
  },
  {
    key: 'Shearling',
    label: 'Shearling',
  },
  {
    key: 'Stanley Blacker',
    label: 'Stanley Blacker',
  },
  {
    key: 'Singh S Madan',
    label: 'Singh S Madan',
  },
  {
    key: 'Smartfit',
    label: 'Smartfit',
  },
  {
    key: 'Studio 8',
    label: 'Studio 8',
  },
  {
    key: 'Shubcheery',
    label: 'Shubcheery',
  },
  {
    key: 'Scott',
    label: 'Scott',
  },
  {
    key: 'Sejour',
    label: 'Sejour',
  },
  {
    key: 'Sperry',
    label: 'Sperry',
  },
  {
    key: 'Scope',
    label: 'Scope',
  },
  {
    key: 'Slinky',
    label: 'Slinky',
  },
  {
    key: 'Seal Kay',
    label: 'Seal Kay',
  },
  {
    key: 'Susana Monaco',
    label: 'Susana Monaco',
  },
  {
    key: 'Skin To Skin',
    label: 'Skin To Skin',
  },
  {
    key: 'Sabatini',
    label: 'Sabatini',
  },
  {
    key: 'Saint Jacques',
    label: 'Saint Jacques',
  },
  {
    key: 'Sonja Blank',
    label: 'Sonja Blank',
  },
  {
    key: 'Stefano',
    label: 'Stefano',
  },
  {
    key: 'Simona Barbieri',
    label: 'Simona Barbieri',
  },
  {
    key: 'Shendel',
    label: 'Shendel',
  },
  {
    key: 'Sea Side',
    label: 'Sea Side',
  },
  {
    key: 'Sarah Pacini',
    label: 'Sarah Pacini',
  },
  {
    key: 'Sequoia',
    label: 'Sequoia',
  },
  {
    key: 'St. Michael',
    label: 'St. Michael',
  },
  {
    key: 'Sail Exp',
    label: 'Sail Exp',
  },
  {
    key: 'Sis Sis',
    label: 'Sis Sis',
  },
  {
    key: 'Suzy Smith',
    label: 'Suzy Smith',
  },
  {
    key: 'Scervino Street',
    label: 'Scervino Street',
  },
  {
    key: 'Shoe Aquarium',
    label: 'Shoe Aquarium',
  },
  {
    key: 'Sunnow',
    label: 'Sunnow',
  },
  {
    key: 'Seductive',
    label: 'Seductive',
  },
  {
    key: 'Sweet Wanderer',
    label: 'Sweet Wanderer',
  },
  {
    key: 'Sonia Rykiel',
    label: 'Sonia Rykiel',
  },
  {
    key: 'Sintesis',
    label: 'Sintesis',
  },
  {
    key: 'Schnizler',
    label: 'Schnizler',
  },
  {
    key: 'Spykar Jeans',
    label: 'Spykar Jeans',
  },
  {
    key: 'Sangermano Collection',
    label: 'Sangermano Collection',
  },
  {
    key: 'Shakuhachi',
    label: 'Shakuhachi',
  },
  {
    key: 'Storets',
    label: 'Storets',
  },
  {
    key: 'Stig P',
    label: 'Stig P',
  },
  {
    key: 'Shelli Segal',
    label: 'Shelli Segal',
  },
  {
    key: 'Stonefly',
    label: 'Stonefly',
  },
  {
    key: 'Stefan Green',
    label: 'Stefan Green',
  },
  {
    key: 'Scarlet Roos',
    label: 'Scarlet Roos',
  },
  {
    key: 'Sonia By Sonia Rykiel',
    label: 'Sonia By Sonia Rykiel',
  },
  {
    key: 'Sg',
    label: 'Sg',
  },
  {
    key: 'Sovereign',
    label: 'Sovereign',
  },
  {
    key: 'Sylver',
    label: 'Sylver',
  },
  {
    key: 'Somewear',
    label: 'Somewear',
  },
  {
    key: 'Stine Goya',
    label: 'Stine Goya',
  },
  {
    key: 'Scotch Co.',
    label: 'Scotch Co.',
  },
  {
    key: 'Sseinse',
    label: 'Sseinse',
  },
  {
    key: 'Scotch Shrunk',
    label: 'Scotch Shrunk',
  },
  {
    key: 'Safran',
    label: 'Safran',
  },
  {
    key: 'Sartorial',
    label: 'Sartorial',
  },
  {
    key: 'Sottomarino',
    label: 'Sottomarino',
  },
  {
    key: 'Sail Racing',
    label: 'Sail Racing',
  },
  {
    key: 'Stylo',
    label: 'Stylo',
  },
  {
    key: 'Soybu',
    label: 'Soybu',
  },
  {
    key: 'Soia & Kyo',
    label: 'Soia & Kyo',
  },
  {
    key: 'Semler',
    label: 'Semler',
  },
  {
    key: 'Straight Down',
    label: 'Straight Down',
  },
  {
    key: 'Surface To Air',
    label: 'Surface To Air',
  },
  {
    key: "Scotch R'belle",
    label: "Scotch R'belle",
  },
  {
    key: 'Sherpani',
    label: 'Sherpani',
  },
  {
    key: 'Sabrina',
    label: 'Sabrina',
  },
  {
    key: 'Sandra Darren',
    label: 'Sandra Darren',
  },
  {
    key: 'Sachi',
    label: 'Sachi',
  },
  {
    key: 'Sami & Jo',
    label: 'Sami & Jo',
  },
  {
    key: 'Super Ego',
    label: 'Super Ego',
  },
  {
    key: 'Superfine',
    label: 'Superfine',
  },
  {
    key: 'Spijkers En Spijkers',
    label: 'Spijkers En Spijkers',
  },
  {
    key: 'Selfie',
    label: 'Selfie',
  },
  {
    key: 'Stizzoli',
    label: 'Stizzoli',
  },
  {
    key: 'Sexy Girl',
    label: 'Sexy Girl',
  },
  {
    key: 'Save The Duck',
    label: 'Save The Duck',
  },
  {
    key: 'Southern Territory',
    label: 'Southern Territory',
  },
  {
    key: 'Sateen',
    label: 'Sateen',
  },
  {
    key: "Steppin' Out",
    label: "Steppin' Out",
  },
  {
    key: 'Soulland',
    label: 'Soulland',
  },
  {
    key: 'Savvy Citizen',
    label: 'Savvy Citizen',
  },
  {
    key: 'Scrapbook',
    label: 'Scrapbook',
  },
  {
    key: 'Sj-05',
    label: 'Sj-05',
  },
  {
    key: 'Sunny Taylor',
    label: 'Sunny Taylor',
  },
  {
    key: 'Setter Lady',
    label: 'Setter Lady',
  },
  {
    key: 'Sweet Protection',
    label: 'Sweet Protection',
  },
  {
    key: 'Sezane X La Redoute',
    label: 'Sezane X La Redoute',
  },
  {
    key: 'Sasha',
    label: 'Sasha',
  },
  {
    key: 'Shine Denim',
    label: 'Shine Denim',
  },
  {
    key: 'Sneaky Steve',
    label: 'Sneaky Steve',
  },
  {
    key: 'Sport Wear',
    label: 'Sport Wear',
  },
  {
    key: 'Solo Donna',
    label: 'Solo Donna',
  },
  {
    key: 'Sport Haley',
    label: 'Sport Haley',
  },
  {
    key: 'Smithroad',
    label: 'Smithroad',
  },
  {
    key: 'So Wear It Declare It',
    label: 'So Wear It Declare It',
  },
  {
    key: 'Swiss Cross',
    label: 'Swiss Cross',
  },
  {
    key: 'Samantha',
    label: 'Samantha',
  },
  {
    key: 'Stylepit',
    label: 'Stylepit',
  },
  {
    key: 'Superiore',
    label: 'Superiore',
  },
  {
    key: 'Shabbies Amsterdam',
    label: 'Shabbies Amsterdam',
  },
  {
    key: 'Silver Peak',
    label: 'Silver Peak',
  },
  {
    key: "Smith's",
    label: "Smith's",
  },
  {
    key: 'Stella Dutti',
    label: 'Stella Dutti',
  },
  {
    key: 'Sportika',
    label: 'Sportika',
  },
  {
    key: 'S Jeng',
    label: 'S Jeng',
  },
  {
    key: 'Stella Mccartney For H&M',
    label: 'Stella Mccartney For H&M',
  },
  {
    key: 'Silk And Soie',
    label: 'Silk And Soie',
  },
  {
    key: 'Sandqvist',
    label: 'Sandqvist',
  },
  {
    key: 'Sos',
    label: 'Sos',
  },
  {
    key: 'Salvatore Ferragamo',
    label: 'Salvatore Ferragamo',
  },
  {
    key: 'Seychelles',
    label: 'Seychelles',
  },
  {
    key: 'Sound Girl',
    label: 'Sound Girl',
  },
  {
    key: 'Studio Pollini',
    label: 'Studio Pollini',
  },
  {
    key: 'Stantt',
    label: 'Stantt',
  },
  {
    key: 'S-Line',
    label: 'S-Line',
  },
  {
    key: 'Startee',
    label: 'Startee',
  },
  {
    key: 'S & G',
    label: 'S & G',
  },
  {
    key: 'Smith',
    label: 'Smith',
  },
  {
    key: 'Skandia',
    label: 'Skandia',
  },
  {
    key: 'Susskind',
    label: 'Susskind',
  },
  {
    key: 'Skinnifit',
    label: 'Skinnifit',
  },
  {
    key: 'Shelby&Palmer',
    label: 'Shelby&Palmer',
  },
  {
    key: 'Sinclaire',
    label: 'Sinclaire',
  },
  {
    key: 'Shop Art',
    label: 'Shop Art',
  },
  {
    key: 'Swear',
    label: 'Swear',
  },
  {
    key: 'Sara Morgan For Haband',
    label: 'Sara Morgan For Haband',
  },
  {
    key: 'Street Level',
    label: 'Street Level',
  },
  {
    key: 'St. John',
    label: 'St. John',
  },
  {
    key: 'Swear-London',
    label: 'Swear-London',
  },
  {
    key: 'Sweet Years',
    label: 'Sweet Years',
  },
  {
    key: 'Senso Unico',
    label: 'Senso Unico',
  },
  {
    key: 'Sagarmatha',
    label: 'Sagarmatha',
  },
  {
    key: 'Santa Cruz',
    label: 'Santa Cruz',
  },
  {
    key: 'Salvage',
    label: 'Salvage',
  },
  {
    key: 'Serena Williams',
    label: 'Serena Williams',
  },
  {
    key: 'Sheri Martin',
    label: 'Sheri Martin',
  },
  {
    key: 'Stride Rite',
    label: 'Stride Rite',
  },
  {
    key: 'Snugadoo Too',
    label: 'Snugadoo Too',
  },
  {
    key: 'Second Skin',
    label: 'Second Skin',
  },
  {
    key: 'Swarovski',
    label: 'Swarovski',
  },
  {
    key: 'Shock Absorber',
    label: 'Shock Absorber',
  },
  {
    key: 'Starting Point',
    label: 'Starting Point',
  },
  {
    key: "Stitch's",
    label: "Stitch's",
  },
  {
    key: 'Simply Tshirt',
    label: 'Simply Tshirt',
  },
  {
    key: 'Sarlini',
    label: 'Sarlini',
  },
  {
    key: 'Simply Yours',
    label: 'Simply Yours',
  },
  {
    key: 'Suzanne Nilsson',
    label: 'Suzanne Nilsson',
  },
  {
    key: 'Sophie Rose',
    label: 'Sophie Rose',
  },
  {
    key: 'Sanmaru',
    label: 'Sanmaru',
  },
  {
    key: 'Silvio Tossi',
    label: 'Silvio Tossi',
  },
  {
    key: 'Stumpf',
    label: 'Stumpf',
  },
  {
    key: 'S.Pa',
    label: 'S.Pa',
  },
  {
    key: 'Spring Boost',
    label: 'Spring Boost',
  },
  {
    key: 'Stella Tweed',
    label: 'Stella Tweed',
  },
  {
    key: 'Stone Bay',
    label: 'Stone Bay',
  },
  {
    key: 'Shewky',
    label: 'Shewky',
  },
  {
    key: 'Stormberg',
    label: 'Stormberg',
  },
  {
    key: 'Scott & Scott',
    label: 'Scott & Scott',
  },
  {
    key: 'Sticks And Stones',
    label: 'Sticks And Stones',
  },
  {
    key: 'Stone Island',
    label: 'Stone Island',
  },
  {
    key: 'S&D',
    label: 'S&D',
  },
  {
    key: 'Stay Warm By Tcm',
    label: 'Stay Warm By Tcm',
  },
  {
    key: "Smith's America",
    label: "Smith's America",
  },
  {
    key: 'Sulu',
    label: 'Sulu',
  },
  {
    key: 'Sakkas',
    label: 'Sakkas',
  },
  {
    key: 'Stups',
    label: 'Stups',
  },
  {
    key: 'Silver Star',
    label: 'Silver Star',
  },
  {
    key: 'Streetammo',
    label: 'Streetammo',
  },
  {
    key: 'Saint John',
    label: 'Saint John',
  },
  {
    key: 'See Thru Soul',
    label: 'See Thru Soul',
  },
  {
    key: 'Swildens',
    label: 'Swildens',
  },
  {
    key: 'Seven Souls',
    label: 'Seven Souls',
  },
  {
    key: 'Sniff',
    label: 'Sniff',
  },
  {
    key: 'Simms',
    label: 'Simms',
  },
  {
    key: 'Smitty',
    label: 'Smitty',
  },
  {
    key: 'Sneaker Freak',
    label: 'Sneaker Freak',
  },
  {
    key: 'Steenholt',
    label: 'Steenholt',
  },
  {
    key: 'Scott & Taylor',
    label: 'Scott & Taylor',
  },
  {
    key: 'Sligo',
    label: 'Sligo',
  },
  {
    key: 'Scoop',
    label: 'Scoop',
  },
  {
    key: 'Silhouette',
    label: 'Silhouette',
  },
  {
    key: 'Stohr',
    label: 'Stohr',
  },
  {
    key: 'Steeam Bee Inspired',
    label: 'Steeam Bee Inspired',
  },
  {
    key: 'Six-O-Seven',
    label: 'Six-O-Seven',
  },
  {
    key: 'Snake Eyes',
    label: 'Snake Eyes',
  },
  {
    key: 'Sumdex',
    label: 'Sumdex',
  },
  {
    key: 'Swingster',
    label: 'Swingster',
  },
  {
    key: 'Stummer',
    label: 'Stummer',
  },
  {
    key: 'Stone',
    label: 'Stone',
  },
  {
    key: 'Suchita',
    label: 'Suchita',
  },
  {
    key: 'Someone For Jd Williams',
    label: 'Someone For Jd Williams',
  },
  {
    key: 'Sugarfly',
    label: 'Sugarfly',
  },
  {
    key: 'Spurr',
    label: 'Spurr',
  },
  {
    key: "Sofie D'hoore",
    label: "Sofie D'hoore",
  },
  {
    key: 'Sportful',
    label: 'Sportful',
  },
  {
    key: 'Sorelle Baldi',
    label: 'Sorelle Baldi',
  },
  {
    key: 'Samuel Dong',
    label: 'Samuel Dong',
  },
  {
    key: 'Safa',
    label: 'Safa',
  },
  {
    key: 'Scott Barber',
    label: 'Scott Barber',
  },
  {
    key: 'St. Moritz',
    label: 'St. Moritz',
  },
  {
    key: 'Shine',
    label: 'Shine',
  },
  {
    key: 'Shikha London',
    label: 'Shikha London',
  },
  {
    key: 'Stardust',
    label: 'Stardust',
  },
  {
    key: 'Superstition',
    label: 'Superstition',
  },
  {
    key: 'Sabine',
    label: 'Sabine',
  },
  {
    key: 'Scooterplus',
    label: 'Scooterplus',
  },
  {
    key: 'Stetson',
    label: 'Stetson',
  },
  {
    key: 'Striessnig',
    label: 'Striessnig',
  },
  {
    key: 'Sanrio',
    label: 'Sanrio',
  },
  {
    key: 'Sweet Heart Rose',
    label: 'Sweet Heart Rose',
  },
  {
    key: 'Simple City',
    label: 'Simple City',
  },
  {
    key: 'Slvr By Adidas',
    label: 'Slvr By Adidas',
  },
  {
    key: 'Seal Of Haritage',
    label: 'Seal Of Haritage',
  },
  {
    key: 'Shabbies Amsterdam',
    label: 'Shabbies Amsterdam',
  },
  {
    key: 'Sweater Works',
    label: 'Sweater Works',
  },
  {
    key: 'Stone Mountain',
    label: 'Stone Mountain',
  },
  {
    key: 'Sparky',
    label: 'Sparky',
  },
  {
    key: 'Sassy Classy',
    label: 'Sassy Classy',
  },
  {
    key: 'Sos Jensen',
    label: 'Sos Jensen',
  },
  {
    key: 'Sunnyfair',
    label: 'Sunnyfair',
  },
  {
    key: 'Soft Line',
    label: 'Soft Line',
  },
  {
    key: 'Sara Michelle',
    label: 'Sara Michelle',
  },
  {
    key: 'Stefi Talman',
    label: 'Stefi Talman',
  },
  {
    key: 'Stella & Dot',
    label: 'Stella & Dot',
  },
  {
    key: 'Sommerkind',
    label: 'Sommerkind',
  },
  {
    key: 'Sojos',
    label: 'Sojos',
  },
  {
    key: "Spencer's",
    label: "Spencer's",
  },
  {
    key: 'Softwalk',
    label: 'Softwalk',
  },
  {
    key: 'Shepherd',
    label: 'Shepherd',
  },
  {
    key: 'Summer Hill',
    label: 'Summer Hill',
  },
  {
    key: 'Sandler',
    label: 'Sandler',
  },
  {
    key: 'Spago Collection',
    label: 'Spago Collection',
  },
  {
    key: 'Style Dome',
    label: 'Style Dome',
  },
  {
    key: 'Soboho',
    label: 'Soboho',
  },
  {
    key: 'Sam & Libby',
    label: 'Sam & Libby',
  },
  {
    key: 'Score Michail',
    label: 'Score Michail',
  },
  {
    key: 'Stephen',
    label: 'Stephen',
  },
  {
    key: 'Sb Boutique',
    label: 'Sb Boutique',
  },
  {
    key: 'Sezane',
    label: 'Sezane',
  },
  {
    key: 'Skip Hop',
    label: 'Skip Hop',
  },
  {
    key: 'Saint Laurent',
    label: 'Saint Laurent',
  },
  {
    key: 'Siempre Es Viernes',
    label: 'Siempre Es Viernes',
  },
  {
    key: 'Sonja Kiefer',
    label: 'Sonja Kiefer',
  },
  {
    key: 'Schuhtzengel',
    label: 'Schuhtzengel',
  },
  {
    key: 'Stylestalker',
    label: 'Stylestalker',
  },
  {
    key: 'Snow Traffic',
    label: 'Snow Traffic',
  },
  {
    key: 'Serge Blanco',
    label: 'Serge Blanco',
  },
  {
    key: 'Sweet & Soft',
    label: 'Sweet & Soft',
  },
  {
    key: 'Smileyes',
    label: 'Smileyes',
  },
  {
    key: 'Saint Andre',
    label: 'Saint Andre',
  },
  {
    key: 'Sepala',
    label: 'Sepala',
  },
  {
    key: 'Sergio Cassani',
    label: 'Sergio Cassani',
  },
  {
    key: 'Steven Alan',
    label: 'Steven Alan',
  },
  {
    key: 'Sondra Roberts',
    label: 'Sondra Roberts',
  },
  {
    key: 'Stylesnob',
    label: 'Stylesnob',
  },
  {
    key: 'Siwy',
    label: 'Siwy',
  },
  {
    key: 'Sweet Sktbs',
    label: 'Sweet Sktbs',
  },
  {
    key: 'Sash',
    label: 'Sash',
  },
  {
    key: 'Siren Lily',
    label: 'Siren Lily',
  },
  {
    key: 'Sunflair',
    label: 'Sunflair',
  },
  {
    key: 'Scandia Woods',
    label: 'Scandia Woods',
  },
  {
    key: 'Spot Oni',
    label: 'Spot Oni',
  },
  {
    key: 'Sofft',
    label: 'Sofft',
  },
  {
    key: 'Shae',
    label: 'Shae',
  },
  {
    key: 'Star Mela',
    label: 'Star Mela',
  },
  {
    key: 'Studio West',
    label: 'Studio West',
  },
  {
    key: 'Santa Monica',
    label: 'Santa Monica',
  },
  {
    key: 'Sneak Peek',
    label: 'Sneak Peek',
  },
  {
    key: 'Soie',
    label: 'Soie',
  },
  {
    key: 'Sartori Dodici',
    label: 'Sartori Dodici',
  },
  {
    key: 'Serengeti',
    label: 'Serengeti',
  },
  {
    key: 'Sahza',
    label: 'Sahza',
  },
  {
    key: 'Special Collection',
    label: 'Special Collection',
  },
  {
    key: 'Smalto',
    label: 'Smalto',
  },
  {
    key: 'Sierra Designs',
    label: 'Sierra Designs',
  },
  {
    key: 'Shoe The Bear',
    label: 'Shoe The Bear',
  },
  {
    key: '',
    label: '',
  },
  {
    key: 'T',
    label: 'T',
  },
  {
    key: 'Tom Tailor',
    label: 'Tom Tailor',
  },
  {
    key: 'Tally Weijl',
    label: 'Tally Weijl',
  },
  {
    key: 'Topshop',
    label: 'Topshop',
  },
  {
    key: 'Tommy Hilfiger',
    label: 'Tommy Hilfiger',
  },
  {
    key: 'Twinset',
    label: 'Twinset',
  },
  {
    key: 'Timberland',
    label: 'Timberland',
  },
  {
    key: 'Tcm',
    label: 'Tcm',
  },
  {
    key: 'Tfnc London',
    label: 'Tfnc London',
  },
  {
    key: 'Taifun',
    label: 'Taifun',
  },
  {
    key: 'Terranova',
    label: 'Terranova',
  },
  {
    key: 'Twintip',
    label: 'Twintip',
  },
  {
    key: 'Topman',
    label: 'Topman',
  },
  {
    key: 'Tout Feu Tout Femme',
    label: 'Tout Feu Tout Femme',
  },
  {
    key: 'Talbots',
    label: 'Talbots',
  },
  {
    key: 'Ted Baker',
    label: 'Ted Baker',
  },
  {
    key: 'Twenty One',
    label: 'Twenty One',
  },
  {
    key: 'The North Face',
    label: 'The North Face',
  },
  {
    key: 'Tiger Of Sweden',
    label: 'Tiger Of Sweden',
  },
  {
    key: 'Tamaris',
    label: 'Tamaris',
  },
  {
    key: 'Trussardi Jeans',
    label: 'Trussardi Jeans',
  },
  {
    key: 'Tu',
    label: 'Tu',
  },
  {
    key: 'Tuzzi',
    label: 'Tuzzi',
  },
  {
    key: 'Trespass',
    label: 'Trespass',
  },
  {
    key: 'True Religion',
    label: 'True Religion',
  },
  {
    key: 'Tahari By Arthur S. Levine',
    label: 'Tahari By Arthur S. Levine',
  },
  {
    key: 'Tezenis',
    label: 'Tezenis',
  },
  {
    key: 'Turnover',
    label: 'Turnover',
  },
  {
    key: 'Tigha',
    label: 'Tigha',
  },
  {
    key: 'True Decadence',
    label: 'True Decadence',
  },
  {
    key: 'Tahari',
    label: 'Tahari',
  },
  {
    key: 'Tatuum',
    label: 'Tatuum',
  },
  {
    key: 'Tara',
    label: 'Tara',
  },
  {
    key: 'Timezone',
    label: 'Timezone',
  },
  {
    key: 'Trussardi',
    label: 'Trussardi',
  },
  {
    key: 'Triangle By S.Oliver',
    label: 'Triangle By S.Oliver',
  },
  {
    key: 'Timeout',
    label: 'Timeout',
  },
  {
    key: 'Toms',
    label: 'Toms',
  },
  {
    key: 'Threadbare',
    label: 'Threadbare',
  },
  {
    key: 'Tiffosi',
    label: 'Tiffosi',
  },
  {
    key: 'Torrente',
    label: 'Torrente',
  },
  {
    key: 'Trueprodigy',
    label: 'Trueprodigy',
  },
  {
    key: 'Takko Fashion',
    label: 'Takko Fashion',
  },
  {
    key: "The Children's Place",
    label: "The Children's Place",
  },
  {
    key: 'Taifun By Gerry Weber',
    label: 'Taifun By Gerry Weber',
  },
  {
    key: 'Tosca Blu',
    label: 'Tosca Blu',
  },
  {
    key: 'The Kooples',
    label: 'The Kooples',
  },
  {
    key: 'Texstar',
    label: 'Texstar',
  },
  {
    key: 'Threadz',
    label: 'Threadz',
  },
  {
    key: 'Tramontana',
    label: 'Tramontana',
  },
  {
    key: "Teddy's",
    label: "Teddy's",
  },
  {
    key: 'Taylor & Wright',
    label: 'Taylor & Wright',
  },
  {
    key: 'T.M.Lewin',
    label: 'T.M.Lewin',
  },
  {
    key: 'Tara Jarmon',
    label: 'Tara Jarmon',
  },
  {
    key: 'Tobi',
    label: 'Tobi',
  },
  {
    key: 'Tweed River',
    label: 'Tweed River',
  },
  {
    key: 'Toxik 3',
    label: 'Toxik 3',
  },
  {
    key: 'Takeout',
    label: 'Takeout',
  },
  {
    key: 'Trina Turk',
    label: 'Trina Turk',
  },
  {
    key: 'Tantum O.N.',
    label: 'Tantum O.N.',
  },
  {
    key: 'Taking Shape',
    label: 'Taking Shape',
  },
  {
    key: 'Theory',
    label: 'Theory',
  },
  {
    key: 'Take Two',
    label: 'Take Two',
  },
  {
    key: 'Tommy Bahama',
    label: 'Tommy Bahama',
  },
  {
    key: 'Trevolution',
    label: 'Trevolution',
  },
  {
    key: 'Thurley',
    label: 'Thurley',
  },
  {
    key: 'The Fifth Label',
    label: 'The Fifth Label',
  },
  {
    key: 'Top Fashion',
    label: 'Top Fashion',
  },
  {
    key: 'Trend One',
    label: 'Trend One',
  },
  {
    key: 'Toni Gard',
    label: 'Toni Gard',
  },
  {
    key: 'Trigema',
    label: 'Trigema',
  },
  {
    key: "Tod's",
    label: "Tod's",
  },
  {
    key: "Travelin'",
    label: "Travelin'",
  },
  {
    key: 'Tara M',
    label: 'Tara M',
  },
  {
    key: 'Ted Lapidus',
    label: 'Ted Lapidus',
  },
  {
    key: 'Top Studio',
    label: 'Top Studio',
  },
  {
    key: 'Tokyo Laundry',
    label: 'Tokyo Laundry',
  },
  {
    key: 'Tina R',
    label: 'Tina R',
  },
  {
    key: 'Tout Simplement',
    label: 'Tout Simplement',
  },
  {
    key: 'Twenty8twelve',
    label: 'Twenty8twelve',
  },
  {
    key: 'The Kooples Sport',
    label: 'The Kooples Sport',
  },
  {
    key: 'The Academy Brand',
    label: 'The Academy Brand',
  },
  {
    key: 'Topolino',
    label: 'Topolino',
  },
  {
    key: 'Tigerlily',
    label: 'Tigerlily',
  },
  {
    key: 'Tenson',
    label: 'Tenson',
  },
  {
    key: 'Thomas Rath',
    label: 'Thomas Rath',
  },
  {
    key: 'Tailor Store',
    label: 'Tailor Store',
  },
  {
    key: 'Thea Plus',
    label: 'Thea Plus',
  },
  {
    key: 'Teenie Weenie',
    label: 'Teenie Weenie',
  },
  {
    key: 'Thomas Nash',
    label: 'Thomas Nash',
  },
  {
    key: 'Teodor',
    label: 'Teodor',
  },
  {
    key: 'Tokyo Fashion',
    label: 'Tokyo Fashion',
  },
  {
    key: 'Toni Dress',
    label: 'Toni Dress',
  },
  {
    key: 'Trucco',
    label: 'Trucco',
  },
  {
    key: 'Truly Mine',
    label: 'Truly Mine',
  },
  {
    key: 'Tziacco',
    label: 'Tziacco',
  },
  {
    key: 'Tex By Max Azria',
    label: 'Tex By Max Azria',
  },
  {
    key: 'T-Bags',
    label: 'T-Bags',
  },
  {
    key: "The Hackbarth's",
    label: "The Hackbarth's",
  },
  {
    key: 'Tracy Reese',
    label: 'Tracy Reese',
  },
  {
    key: 'Thea',
    label: 'Thea',
  },
  {
    key: 'Twist',
    label: 'Twist',
  },
  {
    key: 'Tribord',
    label: 'Tribord',
  },
  {
    key: 'Tea N Rose',
    label: 'Tea N Rose',
  },
  {
    key: 'To Be Famous',
    label: 'To Be Famous',
  },
  {
    key: 'Thomas Pink',
    label: 'Thomas Pink',
  },
  {
    key: 'Title Nine',
    label: 'Title Nine',
  },
  {
    key: 'Tulipano',
    label: 'Tulipano',
  },
  {
    key: 'Topshop Tall',
    label: 'Topshop Tall',
  },
  {
    key: 'Tricky Tracks',
    label: 'Tricky Tracks',
  },
  {
    key: 'Three Little Words',
    label: 'Three Little Words',
  },
  {
    key: 'Think Pink',
    label: 'Think Pink',
  },
  {
    key: 'Temperance',
    label: 'Temperance',
  },
  {
    key: 'Tracey',
    label: 'Tracey',
  },
  {
    key: 'Tory Burch',
    label: 'Tory Burch',
  },
  {
    key: 'Tony Bianco',
    label: 'Tony Bianco',
  },
  {
    key: 'Traffic',
    label: 'Traffic',
  },
  {
    key: 'Toi Du Monde',
    label: 'Toi Du Monde',
  },
  {
    key: 'Terry Lewis',
    label: 'Terry Lewis',
  },
  {
    key: 'The Flexx',
    label: 'The Flexx',
  },
  {
    key: 'Tignanello',
    label: 'Tignanello',
  },
  {
    key: 'Tombolini',
    label: 'Tombolini',
  },
  {
    key: 'Teatro',
    label: 'Teatro',
  },
  {
    key: 'Topomini',
    label: 'Topomini',
  },
  {
    key: 'The Legend',
    label: 'The Legend',
  },
  {
    key: "Tessile D'oro",
    label: "Tessile D'oro",
  },
  {
    key: 'Thomas Albrecht',
    label: 'Thomas Albrecht',
  },
  {
    key: 'The Sak',
    label: 'The Sak',
  },
  {
    key: 'Tania Kay',
    label: 'Tania Kay',
  },
  {
    key: 'Tailya',
    label: 'Tailya',
  },
  {
    key: 'Tom Ford',
    label: 'Tom Ford',
  },
  {
    key: 'Tide',
    label: 'Tide',
  },
  {
    key: 'Tendenza',
    label: 'Tendenza',
  },
  {
    key: 'Tone Barker',
    label: 'Tone Barker',
  },
  {
    key: 'Tempted',
    label: 'Tempted',
  },
  {
    key: 'Tallia',
    label: 'Tallia',
  },
  {
    key: 'Thomas Wylde',
    label: 'Thomas Wylde',
  },
  {
    key: 'Tangerine',
    label: 'Tangerine',
  },
  {
    key: 'The Webster Miami At Target',
    label: 'The Webster Miami At Target',
  },
  {
    key: 'Tailored Originals',
    label: 'Tailored Originals',
  },
  {
    key: 'Toni Sailer',
    label: 'Toni Sailer',
  },
  {
    key: 'Twisted Gorilla',
    label: 'Twisted Gorilla',
  },
  {
    key: 'Toad & Go',
    label: 'Toad & Go',
  },
  {
    key: 'Teri Jon',
    label: 'Teri Jon',
  },
  {
    key: 'Tiffany Rose',
    label: 'Tiffany Rose',
  },
  {
    key: 'Texier',
    label: 'Texier',
  },
  {
    key: 'Taylor & Sage',
    label: 'Taylor & Sage',
  },
  {
    key: 'Tucker Tate',
    label: 'Tucker Tate',
  },
  {
    key: 'T Bar',
    label: 'T Bar',
  },
  {
    key: 'Tommy Life',
    label: 'Tommy Life',
  },
  {
    key: 'Trine Kryger Simonsen',
    label: 'Trine Kryger Simonsen',
  },
  {
    key: 'T.Babaton',
    label: 'T.Babaton',
  },
  {
    key: 'Thierry Mugler',
    label: 'Thierry Mugler',
  },
  {
    key: 'Tammy',
    label: 'Tammy',
  },
  {
    key: 'Tibi',
    label: 'Tibi',
  },
  {
    key: 'Tenax',
    label: 'Tenax',
  },
  {
    key: 'T.M.Lewin',
    label: 'T.M.Lewin',
  },
  {
    key: "Tape A L'oeil",
    label: "Tape A L'oeil",
  },
  {
    key: 'Tine K Home',
    label: 'Tine K Home',
  },
  {
    key: 'Tauledesign',
    label: 'Tauledesign',
  },
  {
    key: 'Tailored By Solid',
    label: 'Tailored By Solid',
  },
  {
    key: 'Tatyana',
    label: 'Tatyana',
  },
  {
    key: 'Tom-Du',
    label: 'Tom-Du',
  },
  {
    key: 'Tecnifibre',
    label: 'Tecnifibre',
  },
  {
    key: 'Tune In',
    label: 'Tune In',
  },
  {
    key: 'Tailor Vintage',
    label: 'Tailor Vintage',
  },
  {
    key: 'Toughskins',
    label: 'Toughskins',
  },
  {
    key: 'Tlc',
    label: 'Tlc',
  },
  {
    key: 'Tsumori Chisato',
    label: 'Tsumori Chisato',
  },
  {
    key: 'Tehama',
    label: 'Tehama',
  },
  {
    key: 'Tt. Studio',
    label: 'Tt. Studio',
  },
  {
    key: 'Thug Life',
    label: 'Thug Life',
  },
  {
    key: 'Tadashi',
    label: 'Tadashi',
  },
  {
    key: 'Tiger Woods Collection By Nike',
    label: 'Tiger Woods Collection By Nike',
  },
  {
    key: 'Tish & Cash',
    label: 'Tish & Cash',
  },
  {
    key: 'Tinddo',
    label: 'Tinddo',
  },
  {
    key: 'Tamrac',
    label: 'Tamrac',
  },
  {
    key: 'True Nation',
    label: 'True Nation',
  },
  {
    key: 'Talbot Runhof',
    label: 'Talbot Runhof',
  },
  {
    key: 'Tse',
    label: 'Tse',
  },
  {
    key: 'Thanth',
    label: 'Thanth',
  },
  {
    key: 'Teddi',
    label: 'Teddi',
  },
  {
    key: 'Thom Mcan',
    label: 'Thom Mcan',
  },
  {
    key: 'Tsubo',
    label: 'Tsubo',
  },
  {
    key: 'Trimtex',
    label: 'Trimtex',
  },
  {
    key: 'Toast',
    label: 'Toast',
  },
  {
    key: 'Torn By Ronny Kobo',
    label: 'Torn By Ronny Kobo',
  },
  {
    key: 'Tractr',
    label: 'Tractr',
  },
  {
    key: 'Truffle',
    label: 'Truffle',
  },
  {
    key: 'Tolani',
    label: 'Tolani',
  },
  {
    key: 'Tabitha',
    label: 'Tabitha',
  },
  {
    key: 'Thakoon',
    label: 'Thakoon',
  },
  {
    key: 'Twenty-29',
    label: 'Twenty-29',
  },
  {
    key: 'Toby',
    label: 'Toby',
  },
  {
    key: 'Tag',
    label: 'Tag',
  },
  {
    key: 'Trikora',
    label: 'Trikora',
  },
  {
    key: 'Two Blue Sisters',
    label: 'Two Blue Sisters',
  },
  {
    key: 'Tauern Gwand',
    label: 'Tauern Gwand',
  },
  {
    key: 'Temperley',
    label: 'Temperley',
  },
  {
    key: 'Toral',
    label: 'Toral',
  },
  {
    key: 'Thml',
    label: 'Thml',
  },
  {
    key: 'Tazzio',
    label: 'Tazzio',
  },
  {
    key: 'Tonello',
    label: 'Tonello',
  },
  {
    key: 'Tony Brown',
    label: 'Tony Brown',
  },
  {
    key: 'Trunks',
    label: 'Trunks',
  },
  {
    key: 'Ten Points',
    label: 'Ten Points',
  },
  {
    key: 'Teddy Bear',
    label: 'Teddy Bear',
  },
  {
    key: 'Timex',
    label: 'Timex',
  },
  {
    key: 'Talia Benson',
    label: 'Talia Benson',
  },
  {
    key: 'Tea',
    label: 'Tea',
  },
  {
    key: 'Tony Cohen',
    label: 'Tony Cohen',
  },
  {
    key: 'Tough Jeansmith',
    label: 'Tough Jeansmith',
  },
  {
    key: 'Tanoli',
    label: 'Tanoli',
  },
  {
    key: 'Transparente',
    label: 'Transparente',
  },
  {
    key: 'Thomas Gee',
    label: 'Thomas Gee',
  },
  {
    key: 'Timber',
    label: 'Timber',
  },
  {
    key: 'Tesori',
    label: 'Tesori',
  },
  {
    key: 'Tissot',
    label: 'Tissot',
  },
  {
    key: 'Tailor Jack',
    label: 'Tailor Jack',
  },
  {
    key: 'Tricot Chic',
    label: 'Tricot Chic',
  },
  {
    key: 'Tori Richard',
    label: 'Tori Richard',
  },
  {
    key: 'Tempo Paris',
    label: 'Tempo Paris',
  },
  {
    key: 'Tani',
    label: 'Tani',
  },
  {
    key: 'Tagliatore',
    label: 'Tagliatore',
  },
  {
    key: 'Tfs',
    label: 'Tfs',
  },
  {
    key: 'Tom Ramsey',
    label: 'Tom Ramsey',
  },
  {
    key: 'Tough Jeans',
    label: 'Tough Jeans',
  },
  {
    key: 'The Hip Tee',
    label: 'The Hip Tee',
  },
  {
    key: 'Tecnica',
    label: 'Tecnica',
  },
  {
    key: 'The Kids Devision',
    label: 'The Kids Devision',
  },
  {
    key: 'Thomas Burberry',
    label: 'Thomas Burberry',
  },
  {
    key: 'The Shoe Tailor',
    label: 'The Shoe Tailor',
  },
  {
    key: 'The Pyramid Collection',
    label: 'The Pyramid Collection',
  },
  {
    key: 'T.U.K.',
    label: 'T.U.K.',
  },
  {
    key: 'Tn T8',
    label: 'Tn T8',
  },
  {
    key: 'Tucker',
    label: 'Tucker',
  },
  {
    key: 'Thomas Rabe',
    label: 'Thomas Rabe',
  },
  {
    key: 'Tulchan',
    label: 'Tulchan',
  },
  {
    key: 'The Mercer N.Y.',
    label: 'The Mercer N.Y.',
  },
  {
    key: 'Tadashi Shoji',
    label: 'Tadashi Shoji',
  },
  {
    key: 'Trixi Schober',
    label: 'Trixi Schober',
  },
  {
    key: 'The Mountain',
    label: 'The Mountain',
  },
  {
    key: 'Terramar',
    label: 'Terramar',
  },
  {
    key: 'Tony Moro',
    label: 'Tony Moro',
  },
  {
    key: 'Takeshy Kurosawa',
    label: 'Takeshy Kurosawa',
  },
  {
    key: 'Territory Ahead',
    label: 'Territory Ahead',
  },
  {
    key: 'Tru-Spec',
    label: 'Tru-Spec',
  },
  {
    key: 'Toska Blu',
    label: 'Toska Blu',
  },
  {
    key: 'Tommy Armour',
    label: 'Tommy Armour',
  },
  {
    key: 'Tom & Jerry',
    label: 'Tom & Jerry',
  },
  {
    key: 'Twelfth Street By Cynthia Vincent',
    label: 'Twelfth Street By Cynthia Vincent',
  },
  {
    key: 'Thomas Baldwin',
    label: 'Thomas Baldwin',
  },
  {
    key: 'Trustyle',
    label: 'Trustyle',
  },
  {
    key: 'Ten - Tex',
    label: 'Ten - Tex',
  },
  {
    key: 'Thursday Friday',
    label: 'Thursday Friday',
  },
  {
    key: 'Taschendieb',
    label: 'Taschendieb',
  },
  {
    key: 'United Colors Of Benetton',
    label: 'United Colors Of Benetton',
  },
  {
    key: 'Uniqlo',
    label: 'Uniqlo',
  },
  {
    key: 'Un Deux Trois',
    label: 'Un Deux Trois',
  },
  {
    key: 'Under Armour',
    label: 'Under Armour',
  },
  {
    key: 'U.S. Polo Assn.',
    label: 'U.S. Polo Assn.',
  },
  {
    key: 'Up 2 Fashion',
    label: 'Up 2 Fashion',
  },
  {
    key: 'Ulla Popken',
    label: 'Ulla Popken',
  },
  {
    key: 'Umbro',
    label: 'Umbro',
  },
  {
    key: 'Usco',
    label: 'Usco',
  },
  {
    key: 'Units',
    label: 'Units',
  },
  {
    key: 'Urban Surface',
    label: 'Urban Surface',
  },
  {
    key: 'Unique',
    label: 'Unique',
  },
  {
    key: 'Ugg Australia',
    label: 'Ugg Australia',
  },
  {
    key: 'Urban Behavior',
    label: 'Urban Behavior',
  },
  {
    key: 'Urban Outfitters',
    label: 'Urban Outfitters',
  },
  {
    key: 'Urban Supply',
    label: 'Urban Supply',
  },
  {
    key: 'Unique 21',
    label: 'Unique 21',
  },
  {
    key: 'Utex',
    label: 'Utex',
  },
  {
    key: 'Un Jour Ailleurs',
    label: 'Un Jour Ailleurs',
  },
  {
    key: 'Umberto Rosetti',
    label: 'Umberto Rosetti',
  },
  {
    key: 'Unisa',
    label: 'Unisa',
  },
  {
    key: 'Unisono',
    label: 'Unisono',
  },
  {
    key: 'Uldahl',
    label: 'Uldahl',
  },
  {
    key: 'Untold',
    label: 'Untold',
  },
  {
    key: 'Uniquely Zero',
    label: 'Uniquely Zero',
  },
  {
    key: 'Utopia',
    label: 'Utopia',
  },
  {
    key: 'Ungaro',
    label: 'Ungaro',
  },
  {
    key: 'Unica',
    label: 'Unica',
  },
  {
    key: 'Uma Parker',
    label: 'Uma Parker',
  },
  {
    key: 'Ungaro Fever',
    label: 'Ungaro Fever',
  },
  {
    key: 'Unique Spectrum',
    label: 'Unique Spectrum',
  },
  {
    key: 'United Nude',
    label: 'United Nude',
  },
  {
    key: 'Ulvang',
    label: 'Ulvang',
  },
  {
    key: 'Uniqlo And Lemaire',
    label: 'Uniqlo And Lemaire',
  },
  {
    key: 'Uniworth',
    label: 'Uniworth',
  },
  {
    key: 'Ultimo',
    label: 'Ultimo',
  },
  {
    key: 'Un Jean',
    label: 'Un Jean',
  },
  {
    key: 'Us Polo Assn.',
    label: 'Us Polo Assn.',
  },
  {
    key: "Ultimate Mik's",
    label: "Ultimate Mik's",
  },
  {
    key: 'Umberto Ginocchietti',
    label: 'Umberto Ginocchietti',
  },
  {
    key: 'Underground Soul',
    label: 'Underground Soul',
  },
  {
    key: 'Uli Schneider',
    label: 'Uli Schneider',
  },
  {
    key: 'Unger',
    label: 'Unger',
  },
  {
    key: 'Us Marshall Official',
    label: 'Us Marshall Official',
  },
  {
    key: 'Unutzer',
    label: 'Unutzer',
  },
  {
    key: 'Ugly',
    label: 'Ugly',
  },
  {
    key: 'Unconditional',
    label: 'Unconditional',
  },
  {
    key: 'Us Basic',
    label: 'Us Basic',
  },
  {
    key: 'Urban Spirit',
    label: 'Urban Spirit',
  },
  {
    key: 'Vila',
    label: 'Vila',
  },
  {
    key: 'Vera Mont',
    label: 'Vera Mont',
  },
  {
    key: 'Van Heusen',
    label: 'Van Heusen',
  },
  {
    key: 'Vans',
    label: 'Vans',
  },
  {
    key: 'Volcom',
    label: 'Volcom',
  },
  {
    key: 'Vert De Rage',
    label: 'Vert De Rage',
  },
  {
    key: 'Violeta By Mango',
    label: 'Violeta By Mango',
  },
  {
    key: 'Via Appia',
    label: 'Via Appia',
  },
  {
    key: 'Vanessa Bruno',
    label: 'Vanessa Bruno',
  },
  {
    key: 'Venice Beach',
    label: 'Venice Beach',
  },
  {
    key: 'Van Laack',
    label: 'Van Laack',
  },
  {
    key: 'Vibram',
    label: 'Vibram',
  },
  {
    key: 'Vince Camuto',
    label: 'Vince Camuto',
  },
  {
    key: 'Veronika Maine',
    label: 'Veronika Maine',
  },
  {
    key: 'Vanille & Chocolat',
    label: 'Vanille & Chocolat',
  },
  {
    key: 'Via Cortesa',
    label: 'Via Cortesa',
  },
  {
    key: 'Vivien Caron',
    label: 'Vivien Caron',
  },
  {
    key: 'Vittorio Rossi',
    label: 'Vittorio Rossi',
  },
  {
    key: 'Vestino',
    label: 'Vestino',
  },
  {
    key: 'Vanilia',
    label: 'Vanilia',
  },
  {
    key: 'Viggo',
    label: 'Viggo',
  },
  {
    key: 'Vavite',
    label: 'Vavite',
  },
  {
    key: 'Versace Jeans',
    label: 'Versace Jeans',
  },
  {
    key: "Victoria's Secret",
    label: "Victoria's Secret",
  },
  {
    key: 'Vera Pelle',
    label: 'Vera Pelle',
  },
  {
    key: 'Vincenzo Boretti',
    label: 'Vincenzo Boretti',
  },
  {
    key: 'Vintage',
    label: 'Vintage',
  },
  {
    key: 'Vaude',
    label: 'Vaude',
  },
  {
    key: 'Vinci',
    label: 'Vinci',
  },
  {
    key: 'Vera & Lucy',
    label: 'Vera & Lucy',
  },
  {
    key: 'Via Spiga',
    label: 'Via Spiga',
  },
  {
    key: 'Voice Of Europe',
    label: 'Voice Of Europe',
  },
  {
    key: 'Vanilia Elements',
    label: 'Vanilia Elements',
  },
  {
    key: 'Vineyard Vines',
    label: 'Vineyard Vines',
  },
  {
    key: 'Vanessa Bruno Athé',
    label: 'Vanessa Bruno Athé',
  },
  {
    key: 'Vroom & Dreesmann',
    label: 'Vroom & Dreesmann',
  },
  {
    key: 'Victoria',
    label: 'Victoria',
  },
  {
    key: 'Vicolo',
    label: 'Vicolo',
  },
  {
    key: 'Velvet',
    label: 'Velvet',
  },
  {
    key: 'Van Gils',
    label: 'Van Gils',
  },
  {
    key: 'Vince',
    label: 'Vince',
  },
  {
    key: 'Victoria Moda',
    label: 'Victoria Moda',
  },
  {
    key: 'Viktor & Rolf',
    label: 'Viktor & Rolf',
  },
  {
    key: 'Vayana',
    label: 'Vayana',
  },
  {
    key: 'Verge',
    label: 'Verge',
  },
  {
    key: 'Vitivic',
    label: 'Vitivic',
  },
  {
    key: 'Vera Varelli',
    label: 'Vera Varelli',
  },
  {
    key: 'Vicomte A.',
    label: 'Vicomte A.',
  },
  {
    key: 'Vianni',
    label: 'Vianni',
  },
  {
    key: 'Vices',
    label: 'Vices',
  },
  {
    key: 'Vangeliza',
    label: 'Vangeliza',
  },
  {
    key: 'Vera Bradley',
    label: 'Vera Bradley',
  },
  {
    key: 'Ventilo La Colline',
    label: 'Ventilo La Colline',
  },
  {
    key: 'Valia',
    label: 'Valia',
  },
  {
    key: "Victoria Sport By Victoria's Secret",
    label: "Victoria Sport By Victoria's Secret",
  },
  {
    key: 'Virgo',
    label: 'Virgo',
  },
  {
    key: 'Versus Versace',
    label: 'Versus Versace',
  },
  {
    key: 'Versace For H&M',
    label: 'Versace For H&M',
  },
  {
    key: 'Via Giulia',
    label: 'Via Giulia',
  },
  {
    key: 'Valette',
    label: 'Valette',
  },
  {
    key: 'Vegea',
    label: 'Vegea',
  },
  {
    key: 'Vansydical',
    label: 'Vansydical',
  },
  {
    key: 'Vanessa Virginia',
    label: 'Vanessa Virginia',
  },
  {
    key: 'Vera Finzzi',
    label: 'Vera Finzzi',
  },
  {
    key: 'Voi&Noi',
    label: 'Voi&Noi',
  },
  {
    key: 'Vivienne Westwood Anglomania',
    label: 'Vivienne Westwood Anglomania',
  },
  {
    key: 'Vali',
    label: 'Vali',
  },
  {
    key: 'Vivienne Westwood',
    label: 'Vivienne Westwood',
  },
  {
    key: 'Varese',
    label: 'Varese',
  },
  {
    key: 'Volkl',
    label: 'Volkl',
  },
  {
    key: 'Vesper',
    label: 'Vesper',
  },
  {
    key: 'Vicky Martin',
    label: 'Vicky Martin',
  },
  {
    key: 'Vintage Love',
    label: 'Vintage Love',
  },
  {
    key: 'Vanzeer',
    label: 'Vanzeer',
  },
  {
    key: 'Versace',
    label: 'Versace',
  },
  {
    key: 'Viacorta',
    label: 'Viacorta',
  },
  {
    key: 'Virgos Lounge',
    label: 'Virgos Lounge',
  },
  {
    key: 'Vabeene',
    label: 'Vabeene',
  },
  {
    key: 'Valentino',
    label: 'Valentino',
  },
  {
    key: 'Vitale Barberis Canonico',
    label: 'Vitale Barberis Canonico',
  },
  {
    key: 'Va Bene',
    label: 'Va Bene',
  },
  {
    key: 'Via Borgospesso',
    label: 'Via Borgospesso',
  },
  {
    key: 'Victor & Rolf For H&M',
    label: 'Victor & Rolf For H&M',
  },
  {
    key: 'Volksfursorge',
    label: 'Volksfursorge',
  },
  {
    key: 'Vigorella',
    label: 'Vigorella',
  },
  {
    key: 'Via Vai',
    label: 'Via Vai',
  },
  {
    key: 'Vertical Design',
    label: 'Vertical Design',
  },
  {
    key: 'Vicardo',
    label: 'Vicardo',
  },
  {
    key: 'Victoria Layne',
    label: 'Victoria Layne',
  },
  {
    key: 'Volcom Stone',
    label: 'Volcom Stone',
  },
  {
    key: 'Vero Cuoio',
    label: 'Vero Cuoio',
  },
  {
    key: 'Voodoo Vixen',
    label: 'Voodoo Vixen',
  },
  {
    key: 'Vic',
    label: 'Vic',
  },
  {
    key: 'Victoria Delef',
    label: 'Victoria Delef',
  },
  {
    key: 'Vera Gomma',
    label: 'Vera Gomma',
  },
  {
    key: 'Ventuno 21',
    label: 'Ventuno 21',
  },
  {
    key: 'Victoria Beckham',
    label: 'Victoria Beckham',
  },
  {
    key: 'Vanitta',
    label: 'Vanitta',
  },
  {
    key: 'Vilagallo',
    label: 'Vilagallo',
  },
  {
    key: 'Via Nova',
    label: 'Via Nova',
  },
  {
    key: 'Very J',
    label: 'Very J',
  },
  {
    key: 'Veja',
    label: 'Veja',
  },
  {
    key: 'Vadum',
    label: 'Vadum',
  },
  {
    key: 'Via Veneto',
    label: 'Via Veneto',
  },
  {
    key: 'Vitti Love',
    label: 'Vitti Love',
  },
  {
    key: 'Vic Matié',
    label: 'Vic Matié',
  },
  {
    key: 'Vert Dense',
    label: 'Vert Dense',
  },
  {
    key: 'Vanny',
    label: 'Vanny',
  },
  {
    key: 'Vionic',
    label: 'Vionic',
  },
  {
    key: 'Vrolijke Famkes',
    label: 'Vrolijke Famkes',
  },
  {
    key: 'Vossatassar',
    label: 'Vossatassar',
  },
  {
    key: 'Vintage America Blues',
    label: 'Vintage America Blues',
  },
  {
    key: 'Valerio Garati',
    label: 'Valerio Garati',
  },
  {
    key: 'Victoria & Karl',
    label: 'Victoria & Karl',
  },
  {
    key: 'Victoria Jones',
    label: 'Victoria Jones',
  },
  {
    key: 'Valentino Di Mario Valentino',
    label: 'Valentino Di Mario Valentino',
  },
  {
    key: 'Visconti',
    label: 'Visconti',
  },
  {
    key: 'Veronella',
    label: 'Veronella',
  },
  {
    key: 'Victor Victoria',
    label: 'Victor Victoria',
  },
  {
    key: 'Vansport',
    label: 'Vansport',
  },
  {
    key: 'V.I.P. Dressing',
    label: 'V.I.P. Dressing',
  },
  {
    key: 'Victoria Cran',
    label: 'Victoria Cran',
  },
  {
    key: 'Vdp Collection',
    label: 'Vdp Collection',
  },
  {
    key: 'Venum',
    label: 'Venum',
  },
  {
    key: 'Van Santen',
    label: 'Van Santen',
  },
  {
    key: 'Vitamins Baby',
    label: 'Vitamins Baby',
  },
  {
    key: 'Voxx',
    label: 'Voxx',
  },
  {
    key: 'Vera Blum',
    label: 'Vera Blum',
  },
  {
    key: 'Viktor & Rolf By H&M',
    label: 'Viktor & Rolf By H&M',
  },
  {
    key: 'Vera Wang',
    label: 'Vera Wang',
  },
  {
    key: '',
    label: '',
  },
  {
    key: 'W',
    label: 'W',
  },
  {
    key: "Women'secret",
    label: "Women'secret",
  },
  {
    key: 'Wallis',
    label: 'Wallis',
  },
  {
    key: 'Witchery',
    label: 'Witchery',
  },
  {
    key: 'Woman By Tchibo',
    label: 'Woman By Tchibo',
  },
  {
    key: 'Warehouse',
    label: 'Warehouse',
  },
  {
    key: 'White House / Black Market',
    label: 'White House / Black Market',
  },
  {
    key: "Watson's",
    label: "Watson's",
  },
  {
    key: 'Women By Tchibo',
    label: 'Women By Tchibo',
  },
  {
    key: 'Westbury',
    label: 'Westbury',
  },
  {
    key: 'Walbusch',
    label: 'Walbusch',
  },
  {
    key: 'W. Lane',
    label: 'W. Lane',
  },
  {
    key: 'Wrangler',
    label: 'Wrangler',
  },
  {
    key: 'Weekend Max Mara',
    label: 'Weekend Max Mara',
  },
  {
    key: 'Wet Seal',
    label: 'Wet Seal',
  },
  {
    key: 'Women Only',
    label: 'Women Only',
  },
  {
    key: 'Windsor.',
    label: 'Windsor.',
  },
  {
    key: 'Won Hundred',
    label: 'Won Hundred',
  },
  {
    key: 'Wilfred Free',
    label: 'Wilfred Free',
  },
  {
    key: 'Warehouse One',
    label: 'Warehouse One',
  },
  {
    key: 'Wish',
    label: 'Wish',
  },
  {
    key: 'Wilsons Leather',
    label: 'Wilsons Leather',
  },
  {
    key: 'Wissmach',
    label: 'Wissmach',
  },
  {
    key: 'Wood Wood',
    label: 'Wood Wood',
  },
  {
    key: 'Wonder Woman',
    label: 'Wonder Woman',
  },
  {
    key: 'Wayne Cooper',
    label: 'Wayne Cooper',
  },
  {
    key: "Woman's Fashion",
    label: "Woman's Fashion",
  },
  {
    key: 'Wellensteyn',
    label: 'Wellensteyn',
  },
  {
    key: 'Whistles',
    label: 'Whistles',
  },
  {
    key: 'Wow',
    label: 'Wow',
  },
  {
    key: 'Who What Wear',
    label: 'Who What Wear',
  },
  {
    key: 'Woodpecker',
    label: 'Woodpecker',
  },
  {
    key: 'Woolrich',
    label: 'Woolrich',
  },
  {
    key: 'Windsor Smith',
    label: 'Windsor Smith',
  },
  {
    key: 'Westport',
    label: 'Westport',
  },
  {
    key: 'Worthington Stretch',
    label: 'Worthington Stretch',
  },
  {
    key: 'White Stuff',
    label: 'White Stuff',
  },
  {
    key: 'Wolford',
    label: 'Wolford',
  },
  {
    key: 'Whiite',
    label: 'Whiite',
  },
  {
    key: 'Wera Stockholm',
    label: 'Wera Stockholm',
  },
  {
    key: "Women's Selection",
    label: "Women's Selection",
  },
  {
    key: 'Wow Couture',
    label: 'Wow Couture',
  },
  {
    key: 'Warren & Parker',
    label: 'Warren & Parker',
  },
  {
    key: 'W118 By Walter Baker',
    label: 'W118 By Walter Baker',
  },
  {
    key: 'White Closet',
    label: 'White Closet',
  },
  {
    key: 'Weinbrenner',
    label: 'Weinbrenner',
  },
  {
    key: 'Weide',
    label: 'Weide',
  },
  {
    key: 'Wilke Rodriguez',
    label: 'Wilke Rodriguez',
  },
  {
    key: 'We',
    label: 'We',
  },
  {
    key: 'Wilvorst',
    label: 'Wilvorst',
  },
  {
    key: "Women's Fashion",
    label: "Women's Fashion",
  },
  {
    key: 'Wardrobe',
    label: 'Wardrobe',
  },
  {
    key: 'Whispers',
    label: 'Whispers',
  },
  {
    key: 'Waimea',
    label: 'Waimea',
  },
  {
    key: 'Whyred',
    label: 'Whyred',
  },
  {
    key: 'W5',
    label: 'W5',
  },
  {
    key: 'Waldlaufer',
    label: 'Waldlaufer',
  },
  {
    key: 'Whistler',
    label: 'Whistler',
  },
  {
    key: 'White Label',
    label: 'White Label',
  },
  {
    key: 'Weissenstein',
    label: 'Weissenstein',
  },
  {
    key: 'Wolf & Whistle',
    label: 'Wolf & Whistle',
  },
  {
    key: 'White Mountain',
    label: 'White Mountain',
  },
  {
    key: 'Wool Overs',
    label: 'Wool Overs',
  },
  {
    key: "Women's",
    label: "Women's",
  },
  {
    key: 'Wild Diva',
    label: 'Wild Diva',
  },
  {
    key: 'Wombat',
    label: 'Wombat',
  },
  {
    key: 'W52',
    label: 'W52',
  },
  {
    key: 'Willow & Clay',
    label: 'Willow & Clay',
  },
  {
    key: "Women's Collection",
    label: "Women's Collection",
  },
  {
    key: 'Wishstone',
    label: 'Wishstone',
  },
  {
    key: 'Wittchen',
    label: 'Wittchen',
  },
  {
    key: 'Woman Collection',
    label: 'Woman Collection',
  },
  {
    key: 'Who*S Who',
    label: 'Who*S Who',
  },
  {
    key: 'Woden',
    label: 'Woden',
  },
  {
    key: 'Women Wear',
    label: 'Women Wear',
  },
  {
    key: 'West Kei',
    label: 'West Kei',
  },
  {
    key: 'Woman By Halens',
    label: 'Woman By Halens',
  },
  {
    key: 'Walkmaxx',
    label: 'Walkmaxx',
  },
  {
    key: 'Wave Board',
    label: 'Wave Board',
  },
  {
    key: 'Westco',
    label: 'Westco',
  },
  {
    key: 'Wheat',
    label: 'Wheat',
  },
  {
    key: 'Whoopi',
    label: 'Whoopi',
  },
  {
    key: 'Walk',
    label: 'Walk',
  },
  {
    key: 'Woman By Earn',
    label: 'Woman By Earn',
  },
  {
    key: 'Willow',
    label: 'Willow',
  },
  {
    key: 'Wiesnkoenig',
    label: 'Wiesnkoenig',
  },
  {
    key: 'Wax Jean',
    label: 'Wax Jean',
  },
  {
    key: 'White Label By Rofa Fashion Group',
    label: 'White Label By Rofa Fashion Group',
  },
  {
    key: 'Walter Hagen',
    label: 'Walter Hagen',
  },
  {
    key: 'Walter Baker',
    label: 'Walter Baker',
  },
  {
    key: 'Wings + Horns',
    label: 'Wings + Horns',
  },
  {
    key: 'Watch Me',
    label: 'Watch Me',
  },
  {
    key: 'Wendy',
    label: 'Wendy',
  },
  {
    key: 'W&B',
    label: 'W&B',
  },
  {
    key: 'Wild West',
    label: 'Wild West',
  },
  {
    key: 'Walnut',
    label: 'Walnut',
  },
  {
    key: 'Wewa',
    label: 'Wewa',
  },
  {
    key: 'Wrung',
    label: 'Wrung',
  },
  {
    key: 'Whale',
    label: 'Whale',
  },
  {
    key: 'Winter Silks',
    label: 'Winter Silks',
  },
  {
    key: 'Wega Fashion',
    label: 'Wega Fashion',
  },
  {
    key: 'W.A.C',
    label: 'W.A.C',
  },
  {
    key: 'Wendy Bellissimo',
    label: 'Wendy Bellissimo',
  },
  {
    key: 'Wilde',
    label: 'Wilde',
  },
  {
    key: 'Wolverine',
    label: 'Wolverine',
  },
  {
    key: 'Wittner',
    label: 'Wittner',
  },
  {
    key: 'Womyn',
    label: 'Womyn',
  },
  {
    key: 'White Mountains',
    label: 'White Mountains',
  },
  {
    key: 'Wolky',
    label: 'Wolky',
  },
  {
    key: "What's Up ?",
    label: "What's Up ?",
  },
  {
    key: 'Warwick Jones',
    label: 'Warwick Jones',
  },
  {
    key: 'Wallace & Barnes',
    label: 'Wallace & Barnes',
  },
  {
    key: 'Wld',
    label: 'Wld',
  },
  {
    key: 'Waredenim',
    label: 'Waredenim',
  },
  {
    key: 'Walter Valentino',
    label: 'Walter Valentino',
  },
  {
    key: 'Wonder Jeans',
    label: 'Wonder Jeans',
  },
  {
    key: "Win's",
    label: "Win's",
  },
  {
    key: 'Weekend Offender',
    label: 'Weekend Offender',
  },
  {
    key: "Woman's Wardrobe",
    label: "Woman's Wardrobe",
  },
  {
    key: 'Wolczanka',
    label: 'Wolczanka',
  },
  {
    key: 'Wonders',
    label: 'Wonders',
  },
  {
    key: 'White Mark',
    label: 'White Mark',
  },
  {
    key: 'Walmart',
    label: 'Walmart',
  },
  {
    key: 'Whoop',
    label: 'Whoop',
  },
  {
    key: 'With Me',
    label: 'With Me',
  },
  {
    key: 'X-Mail',
    label: 'X-Mail',
  },
  {
    key: 'Xanaka',
    label: 'Xanaka',
  },
  {
    key: 'Xx By Mexx',
    label: 'Xx By Mexx',
  },
  {
    key: 'Xside',
    label: 'Xside',
  },
  {
    key: 'Xdye',
    label: 'Xdye',
  },
  {
    key: 'Xagon Man',
    label: 'Xagon Man',
  },
  {
    key: 'Xandres',
    label: 'Xandres',
  },
  {
    key: 'Xlnt',
    label: 'Xlnt',
  },
  {
    key: 'Xyxyx',
    label: 'Xyxyx',
  },
  {
    key: 'Xint',
    label: 'Xint',
  },
  {
    key: 'Xscape',
    label: 'Xscape',
  },
  {
    key: 'X-Treme Sports Gear',
    label: 'X-Treme Sports Gear',
  },
  {
    key: 'Yessica',
    label: 'Yessica',
  },
  {
    key: 'Yumi',
    label: 'Yumi',
  },
  {
    key: 'Your Sixth Sense',
    label: 'Your Sixth Sense',
  },
  {
    key: 'Yes Or No',
    label: 'Yes Or No',
  },
  {
    key: 'Yaya',
    label: 'Yaya',
  },
  {
    key: 'Y.Yendi',
    label: 'Y.Yendi',
  },
  {
    key: 'Young Couture By Barbara Schwarzer',
    label: 'Young Couture By Barbara Schwarzer',
  },
  {
    key: 'Yest',
    label: 'Yest',
  },
  {
    key: 'Yorn',
    label: 'Yorn',
  },
  {
    key: 'Young Couture',
    label: 'Young Couture',
  },
  {
    key: 'Yigga',
    label: 'Yigga',
  },
  {
    key: 'Y.O.U',
    label: 'Y.O.U',
  },
  {
    key: 'Yoins',
    label: 'Yoins',
  },
  {
    key: 'Yours',
    label: 'Yours',
  },
  {
    key: 'Young Spirit',
    label: 'Young Spirit',
  },
  {
    key: 'Yessica Pure',
    label: 'Yessica Pure',
  },
  {
    key: 'Y.D',
    label: 'Y.D',
  },
  {
    key: 'Yves Gerard',
    label: 'Yves Gerard',
  },
  {
    key: 'Yerse',
    label: 'Yerse',
  },
  {
    key: 'Young Dimension',
    label: 'Young Dimension',
  },
  {
    key: 'Yes Zee',
    label: 'Yes Zee',
  },
  {
    key: 'Yes! Miss',
    label: 'Yes! Miss',
  },
  {
    key: 'Yamaha',
    label: 'Yamaha',
  },
  {
    key: 'Yellow Cab',
    label: 'Yellow Cab',
  },
  {
    key: 'Yves',
    label: 'Yves',
  },
  {
    key: 'Yuzi',
    label: 'Yuzi',
  },
  {
    key: 'Yes Yes',
    label: 'Yes Yes',
  },
  {
    key: 'Yvonne',
    label: 'Yvonne',
  },
  {
    key: 'Yoana Baraschi',
    label: 'Yoana Baraschi',
  },
  {
    key: 'Yohji Yamamoto',
    label: 'Yohji Yamamoto',
  },
  {
    key: 'Yummie By Heather Thomson',
    label: 'Yummie By Heather Thomson',
  },
  {
    key: 'Yumi Kim',
    label: 'Yumi Kim',
  },
  {
    key: 'Yachting & Co',
    label: 'Yachting & Co',
  },
  {
    key: 'Y-3 Adidas',
    label: 'Y-3 Adidas',
  },
  {
    key: 'Yetts',
    label: 'Yetts',
  },
  {
    key: 'Younique',
    label: 'Younique',
  },
  {
    key: 'Yeti',
    label: 'Yeti',
  },
  {
    key: 'Yumiko',
    label: 'Yumiko',
  },
  {
    key: 'Yaz Gato',
    label: 'Yaz Gato',
  },
  {
    key: 'Yves Saint Laurent',
    label: 'Yves Saint Laurent',
  },
  {
    key: 'Yazubi',
    label: 'Yazubi',
  },
  {
    key: 'Yumi-Mazao',
    label: 'Yumi-Mazao',
  },
  {
    key: 'Young Threads',
    label: 'Young Threads',
  },
  {
    key: 'Zara',
    label: 'Zara',
  },
  {
    key: 'Zara Trafaluc',
    label: 'Zara Trafaluc',
  },
  {
    key: 'Zebra',
    label: 'Zebra',
  },
  {
    key: 'Zara Man',
    label: 'Zara Man',
  },
  {
    key: 'Zara Kids',
    label: 'Zara Kids',
  },
  {
    key: 'Zara Knitwear',
    label: 'Zara Knitwear',
  },
  {
    key: 'Zero',
    label: 'Zero',
  },
  {
    key: 'Zizzi',
    label: 'Zizzi',
  },
  {
    key: 'Zapa',
    label: 'Zapa',
  },
  {
    key: 'Zeroxposur',
    label: 'Zeroxposur',
  },
  {
    key: 'Zabaione',
    label: 'Zabaione',
  },
  {
    key: 'Ze-Ze',
    label: 'Ze-Ze',
  },
  {
    key: 'Zoul',
    label: 'Zoul',
  },
  {
    key: 'Zerres',
    label: 'Zerres',
  },
  {
    key: 'Zuiki',
    label: 'Zuiki',
  },
  {
    key: 'Zadig & Voltaire',
    label: 'Zadig & Voltaire',
  },
  {
    key: 'Zaful',
    label: 'Zaful',
  },
  {
    key: 'Zign',
    label: 'Zign',
  },
  {
    key: 'Zagora',
    label: 'Zagora',
  },
  {
    key: 'Zee Lane',
    label: 'Zee Lane',
  },
  {
    key: 'Zay',
    label: 'Zay',
  },
  {
    key: 'Zero Base',
    label: 'Zero Base',
  },
  {
    key: 'Zaffiri',
    label: 'Zaffiri',
  },
  {
    key: 'Zanerobe',
    label: 'Zanerobe',
  },
  {
    key: 'Zornitsa',
    label: 'Zornitsa',
  },
  {
    key: 'Zac & Rachel',
    label: 'Zac & Rachel',
  },
  {
    key: 'Zalora',
    label: 'Zalora',
  },
  {
    key: 'Z One By Zabaione',
    label: 'Z One By Zabaione',
  },
  {
    key: 'Zoe Karssen',
    label: 'Zoe Karssen',
  },
  {
    key: 'Zucchero',
    label: 'Zucchero',
  },
  {
    key: 'Zeagoo',
    label: 'Zeagoo',
  },
  {
    key: 'Zella',
    label: 'Zella',
  },
  {
    key: 'Zarga',
    label: 'Zarga',
  },
  {
    key: 'Zipups',
    label: 'Zipups',
  },
  {
    key: 'Zanella',
    label: 'Zanella',
  },
  {
    key: 'Zab',
    label: 'Zab',
  },
  {
    key: 'Zimtstern',
    label: 'Zimtstern',
  },
  {
    key: 'Zola',
    label: 'Zola',
  },
  {
    key: 'Zu Elements',
    label: 'Zu Elements',
  },
  {
    key: 'Zegna Sport',
    label: 'Zegna Sport',
  },
  {
    key: 'Zaps',
    label: 'Zaps',
  },
  {
    key: 'Zimmerli Of Switzerland',
    label: 'Zimmerli Of Switzerland',
  },
  {
    key: 'Zen Garden',
    label: 'Zen Garden',
  },
  {
    key: 'Zenith',
    label: 'Zenith',
  },
  {
    key: 'Zune Poar',
    label: 'Zune Poar',
  },
  {
    key: 'Zone Power',
    label: 'Zone Power',
  },
  {
    key: 'Zadig & Voltaire Deluxe',
    label: 'Zadig & Voltaire Deluxe',
  },
  {
    key: 'Zara Mum',
    label: 'Zara Mum',
  },
  {
    key: 'Ziggy.Denim',
    label: 'Ziggy.Denim',
  },
  {
    key: 'Zoteno',
    label: 'Zoteno',
  },
  {
    key: 'Zhong Fu',
    label: 'Zhong Fu',
  },
  {
    key: 'Zutano',
    label: 'Zutano',
  },
  {
    key: 'Z Zegna',
    label: 'Z Zegna',
  },
  {
    key: 'Zimmermann',
    label: 'Zimmermann',
  },
  {
    key: 'Zamira',
    label: 'Zamira',
  },
  {
    key: 'Zaegoo',
    label: 'Zaegoo',
  },
  {
    key: 'Zonda Nellis',
    label: 'Zonda Nellis',
  },
  {
    key: 'Zus Van Sil',
    label: 'Zus Van Sil',
  },
  {
    key: 'Zara Home',
    label: 'Zara Home',
  },
  {
    key: 'Zoe Wittner',
    label: 'Zoe Wittner',
  },
  {
    key: 'Zarlena',
    label: 'Zarlena',
  },
  {
    key: 'Zensu',
    label: 'Zensu',
  },
  {
    key: 'Zanieri',
    label: 'Zanieri',
  },
  {
    key: 'Zory',
    label: 'Zory',
  },
  {
    key: 'Ziera',
    label: 'Ziera',
  },
  {
    key: 'Zanone',
    label: 'Zanone',
  },
  {
    key: 'Zip Zap',
    label: 'Zip Zap',
  },
  {
    key: 'Zegna',
    label: 'Zegna',
  },
  {
    key: 'Zac Posen',
    label: 'Zac Posen',
  },
  {
    key: 'Zephyr',
    label: 'Zephyr',
  },
  {
    key: 'Zuzi-Zuzi',
    label: 'Zuzi-Zuzi',
  },
  {
    key: 'Zco',
    label: 'Zco',
  },
  {
    key: 'Zinco',
    label: 'Zinco',
  },
  {
    key: 'Zip Code',
    label: 'Zip Code',
  },
  {
    key: 'Zapara',
    label: 'Zapara',
  },
  {
    key: 'Zero Restriction',
    label: 'Zero Restriction',
  },
  {
    key: 'Zion',
    label: 'Zion',
  },
  {
    key: 'Zigi Soho',
    label: 'Zigi Soho',
  },
  {
    key: 'Za Girls',
    label: 'Za Girls',
  },
  {
    key: 'Zlimmy',
    label: 'Zlimmy',
  },
  {
    key: 'Zumo',
    label: 'Zumo',
  },
  {
    key: 'Zuccini',
    label: 'Zuccini',
  },
];

export default options;
