import { FormattedMessage, injectIntl, intlShape } from '../util/reactIntl';

const newOptions = [
  {
    key: 'scarves',
    label: <FormattedMessage id="productSubcategory.scarves" />,
    keyCategory: 'baby',
    productCategory: 'accessories',
  },
  {
    key: 'gloves',
    label: <FormattedMessage id="productSubcategory.gloves" />,
    keyCategory: 'baby',
    productCategory: 'accessories',
  },
  {
    key: 'book',
    label: <FormattedMessage id="productSubcategory.book" />,
    keyCategory: 'kids',
    productCategory: 'toysGamesBooks',
  },
  {
    key: 'puzzle',
    label: <FormattedMessage id="productSubcategory.puzzle" />,
    keyCategory: 'kids',
    productCategory: 'toysGamesBooks',
  },
  {
    key: 'boardGame',
    label: <FormattedMessage id="productSubcategory.boardGame" />,
    keyCategory: 'kids',
    productCategory: 'toysGamesBooks',
  },
  {
    key: 'woodenToy',
    label: <FormattedMessage id="productSubcategory.woodenToy" />,
    keyCategory: 'kids',
    productCategory: 'toysGamesBooks',
  },
  {
    key: 'plushToy',
    label: <FormattedMessage id="productSubcategory.plushtoy" />,
    keyCategory: 'kids',
    productCategory: 'toysGamesBooks',
  },
  {
    key: 'figure',
    label: <FormattedMessage id="productSubcategory.figure" />,
    keyCategory: 'kids',
    productCategory: 'toysGamesBooks',
  },
  {
    key: 'constructorLego',
    label: <FormattedMessage id="productSubcategory.constructorAndLego" />,
    keyCategory: 'kids',
    productCategory: 'toysGamesBooks',
  },
  {
    key: 'creative',
    label: <FormattedMessage id="productSubcategory.creative" />,
    keyCategory: 'kids',
    productCategory: 'toysGamesBooks',
  },
  {
    key: 'educational',
    label: <FormattedMessage id="productSubcategory.educational" />,
    keyCategory: 'kids',
    productCategory: 'toysGamesBooks',
  },
  {
    key: 'craftArtsy',
    label: <FormattedMessage id="productSubcategory.craftAndArtsy" />,
    keyCategory: 'kids',
    productCategory: 'toysGamesBooks',
  },
  {
    key: 'musicToy',
    label: <FormattedMessage id="productSubcategory.musicToy" />,
    keyCategory: 'kids',
    productCategory: 'toysGamesBooks',
  },
  {
    key: 'other',
    label: <FormattedMessage id="productSubcategory.other" />,
    keyCategory: 'kids',
    productCategory: 'toysGamesBooks',
  },
  {
    key: 'book',
    label: <FormattedMessage id="productSubcategory.book" />,
    keyCategory: 'baby',
    productCategory: 'toysGamesBooks',
  },
  {
    key: 'puzzle',
    label: <FormattedMessage id="productSubcategory.puzzle" />,
    keyCategory: 'baby',
    productCategory: 'toysGamesBooks',
  },
  {
    key: 'boardGame',
    label: <FormattedMessage id="productSubcategory.boardGame" />,
    keyCategory: 'baby',
    productCategory: 'toysGamesBooks',
  },
  {
    key: 'woodenToy',
    label: <FormattedMessage id="productSubcategory.woodenToy" />,
    keyCategory: 'baby',
    productCategory: 'toysGamesBooks',
  },
  {
    key: 'plushToy',
    label: <FormattedMessage id="productSubcategory.plushtoy" />,
    keyCategory: 'baby',
    productCategory: 'toysGamesBooks',
  },
  {
    key: 'figure',
    label: <FormattedMessage id="productSubcategory.figure" />,
    keyCategory: 'baby',
    productCategory: 'toysGamesBooks',
  },
  {
    key: 'constructorLego',
    label: <FormattedMessage id="productSubcategory.constructorAndLego" />,
    keyCategory: 'baby',
    productCategory: 'toysGamesBooks',
  },
  {
    key: 'creative',
    label: <FormattedMessage id="productSubcategory.creative" />,
    keyCategory: 'baby',
    productCategory: 'toysGamesBooks',
  },
  {
    key: 'educational',
    label: <FormattedMessage id="productSubcategory.educational" />,
    keyCategory: 'baby',
    productCategory: 'toysGamesBooks',
  },
  {
    key: 'craftArtsy',
    label: <FormattedMessage id="productSubcategory.craftAndArtsy" />,
    keyCategory: 'baby',
    productCategory: 'toysGamesBooks',
  },
  {
    key: 'musicToy',
    label: <FormattedMessage id="productSubcategory.musicToy" />,
    keyCategory: 'baby',
    productCategory: 'toysGamesBooks',
  },
  {
    key: 'other',
    label: <FormattedMessage id="productSubcategory.other" />,
    keyCategory: 'baby',
    productCategory: 'toysGamesBooks',
  },
  {
    key: 'carsMotocycles',
    label: <FormattedMessage id="productSubcategory.carsMotocycles" />,
    keyCategory: 'kids',
    productCategory: 'sports',
  },
  {
    key: 'ridingPushing',
    label: <FormattedMessage id="productSubcategory.forridingAndPushing" />,
    keyCategory: 'kids',
    productCategory: 'sports',
  },
  {
    key: 'wheel',
    label: <FormattedMessage id="productSubcategory.wheel" />,
    keyCategory: 'kids',
    productCategory: 'sports',
  },
  {
    key: 'rollerbladesSkates',
    label: <FormattedMessage id="productSubcategory.rollerbladesSkates" />,
    keyCategory: 'kids',
    productCategory: 'sports',
  },
  {
    key: 'tricycleScooter',
    label: <FormattedMessage id="productSubcategory.tricycleScooter" />,
    keyCategory: 'kids',
    productCategory: 'sports',
  },
  {
    key: 'skateboard',
    label: <FormattedMessage id="productSubcategory.skateboard" />,
    keyCategory: 'kids',
    productCategory: 'sports',
  },
  {
    key: 'trampoline',
    label: <FormattedMessage id="productSubcategory.trampoline" />,
    keyCategory: 'kids',
    productCategory: 'sports',
  },
  {
    key: 'helmet',
    label: <FormattedMessage id="productSubcategory.helmet" />,
    keyCategory: 'kids',
    productCategory: 'sports',
  },
  {
    key: 'others',
    label: <FormattedMessage id="productSubcategory.others" />,
    keyCategory: 'kids',
    productCategory: 'sports',
  },
  {
    key: 'carsMotocycles',
    label: <FormattedMessage id="productSubcategory.carsMotocycles" />,
    keyCategory: 'baby',
    productCategory: 'sports',
  },
  {
    key: 'ridingPushing',
    label: <FormattedMessage id="productSubcategory.forridingAndPushing" />,
    keyCategory: 'baby',
    productCategory: 'sports',
  },
  {
    key: 'wheel',
    label: <FormattedMessage id="productSubcategory.wheel" />,
    keyCategory: 'baby',
    productCategory: 'sports',
  },
  {
    key: 'rollerbladesSkates',
    label: <FormattedMessage id="productSubcategory.rollerbladesSkates" />,
    keyCategory: 'baby',
    productCategory: 'sports',
  },
  {
    key: 'tricycleScooter',
    label: <FormattedMessage id="productSubcategory.tricycleScooter" />,
    keyCategory: 'baby',
    productCategory: 'sports',
  },
  {
    key: 'skateboard',
    label: <FormattedMessage id="productSubcategory.skateboard" />,
    keyCategory: 'baby',
    productCategory: 'sports',
  },
  {
    key: 'trampoline',
    label: <FormattedMessage id="productSubcategory.trampoline" />,
    keyCategory: 'baby',
    productCategory: 'sports',
  },
  {
    key: 'helmet',
    label: <FormattedMessage id="productSubcategory.helmet" />,
    keyCategory: 'baby',
    productCategory: 'sports',
  },
  {
    key: 'others',
    label: <FormattedMessage id="productSubcategory.others" />,
    keyCategory: 'baby',
    productCategory: 'sports',
  },
  {
    key: 'set',
    label: <FormattedMessage id="productSubcategory.set" />,
    keyCategory: 'baby',
    productCategory: 'clothing',
  },
  {
    key: 'bodies',
    label: <FormattedMessage id="productSubcategory.bodies" />,
    keyCategory: 'baby',
    productCategory: 'clothing',
  },
  {
    key: 'tshirts',
    label: <FormattedMessage id="productSubcategory.t-shirts" />,
    keyCategory: 'baby',
    productCategory: 'clothing',
  },
  {
    key: 'blouse',
    label: <FormattedMessage id="productSubcategory.blouse" />,
    keyCategory: 'baby',
    productCategory: 'clothing',
  },
  {
    key: 'dresses',
    label: <FormattedMessage id="productSubcategory.dresses" />,
    keyCategory: 'baby',
    productCategory: 'clothing',
  },
  {
    key: 'jumpsuits',
    label: <FormattedMessage id="productSubcategory.jumpsuits" />,
    keyCategory: 'baby',
    productCategory: 'clothing',
  },
  {
    key: 'romper',
    label: <FormattedMessage id="productSubcategory.romper" />,
    keyCategory: 'baby',
    productCategory: 'clothing',
  },
  {
    key: 'shorts',
    label: <FormattedMessage id="productSubcategory.shorts" />,
    keyCategory: 'baby',
    productCategory: 'clothing',
  },
  {
    key: 'pants',
    label: <FormattedMessage id="productSubcategory.pants" />,
    keyCategory: 'baby',
    productCategory: 'clothing',
  },
  {
    key: 'astronauts',
    label: <FormattedMessage id="productSubcategory.astronauts" />,
    keyCategory: 'baby',
    productCategory: 'clothing',
  },
  {
    key: 'snowsuits',
    label: <FormattedMessage id="productSubcategory.snowsuits" />,
    keyCategory: 'baby',
    productCategory: 'clothing',
  },
  {
    key: 'knitwear',
    label: <FormattedMessage id="productSubcategory.knitwear" />,
    keyCategory: 'baby',
    productCategory: 'clothing',
  },
  {
    key: 'pulloversSweatshirts',
    label: <FormattedMessage id="productSubcategory.pulloverssweatshirts" />,
    keyCategory: 'baby',
    productCategory: 'clothing',
  },
  {
    key: 'jackets',
    label: <FormattedMessage id="productSubcategory.jackets" />,
    keyCategory: 'baby',
    productCategory: 'clothing',
  },
  {
    key: 'softShoes',
    label: <FormattedMessage id="productSubcategory.softShoes" />,
    keyCategory: 'baby',
    productCategory: 'shoes',
  },
  {
    key: 'shoes',
    label: <FormattedMessage id="productSubcategory.shoes" />,
    keyCategory: 'baby',
    productCategory: 'shoes',
  },
  {
    key: 'sandals',
    label: <FormattedMessage id="productSubcategory.sandals" />,
    keyCategory: 'baby',
    productCategory: 'shoes',
  },
  {
    key: 'boots',
    label: <FormattedMessage id="productSubcategory.boots" />,
    keyCategory: 'baby',
    productCategory: 'shoes',
  },
  {
    key: 'rubberBoots',
    label: <FormattedMessage id="productSubcategory.rubberboots" />,
    keyCategory: 'baby',
    productCategory: 'shoes',
  },
  {
    key: 'loafers',
    label: <FormattedMessage id="productSubcategory.loafers" />,
    keyCategory: 'baby',
    productCategory: 'shoes',
  },
  {
    key: 'newborn',
    label: <FormattedMessage id="productSubcategory.fornewborn" />,
    keyCategory: 'baby',
    productCategory: 'strollers',
  },
  {
    key: 'combinedStrollers',
    label: <FormattedMessage id="productSubcategory.combinedStrollers" />,
    keyCategory: 'baby',
    productCategory: 'strollers',
  },
  {
    key: 'summerStrollers',
    label: <FormattedMessage id="productSubcategory.summerStrollers" />,
    keyCategory: 'baby',
    productCategory: 'strollers',
  },
  {
    key: 'forTwins',
    label: <FormattedMessage id="productSubcategory.forTwins" />,
    keyCategory: 'baby',
    productCategory: 'strollers',
  },
  {
    key: 'forTriplets',
    label: <FormattedMessage id="productSubcategory.forTriplets" />,
    keyCategory: 'baby',
    productCategory: 'strollers',
  },
  {
    key: 'strollerMattress',
    label: <FormattedMessage id="productSubcategory.strollerMattress" />,
    keyCategory: 'baby',
    productCategory: 'strollers',
  },
  {
    key: 'strollerAccessories',
    label: <FormattedMessage id="productSubcategory.strollerAccessories" />,
    keyCategory: 'baby',
    productCategory: 'strollers',
  },
  {
    key: 'shades',
    label: <FormattedMessage id="productSubcategory.shades" />,
    keyCategory: 'baby',
    productCategory: 'strollers',
  },
  {
    key: 'umbrellas',
    label: <FormattedMessage id="productSubcategory.umbrellas" />,
    keyCategory: 'baby',
    productCategory: 'strollers',
  },
  {
    key: 'Other Accessories for Strollers',
    label: <FormattedMessage id="productSubcategory.otherAccessoriesForStrollers" />,
    keyCategory: 'baby',
    productCategory: 'strollers',
  },
  {
    key: 'group013',
    label: <FormattedMessage id="productSubcategory.0-13kgGroup0" />,
    keyCategory: 'baby',
    productCategory: 'carSeats',
  },
  {
    key: 'group018',
    label: <FormattedMessage id="productSubcategory.0-18kgGroup01" />,
    keyCategory: 'baby',
    productCategory: 'carSeats',
  },
  {
    key: 'group015',
    label: <FormattedMessage id="productSubcategory.0-15kgGroup012" />,
    keyCategory: 'baby',
    productCategory: 'carSeats',
  },
  {
    key: 'group918',
    label: <FormattedMessage id="productSubcategory.9-18Group1" />,
    keyCategory: 'baby',
    productCategory: 'carSeats',
  },
  {
    key: 'group925',
    label: <FormattedMessage id="productSubcategory.9-25kgGroup12" />,
    keyCategory: 'baby',
    productCategory: 'carSeats',
  },
  {
    key: 'group936',
    label: <FormattedMessage id="productSubcategory.9-36Group123" />,
    keyCategory: 'baby',
    productCategory: 'carSeats',
  },
  {
    key: 'group1536',
    label: <FormattedMessage id="productSubcategory.15-36kgGroup23" />,
    keyCategory: 'baby',
    productCategory: 'carSeats',
  },
  {
    key: 'bases',
    label: <FormattedMessage id="productSubcategory.bases" />,
    keyCategory: 'baby',
    productCategory: 'carSeats',
  },
  {
    key: 'accessoriesForCarSeats',
    label: <FormattedMessage id="productSubcategory.accessoriesforCarSeats" />,
    keyCategory: 'baby',
    productCategory: 'carSeats',
  },
  {
    key: 'kangaroos',
    label: <FormattedMessage id="productSubcategory.kangaroos" />,
    keyCategory: 'baby',
    productCategory: 'kangaroosSlings',
  },
  {
    key: 'slings',
    label: <FormattedMessage id="productSubcategory.slings" />,
    keyCategory: 'baby',
    productCategory: 'kangaroosSlings',
  },
  {
    key: 'woodenToys',
    label: <FormattedMessage id="productSubcategory.woodenToys" />,
    keyCategory: 'baby',
    productCategory: 'toysGames',
  },
  {
    key: 'textileToys',
    label: <FormattedMessage id="productSubcategory.textileToys" />,
    keyCategory: 'baby',
    productCategory: 'toysGames',
  },
  {
    key: 'plasticToys',
    label: <FormattedMessage id="productSubcategory.plasticToys" />,
    keyCategory: 'baby',
    productCategory: 'toysGames',
  },
  {
    key: 'plushToys',
    label: <FormattedMessage id="productSubcategory.plushToys" />,
    keyCategory: 'baby',
    productCategory: 'toysGames',
  },
  {
    key: 'interactiveToys',
    label: <FormattedMessage id="productSubcategory.interactivetoys" />,
    keyCategory: 'baby',
    productCategory: 'toysGames',
  },
  {
    key: 'mobiles',
    label: <FormattedMessage id="productSubcategory.mobiles" />,
    keyCategory: 'baby',
    productCategory: 'toysGames',
  },
  {
    key: 'playmatshangingToys',
    label: <FormattedMessage id="productSubcategory.playmatsAndHangingToys" />,
    keyCategory: 'baby',
    productCategory: 'toysGames',
  },
  {
    key: 'toysForWalking',
    label: <FormattedMessage id="productSubcategory.toysforWalking" />,
    keyCategory: 'baby',
    productCategory: 'toysGames',
  },
  {
    key: 'educationalToys',
    label: <FormattedMessage id="productSubcategory.educationaltoys" />,
    keyCategory: 'baby',
    productCategory: 'toysGames',
  },
  {
    key: 'fineMotorSkills',
    label: <FormattedMessage id="productSubcategory.finemotorSkills" />,
    keyCategory: 'baby',
    productCategory: 'toysGames',
  },
  {
    key: 'musicToys',
    label: <FormattedMessage id="productSubcategory.musicToys" />,
    keyCategory: 'baby',
    productCategory: 'toysGames',
  },
  {
    key: 'beachToys',
    label: <FormattedMessage id="productSubcategory.beachToys" />,
    keyCategory: 'baby',
    productCategory: 'toysGames',
  },
  {
    key: 'craftPainting',
    label: <FormattedMessage id="productSubcategory.craftAndPainting" />,
    keyCategory: 'baby',
    productCategory: 'toysGames',
  },
  {
    key: 'hardBooks',
    label: <FormattedMessage id="productSubcategory.hardbooks" />,
    keyCategory: 'baby',
    productCategory: 'toysGames',
  },
  {
    key: 'paperbackBooks',
    label: <FormattedMessage id="productSubcategory.paperbackbooks" />,
    keyCategory: 'baby',
    productCategory: 'toysGames',
  },
  {
    key: 'forSleeping',
    label: <FormattedMessage id="productSubcategory.forsleeping" />,
    keyCategory: 'baby',
    productCategory: 'furniture',
  },
  {
    key: 'forEating',
    label: <FormattedMessage id="productSubcategory.foreating" />,
    keyCategory: 'baby',
    productCategory: 'furniture',
  },
  {
    key: 'forOrganisation',
    label: <FormattedMessage id="productSubcategory.fororganization" />,
    keyCategory: 'baby',
    productCategory: 'furniture',
  },
  {
    key: 'bathtubsAndBathStands',
    label: <FormattedMessage id="productSubcategory.bathtubsAndBathStands" />,
    keyCategory: 'baby',
    productCategory: 'furniture',
  },
  {
    key: 'decor',
    label: <FormattedMessage id="productSubcategory.decor" />,
    keyCategory: 'baby',
    productCategory: 'furniture',
  },
  {
    key: 'furniture',
    label: <FormattedMessage id="productSubcategory.furniture" />,
    keyCategory: 'baby',
    productCategory: 'furniture',
  },
  {
    key: 'sterilizers',
    label: <FormattedMessage id="productSubcategory.sterilizers" />,
    keyCategory: 'baby',
    productCategory: 'electricalDevices',
  },
  {
    key: 'heaters',
    label: <FormattedMessage id="productSubcategory.heaters" />,
    keyCategory: 'baby',
    productCategory: 'electricalDevices',
  },
  {
    key: 'breastpump',
    label: <FormattedMessage id="productSubcategory.breastpump" />,
    keyCategory: 'baby',
    productCategory: 'electricalDevices',
  },
  {
    key: 'others',
    label: <FormattedMessage id="productSubcategory.others" />,
    keyCategory: 'baby',
    productCategory: 'electricalDevices',
  },
  {
    key: 'sleepingBags',
    label: <FormattedMessage id="productSubcategory.sleepingBags" />,
    keyCategory: 'baby',
    productCategory: 'bags',
  },
  {
    key: 'winterBags',
    label: <FormattedMessage id="productSubcategory.winterbags" />,
    keyCategory: 'baby',
    productCategory: 'bags',
  },
  {
    key: 'backpacksForSchool',
    label: <FormattedMessage id="productSubcategory.backpacksForSchool" />,
    keyCategory: 'baby',
    productCategory: 'accessoriesBags',
  },
  {
    key: 'trolleyBags',
    label: <FormattedMessage id="productSubcategory.trolleybags" />,
    keyCategory: 'baby',
    productCategory: 'accessoriesBags',
  },
  {
    key: 'others',
    label: <FormattedMessage id="productSubcategory.others" />,
    keyCategory: 'baby',
    productCategory: 'accessoriesBags',
  },
  {
    key: 'vases',
    label: <FormattedMessage id="productSubcategory.vases" />,
    keyCategory: 'home',
    productCategory: 'decor',
  },
  {
    key: 'carpets',
    label: <FormattedMessage id="productSubcategory.carpets" />,
    keyCategory: 'home',
    productCategory: 'decor',
  },
  {
    key: 'mirrors',
    label: <FormattedMessage id="productSubcategory.mirrors" />,
    keyCategory: 'home',
    productCategory: 'decor',
  },
  {
    key: 'paintings',
    label: <FormattedMessage id="productSubcategory.paintings" />,
    keyCategory: 'home',
    productCategory: 'decor',
  },
  {
    key: 'posters',
    label: <FormattedMessage id="productSubcategory.posters" />,
    keyCategory: 'home',
    productCategory: 'decor',
  },
  {
    key: 'plants',
    label: <FormattedMessage id="productSubcategory.plants" />,
    keyCategory: 'home',
    productCategory: 'decor',
  },
  {
    key: 'statues',
    label: <FormattedMessage id="productSubcategory.statues" />,
    keyCategory: 'home',
    productCategory: 'decor',
  },
  {
    key: 'lamps',
    label: <FormattedMessage id="productSubcategory.lamps" />,
    keyCategory: 'home',
    productCategory: 'decor',
  },
  {
    key: 'tablewear',
    label: <FormattedMessage id="productSubcategory.tablewear" />,
    keyCategory: 'home',
    productCategory: 'decor',
  },
  {
    key: 'candles',
    label: <FormattedMessage id="productSubcategory.candles" />,
    keyCategory: 'home',
    productCategory: 'decor',
  },
  {
    key: 'tablecovers',
    label: <FormattedMessage id="productSubcategory.tablecovers" />,
    keyCategory: 'home',
    productCategory: 'decor',
  },
  {
    key: 'fragrancesForTheHome',
    label: <FormattedMessage id="productSubcategory.fragrancesforthehome" />,
    keyCategory: 'home',
    productCategory: 'decor',
  },
  {
    key: 'diffusers',
    label: <FormattedMessage id="productSubcategory.diffusers" />,
    keyCategory: 'home',
    productCategory: 'decor',
  },
  {
    key: 'humidifiers',
    label: <FormattedMessage id="productSubcategory.humidifiers" />,
    keyCategory: 'home',
    productCategory: 'decor',
  },
  {
    key: 'others',
    label: <FormattedMessage id="productSubcategory.others" />,
    keyCategory: 'home',
    productCategory: 'decor',
  },
  {
    key: 'tables',
    label: <FormattedMessage id="productSubcategory.tables" />,
    keyCategory: 'home',
    productCategory: 'furnture',
  },
  {
    key: 'chairs',
    label: <FormattedMessage id="productSubcategory.chairs" />,
    keyCategory: 'home',
    productCategory: 'furnture',
  },
  {
    key: 'sofas',
    label: <FormattedMessage id="productSubcategory.sofas" />,
    keyCategory: 'home',
    productCategory: 'furnture',
  },
  {
    key: 'armchairs',
    label: <FormattedMessage id="productSubcategory.armchairs" />,
    keyCategory: 'home',
    productCategory: 'furnture',
  },
  {
    key: 'wardrobes',
    label: <FormattedMessage id="productSubcategory.wardrobes" />,
    keyCategory: 'home',
    productCategory: 'furnture',
  },
  {
    key: 'organising',
    label: <FormattedMessage id="productSubcategory.organising" />,
    keyCategory: 'home',
    productCategory: 'furnture',
  },
  {
    key: 'beds',
    label: <FormattedMessage id="productSubcategory.beds" />,
    keyCategory: 'home',
    productCategory: 'furnture',
  },
  {
    key: 'gardenSet',
    label: <FormattedMessage id="productSubcategory.gardenset" />,
    keyCategory: 'home',
    productCategory: 'furnture',
  },
  {
    key: 'swingsRelaxFurniture',
    label: <FormattedMessage id="productSubcategory.swingsAndRelaxFurniture" />,
    keyCategory: 'home',
    productCategory: 'furnture',
  },
  {
    key: 'umbrellas7Tents',
    label: <FormattedMessage id="productSubcategory.umbrellas7Tents" />,
    keyCategory: 'home',
    productCategory: 'furnture',
  },
  {
    key: 'cream',
    label: <FormattedMessage id="productSubcategory.cream" />,
    keyCategory: 'beauty',
    productCategory: 'cosmetics',
  },
  {
    key: 'serum',
    label: <FormattedMessage id="productSubcategory.serum" />,
    keyCategory: 'beauty',
    productCategory: 'cosmetics',
  },
  {
    key: 'mask',
    label: <FormattedMessage id="productSubcategory.mask" />,
    keyCategory: 'beauty',
    productCategory: 'cosmetics',
  },
  {
    key: 'cleaning',
    label: <FormattedMessage id="productSubcategory.cleaning" />,
    keyCategory: 'beauty',
    productCategory: 'cosmetics',
  },
  {
    key: 'micellarWater',
    label: <FormattedMessage id="productSubcategory.micellarwater" />,
    keyCategory: 'beauty',
    productCategory: 'cosmetics',
  },
  {
    key: 'tonic',
    label: <FormattedMessage id="productSubcategory.tonic" />,
    keyCategory: 'beauty',
    productCategory: 'cosmetics',
  },
  {
    key: 'sunscreen',
    label: <FormattedMessage id="productSubcategory.sunscreen" />,
    keyCategory: 'beauty',
    productCategory: 'cosmetics',
  },
  {
    key: 'bronze',
    label: <FormattedMessage id="productSubcategory.bronze" />,
    keyCategory: 'beauty',
    productCategory: 'cosmetics',
  },
  {
    key: 'shampoo',
    label: <FormattedMessage id="productSubcategory.shampoo" />,
    keyCategory: 'beauty',
    productCategory: 'cosmetics',
  },
  {
    key: 'balm',
    label: <FormattedMessage id="productSubcategory.balm" />,
    keyCategory: 'beauty',
    productCategory: 'cosmetics',
  },
  {
    key: 'styling',
    label: <FormattedMessage id="productSubcategory.styling" />,
    keyCategory: 'beauty',
    productCategory: 'cosmetics',
  },
  {
    key: 'eyes',
    label: <FormattedMessage id="productSubcategory.eyes" />,
    keyCategory: 'beauty',
    productCategory: 'makeup',
  },
  {
    key: 'lips',
    label: <FormattedMessage id="productSubcategory.lips" />,
    keyCategory: 'beauty',
    productCategory: 'makeup',
  },
  {
    key: 'face',
    label: <FormattedMessage id="productSubcategory.face" />,
    keyCategory: 'beauty',
    productCategory: 'makeup',
  },
  {
    key: 'guaSha',
    label: <FormattedMessage id="productSubcategory.guasha" />,
    keyCategory: 'beauty',
    productCategory: 'tools',
  },
  {
    key: 'rollers',
    label: <FormattedMessage id="productSubcategory.rollers" />,
    keyCategory: 'beauty',
    productCategory: 'tools',
  },
  {
    key: 'brushes',
    label: <FormattedMessage id="productSubcategory.brushes" />,
    keyCategory: 'beauty',
    productCategory: 'tools',
  },
  {
    key: 'other',
    label: <FormattedMessage id="productSubcategory.other" />,
    keyCategory: 'beauty',
    productCategory: 'tools',
  },
  {
    key: 'straightener',
    label: <FormattedMessage id="productSubcategory.straightener" />,
    keyCategory: 'beauty',
    productCategory: 'electricalDevices',
  },
  {
    key: 'hairDryer',
    label: <FormattedMessage id="productSubcategory.hairdryer" />,
    keyCategory: 'beauty',
    productCategory: 'electricalDevices',
  },
  {
    key: 'curler',
    label: <FormattedMessage id="productSubcategory.curler" />,
    keyCategory: 'beauty',
    productCategory: 'electricalDevices',
  },
  {
    key: 'electricalFaceBrushes',
    label: <FormattedMessage id="productSubcategory.electricalfaceBrushes" />,
    keyCategory: 'beauty',
    productCategory: 'electricalDevices',
  },

  {
    key: 'outerwear',
    label: <FormattedMessage id="productSubcategory.outerwear" />,
    keyCategory: 'woman',
    productCategory: 'clothing',
  },
  {
    key: 'top',
    label: <FormattedMessage id="productSubcategory.top" />,
    keyCategory: 'woman',
    productCategory: 'clothing',
  },
  {
    key: 'bottom',
    label: <FormattedMessage id="productSubcategory.bottom" />,
    keyCategory: 'woman',
    productCategory: 'clothing',
  },
  {
    key: 'dress',
    label: <FormattedMessage id="productSubcategory.dress" />,
    keyCategory: 'woman',
    productCategory: 'clothing',
  },
  {
    key: 'jumpsuit',
    label: <FormattedMessage id="productSubcategory.jumpsuit" />,
    keyCategory: 'woman',
    productCategory: 'clothing',
  },
  {
    key: 'beachwear',
    label: <FormattedMessage id="productSubcategory.beachwear" />,
    keyCategory: 'woman',
    productCategory: 'clothing',
  },
  {
    key: 'skiSnowboard',
    label: <FormattedMessage id="productSubcategory.skiAndSnowboard" />,
    keyCategory: 'woman',
    productCategory: 'clothing',
  },
  {
    key: 'athleisure',
    label: <FormattedMessage id="productSubcategory.athleisure" />,
    keyCategory: 'woman',
    productCategory: 'clothing',
  },
  {
    key: 'set',
    label: <FormattedMessage id="productSubcategory.set" />,
    keyCategory: 'woman',
    productCategory: 'clothing',
  },
  {
    key: 'outerwear',
    label: <FormattedMessage id="productSubcategory.outerwear" />,
    keyCategory: 'man',
    productCategory: 'clothing',
  },
  {
    key: 'top',
    label: <FormattedMessage id="productSubcategory.top" />,
    keyCategory: 'man',
    productCategory: 'clothing',
  },
  {
    key: 'bottom',
    label: <FormattedMessage id="productSubcategory.bottom" />,
    keyCategory: 'man',
    productCategory: 'clothing',
  },
  {
    key: 'skiSnowboard',
    label: <FormattedMessage id="productSubcategory.skiAndSnowboard" />,
    keyCategory: 'man',
    productCategory: 'clothing',
  },
  {
    key: 'athleisure',
    label: <FormattedMessage id="productSubcategory.athleisure" />,
    keyCategory: 'man',
    productCategory: 'clothing',
  },
  {
    key: 'set',
    label: <FormattedMessage id="productSubcategory.set" />,
    keyCategory: 'man',
    productCategory: 'clothing',
  },
  {
    key: 'outerwear',
    label: <FormattedMessage id="productSubcategory.outerwear" />,
    keyCategory: 'kids',
    productCategory: 'clothing',
  },
  {
    key: 'top',
    label: <FormattedMessage id="productSubcategory.top" />,
    keyCategory: 'kids',
    productCategory: 'clothing',
  },
  {
    key: 'bottom',
    label: <FormattedMessage id="productSubcategory.bottom" />,
    keyCategory: 'kids',
    productCategory: 'clothing',
  },
  {
    key: 'skiSnowboard',
    label: <FormattedMessage id="productSubcategory.skiAndSnowboard" />,
    keyCategory: 'kids',
    productCategory: 'clothing',
  },
  {
    key: 'athleisure',
    label: <FormattedMessage id="productSubcategory.athleisure" />,
    keyCategory: 'kids',
    productCategory: 'clothing',
  },
  {
    key: 'set',
    label: <FormattedMessage id="productSubcategory.set" />,
    keyCategory: 'kids',
    productCategory: 'clothing',
  },
  {
    key: 'dress',
    label: <FormattedMessage id="productSubcategory.dress" />,
    keyCategory: 'kids',
    productCategory: 'clothing',
    kidsGender: 'girl',
  },
  {
    key: 'jumpsuit',
    label: <FormattedMessage id="productSubcategory.jumpsuit" />,
    keyCategory: 'kids',
    productCategory: 'clothing',
    kidsGender: 'girl',
  },
  {
    key: 'beachwear',
    label: <FormattedMessage id="productSubcategory.beachwear" />,
    keyCategory: 'kids',
    productCategory: 'clothing',
    kidsGender: 'girl',
  },
  {
    key: 'outerwear',
    label: <FormattedMessage id="productSubcategory.outerwear" />,
    keyCategory: 'baby',
    productCategory: 'clothing',
  },
  {
    key: 'top',
    label: <FormattedMessage id="productSubcategory.top" />,
    keyCategory: 'baby',
    productCategory: 'clothing',
  },
  {
    key: 'bottom',
    label: <FormattedMessage id="productSubcategory.bottom" />,
    keyCategory: 'baby',
    productCategory: 'clothing',
  },
  {
    key: 'skiSnowboard',
    label: <FormattedMessage id="productSubcategory.skiAndSnowboard" />,
    keyCategory: 'baby',
    productCategory: 'clothing',
  },
  {
    key: 'athleisure',
    label: <FormattedMessage id="productSubcategory.athleisure" />,
    keyCategory: 'baby',
    productCategory: 'clothing',
  },
  {
    key: 'set',
    label: <FormattedMessage id="productSubcategory.set" />,
    keyCategory: 'baby',
    productCategory: 'clothing',
  },
  {
    key: 'sandals',
    label: <FormattedMessage id="productSubcategory.sandals" />,
    keyCategory: 'woman',
    productCategory: 'shoes',
  },
  {
    key: 'highHeels',
    label: <FormattedMessage id="productSubcategory.highHeels" />,
    keyCategory: 'woman',
    productCategory: 'shoes',
  },
  {
    key: 'espadrilles',
    label: <FormattedMessage id="productSubcategory.espadrilles" />,
    keyCategory: 'woman',
    productCategory: 'shoes',
  },
  {
    key: 'mulesClogs',
    label: <FormattedMessage id="productSubcategory.mulesAndClogs" />,
    keyCategory: 'woman',
    productCategory: 'shoes',
  },
  {
    key: 'sneakers',
    label: <FormattedMessage id="productSubcategory.sneakers" />,
    keyCategory: 'woman',
    productCategory: 'shoes',
  },
  {
    key: 'ballerinas',
    label: <FormattedMessage id="productSubcategory.ballerinas" />,
    keyCategory: 'woman',
    productCategory: 'shoes',
  },
  {
    key: 'laceUps',
    label: <FormattedMessage id="productSubcategory.laseUps" />,
    keyCategory: 'woman',
    productCategory: 'shoes',
  },
  {
    key: 'ankleBoots',
    label: <FormattedMessage id="productSubcategory.ankleBoots" />,
    keyCategory: 'woman',
    productCategory: 'shoes',
  },
  {
    key: 'boots',
    label: <FormattedMessage id="productSubcategory.boots" />,
    keyCategory: 'woman',
    productCategory: 'shoes',
  },
  {
    key: 'sandals',
    label: <FormattedMessage id="productSubcategory.sandals" />,
    keyCategory: 'man',
    productCategory: 'shoes',
  },
  {
    key: 'espadrilles',
    label: <FormattedMessage id="productSubcategory.espadrilles" />,
    keyCategory: 'man',
    productCategory: 'shoes',
  },
  {
    key: 'mulesClogs',
    label: <FormattedMessage id="productSubcategory.mulesAndClogs" />,
    keyCategory: 'man',
    productCategory: 'shoes',
  },
  {
    key: 'sneakers',
    label: <FormattedMessage id="productSubcategory.sneakers" />,
    keyCategory: 'man',
    productCategory: 'shoes',
  },
  {
    key: 'laceUps',
    label: <FormattedMessage id="productSubcategory.laseUps" />,
    keyCategory: 'man',
    productCategory: 'shoes',
  },
  {
    key: 'ankleBoots',
    label: <FormattedMessage id="productSubcategory.ankleBoots" />,
    keyCategory: 'man',
    productCategory: 'shoes',
  },
  {
    key: 'boots',
    label: <FormattedMessage id="productSubcategory.boots" />,
    keyCategory: 'man',
    productCategory: 'shoes',
  },
  {
    key: 'sandals',
    label: <FormattedMessage id="productSubcategory.sandals" />,
    keyCategory: 'kids',
    productCategory: 'shoes',
  },
  {
    key: 'espadrilles',
    label: <FormattedMessage id="productSubcategory.espadrilles" />,
    keyCategory: 'kids',
    productCategory: 'shoes',
  },
  {
    key: 'mulesClogs',
    label: <FormattedMessage id="productSubcategory.mulesAndClogs" />,
    keyCategory: 'kids',
    productCategory: 'shoes',
  },
  {
    key: 'sneakers',
    label: <FormattedMessage id="productSubcategory.sneakers" />,
    keyCategory: 'kids',
    productCategory: 'shoes',
  },
  {
    key: 'ballerinas',
    label: <FormattedMessage id="productSubcategory.ballerinas" />,
    keyCategory: 'kids',
    productCategory: 'shoes',
  },
  {
    key: 'laceUps',
    label: <FormattedMessage id="productSubcategory.laseUps" />,
    keyCategory: 'kids',
    productCategory: 'shoes',
  },
  {
    key: 'ankleBoots',
    label: <FormattedMessage id="productSubcategory.ankleBoots" />,
    keyCategory: 'kids',
    productCategory: 'shoes',
  },
  {
    key: 'boots',
    label: <FormattedMessage id="productSubcategory.boots" />,
    keyCategory: 'kids',
    productCategory: 'shoes',
  },
  {
    key: 'sandals',
    label: <FormattedMessage id="productSubcategory.sandals" />,
    keyCategory: 'baby',
    productCategory: 'shoes',
  },
  {
    key: 'highHeels',
    label: <FormattedMessage id="productSubcategory.highHeels" />,
    keyCategory: 'baby',
    productCategory: 'shoes',
  },
  {
    key: 'espadrilles',
    label: <FormattedMessage id="productSubcategory.espadrilles" />,
    keyCategory: 'baby',
    productCategory: 'shoes',
  },
  {
    key: 'mulesClogs',
    label: <FormattedMessage id="productSubcategory.mulesAndClogs" />,
    keyCategory: 'baby',
    productCategory: 'shoes',
  },
  {
    key: 'sneakers',
    label: <FormattedMessage id="productSubcategory.sneakers" />,
    keyCategory: 'baby',
    productCategory: 'shoes',
  },
  {
    key: 'ballerinas',
    label: <FormattedMessage id="productSubcategory.ballerinas" />,
    keyCategory: 'baby',
    productCategory: 'shoes',
  },
  {
    key: 'laceUps',
    label: <FormattedMessage id="productSubcategory.laseUps" />,
    keyCategory: 'baby',
    productCategory: 'shoes',
  },
  {
    key: 'ankleBoots',
    label: <FormattedMessage id="productSubcategory.ankleBoots" />,
    keyCategory: 'baby',
    productCategory: 'shoes',
  },
  {
    key: 'boots',
    label: <FormattedMessage id="productSubcategory.boots" />,
    keyCategory: 'baby',
    productCategory: 'shoes',
  },
  {
    key: 'bags',
    label: <FormattedMessage id="productSubcategory.bags" />,
    keyCategory: 'woman',
    productCategory: 'accessories',
  },
  {
    key: 'jewellery',
    label: <FormattedMessage id="productSubcategory.jewellery" />,
    keyCategory: 'woman',
    productCategory: 'accessories',
  },
  {
    key: 'sunglassesGlasses',
    label: <FormattedMessage id="productSubcategory.sunglassesAndGlasses" />,
    keyCategory: 'woman',
    productCategory: 'accessories',
  },
  {
    key: 'belts',
    label: <FormattedMessage id="productSubcategory.belts" />,
    keyCategory: 'woman',
    productCategory: 'accessories',
  },
  {
    key: 'wallets',
    label: <FormattedMessage id="productSubcategory.wallets" />,
    keyCategory: 'woman',
    productCategory: 'accessories',
  },
  {
    key: 'hats',
    label: <FormattedMessage id="productSubcategory.hats" />,
    keyCategory: 'woman',
    productCategory: 'accessories',
  },
  {
    key: 'scarves',
    label: <FormattedMessage id="productSubcategory.scarves" />,
    keyCategory: 'woman',
    productCategory: 'accessories',
  },
  {
    key: 'gloves',
    label: <FormattedMessage id="productSubcategory.gloves" />,
    keyCategory: 'woman',
    productCategory: 'accessories',
  },
  {
    key: 'bags',
    label: <FormattedMessage id="productSubcategory.bags" />,
    keyCategory: 'man',
    productCategory: 'accessories',
  },
  {
    key: 'sunglassesGlasses',
    label: <FormattedMessage id="productSubcategory.sunglassesAndGlasses" />,
    keyCategory: 'man',
    productCategory: 'accessories',
  },
  {
    key: 'belts',
    label: <FormattedMessage id="productSubcategory.belts" />,
    keyCategory: 'man',
    productCategory: 'accessories',
  },
  {
    key: 'wallets',
    label: <FormattedMessage id="productSubcategory.wallets" />,
    keyCategory: 'man',
    productCategory: 'accessories',
  },
  {
    key: 'hats',
    label: <FormattedMessage id="productSubcategory.hats" />,
    keyCategory: 'man',
    productCategory: 'accessories',
  },
  {
    key: 'scarves',
    label: <FormattedMessage id="productSubcategory.scarves" />,
    keyCategory: 'man',
    productCategory: 'accessories',
  },
  {
    key: 'gloves',
    label: <FormattedMessage id="productSubcategory.gloves" />,
    keyCategory: 'man',
    productCategory: 'accessories',
  },
  {
    key: 'bags',
    label: <FormattedMessage id="productSubcategory.bags" />,
    keyCategory: 'kids',
    productCategory: 'accessories',
  },
  {
    key: 'jewellery',
    label: <FormattedMessage id="productSubcategory.jewellery" />,
    keyCategory: 'kids',
    productCategory: 'accessories',
    kidsGender: 'girl',
  },
  {
    key: 'sunglassesGlasses',
    label: <FormattedMessage id="productSubcategory.sunglassesAndGlasses" />,
    keyCategory: 'kids',
    productCategory: 'accessories',
  },
  {
    key: 'belts',
    label: <FormattedMessage id="productSubcategory.belts" />,
    keyCategory: 'kids',
    productCategory: 'accessories',
  },
  {
    key: 'wallets',
    label: <FormattedMessage id="productSubcategory.wallets" />,
    keyCategory: 'kids',
    productCategory: 'accessories',
  },
  {
    key: 'hats',
    label: <FormattedMessage id="productSubcategory.hats" />,
    keyCategory: 'kids',
    productCategory: 'accessories',
  },
  {
    key: 'scarves',
    label: <FormattedMessage id="productSubcategory.scarves" />,
    keyCategory: 'kids',
    productCategory: 'accessories',
  },
  {
    key: 'gloves',
    label: <FormattedMessage id="productSubcategory.gloves" />,
    keyCategory: 'kids',
    productCategory: 'accessories',
  },
  {
    key: 'bags',
    label: <FormattedMessage id="productSubcategory.bags" />,
    keyCategory: 'baby',
    productCategory: 'accessories',
  },
  {
    key: 'jewellery',
    label: <FormattedMessage id="productSubcategory.jewellery" />,
    keyCategory: 'baby',
    productCategory: 'accessories',
  },
  {
    key: 'sunglassesGlasses',
    label: <FormattedMessage id="productSubcategory.sunglassesAndGlasses" />,
    keyCategory: 'baby',
    productCategory: 'accessories',
  },
  {
    key: 'belts',
    label: <FormattedMessage id="productSubcategory.belts" />,
    keyCategory: 'baby',
    productCategory: 'accessories',
  },
  {
    key: 'wallets',
    label: <FormattedMessage id="productSubcategory.wallets" />,
    keyCategory: 'baby',
    productCategory: 'accessories',
  },
  {
    key: 'hats',
    label: <FormattedMessage id="productSubcategory.hats" />,
    keyCategory: 'baby',
    productCategory: 'accessories',
  },
];

export default newOptions;
