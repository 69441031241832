import React, { useState, useEffect } from 'react';
import CardSection from './CardSection';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import Button from '../../../components/Button/Button';
import { post } from '../../../util/api';
import CheckIcon from '@mui/icons-material/Check';
import css from './BumpListingForm.module.css';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { types as sdkTypes } from '../../../util/sdkLoader';
import { FormattedMessage } from 'react-intl';
const { UUID } = sdkTypes;

Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};

const sharetribeSdk = require('sharetribe-flex-sdk');

const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

function BumpListingForm(props) {
  const { listing, success, setSuccess, currentUser } = props;

  const [submitReady, setSubmitReady] = useState(false);
  const [loading, setLoading] = useState(false);
  const [card, setCard] = useState(null);
  const [stripe, setStripe] = useState(null);
  const [error, setError] = useState(null);

  const [bumpCredits, setBumpCredits] = useState(0);
  const [creditsApplied, setCreditsApplied] = useState(0);

  useEffect(() => {
    if (currentUser?.attributes?.profile?.privateData?.bumpCredits) {
      const credits = Number(currentUser?.attributes?.profile?.privateData?.bumpCredits);
      setBumpCredits(credits);
    }
  }, [currentUser]);

  const amoutToPay = listing ? 120 : 1199;
  const buttonLabel = <FormattedMessage id="BumpListingForm.purchase" />;
  const errorMessage = <FormattedMessage id="BumpListingForm.errorMessage" />;
  const successMessage = listing ? (
    <FormattedMessage id="BumpListingForm.successMessage" />
  ) : (
    <FormattedMessage id="BumpListingForm.successMessageFeaturedWardrobe" />
  );
  const now = new Date();
  const threeDaysAhead = now.addDays(3);
  const fiveDaysAhead = now.addDays(5);

  const handleStripeToken = (event, stripe, elements) => {
    const card = elements.getElement(CardElement);

    if (event.complete) {
      setSubmitReady(true);
    }
    setCard(card);
    setStripe(stripe);
  };

  const submitPaymentMaybe = async amountToPay => {
    if (amountToPay > 0) {
      return stripe.createToken(card).then(resp => {
        const token = resp.token;
        const body1 = {
          token,
          totalPrice: amountToPay,
        };

        return post('/api/send-to-stripe', body1)
          .then(resp => {
            const chargeStatus = resp;
            return chargeStatus;
          })
          .catch(e => {
            throw e;
          });
      });
    } else {
      return 'succeeded';
    }
  };

  const updateUserOrListingData = listing => {
    const bumpProfileInfoMaybe = listing
      ? { privateData: { bumpCredits } }
      : {
          publicData: { bumped: true, bumpEnd: fiveDaysAhead.getTime() },
          privateData: { bumpCredits },
        };

    return sdk.currentUser
      .updateProfile({
        ...bumpProfileInfoMaybe,
      })
      .then(resp => {
        if (listing) {
          return sdk.ownListings
            .update({
              id: new UUID(listing.id.uuid),
              publicData: {
                bumped: true,
                bumpEnd: threeDaysAhead.getTime(),
              },
            })
            .catch(e => {
              throw e;
            });
        } else {
          return true;
        }
      })
      .then(res => {
        setLoading(false);
        setError(false);
        setSuccess(true);
      })
      .catch(e => {
        throw e;
      });
  };

  const submit = () => {
    setLoading(true);

    const creditsAppliedInBgn = creditsApplied * 100; //we convert used credits to BGN subunits;
    const amountAfterDiscountMaybe = amoutToPay - creditsAppliedInBgn;
    return submitPaymentMaybe(amountAfterDiscountMaybe)
      .then(resp => {
        const chargeStatus = resp;
        const isSuccessful = chargeStatus === 'succeeded';
        if (isSuccessful) {
          return updateUserOrListingData(listing)
            .then(res => {
              setLoading(false);
              setError(false);
              setSuccess(true);
            })
            .catch(e => {
              setLoading(false);
              setError(true);
            });
        } else {
          setLoading(false);
          setError(true);
        }
      })
      .catch(e => {
        setLoading(false);
        setError(true);
        return console.log(e);
      });
  };

  const totalPriceConvertedInCredits = listing ? 1.2 : 12;

  const handleUseCredits = () => {
    if (!creditsButtonDisabled) {
      const creditsUsed =
        bumpCredits - totalPriceConvertedInCredits >= 0
          ? totalPriceConvertedInCredits
          : bumpCredits;
      const creditsLeft = bumpCredits - creditsUsed;
      setBumpCredits(Number(creditsLeft).toFixed(1));
      setCreditsApplied(Number(creditsUsed).toFixed(1));
    }
  };

  const priceAmountAfterDiscount = Number(totalPriceConvertedInCredits - creditsApplied).toFixed(2);
  const discountPriceLabel = creditsApplied > 0 && (
    <span>
      <span style={{ textDecoration: 'line-through' }}>{listing ? '1.20' : '11.99'}</span>{' '}
      {priceAmountAfterDiscount}
    </span>
  );

  const priceLabel = discountPriceLabel || <span>{listing ? '1.20' : '11.99'}</span>;
  const paymentRequired = priceAmountAfterDiscount > 0;
  const noPaymentLabel = listing ? (
    <FormattedMessage id="BumpListingForm.bump" />
  ) : (
    <FormattedMessage id="BumpListingForm.feature" />
  );

  const submitDisabled = paymentRequired && (!submitReady || success || loading);
  const creditsButtonDisabled = creditsApplied > 0;
  return (
    <div>
      <br />
      <center>
        <h2>
          <FormattedMessage
            id="BumpListingForm.title"
            values={{
              priceLabel,
              info: listing ? (
                <FormattedMessage id="BumpListingForm.bumpListingInfo" />
              ) : (
                <FormattedMessage id="BumpListingForm.featureWardrobeInfo" />
              ),
            }}
          />
        </h2>
        <p style={{ lineHeight: '22px' }}>
          {listing ? (
            <FormattedMessage id="BumpListingForm.subtitle" />
          ) : (
            <FormattedMessage id="BumpListingForm.subtitle2" />
          )}
        </p>
      </center>

      <div className={css.bumpCreditsWrapper}>
        <p>
          <FormattedMessage id="BumpListingForm.creditsLeft" values={{ credits: bumpCredits }} />
        </p>
      </div>

      <div className={css.cardWrapper}>
        {paymentRequired && <CardSection handleStripeToken={handleStripeToken} />}
      </div>

      <br />
      {error ? <p className={css.error}>{errorMessage}</p> : null}
      {success ? <p className={css.success}>{successMessage}</p> : null}
      <div className={css.buttonWrapper}>
        <Button
          className={submitDisabled ? css.submitButtonDisabled : css.submitButton}
          onClick={submit}
          disabled={submitDisabled}
        >
          {success ? (
            <CheckIcon />
          ) : loading ? (
            <Box sx={{ display: 'flex' }}>
              <CircularProgress className={css.spinnerMiddle} />
            </Box>
          ) : paymentRequired ? (
            buttonLabel
          ) : (
            noPaymentLabel
          )}
        </Button>

        <Button
          onClick={handleUseCredits}
          className={creditsButtonDisabled ? css.bumpCreditsButtonDisabled : css.bumpCreditsButton}
          disabled={creditsButtonDisabled}
        >
          <FormattedMessage id="BumpListingForm.bumpCreditsButtonMessage" />
        </Button>
      </div>
    </div>
  );
}

export default BumpListingForm;
