/**
 * This is a wrapper component for different Layouts.
 * Navigational 'aside' content should be added to this wrapper.
 */
import React, { useState, useEffect } from 'react';
import { node, number, string, shape } from 'prop-types';
import { compose } from 'redux';

import { FormattedMessage } from '../../util/reactIntl';
import { withViewport } from '../../util/contextHelpers';
import { LayoutWrapperSideNav } from '../../components';
import { types as sdkTypes } from '../../util/sdkLoader';
import { post, get } from '../../util/api';
const sharetribeSdk = require('sharetribe-flex-sdk');

const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

const { UUID, LatLng } = sdkTypes;

import { createGlobalState } from './hookGlobalState';

const MAX_HORIZONTAL_NAV_SCREEN_WIDTH = 1023;

// Add global state for tab scrolling effect
const initialScrollState = { scrollLeft: 0 };
const { useGlobalState } = createGlobalState(initialScrollState);

// Horizontal scroll animation using element.scrollTo()
const scrollToTab = (currentTab, scrollLeft, setScrollLeft) => {
  const el = document.querySelector(`#${currentTab}Tab`);

  if (el) {
    // el.scrollIntoView doesn't work with Safari and it considers vertical positioning too.
    // This scroll behaviour affects horizontal scrolling only
    // and it expects that the immediate parent element is scrollable.
    const parent = el.parentElement;
    const parentRect = parent.getBoundingClientRect();
    const maxScrollDistance = parent.scrollWidth - parentRect.width;

    const hasParentScrolled = parent.scrollLeft > 0;
    const scrollPositionCurrent = hasParentScrolled ? parent.scrollLeft : scrollLeft;

    const tabRect = el.getBoundingClientRect();
    const diffLeftBetweenTabAndParent = tabRect.left - parentRect.left;
    const tabScrollPosition = parent.scrollLeft + diffLeftBetweenTabAndParent;

    const scrollPositionNew =
      tabScrollPosition > maxScrollDistance
        ? maxScrollDistance
        : parent.scrollLeft + diffLeftBetweenTabAndParent;

    const needsSmoothScroll = scrollPositionCurrent !== scrollPositionNew;

    if (!hasParentScrolled || (hasParentScrolled && needsSmoothScroll)) {
      // Ensure that smooth scroll animation uses old position as starting point after navigation.
      parent.scrollTo({ left: scrollPositionCurrent });
      // Scroll to new position
      parent.scrollTo({ left: scrollPositionNew, behavior: 'smooth' });
    }
    // Always keep track of new position (even if smooth scrolling is not applied)
    if (setScrollLeft) {
      setScrollLeft(scrollPositionNew);
    }
  }
};

const LayoutWrapperAccountSettingsSideNavComponent = props => {
  const { currentTab, viewport } = props;
  const [isStripeConnected, setIsStripeConnected] = useState(false);
  const [subscriptions, setSubscriptions] = useState([]);
  const [currentUser, setCurrentUser] = useState(null);
  useEffect(() => {
    sdk.currentUser
      .show({
        include: ['stripeAccount', 'stripeCustomer'],
      })
      .then(resp => {
        setCurrentUser(resp.data.data);
        const isConnected = resp.data.data.attributes.stripeConnected;
        const userEmail = resp.data.data.attributes.email;
        const userId = resp.data.data.id.uuid;
        const hasCustomerId = resp.data.data.attributes.profile.privateData
          ? resp.data.data.attributes.profile.privateData.stripeCustomerId
            ? resp.data.data.attributes.profile.privateData.stripeCustomerId
            : null
          : null;
        setIsStripeConnected(isConnected);
        return [hasCustomerId, userEmail, userId];
      })
      .then(resp => {
        const stripeCustomerId = resp[0];
        const userEmail = resp[1];
        const userId = resp[2];

        if (stripeCustomerId) {
          const requestOptions = {
            // method: 'POST',
            // headers: {
            //   'Content-Type': 'application/json'
            // },
            // body: JSON.stringify({
            stripeCustomerId: stripeCustomerId,
            // })
          };

          const fetchData = async () => {
            const { subscriptions } = await post('/api/subscriptions', requestOptions);

            setSubscriptions(subscriptions.data);
          };
          fetchData();
        } else {
          post('/api/create-customer', {
            // method: 'post',
            // headers: {
            //   'Content-Type': 'application/json',
            // },
            // body: JSON.stringify({
            email: userEmail,
            userId: userId,
            // }),
          }).catch(error => console.log(error));

          setSubscriptions([]);
        }
      })
      .catch(e => console.log(e));
  }, []);

  const hasSubscriptions = subscriptions.length !== 0;

  let hasScrolledToTab = false;

  const { width } = viewport;
  const hasViewport = width > 0;
  const hasHorizontalTabLayout = hasViewport && width <= MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
  const hasVerticalTabLayout = hasViewport && width > MAX_HORIZONTAL_NAV_SCREEN_WIDTH;
  const hasFontsLoaded = hasViewport && document.documentElement.classList.contains('fontsLoaded');

  // Check if scrollToTab call is needed (tab is not visible on mobile)
  if (hasVerticalTabLayout) {
    hasScrolledToTab = true;
  } else if (hasHorizontalTabLayout && !hasScrolledToTab && hasFontsLoaded) {
    scrollToTab(currentTab);
    hasScrolledToTab = true;
  }

  const tabs = isStripeConnected
    ? hasSubscriptions
      ? [
          {
            text: (
              <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.contactDetailsTabTitle" />
            ),
            selected: currentTab === 'ContactDetailsPage',
            id: 'ContactDetailsPageTab',
            linkProps: {
              name: 'ContactDetailsPage',
            },
          },
          {
            text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.passwordTabTitle" />,
            selected: currentTab === 'PasswordChangePage',
            id: 'PasswordChangePageTab',
            linkProps: {
              name: 'PasswordChangePage',
            },
          },
          // {
          //   text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.paymentsTabTitle" />,
          //   selected: currentTab === 'StripePayoutPage',
          //   id: 'StripePayoutPageTab',
          //   linkProps: {
          //     name: 'StripePayoutPage',
          //   },
          // },
          // {
          //   text: (
          //     <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.paymentMethodsTabTitle" />
          //   ),
          //   selected: currentTab === 'PaymentMethodsPage',
          //   id: 'PaymentMethodsPageTab',
          //   linkProps: {
          //     name: 'PaymentMethodsPage',
          //   },
          // },
          {
            text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.stripeSubscriptions" />,
            selected: currentTab === 'StripeAccountSubscriptionsPage',
            id: 'StripeAccountSubscriptionsTab',
            linkProps: {
              name: 'StripeAccountSubscriptionsPage',
            },
          },
        ]
      : [
          {
            text: (
              <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.contactDetailsTabTitle" />
            ),
            selected: currentTab === 'ContactDetailsPage',
            id: 'ContactDetailsPageTab',
            linkProps: {
              name: 'ContactDetailsPage',
            },
          },
          {
            text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.passwordTabTitle" />,
            selected: currentTab === 'PasswordChangePage',
            id: 'PasswordChangePageTab',
            linkProps: {
              name: 'PasswordChangePage',
            },
          },
          // {
          //   text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.paymentsTabTitle" />,
          //   selected: currentTab === 'StripePayoutPage',
          //   id: 'StripePayoutPageTab',
          //   linkProps: {
          //     name: 'StripePayoutPage',
          //   },
          // },
          // {
          //   text: (
          //     <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.paymentMethodsTabTitle" />
          //   ),
          //   selected: currentTab === 'PaymentMethodsPage',
          //   id: 'PaymentMethodsPageTab',
          //   linkProps: {
          //     name: 'PaymentMethodsPage',
          //   },
          // },
          {
            text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.stripeSubscriptions" />,
            selected: currentTab === 'StripeSubscriptionsPricesPage',
            id: 'StripeSubscriptionsPricesTab',
            linkProps: {
              name: 'StripeSubscriptionsPricesPage',
            },
          },
        ]
    : [
        {
          text: (
            <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.contactDetailsTabTitle" />
          ),
          selected: currentTab === 'ContactDetailsPage',
          id: 'ContactDetailsPageTab',
          linkProps: {
            name: 'ContactDetailsPage',
          },
        },
        {
          text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.passwordTabTitle" />,
          selected: currentTab === 'PasswordChangePage',
          id: 'PasswordChangePageTab',
          linkProps: {
            name: 'PasswordChangePage',
          },
        },
        // {
        //   text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.paymentsTabTitle" />,
        //   selected: currentTab === 'StripePayoutPage',
        //   id: 'StripePayoutPageTab',
        //   linkProps: {
        //     name: 'StripePayoutPage',
        //   },
        // },
        // {
        //   text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.paymentMethodsTabTitle" />,
        //   selected: currentTab === 'PaymentMethodsPage',
        //   id: 'PaymentMethodsPageTab',
        //   linkProps: {
        //     name: 'PaymentMethodsPage',
        //   },
        // },
        {
          text: <FormattedMessage id="LayoutWrapperAccountSettingsSideNav.stripeSubscriptions" />,
          selected: currentTab === 'StripeAccountSubscriptionsPage',
          id: 'StripeAccountSubscriptionsTab',
          linkProps: {
            name: 'StripeAccountSubscriptionsPage',
          },
        },
      ];
  const finalTabs =
    currentUser && currentUser.attributes.profile.publicData.isCompany
      ? tabs
      : tabs.filter(t => t.id !== 'StripeAccountSubscriptionsTab');

  return <LayoutWrapperSideNav tabs={finalTabs} />;
};

LayoutWrapperAccountSettingsSideNavComponent.defaultProps = {
  className: null,
  rootClassName: null,
  children: null,
  currentTab: null,
};

LayoutWrapperAccountSettingsSideNavComponent.propTypes = {
  children: node,
  className: string,
  rootClassName: string,
  currentTab: string,

  // from withViewport
  viewport: shape({
    width: number.isRequired,
    height: number.isRequired,
  }).isRequired,
};

const LayoutWrapperAccountSettingsSideNav = compose(withViewport)(
  LayoutWrapperAccountSettingsSideNavComponent
);

export default LayoutWrapperAccountSettingsSideNav;
