import React from 'react';
import { useTable } from 'react-table';
import { FormattedMessage } from '../../../util/reactIntl';
import css from './PricesTable.module.css';

function PricesTable(isProvider) {
  const data = React.useMemo(
    () => [
      {
        col1: 'До 1кг.',
        col2: '3.89',
        col3: '5.99',
      },
      {
        col1: 'До 3кг.',
        col2: '3.89',
        col3: '8.15',
      },
      {
        col1: 'До 6кг.',
        col2: '5.58',
        col3: '11.20',
      },
      {
        col1: 'До 10кг.',
        col2: '6.96',
        col3: '12.44',
      },
      {
        col1: 'До 20кг.',
        col2: '12.78',
        col3: '20.14',
      },
    ],
    []
  );

  const columns = React.useMemo(
    () => [
      {
        Header: 'Килограми',
        accessor: 'col1', // accessor is the "key" in the data
      },
      {
        Header: 'До офис',
        accessor: 'col2',
      },
      {
        Header: 'До адрес',
        accessor: 'col3',
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });

  const speedyLink1 = (
    <a
      style={{ textDecoration: 'underline' }}
      href="https://www.speedy.bg/bg/cash-on-delivery"
      target="_blank"
    >
      <FormattedMessage id="ProductOrderForm.speedyLink1Label" />
    </a>
  );

  console.log(isProvider);
  console.log('in prices table');

  return (
    <div className={css.tableWrapper}>
      {/* <table {...getTableProps()} style={{ width: '100%' }}>
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th
                  {...column.getHeaderProps()}
                  style={{
                    color: 'black',
                    border: '1px solid #4a4a4a',
                  }}
                >
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        padding: '10px',
                        border: 'solid 1px #4a4a4a',
                        width: '33%',
                      }}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table> */}

      
      <center>
        <p className={css.tableBottomText}>
          {isProvider.isProvider ? <FormattedMessage
            id="ProductOrderForm.deliveryModalBottomText"
            values={{ speedyLink1 }}
            /> : <FormattedMessage
            id="ProductOrderForm.deliveryBuyerModalBottomText"
            values={{ speedyLink1 }}
        />}
          {/* <FormattedMessage
            id="ProductOrderForm.deliveryModalBottomText"
            values={{ speedyLink1 }}
          /> */}
        </p>
      </center>
    </div>
  );
}

export default PricesTable;
