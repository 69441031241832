const speedyOffices = [

        {
            "id": 1,
            "name": "ПЛОВДИВ - СКЛАД",
            "nameEn": "PLOVDIV - WAREHOUSE",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "streetId": 10621,
                "streetType": "УЛ.",
                "streetName": "КУКЛЕНСКО ШОСЕ",
                "streetNo": "15",
                "x": 24.761268,
                "y": 42.120139,
                "fullAddressString": "гр. ПЛОВДИВ ул. КУКЛЕНСКО ШОСЕ No 15",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "ул. КУКЛЕНСКО ШОСЕ No 15"
            },
            "workingTimeFrom": "08:30",
            "workingTimeTo": "19:30",
            "workingTimeHalfFrom": "08:30",
            "workingTimeHalfTo": "14:30",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "19:30",
            "sameDayDepartureCutoffHalf": "14:30",
            "sameDayDepartureCutoffDayOff": "19:00",
            "maxParcelDimensions": {
                "width": 240,
                "height": 240,
                "depth": 600
            },
            "maxParcelWeight": 1200.0,
            "type": "OFFICE",
            "nearbyOfficeId": 836,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "19:30",
                    "sameDayDepartureCutoff": "19:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "19:30",
                    "sameDayDepartureCutoff": "19:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "19:30",
                    "sameDayDepartureCutoff": "19:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "14:30",
                    "sameDayDepartureCutoff": "14:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "19:30",
                    "sameDayDepartureCutoff": "19:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "19:30",
                    "sameDayDepartureCutoff": "19:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "19:30",
                    "sameDayDepartureCutoff": "19:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "19:30",
                    "sameDayDepartureCutoff": "19:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "19:30",
                    "sameDayDepartureCutoff": "19:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PALLET",
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": true,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 2,
            "name": "СОФИЯ - СОМАТ",
            "nameEn": "SOFIA - SOMAT",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 4650,
                "streetType": "УЛ.",
                "streetName": "АБАГАР",
                "streetNo": "22",
                "addressNote": "СОФИЯ СИТИ ЛОДЖИСТИК ПАРК",
                "x": 23.392707,
                "y": 42.642021,
                "fullAddressString": "гр. СОФИЯ ул. АБАГАР No 22 СОФИЯ СИТИ ЛОДЖИСТИК ПАРК",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. АБАГАР No 22 СОФИЯ СИТИ ЛОДЖИСТИК ПАРК"
            },
            "workingTimeFrom": "07:30",
            "workingTimeTo": "20:30",
            "workingTimeHalfFrom": "07:30",
            "workingTimeHalfTo": "15:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "20:30",
            "sameDayDepartureCutoffHalf": "15:00",
            "sameDayDepartureCutoffDayOff": "20:00",
            "maxParcelDimensions": {
                "width": 240,
                "height": 240,
                "depth": 700
            },
            "maxParcelWeight": 1200.0,
            "type": "OFFICE",
            "nearbyOfficeId": 2,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "20:30",
                    "sameDayDepartureCutoff": "20:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "20:30",
                    "sameDayDepartureCutoff": "20:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "20:30",
                    "sameDayDepartureCutoff": "20:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "15:00",
                    "sameDayDepartureCutoff": "15:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "20:30",
                    "sameDayDepartureCutoff": "20:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "20:30",
                    "sameDayDepartureCutoff": "20:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "20:30",
                    "sameDayDepartureCutoff": "20:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "20:30",
                    "sameDayDepartureCutoff": "20:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "20:30",
                    "sameDayDepartureCutoff": "20:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PALLET",
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": true,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 3,
            "name": "БУРГАС - СКЛАД",
            "nameEn": "BURGAS - WAREHOUSE",
            "siteId": 7079,
            "address": {
                "countryId": 100,
                "siteId": 7079,
                "siteType": "ГР.",
                "siteName": "БУРГАС",
                "postCode": "8000",
                "addressNote": "УЛ. КРАЙЕЗЕРНА, БАЗА СОМАТ",
                "x": 27.409695,
                "y": 42.518513,
                "fullAddressString": "гр. БУРГАС УЛ. КРАЙЕЗЕРНА, БАЗА СОМАТ",
                "siteAddressString": "гр. БУРГАС",
                "localAddressString": "УЛ. КРАЙЕЗЕРНА, БАЗА СОМАТ"
            },
            "workingTimeFrom": "08:30",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "19:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "18:00",
            "maxParcelDimensions": {
                "width": 200,
                "height": 240,
                "depth": 600
            },
            "maxParcelWeight": 1200.0,
            "type": "OFFICE",
            "nearbyOfficeId": 145,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PALLET",
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": true,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 4,
            "name": "ВАРНА - СКЛАД",
            "nameEn": "VARNA - WAREHOUSE",
            "siteId": 10135,
            "address": {
                "countryId": 100,
                "siteId": 10135,
                "siteType": "ГР.",
                "siteName": "ВАРНА",
                "postCode": "9000",
                "streetNo": "2",
                "complexId": 620,
                "complexType": "",
                "complexName": "ЗАПАДНА ПРОМИШЛЕНА ЗОНА",
                "x": 27.865105,
                "y": 43.217495,
                "fullAddressString": "гр. ВАРНА ЗАПАДНА ПРОМИШЛЕНА ЗОНА No 2",
                "siteAddressString": "гр. ВАРНА",
                "localAddressString": "ЗАПАДНА ПРОМИШЛЕНА ЗОНА No 2"
            },
            "workingTimeFrom": "08:30",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "19:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "18:00",
            "maxParcelDimensions": {
                "width": 200,
                "height": 240,
                "depth": 600
            },
            "maxParcelWeight": 1200.0,
            "type": "OFFICE",
            "nearbyOfficeId": 111,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PALLET",
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": true,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 5,
            "name": "СТАРА ЗАГОРА - СКЛАД",
            "nameEn": "STARA ZAGORA - WEREHOUSE",
            "siteId": 68850,
            "address": {
                "countryId": 100,
                "siteId": 68850,
                "siteType": "ГР.",
                "siteName": "СТАРА ЗАГОРА",
                "postCode": "6000",
                "addressNote": "ПТИЦЕКОМБИНАТ С\\У С. ЗАГОРЕ",
                "x": 25.659016,
                "y": 42.349127,
                "fullAddressString": "гр. СТАРА ЗАГОРА ПТИЦЕКОМБИНАТ С\\У С. ЗАГОРЕ",
                "siteAddressString": "гр. СТАРА ЗАГОРА",
                "localAddressString": "ПТИЦЕКОМБИНАТ С\\У С. ЗАГОРЕ"
            },
            "workingTimeFrom": "08:30",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "08:30",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "22:30",
            "sameDayDepartureCutoffHalf": "22:30",
            "sameDayDepartureCutoffDayOff": "22:30",
            "maxParcelDimensions": {
                "width": 240,
                "height": 240,
                "depth": 600
            },
            "maxParcelWeight": 1200.0,
            "type": "OFFICE",
            "nearbyOfficeId": 144,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "22:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "22:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "22:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "22:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "22:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "22:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "22:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "22:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "22:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PALLET",
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": true,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": false
        },
        {
            "id": 6,
            "name": "ХАСКОВО - СКЛАД",
            "nameEn": "HASKOVO - WAREHOUSE",
            "siteId": 77195,
            "address": {
                "countryId": 100,
                "siteId": 77195,
                "siteType": "ГР.",
                "siteName": "ХАСКОВО",
                "postCode": "6300",
                "complexId": 2325,
                "complexType": "",
                "complexName": "ИЗТОЧНА ИНДУСТРИАЛНА ЗОНА",
                "x": 25.603441,
                "y": 41.932721,
                "fullAddressString": "гр. ХАСКОВО [6300] ИЗТОЧНА ИНДУСТРИАЛНА ЗОНА",
                "siteAddressString": "гр. ХАСКОВО [6300]",
                "localAddressString": "ИЗТОЧНА ИНДУСТРИАЛНА ЗОНА"
            },
            "workingTimeFrom": "08:30",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "08:30",
            "workingTimeHalfTo": "14:30",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 240,
                "height": 240,
                "depth": 600
            },
            "maxParcelWeight": 1200.0,
            "type": "OFFICE",
            "nearbyOfficeId": 348,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "14:30",
                    "sameDayDepartureCutoff": "14:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PALLET",
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": true,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 7,
            "name": "СЛИВЕН - СТЕФАН КАРАДЖА",
            "nameEn": "SLIVEN - STEFAN KARADZHA",
            "siteId": 67338,
            "address": {
                "countryId": 100,
                "siteId": 67338,
                "siteType": "ГР.",
                "siteName": "СЛИВЕН",
                "postCode": "8800",
                "streetId": 44011,
                "streetType": "БУЛ.",
                "streetName": "СТЕФАН КАРАДЖА",
                "streetNo": "38",
                "x": 26.336229,
                "y": 42.651844,
                "fullAddressString": "гр. СЛИВЕН [8800] бул. СТЕФАН КАРАДЖА No 38",
                "siteAddressString": "гр. СЛИВЕН [8800]",
                "localAddressString": "бул. СТЕФАН КАРАДЖА No 38"
            },
            "workingTimeFrom": "08:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "08:00",
            "workingTimeHalfTo": "14:30",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "19:00",
            "sameDayDepartureCutoffHalf": "14:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 240,
                "height": 240,
                "depth": 600
            },
            "maxParcelWeight": 1200.0,
            "type": "OFFICE",
            "nearbyOfficeId": 92,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "14:30",
                    "sameDayDepartureCutoff": "14:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PALLET",
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": true,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 8,
            "name": "ШУМЕН - СКЛАД",
            "nameEn": "SHUMEN - WAREHOUSE",
            "siteId": 83510,
            "address": {
                "countryId": 100,
                "siteId": 83510,
                "siteType": "ГР.",
                "siteName": "ШУМЕН",
                "postCode": "9700",
                "streetId": 24577,
                "streetType": "УЛ.",
                "streetName": "ИНДУСТРИАЛНА",
                "complexId": 1204,
                "complexType": "КВ.",
                "complexName": "ТРАКИЯ",
                "addressNote": "СКЛ. БАЗА АГРО-НАЙЛ",
                "x": 26.967874,
                "y": 43.265454,
                "fullAddressString": "гр. ШУМЕН [9700] кв. ТРАКИЯ ул. ИНДУСТРИАЛНА СКЛ. БАЗА АГРО-НАЙЛ",
                "siteAddressString": "гр. ШУМЕН [9700]",
                "localAddressString": "кв. ТРАКИЯ ул. ИНДУСТРИАЛНА СКЛ. БАЗА АГРО-НАЙЛ"
            },
            "workingTimeFrom": "08:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "08:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 200,
                "height": 220,
                "depth": 600
            },
            "maxParcelWeight": 1200.0,
            "type": "OFFICE",
            "nearbyOfficeId": 447,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PALLET",
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": true,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 9,
            "name": "РАЗГРАД - ОФИС",
            "nameEn": "RAZGRAD - OFFICE",
            "siteId": 61710,
            "address": {
                "countryId": 100,
                "siteId": 61710,
                "siteType": "ГР.",
                "siteName": "РАЗГРАД",
                "postCode": "7200",
                "complexId": 2105,
                "complexType": "ЖК",
                "complexName": "ОСВОБОЖДЕНИЕ",
                "blockNo": "5",
                "addressNote": "ДО ОВЪРГАЗ",
                "x": 26.524247,
                "y": 43.530252,
                "fullAddressString": "гр. РАЗГРАД [7200] жк ОСВОБОЖДЕНИЕ бл. 5 ДО ОВЪРГАЗ",
                "siteAddressString": "гр. РАЗГРАД [7200]",
                "localAddressString": "жк ОСВОБОЖДЕНИЕ бл. 5 ДО ОВЪРГАЗ"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 170,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 120,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 10,
            "name": "РУСЕ - БЪЛГАРИЯ (БУЛ.)",
            "nameEn": "RUSE - BULGARIA (BUL.)",
            "siteId": 63427,
            "address": {
                "countryId": 100,
                "siteId": 63427,
                "siteType": "ГР.",
                "siteName": "РУСЕ",
                "postCode": "7000",
                "streetId": 16091,
                "streetType": "БУЛ.",
                "streetName": "БЪЛГАРИЯ",
                "streetNo": "310",
                "x": 26.016864,
                "y": 43.872216,
                "fullAddressString": "гр. РУСЕ бул. БЪЛГАРИЯ No 310",
                "siteAddressString": "гр. РУСЕ",
                "localAddressString": "бул. БЪЛГАРИЯ No 310"
            },
            "workingTimeFrom": "08:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "08:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "21:00",
            "sameDayDepartureCutoffHalf": "21:00",
            "sameDayDepartureCutoffDayOff": "21:00",
            "maxParcelDimensions": {
                "width": 240,
                "height": 220,
                "depth": 600
            },
            "maxParcelWeight": 1200.0,
            "type": "OFFICE",
            "nearbyOfficeId": 849,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "21:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "21:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "21:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "21:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "21:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "21:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "21:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "21:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "21:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PALLET",
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": true,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 11,
            "name": "ВЕЛИКО ТЪРНОВО - СКЛАД",
            "nameEn": "VELIKO TARNOVO - WAREHOUSE",
            "siteId": 10447,
            "address": {
                "countryId": 100,
                "siteId": 10447,
                "siteType": "ГР.",
                "siteName": "ВЕЛИКО ТЪРНОВО",
                "postCode": "5000",
                "streetId": 34151,
                "streetType": "БУЛ.",
                "streetName": "НИКОЛА ГАБРОВСКИ",
                "streetNo": "73К",
                "complexId": 1709,
                "complexType": "",
                "complexName": "ЗАПАДНА ИНДУСТРИАЛНА ЗОНА",
                "x": 25.590441,
                "y": 43.071819,
                "fullAddressString": "гр. ВЕЛИКО ТЪРНОВО ЗАПАДНА ИНДУСТРИАЛНА ЗОНА бул. НИКОЛА ГАБРОВСКИ No 73К",
                "siteAddressString": "гр. ВЕЛИКО ТЪРНОВО",
                "localAddressString": "ЗАПАДНА ИНДУСТРИАЛНА ЗОНА бул. НИКОЛА ГАБРОВСКИ No 73К"
            },
            "workingTimeFrom": "08:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "08:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "22:30",
            "sameDayDepartureCutoffHalf": "17:00",
            "sameDayDepartureCutoffDayOff": "22:30",
            "maxParcelDimensions": {
                "width": 240,
                "height": 220,
                "depth": 600
            },
            "maxParcelWeight": 1200.0,
            "type": "OFFICE",
            "nearbyOfficeId": 736,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "22:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "22:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "22:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "22:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "22:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "22:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "22:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "22:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PALLET",
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": true,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 12,
            "name": "ПЛЕВЕН - СКЛАД",
            "nameEn": "PLEVEN - WAREHOUSE",
            "siteId": 56722,
            "address": {
                "countryId": 100,
                "siteId": 56722,
                "siteType": "ГР.",
                "siteName": "ПЛЕВЕН",
                "postCode": "5800",
                "streetNo": "140",
                "complexId": 1003,
                "complexType": "ЖК",
                "complexName": "СТОРГОЗИЯ",
                "addressNote": "ИНДУСТР. И ЛОГИСТ. ПАРК ПЛЕВЕН",
                "x": 24.588246,
                "y": 43.426197,
                "fullAddressString": "гр. ПЛЕВЕН жк СТОРГОЗИЯ No 140 ИНДУСТР. И ЛОГИСТ. ПАРК ПЛЕВЕН",
                "siteAddressString": "гр. ПЛЕВЕН",
                "localAddressString": "жк СТОРГОЗИЯ No 140 ИНДУСТР. И ЛОГИСТ. ПАРК ПЛЕВЕН"
            },
            "workingTimeFrom": "08:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "08:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 240,
                "height": 220,
                "depth": 600
            },
            "maxParcelWeight": 1200.0,
            "type": "OFFICE",
            "nearbyOfficeId": 374,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PALLET",
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": true,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 13,
            "name": "БЛАГОЕВГРАД - СКЛАД",
            "nameEn": "BLAGOEVGRAD - WAREHOUSE",
            "siteId": 4279,
            "address": {
                "countryId": 100,
                "siteId": 4279,
                "siteType": "ГР.",
                "siteName": "БЛАГОЕВГРАД",
                "postCode": "2700",
                "streetId": 30053,
                "streetType": "БУЛ.",
                "streetName": "ВАСИЛ ЛЕВСКИ",
                "streetNo": "61",
                "addressNote": "БИВШ ЗАВОД НА ЗСТ",
                "x": 23.085226,
                "y": 42.006671,
                "fullAddressString": "гр. БЛАГОЕВГРАД бул. ВАСИЛ ЛЕВСКИ No 61 БИВШ ЗАВОД НА ЗСТ",
                "siteAddressString": "гр. БЛАГОЕВГРАД",
                "localAddressString": "бул. ВАСИЛ ЛЕВСКИ No 61 БИВШ ЗАВОД НА ЗСТ"
            },
            "workingTimeFrom": "08:30",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "19:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "18:00",
            "maxParcelDimensions": {
                "width": 240,
                "height": 220,
                "depth": 600
            },
            "maxParcelWeight": 1200.0,
            "type": "OFFICE",
            "nearbyOfficeId": 205,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PALLET",
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": true,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 14,
            "name": "ДОБРИЧ",
            "nameEn": "DOBRICH",
            "siteId": 72624,
            "address": {
                "countryId": 100,
                "siteId": 72624,
                "siteType": "ГР.",
                "siteName": "ДОБРИЧ",
                "postCode": "9300",
                "streetId": 22218,
                "streetType": "ПЛ.",
                "streetName": "СВОБОДА",
                "streetNo": "8",
                "x": 27.829721,
                "y": 43.567638,
                "fullAddressString": "гр. ДОБРИЧ [9300] пл. СВОБОДА No 8",
                "siteAddressString": "гр. ДОБРИЧ [9300]",
                "localAddressString": "пл. СВОБОДА No 8"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:30",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 180,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 852,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:30",
                    "sameDayDepartureCutoff": "13:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 15,
            "name": "ГАБРОВО - СКЛАД",
            "nameEn": "GABROVO - WAREHOUSE",
            "siteId": 14218,
            "address": {
                "countryId": 100,
                "siteId": 14218,
                "siteType": "ГР.",
                "siteName": "ГАБРОВО",
                "postCode": "5300",
                "streetId": 48221,
                "streetType": "УЛ.",
                "streetName": "ИНДУСТРИАЛНА",
                "streetNo": "48",
                "x": 25.331318,
                "y": 42.909763,
                "fullAddressString": "гр. ГАБРОВО [5300] ул. ИНДУСТРИАЛНА No 48",
                "siteAddressString": "гр. ГАБРОВО [5300]",
                "localAddressString": "ул. ИНДУСТРИАЛНА No 48"
            },
            "workingTimeFrom": "08:30",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "08:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 240,
                "height": 220,
                "depth": 600
            },
            "maxParcelWeight": 1200.0,
            "type": "OFFICE",
            "nearbyOfficeId": 456,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PALLET",
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": true,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 16,
            "name": "ТЪРГОВИЩЕ - СКЛАД",
            "nameEn": "TARGOVISHTE - WAREHOUSE",
            "siteId": 73626,
            "address": {
                "countryId": 100,
                "siteId": 73626,
                "siteType": "ГР.",
                "siteName": "ТЪРГОВИЩЕ",
                "postCode": "7700",
                "addressNote": "УЛ. ЦАР СИМЕОН, ПРОМИШЛЕНА ЗОНА РОДОПА",
                "x": 26.568047,
                "y": 43.25821,
                "fullAddressString": "гр. ТЪРГОВИЩЕ [7700] УЛ. ЦАР СИМЕОН, ПРОМИШЛЕНА ЗОНА РОДОПА",
                "siteAddressString": "гр. ТЪРГОВИЩЕ [7700]",
                "localAddressString": "УЛ. ЦАР СИМЕОН, ПРОМИШЛЕНА ЗОНА РОДОПА"
            },
            "workingTimeFrom": "08:30",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "08:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 200,
                "height": 200,
                "depth": 600
            },
            "maxParcelWeight": 1000.0,
            "type": "OFFICE",
            "nearbyOfficeId": 132,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PALLET",
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": true,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 17,
            "name": "АСЕНОВГРАД",
            "nameEn": "ASENOVGRAD",
            "siteId": 702,
            "address": {
                "countryId": 100,
                "siteId": 702,
                "siteType": "ГР.",
                "siteName": "АСЕНОВГРАД",
                "postCode": "4230",
                "streetId": 65460,
                "streetType": "УЛ.",
                "streetName": "ГОЦЕ ДЕЛЧЕВ",
                "streetNo": "91-93",
                "x": 24.865657,
                "y": 42.013876,
                "fullAddressString": "гр. АСЕНОВГРАД [4230] ул. ГОЦЕ ДЕЛЧЕВ No 91-93",
                "siteAddressString": "гр. АСЕНОВГРАД [4230]",
                "localAddressString": "ул. ГОЦЕ ДЕЛЧЕВ No 91-93"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 330
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 395,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 18,
            "name": "ПАЗАРДЖИК",
            "nameEn": "PAZARDZHIK",
            "siteId": 55155,
            "address": {
                "countryId": 100,
                "siteId": 55155,
                "siteType": "ГР.",
                "siteName": "ПАЗАРДЖИК",
                "postCode": "4400",
                "streetId": 40296,
                "streetType": "БУЛ.",
                "streetName": "СТЕФАН СТАМБОЛОВ",
                "streetNo": "30",
                "x": 24.350145,
                "y": 42.184268,
                "fullAddressString": "гр. ПАЗАРДЖИК [4400] бул. СТЕФАН СТАМБОЛОВ No 30",
                "siteAddressString": "гр. ПАЗАРДЖИК [4400]",
                "localAddressString": "бул. СТЕФАН СТАМБОЛОВ No 30"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 184,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 19,
            "name": "ВРАЦА - ОФИС",
            "nameEn": "VRATSA - OFFICE",
            "siteId": 12259,
            "address": {
                "countryId": 100,
                "siteId": 12259,
                "siteType": "ГР.",
                "siteName": "ВРАЦА",
                "postCode": "3000",
                "streetId": 38163,
                "streetType": "БУЛ.",
                "streetName": "МИТО ОРОЗОВ",
                "streetNo": "6",
                "x": 23.547339,
                "y": 43.20778,
                "fullAddressString": "гр. ВРАЦА [3000] бул. МИТО ОРОЗОВ No 6",
                "siteAddressString": "гр. ВРАЦА [3000]",
                "localAddressString": "бул. МИТО ОРОЗОВ No 6"
            },
            "workingTimeFrom": "08:30",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "08:30",
            "workingTimeHalfTo": "13:30",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 170,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 548,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "13:30",
                    "sameDayDepartureCutoff": "13:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 20,
            "name": "ВРАЦА - СКЛАД",
            "nameEn": "VRATSA - WAREHOUSE",
            "siteId": 12259,
            "address": {
                "countryId": 100,
                "siteId": 12259,
                "siteType": "ГР.",
                "siteName": "ВРАЦА",
                "postCode": "3000",
                "streetId": 38163,
                "streetType": "БУЛ.",
                "streetName": "МИТО ОРОЗОВ",
                "streetNo": "32",
                "x": 23.559079,
                "y": 43.220059,
                "fullAddressString": "гр. ВРАЦА [3000] бул. МИТО ОРОЗОВ No 32",
                "siteAddressString": "гр. ВРАЦА [3000]",
                "localAddressString": "бул. МИТО ОРОЗОВ No 32"
            },
            "workingTimeFrom": "08:00",
            "workingTimeTo": "18:30",
            "workingTimeHalfFrom": "08:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:30",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 240,
                "height": 240,
                "depth": 600
            },
            "maxParcelWeight": 1200.0,
            "type": "OFFICE",
            "nearbyOfficeId": 129,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PALLET",
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": true,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 21,
            "name": "КАЗАНЛЪК - СКЛАД, ДО OMV",
            "nameEn": "KAZANLAK - WAREHOUSE, NEXT TO OMV",
            "siteId": 35167,
            "address": {
                "countryId": 100,
                "siteId": 35167,
                "siteType": "ГР.",
                "siteName": "КАЗАНЛЪК",
                "postCode": "6100",
                "streetId": 91985,
                "streetType": "БУЛ.",
                "streetName": "КНЯЗ АЛЕКСАНДЪР БАТЕНБЕРГ",
                "streetNo": "221",
                "addressNote": "В ДВОРА НА ФЕРОХРОМ",
                "x": 25.418742,
                "y": 42.616228,
                "fullAddressString": "гр. КАЗАНЛЪК [6100] бул. КНЯЗ АЛЕКСАНДЪР БАТЕНБЕРГ No 221 В ДВОРА НА ФЕРОХРОМ",
                "siteAddressString": "гр. КАЗАНЛЪК [6100]",
                "localAddressString": "бул. КНЯЗ АЛЕКСАНДЪР БАТЕНБЕРГ No 221 В ДВОРА НА ФЕРОХРОМ"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 240,
                "height": 240,
                "depth": 600
            },
            "maxParcelWeight": 1200.0,
            "type": "OFFICE",
            "nearbyOfficeId": 135,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PALLET",
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": true,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 22,
            "name": "КАРЛОВО",
            "nameEn": "KARLOVO",
            "siteId": 36498,
            "address": {
                "countryId": 100,
                "siteId": 36498,
                "siteType": "ГР.",
                "siteName": "КАРЛОВО",
                "postCode": "4300",
                "streetId": 78823,
                "streetType": "УЛ.",
                "streetName": "ГЕН. ЗАИМОВ",
                "streetNo": "8",
                "x": 24.800139,
                "y": 42.639496,
                "fullAddressString": "гр. КАРЛОВО [4300] ул. ГЕН. ЗАИМОВ No 8",
                "siteAddressString": "гр. КАРЛОВО [4300]",
                "localAddressString": "ул. ГЕН. ЗАИМОВ No 8"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 200,
                "depth": 400
            },
            "maxParcelWeight": 600.0,
            "type": "OFFICE",
            "nearbyOfficeId": 403,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PALLET",
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": true,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 23,
            "name": "КЮСТЕНДИЛ",
            "nameEn": "KYUSTENDIL",
            "siteId": 41112,
            "address": {
                "countryId": 100,
                "siteId": 41112,
                "siteType": "ГР.",
                "siteName": "КЮСТЕНДИЛ",
                "postCode": "2500",
                "streetId": 54306,
                "streetType": "УЛ.",
                "streetName": "ХРИСТО БОТЕВ",
                "streetNo": "51",
                "x": 22.694024,
                "y": 42.285743,
                "fullAddressString": "гр. КЮСТЕНДИЛ [2500] ул. ХРИСТО БОТЕВ No 51",
                "siteAddressString": "гр. КЮСТЕНДИЛ [2500]",
                "localAddressString": "ул. ХРИСТО БОТЕВ No 51"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 200,
                "height": 200,
                "depth": 600
            },
            "maxParcelWeight": 1200.0,
            "type": "OFFICE",
            "nearbyOfficeId": 385,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PALLET",
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": true,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": false
        },
        {
            "id": 24,
            "name": "СИЛИСТРА",
            "nameEn": "SILISTRA",
            "siteId": 66425,
            "address": {
                "countryId": 100,
                "siteId": 66425,
                "siteType": "ГР.",
                "siteName": "СИЛИСТРА",
                "postCode": "7500",
                "streetId": 46283,
                "streetType": "УЛ.",
                "streetName": "ТУТРАКАН",
                "streetNo": "14",
                "x": 27.23779,
                "y": 44.107201,
                "fullAddressString": "гр. СИЛИСТРА [7500] ул. ТУТРАКАН No 14",
                "siteAddressString": "гр. СИЛИСТРА [7500]",
                "localAddressString": "ул. ТУТРАКАН No 14"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "16:45",
            "sameDayDepartureCutoffHalf": "12:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 230,
                "height": 220,
                "depth": 600
            },
            "maxParcelWeight": 1200.0,
            "type": "OFFICE",
            "nearbyOfficeId": 171,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:45",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:45",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:45",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "12:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:45",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:45",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:45",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:45",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:45",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PALLET",
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": true,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 25,
            "name": "ЛОВЕЧ",
            "nameEn": "LOVECH",
            "siteId": 43952,
            "address": {
                "countryId": 100,
                "siteId": 43952,
                "siteType": "ГР.",
                "siteName": "ЛОВЕЧ",
                "postCode": "5500",
                "streetId": 32178,
                "streetType": "УЛ.",
                "streetName": "СТАРА ПЛАНИНА",
                "streetNo": "29",
                "x": 24.704368,
                "y": 43.137727,
                "fullAddressString": "гр. ЛОВЕЧ [5500] ул. СТАРА ПЛАНИНА No 29",
                "siteAddressString": "гр. ЛОВЕЧ [5500]",
                "localAddressString": "ул. СТАРА ПЛАНИНА No 29"
            },
            "workingTimeFrom": "08:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "08:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 240,
                "height": 220,
                "depth": 600
            },
            "maxParcelWeight": 1200.0,
            "type": "OFFICE",
            "nearbyOfficeId": 425,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PALLET",
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": true,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 26,
            "name": "ДУПНИЦА",
            "nameEn": "DUPNITSA",
            "siteId": 68789,
            "address": {
                "countryId": 100,
                "siteId": 68789,
                "siteType": "ГР.",
                "siteName": "ДУПНИЦА",
                "postCode": "2600",
                "streetId": 72731,
                "streetType": "УЛ.",
                "streetName": "СТРАХИЛ ВОЙВОДА",
                "streetNo": "30",
                "addressNote": "С/У LIDL",
                "x": 23.111673,
                "y": 42.266689,
                "fullAddressString": "гр. ДУПНИЦА [2600] ул. СТРАХИЛ ВОЙВОДА No 30 С/У LIDL",
                "siteAddressString": "гр. ДУПНИЦА [2600]",
                "localAddressString": "ул. СТРАХИЛ ВОЙВОДА No 30 С/У LIDL"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 200,
                "depth": 200
            },
            "maxParcelWeight": 600.0,
            "type": "OFFICE",
            "nearbyOfficeId": 118,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 27,
            "name": "ПЕТРИЧ - ОФИС",
            "nameEn": "PETRICH - OFFICE",
            "siteId": 56126,
            "address": {
                "countryId": 100,
                "siteId": 56126,
                "siteType": "ГР.",
                "siteName": "ПЕТРИЧ",
                "postCode": "2850",
                "streetId": 88653,
                "streetType": "ПЛ.",
                "streetName": "МАКЕДОНИЯ",
                "streetNo": "10",
                "x": 23.207333,
                "y": 41.391396,
                "fullAddressString": "гр. ПЕТРИЧ [2850] пл. МАКЕДОНИЯ No 10",
                "siteAddressString": "гр. ПЕТРИЧ [2850]",
                "localAddressString": "пл. МАКЕДОНИЯ No 10"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "16:00",
            "sameDayDepartureCutoffHalf": "11:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 100,
                "height": 180,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 139,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "11:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 28,
            "name": "КАРНОБАТ",
            "nameEn": "KARNOBAT",
            "siteId": 36525,
            "address": {
                "countryId": 100,
                "siteId": 36525,
                "siteType": "ГР.",
                "siteName": "КАРНОБАТ",
                "postCode": "8400",
                "streetId": 78975,
                "streetType": "УЛ.",
                "streetName": "ВАСИЛ ЛЕВСКИ",
                "streetNo": "26",
                "x": 26.982379,
                "y": 42.650729,
                "fullAddressString": "гр. КАРНОБАТ [8400] ул. ВАСИЛ ЛЕВСКИ No 26",
                "siteAddressString": "гр. КАРНОБАТ [8400]",
                "localAddressString": "ул. ВАСИЛ ЛЕВСКИ No 26"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 200,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 7,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 29,
            "name": "СМОЛЯН",
            "nameEn": "SMOLYAN",
            "siteId": 67653,
            "address": {
                "countryId": 100,
                "siteId": 67653,
                "siteType": "ГР.",
                "siteName": "СМОЛЯН",
                "postCode": "4700",
                "streetId": 62037,
                "streetType": "БУЛ.",
                "streetName": "БЪЛГАРИЯ",
                "streetNo": "9А",
                "x": 24.707337,
                "y": 41.575192,
                "fullAddressString": "гр. СМОЛЯН [4700] бул. БЪЛГАРИЯ No 9А",
                "siteAddressString": "гр. СМОЛЯН [4700]",
                "localAddressString": "бул. БЪЛГАРИЯ No 9А"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "12:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 147,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "12:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 30,
            "name": "САНДАНСКИ",
            "nameEn": "SANDANSKI",
            "siteId": 65334,
            "address": {
                "countryId": 100,
                "siteId": 65334,
                "siteType": "ГР.",
                "siteName": "САНДАНСКИ",
                "postCode": "2800",
                "streetId": 89105,
                "streetType": "УЛ.",
                "streetName": "ПЕРУН",
                "streetNo": "1",
                "x": 23.279699,
                "y": 41.561402,
                "fullAddressString": "гр. САНДАНСКИ [2800] ул. ПЕРУН No 1",
                "siteAddressString": "гр. САНДАНСКИ [2800]",
                "localAddressString": "ул. ПЕРУН No 1"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "12:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 426,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "12:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 31,
            "name": "ГОЦЕ ДЕЛЧЕВ - ПАОЛО ЛЕНЦ",
            "nameEn": "GOTSE DELCHEV - PAOLO LENTS",
            "siteId": 17395,
            "address": {
                "countryId": 100,
                "siteId": 17395,
                "siteType": "ГР.",
                "siteName": "ГОЦЕ ДЕЛЧЕВ",
                "postCode": "2900",
                "streetId": 88129,
                "streetType": "УЛ.",
                "streetName": "ПАОЛО ЛЕНЦ",
                "streetNo": "4",
                "x": 23.730608,
                "y": 41.57018,
                "fullAddressString": "гр. ГОЦЕ ДЕЛЧЕВ [2900] ул. ПАОЛО ЛЕНЦ No 4",
                "siteAddressString": "гр. ГОЦЕ ДЕЛЧЕВ [2900]",
                "localAddressString": "ул. ПАОЛО ЛЕНЦ No 4"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "16:00",
            "sameDayDepartureCutoffHalf": "11:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 130,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "11:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 32,
            "name": "КЪРДЖАЛИ",
            "nameEn": "KARDZHALI",
            "siteId": 40909,
            "address": {
                "countryId": 100,
                "siteId": 40909,
                "siteType": "ГР.",
                "siteName": "КЪРДЖАЛИ",
                "postCode": "6600",
                "streetId": 56001,
                "streetType": "БУЛ.",
                "streetName": "БЕЛОМОРСКИ",
                "streetNo": "68",
                "addressNote": "В ДВОРА НА ХЛЕБОЗАВОДА",
                "x": 25.37963,
                "y": 41.632685,
                "fullAddressString": "гр. КЪРДЖАЛИ [6600] бул. БЕЛОМОРСКИ No 68 В ДВОРА НА ХЛЕБОЗАВОДА",
                "siteAddressString": "гр. КЪРДЖАЛИ [6600]",
                "localAddressString": "бул. БЕЛОМОРСКИ No 68 В ДВОРА НА ХЛЕБОЗАВОДА"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 240,
                "height": 240,
                "depth": 350
            },
            "maxParcelWeight": 1200.0,
            "type": "OFFICE",
            "nearbyOfficeId": 223,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PALLET",
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": true,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 33,
            "name": "СЕВЛИЕВО",
            "nameEn": "SEVLIEVO",
            "siteId": 65927,
            "address": {
                "countryId": 100,
                "siteId": 65927,
                "siteType": "ГР.",
                "siteName": "СЕВЛИЕВО",
                "postCode": "5400",
                "streetId": 94504,
                "streetType": "УЛ.",
                "streetName": "СТЕФАН ПЕШЕВ",
                "streetNo": "101",
                "addressNote": "МАГАЗИН А",
                "x": 25.097289,
                "y": 43.027397,
                "fullAddressString": "гр. СЕВЛИЕВО [5400] ул. СТЕФАН ПЕШЕВ No 101 МАГАЗИН А",
                "siteAddressString": "гр. СЕВЛИЕВО [5400]",
                "localAddressString": "ул. СТЕФАН ПЕШЕВ No 101 МАГАЗИН А"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 170,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 156,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 34,
            "name": "БАНСКО",
            "nameEn": "BANSKO",
            "siteId": 2676,
            "address": {
                "countryId": 100,
                "siteId": 2676,
                "siteType": "ГР.",
                "siteName": "БАНСКО",
                "postCode": "2770",
                "streetId": 66423,
                "streetType": "УЛ.",
                "streetName": "ЦАР СИМЕОН",
                "streetNo": "80Б",
                "addressNote": "С/У ЗАВОД ЗА ТЕЛ. АПАРАТУРА",
                "x": 23.495135,
                "y": 41.83368,
                "fullAddressString": "гр. БАНСКО [2770] ул. ЦАР СИМЕОН No 80Б С/У ЗАВОД ЗА ТЕЛ. АПАРАТУРА",
                "siteAddressString": "гр. БАНСКО [2770]",
                "localAddressString": "ул. ЦАР СИМЕОН No 80Б С/У ЗАВОД ЗА ТЕЛ. АПАРАТУРА"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "12:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 200,
                "depth": 150
            },
            "maxParcelWeight": 600.0,
            "type": "OFFICE",
            "nearbyOfficeId": 302,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "12:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 35,
            "name": "ПЕРНИК",
            "nameEn": "PERNIK",
            "siteId": 55871,
            "address": {
                "countryId": 100,
                "siteId": 55871,
                "siteType": "ГР.",
                "siteName": "ПЕРНИК",
                "postCode": "2300",
                "streetId": 50530,
                "streetType": "УЛ.",
                "streetName": "СВ. СВ. КИРИЛ И МЕТОДИЙ",
                "streetNo": "23",
                "addressNote": "БЦ ЕЛЕНА",
                "x": 23.040782,
                "y": 42.611087,
                "fullAddressString": "гр. ПЕРНИК [2300] ул. СВ. СВ. КИРИЛ И МЕТОДИЙ No 23 БЦ ЕЛЕНА",
                "siteAddressString": "гр. ПЕРНИК [2300]",
                "localAddressString": "ул. СВ. СВ. КИРИЛ И МЕТОДИЙ No 23 БЦ ЕЛЕНА"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 127,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 36,
            "name": "СВИЩОВ",
            "nameEn": "SVISHTOV",
            "siteId": 65766,
            "address": {
                "countryId": 100,
                "siteId": 65766,
                "siteType": "ГР.",
                "siteName": "СВИЩОВ",
                "postCode": "5250",
                "streetId": 89350,
                "streetType": "УЛ.",
                "streetName": "АЛЕКО КОНСТАНТИНОВ",
                "streetNo": "18",
                "x": 25.34047,
                "y": 43.61884,
                "fullAddressString": "гр. СВИЩОВ [5250] ул. АЛЕКО КОНСТАНТИНОВ No 18",
                "siteAddressString": "гр. СВИЩОВ [5250]",
                "localAddressString": "ул. АЛЕКО КОНСТАНТИНОВ No 18"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 170,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 250,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 42,
            "name": "ДИМИТРОВГРАД",
            "nameEn": "DIMITROVGRAD",
            "siteId": 21052,
            "address": {
                "countryId": 100,
                "siteId": 21052,
                "siteType": "ГР.",
                "siteName": "ДИМИТРОВГРАД",
                "postCode": "6400",
                "streetId": 68501,
                "streetType": "УЛ.",
                "streetName": "РОМЕН РОЛАН",
                "streetNo": "16",
                "x": 25.588295,
                "y": 42.059943,
                "fullAddressString": "гр. ДИМИТРОВГРАД [6400] ул. РОМЕН РОЛАН No 16",
                "siteAddressString": "гр. ДИМИТРОВГРАД [6400]",
                "localAddressString": "ул. РОМЕН РОЛАН No 16"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 200,
                "depth": 350
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 249,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 44,
            "name": "ТРОЯН",
            "nameEn": "TROYAN",
            "siteId": 73198,
            "address": {
                "countryId": 100,
                "siteId": 73198,
                "siteType": "ГР.",
                "siteName": "ТРОЯН",
                "postCode": "5600",
                "streetId": 95119,
                "streetType": "УЛ.",
                "streetName": "ВАСИЛ ЛЕВСКИ",
                "streetNo": "240",
                "x": 24.712737,
                "y": 42.894454,
                "fullAddressString": "гр. ТРОЯН [5600] ул. ВАСИЛ ЛЕВСКИ No 240",
                "siteAddressString": "гр. ТРОЯН [5600]",
                "localAddressString": "ул. ВАСИЛ ЛЕВСКИ No 240"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 170,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 750,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 46,
            "name": "ГОРНА ОРЯХОВИЦА - СВ.КНЯЗ БОРИС I (УЛ.)",
            "nameEn": "GORNA ORYAHOVITSA - SV.KNYAZ BORIS I (UL.)",
            "siteId": 16359,
            "address": {
                "countryId": 100,
                "siteId": 16359,
                "siteType": "ГР.",
                "siteName": "ГОРНА ОРЯХОВИЦА",
                "postCode": "5100",
                "streetId": 67837,
                "streetType": "УЛ.",
                "streetName": "СВ. КНЯЗ БОРИС I",
                "streetNo": "24",
                "x": 25.6924,
                "y": 43.122654,
                "fullAddressString": "гр. ГОРНА ОРЯХОВИЦА [5100] ул. СВ. КНЯЗ БОРИС I No 24",
                "siteAddressString": "гр. ГОРНА ОРЯХОВИЦА [5100]",
                "localAddressString": "ул. СВ. КНЯЗ БОРИС I No 24"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 170,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 756,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 47,
            "name": "ХАРМАНЛИ",
            "nameEn": "HARMANLI",
            "siteId": 77181,
            "address": {
                "countryId": 100,
                "siteId": 77181,
                "siteType": "ГР.",
                "siteName": "ХАРМАНЛИ",
                "postCode": "6450",
                "streetId": 72930,
                "streetType": "УЛ.",
                "streetName": "НИКОЛА ПЕТКОВ",
                "streetNo": "13",
                "x": 25.90121,
                "y": 41.928056,
                "fullAddressString": "гр. ХАРМАНЛИ [6450] ул. НИКОЛА ПЕТКОВ No 13",
                "siteAddressString": "гр. ХАРМАНЛИ [6450]",
                "localAddressString": "ул. НИКОЛА ПЕТКОВ No 13"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 200,
                "depth": 350
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 415,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": false
        },
        {
            "id": 48,
            "name": "СВИЛЕНГРАД",
            "nameEn": "SVILENGRAD",
            "siteId": 65677,
            "address": {
                "countryId": 100,
                "siteId": 65677,
                "siteType": "ГР.",
                "siteName": "СВИЛЕНГРАД",
                "postCode": "6500",
                "streetId": 71907,
                "streetType": "ПЛ.",
                "streetName": "14-ТИ МАРТ",
                "streetNo": "13",
                "x": 26.198277,
                "y": 41.766743,
                "fullAddressString": "гр. СВИЛЕНГРАД [6500] пл. 14-ТИ МАРТ No 13",
                "siteAddressString": "гр. СВИЛЕНГРАД [6500]",
                "localAddressString": "пл. 14-ТИ МАРТ No 13"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 200,
                "depth": 350
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 339,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 49,
            "name": "ВЕЛИНГРАД",
            "nameEn": "VELINGRAD",
            "siteId": 10450,
            "address": {
                "countryId": 100,
                "siteId": 10450,
                "siteType": "ГР.",
                "siteName": "ВЕЛИНГРАД",
                "postCode": "4600",
                "streetId": 67209,
                "streetType": "БУЛ.",
                "streetName": "ХАН АСПАРУХ",
                "streetNo": "2",
                "x": 23.996329,
                "y": 42.028177,
                "fullAddressString": "гр. ВЕЛИНГРАД [4600] бул. ХАН АСПАРУХ No 2",
                "siteAddressString": "гр. ВЕЛИНГРАД [4600]",
                "localAddressString": "бул. ХАН АСПАРУХ No 2"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "16:30",
            "sameDayDepartureCutoffHalf": "12:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 180
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 410,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "12:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 55,
            "name": "ЯМБОЛ - ОФИС",
            "nameEn": "YAMBOL - OFFICE",
            "siteId": 87374,
            "address": {
                "countryId": 100,
                "siteId": 87374,
                "siteType": "ГР.",
                "siteName": "ЯМБОЛ",
                "postCode": "8600",
                "streetId": 26101,
                "streetType": "УЛ.",
                "streetName": "ДИМИТЪР БЛАГОЕВ",
                "streetNo": "6",
                "x": 26.5042,
                "y": 42.4874,
                "fullAddressString": "гр. ЯМБОЛ [8600] ул. ДИМИТЪР БЛАГОЕВ No 6",
                "siteAddressString": "гр. ЯМБОЛ [8600]",
                "localAddressString": "ул. ДИМИТЪР БЛАГОЕВ No 6"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 200,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 93,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 56,
            "name": "НОВА ЗАГОРА",
            "nameEn": "NOVA ZAGORA",
            "siteId": 51809,
            "address": {
                "countryId": 100,
                "siteId": 51809,
                "siteType": "ГР.",
                "siteName": "НОВА ЗАГОРА",
                "postCode": "8900",
                "streetId": 92942,
                "streetType": "УЛ.",
                "streetName": "14-ТИ ЯНУАРИ",
                "streetNo": "77",
                "x": 26.010497,
                "y": 42.492266,
                "fullAddressString": "гр. НОВА ЗАГОРА [8900] ул. 14-ТИ ЯНУАРИ No 77",
                "siteAddressString": "гр. НОВА ЗАГОРА [8900]",
                "localAddressString": "ул. 14-ТИ ЯНУАРИ No 77"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 200,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 7,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 59,
            "name": "БЯЛА СЛАТИНА",
            "nameEn": "BYALA SLATINA",
            "siteId": 7702,
            "address": {
                "countryId": 100,
                "siteId": 7702,
                "siteType": "ГР.",
                "siteName": "БЯЛА СЛАТИНА",
                "postCode": "3200",
                "streetId": 75363,
                "streetType": "УЛ.",
                "streetName": "ЗАХАРИ СТОЯНОВ",
                "streetNo": "6",
                "x": 23.945743,
                "y": 43.474435,
                "fullAddressString": "гр. БЯЛА СЛАТИНА [3200] ул. ЗАХАРИ СТОЯНОВ No 6",
                "siteAddressString": "гр. БЯЛА СЛАТИНА [3200]",
                "localAddressString": "ул. ЗАХАРИ СТОЯНОВ No 6"
            },
            "workingTimeFrom": "08:30",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "08:30",
            "workingTimeHalfTo": "13:30",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 170,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 853,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "13:30",
                    "sameDayDepartureCutoff": "13:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 60,
            "name": "МОНТАНА - СКЛАД",
            "nameEn": "MONTANA - WAREHOUSE",
            "siteId": 48489,
            "address": {
                "countryId": 100,
                "siteId": 48489,
                "siteType": "ГР.",
                "siteName": "МОНТАНА",
                "postCode": "3400",
                "streetId": 58224,
                "streetType": "УЛ.",
                "streetName": "СИРМА ВОЙВОДА",
                "streetNo": "6В",
                "x": 23.236197,
                "y": 43.405203,
                "fullAddressString": "гр. МОНТАНА [3400] ул. СИРМА ВОЙВОДА No 6В",
                "siteAddressString": "гр. МОНТАНА [3400]",
                "localAddressString": "ул. СИРМА ВОЙВОДА No 6В"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:30",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 240,
                "height": 240,
                "depth": 600
            },
            "maxParcelWeight": 1200.0,
            "type": "OFFICE",
            "nearbyOfficeId": 78,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:30",
                    "sameDayDepartureCutoff": "13:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PALLET",
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": true,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 61,
            "name": "БОТЕВГРАД",
            "nameEn": "BOTEVGRAD",
            "siteId": 5815,
            "address": {
                "countryId": 100,
                "siteId": 5815,
                "siteType": "ГР.",
                "siteName": "БОТЕВГРАД",
                "postCode": "2140",
                "streetId": 74619,
                "streetType": "УЛ.",
                "streetName": "ПАТРИАРХ ЕВТИМИЙ",
                "streetNo": "18",
                "x": 23.795804,
                "y": 42.906725,
                "fullAddressString": "гр. БОТЕВГРАД [2140] ул. ПАТРИАРХ ЕВТИМИЙ No 18",
                "siteAddressString": "гр. БОТЕВГРАД [2140]",
                "localAddressString": "ул. ПАТРИАРХ ЕВТИМИЙ No 18"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 170,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 141,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 62,
            "name": "ВИДИН - ОФИС",
            "nameEn": "VIDIN - OFFICE",
            "siteId": 10971,
            "address": {
                "countryId": 100,
                "siteId": 10971,
                "siteType": "ГР.",
                "siteName": "ВИДИН",
                "postCode": "3700",
                "streetId": 36109,
                "streetType": "УЛ.",
                "streetName": "ЕКЗАРХ ЙОСИФ I",
                "streetNo": "13",
                "x": 22.873449,
                "y": 43.98661,
                "fullAddressString": "гр. ВИДИН [3700] ул. ЕКЗАРХ ЙОСИФ I No 13",
                "siteAddressString": "гр. ВИДИН [3700]",
                "localAddressString": "ул. ЕКЗАРХ ЙОСИФ I No 13"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 170,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 818,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 63,
            "name": "ЛОМ",
            "nameEn": "LOM",
            "siteId": 44238,
            "address": {
                "countryId": 100,
                "siteId": 44238,
                "siteType": "ГР.",
                "siteName": "ЛОМ",
                "postCode": "3600",
                "streetId": 70445,
                "streetType": "УЛ.",
                "streetName": "СЛАВЯНСКА",
                "streetNo": "62",
                "x": 23.238042,
                "y": 43.819786,
                "fullAddressString": "гр. ЛОМ [3600] ул. СЛАВЯНСКА No 62",
                "siteAddressString": "гр. ЛОМ [3600]",
                "localAddressString": "ул. СЛАВЯНСКА No 62"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:30",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 170,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 831,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:30",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 64,
            "name": "КОЗЛОДУЙ - СКЛАД",
            "nameEn": "KOZLODUY - WAREHOUSE",
            "siteId": 37798,
            "address": {
                "countryId": 100,
                "siteId": 37798,
                "siteType": "ГР.",
                "siteName": "КОЗЛОДУЙ",
                "postCode": "3320",
                "streetId": 92273,
                "streetType": "УЛ.",
                "streetName": "ЛОМСКА",
                "streetNo": "6",
                "x": 23.720661,
                "y": 43.77942,
                "fullAddressString": "гр. КОЗЛОДУЙ [3320] ул. ЛОМСКА No 6",
                "siteAddressString": "гр. КОЗЛОДУЙ [3320]",
                "localAddressString": "ул. ЛОМСКА No 6"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:30",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "13:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 240,
                "height": 240,
                "depth": 600
            },
            "maxParcelWeight": 1200.0,
            "type": "OFFICE",
            "nearbyOfficeId": 148,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:30",
                    "sameDayDepartureCutoff": "13:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 65,
            "name": "СОФИЯ - СЕРДИКА (ЖК)",
            "nameEn": "SOFIA - SERDIKA (ZHK)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 3403,
                "streetType": "БУЛ.",
                "streetName": "АКАД. ИВАН ЕВСТРАТИЕВ ГЕШОВ",
                "streetNo": "2Е",
                "complexId": 67,
                "complexType": "ЖК",
                "complexName": "СЕРДИКА",
                "blockNo": "1",
                "floorNo": "0",
                "apartmentNo": "002",
                "addressNote": "УЛ. ГЮЕШЕВО №83",
                "x": 23.294106,
                "y": 42.691162,
                "fullAddressString": "гр. СОФИЯ жк СЕРДИКА бул. АКАД. ИВАН ЕВСТРАТИЕВ ГЕШОВ No 2Е бл. 1 ет. 0 ап. 002 УЛ. ГЮЕШЕВО №83",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк СЕРДИКА бул. АКАД. ИВАН ЕВСТРАТИЕВ ГЕШОВ No 2Е бл. 1 ет. 0 ап. 002 УЛ. ГЮЕШЕВО №83"
            },
            "workingTimeFrom": "08:30",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "19:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 150
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 323,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 66,
            "name": "СОФИЯ - МЛАДОСТ 4 (ЖК)",
            "nameEn": "SOFIA - MLADOST 4 (ZHK)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 3695,
                "streetType": "УЛ.",
                "streetName": "БИЗНЕС ПАРК СОФИЯ",
                "complexId": 51,
                "complexType": "ЖК",
                "complexName": "МЛАДОСТ 4",
                "blockNo": "2",
                "x": 23.373724,
                "y": 42.628569,
                "fullAddressString": "гр. СОФИЯ жк МЛАДОСТ 4 ул. БИЗНЕС ПАРК СОФИЯ бл. 2",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк МЛАДОСТ 4 ул. БИЗНЕС ПАРК СОФИЯ бл. 2"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 258,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 71,
            "name": "САМОКОВ – СКЛАД",
            "nameEn": "SAMOKOV - SKLAD",
            "siteId": 65231,
            "address": {
                "countryId": 100,
                "siteId": 65231,
                "siteType": "ГР.",
                "siteName": "САМОКОВ",
                "postCode": "2000",
                "streetId": 71637,
                "streetType": "УЛ.",
                "streetName": "БЪЛГАРИЯ",
                "streetNo": "206",
                "x": 23.564779,
                "y": 42.329995,
                "fullAddressString": "гр. САМОКОВ [2000] ул. БЪЛГАРИЯ No 206",
                "siteAddressString": "гр. САМОКОВ [2000]",
                "localAddressString": "ул. БЪЛГАРИЯ No 206"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 240,
                "height": 220,
                "depth": 600
            },
            "maxParcelWeight": 1200.0,
            "type": "OFFICE",
            "nearbyOfficeId": 401,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PALLET",
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": true,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 73,
            "name": "ЕЛХОВО",
            "nameEn": "ELHOVO",
            "siteId": 27382,
            "address": {
                "countryId": 100,
                "siteId": 27382,
                "siteType": "ГР.",
                "siteName": "ЕЛХОВО",
                "postCode": "8700",
                "streetId": 77800,
                "streetType": "УЛ.",
                "streetName": "ТЪРГОВСКА",
                "streetNo": "69",
                "addressNote": "ПАРТЕР",
                "x": 26.569403,
                "y": 42.17202,
                "fullAddressString": "гр. ЕЛХОВО [8700] ул. ТЪРГОВСКА No 69 ПАРТЕР",
                "siteAddressString": "гр. ЕЛХОВО [8700]",
                "localAddressString": "ул. ТЪРГОВСКА No 69 ПАРТЕР"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 200,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 93,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 75,
            "name": "СОФИЯ - ЧЕРНИ ВРЪХ",
            "nameEn": "SOFIA - CHERNI VRAH",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 134,
                "streetType": "БУЛ.",
                "streetName": "ЧЕРНИ ВРЪХ",
                "streetNo": "67",
                "x": 23.317145,
                "y": 42.652661,
                "fullAddressString": "гр. СОФИЯ бул. ЧЕРНИ ВРЪХ No 67",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "бул. ЧЕРНИ ВРЪХ No 67"
            },
            "workingTimeFrom": "08:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "19:30",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 240,
                "height": 240,
                "depth": 600
            },
            "maxParcelWeight": 1000.0,
            "type": "OFFICE",
            "nearbyOfficeId": 261,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PALLET",
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": true,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 77,
            "name": "ВАРНА - ОФИС",
            "nameEn": "VARNA - OFFICE",
            "siteId": 10135,
            "address": {
                "countryId": 100,
                "siteId": 10135,
                "siteType": "ГР.",
                "siteName": "ВАРНА",
                "postCode": "9000",
                "streetId": 12172,
                "streetType": "УЛ.",
                "streetName": "ГЕН. ПАРЕНСОВ",
                "streetNo": "8",
                "x": 27.923576,
                "y": 43.21076,
                "fullAddressString": "гр. ВАРНА ул. ГЕН. ПАРЕНСОВ No 8",
                "siteAddressString": "гр. ВАРНА",
                "localAddressString": "ул. ГЕН. ПАРЕНСОВ No 8"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 180,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 854,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 78,
            "name": "МОНТАНА - ОФИС",
            "nameEn": "MONTANA - OFFICE",
            "siteId": 48489,
            "address": {
                "countryId": 100,
                "siteId": 48489,
                "siteType": "ГР.",
                "siteName": "МОНТАНА",
                "postCode": "3400",
                "streetId": 58001,
                "streetType": "БУЛ.",
                "streetName": "3-ТИ МАРТ",
                "streetNo": "30",
                "x": 23.222079,
                "y": 43.402375,
                "fullAddressString": "гр. МОНТАНА [3400] бул. 3-ТИ МАРТ No 30",
                "siteAddressString": "гр. МОНТАНА [3400]",
                "localAddressString": "бул. 3-ТИ МАРТ No 30"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:30",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 170,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 330,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:30",
                    "sameDayDepartureCutoff": "13:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 79,
            "name": "ПЛОВДИВ - ОФИС",
            "nameEn": "PLOVDIV - OFFICE",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "streetId": 10593,
                "streetType": "УЛ.",
                "streetName": "КОНСТАНТИН СТОИЛОВ",
                "streetNo": "19",
                "x": 24.749681,
                "y": 42.148486,
                "fullAddressString": "гр. ПЛОВДИВ ул. КОНСТАНТИН СТОИЛОВ No 19",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "ул. КОНСТАНТИН СТОИЛОВ No 19"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 160,
                "depth": 200
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 435,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 80,
            "name": "ВЕЛИКО ТЪРНОВО - КОЗЛОДУЙ",
            "nameEn": "VELIKO TARNOVO - KOZLODUY",
            "siteId": 10447,
            "address": {
                "countryId": 100,
                "siteId": 10447,
                "siteType": "ГР.",
                "siteName": "ВЕЛИКО ТЪРНОВО",
                "postCode": "5000",
                "streetId": 34116,
                "streetType": "УЛ.",
                "streetName": "КОЗЛОДУЙ",
                "streetNo": "4",
                "x": 25.629105,
                "y": 43.075547,
                "fullAddressString": "гр. ВЕЛИКО ТЪРНОВО ул. КОЗЛОДУЙ No 4",
                "siteAddressString": "гр. ВЕЛИКО ТЪРНОВО",
                "localAddressString": "ул. КОЗЛОДУЙ No 4"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 170,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 804,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 82,
            "name": "ХАСКОВО - ОФИС",
            "nameEn": "HASKOVO - OFFICE",
            "siteId": 77195,
            "address": {
                "countryId": 100,
                "siteId": 77195,
                "siteType": "ГР.",
                "siteName": "ХАСКОВО",
                "postCode": "6300",
                "streetId": 28082,
                "streetType": "УЛ.",
                "streetName": "ВАСИЛ ДРУМЕВ",
                "streetNo": "18",
                "x": 25.554575,
                "y": 41.932758,
                "fullAddressString": "гр. ХАСКОВО [6300] ул. ВАСИЛ ДРУМЕВ No 18",
                "siteAddressString": "гр. ХАСКОВО [6300]",
                "localAddressString": "ул. ВАСИЛ ДРУМЕВ No 18"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 200,
                "depth": 350
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 882,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 83,
            "name": "НЕСЕБЪР",
            "nameEn": "NESEBAR",
            "siteId": 51500,
            "address": {
                "countryId": 100,
                "siteId": 51500,
                "siteType": "ГР.",
                "siteName": "НЕСЕБЪР",
                "postCode": "8230",
                "streetId": 70960,
                "streetType": "УЛ.",
                "streetName": "ЕДЕЛВАЙС",
                "streetNo": "44",
                "x": 27.714031,
                "y": 42.658966,
                "fullAddressString": "гр. НЕСЕБЪР [8230] ул. ЕДЕЛВАЙС No 44",
                "siteAddressString": "гр. НЕСЕБЪР [8230]",
                "localAddressString": "ул. ЕДЕЛВАЙС No 44"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 170,
                "depth": 350
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 216,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 84,
            "name": "СТАРА ЗАГОРА - ОФИС",
            "nameEn": "STARA ZAGORA - OFFICE",
            "siteId": 68850,
            "address": {
                "countryId": 100,
                "siteId": 68850,
                "siteType": "ГР.",
                "siteName": "СТАРА ЗАГОРА",
                "postCode": "6000",
                "streetId": 18339,
                "streetType": "УЛ.",
                "streetName": "ХРИСТО БОТЕВ",
                "streetNo": "100",
                "x": 25.631097,
                "y": 42.422289,
                "fullAddressString": "гр. СТАРА ЗАГОРА ул. ХРИСТО БОТЕВ No 100",
                "siteAddressString": "гр. СТАРА ЗАГОРА",
                "localAddressString": "ул. ХРИСТО БОТЕВ No 100"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 200,
                "depth": 350
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 838,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 86,
            "name": "ШУМЕН - ОФИС",
            "nameEn": "SHUMEN - OFFICE",
            "siteId": 83510,
            "address": {
                "countryId": 100,
                "siteId": 83510,
                "siteType": "ГР.",
                "siteName": "ШУМЕН",
                "postCode": "9700",
                "streetId": 24244,
                "streetType": "БУЛ.",
                "streetName": "СИМЕОН ВЕЛИКИ",
                "streetNo": "46В",
                "addressNote": "МАГАЗИН 2-3",
                "x": 26.927367,
                "y": 43.273718,
                "fullAddressString": "гр. ШУМЕН [9700] бул. СИМЕОН ВЕЛИКИ No 46В МАГАЗИН 2-3",
                "siteAddressString": "гр. ШУМЕН [9700]",
                "localAddressString": "бул. СИМЕОН ВЕЛИКИ No 46В МАГАЗИН 2-3"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 170,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 253,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 88,
            "name": "ТЕТЕВЕН",
            "nameEn": "TETEVEN",
            "siteId": 72343,
            "address": {
                "countryId": 100,
                "siteId": 72343,
                "siteType": "ГР.",
                "siteName": "ТЕТЕВЕН",
                "postCode": "5700",
                "streetId": 94879,
                "streetType": "УЛ.",
                "streetName": "ПЕНКА МИХАЙЛОВА",
                "streetNo": "83",
                "x": 24.25551,
                "y": 42.91786,
                "fullAddressString": "гр. ТЕТЕВЕН [5700] ул. ПЕНКА МИХАЙЛОВА No 83",
                "siteAddressString": "гр. ТЕТЕВЕН [5700]",
                "localAddressString": "ул. ПЕНКА МИХАЙЛОВА No 83"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "12:30",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "12:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 170,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 141,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "12:30",
                    "sameDayDepartureCutoff": "12:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 91,
            "name": "РУСЕ - ЦЕНТЪР, ОПЕРА",
            "nameEn": "RUSE - TSENTUR, OPERA",
            "siteId": 63427,
            "address": {
                "countryId": 100,
                "siteId": 63427,
                "siteType": "ГР.",
                "siteName": "РУСЕ",
                "postCode": "7000",
                "streetId": 16372,
                "streetType": "УЛ.",
                "streetName": "МУТКУРОВА",
                "streetNo": "36",
                "x": 25.957646,
                "y": 43.847172,
                "fullAddressString": "гр. РУСЕ ул. МУТКУРОВА No 36",
                "siteAddressString": "гр. РУСЕ",
                "localAddressString": "ул. МУТКУРОВА No 36"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 170,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 202,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 92,
            "name": "СЛИВЕН - ЛЪДЖЕНСКА",
            "nameEn": "SLIVEN - LADZHENSKA",
            "siteId": 67338,
            "address": {
                "countryId": 100,
                "siteId": 67338,
                "siteType": "ГР.",
                "siteName": "СЛИВЕН",
                "postCode": "8800",
                "streetId": 44269,
                "streetType": "УЛ.",
                "streetName": "ЛЪДЖЕНСКА",
                "streetNo": "11",
                "x": 26.317801,
                "y": 42.677898,
                "fullAddressString": "гр. СЛИВЕН [8800] ул. ЛЪДЖЕНСКА No 11",
                "siteAddressString": "гр. СЛИВЕН [8800]",
                "localAddressString": "ул. ЛЪДЖЕНСКА No 11"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 200,
                "depth": 350
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 7,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 93,
            "name": "ЯМБОЛ - СКЛАД",
            "nameEn": "YAMBOL - WAREHOUSE",
            "siteId": 87374,
            "address": {
                "countryId": 100,
                "siteId": 87374,
                "siteType": "ГР.",
                "siteName": "ЯМБОЛ",
                "postCode": "8600",
                "streetId": 102506,
                "streetType": "БУЛ.",
                "streetName": "ЕВРОПА",
                "streetNo": "45",
                "x": 26.475826,
                "y": 42.497255,
                "fullAddressString": "гр. ЯМБОЛ [8600] бул. ЕВРОПА No 45",
                "siteAddressString": "гр. ЯМБОЛ [8600]",
                "localAddressString": "бул. ЕВРОПА No 45"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 240,
                "height": 240,
                "depth": 600
            },
            "maxParcelWeight": 1200.0,
            "type": "OFFICE",
            "nearbyOfficeId": 55,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PALLET",
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": true,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 94,
            "name": "ПЛОВДИВ - РОДОПИ",
            "nameEn": "PLOVDIV - RODOPI",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "streetId": 11402,
                "streetType": "УЛ.",
                "streetName": "НАЙЧО ЦАНОВ",
                "addressNote": "НАДЛЕЗ РОДОПИ",
                "x": 24.757661,
                "y": 42.133065,
                "fullAddressString": "гр. ПЛОВДИВ ул. НАЙЧО ЦАНОВ НАДЛЕЗ РОДОПИ",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "ул. НАЙЧО ЦАНОВ НАДЛЕЗ РОДОПИ"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 200,
                "depth": 200
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 1,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 98,
            "name": "СОФИЯ - МЛАДОСТ 1 (ЖК)",
            "nameEn": "SOFIA - MLADOST 1 (ZHK)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "complexId": 47,
                "complexType": "ЖК",
                "complexName": "МЛАДОСТ 1",
                "blockNo": "12А",
                "addressNote": "МАГАЗИН 14",
                "x": 23.376336,
                "y": 42.65758,
                "fullAddressString": "гр. СОФИЯ жк МЛАДОСТ 1 бл. 12А МАГАЗИН 14",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк МЛАДОСТ 1 бл. 12А МАГАЗИН 14"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "19:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 240,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 106,
            "name": "ПЛЕВЕН - ОФИС",
            "nameEn": "PLEVEN - OFFICE",
            "siteId": 56722,
            "address": {
                "countryId": 100,
                "siteId": 56722,
                "siteType": "ГР.",
                "siteName": "ПЛЕВЕН",
                "postCode": "5800",
                "streetId": 20428,
                "streetType": "УЛ.",
                "streetName": "ЦАР БОРИС III",
                "streetNo": "74",
                "x": 24.614982,
                "y": 43.416284,
                "fullAddressString": "гр. ПЛЕВЕН ул. ЦАР БОРИС III No 74",
                "siteAddressString": "гр. ПЛЕВЕН",
                "localAddressString": "ул. ЦАР БОРИС III No 74"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 170,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 12,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 108,
            "name": "БЕЛЕНЕ",
            "nameEn": "BELENE",
            "siteId": 3366,
            "address": {
                "countryId": 100,
                "siteId": 3366,
                "siteType": "ГР.",
                "siteName": "БЕЛЕНЕ",
                "postCode": "5930",
                "streetId": 73766,
                "streetType": "ПЛ.",
                "streetName": "БЪЛГАРИЯ",
                "streetNo": "1А",
                "addressNote": "ОФИС ЦЕНТЪР КРИСТИНИ",
                "x": 25.123898,
                "y": 43.643313,
                "fullAddressString": "гр. БЕЛЕНЕ [5930] пл. БЪЛГАРИЯ No 1А ОФИС ЦЕНТЪР КРИСТИНИ",
                "siteAddressString": "гр. БЕЛЕНЕ [5930]",
                "localAddressString": "пл. БЪЛГАРИЯ No 1А ОФИС ЦЕНТЪР КРИСТИНИ"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "16:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 170,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 250,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 109,
            "name": "ПЛОВДИВ - ГЕОРГИ ТРИНГОВ",
            "nameEn": "PLOVDIV - GEORGI TRINGOV",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "streetId": 87513,
                "streetType": "УЛ.",
                "streetName": "ГЕОРГИ ТРИНГОВ",
                "x": 24.750121,
                "y": 42.167825,
                "fullAddressString": "гр. ПЛОВДИВ ул. ГЕОРГИ ТРИНГОВ",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "ул. ГЕОРГИ ТРИНГОВ"
            },
            "workingTimeFrom": "08:30",
            "workingTimeTo": "18:30",
            "workingTimeHalfFrom": "08:30",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:30",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 240,
                "height": 240,
                "depth": 600
            },
            "maxParcelWeight": 1200.0,
            "type": "OFFICE",
            "nearbyOfficeId": 338,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PALLET",
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": true,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 110,
            "name": "СОФИЯ - ЦАР СИМЕОН",
            "nameEn": "SOFIA - TSAR SIMEON",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 3211,
                "streetType": "УЛ.",
                "streetName": "ЦАР СИМЕОН",
                "streetNo": "172",
                "addressNote": "СГР. НА ПЕТРОЛ",
                "x": 23.312367,
                "y": 42.702999,
                "fullAddressString": "гр. СОФИЯ ул. ЦАР СИМЕОН No 172 СГР. НА ПЕТРОЛ",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. ЦАР СИМЕОН No 172 СГР. НА ПЕТРОЛ"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "19:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 150
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 870,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 111,
            "name": "ВАРНА - КАЙСИЕВА ГРАДИНА (ЖК)",
            "nameEn": "VARNA - KAYSIEVA GRADINA (ZHK)",
            "siteId": 10135,
            "address": {
                "countryId": 100,
                "siteId": 10135,
                "siteType": "ГР.",
                "siteName": "ВАРНА",
                "postCode": "9000",
                "complexId": 1000072,
                "complexType": "ЖК",
                "complexName": "ВЛАДИСЛАВ ВАРНЕНЧИК II",
                "blockNo": "259",
                "floorNo": "1",
                "addressNote": "ОБЕКТ 3",
                "x": 27.86386,
                "y": 43.236856,
                "fullAddressString": "гр. ВАРНА жк ВЛАДИСЛАВ ВАРНЕНЧИК II бл. 259 ет. 1 ОБЕКТ 3",
                "siteAddressString": "гр. ВАРНА",
                "localAddressString": "жк ВЛАДИСЛАВ ВАРНЕНЧИК II бл. 259 ет. 1 ОБЕКТ 3"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 180,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 241,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 112,
            "name": "СОФИЯ - ИЛИЯНЦИ",
            "nameEn": "SOFIA - ILIYANTSI",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 2580,
                "streetType": "УЛ.",
                "streetName": "ПЕТЪР ПАНАЙОТОВ",
                "streetNo": "6",
                "addressNote": "БАЗАР ФЕНИКС, ДО БАЗАР ИЛИЯНЦИ",
                "x": 23.312508,
                "y": 42.739933,
                "fullAddressString": "гр. СОФИЯ ул. ПЕТЪР ПАНАЙОТОВ No 6 БАЗАР ФЕНИКС, ДО БАЗАР ИЛИЯНЦИ",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. ПЕТЪР ПАНАЙОТОВ No 6 БАЗАР ФЕНИКС, ДО БАЗАР ИЛИЯНЦИ"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 150
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 380,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-08-20",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 114,
            "name": "РАЗЛОГ",
            "nameEn": "RAZLOG",
            "siteId": 61813,
            "address": {
                "countryId": 100,
                "siteId": 61813,
                "siteType": "ГР.",
                "siteName": "РАЗЛОГ",
                "postCode": "2760",
                "streetId": 93990,
                "streetType": "УЛ.",
                "streetName": "АЛЕКСАНДЪР СТАМБОЛИЙСКИ",
                "streetNo": "86",
                "x": 23.460477,
                "y": 41.881931,
                "fullAddressString": "гр. РАЗЛОГ [2760] ул. АЛЕКСАНДЪР СТАМБОЛИЙСКИ No 86",
                "siteAddressString": "гр. РАЗЛОГ [2760]",
                "localAddressString": "ул. АЛЕКСАНДЪР СТАМБОЛИЙСКИ No 86"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "12:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 120,
                "height": 180,
                "depth": 400
            },
            "maxParcelWeight": 600.0,
            "type": "OFFICE",
            "nearbyOfficeId": 416,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "12:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PALLET",
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": true,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 115,
            "name": "БУРГАС - ИВАН БОГОРОВ",
            "nameEn": "BURGAS - IVAN BOGOROV",
            "siteId": 7079,
            "address": {
                "countryId": 100,
                "siteId": 7079,
                "siteType": "ГР.",
                "siteName": "БУРГАС",
                "postCode": "8000",
                "streetId": 14124,
                "streetType": "УЛ.",
                "streetName": "ИВАН БОГОРОВ",
                "streetNo": "20",
                "x": 27.47457,
                "y": 42.501487,
                "fullAddressString": "гр. БУРГАС ул. ИВАН БОГОРОВ No 20",
                "siteAddressString": "гр. БУРГАС",
                "localAddressString": "ул. ИВАН БОГОРОВ No 20"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 170,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 273,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 116,
            "name": "САНДАНСКИ - СКЛАД",
            "nameEn": "SANDANSKI - WAREHOUSE",
            "siteId": 65334,
            "address": {
                "countryId": 100,
                "siteId": 65334,
                "siteType": "ГР.",
                "siteName": "САНДАНСКИ",
                "postCode": "2800",
                "addressNote": "ИЗХОДА НА ГРАДА, СРЕЩУ М-Н ТЕХНОПОЛИС",
                "x": 23.276725,
                "y": 41.505042,
                "fullAddressString": "гр. САНДАНСКИ [2800] ИЗХОДА НА ГРАДА, СРЕЩУ М-Н ТЕХНОПОЛИС",
                "siteAddressString": "гр. САНДАНСКИ [2800]",
                "localAddressString": "ИЗХОДА НА ГРАДА, СРЕЩУ М-Н ТЕХНОПОЛИС"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:15",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 200,
                "height": 200,
                "depth": 600
            },
            "maxParcelWeight": 1200.0,
            "type": "OFFICE",
            "nearbyOfficeId": 426,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:15",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:15",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:15",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:15",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:15",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:15",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:15",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:15",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PALLET",
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": true,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 117,
            "name": "ВАРНА - ПРИМОРСКИ",
            "nameEn": "VARNA - PRIMORSKI",
            "siteId": 10135,
            "address": {
                "countryId": 100,
                "siteId": 10135,
                "siteType": "ГР.",
                "siteName": "ВАРНА",
                "postCode": "9000",
                "streetId": 12613,
                "streetType": "БУЛ.",
                "streetName": "ПРИМОРСКИ",
                "streetNo": "27",
                "x": 27.919604,
                "y": 43.198068,
                "fullAddressString": "гр. ВАРНА бул. ПРИМОРСКИ No 27",
                "siteAddressString": "гр. ВАРНА",
                "localAddressString": "бул. ПРИМОРСКИ No 27"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 180,
                "depth": 220
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 784,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 118,
            "name": "ДУПНИЦА - СКЛАД",
            "nameEn": "DUPNITSA - WAREHOUSE",
            "siteId": 68789,
            "address": {
                "countryId": 100,
                "siteId": 68789,
                "siteType": "ГР.",
                "siteName": "ДУПНИЦА",
                "postCode": "2600",
                "streetId": 72638,
                "streetType": "УЛ.",
                "streetName": "ПАТРИАРХ ЕВТИМИЙ",
                "streetNo": "151",
                "x": 23.13237,
                "y": 42.25405,
                "fullAddressString": "гр. ДУПНИЦА [2600] ул. ПАТРИАРХ ЕВТИМИЙ No 151",
                "siteAddressString": "гр. ДУПНИЦА [2600]",
                "localAddressString": "ул. ПАТРИАРХ ЕВТИМИЙ No 151"
            },
            "workingTimeFrom": "08:30",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 240,
                "height": 200,
                "depth": 600
            },
            "maxParcelWeight": 1200.0,
            "type": "OFFICE",
            "nearbyOfficeId": 449,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2000-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PALLET",
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": true,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": false
        },
        {
            "id": 120,
            "name": "РАЗГРАД - СКЛАД",
            "nameEn": "RAZGRAD - WAREHOUSE",
            "siteId": 61710,
            "address": {
                "countryId": 100,
                "siteId": 61710,
                "siteType": "ГР.",
                "siteName": "РАЗГРАД",
                "postCode": "7200",
                "streetId": 113172,
                "streetType": "УЛ.",
                "streetName": "МЕБЕЛНА",
                "streetNo": "1",
                "x": 26.504523,
                "y": 43.529438,
                "fullAddressString": "гр. РАЗГРАД [7200] ул. МЕБЕЛНА No 1",
                "siteAddressString": "гр. РАЗГРАД [7200]",
                "localAddressString": "ул. МЕБЕЛНА No 1"
            },
            "workingTimeFrom": "08:30",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "08:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 240,
                "height": 220,
                "depth": 400
            },
            "maxParcelWeight": 1000.0,
            "type": "OFFICE",
            "nearbyOfficeId": 9,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2012-04-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PALLET",
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": true,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 122,
            "name": "СТАРА ЗАГОРА - ЦАР СИМЕОН ВЕЛИКИ",
            "nameEn": "STARA ZAGORA - TSAR SIMEON VELIKI",
            "siteId": 68850,
            "address": {
                "countryId": 100,
                "siteId": 68850,
                "siteType": "ГР.",
                "siteName": "СТАРА ЗАГОРА",
                "postCode": "6000",
                "streetId": 18013,
                "streetType": "БУЛ.",
                "streetName": "ЦАР СИМЕОН ВЕЛИКИ",
                "streetNo": "28",
                "x": 25.614889,
                "y": 42.421503,
                "fullAddressString": "гр. СТАРА ЗАГОРА бул. ЦАР СИМЕОН ВЕЛИКИ No 28",
                "siteAddressString": "гр. СТАРА ЗАГОРА",
                "localAddressString": "бул. ЦАР СИМЕОН ВЕЛИКИ No 28"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 200,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 440,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2012-07-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 123,
            "name": "СЛИВЕН - ЦАР ОСВОБОДИТЕЛ",
            "nameEn": "SLIVEN - TSAR OSVOBODITEL",
            "siteId": 67338,
            "address": {
                "countryId": 100,
                "siteId": 67338,
                "siteType": "ГР.",
                "siteName": "СЛИВЕН",
                "postCode": "8800",
                "streetId": 44017,
                "streetType": "БУЛ.",
                "streetName": "ЦАР ОСВОБОДИТЕЛ",
                "streetNo": "28",
                "x": 26.319286,
                "y": 42.680192,
                "fullAddressString": "гр. СЛИВЕН [8800] бул. ЦАР ОСВОБОДИТЕЛ No 28",
                "siteAddressString": "гр. СЛИВЕН [8800]",
                "localAddressString": "бул. ЦАР ОСВОБОДИТЕЛ No 28"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 160,
                "depth": 200
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 92,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2013-01-02",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 127,
            "name": "ПЕРНИК - СКЛАД",
            "nameEn": "PERNIK - WAREHOUSE",
            "siteId": 55871,
            "address": {
                "countryId": 100,
                "siteId": 55871,
                "siteType": "ГР.",
                "siteName": "ПЕРНИК",
                "postCode": "2300",
                "streetId": 50550,
                "streetType": "УЛ.",
                "streetName": "СОФИЙСКО ШОСЕ",
                "streetNo": "15",
                "x": 23.03398,
                "y": 42.60205,
                "fullAddressString": "гр. ПЕРНИК [2300] ул. СОФИЙСКО ШОСЕ No 15",
                "siteAddressString": "гр. ПЕРНИК [2300]",
                "localAddressString": "ул. СОФИЙСКО ШОСЕ No 15"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:30",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 240,
                "height": 200,
                "depth": 600
            },
            "maxParcelWeight": 1200.0,
            "type": "OFFICE",
            "nearbyOfficeId": 35,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2014-02-10",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PALLET",
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": true,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 128,
            "name": "ДОБРИЧ - СКЛАД",
            "nameEn": "DOBRICH - WAREHOUSE",
            "siteId": 72624,
            "address": {
                "countryId": 100,
                "siteId": 72624,
                "siteType": "ГР.",
                "siteName": "ДОБРИЧ",
                "postCode": "9300",
                "streetId": 97956,
                "streetType": "",
                "streetName": "ВАРНЕНСКИ ПЪТ",
                "streetNo": "47",
                "x": 27.819617,
                "y": 43.535206,
                "fullAddressString": "гр. ДОБРИЧ [9300] ВАРНЕНСКИ ПЪТ No 47",
                "siteAddressString": "гр. ДОБРИЧ [9300]",
                "localAddressString": "ВАРНЕНСКИ ПЪТ No 47"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:30",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 200,
                "height": 200,
                "depth": 600
            },
            "maxParcelWeight": 1200.0,
            "type": "OFFICE",
            "nearbyOfficeId": 245,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:30",
                    "sameDayDepartureCutoff": "13:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2014-02-12",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PALLET",
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": true,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 129,
            "name": "ВРАЦА - ДЪБНИКА (ЖК)",
            "nameEn": "VRATSA - DABNIKA (ZHK)",
            "siteId": 12259,
            "address": {
                "countryId": 100,
                "siteId": 12259,
                "siteType": "ГР.",
                "siteName": "ВРАЦА",
                "postCode": "3000",
                "complexId": 1901,
                "complexType": "ЖК",
                "complexName": "ДЪБНИКА",
                "addressNote": "ПАЗАРЧЕТО",
                "x": 23.546059,
                "y": 43.222097,
                "fullAddressString": "гр. ВРАЦА [3000] жк ДЪБНИКА ПАЗАРЧЕТО",
                "siteAddressString": "гр. ВРАЦА [3000]",
                "localAddressString": "жк ДЪБНИКА ПАЗАРЧЕТО"
            },
            "workingTimeFrom": "08:30",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "08:30",
            "workingTimeHalfTo": "13:30",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 170,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 20,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "13:30",
                    "sameDayDepartureCutoff": "13:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2014-05-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 130,
            "name": "ГОЦЕ ДЕЛЧЕВ - СКЛАД",
            "nameEn": "GOTSE DELCHEV - WAREHOUSE",
            "siteId": 17395,
            "address": {
                "countryId": 100,
                "siteId": 17395,
                "siteType": "ГР.",
                "siteName": "ГОЦЕ ДЕЛЧЕВ",
                "postCode": "2900",
                "streetId": 68082,
                "streetType": "УЛ.",
                "streetName": "ПАНАИРСКА ЛИВАДА",
                "addressNote": "ПРOДЪЛЖЕНИЕТО НА УЛИЦАТА",
                "x": 23.744876,
                "y": 41.581019,
                "fullAddressString": "гр. ГОЦЕ ДЕЛЧЕВ [2900] ул. ПАНАИРСКА ЛИВАДА ПРOДЪЛЖЕНИЕТО НА УЛИЦАТА",
                "siteAddressString": "гр. ГОЦЕ ДЕЛЧЕВ [2900]",
                "localAddressString": "ул. ПАНАИРСКА ЛИВАДА ПРOДЪЛЖЕНИЕТО НА УЛИЦАТА"
            },
            "workingTimeFrom": "08:00",
            "workingTimeTo": "17:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "12:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "16:15",
            "sameDayDepartureCutoffHalf": "11:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 120,
                "height": 200,
                "depth": 600
            },
            "maxParcelWeight": 1200.0,
            "type": "OFFICE",
            "nearbyOfficeId": 31,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "16:15",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "16:15",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "16:15",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "12:00",
                    "sameDayDepartureCutoff": "11:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "16:15",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "16:15",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "16:15",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "16:15",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "16:15",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2014-05-12",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PALLET",
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": true,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 132,
            "name": "ТЪРГОВИЩЕ - РАКОВСКА",
            "nameEn": "TARGOVISHTE - RAKOVSKA",
            "siteId": 73626,
            "address": {
                "countryId": 100,
                "siteId": 73626,
                "siteType": "ГР.",
                "siteName": "ТЪРГОВИЩЕ",
                "postCode": "7700",
                "streetId": 60075,
                "streetType": "УЛ.",
                "streetName": "ГЕОРГИ САВА РАКОВСКИ",
                "streetNo": "17",
                "x": 26.569802,
                "y": 43.243225,
                "fullAddressString": "гр. ТЪРГОВИЩЕ [7700] ул. ГЕОРГИ САВА РАКОВСКИ No 17",
                "siteAddressString": "гр. ТЪРГОВИЩЕ [7700]",
                "localAddressString": "ул. ГЕОРГИ САВА РАКОВСКИ No 17"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 170,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 16,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2014-07-28",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 134,
            "name": "СОФИЯ - СЛАТИНА (Ж.К.), ДО ПАЗАРА",
            "nameEn": "SOFIA - SLATINA (ZH.K.), DO PAZARA",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 3235,
                "streetType": "УЛ.",
                "streetName": "ЦИКЛАМА",
                "streetNo": "8",
                "complexId": 69,
                "complexType": "ЖК",
                "complexName": "СЛАТИНА",
                "addressNote": "ПАЗАР СЛАТИНА",
                "x": 23.372566,
                "y": 42.681861,
                "fullAddressString": "гр. СОФИЯ жк СЛАТИНА ул. ЦИКЛАМА No 8 ПАЗАР СЛАТИНА",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк СЛАТИНА ул. ЦИКЛАМА No 8 ПАЗАР СЛАТИНА"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 255,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2014-08-30",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 135,
            "name": "КАЗАНЛЪК - ПАРК РОЗАРИУМ",
            "nameEn": "KAZANLAK - PARK ROZARIUM",
            "siteId": 35167,
            "address": {
                "countryId": 100,
                "siteId": 35167,
                "siteType": "ГР.",
                "siteName": "КАЗАНЛЪК",
                "postCode": "6100",
                "streetId": 91914,
                "streetType": "УЛ.",
                "streetName": "ГЕН. СТОЛЕТОВ",
                "streetNo": "1",
                "x": 25.38537,
                "y": 42.624072,
                "fullAddressString": "гр. КАЗАНЛЪК [6100] ул. ГЕН. СТОЛЕТОВ No 1",
                "siteAddressString": "гр. КАЗАНЛЪК [6100]",
                "localAddressString": "ул. ГЕН. СТОЛЕТОВ No 1"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 21,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2014-09-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 137,
            "name": "БЛАГОЕВГРАД - ГРИГОР ПЪРЛИЧЕВ",
            "nameEn": "BLAGOEVGRAD - GRIGOR PARLICHEV",
            "siteId": 4279,
            "address": {
                "countryId": 100,
                "siteId": 4279,
                "siteType": "ГР.",
                "siteName": "БЛАГОЕВГРАД",
                "postCode": "2700",
                "streetId": 30078,
                "streetType": "УЛ.",
                "streetName": "ГРИГОР ПЪРЛИЧЕВ",
                "streetNo": "2",
                "addressNote": "СРЕЩУ КЛИНИКА РУБИДЕНТ ДО СТАДИОНА",
                "x": 23.09714,
                "y": 42.026142,
                "fullAddressString": "гр. БЛАГОЕВГРАД ул. ГРИГОР ПЪРЛИЧЕВ No 2 СРЕЩУ КЛИНИКА РУБИДЕНТ ДО СТАДИОНА",
                "siteAddressString": "гр. БЛАГОЕВГРАД",
                "localAddressString": "ул. ГРИГОР ПЪРЛИЧЕВ No 2 СРЕЩУ КЛИНИКА РУБИДЕНТ ДО СТАДИОНА"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "12:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 802,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "12:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2015-01-19",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 138,
            "name": "СОФИЯ - ЛЮЛИН 8 (ЖК)",
            "nameEn": "SOFIA - LYULIN 8 (ZHK)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 339,
                "streetType": "УЛ.",
                "streetName": "3011",
                "streetNo": "9",
                "complexId": 45,
                "complexType": "ЖК",
                "complexName": "ЛЮЛИН 8",
                "entranceNo": "А",
                "x": 23.256577,
                "y": 42.720982,
                "fullAddressString": "гр. СОФИЯ жк ЛЮЛИН 8 ул. 3011 No 9 вх. А",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк ЛЮЛИН 8 ул. 3011 No 9 вх. А"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 353,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2015-05-04",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 139,
            "name": "ПЕТРИЧ - СКЛАД",
            "nameEn": "PETRICH - WAREHOUSE",
            "siteId": 56126,
            "address": {
                "countryId": 100,
                "siteId": 56126,
                "siteType": "ГР.",
                "siteName": "ПЕТРИЧ",
                "postCode": "2850",
                "streetId": 96320,
                "streetType": "УЛ.",
                "streetName": "ИВАН АПОСТОЛОВ",
                "streetNo": "16",
                "addressNote": "БАЗА ЮЖЕН ПЛОД ПРЕДИ ТОПЛИВО",
                "x": 23.206805,
                "y": 41.407708,
                "fullAddressString": "гр. ПЕТРИЧ [2850] ул. ИВАН АПОСТОЛОВ No 16 БАЗА ЮЖЕН ПЛОД ПРЕДИ ТОПЛИВО",
                "siteAddressString": "гр. ПЕТРИЧ [2850]",
                "localAddressString": "ул. ИВАН АПОСТОЛОВ No 16 БАЗА ЮЖЕН ПЛОД ПРЕДИ ТОПЛИВО"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "12:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "16:15",
            "sameDayDepartureCutoffHalf": "12:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 120,
                "height": 220,
                "depth": 600
            },
            "maxParcelWeight": 1200.0,
            "type": "OFFICE",
            "nearbyOfficeId": 27,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "16:15",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "16:15",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "16:15",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "12:00",
                    "sameDayDepartureCutoff": "12:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "16:15",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "16:15",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "16:15",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "16:15",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "16:15",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2015-05-31",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PALLET",
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": true,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 141,
            "name": "БОТЕВГРАД - СКЛАД",
            "nameEn": "BOTEVGRAD - WAREHOUSE",
            "siteId": 5815,
            "address": {
                "countryId": 100,
                "siteId": 5815,
                "siteType": "ГР.",
                "siteName": "БОТЕВГРАД",
                "postCode": "2140",
                "streetId": 74646,
                "streetType": "УЛ.",
                "streetName": "ЦАР ИВАН ШИШМАН",
                "streetNo": "42",
                "complexId": 15797,
                "complexType": "ЖК",
                "complexName": "ВАСИЛ ЛЕВСКИ",
                "addressNote": "СКЛАДОВА БАЗА ЛЪЧ ЛАЗУР",
                "x": 23.806504,
                "y": 42.906105,
                "fullAddressString": "гр. БОТЕВГРАД [2140] жк ВАСИЛ ЛЕВСКИ ул. ЦАР ИВАН ШИШМАН No 42 СКЛАДОВА БАЗА ЛЪЧ ЛАЗУР",
                "siteAddressString": "гр. БОТЕВГРАД [2140]",
                "localAddressString": "жк ВАСИЛ ЛЕВСКИ ул. ЦАР ИВАН ШИШМАН No 42 СКЛАДОВА БАЗА ЛЪЧ ЛАЗУР"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:30",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 240,
                "height": 240,
                "depth": 600
            },
            "maxParcelWeight": 1200.0,
            "type": "OFFICE",
            "nearbyOfficeId": 61,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:30",
                    "sameDayDepartureCutoff": "13:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2015-09-16",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PALLET",
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": true,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 142,
            "name": "БОЖУРИЩЕ",
            "nameEn": "BOZHURISHTE",
            "siteId": 5027,
            "address": {
                "countryId": 100,
                "siteId": 5027,
                "siteType": "ГР.",
                "siteName": "БОЖУРИЩЕ",
                "postCode": "2227",
                "streetId": 1000226,
                "streetType": "УЛ.",
                "streetName": "ВАЛЕРИ ПЕТРОВ",
                "streetNo": "5",
                "x": 23.203478,
                "y": 42.74604,
                "fullAddressString": "гр. БОЖУРИЩЕ [2227] ул. ВАЛЕРИ ПЕТРОВ No 5",
                "siteAddressString": "гр. БОЖУРИЩЕ [2227]",
                "localAddressString": "ул. ВАЛЕРИ ПЕТРОВ No 5"
            },
            "workingTimeFrom": "08:30",
            "workingTimeTo": "20:00",
            "workingTimeHalfFrom": "08:30",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "20:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "22:00",
            "maxParcelDimensions": {
                "width": 240,
                "height": 220,
                "depth": 600
            },
            "maxParcelWeight": 1200.0,
            "type": "OFFICE",
            "nearbyOfficeId": 257,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "20:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "20:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "20:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "20:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "20:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "20:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "20:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "20:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2015-10-30",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PALLET",
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": true,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 144,
            "name": "СТАРА ЗАГОРА - СВ. ПАТРИАРХ ЕВТИМИЙ",
            "nameEn": "STARA ZAGORA - SV. PATRIARH EVTIMIY",
            "siteId": 68850,
            "address": {
                "countryId": 100,
                "siteId": 68850,
                "siteType": "ГР.",
                "siteName": "СТАРА ЗАГОРА",
                "postCode": "6000",
                "streetId": 18011,
                "streetType": "БУЛ.",
                "streetName": "СВ. ПАТРИАРХ ЕВТИМИЙ",
                "streetNo": "46",
                "addressNote": "БИВШ СКЛАД СИКО",
                "x": 25.642262,
                "y": 42.405904,
                "fullAddressString": "гр. СТАРА ЗАГОРА бул. СВ. ПАТРИАРХ ЕВТИМИЙ No 46 БИВШ СКЛАД СИКО",
                "siteAddressString": "гр. СТАРА ЗАГОРА",
                "localAddressString": "бул. СВ. ПАТРИАРХ ЕВТИМИЙ No 46 БИВШ СКЛАД СИКО"
            },
            "workingTimeFrom": "08:30",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "08:30",
            "workingTimeHalfTo": "14:30",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 240,
                "height": 240,
                "depth": 600
            },
            "maxParcelWeight": 1200.0,
            "type": "OFFICE",
            "nearbyOfficeId": 5,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "14:30",
                    "sameDayDepartureCutoff": "14:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2015-11-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PALLET",
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": true,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 145,
            "name": "БУРГАС - ЯНКО КОМИТОВ",
            "nameEn": "BURGAS - YANKO KOMITOV",
            "siteId": 7079,
            "address": {
                "countryId": 100,
                "siteId": 7079,
                "siteType": "ГР.",
                "siteName": "БУРГАС",
                "postCode": "8000",
                "complexId": 794,
                "complexType": "ЖК",
                "complexName": "СЛАВЕЙКОВ",
                "blockNo": "179",
                "x": 27.442839,
                "y": 42.51964,
                "fullAddressString": "гр. БУРГАС жк СЛАВЕЙКОВ бл. 179",
                "siteAddressString": "гр. БУРГАС",
                "localAddressString": "жк СЛАВЕЙКОВ бл. 179"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 180,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 829,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-04-25",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 147,
            "name": "СМОЛЯН - СКЛАД",
            "nameEn": "SMOLYAN - WAREHOUSE",
            "siteId": 67653,
            "address": {
                "countryId": 100,
                "siteId": 67653,
                "siteType": "ГР.",
                "siteName": "СМОЛЯН",
                "postCode": "4700",
                "streetId": 62155,
                "streetType": "УЛ.",
                "streetName": "КОСТА АВРАМИКОВ",
                "streetNo": "41",
                "x": 24.750534,
                "y": 41.568538,
                "fullAddressString": "гр. СМОЛЯН [4700] ул. КОСТА АВРАМИКОВ No 41",
                "siteAddressString": "гр. СМОЛЯН [4700]",
                "localAddressString": "ул. КОСТА АВРАМИКОВ No 41"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "12:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 200,
                "height": 200,
                "depth": 400
            },
            "maxParcelWeight": 1000.0,
            "type": "OFFICE",
            "nearbyOfficeId": 29,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "12:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-10-14",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PALLET",
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": true,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 148,
            "name": "КОЗЛОДУЙ - ОФИС",
            "nameEn": "KOZLODUY - OFFICE",
            "siteId": 37798,
            "address": {
                "countryId": 100,
                "siteId": 37798,
                "siteType": "ГР.",
                "siteName": "КОЗЛОДУЙ",
                "postCode": "3320",
                "streetId": 92326,
                "streetType": "УЛ.",
                "streetName": "ХРИСТО БОТЕВ",
                "streetNo": "1",
                "x": 23.727819,
                "y": 43.778023,
                "fullAddressString": "гр. КОЗЛОДУЙ [3320] ул. ХРИСТО БОТЕВ No 1",
                "siteAddressString": "гр. КОЗЛОДУЙ [3320]",
                "localAddressString": "ул. ХРИСТО БОТЕВ No 1"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:30",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "13:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 170,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 64,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:30",
                    "sameDayDepartureCutoff": "13:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-10-03",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 151,
            "name": "КАЗАНЛЪК - МАНАСТИРА",
            "nameEn": "KAZANLAK - MANASTIRA",
            "siteId": 35167,
            "address": {
                "countryId": 100,
                "siteId": 35167,
                "siteType": "ГР.",
                "siteName": "КАЗАНЛЪК",
                "postCode": "6100",
                "streetId": 91849,
                "streetType": "БУЛ.",
                "streetName": "23-ТИ ПЕХОТЕН ШИПЧЕНСКИ ПОЛК",
                "streetNo": "40",
                "x": 25.384013,
                "y": 42.617673,
                "fullAddressString": "гр. КАЗАНЛЪК [6100] бул. 23-ТИ ПЕХОТЕН ШИПЧЕНСКИ ПОЛК No 40",
                "siteAddressString": "гр. КАЗАНЛЪК [6100]",
                "localAddressString": "бул. 23-ТИ ПЕХОТЕН ШИПЧЕНСКИ ПОЛК No 40"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 135,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-06-30",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 152,
            "name": "ВЕЛИКИ ПРЕСЛАВ",
            "nameEn": "VELIKI PRESLAV",
            "siteId": 58222,
            "address": {
                "countryId": 100,
                "siteId": 58222,
                "siteType": "ГР.",
                "siteName": "ВЕЛИКИ ПРЕСЛАВ",
                "postCode": "9850",
                "streetId": 83134,
                "streetType": "УЛ.",
                "streetName": "БОРИС СПИРОВ",
                "streetNo": "63",
                "addressNote": "СРЕЩУ ХИПЕРМАРКЕТ ЙОМИ (МАЛКОТО CBA)",
                "x": 26.808679,
                "y": 43.16229,
                "fullAddressString": "гр. ВЕЛИКИ ПРЕСЛАВ [9850] ул. БОРИС СПИРОВ No 63 СРЕЩУ ХИПЕРМАРКЕТ ЙОМИ (МАЛКОТО CBA)",
                "siteAddressString": "гр. ВЕЛИКИ ПРЕСЛАВ [9850]",
                "localAddressString": "ул. БОРИС СПИРОВ No 63 СРЕЩУ ХИПЕРМАРКЕТ ЙОМИ (МАЛКОТО CBA)"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 8,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-07-09",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 153,
            "name": "СОФИЯ - СОЛУНСКА (УЛ.)",
            "nameEn": "SOFIA - SOLUNSKA (UL.)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 1625,
                "streetType": "УЛ.",
                "streetName": "Д-Р ГЕОРГИ ВЪЛКОВИЧ",
                "streetNo": "10",
                "x": 23.321681,
                "y": 42.691703,
                "fullAddressString": "гр. СОФИЯ ул. Д-Р ГЕОРГИ ВЪЛКОВИЧ No 10",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. Д-Р ГЕОРГИ ВЪЛКОВИЧ No 10"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 364,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-08-27",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": false
        },
        {
            "id": 154,
            "name": "ШУМЕН - РУСКИ ПАМЕТНИК",
            "nameEn": "SHUMEN - RUSKI PAMETNIK",
            "siteId": 83510,
            "address": {
                "countryId": 100,
                "siteId": 83510,
                "siteType": "ГР.",
                "siteName": "ШУМЕН",
                "postCode": "9700",
                "streetId": 24165,
                "streetType": "БУЛ.",
                "streetName": "МАДАРА",
                "streetNo": "40",
                "x": 26.9375,
                "y": 43.270886,
                "fullAddressString": "гр. ШУМЕН [9700] бул. МАДАРА No 40",
                "siteAddressString": "гр. ШУМЕН [9700]",
                "localAddressString": "бул. МАДАРА No 40"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 400,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-08-13",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 155,
            "name": "СОФИЯ - СТЕФАН СТАМБОЛОВ",
            "nameEn": "SOFIA - STEFAN STAMBOLOV",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 114,
                "streetType": "БУЛ.",
                "streetName": "СТЕФАН СТАМБОЛОВ",
                "streetNo": "8",
                "x": 23.316624,
                "y": 42.699209,
                "fullAddressString": "гр. СОФИЯ бул. СТЕФАН СТАМБОЛОВ No 8",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "бул. СТЕФАН СТАМБОЛОВ No 8"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 110,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-09-10",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": false
        },
        {
            "id": 156,
            "name": "СЕВЛИЕВО - АВТОГАРА",
            "nameEn": "SEVLIEVO - AVTOGARA",
            "siteId": 65927,
            "address": {
                "countryId": 100,
                "siteId": 65927,
                "siteType": "ГР.",
                "siteName": "СЕВЛИЕВО",
                "postCode": "5400",
                "streetId": 94507,
                "streetType": "УЛ.",
                "streetName": "СТОЯН БЪЧВАРОВ",
                "streetNo": "8",
                "x": 25.097186,
                "y": 43.022462,
                "fullAddressString": "гр. СЕВЛИЕВО [5400] ул. СТОЯН БЪЧВАРОВ No 8",
                "siteAddressString": "гр. СЕВЛИЕВО [5400]",
                "localAddressString": "ул. СТОЯН БЪЧВАРОВ No 8"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 180,
                "depth": 220
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 33,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-10-15",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": false
        },
        {
            "id": 157,
            "name": "СТАРА ЗАГОРА - АВТОГАРА",
            "nameEn": "STARA ZAGORA - AVTOGARA",
            "siteId": 68850,
            "address": {
                "countryId": 100,
                "siteId": 68850,
                "siteType": "ГР.",
                "siteName": "СТАРА ЗАГОРА",
                "postCode": "6000",
                "streetId": 18012,
                "streetType": "БУЛ.",
                "streetName": "СЛАВЯНСКИ",
                "streetNo": "64",
                "x": 25.632967,
                "y": 42.419965,
                "fullAddressString": "гр. СТАРА ЗАГОРА бул. СЛАВЯНСКИ No 64",
                "siteAddressString": "гр. СТАРА ЗАГОРА",
                "localAddressString": "бул. СЛАВЯНСКИ No 64"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 30.0,
            "type": "OFFICE",
            "nearbyOfficeId": 84,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-09-10",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 158,
            "name": "СТАРА ЗАГОРА - ПРАКТИКЕР",
            "nameEn": "STARA ZAGORA - PRAKTIKER",
            "siteId": 68850,
            "address": {
                "countryId": 100,
                "siteId": 68850,
                "siteType": "ГР.",
                "siteName": "СТАРА ЗАГОРА",
                "postCode": "6000",
                "streetId": 18009,
                "streetType": "БУЛ.",
                "streetName": "НИКОЛА ПЕТКОВ",
                "streetNo": "48",
                "addressNote": "ПРАКТИКЕР",
                "x": 25.65975,
                "y": 42.426941,
                "fullAddressString": "гр. СТАРА ЗАГОРА бул. НИКОЛА ПЕТКОВ No 48 ПРАКТИКЕР",
                "siteAddressString": "гр. СТАРА ЗАГОРА",
                "localAddressString": "бул. НИКОЛА ПЕТКОВ No 48 ПРАКТИКЕР"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 30.0,
            "type": "OFFICE",
            "nearbyOfficeId": 843,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-09-11",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 160,
            "name": "ХАСКОВО - ДОБРУДЖА (УЛ.)",
            "nameEn": "HASKOVO - DOBRUDZHA (UL.)",
            "siteId": 77195,
            "address": {
                "countryId": 100,
                "siteId": 77195,
                "siteType": "ГР.",
                "siteName": "ХАСКОВО",
                "postCode": "6300",
                "streetId": 28391,
                "streetType": "УЛ.",
                "streetName": "ХРИСТО БОТЕВ",
                "streetNo": "13",
                "x": 25.549946,
                "y": 41.931944,
                "fullAddressString": "гр. ХАСКОВО [6300] ул. ХРИСТО БОТЕВ No 13",
                "siteAddressString": "гр. ХАСКОВО [6300]",
                "localAddressString": "ул. ХРИСТО БОТЕВ No 13"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 265,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-09-21",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 161,
            "name": "СОФИЯ - ДРУЖБА 2",
            "nameEn": "SOFIA - DRUJBA 2",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 99,
                "streetType": "БУЛ.",
                "streetName": "ПРОФ. ЦВЕТАН ЛАЗАРОВ",
                "streetNo": "126",
                "x": 23.405238,
                "y": 42.65141,
                "fullAddressString": "гр. СОФИЯ бул. ПРОФ. ЦВЕТАН ЛАЗАРОВ No 126",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "бул. ПРОФ. ЦВЕТАН ЛАЗАРОВ No 126"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "19:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 790,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-01-02",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 162,
            "name": "СОФИЯ - ПОЛИГОНА",
            "nameEn": "SOFIA - POLIGONA",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 131,
                "streetType": "БУЛ.",
                "streetName": "ЦАРИГРАДСКО ШОСЕ",
                "streetNo": "113А",
                "x": 23.380365,
                "y": 42.663038,
                "fullAddressString": "гр. СОФИЯ бул. ЦАРИГРАДСКО ШОСЕ No 113А",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "бул. ЦАРИГРАДСКО ШОСЕ No 113А"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 354,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-11-26",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 163,
            "name": "СОФИЯ - ПРОФ. ГЕОРГИ БРАДИСТИЛОВ (УЛ.), ПАЗАР ТУ",
            "nameEn": "SOFIA - PROF. GEORGI BRADISTILOV (UL.), TU MARKET",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 2689,
                "streetType": "УЛ.",
                "streetName": "ПРОФ. ГЕОРГИ БРАДИСТИЛОВ",
                "streetNo": "3",
                "addressNote": "ПАЗАР ТУ",
                "x": 23.358178,
                "y": 42.656813,
                "fullAddressString": "гр. СОФИЯ ул. ПРОФ. ГЕОРГИ БРАДИСТИЛОВ No 3 ПАЗАР ТУ",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. ПРОФ. ГЕОРГИ БРАДИСТИЛОВ No 3 ПАЗАР ТУ"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "19:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 886,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-09-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 164,
            "name": "СТАРА ЗАГОРА - БАНЯ ПИПЕРКА",
            "nameEn": "STARA ZAGORA - BANYA PIPERKA",
            "siteId": 68850,
            "address": {
                "countryId": 100,
                "siteId": 68850,
                "siteType": "ГР.",
                "siteName": "СТАРА ЗАГОРА",
                "postCode": "6000",
                "streetId": 18116,
                "streetType": "УЛ.",
                "streetName": "ГРАФ НИКОЛАЙ ИГНАТИЕВ",
                "streetNo": "30",
                "x": 25.635393,
                "y": 42.428284,
                "fullAddressString": "гр. СТАРА ЗАГОРА ул. ГРАФ НИКОЛАЙ ИГНАТИЕВ No 30",
                "siteAddressString": "гр. СТАРА ЗАГОРА",
                "localAddressString": "ул. ГРАФ НИКОЛАЙ ИГНАТИЕВ No 30"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 397,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-11-12",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": false
        },
        {
            "id": 165,
            "name": "СОФИЯ - ЛЮБЛЯНА",
            "nameEn": "SOFIA - LYUBLYANA",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 2178,
                "streetType": "УЛ.",
                "streetName": "ЛЮБЛЯНА",
                "streetNo": "22А",
                "x": 23.263287,
                "y": 42.674107,
                "fullAddressString": "гр. СОФИЯ ул. ЛЮБЛЯНА No 22А",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. ЛЮБЛЯНА No 22А"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 299,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-10-26",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 166,
            "name": "ПАНАГЮРИЩЕ",
            "nameEn": "PANAGYURISHTE",
            "siteId": 55302,
            "address": {
                "countryId": 100,
                "siteId": 55302,
                "siteType": "ГР.",
                "siteName": "ПАНАГЮРИЩЕ",
                "postCode": "4500",
                "streetId": 82375,
                "streetType": "УЛ.",
                "streetName": "20-ТИ АПРИЛ",
                "addressNote": "ПАЗАРА",
                "x": 24.188016,
                "y": 42.506468,
                "fullAddressString": "гр. ПАНАГЮРИЩЕ [4500] ул. 20-ТИ АПРИЛ ПАЗАРА",
                "siteAddressString": "гр. ПАНАГЮРИЩЕ [4500]",
                "localAddressString": "ул. 20-ТИ АПРИЛ ПАЗАРА"
            },
            "workingTimeFrom": "10:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "10:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "12:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 184,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "12:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-01-08",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": false
        },
        {
            "id": 167,
            "name": "ТВЪРДИЦА",
            "nameEn": "TVARDITSA",
            "siteId": 72165,
            "address": {
                "countryId": 100,
                "siteId": 72165,
                "siteType": "ГР.",
                "siteName": "ТВЪРДИЦА",
                "postCode": "8890",
                "streetId": 85520,
                "streetType": "УЛ.",
                "streetName": "ХАН АСПАРУХ",
                "streetNo": "13А",
                "x": 25.89794,
                "y": 42.700573,
                "fullAddressString": "гр. ТВЪРДИЦА [8890] ул. ХАН АСПАРУХ No 13А",
                "siteAddressString": "гр. ТВЪРДИЦА [8890]",
                "localAddressString": "ул. ХАН АСПАРУХ No 13А"
            },
            "workingTimeFrom": "10:30",
            "workingTimeTo": "17:00",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 56,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "10:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "10:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "10:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "10:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "10:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "10:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "10:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "10:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-11-02",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 168,
            "name": "ДОЛНИ ЧИФЛИК",
            "nameEn": "DOLNI CHIFLIK",
            "siteId": 21912,
            "address": {
                "countryId": 100,
                "siteId": 21912,
                "siteType": "ГР.",
                "siteName": "ДОЛНИ ЧИФЛИК",
                "postCode": "9120",
                "streetId": 91382,
                "streetType": "УЛ.",
                "streetName": "ШИПКА",
                "streetNo": "10",
                "x": 27.71749,
                "y": 42.9874,
                "fullAddressString": "гр. ДОЛНИ ЧИФЛИК [9120] ул. ШИПКА No 10",
                "siteAddressString": "гр. ДОЛНИ ЧИФЛИК [9120]",
                "localAddressString": "ул. ШИПКА No 10"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 263,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-10-08",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": false
        },
        {
            "id": 169,
            "name": "ЛОВЕЧ - ТЪРГОВСКА (УЛ.)",
            "nameEn": "LOVECH - TARGOVSKA (UL.)",
            "siteId": 43952,
            "address": {
                "countryId": 100,
                "siteId": 43952,
                "siteType": "ГР.",
                "siteName": "ЛОВЕЧ",
                "postCode": "5500",
                "streetId": 32192,
                "streetType": "УЛ.",
                "streetName": "ТЪРГОВСКА",
                "streetNo": "58",
                "x": 24.718592,
                "y": 43.141511,
                "fullAddressString": "гр. ЛОВЕЧ [5500] ул. ТЪРГОВСКА No 58",
                "siteAddressString": "гр. ЛОВЕЧ [5500]",
                "localAddressString": "ул. ТЪРГОВСКА No 58"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 25,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-10-08",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": false
        },
        {
            "id": 171,
            "name": "СИЛИСТРА - МАКЕДОНИЯ (БУЛ.)",
            "nameEn": "SILISTRA - MAKEDONIA (BUL.)",
            "siteId": 66425,
            "address": {
                "countryId": 100,
                "siteId": 66425,
                "siteType": "ГР.",
                "siteName": "СИЛИСТРА",
                "postCode": "7500",
                "streetId": 46113,
                "streetType": "БУЛ.",
                "streetName": "МАКЕДОНИЯ",
                "streetNo": "127Г",
                "floorNo": "1",
                "x": 27.263625,
                "y": 44.112918,
                "fullAddressString": "гр. СИЛИСТРА [7500] бул. МАКЕДОНИЯ No 127Г ет. 1",
                "siteAddressString": "гр. СИЛИСТРА [7500]",
                "localAddressString": "бул. МАКЕДОНИЯ No 127Г ет. 1"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "16:30",
            "sameDayDepartureCutoffHalf": "11:45",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 436,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "11:45",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-11-05",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 172,
            "name": "СОФИЯ - ЗОНА Б-19",
            "nameEn": "SOFIA - ZONA B-19",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 6,
                "streetType": "БУЛ.",
                "streetName": "АЛЕКСАНДЪР СТАМБОЛИЙСКИ",
                "streetNo": "156",
                "entranceNo": "А",
                "x": 23.298817,
                "y": 42.700437,
                "fullAddressString": "гр. СОФИЯ бул. АЛЕКСАНДЪР СТАМБОЛИЙСКИ No 156 вх. А",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "бул. АЛЕКСАНДЪР СТАМБОЛИЙСКИ No 156 вх. А"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 301,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-10-29",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 173,
            "name": "ВЪРШЕЦ - РЕПУБЛИКА",
            "nameEn": "VARSHETS - REPUBLIKA",
            "siteId": 12961,
            "address": {
                "countryId": 100,
                "siteId": 12961,
                "siteType": "ГР.",
                "siteName": "ВЪРШЕЦ",
                "postCode": "3540",
                "streetId": 76101,
                "streetType": "УЛ.",
                "streetName": "РЕПУБЛИКА",
                "streetNo": "70",
                "x": 23.28736,
                "y": 43.19487,
                "fullAddressString": "гр. ВЪРШЕЦ [3540] ул. РЕПУБЛИКА No 70",
                "siteAddressString": "гр. ВЪРШЕЦ [3540]",
                "localAddressString": "ул. РЕПУБЛИКА No 70"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 528,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-10-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 174,
            "name": "ПЕРНИК - ЮРИЙ ГАГАРИН",
            "nameEn": "PERNIK - IURY GAGARIN",
            "siteId": 55871,
            "address": {
                "countryId": 100,
                "siteId": 55871,
                "siteType": "ГР.",
                "siteName": "ПЕРНИК",
                "postCode": "2300",
                "complexId": 3030,
                "complexType": "КВ.",
                "complexName": "ИЗТОК",
                "addressNote": "М/У БЛ.34 И БЛ.36",
                "x": 23.106083,
                "y": 42.609509,
                "fullAddressString": "гр. ПЕРНИК [2300] кв. ИЗТОК М/У БЛ.34 И БЛ.36",
                "siteAddressString": "гр. ПЕРНИК [2300]",
                "localAddressString": "кв. ИЗТОК М/У БЛ.34 И БЛ.36"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "16:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 35,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-02-19",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 175,
            "name": "СОФИЯ - МЛАДОСТ 3 (ЖК), СРЕЩУ БЛ.325Б",
            "nameEn": "SOFIA - MLADOST 3 (ZHK),  SRESHTU BL.325B",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "complexId": 51,
                "complexType": "ЖК",
                "complexName": "МЛАДОСТ 4",
                "blockNo": "458",
                "x": 23.374727,
                "y": 42.635083,
                "fullAddressString": "гр. СОФИЯ жк МЛАДОСТ 4 бл. 458",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк МЛАДОСТ 4 бл. 458"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 258,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-11-04",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 177,
            "name": "КОСТИНБРОД - БИЛЛА ПАРК",
            "nameEn": "KOSTINBROD - BILLA PARK",
            "siteId": 38978,
            "address": {
                "countryId": 100,
                "siteId": 38978,
                "siteType": "ГР.",
                "siteName": "КОСТИНБРОД",
                "postCode": "2230",
                "streetId": 79532,
                "streetType": "УЛ.",
                "streetName": "СЛАВЯНСКА",
                "streetNo": "34",
                "x": 23.21528,
                "y": 42.810098,
                "fullAddressString": "гр. КОСТИНБРОД [2230] ул. СЛАВЯНСКА No 34",
                "siteAddressString": "гр. КОСТИНБРОД [2230]",
                "localAddressString": "ул. СЛАВЯНСКА No 34"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 437,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-11-07",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 178,
            "name": "ВАРНА - ВЛ. ВАРНЕНЧИК (ЖК)",
            "nameEn": "VARNA - VL. VARNENCHIK (ZHK)",
            "siteId": 10135,
            "address": {
                "countryId": 100,
                "siteId": 10135,
                "siteType": "ГР.",
                "siteName": "ВАРНА",
                "postCode": "9000",
                "streetId": 12529,
                "streetType": "УЛ.",
                "streetName": "НИШ",
                "streetNo": "33",
                "complexId": 601,
                "complexType": "ЖК",
                "complexName": "ВЛАДИСЛАВ ВАРНЕНЧИК",
                "x": 27.853324,
                "y": 43.246602,
                "fullAddressString": "гр. ВАРНА жк ВЛАДИСЛАВ ВАРНЕНЧИК ул. НИШ No 33",
                "siteAddressString": "гр. ВАРНА",
                "localAddressString": "жк ВЛАДИСЛАВ ВАРНЕНЧИК ул. НИШ No 33"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 220
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 328,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-12-06",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 179,
            "name": "РУСЕ - ПРАКТИКЕР",
            "nameEn": "RUSE - PRAKTIKER",
            "siteId": 63427,
            "address": {
                "countryId": 100,
                "siteId": 63427,
                "siteType": "ГР.",
                "siteName": "РУСЕ",
                "postCode": "7000",
                "streetId": 16317,
                "streetType": "БУЛ.",
                "streetName": "ЛИПНИК",
                "streetNo": "10",
                "x": 25.967062,
                "y": 43.846394,
                "fullAddressString": "гр. РУСЕ бул. ЛИПНИК No 10",
                "siteAddressString": "гр. РУСЕ",
                "localAddressString": "бул. ЛИПНИК No 10"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 849,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-12-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": false
        },
        {
            "id": 182,
            "name": "ТРУД",
            "nameEn": "TRUD",
            "siteId": 73242,
            "address": {
                "countryId": 100,
                "siteId": 73242,
                "siteType": "С.",
                "siteName": "ТРУД",
                "postCode": "4199",
                "streetId": 85783,
                "streetType": "УЛ.",
                "streetName": "СЕРГЕЙ РУМЯНЦЕВ",
                "streetNo": "2",
                "x": 24.724817,
                "y": 42.229377,
                "fullAddressString": "с. ТРУД [4199] ул. СЕРГЕЙ РУМЯНЦЕВ No 2",
                "siteAddressString": "с. ТРУД [4199]",
                "localAddressString": "ул. СЕРГЕЙ РУМЯНЦЕВ No 2"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "12:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 794,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "12:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-11-15",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 183,
            "name": "НОВИ ИСКЪР",
            "nameEn": "NOVI ISKAR",
            "siteId": 357,
            "address": {
                "countryId": 100,
                "siteId": 357,
                "siteType": "ГР.",
                "siteName": "НОВИ ИСКЪР",
                "postCode": "1280",
                "streetId": 65108,
                "streetType": "УЛ.",
                "streetName": "ИСКЪРСКО ДЕФИЛЕ",
                "streetNo": "281",
                "x": 23.353769,
                "y": 42.825703,
                "fullAddressString": "гр. НОВИ ИСКЪР [1280] ул. ИСКЪРСКО ДЕФИЛЕ No 281",
                "siteAddressString": "гр. НОВИ ИСКЪР [1280]",
                "localAddressString": "ул. ИСКЪРСКО ДЕФИЛЕ No 281"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 112,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-02-25",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": false
        },
        {
            "id": 184,
            "name": "ПАЗАРДЖИК - СКЛАД",
            "nameEn": "PAZARDZHIK - SKLAD",
            "siteId": 55155,
            "address": {
                "countryId": 100,
                "siteId": 55155,
                "siteType": "ГР.",
                "siteName": "ПАЗАРДЖИК",
                "postCode": "4400",
                "streetId": 40108,
                "streetType": "УЛ.",
                "streetName": "ДИМЧО ДЕБЕЛЯНОВ",
                "streetNo": "80",
                "x": 24.358889,
                "y": 42.173701,
                "fullAddressString": "гр. ПАЗАРДЖИК [4400] ул. ДИМЧО ДЕБЕЛЯНОВ No 80",
                "siteAddressString": "гр. ПАЗАРДЖИК [4400]",
                "localAddressString": "ул. ДИМЧО ДЕБЕЛЯНОВ No 80"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 240,
                "height": 240,
                "depth": 600
            },
            "maxParcelWeight": 1200.0,
            "type": "OFFICE",
            "nearbyOfficeId": 18,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-11-26",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PALLET",
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": true,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": false
        },
        {
            "id": 185,
            "name": "ЯМБОЛ - Н. ПЕТРИНИ",
            "nameEn": "YAMBOL - N. PETRINI",
            "siteId": 87374,
            "address": {
                "countryId": 100,
                "siteId": 87374,
                "siteType": "ГР.",
                "siteName": "ЯМБОЛ",
                "postCode": "8600",
                "streetId": 26213,
                "streetType": "УЛ.",
                "streetName": "НИКОЛАЙ ПЕТРИНИ",
                "streetNo": "8А",
                "addressNote": "ДО ПАРКИНГА НА ЛОВЕН ДОМ",
                "x": 26.502771,
                "y": 42.482695,
                "fullAddressString": "гр. ЯМБОЛ [8600] ул. НИКОЛАЙ ПЕТРИНИ No 8А ДО ПАРКИНГА НА ЛОВЕН ДОМ",
                "siteAddressString": "гр. ЯМБОЛ [8600]",
                "localAddressString": "ул. НИКОЛАЙ ПЕТРИНИ No 8А ДО ПАРКИНГА НА ЛОВЕН ДОМ"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 55,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-11-27",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": false
        },
        {
            "id": 186,
            "name": "СОФИЯ - МЛАДОСТ 2 (ЖК)",
            "nameEn": "SOFIA - MLADOST 2 (ZHK)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "complexId": 49,
                "complexType": "ЖК",
                "complexName": "МЛАДОСТ 2",
                "blockNo": "247А",
                "addressNote": "МАГАЗИН 1",
                "x": 23.367019,
                "y": 42.644736,
                "fullAddressString": "гр. СОФИЯ жк МЛАДОСТ 2 бл. 247А МАГАЗИН 1",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк МЛАДОСТ 2 бл. 247А МАГАЗИН 1"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 240,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-12-03",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 187,
            "name": "МОНТАНА - ПЛИСКА",
            "nameEn": "MONTANA - PLISKA",
            "siteId": 48489,
            "address": {
                "countryId": 100,
                "siteId": 48489,
                "siteType": "ГР.",
                "siteName": "МОНТАНА",
                "postCode": "3400",
                "streetId": 58009,
                "streetType": "БУЛ.",
                "streetName": "ПАРТА",
                "complexId": 2704,
                "complexType": "ЖК",
                "complexName": "ПЛИСКА",
                "blockNo": "2",
                "x": 23.215249,
                "y": 43.414884,
                "fullAddressString": "гр. МОНТАНА [3400] жк ПЛИСКА бул. ПАРТА бл. 2",
                "siteAddressString": "гр. МОНТАНА [3400]",
                "localAddressString": "жк ПЛИСКА бул. ПАРТА бл. 2"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:30",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "12:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 78,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:30",
                    "sameDayDepartureCutoff": "12:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-12-15",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": false
        },
        {
            "id": 188,
            "name": "СОФИЯ - ЯНКО САКЪЗОВ",
            "nameEn": "SOFIA - YANKO SAKAZOV",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 139,
                "streetType": "БУЛ.",
                "streetName": "ЯНКО САКЪЗОВ",
                "streetNo": "56",
                "x": 23.342973,
                "y": 42.696161,
                "fullAddressString": "гр. СОФИЯ бул. ЯНКО САКЪЗОВ No 56",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "бул. ЯНКО САКЪЗОВ No 56"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 326,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-02-11",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": false
        },
        {
            "id": 189,
            "name": "СОФИЯ - ТОДОР КАБЛЕШКОВ",
            "nameEn": "SOFIA - TODOR KABLESHKOV",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 1546,
                "streetType": "УЛ.",
                "streetName": "ДЕЯН БЕЛИШКИ",
                "streetNo": "64",
                "x": 23.298929,
                "y": 42.660035,
                "fullAddressString": "гр. СОФИЯ ул. ДЕЯН БЕЛИШКИ No 64",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. ДЕЯН БЕЛИШКИ No 64"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "19:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 413,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-08-26",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 190,
            "name": "АЙТОС - КИРИЛ И МЕТОДИЙ (УЛ.)",
            "nameEn": "AYTOS - KIRIL I METODI (UL.)",
            "siteId": 151,
            "address": {
                "countryId": 100,
                "siteId": 151,
                "siteType": "ГР.",
                "siteName": "АЙТОС",
                "postCode": "8500",
                "streetId": 87709,
                "streetType": "УЛ.",
                "streetName": "КИРИЛ И МЕТОДИЙ",
                "streetNo": "17",
                "x": 27.250811,
                "y": 42.703595,
                "fullAddressString": "гр. АЙТОС [8500] ул. КИРИЛ И МЕТОДИЙ No 17",
                "siteAddressString": "гр. АЙТОС [8500]",
                "localAddressString": "ул. КИРИЛ И МЕТОДИЙ No 17"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 180,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 460,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-02-20",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 191,
            "name": "ОМУРТАГ",
            "nameEn": "OMURTAG",
            "siteId": 53535,
            "address": {
                "countryId": 100,
                "siteId": 53535,
                "siteType": "ГР.",
                "siteName": "ОМУРТАГ",
                "postCode": "7900",
                "streetId": 101705,
                "streetType": "УЛ.",
                "streetName": "ХРИСТО БОТЕВ",
                "streetNo": "32",
                "x": 26.41767,
                "y": 43.1084,
                "fullAddressString": "гр. ОМУРТАГ [7900] ул. ХРИСТО БОТЕВ No 32",
                "siteAddressString": "гр. ОМУРТАГ [7900]",
                "localAddressString": "ул. ХРИСТО БОТЕВ No 32"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "16:30",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 16,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-02-19",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 192,
            "name": "ВАРНА - ЧАЙКА (ЖК)",
            "nameEn": "VARNA - CHAYKA (ZHK)",
            "siteId": 10135,
            "address": {
                "countryId": 100,
                "siteId": 10135,
                "siteType": "ГР.",
                "siteName": "ВАРНА",
                "postCode": "9000",
                "complexId": 611,
                "complexType": "ЖК",
                "complexName": "ЧАЙКА",
                "blockNo": "185",
                "addressNote": "ПАРТЕР",
                "x": 27.942293,
                "y": 43.215197,
                "fullAddressString": "гр. ВАРНА жк ЧАЙКА бл. 185 ПАРТЕР",
                "siteAddressString": "гр. ВАРНА",
                "localAddressString": "жк ЧАЙКА бл. 185 ПАРТЕР"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 220
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 785,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-03-21",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 193,
            "name": "СОФИЯ - ТОДОРИНИ КУКЛИ",
            "nameEn": "SOFIA - TODORINI KUKLI",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 3054,
                "streetType": "УЛ.",
                "streetName": "ТОДОРИНИ КУКЛИ",
                "streetNo": "4",
                "x": 23.354542,
                "y": 42.701839,
                "fullAddressString": "гр. СОФИЯ ул. ТОДОРИНИ КУКЛИ No 4",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. ТОДОРИНИ КУКЛИ No 4"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 316,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-03-18",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 196,
            "name": "ВЕЛИКО ТЪРНОВО - КАРТАЛА",
            "nameEn": "VELIKO TARNOVO - KARTALA",
            "siteId": 10447,
            "address": {
                "countryId": 100,
                "siteId": 10447,
                "siteType": "ГР.",
                "siteName": "ВЕЛИКО ТЪРНОВО",
                "postCode": "5000",
                "streetId": 34212,
                "streetType": "УЛ.",
                "streetName": "СТОЯН МИХАЙЛОВСКИ",
                "streetNo": "7",
                "x": 25.622332,
                "y": 43.084044,
                "fullAddressString": "гр. ВЕЛИКО ТЪРНОВО ул. СТОЯН МИХАЙЛОВСКИ No 7",
                "siteAddressString": "гр. ВЕЛИКО ТЪРНОВО",
                "localAddressString": "ул. СТОЯН МИХАЙЛОВСКИ No 7"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 269,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-03-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 197,
            "name": "ПОМОРИЕ – ПЕТКО КАРАВЕЛОВ (УЛ.)",
            "nameEn": "POMORIE - PETKO KARAVELOV (UL.)",
            "siteId": 57491,
            "address": {
                "countryId": 100,
                "siteId": 57491,
                "siteType": "ГР.",
                "siteName": "ПОМОРИЕ",
                "postCode": "8200",
                "streetId": 95714,
                "streetType": "УЛ.",
                "streetName": "ПЕТКО КАРАВЕЛОВ",
                "streetNo": "23",
                "addressNote": "ДО ПЛАТЕНИЯ ПАРКИНГ",
                "x": 27.64039,
                "y": 42.56094,
                "fullAddressString": "гр. ПОМОРИЕ [8200] ул. ПЕТКО КАРАВЕЛОВ No 23 ДО ПЛАТЕНИЯ ПАРКИНГ",
                "siteAddressString": "гр. ПОМОРИЕ [8200]",
                "localAddressString": "ул. ПЕТКО КАРАВЕЛОВ No 23 ДО ПЛАТЕНИЯ ПАРКИНГ"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 120,
                "height": 180,
                "depth": 200
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 450,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-03-25",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 199,
            "name": "СОФИЯ - МЛАДОСТ 4 (Ж.К.), БЛ.488",
            "nameEn": "SOFIA - MLADOST 4 (ZH.K), BL.488",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "complexId": 51,
                "complexType": "ЖК",
                "complexName": "МЛАДОСТ 4",
                "blockNo": "488",
                "x": 23.384094,
                "y": 42.626468,
                "fullAddressString": "гр. СОФИЯ жк МЛАДОСТ 4 бл. 488",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк МЛАДОСТ 4 бл. 488"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "19:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 66,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-06-03",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 200,
            "name": "ХАСКОВО – ГРАДСКА ГРАДИНА",
            "nameEn": "HASKOVO - GRADSKA GRADINA",
            "siteId": 77195,
            "address": {
                "countryId": 100,
                "siteId": 77195,
                "siteType": "ГР.",
                "siteName": "ХАСКОВО",
                "postCode": "6300",
                "streetId": 28074,
                "streetType": "БУЛ.",
                "streetName": "БЪЛГАРИЯ",
                "streetNo": "15",
                "x": 25.546678,
                "y": 41.934322,
                "fullAddressString": "гр. ХАСКОВО [6300] бул. БЪЛГАРИЯ No 15",
                "siteAddressString": "гр. ХАСКОВО [6300]",
                "localAddressString": "бул. БЪЛГАРИЯ No 15"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 882,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-04-22",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 201,
            "name": "СТАМБОЛИЙСКИ",
            "nameEn": "STAMBOLIYSKI",
            "siteId": 51980,
            "address": {
                "countryId": 100,
                "siteId": 51980,
                "siteType": "ГР.",
                "siteName": "СТАМБОЛИЙСКИ",
                "postCode": "4210",
                "streetId": 93301,
                "streetType": "УЛ.",
                "streetName": "ХРИСТО БОТЕВ",
                "streetNo": "7",
                "x": 24.530979,
                "y": 42.130471,
                "fullAddressString": "гр. СТАМБОЛИЙСКИ [4210] ул. ХРИСТО БОТЕВ No 7",
                "siteAddressString": "гр. СТАМБОЛИЙСКИ [4210]",
                "localAddressString": "ул. ХРИСТО БОТЕВ No 7"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "12:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 532,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "12:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-07-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 202,
            "name": "РУСЕ - БУРОВ",
            "nameEn": "RUSE - BUROV",
            "siteId": 63427,
            "address": {
                "countryId": 100,
                "siteId": 63427,
                "siteType": "ГР.",
                "siteName": "РУСЕ",
                "postCode": "7000",
                "streetId": 16809,
                "streetType": "УЛ.",
                "streetName": "БУРОВ",
                "streetNo": "2",
                "x": 25.951992,
                "y": 43.846591,
                "fullAddressString": "гр. РУСЕ ул. БУРОВ No 2",
                "siteAddressString": "гр. РУСЕ",
                "localAddressString": "ул. БУРОВ No 2"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 737,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-04-16",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 203,
            "name": "СОФИЯ - ЦАРЕВО СЕЛО (УЛ.)",
            "nameEn": "SOFIA - TSAREVO SELO (UL.)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 2920,
                "streetType": "УЛ.",
                "streetName": "СМЪРЧ",
                "streetNo": "4",
                "complexId": 2,
                "complexType": "ЖК",
                "complexName": "БЕЛИТЕ БРЕЗИ",
                "x": 23.289866,
                "y": 42.678166,
                "fullAddressString": "гр. СОФИЯ жк БЕЛИТЕ БРЕЗИ ул. СМЪРЧ No 4",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк БЕЛИТЕ БРЕЗИ ул. СМЪРЧ No 4"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 443,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-06-03",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 204,
            "name": "СУВОРОВО",
            "nameEn": "SUVOROVO",
            "siteId": 70175,
            "address": {
                "countryId": 100,
                "siteId": 70175,
                "siteType": "ГР.",
                "siteName": "СУВОРОВО",
                "postCode": "9170",
                "streetId": 85075,
                "streetType": "УЛ.",
                "streetName": "ДРАГАН СТОЯНОВ",
                "streetNo": "1А",
                "x": 27.593295,
                "y": 43.328335,
                "fullAddressString": "гр. СУВОРОВО [9170] ул. ДРАГАН СТОЯНОВ No 1А",
                "siteAddressString": "гр. СУВОРОВО [9170]",
                "localAddressString": "ул. ДРАГАН СТОЯНОВ No 1А"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "17:00",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 888,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-03-25",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 205,
            "name": "БЛАГОЕВГРАД - КИРИЛ И МЕТОДИЙ (БУЛ.)",
            "nameEn": "BLAGOEVGRAD - KIRIL I METODII (BUL.)",
            "siteId": 4279,
            "address": {
                "countryId": 100,
                "siteId": 4279,
                "siteType": "ГР.",
                "siteName": "БЛАГОЕВГРАД",
                "postCode": "2700",
                "streetId": 30223,
                "streetType": "БУЛ.",
                "streetName": "СВ. СВ. КИРИЛ И МЕТОДИЙ",
                "streetNo": "24",
                "x": 23.08691,
                "y": 42.015249,
                "fullAddressString": "гр. БЛАГОЕВГРАД бул. СВ. СВ. КИРИЛ И МЕТОДИЙ No 24",
                "siteAddressString": "гр. БЛАГОЕВГРАД",
                "localAddressString": "бул. СВ. СВ. КИРИЛ И МЕТОДИЙ No 24"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "12:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 398,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "12:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-04-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 206,
            "name": "СОФИЯ - СТУДЕНТСКИ ГРАД - НСА",
            "nameEn": "SOFIA - STUDENTSKI GRAD - NSA",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 3433,
                "streetType": "УЛ.",
                "streetName": "АКАД. СТЕФАН МЛАДЕНОВ",
                "streetNo": "56",
                "x": 23.345157,
                "y": 42.647675,
                "fullAddressString": "гр. СОФИЯ ул. АКАД. СТЕФАН МЛАДЕНОВ No 56",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. АКАД. СТЕФАН МЛАДЕНОВ No 56"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "19:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 225,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-04-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 208,
            "name": "ГЕНЕРАЛ ТОШЕВО",
            "nameEn": "GENERAL TOSHEVO",
            "siteId": 14711,
            "address": {
                "countryId": 100,
                "siteId": 14711,
                "siteType": "ГР.",
                "siteName": "ГЕНЕРАЛ ТОШЕВО",
                "postCode": "9500",
                "streetId": 90734,
                "streetType": "УЛ.",
                "streetName": "Г. С. РАКОВСКИ",
                "streetNo": "6",
                "x": 28.034856,
                "y": 43.700342,
                "fullAddressString": "гр. ГЕНЕРАЛ ТОШЕВО [9500] ул. Г. С. РАКОВСКИ No 6",
                "siteAddressString": "гр. ГЕНЕРАЛ ТОШЕВО [9500]",
                "localAddressString": "ул. Г. С. РАКОВСКИ No 6"
            },
            "workingTimeFrom": "10:00",
            "workingTimeTo": "17:00",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 220
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 851,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-05-21",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 210,
            "name": "СОФИЯ - ДИМИТЪР ХАДЖИКОЦЕВ (УЛ.)",
            "nameEn": "SOFIA - DIMITUR HADJIKOCEV (UL.)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 1595,
                "streetType": "УЛ.",
                "streetName": "ДИМИТЪР ХАДЖИКОЦЕВ",
                "streetNo": "51",
                "x": 23.315797,
                "y": 42.67476,
                "fullAddressString": "гр. СОФИЯ ул. ДИМИТЪР ХАДЖИКОЦЕВ No 51",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. ДИМИТЪР ХАДЖИКОЦЕВ No 51"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 774,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-08-07",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 211,
            "name": "СОФИЯ - БРАТЯ БЪКСТОН (БУЛ.)",
            "nameEn": "SOFIA - BRATYA BUXTON (BUL.",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 16,
                "streetType": "БУЛ.",
                "streetName": "БРАТЯ БЪКСТОН",
                "blockNo": "208",
                "x": 23.27731,
                "y": 42.670182,
                "fullAddressString": "гр. СОФИЯ бул. БРАТЯ БЪКСТОН бл. 208",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "бул. БРАТЯ БЪКСТОН бл. 208"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 203,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-06-03",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 212,
            "name": "СОФИЯ - БАНИШОРА",
            "nameEn": "SOFIA - BANISHORA",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 42,
                "streetType": "БУЛ.",
                "streetName": "ГЕН. НИКОЛАЙ Г. СТОЛЕТОВ",
                "complexId": 1,
                "complexType": "ЖК",
                "complexName": "БАНИШОРА",
                "blockNo": "5А",
                "addressNote": "ПАРТЕР",
                "x": 23.312291,
                "y": 42.712281,
                "fullAddressString": "гр. СОФИЯ жк БАНИШОРА бул. ГЕН. НИКОЛАЙ Г. СТОЛЕТОВ бл. 5А ПАРТЕР",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк БАНИШОРА бул. ГЕН. НИКОЛАЙ Г. СТОЛЕТОВ бл. 5А ПАРТЕР"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 351,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-06-20",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 214,
            "name": "СОФИЯ - ВОЕННА РАМПА",
            "nameEn": "SOFIA - VOENNA RAMPA",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 3482,
                "streetType": "БУЛ.",
                "streetName": "ИЛИЯНЦИ",
                "streetNo": "40",
                "x": 23.319424,
                "y": 42.729172,
                "fullAddressString": "гр. СОФИЯ бул. ИЛИЯНЦИ No 40",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "бул. ИЛИЯНЦИ No 40"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 215,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-05-08",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 215,
            "name": "СОФИЯ - НАДЕЖДА 1 (Ж.К.)",
            "nameEn": "SOFIA - NADEZHDA 1 (ZH.K.)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 3281,
                "streetType": "УЛ.",
                "streetName": "ЧИПРОВСКО ВЪСТАНИЕ",
                "streetNo": "2",
                "complexId": 54,
                "complexType": "ЖК",
                "complexName": "НАДЕЖДА 1",
                "x": 23.299298,
                "y": 42.728376,
                "fullAddressString": "гр. СОФИЯ жк НАДЕЖДА 1 ул. ЧИПРОВСКО ВЪСТАНИЕ No 2",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк НАДЕЖДА 1 ул. ЧИПРОВСКО ВЪСТАНИЕ No 2"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 306,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-06-04",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 216,
            "name": "НЕСЕБЪР 2",
            "nameEn": "NESEBAR 2",
            "siteId": 51500,
            "address": {
                "countryId": 100,
                "siteId": 51500,
                "siteType": "ГР.",
                "siteName": "НЕСЕБЪР",
                "postCode": "8230",
                "streetId": 70967,
                "streetType": "УЛ.",
                "streetName": "ИВАН ВАЗОВ",
                "streetNo": "13",
                "addressNote": "ДО ЗДРАВНАТА КЪЩА",
                "x": 27.719219,
                "y": 42.659484,
                "fullAddressString": "гр. НЕСЕБЪР [8230] ул. ИВАН ВАЗОВ No 13 ДО ЗДРАВНАТА КЪЩА",
                "siteAddressString": "гр. НЕСЕБЪР [8230]",
                "localAddressString": "ул. ИВАН ВАЗОВ No 13 ДО ЗДРАВНАТА КЪЩА"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 120,
                "height": 170,
                "depth": 120
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 83,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-07-08",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 220,
            "name": "МАДАН",
            "nameEn": "MADAN",
            "siteId": 46045,
            "address": {
                "countryId": 100,
                "siteId": 46045,
                "siteType": "ГР.",
                "siteName": "МАДАН",
                "postCode": "4900",
                "streetId": 80663,
                "streetType": "УЛ.",
                "streetName": "ОБЕДИНЕНИЕ",
                "streetNo": "5",
                "x": 24.93888,
                "y": 41.50076,
                "fullAddressString": "гр. МАДАН [4900] ул. ОБЕДИНЕНИЕ No 5",
                "siteAddressString": "гр. МАДАН [4900]",
                "localAddressString": "ул. ОБЕДИНЕНИЕ No 5"
            },
            "workingTimeFrom": "10:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "16:00",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 221,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-06-24",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 221,
            "name": "РУДОЗЕМ",
            "nameEn": "RUDOZEM",
            "siteId": 63207,
            "address": {
                "countryId": 100,
                "siteId": 63207,
                "siteType": "ГР.",
                "siteName": "РУДОЗЕМ",
                "postCode": "4960",
                "streetId": 83873,
                "streetType": "УЛ.",
                "streetName": "КАП. ПЕТКО ВОЙВОДА",
                "streetNo": "1",
                "addressNote": "ПАЗАРА",
                "x": 24.849004,
                "y": 41.484783,
                "fullAddressString": "гр. РУДОЗЕМ [4960] ул. КАП. ПЕТКО ВОЙВОДА No 1 ПАЗАРА",
                "siteAddressString": "гр. РУДОЗЕМ [4960]",
                "localAddressString": "ул. КАП. ПЕТКО ВОЙВОДА No 1 ПАЗАРА"
            },
            "workingTimeFrom": "10:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "16:00",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 220,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-06-24",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 222,
            "name": "ПЛОВДИВ - ОБОРИЩЕ",
            "nameEn": "PLOVDIV - OBORISHTE",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "streetId": 10775,
                "streetType": "УЛ.",
                "streetName": "ОБОРИЩЕ",
                "streetNo": "5",
                "x": 24.740653,
                "y": 42.156898,
                "fullAddressString": "гр. ПЛОВДИВ ул. ОБОРИЩЕ No 5",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "ул. ОБОРИЩЕ No 5"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 238,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-06-24",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 223,
            "name": "КЪРДЖАЛИ - ЕКЗАРХ ЙОСИФ",
            "nameEn": "KARDZHALI - EKZARH YOSIF",
            "siteId": 40909,
            "address": {
                "countryId": 100,
                "siteId": 40909,
                "siteType": "ГР.",
                "siteName": "КЪРДЖАЛИ",
                "postCode": "6600",
                "streetId": 56081,
                "streetType": "УЛ.",
                "streetName": "ЕКЗАРХ ЙОСИФ",
                "streetNo": "5",
                "x": 25.371336,
                "y": 41.645921,
                "fullAddressString": "гр. КЪРДЖАЛИ [6600] ул. ЕКЗАРХ ЙОСИФ No 5",
                "siteAddressString": "гр. КЪРДЖАЛИ [6600]",
                "localAddressString": "ул. ЕКЗАРХ ЙОСИФ No 5"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 32,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-07-11",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 224,
            "name": "КУБРАТ",
            "nameEn": "KUBRAT",
            "siteId": 40422,
            "address": {
                "countryId": 100,
                "siteId": 40422,
                "siteType": "ГР.",
                "siteName": "КУБРАТ",
                "postCode": "7300",
                "streetId": 79902,
                "streetType": "УЛ.",
                "streetName": "Н. Й. ВАПЦАРОВ",
                "streetNo": "6",
                "x": 26.49858,
                "y": 43.79614,
                "fullAddressString": "гр. КУБРАТ [7300] ул. Н. Й. ВАПЦАРОВ No 6",
                "siteAddressString": "гр. КУБРАТ [7300]",
                "localAddressString": "ул. Н. Й. ВАПЦАРОВ No 6"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "16:00",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 120,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-07-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 225,
            "name": "СОФИЯ - СТУДЕНТСКИ ГРАД - КАУФЛАНД",
            "nameEn": "SOFIA - STUDENTSKI GRAD - KAUFLAND",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 2698,
                "streetType": "УЛ.",
                "streetName": "ПРОФ. КИРИЛ ПОПОВ",
                "streetNo": "59",
                "x": 23.336565,
                "y": 42.643678,
                "fullAddressString": "гр. СОФИЯ ул. ПРОФ. КИРИЛ ПОПОВ No 59",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. ПРОФ. КИРИЛ ПОПОВ No 59"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 200,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 402,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-06-24",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 226,
            "name": "СОФИЯ - ДРАГАЛЕВЦИ",
            "nameEn": "SOFIA - DRAGALEVCI",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 3317,
                "streetType": "УЛ.",
                "streetName": "ЯБЪЛКОВА ГРАДИНА",
                "streetNo": "2",
                "x": 23.309677,
                "y": 42.628423,
                "fullAddressString": "гр. СОФИЯ ул. ЯБЪЛКОВА ГРАДИНА No 2",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. ЯБЪЛКОВА ГРАДИНА No 2"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 75,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-01-02",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 228,
            "name": "ДОБРИНИЩЕ",
            "nameEn": "DOBRINISHTE",
            "siteId": 21498,
            "address": {
                "countryId": 100,
                "siteId": 21498,
                "siteType": "ГР.",
                "siteName": "ДОБРИНИЩЕ",
                "postCode": "2777",
                "streetId": 76762,
                "streetType": "УЛ.",
                "streetName": "АЛЕКСАНДЪР СТАМБОЛИЙСКИ",
                "streetNo": "11",
                "x": 23.560964,
                "y": 41.819,
                "fullAddressString": "гр. ДОБРИНИЩЕ [2777] ул. АЛЕКСАНДЪР СТАМБОЛИЙСКИ No 11",
                "siteAddressString": "гр. ДОБРИНИЩЕ [2777]",
                "localAddressString": "ул. АЛЕКСАНДЪР СТАМБОЛИЙСКИ No 11"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "16:00",
            "sameDayDepartureCutoffHalf": "11:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 34,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "11:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-06-03",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 230,
            "name": "ВАРНА - СТАРА ПЛАНИНА",
            "nameEn": "VARNA - STARА PLANINA",
            "siteId": 10135,
            "address": {
                "countryId": 100,
                "siteId": 10135,
                "siteType": "ГР.",
                "siteName": "ВАРНА",
                "postCode": "9000",
                "streetId": 12695,
                "streetType": "УЛ.",
                "streetName": "СТАРА ПЛАНИНА",
                "streetNo": "34-36",
                "x": 27.899399,
                "y": 43.210611,
                "fullAddressString": "гр. ВАРНА ул. СТАРА ПЛАНИНА No 34-36",
                "siteAddressString": "гр. ВАРНА",
                "localAddressString": "ул. СТАРА ПЛАНИНА No 34-36"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 220
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 662,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-08-27",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 231,
            "name": "ПОЛСКИ ТРЪМБЕШ",
            "nameEn": "POLSKI TRAMBESH",
            "siteId": 57354,
            "address": {
                "countryId": 100,
                "siteId": 57354,
                "siteType": "ГР.",
                "siteName": "ПОЛСКИ ТРЪМБЕШ",
                "postCode": "5180",
                "streetId": 82802,
                "streetType": "УЛ.",
                "streetName": "ТЪРГОВСКА",
                "streetNo": "78",
                "x": 25.636007,
                "y": 43.369291,
                "fullAddressString": "гр. ПОЛСКИ ТРЪМБЕШ [5180] ул. ТЪРГОВСКА No 78",
                "siteAddressString": "гр. ПОЛСКИ ТРЪМБЕШ [5180]",
                "localAddressString": "ул. ТЪРГОВСКА No 78"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 11,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-06-20",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 232,
            "name": "ЕЛИН ПЕЛИН - НИКОЛАЙ ЧУДОТВОРЕЦ (ПЛ.)",
            "nameEn": "ELIN PELIN - NIKOLAY CHUDOTVORETS (PL.)",
            "siteId": 27303,
            "address": {
                "countryId": 100,
                "siteId": 27303,
                "siteType": "ГР.",
                "siteName": "ЕЛИН ПЕЛИН",
                "postCode": "2100",
                "streetId": 77657,
                "streetType": "УЛ.",
                "streetName": "КИРИЛ И МЕТОДИЙ",
                "streetNo": "6",
                "x": 23.60422,
                "y": 42.66994,
                "fullAddressString": "гр. ЕЛИН ПЕЛИН [2100] ул. КИРИЛ И МЕТОДИЙ No 6",
                "siteAddressString": "гр. ЕЛИН ПЕЛИН [2100]",
                "localAddressString": "ул. КИРИЛ И МЕТОДИЙ No 6"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 350,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-07-12",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 233,
            "name": "ШУМЕН - СЪЕДИНЕНИЕ",
            "nameEn": "SHUMEN - SAEDINENIE",
            "siteId": 83510,
            "address": {
                "countryId": 100,
                "siteId": 83510,
                "siteType": "ГР.",
                "siteName": "ШУМЕН",
                "postCode": "9700",
                "streetId": 24265,
                "streetType": "УЛ.",
                "streetName": "СЪЕДИНЕНИЕ",
                "streetNo": "123",
                "addressNote": "ДО СЪДЕБНА ПАЛАТА",
                "x": 26.921343,
                "y": 43.269456,
                "fullAddressString": "гр. ШУМЕН [9700] ул. СЪЕДИНЕНИЕ No 123 ДО СЪДЕБНА ПАЛАТА",
                "siteAddressString": "гр. ШУМЕН [9700]",
                "localAddressString": "ул. СЪЕДИНЕНИЕ No 123 ДО СЪДЕБНА ПАЛАТА"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 253,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-08-20",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 234,
            "name": "ВАРНА - ПОБЕДА (Ж.К.)",
            "nameEn": "VARNA - POBEDA (ZH.K.)",
            "siteId": 10135,
            "address": {
                "countryId": 100,
                "siteId": 10135,
                "siteType": "ГР.",
                "siteName": "ВАРНА",
                "postCode": "9000",
                "streetId": 12734,
                "streetType": "УЛ.",
                "streetName": "ТОДОР РАДЕВ ПЕНЕВ",
                "streetNo": "13",
                "complexId": 609,
                "complexType": "ЖК",
                "complexName": "ПОБЕДА",
                "addressNote": "МАГАЗИН 3",
                "x": 27.901555,
                "y": 43.225472,
                "fullAddressString": "гр. ВАРНА жк ПОБЕДА ул. ТОДОР РАДЕВ ПЕНЕВ No 13 МАГАЗИН 3",
                "siteAddressString": "гр. ВАРНА",
                "localAddressString": "жк ПОБЕДА ул. ТОДОР РАДЕВ ПЕНЕВ No 13 МАГАЗИН 3"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 220
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 786,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-09-10",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 235,
            "name": "СОФИЯ - СИМЕОНОВО",
            "nameEn": "SOFIA - SIMEONOVO",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 108,
                "streetType": "БУЛ.",
                "streetName": "СИМЕОНОВСКО ШОСЕ",
                "streetNo": "205",
                "x": 23.338314,
                "y": 42.615576,
                "fullAddressString": "гр. СОФИЯ бул. СИМЕОНОВСКО ШОСЕ No 205",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "бул. СИМЕОНОВСКО ШОСЕ No 205"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 402,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-08-23",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 236,
            "name": "СОФИЯ - ЗЛАТОВРЪХ",
            "nameEn": "SOFIA - ZLATOVRAH",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 1777,
                "streetType": "УЛ.",
                "streetName": "ЗЛАТОВРЪХ",
                "streetNo": "11",
                "complexId": 36,
                "complexType": "ЖК",
                "complexName": "ЛОЗЕНЕЦ",
                "addressNote": "МАГАЗИН 2",
                "x": 23.333837,
                "y": 42.673392,
                "fullAddressString": "гр. СОФИЯ жк ЛОЗЕНЕЦ ул. ЗЛАТОВРЪХ No 11 МАГАЗИН 2",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк ЛОЗЕНЕЦ ул. ЗЛАТОВРЪХ No 11 МАГАЗИН 2"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 828,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-10-31",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 237,
            "name": "ЧИРПАН - ЦЕНТЪР",
            "nameEn": "CHIRPAN - CENTER",
            "siteId": 81414,
            "address": {
                "countryId": 100,
                "siteId": 81414,
                "siteType": "ГР.",
                "siteName": "ЧИРПАН",
                "postCode": "6200",
                "streetId": 95528,
                "streetType": "УЛ.",
                "streetName": "ПЕЙО ЯВОРОВ",
                "streetNo": "9",
                "x": 25.326383,
                "y": 42.199907,
                "fullAddressString": "гр. ЧИРПАН [6200] ул. ПЕЙО ЯВОРОВ No 9",
                "siteAddressString": "гр. ЧИРПАН [6200]",
                "localAddressString": "ул. ПЕЙО ЯВОРОВ No 9"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 454,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-08-14",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 238,
            "name": "ПЛОВДИВ - РИВА ЦЕНТЪР",
            "nameEn": "PLOVDIV - RIVA CENTER",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "streetId": 10488,
                "streetType": "УЛ.",
                "streetName": "ЗЛАТНА ПАНЕГА",
                "streetNo": "4",
                "x": 24.733068,
                "y": 42.155299,
                "fullAddressString": "гр. ПЛОВДИВ ул. ЗЛАТНА ПАНЕГА No 4",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "ул. ЗЛАТНА ПАНЕГА No 4"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 222,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-10-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 239,
            "name": "ПЛОВДИВ - КОМПЛЕКС ГЕРБЕР",
            "nameEn": "PLOVDIV - COMPLEX GERBER",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "complexId": 504,
                "complexType": "ЖК",
                "complexName": "ТРАКИЯ",
                "blockNo": "194",
                "entranceNo": "Г",
                "x": 24.777059,
                "y": 42.129828,
                "fullAddressString": "гр. ПЛОВДИВ жк ТРАКИЯ бл. 194 вх. Г",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "жк ТРАКИЯ бл. 194 вх. Г"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 336,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-10-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 240,
            "name": "СОФИЯ - МЛАДОСТ-ШЕЛ",
            "nameEn": "SOFIA MLADOST-SHELL",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 4,
                "streetType": "БУЛ.",
                "streetName": "АЛЕКСАНДЪР МАЛИНОВ",
                "complexId": 49,
                "complexType": "ЖК",
                "complexName": "МЛАДОСТ 2",
                "x": 23.376235,
                "y": 42.647818,
                "fullAddressString": "гр. СОФИЯ жк МЛАДОСТ 2 бул. АЛЕКСАНДЪР МАЛИНОВ",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк МЛАДОСТ 2 бул. АЛЕКСАНДЪР МАЛИНОВ"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "19:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 98,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-10-28",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 241,
            "name": "ВАРНА - ВЪЗРАЖДАНЕ (ЖК)",
            "nameEn": "VARNA - VAZRAZHDANE (ZHK)",
            "siteId": 10135,
            "address": {
                "countryId": 100,
                "siteId": 10135,
                "siteType": "ГР.",
                "siteName": "ВАРНА",
                "postCode": "9000",
                "streetId": 98305,
                "streetType": "УЛ.",
                "streetName": "ЗДРАВКО ЧАКЪРОВ",
                "streetNo": "1",
                "addressNote": "ПАРТЕР",
                "x": 27.874417,
                "y": 43.237536,
                "fullAddressString": "гр. ВАРНА ул. ЗДРАВКО ЧАКЪРОВ No 1 ПАРТЕР",
                "siteAddressString": "гр. ВАРНА",
                "localAddressString": "ул. ЗДРАВКО ЧАКЪРОВ No 1 ПАРТЕР"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 220
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 111,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-11-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 242,
            "name": "ВАРНА - СТРАХИЛ ВОЙВОДА (УЛ.)",
            "nameEn": "VARNA - STRAHIL VOYVODA (UL.)",
            "siteId": 10135,
            "address": {
                "countryId": 100,
                "siteId": 10135,
                "siteType": "ГР.",
                "siteName": "ВАРНА",
                "postCode": "9000",
                "streetId": 12713,
                "streetType": "УЛ.",
                "streetName": "СТРАХИЛ ВОЙВОДА",
                "streetNo": "38",
                "x": 27.90951,
                "y": 43.215466,
                "fullAddressString": "гр. ВАРНА ул. СТРАХИЛ ВОЙВОДА No 38",
                "siteAddressString": "гр. ВАРНА",
                "localAddressString": "ул. СТРАХИЛ ВОЙВОДА No 38"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 220
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 267,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-10-18",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 243,
            "name": "СТАРА ЗАГОРА - АЯЗМОTO",
            "nameEn": "STARA ZAGORA - AYAZMOTO",
            "siteId": 68850,
            "address": {
                "countryId": 100,
                "siteId": 68850,
                "siteType": "ГР.",
                "siteName": "СТАРА ЗАГОРА",
                "postCode": "6000",
                "streetId": 18297,
                "streetType": "УЛ.",
                "streetName": "СВ. ОТЕЦ ПАИСИЙ",
                "streetNo": "179",
                "x": 25.61926,
                "y": 42.430319,
                "fullAddressString": "гр. СТАРА ЗАГОРА ул. СВ. ОТЕЦ ПАИСИЙ No 179",
                "siteAddressString": "гр. СТАРА ЗАГОРА",
                "localAddressString": "ул. СВ. ОТЕЦ ПАИСИЙ No 179"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 838,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-12-02",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 245,
            "name": "ДОБРИЧ - ДУНАВ",
            "nameEn": "DOBRICH - DUNAV",
            "siteId": 72624,
            "address": {
                "countryId": 100,
                "siteId": 72624,
                "siteType": "ГР.",
                "siteName": "ДОБРИЧ",
                "postCode": "9300",
                "streetId": 22099,
                "streetType": "УЛ.",
                "streetName": "ДУНАВ",
                "streetNo": "16",
                "x": 27.836735,
                "y": 43.563915,
                "fullAddressString": "гр. ДОБРИЧ [9300] ул. ДУНАВ No 16",
                "siteAddressString": "гр. ДОБРИЧ [9300]",
                "localAddressString": "ул. ДУНАВ No 16"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 220
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 852,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-11-04",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 246,
            "name": "ЯМБОЛ - МАГАЗИН САРА",
            "nameEn": "YAMBOL - MAGAZIN SARA",
            "siteId": 87374,
            "address": {
                "countryId": 100,
                "siteId": 87374,
                "siteType": "ГР.",
                "siteName": "ЯМБОЛ",
                "postCode": "8600",
                "streetId": 26196,
                "streetType": "УЛ.",
                "streetName": "МИЛИН КАМЪК",
                "streetNo": "43",
                "x": 26.527315,
                "y": 42.475405,
                "fullAddressString": "гр. ЯМБОЛ [8600] ул. МИЛИН КАМЪК No 43",
                "siteAddressString": "гр. ЯМБОЛ [8600]",
                "localAddressString": "ул. МИЛИН КАМЪК No 43"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 55,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-10-28",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 247,
            "name": "ПЛЕВЕН - ХАДЖИ ДИМИТЪР (УЛ.)",
            "nameEn": "PLEVEN - HADZHI DIMITAR (UL.)",
            "siteId": 56722,
            "address": {
                "countryId": 100,
                "siteId": 56722,
                "siteType": "ГР.",
                "siteName": "ПЛЕВЕН",
                "postCode": "5800",
                "streetId": 20363,
                "streetType": "УЛ.",
                "streetName": "ХАДЖИ ДИМИТЪР",
                "streetNo": "52",
                "x": 24.613172,
                "y": 43.403717,
                "fullAddressString": "гр. ПЛЕВЕН ул. ХАДЖИ ДИМИТЪР No 52",
                "siteAddressString": "гр. ПЛЕВЕН",
                "localAddressString": "ул. ХАДЖИ ДИМИТЪР No 52"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 106,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-11-04",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 248,
            "name": "ПЛЕВЕН - НЕОФИТ РИЛСКИ",
            "nameEn": "PLEVEN- NEOFIT RILSKI",
            "siteId": 56722,
            "address": {
                "countryId": 100,
                "siteId": 56722,
                "siteType": "ГР.",
                "siteName": "ПЛЕВЕН",
                "postCode": "5800",
                "streetId": 20244,
                "streetType": "УЛ.",
                "streetName": "НЕОФИТ РИЛСКИ",
                "streetNo": "32А",
                "x": 24.623539,
                "y": 43.415721,
                "fullAddressString": "гр. ПЛЕВЕН ул. НЕОФИТ РИЛСКИ No 32А",
                "siteAddressString": "гр. ПЛЕВЕН",
                "localAddressString": "ул. НЕОФИТ РИЛСКИ No 32А"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 745,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-11-18",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PALLET",
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": true,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 249,
            "name": "ДИМИТРОВГРАД - ДИМИТЪР БЛАГОЕВ (БУЛ.)",
            "nameEn": "DIMITROVGRAD - DIMITAR BLAGOEV (BUL.)",
            "siteId": 21052,
            "address": {
                "countryId": 100,
                "siteId": 21052,
                "siteType": "ГР.",
                "siteName": "ДИМИТРОВГРАД",
                "postCode": "6400",
                "streetId": 68372,
                "streetType": "БУЛ.",
                "streetName": "ДИМИТЪР БЛАГОЕВ",
                "streetNo": "5",
                "x": 25.589304,
                "y": 42.056722,
                "fullAddressString": "гр. ДИМИТРОВГРАД [6400] бул. ДИМИТЪР БЛАГОЕВ No 5",
                "siteAddressString": "гр. ДИМИТРОВГРАД [6400]",
                "localAddressString": "бул. ДИМИТЪР БЛАГОЕВ No 5"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 42,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-03-09",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 250,
            "name": "СВИЩОВ - ХАН БЪЧВА",
            "nameEn": "SVISHTOV-HAN BUCHVA",
            "siteId": 65766,
            "address": {
                "countryId": 100,
                "siteId": 65766,
                "siteType": "ГР.",
                "siteName": "СВИЩОВ",
                "postCode": "5250",
                "streetId": 89469,
                "streetType": "УЛ.",
                "streetName": "ПАТРИАРХ ЕВТИМИЙ",
                "streetNo": "99А",
                "addressNote": "МАГАЗИН 3",
                "x": 25.35551,
                "y": 43.61232,
                "fullAddressString": "гр. СВИЩОВ [5250] ул. ПАТРИАРХ ЕВТИМИЙ No 99А МАГАЗИН 3",
                "siteAddressString": "гр. СВИЩОВ [5250]",
                "localAddressString": "ул. ПАТРИАРХ ЕВТИМИЙ No 99А МАГАЗИН 3"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 170,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 36,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-12-16",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 253,
            "name": "ШУМЕН - ВИЕНСКА ГРАДИНА",
            "nameEn": "SHUMEN - VIENSKA GRADINA",
            "siteId": 83510,
            "address": {
                "countryId": 100,
                "siteId": 83510,
                "siteType": "ГР.",
                "siteName": "ШУМЕН",
                "postCode": "9700",
                "streetId": 24244,
                "streetType": "БУЛ.",
                "streetName": "СИМЕОН ВЕЛИКИ",
                "streetNo": "74",
                "addressNote": "ДО ВИЕНСКА ГРАДИНА",
                "x": 26.917818,
                "y": 43.272257,
                "fullAddressString": "гр. ШУМЕН [9700] бул. СИМЕОН ВЕЛИКИ No 74 ДО ВИЕНСКА ГРАДИНА",
                "siteAddressString": "гр. ШУМЕН [9700]",
                "localAddressString": "бул. СИМЕОН ВЕЛИКИ No 74 ДО ВИЕНСКА ГРАДИНА"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 86,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-12-16",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 254,
            "name": "СТАРА ЗАГОРА - ЖЕЛЕЗНИК",
            "nameEn": "STARA ZAGORA - ZHELEZNIK",
            "siteId": 68850,
            "address": {
                "countryId": 100,
                "siteId": 68850,
                "siteType": "ГР.",
                "siteName": "СТАРА ЗАГОРА",
                "postCode": "6000",
                "streetId": 18013,
                "streetType": "БУЛ.",
                "streetName": "ЦАР СИМЕОН ВЕЛИКИ",
                "addressNote": "С/У М-Н ЗОРА",
                "x": 25.597839,
                "y": 42.413223,
                "fullAddressString": "гр. СТАРА ЗАГОРА бул. ЦАР СИМЕОН ВЕЛИКИ С/У М-Н ЗОРА",
                "siteAddressString": "гр. СТАРА ЗАГОРА",
                "localAddressString": "бул. ЦАР СИМЕОН ВЕЛИКИ С/У М-Н ЗОРА"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 122,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-12-02",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 255,
            "name": "СОФИЯ - СЛАТИНА (Ж.К.)",
            "nameEn": "SOFIA - SLATINA (ZH.K.)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 2911,
                "streetType": "УЛ.",
                "streetName": "СЛАТИНСКА",
                "complexId": 69,
                "complexType": "ЖК",
                "complexName": "СЛАТИНА",
                "blockNo": "44А",
                "entranceNo": "Б",
                "x": 23.370772,
                "y": 42.688121,
                "fullAddressString": "гр. СОФИЯ жк СЛАТИНА ул. СЛАТИНСКА бл. 44А вх. Б",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк СЛАТИНА ул. СЛАТИНСКА бл. 44А вх. Б"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 134,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-12-02",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 256,
            "name": "БУРГАС - ЗОРНИЦА - МАКДОНАЛДС",
            "nameEn": "BURGAS - ZORNITSA - MCDONALDS",
            "siteId": 7079,
            "address": {
                "countryId": 100,
                "siteId": 7079,
                "siteType": "ГР.",
                "siteName": "БУРГАС",
                "postCode": "8000",
                "complexId": 709,
                "complexType": "ЖК",
                "complexName": "ЗОРНИЦА",
                "blockNo": "50",
                "addressNote": "ДО МАКДОНАЛДС",
                "x": 27.462033,
                "y": 42.516777,
                "fullAddressString": "гр. БУРГАС жк ЗОРНИЦА бл. 50 ДО МАКДОНАЛДС",
                "siteAddressString": "гр. БУРГАС",
                "localAddressString": "жк ЗОРНИЦА бл. 50 ДО МАКДОНАЛДС"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 200
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 719,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-12-16",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 257,
            "name": "СОФИЯ - ЛЮЛИН 1 (Ж.К.)",
            "nameEn": "SOFIA - LYULIN 1 (ZH.K.)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "complexId": 37,
                "complexType": "ЖК",
                "complexName": "ЛЮЛИН 1",
                "blockNo": "2",
                "entranceNo": "1",
                "x": 23.252391,
                "y": 42.730874,
                "fullAddressString": "гр. СОФИЯ жк ЛЮЛИН 1 бл. 2 вх. 1",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк ЛЮЛИН 1 бл. 2 вх. 1"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 138,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-01-13",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 258,
            "name": "СОФИЯ - МЛАДОСТ 4-МЕТРОСТАНЦИЯ",
            "nameEn": "SOFIA - MLADOST 4-METRO STATION",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 4,
                "streetType": "БУЛ.",
                "streetName": "АЛЕКСАНДЪР МАЛИНОВ",
                "streetNo": "91А",
                "complexId": 51,
                "complexType": "ЖК",
                "complexName": "МЛАДОСТ 4",
                "x": 23.373555,
                "y": 42.631103,
                "fullAddressString": "гр. СОФИЯ жк МЛАДОСТ 4 бул. АЛЕКСАНДЪР МАЛИНОВ No 91А",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк МЛАДОСТ 4 бул. АЛЕКСАНДЪР МАЛИНОВ No 91А"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 150
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 66,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-12-09",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 260,
            "name": "СОФИЯ - ЦАР ИВАН АСЕН II (УЛ.)",
            "nameEn": "SOFIA - TSAR IVAN ASEN II (UL.)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 3205,
                "streetType": "УЛ.",
                "streetName": "ЦАР ИВАН АСЕН II",
                "streetNo": "39",
                "complexId": 77,
                "complexType": "ЖК",
                "complexName": "ЯВОРОВ",
                "x": 23.34522,
                "y": 42.688783,
                "fullAddressString": "гр. СОФИЯ жк ЯВОРОВ ул. ЦАР ИВАН АСЕН II No 39",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк ЯВОРОВ ул. ЦАР ИВАН АСЕН II No 39"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "19:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 847,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-02-03",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 261,
            "name": "СОФИЯ - СРЕБЪРНА",
            "nameEn": "SOFIA - SREBARNA",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 2939,
                "streetType": "УЛ.",
                "streetName": "СРЕБЪРНА",
                "streetNo": "15",
                "x": 23.318581000000002,
                "y": 42.660478,
                "fullAddressString": "гр. СОФИЯ ул. СРЕБЪРНА No 15",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. СРЕБЪРНА No 15"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 75,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-10-12",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 262,
            "name": "ВАРНА - ТРИ УШИ (УЛ.)",
            "nameEn": "VARNA - TRI USHI (UL.)",
            "siteId": 10135,
            "address": {
                "countryId": 100,
                "siteId": 10135,
                "siteType": "ГР.",
                "siteName": "ВАРНА",
                "postCode": "9000",
                "streetId": 12744,
                "streetType": "УЛ.",
                "streetName": "ТРИ УШИ",
                "streetNo": "5",
                "x": 27.901249,
                "y": 43.205546,
                "fullAddressString": "гр. ВАРНА ул. ТРИ УШИ No 5",
                "siteAddressString": "гр. ВАРНА",
                "localAddressString": "ул. ТРИ УШИ No 5"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 220
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 662,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-03-12",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 263,
            "name": "БЯЛА (ВАРНА)",
            "nameEn": "BYALA (VARNA)",
            "siteId": 7598,
            "address": {
                "countryId": 100,
                "siteId": 7598,
                "siteType": "ГР.",
                "siteName": "БЯЛА",
                "postCode": "9101",
                "streetId": 90516,
                "streetType": "УЛ.",
                "streetName": "АНДРЕЙ ПРЕМЯНОВ",
                "streetNo": "81",
                "x": 27.88717,
                "y": 42.87,
                "fullAddressString": "гр. БЯЛА [9101] ул. АНДРЕЙ ПРЕМЯНОВ No 81",
                "siteAddressString": "гр. БЯЛА [9101]",
                "localAddressString": "ул. АНДРЕЙ ПРЕМЯНОВ No 81"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "17:00",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 229,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-04-21",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 264,
            "name": "КАЗАНЛЪК - ПАЗАРА",
            "nameEn": "KAZANLAK - PAZARA",
            "siteId": 35167,
            "address": {
                "countryId": 100,
                "siteId": 35167,
                "siteType": "ГР.",
                "siteName": "КАЗАНЛЪК",
                "postCode": "6100",
                "streetId": 92048,
                "streetType": "УЛ.",
                "streetName": "НЕОФИТ РИЛСКИ",
                "streetNo": "3",
                "x": 25.39589,
                "y": 42.62207,
                "fullAddressString": "гр. КАЗАНЛЪК [6100] ул. НЕОФИТ РИЛСКИ No 3",
                "siteAddressString": "гр. КАЗАНЛЪК [6100]",
                "localAddressString": "ул. НЕОФИТ РИЛСКИ No 3"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 135,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-02-17",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 265,
            "name": "ХАСКОВО - БАДЕМА",
            "nameEn": "HASKOVO - BADEMA",
            "siteId": 77195,
            "address": {
                "countryId": 100,
                "siteId": 77195,
                "siteType": "ГР.",
                "siteName": "ХАСКОВО",
                "postCode": "6300",
                "streetId": 89765,
                "streetType": "УЛ.",
                "streetName": "22-РИ СЕПТЕМВРИ",
                "streetNo": "12",
                "x": 25.534826,
                "y": 41.937621,
                "fullAddressString": "гр. ХАСКОВО [6300] ул. 22-РИ СЕПТЕМВРИ No 12",
                "siteAddressString": "гр. ХАСКОВО [6300]",
                "localAddressString": "ул. 22-РИ СЕПТЕМВРИ No 12"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 160,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-04-03",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 266,
            "name": "КАВАРНА - ГЕОРГИ КИРКОВ",
            "nameEn": "KAVARNA - GEORGI KIRKOV",
            "siteId": 35064,
            "address": {
                "countryId": 100,
                "siteId": 35064,
                "siteType": "ГР.",
                "siteName": "КАВАРНА",
                "postCode": "9650",
                "streetId": 91719,
                "streetType": "УЛ.",
                "streetName": "ГЕОРГИ КИРКОВ",
                "streetNo": "9",
                "x": 28.33746,
                "y": 43.43528,
                "fullAddressString": "гр. КАВАРНА [9650] ул. ГЕОРГИ КИРКОВ No 9",
                "siteAddressString": "гр. КАВАРНА [9650]",
                "localAddressString": "ул. ГЕОРГИ КИРКОВ No 9"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 220
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 732,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-03-19",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 267,
            "name": "ВАРНА - ГЕОРГИ БЕНКОВСКИ",
            "nameEn": "VARNA - GEORGI BENKOVSKI",
            "siteId": 10135,
            "address": {
                "countryId": 100,
                "siteId": 10135,
                "siteType": "ГР.",
                "siteName": "ВАРНА",
                "postCode": "9000",
                "streetId": 12186,
                "streetType": "УЛ.",
                "streetName": "ГЕОРГИ БЕНКОВСКИ",
                "streetNo": "3",
                "x": 27.911715,
                "y": 43.209679,
                "fullAddressString": "гр. ВАРНА ул. ГЕОРГИ БЕНКОВСКИ No 3",
                "siteAddressString": "гр. ВАРНА",
                "localAddressString": "ул. ГЕОРГИ БЕНКОВСКИ No 3"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 220
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 242,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-03-20",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 268,
            "name": "ВАРНА - АСПАРУХОВО 2",
            "nameEn": "VARNA - ASPARUHOVO 2",
            "siteId": 10135,
            "address": {
                "countryId": 100,
                "siteId": 10135,
                "siteType": "ГР.",
                "siteName": "ВАРНА",
                "postCode": "9000",
                "streetId": 12689,
                "streetType": "УЛ.",
                "streetName": "СРЕДЕЦ",
                "streetNo": "4",
                "addressNote": "ПАРТЕР",
                "x": 27.895145,
                "y": 43.180979,
                "fullAddressString": "гр. ВАРНА ул. СРЕДЕЦ No 4 ПАРТЕР",
                "siteAddressString": "гр. ВАРНА",
                "localAddressString": "ул. СРЕДЕЦ No 4 ПАРТЕР"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 220
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 833,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-03-13",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 269,
            "name": "ВЕЛИКО ТЪРНОВО - ХАЛИТЕ",
            "nameEn": "VELIKO TARNOVO - HALITE",
            "siteId": 10447,
            "address": {
                "countryId": 100,
                "siteId": 10447,
                "siteType": "ГР.",
                "siteName": "ВЕЛИКО ТЪРНОВО",
                "postCode": "5000",
                "streetId": 34351,
                "streetType": "ПЛ.",
                "streetName": "ПЕНЧО СЛАВЕЙКОВ",
                "streetNo": "11",
                "x": 25.636253,
                "y": 43.085008,
                "fullAddressString": "гр. ВЕЛИКО ТЪРНОВО пл. ПЕНЧО СЛАВЕЙКОВ No 11",
                "siteAddressString": "гр. ВЕЛИКО ТЪРНОВО",
                "localAddressString": "пл. ПЕНЧО СЛАВЕЙКОВ No 11"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 196,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-03-16",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 270,
            "name": "БЛАГОЕВГРАД - ИВАН РИЛСКИ (УЛ.)",
            "nameEn": "BLAGOEVGRAD - IVAN RILSKI (UL.)",
            "siteId": 4279,
            "address": {
                "countryId": 100,
                "siteId": 4279,
                "siteType": "ГР.",
                "siteName": "БЛАГОЕВГРАД",
                "postCode": "2700",
                "streetId": 30221,
                "streetType": "УЛ.",
                "streetName": "СВ. ИВАН РИЛСКИ",
                "streetNo": "11",
                "addressNote": "ПОД ДЖАМИЯТА",
                "x": 23.101834,
                "y": 42.023124,
                "fullAddressString": "гр. БЛАГОЕВГРАД ул. СВ. ИВАН РИЛСКИ No 11 ПОД ДЖАМИЯТА",
                "siteAddressString": "гр. БЛАГОЕВГРАД",
                "localAddressString": "ул. СВ. ИВАН РИЛСКИ No 11 ПОД ДЖАМИЯТА"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "12:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 137,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "12:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-03-04",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 272,
            "name": "ПАВЛИКЕНИ - ИЛИЯ ЗЛАТЕВ (УЛ.)",
            "nameEn": "PAVLIKENI - ILIYA ZLATEV (UL.)",
            "siteId": 55052,
            "address": {
                "countryId": 100,
                "siteId": 55052,
                "siteType": "ГР.",
                "siteName": "ПАВЛИКЕНИ",
                "postCode": "5200",
                "streetId": 82321,
                "streetType": "УЛ.",
                "streetName": "ИЛИЯ ЗЛАТЕВ",
                "streetNo": "9",
                "x": 25.304217,
                "y": 43.238622,
                "fullAddressString": "гр. ПАВЛИКЕНИ [5200] ул. ИЛИЯ ЗЛАТЕВ No 9",
                "siteAddressString": "гр. ПАВЛИКЕНИ [5200]",
                "localAddressString": "ул. ИЛИЯ ЗЛАТЕВ No 9"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "12:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 11,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "12:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-03-18",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 273,
            "name": "БУРГАС - ОБОРИЩЕ",
            "nameEn": "BURGAS - OBORISCHTE",
            "siteId": 7079,
            "address": {
                "countryId": 100,
                "siteId": 7079,
                "siteType": "ГР.",
                "siteName": "БУРГАС",
                "postCode": "8000",
                "streetId": 14210,
                "streetType": "УЛ.",
                "streetName": "ОБОРИЩЕ",
                "streetNo": "68",
                "x": 27.475657,
                "y": 42.498735,
                "fullAddressString": "гр. БУРГАС ул. ОБОРИЩЕ No 68",
                "siteAddressString": "гр. БУРГАС",
                "localAddressString": "ул. ОБОРИЩЕ No 68"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 180,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 115,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-05-04",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 274,
            "name": "ПИРДОП",
            "nameEn": "PIRDOP",
            "siteId": 56407,
            "address": {
                "countryId": 100,
                "siteId": 56407,
                "siteType": "ГР.",
                "siteName": "ПИРДОП",
                "postCode": "2070",
                "streetId": 96417,
                "streetType": "УЛ.",
                "streetName": "ТОДОР ВЛАЙКОВ",
                "streetNo": "15",
                "x": 24.178344,
                "y": 42.70254,
                "fullAddressString": "гр. ПИРДОП [2070] ул. ТОДОР ВЛАЙКОВ No 15",
                "siteAddressString": "гр. ПИРДОП [2070]",
                "localAddressString": "ул. ТОДОР ВЛАЙКОВ No 15"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "12:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 441,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "12:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-04-13",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 275,
            "name": "ПЛЕВЕН - САН СТЕФАНО (УЛ.)",
            "nameEn": "PLEVEN - SAN STEFANO (UL.)",
            "siteId": 56722,
            "address": {
                "countryId": 100,
                "siteId": 56722,
                "siteType": "ГР.",
                "siteName": "ПЛЕВЕН",
                "postCode": "5800",
                "streetId": 20310,
                "streetType": "УЛ.",
                "streetName": "САН СТЕФАНО",
                "streetNo": "35",
                "x": 24.622275,
                "y": 43.400569,
                "fullAddressString": "гр. ПЛЕВЕН ул. САН СТЕФАНО No 35",
                "siteAddressString": "гр. ПЛЕВЕН",
                "localAddressString": "ул. САН СТЕФАНО No 35"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 284,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-05-18",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 276,
            "name": "ПЛОВДИВ - ТРАКИЯ (ЖК), БЛ.1",
            "nameEn": "PLOVDIV - TRAKIA (ZHK), BL.1",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "streetId": 10026,
                "streetType": "БУЛ.",
                "streetName": "ОСВОБОЖДЕНИЕ",
                "streetNo": "31",
                "x": 24.788176,
                "y": 42.141635,
                "fullAddressString": "гр. ПЛОВДИВ бул. ОСВОБОЖДЕНИЕ No 31",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "бул. ОСВОБОЖДЕНИЕ No 31"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 837,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-05-11",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 277,
            "name": "СОФИЯ - КРАСНО СЕЛО – ЛАГЕРА",
            "nameEn": "SOFIA - KRASNO SELO - LAGERA",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 3164,
                "streetType": "УЛ.",
                "streetName": "ХРИЗАНТЕМА",
                "streetNo": "19-21",
                "x": 23.284152,
                "y": 42.680123,
                "fullAddressString": "гр. СОФИЯ ул. ХРИЗАНТЕМА No 19-21",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. ХРИЗАНТЕМА No 19-21"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 323,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-05-18",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 278,
            "name": "ДОЛНА МИТРОПОЛИЯ",
            "nameEn": "DOLNA MITROPOLIYA",
            "siteId": 22215,
            "address": {
                "countryId": 100,
                "siteId": 22215,
                "siteType": "ГР.",
                "siteName": "ДОЛНА МИТРОПОЛИЯ",
                "postCode": "5855",
                "streetId": 76960,
                "streetType": "УЛ.",
                "streetName": "ХРИСТО БОТЕВ",
                "streetNo": "2",
                "x": 24.530809,
                "y": 43.468218,
                "fullAddressString": "гр. ДОЛНА МИТРОПОЛИЯ [5855] ул. ХРИСТО БОТЕВ No 2",
                "siteAddressString": "гр. ДОЛНА МИТРОПОЛИЯ [5855]",
                "localAddressString": "ул. ХРИСТО БОТЕВ No 2"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 12,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-04-13",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 279,
            "name": "СОФИЯ - МАЛЬОВИЦА (УЛ.)",
            "nameEn": "SOFIA - MALYOVITSA (UL.)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 2229,
                "streetType": "УЛ.",
                "streetName": "МАЛЬОВИЦА",
                "streetNo": "1",
                "x": 23.328161,
                "y": 42.689184,
                "fullAddressString": "гр. СОФИЯ ул. МАЛЬОВИЦА No 1",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. МАЛЬОВИЦА No 1"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 873,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-05-11",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 280,
            "name": "ПЕТРИЧ - ЦАР СИМЕОН (УЛ.)",
            "nameEn": "PETRICH - TSAR SIMEON (UL.)",
            "siteId": 56126,
            "address": {
                "countryId": 100,
                "siteId": 56126,
                "siteType": "ГР.",
                "siteName": "ПЕТРИЧ",
                "postCode": "2850",
                "streetId": 88756,
                "streetType": "УЛ.",
                "streetName": "ЦАР СИМЕОН",
                "streetNo": "21",
                "addressNote": "СРЕЩУ РАЙ ПОЛЯНИТЕ",
                "x": 23.199209,
                "y": 41.394043,
                "fullAddressString": "гр. ПЕТРИЧ [2850] ул. ЦАР СИМЕОН No 21 СРЕЩУ РАЙ ПОЛЯНИТЕ",
                "siteAddressString": "гр. ПЕТРИЧ [2850]",
                "localAddressString": "ул. ЦАР СИМЕОН No 21 СРЕЩУ РАЙ ПОЛЯНИТЕ"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "16:00",
            "sameDayDepartureCutoffHalf": "12:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 139,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "12:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-05-11",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 281,
            "name": "СОФИЯ - НАДЕЖДА (ЖК)",
            "nameEn": "SOFIA - NADEZHDA (ZHK)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 2209,
                "streetType": "УЛ.",
                "streetName": "МАЙСКА ПЕСЕН",
                "streetNo": "13",
                "complexId": 171,
                "complexType": "ЖК",
                "complexName": "НАДЕЖДА",
                "x": 23.294712,
                "y": 42.7379,
                "fullAddressString": "гр. СОФИЯ жк НАДЕЖДА ул. МАЙСКА ПЕСЕН No 13",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк НАДЕЖДА ул. МАЙСКА ПЕСЕН No 13"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 538,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-06-22",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 282,
            "name": "СОФИЯ - ОБЕЛЯ (ЖК)",
            "nameEn": "SOFIA - OBELYA (ZHK)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "complexId": 60,
                "complexType": "ЖК",
                "complexName": "ОБЕЛЯ 1",
                "blockNo": "110",
                "entranceNo": "А",
                "addressNote": "ПАРТЕР",
                "x": 23.270415,
                "y": 42.737507,
                "fullAddressString": "гр. СОФИЯ жк ОБЕЛЯ 1 бл. 110 вх. А ПАРТЕР",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк ОБЕЛЯ 1 бл. 110 вх. А ПАРТЕР"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 437,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-06-29",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 283,
            "name": "РАДОМИР - ЕВЛОГИ ГЕОРГИЕВ (УЛ.)",
            "nameEn": "RADOMIR - EVLOGI GEORGIEV",
            "siteId": 61577,
            "address": {
                "countryId": 100,
                "siteId": 61577,
                "siteType": "ГР.",
                "siteName": "РАДОМИР",
                "postCode": "2400",
                "streetId": 93820,
                "streetType": "УЛ.",
                "streetName": "БРАТЯ МИЛЕНКОВИ",
                "streetNo": "16",
                "x": 22.963407,
                "y": 42.542933,
                "fullAddressString": "гр. РАДОМИР [2400] ул. БРАТЯ МИЛЕНКОВИ No 16",
                "siteAddressString": "гр. РАДОМИР [2400]",
                "localAddressString": "ул. БРАТЯ МИЛЕНКОВИ No 16"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "12:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 540,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "12:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-05-11",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 284,
            "name": "ПЛЕВЕН - ДРУЖБА (ЖК)",
            "nameEn": "PLEVEN - DRUZHBA (ZHK)",
            "siteId": 56722,
            "address": {
                "countryId": 100,
                "siteId": 56722,
                "siteType": "ГР.",
                "siteName": "ПЛЕВЕН",
                "postCode": "5800",
                "streetId": 102906,
                "streetType": "УЛ.",
                "streetName": "БЪЛГАРИЯ",
                "streetNo": "17",
                "x": 24.634661,
                "y": 43.404265,
                "fullAddressString": "гр. ПЛЕВЕН ул. БЪЛГАРИЯ No 17",
                "siteAddressString": "гр. ПЛЕВЕН",
                "localAddressString": "ул. БЪЛГАРИЯ No 17"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 275,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-04-27",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 285,
            "name": "СОФИЯ - АСЕН РАЗЦВЕТНИКОВ (УЛ.)",
            "nameEn": "SOFIA - ASEN RAZTSVETNIKOV (UL.)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 1004,
                "streetType": "УЛ.",
                "streetName": "АСЕН РАЗЦВЕТНИКОВ",
                "streetNo": "1",
                "complexId": 82,
                "complexType": "КВ.",
                "complexName": "ВИТОША",
                "x": 23.333808,
                "y": 42.647715,
                "fullAddressString": "гр. СОФИЯ кв. ВИТОША ул. АСЕН РАЗЦВЕТНИКОВ No 1",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "кв. ВИТОША ул. АСЕН РАЗЦВЕТНИКОВ No 1"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "19:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 781,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-06-22",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 287,
            "name": "БУРГАС - ВЪЗРАЖДАНЕ (ЖК), ЦАР САМУИЛ",
            "nameEn": "BURGAS - VAZRAZHDANE (ZHK), TSAR SAMUIL",
            "siteId": 7079,
            "address": {
                "countryId": 100,
                "siteId": 7079,
                "siteType": "ГР.",
                "siteName": "БУРГАС",
                "postCode": "8000",
                "streetId": 14349,
                "streetType": "УЛ.",
                "streetName": "ЦАР САМУИЛ",
                "streetNo": "92",
                "complexId": 712,
                "complexType": "ЖК",
                "complexName": "ВЪЗРАЖДАНЕ",
                "x": 27.46121,
                "y": 42.499658,
                "fullAddressString": "гр. БУРГАС жк ВЪЗРАЖДАНЕ ул. ЦАР САМУИЛ No 92",
                "siteAddressString": "гр. БУРГАС",
                "localAddressString": "жк ВЪЗРАЖДАНЕ ул. ЦАР САМУИЛ No 92"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 180,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 602,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-11-09",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 288,
            "name": "СОФИЯ - НИШАВА",
            "nameEn": "SOFIA - NISHAVA",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 1775,
                "streetType": "УЛ.",
                "streetName": "ЗЛАТНА ВРАТА",
                "streetNo": "17",
                "complexId": 71,
                "complexType": "ЖК",
                "complexName": "СТРЕЛБИЩЕ",
                "x": 23.302401,
                "y": 42.671665,
                "fullAddressString": "гр. СОФИЯ жк СТРЕЛБИЩЕ ул. ЗЛАТНА ВРАТА No 17",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк СТРЕЛБИЩЕ ул. ЗЛАТНА ВРАТА No 17"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 300,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-08-03",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 289,
            "name": "САНДАНСКИ - ХРИСТО БОТЕВ (УЛ.)",
            "nameEn": "SANDANSKI - HRISTO BOTEV (UL.)",
            "siteId": 65334,
            "address": {
                "countryId": 100,
                "siteId": 65334,
                "siteType": "ГР.",
                "siteName": "САНДАНСКИ",
                "postCode": "2800",
                "streetId": 89179,
                "streetType": "УЛ.",
                "streetName": "ХРИСТО БОТЕВ",
                "streetNo": "2",
                "addressNote": "НАД ЧЕРКВАТА",
                "x": 23.278903,
                "y": 41.566423,
                "fullAddressString": "гр. САНДАНСКИ [2800] ул. ХРИСТО БОТЕВ No 2 НАД ЧЕРКВАТА",
                "siteAddressString": "гр. САНДАНСКИ [2800]",
                "localAddressString": "ул. ХРИСТО БОТЕВ No 2 НАД ЧЕРКВАТА"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "16:45",
            "sameDayDepartureCutoffHalf": "12:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 116,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:45",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:45",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:45",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "12:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:45",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:45",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:45",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:45",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:45",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-07-13",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 290,
            "name": "СОФИЯ - ПРЕЗИДЕНТ ЛИНКЪЛН",
            "nameEn": "SOFIA - PRESIDENT LINCOLN",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 5097,
                "streetType": "БУЛ.",
                "streetName": "ПРЕЗИДЕНТ ЛИНКЪЛН",
                "addressNote": "ПАЗАР ОВЧА КУПЕЛ 2",
                "x": 23.241535,
                "y": 42.68442,
                "fullAddressString": "гр. СОФИЯ бул. ПРЕЗИДЕНТ ЛИНКЪЛН ПАЗАР ОВЧА КУПЕЛ 2",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "бул. ПРЕЗИДЕНТ ЛИНКЪЛН ПАЗАР ОВЧА КУПЕЛ 2"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 391,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-10-20",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 291,
            "name": "СОФИЯ - ВАРДАР",
            "nameEn": "SOFIA - VARDAR",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 22,
                "streetType": "БУЛ.",
                "streetName": "ВАРДАР",
                "complexId": 364,
                "complexType": "ЖК",
                "complexName": "РАЗСАДНИКА",
                "blockNo": "22А",
                "entranceNo": "Г",
                "x": 23.287108,
                "y": 42.697852,
                "fullAddressString": "гр. СОФИЯ жк РАЗСАДНИКА бул. ВАРДАР бл. 22А вх. Г",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк РАЗСАДНИКА бул. ВАРДАР бл. 22А вх. Г"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 384,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-09-02",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 292,
            "name": "СЛИВЕН - ПЛ. ВАСИЛ ЛЕВСКИ",
            "nameEn": "SLIVEN - VASIL LEVSKI SQ. ",
            "siteId": 67338,
            "address": {
                "countryId": 100,
                "siteId": 67338,
                "siteType": "ГР.",
                "siteName": "СЛИВЕН",
                "postCode": "8800",
                "streetId": 44022,
                "streetType": "ПЛ.",
                "streetName": "ВАСИЛ ЛЕВСКИ",
                "streetNo": "11",
                "x": 26.322727,
                "y": 42.681386,
                "fullAddressString": "гр. СЛИВЕН [8800] пл. ВАСИЛ ЛЕВСКИ No 11",
                "siteAddressString": "гр. СЛИВЕН [8800]",
                "localAddressString": "пл. ВАСИЛ ЛЕВСКИ No 11"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 123,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-08-31",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 294,
            "name": "СОФИЯ - КРИВА РЕКА",
            "nameEn": "SOFIA - KRIVA REKA",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 959,
                "streetType": "УЛ.",
                "streetName": "АМИ БУЕ",
                "streetNo": "17-23",
                "x": 23.303582,
                "y": 42.68757,
                "fullAddressString": "гр. СОФИЯ ул. АМИ БУЕ No 17-23",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. АМИ БУЕ No 17-23"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 773,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-09-21",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 295,
            "name": "СОФИЯ - ОРЛАНДОВЦИ 2",
            "nameEn": "SOFIA - ORLANDOVTSI 2",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 2238,
                "streetType": "УЛ.",
                "streetName": "МАРА БУНЕВА",
                "streetNo": "52",
                "complexId": 102,
                "complexType": "КВ.",
                "complexName": "ОРЛАНДОВЦИ",
                "x": 23.341949,
                "y": 42.721362,
                "fullAddressString": "гр. СОФИЯ кв. ОРЛАНДОВЦИ ул. МАРА БУНЕВА No 52",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "кв. ОРЛАНДОВЦИ ул. МАРА БУНЕВА No 52"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 859,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-07-14",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 298,
            "name": "СОФИЯ - МЛАДОСТ 3 (ЖК), БЛ. 380",
            "nameEn": "SOFIA - MLADOST 3 (ZHK), BL. 380",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "complexId": 50,
                "complexType": "ЖК",
                "complexName": "МЛАДОСТ 3",
                "blockNo": "380",
                "addressNote": "ПАРТЕР",
                "x": 23.382527,
                "y": 42.641271,
                "fullAddressString": "гр. СОФИЯ жк МЛАДОСТ 3 бл. 380 ПАРТЕР",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк МЛАДОСТ 3 бл. 380 ПАРТЕР"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 240,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-10-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 299,
            "name": "СОФИЯ - ОВЧА КУПЕЛ - СТАРАТА ЧАСТ 2",
            "nameEn": "SOFIA - OVCHA KUPEL - STARATA CHAST 2",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 2373,
                "streetType": "УЛ.",
                "streetName": "НАРОДНО ХОРО",
                "streetNo": "11",
                "x": 23.265583,
                "y": 42.679961,
                "fullAddressString": "гр. СОФИЯ ул. НАРОДНО ХОРО No 11",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. НАРОДНО ХОРО No 11"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 165,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-08-14",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 300,
            "name": "СОФИЯ - КОСТЕНСКИ ВОДОПАД",
            "nameEn": "SOFIA - KOSTENSKI VODOPAD",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 2045,
                "streetType": "УЛ.",
                "streetName": "КОСТЕНСКИ ВОДОПАД",
                "streetNo": "14",
                "x": 23.302056,
                "y": 42.668481,
                "fullAddressString": "гр. СОФИЯ ул. КОСТЕНСКИ ВОДОПАД No 14",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. КОСТЕНСКИ ВОДОПАД No 14"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 288,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-11-23",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 301,
            "name": "СОФИЯ - ОДРИН (УЛ.)",
            "nameEn": "SOFIA - ODRIN (UL.)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 2629,
                "streetType": "УЛ.",
                "streetName": "ПОЗИТАНО",
                "blockNo": "11",
                "x": 23.304501,
                "y": 42.698574,
                "fullAddressString": "гр. СОФИЯ ул. ПОЗИТАНО бл. 11",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. ПОЗИТАНО бл. 11"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 172,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-10-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 302,
            "name": "БАНСКО - ВАСИЛ КЪНЧОВ (УЛ.)",
            "nameEn": "BANSKO-VASIL KYNCHOV (UL.)",
            "siteId": 2676,
            "address": {
                "countryId": 100,
                "siteId": 2676,
                "siteType": "ГР.",
                "siteName": "БАНСКО",
                "postCode": "2770",
                "streetId": 66212,
                "streetType": "УЛ.",
                "streetName": "ВАСИЛ КЪНЧОВ",
                "streetNo": "9",
                "x": 23.48495,
                "y": 41.843878,
                "fullAddressString": "гр. БАНСКО [2770] ул. ВАСИЛ КЪНЧОВ No 9",
                "siteAddressString": "гр. БАНСКО [2770]",
                "localAddressString": "ул. ВАСИЛ КЪНЧОВ No 9"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 34,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-09-17",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 303,
            "name": "ВАРНА - ВИНИЦА",
            "nameEn": "VARNA - VINICA",
            "siteId": 10135,
            "address": {
                "countryId": 100,
                "siteId": 10135,
                "siteType": "ГР.",
                "siteName": "ВАРНА",
                "postCode": "9000",
                "streetId": 12660,
                "streetType": "УЛ.",
                "streetName": "СВ. ДИМИТЪР СОЛУНСКИ",
                "streetNo": "4В",
                "x": 27.979351,
                "y": 43.246336,
                "fullAddressString": "гр. ВАРНА ул. СВ. ДИМИТЪР СОЛУНСКИ No 4В",
                "siteAddressString": "гр. ВАРНА",
                "localAddressString": "ул. СВ. ДИМИТЪР СОЛУНСКИ No 4В"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 220
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 429,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-10-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 306,
            "name": "СОФИЯ - ЛОМСКО ШОСЕ",
            "nameEn": "SOFIA - LOMSKO SHOSE",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 74,
                "streetType": "БУЛ.",
                "streetName": "ЛОМСКО ШОСЕ",
                "streetNo": "154",
                "x": 23.295718,
                "y": 42.733241,
                "fullAddressString": "гр. СОФИЯ бул. ЛОМСКО ШОСЕ No 154",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "бул. ЛОМСКО ШОСЕ No 154"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 281,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-12-19",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 307,
            "name": "СОФИЯ - ЧАРЛЗ ДАРВИН (УЛ.)",
            "nameEn": "SOFIA - CHARLES DARWIN (UL.)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 3250,
                "streetType": "УЛ.",
                "streetName": "ЧАРЛЗ ДАРВИН",
                "streetNo": "27",
                "complexId": 25,
                "complexType": "ЖК",
                "complexName": "ИЗТОК",
                "x": 23.356368,
                "y": 42.668017,
                "fullAddressString": "гр. СОФИЯ жк ИЗТОК ул. ЧАРЛЗ ДАРВИН No 27",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк ИЗТОК ул. ЧАРЛЗ ДАРВИН No 27"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 352,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-10-29",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 308,
            "name": "СОФИЯ - ЛЕВСКИ ЗОНА Г 2",
            "nameEn": "SOFIA - LEVSKI ZONA G 2",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "complexId": 335,
                "complexType": "ЖК",
                "complexName": "ЛЕВСКИ ЗОНА Г",
                "blockNo": "6Б",
                "x": 23.37623,
                "y": 42.712898,
                "fullAddressString": "гр. СОФИЯ жк ЛЕВСКИ ЗОНА Г бл. 6Б",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк ЛЕВСКИ ЗОНА Г бл. 6Б"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 675,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-11-17",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 309,
            "name": "СВОГЕ - ЦАР СИМЕОН",
            "nameEn": "SVOGE - TSAR SIMEON",
            "siteId": 65869,
            "address": {
                "countryId": 100,
                "siteId": 65869,
                "siteType": "ГР.",
                "siteName": "СВОГЕ",
                "postCode": "2260",
                "streetId": 84287,
                "streetType": "УЛ.",
                "streetName": "ЦАР СИМЕОН",
                "streetNo": "18Д",
                "x": 23.345292,
                "y": 42.960867,
                "fullAddressString": "гр. СВОГЕ [2260] ул. ЦАР СИМЕОН No 18Д",
                "siteAddressString": "гр. СВОГЕ [2260]",
                "localAddressString": "ул. ЦАР СИМЕОН No 18Д"
            },
            "workingTimeFrom": "10:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "10:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 183,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-11-13",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 311,
            "name": "КРИЧИМ",
            "nameEn": "KRICHIM",
            "siteId": 39921,
            "address": {
                "countryId": 100,
                "siteId": 39921,
                "siteType": "ГР.",
                "siteName": "КРИЧИМ",
                "postCode": "4220",
                "streetId": 92557,
                "streetType": "БУЛ.",
                "streetName": "АЛЕКСАНДЪР СТАМБОЛИЙСКИ",
                "x": 24.473722,
                "y": 42.042205,
                "fullAddressString": "гр. КРИЧИМ [4220] бул. АЛЕКСАНДЪР СТАМБОЛИЙСКИ",
                "siteAddressString": "гр. КРИЧИМ [4220]",
                "localAddressString": "бул. АЛЕКСАНДЪР СТАМБОЛИЙСКИ"
            },
            "workingTimeFrom": "10:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "10:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 201,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-12-15",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 312,
            "name": "СМОЛЯН - БУЗЛУДЖА",
            "nameEn": "SMOLYAN - BUZLUDZHA",
            "siteId": 67653,
            "address": {
                "countryId": 100,
                "siteId": 67653,
                "siteType": "ГР.",
                "siteName": "СМОЛЯН",
                "postCode": "4700",
                "streetId": 62036,
                "streetType": "УЛ.",
                "streetName": "БУЗЛУДЖА",
                "streetNo": "1",
                "x": 24.709947,
                "y": 41.576982,
                "fullAddressString": "гр. СМОЛЯН [4700] ул. БУЗЛУДЖА No 1",
                "siteAddressString": "гр. СМОЛЯН [4700]",
                "localAddressString": "ул. БУЗЛУДЖА No 1"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "16:30",
            "sameDayDepartureCutoffHalf": "12:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 29,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "12:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-12-02",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 313,
            "name": "ЯГОДОВО",
            "nameEn": "YAGODOVO",
            "siteId": 87240,
            "address": {
                "countryId": 100,
                "siteId": 87240,
                "siteType": "С.",
                "siteName": "ЯГОДОВО",
                "postCode": "4113",
                "streetId": 87280,
                "streetType": "УЛ.",
                "streetName": "ХРИСТО БОТЕВ",
                "streetNo": "21",
                "x": 24.85035,
                "y": 42.110688,
                "fullAddressString": "с. ЯГОДОВО [4113] ул. ХРИСТО БОТЕВ No 21",
                "siteAddressString": "с. ЯГОДОВО [4113]",
                "localAddressString": "ул. ХРИСТО БОТЕВ No 21"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 442,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-11-09",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 315,
            "name": "ПЕЩЕРА",
            "nameEn": "PESHTERA",
            "siteId": 56277,
            "address": {
                "countryId": 100,
                "siteId": 56277,
                "siteType": "ГР.",
                "siteName": "ПЕЩЕРА",
                "postCode": "4550",
                "streetId": 71213,
                "streetType": "УЛ.",
                "streetName": "ВЛАДИМИР РИЛСКИ",
                "streetNo": "8",
                "x": 24.298249,
                "y": 42.031644,
                "fullAddressString": "гр. ПЕЩЕРА [4550] ул. ВЛАДИМИР РИЛСКИ No 8",
                "siteAddressString": "гр. ПЕЩЕРА [4550]",
                "localAddressString": "ул. ВЛАДИМИР РИЛСКИ No 8"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "12:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 184,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "12:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-11-24",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 316,
            "name": "СОФИЯ - ДОНДУКОВ",
            "nameEn": "SOFIA - DONDUKOV",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 64,
                "streetType": "БУЛ.",
                "streetName": "КНЯЗ АЛЕКСАНДЪР ДОНДУКОВ",
                "streetNo": "131",
                "x": 23.342036,
                "y": 42.699773,
                "fullAddressString": "гр. СОФИЯ бул. КНЯЗ АЛЕКСАНДЪР ДОНДУКОВ No 131",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "бул. КНЯЗ АЛЕКСАНДЪР ДОНДУКОВ No 131"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 188,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-01-20",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 317,
            "name": "ЛЕВСКИ - ХРИСТО БОТЕВ",
            "nameEn": "LEVSKI - HRISTO BOTEV",
            "siteId": 43236,
            "address": {
                "countryId": 100,
                "siteId": 43236,
                "siteType": "ГР.",
                "siteName": "ЛЕВСКИ",
                "postCode": "5900",
                "streetId": 92699,
                "streetType": "УЛ.",
                "streetName": "ХРИСТО БОТЕВ",
                "streetNo": "52",
                "x": 25.144626,
                "y": 43.362801,
                "fullAddressString": "гр. ЛЕВСКИ [5900] ул. ХРИСТО БОТЕВ No 52",
                "siteAddressString": "гр. ЛЕВСКИ [5900]",
                "localAddressString": "ул. ХРИСТО БОТЕВ No 52"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 170,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 25,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-12-14",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 318,
            "name": "ПЪРВОМАЙ - ДИМИТЪР БЛАГОЕВ",
            "nameEn": "PARVOMAY - DIMITAR BLAGOEV",
            "siteId": 59080,
            "address": {
                "countryId": 100,
                "siteId": 59080,
                "siteType": "ГР.",
                "siteName": "ПЪРВОМАЙ",
                "postCode": "4270",
                "streetId": 83285,
                "streetType": "УЛ.",
                "streetName": "ДИМИТЪР БЛАГОЕВ",
                "streetNo": "23",
                "x": 25.224674,
                "y": 42.09647,
                "fullAddressString": "гр. ПЪРВОМАЙ [4270] ул. ДИМИТЪР БЛАГОЕВ No 23",
                "siteAddressString": "гр. ПЪРВОМАЙ [4270]",
                "localAddressString": "ул. ДИМИТЪР БЛАГОЕВ No 23"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "12:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "12:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 17,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "12:00",
                    "sameDayDepartureCutoff": "12:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-12-10",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 319,
            "name": "БУРГАС - МЕДЕН РУДНИК 2, ДО АВТОБУСЕН ТЕРМИНАЛ",
            "nameEn": "BURGAS - MEDEN RUDNIK 2, NEXT TO BUS STATION",
            "siteId": 7079,
            "address": {
                "countryId": 100,
                "siteId": 7079,
                "siteType": "ГР.",
                "siteName": "БУРГАС",
                "postCode": "8000",
                "complexId": 706,
                "complexType": "ЖК",
                "complexName": "МЕДЕН РУДНИК",
                "blockNo": "508",
                "addressNote": "ДО АВТОБУСЕН ТЕРМИНАЛ",
                "x": 27.417846,
                "y": 42.452512,
                "fullAddressString": "гр. БУРГАС жк МЕДЕН РУДНИК бл. 508 ДО АВТОБУСЕН ТЕРМИНАЛ",
                "siteAddressString": "гр. БУРГАС",
                "localAddressString": "жк МЕДЕН РУДНИК бл. 508 ДО АВТОБУСЕН ТЕРМИНАЛ"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 200,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 860,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-01-18",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": false
        },
        {
            "id": 320,
            "name": "БУРГАС - СЛАВЕЙКОВ (ЖК), БЛ.55",
            "nameEn": "BURGAS - SLAVEYKOV 55 (ZHK), BL.55",
            "siteId": 7079,
            "address": {
                "countryId": 100,
                "siteId": 7079,
                "siteType": "ГР.",
                "siteName": "БУРГАС",
                "postCode": "8000",
                "complexId": 794,
                "complexType": "ЖК",
                "complexName": "СЛАВЕЙКОВ",
                "blockNo": "55",
                "addressNote": "МЕЖДУ ВХ. 8 И 9",
                "x": 27.453799,
                "y": 42.523131,
                "fullAddressString": "гр. БУРГАС жк СЛАВЕЙКОВ бл. 55 МЕЖДУ ВХ. 8 И 9",
                "siteAddressString": "гр. БУРГАС",
                "localAddressString": "жк СЛАВЕЙКОВ бл. 55 МЕЖДУ ВХ. 8 И 9"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 200,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 829,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-01-11",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 321,
            "name": "СТАРА ЗАГОРА - ВОЕННО ОКРЪЖИЕ",
            "nameEn": "STARA ZAGORA - VOENNO OKRAZHIE",
            "siteId": 68850,
            "address": {
                "countryId": 100,
                "siteId": 68850,
                "siteType": "ГР.",
                "siteName": "СТАРА ЗАГОРА",
                "postCode": "6000",
                "streetId": 18291,
                "streetType": "УЛ.",
                "streetName": "САВА СИЛОВ",
                "streetNo": "57",
                "x": 25.62371,
                "y": 42.425046,
                "fullAddressString": "гр. СТАРА ЗАГОРА ул. САВА СИЛОВ No 57",
                "siteAddressString": "гр. СТАРА ЗАГОРА",
                "localAddressString": "ул. САВА СИЛОВ No 57"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 838,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-03-29",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 322,
            "name": "СОФИЯ - ЦАР БОРИС (БУЛ.)",
            "nameEn": "SOFIA - TSAR BORIS (BUL.)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 129,
                "streetType": "БУЛ.",
                "streetName": "ЦАР БОРИС III",
                "streetNo": "211",
                "x": 23.267908,
                "y": 42.668771,
                "fullAddressString": "гр. СОФИЯ бул. ЦАР БОРИС III No 211",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "бул. ЦАР БОРИС III No 211"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 876,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-04-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 323,
            "name": "СОФИЯ - ЛАГЕРА – СМОЛЯНСКА (УЛ.)",
            "nameEn": "SOFIA – LAGERA – SMOLYANSKA (UL.)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 2918,
                "streetType": "УЛ.",
                "streetName": "СМОЛЯНСКА",
                "streetNo": "12-16",
                "x": 23.296521,
                "y": 42.688627,
                "fullAddressString": "гр. СОФИЯ ул. СМОЛЯНСКА No 12-16",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. СМОЛЯНСКА No 12-16"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 277,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-04-23",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 324,
            "name": "СОФИЯ - СИЕЛА",
            "nameEn": "SOFIA - SIELA",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 4176,
                "streetType": "УЛ.",
                "streetName": "ПОРУЧИК ХРИСТО ТОПРАКЧИЕВ",
                "streetNo": "11",
                "x": 23.41319,
                "y": 42.665519,
                "fullAddressString": "гр. СОФИЯ ул. ПОРУЧИК ХРИСТО ТОПРАКЧИЕВ No 11",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. ПОРУЧИК ХРИСТО ТОПРАКЧИЕВ No 11"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 798,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-02-22",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 326,
            "name": "СОФИЯ - ОБОРИЩЕ (УЛ.)",
            "nameEn": "SOFIA - OBORISHTE (UL.)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 2443,
                "streetType": "УЛ.",
                "streetName": "ОБОРИЩЕ",
                "streetNo": "60",
                "x": 23.350166,
                "y": 42.693555,
                "fullAddressString": "гр. СОФИЯ ул. ОБОРИЩЕ No 60",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. ОБОРИЩЕ No 60"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 821,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-04-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 327,
            "name": "ВАРНА - СЕЛИОЛУ",
            "nameEn": "VARNA - SELIOLU",
            "siteId": 10135,
            "address": {
                "countryId": 100,
                "siteId": 10135,
                "siteType": "ГР.",
                "siteName": "ВАРНА",
                "postCode": "9000",
                "streetId": 12675,
                "streetType": "УЛ.",
                "streetName": "СЕЛИОЛУ",
                "streetNo": "17",
                "addressNote": "ПАРТЕР",
                "x": 27.916801,
                "y": 43.216494,
                "fullAddressString": "гр. ВАРНА ул. СЕЛИОЛУ No 17 ПАРТЕР",
                "siteAddressString": "гр. ВАРНА",
                "localAddressString": "ул. СЕЛИОЛУ No 17 ПАРТЕР"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 220
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 854,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-03-03",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 328,
            "name": "ВАРНА - ВЛ. ВАРНЕНЧИК (ЖК) 2",
            "nameEn": "VARNA - VL. VARNENCHIK (ZHK) 2",
            "siteId": 10135,
            "address": {
                "countryId": 100,
                "siteId": 10135,
                "siteType": "ГР.",
                "siteName": "ВАРНА",
                "postCode": "9000",
                "complexId": 601,
                "complexType": "ЖК",
                "complexName": "ВЛАДИСЛАВ ВАРНЕНЧИК",
                "blockNo": "409",
                "x": 27.846393,
                "y": 43.250189,
                "fullAddressString": "гр. ВАРНА жк ВЛАДИСЛАВ ВАРНЕНЧИК бл. 409",
                "siteAddressString": "гр. ВАРНА",
                "localAddressString": "жк ВЛАДИСЛАВ ВАРНЕНЧИК бл. 409"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 220
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 178,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-03-08",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 330,
            "name": "МОНТАНА - БЛОК ПИРИН",
            "nameEn": "MONTANA - BLOK PIRIN",
            "siteId": 48489,
            "address": {
                "countryId": 100,
                "siteId": 48489,
                "siteType": "ГР.",
                "siteName": "МОНТАНА",
                "postCode": "3400",
                "streetId": 58237,
                "streetType": "УЛ.",
                "streetName": "СТРАНДЖА",
                "streetNo": "2",
                "x": 23.226278,
                "y": 43.41313,
                "fullAddressString": "гр. МОНТАНА [3400] ул. СТРАНДЖА No 2",
                "siteAddressString": "гр. МОНТАНА [3400]",
                "localAddressString": "ул. СТРАНДЖА No 2"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:30",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 170,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 78,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:30",
                    "sameDayDepartureCutoff": "13:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-03-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 332,
            "name": "СОФИЯ - НБУ",
            "nameEn": "SOFIA - NBU",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 3414,
                "streetType": "УЛ.",
                "streetName": "МАЕСТРО КЪНЕВ",
                "streetNo": "78",
                "x": 23.253537,
                "y": 42.679233,
                "fullAddressString": "гр. СОФИЯ ул. МАЕСТРО КЪНЕВ No 78",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. МАЕСТРО КЪНЕВ No 78"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 808,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-04-26",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 333,
            "name": "БЛАГОЕВГРАД – ЕЛЕНОВО",
            "nameEn": "BLAGOEVGRAD - ELENOVO",
            "siteId": 4279,
            "address": {
                "countryId": 100,
                "siteId": 4279,
                "siteType": "ГР.",
                "siteName": "БЛАГОЕВГРАД",
                "postCode": "2700",
                "streetId": 102563,
                "streetType": "УЛ.",
                "streetName": "ГЕОРГИ АНДРЕЙЧИН",
                "streetNo": "7",
                "addressNote": "ДО АПТЕКАТА В ПОСОКА С.ЕЛЕНОВО",
                "x": 23.108282,
                "y": 42.003714,
                "fullAddressString": "гр. БЛАГОЕВГРАД ул. ГЕОРГИ АНДРЕЙЧИН No 7 ДО АПТЕКАТА В ПОСОКА С.ЕЛЕНОВО",
                "siteAddressString": "гр. БЛАГОЕВГРАД",
                "localAddressString": "ул. ГЕОРГИ АНДРЕЙЧИН No 7 ДО АПТЕКАТА В ПОСОКА С.ЕЛЕНОВО"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "12:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 13,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "12:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-04-06",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 334,
            "name": "МОНТАНА - МЛАДОСТ",
            "nameEn": "MONTANA - MLADOST",
            "siteId": 48489,
            "address": {
                "countryId": 100,
                "siteId": 48489,
                "siteType": "ГР.",
                "siteName": "МОНТАНА",
                "postCode": "3400",
                "streetId": 58003,
                "streetType": "БУЛ.",
                "streetName": "ГЕН. АРНОЛДИ",
                "streetNo": "9",
                "addressNote": "БИВШ РЕСТОРАНТ БАВАРИЯ",
                "x": 23.221782,
                "y": 43.418652,
                "fullAddressString": "гр. МОНТАНА [3400] бул. ГЕН. АРНОЛДИ No 9 БИВШ РЕСТОРАНТ БАВАРИЯ",
                "siteAddressString": "гр. МОНТАНА [3400]",
                "localAddressString": "бул. ГЕН. АРНОЛДИ No 9 БИВШ РЕСТОРАНТ БАВАРИЯ"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:30",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "13:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 170,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 330,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:30",
                    "sameDayDepartureCutoff": "13:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-03-22",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 335,
            "name": "СОФИЯ - МУСАГЕНИЦА",
            "nameEn": "SOFIA - MUSAGENITSA",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 3960,
                "streetType": "УЛ.",
                "streetName": "ВЪРТОПО",
                "complexId": 53,
                "complexType": "ЖК",
                "complexName": "МУСАГЕНИЦА",
                "blockNo": "104",
                "x": 23.366085,
                "y": 42.660277,
                "fullAddressString": "гр. СОФИЯ жк МУСАГЕНИЦА ул. ВЪРТОПО бл. 104",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк МУСАГЕНИЦА ул. ВЪРТОПО бл. 104"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 886,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-04-12",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 336,
            "name": "ПЛОВДИВ - КОЛОДРУМА",
            "nameEn": "PLOVDIV - KOLODRUMA",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "streetId": 10140,
                "streetType": "УЛ.",
                "streetName": "АСЕНОВГРАДСКО ШОСЕ",
                "streetNo": "4",
                "x": 24.76733,
                "y": 42.128856,
                "fullAddressString": "гр. ПЛОВДИВ ул. АСЕНОВГРАДСКО ШОСЕ No 4",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "ул. АСЕНОВГРАДСКО ШОСЕ No 4"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 239,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-04-21",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 337,
            "name": "ПЛОВДИВ - 2 - РО РПУ",
            "nameEn": "PLOVDIV - 2 RPU",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "streetId": 11139,
                "streetType": "УЛ.",
                "streetName": "ХРИСТО ЧЕРНОПЕЕВ",
                "streetNo": "5",
                "x": 24.730371,
                "y": 42.146362,
                "fullAddressString": "гр. ПЛОВДИВ ул. ХРИСТО ЧЕРНОПЕЕВ No 5",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "ул. ХРИСТО ЧЕРНОПЕЕВ No 5"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 883,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-04-19",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 338,
            "name": "ПЛОВДИВ - БРЕЗОВСКО ШОСЕ, ХОТЕЛ ФИЛИПОПОЛИС",
            "nameEn": "PLOVDIV - BREZOVSKO SHOSE, HOTEL FILIPOPOLIS",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "streetId": 10217,
                "streetType": "УЛ.",
                "streetName": "БРЕЗОВСКО ШОСЕ",
                "streetNo": "145А",
                "addressNote": "ПОД ХОТЕЛ ФИЛИПОПОЛИС",
                "x": 24.754338,
                "y": 42.171639,
                "fullAddressString": "гр. ПЛОВДИВ ул. БРЕЗОВСКО ШОСЕ No 145А ПОД ХОТЕЛ ФИЛИПОПОЛИС",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "ул. БРЕЗОВСКО ШОСЕ No 145А ПОД ХОТЕЛ ФИЛИПОПОЛИС"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 109,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-04-19",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 339,
            "name": "СВИЛЕНГРАД - ХЛЕБОЗАВОДА",
            "nameEn": "SVILENGRAD - HLEBOZAVODA",
            "siteId": 65677,
            "address": {
                "countryId": 100,
                "siteId": 65677,
                "siteType": "ГР.",
                "siteName": "СВИЛЕНГРАД",
                "postCode": "6500",
                "streetId": 72203,
                "streetType": "УЛ.",
                "streetName": "ЯЗОВИРСКА",
                "streetNo": "1",
                "x": 26.205552,
                "y": 41.775684,
                "fullAddressString": "гр. СВИЛЕНГРАД [6500] ул. ЯЗОВИРСКА No 1",
                "siteAddressString": "гр. СВИЛЕНГРАД [6500]",
                "localAddressString": "ул. ЯЗОВИРСКА No 1"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 48,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-05-10",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 340,
            "name": "ТЪРГОВИЩЕ - ЗАПАД",
            "nameEn": "TARGOVISHTE - ZAPAD",
            "siteId": 73626,
            "address": {
                "countryId": 100,
                "siteId": 73626,
                "siteType": "ГР.",
                "siteName": "ТЪРГОВИЩЕ",
                "postCode": "7700",
                "streetId": 60265,
                "streetType": "БУЛ.",
                "streetName": "СЮРЕН",
                "streetNo": "1",
                "x": 26.56021,
                "y": 43.242569,
                "fullAddressString": "гр. ТЪРГОВИЩЕ [7700] бул. СЮРЕН No 1",
                "siteAddressString": "гр. ТЪРГОВИЩЕ [7700]",
                "localAddressString": "бул. СЮРЕН No 1"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 170,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 132,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-04-12",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 341,
            "name": "ЛЯСКОВЕЦ",
            "nameEn": "LYASKOVETS",
            "siteId": 44793,
            "address": {
                "countryId": 100,
                "siteId": 44793,
                "siteType": "ГР.",
                "siteName": "ЛЯСКОВЕЦ",
                "postCode": "5140",
                "streetId": 80585,
                "streetType": "УЛ.",
                "streetName": "ВАСИЛ ЛЕВСКИ",
                "streetNo": "8В",
                "x": 25.717492,
                "y": 43.104281,
                "fullAddressString": "гр. ЛЯСКОВЕЦ [5140] ул. ВАСИЛ ЛЕВСКИ No 8В",
                "siteAddressString": "гр. ЛЯСКОВЕЦ [5140]",
                "localAddressString": "ул. ВАСИЛ ЛЕВСКИ No 8В"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "12:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 170,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 756,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "12:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-04-05",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 342,
            "name": "СОФИЯ - БЪЛГАРИЯ (БУЛ.)",
            "nameEn": "SOFIA - BULGARIA (BUL.)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 18,
                "streetType": "БУЛ.",
                "streetName": "БЪЛГАРИЯ",
                "streetNo": "60В",
                "x": 23.290682,
                "y": 42.668733,
                "fullAddressString": "гр. СОФИЯ бул. БЪЛГАРИЯ No 60В",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "бул. БЪЛГАРИЯ No 60В"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:30",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 877,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-04-12",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 343,
            "name": "КОСТЕНЕЦ - ТЪРГОВСКА",
            "nameEn": "KOSTENETS - TARGOVSKA",
            "siteId": 38902,
            "address": {
                "countryId": 100,
                "siteId": 38902,
                "siteType": "ГР.",
                "siteName": "КОСТЕНЕЦ",
                "postCode": "2030",
                "streetId": 69564,
                "streetType": "УЛ.",
                "streetName": "ТЪРГОВСКА",
                "streetNo": "2",
                "addressNote": "БИВШ ЕВРОФУТБОЛ",
                "x": 23.86035,
                "y": 42.306664,
                "fullAddressString": "гр. КОСТЕНЕЦ [2030] ул. ТЪРГОВСКА No 2 БИВШ ЕВРОФУТБОЛ",
                "siteAddressString": "гр. КОСТЕНЕЦ [2030]",
                "localAddressString": "ул. ТЪРГОВСКА No 2 БИВШ ЕВРОФУТБОЛ"
            },
            "workingTimeFrom": "10:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "10:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 412,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-05-10",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 344,
            "name": "СОФИЯ - ТРИ УШИ (УЛ.)",
            "nameEn": "SOFIA - TRI USHI (UL.)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 3075,
                "streetType": "УЛ.",
                "streetName": "ТРИ УШИ",
                "streetNo": "8",
                "x": 23.316439,
                "y": 42.695854,
                "fullAddressString": "гр. СОФИЯ ул. ТРИ УШИ No 8",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. ТРИ УШИ No 8"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 869,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-05-10",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 345,
            "name": "СОФИЯ - СТУДЕНТСКИ ГРАД, СРЕЩУ БЛ. 34А",
            "nameEn": "SOFIA - STUDENTSKI GRAD, SREHSTU BL. 34A",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 5080,
                "streetType": "УЛ.",
                "streetName": "ПРОФ. ХРИСТО ДАНОВ",
                "streetNo": "19",
                "complexId": 327,
                "complexType": "ЖК",
                "complexName": "СТУДЕНТСКИ ГРАД",
                "x": 23.344359,
                "y": 42.656085,
                "fullAddressString": "гр. СОФИЯ жк СТУДЕНТСКИ ГРАД ул. ПРОФ. ХРИСТО ДАНОВ No 19",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк СТУДЕНТСКИ ГРАД ул. ПРОФ. ХРИСТО ДАНОВ No 19"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "19:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 772,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-04-12",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 347,
            "name": "СОФИЯ - ГЕО МИЛЕВ - ЛИДИЦЕ",
            "nameEn": "SOFIA - GEO MILEV - LIDICE",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 2145,
                "streetType": "УЛ.",
                "streetName": "ЛИДИЦЕ",
                "streetNo": "29",
                "complexId": 10,
                "complexType": "ЖК",
                "complexName": "ГЕО МИЛЕВ",
                "x": 23.363142,
                "y": 42.679838,
                "fullAddressString": "гр. СОФИЯ жк ГЕО МИЛЕВ ул. ЛИДИЦЕ No 29",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк ГЕО МИЛЕВ ул. ЛИДИЦЕ No 29"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "19:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 134,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-04-19",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": false
        },
        {
            "id": 348,
            "name": "ХАСКОВО - ВАСИЛ ЛЕВСКИ (БУЛ.)",
            "nameEn": "HASKOVO - VASIL LEVSKI (BUL.)",
            "siteId": 77195,
            "address": {
                "countryId": 100,
                "siteId": 77195,
                "siteType": "ГР.",
                "siteName": "ХАСКОВО",
                "postCode": "6300",
                "streetId": 28084,
                "streetType": "БУЛ.",
                "streetName": "ВАСИЛ ЛЕВСКИ",
                "streetNo": "60",
                "x": 25.557032,
                "y": 41.948671,
                "fullAddressString": "гр. ХАСКОВО [6300] бул. ВАСИЛ ЛЕВСКИ No 60",
                "siteAddressString": "гр. ХАСКОВО [6300]",
                "localAddressString": "бул. ВАСИЛ ЛЕВСКИ No 60"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 200,
                "depth": 350
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 82,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-03-31",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 349,
            "name": "ЛОЗЕН",
            "nameEn": "LOZEN",
            "siteId": 44063,
            "address": {
                "countryId": 100,
                "siteId": 44063,
                "siteType": "С.",
                "siteName": "ЛОЗЕН",
                "postCode": "1151",
                "streetId": 69967,
                "streetType": "УЛ.",
                "streetName": "ИВАН ПЕШЕВ",
                "streetNo": "15",
                "addressNote": "НА ЪГЪЛА С УЛ. РАШОВЕЦ",
                "x": 23.49628,
                "y": 42.602133,
                "fullAddressString": "с. ЛОЗЕН [1151] ул. ИВАН ПЕШЕВ No 15 НА ЪГЪЛА С УЛ. РАШОВЕЦ",
                "siteAddressString": "с. ЛОЗЕН [1151]",
                "localAddressString": "ул. ИВАН ПЕШЕВ No 15 НА ЪГЪЛА С УЛ. РАШОВЕЦ"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 350,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-06-21",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 350,
            "name": "КАЗИЧЕНЕ - ХАН КРУМ",
            "nameEn": "KAZICHENE - KHAN KRUM",
            "siteId": 35239,
            "address": {
                "countryId": 100,
                "siteId": 35239,
                "siteType": "С.",
                "siteName": "КАЗИЧЕНЕ",
                "postCode": "1532",
                "streetId": 69265,
                "streetType": "УЛ.",
                "streetName": "ХАН КРУМ",
                "streetNo": "4",
                "x": 23.466614,
                "y": 42.659486,
                "fullAddressString": "с. КАЗИЧЕНЕ [1532] ул. ХАН КРУМ No 4",
                "siteAddressString": "с. КАЗИЧЕНЕ [1532]",
                "localAddressString": "ул. ХАН КРУМ No 4"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 349,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-05-18",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 351,
            "name": "СОФИЯ - ХРИСТО БОТЕВ (БУЛ)",
            "nameEn": "SOFIA - HRISTO BOTEV (BUL)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 124,
                "streetType": "БУЛ.",
                "streetName": "ХРИСТО БОТЕВ",
                "streetNo": "128",
                "x": 23.318154,
                "y": 42.707083,
                "fullAddressString": "гр. СОФИЯ бул. ХРИСТО БОТЕВ No 128",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "бул. ХРИСТО БОТЕВ No 128"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 859,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-06-04",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 352,
            "name": "СОФИЯ - ИЗТОК 2",
            "nameEn": "SOFIA - IZTOK 2",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 2732,
                "streetType": "УЛ.",
                "streetName": "РАЙКО АЛЕКСИЕВ",
                "streetNo": "13",
                "x": 23.357563,
                "y": 42.67389,
                "fullAddressString": "гр. СОФИЯ ул. РАЙКО АЛЕКСИЕВ No 13",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. РАЙКО АЛЕКСИЕВ No 13"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "19:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 307,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-05-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 353,
            "name": "СОФИЯ - ЛЮЛИН 4 (ЖК)",
            "nameEn": "SOFIA - LYULIN 4 (ZHK)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "complexId": 40,
                "complexType": "ЖК",
                "complexName": "ЛЮЛИН 4",
                "blockNo": "417П",
                "entranceNo": "А",
                "x": 23.247778,
                "y": 42.717336,
                "fullAddressString": "гр. СОФИЯ жк ЛЮЛИН 4 бл. 417П вх. А",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк ЛЮЛИН 4 бл. 417П вх. А"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 760,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-05-26",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 354,
            "name": "СОФИЯ - ОКРЪЖНА БОЛНИЦА",
            "nameEn": "SOFIA - OKRAZHNA BOLNITSA",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "complexId": 47,
                "complexType": "ЖК",
                "complexName": "МЛАДОСТ 1",
                "blockNo": "124",
                "entranceNo": "Д",
                "x": 23.374776,
                "y": 42.66333,
                "fullAddressString": "гр. СОФИЯ жк МЛАДОСТ 1 бл. 124 вх. Д",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк МЛАДОСТ 1 бл. 124 вх. Д"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "19:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 98,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-05-05",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 355,
            "name": "ПЛОВДИВ - КОМАТЕВО",
            "nameEn": "PLOVDIV - KOMATEVO",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "streetId": 10774,
                "streetType": "УЛ.",
                "streetName": "ОБНОВА",
                "streetNo": "23",
                "x": 24.701772,
                "y": 42.099444,
                "fullAddressString": "гр. ПЛОВДИВ ул. ОБНОВА No 23",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "ул. ОБНОВА No 23"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 370,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-09-09",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 356,
            "name": "СОФИЯ - МОДЕРНО ПРЕДГРАДИЕ",
            "nameEn": "SOFIA - MODERNO PREDGRADIE",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 2280,
                "streetType": "УЛ.",
                "streetName": "МЕТОДИ МАКЕДОНСКИ",
                "streetNo": "20",
                "x": 23.271057,
                "y": 42.724773,
                "fullAddressString": "гр. СОФИЯ ул. МЕТОДИ МАКЕДОНСКИ No 20",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. МЕТОДИ МАКЕДОНСКИ No 20"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 257,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-07-26",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 357,
            "name": "ВАРНА - ЖЕЛЕЗКОВА",
            "nameEn": "VARNA - ZHELEZKOVA",
            "siteId": 10135,
            "address": {
                "countryId": 100,
                "siteId": 10135,
                "siteType": "ГР.",
                "siteName": "ВАРНА",
                "postCode": "9000",
                "streetId": 12324,
                "streetType": "УЛ.",
                "streetName": "ЗОРА",
                "streetNo": "16",
                "x": 27.927641,
                "y": 43.219112,
                "fullAddressString": "гр. ВАРНА ул. ЗОРА No 16",
                "siteAddressString": "гр. ВАРНА",
                "localAddressString": "ул. ЗОРА No 16"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 220
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 661,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-07-09",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 358,
            "name": "РАДНЕВО - ЦЕНТЪР",
            "nameEn": "RADNEVO - CENTRE",
            "siteId": 61460,
            "address": {
                "countryId": 100,
                "siteId": 61460,
                "siteType": "ГР.",
                "siteName": "РАДНЕВО",
                "postCode": "6260",
                "streetId": 88917,
                "streetType": "УЛ.",
                "streetName": "ТАЧО ДАСКАЛОВ",
                "streetNo": "8",
                "x": 25.931543,
                "y": 42.290056,
                "fullAddressString": "гр. РАДНЕВО [6260] ул. ТАЧО ДАСКАЛОВ No 8",
                "siteAddressString": "гр. РАДНЕВО [6260]",
                "localAddressString": "ул. ТАЧО ДАСКАЛОВ No 8"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "17:00",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 463,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-07-26",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 360,
            "name": "СОФИЯ - ЛЮЛИН 5 (ЖК)",
            "nameEn": "SOFIA - LYULIN 5 (ZHK)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "complexId": 42,
                "complexType": "ЖК",
                "complexName": "ЛЮЛИН 5",
                "blockNo": "549",
                "entranceNo": "Г",
                "x": 23.246969,
                "y": 42.712472,
                "fullAddressString": "гр. СОФИЯ жк ЛЮЛИН 5 бл. 549 вх. Г",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк ЛЮЛИН 5 бл. 549 вх. Г"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 760,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-06-28",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 361,
            "name": "СОФИЯ - РАЛЕВИЦА (УЛ.)",
            "nameEn": "SOFIA - RALEVITSA (UL.)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 3580,
                "streetType": "УЛ.",
                "streetName": "РАЛЕВИЦА",
                "streetNo": "64",
                "x": 23.280071,
                "y": 42.658567,
                "fullAddressString": "гр. СОФИЯ ул. РАЛЕВИЦА No 64",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. РАЛЕВИЦА No 64"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 876,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-06-21",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 362,
            "name": "СОФИЯ - МЛАДОСТ 1А (ЖК)",
            "nameEn": "SOFIA - MLADOST 1A (ZHK)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "complexId": 48,
                "complexType": "ЖК",
                "complexName": "МЛАДОСТ 1А",
                "blockNo": "508",
                "x": 23.380873,
                "y": 42.650174,
                "fullAddressString": "гр. СОФИЯ жк МЛАДОСТ 1А бл. 508",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк МЛАДОСТ 1А бл. 508"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 240,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-06-21",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 363,
            "name": "СОФИЯ - ДРУЖБА 1, ПАЗАРА",
            "nameEn": "SOFIA - DRUZHBA 1, PAZARA",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 1794,
                "streetType": "УЛ.",
                "streetName": "ИВАН АРАБАДЖИЯТА",
                "complexId": 14,
                "complexType": "ЖК",
                "complexName": "ДРУЖБА 1",
                "x": 23.396249,
                "y": 42.667673,
                "fullAddressString": "гр. СОФИЯ жк ДРУЖБА 1 ул. ИВАН АРАБАДЖИЯТА",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк ДРУЖБА 1 ул. ИВАН АРАБАДЖИЯТА"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 798,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-07-19",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 364,
            "name": "СОФИЯ - ВАСИЛ ЛЕВСКИ (БУЛ.)",
            "nameEn": "SOFIA - VASIL LEVSKI (BUL.)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 23,
                "streetType": "БУЛ.",
                "streetName": "ВАСИЛ ЛЕВСКИ",
                "streetNo": "31",
                "x": 23.325196,
                "y": 42.687007,
                "fullAddressString": "гр. СОФИЯ бул. ВАСИЛ ЛЕВСКИ No 31",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "бул. ВАСИЛ ЛЕВСКИ No 31"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 153,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-07-26",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 365,
            "name": "РАКИТОВО",
            "nameEn": "RAKITOVO",
            "siteId": 62004,
            "address": {
                "countryId": 100,
                "siteId": 62004,
                "siteType": "ГР.",
                "siteName": "РАКИТОВО",
                "postCode": "4640",
                "streetId": 83444,
                "streetType": "УЛ.",
                "streetName": "БРАТЯ КРЪСТИНИ",
                "streetNo": "1",
                "x": 24.08884,
                "y": 41.98971,
                "fullAddressString": "гр. РАКИТОВО [4640] ул. БРАТЯ КРЪСТИНИ No 1",
                "siteAddressString": "гр. РАКИТОВО [4640]",
                "localAddressString": "ул. БРАТЯ КРЪСТИНИ No 1"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 49,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-08-02",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 367,
            "name": "ВЕТОВО",
            "nameEn": "VETOVO",
            "siteId": 10803,
            "address": {
                "countryId": 100,
                "siteId": 10803,
                "siteType": "ГР.",
                "siteName": "ВЕТОВО",
                "postCode": "7080",
                "streetId": 75497,
                "streetType": "УЛ.",
                "streetName": "ВЕЛИКО ТЪРНОВО",
                "streetNo": "5",
                "x": 26.264432,
                "y": 43.704032,
                "fullAddressString": "гр. ВЕТОВО [7080] ул. ВЕЛИКО ТЪРНОВО No 5",
                "siteAddressString": "гр. ВЕТОВО [7080]",
                "localAddressString": "ул. ВЕЛИКО ТЪРНОВО No 5"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 10,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-08-02",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 368,
            "name": "ДУЛОВО - НИКОЛА ПЕТКОВ (УЛ.)",
            "nameEn": "DULOVO - NIKOLA PETKOV (UL.)",
            "siteId": 24030,
            "address": {
                "countryId": 100,
                "siteId": 24030,
                "siteType": "ГР.",
                "siteName": "ДУЛОВО",
                "postCode": "7650",
                "streetId": 77347,
                "streetType": "УЛ.",
                "streetName": "НИКОЛА ПЕТКОВ",
                "streetNo": "7",
                "addressNote": "ПЪТЯ ЗА ЧЕРНИК",
                "x": 27.13897,
                "y": 43.82143,
                "fullAddressString": "гр. ДУЛОВО [7650] ул. НИКОЛА ПЕТКОВ No 7 ПЪТЯ ЗА ЧЕРНИК",
                "siteAddressString": "гр. ДУЛОВО [7650]",
                "localAddressString": "ул. НИКОЛА ПЕТКОВ No 7 ПЪТЯ ЗА ЧЕРНИК"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "16:00",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 24,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-06-15",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 370,
            "name": "ПЛОВДИВ - ОСТРОМИЛА",
            "nameEn": "PLOVDIV - OSTROMILA",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "streetNo": "14Б",
                "complexId": 512,
                "complexType": "КВ.",
                "complexName": "ОСТРОМИЛА",
                "x": 24.729549,
                "y": 42.114054,
                "fullAddressString": "гр. ПЛОВДИВ кв. ОСТРОМИЛА No 14Б",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "кв. ОСТРОМИЛА No 14Б"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 355,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-09-03",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 371,
            "name": "РУСЕ - ТЕХНОПОЛИС",
            "nameEn": "RUSE - TECHNOPOLIS",
            "siteId": 63427,
            "address": {
                "countryId": 100,
                "siteId": 63427,
                "siteType": "ГР.",
                "siteName": "РУСЕ",
                "postCode": "7000",
                "streetId": 16577,
                "streetType": "БУЛ.",
                "streetName": "ХРИСТО БОТЕВ",
                "streetNo": "38",
                "x": 25.970027,
                "y": 43.831454,
                "fullAddressString": "гр. РУСЕ бул. ХРИСТО БОТЕВ No 38",
                "siteAddressString": "гр. РУСЕ",
                "localAddressString": "бул. ХРИСТО БОТЕВ No 38"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 791,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-08-02",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 372,
            "name": "ЕТРОПОЛЕ - ПАРТИЗАНСКИ (БУЛ.)",
            "nameEn": "ETROPOLE - PARTIZANSKI (BUL.)",
            "siteId": 27632,
            "address": {
                "countryId": 100,
                "siteId": 27632,
                "siteType": "ГР.",
                "siteName": "ЕТРОПОЛЕ",
                "postCode": "2180",
                "streetId": 77891,
                "streetType": "БУЛ.",
                "streetName": "ПАРТИЗАНСКИ",
                "streetNo": "23",
                "x": 23.999993,
                "y": 42.832647,
                "fullAddressString": "гр. ЕТРОПОЛЕ [2180] бул. ПАРТИЗАНСКИ No 23",
                "siteAddressString": "гр. ЕТРОПОЛЕ [2180]",
                "localAddressString": "бул. ПАРТИЗАНСКИ No 23"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 80,
                "depth": 150
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 462,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-07-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 373,
            "name": "ТРОЯН - ЛЪГЪТ",
            "nameEn": "TROYAN - LAGAT",
            "siteId": 73198,
            "address": {
                "countryId": 100,
                "siteId": 73198,
                "siteType": "ГР.",
                "siteName": "ТРОЯН",
                "postCode": "5600",
                "streetNo": "19",
                "complexId": 19578,
                "complexType": "ЖК",
                "complexName": "ЛЪГЪТ",
                "x": 24.711147,
                "y": 42.871313,
                "fullAddressString": "гр. ТРОЯН [5600] жк ЛЪГЪТ No 19",
                "siteAddressString": "гр. ТРОЯН [5600]",
                "localAddressString": "жк ЛЪГЪТ No 19"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 180,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 44,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-08-19",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 374,
            "name": "ПЛЕВЕН - СТОРГОЗИЯ (ЖК)",
            "nameEn": "PLEVEN - STORGOZIA (ZHK)",
            "siteId": 56722,
            "address": {
                "countryId": 100,
                "siteId": 56722,
                "siteType": "ГР.",
                "siteName": "ПЛЕВЕН",
                "postCode": "5800",
                "streetNo": "39А",
                "complexId": 1003,
                "complexType": "ЖК",
                "complexName": "СТОРГОЗИЯ",
                "x": 24.595292,
                "y": 43.421854,
                "fullAddressString": "гр. ПЛЕВЕН жк СТОРГОЗИЯ No 39А",
                "siteAddressString": "гр. ПЛЕВЕН",
                "localAddressString": "жк СТОРГОЗИЯ No 39А"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 12,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-08-18",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 375,
            "name": "ДОБРИЧ - ЦАР ПЕТЪР",
            "nameEn": "DOBRICH - TSAR PETER",
            "siteId": 72624,
            "address": {
                "countryId": 100,
                "siteId": 72624,
                "siteType": "ГР.",
                "siteName": "ДОБРИЧ",
                "postCode": "9300",
                "streetId": 22258,
                "streetType": "УЛ.",
                "streetName": "ЦАР ПЕТЪР",
                "streetNo": "2",
                "x": 27.822077,
                "y": 43.56912,
                "fullAddressString": "гр. ДОБРИЧ [9300] ул. ЦАР ПЕТЪР No 2",
                "siteAddressString": "гр. ДОБРИЧ [9300]",
                "localAddressString": "ул. ЦАР ПЕТЪР No 2"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 220
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 851,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-09-20",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 377,
            "name": "СОФИЯ - БОЯНА",
            "nameEn": "SOFIA - BOYANA",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 2859,
                "streetType": "УЛ.",
                "streetName": "СЕВАСТОКРАТОР КАЛОЯН",
                "streetNo": "63",
                "complexId": 80,
                "complexType": "КВ.",
                "complexName": "БОЯНА",
                "x": 23.262677,
                "y": 42.648565,
                "fullAddressString": "гр. СОФИЯ кв. БОЯНА ул. СЕВАСТОКРАТОР КАЛОЯН No 63",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "кв. БОЯНА ул. СЕВАСТОКРАТОР КАЛОЯН No 63"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 876,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-09-27",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 378,
            "name": "НОВИ ПАЗАР - ХАДЖИ ДИМИТЪР (УЛ.)",
            "nameEn": "NOVI PAZAR - HADZHI DIMITAR (UL.)",
            "siteId": 52009,
            "address": {
                "countryId": 100,
                "siteId": 52009,
                "siteType": "ГР.",
                "siteName": "НОВИ ПАЗАР",
                "postCode": "9900",
                "streetId": 81852,
                "streetType": "УЛ.",
                "streetName": "ХАДЖИ ДИМИТЪР",
                "streetNo": "11А",
                "addressNote": "ДО ХОТЕЛ ДИОНИСИЙ",
                "x": 27.197889,
                "y": 43.342572,
                "fullAddressString": "гр. НОВИ ПАЗАР [9900] ул. ХАДЖИ ДИМИТЪР No 11А ДО ХОТЕЛ ДИОНИСИЙ",
                "siteAddressString": "гр. НОВИ ПАЗАР [9900]",
                "localAddressString": "ул. ХАДЖИ ДИМИТЪР No 11А ДО ХОТЕЛ ДИОНИСИЙ"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 8,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-09-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 379,
            "name": "ПОПОВО - АВТОГАРА",
            "nameEn": "POPOVO - AVTOGARA",
            "siteId": 57649,
            "address": {
                "countryId": 100,
                "siteId": 57649,
                "siteType": "ГР.",
                "siteName": "ПОПОВО",
                "postCode": "7800",
                "streetId": 82937,
                "streetType": "УЛ.",
                "streetName": "ПИРИН ПЛАНИНА",
                "streetNo": "3",
                "x": 26.224269,
                "y": 43.346637,
                "fullAddressString": "гр. ПОПОВО [7800] ул. ПИРИН ПЛАНИНА No 3",
                "siteAddressString": "гр. ПОПОВО [7800]",
                "localAddressString": "ул. ПИРИН ПЛАНИНА No 3"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 180,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 730,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-10-11",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 380,
            "name": "СОФИЯ - СВОБОДА (ЖК)",
            "nameEn": "SOFIA - SVOBODA (ZHK)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 1407,
                "streetType": "УЛ.",
                "streetName": "ГЕН. НИКОЛА ЖЕКОВ",
                "streetNo": "22",
                "x": 23.301793,
                "y": 42.737143,
                "fullAddressString": "гр. СОФИЯ ул. ГЕН. НИКОЛА ЖЕКОВ No 22",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. ГЕН. НИКОЛА ЖЕКОВ No 22"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 306,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-01-10",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 381,
            "name": "ТРЯВНА",
            "nameEn": "TRYAVNA",
            "siteId": 73403,
            "address": {
                "countryId": 100,
                "siteId": 73403,
                "siteType": "ГР.",
                "siteName": "ТРЯВНА",
                "postCode": "5350",
                "streetId": 85835,
                "streetType": "УЛ.",
                "streetName": "ИВАНКА ГОРОВА",
                "streetNo": "2А",
                "x": 25.494139,
                "y": 42.868085,
                "fullAddressString": "гр. ТРЯВНА [5350] ул. ИВАНКА ГОРОВА No 2А",
                "siteAddressString": "гр. ТРЯВНА [5350]",
                "localAddressString": "ул. ИВАНКА ГОРОВА No 2А"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "12:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 180,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 841,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "12:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-09-16",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 382,
            "name": "СЪЕДИНЕНИЕ",
            "nameEn": "SAEDINENIE",
            "siteId": 70528,
            "address": {
                "countryId": 100,
                "siteId": 70528,
                "siteType": "ГР.",
                "siteName": "СЪЕДИНЕНИЕ",
                "postCode": "4190",
                "streetId": 95804,
                "streetType": "УЛ.",
                "streetName": "БАБА ИСАКА СКАЧКОВА",
                "streetNo": "6А",
                "x": 24.539499,
                "y": 42.273107,
                "fullAddressString": "гр. СЪЕДИНЕНИЕ [4190] ул. БАБА ИСАКА СКАЧКОВА No 6А",
                "siteAddressString": "гр. СЪЕДИНЕНИЕ [4190]",
                "localAddressString": "ул. БАБА ИСАКА СКАЧКОВА No 6А"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "12:30",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "12:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 201,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "12:30",
                    "sameDayDepartureCutoff": "12:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-11-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 383,
            "name": "РАЗГРАД - ОРЕЛ (ЖК)",
            "nameEn": "RAZGRAD - OREL (ZHK)",
            "siteId": 61710,
            "address": {
                "countryId": 100,
                "siteId": 61710,
                "siteType": "ГР.",
                "siteName": "РАЗГРАД",
                "postCode": "7200",
                "complexId": 2122,
                "complexType": "ЖК",
                "complexName": "ОРЕЛ",
                "blockNo": "16",
                "addressNote": "СРЕЩУ ГАЛАТЕЯ",
                "x": 26.518785,
                "y": 43.516963,
                "fullAddressString": "гр. РАЗГРАД [7200] жк ОРЕЛ бл. 16 СРЕЩУ ГАЛАТЕЯ",
                "siteAddressString": "гр. РАЗГРАД [7200]",
                "localAddressString": "жк ОРЕЛ бл. 16 СРЕЩУ ГАЛАТЕЯ"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 9,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-09-20",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 384,
            "name": "СОФИЯ - КРАСНА ПОЛЯНА",
            "nameEn": "SOFIA – KRASNA POLYANA",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 86,
                "streetType": "БУЛ.",
                "streetName": "НИКОЛА МУШАНОВ",
                "streetNo": "80",
                "x": 23.283366,
                "y": 42.694541,
                "fullAddressString": "гр. СОФИЯ бул. НИКОЛА МУШАНОВ No 80",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "бул. НИКОЛА МУШАНОВ No 80"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 291,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-12-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 385,
            "name": "КЮСТЕНДИЛ - ЦЕНТЪР",
            "nameEn": "KYUSTENDIL - CENTAR",
            "siteId": 41112,
            "address": {
                "countryId": 100,
                "siteId": 41112,
                "siteType": "ГР.",
                "siteName": "КЮСТЕНДИЛ",
                "postCode": "2500",
                "streetId": 54317,
                "streetType": "УЛ.",
                "streetName": "ЦАР СИМЕОН I",
                "streetNo": "23",
                "x": 22.69078,
                "y": 42.283246,
                "fullAddressString": "гр. КЮСТЕНДИЛ [2500] ул. ЦАР СИМЕОН I No 23",
                "siteAddressString": "гр. КЮСТЕНДИЛ [2500]",
                "localAddressString": "ул. ЦАР СИМЕОН I No 23"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 23,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-11-29",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 386,
            "name": "ПЛОВДИВ - АСЕН ХРИСТОФОРОВ",
            "nameEn": "PLOVDIV - ASEN HRISTOFOROV",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "streetId": 10138,
                "streetType": "УЛ.",
                "streetName": "АСЕН ХРИСТОФОРОВ",
                "streetNo": "23",
                "x": 24.729073,
                "y": 42.119516,
                "fullAddressString": "гр. ПЛОВДИВ ул. АСЕН ХРИСТОФОРОВ No 23",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "ул. АСЕН ХРИСТОФОРОВ No 23"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 752,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-11-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 387,
            "name": "МОМЧИЛГРАД",
            "nameEn": "MOMCHILGRAD",
            "siteId": 48996,
            "address": {
                "countryId": 100,
                "siteId": 48996,
                "siteType": "ГР.",
                "siteName": "МОМЧИЛГРАД",
                "postCode": "6800",
                "streetId": 70661,
                "streetType": "УЛ.",
                "streetName": "9-ТИ СЕПТЕМВРИ",
                "streetNo": "5",
                "x": 25.40967,
                "y": 41.52547,
                "fullAddressString": "гр. МОМЧИЛГРАД [6800] ул. 9-ТИ СЕПТЕМВРИ No 5",
                "siteAddressString": "гр. МОМЧИЛГРАД [6800]",
                "localAddressString": "ул. 9-ТИ СЕПТЕМВРИ No 5"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "12:45",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 32,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "12:45",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-12-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 390,
            "name": "БУРГАС - ИЗГРЕВ (ЖК), БЛ. 94",
            "nameEn": "BURGAS - IZGREV (ZHK), BL. 94",
            "siteId": 7079,
            "address": {
                "countryId": 100,
                "siteId": 7079,
                "siteType": "ГР.",
                "siteName": "БУРГАС",
                "postCode": "8000",
                "complexId": 707,
                "complexType": "ЖК",
                "complexName": "ИЗГРЕВ",
                "blockNo": "94",
                "addressNote": "ДО ПАРКА",
                "x": 27.46332,
                "y": 42.523748,
                "fullAddressString": "гр. БУРГАС жк ИЗГРЕВ бл. 94 ДО ПАРКА",
                "siteAddressString": "гр. БУРГАС",
                "localAddressString": "жк ИЗГРЕВ бл. 94 ДО ПАРКА"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 90,
                "height": 170,
                "depth": 120
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 256,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-11-15",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 391,
            "name": "СОФИЯ - КАРПУЗИЦА",
            "nameEn": "SOFIA - KARPUZITSA",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 1678,
                "streetType": "УЛ.",
                "streetName": "ЕВЛИЯ ЧЕЛЕБИ",
                "streetNo": "96",
                "x": 23.243543,
                "y": 42.661333,
                "fullAddressString": "гр. СОФИЯ ул. ЕВЛИЯ ЧЕЛЕБИ No 96",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. ЕВЛИЯ ЧЕЛЕБИ No 96"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 876,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-12-06",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 392,
            "name": "СЛИВЕН - КЛУЦОХОР",
            "nameEn": "SLIVEN - KLUTSOHOR",
            "siteId": 67338,
            "address": {
                "countryId": 100,
                "siteId": 67338,
                "siteType": "ГР.",
                "siteName": "СЛИВЕН",
                "postCode": "8800",
                "streetId": 44133,
                "streetType": "УЛ.",
                "streetName": "ГЕОРГИ ИКОНОМОВ",
                "streetNo": "40",
                "complexId": 2806,
                "complexType": "КВ.",
                "complexName": "КЛУЦОХОР",
                "x": 26.308263,
                "y": 42.678355,
                "fullAddressString": "гр. СЛИВЕН [8800] кв. КЛУЦОХОР ул. ГЕОРГИ ИКОНОМОВ No 40",
                "siteAddressString": "гр. СЛИВЕН [8800]",
                "localAddressString": "кв. КЛУЦОХОР ул. ГЕОРГИ ИКОНОМОВ No 40"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 92,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-03-14",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 394,
            "name": "ДЕВИН",
            "nameEn": "DEVIN",
            "siteId": 20465,
            "address": {
                "countryId": 100,
                "siteId": 20465,
                "siteType": "ГР.",
                "siteName": "ДЕВИН",
                "postCode": "4800",
                "streetId": 91116,
                "streetType": "УЛ.",
                "streetName": "ЦВЕТАН ЗАНГОВ",
                "streetNo": "3",
                "x": 24.39826,
                "y": 41.74211,
                "fullAddressString": "гр. ДЕВИН [4800] ул. ЦВЕТАН ЗАНГОВ No 3",
                "siteAddressString": "гр. ДЕВИН [4800]",
                "localAddressString": "ул. ЦВЕТАН ЗАНГОВ No 3"
            },
            "workingTimeFrom": "10:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "16:00",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 29,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-02-07",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 395,
            "name": "АСЕНОВГРАД - ЗАПАД",
            "nameEn": "ASENOVGRAD - ZAPAD",
            "siteId": 702,
            "address": {
                "countryId": 100,
                "siteId": 702,
                "siteType": "ГР.",
                "siteName": "АСЕНОВГРАД",
                "postCode": "4230",
                "streetId": 65408,
                "streetType": "БУЛ.",
                "streetName": "БЪЛГАРИЯ",
                "streetNo": "84",
                "x": 24.86154,
                "y": 42.021893,
                "fullAddressString": "гр. АСЕНОВГРАД [4230] бул. БЪЛГАРИЯ No 84",
                "siteAddressString": "гр. АСЕНОВГРАД [4230]",
                "localAddressString": "бул. БЪЛГАРИЯ No 84"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 17,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-01-10",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 396,
            "name": "ПЕРНИК - СТРУМА",
            "nameEn": "PERNIK - STRUMA",
            "siteId": 55871,
            "address": {
                "countryId": 100,
                "siteId": 55871,
                "siteType": "ГР.",
                "siteName": "ПЕРНИК",
                "postCode": "2300",
                "streetId": 50573,
                "streetType": "УЛ.",
                "streetName": "СТРУМА",
                "streetNo": "9А",
                "x": 23.021515,
                "y": 42.598035,
                "fullAddressString": "гр. ПЕРНИК [2300] ул. СТРУМА No 9А",
                "siteAddressString": "гр. ПЕРНИК [2300]",
                "localAddressString": "ул. СТРУМА No 9А"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 127,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-01-10",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 397,
            "name": "СТАРА ЗАГОРА - ТАНГРА",
            "nameEn": "STARA ZAGORA - TANGRA",
            "siteId": 68850,
            "address": {
                "countryId": 100,
                "siteId": 68850,
                "siteType": "ГР.",
                "siteName": "СТАРА ЗАГОРА",
                "postCode": "6000",
                "streetId": 18296,
                "streetType": "УЛ.",
                "streetName": "СВ. КНЯЗ БОРИС",
                "streetNo": "136",
                "x": 25.637997,
                "y": 42.427285,
                "fullAddressString": "гр. СТАРА ЗАГОРА ул. СВ. КНЯЗ БОРИС No 136",
                "siteAddressString": "гр. СТАРА ЗАГОРА",
                "localAddressString": "ул. СВ. КНЯЗ БОРИС No 136"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 164,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-12-30",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 398,
            "name": "БЛАГОЕВГРАД - КОНЧЕТО",
            "nameEn": "BLAGOEVGRAD - KONCHETO",
            "siteId": 4279,
            "address": {
                "countryId": 100,
                "siteId": 4279,
                "siteType": "ГР.",
                "siteName": "БЛАГОЕВГРАД",
                "postCode": "2700",
                "streetId": 30046,
                "streetType": "УЛ.",
                "streetName": "ВЛАДО ЧЕРНОЗЕМСКИ",
                "streetNo": "3В",
                "x": 23.091524,
                "y": 42.01933,
                "fullAddressString": "гр. БЛАГОЕВГРАД ул. ВЛАДО ЧЕРНОЗЕМСКИ No 3В",
                "siteAddressString": "гр. БЛАГОЕВГРАД",
                "localAddressString": "ул. ВЛАДО ЧЕРНОЗЕМСКИ No 3В"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "12:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 205,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "12:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-12-20",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 399,
            "name": "ЗЛАТОГРАД",
            "nameEn": "ZLATOGRAD",
            "siteId": 31111,
            "address": {
                "countryId": 100,
                "siteId": 31111,
                "siteType": "ГР.",
                "siteName": "ЗЛАТОГРАД",
                "postCode": "4980",
                "streetId": 78144,
                "streetType": "УЛ.",
                "streetName": "БЕЛОВИДОВО",
                "streetNo": "15",
                "x": 25.09838,
                "y": 41.37893,
                "fullAddressString": "гр. ЗЛАТОГРАД [4980] ул. БЕЛОВИДОВО No 15",
                "siteAddressString": "гр. ЗЛАТОГРАД [4980]",
                "localAddressString": "ул. БЕЛОВИДОВО No 15"
            },
            "workingTimeFrom": "10:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "16:00",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 220,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-01-31",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 400,
            "name": "ШУМЕН - ПЛИСКА",
            "nameEn": "SHUMEN - PLISKA",
            "siteId": 83510,
            "address": {
                "countryId": 100,
                "siteId": 83510,
                "siteType": "ГР.",
                "siteName": "ШУМЕН",
                "postCode": "9700",
                "streetId": 24213,
                "streetType": "БУЛ.",
                "streetName": "ПЛИСКА",
                "streetNo": "30",
                "x": 26.936674,
                "y": 43.271944,
                "fullAddressString": "гр. ШУМЕН [9700] бул. ПЛИСКА No 30",
                "siteAddressString": "гр. ШУМЕН [9700]",
                "localAddressString": "бул. ПЛИСКА No 30"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 154,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-03-14",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 401,
            "name": "САМОКОВ - ПЛОЩАДА",
            "nameEn": "SAMOKOV - PLOSHTADA",
            "siteId": 65231,
            "address": {
                "countryId": 100,
                "siteId": 65231,
                "siteType": "ГР.",
                "siteName": "САМОКОВ",
                "postCode": "2000",
                "streetId": 71640,
                "streetType": "УЛ.",
                "streetName": "ВАСИЛ ЛЕВСКИ",
                "streetNo": "10",
                "x": 23.55735,
                "y": 42.33623,
                "fullAddressString": "гр. САМОКОВ [2000] ул. ВАСИЛ ЛЕВСКИ No 10",
                "siteAddressString": "гр. САМОКОВ [2000]",
                "localAddressString": "ул. ВАСИЛ ЛЕВСКИ No 10"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "12:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 71,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "12:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-04-11",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 402,
            "name": "СОФИЯ - МАЛИНОВА ДОЛИНА (КВ.)",
            "nameEn": "SOFIA - MALINOVA DOLINA  (KV.)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "complexId": 96,
                "complexType": "КВ.",
                "complexName": "МАЛИНОВА ДОЛИНА",
                "blockNo": "25",
                "x": 23.339145,
                "y": 42.638063,
                "fullAddressString": "гр. СОФИЯ кв. МАЛИНОВА ДОЛИНА бл. 25",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "кв. МАЛИНОВА ДОЛИНА бл. 25"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "19:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 225,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-02-28",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 403,
            "name": "КАРЛОВО - СТАМБОЛИЙСКИ",
            "nameEn": "KARLOVO - STAMBOLISKI",
            "siteId": 36498,
            "address": {
                "countryId": 100,
                "siteId": 36498,
                "siteType": "ГР.",
                "siteName": "КАРЛОВО",
                "postCode": "4300",
                "streetId": 78799,
                "streetType": "УЛ.",
                "streetName": "АЛЕКСАНДЪР СТАМБОЛИЙСКИ",
                "streetNo": "62",
                "x": 24.806174,
                "y": 42.633828,
                "fullAddressString": "гр. КАРЛОВО [4300] ул. АЛЕКСАНДЪР СТАМБОЛИЙСКИ No 62",
                "siteAddressString": "гр. КАРЛОВО [4300]",
                "localAddressString": "ул. АЛЕКСАНДЪР СТАМБОЛИЙСКИ No 62"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 22,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-03-28",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 404,
            "name": "ПЛОВДИВ - МАКЕДОНИЯ",
            "nameEn": "PLOVDIV - MAKEDONIA",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "streetId": 10676,
                "streetType": "УЛ.",
                "streetName": "МАКЕДОНИЯ",
                "streetNo": "50",
                "x": 24.741274,
                "y": 42.125652,
                "fullAddressString": "гр. ПЛОВДИВ ул. МАКЕДОНИЯ No 50",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "ул. МАКЕДОНИЯ No 50"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 464,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-05-03",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 405,
            "name": "ГАБРОВО - ВЪЗРАЖДАНЕ",
            "nameEn": "GABROVO - VAZRAZHDANE",
            "siteId": 14218,
            "address": {
                "countryId": 100,
                "siteId": 14218,
                "siteType": "ГР.",
                "siteName": "ГАБРОВО",
                "postCode": "5300",
                "streetId": 48101,
                "streetType": "УЛ.",
                "streetName": "ВЪЗРАЖДАНЕ",
                "streetNo": "7",
                "addressNote": "ЗАД ОБЩИНАТА",
                "x": 25.31471,
                "y": 42.872017,
                "fullAddressString": "гр. ГАБРОВО [5300] ул. ВЪЗРАЖДАНЕ No 7 ЗАД ОБЩИНАТА",
                "siteAddressString": "гр. ГАБРОВО [5300]",
                "localAddressString": "ул. ВЪЗРАЖДАНЕ No 7 ЗАД ОБЩИНАТА"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 874,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-04-13",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 406,
            "name": "ПЕТРИЧ - РОКФЕЛЕР (УЛ.)",
            "nameEn": "PETRICH - ROKFELER (UL.)",
            "siteId": 56126,
            "address": {
                "countryId": 100,
                "siteId": 56126,
                "siteType": "ГР.",
                "siteName": "ПЕТРИЧ",
                "postCode": "2850",
                "streetId": 88708,
                "streetType": "УЛ.",
                "streetName": "РОКФЕЛЕР",
                "streetNo": "44",
                "x": 23.211644,
                "y": 41.397351,
                "fullAddressString": "гр. ПЕТРИЧ [2850] ул. РОКФЕЛЕР No 44",
                "siteAddressString": "гр. ПЕТРИЧ [2850]",
                "localAddressString": "ул. РОКФЕЛЕР No 44"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "16:00",
            "sameDayDepartureCutoffHalf": "12:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 139,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "12:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-04-26",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 407,
            "name": "ПЛОВДИВ - ИЗГРЕВ",
            "nameEn": "PLOVDIV - IZGREV",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "streetId": 10792,
                "streetType": "УЛ.",
                "streetName": "ОСТРЕЦ",
                "streetNo": "1",
                "x": 24.790714,
                "y": 42.157319,
                "fullAddressString": "гр. ПЛОВДИВ ул. ОСТРЕЦ No 1",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "ул. ОСТРЕЦ No 1"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 442,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-04-26",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 408,
            "name": "ХИСАРЯ - ХРИСТО БОТЕВ",
            "nameEn": "HISARIA - HRISTO BOTEV",
            "siteId": 77270,
            "address": {
                "countryId": 100,
                "siteId": 77270,
                "siteType": "ГР.",
                "siteName": "ХИСАРЯ",
                "postCode": "4180",
                "streetId": 86402,
                "streetType": "БУЛ.",
                "streetName": "ХРИСТО БОТЕВ",
                "streetNo": "19А",
                "x": 24.704915,
                "y": 42.505033,
                "fullAddressString": "гр. ХИСАРЯ [4180] бул. ХРИСТО БОТЕВ No 19А",
                "siteAddressString": "гр. ХИСАРЯ [4180]",
                "localAddressString": "бул. ХРИСТО БОТЕВ No 19А"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "12:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 403,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "12:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-04-04",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 410,
            "name": "ВЕЛИНГРАД - СЪЕДИНЕНИЕ",
            "nameEn": "VELINGRAD - SAEDINENIE",
            "siteId": 10450,
            "address": {
                "countryId": 100,
                "siteId": 10450,
                "siteType": "ГР.",
                "siteName": "ВЕЛИНГРАД",
                "postCode": "4600",
                "streetId": 67195,
                "streetType": "БУЛ.",
                "streetName": "СЪЕДИНЕНИЕ",
                "streetNo": "49М",
                "x": 23.98802,
                "y": 42.015891,
                "fullAddressString": "гр. ВЕЛИНГРАД [4600] бул. СЪЕДИНЕНИЕ No 49М",
                "siteAddressString": "гр. ВЕЛИНГРАД [4600]",
                "localAddressString": "бул. СЪЕДИНЕНИЕ No 49М"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "16:30",
            "sameDayDepartureCutoffHalf": "12:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 49,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "12:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-05-09",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 412,
            "name": "ИХТИМАН",
            "nameEn": "IHTIMAN",
            "siteId": 32901,
            "address": {
                "countryId": 100,
                "siteId": 32901,
                "siteType": "ГР.",
                "siteName": "ИХТИМАН",
                "postCode": "2050",
                "streetId": 69138,
                "streetType": "УЛ.",
                "streetName": "СВ. ПАТРИАРХ ЕВТИМИЙ",
                "streetNo": "20",
                "x": 23.817853,
                "y": 42.437146,
                "fullAddressString": "гр. ИХТИМАН [2050] ул. СВ. ПАТРИАРХ ЕВТИМИЙ No 20",
                "siteAddressString": "гр. ИХТИМАН [2050]",
                "localAddressString": "ул. СВ. ПАТРИАРХ ЕВТИМИЙ No 20"
            },
            "workingTimeFrom": "10:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 343,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-09-05",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 413,
            "name": "СОФИЯ - ФЛОРА",
            "nameEn": "SOFIA - FLORA",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 5338,
                "streetType": "УЛ.",
                "streetName": "ФЛОРА",
                "blockNo": "66",
                "x": 23.28755,
                "y": 42.659685,
                "fullAddressString": "гр. СОФИЯ ул. ФЛОРА бл. 66",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. ФЛОРА бл. 66"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 189,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-09-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 414,
            "name": "ПЛОВДИВ - ВЕЛЕШКИ",
            "nameEn": "PLOVDIV - VELESHKI",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "streetId": 10086,
                "streetType": "УЛ.",
                "streetName": "АВКСЕНТИЙ ВЕЛЕШКИ",
                "streetNo": "31",
                "x": 24.74762,
                "y": 42.137867,
                "fullAddressString": "гр. ПЛОВДИВ ул. АВКСЕНТИЙ ВЕЛЕШКИ No 31",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "ул. АВКСЕНТИЙ ВЕЛЕШКИ No 31"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 433,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-06-13",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 415,
            "name": "ХАРМАНЛИ - ПАЗАРА",
            "nameEn": "HARMANLI - PAZARA",
            "siteId": 77181,
            "address": {
                "countryId": 100,
                "siteId": 77181,
                "siteType": "ГР.",
                "siteName": "ХАРМАНЛИ",
                "postCode": "6450",
                "streetId": 103094,
                "streetType": "УЛ.",
                "streetName": "МАРИН ВЕЛЕВ",
                "streetNo": "1",
                "x": 25.900987,
                "y": 41.932514,
                "fullAddressString": "гр. ХАРМАНЛИ [6450] ул. МАРИН ВЕЛЕВ No 1",
                "siteAddressString": "гр. ХАРМАНЛИ [6450]",
                "localAddressString": "ул. МАРИН ВЕЛЕВ No 1"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 47,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-08-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 416,
            "name": "РАЗЛОГ - БЕЛА РЕКА",
            "nameEn": "RAZLOG - BЕLA REKA",
            "siteId": 61813,
            "address": {
                "countryId": 100,
                "siteId": 61813,
                "siteType": "ГР.",
                "siteName": "РАЗЛОГ",
                "postCode": "2760",
                "streetId": 93993,
                "streetType": "УЛ.",
                "streetName": "БЕЛА РЕКА",
                "streetNo": "4В",
                "x": 23.468438,
                "y": 41.888277,
                "fullAddressString": "гр. РАЗЛОГ [2760] ул. БЕЛА РЕКА No 4В",
                "siteAddressString": "гр. РАЗЛОГ [2760]",
                "localAddressString": "ул. БЕЛА РЕКА No 4В"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "12:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 114,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "12:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-07-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 417,
            "name": "ГОЦЕ ДЕЛЧЕВ - ДУНАВ",
            "nameEn": "GOTSE DELCHEV - DUNAV",
            "siteId": 17395,
            "address": {
                "countryId": 100,
                "siteId": 17395,
                "siteType": "ГР.",
                "siteName": "ГОЦЕ ДЕЛЧЕВ",
                "postCode": "2900",
                "streetId": 68024,
                "streetType": "УЛ.",
                "streetName": "ДУНАВ",
                "streetNo": "26",
                "addressNote": "СРЕЩУ ЗЛАТНОТО ПИЛЕ",
                "x": 23.737475,
                "y": 41.57083,
                "fullAddressString": "гр. ГОЦЕ ДЕЛЧЕВ [2900] ул. ДУНАВ No 26 СРЕЩУ ЗЛАТНОТО ПИЛЕ",
                "siteAddressString": "гр. ГОЦЕ ДЕЛЧЕВ [2900]",
                "localAddressString": "ул. ДУНАВ No 26 СРЕЩУ ЗЛАТНОТО ПИЛЕ"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "16:00",
            "sameDayDepartureCutoffHalf": "11:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 31,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "11:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-07-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 418,
            "name": "ЯМБОЛ - КАРГОН",
            "nameEn": "YAMBOL - KARGON",
            "siteId": 87374,
            "address": {
                "countryId": 100,
                "siteId": 87374,
                "siteType": "ГР.",
                "siteName": "ЯМБОЛ",
                "postCode": "8600",
                "streetId": 26124,
                "streetType": "УЛ.",
                "streetName": "ЖЕКО АНДРЕЕВ",
                "streetNo": "53",
                "x": 26.497618,
                "y": 42.477059,
                "fullAddressString": "гр. ЯМБОЛ [8600] ул. ЖЕКО АНДРЕЕВ No 53",
                "siteAddressString": "гр. ЯМБОЛ [8600]",
                "localAddressString": "ул. ЖЕКО АНДРЕЕВ No 53"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 185,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-08-02",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 419,
            "name": "ВИДИН - БОНОНИЯ",
            "nameEn": "VIDIN - BONONIA",
            "siteId": 10971,
            "address": {
                "countryId": 100,
                "siteId": 10971,
                "siteType": "ГР.",
                "siteName": "ВИДИН",
                "postCode": "3700",
                "streetId": 36059,
                "streetType": "УЛ.",
                "streetName": "ВЕЛА ПЕЕВА",
                "streetNo": "21",
                "complexId": 1801,
                "complexType": "ЖК",
                "complexName": "БОНОНИЯ",
                "x": 22.8812,
                "y": 43.99717,
                "fullAddressString": "гр. ВИДИН [3700] жк БОНОНИЯ ул. ВЕЛА ПЕЕВА No 21",
                "siteAddressString": "гр. ВИДИН [3700]",
                "localAddressString": "жк БОНОНИЯ ул. ВЕЛА ПЕЕВА No 21"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 170,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 818,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-07-25",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 422,
            "name": "СОФИЯ - ВРЪБНИЦА 1 (ЖК)",
            "nameEn": "SOFIA - VRABNITSA 1 (ZHK)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "complexId": 6,
                "complexType": "ЖК",
                "complexName": "ВРЪБНИЦА 1",
                "blockNo": "526",
                "entranceNo": "Б",
                "x": 23.289254,
                "y": 42.733733,
                "fullAddressString": "гр. СОФИЯ жк ВРЪБНИЦА 1 бл. 526 вх. Б",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк ВРЪБНИЦА 1 бл. 526 вх. Б"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 281,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-08-08",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 424,
            "name": "ДИМИТРОВГРАД - РАКОВСКИ",
            "nameEn": "DIMITROVGRAD - RAKOVSKI",
            "siteId": 21052,
            "address": {
                "countryId": 100,
                "siteId": 21052,
                "siteType": "ГР.",
                "siteName": "ДИМИТРОВГРАД",
                "postCode": "6400",
                "streetId": 68355,
                "streetType": "БУЛ.",
                "streetName": "Г. С. РАКОВСКИ",
                "streetNo": "94",
                "x": 25.608573,
                "y": 42.052671,
                "fullAddressString": "гр. ДИМИТРОВГРАД [6400] бул. Г. С. РАКОВСКИ No 94",
                "siteAddressString": "гр. ДИМИТРОВГРАД [6400]",
                "localAddressString": "бул. Г. С. РАКОВСКИ No 94"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 42,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-08-29",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 425,
            "name": "ЛОВЕЧ - КНЯЗ ИМЕРЕТИНСКИ (УЛ.)",
            "nameEn": "LOVECH - KNYAZ IMERETINSKI (UL.)",
            "siteId": 43952,
            "address": {
                "countryId": 100,
                "siteId": 43952,
                "siteType": "ГР.",
                "siteName": "ЛОВЕЧ",
                "postCode": "5500",
                "streetId": 32095,
                "streetType": "УЛ.",
                "streetName": "КНЯЗ ИМЕРЕТИНСКИ",
                "streetNo": "58Б",
                "x": 24.712318,
                "y": 43.140538,
                "fullAddressString": "гр. ЛОВЕЧ [5500] ул. КНЯЗ ИМЕРЕТИНСКИ No 58Б",
                "siteAddressString": "гр. ЛОВЕЧ [5500]",
                "localAddressString": "ул. КНЯЗ ИМЕРЕТИНСКИ No 58Б"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 100,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 25,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-08-29",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 426,
            "name": "САНДАНСКИ - НОВА АВТОГАРА",
            "nameEn": "SANDANSKI - NOVA AVTOGARA",
            "siteId": 65334,
            "address": {
                "countryId": 100,
                "siteId": 65334,
                "siteType": "ГР.",
                "siteName": "САНДАНСКИ",
                "postCode": "2800",
                "streetId": 89169,
                "streetType": "УЛ.",
                "streetName": "ТОДОР КАБЛЕШКОВ",
                "streetNo": "2",
                "x": 23.272133,
                "y": 41.552992,
                "fullAddressString": "гр. САНДАНСКИ [2800] ул. ТОДОР КАБЛЕШКОВ No 2",
                "siteAddressString": "гр. САНДАНСКИ [2800]",
                "localAddressString": "ул. ТОДОР КАБЛЕШКОВ No 2"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "12:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 30,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "12:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-09-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 427,
            "name": "КРУМОВГРАД",
            "nameEn": "KRUMOVGRAD",
            "siteId": 39970,
            "address": {
                "countryId": 100,
                "siteId": 39970,
                "siteType": "ГР.",
                "siteName": "КРУМОВГРАД",
                "postCode": "6900",
                "streetId": 69696,
                "streetType": "УЛ.",
                "streetName": "КНЯЗ БОРИС I",
                "streetNo": "6",
                "x": 25.654031,
                "y": 41.472626,
                "fullAddressString": "гр. КРУМОВГРАД [6900] ул. КНЯЗ БОРИС I No 6",
                "siteAddressString": "гр. КРУМОВГРАД [6900]",
                "localAddressString": "ул. КНЯЗ БОРИС I No 6"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "16:30",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 32,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-09-26",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 428,
            "name": "СОФИЯ - СКОБЕЛЕВ (БУЛ.)",
            "nameEn": "SOFIA - SKOBELEV (BUL.)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 41,
                "streetType": "БУЛ.",
                "streetName": "ГЕН. МИХАИЛ Д. СКОБЕЛЕВ",
                "streetNo": "44",
                "x": 23.312076,
                "y": 42.691163,
                "fullAddressString": "гр. СОФИЯ бул. ГЕН. МИХАИЛ Д. СКОБЕЛЕВ No 44",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "бул. ГЕН. МИХАИЛ Д. СКОБЕЛЕВ No 44"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 452,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-09-30",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 429,
            "name": "ВАРНА - ВИНИЦА 2",
            "nameEn": "VARNA - VINICA 2",
            "siteId": 10135,
            "address": {
                "countryId": 100,
                "siteId": 10135,
                "siteType": "ГР.",
                "siteName": "ВАРНА",
                "postCode": "9000",
                "streetId": 12797,
                "streetType": "УЛ.",
                "streetName": "ЦАР БОРИС III",
                "streetNo": "10",
                "complexId": 615,
                "complexType": "КВ.",
                "complexName": "ВИНИЦА",
                "x": 27.978787,
                "y": 43.243024,
                "fullAddressString": "гр. ВАРНА кв. ВИНИЦА ул. ЦАР БОРИС III No 10",
                "siteAddressString": "гр. ВАРНА",
                "localAddressString": "кв. ВИНИЦА ул. ЦАР БОРИС III No 10"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 220
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 303,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-10-17",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 430,
            "name": "ПЛОВДИВ - ТРАКИЯ (ЖК), БЛ.100",
            "nameEn": "PLOVDIV - TRAKIA (ZHK), BL.100",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "streetId": 10203,
                "streetType": "УЛ.",
                "streetName": "БРАТАН",
                "streetNo": "2",
                "complexId": 504,
                "complexType": "ЖК",
                "complexName": "ТРАКИЯ",
                "x": 24.795349,
                "y": 42.132387,
                "fullAddressString": "гр. ПЛОВДИВ жк ТРАКИЯ ул. БРАТАН No 2",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "жк ТРАКИЯ ул. БРАТАН No 2"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 837,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-10-24",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 431,
            "name": "ДРЯНОВО - ШИПКА (УЛ.)",
            "nameEn": "DRYANOVO - SHIPKA (UL.)",
            "siteId": 23947,
            "address": {
                "countryId": 100,
                "siteId": 23947,
                "siteType": "ГР.",
                "siteName": "ДРЯНОВО",
                "postCode": "5370",
                "streetId": 77297,
                "streetType": "УЛ.",
                "streetName": "ШИПКА",
                "streetNo": "112",
                "x": 25.47571,
                "y": 42.97757,
                "fullAddressString": "гр. ДРЯНОВО [5370] ул. ШИПКА No 112",
                "siteAddressString": "гр. ДРЯНОВО [5370]",
                "localAddressString": "ул. ШИПКА No 112"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "12:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 11,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "12:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-10-31",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 432,
            "name": "ПАЗАРДЖИК - УСТРЕМ",
            "nameEn": "PAZARDZHIK - USTREM",
            "siteId": 55155,
            "address": {
                "countryId": 100,
                "siteId": 55155,
                "siteType": "ГР.",
                "siteName": "ПАЗАРДЖИК",
                "postCode": "4400",
                "streetId": 40243,
                "streetType": "УЛ.",
                "streetName": "ПЕТЪР БОНЕВ",
                "streetNo": "70",
                "x": 24.322808,
                "y": 42.202109,
                "fullAddressString": "гр. ПАЗАРДЖИК [4400] ул. ПЕТЪР БОНЕВ No 70",
                "siteAddressString": "гр. ПАЗАРДЖИК [4400]",
                "localAddressString": "ул. ПЕТЪР БОНЕВ No 70"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 18,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2023-02-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 433,
            "name": "ПЛОВДИВ - ЦЕНТРАЛНА ГАРА",
            "nameEn": "PLOVDIV - CENTRALNA GARA",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "streetId": 10032,
                "streetType": "БУЛ.",
                "streetName": "ХРИСТО БОТЕВ",
                "streetNo": "7",
                "x": 24.736077,
                "y": 42.134306,
                "fullAddressString": "гр. ПЛОВДИВ бул. ХРИСТО БОТЕВ No 7",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "бул. ХРИСТО БОТЕВ No 7"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 414,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-11-21",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 434,
            "name": "ПЛОВДИВ - ГРОЗДОВ ПАЗАР",
            "nameEn": "PLOVDIV - GROZDOV PAZAR",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "streetId": 10043,
                "streetType": "ПЛ.",
                "streetName": "ГРОЗДОВ ПАЗАР",
                "streetNo": "1",
                "x": 24.745615,
                "y": 42.144275,
                "fullAddressString": "гр. ПЛОВДИВ пл. ГРОЗДОВ ПАЗАР No 1",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "пл. ГРОЗДОВ ПАЗАР No 1"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 337,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-11-21",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 435,
            "name": "ПЛОВДИВ - ПЛ. СЪЕДИНЕНИЕ",
            "nameEn": "PLOVDIV - PL. SAEDINENIE",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "streetId": 10831,
                "streetType": "УЛ.",
                "streetName": "ПЕТКО КАРАВЕЛОВ",
                "streetNo": "26Б",
                "x": 24.745697,
                "y": 42.151492,
                "fullAddressString": "гр. ПЛОВДИВ ул. ПЕТКО КАРАВЕЛОВ No 26Б",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "ул. ПЕТКО КАРАВЕЛОВ No 26Б"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 337,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2023-02-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 436,
            "name": "СИЛИСТРА - 7-МИ СЕПТЕМВРИ (УЛ.)",
            "nameEn": "SILISTRA - 7-MI SEPTEMVRI (UL.)",
            "siteId": 66425,
            "address": {
                "countryId": 100,
                "siteId": 66425,
                "siteType": "ГР.",
                "siteName": "СИЛИСТРА",
                "postCode": "7500",
                "streetId": 46220,
                "streetType": "УЛ.",
                "streetName": "7-МИ СЕПТЕМВРИ",
                "streetNo": "37",
                "x": 27.27301,
                "y": 44.11314,
                "fullAddressString": "гр. СИЛИСТРА [7500] ул. 7-МИ СЕПТЕМВРИ No 37",
                "siteAddressString": "гр. СИЛИСТРА [7500]",
                "localAddressString": "ул. 7-МИ СЕПТЕМВРИ No 37"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "16:30",
            "sameDayDepartureCutoffHalf": "11:45",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 171,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "11:45",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-12-08",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 437,
            "name": "СОФИЯ - ОБЕЛЯ 2 (ЖК)",
            "nameEn": "SOFIA - OBELYA 2 (ZHK)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 153,
                "streetType": "УЛ.",
                "streetName": "106",
                "streetNo": "34",
                "complexId": 61,
                "complexType": "ЖК",
                "complexName": "ОБЕЛЯ 2",
                "x": 23.270012,
                "y": 42.747644,
                "fullAddressString": "гр. СОФИЯ жк ОБЕЛЯ 2 ул. 106 No 34",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк ОБЕЛЯ 2 ул. 106 No 34"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 282,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-12-12",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 439,
            "name": "КОТЕЛ",
            "nameEn": "KOTEL",
            "siteId": 39030,
            "address": {
                "countryId": 100,
                "siteId": 39030,
                "siteType": "ГР.",
                "siteName": "КОТЕЛ",
                "postCode": "8970",
                "streetId": 92409,
                "streetType": "УЛ.",
                "streetName": "ПРОФ. ПАВЛОВ",
                "streetNo": "41А",
                "x": 26.44532,
                "y": 42.88855,
                "fullAddressString": "гр. КОТЕЛ [8970] ул. ПРОФ. ПАВЛОВ No 41А",
                "siteAddressString": "гр. КОТЕЛ [8970]",
                "localAddressString": "ул. ПРОФ. ПАВЛОВ No 41А"
            },
            "workingTimeFrom": "10:00",
            "workingTimeTo": "16:30",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "16:30",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 7,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "16:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "16:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "16:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "16:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "16:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "16:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "16:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "16:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-12-15",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 440,
            "name": "СТАРА ЗАГОРА - КАЗАНСКИ",
            "nameEn": "STARA ZAGORA - KAZANSKI",
            "siteId": 68850,
            "address": {
                "countryId": 100,
                "siteId": 68850,
                "siteType": "ГР.",
                "siteName": "СТАРА ЗАГОРА",
                "postCode": "6000",
                "streetNo": "18",
                "complexId": 902,
                "complexType": "КВ.",
                "complexName": "КАЗАНСКИ",
                "x": 25.610048,
                "y": 42.414708,
                "fullAddressString": "гр. СТАРА ЗАГОРА кв. КАЗАНСКИ No 18",
                "siteAddressString": "гр. СТАРА ЗАГОРА",
                "localAddressString": "кв. КАЗАНСКИ No 18"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 254,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2023-03-13",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 441,
            "name": "СОПОТ",
            "nameEn": "SOPOT",
            "siteId": 68080,
            "address": {
                "countryId": 100,
                "siteId": 68080,
                "siteType": "ГР.",
                "siteName": "СОПОТ",
                "postCode": "4330",
                "streetId": 84830,
                "streetType": "УЛ.",
                "streetName": "ХРИСТО МАДЖАРОВ",
                "streetNo": "2А",
                "x": 24.755883,
                "y": 42.65357,
                "fullAddressString": "гр. СОПОТ [4330] ул. ХРИСТО МАДЖАРОВ No 2А",
                "siteAddressString": "гр. СОПОТ [4330]",
                "localAddressString": "ул. ХРИСТО МАДЖАРОВ No 2А"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 22,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2023-03-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 442,
            "name": "ПЛОВДИВ - СКОБЕЛЕВА МАЙКА",
            "nameEn": "PLOVDIV - SKOBELEVA MAIKA",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "streetId": 10333,
                "streetType": "УЛ.",
                "streetName": "ГЕОРГИ ЦАРИГРАДСКИ",
                "streetNo": "6",
                "x": 24.798921,
                "y": 42.144605,
                "fullAddressString": "гр. ПЛОВДИВ ул. ГЕОРГИ ЦАРИГРАДСКИ No 6",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "ул. ГЕОРГИ ЦАРИГРАДСКИ No 6"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 407,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2023-07-03",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 443,
            "name": "СОФИЯ - ХУБЧА (УЛ.)",
            "nameEn": "SOFIA - HUBCHA (UL.)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 3198,
                "streetType": "УЛ.",
                "streetName": "ХУБЧА",
                "streetNo": "2",
                "complexId": 30,
                "complexType": "ЖК",
                "complexName": "КРАСНО СЕЛО",
                "x": 23.286245,
                "y": 42.677242,
                "fullAddressString": "гр. СОФИЯ жк КРАСНО СЕЛО ул. ХУБЧА No 2",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк КРАСНО СЕЛО ул. ХУБЧА No 2"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 203,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2023-02-20",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 444,
            "name": "ПРИМОРСКО - АЗИНЕ (УЛ.)",
            "nameEn": "PRIMORSKO - AZINE (UL.)",
            "siteId": 58356,
            "address": {
                "countryId": 100,
                "siteId": 58356,
                "siteType": "ГР.",
                "siteName": "ПРИМОРСКО",
                "postCode": "8180",
                "streetId": 95734,
                "streetType": "УЛ.",
                "streetName": "АЗИНЕ",
                "streetNo": "1",
                "addressNote": "ДО АКВАПАРКА",
                "x": 27.746937,
                "y": 42.266945,
                "fullAddressString": "гр. ПРИМОРСКО [8180] ул. АЗИНЕ No 1 ДО АКВАПАРКА",
                "siteAddressString": "гр. ПРИМОРСКО [8180]",
                "localAddressString": "ул. АЗИНЕ No 1 ДО АКВАПАРКА"
            },
            "workingTimeFrom": "10:00",
            "workingTimeTo": "17:00",
            "workingTimeHalfFrom": "10:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 728,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2023-03-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 445,
            "name": "ДОСПАТ",
            "nameEn": "DOSPAT",
            "siteId": 23025,
            "address": {
                "countryId": 100,
                "siteId": 23025,
                "siteType": "ГР.",
                "siteName": "ДОСПАТ",
                "postCode": "4831",
                "streetId": 77077,
                "streetType": "УЛ.",
                "streetName": "КАП. ПЕТКО ВОЙВОДА",
                "streetNo": "5",
                "x": 24.16175,
                "y": 41.65136,
                "fullAddressString": "гр. ДОСПАТ [4831] ул. КАП. ПЕТКО ВОЙВОДА No 5",
                "siteAddressString": "гр. ДОСПАТ [4831]",
                "localAddressString": "ул. КАП. ПЕТКО ВОЙВОДА No 5"
            },
            "workingTimeFrom": "10:30",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "15:30",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 394,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "10:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "15:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "10:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "15:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "10:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "15:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "10:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "15:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "10:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "15:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "10:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "15:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "10:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "15:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "10:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "15:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2023-04-03",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 446,
            "name": "КРУШОВИЦА",
            "nameEn": "KRUSHOVITSA",
            "siteId": 40200,
            "address": {
                "countryId": 100,
                "siteId": 40200,
                "siteType": "С.",
                "siteName": "КРУШОВИЦА",
                "postCode": "3349",
                "streetId": 125689,
                "streetType": "УЛ.",
                "streetName": "МИНКО КОСТАДИНОВ",
                "streetNo": "83",
                "x": 23.848872,
                "y": 43.62511,
                "fullAddressString": "с. КРУШОВИЦА [3349] ул. МИНКО КОСТАДИНОВ No 83",
                "siteAddressString": "с. КРУШОВИЦА [3349]",
                "localAddressString": "ул. МИНКО КОСТАДИНОВ No 83"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 766,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2023-02-27",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 447,
            "name": "ШУМЕН - ТРАКИЯ ПЛАЗА",
            "nameEn": "SHUMEN - TRAKIYA PLAZA",
            "siteId": 83510,
            "address": {
                "countryId": 100,
                "siteId": 83510,
                "siteType": "ГР.",
                "siteName": "ШУМЕН",
                "postCode": "9700",
                "streetId": 24229,
                "streetType": "УЛ.",
                "streetName": "РОДОПИ",
                "streetNo": "24",
                "complexId": 1204,
                "complexType": "КВ.",
                "complexName": "ТРАКИЯ",
                "addressNote": "ТРАКИЯ ПЛАЗА",
                "x": 26.954725,
                "y": 43.275284,
                "fullAddressString": "гр. ШУМЕН [9700] кв. ТРАКИЯ ул. РОДОПИ No 24 ТРАКИЯ ПЛАЗА",
                "siteAddressString": "гр. ШУМЕН [9700]",
                "localAddressString": "кв. ТРАКИЯ ул. РОДОПИ No 24 ТРАКИЯ ПЛАЗА"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 100,
                "height": 180,
                "depth": 100
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 8,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2023-04-03",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 448,
            "name": "КАЛОЯНОВО",
            "nameEn": "KALOYANOVO",
            "siteId": 35523,
            "address": {
                "countryId": 100,
                "siteId": 35523,
                "siteType": "С.",
                "siteName": "КАЛОЯНОВО",
                "postCode": "4173",
                "streetId": 78527,
                "streetType": "УЛ.",
                "streetName": "ВАСИЛ ЛЕВСКИ",
                "streetNo": "6",
                "x": 24.7326,
                "y": 42.35627,
                "fullAddressString": "с. КАЛОЯНОВО [4173] ул. ВАСИЛ ЛЕВСКИ No 6",
                "siteAddressString": "с. КАЛОЯНОВО [4173]",
                "localAddressString": "ул. ВАСИЛ ЛЕВСКИ No 6"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "16:30",
            "sameDayDepartureCutoffHalf": "12:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 408,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "12:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2023-05-02",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 449,
            "name": "ПЕРНИК - ИЗТОК",
            "nameEn": "PERNIK - IZTOK",
            "siteId": 55871,
            "address": {
                "countryId": 100,
                "siteId": 55871,
                "siteType": "ГР.",
                "siteName": "ПЕРНИК",
                "postCode": "2300",
                "streetId": 50507,
                "streetType": "УЛ.",
                "streetName": "РИГА",
                "streetNo": "35",
                "complexId": 3030,
                "complexType": "КВ.",
                "complexName": "ИЗТОК",
                "x": 23.097445,
                "y": 42.611187,
                "fullAddressString": "гр. ПЕРНИК [2300] кв. ИЗТОК ул. РИГА No 35",
                "siteAddressString": "гр. ПЕРНИК [2300]",
                "localAddressString": "кв. ИЗТОК ул. РИГА No 35"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 174,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2023-04-24",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 450,
            "name": "ПОМОРИЕ – МОРСКА (УЛ.)",
            "nameEn": "POMORIE - MORSKA (UL.)",
            "siteId": 57491,
            "address": {
                "countryId": 100,
                "siteId": 57491,
                "siteType": "ГР.",
                "siteName": "ПОМОРИЕ",
                "postCode": "8200",
                "streetId": 93538,
                "streetType": "УЛ.",
                "streetName": "МОРСКА",
                "streetNo": "52",
                "addressNote": "НА АВТОГАРАТА",
                "x": 27.61111,
                "y": 42.565566,
                "fullAddressString": "гр. ПОМОРИЕ [8200] ул. МОРСКА No 52 НА АВТОГАРАТА",
                "siteAddressString": "гр. ПОМОРИЕ [8200]",
                "localAddressString": "ул. МОРСКА No 52 НА АВТОГАРАТА"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 120,
                "height": 180,
                "depth": 200
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 197,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2023-05-03",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 451,
            "name": "ЕЛЕНА",
            "nameEn": "ELENA",
            "siteId": 27190,
            "address": {
                "countryId": 100,
                "siteId": 27190,
                "siteType": "ГР.",
                "siteName": "ЕЛЕНА",
                "postCode": "5070",
                "streetId": 77546,
                "streetType": "УЛ.",
                "streetName": "3-ТИ МАРТ",
                "streetNo": "9",
                "x": 25.894636,
                "y": 42.929209,
                "fullAddressString": "гр. ЕЛЕНА [5070] ул. 3-ТИ МАРТ No 9",
                "siteAddressString": "гр. ЕЛЕНА [5070]",
                "localAddressString": "ул. 3-ТИ МАРТ No 9"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 11,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2023-04-27",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 452,
            "name": "СОФИЯ - ПЕТТЕ КЬОШЕТА",
            "nameEn": "SOFIA - PETTE KYOSHETA",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 124,
                "streetType": "БУЛ.",
                "streetName": "ХРИСТО БОТЕВ",
                "streetNo": "12",
                "x": 23.314568,
                "y": 42.690837,
                "fullAddressString": "гр. СОФИЯ бул. ХРИСТО БОТЕВ No 12",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "бул. ХРИСТО БОТЕВ No 12"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 428,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2023-05-30",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 453,
            "name": "СЛЪНЧЕВ БРЯГ",
            "nameEn": "SUNNY BEACH",
            "siteId": 98212,
            "address": {
                "countryId": 100,
                "siteId": 98212,
                "siteType": "К.К.",
                "siteName": "СЛЪНЧЕВ БРЯГ",
                "postCode": "8240",
                "streetNo": "121",
                "complexId": 3825,
                "complexType": "КВ.",
                "complexName": "КАМЕЛИЯ",
                "addressNote": "ЖИЛИЩНА СГРАДА ЛУНА",
                "x": 27.705309,
                "y": 42.692889,
                "fullAddressString": "к.к. СЛЪНЧЕВ БРЯГ [8240] кв. КАМЕЛИЯ No 121 ЖИЛИЩНА СГРАДА ЛУНА",
                "siteAddressString": "к.к. СЛЪНЧЕВ БРЯГ [8240]",
                "localAddressString": "кв. КАМЕЛИЯ No 121 ЖИЛИЩНА СГРАДА ЛУНА"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 200,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 878,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2023-05-18",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 454,
            "name": "ЧИРПАН - ИЗТОК",
            "nameEn": "CHIRPAN - IZTOK",
            "siteId": 81414,
            "address": {
                "countryId": 100,
                "siteId": 81414,
                "siteType": "ГР.",
                "siteName": "ЧИРПАН",
                "postCode": "6200",
                "streetId": 95428,
                "streetType": "БУЛ.",
                "streetName": "ГЕОРГИ ДИМИТРОВ",
                "streetNo": "75",
                "x": 25.332659,
                "y": 42.198767,
                "fullAddressString": "гр. ЧИРПАН [6200] бул. ГЕОРГИ ДИМИТРОВ No 75",
                "siteAddressString": "гр. ЧИРПАН [6200]",
                "localAddressString": "бул. ГЕОРГИ ДИМИТРОВ No 75"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 237,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2023-07-03",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 455,
            "name": "СВЕТИ ВЛАС",
            "nameEn": "SVETI VLAS",
            "siteId": 11538,
            "address": {
                "countryId": 100,
                "siteId": 11538,
                "siteType": "ГР.",
                "siteName": "СВЕТИ ВЛАС",
                "postCode": "8256",
                "streetId": 96009,
                "streetType": "УЛ.",
                "streetName": "ЦАР СИМЕОН",
                "streetNo": "32",
                "addressNote": "ВХОД ОТ УЛ. ЗОРА",
                "x": 27.75949,
                "y": 42.713187,
                "fullAddressString": "гр. СВЕТИ ВЛАС [8256] ул. ЦАР СИМЕОН No 32 ВХОД ОТ УЛ. ЗОРА",
                "siteAddressString": "гр. СВЕТИ ВЛАС [8256]",
                "localAddressString": "ул. ЦАР СИМЕОН No 32 ВХОД ОТ УЛ. ЗОРА"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 150,
                "depth": 250
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 453,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2023-06-14",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 456,
            "name": "ГАБРОВО - БИЛЛА",
            "nameEn": "GABROVO - BILLA",
            "siteId": 14218,
            "address": {
                "countryId": 100,
                "siteId": 14218,
                "siteType": "ГР.",
                "siteName": "ГАБРОВО",
                "postCode": "5300",
                "streetId": 48352,
                "streetType": "УЛ.",
                "streetName": "ОРЛОВСКА",
                "streetNo": "119",
                "x": 25.320579,
                "y": 42.880752,
                "fullAddressString": "гр. ГАБРОВО [5300] ул. ОРЛОВСКА No 119",
                "siteAddressString": "гр. ГАБРОВО [5300]",
                "localAddressString": "ул. ОРЛОВСКА No 119"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 170,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 15,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2023-05-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 457,
            "name": "ДИМИТРОВГРАД - ПАТРИАРХ ЕВТИМИЙ ДО РЕЛАКС",
            "nameEn": "DIMITROVGRAD - PATRIARH EVTIMIY DO RELAX",
            "siteId": 21052,
            "address": {
                "countryId": 100,
                "siteId": 21052,
                "siteType": "ГР.",
                "siteName": "ДИМИТРОВГРАД",
                "postCode": "6400",
                "streetId": 68483,
                "streetType": "УЛ.",
                "streetName": "ПАТРИАРХ ЕВТИМИЙ",
                "streetNo": "7",
                "x": 25.582448,
                "y": 42.052623,
                "fullAddressString": "гр. ДИМИТРОВГРАД [6400] ул. ПАТРИАРХ ЕВТИМИЙ No 7",
                "siteAddressString": "гр. ДИМИТРОВГРАД [6400]",
                "localAddressString": "ул. ПАТРИАРХ ЕВТИМИЙ No 7"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 249,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2023-08-07",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 458,
            "name": "ХАСКОВО - ХИСАРЯ",
            "nameEn": "HASKOVO - HISARYA",
            "siteId": 77195,
            "address": {
                "countryId": 100,
                "siteId": 77195,
                "siteType": "ГР.",
                "siteName": "ХАСКОВО",
                "postCode": "6300",
                "streetId": 28229,
                "streetType": "УЛ.",
                "streetName": "ЛОМ",
                "streetNo": "1",
                "x": 25.558823,
                "y": 41.922034,
                "fullAddressString": "гр. ХАСКОВО [6300] ул. ЛОМ No 1",
                "siteAddressString": "гр. ХАСКОВО [6300]",
                "localAddressString": "ул. ЛОМ No 1"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 882,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2023-08-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 459,
            "name": "ХАСКОВО - ДУНАВ (УЛ.), РИТЕЙЛ ПАРК",
            "nameEn": "HASKOVO - DUNAV (UL.), RETAIL PARK",
            "siteId": 77195,
            "address": {
                "countryId": 100,
                "siteId": 77195,
                "siteType": "ГР.",
                "siteName": "ХАСКОВО",
                "postCode": "6300",
                "streetId": 28153,
                "streetType": "УЛ.",
                "streetName": "ДУНАВ",
                "streetNo": "28",
                "x": 25.556851,
                "y": 41.939642,
                "fullAddressString": "гр. ХАСКОВО [6300] ул. ДУНАВ No 28",
                "siteAddressString": "гр. ХАСКОВО [6300]",
                "localAddressString": "ул. ДУНАВ No 28"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 82,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2023-08-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 460,
            "name": "АЙТОС - ПАЗАРА",
            "nameEn": "AYTOS - PAZARA",
            "siteId": 151,
            "address": {
                "countryId": 100,
                "siteId": 151,
                "siteType": "ГР.",
                "siteName": "АЙТОС",
                "postCode": "8500",
                "streetId": 87676,
                "streetType": "УЛ.",
                "streetName": "ДИМИТЪР БЛАГОЕВ",
                "streetNo": "1",
                "addressNote": "ДО ПАЗАРА",
                "x": 27.256662,
                "y": 42.70144,
                "fullAddressString": "гр. АЙТОС [8500] ул. ДИМИТЪР БЛАГОЕВ No 1 ДО ПАЗАРА",
                "siteAddressString": "гр. АЙТОС [8500]",
                "localAddressString": "ул. ДИМИТЪР БЛАГОЕВ No 1 ДО ПАЗАРА"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 180,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 190,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2023-09-18",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 462,
            "name": "ПРАВЕЦ - ОПЪЛЧЕНСКА (УЛ.)",
            "nameEn": "PRAVETS - OPALCHENSKA (UL.)",
            "siteId": 58030,
            "address": {
                "countryId": 100,
                "siteId": 58030,
                "siteType": "ГР.",
                "siteName": "ПРАВЕЦ",
                "postCode": "2161",
                "streetId": 83102,
                "streetType": "УЛ.",
                "streetName": "ОПЪЛЧЕНСКА",
                "streetNo": "8",
                "x": 23.91383,
                "y": 42.89704,
                "fullAddressString": "гр. ПРАВЕЦ [2161] ул. ОПЪЛЧЕНСКА No 8",
                "siteAddressString": "гр. ПРАВЕЦ [2161]",
                "localAddressString": "ул. ОПЪЛЧЕНСКА No 8"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 180,
                "depth": 50
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 141,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2023-09-13",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 463,
            "name": "ГЪЛЪБОВО - ЦЕНТЪР",
            "nameEn": "GALABOVO - CENTAR",
            "siteId": 18280,
            "address": {
                "countryId": 100,
                "siteId": 18280,
                "siteType": "ГР.",
                "siteName": "ГЪЛЪБОВО",
                "postCode": "6280",
                "streetId": 88256,
                "streetType": "УЛ.",
                "streetName": "ТУНДЖА",
                "streetNo": "10",
                "x": 25.869123,
                "y": 42.138841,
                "fullAddressString": "гр. ГЪЛЪБОВО [6280] ул. ТУНДЖА No 10",
                "siteAddressString": "гр. ГЪЛЪБОВО [6280]",
                "localAddressString": "ул. ТУНДЖА No 10"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "17:00",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 358,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2023-08-15",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": false
        },
        {
            "id": 464,
            "name": "ПЛОВДИВ - ПЕРЕ ТОШЕВ",
            "nameEn": "PLODVIV - PERE TOSHEV",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "streetId": 10822,
                "streetType": "УЛ.",
                "streetName": "ПЕРЕ ТОШЕВ",
                "streetNo": "6",
                "x": 24.747979,
                "y": 42.129024,
                "fullAddressString": "гр. ПЛОВДИВ ул. ПЕРЕ ТОШЕВ No 6",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "ул. ПЕРЕ ТОШЕВ No 6"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 404,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2023-11-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 465,
            "name": "СЛИВНИЦА - ПАИСИЙ ХИЛЕНДАРСКИ",
            "nameEn": "SLIVNITSA - PAISIY HILENDARSKI ",
            "siteId": 67372,
            "address": {
                "countryId": 100,
                "siteId": 67372,
                "siteType": "ГР.",
                "siteName": "СЛИВНИЦА",
                "postCode": "2200",
                "streetId": 94645,
                "streetType": "БУЛ.",
                "streetName": "ПАИСИЙ ХИЛЕНДАРСКИ",
                "streetNo": "68А",
                "x": 23.03524,
                "y": 42.85516,
                "fullAddressString": "гр. СЛИВНИЦА [2200] бул. ПАИСИЙ ХИЛЕНДАРСКИ No 68А",
                "siteAddressString": "гр. СЛИВНИЦА [2200]",
                "localAddressString": "бул. ПАИСИЙ ХИЛЕНДАРСКИ No 68А"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 142,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2023-10-10",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 466,
            "name": "ЯКОРУДА - ЦЕНТЪР",
            "nameEn": "YAKORUDA-CENTAR",
            "siteId": 87338,
            "address": {
                "countryId": 100,
                "siteId": 87338,
                "siteType": "ГР.",
                "siteName": "ЯКОРУДА",
                "postCode": "2790",
                "streetId": 87471,
                "streetType": "УЛ.",
                "streetName": "ПЕТКО Р. СЛАВЕЙКОВ",
                "streetNo": "3",
                "x": 23.669754,
                "y": 42.0178,
                "fullAddressString": "гр. ЯКОРУДА [2790] ул. ПЕТКО Р. СЛАВЕЙКОВ No 3",
                "siteAddressString": "гр. ЯКОРУДА [2790]",
                "localAddressString": "ул. ПЕТКО Р. СЛАВЕЙКОВ No 3"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "14:00",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 0,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2023-11-10",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 467,
            "name": "СОФИЯ – ЧЕЛОПЕЧЕНЕ (КВ)",
            "nameEn": "SOFIA - CHELOPECHENE (KV.)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 4757,
                "streetType": "УЛ.",
                "streetName": "АНГЕЛ МАДЖАРОВ",
                "streetNo": "31",
                "complexId": 152,
                "complexType": "КВ.",
                "complexName": "ЧЕЛОПЕЧЕНЕ",
                "x": 23.47293,
                "y": 42.732663,
                "fullAddressString": "гр. СОФИЯ кв. ЧЕЛОПЕЧЕНЕ ул. АНГЕЛ МАДЖАРОВ No 31",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "кв. ЧЕЛОПЕЧЕНЕ ул. АНГЕЛ МАДЖАРОВ No 31"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 830,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2023-12-11",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 503,
            "name": "СИМИТЛИ",
            "nameEn": "SIMITLI",
            "siteId": 66460,
            "address": {
                "countryId": 100,
                "siteId": 66460,
                "siteType": "ГР.",
                "siteName": "СИМИТЛИ",
                "postCode": "2730",
                "streetId": 84402,
                "streetType": "УЛ.",
                "streetName": "ГЕОРГИ ДИМИТРОВ",
                "streetNo": "62А",
                "x": 23.107219,
                "y": 41.89047,
                "fullAddressString": "гр. СИМИТЛИ [2730] ул. ГЕОРГИ ДИМИТРОВ No 62А",
                "siteAddressString": "гр. СИМИТЛИ [2730]",
                "localAddressString": "ул. ГЕОРГИ ДИМИТРОВ No 62А"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "15:00",
            "sameDayDepartureCutoffHalf": "11:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 13,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "15:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "15:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "15:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "11:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "15:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "15:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "15:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "15:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "15:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2013-04-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 513,
            "name": "ВАРНА - ВЛ. ВАРНЕНЧИК (БУЛ.)",
            "nameEn": "VARNA - VL. VARNENCHIK (BUL.)",
            "siteId": 10135,
            "address": {
                "countryId": 100,
                "siteId": 10135,
                "siteType": "ГР.",
                "siteName": "ВАРНА",
                "postCode": "9000",
                "streetId": 12153,
                "streetType": "БУЛ.",
                "streetName": "ВЛАДИСЛАВ ВАРНЕНЧИК",
                "streetNo": "126",
                "x": 27.900499,
                "y": 43.214102,
                "fullAddressString": "гр. ВАРНА бул. ВЛАДИСЛАВ ВАРНЕНЧИК No 126",
                "siteAddressString": "гр. ВАРНА",
                "localAddressString": "бул. ВЛАДИСЛАВ ВАРНЕНЧИК No 126"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 220
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 786,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2013-04-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 523,
            "name": "ТУТРАКАН",
            "nameEn": "TUTRAKAN",
            "siteId": 73496,
            "address": {
                "countryId": 100,
                "siteId": 73496,
                "siteType": "ГР.",
                "siteName": "ТУТРАКАН",
                "postCode": "7600",
                "streetId": 85997,
                "streetType": "УЛ.",
                "streetName": "СИЛИСТРА",
                "streetNo": "11",
                "x": 26.617523,
                "y": 44.046789,
                "fullAddressString": "гр. ТУТРАКАН [7600] ул. СИЛИСТРА No 11",
                "siteAddressString": "гр. ТУТРАКАН [7600]",
                "localAddressString": "ул. СИЛИСТРА No 11"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "16:00",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 10,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2013-04-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 525,
            "name": "БЯЛА (РУСЕ)",
            "nameEn": "BYALA (RUSE)",
            "siteId": 7603,
            "address": {
                "countryId": 100,
                "siteId": 7603,
                "siteType": "ГР.",
                "siteName": "БЯЛА",
                "postCode": "7100",
                "streetId": 75184,
                "streetType": "УЛ.",
                "streetName": "ВАСИЛ ЛЕВСКИ",
                "streetNo": "19",
                "x": 25.740923,
                "y": 43.460218,
                "fullAddressString": "гр. БЯЛА [7100] ул. ВАСИЛ ЛЕВСКИ No 19",
                "siteAddressString": "гр. БЯЛА [7100]",
                "localAddressString": "ул. ВАСИЛ ЛЕВСКИ No 19"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "16:00",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 100.0,
            "type": "OFFICE",
            "nearbyOfficeId": 10,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2013-04-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 527,
            "name": "МЕЗДРА",
            "nameEn": "MEZDRA",
            "siteId": 47714,
            "address": {
                "countryId": 100,
                "siteId": 47714,
                "siteType": "ГР.",
                "siteName": "МЕЗДРА",
                "postCode": "3100",
                "streetId": 81230,
                "streetType": "УЛ.",
                "streetName": "СВ. СВ. КИРИЛ И МЕТОДИЙ",
                "streetNo": "16",
                "x": 23.717026,
                "y": 43.145153,
                "fullAddressString": "гр. МЕЗДРА [3100] ул. СВ. СВ. КИРИЛ И МЕТОДИЙ No 16",
                "siteAddressString": "гр. МЕЗДРА [3100]",
                "localAddressString": "ул. СВ. СВ. КИРИЛ И МЕТОДИЙ No 16"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:30",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 817,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:30",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2013-04-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 528,
            "name": "БЕРКОВИЦА - АЛЕКСАНДРОВСКА (УЛ.)",
            "nameEn": "BERKOVITSA - ALEKSANDROVSKA (UL.)",
            "siteId": 3928,
            "address": {
                "countryId": 100,
                "siteId": 3928,
                "siteType": "ГР.",
                "siteName": "БЕРКОВИЦА",
                "postCode": "3500",
                "streetId": 90101,
                "streetType": "УЛ.",
                "streetName": "АЛЕКСАНДРОВСКА",
                "streetNo": "12",
                "x": 23.12716,
                "y": 43.23617,
                "fullAddressString": "гр. БЕРКОВИЦА [3500] ул. АЛЕКСАНДРОВСКА No 12",
                "siteAddressString": "гр. БЕРКОВИЦА [3500]",
                "localAddressString": "ул. АЛЕКСАНДРОВСКА No 12"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:30",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "16:30",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 173,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:30",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-07-05",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 529,
            "name": "ЛУКОВИТ",
            "nameEn": "LUKOVIT",
            "siteId": 44327,
            "address": {
                "countryId": 100,
                "siteId": 44327,
                "siteType": "ГР.",
                "siteName": "ЛУКОВИТ",
                "postCode": "5770",
                "streetId": 80325,
                "streetType": "УЛ.",
                "streetName": "ВЪЗРАЖДАНЕ",
                "streetNo": "85",
                "addressNote": "БЛОКА НА ДИПЛОМАТ",
                "x": 24.162905,
                "y": 43.207702,
                "fullAddressString": "гр. ЛУКОВИТ [5770] ул. ВЪЗРАЖДАНЕ No 85 БЛОКА НА ДИПЛОМАТ",
                "siteAddressString": "гр. ЛУКОВИТ [5770]",
                "localAddressString": "ул. ВЪЗРАЖДАНЕ No 85 БЛОКА НА ДИПЛОМАТ"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:30",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "16:30",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 170,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 563,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:30",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2013-04-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 532,
            "name": "ПЛОВДИВ - ПРОСЛАВ",
            "nameEn": "PLOVDIV - PRОSLAV",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "streetId": 10449,
                "streetType": "УЛ.",
                "streetName": "ЕЛИН ПЕЛИН",
                "streetNo": "30",
                "x": 24.688001,
                "y": 42.125673,
                "fullAddressString": "гр. ПЛОВДИВ ул. ЕЛИН ПЕЛИН No 30",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "ул. ЕЛИН ПЕЛИН No 30"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 861,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-09-11",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 536,
            "name": "СОФИЯ - МАНАСТИРСКИ ЛИВАДИ",
            "nameEn": "SOFIA - MANASTIRSKI LIVADI",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 5050,
                "streetType": "УЛ.",
                "streetName": "СИНАНИШКО ЕЗЕРО",
                "streetNo": "9А",
                "complexId": 320,
                "complexType": "ЖК",
                "complexName": "МАНАСТИРСКИ ЛИВАДИ",
                "x": 23.284274,
                "y": 42.664946,
                "fullAddressString": "гр. СОФИЯ жк МАНАСТИРСКИ ЛИВАДИ ул. СИНАНИШКО ЕЗЕРО No 9А",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк МАНАСТИРСКИ ЛИВАДИ ул. СИНАНИШКО ЕЗЕРО No 9А"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "19:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 342,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2013-04-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 545,
            "name": "ТОПОЛИ - Д-Р ЛИПОВ",
            "nameEn": "TOPOLI - D-R LIPOV",
            "siteId": 72709,
            "address": {
                "countryId": 100,
                "siteId": 72709,
                "siteType": "С.",
                "siteName": "ТОПОЛИ",
                "postCode": "9140",
                "streetNo": "20",
                "x": 27.820081,
                "y": 43.216448,
                "fullAddressString": "с. ТОПОЛИ [9140] ул. ДОКТОР ЛИПОВ No 20",
                "siteAddressString": "с. ТОПОЛИ [9140]",
                "localAddressString": "ул. ДОКТОР ЛИПОВ No 20"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 4,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2013-08-05",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 548,
            "name": "ВРАЦА - ПРОБИВА",
            "nameEn": "VRATSA - PROBIVA",
            "siteId": 12259,
            "address": {
                "countryId": 100,
                "siteId": 12259,
                "siteType": "ГР.",
                "siteName": "ВРАЦА",
                "postCode": "3000",
                "streetId": 38478,
                "streetType": "УЛ.",
                "streetName": "НИКОЛА ВОЙВОДОВ",
                "streetNo": "16",
                "x": 23.553447,
                "y": 43.20488,
                "fullAddressString": "гр. ВРАЦА [3000] ул. НИКОЛА ВОЙВОДОВ No 16",
                "siteAddressString": "гр. ВРАЦА [3000]",
                "localAddressString": "ул. НИКОЛА ВОЙВОДОВ No 16"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:30",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 817,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:30",
                    "sameDayDepartureCutoff": "13:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2013-09-02",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 550,
            "name": "БЕЛОГРАДЧИК",
            "nameEn": "BELOGRADCHIK",
            "siteId": 3616,
            "address": {
                "countryId": 100,
                "siteId": 3616,
                "siteType": "ГР.",
                "siteName": "БЕЛОГРАДЧИК",
                "postCode": "3900",
                "streetId": 74021,
                "streetType": "УЛ.",
                "streetName": "КНЯЗ БОРИС I",
                "streetNo": "50",
                "x": 22.681852,
                "y": 43.62539,
                "fullAddressString": "гр. БЕЛОГРАДЧИК [3900] ул. КНЯЗ БОРИС I No 50",
                "siteAddressString": "гр. БЕЛОГРАДЧИК [3900]",
                "localAddressString": "ул. КНЯЗ БОРИС I No 50"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "13:00",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 60,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2013-08-31",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 563,
            "name": "ЧЕРВЕН БРЯГ",
            "nameEn": "CHERVEN BRYAG",
            "siteId": 80501,
            "address": {
                "countryId": 100,
                "siteId": 80501,
                "siteType": "ГР.",
                "siteName": "ЧЕРВЕН БРЯГ",
                "postCode": "5980",
                "streetId": 86923,
                "streetType": "УЛ.",
                "streetName": "ЕКЗАРХ ЙОСИФ",
                "streetNo": "18А",
                "x": 24.08104,
                "y": 43.27897,
                "fullAddressString": "гр. ЧЕРВЕН БРЯГ [5980] ул. ЕКЗАРХ ЙОСИФ No 18А",
                "siteAddressString": "гр. ЧЕРВЕН БРЯГ [5980]",
                "localAddressString": "ул. ЕКЗАРХ ЙОСИФ No 18А"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:30",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "16:50",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 529,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:50",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:50",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:50",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:30",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:50",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:50",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:50",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:50",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "16:50",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2013-10-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 565,
            "name": "СОФИЯ - ДРУЖБА 1, ДО ЕЗЕРОТО",
            "nameEn": "SOFIA - DRUZHBA 1, NEAR THE LAKE",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 3039,
                "streetType": "УЛ.",
                "streetName": "ТИРАНА",
                "streetNo": "14-16",
                "complexId": 14,
                "complexType": "ЖК",
                "complexName": "ДРУЖБА 1",
                "addressNote": "ДО ЕЗЕРОТО, ПАЗАР КЛАСИК",
                "x": 23.397714,
                "y": 42.661752,
                "fullAddressString": "гр. СОФИЯ жк ДРУЖБА 1 ул. ТИРАНА No 14-16 ДО ЕЗЕРОТО, ПАЗАР КЛАСИК",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк ДРУЖБА 1 ул. ТИРАНА No 14-16 ДО ЕЗЕРОТО, ПАЗАР КЛАСИК"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 363,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2013-10-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 566,
            "name": "САТОВЧА",
            "nameEn": "SATOVCHA",
            "siteId": 65440,
            "address": {
                "countryId": 100,
                "siteId": 65440,
                "siteType": "С.",
                "siteName": "САТОВЧА",
                "postCode": "2950",
                "streetId": 84114,
                "streetType": "УЛ.",
                "streetName": "АЛ. СТАМБОЛИЙСКИ",
                "streetNo": "5Б",
                "x": 23.975472,
                "y": 41.618972,
                "fullAddressString": "с. САТОВЧА [2950] ул. АЛ. СТАМБОЛИЙСКИ No 5Б",
                "siteAddressString": "с. САТОВЧА [2950]",
                "localAddressString": "ул. АЛ. СТАМБОЛИЙСКИ No 5Б"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "14:00",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 31,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2013-10-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 569,
            "name": "БУРГАС - МЕДЕН РУДНИК, БИТОВ КОМБИНАТ",
            "nameEn": "BURGAS - MEDEN RUDNIK, BITOV KOMBINAT",
            "siteId": 7079,
            "address": {
                "countryId": 100,
                "siteId": 7079,
                "siteType": "ГР.",
                "siteName": "БУРГАС",
                "postCode": "8000",
                "complexId": 706,
                "complexType": "ЖК",
                "complexName": "МЕДЕН РУДНИК",
                "blockNo": "414",
                "addressNote": "ДО БИВШ БИТОВ КОМБИНАТ",
                "x": 27.418908,
                "y": 42.464923,
                "fullAddressString": "гр. БУРГАС жк МЕДЕН РУДНИК бл. 414 ДО БИВШ БИТОВ КОМБИНАТ",
                "siteAddressString": "гр. БУРГАС",
                "localAddressString": "жк МЕДЕН РУДНИК бл. 414 ДО БИВШ БИТОВ КОМБИНАТ"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 740,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2013-10-14",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 589,
            "name": "БЕЛОСЛАВ",
            "nameEn": "BELOSLAV",
            "siteId": 3719,
            "address": {
                "countryId": 100,
                "siteId": 3719,
                "siteType": "ГР.",
                "siteName": "БЕЛОСЛАВ",
                "postCode": "9178",
                "streetNo": "2",
                "x": 27.703864,
                "y": 43.192619,
                "fullAddressString": "гр. БЕЛОСЛАВ [9178] ул. ГЕБЕДЖЕ No 2",
                "siteAddressString": "гр. БЕЛОСЛАВ [9178]",
                "localAddressString": "ул. ГЕБЕДЖЕ No 2"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 833,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2014-02-03",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 591,
            "name": "БЛАГОЕВГРАД - ЦЕНТЪР",
            "nameEn": "BLAGOEVGRAD - CENTER",
            "siteId": 4279,
            "address": {
                "countryId": 100,
                "siteId": 4279,
                "siteType": "ГР.",
                "siteName": "БЛАГОЕВГРАД",
                "postCode": "2700",
                "streetId": 30258,
                "streetType": "УЛ.",
                "streetName": "ТОДОР АЛЕКСАНДРОВ",
                "streetNo": "23",
                "addressNote": "НА ГЪРБА НА БИТОВИЯ КОМБИНАТ",
                "x": 23.099273,
                "y": 42.019286,
                "fullAddressString": "гр. БЛАГОЕВГРАД ул. ТОДОР АЛЕКСАНДРОВ No 23 НА ГЪРБА НА БИТОВИЯ КОМБИНАТ",
                "siteAddressString": "гр. БЛАГОЕВГРАД",
                "localAddressString": "ул. ТОДОР АЛЕКСАНДРОВ No 23 НА ГЪРБА НА БИТОВИЯ КОМБИНАТ"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "12:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 13,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "12:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2014-02-13",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 594,
            "name": "ИСПЕРИХ",
            "nameEn": "ISPERIH",
            "siteId": 32874,
            "address": {
                "countryId": 100,
                "siteId": 32874,
                "siteType": "ГР.",
                "siteName": "ИСПЕРИХ",
                "postCode": "7400",
                "streetId": 78391,
                "streetType": "УЛ.",
                "streetName": "СТЕФАН КАРАДЖА",
                "streetNo": "1",
                "x": 26.829171,
                "y": 43.715919,
                "fullAddressString": "гр. ИСПЕРИХ [7400] ул. СТЕФАН КАРАДЖА No 1",
                "siteAddressString": "гр. ИСПЕРИХ [7400]",
                "localAddressString": "ул. СТЕФАН КАРАДЖА No 1"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "16:00",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 120,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2014-03-04",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 602,
            "name": "БУРГАС - ВЪЗРАЖДАНЕ",
            "nameEn": "BURGAS - VAZRAZHDANE",
            "siteId": 7079,
            "address": {
                "countryId": 100,
                "siteId": 7079,
                "siteType": "ГР.",
                "siteName": "БУРГАС",
                "postCode": "8000",
                "streetId": 14285,
                "streetType": "УЛ.",
                "streetName": "СЛИВНИЦА",
                "streetNo": "55",
                "complexId": 712,
                "complexType": "ЖК",
                "complexName": "ВЪЗРАЖДАНЕ",
                "x": 27.464065,
                "y": 42.494946,
                "fullAddressString": "гр. БУРГАС жк ВЪЗРАЖДАНЕ ул. СЛИВНИЦА No 55",
                "siteAddressString": "гр. БУРГАС",
                "localAddressString": "жк ВЪЗРАЖДАНЕ ул. СЛИВНИЦА No 55"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 150
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 789,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2014-03-10",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 606,
            "name": "ПЛОВДИВ - ТЕТ ЛЕНИН",
            "nameEn": "PLOVDIV - TET LENIN",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "streetId": 10027,
                "streetType": "БУЛ.",
                "streetName": "ПЕЩЕРСКО ШОСЕ",
                "streetNo": "28А",
                "x": 24.729173,
                "y": 42.140026,
                "fullAddressString": "гр. ПЛОВДИВ бул. ПЕЩЕРСКО ШОСЕ No 28А",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "бул. ПЕЩЕРСКО ШОСЕ No 28А"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 753,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2014-03-21",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 608,
            "name": "ПЛОВДИВ - ТРАКИЯ (ЖК), БЛ.148",
            "nameEn": "PLOVDIV - TRAKIA (ZHK), BL.148",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "complexId": 504,
                "complexType": "ЖК",
                "complexName": "ТРАКИЯ",
                "blockNo": "148",
                "x": 24.788574,
                "y": 42.130548,
                "fullAddressString": "гр. ПЛОВДИВ жк ТРАКИЯ бл. 148",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "жк ТРАКИЯ бл. 148"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 430,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2014-03-25",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 616,
            "name": "СЛИВЕН - ДРУЖБА",
            "nameEn": "SLIVEN - DRUZHBA",
            "siteId": 67338,
            "address": {
                "countryId": 100,
                "siteId": 67338,
                "siteType": "ГР.",
                "siteName": "СЛИВЕН",
                "postCode": "8800",
                "streetId": 44012,
                "streetType": "БУЛ.",
                "streetName": "СТЕФАН СТАМБОЛОВ",
                "streetNo": "14А",
                "complexId": 2844,
                "complexType": "КВ.",
                "complexName": "ДРУЖБА",
                "addressNote": "М-Н ХОЛИС",
                "x": 26.34497,
                "y": 42.677497,
                "fullAddressString": "гр. СЛИВЕН [8800] кв. ДРУЖБА бул. СТЕФАН СТАМБОЛОВ No 14А М-Н ХОЛИС",
                "siteAddressString": "гр. СЛИВЕН [8800]",
                "localAddressString": "кв. ДРУЖБА бул. СТЕФАН СТАМБОЛОВ No 14А М-Н ХОЛИС"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 292,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2014-04-07",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 617,
            "name": "БЕЛИЦА",
            "nameEn": "BELITSA",
            "siteId": 3504,
            "address": {
                "countryId": 100,
                "siteId": 3504,
                "siteType": "ГР.",
                "siteName": "БЕЛИЦА",
                "postCode": "2780",
                "streetId": 73863,
                "streetType": "УЛ.",
                "streetName": "АЛЕКСА САЧКОВ",
                "streetNo": "3",
                "x": 23.561093,
                "y": 41.946995,
                "fullAddressString": "гр. БЕЛИЦА [2780] ул. АЛЕКСА САЧКОВ No 3",
                "siteAddressString": "гр. БЕЛИЦА [2780]",
                "localAddressString": "ул. АЛЕКСА САЧКОВ No 3"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "13:30",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 114,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "13:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "13:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "13:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "13:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "13:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "13:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "13:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "13:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2014-04-14",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 618,
            "name": "ВИДИН - ВИДА",
            "nameEn": "VIDIN - VIDA",
            "siteId": 10971,
            "address": {
                "countryId": 100,
                "siteId": 10971,
                "siteType": "ГР.",
                "siteName": "ВИДИН",
                "postCode": "3700",
                "streetId": 36289,
                "streetType": "УЛ.",
                "streetName": "ШИРОКА",
                "streetNo": "96Б",
                "x": 22.866132,
                "y": 43.997364,
                "fullAddressString": "гр. ВИДИН [3700] ул. ШИРОКА No 96Б",
                "siteAddressString": "гр. ВИДИН [3700]",
                "localAddressString": "ул. ШИРОКА No 96Б"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 100.0,
            "type": "OFFICE",
            "nearbyOfficeId": 419,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2014-04-14",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 649,
            "name": "БЕЛОВО",
            "nameEn": "BELOVO",
            "siteId": 3592,
            "address": {
                "countryId": 100,
                "siteId": 3592,
                "siteType": "ГР.",
                "siteName": "БЕЛОВО",
                "postCode": "4470",
                "streetId": 73977,
                "streetType": "БУЛ.",
                "streetName": "ЮНДОЛА",
                "streetNo": "1",
                "x": 24.01807,
                "y": 42.21434,
                "fullAddressString": "гр. БЕЛОВО [4470] бул. ЮНДОЛА No 1",
                "siteAddressString": "гр. БЕЛОВО [4470]",
                "localAddressString": "бул. ЮНДОЛА No 1"
            },
            "workingTimeFrom": "10:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "16:30",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 761,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2014-07-21",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 656,
            "name": "ПЛОВДИВ - БРЕЗОВСКА",
            "nameEn": "PLOVDIV - BREZOVSKA",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "streetId": 10216,
                "streetType": "УЛ.",
                "streetName": "БРЕЗОВСКА",
                "streetNo": "27",
                "addressNote": "ДО ТРЕТО РПУ",
                "x": 24.744135,
                "y": 42.157987,
                "fullAddressString": "гр. ПЛОВДИВ ул. БРЕЗОВСКА No 27 ДО ТРЕТО РПУ",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "ул. БРЕЗОВСКА No 27 ДО ТРЕТО РПУ"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 350.0,
            "type": "OFFICE",
            "nearbyOfficeId": 222,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2014-07-10",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 661,
            "name": "ВАРНА - ЛЕВСКИ (КВ.)",
            "nameEn": "VARNA - LEVSKI (KV.)",
            "siteId": 10135,
            "address": {
                "countryId": 100,
                "siteId": 10135,
                "siteType": "ГР.",
                "siteName": "ВАРНА",
                "postCode": "9000",
                "streetId": 12717,
                "streetType": "УЛ.",
                "streetName": "СТУДЕНТСКА",
                "streetNo": "4",
                "addressNote": "ДО ВХ. Д",
                "x": 27.931699,
                "y": 43.223345,
                "fullAddressString": "гр. ВАРНА ул. СТУДЕНТСКА No 4 ДО ВХ. Д",
                "siteAddressString": "гр. ВАРНА",
                "localAddressString": "ул. СТУДЕНТСКА No 4 ДО ВХ. Д"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 220
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 832,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2014-08-15",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 662,
            "name": "ВАРНА - ЦЕНТРАЛЕН ПАЗАР",
            "nameEn": "VARNA - TSENTRALEN PAZAR",
            "siteId": 10135,
            "address": {
                "countryId": 100,
                "siteId": 10135,
                "siteType": "ГР.",
                "siteName": "ВАРНА",
                "postCode": "9000",
                "streetId": 12080,
                "streetType": "УЛ.",
                "streetName": "БАБА ТОНКА",
                "streetNo": "13",
                "x": 27.905474,
                "y": 43.210725,
                "fullAddressString": "гр. ВАРНА ул. БАБА ТОНКА No 13",
                "siteAddressString": "гр. ВАРНА",
                "localAddressString": "ул. БАБА ТОНКА No 13"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 220
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 871,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2014-08-15",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 675,
            "name": "СОФИЯ - ХАДЖИ ДИМИТЪР (ЖК)",
            "nameEn": "SOFIA - HADZHI DIMITAR (ZHK)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 2396,
                "streetType": "УЛ.",
                "streetName": "НИКОЛА ВОЙНОВСКИ",
                "streetNo": "67",
                "complexId": 75,
                "complexType": "ЖК",
                "complexName": "ХАДЖИ ДИМИТЪР",
                "x": 23.348346,
                "y": 42.70963,
                "fullAddressString": "гр. СОФИЯ жк ХАДЖИ ДИМИТЪР ул. НИКОЛА ВОЙНОВСКИ No 67",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк ХАДЖИ ДИМИТЪР ул. НИКОЛА ВОЙНОВСКИ No 67"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 193,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2014-11-12",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 701,
            "name": "ВАРНА - МЛАДОСТ (ЖК), ХАН СЕВАР 5",
            "nameEn": "VARNA - MLADOST (ZHK), HAN SEVAR 5",
            "siteId": 10135,
            "address": {
                "countryId": 100,
                "siteId": 10135,
                "siteType": "ГР.",
                "siteName": "ВАРНА",
                "postCode": "9000",
                "streetId": 87615,
                "streetType": "УЛ.",
                "streetName": "ХАН СЕВАР",
                "streetNo": "5",
                "complexId": 682,
                "complexType": "ЖК",
                "complexName": "МЛАДОСТ",
                "blockNo": ".",
                "x": 27.884744,
                "y": 43.229426,
                "fullAddressString": "гр. ВАРНА жк МЛАДОСТ ул. ХАН СЕВАР No 5 бл. .",
                "siteAddressString": "гр. ВАРНА",
                "localAddressString": "жк МЛАДОСТ ул. ХАН СЕВАР No 5 бл. ."
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 220
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 856,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2015-02-25",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 702,
            "name": "КАБЛЕШКОВО",
            "nameEn": "KABLESHKOVO",
            "siteId": 35033,
            "address": {
                "countryId": 100,
                "siteId": 35033,
                "siteType": "ГР.",
                "siteName": "КАБЛЕШКОВО",
                "postCode": "8210",
                "streetId": 78474,
                "streetType": "УЛ.",
                "streetName": "ЧЕРНО МОРЕ",
                "streetNo": "20",
                "x": 27.569144,
                "y": 42.65619,
                "fullAddressString": "гр. КАБЛЕШКОВО [8210] ул. ЧЕРНО МОРЕ No 20",
                "siteAddressString": "гр. КАБЛЕШКОВО [8210]",
                "localAddressString": "ул. ЧЕРНО МОРЕ No 20"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 813,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2015-03-04",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 705,
            "name": "БУРГАС - БРАТЯ МИЛАДИНОВИ",
            "nameEn": "BURGAS - BRATYA MILADINOVI",
            "siteId": 7079,
            "address": {
                "countryId": 100,
                "siteId": 7079,
                "siteType": "ГР.",
                "siteName": "БУРГАС",
                "postCode": "8000",
                "complexId": 711,
                "complexType": "ЖК",
                "complexName": "БРАТЯ МИЛАДИНОВИ",
                "blockNo": "135",
                "x": 27.468084,
                "y": 42.506223,
                "fullAddressString": "гр. БУРГАС жк БРАТЯ МИЛАДИНОВИ бл. 135",
                "siteAddressString": "гр. БУРГАС",
                "localAddressString": "жк БРАТЯ МИЛАДИНОВИ бл. 135"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 120,
                "height": 150,
                "depth": 200
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 719,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2015-03-16",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 714,
            "name": "РУСЕ - ЯНТРА, УНИВЕРСИТЕТ",
            "nameEn": "RUSE - YANTRA, UNIVERSITY",
            "siteId": 63427,
            "address": {
                "countryId": 100,
                "siteId": 63427,
                "siteType": "ГР.",
                "siteName": "РУСЕ",
                "postCode": "7000",
                "streetId": 16621,
                "streetType": "УЛ.",
                "streetName": "ЯНТРА",
                "streetNo": "9",
                "addressNote": "БЛ. ДАВ 3",
                "x": 25.969791,
                "y": 43.855504,
                "fullAddressString": "гр. РУСЕ ул. ЯНТРА No 9 БЛ. ДАВ 3",
                "siteAddressString": "гр. РУСЕ",
                "localAddressString": "ул. ЯНТРА No 9 БЛ. ДАВ 3"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 776,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-09-25",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 719,
            "name": "БУРГАС - ЛАЗУР",
            "nameEn": "BURGAS - LAZUR",
            "siteId": 7079,
            "address": {
                "countryId": 100,
                "siteId": 7079,
                "siteType": "ГР.",
                "siteName": "БУРГАС",
                "postCode": "8000",
                "streetId": 14139,
                "streetType": "УЛ.",
                "streetName": "КАЛОФЕР",
                "streetNo": "13",
                "complexId": 710,
                "complexType": "ЖК",
                "complexName": "ЛАЗУР",
                "addressNote": "ДО РУСКАТА ГИМНАЗИЯ",
                "x": 27.473058,
                "y": 42.507793,
                "fullAddressString": "гр. БУРГАС жк ЛАЗУР ул. КАЛОФЕР No 13 ДО РУСКАТА ГИМНАЗИЯ",
                "siteAddressString": "гр. БУРГАС",
                "localAddressString": "жк ЛАЗУР ул. КАЛОФЕР No 13 ДО РУСКАТА ГИМНАЗИЯ"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 200,
                "depth": 150
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 705,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-03-20",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 722,
            "name": "СОЗОПОЛ",
            "nameEn": "SOZOPOL",
            "siteId": 67800,
            "address": {
                "countryId": 100,
                "siteId": 67800,
                "siteType": "ГР.",
                "siteName": "СОЗОПОЛ",
                "postCode": "8130",
                "streetId": 72263,
                "streetType": "УЛ.",
                "streetName": "РЕПУБЛИКАНСКА",
                "streetNo": "28",
                "x": 27.697547,
                "y": 42.413655,
                "fullAddressString": "гр. СОЗОПОЛ [8130] ул. РЕПУБЛИКАНСКА No 28",
                "siteAddressString": "гр. СОЗОПОЛ [8130]",
                "localAddressString": "ул. РЕПУБЛИКАНСКА No 28"
            },
            "workingTimeFrom": "10:00",
            "workingTimeTo": "17:00",
            "workingTimeHalfFrom": "10:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 60,
                "height": 150,
                "depth": 100
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 740,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-03-20",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 728,
            "name": "ЦАРЕВО",
            "nameEn": "TSAREVO",
            "siteId": 48619,
            "address": {
                "countryId": 100,
                "siteId": 48619,
                "siteType": "ГР.",
                "siteName": "ЦАРЕВО",
                "postCode": "8260",
                "streetId": 92915,
                "streetType": "УЛ.",
                "streetName": "ХАН АСПАРУХ",
                "streetNo": "41",
                "x": 27.848026,
                "y": 42.169746,
                "fullAddressString": "гр. ЦАРЕВО [8260] ул. ХАН АСПАРУХ No 41",
                "siteAddressString": "гр. ЦАРЕВО [8260]",
                "localAddressString": "ул. ХАН АСПАРУХ No 41"
            },
            "workingTimeFrom": "10:00",
            "workingTimeTo": "17:00",
            "workingTimeHalfFrom": "10:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 90,
                "height": 150,
                "depth": 200
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 444,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2015-07-20",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 730,
            "name": "ПОПОВО",
            "nameEn": "POPOVO",
            "siteId": 57649,
            "address": {
                "countryId": 100,
                "siteId": 57649,
                "siteType": "ГР.",
                "siteName": "ПОПОВО",
                "postCode": "7800",
                "streetId": 82840,
                "streetType": "УЛ.",
                "streetName": "БЪЛГАРИЯ",
                "streetNo": "110",
                "addressNote": "С/У НЕТУЪРК",
                "x": 26.220669,
                "y": 43.348276,
                "fullAddressString": "гр. ПОПОВО [7800] ул. БЪЛГАРИЯ No 110 С/У НЕТУЪРК",
                "siteAddressString": "гр. ПОПОВО [7800]",
                "localAddressString": "ул. БЪЛГАРИЯ No 110 С/У НЕТУЪРК"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "12:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 379,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "12:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2015-08-24",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 732,
            "name": "БАЛЧИК - ХРИСТО БОТЕВ",
            "nameEn": "BALCHIK - UL. HRISTO BOTEV",
            "siteId": 2508,
            "address": {
                "countryId": 100,
                "siteId": 2508,
                "siteType": "ГР.",
                "siteName": "БАЛЧИК",
                "postCode": "9600",
                "streetId": 89992,
                "streetType": "УЛ.",
                "streetName": "ХРИСТО БОТЕВ",
                "streetNo": "39",
                "x": 28.162411,
                "y": 43.424983,
                "fullAddressString": "гр. БАЛЧИК [9600] ул. ХРИСТО БОТЕВ No 39",
                "siteAddressString": "гр. БАЛЧИК [9600]",
                "localAddressString": "ул. ХРИСТО БОТЕВ No 39"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 220
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 266,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2015-08-17",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 733,
            "name": "ПЛОВДИВ - БЪЛГАРИЯ (БУЛ.)",
            "nameEn": "PLOVDIV - BULGARIA (BUL.)",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "streetId": 10187,
                "streetType": "УЛ.",
                "streetName": "БОРБА",
                "streetNo": "26",
                "x": 24.756263,
                "y": 42.163817,
                "fullAddressString": "гр. ПЛОВДИВ ул. БОРБА No 26",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "ул. БОРБА No 26"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 120,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 350.0,
            "type": "OFFICE",
            "nearbyOfficeId": 338,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2017-05-22",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 734,
            "name": "СОФИЯ - ЛЮЛИН 10 (ЖК)",
            "nameEn": "SOFIA - LYULIN 10 (ZHK)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 2170,
                "streetType": "УЛ.",
                "streetName": "ЛУИ ПАСТЬОР",
                "complexId": 170,
                "complexType": "ЖК",
                "complexName": "ЛЮЛИН",
                "blockNo": "161",
                "x": 23.266443,
                "y": 42.714155,
                "fullAddressString": "гр. СОФИЯ жк ЛЮЛИН ул. ЛУИ ПАСТЬОР бл. 161",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк ЛЮЛИН ул. ЛУИ ПАСТЬОР бл. 161"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 760,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2015-08-18",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 736,
            "name": "ВЕЛИКО ТЪРНОВО - БУЗЛУДЖА (ЖК)",
            "nameEn": "VELIKO TARNOVO - BUZLUDZHA (ZHK)",
            "siteId": 10447,
            "address": {
                "countryId": 100,
                "siteId": 10447,
                "siteType": "ГР.",
                "siteName": "ВЕЛИКО ТЪРНОВО",
                "postCode": "5000",
                "streetId": 34072,
                "streetType": "УЛ.",
                "streetName": "ДИМИТЪР БЛАГОЕВ",
                "streetNo": "22",
                "entranceNo": "Б",
                "x": 25.608594,
                "y": 43.072454,
                "fullAddressString": "гр. ВЕЛИКО ТЪРНОВО ул. ДИМИТЪР БЛАГОЕВ No 22 вх. Б",
                "siteAddressString": "гр. ВЕЛИКО ТЪРНОВО",
                "localAddressString": "ул. ДИМИТЪР БЛАГОЕВ No 22 вх. Б"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 11,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2015-09-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 737,
            "name": "РУСЕ - БОРИСОВА",
            "nameEn": "RUSE - BORISOVA",
            "siteId": 63427,
            "address": {
                "countryId": 100,
                "siteId": 63427,
                "siteType": "ГР.",
                "siteName": "РУСЕ",
                "postCode": "7000",
                "streetId": 16071,
                "streetType": "УЛ.",
                "streetName": "БОРИСОВА",
                "streetNo": "113",
                "x": 25.956028,
                "y": 43.835456,
                "fullAddressString": "гр. РУСЕ ул. БОРИСОВА No 113",
                "siteAddressString": "гр. РУСЕ",
                "localAddressString": "ул. БОРИСОВА No 113"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 100.0,
            "type": "OFFICE",
            "nearbyOfficeId": 202,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2015-09-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 738,
            "name": "ПЛОВДИВ - КОМАТЕВСКИ ВЪЗЕЛ",
            "nameEn": "PLOVDIV - KOMATEVSKI VAZEL",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "streetId": 10875,
                "streetType": "УЛ.",
                "streetName": "ПОРТ АРТУР",
                "streetNo": "10",
                "addressNote": "ВХОД ОТ БУЛ. НИКОЛА ВАПЦАРОВ 138",
                "x": 24.724297,
                "y": 42.128824,
                "fullAddressString": "гр. ПЛОВДИВ ул. ПОРТ АРТУР No 10 ВХОД ОТ БУЛ. НИКОЛА ВАПЦАРОВ 138",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "ул. ПОРТ АРТУР No 10 ВХОД ОТ БУЛ. НИКОЛА ВАПЦАРОВ 138"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 752,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2015-09-15",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 740,
            "name": "БУРГАС - ПОБЕДА",
            "nameEn": "BURGAS - POBEDA",
            "siteId": 7079,
            "address": {
                "countryId": 100,
                "siteId": 7079,
                "siteType": "ГР.",
                "siteName": "БУРГАС",
                "postCode": "8000",
                "streetId": 14354,
                "streetType": "УЛ.",
                "streetName": "ЧАТАЛДЖА",
                "streetNo": "52",
                "complexId": 715,
                "complexType": "КВ.",
                "complexName": "ПОБЕДА",
                "addressNote": "ЗАД БЕНЗИНОСТАНЦИЯ ШЕЛ",
                "x": 27.438521,
                "y": 42.478633,
                "fullAddressString": "гр. БУРГАС кв. ПОБЕДА ул. ЧАТАЛДЖА No 52 ЗАД БЕНЗИНОСТАНЦИЯ ШЕЛ",
                "siteAddressString": "гр. БУРГАС",
                "localAddressString": "кв. ПОБЕДА ул. ЧАТАЛДЖА No 52 ЗАД БЕНЗИНОСТАНЦИЯ ШЕЛ"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 150,
                "height": 180,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 569,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2015-10-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 745,
            "name": "ПЛЕВЕН - ГЕОРГИ КОЧЕВ",
            "nameEn": "PLEVEN - GEORGI KOCHEV",
            "siteId": 56722,
            "address": {
                "countryId": 100,
                "siteId": 56722,
                "siteType": "ГР.",
                "siteName": "ПЛЕВЕН",
                "postCode": "5800",
                "streetId": 20099,
                "streetType": "УЛ.",
                "streetName": "ГЕОРГИ КОЧЕВ",
                "streetNo": "88",
                "x": 24.616263,
                "y": 43.422902,
                "fullAddressString": "гр. ПЛЕВЕН ул. ГЕОРГИ КОЧЕВ No 88",
                "siteAddressString": "гр. ПЛЕВЕН",
                "localAddressString": "ул. ГЕОРГИ КОЧЕВ No 88"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 160,
                "height": 300,
                "depth": 160
            },
            "maxParcelWeight": 100.0,
            "type": "OFFICE",
            "nearbyOfficeId": 248,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2015-10-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 746,
            "name": "ВАРНА - ДРАГОМАН",
            "nameEn": "VARNA - DRAGOMAN",
            "siteId": 10135,
            "address": {
                "countryId": 100,
                "siteId": 10135,
                "siteType": "ГР.",
                "siteName": "ВАРНА",
                "postCode": "9000",
                "streetId": 12823,
                "streetType": "УЛ.",
                "streetName": "ШЕЙНОВО",
                "streetNo": "24",
                "x": 27.913802,
                "y": 43.204855,
                "fullAddressString": "гр. ВАРНА ул. ШЕЙНОВО No 24",
                "siteAddressString": "гр. ВАРНА",
                "localAddressString": "ул. ШЕЙНОВО No 24"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 220
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 884,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2015-10-14",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 748,
            "name": "ВЕЛИКО ТЪРНОВО - ТРИЪГЪЛНИКА",
            "nameEn": "VELIKO TARNOVO - TRIAGALNIKA",
            "siteId": 10447,
            "address": {
                "countryId": 100,
                "siteId": 10447,
                "siteType": "ГР.",
                "siteName": "ВЕЛИКО ТЪРНОВО",
                "postCode": "5000",
                "streetId": 34178,
                "streetType": "УЛ.",
                "streetName": "ПОЛТАВА",
                "streetNo": "1Б",
                "addressNote": "МАГАЗИН 4",
                "x": 25.608466,
                "y": 43.080581,
                "fullAddressString": "гр. ВЕЛИКО ТЪРНОВО ул. ПОЛТАВА No 1Б МАГАЗИН 4",
                "siteAddressString": "гр. ВЕЛИКО ТЪРНОВО",
                "localAddressString": "ул. ПОЛТАВА No 1Б МАГАЗИН 4"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 100.0,
            "type": "OFFICE",
            "nearbyOfficeId": 816,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2015-10-21",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 750,
            "name": "ТРОЯН - ОПЪЛЧЕНСКА",
            "nameEn": "TROYAN - OPALCHENSKA",
            "siteId": 73198,
            "address": {
                "countryId": 100,
                "siteId": 73198,
                "siteType": "ГР.",
                "siteName": "ТРОЯН",
                "postCode": "5600",
                "streetId": 95193,
                "streetType": "УЛ.",
                "streetName": "ОПЪЛЧЕНСКА",
                "streetNo": "1",
                "x": 24.714882,
                "y": 42.885367,
                "fullAddressString": "гр. ТРОЯН [5600] ул. ОПЪЛЧЕНСКА No 1",
                "siteAddressString": "гр. ТРОЯН [5600]",
                "localAddressString": "ул. ОПЪЛЧЕНСКА No 1"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 100.0,
            "type": "OFFICE",
            "nearbyOfficeId": 44,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-05-25",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 752,
            "name": "ПЛОВДИВ - СЛЪНЧЕВИ ЛЪЧИ",
            "nameEn": "PLOVDIV - SLANCHEVI LACHI",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "streetId": 10722,
                "streetType": "УЛ.",
                "streetName": "МИХАЛАКИ ГЕОРГИЕВ",
                "streetNo": "38",
                "x": 24.727789,
                "y": 42.124308,
                "fullAddressString": "гр. ПЛОВДИВ ул. МИХАЛАКИ ГЕОРГИЕВ No 38",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "ул. МИХАЛАКИ ГЕОРГИЕВ No 38"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 386,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2015-11-02",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 753,
            "name": "ПЛОВДИВ - ЛЕРИН",
            "nameEn": "PLOVDIV - LERIN",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "streetId": 10649,
                "streetType": "УЛ.",
                "streetName": "ЛЕРИН",
                "streetNo": "1",
                "x": 24.718498,
                "y": 42.137258,
                "fullAddressString": "гр. ПЛОВДИВ ул. ЛЕРИН No 1",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "ул. ЛЕРИН No 1"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 861,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2015-11-16",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 756,
            "name": "ГОРНА ОРЯХОВИЦА - АМБАРИЦА",
            "nameEn": "GORNA ORYAHOVITSA - AMBARITSA",
            "siteId": 16359,
            "address": {
                "countryId": 100,
                "siteId": 16359,
                "siteType": "ГР.",
                "siteName": "ГОРНА ОРЯХОВИЦА",
                "postCode": "5100",
                "streetId": 67660,
                "streetType": "УЛ.",
                "streetName": "ВИЧО ГРЪНЧАРОВ",
                "streetNo": "12",
                "addressNote": "КОМПЛЕКС АМБАРИЦА",
                "x": 25.68361,
                "y": 43.128418,
                "fullAddressString": "гр. ГОРНА ОРЯХОВИЦА [5100] ул. ВИЧО ГРЪНЧАРОВ No 12 КОМПЛЕКС АМБАРИЦА",
                "siteAddressString": "гр. ГОРНА ОРЯХОВИЦА [5100]",
                "localAddressString": "ул. ВИЧО ГРЪНЧАРОВ No 12 КОМПЛЕКС АМБАРИЦА"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 100.0,
            "type": "OFFICE",
            "nearbyOfficeId": 46,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2015-11-16",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 760,
            "name": "СОФИЯ - ЛЮЛИН 7 (ЖК), СИЛВЪР ЦЕНТЪР",
            "nameEn": "SOFIA - LYULIN 7 (ZHK), SILVER CENTER",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 48,
                "streetType": "БУЛ.",
                "streetName": "ДЖАВАХАРЛАЛ НЕРУ",
                "streetNo": "28",
                "complexId": 170,
                "complexType": "ЖК",
                "complexName": "ЛЮЛИН",
                "addressNote": "МАГ. 3",
                "x": 23.254295,
                "y": 42.715294,
                "fullAddressString": "гр. СОФИЯ жк ЛЮЛИН бул. ДЖАВАХАРЛАЛ НЕРУ No 28 МАГ. 3",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк ЛЮЛИН бул. ДЖАВАХАРЛАЛ НЕРУ No 28 МАГ. 3"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 360,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2015-01-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 761,
            "name": "СЕПТЕМВРИ",
            "nameEn": "SEPTEMVRI",
            "siteId": 66264,
            "address": {
                "countryId": 100,
                "siteId": 66264,
                "siteType": "ГР.",
                "siteName": "СЕПТЕМВРИ",
                "postCode": "4490",
                "streetId": 84334,
                "streetType": "УЛ.",
                "streetName": "КНЯЗ БОРИС I",
                "streetNo": "16",
                "x": 24.130349,
                "y": 42.207477,
                "fullAddressString": "гр. СЕПТЕМВРИ [4490] ул. КНЯЗ БОРИС I No 16",
                "siteAddressString": "гр. СЕПТЕМВРИ [4490]",
                "localAddressString": "ул. КНЯЗ БОРИС I No 16"
            },
            "workingTimeFrom": "10:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 18,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-01-05",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 766,
            "name": "МИЗИЯ – НОВОТО СЕЛО (КВ.)",
            "nameEn": "MIZIA – NOVOTO SELO (KV.)",
            "siteId": 48043,
            "address": {
                "countryId": 100,
                "siteId": 48043,
                "siteType": "ГР.",
                "siteName": "МИЗИЯ",
                "postCode": "3330",
                "streetId": 81273,
                "streetType": "УЛ.",
                "streetName": "ГЕОРГИ ДИМИТРОВ",
                "streetNo": "92",
                "x": 23.857746,
                "y": 43.682182,
                "fullAddressString": "гр. МИЗИЯ [3330] ул. ГЕОРГИ ДИМИТРОВ No 92",
                "siteAddressString": "гр. МИЗИЯ [3330]",
                "localAddressString": "ул. ГЕОРГИ ДИМИТРОВ No 92"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:30",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 771,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:30",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2015-11-25",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 771,
            "name": "ОРЯХОВО",
            "nameEn": "ORYAHOVO",
            "siteId": 54020,
            "address": {
                "countryId": 100,
                "siteId": 54020,
                "siteType": "ГР.",
                "siteName": "ОРЯХОВО",
                "postCode": "3300",
                "streetId": 93346,
                "streetType": "УЛ.",
                "streetName": "ВАСИЛ ЛЕВСКИ",
                "streetNo": "9",
                "x": 23.956697,
                "y": 43.736134,
                "fullAddressString": "гр. ОРЯХОВО [3300] ул. ВАСИЛ ЛЕВСКИ No 9",
                "siteAddressString": "гр. ОРЯХОВО [3300]",
                "localAddressString": "ул. ВАСИЛ ЛЕВСКИ No 9"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 766,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2015-12-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 772,
            "name": "СОФИЯ - Г. М. ДИМИТРОВ",
            "nameEn": "SOFIA - G. M. DIMITROV",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 2076,
                "streetType": "УЛ.",
                "streetName": "КРУМ КЮЛЯВКОВ",
                "blockNo": "25",
                "x": 23.349669,
                "y": 42.660931,
                "fullAddressString": "гр. СОФИЯ ул. КРУМ КЮЛЯВКОВ бл. 25",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. КРУМ КЮЛЯВКОВ бл. 25"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "19:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 345,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2015-12-22",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 773,
            "name": "СОФИЯ – ХИПОДРУМА – БИЛЛА",
            "nameEn": "SOFIA – HIPODRUMA – BILLA",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 2929,
                "streetType": "УЛ.",
                "streetName": "СОФИЙСКИ ГЕРОЙ",
                "streetNo": "4",
                "x": 23.297168,
                "y": 42.683473,
                "fullAddressString": "гр. СОФИЯ ул. СОФИЙСКИ ГЕРОЙ No 4",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. СОФИЙСКИ ГЕРОЙ No 4"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 294,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-01-18",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 774,
            "name": "СОФИЯ - ВИТОША (БУЛ.)",
            "nameEn": "SOFIA - VITOSHA (BUL.)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 26,
                "streetType": "БУЛ.",
                "streetName": "ВИТОША",
                "streetNo": "129",
                "x": 23.309626,
                "y": 42.675902,
                "fullAddressString": "гр. СОФИЯ бул. ВИТОША No 129",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "бул. ВИТОША No 129"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "19:00",
            "sameDayDepartureCutoffHalf": "13:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 210,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-01-18",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 776,
            "name": "РУСЕ - БОРЦИТЕ",
            "nameEn": "RUSE - BORTSITE",
            "siteId": 63427,
            "address": {
                "countryId": 100,
                "siteId": 63427,
                "siteType": "ГР.",
                "siteName": "РУСЕ",
                "postCode": "7000",
                "streetId": 16370,
                "streetType": "УЛ.",
                "streetName": "МОСТОВА",
                "streetNo": "3",
                "addressNote": "СРЕЩУ ЕНЕРГОТО",
                "x": 25.957893,
                "y": 43.853798,
                "fullAddressString": "гр. РУСЕ ул. МОСТОВА No 3 СРЕЩУ ЕНЕРГОТО",
                "siteAddressString": "гр. РУСЕ",
                "localAddressString": "ул. МОСТОВА No 3 СРЕЩУ ЕНЕРГОТО"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:30",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:30",
            "sameDayDepartureCutoffHalf": "13:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 868,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:30",
                    "sameDayDepartureCutoff": "13:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-03-28",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 777,
            "name": "ПЛЕВЕН - ЦЕНТЪР",
            "nameEn": "PLEVEN - CENTER",
            "siteId": 56722,
            "address": {
                "countryId": 100,
                "siteId": 56722,
                "siteType": "ГР.",
                "siteName": "ПЛЕВЕН",
                "postCode": "5800",
                "streetId": 20192,
                "streetType": "УЛ.",
                "streetName": "КОСТА ХАДЖИПАКЕВ",
                "streetNo": "13",
                "x": 24.615811,
                "y": 43.411317,
                "fullAddressString": "гр. ПЛЕВЕН ул. КОСТА ХАДЖИПАКЕВ No 13",
                "siteAddressString": "гр. ПЛЕВЕН",
                "localAddressString": "ул. КОСТА ХАДЖИПАКЕВ No 13"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 100.0,
            "type": "OFFICE",
            "nearbyOfficeId": 106,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-03-21",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 778,
            "name": "СОФИЯ - ФОНДОВИ ЖИЛИЩА",
            "nameEn": "SOFIA - FONDOVI ZHILISHTA",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "complexId": 74,
                "complexType": "ЖК",
                "complexName": "ФОНДОВИ ЖИЛИЩА",
                "blockNo": "203",
                "entranceNo": "Б",
                "x": 23.298395,
                "y": 42.714576,
                "fullAddressString": "гр. СОФИЯ жк ФОНДОВИ ЖИЛИЩА бл. 203 вх. Б",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк ФОНДОВИ ЖИЛИЩА бл. 203 вх. Б"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 806,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-04-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 780,
            "name": "БАНКЯ",
            "nameEn": "BANKYA",
            "siteId": 2659,
            "address": {
                "countryId": 100,
                "siteId": 2659,
                "siteType": "ГР.",
                "siteName": "БАНКЯ",
                "postCode": "1320",
                "streetId": 66102,
                "streetType": "УЛ.",
                "streetName": "СОФИЯ",
                "streetNo": "44",
                "x": 23.17208,
                "y": 42.714295,
                "fullAddressString": "гр. БАНКЯ [1320] ул. СОФИЯ No 44",
                "siteAddressString": "гр. БАНКЯ [1320]",
                "localAddressString": "ул. СОФИЯ No 44"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 142,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-04-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 784,
            "name": "ВАРНА - СТЕФАН СТАМБОЛОВ",
            "nameEn": "VARNA - STEFAN STAMBOLOV",
            "siteId": 10135,
            "address": {
                "countryId": 100,
                "siteId": 10135,
                "siteType": "ГР.",
                "siteName": "ВАРНА",
                "postCode": "9000",
                "streetId": 12701,
                "streetType": "УЛ.",
                "streetName": "СТЕФАН СТАМБОЛОВ",
                "streetNo": "15",
                "x": 27.919417,
                "y": 43.204234,
                "fullAddressString": "гр. ВАРНА ул. СТЕФАН СТАМБОЛОВ No 15",
                "siteAddressString": "гр. ВАРНА",
                "localAddressString": "ул. СТЕФАН СТАМБОЛОВ No 15"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 220
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 117,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-05-03",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 785,
            "name": "ВАРНА - НИКОЛА ВАПЦАРОВ",
            "nameEn": "VARNA - NIKOLA VAPTSAROV",
            "siteId": 10135,
            "address": {
                "countryId": 100,
                "siteId": 10135,
                "siteType": "ГР.",
                "siteName": "ВАРНА",
                "postCode": "9000",
                "streetId": 13044,
                "streetType": "УЛ.",
                "streetName": "НИКОЛА Й. ВАПЦАРОВ",
                "streetNo": "5",
                "addressNote": "ПАРТЕР НА ТЪРГОВСКИ КОМПЛЕКС ЧАЙКА",
                "x": 27.933821,
                "y": 43.213805,
                "fullAddressString": "гр. ВАРНА ул. НИКОЛА Й. ВАПЦАРОВ No 5 ПАРТЕР НА ТЪРГОВСКИ КОМПЛЕКС ЧАЙКА",
                "siteAddressString": "гр. ВАРНА",
                "localAddressString": "ул. НИКОЛА Й. ВАПЦАРОВ No 5 ПАРТЕР НА ТЪРГОВСКИ КОМПЛЕКС ЧАЙКА"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 220
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 192,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-04-25",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 786,
            "name": "ВАРНА - СТРУГА",
            "nameEn": "VARNA - STRUGA",
            "siteId": 10135,
            "address": {
                "countryId": 100,
                "siteId": 10135,
                "siteType": "ГР.",
                "siteName": "ВАРНА",
                "postCode": "9000",
                "streetId": 12129,
                "streetType": "УЛ.",
                "streetName": "ВАСИЛ АПРИЛОВ",
                "streetNo": "28",
                "addressNote": "НА ЪГЪЛА НА УЛ.СТРУГА С УЛ. ВАСИЛ АПРИЛОВ",
                "x": 27.906792,
                "y": 43.218508,
                "fullAddressString": "гр. ВАРНА ул. ВАСИЛ АПРИЛОВ No 28 НА ЪГЪЛА НА УЛ.СТРУГА С УЛ. ВАСИЛ АПРИЛОВ",
                "siteAddressString": "гр. ВАРНА",
                "localAddressString": "ул. ВАСИЛ АПРИЛОВ No 28 НА ЪГЪЛА НА УЛ.СТРУГА С УЛ. ВАСИЛ АПРИЛОВ"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 220
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 513,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-04-21",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 787,
            "name": "ВАРНА - АКАДЕМИК КУРЧАТОВ (УЛ.)",
            "nameEn": "VARNA - ACADEMICIAN KURCHATOV (UL.)",
            "siteId": 10135,
            "address": {
                "countryId": 100,
                "siteId": 10135,
                "siteType": "ГР.",
                "siteName": "ВАРНА",
                "postCode": "9000",
                "streetId": 13083,
                "streetType": "УЛ.",
                "streetName": "АКАД. КУРЧАТОВ",
                "streetNo": "1",
                "x": 27.884631,
                "y": 43.218289,
                "fullAddressString": "гр. ВАРНА ул. АКАД. КУРЧАТОВ No 1",
                "siteAddressString": "гр. ВАРНА",
                "localAddressString": "ул. АКАД. КУРЧАТОВ No 1"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 220
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 855,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-05-03",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 788,
            "name": "СРЕДЕЦ",
            "nameEn": "SREDETS",
            "siteId": 17974,
            "address": {
                "countryId": 100,
                "siteId": 17974,
                "siteType": "ГР.",
                "siteName": "СРЕДЕЦ",
                "postCode": "8300",
                "streetId": 76335,
                "streetType": "УЛ.",
                "streetName": "ВАСИЛ КОЛАРОВ",
                "streetNo": "62",
                "x": 27.18409,
                "y": 42.348225,
                "fullAddressString": "гр. СРЕДЕЦ [8300] ул. ВАСИЛ КОЛАРОВ No 62",
                "siteAddressString": "гр. СРЕДЕЦ [8300]",
                "localAddressString": "ул. ВАСИЛ КОЛАРОВ No 62"
            },
            "workingTimeFrom": "10:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "10:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 319,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-04-11",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 789,
            "name": "БУРГАС - ВЪЗРАЖДАНЕ (ЖК), ШЕЙНОВО",
            "nameEn": "BURGAS - VAZRAZHDANE (ZHK), SHEYNOVO",
            "siteId": 7079,
            "address": {
                "countryId": 100,
                "siteId": 7079,
                "siteType": "ГР.",
                "siteName": "БУРГАС",
                "postCode": "8000",
                "streetId": 14360,
                "streetType": "УЛ.",
                "streetName": "ШЕЙНОВО",
                "streetNo": "46",
                "complexId": 712,
                "complexType": "ЖК",
                "complexName": "ВЪЗРАЖДАНЕ",
                "addressNote": "ЗАД ДНА",
                "x": 27.46829,
                "y": 42.49545,
                "fullAddressString": "гр. БУРГАС жк ВЪЗРАЖДАНЕ ул. ШЕЙНОВО No 46 ЗАД ДНА",
                "siteAddressString": "гр. БУРГАС",
                "localAddressString": "жк ВЪЗРАЖДАНЕ ул. ШЕЙНОВО No 46 ЗАД ДНА"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 150
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 602,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-04-12",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 790,
            "name": "СОФИЯ - ДРУЖБА 2, ОБИКОЛНА 29",
            "nameEn": "SOFIA - DRUZHBA 2, OBIKOLNA 29",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 2442,
                "streetType": "УЛ.",
                "streetName": "ОБИКОЛНА",
                "streetNo": "29",
                "complexId": 169,
                "complexType": "ЖК",
                "complexName": "ДРУЖБА",
                "x": 23.408343,
                "y": 42.641866,
                "fullAddressString": "гр. СОФИЯ жк ДРУЖБА ул. ОБИКОЛНА No 29",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк ДРУЖБА ул. ОБИКОЛНА No 29"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 161,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-04-18",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 791,
            "name": "РУСЕ - ЧАРОДЕЙКА (ЖК), ДРУЖБА",
            "nameEn": "RUSE - CHARODEYKA (ZHK), DRUZHBA",
            "siteId": 63427,
            "address": {
                "countryId": 100,
                "siteId": 63427,
                "siteType": "ГР.",
                "siteName": "РУСЕ",
                "postCode": "7000",
                "streetId": 16229,
                "streetType": "УЛ.",
                "streetName": "ЗОРНИЦА",
                "streetNo": "3Б",
                "x": 25.969845,
                "y": 43.835107,
                "fullAddressString": "гр. РУСЕ ул. ЗОРНИЦА No 3Б",
                "siteAddressString": "гр. РУСЕ",
                "localAddressString": "ул. ЗОРНИЦА No 3Б"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 371,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-04-25",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 792,
            "name": "ПЛОВДИВ - ВОЕННА БОЛНИЦА",
            "nameEn": "PLOVDIV - VOENNA BOLNITSA",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "streetId": 10032,
                "streetType": "БУЛ.",
                "streetName": "ХРИСТО БОТЕВ",
                "streetNo": "85",
                "x": 24.757667,
                "y": 42.136411,
                "fullAddressString": "гр. ПЛОВДИВ бул. ХРИСТО БОТЕВ No 85",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "бул. ХРИСТО БОТЕВ No 85"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 94,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-05-04",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 794,
            "name": "ПЛОВДИВ - ДУНАВ",
            "nameEn": "PLOVDIV - DUNAV",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "streetId": 10011,
                "streetType": "БУЛ.",
                "streetName": "ДУНАВ",
                "streetNo": "66",
                "x": 24.740986,
                "y": 42.162378,
                "fullAddressString": "гр. ПЛОВДИВ бул. ДУНАВ No 66",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "бул. ДУНАВ No 66"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 120,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 350.0,
            "type": "OFFICE",
            "nearbyOfficeId": 222,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-05-04",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 795,
            "name": "ПЛОВДИВ - ШАХБАЗЯН",
            "nameEn": "PLOVDIV - SHAHBAZYAN",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "streetId": 10636,
                "streetType": "УЛ.",
                "streetName": "ЛАМАРТИН",
                "streetNo": "2",
                "x": 24.756166,
                "y": 42.15228,
                "fullAddressString": "гр. ПЛОВДИВ ул. ЛАМАРТИН No 2",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "ул. ЛАМАРТИН No 2"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 79,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-05-05",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 796,
            "name": "СОФИЯ - ГЕОРГИ РАКОВСКИ",
            "nameEn": "SOFIA - GEORGI RAKOVSKI",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 1447,
                "streetType": "УЛ.",
                "streetName": "ГЕОРГИ С. РАКОВСКИ",
                "streetNo": "49",
                "x": 23.329085,
                "y": 42.70346,
                "fullAddressString": "гр. СОФИЯ ул. ГЕОРГИ С. РАКОВСКИ No 49",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. ГЕОРГИ С. РАКОВСКИ No 49"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 848,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-05-16",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 798,
            "name": "СОФИЯ - ДРУЖБА (ЖК), ИСКЪРСКО ШОСЕ",
            "nameEn": "SOFIA - DRUZHBA (ZHK), ISKARSKO SHOSE",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 1866,
                "streetType": "УЛ.",
                "streetName": "ИСКЪРСКО ШОСЕ",
                "streetNo": "13",
                "x": 23.399093,
                "y": 42.670434,
                "fullAddressString": "гр. СОФИЯ ул. ИСКЪРСКО ШОСЕ No 13",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. ИСКЪРСКО ШОСЕ No 13"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 363,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-05-16",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 801,
            "name": "БЛАГОЕВГРАД - СТРУМСКО (ЖК)",
            "nameEn": "BLAGOEVGRAD - STRUMSKO (ZHK)",
            "siteId": 4279,
            "address": {
                "countryId": 100,
                "siteId": 4279,
                "siteType": "ГР.",
                "siteName": "БЛАГОЕВГРАД",
                "postCode": "2700",
                "streetId": 30331,
                "streetType": "УЛ.",
                "streetName": "СТРУГА",
                "streetNo": "1",
                "complexId": 1506,
                "complexType": "ЖК",
                "complexName": "СТРУМСКО",
                "addressNote": "НАД М-Н БРИКОЛАЖ",
                "x": 23.089571,
                "y": 41.996174,
                "fullAddressString": "гр. БЛАГОЕВГРАД жк СТРУМСКО ул. СТРУГА No 1 НАД М-Н БРИКОЛАЖ",
                "siteAddressString": "гр. БЛАГОЕВГРАД",
                "localAddressString": "жк СТРУМСКО ул. СТРУГА No 1 НАД М-Н БРИКОЛАЖ"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "12:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 13,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "12:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-09-07",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 802,
            "name": "БЛАГОЕВГРАД - ЗАПАД (ЖК)",
            "nameEn": "BLAGOEVGRAD - ZAPAD",
            "siteId": 4279,
            "address": {
                "countryId": 100,
                "siteId": 4279,
                "siteType": "ГР.",
                "siteName": "БЛАГОЕВГРАД",
                "postCode": "2700",
                "streetId": 30233,
                "streetType": "УЛ.",
                "streetName": "СЛАВЯНСКА",
                "streetNo": "83",
                "complexId": 1503,
                "complexType": "ЖК",
                "complexName": "ЗАПАД",
                "addressNote": "ДО ПИЦАРИЯ БОНЖУР",
                "x": 23.083722,
                "y": 42.021211,
                "fullAddressString": "гр. БЛАГОЕВГРАД жк ЗАПАД ул. СЛАВЯНСКА No 83 ДО ПИЦАРИЯ БОНЖУР",
                "siteAddressString": "гр. БЛАГОЕВГРАД",
                "localAddressString": "жк ЗАПАД ул. СЛАВЯНСКА No 83 ДО ПИЦАРИЯ БОНЖУР"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "12:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 13,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "12:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-09-07",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 803,
            "name": "ГОЦЕ ДЕЛЧЕВ",
            "nameEn": "GOTSE DELCHEV",
            "siteId": 17395,
            "address": {
                "countryId": 100,
                "siteId": 17395,
                "siteType": "ГР.",
                "siteName": "ГОЦЕ ДЕЛЧЕВ",
                "postCode": "2900",
                "streetId": 68041,
                "streetType": "УЛ.",
                "streetName": "ИЛИЯ ДУКОВ",
                "streetNo": "1",
                "addressNote": "ЗАД 1- ВО ОУ, НА СВЕТОФАРА",
                "x": 23.724607,
                "y": 41.573795,
                "fullAddressString": "гр. ГОЦЕ ДЕЛЧЕВ [2900] ул. ИЛИЯ ДУКОВ No 1 ЗАД 1- ВО ОУ, НА СВЕТОФАРА",
                "siteAddressString": "гр. ГОЦЕ ДЕЛЧЕВ [2900]",
                "localAddressString": "ул. ИЛИЯ ДУКОВ No 1 ЗАД 1- ВО ОУ, НА СВЕТОФАРА"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "16:00",
            "sameDayDepartureCutoffHalf": "11:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 31,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "11:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-09-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 804,
            "name": "ВЕЛИКО ТЪРНОВО - ЦЕНТЪР",
            "nameEn": "VELIKO TARNOVO - CENTER",
            "siteId": 10447,
            "address": {
                "countryId": 100,
                "siteId": 10447,
                "siteType": "ГР.",
                "siteName": "ВЕЛИКО ТЪРНОВО",
                "postCode": "5000",
                "streetId": 34015,
                "streetType": "УЛ.",
                "streetName": "АЛЕКСАНДЪР СТАМБОЛИЙСКИ",
                "streetNo": "11",
                "x": 25.634907,
                "y": 43.081612,
                "fullAddressString": "гр. ВЕЛИКО ТЪРНОВО ул. АЛЕКСАНДЪР СТАМБОЛИЙСКИ No 11",
                "siteAddressString": "гр. ВЕЛИКО ТЪРНОВО",
                "localAddressString": "ул. АЛЕКСАНДЪР СТАМБОЛИЙСКИ No 11"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 300,
                "depth": 170
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 80,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-09-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 806,
            "name": "СОФИЯ - СВЕТА ТРОИЦА (ЖК), БЛ.304",
            "nameEn": "SOFIA - SVETA TROITSA (ZHK), BL.304",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "complexId": 65,
                "complexType": "ЖК",
                "complexName": "СВЕТА ТРОИЦА",
                "blockNo": "304",
                "x": 23.290462,
                "y": 42.709959,
                "fullAddressString": "гр. СОФИЯ жк СВЕТА ТРОИЦА бл. 304",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк СВЕТА ТРОИЦА бл. 304"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 421,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-06-18",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 808,
            "name": "СОФИЯ - ОВЧА КУПЕЛ 1 - ПРОМИШЛЕНА",
            "nameEn": "SOFIA - OVCA KUPEL 1 - PROMISHLENA",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 3820,
                "streetType": "УЛ.",
                "streetName": "ПРОМИШЛЕНА",
                "complexId": 172,
                "complexType": "ЖК",
                "complexName": "ОВЧА КУПЕЛ",
                "blockNo": "429",
                "addressNote": "СРЕЩУ БИЛЛА",
                "x": 23.24989,
                "y": 42.688741,
                "fullAddressString": "гр. СОФИЯ жк ОВЧА КУПЕЛ ул. ПРОМИШЛЕНА бл. 429 СРЕЩУ БИЛЛА",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк ОВЧА КУПЕЛ ул. ПРОМИШЛЕНА бл. 429 СРЕЩУ БИЛЛА"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 290,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2017-06-05",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 813,
            "name": "БУРГАС - САРАФОВО (КВ.)",
            "nameEn": "BURGAS - SARAFOVO (KV.)",
            "siteId": 7079,
            "address": {
                "countryId": 100,
                "siteId": 7079,
                "siteType": "ГР.",
                "siteName": "БУРГАС",
                "postCode": "8000",
                "streetId": 14047,
                "streetType": "УЛ.",
                "streetName": "БРАЦИГОВО",
                "streetNo": "16А",
                "complexId": 705,
                "complexType": "КВ.",
                "complexName": "САРАФОВО",
                "x": 27.521457,
                "y": 42.562038,
                "fullAddressString": "гр. БУРГАС кв. САРАФОВО ул. БРАЦИГОВО No 16А",
                "siteAddressString": "гр. БУРГАС",
                "localAddressString": "кв. САРАФОВО ул. БРАЦИГОВО No 16А"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 170,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 814,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2017-03-07",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 814,
            "name": "БУРГАС - ИЗГРЕВ (ЖК)",
            "nameEn": "BURGAS - IZGREV (ZHK)",
            "siteId": 7079,
            "address": {
                "countryId": 100,
                "siteId": 7079,
                "siteType": "ГР.",
                "siteName": "БУРГАС",
                "postCode": "8000",
                "streetId": 14321,
                "streetType": "УЛ.",
                "streetName": "ТРАНСПОРТНА",
                "streetNo": "15",
                "complexId": 707,
                "complexType": "ЖК",
                "complexName": "ИЗГРЕВ",
                "addressNote": "В СГРАДАТА НА СМАРТ ЦЕНТЪР",
                "x": 27.464667,
                "y": 42.531645,
                "fullAddressString": "гр. БУРГАС жк ИЗГРЕВ ул. ТРАНСПОРТНА No 15 В СГРАДАТА НА СМАРТ ЦЕНТЪР",
                "siteAddressString": "гр. БУРГАС",
                "localAddressString": "жк ИЗГРЕВ ул. ТРАНСПОРТНА No 15 В СГРАДАТА НА СМАРТ ЦЕНТЪР"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "10:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 170,
                "depth": 350
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 390,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2017-03-07",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 816,
            "name": "ВЕЛИКО ТЪРНОВО - Н. ГАБРОВСКИ (БУЛ.)",
            "nameEn": "VELIKO TARNOVO - N. GABROVSKI (BUL.)",
            "siteId": 10447,
            "address": {
                "countryId": 100,
                "siteId": 10447,
                "siteType": "ГР.",
                "siteName": "ВЕЛИКО ТЪРНОВО",
                "postCode": "5000",
                "streetId": 34151,
                "streetType": "БУЛ.",
                "streetName": "НИКОЛА ГАБРОВСКИ",
                "streetNo": "58",
                "x": 25.616002,
                "y": 43.07483,
                "fullAddressString": "гр. ВЕЛИКО ТЪРНОВО бул. НИКОЛА ГАБРОВСКИ No 58",
                "siteAddressString": "гр. ВЕЛИКО ТЪРНОВО",
                "localAddressString": "бул. НИКОЛА ГАБРОВСКИ No 58"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 80,
                "depth": 180
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 80,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2017-02-27",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 817,
            "name": "ВРАЦА - ПЛ. ЛЕВСКИ",
            "nameEn": "VRATSA - PL. LEVSKI",
            "siteId": 12259,
            "address": {
                "countryId": 100,
                "siteId": 12259,
                "siteType": "ГР.",
                "siteName": "ВРАЦА",
                "postCode": "3000",
                "streetId": 38060,
                "streetType": "УЛ.",
                "streetName": "ГЕН. ЛЕОНОВ",
                "streetNo": "74",
                "x": 23.558385,
                "y": 43.199972,
                "fullAddressString": "гр. ВРАЦА [3000] ул. ГЕН. ЛЕОНОВ No 74",
                "siteAddressString": "гр. ВРАЦА [3000]",
                "localAddressString": "ул. ГЕН. ЛЕОНОВ No 74"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:30",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 170,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 548,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:30",
                    "sameDayDepartureCutoff": "13:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2017-04-03",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 818,
            "name": "ВИДИН - КАЛЕТО",
            "nameEn": "VIDIN - KALETO",
            "siteId": 10971,
            "address": {
                "countryId": 100,
                "siteId": 10971,
                "siteType": "ГР.",
                "siteName": "ВИДИН",
                "postCode": "3700",
                "streetId": 36042,
                "streetType": "УЛ.",
                "streetName": "БДИН",
                "streetNo": "8",
                "complexId": 1834,
                "complexType": "КВ.",
                "complexName": "КАЛЕТО",
                "x": 22.878645,
                "y": 43.986526,
                "fullAddressString": "гр. ВИДИН [3700] кв. КАЛЕТО ул. БДИН No 8",
                "siteAddressString": "гр. ВИДИН [3700]",
                "localAddressString": "кв. КАЛЕТО ул. БДИН No 8"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 170,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 62,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2017-04-24",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 821,
            "name": "СОФИЯ - ЕВЛОГИ ГЕОРГИЕВ",
            "nameEn": "SOFIA - EVLOGI GEORGIEV",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 4905,
                "streetType": "БУЛ.",
                "streetName": "ЕВЛОГИ И ХРИСТО ГЕОРГИЕВИ",
                "streetNo": "105",
                "x": 23.339427,
                "y": 42.691404,
                "fullAddressString": "гр. СОФИЯ бул. ЕВЛОГИ И ХРИСТО ГЕОРГИЕВИ No 105",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "бул. ЕВЛОГИ И ХРИСТО ГЕОРГИЕВИ No 105"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 120,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 326,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2017-06-06",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 825,
            "name": "ПЛЕВЕН - БЕНЗИНОСТАНЦИЯ ШЕЛ",
            "nameEn": "PLEVEN - PETROL STATION SHELL",
            "siteId": 56722,
            "address": {
                "countryId": 100,
                "siteId": 56722,
                "siteType": "ГР.",
                "siteName": "ПЛЕВЕН",
                "postCode": "5800",
                "streetId": 20056,
                "streetType": "УЛ.",
                "streetName": "БЪЛГАРСКА АВИАЦИЯ",
                "streetNo": "125",
                "x": 24.609117,
                "y": 43.418674,
                "fullAddressString": "гр. ПЛЕВЕН ул. БЪЛГАРСКА АВИАЦИЯ No 125",
                "siteAddressString": "гр. ПЛЕВЕН",
                "localAddressString": "ул. БЪЛГАРСКА АВИАЦИЯ No 125"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 12,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2017-10-02",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 826,
            "name": "РАКОВСКИ - АЛ. СТАМБОЛИЙСКИ (УЛ.)",
            "nameEn": "RAKOVSKI - AL. STAMBOLIYSKI (UL.)",
            "siteId": 62075,
            "address": {
                "countryId": 100,
                "siteId": 62075,
                "siteType": "ГР.",
                "siteName": "РАКОВСКИ",
                "postCode": "4150",
                "streetId": 83549,
                "streetType": "УЛ.",
                "streetName": "АЛЕКСАНДЪР СТАМБОЛИЙСКИ",
                "streetNo": "5",
                "x": 24.96418,
                "y": 42.28466,
                "fullAddressString": "гр. РАКОВСКИ [4150] ул. АЛЕКСАНДЪР СТАМБОЛИЙСКИ No 5",
                "siteAddressString": "гр. РАКОВСКИ [4150]",
                "localAddressString": "ул. АЛЕКСАНДЪР СТАМБОЛИЙСКИ No 5"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "12:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 442,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "12:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2017-10-16",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 828,
            "name": "СОФИЯ – КРИВОЛАК (УЛ.)",
            "nameEn": "SOFIA - KRIVOLAK (UL.)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 2070,
                "streetType": "УЛ.",
                "streetName": "КРИВОЛАК",
                "streetNo": "8",
                "addressNote": "ДО ПЛ. ЖУРНАЛИСТ",
                "x": 23.330255,
                "y": 42.680846,
                "fullAddressString": "гр. СОФИЯ ул. КРИВОЛАК No 8 ДО ПЛ. ЖУРНАЛИСТ",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. КРИВОЛАК No 8 ДО ПЛ. ЖУРНАЛИСТ"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 236,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2017-11-06",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 829,
            "name": "БУРГАС - СЛАВЕЙКОВ (ЖК)",
            "nameEn": "BURGAS - SLAVEYKOV 2 (ZHK)",
            "siteId": 7079,
            "address": {
                "countryId": 100,
                "siteId": 7079,
                "siteType": "ГР.",
                "siteName": "БУРГАС",
                "postCode": "8000",
                "complexId": 794,
                "complexType": "ЖК",
                "complexName": "СЛАВЕЙКОВ",
                "blockNo": "113",
                "addressNote": "ДО БИЛЛА",
                "x": 27.451894,
                "y": 42.517766,
                "fullAddressString": "гр. БУРГАС жк СЛАВЕЙКОВ бл. 113 ДО БИЛЛА",
                "siteAddressString": "гр. БУРГАС",
                "localAddressString": "жк СЛАВЕЙКОВ бл. 113 ДО БИЛЛА"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 200,
                "depth": 200
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 145,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2017-12-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 830,
            "name": "СОФИЯ - ВРАЖДЕБНА",
            "nameEn": "SOFIA - VRAZHDEBNA",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 895,
                "streetType": "УЛ.",
                "streetName": "8",
                "streetNo": "95",
                "complexId": 83,
                "complexType": "КВ.",
                "complexName": "ВРАЖДЕБНА",
                "x": 23.420104,
                "y": 42.71203,
                "fullAddressString": "гр. СОФИЯ кв. ВРАЖДЕБНА ул. 8 No 95",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "кв. ВРАЖДЕБНА ул. 8 No 95"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 308,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2017-12-05",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 831,
            "name": "ЛОМ - НЕЧАЕВ",
            "nameEn": "LOM - NECHAEV",
            "siteId": 44238,
            "address": {
                "countryId": 100,
                "siteId": 44238,
                "siteType": "ГР.",
                "siteName": "ЛОМ",
                "postCode": "3600",
                "streetId": 70259,
                "streetType": "УЛ.",
                "streetName": "ДУНАВСКА",
                "streetNo": "71",
                "x": 23.233418,
                "y": 43.831423,
                "fullAddressString": "гр. ЛОМ [3600] ул. ДУНАВСКА No 71",
                "siteAddressString": "гр. ЛОМ [3600]",
                "localAddressString": "ул. ДУНАВСКА No 71"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:30",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "13:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 300,
                "depth": 170
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 63,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:30",
                    "sameDayDepartureCutoff": "13:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2017-11-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 832,
            "name": "ВАРНА - ЛЕВСКИ (КВ.) 2",
            "nameEn": "VARNA - LEVSKI (KV.) 2",
            "siteId": 10135,
            "address": {
                "countryId": 100,
                "siteId": 10135,
                "siteType": "ГР.",
                "siteName": "ВАРНА",
                "postCode": "9000",
                "streetId": 12135,
                "streetType": "УЛ.",
                "streetName": "ВАСИЛ СТАВРЕВ",
                "streetNo": "2",
                "entranceNo": "1",
                "x": 27.923008,
                "y": 43.226479,
                "fullAddressString": "гр. ВАРНА ул. ВАСИЛ СТАВРЕВ No 2 вх. 1",
                "siteAddressString": "гр. ВАРНА",
                "localAddressString": "ул. ВАСИЛ СТАВРЕВ No 2 вх. 1"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 220
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 661,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2017-12-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 833,
            "name": "ВАРНА - АСПАРУХОВО",
            "nameEn": "VARNA - ASPARUHOVO",
            "siteId": 10135,
            "address": {
                "countryId": 100,
                "siteId": 10135,
                "siteType": "ГР.",
                "siteName": "ВАРНА",
                "postCode": "9000",
                "streetId": 12464,
                "streetType": "УЛ.",
                "streetName": "МАРА ТАСЕВА",
                "streetNo": "1",
                "complexId": 612,
                "complexType": "КВ.",
                "complexName": "АСПАРУХОВО",
                "addressNote": "ДО СИТИ ЦЕНТЪР АСПАРУХОВО",
                "x": 27.894657,
                "y": 43.184182,
                "fullAddressString": "гр. ВАРНА кв. АСПАРУХОВО ул. МАРА ТАСЕВА No 1 ДО СИТИ ЦЕНТЪР АСПАРУХОВО",
                "siteAddressString": "гр. ВАРНА",
                "localAddressString": "кв. АСПАРУХОВО ул. МАРА ТАСЕВА No 1 ДО СИТИ ЦЕНТЪР АСПАРУХОВО"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 220
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 268,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2017-12-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 834,
            "name": "ШУМЕН – БЕЛИ ЛОМ, ДО КАУФЛАНД",
            "nameEn": "SHUMEN – BELI LOM, DO KAUFLAND",
            "siteId": 83510,
            "address": {
                "countryId": 100,
                "siteId": 83510,
                "siteType": "ГР.",
                "siteName": "ШУМЕН",
                "postCode": "9700",
                "streetId": 24026,
                "streetType": "УЛ.",
                "streetName": "БЕЛИ ЛОМ",
                "streetNo": "2",
                "addressNote": "ДО КАУФЛАНД 2",
                "x": 26.915007,
                "y": 43.278291,
                "fullAddressString": "гр. ШУМЕН [9700] ул. БЕЛИ ЛОМ No 2 ДО КАУФЛАНД 2",
                "siteAddressString": "гр. ШУМЕН [9700]",
                "localAddressString": "ул. БЕЛИ ЛОМ No 2 ДО КАУФЛАНД 2"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 253,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2017-12-11",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 835,
            "name": "КАРЛОВО - КОМПЛЕКС ЗАМЪКА",
            "nameEn": "KARLOVO - KOMPLEKS ZAMAKA",
            "siteId": 36498,
            "address": {
                "countryId": 100,
                "siteId": 36498,
                "siteType": "ГР.",
                "siteName": "КАРЛОВО",
                "postCode": "4300",
                "streetId": 78894,
                "streetType": "БУЛ.",
                "streetName": "ОСВОБОЖДЕНИЕ",
                "streetNo": "19А",
                "x": 24.813405,
                "y": 42.640585,
                "fullAddressString": "гр. КАРЛОВО [4300] бул. ОСВОБОЖДЕНИЕ No 19А",
                "siteAddressString": "гр. КАРЛОВО [4300]",
                "localAddressString": "бул. ОСВОБОЖДЕНИЕ No 19А"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 403,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-03-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 836,
            "name": "ПЛОВДИВ - 2-РА ГРАДСКА БОЛНИЦА",
            "nameEn": "PLOVDIV - 2-RA GRADSKA BOLNICA",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "streetId": 10025,
                "streetType": "БУЛ.",
                "streetName": "НИКОЛА ВАПЦАРОВ",
                "streetNo": "9",
                "x": 24.750679,
                "y": 42.121897,
                "fullAddressString": "гр. ПЛОВДИВ бул. НИКОЛА ВАПЦАРОВ No 9",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "бул. НИКОЛА ВАПЦАРОВ No 9"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 1,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-01-10",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 837,
            "name": "ПЛОВДИВ - ТРАКИЯ (ЖК), БЛ.45",
            "nameEn": "PLOVDIV - TRAKIA (ZHK), BL.45",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "complexId": 504,
                "complexType": "ЖК",
                "complexName": "ТРАКИЯ",
                "blockNo": "45",
                "x": 24.789979,
                "y": 42.135828,
                "fullAddressString": "гр. ПЛОВДИВ жк ТРАКИЯ бл. 45",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "жк ТРАКИЯ бл. 45"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 430,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-03-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 838,
            "name": "СТАРА ЗАГОРА - ХРИСТО БОТЕВ",
            "nameEn": "STARA ZAGORA - HRISTO BOTEV",
            "siteId": 68850,
            "address": {
                "countryId": 100,
                "siteId": 68850,
                "siteType": "ГР.",
                "siteName": "СТАРА ЗАГОРА",
                "postCode": "6000",
                "streetId": 18339,
                "streetType": "УЛ.",
                "streetName": "ХРИСТО БОТЕВ",
                "streetNo": "50",
                "x": 25.622901,
                "y": 42.420878,
                "fullAddressString": "гр. СТАРА ЗАГОРА ул. ХРИСТО БОТЕВ No 50",
                "siteAddressString": "гр. СТАРА ЗАГОРА",
                "localAddressString": "ул. ХРИСТО БОТЕВ No 50"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 84,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-01-22",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 840,
            "name": "СОФИЯ - РЕДУТА",
            "nameEn": "SOFIA - REDUTA",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 1283,
                "streetType": "УЛ.",
                "streetName": "ВЕЛЧО АТАНАСОВ",
                "streetNo": "14",
                "complexId": 106,
                "complexType": "КВ.",
                "complexName": "РЕДУТА",
                "x": 23.360081,
                "y": 42.69469,
                "fullAddressString": "гр. СОФИЯ кв. РЕДУТА ул. ВЕЛЧО АТАНАСОВ No 14",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "кв. РЕДУТА ул. ВЕЛЧО АТАНАСОВ No 14"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "19:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 255,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-11-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 841,
            "name": "ГАБРОВО - ШИВАРОВ МОСТ",
            "nameEn": "GABROVO - SHIVAROV BRIDGE",
            "siteId": 14218,
            "address": {
                "countryId": 100,
                "siteId": 14218,
                "siteType": "ГР.",
                "siteName": "ГАБРОВО",
                "postCode": "5300",
                "streetId": 48224,
                "streetType": "УЛ.",
                "streetName": "ЙОСИФ СОКОЛСКИ",
                "streetNo": "18",
                "x": 25.326062,
                "y": 42.861414,
                "fullAddressString": "гр. ГАБРОВО [5300] ул. ЙОСИФ СОКОЛСКИ No 18",
                "siteAddressString": "гр. ГАБРОВО [5300]",
                "localAddressString": "ул. ЙОСИФ СОКОЛСКИ No 18"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 456,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-02-26",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 842,
            "name": "СТАРА ЗАГОРА - ИНДУСТРИАЛЕН",
            "nameEn": "STARA ZAGORA - INDUSTRIALEN",
            "siteId": 68850,
            "address": {
                "countryId": 100,
                "siteId": 68850,
                "siteType": "ГР.",
                "siteName": "СТАРА ЗАГОРА",
                "postCode": "6000",
                "streetId": 18170,
                "streetType": "УЛ.",
                "streetName": "ИНДУСТРИАЛНА",
                "streetNo": "18",
                "complexId": 905,
                "complexType": "КВ.",
                "complexName": "ИНДУСТРИАЛЕН",
                "x": 25.627155,
                "y": 42.409516,
                "fullAddressString": "гр. СТАРА ЗАГОРА кв. ИНДУСТРИАЛЕН ул. ИНДУСТРИАЛНА No 18",
                "siteAddressString": "гр. СТАРА ЗАГОРА",
                "localAddressString": "кв. ИНДУСТРИАЛЕН ул. ИНДУСТРИАЛНА No 18"
            },
            "workingTimeFrom": "08:30",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "08:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 144,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-03-06",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 843,
            "name": "СТАРА ЗАГОРА – МОТОТЕХНИКА",
            "nameEn": "STARA ZAGORA - MOTOTEHNIKA",
            "siteId": 68850,
            "address": {
                "countryId": 100,
                "siteId": 68850,
                "siteType": "ГР.",
                "siteName": "СТАРА ЗАГОРА",
                "postCode": "6000",
                "streetId": 18013,
                "streetType": "БУЛ.",
                "streetName": "ЦАР СИМЕОН ВЕЛИКИ",
                "streetNo": "161",
                "x": 25.646123,
                "y": 42.42781,
                "fullAddressString": "гр. СТАРА ЗАГОРА бул. ЦАР СИМЕОН ВЕЛИКИ No 161",
                "siteAddressString": "гр. СТАРА ЗАГОРА",
                "localAddressString": "бул. ЦАР СИМЕОН ВЕЛИКИ No 161"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 158,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-04-10",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 847,
            "name": "СОФИЯ - ПАЗАР СИТНЯКОВО",
            "nameEn": "SOFIA - PAZAR SITNYAKOVO",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 136,
                "streetType": "БУЛ.",
                "streetName": "ШИПЧЕНСКИ ПРОХОД",
                "streetNo": "3",
                "x": 23.353313,
                "y": 42.684541,
                "fullAddressString": "гр. СОФИЯ бул. ШИПЧЕНСКИ ПРОХОД No 3",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "бул. ШИПЧЕНСКИ ПРОХОД No 3"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 347,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-02-15",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 848,
            "name": "СОФИЯ - СЕРДИКА (УЛ.)",
            "nameEn": "SOFIA - SERDIKA (UL.)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 2877,
                "streetType": "УЛ.",
                "streetName": "СЕРДИКА",
                "streetNo": "28",
                "x": 23.324328,
                "y": 42.700839,
                "fullAddressString": "гр. СОФИЯ ул. СЕРДИКА No 28",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. СЕРДИКА No 28"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 796,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-03-16",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 849,
            "name": "РУСЕ - ЗДРАВЕЦ ИЗТОК",
            "nameEn": "RUSE - ZDRAVETS IZTOK",
            "siteId": 63427,
            "address": {
                "countryId": 100,
                "siteId": 63427,
                "siteType": "ГР.",
                "siteName": "РУСЕ",
                "postCode": "7000",
                "streetId": 16298,
                "streetType": "УЛ.",
                "streetName": "КОТОВСК",
                "streetNo": "12",
                "complexId": 845,
                "complexType": "ЖК",
                "complexName": "ЗДРАВЕЦ-ИЗТОК",
                "addressNote": "МАГАЗИН 4, ДОЙЧЕ ШУЛЕ",
                "x": 25.985499,
                "y": 43.849346,
                "fullAddressString": "гр. РУСЕ жк ЗДРАВЕЦ-ИЗТОК ул. КОТОВСК No 12 МАГАЗИН 4, ДОЙЧЕ ШУЛЕ",
                "siteAddressString": "гр. РУСЕ",
                "localAddressString": "жк ЗДРАВЕЦ-ИЗТОК ул. КОТОВСК No 12 МАГАЗИН 4, ДОЙЧЕ ШУЛЕ"
            },
            "workingTimeFrom": "08:30",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 179,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-03-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 851,
            "name": "ДОБРИЧ - 25-ТИ СЕПТЕМВРИ (БУЛ.)",
            "nameEn": "DOBRICH - 25-TI SEPTEMBER (BUL.)",
            "siteId": 72624,
            "address": {
                "countryId": 100,
                "siteId": 72624,
                "siteType": "ГР.",
                "siteName": "ДОБРИЧ",
                "postCode": "9300",
                "streetId": 22001,
                "streetType": "БУЛ.",
                "streetName": "25-ТИ СЕПТЕМВРИ",
                "streetNo": "37",
                "x": 27.827274,
                "y": 43.575127,
                "fullAddressString": "гр. ДОБРИЧ [9300] бул. 25-ТИ СЕПТЕМВРИ No 37",
                "siteAddressString": "гр. ДОБРИЧ [9300]",
                "localAddressString": "бул. 25-ТИ СЕПТЕМВРИ No 37"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 220
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 375,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-03-19",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 852,
            "name": "ДОБРИЧ - ГЕН. КОЛЕВ (УЛ.)",
            "nameEn": "DOBRICH - GEN. KOLEV (UL.)",
            "siteId": 72624,
            "address": {
                "countryId": 100,
                "siteId": 72624,
                "siteType": "ГР.",
                "siteName": "ДОБРИЧ",
                "postCode": "9300",
                "streetId": 22064,
                "streetType": "УЛ.",
                "streetName": "ГЕН. КОЛЕВ",
                "streetNo": "30",
                "x": 27.824936,
                "y": 43.56378,
                "fullAddressString": "гр. ДОБРИЧ [9300] ул. ГЕН. КОЛЕВ No 30",
                "siteAddressString": "гр. ДОБРИЧ [9300]",
                "localAddressString": "ул. ГЕН. КОЛЕВ No 30"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "17:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 220
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 14,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-04-02",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 853,
            "name": "КНЕЖА - ДИМИТЪР БУТАНСКИ (УЛ)",
            "nameEn": "KNEZHA - DIMITAR BUTANSKI (UL)",
            "siteId": 37376,
            "address": {
                "countryId": 100,
                "siteId": 37376,
                "siteType": "ГР.",
                "siteName": "КНЕЖА",
                "postCode": "5835",
                "streetId": 79198,
                "streetType": "УЛ.",
                "streetName": "ДИМИТЪР БУТАНСКИ",
                "streetNo": "27",
                "x": 24.081784,
                "y": 43.492538,
                "fullAddressString": "гр. КНЕЖА [5835] ул. ДИМИТЪР БУТАНСКИ No 27",
                "siteAddressString": "гр. КНЕЖА [5835]",
                "localAddressString": "ул. ДИМИТЪР БУТАНСКИ No 27"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:30",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 170,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 59,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:30",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-02-26",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 854,
            "name": "ВАРНА - ГЕН. КОЛЕВ (УЛ.)",
            "nameEn": "VARNA - GEN. KOLEV (UL.)",
            "siteId": 10135,
            "address": {
                "countryId": 100,
                "siteId": 10135,
                "siteType": "ГР.",
                "siteName": "ВАРНА",
                "postCode": "9000",
                "streetId": 12171,
                "streetType": "УЛ.",
                "streetName": "ГЕН. КОЛЕВ",
                "streetNo": "68",
                "x": 27.919261,
                "y": 43.213368,
                "fullAddressString": "гр. ВАРНА ул. ГЕН. КОЛЕВ No 68",
                "siteAddressString": "гр. ВАРНА",
                "localAddressString": "ул. ГЕН. КОЛЕВ No 68"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 220
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 77,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-03-19",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 855,
            "name": "ВАРНА - ТРОШЕВО (ЖК)",
            "nameEn": "VARNA - TROSHEVO (ZHK)",
            "siteId": 10135,
            "address": {
                "countryId": 100,
                "siteId": 10135,
                "siteType": "ГР.",
                "siteName": "ВАРНА",
                "postCode": "9000",
                "streetId": 12626,
                "streetType": "УЛ.",
                "streetName": "РАДОСТ",
                "streetNo": "9",
                "complexId": 610,
                "complexType": "ЖК",
                "complexName": "ТРОШЕВО",
                "x": 27.879106,
                "y": 43.222339,
                "fullAddressString": "гр. ВАРНА жк ТРОШЕВО ул. РАДОСТ No 9",
                "siteAddressString": "гр. ВАРНА",
                "localAddressString": "жк ТРОШЕВО ул. РАДОСТ No 9"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 220
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 856,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-03-19",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 856,
            "name": "ВАРНА - МЛАДОСТ (Ж.К.), ПАЗАР ГЮРЛАТА",
            "nameEn": "VARNA - MLADOST (ZH.K.), PAZAR GYURLATA",
            "siteId": 10135,
            "address": {
                "countryId": 100,
                "siteId": 10135,
                "siteType": "ГР.",
                "siteName": "ВАРНА",
                "postCode": "9000",
                "complexId": 682,
                "complexType": "ЖК",
                "complexName": "МЛАДОСТ",
                "blockNo": ".",
                "addressNote": "ПАЗАР ГЮРЛАТА МОДУЛ 37",
                "x": 27.871204,
                "y": 43.230047,
                "fullAddressString": "гр. ВАРНА жк МЛАДОСТ бл. . ПАЗАР ГЮРЛАТА МОДУЛ 37",
                "siteAddressString": "гр. ВАРНА",
                "localAddressString": "жк МЛАДОСТ бл. . ПАЗАР ГЮРЛАТА МОДУЛ 37"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 220
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 855,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-03-26",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 857,
            "name": "БОТЕВГРАД - 3-ТИ МАРТ (БУЛ.)",
            "nameEn": "BOTEVGRAD - 3-TI MART (BUL.)",
            "siteId": 5815,
            "address": {
                "countryId": 100,
                "siteId": 5815,
                "siteType": "ГР.",
                "siteName": "БОТЕВГРАД",
                "postCode": "2140",
                "streetId": 74536,
                "streetType": "УЛ.",
                "streetName": "17-ТИ НОЕМВРИ",
                "streetNo": "1",
                "x": 23.788926,
                "y": 42.90773,
                "fullAddressString": "гр. БОТЕВГРАД [2140] ул. 17-ТИ НОЕМВРИ No 1",
                "siteAddressString": "гр. БОТЕВГРАД [2140]",
                "localAddressString": "ул. 17-ТИ НОЕМВРИ No 1"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:30",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 61,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:30",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-03-19",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 858,
            "name": "БУРГАС - ЦЕНТЪР, УЛ. ЛЕРМОНТОВ NO 36",
            "nameEn": "BURGAS - CENTER, UL. LERMONTOV № 36",
            "siteId": 7079,
            "address": {
                "countryId": 100,
                "siteId": 7079,
                "siteType": "ГР.",
                "siteName": "БУРГАС",
                "postCode": "8000",
                "streetId": 14454,
                "streetType": "УЛ.",
                "streetName": "ЛЕРМОНТОВ",
                "streetNo": "36",
                "addressNote": "ДО I-ВО РПУ",
                "x": 27.474318,
                "y": 42.494821,
                "fullAddressString": "гр. БУРГАС ул. ЛЕРМОНТОВ No 36 ДО I-ВО РПУ",
                "siteAddressString": "гр. БУРГАС",
                "localAddressString": "ул. ЛЕРМОНТОВ No 36 ДО I-ВО РПУ"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 200,
                "depth": 120
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 273,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-04-30",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 859,
            "name": "СОФИЯ - КОЗЛОДУЙ (УЛ.)",
            "nameEn": "SOFIA - KOZLODUY (UL.)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 1994,
                "streetType": "УЛ.",
                "streetName": "КОЗЛОДУЙ",
                "streetNo": "55",
                "x": 23.326122,
                "y": 42.707138,
                "fullAddressString": "гр. СОФИЯ ул. КОЗЛОДУЙ No 55",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. КОЗЛОДУЙ No 55"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 351,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-05-02",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 860,
            "name": "БУРГАС - МЕДЕН РУДНИК",
            "nameEn": "BURGAS - MEDEN RUDNIK",
            "siteId": 7079,
            "address": {
                "countryId": 100,
                "siteId": 7079,
                "siteType": "ГР.",
                "siteName": "БУРГАС",
                "postCode": "8000",
                "complexId": 706,
                "complexType": "ЖК",
                "complexName": "МЕДЕН РУДНИК",
                "blockNo": "333",
                "x": 27.412278,
                "y": 42.461055,
                "fullAddressString": "гр. БУРГАС жк МЕДЕН РУДНИК бл. 333",
                "siteAddressString": "гр. БУРГАС",
                "localAddressString": "жк МЕДЕН РУДНИК бл. 333"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 200,
                "depth": 200
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 319,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-04-23",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 861,
            "name": "ПЛОВДИВ - ЮНДОЛА",
            "nameEn": "PLOVDIV - YUNDOLA",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "streetId": 10790,
                "streetType": "УЛ.",
                "streetName": "ОРФЕЙ",
                "streetNo": "24",
                "x": 24.703944,
                "y": 42.128542,
                "fullAddressString": "гр. ПЛОВДИВ ул. ОРФЕЙ No 24",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "ул. ОРФЕЙ No 24"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 753,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-04-26",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 862,
            "name": "ПАЗАРДЖИК - ГЕНЕРАЛ ГУРКО",
            "nameEn": "PAZARDZHIK - GENERAL GURKO",
            "siteId": 55155,
            "address": {
                "countryId": 100,
                "siteId": 55155,
                "siteType": "ГР.",
                "siteName": "ПАЗАРДЖИК",
                "postCode": "4400",
                "streetId": 40123,
                "streetType": "УЛ.",
                "streetName": "ЗАВОЯ НА ЧЕРНА",
                "streetNo": "7",
                "x": 24.335656,
                "y": 42.188986,
                "fullAddressString": "гр. ПАЗАРДЖИК [4400] ул. ЗАВОЯ НА ЧЕРНА No 7",
                "siteAddressString": "гр. ПАЗАРДЖИК [4400]",
                "localAddressString": "ул. ЗАВОЯ НА ЧЕРНА No 7"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 18,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-04-20",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 863,
            "name": "ПАЗАРДЖИК - АЛЕКСАНДЪР СТАМБОЛИЙСКИ",
            "nameEn": "PAZARDZHIK - ALEKSANDAR STAMBOLIYSKI",
            "siteId": 55155,
            "address": {
                "countryId": 100,
                "siteId": 55155,
                "siteType": "ГР.",
                "siteName": "ПАЗАРДЖИК",
                "postCode": "4400",
                "streetId": 40009,
                "streetType": "БУЛ.",
                "streetName": "АЛЕКСАНДЪР СТАМБОЛИЙСКИ",
                "streetNo": "38",
                "x": 24.328811,
                "y": 42.195349,
                "fullAddressString": "гр. ПАЗАРДЖИК [4400] бул. АЛЕКСАНДЪР СТАМБОЛИЙСКИ No 38",
                "siteAddressString": "гр. ПАЗАРДЖИК [4400]",
                "localAddressString": "бул. АЛЕКСАНДЪР СТАМБОЛИЙСКИ No 38"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 18,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-04-20",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 864,
            "name": "АСЕНОВГРАД - ЗЪБЧЕТО",
            "nameEn": "ASENOVGRAD - ZABCHETO",
            "siteId": 702,
            "address": {
                "countryId": 100,
                "siteId": 702,
                "siteType": "ГР.",
                "siteName": "АСЕНОВГРАД",
                "postCode": "4230",
                "streetId": 65346,
                "streetType": "ПЛ.",
                "streetName": "АВКСЕНТИЙ ВЕЛЕШКИ",
                "streetNo": "7",
                "x": 24.883829,
                "y": 42.015552,
                "fullAddressString": "гр. АСЕНОВГРАД [4230] пл. АВКСЕНТИЙ ВЕЛЕШКИ No 7",
                "siteAddressString": "гр. АСЕНОВГРАД [4230]",
                "localAddressString": "пл. АВКСЕНТИЙ ВЕЛЕШКИ No 7"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 17,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-04-26",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 865,
            "name": "КЪРДЖАЛИ - ВЪЗРОЖДЕНЦИ",
            "nameEn": "KARDZHALI - VAZROZHDENTSI",
            "siteId": 40909,
            "address": {
                "countryId": 100,
                "siteId": 40909,
                "siteType": "ГР.",
                "siteName": "КЪРДЖАЛИ",
                "postCode": "6600",
                "streetId": 56229,
                "streetType": "БУЛ.",
                "streetName": "ХРИСТО БОТЕВ",
                "streetNo": "87",
                "x": 25.353942,
                "y": 41.639298,
                "fullAddressString": "гр. КЪРДЖАЛИ [6600] бул. ХРИСТО БОТЕВ No 87",
                "siteAddressString": "гр. КЪРДЖАЛИ [6600]",
                "localAddressString": "бул. ХРИСТО БОТЕВ No 87"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 32,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-04-13",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 867,
            "name": "ПЛОВДИВ - МАРИЯ ЛУИЗА",
            "nameEn": "PLOVDIV - MARIA LUIZA",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "streetId": 10309,
                "streetType": "УЛ.",
                "streetName": "ГЕО МИЛЕВ",
                "streetNo": "10",
                "x": 24.757226,
                "y": 42.144883,
                "fullAddressString": "гр. ПЛОВДИВ ул. ГЕО МИЛЕВ No 10",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "ул. ГЕО МИЛЕВ No 10"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 792,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-05-02",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 868,
            "name": "РУСЕ - ЦЕНТЪР, НОВАТА ПОЩА",
            "nameEn": "RUSE - TSENTUR,NEW POST",
            "siteId": 63427,
            "address": {
                "countryId": 100,
                "siteId": 63427,
                "siteType": "ГР.",
                "siteName": "РУСЕ",
                "postCode": "7000",
                "streetId": 16201,
                "streetType": "УЛ.",
                "streetName": "ДУХОВНО ВЪЗРАЖДАНЕ",
                "streetNo": "3",
                "x": 25.950194,
                "y": 43.849719,
                "fullAddressString": "гр. РУСЕ ул. ДУХОВНО ВЪЗРАЖДАНЕ No 3",
                "siteAddressString": "гр. РУСЕ",
                "localAddressString": "ул. ДУХОВНО ВЪЗРАЖДАНЕ No 3"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 776,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-04-16",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 869,
            "name": "СОФИЯ - ЦАР САМУИЛ",
            "nameEn": "SOFIA - TSAR SAMUIL",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 1096,
                "streetType": "УЛ.",
                "streetName": "БИСТРИЦА",
                "streetNo": "9",
                "x": 23.3167,
                "y": 42.69408,
                "fullAddressString": "гр. СОФИЯ ул. БИСТРИЦА No 9",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. БИСТРИЦА No 9"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 452,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-09-03",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 870,
            "name": "СОФИЯ - ЗОНА Б-18",
            "nameEn": "SOFIA - ZONA B-18",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "complexId": 315,
                "complexType": "ЖК",
                "complexName": "ЗОНА Б-18",
                "blockNo": "8",
                "x": 23.303402,
                "y": 42.704173,
                "fullAddressString": "гр. СОФИЯ жк ЗОНА Б-18 бл. 8",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк ЗОНА Б-18 бл. 8"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 110,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-05-02",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 871,
            "name": "ВАРНА - ГРАДСКА БОЛНИЦА",
            "nameEn": "VARNA - GRADSKA BOLNITSA",
            "siteId": 10135,
            "address": {
                "countryId": 100,
                "siteId": 10135,
                "siteType": "ГР.",
                "siteName": "ВАРНА",
                "postCode": "9000",
                "streetId": 12787,
                "streetType": "УЛ.",
                "streetName": "ХРИСТО ПОПОВИЧ",
                "streetNo": "3",
                "addressNote": "ДО ГРАДСКА БОЛНИЦА",
                "x": 27.908556,
                "y": 43.20631,
                "fullAddressString": "гр. ВАРНА ул. ХРИСТО ПОПОВИЧ No 3 ДО ГРАДСКА БОЛНИЦА",
                "siteAddressString": "гр. ВАРНА",
                "localAddressString": "ул. ХРИСТО ПОПОВИЧ No 3 ДО ГРАДСКА БОЛНИЦА"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 220
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 662,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-07-02",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 873,
            "name": "СОФИЯ - ГЕН. ГУРКО (УЛ.)",
            "nameEn": "SOFIA - GEN. GURKO (UL.)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 1397,
                "streetType": "УЛ.",
                "streetName": "ГЕН. ГУРКО",
                "streetNo": "19",
                "x": 23.327399,
                "y": 42.692367,
                "fullAddressString": "гр. СОФИЯ ул. ГЕН. ГУРКО No 19",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. ГЕН. ГУРКО No 19"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:30",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 279,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-05-14",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": false
        },
        {
            "id": 874,
            "name": "ГАБРОВО - ЕСА",
            "nameEn": "GABROVO - ESA",
            "siteId": 14218,
            "address": {
                "countryId": 100,
                "siteId": 14218,
                "siteType": "ГР.",
                "siteName": "ГАБРОВО",
                "postCode": "5300",
                "streetId": 48004,
                "streetType": "БУЛ.",
                "streetName": "МОГИЛЬОВ",
                "streetNo": "28",
                "x": 25.313498,
                "y": 42.886019,
                "fullAddressString": "гр. ГАБРОВО [5300] бул. МОГИЛЬОВ No 28",
                "siteAddressString": "гр. ГАБРОВО [5300]",
                "localAddressString": "бул. МОГИЛЬОВ No 28"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 405,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-06-11",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 876,
            "name": "СОФИЯ - ПАВЛОВО",
            "nameEn": "SOFIA - PAVLOVO",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 1538,
                "streetType": "УЛ.",
                "streetName": "ДЕСПОТ СЛАВ",
                "streetNo": "9",
                "x": 23.26238,
                "y": 42.664811,
                "fullAddressString": "гр. СОФИЯ ул. ДЕСПОТ СЛАВ No 9",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. ДЕСПОТ СЛАВ No 9"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 322,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-07-16",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 877,
            "name": "СОФИЯ - БОРОВО",
            "nameEn": "SOFIA - BOROVO",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 47,
                "streetType": "БУЛ.",
                "streetName": "ГОЦЕ ДЕЛЧЕВ",
                "streetNo": "56",
                "blockNo": "15",
                "entranceNo": "А",
                "x": 23.290941,
                "y": 42.674053,
                "fullAddressString": "гр. СОФИЯ бул. ГОЦЕ ДЕЛЧЕВ No 56 бл. 15 вх. А",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "бул. ГОЦЕ ДЕЛЧЕВ No 56 бл. 15 вх. А"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "19:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 342,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "19:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-07-16",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 878,
            "name": "СЛЪНЧЕВ БРЯГ 2",
            "nameEn": "SUNNY BEACH 2",
            "siteId": 98212,
            "address": {
                "countryId": 100,
                "siteId": 98212,
                "siteType": "К.К.",
                "siteName": "СЛЪНЧЕВ БРЯГ",
                "postCode": "8240",
                "streetNo": "101",
                "complexId": 3825,
                "complexType": "КВ.",
                "complexName": "КАМЕЛИЯ",
                "addressNote": "СГРАДА ТАРАНТУЛА",
                "x": 27.703882,
                "y": 42.689048,
                "fullAddressString": "к.к. СЛЪНЧЕВ БРЯГ [8240] кв. КАМЕЛИЯ No 101 СГРАДА ТАРАНТУЛА",
                "siteAddressString": "к.к. СЛЪНЧЕВ БРЯГ [8240]",
                "localAddressString": "кв. КАМЕЛИЯ No 101 СГРАДА ТАРАНТУЛА"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 130,
                "height": 200,
                "depth": 300
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 453,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-06-04",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": false
        },
        {
            "id": 879,
            "name": "АПРИЛЦИ",
            "nameEn": "APRILTSI",
            "siteId": 52218,
            "address": {
                "countryId": 100,
                "siteId": 52218,
                "siteType": "ГР.",
                "siteName": "АПРИЛЦИ",
                "postCode": "5641",
                "streetId": 81976,
                "streetType": "УЛ.",
                "streetName": "ВАСИЛ ЛЕВСКИ",
                "streetNo": "222",
                "addressNote": "ХИПЕРМАРКЕТ ЕРМИ",
                "x": 24.924202,
                "y": 42.832084,
                "fullAddressString": "гр. АПРИЛЦИ [5641] ул. ВАСИЛ ЛЕВСКИ No 222 ХИПЕРМАРКЕТ ЕРМИ",
                "siteAddressString": "гр. АПРИЛЦИ [5641]",
                "localAddressString": "ул. ВАСИЛ ЛЕВСКИ No 222 ХИПЕРМАРКЕТ ЕРМИ"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "17:30",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "15:00",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 44,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "15:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "15:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "15:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "15:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "15:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "15:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "15:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "17:30",
                    "sameDayDepartureCutoff": "15:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-06-04",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 880,
            "name": "ГОДЕЧ",
            "nameEn": "GODECH",
            "siteId": 15309,
            "address": {
                "countryId": 100,
                "siteId": 15309,
                "siteType": "ГР.",
                "siteName": "ГОДЕЧ",
                "postCode": "2240",
                "streetId": 112309,
                "streetType": "ПЛ.",
                "streetName": "СВОБОДА",
                "addressNote": "ПАВИЛИОН 4",
                "x": 23.047176,
                "y": 43.015925,
                "fullAddressString": "гр. ГОДЕЧ [2240] пл. СВОБОДА ПАВИЛИОН 4",
                "siteAddressString": "гр. ГОДЕЧ [2240]",
                "localAddressString": "пл. СВОБОДА ПАВИЛИОН 4"
            },
            "workingTimeFrom": "10:30",
            "workingTimeTo": "15:00",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "15:00",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 142,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "10:30",
                    "workingTimeTo": "15:00",
                    "sameDayDepartureCutoff": "15:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "10:30",
                    "workingTimeTo": "15:00",
                    "sameDayDepartureCutoff": "15:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "10:30",
                    "workingTimeTo": "15:00",
                    "sameDayDepartureCutoff": "15:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "10:30",
                    "workingTimeTo": "15:00",
                    "sameDayDepartureCutoff": "15:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "10:30",
                    "workingTimeTo": "15:00",
                    "sameDayDepartureCutoff": "15:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "10:30",
                    "workingTimeTo": "15:00",
                    "sameDayDepartureCutoff": "15:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "10:30",
                    "workingTimeTo": "15:00",
                    "sameDayDepartureCutoff": "15:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "10:30",
                    "workingTimeTo": "15:00",
                    "sameDayDepartureCutoff": "15:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-06-21",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": false
        },
        {
            "id": 881,
            "name": "АКСАКОВО - ПАЗАР АКСАКОВО",
            "nameEn": "AKSAKOVO - AKSAKOVO MARKET",
            "siteId": 182,
            "address": {
                "countryId": 100,
                "siteId": 182,
                "siteType": "ГР.",
                "siteName": "АКСАКОВО",
                "postCode": "9154",
                "addressNote": "УЛ. КАП. ПЕТКО ВОЙВОДА, ПАЗАР АКСАКОВО М-Н 3",
                "x": 27.817683,
                "y": 43.257721,
                "fullAddressString": "гр. АКСАКОВО [9154] УЛ. КАП. ПЕТКО ВОЙВОДА, ПАЗАР АКСАКОВО М-Н 3",
                "siteAddressString": "гр. АКСАКОВО [9154]",
                "localAddressString": "УЛ. КАП. ПЕТКО ВОЙВОДА, ПАЗАР АКСАКОВО М-Н 3"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 220
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 328,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-09-10",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 882,
            "name": "ХАСКОВО - БЪЛГАРИЯ (БУЛ.), М-Н КИЛЕРА",
            "nameEn": "HASKOVO - BULGARIA (BUL.), M-N KILERA",
            "siteId": 77195,
            "address": {
                "countryId": 100,
                "siteId": 77195,
                "siteType": "ГР.",
                "siteName": "ХАСКОВО",
                "postCode": "6300",
                "streetId": 28074,
                "streetType": "БУЛ.",
                "streetName": "БЪЛГАРИЯ",
                "streetNo": "164",
                "x": 25.561666,
                "y": 41.928767,
                "fullAddressString": "гр. ХАСКОВО [6300] бул. БЪЛГАРИЯ No 164",
                "siteAddressString": "гр. ХАСКОВО [6300]",
                "localAddressString": "бул. БЪЛГАРИЯ No 164"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 82,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-08-06",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 883,
            "name": "ПЛОВДИВ - ВХВП",
            "nameEn": "PLOVDIV - VHVP",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "streetId": 11204,
                "streetType": "УЛ.",
                "streetName": "ЯНКО САКЪЗОВ",
                "streetNo": "11",
                "x": 24.737649,
                "y": 42.151,
                "fullAddressString": "гр. ПЛОВДИВ ул. ЯНКО САКЪЗОВ No 11",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "ул. ЯНКО САКЪЗОВ No 11"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 337,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-08-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL",
                "TYRE"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 884,
            "name": "ВАРНА - ЦАРИБРОД  (УЛ.)",
            "nameEn": "VARNA - TSARIBROD (UL.)",
            "siteId": 10135,
            "address": {
                "countryId": 100,
                "siteId": 10135,
                "siteType": "ГР.",
                "siteName": "ВАРНА",
                "postCode": "9000",
                "streetId": 12807,
                "streetType": "УЛ.",
                "streetName": "ЦАРИБРОД",
                "streetNo": "48",
                "x": 27.91133,
                "y": 43.199666,
                "fullAddressString": "гр. ВАРНА ул. ЦАРИБРОД No 48",
                "siteAddressString": "гр. ВАРНА",
                "localAddressString": "ул. ЦАРИБРОД No 48"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 220
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 746,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-09-10",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 885,
            "name": "ВАРНА - ЦВЕТЕН КВАРТАЛ",
            "nameEn": "VARNA - TSVETEN KVARTAL",
            "siteId": 10135,
            "address": {
                "countryId": 100,
                "siteId": 10135,
                "siteType": "ГР.",
                "siteName": "ВАРНА",
                "postCode": "9000",
                "streetId": 12641,
                "streetType": "УЛ.",
                "streetName": "РОЗА",
                "blockNo": "37",
                "addressNote": "ДО ВХ. Б",
                "x": 27.913395,
                "y": 43.224301,
                "fullAddressString": "гр. ВАРНА ул. РОЗА бл. 37 ДО ВХ. Б",
                "siteAddressString": "гр. ВАРНА",
                "localAddressString": "ул. РОЗА бл. 37 ДО ВХ. Б"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 220
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 832,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-09-10",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 886,
            "name": "СОФИЯ - ДЪРВЕНИЦА",
            "nameEn": "SOFIA - DARVENITSA",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 106,
                "streetType": "БУЛ.",
                "streetName": "СВ. КЛИМЕНТ ОХРИДСКИ",
                "streetNo": "23",
                "complexId": 16,
                "complexType": "ЖК",
                "complexName": "ДЪРВЕНИЦА",
                "x": 23.359331,
                "y": 42.651197,
                "fullAddressString": "гр. СОФИЯ жк ДЪРВЕНИЦА бул. СВ. КЛИМЕНТ ОХРИДСКИ No 23",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк ДЪРВЕНИЦА бул. СВ. КЛИМЕНТ ОХРИДСКИ No 23"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "19:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 80
            },
            "maxParcelWeight": 32.0,
            "type": "OFFICE",
            "nearbyOfficeId": 163,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-07-09",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 888,
            "name": "ПРОВАДИЯ",
            "nameEn": "PROVADIA",
            "siteId": 58503,
            "address": {
                "countryId": 100,
                "siteId": 58503,
                "siteType": "ГР.",
                "siteName": "ПРОВАДИЯ",
                "postCode": "9200",
                "streetId": 93751,
                "streetType": "УЛ.",
                "streetName": "ЦАР ОСВОБОДИТЕЛ",
                "streetNo": "35",
                "x": 27.442549,
                "y": 43.175647,
                "fullAddressString": "гр. ПРОВАДИЯ [9200] ул. ЦАР ОСВОБОДИТЕЛ No 35",
                "siteAddressString": "гр. ПРОВАДИЯ [9200]",
                "localAddressString": "ул. ЦАР ОСВОБОДИТЕЛ No 35"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "17:00",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "13:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 80,
                "height": 180,
                "depth": 220
            },
            "maxParcelWeight": 50.0,
            "type": "OFFICE",
            "nearbyOfficeId": 204,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "13:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "17:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-09-17",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": true,
            "cardPaymentAllowed": true
        },
        {
            "id": 9001,
            "name": "СОФИЯ - МЕТРО 1 (АВТОМАТ)",
            "nameEn": "SOFIA - METRO 1 (AUTOMAT)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 131,
                "streetType": "БУЛ.",
                "streetName": "ЦАРИГРАДСКО ШОСЕ",
                "streetNo": "11А",
                "x": 23.393025,
                "y": 42.646909,
                "fullAddressString": "гр. СОФИЯ бул. ЦАРИГРАДСКО ШОСЕ No 11А",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "бул. ЦАРИГРАДСКО ШОСЕ No 11А"
            },
            "workingTimeFrom": "00:00",
            "workingTimeTo": "23:59",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "23:59",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "23:59",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 2,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-08-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9002,
            "name": "СОФИЯ - ХИТ 1 МЛАДОСТ (АВТОМАТ)",
            "nameEn": "SOFIA - HIT 1 MLADOST (AUTOMAT)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 4,
                "streetType": "БУЛ.",
                "streetName": "АЛЕКСАНДЪР МАЛИНОВ",
                "streetNo": "75",
                "x": 23.371885,
                "y": 42.638416,
                "fullAddressString": "гр. СОФИЯ бул. АЛЕКСАНДЪР МАЛИНОВ No 75",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "бул. АЛЕКСАНДЪР МАЛИНОВ No 75"
            },
            "workingTimeFrom": "08:00",
            "workingTimeTo": "22:00",
            "workingTimeHalfFrom": "08:00",
            "workingTimeHalfTo": "22:00",
            "workingTimeDayOffFrom": "08:00",
            "workingTimeDayOffTo": "22:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 175,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-08-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9003,
            "name": "СОФИЯ - ХИТ 2 ЛЮЛИН (АВТОМАТ)",
            "nameEn": "SOFIA - HIT 2 LYULIN (AUTOMAT)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 2439,
                "streetType": "УЛ.",
                "streetName": "ОБЕЛСКО ШОСЕ",
                "streetNo": "20",
                "x": 23.268118,
                "y": 42.730348,
                "fullAddressString": "гр. СОФИЯ ул. ОБЕЛСКО ШОСЕ No 20",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. ОБЕЛСКО ШОСЕ No 20"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "21:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "21:00",
            "workingTimeDayOffFrom": "09:00",
            "workingTimeDayOffTo": "21:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 282,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-08-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9004,
            "name": "СОФИЯ - ФАНТАСТИКО МЛАДОСТ 4 (АВТОМАТ)",
            "nameEn": "SOFIA - FANTASTICO MLADOST 4 (AUTOMAT)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "complexId": 51,
                "complexType": "ЖК",
                "complexName": "МЛАДОСТ 4",
                "addressNote": "ДО БЛ. 405",
                "x": 23.377255,
                "y": 42.635791,
                "fullAddressString": "гр. СОФИЯ жк МЛАДОСТ 4 ДО БЛ. 405",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк МЛАДОСТ 4 ДО БЛ. 405"
            },
            "workingTimeFrom": "07:30",
            "workingTimeTo": "22:00",
            "workingTimeHalfFrom": "07:30",
            "workingTimeHalfTo": "22:00",
            "workingTimeDayOffFrom": "07:30",
            "workingTimeDayOffTo": "22:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 175,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-08-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9005,
            "name": "КЮСТЕНДИЛ - ФАНТАСТИКО (АВТОМАТ)",
            "nameEn": "KYUSTENDIL - FANTASTICO (AUTOMAT)",
            "siteId": 41112,
            "address": {
                "countryId": 100,
                "siteId": 41112,
                "siteType": "ГР.",
                "siteName": "КЮСТЕНДИЛ",
                "postCode": "2500",
                "streetId": 54314,
                "streetType": "УЛ.",
                "streetName": "ЦАР ОСВОБОДИТЕЛ",
                "streetNo": "231",
                "x": 22.70049,
                "y": 42.28353,
                "fullAddressString": "гр. КЮСТЕНДИЛ [2500] ул. ЦАР ОСВОБОДИТЕЛ No 231",
                "siteAddressString": "гр. КЮСТЕНДИЛ [2500]",
                "localAddressString": "ул. ЦАР ОСВОБОДИТЕЛ No 231"
            },
            "workingTimeFrom": "07:30",
            "workingTimeTo": "22:00",
            "workingTimeHalfFrom": "07:30",
            "workingTimeHalfTo": "22:00",
            "workingTimeDayOffFrom": "07:30",
            "workingTimeDayOffTo": "22:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 23,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-05-18",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9006,
            "name": "СОФИЯ - ФАНТАСТИКО ЛЮЛИН ЦЕНТЪР (АВТОМАТ)",
            "nameEn": "SOFIA - FANTASTICO LYULIN CENTER (AUTOMAT)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 48,
                "streetType": "БУЛ.",
                "streetName": "ДЖАВАХАРЛАЛ НЕРУ",
                "complexId": 170,
                "complexType": "ЖК",
                "complexName": "ЛЮЛИН",
                "x": 23.25487,
                "y": 42.714756,
                "fullAddressString": "гр. СОФИЯ жк ЛЮЛИН бул. ДЖАВАХАРЛАЛ НЕРУ",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк ЛЮЛИН бул. ДЖАВАХАРЛАЛ НЕРУ"
            },
            "workingTimeFrom": "07:30",
            "workingTimeTo": "22:00",
            "workingTimeHalfFrom": "07:30",
            "workingTimeHalfTo": "22:00",
            "workingTimeDayOffFrom": "07:30",
            "workingTimeDayOffTo": "22:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 760,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-08-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9007,
            "name": "СОФИЯ - ФАНТАСТИКО СТУДЕНТСКИ ГРАД (АВТОМАТ)",
            "nameEn": "SOFIA - FANTASTICO STUDENTSKI GRAD (AUTOMAT)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 922,
                "streetType": "УЛ.",
                "streetName": "АКАД. БОРИС СТЕФАНОВ",
                "streetNo": "12",
                "complexId": 327,
                "complexType": "ЖК",
                "complexName": "СТУДЕНТСКИ ГРАД",
                "x": 23.34136,
                "y": 42.650263,
                "fullAddressString": "гр. СОФИЯ жк СТУДЕНТСКИ ГРАД ул. АКАД. БОРИС СТЕФАНОВ No 12",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк СТУДЕНТСКИ ГРАД ул. АКАД. БОРИС СТЕФАНОВ No 12"
            },
            "workingTimeFrom": "07:30",
            "workingTimeTo": "23:59",
            "workingTimeHalfFrom": "07:30",
            "workingTimeHalfTo": "23:59",
            "workingTimeDayOffFrom": "07:30",
            "workingTimeDayOffTo": "23:59",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 206,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-08-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9008,
            "name": "СОФИЯ - ФАНТАСТИКО ДРУЖБА (АВТОМАТ)",
            "nameEn": "SOFIA - FANTASTICO DRUZHBA (AUTOMAT)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 3039,
                "streetType": "УЛ.",
                "streetName": "ТИРАНА",
                "streetNo": "3",
                "complexId": 169,
                "complexType": "ЖК",
                "complexName": "ДРУЖБА",
                "addressNote": "ДО БЛ. 2",
                "x": 23.392642,
                "y": 42.664898,
                "fullAddressString": "гр. СОФИЯ жк ДРУЖБА ул. ТИРАНА No 3 ДО БЛ. 2",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк ДРУЖБА ул. ТИРАНА No 3 ДО БЛ. 2"
            },
            "workingTimeFrom": "07:30",
            "workingTimeTo": "22:00",
            "workingTimeHalfFrom": "07:30",
            "workingTimeHalfTo": "22:00",
            "workingTimeDayOffFrom": "07:30",
            "workingTimeDayOffTo": "22:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 565,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-08-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9009,
            "name": "СОФИЯ - ФАНТАСТИКО МЛАДОСТ 3 (АВТОМАТ)",
            "nameEn": "SOFIA - FANTASTICO MLADOST 3 (APS)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 148,
                "streetType": "УЛ.",
                "streetName": "102",
                "complexId": 50,
                "complexType": "ЖК",
                "complexName": "МЛАДОСТ 3",
                "addressNote": "С/У БЛ. 321",
                "x": 23.375182,
                "y": 42.642083,
                "fullAddressString": "гр. СОФИЯ жк МЛАДОСТ 3 ул. 102 С/У БЛ. 321",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк МЛАДОСТ 3 ул. 102 С/У БЛ. 321"
            },
            "workingTimeFrom": "07:30",
            "workingTimeTo": "22:00",
            "workingTimeHalfFrom": "07:30",
            "workingTimeHalfTo": "22:00",
            "workingTimeDayOffFrom": "07:30",
            "workingTimeDayOffTo": "22:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 175,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-08-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9010,
            "name": "СОФИЯ - ФАНТАСТИКО СИМЕОНОВСКО ШОСЕ (АВТОМАТ)",
            "nameEn": "SOFIA - FANTASTICO SIMEONOVSKO SHOSE (AUTOMAT)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 108,
                "streetType": "БУЛ.",
                "streetName": "СИМЕОНОВСКО ШОСЕ",
                "streetNo": "87А",
                "x": 23.33528,
                "y": 42.640703,
                "fullAddressString": "гр. СОФИЯ бул. СИМЕОНОВСКО ШОСЕ No 87А",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "бул. СИМЕОНОВСКО ШОСЕ No 87А"
            },
            "workingTimeFrom": "07:30",
            "workingTimeTo": "23:00",
            "workingTimeHalfFrom": "07:30",
            "workingTimeHalfTo": "23:00",
            "workingTimeDayOffFrom": "07:30",
            "workingTimeDayOffTo": "23:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 225,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-08-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9011,
            "name": "СОФИЯ - ФАНТАСТИКО ДЪРВЕНИЦА (АВТОМАТ)",
            "nameEn": "SOFIA - FANTASTICO DARVENITSA (AUTOMAT)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 106,
                "streetType": "БУЛ.",
                "streetName": "СВ. КЛИМЕНТ ОХРИДСКИ",
                "streetNo": "15",
                "complexId": 16,
                "complexType": "ЖК",
                "complexName": "ДЪРВЕНИЦА",
                "x": 23.360516,
                "y": 42.653772,
                "fullAddressString": "гр. СОФИЯ жк ДЪРВЕНИЦА бул. СВ. КЛИМЕНТ ОХРИДСКИ No 15",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк ДЪРВЕНИЦА бул. СВ. КЛИМЕНТ ОХРИДСКИ No 15"
            },
            "workingTimeFrom": "07:30",
            "workingTimeTo": "23:00",
            "workingTimeHalfFrom": "07:30",
            "workingTimeHalfTo": "23:00",
            "workingTimeDayOffFrom": "07:30",
            "workingTimeDayOffTo": "23:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 886,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-08-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9012,
            "name": "СОФИЯ - БИЛЛА БУЛ. БЪЛГАРИЯ (АВТОМАТ)",
            "nameEn": "SOFIA - BILLA BUL. BULGARIA (AUTOMAT)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 18,
                "streetType": "БУЛ.",
                "streetName": "БЪЛГАРИЯ",
                "streetNo": "55",
                "x": 23.289526,
                "y": 42.66534,
                "fullAddressString": "гр. СОФИЯ бул. БЪЛГАРИЯ No 55",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "бул. БЪЛГАРИЯ No 55"
            },
            "workingTimeFrom": "07:30",
            "workingTimeTo": "23:00",
            "workingTimeHalfFrom": "07:30",
            "workingTimeHalfTo": "23:00",
            "workingTimeDayOffFrom": "07:30",
            "workingTimeDayOffTo": "23:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 342,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2017-06-20",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9013,
            "name": "СОФИЯ - СЕРДИКА ЦЕНТЪР (АВТОМАТ)",
            "nameEn": "SOFIA - SERDIKA CENTER (AUTOMAT)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 109,
                "streetType": "БУЛ.",
                "streetName": "СИТНЯКОВО",
                "streetNo": "48",
                "floorNo": "-2",
                "x": 23.353791,
                "y": 42.69124,
                "fullAddressString": "гр. СОФИЯ бул. СИТНЯКОВО No 48 ет. -2",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "бул. СИТНЯКОВО No 48 ет. -2"
            },
            "workingTimeFrom": "10:00",
            "workingTimeTo": "22:00",
            "workingTimeHalfFrom": "10:00",
            "workingTimeHalfTo": "22:00",
            "workingTimeDayOffFrom": "10:00",
            "workingTimeDayOffTo": "22:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 326,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-08-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9014,
            "name": "СОФИЯ - МЕГА МОЛ (АВТОМАТ)",
            "nameEn": "SOFIA - MEGA MALL (AUTOMAT)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 4945,
                "streetType": "БУЛ.",
                "streetName": "ЦАРИЦА ЙОАННА",
                "streetNo": "15",
                "x": 23.270623,
                "y": 42.710602,
                "fullAddressString": "гр. СОФИЯ бул. ЦАРИЦА ЙОАННА No 15",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "бул. ЦАРИЦА ЙОАННА No 15"
            },
            "workingTimeFrom": "10:00",
            "workingTimeTo": "22:00",
            "workingTimeHalfFrom": "10:00",
            "workingTimeHalfTo": "22:00",
            "workingTimeDayOffFrom": "10:00",
            "workingTimeDayOffTo": "22:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 734,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-08-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9015,
            "name": "СОФИЯ - КАПИТАЛ ФОРТ (АВТОМАТ)",
            "nameEn": "SOFIA - CAPITAL FORT (AUTOMAT)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 131,
                "streetType": "БУЛ.",
                "streetName": "ЦАРИГРАДСКО ШОСЕ",
                "streetNo": "90",
                "floorNo": "-2",
                "addressNote": "ДО ЕСКАЛАТОРИТЕ",
                "x": 23.395928,
                "y": 42.646997,
                "fullAddressString": "гр. СОФИЯ бул. ЦАРИГРАДСКО ШОСЕ No 90 ет. -2 ДО ЕСКАЛАТОРИТЕ",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "бул. ЦАРИГРАДСКО ШОСЕ No 90 ет. -2 ДО ЕСКАЛАТОРИТЕ"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "00:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "00:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 2,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "00:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-07-18",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9016,
            "name": "ПЛОВДИВ - ЛЕКСИ ПЕЩЕРСКО ШОСЕ (АВТОМАТ)",
            "nameEn": "PLOVDIV - LEKSI PESHTERSKO SHOSE (AUTOMAT)",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "streetId": 10027,
                "streetType": "БУЛ.",
                "streetName": "ПЕЩЕРСКО ШОСЕ",
                "streetNo": "78",
                "x": 24.709985,
                "y": 42.134692,
                "fullAddressString": "гр. ПЛОВДИВ бул. ПЕЩЕРСКО ШОСЕ No 78",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "бул. ПЕЩЕРСКО ШОСЕ No 78"
            },
            "workingTimeFrom": "08:00",
            "workingTimeTo": "21:30",
            "workingTimeHalfFrom": "08:00",
            "workingTimeHalfTo": "21:30",
            "workingTimeDayOffFrom": "08:00",
            "workingTimeDayOffTo": "21:30",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 753,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-06-18",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9017,
            "name": "ПЛОВДИВ - ЛЕКСИ КЪРШИЯКА (АВТОМАТ)",
            "nameEn": "PLOVDIV - LEKSI KARSHIYAKA (AUTOMAT)",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "streetId": 10021,
                "streetType": "БУЛ.",
                "streetName": "МАРИЦА",
                "streetNo": "19",
                "x": 24.729731,
                "y": 42.154898,
                "fullAddressString": "гр. ПЛОВДИВ бул. МАРИЦА No 19",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "бул. МАРИЦА No 19"
            },
            "workingTimeFrom": "08:00",
            "workingTimeTo": "21:30",
            "workingTimeHalfFrom": "08:00",
            "workingTimeHalfTo": "21:30",
            "workingTimeDayOffFrom": "08:00",
            "workingTimeDayOffTo": "21:30",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 238,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-08-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9018,
            "name": "ПЛОВДИВ - ЛЕКСИ КАП. РАЙЧО (АВТОМАТ)",
            "nameEn": "PLOVDIV - LEKSI KAP. RAYCHO (AUTOMAT)",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "streetId": 10548,
                "streetType": "УЛ.",
                "streetName": "КАП. РАЙЧО",
                "streetNo": "56",
                "x": 24.75663,
                "y": 42.142101,
                "fullAddressString": "гр. ПЛОВДИВ ул. КАП. РАЙЧО No 56",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "ул. КАП. РАЙЧО No 56"
            },
            "workingTimeFrom": "08:00",
            "workingTimeTo": "21:30",
            "workingTimeHalfFrom": "08:00",
            "workingTimeHalfTo": "21:30",
            "workingTimeDayOffFrom": "08:00",
            "workingTimeDayOffTo": "21:30",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 867,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-08-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9019,
            "name": "ПЛОВДИВ - МЕТРО 1 (АВТОМАТ)",
            "nameEn": "PLOVDIV - METRO 1 (AUTOMAT)",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "streetId": 10029,
                "streetType": "БУЛ.",
                "streetName": "САНКТ ПЕТЕРБУРГ",
                "streetNo": "135",
                "x": 24.782871,
                "y": 42.143288,
                "fullAddressString": "гр. ПЛОВДИВ бул. САНКТ ПЕТЕРБУРГ No 135",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "бул. САНКТ ПЕТЕРБУРГ No 135"
            },
            "workingTimeFrom": "06:00",
            "workingTimeTo": "23:59",
            "workingTimeHalfFrom": "06:00",
            "workingTimeHalfTo": "23:59",
            "workingTimeDayOffFrom": "06:00",
            "workingTimeDayOffTo": "23:59",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 276,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "06:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "06:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "06:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "06:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "06:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "06:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "06:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "06:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "06:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "06:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-08-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9020,
            "name": "БУРГАС - МЕТРО (АВТОМАТ)",
            "nameEn": "BURGAS - METRO (AUTOMAT)",
            "siteId": 7079,
            "address": {
                "countryId": 100,
                "siteId": 7079,
                "siteType": "ГР.",
                "siteName": "БУРГАС",
                "postCode": "8000",
                "streetId": 14297,
                "streetType": "БУЛ.",
                "streetName": "СТЕФАН СТАМБОЛОВ",
                "streetNo": "103",
                "x": 27.452842,
                "y": 42.529302,
                "fullAddressString": "гр. БУРГАС бул. СТЕФАН СТАМБОЛОВ No 103",
                "siteAddressString": "гр. БУРГАС",
                "localAddressString": "бул. СТЕФАН СТАМБОЛОВ No 103"
            },
            "workingTimeFrom": "06:00",
            "workingTimeTo": "23:59",
            "workingTimeHalfFrom": "06:00",
            "workingTimeHalfTo": "23:59",
            "workingTimeDayOffFrom": "06:00",
            "workingTimeDayOffTo": "23:59",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 814,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "06:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "06:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "06:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "06:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "06:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "06:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "06:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "06:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "06:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "06:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-05-04",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9021,
            "name": "БУРГАС - МОЛ ГАЛЕРИЯ (АВТОМАТ)",
            "nameEn": "BURGAS - MALL GALLERIA (AUTOMAT)",
            "siteId": 7079,
            "address": {
                "countryId": 100,
                "siteId": 7079,
                "siteType": "ГР.",
                "siteName": "БУРГАС",
                "postCode": "8000",
                "streetId": 14371,
                "streetType": "БУЛ.",
                "streetName": "ЯНКО КОМИТОВ",
                "streetNo": "6",
                "x": 27.453941,
                "y": 42.512288,
                "fullAddressString": "гр. БУРГАС бул. ЯНКО КОМИТОВ No 6",
                "siteAddressString": "гр. БУРГАС",
                "localAddressString": "бул. ЯНКО КОМИТОВ No 6"
            },
            "workingTimeFrom": "10:00",
            "workingTimeTo": "21:00",
            "workingTimeHalfFrom": "10:00",
            "workingTimeHalfTo": "21:00",
            "workingTimeDayOffFrom": "10:00",
            "workingTimeDayOffTo": "21:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 829,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-08-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9022,
            "name": "ВАРНА - МЕТРО (АВТОМАТ)",
            "nameEn": "VARNA - METRO (AUTOMAT)",
            "siteId": 10135,
            "address": {
                "countryId": 100,
                "siteId": 10135,
                "siteType": "ГР.",
                "siteName": "ВАРНА",
                "postCode": "9000",
                "addressNote": "ИНД. ЗОНА ПЛАНОВА",
                "x": 27.860966,
                "y": 43.232836,
                "fullAddressString": "гр. ВАРНА ИНД. ЗОНА ПЛАНОВА",
                "siteAddressString": "гр. ВАРНА",
                "localAddressString": "ИНД. ЗОНА ПЛАНОВА"
            },
            "workingTimeFrom": "06:00",
            "workingTimeTo": "23:59",
            "workingTimeHalfFrom": "06:00",
            "workingTimeHalfTo": "23:59",
            "workingTimeDayOffFrom": "06:00",
            "workingTimeDayOffTo": "23:59",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 111,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "06:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "06:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "06:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "06:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "06:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "06:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "06:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "06:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "06:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "06:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-08-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9023,
            "name": "ПЛОВДИВ - МОЛ ПЛАЗА (АВТОМАТ)",
            "nameEn": "PLOVDIV - PLAZA MALL (AUTOMAT)",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "streetId": 98035,
                "streetType": "УЛ.",
                "streetName": "Д-Р ГЕОРГИ СТРАНСКИ",
                "streetNo": "3",
                "x": 24.78126,
                "y": 42.145037,
                "fullAddressString": "гр. ПЛОВДИВ ул. Д-Р ГЕОРГИ СТРАНСКИ No 3",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "ул. Д-Р ГЕОРГИ СТРАНСКИ No 3"
            },
            "workingTimeFrom": "10:00",
            "workingTimeTo": "21:00",
            "workingTimeHalfFrom": "10:00",
            "workingTimeHalfTo": "21:00",
            "workingTimeDayOffFrom": "10:00",
            "workingTimeDayOffTo": "21:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 276,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-08-04",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9024,
            "name": "СТАРА ЗАГОРА - МОЛ ГАЛЕРИЯ (АВТОМАТ)",
            "nameEn": "STARA ZAGORA - MALL GALLERIA (AUTOMAT)",
            "siteId": 68850,
            "address": {
                "countryId": 100,
                "siteId": 68850,
                "siteType": "ГР.",
                "siteName": "СТАРА ЗАГОРА",
                "postCode": "6000",
                "streetId": 18336,
                "streetType": "УЛ.",
                "streetName": "ХАН АСПАРУХ",
                "streetNo": "30",
                "x": 25.632252,
                "y": 42.438146,
                "fullAddressString": "гр. СТАРА ЗАГОРА ул. ХАН АСПАРУХ No 30",
                "siteAddressString": "гр. СТАРА ЗАГОРА",
                "localAddressString": "ул. ХАН АСПАРУХ No 30"
            },
            "workingTimeFrom": "10:00",
            "workingTimeTo": "21:00",
            "workingTimeHalfFrom": "10:00",
            "workingTimeHalfTo": "21:00",
            "workingTimeDayOffFrom": "10:00",
            "workingTimeDayOffTo": "21:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 164,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-08-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9025,
            "name": "СОФИЯ - БИЛЛА ОПЪЛЧЕНСКА (АВТОМАТ)",
            "nameEn": "SOFIA - BILLA OPALCHENSKA (AUTOMAT)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 2461,
                "streetType": "УЛ.",
                "streetName": "ОПЪЛЧЕНСКА",
                "streetNo": "35",
                "x": 23.310724,
                "y": 42.704029,
                "fullAddressString": "гр. СОФИЯ ул. ОПЪЛЧЕНСКА No 35",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. ОПЪЛЧЕНСКА No 35"
            },
            "workingTimeFrom": "07:30",
            "workingTimeTo": "22:00",
            "workingTimeHalfFrom": "07:30",
            "workingTimeHalfTo": "22:00",
            "workingTimeDayOffFrom": "07:30",
            "workingTimeDayOffTo": "22:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 110,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-06-16",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9026,
            "name": "СОФИЯ - МОЛ ПАРАДАЙС (АВТОМАТ)",
            "nameEn": "SOFIA - MALL PARADISE (AUTOMAT)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 134,
                "streetType": "БУЛ.",
                "streetName": "ЧЕРНИ ВРЪХ",
                "streetNo": "100",
                "floorNo": "-2",
                "x": 23.315006,
                "y": 42.657875,
                "fullAddressString": "гр. СОФИЯ бул. ЧЕРНИ ВРЪХ No 100 ет. -2",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "бул. ЧЕРНИ ВРЪХ No 100 ет. -2"
            },
            "workingTimeFrom": "10:00",
            "workingTimeTo": "22:00",
            "workingTimeHalfFrom": "10:00",
            "workingTimeHalfTo": "22:00",
            "workingTimeDayOffFrom": "10:00",
            "workingTimeDayOffTo": "22:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 261,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-08-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9027,
            "name": "СОФИЯ - СКАЙ СИТИ (АВТОМАТ)",
            "nameEn": "SOFIA - SKY CITY (AUTOMAT)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 2037,
                "streetType": "УЛ.",
                "streetName": "КОСТА ЛУЛЧЕВ",
                "streetNo": "52",
                "x": 23.367585,
                "y": 42.679847,
                "fullAddressString": "гр. СОФИЯ ул. КОСТА ЛУЛЧЕВ No 52",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. КОСТА ЛУЛЧЕВ No 52"
            },
            "workingTimeFrom": "10:00",
            "workingTimeTo": "22:00",
            "workingTimeHalfFrom": "10:00",
            "workingTimeHalfTo": "22:00",
            "workingTimeDayOffFrom": "10:00",
            "workingTimeDayOffTo": "22:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 347,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-04-23",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9028,
            "name": "СОФИЯ - ФАНТАСТИКО КОПЕРНИК (АВТОМАТ)",
            "nameEn": "SOFIA - FANTASTICO KOPERNIK (AUTOMAT)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 2417,
                "streetType": "УЛ.",
                "streetName": "НИКОЛАЙ КОПЕРНИК",
                "streetNo": "27",
                "x": 23.36131,
                "y": 42.680552,
                "fullAddressString": "гр. СОФИЯ ул. НИКОЛАЙ КОПЕРНИК No 27",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. НИКОЛАЙ КОПЕРНИК No 27"
            },
            "workingTimeFrom": "07:30",
            "workingTimeTo": "23:59",
            "workingTimeHalfFrom": "07:30",
            "workingTimeHalfTo": "23:59",
            "workingTimeDayOffFrom": "07:30",
            "workingTimeDayOffTo": "23:59",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 347,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-12-15",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9029,
            "name": "ПАЗАРДЖИК - БИЛЛА ЛЮБЕН БОЯНОВ (АВТОМАТ)",
            "nameEn": "PAZARDZHIK - BILLA LYUBЕН BOYANOV (AUTOMAT)",
            "siteId": 55155,
            "address": {
                "countryId": 100,
                "siteId": 55155,
                "siteType": "ГР.",
                "siteName": "ПАЗАРДЖИК",
                "postCode": "4400",
                "streetId": 40179,
                "streetType": "УЛ.",
                "streetName": "ЛЮБЕН БОЯНОВ",
                "streetNo": "1",
                "x": 24.320222,
                "y": 42.186537,
                "fullAddressString": "гр. ПАЗАРДЖИК [4400] ул. ЛЮБЕН БОЯНОВ No 1",
                "siteAddressString": "гр. ПАЗАРДЖИК [4400]",
                "localAddressString": "ул. ЛЮБЕН БОЯНОВ No 1"
            },
            "workingTimeFrom": "07:30",
            "workingTimeTo": "22:00",
            "workingTimeHalfFrom": "07:30",
            "workingTimeHalfTo": "22:00",
            "workingTimeDayOffFrom": "07:30",
            "workingTimeDayOffTo": "22:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 18,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2017-07-05",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9030,
            "name": "ЛОВЕЧ - БИЛЛА СЪЙКО СЪЕВ (АВТОМАТ)",
            "nameEn": "LOVECH - BILLA SAYKO SAEV (AUTOMAT)",
            "siteId": 43952,
            "address": {
                "countryId": 100,
                "siteId": 43952,
                "siteType": "ГР.",
                "siteName": "ЛОВЕЧ",
                "postCode": "5500",
                "streetId": 32061,
                "streetType": "УЛ.",
                "streetName": "Д-Р СЪЙКО СЪЕВ",
                "streetNo": "30",
                "x": 24.709416,
                "y": 43.134768,
                "fullAddressString": "гр. ЛОВЕЧ [5500] ул. Д-Р СЪЙКО СЪЕВ No 30",
                "siteAddressString": "гр. ЛОВЕЧ [5500]",
                "localAddressString": "ул. Д-Р СЪЙКО СЪЕВ No 30"
            },
            "workingTimeFrom": "07:30",
            "workingTimeTo": "22:00",
            "workingTimeHalfFrom": "07:30",
            "workingTimeHalfTo": "22:00",
            "workingTimeDayOffFrom": "07:30",
            "workingTimeDayOffTo": "22:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 25,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-06-14",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9031,
            "name": "ПЛЕВЕН - БИЛЛА АСЕН ХАЛАЧЕВ (АВТОМАТ)",
            "nameEn": "PLEVEN - BILLA ASEN HALACHEV (AUTOMAT)",
            "siteId": 56722,
            "address": {
                "countryId": 100,
                "siteId": 56722,
                "siteType": "ГР.",
                "siteName": "ПЛЕВЕН",
                "postCode": "5800",
                "streetId": 20024,
                "streetType": "УЛ.",
                "streetName": "АСЕН ХАЛАЧЕВ",
                "streetNo": "2",
                "x": 24.612599,
                "y": 43.417836,
                "fullAddressString": "гр. ПЛЕВЕН ул. АСЕН ХАЛАЧЕВ No 2",
                "siteAddressString": "гр. ПЛЕВЕН",
                "localAddressString": "ул. АСЕН ХАЛАЧЕВ No 2"
            },
            "workingTimeFrom": "07:30",
            "workingTimeTo": "22:00",
            "workingTimeHalfFrom": "07:30",
            "workingTimeHalfTo": "22:00",
            "workingTimeDayOffFrom": "07:30",
            "workingTimeDayOffTo": "22:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 106,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-06-14",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9033,
            "name": "САМОКОВ - БИЛЛА (АВТОМАТ)",
            "nameEn": "SAMOKOV - BILLA (AUTOMAT)",
            "siteId": 65231,
            "address": {
                "countryId": 100,
                "siteId": 65231,
                "siteType": "ГР.",
                "siteName": "САМОКОВ",
                "postCode": "2000",
                "streetId": 71702,
                "streetType": "БУЛ.",
                "streetName": "ИСКЪР",
                "streetNo": "2",
                "x": 23.555288,
                "y": 42.334862,
                "fullAddressString": "гр. САМОКОВ [2000] бул. ИСКЪР No 2",
                "siteAddressString": "гр. САМОКОВ [2000]",
                "localAddressString": "бул. ИСКЪР No 2"
            },
            "workingTimeFrom": "07:30",
            "workingTimeTo": "22:00",
            "workingTimeHalfFrom": "07:30",
            "workingTimeHalfTo": "22:00",
            "workingTimeDayOffFrom": "07:30",
            "workingTimeDayOffTo": "22:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 71,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-08-30",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9034,
            "name": "СОФИЯ - СОМАТ (АВТОМАТ)",
            "nameEn": "SOFIA - SOMAT (AUTOMAT)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 4650,
                "streetType": "УЛ.",
                "streetName": "АБАГАР",
                "streetNo": "22",
                "addressNote": "СОФИЯ СИТИ ЛОДЖИСТИК ПАРК, АДМИНИСТРАТИВНА СГРАДА,ПАРТЕР",
                "x": 23.396255,
                "y": 42.64107,
                "fullAddressString": "гр. СОФИЯ ул. АБАГАР No 22 СОФИЯ СИТИ ЛОДЖИСТИК ПАРК, АДМИНИСТРАТИВНА СГРАДА,ПАРТЕР",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. АБАГАР No 22 СОФИЯ СИТИ ЛОДЖИСТИК ПАРК, АДМИНИСТРАТИВНА СГРАДА,ПАРТЕР"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 2,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2017-02-09",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9035,
            "name": "СОФИЯ - KILER.BG (АВТОМАТ)",
            "nameEn": "SOFIA - KILER.BG (AUTOMAT)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 4650,
                "streetType": "УЛ.",
                "streetName": "АБАГАР",
                "streetNo": "22",
                "addressNote": "СОФИЯ СИТИ ЛОДЖИСТИК ПАРК, СКЛАД 4",
                "x": 23.395997,
                "y": 42.642214,
                "fullAddressString": "гр. СОФИЯ ул. АБАГАР No 22 СОФИЯ СИТИ ЛОДЖИСТИК ПАРК, СКЛАД 4",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. АБАГАР No 22 СОФИЯ СИТИ ЛОДЖИСТИК ПАРК, СКЛАД 4"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "20:00",
            "workingTimeHalfFrom": "10:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 2,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-11-10",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9036,
            "name": "СОФИЯ - ТЕХНИЧЕСКИ УНИВЕРСИТЕТ (АВТОМАТ)",
            "nameEn": "SOFIA - TECHNICAL UNIVERSITY (AUTOMAT)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 106,
                "streetType": "БУЛ.",
                "streetName": "СВ. КЛИМЕНТ ОХРИДСКИ",
                "streetNo": "8",
                "x": 23.355543,
                "y": 42.656698,
                "fullAddressString": "гр. СОФИЯ бул. СВ. КЛИМЕНТ ОХРИДСКИ No 8",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "бул. СВ. КЛИМЕНТ ОХРИДСКИ No 8"
            },
            "workingTimeFrom": "08:00",
            "workingTimeTo": "20:00",
            "workingTimeHalfFrom": "08:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 163,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2017-02-23",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9037,
            "name": "СОФИЯ - ЦАРИГРАДСКИ КОМПЛЕКС (АВТОМАТ)",
            "nameEn": "SOFIA - TSARIGRADSKI KOMPLEKS (AUTOMAT)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "complexId": 15,
                "complexType": "ЖК",
                "complexName": "ДРУЖБА 2",
                "blockNo": "281",
                "entranceNo": "Б",
                "addressNote": "ПАРТЕР",
                "x": 23.40096,
                "y": 42.647289,
                "fullAddressString": "гр. СОФИЯ жк ДРУЖБА 2 бл. 281 вх. Б ПАРТЕР",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк ДРУЖБА 2 бл. 281 вх. Б ПАРТЕР"
            },
            "workingTimeFrom": "08:00",
            "workingTimeTo": "19:30",
            "workingTimeHalfFrom": "10:00",
            "workingTimeHalfTo": "15:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 161,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "19:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "19:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "19:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "15:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "19:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "19:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "19:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "19:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "19:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-04-15",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9038,
            "name": "ПЛОВДИВ - ТЕХНИЧЕСКИ УНИВЕРСИТЕТ (АВТОМАТ)",
            "nameEn": "PLOVDIV - TECHNICAL UNIVERSITY (AUTOMAT)",
            "siteId": 56784,
            "address": {
                "countryId": 100,
                "siteId": 56784,
                "siteType": "ГР.",
                "siteName": "ПЛОВДИВ",
                "postCode": "4000",
                "streetId": 11142,
                "streetType": "УЛ.",
                "streetName": "ЦАНКО ДЮСТАБАНОВ",
                "streetNo": "25",
                "x": 24.74903,
                "y": 42.138246,
                "fullAddressString": "гр. ПЛОВДИВ ул. ЦАНКО ДЮСТАБАНОВ No 25",
                "siteAddressString": "гр. ПЛОВДИВ",
                "localAddressString": "ул. ЦАНКО ДЮСТАБАНОВ No 25"
            },
            "workingTimeFrom": "08:00",
            "workingTimeTo": "18:00",
            "workingTimeHalfFrom": "08:00",
            "workingTimeHalfTo": "14:00",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "00:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 414,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "14:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "18:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2017-02-10",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9039,
            "name": "СОФИЯ - ФАНТАСТИКО ДРАГАЛЕВЦИ (АВТОМАТ)",
            "nameEn": "SOFIA - FANTASTICO DRAGALEVCI (AUTOMAT)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 134,
                "streetType": "БУЛ.",
                "streetName": "ЧЕРНИ ВРЪХ",
                "streetNo": "204",
                "x": 23.311496,
                "y": 42.640205,
                "fullAddressString": "гр. СОФИЯ бул. ЧЕРНИ ВРЪХ No 204",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "бул. ЧЕРНИ ВРЪХ No 204"
            },
            "workingTimeFrom": "07:30",
            "workingTimeTo": "23:59",
            "workingTimeHalfFrom": "07:30",
            "workingTimeHalfTo": "23:59",
            "workingTimeDayOffFrom": "07:30",
            "workingTimeDayOffTo": "23:59",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 75,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-03-07",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9040,
            "name": "СЛИВЕН - БИЛЛА БРАТЯ МИЛАДИНОВИ (АВТОМАТ)",
            "nameEn": "SLIVEN - BILLA BRATYA MILADINOVI (AUTOMAT)",
            "siteId": 67338,
            "address": {
                "countryId": 100,
                "siteId": 67338,
                "siteType": "ГР.",
                "siteName": "СЛИВЕН",
                "postCode": "8800",
                "streetId": 44087,
                "streetType": "УЛ.",
                "streetName": "БРАТЯ МИЛАДИНОВИ",
                "streetNo": "25",
                "x": 26.321264,
                "y": 42.67589,
                "fullAddressString": "гр. СЛИВЕН [8800] ул. БРАТЯ МИЛАДИНОВИ No 25",
                "siteAddressString": "гр. СЛИВЕН [8800]",
                "localAddressString": "ул. БРАТЯ МИЛАДИНОВИ No 25"
            },
            "workingTimeFrom": "07:30",
            "workingTimeTo": "22:00",
            "workingTimeHalfFrom": "07:30",
            "workingTimeHalfTo": "22:00",
            "workingTimeDayOffFrom": "07:30",
            "workingTimeDayOffTo": "22:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 92,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-12-05",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9041,
            "name": "СОФИЯ - ФАНТАСТИКО Г.М. ДИМИТРОВ (АВТОМАТ)",
            "nameEn": "SOFIA - FANTASTICO G.M. DIMITROV (AUTOMAT)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 52,
                "streetType": "БУЛ.",
                "streetName": "Д-Р Г. М. ДИМИТРОВ",
                "streetNo": "77",
                "x": 23.347053,
                "y": 42.658974,
                "fullAddressString": "гр. СОФИЯ бул. Д-Р Г. М. ДИМИТРОВ No 77",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "бул. Д-Р Г. М. ДИМИТРОВ No 77"
            },
            "workingTimeFrom": "07:30",
            "workingTimeTo": "23:00",
            "workingTimeHalfFrom": "07:30",
            "workingTimeHalfTo": "23:00",
            "workingTimeDayOffFrom": "07:30",
            "workingTimeDayOffTo": "23:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 772,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "23:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-12-13",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9042,
            "name": "РУСЕ - МОЛ (АВТОМАТ)",
            "nameEn": "RUSE - MALL (AUTOMAT)",
            "siteId": 63427,
            "address": {
                "countryId": 100,
                "siteId": 63427,
                "siteType": "ГР.",
                "siteName": "РУСЕ",
                "postCode": "7000",
                "streetId": 16317,
                "streetType": "БУЛ.",
                "streetName": "ЛИПНИК",
                "streetNo": "121Д",
                "x": 25.991696,
                "y": 43.852345,
                "fullAddressString": "гр. РУСЕ бул. ЛИПНИК No 121Д",
                "siteAddressString": "гр. РУСЕ",
                "localAddressString": "бул. ЛИПНИК No 121Д"
            },
            "workingTimeFrom": "08:30",
            "workingTimeTo": "21:30",
            "workingTimeHalfFrom": "08:30",
            "workingTimeHalfTo": "21:30",
            "workingTimeDayOffFrom": "08:30",
            "workingTimeDayOffTo": "21:30",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 849,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "08:30",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-06-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9043,
            "name": "СОФИЯ - РИНГ МОЛ (АВТОМАТ)",
            "nameEn": "SOFIA - RING MALL (AUTOMAT)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 4143,
                "streetType": "УЛ.",
                "streetName": "ОКОЛОВРЪСТЕН ПЪТ",
                "streetNo": "214",
                "addressNote": "НИВО -1",
                "x": 23.35246,
                "y": 42.62479,
                "fullAddressString": "гр. СОФИЯ ул. ОКОЛОВРЪСТЕН ПЪТ No 214 НИВО -1",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. ОКОЛОВРЪСТЕН ПЪТ No 214 НИВО -1"
            },
            "workingTimeFrom": "10:00",
            "workingTimeTo": "22:00",
            "workingTimeHalfFrom": "10:00",
            "workingTimeHalfTo": "22:00",
            "workingTimeDayOffFrom": "10:00",
            "workingTimeDayOffTo": "22:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 66,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-08-22",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9044,
            "name": "КАЗАНЛЪК - БИЛЛА БАЧО КИРО (АВТОМАТ)",
            "nameEn": "KAZANLAK - BILLA BACHO KIRO (AUTOMAT)",
            "siteId": 35167,
            "address": {
                "countryId": 100,
                "siteId": 35167,
                "siteType": "ГР.",
                "siteName": "КАЗАНЛЪК",
                "postCode": "6100",
                "streetId": 91877,
                "streetType": "УЛ.",
                "streetName": "БАЧО КИРО",
                "streetNo": "6",
                "x": 25.390391,
                "y": 42.614732,
                "fullAddressString": "гр. КАЗАНЛЪК [6100] ул. БАЧО КИРО No 6",
                "siteAddressString": "гр. КАЗАНЛЪК [6100]",
                "localAddressString": "ул. БАЧО КИРО No 6"
            },
            "workingTimeFrom": "07:30",
            "workingTimeTo": "22:00",
            "workingTimeHalfFrom": "07:30",
            "workingTimeHalfTo": "22:00",
            "workingTimeDayOffFrom": "07:30",
            "workingTimeDayOffTo": "22:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 151,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-07-05",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9045,
            "name": "ПЕРНИК - ФАНТАСТИКО (АВТОМАТ)",
            "nameEn": "PERNIK - FANTASTICO (AUTOMAT)",
            "siteId": 55871,
            "address": {
                "countryId": 100,
                "siteId": 55871,
                "siteType": "ГР.",
                "siteName": "ПЕРНИК",
                "postCode": "2300",
                "streetId": 50661,
                "streetType": "УЛ.",
                "streetName": "ЮРИЙ ГАГАРИН",
                "streetNo": "52",
                "x": 23.113934,
                "y": 42.60393,
                "fullAddressString": "гр. ПЕРНИК [2300] ул. ЮРИЙ ГАГАРИН No 52",
                "siteAddressString": "гр. ПЕРНИК [2300]",
                "localAddressString": "ул. ЮРИЙ ГАГАРИН No 52"
            },
            "workingTimeFrom": "07:30",
            "workingTimeTo": "22:00",
            "workingTimeHalfFrom": "07:30",
            "workingTimeHalfTo": "22:00",
            "workingTimeDayOffFrom": "07:30",
            "workingTimeDayOffTo": "22:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 174,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-08-20",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9046,
            "name": "КЪРДЖАЛИ - БИЛЛА БУЛ. БЪЛГАРИЯ (АВТОМАТ)",
            "nameEn": "KARDZHALI - BILLA BUL. BULGARIA (AUTOMAT)",
            "siteId": 40909,
            "address": {
                "countryId": 100,
                "siteId": 40909,
                "siteType": "ГР.",
                "siteName": "КЪРДЖАЛИ",
                "postCode": "6600",
                "streetId": 56002,
                "streetType": "БУЛ.",
                "streetName": "БЪЛГАРИЯ",
                "streetNo": "115",
                "x": 25.38014,
                "y": 41.633622,
                "fullAddressString": "гр. КЪРДЖАЛИ [6600] бул. БЪЛГАРИЯ No 115",
                "siteAddressString": "гр. КЪРДЖАЛИ [6600]",
                "localAddressString": "бул. БЪЛГАРИЯ No 115"
            },
            "workingTimeFrom": "07:30",
            "workingTimeTo": "22:00",
            "workingTimeHalfFrom": "07:30",
            "workingTimeHalfTo": "22:00",
            "workingTimeDayOffFrom": "07:30",
            "workingTimeDayOffTo": "22:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 32,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-09-12",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9047,
            "name": "СОФИЯ - БИЛЛА МЛАДОСТ 4 (АВТОМАТ)",
            "nameEn": "SOFIA - BILLA MLADOST 4 (AUTOMAT)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 440,
                "streetType": "УЛ.",
                "streetName": "406",
                "streetNo": "14",
                "complexId": 51,
                "complexType": "ЖК",
                "complexName": "МЛАДОСТ 4",
                "x": 23.381152,
                "y": 42.629789,
                "fullAddressString": "гр. СОФИЯ жк МЛАДОСТ 4 ул. 406 No 14",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк МЛАДОСТ 4 ул. 406 No 14"
            },
            "workingTimeFrom": "07:30",
            "workingTimeTo": "22:00",
            "workingTimeHalfFrom": "07:30",
            "workingTimeHalfTo": "22:00",
            "workingTimeDayOffFrom": "07:30",
            "workingTimeDayOffTo": "22:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 199,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2017-06-21",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9048,
            "name": "ГАБРОВО - ТЕРА МОЛ (АВТОМАТ)",
            "nameEn": "GABROVO - TERRA MALL (AUTOMAT)",
            "siteId": 14218,
            "address": {
                "countryId": 100,
                "siteId": 14218,
                "siteType": "ГР.",
                "siteName": "ГАБРОВО",
                "postCode": "5300",
                "streetId": 48004,
                "streetType": "БУЛ.",
                "streetName": "МОГИЛЬОВ",
                "streetNo": "47",
                "x": 25.313004,
                "y": 42.885791,
                "fullAddressString": "гр. ГАБРОВО [5300] бул. МОГИЛЬОВ No 47",
                "siteAddressString": "гр. ГАБРОВО [5300]",
                "localAddressString": "бул. МОГИЛЬОВ No 47"
            },
            "workingTimeFrom": "10:00",
            "workingTimeTo": "22:00",
            "workingTimeHalfFrom": "10:00",
            "workingTimeHalfTo": "22:00",
            "workingTimeDayOffFrom": "10:00",
            "workingTimeDayOffTo": "22:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 874,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-11-25",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9050,
            "name": "ШУМЕН - СИТИ ЦЕНТЪР (АВТОМАТ)",
            "nameEn": "SHUMEN - CITY CENTER (AUTOMAT)",
            "siteId": 83510,
            "address": {
                "countryId": 100,
                "siteId": 83510,
                "siteType": "ГР.",
                "siteName": "ШУМЕН",
                "postCode": "9700",
                "streetId": 24051,
                "streetType": "БУЛ.",
                "streetName": "ВЕЛИКИ ПРЕСЛАВ",
                "streetNo": "28",
                "floorNo": "1",
                "x": 26.93602,
                "y": 43.267191,
                "fullAddressString": "гр. ШУМЕН [9700] бул. ВЕЛИКИ ПРЕСЛАВ No 28 ет. 1",
                "siteAddressString": "гр. ШУМЕН [9700]",
                "localAddressString": "бул. ВЕЛИКИ ПРЕСЛАВ No 28 ет. 1"
            },
            "workingTimeFrom": "10:00",
            "workingTimeTo": "20:00",
            "workingTimeHalfFrom": "10:00",
            "workingTimeHalfTo": "20:00",
            "workingTimeDayOffFrom": "10:00",
            "workingTimeDayOffTo": "19:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 154,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-04-12",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9051,
            "name": "РУСЕ - ЦБА ЗДРАВЕЦ (АВТОМАТ)",
            "nameEn": "RUSE - CBA ZDRAVETS (AUTOMAT)",
            "siteId": 63427,
            "address": {
                "countryId": 100,
                "siteId": 63427,
                "siteType": "ГР.",
                "siteName": "РУСЕ",
                "postCode": "7000",
                "streetId": 16317,
                "streetType": "БУЛ.",
                "streetName": "ЛИПНИК",
                "streetNo": "88",
                "x": 25.987815,
                "y": 43.851204,
                "fullAddressString": "гр. РУСЕ бул. ЛИПНИК No 88",
                "siteAddressString": "гр. РУСЕ",
                "localAddressString": "бул. ЛИПНИК No 88"
            },
            "workingTimeFrom": "07:00",
            "workingTimeTo": "22:00",
            "workingTimeHalfFrom": "07:00",
            "workingTimeHalfTo": "22:00",
            "workingTimeDayOffFrom": "07:00",
            "workingTimeDayOffTo": "22:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 849,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2016-12-08",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9052,
            "name": "ВЕЛИКО ТЪРНОВО - МОЛ ВЕЛИКО ТЪРНОВО (АВТОМАТ)",
            "nameEn": "VELIKO TARNOVO - MALL VELIKO TARNOVO (AUTOMAT)",
            "siteId": 10447,
            "address": {
                "countryId": 100,
                "siteId": 10447,
                "siteType": "ГР.",
                "siteName": "ВЕЛИКО ТЪРНОВО",
                "postCode": "5000",
                "streetId": 34158,
                "streetType": "УЛ.",
                "streetName": "ОБОРИЩЕ",
                "streetNo": "18",
                "x": 25.600033,
                "y": 43.078919,
                "fullAddressString": "гр. ВЕЛИКО ТЪРНОВО ул. ОБОРИЩЕ No 18",
                "siteAddressString": "гр. ВЕЛИКО ТЪРНОВО",
                "localAddressString": "ул. ОБОРИЩЕ No 18"
            },
            "workingTimeFrom": "10:00",
            "workingTimeTo": "21:00",
            "workingTimeHalfFrom": "10:00",
            "workingTimeHalfTo": "21:00",
            "workingTimeDayOffFrom": "10:00",
            "workingTimeDayOffTo": "21:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 748,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2017-02-21",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9053,
            "name": "ВЕЛИКО ТЪРНОВО - СКАЙ ЦЕНТЪР (АВТОМАТ)",
            "nameEn": "VELIKO TARNOVO - SKY CENTER (AUTOMAT)",
            "siteId": 10447,
            "address": {
                "countryId": 100,
                "siteId": 10447,
                "siteType": "ГР.",
                "siteName": "ВЕЛИКО ТЪРНОВО",
                "postCode": "5000",
                "streetId": 34239,
                "streetType": "УЛ.",
                "streetName": "ЦАНКО ЦЕРКОВСКИ",
                "streetNo": "37",
                "x": 25.627798,
                "y": 43.079319,
                "fullAddressString": "гр. ВЕЛИКО ТЪРНОВО ул. ЦАНКО ЦЕРКОВСКИ No 37",
                "siteAddressString": "гр. ВЕЛИКО ТЪРНОВО",
                "localAddressString": "ул. ЦАНКО ЦЕРКОВСКИ No 37"
            },
            "workingTimeFrom": "10:00",
            "workingTimeTo": "20:00",
            "workingTimeHalfFrom": "10:00",
            "workingTimeHalfTo": "20:00",
            "workingTimeDayOffFrom": "10:00",
            "workingTimeDayOffTo": "20:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "12:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 80,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "12:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-04-25",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9054,
            "name": "СОФИЯ - ФАНТАСТИКО ЙЕРУСАЛИМ (АВТОМАТ)",
            "nameEn": "SOFIA - FANTASTICO JERUSALIM (AUTOMAT)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 1868,
                "streetType": "УЛ.",
                "streetName": "ЙЕРУСАЛИМ",
                "streetNo": "22",
                "complexId": 47,
                "complexType": "ЖК",
                "complexName": "МЛАДОСТ 1",
                "x": 23.374587,
                "y": 42.655879,
                "fullAddressString": "гр. СОФИЯ жк МЛАДОСТ 1 ул. ЙЕРУСАЛИМ No 22",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк МЛАДОСТ 1 ул. ЙЕРУСАЛИМ No 22"
            },
            "workingTimeFrom": "07:30",
            "workingTimeTo": "22:00",
            "workingTimeHalfFrom": "07:30",
            "workingTimeHalfTo": "22:00",
            "workingTimeDayOffFrom": "07:30",
            "workingTimeDayOffTo": "22:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 98,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2017-11-02",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9055,
            "name": "СОФИЯ - КАУФЛАНД КАБЛЕШКОВ (АВТОМАТ)",
            "nameEn": "SOFIA - KAUFLAND KABLESHKOV (AUTOMAT)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 3047,
                "streetType": "УЛ.",
                "streetName": "ТОДОР КАБЛЕШКОВ",
                "streetNo": "3",
                "x": 23.273408,
                "y": 42.670489,
                "fullAddressString": "гр. СОФИЯ ул. ТОДОР КАБЛЕШКОВ No 3",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. ТОДОР КАБЛЕШКОВ No 3"
            },
            "workingTimeFrom": "07:00",
            "workingTimeTo": "22:00",
            "workingTimeHalfFrom": "07:00",
            "workingTimeHalfTo": "22:00",
            "workingTimeDayOffFrom": "07:00",
            "workingTimeDayOffTo": "22:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 322,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-10-25",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9056,
            "name": "ПЕРНИК - БИЛЛА СВ. СВ. КИРИЛ И МЕТОДИЙ (АВТОМАТ)",
            "nameEn": "PERNIK - BILLA SV. SV. KIRIL I METODIY (AUTOMAT)",
            "siteId": 55871,
            "address": {
                "countryId": 100,
                "siteId": 55871,
                "siteType": "ГР.",
                "siteName": "ПЕРНИК",
                "postCode": "2300",
                "streetId": 50530,
                "streetType": "УЛ.",
                "streetName": "СВ. СВ. КИРИЛ И МЕТОДИЙ",
                "streetNo": "29",
                "x": 23.041613,
                "y": 42.610433,
                "fullAddressString": "гр. ПЕРНИК [2300] ул. СВ. СВ. КИРИЛ И МЕТОДИЙ No 29",
                "siteAddressString": "гр. ПЕРНИК [2300]",
                "localAddressString": "ул. СВ. СВ. КИРИЛ И МЕТОДИЙ No 29"
            },
            "workingTimeFrom": "07:30",
            "workingTimeTo": "22:00",
            "workingTimeHalfFrom": "07:30",
            "workingTimeHalfTo": "22:00",
            "workingTimeDayOffFrom": "07:30",
            "workingTimeDayOffTo": "22:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 35,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-06-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9057,
            "name": "МОНТАНА - БИЛЛА КЛИМЕНТ ОХРИДСКИ (АВТОМАТ)",
            "nameEn": "MONTANA - BILLA KLIMENT OHRIDSKI (AUTOMAT)",
            "siteId": 48489,
            "address": {
                "countryId": 100,
                "siteId": 48489,
                "siteType": "ГР.",
                "siteName": "МОНТАНА",
                "postCode": "3400",
                "streetId": 58217,
                "streetType": "УЛ.",
                "streetName": "СВ. КЛИМЕНТ ОХРИДСКИ",
                "streetNo": "5",
                "x": 23.227632,
                "y": 43.409737,
                "fullAddressString": "гр. МОНТАНА [3400] ул. СВ. КЛИМЕНТ ОХРИДСКИ No 5",
                "siteAddressString": "гр. МОНТАНА [3400]",
                "localAddressString": "ул. СВ. КЛИМЕНТ ОХРИДСКИ No 5"
            },
            "workingTimeFrom": "07:30",
            "workingTimeTo": "22:00",
            "workingTimeHalfFrom": "07:30",
            "workingTimeHalfTo": "22:00",
            "workingTimeDayOffFrom": "07:30",
            "workingTimeDayOffTo": "22:00",
            "sameDayDepartureCutoff": "16:30",
            "sameDayDepartureCutoffHalf": "12:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 330,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "12:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "16:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-07-05",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9058,
            "name": "ВРАЦА - ТЦ СУМИ (АВТОМАТ)",
            "nameEn": "VRATZA - TC SUMI (AUTOMAT)",
            "siteId": 12259,
            "address": {
                "countryId": 100,
                "siteId": 12259,
                "siteType": "ГР.",
                "siteName": "ВРАЦА",
                "postCode": "3000",
                "streetId": 38079,
                "streetType": "БУЛ.",
                "streetName": "ДЕМОКРАЦИЯ",
                "streetNo": "16",
                "x": 23.555991,
                "y": 43.205127,
                "fullAddressString": "гр. ВРАЦА [3000] бул. ДЕМОКРАЦИЯ No 16",
                "siteAddressString": "гр. ВРАЦА [3000]",
                "localAddressString": "бул. ДЕМОКРАЦИЯ No 16"
            },
            "workingTimeFrom": "09:30",
            "workingTimeTo": "20:00",
            "workingTimeHalfFrom": "09:30",
            "workingTimeHalfTo": "20:00",
            "workingTimeDayOffFrom": "09:30",
            "workingTimeDayOffTo": "19:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 548,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:30",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2017-02-15",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9059,
            "name": "ЕЛИН ПЕЛИН - ФАНТАСТИКО (АВТОМАТ)",
            "nameEn": "ELIN PELIN - FANTASTICO (AUTOMAT)",
            "siteId": 27303,
            "address": {
                "countryId": 100,
                "siteId": 27303,
                "siteType": "ГР.",
                "siteName": "ЕЛИН ПЕЛИН",
                "postCode": "2100",
                "streetId": 77631,
                "streetType": "УЛ.",
                "streetName": "ВИТОША",
                "streetNo": "7",
                "x": 23.59653,
                "y": 42.66418,
                "fullAddressString": "гр. ЕЛИН ПЕЛИН [2100] ул. ВИТОША No 7",
                "siteAddressString": "гр. ЕЛИН ПЕЛИН [2100]",
                "localAddressString": "ул. ВИТОША No 7"
            },
            "workingTimeFrom": "07:30",
            "workingTimeTo": "22:00",
            "workingTimeHalfFrom": "07:30",
            "workingTimeHalfTo": "22:00",
            "workingTimeDayOffFrom": "07:30",
            "workingTimeDayOffTo": "22:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 232,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2017-06-20",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9060,
            "name": "СОФИЯ - THE MALL (АВТОМАТ)",
            "nameEn": "SOFIA - THE MALL (AUTOMAT)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 131,
                "streetType": "БУЛ.",
                "streetName": "ЦАРИГРАДСКО ШОСЕ",
                "streetNo": "115",
                "floorNo": "-3",
                "addressNote": "СРЕЩУ АСАНСЬОРИТЕ",
                "x": 23.382334,
                "y": 42.660585,
                "fullAddressString": "гр. СОФИЯ бул. ЦАРИГРАДСКО ШОСЕ No 115 ет. -3 СРЕЩУ АСАНСЬОРИТЕ",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "бул. ЦАРИГРАДСКО ШОСЕ No 115 ет. -3 СРЕЩУ АСАНСЬОРИТЕ"
            },
            "workingTimeFrom": "10:00",
            "workingTimeTo": "22:00",
            "workingTimeHalfFrom": "10:00",
            "workingTimeHalfTo": "22:00",
            "workingTimeDayOffFrom": "10:00",
            "workingTimeDayOffTo": "22:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 162,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2018-04-25",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9061,
            "name": "ЛОВЕЧ - СИТИ ЦЕНТЪР (АВТОМАТ)",
            "nameEn": "LOVECH - CITY CENTRE (AUTOMAT)",
            "siteId": 43952,
            "address": {
                "countryId": 100,
                "siteId": 43952,
                "siteType": "ГР.",
                "siteName": "ЛОВЕЧ",
                "postCode": "5500",
                "streetId": 32192,
                "streetType": "УЛ.",
                "streetName": "ТЪРГОВСКА",
                "streetNo": "45",
                "x": 24.717058,
                "y": 43.135681,
                "fullAddressString": "гр. ЛОВЕЧ [5500] ул. ТЪРГОВСКА No 45",
                "siteAddressString": "гр. ЛОВЕЧ [5500]",
                "localAddressString": "ул. ТЪРГОВСКА No 45"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "20:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "20:00",
            "workingTimeDayOffFrom": "09:00",
            "workingTimeDayOffTo": "20:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 25,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-06-17",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9062,
            "name": "ГАБРОВО - МОЛ (АВТОМАТ)",
            "nameEn": "GABROVO - MALL (AUTOMAT)",
            "siteId": 14218,
            "address": {
                "countryId": 100,
                "siteId": 14218,
                "siteType": "ГР.",
                "siteName": "ГАБРОВО",
                "postCode": "5300",
                "streetId": 48001,
                "streetType": "БУЛ.",
                "streetName": "АПРИЛОВ",
                "streetNo": "40",
                "x": 25.317205,
                "y": 42.86752,
                "fullAddressString": "гр. ГАБРОВО [5300] бул. АПРИЛОВ No 40",
                "siteAddressString": "гр. ГАБРОВО [5300]",
                "localAddressString": "бул. АПРИЛОВ No 40"
            },
            "workingTimeFrom": "08:00",
            "workingTimeTo": "20:00",
            "workingTimeHalfFrom": "08:00",
            "workingTimeHalfTo": "20:00",
            "workingTimeDayOffFrom": "08:00",
            "workingTimeDayOffTo": "20:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 841,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-06-14",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9063,
            "name": "СОФИЯ - ПАРК ЦЕНТЪР (АВТОМАТ)",
            "nameEn": "SOFIA - PARK CENTER (AUTOMAT)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 11,
                "streetType": "БУЛ.",
                "streetName": "АРСЕНАЛСКИ",
                "streetNo": "2",
                "addressNote": "НИВО -2",
                "x": 23.31998,
                "y": 42.67852,
                "fullAddressString": "гр. СОФИЯ бул. АРСЕНАЛСКИ No 2 НИВО -2",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "бул. АРСЕНАЛСКИ No 2 НИВО -2"
            },
            "workingTimeFrom": "10:00",
            "workingTimeTo": "22:00",
            "workingTimeHalfFrom": "10:00",
            "workingTimeHalfTo": "22:00",
            "workingTimeDayOffFrom": "10:00",
            "workingTimeDayOffTo": "22:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 210,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-12-03",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9064,
            "name": "КЪРДЖАЛИ - КАУФЛАНД (АВТОМАТ)",
            "nameEn": "KARDZHALI - KAUFLAND (AUTOMAT)",
            "siteId": 40909,
            "address": {
                "countryId": 100,
                "siteId": 40909,
                "siteType": "ГР.",
                "siteName": "КЪРДЖАЛИ",
                "postCode": "6600",
                "streetId": 56089,
                "streetType": "УЛ.",
                "streetName": "ЗДРАВЕЦ",
                "streetNo": "13",
                "x": 25.379786,
                "y": 41.635106,
                "fullAddressString": "гр. КЪРДЖАЛИ [6600] ул. ЗДРАВЕЦ No 13",
                "siteAddressString": "гр. КЪРДЖАЛИ [6600]",
                "localAddressString": "ул. ЗДРАВЕЦ No 13"
            },
            "workingTimeFrom": "07:00",
            "workingTimeTo": "21:00",
            "workingTimeHalfFrom": "07:00",
            "workingTimeHalfTo": "21:00",
            "workingTimeDayOffFrom": "08:00",
            "workingTimeDayOffTo": "21:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 32,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-07-23",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9065,
            "name": "СОФИЯ - БЪЛГАРИЯ МОЛ (АВТОМАТ)",
            "nameEn": "SOFIA - BULGARIA MALL (AUTOMAT)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 18,
                "streetType": "БУЛ.",
                "streetName": "БЪЛГАРИЯ",
                "streetNo": "69",
                "floorNo": "-2",
                "x": 23.288361,
                "y": 42.664561,
                "fullAddressString": "гр. СОФИЯ бул. БЪЛГАРИЯ No 69 ет. -2",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "бул. БЪЛГАРИЯ No 69 ет. -2"
            },
            "workingTimeFrom": "10:00",
            "workingTimeTo": "21:00",
            "workingTimeHalfFrom": "10:00",
            "workingTimeHalfTo": "21:00",
            "workingTimeDayOffFrom": "10:00",
            "workingTimeDayOffTo": "21:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 536,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2023-01-18",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9066,
            "name": "СОФИЯ - БИЛЛА ГЕРЕНА (АВТОМАТ)",
            "nameEn": "SOFIA - BILLA GERENA (AUTOMAT)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 943,
                "streetType": "УЛ.",
                "streetName": "АЛЕКСАНДЪР ЕКЗАРХ",
                "streetNo": "45",
                "x": 23.359308,
                "y": 42.702687,
                "fullAddressString": "гр. СОФИЯ ул. АЛЕКСАНДЪР ЕКЗАРХ No 45",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. АЛЕКСАНДЪР ЕКЗАРХ No 45"
            },
            "workingTimeFrom": "07:30",
            "workingTimeTo": "22:00",
            "workingTimeHalfFrom": "07:30",
            "workingTimeHalfTo": "22:00",
            "workingTimeDayOffFrom": "07:30",
            "workingTimeDayOffTo": "22:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 193,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-12-16",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9068,
            "name": "ВАРНА - КАУФЛАНД БРИЗ (АВТОМАТ)",
            "nameEn": "VARNA - KAUFLAND BRIZ (AUTOMAT)",
            "siteId": 10135,
            "address": {
                "countryId": 100,
                "siteId": 10135,
                "siteType": "ГР.",
                "siteName": "ВАРНА",
                "postCode": "9000",
                "streetId": 98399,
                "streetType": "УЛ.",
                "streetName": "Д-Р ПЕТЪР СКОРЧЕВ",
                "streetNo": "2",
                "x": 27.940373,
                "y": 43.222605,
                "fullAddressString": "гр. ВАРНА ул. Д-Р ПЕТЪР СКОРЧЕВ No 2",
                "siteAddressString": "гр. ВАРНА",
                "localAddressString": "ул. Д-Р ПЕТЪР СКОРЧЕВ No 2"
            },
            "workingTimeFrom": "07:00",
            "workingTimeTo": "22:00",
            "workingTimeHalfFrom": "07:00",
            "workingTimeHalfTo": "22:00",
            "workingTimeDayOffFrom": "07:00",
            "workingTimeDayOffTo": "22:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 661,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-08-29",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9069,
            "name": "ШУМЕН - КАУФЛАНД (АВТОМАТ)",
            "nameEn": "SHUMEN - KAUFLAND (AUTOMAT)",
            "siteId": 83510,
            "address": {
                "countryId": 100,
                "siteId": 83510,
                "siteType": "ГР.",
                "siteName": "ШУМЕН",
                "postCode": "9700",
                "streetId": 24026,
                "streetType": "УЛ.",
                "streetName": "БЕЛИ ЛОМ",
                "streetNo": "1",
                "x": 26.914385,
                "y": 43.278244,
                "fullAddressString": "гр. ШУМЕН [9700] ул. БЕЛИ ЛОМ No 1",
                "siteAddressString": "гр. ШУМЕН [9700]",
                "localAddressString": "ул. БЕЛИ ЛОМ No 1"
            },
            "workingTimeFrom": "07:00",
            "workingTimeTo": "21:30",
            "workingTimeHalfFrom": "07:00",
            "workingTimeHalfTo": "21:30",
            "workingTimeDayOffFrom": "07:00",
            "workingTimeDayOffTo": "21:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 834,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-01-17",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9070,
            "name": "ДУПНИЦА - КАУФЛАНД (АВТОМАТ)",
            "nameEn": "DUPNITSA - KAUFLAND (AUTOMAT)",
            "siteId": 68789,
            "address": {
                "countryId": 100,
                "siteId": 68789,
                "siteType": "ГР.",
                "siteName": "ДУПНИЦА",
                "postCode": "2600",
                "streetId": 72694,
                "streetType": "УЛ.",
                "streetName": "СВ. ИВАН РИЛСКИ",
                "streetNo": "96А",
                "x": 23.103684,
                "y": 42.258995,
                "fullAddressString": "гр. ДУПНИЦА [2600] ул. СВ. ИВАН РИЛСКИ No 96А",
                "siteAddressString": "гр. ДУПНИЦА [2600]",
                "localAddressString": "ул. СВ. ИВАН РИЛСКИ No 96А"
            },
            "workingTimeFrom": "07:00",
            "workingTimeTo": "21:00",
            "workingTimeHalfFrom": "07:00",
            "workingTimeHalfTo": "21:00",
            "workingTimeDayOffFrom": "08:00",
            "workingTimeDayOffTo": "21:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 26,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-11-29",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9071,
            "name": "СОФИЯ - КАУФЛАНД ХАДЖИ ДИМИТЪР (АВТОМАТ)",
            "nameEn": "SOFIA - KAUFLAND HADZHI DIMITAR (AUTOMAT)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 2746,
                "streetType": "УЛ.",
                "streetName": "РЕЗБАРСКА",
                "streetNo": "7Б",
                "x": 23.353742,
                "y": 42.71363,
                "fullAddressString": "гр. СОФИЯ ул. РЕЗБАРСКА No 7Б",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. РЕЗБАРСКА No 7Б"
            },
            "workingTimeFrom": "07:00",
            "workingTimeTo": "22:00",
            "workingTimeHalfFrom": "07:00",
            "workingTimeHalfTo": "22:00",
            "workingTimeDayOffFrom": "07:00",
            "workingTimeDayOffTo": "22:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 675,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2019-12-11",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9073,
            "name": "КАРЛОВО - КАУФЛАНД (АВТОМАТ)",
            "nameEn": "KARLOVO - KAUFLAND (AUTOMAT)",
            "siteId": 36498,
            "address": {
                "countryId": 100,
                "siteId": 36498,
                "siteType": "ГР.",
                "siteName": "КАРЛОВО",
                "postCode": "4300",
                "streetId": 78928,
                "streetType": "УЛ.",
                "streetName": "ТЕОФАН РАЙНОВ",
                "streetNo": "72",
                "x": 24.818011,
                "y": 42.633145,
                "fullAddressString": "гр. КАРЛОВО [4300] ул. ТЕОФАН РАЙНОВ No 72",
                "siteAddressString": "гр. КАРЛОВО [4300]",
                "localAddressString": "ул. ТЕОФАН РАЙНОВ No 72"
            },
            "workingTimeFrom": "07:00",
            "workingTimeTo": "21:00",
            "workingTimeHalfFrom": "07:00",
            "workingTimeHalfTo": "21:00",
            "workingTimeDayOffFrom": "07:00",
            "workingTimeDayOffTo": "21:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 835,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-02-14",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9074,
            "name": "ПАНАГЮРИЩЕ - БИЛЛА ГЕОРГИ БЕНКОВСКИ (АВТОМАТ)",
            "nameEn": "PANAGYURISHTE - BILLA GEORGI BENKOVSKI (AUTOMAT)",
            "siteId": 55302,
            "address": {
                "countryId": 100,
                "siteId": 55302,
                "siteType": "ГР.",
                "siteName": "ПАНАГЮРИЩЕ",
                "postCode": "4500",
                "streetId": 82411,
                "streetType": "УЛ.",
                "streetName": "ГЕОРГИ БЕНКОВСКИ",
                "streetNo": "67",
                "x": 24.187259,
                "y": 42.495371,
                "fullAddressString": "гр. ПАНАГЮРИЩЕ [4500] ул. ГЕОРГИ БЕНКОВСКИ No 67",
                "siteAddressString": "гр. ПАНАГЮРИЩЕ [4500]",
                "localAddressString": "ул. ГЕОРГИ БЕНКОВСКИ No 67"
            },
            "workingTimeFrom": "08:00",
            "workingTimeTo": "21:00",
            "workingTimeHalfFrom": "08:00",
            "workingTimeHalfTo": "21:00",
            "workingTimeDayOffFrom": "08:00",
            "workingTimeDayOffTo": "21:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "12:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 166,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "12:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-01-17",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9075,
            "name": "СЛИВЕН - КАУФЛАНД (АВТОМАТ)",
            "nameEn": "SLIVEN - KAUFLAND (AUTOMAT)",
            "siteId": 67338,
            "address": {
                "countryId": 100,
                "siteId": 67338,
                "siteType": "ГР.",
                "siteName": "СЛИВЕН",
                "postCode": "8800",
                "streetId": 44018,
                "streetType": "БУЛ.",
                "streetName": "ЦАР СИМЕОН",
                "streetNo": "39",
                "x": 26.32371,
                "y": 42.673775,
                "fullAddressString": "гр. СЛИВЕН [8800] бул. ЦАР СИМЕОН No 39",
                "siteAddressString": "гр. СЛИВЕН [8800]",
                "localAddressString": "бул. ЦАР СИМЕОН No 39"
            },
            "workingTimeFrom": "07:00",
            "workingTimeTo": "21:30",
            "workingTimeHalfFrom": "07:00",
            "workingTimeHalfTo": "21:30",
            "workingTimeDayOffFrom": "08:00",
            "workingTimeDayOffTo": "20:30",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 92,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "20:30",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-09-03",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9076,
            "name": "БАНСКО - БИЛЛА ЦАР СИМЕОН (АВТОМАТ)",
            "nameEn": "BANSKO - BILLA TSAR SIMEON (AUTOMAT)",
            "siteId": 2676,
            "address": {
                "countryId": 100,
                "siteId": 2676,
                "siteType": "ГР.",
                "siteName": "БАНСКО",
                "postCode": "2770",
                "streetId": 66423,
                "streetType": "УЛ.",
                "streetName": "ЦАР СИМЕОН",
                "streetNo": "28",
                "x": 23.484911,
                "y": 41.840558,
                "fullAddressString": "гр. БАНСКО [2770] ул. ЦАР СИМЕОН No 28",
                "siteAddressString": "гр. БАНСКО [2770]",
                "localAddressString": "ул. ЦАР СИМЕОН No 28"
            },
            "workingTimeFrom": "07:30",
            "workingTimeTo": "22:00",
            "workingTimeHalfFrom": "07:30",
            "workingTimeHalfTo": "22:00",
            "workingTimeDayOffFrom": "07:30",
            "workingTimeDayOffTo": "22:00",
            "sameDayDepartureCutoff": "16:00",
            "sameDayDepartureCutoffHalf": "11:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 302,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "11:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "16:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-01-18",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9077,
            "name": "ПИРДОП - БИЛЛА (АВТОМАТ)",
            "nameEn": "PIRDOP - BILLA (AUTOMAT)",
            "siteId": 56407,
            "address": {
                "countryId": 100,
                "siteId": 56407,
                "siteType": "ГР.",
                "siteName": "ПИРДОП",
                "postCode": "2070",
                "addressNote": "РИТЕЙЛ ПАРК СРЕДНОГОРИЕ",
                "x": 24.16342,
                "y": 42.703774,
                "fullAddressString": "гр. ПИРДОП [2070] РИТЕЙЛ ПАРК СРЕДНОГОРИЕ",
                "siteAddressString": "гр. ПИРДОП [2070]",
                "localAddressString": "РИТЕЙЛ ПАРК СРЕДНОГОРИЕ"
            },
            "workingTimeFrom": "07:30",
            "workingTimeTo": "22:00",
            "workingTimeHalfFrom": "07:30",
            "workingTimeHalfTo": "22:00",
            "workingTimeDayOffFrom": "07:30",
            "workingTimeDayOffTo": "22:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "12:30",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 274,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "12:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-01-17",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9078,
            "name": "БОТЕВГРАД - ФАНТАСТИКО (АВТОМАТ)",
            "nameEn": "BOTEVGRAD - FANTASTICO (AUTOMAT)",
            "siteId": 5815,
            "address": {
                "countryId": 100,
                "siteId": 5815,
                "siteType": "ГР.",
                "siteName": "БОТЕВГРАД",
                "postCode": "2140",
                "streetId": 74589,
                "streetType": "УЛ.",
                "streetName": "ВИТОША",
                "streetNo": "1",
                "x": 23.785491,
                "y": 42.907986,
                "fullAddressString": "гр. БОТЕВГРАД [2140] ул. ВИТОША No 1",
                "siteAddressString": "гр. БОТЕВГРАД [2140]",
                "localAddressString": "ул. ВИТОША No 1"
            },
            "workingTimeFrom": "07:30",
            "workingTimeTo": "22:00",
            "workingTimeHalfFrom": "07:30",
            "workingTimeHalfTo": "22:00",
            "workingTimeDayOffFrom": "07:30",
            "workingTimeDayOffTo": "22:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 857,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2021-12-29",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9079,
            "name": "ЯМБОЛ - ЯМБОЛ ПАРК (АВТОМАТ)",
            "nameEn": "YAMBOL - YAMBOL PARK (AUTOMAT)",
            "siteId": 87374,
            "address": {
                "countryId": 100,
                "siteId": 87374,
                "siteType": "ГР.",
                "siteName": "ЯМБОЛ",
                "postCode": "8600",
                "streetId": 26372,
                "streetType": "УЛ.",
                "streetName": "ГРАФ ИГНАТИЕВ",
                "streetNo": "28",
                "addressNote": "НИВО -1",
                "x": 26.51818,
                "y": 42.478306,
                "fullAddressString": "гр. ЯМБОЛ [8600] ул. ГРАФ ИГНАТИЕВ No 28 НИВО -1",
                "siteAddressString": "гр. ЯМБОЛ [8600]",
                "localAddressString": "ул. ГРАФ ИГНАТИЕВ No 28 НИВО -1"
            },
            "workingTimeFrom": "09:00",
            "workingTimeTo": "20:00",
            "workingTimeHalfFrom": "09:00",
            "workingTimeHalfTo": "20:00",
            "workingTimeDayOffFrom": "09:00",
            "workingTimeDayOffTo": "19:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 246,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "19:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "09:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-03-24",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9080,
            "name": "ВАРНА - КАУФЛАНД ТРОШЕВО (АВТОМАТ)",
            "nameEn": "VARNA - KAUFLAND TROSHEVO (AUTOMAT)",
            "siteId": 10135,
            "address": {
                "countryId": 100,
                "siteId": 10135,
                "siteType": "ГР.",
                "siteName": "ВАРНА",
                "postCode": "9000",
                "streetId": 13350,
                "streetType": "УЛ.",
                "streetName": "ХРИСТО СМИРНЕНСКИ",
                "streetNo": "2",
                "x": 27.89074,
                "y": 43.22128,
                "fullAddressString": "гр. ВАРНА ул. ХРИСТО СМИРНЕНСКИ No 2",
                "siteAddressString": "гр. ВАРНА",
                "localAddressString": "ул. ХРИСТО СМИРНЕНСКИ No 2"
            },
            "workingTimeFrom": "07:00",
            "workingTimeTo": "22:00",
            "workingTimeHalfFrom": "07:00",
            "workingTimeHalfTo": "22:00",
            "workingTimeDayOffFrom": "07:00",
            "workingTimeDayOffTo": "22:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 855,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-04-15",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9081,
            "name": "АСЕНОВГРАД - КАУФЛАНД (АВТОМАТ)",
            "nameEn": "ASENOVGRAD KAUFLAND (AUTOMAT)",
            "siteId": 702,
            "address": {
                "countryId": 100,
                "siteId": 702,
                "siteType": "ГР.",
                "siteName": "АСЕНОВГРАД",
                "postCode": "4230",
                "streetId": 65724,
                "streetType": "УЛ.",
                "streetName": "ХАДЖИ ДИМИТЪР СТОИЛОВ",
                "streetNo": "1",
                "x": 24.860799,
                "y": 42.018701,
                "fullAddressString": "гр. АСЕНОВГРАД [4230] ул. ХАДЖИ ДИМИТЪР СТОИЛОВ No 1",
                "siteAddressString": "гр. АСЕНОВГРАД [4230]",
                "localAddressString": "ул. ХАДЖИ ДИМИТЪР СТОИЛОВ No 1"
            },
            "workingTimeFrom": "07:00",
            "workingTimeTo": "21:30",
            "workingTimeHalfFrom": "07:00",
            "workingTimeHalfTo": "21:30",
            "workingTimeDayOffFrom": "08:00",
            "workingTimeDayOffTo": "21:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 17,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-02-10",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9082,
            "name": "СТАРА ЗАГОРА - КАУФЛАНД КАЗАНСКИ (АВТОМАТ)",
            "nameEn": "STARA ZAGORA - KAUFLAND KAZANSKI (AUTOMAT)",
            "siteId": 68850,
            "address": {
                "countryId": 100,
                "siteId": 68850,
                "siteType": "ГР.",
                "siteName": "СТАРА ЗАГОРА",
                "postCode": "6000",
                "streetId": 18310,
                "streetType": "УЛ.",
                "streetName": "СТАМО ПУЛЕВ",
                "streetNo": "2",
                "x": 25.620462,
                "y": 42.415531,
                "fullAddressString": "гр. СТАРА ЗАГОРА ул. СТАМО ПУЛЕВ No 2",
                "siteAddressString": "гр. СТАРА ЗАГОРА",
                "localAddressString": "ул. СТАМО ПУЛЕВ No 2"
            },
            "workingTimeFrom": "07:00",
            "workingTimeTo": "21:30",
            "workingTimeHalfFrom": "07:00",
            "workingTimeHalfTo": "21:30",
            "workingTimeDayOffFrom": "08:00",
            "workingTimeDayOffTo": "21:30",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 440,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-06-29",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9083,
            "name": "СОФИЯ - КАУФЛАНД МАЛИНОВА ДОЛИНА (АВТОМАТ)",
            "nameEn": "SOFIA - KAUFLAND MALINOVA DOLINA (AUTOMAT)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 3434,
                "streetType": "УЛ.",
                "streetName": "ПРОФ. Д-Р ИВАН СТРАНСКИ",
                "streetNo": "20",
                "x": 23.336257,
                "y": 42.642038,
                "fullAddressString": "гр. СОФИЯ ул. ПРОФ. Д-Р ИВАН СТРАНСКИ No 20",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "ул. ПРОФ. Д-Р ИВАН СТРАНСКИ No 20"
            },
            "workingTimeFrom": "07:00",
            "workingTimeTo": "22:00",
            "workingTimeHalfFrom": "07:00",
            "workingTimeHalfTo": "22:00",
            "workingTimeDayOffFrom": "07:00",
            "workingTimeDayOffTo": "22:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 225,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-07-20",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9085,
            "name": "БАНКЯ - ФАНТАСТИКО (АВТОМАТ)",
            "nameEn": "BANKYA - FANTASTICO (AUTOMAT)",
            "siteId": 2659,
            "address": {
                "countryId": 100,
                "siteId": 2659,
                "siteType": "ГР.",
                "siteName": "БАНКЯ",
                "postCode": "1320",
                "streetId": 66107,
                "streetType": "УЛ.",
                "streetName": "СТЕФАН СТАМБОЛОВ",
                "streetNo": "138",
                "x": 23.170063,
                "y": 42.716695,
                "fullAddressString": "гр. БАНКЯ [1320] ул. СТЕФАН СТАМБОЛОВ No 138",
                "siteAddressString": "гр. БАНКЯ [1320]",
                "localAddressString": "ул. СТЕФАН СТАМБОЛОВ No 138"
            },
            "workingTimeFrom": "07:30",
            "workingTimeTo": "22:00",
            "workingTimeHalfFrom": "07:30",
            "workingTimeHalfTo": "22:00",
            "workingTimeDayOffFrom": "07:30",
            "workingTimeDayOffTo": "22:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 780,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-06-01",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9086,
            "name": "НЕСЕБЪР - ХАН КРУМ (УЛ.) (АВТОМАТ)",
            "nameEn": "NESSEBAR - HAN KRUM (UL.) (AUTOMAT)",
            "siteId": 51500,
            "address": {
                "countryId": 100,
                "siteId": 51500,
                "siteType": "ГР.",
                "siteName": "НЕСЕБЪР",
                "postCode": "8230",
                "streetId": 71019,
                "streetType": "УЛ.",
                "streetName": "ХАН КРУМ",
                "streetNo": "2Б",
                "x": 27.72278,
                "y": 42.66108,
                "fullAddressString": "гр. НЕСЕБЪР [8230] ул. ХАН КРУМ No 2Б",
                "siteAddressString": "гр. НЕСЕБЪР [8230]",
                "localAddressString": "ул. ХАН КРУМ No 2Б"
            },
            "workingTimeFrom": "00:00",
            "workingTimeTo": "23:59",
            "workingTimeHalfFrom": "00:00",
            "workingTimeHalfTo": "23:59",
            "workingTimeDayOffFrom": "00:00",
            "workingTimeDayOffTo": "23:59",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 83,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "00:00",
                    "workingTimeTo": "23:59",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2023-09-14",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9087,
            "name": "ПЪРВОМАЙ - БИЛЛА (АВТОМАТ)",
            "nameEn": "PARVOMAY - BILLA (AUTOMAT)",
            "siteId": 59080,
            "address": {
                "countryId": 100,
                "siteId": 59080,
                "siteType": "ГР.",
                "siteName": "ПЪРВОМАЙ",
                "postCode": "4270",
                "streetId": 83322,
                "streetType": "УЛ.",
                "streetName": "КНЯЗ БОРИС I",
                "streetNo": "51В",
                "x": 25.211662,
                "y": 42.090659,
                "fullAddressString": "гр. ПЪРВОМАЙ [4270] ул. КНЯЗ БОРИС I No 51В",
                "siteAddressString": "гр. ПЪРВОМАЙ [4270]",
                "localAddressString": "ул. КНЯЗ БОРИС I No 51В"
            },
            "workingTimeFrom": "07:30",
            "workingTimeTo": "22:00",
            "workingTimeHalfFrom": "07:30",
            "workingTimeHalfTo": "22:00",
            "workingTimeDayOffFrom": "07:30",
            "workingTimeDayOffTo": "22:00",
            "sameDayDepartureCutoff": "17:00",
            "sameDayDepartureCutoffHalf": "12:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 318,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "12:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:30",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "17:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-07-26",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9088,
            "name": "КАЗАНЛЪК - КАУФЛАНД (АВТОМАТ)",
            "nameEn": "KAZANLAK - KAUFLAND (AUTOMAT)",
            "siteId": 35167,
            "address": {
                "countryId": 100,
                "siteId": 35167,
                "siteType": "ГР.",
                "siteName": "КАЗАНЛЪК",
                "postCode": "6100",
                "streetId": 91849,
                "streetType": "БУЛ.",
                "streetName": "23-ТИ ПЕХОТЕН ШИПЧЕНСКИ ПОЛК",
                "streetNo": "153",
                "x": 25.369374,
                "y": 42.617958,
                "fullAddressString": "гр. КАЗАНЛЪК [6100] бул. 23-ТИ ПЕХОТЕН ШИПЧЕНСКИ ПОЛК No 153",
                "siteAddressString": "гр. КАЗАНЛЪК [6100]",
                "localAddressString": "бул. 23-ТИ ПЕХОТЕН ШИПЧЕНСКИ ПОЛК No 153"
            },
            "workingTimeFrom": "07:00",
            "workingTimeTo": "21:30",
            "workingTimeHalfFrom": "07:00",
            "workingTimeHalfTo": "21:30",
            "workingTimeDayOffFrom": "08:00",
            "workingTimeDayOffTo": "21:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 151,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-10-02",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9089,
            "name": "СОФИЯ - КАУФЛАНД МЛАДОСТ 4 (АВТОМАТ)",
            "nameEn": "SOFIA -  KAUFLAND MLADOST 4 (AUTOMAT)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 4143,
                "streetType": "УЛ.",
                "streetName": "ОКОЛОВРЪСТЕН ПЪТ",
                "streetNo": "271",
                "complexId": 51,
                "complexType": "ЖК",
                "complexName": "МЛАДОСТ 4",
                "x": 23.384048,
                "y": 42.624109,
                "fullAddressString": "гр. СОФИЯ жк МЛАДОСТ 4 ул. ОКОЛОВРЪСТЕН ПЪТ No 271",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк МЛАДОСТ 4 ул. ОКОЛОВРЪСТЕН ПЪТ No 271"
            },
            "workingTimeFrom": "07:00",
            "workingTimeTo": "22:00",
            "workingTimeHalfFrom": "07:00",
            "workingTimeHalfTo": "22:00",
            "workingTimeDayOffFrom": "07:00",
            "workingTimeDayOffTo": "22:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 199,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2022-06-14",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9090,
            "name": "СОФИЯ - КАУФЛАНД КРИВА РЕКА (АВТОМАТ)",
            "nameEn": "SOFIA - KAUFLAND KRIVA REKA (AUTOMAТ)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 36,
                "streetType": "БУЛ.",
                "streetName": "ГЕН. ЕДУАРД И. ТОТЛЕБЕН",
                "streetNo": "36",
                "x": 23.301907,
                "y": 42.69018,
                "fullAddressString": "гр. СОФИЯ бул. ГЕН. ЕДУАРД И. ТОТЛЕБЕН No 36",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "бул. ГЕН. ЕДУАРД И. ТОТЛЕБЕН No 36"
            },
            "workingTimeFrom": "07:00",
            "workingTimeTo": "22:00",
            "workingTimeHalfFrom": "07:00",
            "workingTimeHalfTo": "22:00",
            "workingTimeDayOffFrom": "07:00",
            "workingTimeDayOffTo": "22:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 323,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-10-05",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9091,
            "name": "ВАРНА - ЪПТАУН ШОПИНГ ЦЕНТЪР (АВТОМАТ)",
            "nameEn": "VARNA - UPTOWN SHOPPING CENTRE (AUTOMAT)",
            "siteId": 10135,
            "address": {
                "countryId": 100,
                "siteId": 10135,
                "siteType": "ГР.",
                "siteName": "ВАРНА",
                "postCode": "9000",
                "streetId": 12545,
                "streetType": "БУЛ.",
                "streetName": "8-МИ ПРИМОРСКИ ПОЛК",
                "streetNo": "115",
                "x": 27.925776,
                "y": 43.214223,
                "fullAddressString": "гр. ВАРНА бул. 8-МИ ПРИМОРСКИ ПОЛК No 115",
                "siteAddressString": "гр. ВАРНА",
                "localAddressString": "бул. 8-МИ ПРИМОРСКИ ПОЛК No 115"
            },
            "workingTimeFrom": "08:00",
            "workingTimeTo": "20:00",
            "workingTimeHalfFrom": "08:00",
            "workingTimeHalfTo": "20:00",
            "workingTimeDayOffFrom": "08:00",
            "workingTimeDayOffTo": "20:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 785,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "20:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-11-05",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9092,
            "name": "ПАЗАРДЖИК - КАУФЛАНД (АВТОМАТ)",
            "nameEn": "PAZARDZHIK - KAUFLAND (AUTOMAT)",
            "siteId": 55155,
            "address": {
                "countryId": 100,
                "siteId": 55155,
                "siteType": "ГР.",
                "siteName": "ПАЗАРДЖИК",
                "postCode": "4400",
                "streetId": 40108,
                "streetType": "УЛ.",
                "streetName": "ДИМЧО ДЕБЕЛЯНОВ",
                "streetNo": "3",
                "x": 24.34629,
                "y": 42.18636,
                "fullAddressString": "гр. ПАЗАРДЖИК [4400] ул. ДИМЧО ДЕБЕЛЯНОВ No 3",
                "siteAddressString": "гр. ПАЗАРДЖИК [4400]",
                "localAddressString": "ул. ДИМЧО ДЕБЕЛЯНОВ No 3"
            },
            "workingTimeFrom": "07:00",
            "workingTimeTo": "21:30",
            "workingTimeHalfFrom": "07:00",
            "workingTimeHalfTo": "21:30",
            "workingTimeDayOffFrom": "08:00",
            "workingTimeDayOffTo": "21:30",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 18,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2023-08-09",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9094,
            "name": "ДОБРИЧ - КАУФЛАНД (АВТОМАТ)",
            "nameEn": "DOBRICH - KAUFLAND (AUTOMAT)",
            "siteId": 72624,
            "address": {
                "countryId": 100,
                "siteId": 72624,
                "siteType": "ГР.",
                "siteName": "ДОБРИЧ",
                "postCode": "9300",
                "streetId": 22099,
                "streetType": "УЛ.",
                "streetName": "ДУНАВ",
                "streetNo": "55",
                "x": 27.839473,
                "y": 43.566672,
                "fullAddressString": "гр. ДОБРИЧ [9300] ул. ДУНАВ No 55",
                "siteAddressString": "гр. ДОБРИЧ [9300]",
                "localAddressString": "ул. ДУНАВ No 55"
            },
            "workingTimeFrom": "07:00",
            "workingTimeTo": "21:30",
            "workingTimeHalfFrom": "07:00",
            "workingTimeHalfTo": "21:30",
            "workingTimeDayOffFrom": "08:00",
            "workingTimeDayOffTo": "21:00",
            "sameDayDepartureCutoff": "17:30",
            "sameDayDepartureCutoffHalf": "13:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 245,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "13:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:30",
                    "sameDayDepartureCutoff": "17:30",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2023-07-24",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9095,
            "name": "ГАБРОВО - КАУФЛАНД (АВТОМАТ)",
            "nameEn": "GABROVO - KAUFLAND (AUTOMAT)",
            "siteId": 14218,
            "address": {
                "countryId": 100,
                "siteId": 14218,
                "siteType": "ГР.",
                "siteName": "ГАБРОВО",
                "postCode": "5300",
                "streetId": 48200,
                "streetType": "УЛ.",
                "streetName": "ИВАЙЛО",
                "streetNo": "6",
                "x": 25.320933,
                "y": 42.883063,
                "fullAddressString": "гр. ГАБРОВО [5300] ул. ИВАЙЛО No 6",
                "siteAddressString": "гр. ГАБРОВО [5300]",
                "localAddressString": "ул. ИВАЙЛО No 6"
            },
            "workingTimeFrom": "07:00",
            "workingTimeTo": "21:00",
            "workingTimeHalfFrom": "07:00",
            "workingTimeHalfTo": "21:00",
            "workingTimeDayOffFrom": "08:00",
            "workingTimeDayOffTo": "21:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 456,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "08:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "21:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2020-09-16",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9096,
            "name": "СОФИЯ - КАУФЛАНД ЛЮЛИН (АВТОМАТ)",
            "nameEn": "SOFIA - KAUFLAND LYULIN (AUTOMAT)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 4945,
                "streetType": "БУЛ.",
                "streetName": "ЦАРИЦА ЙОАННА",
                "complexId": 170,
                "complexType": "ЖК",
                "complexName": "ЛЮЛИН",
                "x": 23.242038,
                "y": 42.726098,
                "fullAddressString": "гр. СОФИЯ жк ЛЮЛИН бул. ЦАРИЦА ЙОАННА",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "жк ЛЮЛИН бул. ЦАРИЦА ЙОАННА"
            },
            "workingTimeFrom": "07:00",
            "workingTimeTo": "22:00",
            "workingTimeHalfFrom": "07:00",
            "workingTimeHalfTo": "22:00",
            "workingTimeDayOffFrom": "07:00",
            "workingTimeDayOffTo": "22:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 138,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "07:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2023-10-11",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        },
        {
            "id": 9098,
            "name": "СОФИЯ - MALL OF SOFIA (АВТОМАТ)",
            "nameEn": "SOFIA - MALL OF SOFIA (AUTOMAT)",
            "siteId": 68134,
            "address": {
                "countryId": 100,
                "siteId": 68134,
                "siteType": "ГР.",
                "siteName": "СОФИЯ",
                "postCode": "1000",
                "streetId": 6,
                "streetType": "БУЛ.",
                "streetName": "АЛЕКСАНДЪР СТАМБОЛИЙСКИ",
                "streetNo": "101",
                "addressNote": "НИВО -3",
                "x": 23.30848,
                "y": 42.69815,
                "fullAddressString": "гр. СОФИЯ бул. АЛЕКСАНДЪР СТАМБОЛИЙСКИ No 101 НИВО -3",
                "siteAddressString": "гр. СОФИЯ",
                "localAddressString": "бул. АЛЕКСАНДЪР СТАМБОЛИЙСКИ No 101 НИВО -3"
            },
            "workingTimeFrom": "10:00",
            "workingTimeTo": "22:00",
            "workingTimeHalfFrom": "10:00",
            "workingTimeHalfTo": "22:00",
            "workingTimeDayOffFrom": "10:00",
            "workingTimeDayOffTo": "22:00",
            "sameDayDepartureCutoff": "18:00",
            "sameDayDepartureCutoffHalf": "14:00",
            "sameDayDepartureCutoffDayOff": "00:00",
            "maxParcelDimensions": {
                "width": 36,
                "height": 38,
                "depth": 62
            },
            "maxParcelWeight": 20.0,
            "type": "APT",
            "nearbyOfficeId": 301,
            "workingTimeSchedule": [
                {
                    "date": "2024-02-21",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-22",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-23",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-24",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "14:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-25",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "00:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-26",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-27",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-28",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-02-29",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                },
                {
                    "date": "2024-03-01",
                    "workingTimeFrom": "10:00",
                    "workingTimeTo": "22:00",
                    "sameDayDepartureCutoff": "18:00",
                    "standardSchedule": true
                }
            ],
            "validFrom": "2024-01-17",
            "validTo": "3000-01-01",
            "cargoTypesAllowed": [
                "PARCEL"
            ],
            "pickUpAllowed": true,
            "dropOffAllowed": true,
            "palletOffice": false,
            "cashPaymentAllowed": false,
            "cardPaymentAllowed": true
        }
    
];

const filteredSpeedyOffices = speedyOffices.filter(s => s.cashPaymentAllowed);

export default filteredSpeedyOffices;
