/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

import productSubcategoryOptions from './productSubcategoryOptions';
import productTypeOptions from './productTypeOptions';
import sizeOptions from './sizeOptions';
import brandOptions from './brandOptions';

import { FormattedMessage, injectIntl, intlShape } from '../util/reactIntl';

export const filters = [
  {
    id: 'keyCategory',
    label: <FormattedMessage id="EditListingDetailsForm.keyCategoryMessage" />,
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_keyCategory'],
    showIf: ['any'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'multi-enum',
      searchMode: 'has_any',
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'woman', label: <FormattedMessage id="keyCategory.woman" /> },
        { key: 'man', label: <FormattedMessage id="keyCategory.man" /> },
        { key: 'kids', label: <FormattedMessage id="keyCategory.kids" /> },
        { key: 'baby', label: <FormattedMessage id="keyCategory.baby" /> },
        { key: 'home', label: <FormattedMessage id="keyCategory.home" /> },
        { key: 'beauty', label: <FormattedMessage id="keyCategory.beauty" /> },
      ],
    },
  },
  {
    id: 'kidsGender',
    label: <FormattedMessage id="gender.label" />,
    type: 'SelectMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_kidsGender'],
    showIf: ['keyCategory'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'multi-enum',
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'girl', label: <FormattedMessage id="kidsGender.girl" />, keyCategory: 'kids' },
        { key: 'boy', label: <FormattedMessage id="kidsGender.boy" />, keyCategory: 'kids' },
        { key: 'unisex', label: <FormattedMessage id="kidsGender.unisex" />, keyCategory: 'kids' },
      ],
    },
  },
  {
    id: 'productCategory',
    label: <FormattedMessage id="productCategory.label" />,
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_productCategory'],
    showIf: ['keyCategory'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        // woman
        {
          key: 'clothing',
          label: <FormattedMessage id="productCategory.clothing" />,
          keyCategory: 'woman',
        },
        {
          key: 'shoes',
          label: <FormattedMessage id="productCategory.shoes" />,
          keyCategory: 'woman',
        },
        {
          key: 'accessories',
          label: <FormattedMessage id="productCategory.accessories" />,
          keyCategory: 'woman',
        },

        //man
        {
          key: 'clothing',
          label: <FormattedMessage id="productCategory.clothing" />,
          keyCategory: 'man',
        },
        {
          key: 'shoes',
          label: <FormattedMessage id="productCategory.shoes" />,
          keyCategory: 'man',
        },
        {
          key: 'accessories',
          label: <FormattedMessage id="productCategory.accessories" />,
          keyCategory: 'man',
        },

        //kids
        {
          key: 'clothing',
          label: <FormattedMessage id="productCategory.clothing" />,
          keyCategory: 'kids',
        },
        {
          key: 'shoes',
          label: <FormattedMessage id="productCategory.shoes" />,
          keyCategory: 'kids',
        },
        {
          key: 'accessories',
          label: <FormattedMessage id="productCategory.accessories" />,
          keyCategory: 'kids',
        },
        {
          key: 'toysGamesBooks',
          label: <FormattedMessage id="productCategory.toysGamesBooks" />,
          keyCategory: 'kids',
        },
        {
          key: 'sports',
          label: <FormattedMessage id="productCategory.sports" />,
          keyCategory: 'kids',
        },

        //baby
        {
          key: 'clothing',
          label: <FormattedMessage id="productCategory.clothing" />,
          keyCategory: 'baby',
        },
        {
          key: 'shoes',
          label: <FormattedMessage id="productCategory.shoes" />,
          keyCategory: 'baby',
        },
        {
          key: 'accessories',
          label: <FormattedMessage id="productCategory.accessories" />,
          keyCategory: 'baby',
        },
        {
          key: 'toysGamesBooks',
          label: <FormattedMessage id="productCategory.toysGamesBooks" />,
          keyCategory: 'baby',
        },
        {
          key: 'sports',
          label: <FormattedMessage id="productCategory.sports" />,
          keyCategory: 'baby',
        },
        {
          key: 'strollers',
          label: <FormattedMessage id="productCategory.strollers" />,
          keyCategory: 'baby',
        },
        {
          key: 'carSeats',
          label: <FormattedMessage id="productCategory.carSeats" />,
          keyCategory: 'baby',
        },
        {
          key: 'kangaroosSlings',
          label: <FormattedMessage id="productCategory.kangaroosAndSlings" />,
          keyCategory: 'baby',
        },
        {
          key: 'toysGames',
          label: <FormattedMessage id="productCategory.toysAndGames" />,
          keyCategory: 'baby',
        },
        {
          key: 'furniture',
          label: <FormattedMessage id="productCategory.furniture" />,
          keyCategory: 'baby',
        },
        {
          key: 'electricalDevices',
          label: <FormattedMessage id="productCategory.electricalDevices" />,
          keyCategory: 'baby',
        },
        { key: 'bags', label: <FormattedMessage id="productCategory.bags" />, keyCategory: 'baby' },
        {
          key: 'accessoriesBags',
          label: <FormattedMessage id="productCategory.accessoriesBagsBagpacks" />,
          keyCategory: 'baby',
        },

        //home
        {
          key: 'decor',
          label: <FormattedMessage id="productCategory.decor" />,
          keyCategory: 'home',
        },
        {
          key: 'furniture',
          label: <FormattedMessage id="productCategory.furniture" />,
          keyCategory: 'home',
        },
        {
          key: 'booksMagazines',
          label: <FormattedMessage id="productCategory.booksAndMagazines" />,
          keyCategory: 'home',
        },

        //beauty
        {
          key: 'cosmetics',
          label: <FormattedMessage id="productCategory.cosmetics" />,
          keyCategory: 'beauty',
        },
        {
          key: 'makeup',
          label: <FormattedMessage id="productCategory.makeUp" />,
          keyCategory: 'beauty',
        },
        {
          key: 'tools',
          label: <FormattedMessage id="productCategory.tools" />,
          keyCategory: 'beauty',
        },
        {
          key: 'perfumes',
          label: <FormattedMessage id="productCategory.perfumes" />,
          keyCategory: 'beauty',
        },
        {
          key: 'electricalDevices',
          label: <FormattedMessage id="productCategory.electricalDevices" />,
          keyCategory: 'beauty',
        },
      ],
    },
  },
  {
    id: 'productSubcategory',
    label: <FormattedMessage id="productSubcategory.label" />,
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_productSubcategory'],
    showIf: ['keyCategory', 'productCategory'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...productSubcategoryOptions],
    },
  },
  {
    id: 'productType',
    label: <FormattedMessage id="productType.label" />,
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_productType'],
    showIf: ['keyCategory', 'productCategory', 'productSubcategory'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...productTypeOptions],
    },
  },
  {
    id: 'brand',
    label: <FormattedMessage id="brand.label" />,
    type: 'AutocompleteFilter',
    group: 'primary',
    queryParamNames: ['pub_brand'],
    showIf: ['any'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...brandOptions],
    },
  },
  {
    id: 'sizeSystem',
    label: <FormattedMessage id="sizeSystem.label" />,
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_sizeSystem'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'int', label: <FormattedMessage id="sizeSystem.int" /> },
        { key: 'eu', label: <FormattedMessage id="sizeSystem.eu" /> },
        { key: 'uk', label: <FormattedMessage id="sizeSystem.uk" /> },
        { key: 'us', label: <FormattedMessage id="sizeSystem.us" /> },
        { key: 'it', label: <FormattedMessage id="sizeSystem.it" /> },
        { key: 'oneSize', label: <FormattedMessage id="sizeSystem.oneSize" /> },
      ],
    },
  },
  {
    id: 'size',
    label: <FormattedMessage id="Size.label" />,
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_size'],
    showIf: ['keyCategory', 'productCategory'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [...sizeOptions],
    },
  },
  {
    id: 'productColor',
    label: <FormattedMessage id="productColor.label" />,
    type: 'SelectCustomMultipleFilter',
    group: 'primary',
    queryParamNames: ['pub_productColor'],
    showIf: ['any'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'multi-enum',
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'white', label: <FormattedMessage id="productColor.white" />, hex: '#FFFFFF' },
        { key: 'black', label: <FormattedMessage id="productColor.black" />, hex: '#000000' },
        { key: 'grey', label: <FormattedMessage id="productColor.grey" />, hex: '#8E8E8E ' },
        { key: 'nude', label: <FormattedMessage id="productColor.nude" />, hex: '#EDD5C1' },
        // { key: 'colorful', label: 'Colorful', hex:'#' },
        { key: 'blue', label: <FormattedMessage id="productColor.blue" />, hex: '#4675D9' },
        { key: 'green', label: <FormattedMessage id="productColor.green" />, hex: '#008000' },
        { key: 'orange', label: <FormattedMessage id="productColor.orange" />, hex: '#FFA72D' },
        { key: 'red', label: <FormattedMessage id="productColor.red" />, hex: '#ff0000' },
        { key: 'brown', label: <FormattedMessage id="productColor.brown" />, hex: '#94673A' },
        { key: 'monochrome', label: <FormattedMessage id="productColor.monochrome" /> },
        { key: 'metallic', label: <FormattedMessage id="productColor.metallic" />, hex: '#BBC2CC' },
        { key: 'yellow', label: <FormattedMessage id="productColor.yellow" />, hex: '#FFF665' },
        { key: 'purple', label: <FormattedMessage id="productColor.purple" />, hex: '#8354CC' },
        { key: 'pink', label: <FormattedMessage id="productColor.pink" />, hex: '#FF8EB1' },
        { key: 'fuchsia', label: <FormattedMessage id="productColor.fuchsia" />, hex: '#FF1696' },
        { key: 'darkBlue', label: <FormattedMessage id="productColor.darkBlue" />, hex: '#3842BF' },
        { key: 'gold', label: <FormattedMessage id="productColor.gold" />, hex: '#E0CD67 ' },
        { key: 'silver', label: <FormattedMessage id="productColor.silver" />, hex: '#C3C7C7' },
        { key: 'olive', label: <FormattedMessage id="productColor.olive" />, hex: '#808000' },
        { key: 'khaki', label: <FormattedMessage id="productColor.khaki" />, hex: '#C0AD8C' },
        { key: 'mint', label: <FormattedMessage id="productColor.mint" />, hex: '#94FFB9' },
        { key: 'ecru', label: <FormattedMessage id="productColor.ecru" />, hex: '#FFF0D9' },
        { key: 'cream', label: <FormattedMessage id="productColor.cream" />, hex: '#FFFDD0' },
      ],
    },
  },
  // {
  //   id: 'composition',
  //   label: <FormattedMessage id="composition.label" />,
  //   type: 'AutocompleteFilter',
  //   group: 'primary',
  //   queryParamNames: ['pub_composition'],
  //   showIf: ['any'],
  //   config: {
  //     // Schema type is enum for SelectSingleFilter
  //     schemaType: 'enum',

  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for the UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [
  //       { key: 'acrylic', label: <FormattedMessage id="composition.acrylic" /> },
  //       { key: 'suede', label: <FormattedMessage id="composition.suede" /> },
  //       { key: 'viscose', label: <FormattedMessage id="composition.viscose" /> },
  //       { key: 'wool', label: <FormattedMessage id="composition.wool" /> },
  //       { key: 'rubber', label: <FormattedMessage id="composition.rubber" /> },
  //       { key: 'wood', label: <FormattedMessage id="composition.wood" /> },
  //       { key: 'ecoLeather', label: <FormattedMessage id="composition.ecoLeather" /> },
  //       { key: 'elastane', label: <FormattedMessage id="composition.elastane" /> },
  //       { key: 'fur', label: <FormattedMessage id="composition.fur" /> },
  //       { key: 'velvet', label: <FormattedMessage id="composition.velvet" /> },
  //       { key: 'cashmere', label: <FormattedMessage id="composition.cashmere" /> },
  //       { key: 'leather', label: <FormattedMessage id="composition.leather" /> },
  //       { key: 'silk', label: <FormattedMessage id="composition.silk" /> },
  //       { key: 'lack', label: <FormattedMessage id="composition.lack" /> },
  //       { key: 'linen', label: <FormattedMessage id="composition.linen" /> },
  //       { key: 'nylon', label: <FormattedMessage id="composition.nylon" /> },
  //       { key: 'organicCotton', label: <FormattedMessage id="composition.organicCotton" /> },
  //       { key: 'cotton', label: <FormattedMessage id="composition.cotton" /> },
  //       { key: 'polyamide', label: <FormattedMessage id="composition.polyamide" /> },
  //       { key: 'polyester', label: <FormattedMessage id="composition.polyester" /> },
  //       { key: 'down', label: <FormattedMessage id="composition.down" /> },
  //       { key: 'rattan', label: <FormattedMessage id="composition.rattab" /> },
  //       { key: 'satin', label: <FormattedMessage id="composition.satin" /> },
  //       { key: 'synthetic', label: <FormattedMessage id="composition.synthetic" /> },
  //       { key: 'glass', label: <FormattedMessage id="composition.glass" /> },
  //       { key: 'canvas', label: <FormattedMessage id="composition.canvas" /> },
  //       { key: 'moher', label: <FormattedMessage id="composition.moher" /> },
  //       { key: 'lyocell', label: <FormattedMessage id="composition.lyocell" /> },
  //       { key: 'lace', label: <FormattedMessage id="composition.lace" /> },
  //       { key: 'tule', label: <FormattedMessage id="composition.tule" /> },
  //       { key: 'gold', label: <FormattedMessage id="composition.gold" /> },
  //       { key: 'silver', label: <FormattedMessage id="composition.silver" /> },
  //       { key: 'bronze', label: <FormattedMessage id="composition.bronze" /> },
  //       { key: 'copper', label: <FormattedMessage id="composition.copper" /> },
  //       { key: 'Steel', label: <FormattedMessage id="composition.steel" /> },
  //       { key: 'stainlessSteel', label: <FormattedMessage id="composition.stainlessSteel" /> },
  //       { key: 'polyurethane', label: <FormattedMessage id="composition.polyurethane" /> },
  //       { key: 'bamboo', label: <FormattedMessage id="composition.bamboo" /> },
  //       { key: 'tencel', label: <FormattedMessage id="composition.tencel" /> },
  //       { key: 'cupro', label: <FormattedMessage id="composition.cupro" /> },
  //     ],
  //   },
  // },
  {
    id: 'condition',
    label: <FormattedMessage id="condition.label" />,
    type: 'SelectSingleFilter',
    group: 'primary',
    queryParamNames: ['pub_condition'],
    showIf: ['any'],
    config: {
      // Schema type is enum for SelectSingleFilter
      schemaType: 'enum',
      searchMode: 'has_any',

      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'neverWorn', label: <FormattedMessage id="condition.neverWorn" /> },
        { key: 'excellent', label: <FormattedMessage id="condition.excellent" /> },
        {
          key: 'goodWithNegligibleDefects',
          label: <FormattedMessage id="condition.goodWithDefects1" />,
        },
        { key: 'goodWithDefects', label: <FormattedMessage id="condition.goodWithDefects2" /> },
        { key: 'vintage', label: <FormattedMessage id="condition.vintage" /> },
      ],
    },
  },
  // {
  //   id: 'size',
  //   label: 'Size (US)',
  //   type: 'SelectMultipleFilter',
  //   group: 'primary',
  //   queryParamNames: ['pub_size'],
  //   config: {
  //     // Schema type options: 'enum', 'multi-enum'
  //     // Both types can work so that user selects multiple values when filtering search results.
  //     // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
  //     // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
  //     schemaType: 'enum',

  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for the UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [
  //       { key: '4', label: '4' },
  //       { key: '5', label: '5' },
  //       { key: '6', label: '6' },
  //       { key: '7', label: '7' },
  //       { key: '8', label: '8' },
  //       { key: '9', label: '9' },
  //       { key: '10', label: '10' },
  //       { key: '11', label: '11' },
  //       { key: '12', label: '12' },
  //     ],
  //   },
  // },
  // {
  //   id: 'brand',
  //   label: 'Brand',
  //   type: 'SelectMultipleFilter',
  //   group: 'primary',
  //   queryParamNames: ['pub_brand'],
  //   config: {
  //     // Schema type options: 'enum', 'multi-enum'
  //     // Both types can work so that user selects multiple values when filtering search results.
  //     // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
  //     // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
  //     schemaType: 'enum',

  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for the UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [
  //       { key: 'adidas', label: 'Adidas' },
  //       { key: 'air_jordan', label: 'Air Jordan' },
  //       { key: 'converse', label: 'Converse' },
  //       { key: 'new_balance', label: 'New Balance' },
  //       { key: 'nike', label: 'Nike' },
  //       { key: 'puma', label: 'Puma' },
  //       { key: 'ultraboost', label: 'Ultraboost' },
  //       { key: 'vans', label: 'Vans' },
  //       { key: 'yeezy', label: 'Yeezy' },
  //       { key: 'other', label: 'Other' },
  //     ],
  //   },
  // },
  {
    id: 'price',
    label: <FormattedMessage id="price.label" />,
    type: 'PriceFilter',
    group: 'primary',
    // Note: PriceFilter is fixed filter,
    // you can't change "queryParamNames: ['price'],"
    queryParamNames: ['price'],
    showIf: ['any'],

    // Price filter configuration
    // Note: unlike most prices this is not handled in subunits
    config: {
      min: 0,
      max: 10000,
      step: 5,
    },
  },
  {
    id: 'keyword',
    label: <FormattedMessage id="keyword.label" />,
    type: 'KeywordFilter',
    group: 'primary',
    // Note: KeywordFilter is fixed filter,
    // you can't change "queryParamNames: ['keywords'],"
    queryParamNames: ['keywords'],
    // NOTE: If you are ordering search results by distance
    // the keyword search can't be used at the same time.
    // You can turn on/off ordering by distance from config.js file.
    config: {},
  },

  // Here is an example of multi-enum search filter.
  //
  // {
  //   id: 'amenities',
  //   label: 'Amenities',
  //   type: 'SelectMultipleFilter',
  //   group: 'secondary',
  //   queryParamNames: ['pub_amenities'],
  //   config: {
  //     // Schema type options: 'enum', 'multi-enum'
  //     // Both types can work so that user selects multiple values when filtering search results.
  //     // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
  //     // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
  //     schemaType: 'multi-enum',

  //     // Optional modes: 'has_all', 'has_any'
  //     // Note: this is relevant only for schema type 'multi-enum'
  //     // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
  //     searchMode: 'has_all',

  //     // "key" is the option you see in Flex Console.
  //     // "label" is set here for this web app's UI only.
  //     // Note: label is not added through the translation files
  //     // to make filter customizations a bit easier.
  //     options: [
  //       { key: 'towels', label: 'Towels' },
  //       { key: 'bathroom', label: 'Bathroom' },
  //       { key: 'swimming_pool', label: 'Swimming pool' },
  //       { key: 'barbeque', label: 'Barbeque' },
  //     ],
  //   },
  // },
];

export const sortConfig = {
  // Enable/disable the sorting control in the SearchPage
  active: true,

  // Note: queryParamName 'sort' is fixed,
  // you can't change it since Flex API expects it to be named as 'sort'
  queryParamName: 'sort',

  // Internal key for the relevance option, see notes below.
  relevanceKey: 'relevance',

  // Relevance key is used with keywords filter.
  // Keywords filter also sorts results according to relevance.
  relevanceFilter: 'keywords',

  // Keyword filter is sorting the results by relevance.
  // If keyword filter is active, one might want to disable other sorting options
  // by adding 'keyword' to this list.
  conflictingFilters: [],

  options: [
    { key: 'createdAt', label: <FormattedMessage id="sort.newest" /> },
    { key: '-createdAt', label: <FormattedMessage id="sort.oldest" /> },
    { key: '-price', label: <FormattedMessage id="sort.lowestPrice" /> },
    { key: 'price', label: <FormattedMessage id="sort.highestPrice" /> },

    // The relevance is only used for keyword search, but the
    // parameter isn't sent to the Marketplace API. The key is purely
    // for handling the internal state of the sorting dropdown.
    {
      key: 'relevance',
      label: <FormattedMessage id="sort.relevance" />,
      longLabel: <FormattedMessage id="Sort.relevanceLong" />,
    },
  ],
};

export const listing = {
  // These should be listing details from public data with schema type: enum
  // SectionDetailsMaybe component shows these on listing page.
  enumFieldDetails: ['size', 'brand', 'category'],
};
