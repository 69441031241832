import { FormattedMessage, injectIntl, intlShape } from '../util/reactIntl';

const options = [
  //clothing INT
  {
    key: 'xxs',
    label: 'XXS',
    keyCategory: 'woman',
    productCategory: 'clothing',
    sizeSystem: 'int',
  },
  { key: 'xs', label: 'XS', keyCategory: 'woman', productCategory: 'clothing', sizeSystem: 'int' },
  { key: 's', label: 'S', keyCategory: 'woman', productCategory: 'clothing', sizeSystem: 'int' },
  { key: 'm', label: 'M', keyCategory: 'woman', productCategory: 'clothing', sizeSystem: 'int' },
  { key: 'l', label: 'L', keyCategory: 'woman', productCategory: 'clothing', sizeSystem: 'int' },
  { key: 'xl', label: 'XL', keyCategory: 'woman', productCategory: 'clothing', sizeSystem: 'int' },
  {
    key: 'xxl',
    label: 'XXL',
    keyCategory: 'woman',
    productCategory: 'clothing',
    sizeSystem: 'int',
  },
  {
    key: 'xxxl',
    label: 'XXXL',
    keyCategory: 'woman',
    productCategory: 'clothing',
    sizeSystem: 'int',
  },

  { key: 'xxs', label: 'XXS', keyCategory: 'man', productCategory: 'clothing', sizeSystem: 'int' },
  { key: 'xs', label: 'XS', keyCategory: 'man', productCategory: 'clothing', sizeSystem: 'int' },
  { key: 's', label: 'S', keyCategory: 'man', productCategory: 'clothing', sizeSystem: 'int' },
  { key: 'm', label: 'M', keyCategory: 'man', productCategory: 'clothing', sizeSystem: 'int' },
  { key: 'l', label: 'L', keyCategory: 'man', productCategory: 'clothing', sizeSystem: 'int' },
  { key: 'xl', label: 'XL', keyCategory: 'man', productCategory: 'clothing', sizeSystem: 'int' },
  { key: 'xxl', label: 'XXL', keyCategory: 'man', productCategory: 'clothing', sizeSystem: 'int' },
  {
    key: 'xxxl',
    label: 'XXXL',
    keyCategory: 'man',
    productCategory: 'clothing',
    sizeSystem: 'int',
  },

  //clothing EU
  { key: 'xxs', label: '34', keyCategory: 'woman', productCategory: 'clothing', sizeSystem: 'eu' },
  { key: 'xs', label: '36', keyCategory: 'woman', productCategory: 'clothing', sizeSystem: 'eu' },
  { key: 's', label: '38', keyCategory: 'woman', productCategory: 'clothing', sizeSystem: 'eu' },
  { key: 'm', label: '40', keyCategory: 'woman', productCategory: 'clothing', sizeSystem: 'eu' },
  { key: 'l', label: '42', keyCategory: 'woman', productCategory: 'clothing', sizeSystem: 'eu' },
  { key: 'xl', label: '44', keyCategory: 'woman', productCategory: 'clothing', sizeSystem: 'eu' },
  { key: 'xxl', label: '46', keyCategory: 'woman', productCategory: 'clothing', sizeSystem: 'eu' },
  { key: 'xxxl', label: '48', keyCategory: 'woman', productCategory: 'clothing', sizeSystem: 'eu' },

  { key: 'xxs', label: '44', keyCategory: 'man', productCategory: 'clothing', sizeSystem: 'eu' },
  { key: 'xs', label: '46', keyCategory: 'man', productCategory: 'clothing', sizeSystem: 'eu' },
  { key: 's', label: '48', keyCategory: 'man', productCategory: 'clothing', sizeSystem: 'eu' },
  { key: 'm', label: '50', keyCategory: 'man', productCategory: 'clothing', sizeSystem: 'eu' },
  { key: 'l', label: '52', keyCategory: 'man', productCategory: 'clothing', sizeSystem: 'eu' },
  { key: 'xl', label: '54', keyCategory: 'man', productCategory: 'clothing', sizeSystem: 'eu' },
  { key: 'xxl', label: '56', keyCategory: 'man', productCategory: 'clothing', sizeSystem: 'eu' },
  { key: 'xxxl', label: '58', keyCategory: 'man', productCategory: 'clothing', sizeSystem: 'eu' },

  //clothing UK
  { key: 'xxs', label: '6', keyCategory: 'woman', productCategory: 'clothing', sizeSystem: 'uk' },
  { key: 'xs', label: '8', keyCategory: 'woman', productCategory: 'clothing', sizeSystem: 'uk' },
  { key: 's', label: '10', keyCategory: 'woman', productCategory: 'clothing', sizeSystem: 'uk' },
  { key: 'm', label: '12', keyCategory: 'woman', productCategory: 'clothing', sizeSystem: 'uk' },
  { key: 'l', label: '14', keyCategory: 'woman', productCategory: 'clothing', sizeSystem: 'uk' },
  { key: 'xl', label: '16', keyCategory: 'woman', productCategory: 'clothing', sizeSystem: 'uk' },
  { key: 'xxl', label: '18', keyCategory: 'woman', productCategory: 'clothing', sizeSystem: 'uk' },
  { key: 'xxxl', label: '20', keyCategory: 'woman', productCategory: 'clothing', sizeSystem: 'uk' },

  { key: 'xxs', label: '34', keyCategory: 'man', productCategory: 'clothing', sizeSystem: 'uk' },
  { key: 'xs', label: '36', keyCategory: 'man', productCategory: 'clothing', sizeSystem: 'uk' },
  { key: 's', label: '38', keyCategory: 'man', productCategory: 'clothing', sizeSystem: 'uk' },
  { key: 'm', label: '40', keyCategory: 'man', productCategory: 'clothing', sizeSystem: 'uk' },
  { key: 'l', label: '42', keyCategory: 'man', productCategory: 'clothing', sizeSystem: 'uk' },
  { key: 'xl', label: '44', keyCategory: 'man', productCategory: 'clothing', sizeSystem: 'uk' },
  { key: 'xxl', label: '46', keyCategory: 'man', productCategory: 'clothing', sizeSystem: 'uk' },
  { key: 'xxxl', label: '48', keyCategory: 'man', productCategory: 'clothing', sizeSystem: 'uk' },

  //clothing US
  { key: 'xxs', label: '2', keyCategory: 'woman', productCategory: 'clothing', sizeSystem: 'us' },
  { key: 'xs', label: '4', keyCategory: 'woman', productCategory: 'clothing', sizeSystem: 'us' },
  { key: 's', label: '6', keyCategory: 'woman', productCategory: 'clothing', sizeSystem: 'us' },
  { key: 'm', label: '8', keyCategory: 'woman', productCategory: 'clothing', sizeSystem: 'us' },
  { key: 'l', label: '10', keyCategory: 'woman', productCategory: 'clothing', sizeSystem: 'us' },
  { key: 'xl', label: '12', keyCategory: 'woman', productCategory: 'clothing', sizeSystem: 'us' },
  { key: 'xxl', label: '14', keyCategory: 'woman', productCategory: 'clothing', sizeSystem: 'us' },
  { key: 'xxxl', label: '16', keyCategory: 'woman', productCategory: 'clothing', sizeSystem: 'us' },

  { key: 'xxs', label: '34', keyCategory: 'man', productCategory: 'clothing', sizeSystem: 'us' },
  { key: 'xs', label: '36', keyCategory: 'man', productCategory: 'clothing', sizeSystem: 'us' },
  { key: 's', label: '38', keyCategory: 'man', productCategory: 'clothing', sizeSystem: 'us' },
  { key: 'm', label: '40', keyCategory: 'man', productCategory: 'clothing', sizeSystem: 'us' },
  { key: 'l', label: '42', keyCategory: 'man', productCategory: 'clothing', sizeSystem: 'us' },
  { key: 'xl', label: '44', keyCategory: 'man', productCategory: 'clothing', sizeSystem: 'us' },
  { key: 'xxl', label: '46', keyCategory: 'man', productCategory: 'clothing', sizeSystem: 'us' },
  { key: 'xxxl', label: '48', keyCategory: 'man', productCategory: 'clothing', sizeSystem: 'us' },

  //clothing IT
  { key: 'xxs', label: '38', keyCategory: 'woman', productCategory: 'clothing', sizeSystem: 'it' },
  { key: 'xs', label: '40', keyCategory: 'woman', productCategory: 'clothing', sizeSystem: 'it' },
  { key: 's', label: '42', keyCategory: 'woman', productCategory: 'clothing', sizeSystem: 'it' },
  { key: 'm', label: '44', keyCategory: 'woman', productCategory: 'clothing', sizeSystem: 'it' },
  { key: 'l', label: '46', keyCategory: 'woman', productCategory: 'clothing', sizeSystem: 'it' },
  { key: 'xl', label: '48', keyCategory: 'woman', productCategory: 'clothing', sizeSystem: 'it' },
  { key: 'xxl', label: '50', keyCategory: 'woman', productCategory: 'clothing', sizeSystem: 'it' },
  { key: 'xxxl', label: '52', keyCategory: 'woman', productCategory: 'clothing', sizeSystem: 'it' },

  { key: 'xxs', label: '44', keyCategory: 'man', productCategory: 'clothing', sizeSystem: 'it' },
  { key: 'xs', label: '46', keyCategory: 'man', productCategory: 'clothing', sizeSystem: 'it' },
  { key: 's', label: '48', keyCategory: 'man', productCategory: 'clothing', sizeSystem: 'it' },
  { key: 'm', label: '50', keyCategory: 'man', productCategory: 'clothing', sizeSystem: 'it' },
  { key: 'l', label: '52', keyCategory: 'man', productCategory: 'clothing', sizeSystem: 'it' },
  { key: 'xl', label: '54', keyCategory: 'man', productCategory: 'clothing', sizeSystem: 'it' },
  { key: 'xxl', label: '56', keyCategory: 'man', productCategory: 'clothing', sizeSystem: 'it' },
  { key: 'xxxl', label: '58', keyCategory: 'man', productCategory: 'clothing', sizeSystem: 'it' },

  //clothing kids
  {
    key: '0-1months',
    label: <FormattedMessage id="size.01months" />,
    keyCategory: 'kids',
    productCategory: 'clothing',
  },
  {
    key: '1-3months',
    label: <FormattedMessage id="size.13months" />,
    keyCategory: 'kids',
    productCategory: 'clothing',
  },
  {
    key: '3-6months',
    label: <FormattedMessage id="size.36months" />,
    keyCategory: 'kids',
    productCategory: 'clothing',
  },
  {
    key: '6-9months',
    label: <FormattedMessage id="size.69months" />,
    keyCategory: 'kids',
    productCategory: 'clothing',
  },
  {
    key: '9-12months',
    label: <FormattedMessage id="size.912months" />,
    keyCategory: 'kids',
    productCategory: 'clothing',
  },
  {
    key: '12-18months',
    label: <FormattedMessage id="size.1218months" />,
    keyCategory: 'kids',
    productCategory: 'clothing',
  },
  {
    key: '18-24months',
    label: <FormattedMessage id="size.1824months" />,
    keyCategory: 'kids',
    productCategory: 'clothing',
  },
  {
    key: '2-3years',
    label: <FormattedMessage id="size.23years" />,
    keyCategory: 'kids',
    productCategory: 'clothing',
  },
  {
    key: '3-4years',
    label: <FormattedMessage id="size.34years" />,
    keyCategory: 'kids',
    productCategory: 'clothing',
  },
  {
    key: '4-5years',
    label: <FormattedMessage id="size.45years" />,
    keyCategory: 'kids',
    productCategory: 'clothing',
  },
  {
    key: '6years',
    label: <FormattedMessage id="size.6years" />,
    keyCategory: 'kids',
    productCategory: 'clothing',
  },
  {
    key: '7years',
    label: <FormattedMessage id="size.7years" />,
    keyCategory: 'kids',
    productCategory: 'clothing',
  },
  {
    key: '8years',
    label: <FormattedMessage id="size.8years" />,
    keyCategory: 'kids',
    productCategory: 'clothing',
  },
  {
    key: '9years',
    label: <FormattedMessage id="size.9years" />,
    keyCategory: 'kids',
    productCategory: 'clothing',
  },
  {
    key: '10years',
    label: <FormattedMessage id="size.10years" />,
    keyCategory: 'kids',
    productCategory: 'clothing',
  },
  {
    key: '11-12years',
    label: <FormattedMessage id="size.1112years" />,
    keyCategory: 'kids',
    productCategory: 'clothing',
  },
  {
    key: '13-14years',
    label: <FormattedMessage id="size.1314years" />,
    keyCategory: 'kids',
    productCategory: 'clothing',
  },

  //shoes kids
  { key: '18', label: '18 (10.9 cm)', keyCategory: 'kids', productCategory: 'shoes' },
  { key: '19', label: '19 (11.6 cm)', keyCategory: 'kids', productCategory: 'shoes' },
  { key: '20', label: '20 (12.3 cm)', keyCategory: 'kids', productCategory: 'shoes' },
  { key: '21', label: '21 (12.9 cm)', keyCategory: 'kids', productCategory: 'shoes' },
  { key: '22', label: '22 (13.6 cm)', keyCategory: 'kids', productCategory: 'shoes' },
  { key: '23', label: '23 (14.3 cm)', keyCategory: 'kids', productCategory: 'shoes' },
  { key: '24', label: '24 (14.9 cm)', keyCategory: 'kids', productCategory: 'shoes' },
  { key: '25', label: '25 (15.6 cm)', keyCategory: 'kids', productCategory: 'shoes' },
  { key: '26', label: '26 (16.3 cm)', keyCategory: 'kids', productCategory: 'shoes' },
  { key: '27', label: '27 (16.9 cm)', keyCategory: 'kids', productCategory: 'shoes' },
  { key: '28', label: '28 (17.6 cm)', keyCategory: 'kids', productCategory: 'shoes' },
  { key: '29', label: '29 (18.3 cm)', keyCategory: 'kids', productCategory: 'shoes' },
  { key: '30', label: '30 (18.9 cm)', keyCategory: 'kids', productCategory: 'shoes' },
  { key: '31', label: '31 (19.6 cm)', keyCategory: 'kids', productCategory: 'shoes' },
  { key: '32', label: '32 (20.3 cm)', keyCategory: 'kids', productCategory: 'shoes' },
  { key: '33', label: '33 (20.9 cm)', keyCategory: 'kids', productCategory: 'shoes' },
  { key: '34', label: '34 (21.6 cm)', keyCategory: 'kids', productCategory: 'shoes' },
  { key: '35', label: '35 (22.3 cm)', keyCategory: 'kids', productCategory: 'shoes' },
  { key: '36', label: '36 (22.9 cm)', keyCategory: 'kids', productCategory: 'shoes' },
  { key: '37', label: '37 (23.6 cm)', keyCategory: 'kids', productCategory: 'shoes' },
  { key: '38', label: '38 (24.3 cm)', keyCategory: 'kids', productCategory: 'shoes' },
  { key: '39', label: '39 (24.9 cm)', keyCategory: 'kids', productCategory: 'shoes' },
  { key: '40', label: '40 (25.6 cm)', keyCategory: 'kids', productCategory: 'shoes' },
  { key: '41', label: '41 (26.3 cm)', keyCategory: 'kids', productCategory: 'shoes' },

  //shoes EU
  { key: '35', label: '35', keyCategory: 'woman', productCategory: 'shoes', sizeSystem: 'eu' },
  { key: '36', label: '36', keyCategory: 'woman', productCategory: 'shoes', sizeSystem: 'eu' },
  { key: '37', label: '37', keyCategory: 'woman', productCategory: 'shoes', sizeSystem: 'eu' },
  { key: '38', label: '38', keyCategory: 'woman', productCategory: 'shoes', sizeSystem: 'eu' },
  { key: '39', label: '39', keyCategory: 'woman', productCategory: 'shoes', sizeSystem: 'eu' },
  { key: '40', label: '40', keyCategory: 'woman', productCategory: 'shoes', sizeSystem: 'eu' },
  { key: '41', label: '41', keyCategory: 'woman', productCategory: 'shoes', sizeSystem: 'eu' },
  { key: '42', label: '42', keyCategory: 'woman', productCategory: 'shoes', sizeSystem: 'eu' },

  { key: '38', label: '38', keyCategory: 'man', productCategory: 'shoes', sizeSystem: 'eu' },
  { key: '39', label: '39', keyCategory: 'man', productCategory: 'shoes', sizeSystem: 'eu' },
  { key: '40', label: '40', keyCategory: 'man', productCategory: 'shoes', sizeSystem: 'eu' },
  { key: '41', label: '41', keyCategory: 'man', productCategory: 'shoes', sizeSystem: 'eu' },
  { key: '42', label: '42', keyCategory: 'man', productCategory: 'shoes', sizeSystem: 'eu' },
  { key: '43', label: '43', keyCategory: 'man', productCategory: 'shoes', sizeSystem: 'eu' },
  { key: '44', label: '44', keyCategory: 'man', productCategory: 'shoes', sizeSystem: 'eu' },
  { key: '45', label: '45', keyCategory: 'man', productCategory: 'shoes', sizeSystem: 'eu' },
  { key: '46', label: '46', keyCategory: 'man', productCategory: 'shoes', sizeSystem: 'eu' },

  //shoes UK
  { key: '35', label: '2', keyCategory: 'woman', productCategory: 'shoes', sizeSystem: 'uk' },
  { key: '36', label: '3', keyCategory: 'woman', productCategory: 'shoes', sizeSystem: 'uk' },
  { key: '37', label: '4', keyCategory: 'woman', productCategory: 'shoes', sizeSystem: 'uk' },
  { key: '38', label: '5', keyCategory: 'woman', productCategory: 'shoes', sizeSystem: 'uk' },
  { key: '39', label: '6', keyCategory: 'woman', productCategory: 'shoes', sizeSystem: 'uk' },
  { key: '40', label: '7', keyCategory: 'woman', productCategory: 'shoes', sizeSystem: 'uk' },
  { key: '41', label: '8', keyCategory: 'woman', productCategory: 'shoes', sizeSystem: 'uk' },
  { key: '42', label: '9', keyCategory: 'woman', productCategory: 'shoes', sizeSystem: 'uk' },

  { key: '35', label: '4', keyCategory: 'man', productCategory: 'shoes', sizeSystem: 'uk' },
  { key: '36', label: '5', keyCategory: 'man', productCategory: 'shoes', sizeSystem: 'uk' },
  { key: '37', label: '6', keyCategory: 'man', productCategory: 'shoes', sizeSystem: 'uk' },
  { key: '38', label: '7', keyCategory: 'man', productCategory: 'shoes', sizeSystem: 'uk' },
  { key: '39', label: '8', keyCategory: 'man', productCategory: 'shoes', sizeSystem: 'uk' },
  { key: '40', label: '9', keyCategory: 'man', productCategory: 'shoes', sizeSystem: 'uk' },
  { key: '41', label: '10', keyCategory: 'man', productCategory: 'shoes', sizeSystem: 'uk' },
  { key: '42', label: '11', keyCategory: 'man', productCategory: 'shoes', sizeSystem: 'uk' },
  { key: '43', label: '12', keyCategory: 'man', productCategory: 'shoes', sizeSystem: 'uk' },

  //shoes US
  { key: '35', label: '5', keyCategory: 'woman', productCategory: 'shoes', sizeSystem: 'us' },
  { key: '36', label: '6', keyCategory: 'woman', productCategory: 'shoes', sizeSystem: 'us' },
  { key: '37', label: '6.5', keyCategory: 'woman', productCategory: 'shoes', sizeSystem: 'us' },
  { key: '38', label: '7.5', keyCategory: 'woman', productCategory: 'shoes', sizeSystem: 'us' },
  { key: '39', label: '8', keyCategory: 'woman', productCategory: 'shoes', sizeSystem: 'us' },
  { key: '40', label: '9', keyCategory: 'woman', productCategory: 'shoes', sizeSystem: 'us' },
  { key: '41', label: '10', keyCategory: 'woman', productCategory: 'shoes', sizeSystem: 'us' },
  { key: '42', label: '11', keyCategory: 'woman', productCategory: 'shoes', sizeSystem: 'us' },

  { key: '35', label: '5', keyCategory: 'man', productCategory: 'shoes', sizeSystem: 'us' },
  { key: '36', label: '6', keyCategory: 'man', productCategory: 'shoes', sizeSystem: 'us' },
  { key: '37', label: '7', keyCategory: 'man', productCategory: 'shoes', sizeSystem: 'us' },
  { key: '38', label: '8', keyCategory: 'man', productCategory: 'shoes', sizeSystem: 'us' },
  { key: '39', label: '9', keyCategory: 'man', productCategory: 'shoes', sizeSystem: 'us' },
  { key: '40', label: '10', keyCategory: 'man', productCategory: 'shoes', sizeSystem: 'us' },
  { key: '41', label: '11', keyCategory: 'man', productCategory: 'shoes', sizeSystem: 'us' },
  { key: '42', label: '12', keyCategory: 'man', productCategory: 'shoes', sizeSystem: 'us' },
  { key: '43', label: '13', keyCategory: 'man', productCategory: 'shoes', sizeSystem: 'us' },

  //shoes IT
  { key: '35', label: '35', keyCategory: 'woman', productCategory: 'shoes', sizeSystem: 'it' },
  { key: '36', label: '36', keyCategory: 'woman', productCategory: 'shoes', sizeSystem: 'it' },
  { key: '37', label: '37', keyCategory: 'woman', productCategory: 'shoes', sizeSystem: 'it' },
  { key: '38', label: '38', keyCategory: 'woman', productCategory: 'shoes', sizeSystem: 'it' },
  { key: '39', label: '39', keyCategory: 'woman', productCategory: 'shoes', sizeSystem: 'it' },
  { key: '40', label: '40', keyCategory: 'woman', productCategory: 'shoes', sizeSystem: 'it' },
  { key: '41', label: '41', keyCategory: 'woman', productCategory: 'shoes', sizeSystem: 'it' },
  { key: '42', label: '42', keyCategory: 'woman', productCategory: 'shoes', sizeSystem: 'it' },

  { key: '35', label: '35', keyCategory: 'man', productCategory: 'shoes', sizeSystem: 'it' },
  { key: '36', label: '36', keyCategory: 'man', productCategory: 'shoes', sizeSystem: 'it' },
  { key: '37', label: '37', keyCategory: 'man', productCategory: 'shoes', sizeSystem: 'it' },
  { key: '38', label: '38', keyCategory: 'man', productCategory: 'shoes', sizeSystem: 'it' },
  { key: '39', label: '39', keyCategory: 'man', productCategory: 'shoes', sizeSystem: 'it' },
  { key: '40', label: '40', keyCategory: 'man', productCategory: 'shoes', sizeSystem: 'it' },
  { key: '41', label: '41', keyCategory: 'man', productCategory: 'shoes', sizeSystem: 'it' },
  { key: '42', label: '42', keyCategory: 'man', productCategory: 'shoes', sizeSystem: 'it' },
  { key: '43', label: '43', keyCategory: 'man', productCategory: 'shoes', sizeSystem: 'it' },

  //ring sizes
  { key: 'xs', label: 'XS', keyCategory: 'woman', productType: 'ring' },
  { key: 's', label: 'S', keyCategory: 'woman', productType: 'ring' },
  { key: 'm', label: 'M', keyCategory: 'woman', productType: 'ring' },
  { key: 'l', label: 'L', keyCategory: 'woman', productType: 'ring' },
  { key: 'oneSize', label: 'oneSize', keyCategory: 'woman', productType: 'ring' },

  //belt kids sizes
  { key: '45cm', label: '45 cm', keyCategory: 'kids', productSubcategory: 'belts' },
  { key: '50cm', label: '50 cm', keyCategory: 'kids', productSubcategory: 'belts' },
  { key: '55cm', label: '55 cm', keyCategory: 'kids', productSubcategory: 'belts' },
  { key: '60cm', label: '60 cm', keyCategory: 'kids', productSubcategory: 'belts' },
  { key: '65cm', label: '65 cm', keyCategory: 'kids', productSubcategory: 'belts' },
  { key: '70cm', label: '70 cm', keyCategory: 'kids', productSubcategory: 'belts' },
  { key: '75cm', label: '75 cm', keyCategory: 'kids', productSubcategory: 'belts' },
  { key: '80cm', label: '80 cm', keyCategory: 'kids', productSubcategory: 'belts' },
  { key: '85cm', label: '85 cm', keyCategory: 'kids', productSubcategory: 'belts' },
  { key: '90cm', label: '90 cm', keyCategory: 'kids', productSubcategory: 'belts' },
  { key: '95cm', label: '95 cm', keyCategory: 'kids', productSubcategory: 'belts' },
  { key: '100cm', label: '100 cm', keyCategory: 'kids', productSubcategory: 'belts' },
  { key: '105cm', label: '105 cm', keyCategory: 'kids', productSubcategory: 'belts' },
  { key: '110cm', label: '110 cm', keyCategory: 'kids', productSubcategory: 'belts' },
  { key: '115cm', label: '115 cm', keyCategory: 'kids', productSubcategory: 'belts' },
  { key: '120cm', label: '120 cm', keyCategory: 'kids', productSubcategory: 'belts' },
  { key: '125cm', label: '125 cm', keyCategory: 'kids', productSubcategory: 'belts' },
  { key: '130cm', label: '130 cm', keyCategory: 'kids', productSubcategory: 'belts' },
  { key: '135cm', label: '135 cm', keyCategory: 'kids', productSubcategory: 'belts' },
  { key: 'oneSize', label: 'One Size', keyCategory: 'kids', productSubcategory: 'belts' },

  //hips belt sizes
  { key: 'xs', label: 'XS', keyCategory: 'woman', productType: 'hipsBelt' },
  { key: 's', label: 'S', keyCategory: 'woman', productType: 'hipsBelt' },
  { key: 'm', label: 'M', keyCategory: 'woman', productType: 'hipsBelt' },
  { key: 'l', label: 'L', keyCategory: 'woman', productType: 'hipsBelt' },
  { key: 'xl', label: 'XL', keyCategory: 'woman', productType: 'hipsBelt' },
  { key: 'oneSize', label: 'oneSize', keyCategory: 'woman', productType: 'hipsBelt' },

  { key: 'xs', label: 'XS', keyCategory: 'man', productType: 'hipsBelt' },
  { key: 's', label: 'S', keyCategory: 'man', productType: 'hipsBelt' },
  { key: 'm', label: 'M', keyCategory: 'man', productType: 'hipsBelt' },
  { key: 'l', label: 'L', keyCategory: 'man', productType: 'hipsBelt' },
  { key: 'xl', label: 'XL', keyCategory: 'man', productType: 'hipsBelt' },
  { key: 'oneSize', label: 'oneSize', keyCategory: 'man', productType: 'hipsBelt' },

  //waist belt
  { key: 'xs', label: 'XS', keyCategory: 'woman', productType: 'waistBelt' },
  { key: 's', label: 'S', keyCategory: 'woman', productType: 'waistBelt' },
  { key: 'm', label: 'M', keyCategory: 'woman', productType: 'waistBelt' },
  { key: 'l', label: 'L', keyCategory: 'woman', productType: 'waistBelt' },
  { key: 'xl', label: 'XL', keyCategory: 'woman', productType: 'waistBelt' },
  { key: 'oneSize', label: 'oneSize', keyCategory: 'woman', productType: 'waistBelt' },

  //hats sizes
  { key: 's', label: 'S', keyCategory: 'woman', productSubcategory: 'hats' },
  { key: 'm', label: 'M', keyCategory: 'woman', productSubcategory: 'hats' },
  { key: 'l', label: 'L', keyCategory: 'woman', productSubcategory: 'hats' },
  { key: 'oneSize', label: 'oneSize', keyCategory: 'woman', productSubcategory: 'hats' },

  { key: 's', label: 'S', keyCategory: 'man', productSubcategory: 'hats' },
  { key: 'm', label: 'M', keyCategory: 'man', productSubcategory: 'hats' },
  { key: 'l', label: 'L', keyCategory: 'man', productSubcategory: 'hats' },
  { key: 'xl', label: 'XL', keyCategory: 'man', productSubcategory: 'hats' },
  { key: 'oneSize', label: 'oneSize', keyCategory: 'man', productSubcategory: 'hats' },

  //hat kids sizes
  { key: 'xxs', label: 'XXS (48 cm)', keyCategory: 'kids', productSubcategory: 'hats' },
  { key: 'xs', label: 'XS (50 cm)', keyCategory: 'kids', productSubcategory: 'hats' },
  { key: 's', label: 'S (52 cm)', keyCategory: 'kids', productSubcategory: 'hats' },
  { key: 'm', label: 'M (54 cm)', keyCategory: 'kids', productSubcategory: 'hats' },
  { key: 'l', label: 'L (56 cm)', keyCategory: 'kids', productSubcategory: 'hats' },
  { key: 'oneSize', label: 'oneSize', keyCategory: 'kids', productSubcategory: 'hats' },

  //gloves kids sizes
  { key: 'xxs', label: 'XXS (56 - 80 cm)', keyCategory: 'kids', productSubcategory: 'gloves' },
  { key: 'xs', label: 'XS (80-98 cm)', keyCategory: 'kids', productSubcategory: 'gloves' },
  { key: 's', label: 'S (104-110 cm)', keyCategory: 'kids', productSubcategory: 'gloves' },
  { key: 'm', label: 'M (116-134 cm)', keyCategory: 'kids', productSubcategory: 'gloves' },
  { key: 'l', label: 'L (140-164 cm)', keyCategory: 'kids', productSubcategory: 'gloves' },
  { key: 'oneSize', label: 'oneSize', keyCategory: 'kids', productSubcategory: 'gloves' },

  //gloves sizes
  { key: 'xs', label: 'XS', keyCategory: 'woman', productSubcategory: 'gloves' },
  { key: 's', label: 'S', keyCategory: 'woman', productSubcategory: 'gloves' },
  { key: 'm', label: 'M', keyCategory: 'woman', productSubcategory: 'gloves' },
  { key: 'l', label: 'L', keyCategory: 'woman', productSubcategory: 'gloves' },
  { key: 'oneSize', label: 'oneSize', keyCategory: 'woman', productSubcategory: 'gloves' },

  { key: 'xs', label: 'XS', keyCategory: 'man', productSubcategory: 'gloves' },
  { key: 's', label: 'S', keyCategory: 'man', productSubcategory: 'gloves' },
  { key: 'm', label: 'M', keyCategory: 'man', productSubcategory: 'gloves' },
  { key: 'l', label: 'L', keyCategory: 'man', productSubcategory: 'gloves' },
  { key: 'xl', label: 'XL', keyCategory: 'man', productSubcategory: 'gloves' },
  { key: 'oneSize', label: 'oneSize', keyCategory: 'man', productSubcategory: 'gloves' },

  //clothing baby
  {
    key: '0-1months',
    label: <FormattedMessage id="size.01months" />,
    keyCategory: 'baby',
    productCategory: 'clothing',
  },
  {
    key: '1-3months',
    label: <FormattedMessage id="size.13months" />,
    keyCategory: 'baby',
    productCategory: 'clothing',
  },
  {
    key: '3-6months',
    label: <FormattedMessage id="size.36months" />,
    keyCategory: 'baby',
    productCategory: 'clothing',
  },
  {
    key: '6-9months',
    label: <FormattedMessage id="size.69months" />,
    keyCategory: 'baby',
    productCategory: 'clothing',
  },
  {
    key: '9-12months',
    label: <FormattedMessage id="size.912months" />,
    keyCategory: 'baby',
    productCategory: 'clothing',
  },
  {
    key: '12-18months',
    label: <FormattedMessage id="size.1218months" />,
    keyCategory: 'baby',
    productCategory: 'clothing',
  },
  {
    key: '18-24months',
    label: <FormattedMessage id="size.1824months" />,
    keyCategory: 'baby',
    productCategory: 'clothing',
  },
  {
    key: '2-3years',
    label: <FormattedMessage id="size.23years" />,
    keyCategory: 'baby',
    productCategory: 'clothing',
  },
  {
    key: '3-4years',
    label: <FormattedMessage id="size.34years" />,
    keyCategory: 'baby',
    productCategory: 'clothing',
  },
  {
    key: '4-5years',
    label: <FormattedMessage id="size.45years" />,
    keyCategory: 'baby',
    productCategory: 'clothing',
  },
  {
    key: '6years',
    label: <FormattedMessage id="size.6years" />,
    keyCategory: 'baby',
    productCategory: 'clothing',
  },
  {
    key: '7years',
    label: <FormattedMessage id="size.7years" />,
    keyCategory: 'baby',
    productCategory: 'clothing',
  },
  {
    key: '8years',
    label: <FormattedMessage id="size.8years" />,
    keyCategory: 'baby',
    productCategory: 'clothing',
  },
  {
    key: '9years',
    label: <FormattedMessage id="size.9years" />,
    keyCategory: 'baby',
    productCategory: 'clothing',
  },
  {
    key: '10years',
    label: <FormattedMessage id="size.10years" />,
    keyCategory: 'baby',
    productCategory: 'clothing',
  },
  {
    key: '11-12years',
    label: <FormattedMessage id="size.1112years" />,
    keyCategory: 'baby',
    productCategory: 'clothing',
  },
  {
    key: '13-14years',
    label: <FormattedMessage id="size.1314years" />,
    keyCategory: 'baby',
    productCategory: 'clothing',
  },

  //shoes baby
  { key: '18', label: '18 (10.9 cm)', keyCategory: 'baby', productCategory: 'shoes' },
  { key: '19', label: '19 (11.6 cm)', keyCategory: 'baby', productCategory: 'shoes' },
  { key: '20', label: '20 (12.3 cm)', keyCategory: 'baby', productCategory: 'shoes' },
  { key: '21', label: '21 (12.9 cm)', keyCategory: 'baby', productCategory: 'shoes' },
  { key: '22', label: '22 (13.6 cm)', keyCategory: 'baby', productCategory: 'shoes' },
  { key: '23', label: '23 (14.3 cm)', keyCategory: 'baby', productCategory: 'shoes' },
  { key: '24', label: '24 (14.9 cm)', keyCategory: 'baby', productCategory: 'shoes' },
  { key: '25', label: '25 (15.6 cm)', keyCategory: 'baby', productCategory: 'shoes' },
  { key: '26', label: '26 (16.3 cm)', keyCategory: 'baby', productCategory: 'shoes' },
  { key: '27', label: '27 (16.9 cm)', keyCategory: 'baby', productCategory: 'shoes' },
  { key: '28', label: '28 (17.6 cm)', keyCategory: 'baby', productCategory: 'shoes' },
  { key: '29', label: '29 (18.3 cm)', keyCategory: 'baby', productCategory: 'shoes' },
  { key: '30', label: '30 (18.9 cm)', keyCategory: 'baby', productCategory: 'shoes' },
  { key: '31', label: '31 (19.6 cm)', keyCategory: 'baby', productCategory: 'shoes' },
  { key: '32', label: '32 (20.3 cm)', keyCategory: 'baby', productCategory: 'shoes' },
  { key: '33', label: '33 (20.9 cm)', keyCategory: 'baby', productCategory: 'shoes' },
  { key: '34', label: '34 (21.6 cm)', keyCategory: 'baby', productCategory: 'shoes' },
  { key: '35', label: '35 (22.3 cm)', keyCategory: 'baby', productCategory: 'shoes' },
  { key: '36', label: '36 (22.9 cm)', keyCategory: 'baby', productCategory: 'shoes' },
  { key: '37', label: '37 (23.6 cm)', keyCategory: 'baby', productCategory: 'shoes' },
  { key: '38', label: '38 (24.3 cm)', keyCategory: 'baby', productCategory: 'shoes' },
  { key: '39', label: '39 (24.9 cm)', keyCategory: 'baby', productCategory: 'shoes' },
  { key: '40', label: '40 (25.6 cm)', keyCategory: 'baby', productCategory: 'shoes' },
  { key: '41', label: '41 (26.3 cm)', keyCategory: 'baby', productCategory: 'shoes' },

  //belt baby sizes
  { key: '45cm', label: '45 cm', keyCategory: 'baby', productSubcategory: 'belts' },
  { key: '50cm', label: '50 cm', keyCategory: 'baby', productSubcategory: 'belts' },
  { key: '55cm', label: '55 cm', keyCategory: 'baby', productSubcategory: 'belts' },
  { key: '60cm', label: '60 cm', keyCategory: 'baby', productSubcategory: 'belts' },
  { key: '65cm', label: '65 cm', keyCategory: 'baby', productSubcategory: 'belts' },
  { key: '70cm', label: '70 cm', keyCategory: 'baby', productSubcategory: 'belts' },
  { key: '75cm', label: '75 cm', keyCategory: 'baby', productSubcategory: 'belts' },
  { key: '80cm', label: '80 cm', keyCategory: 'baby', productSubcategory: 'belts' },
  { key: '85cm', label: '85 cm', keyCategory: 'baby', productSubcategory: 'belts' },
  { key: '90cm', label: '90 cm', keyCategory: 'baby', productSubcategory: 'belts' },
  { key: '95cm', label: '95 cm', keyCategory: 'baby', productSubcategory: 'belts' },
  { key: '100cm', label: '100 cm', keyCategory: 'baby', productSubcategory: 'belts' },
  { key: '105cm', label: '105 cm', keyCategory: 'baby', productSubcategory: 'belts' },
  { key: '110cm', label: '110 cm', keyCategory: 'baby', productSubcategory: 'belts' },
  { key: '115cm', label: '115 cm', keyCategory: 'baby', productSubcategory: 'belts' },
  { key: '120cm', label: '120 cm', keyCategory: 'baby', productSubcategory: 'belts' },
  { key: '125cm', label: '125 cm', keyCategory: 'baby', productSubcategory: 'belts' },
  { key: '130cm', label: '130 cm', keyCategory: 'baby', productSubcategory: 'belts' },
  { key: '135cm', label: '135 cm', keyCategory: 'baby', productSubcategory: 'belts' },
  { key: 'oneSize', label: 'One Size', keyCategory: 'baby', productSubcategory: 'belts' },

  //hat baby sizes
  { key: 'xxs', label: 'XXS (48 cm)', keyCategory: 'baby', productSubcategory: 'hats' },
  { key: 'xs', label: 'XS (50 cm)', keyCategory: 'baby', productSubcategory: 'hats' },
  { key: 's', label: 'S (52 cm)', keyCategory: 'baby', productSubcategory: 'hats' },
  { key: 'm', label: 'M (54 cm)', keyCategory: 'baby', productSubcategory: 'hats' },
  { key: 'l', label: 'L (56 cm)', keyCategory: 'baby', productSubcategory: 'hats' },
  { key: 'oneSize', label: 'oneSize', keyCategory: 'baby', productSubcategory: 'hats' },

  //gloves baby sizes
  { key: 'xxs', label: 'XXS (56 - 80 cm)', keyCategory: 'baby', productSubcategory: 'gloves' },
  { key: 'xs', label: 'XS (80-98 cm)', keyCategory: 'baby', productSubcategory: 'gloves' },
  { key: 's', label: 'S (104-110 cm)', keyCategory: 'baby', productSubcategory: 'gloves' },
  { key: 'm', label: 'M (116-134 cm)', keyCategory: 'baby', productSubcategory: 'gloves' },
  { key: 'l', label: 'L (140-164 cm)', keyCategory: 'baby', productSubcategory: 'gloves' },
  { key: 'oneSize', label: 'oneSize', keyCategory: 'baby', productSubcategory: 'gloves' },
];

export default options;
