import React, { useState, useEffect } from 'react';
import { Form as FinalForm, FormSpy, Field } from 'react-final-form';
import * as validators from '../../../util/validators';
import arrayMutators from 'final-form-arrays';
import { Button, FieldTextInput, FieldSelect, FieldDateInput } from '../../../components';
import AutocompleteField from '../../AutocompleteField/AutoCompleteField';
import config from '../../../config';
import { post } from '../../../util/api';
import { required as selectRequired } from '../../../util/validators';
import { FormattedMessage } from 'react-intl';
import css from './ProductOrderForm.module.css';
import { getAddressLabel } from './Helpers';
import { updateProfile } from '../../../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import { Grid } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import PricesTable from './PricesTable';
import DeleteIcon from '@mui/icons-material/Delete';
const isEnglish = process.env.REACT_APP_LANGUAGE === 'en';
const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});

const DeliveryAddressForm = props => {
  const {
    //customer
    // currentUser,
    intl,
    handleSubmit,
    quantity,
    handleModalOpen,
    //provider
    isProvider,
    modalOpen,
  } = props;

  const [currentUser, setCurrentUser] = useState(props.currentUser);
  const [stateData, setStateData] = useState(null);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [phoneNumberError, setPhoneNumberError] = useState(null);
  const [officeOptions, setOfficeOptions] = useState([]);
  const [submitError, setSubmitError] = useState(null);
  const [reload, setReload] = useState(false);
  const speedyOfficesOptions = config.speedyOfficesOptions;
  // const selectOfficesFieldOptions = speedyOfficesOptions
  //   .map(o => {
  //     return {
  //       key: o.id,
  //       label: isEnglish ? o.nameEn : o.name,
  //     };
  //   })
  //   .sort((a, b) => a.label.localeCompare(b.label));

  const selectSiteFieldOptions = config.sitesOptions
    .map(o => {
      return {
        key: o.id,
        label: isEnglish ? o.nameEn : o.name,
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  //we need to fetch current user info so that the deleted addresses won't show up again on modal switching
  useEffect(() => {
    sdk.currentUser
      .show()
      .then(resp => {
        setCurrentUser(resp.data.data);
      })
      .catch(e => {
        console.log(e);
      });
  }, [modalOpen, reload]);

  useEffect(() => {
    if (currentUser && currentUser.attributes?.profile) {
      // const userAddresses = currentUser?.attributes?.profile.publicData?.savedAddresses ?? [];
      // if (userAddresses.length > 0) {
      //   setStateData('addresses-list');
      // } else {
      setStateData('select-delivery-method');
      // }
    }
  }, [currentUser, modalOpen]);

  const onSubmit = values => {
    const { pickupDate, pickupTime, lastPickupTime } = values;
    return post('/api/check-phone-number', { testedPhoneNumber: values.phoneNumber })
      .then(resp => {
        if (resp) {
          setPhoneNumberError(false);
          const userAddresses = currentUser?.attributes?.profile.publicData?.savedAddresses ?? [];

          if (
            (stateData === 'addresses-list-office' || stateData === 'addresses-list-address') &&
            selectedAddress
          ) {
            const selectedAddressInfo = userAddresses.find(a => a.id === selectedAddress);

            const protectedDataInfo = isProvider
              ? {
                  providerShippingAddress: selectedAddressInfo,
                  pickupDate,
                  pickupTime,
                  lastPickupTime,
                }
              : {
                  customerShippingAddress: selectedAddressInfo,
                  quantity,
                };

            return handleSubmit(protectedDataInfo)
              .then(resp => {
                setSubmitError(null);
                handleModalOpen(false);
              })
              .catch(e => {
                setSubmitError(e?.message);
              });
          } else {
            //the speedy office value only contains the id, we convert it to the full address
            if (values.speedyOffice) {
              values.speedyOffice = speedyOfficesOptions.find(
                o => o.id.toString() === values.speedyOffice.toString()
              );
            }
            //
            const newAddress = {
              ...values,
            };
            newAddress.id = (Number(userAddresses.length) + 1).toString();
            delete newAddress.pickupDate;
            delete newAddress.pickupTime;
            delete newAddress.lastPickupTime;

            userAddresses.push(newAddress);

            return sdk.currentUser
              .updateProfile({
                publicData: {
                  savedAddresses: userAddresses,
                },
              })
              .then(resp => {
                const protectedDataInfo = isProvider
                  ? {
                      providerShippingAddress: newAddress,
                      pickupDate,
                      pickupTime,
                      lastPickupTime,
                    }
                  : {
                      customerShippingAddress: newAddress,
                      quantity,
                    };

                return handleSubmit(protectedDataInfo)
                  .then(resp => {
                    setSubmitError(null);
                    handleModalOpen(false);
                  })
                  .catch(e => {
                    setSubmitError(e?.message);
                  });
              })
              .catch(e => {
                console.log(e);
              });
          }
        } else {
          setPhoneNumberError(true);
        }
      })
      .catch(e => {
        setPhoneNumberError(true);
      });

    // handleSubmit()
  };

  const handleOnChange = formValues => {
    // console.log(formValues.values)
  };
  const currentUserAddresses = currentUser?.attributes?.profile.publicData?.savedAddresses ?? [];
  const hasAnyAddressSaved = currentUserAddresses.length > 0;
  if (!stateData) {
    return null;
  }

  const handleDeleteSavedAddress = id => {
    const newAddresses = currentUserAddresses.filter(x => x.id !== id);

    return sdk.currentUser
      .updateProfile({
        publicData: {
          savedAddresses: newAddresses,
        },
      })
      .then(resp => {
        setReload(!reload);
      })
      .catch(e => console.log(e));
  };

  return (
    <div>
      <FinalForm
        onSubmit={onSubmit}
        mutators={{ ...arrayMutators }}
        render={fieldRenderProps => {
          const {
            handleSubmit,
            invalid,
            pristine,
            submitting,
            formName,
            emailSend,
            values,
          } = fieldRenderProps;

          const deliveryPlaceField = ({ input: { onChange, value }, label, ...rest }) => (
            <div className={css.addressButtonsWrapper}>
              <div
                className={
                  values.deliveryPlace === 'speedyOffice'
                    ? css.addressButtonSelected
                    : css.addressButton
                }
                onClick={() => {
                  const savedOffices =
                    currentUser?.attributes?.profile.publicData?.savedAddresses?.filter(
                      i => i?.deliveryPlace === 'speedyOffice'
                    ) || [];
                  if (savedOffices.length > 0) {
                    setStateData('addresses-list-office');
                  } else {
                    onChange('speedyOffice');
                    setStateData('new-office');
                  }
                }}
              >
                {intl.formatMessage({
                  id: isProvider
                    ? 'ProductOrderForm.deliveryModalButton1Provider'
                    : 'ProductOrderForm.deliveryModalButton1',
                })}
              </div>

              <div className={css.addressButtonsMidText}>
                {intl.formatMessage({ id: 'ProductOrderForm.or' })}
              </div>

              <div
                className={
                  // css.addressButtonDisabled - for disabled address delivery
                  values.deliveryPlace === 'address' ? css.addressButtonSelected : css.addressButton
                }
                onClick={() => {
                  const savedAddresses =
                    currentUser?.attributes?.profile.publicData?.savedAddresses?.filter(
                      i => i?.deliveryPlace === 'address'
                    ) || [];
                  if (savedAddresses.length > 0) {
                    setStateData('addresses-list-address');
                  } else {
                    onChange('address');
                    setStateData('new-address');
                  }
                }}
              >
                {intl.formatMessage({
                  id: isProvider
                    ? 'ProductOrderForm.deliveryModalButton2Provider'
                    : 'ProductOrderForm.deliveryModalButton2',
                })}
                {/* <span className={css.commingSoonText}>
                  {intl.formatMessage({ id: 'ProductOrderForm.commingSoon' })}
                </span> */}
              </div>
            </div>
          );

          const countriesOptions = config.countriesOptions.map(o => {
            return {
              id: o.id,
              name: o.name,
              nameEn: o.nameEn,
            };
          });
          const sitesOptions = config.sitesOptions.map(o => {
            return {
              id: o.id,
              name: o.name,
              nameEn: o.nameEn,
            };
          });

          const countryField = ({ input: { onChange, value }, label, ...rest }) => {
            return (
              <div>
                <div className={css.inputLabel}>
                  <FormattedMessage id="ProfileSettingsForm.countryLabel" />
                </div>
                <AutocompleteField
                  suggestions={countriesOptions.map(c => c.name)}
                  fullInfoSuggestions={countriesOptions}
                  initialValue={value}
                  onChangeValue={onChange}
                  placeholder={intl.formatMessage({ id: 'DeliveryAddressForm.countryPlaceholder' })}
                  labelPropName={'name'}
                />
              </div>
            );
          };

          const siteField = ({ input: { onChange, value }, label, ...rest }) => {
            return (
              <div>
                <div className={css.inputLabel}>
                  <FormattedMessage id="ProfileSettingsForm.siteLabel" />
                </div>
                <AutocompleteField
                  suggestions={sitesOptions.map(c => c.name)}
                  fullInfoSuggestions={sitesOptions}
                  initialValue={value}
                  onChangeValue={onChange}
                  placeholder={intl.formatMessage({ id: 'ProfileSettingsForm.cityPlaceholder' })}
                  labelPropName={'name'}
                />
              </div>
            );
          };

          const streetField = ({ input: { onChange, value }, label, ...rest }) => {
            const [loadingResults, setLoadingResults] = useState(false);
            const [streetOptions, setStreetOptions] = useState([]);

            const asyncGetOptions = streetName => {
              setLoadingResults(true);
              return post('/api/get-location-info', {
                endpoint: `location/street`,
                searchTarget: 'street',
                siteId: values.site.id,
                streetName: streetName,
              })
                .then(resp => {
                  setLoadingResults(false);
                  setStreetOptions(
                    resp.streets?.map(o => {
                      return {
                        id: o.id,
                        name: o.name,
                        nameEn: o.nameEn,
                      };
                    })
                  );

                  const data = resp.streets?.map(o => o.name);

                  return data;
                })
                .catch(e => {
                  //ignore error
                });
            };
            return (
              <div>
                <div className={css.inputLabel}>
                  <FormattedMessage id="ProfileSettingsForm.streetLabel" />
                </div>
                <AutocompleteField
                  suggestions={streetOptions.map(c => c.name)}
                  fullInfoSuggestions={streetOptions}
                  initialValue={value}
                  onChangeValue={onChange}
                  placeholder={intl.formatMessage({
                    id: 'DeliveryAddressForm.streetPlaceholder',
                  })}
                  labelPropName={'name'}
                  loadingResults={loadingResults}
                  setLoadingResults={setLoadingResults}
                  asyncGetOptions={asyncGetOptions}
                />
              </div>
            );
          };

          const siteId = values && values?.site?.id;

          useEffect(() => {
            if (siteId) {
              post('/api/get-location-info', {
                endpoint: `location/office`,
                searchTarget: 'office',
                countryId: 100,
                siteId,
              })
                .then(resp => {
                  setOfficeOptions(resp.offices);
                })
                .catch(e => console.log(e));
            }
          }, [siteId]);

          const officeField = ({ input: { onChange, value }, label, ...rest }) => {
            // const [loadingResults, setLoadingResults] = useState(false);
            // const [officeOptions, setOfficeOptions] = useState([]);

            // const asyncGetOptions = () => {
            //   setLoadingResults(true);
            //   return post('/api/get-location-info', {
            //     endpoint: `location/office`,
            //     searchTarget: 'office',
            //     countryId: 100,
            //     siteId,
            //   })
            //     .then(resp => {
            //       setLoadingResults(false);
            //       setOfficeOptions(resp.offices);

            //       const data = resp.offices?.map(o => o.name);

            //       return data;
            //     })
            //     .catch(e => {
            //       //ignore error
            //     });
            // };
            return (
              <div>
                <div className={css.inputLabel}>
                  <FormattedMessage id="ProfileSettingsForm.speedyOffice" />
                </div>
                <AutocompleteField
                  suggestions={officeOptions.map(c => c.name)}
                  fullInfoSuggestions={officeOptions}
                  initialValue={value}
                  onChangeValue={onChange}
                  placeholder={intl.formatMessage({
                    id: 'DeliveryAddressForm.speedyOfficePlaceholder',
                  })}
                  labelPropName={'name'}
                  searchForAny={true}
                  // loadingResults={loadingResults}
                  // setLoadingResults={setLoadingResults}
                  // asyncGetOptions={asyncGetOptions}
                />
              </div>
            );
          };

          const complexField = ({ input: { onChange, value }, label, ...rest }) => {
            const [loadingResults, setLoadingResults] = useState(false);
            const [complexOptions, setComplexOptions] = useState([]);

            const asyncGetOptions = streetName => {
              setLoadingResults(true);
              return post('/api/get-location-info', {
                endpoint: `location/complex`,
                searchTarget: 'complex',
                siteId: values.site.id,
                streetName: streetName,
              })
                .then(resp => {
                  setLoadingResults(false);
                  setComplexOptions(
                    resp.complexes.map(o => {
                      return {
                        id: o.id,
                        name: o.name,
                        nameEn: o.nameEn,
                      };
                    })
                  );

                  const data = resp.complexes?.map(o => o.name);

                  return data;
                })
                .catch(e => {
                  //ignore error
                });
            };
            return (
              <div>
                <div className={css.inputLabel}>
                  <FormattedMessage id="ProfileSettingsForm.ComplexLabel" />
                </div>
                <AutocompleteField
                  suggestions={complexOptions.map(c => c.name)}
                  fullInfoSuggestions={complexOptions}
                  initialValue={value}
                  onChangeValue={onChange}
                  placeholder={intl.formatMessage({ id: 'DeliveryAddressForm.complexPlaceholder' })}
                  labelPropName={'name'}
                  loadingResults={loadingResults}
                  setLoadingResults={setLoadingResults}
                  asyncGetOptions={asyncGetOptions}
                  startSearchLetter={0}
                />
              </div>
            );
          };

          const DeliveryAddressOption = props => {
            const data = props.data;
            const isSpeedyOffice = data?.deliveryPlace === 'speedyOffice';
            const cashPayment = data?.speedyOffice?.cashPaymentAllowed;
            const addressLabel = getAddressLabel(data);
            const priceLabel =
              data.deliveryPlace === 'speedyOffice' ? (
                <FormattedMessage id="DeliveryAddressForm.speedyOfficeDeliveryPrice" />
              ) : (
                <FormattedMessage id="DeliveryAddressForm.ownAddressDeliveryPrice" />
              );
            return (
              <div
                className={
                  isSpeedyOffice && !cashPayment
                    ? css.selectAddressOptionDisabled
                    : selectedAddress === data.id
                    ? css.selectAddressOptionSelected
                    : css.selectAddressOptionNotSelected
                }
              >
                <Grid container item xs={12} justifyContent="flex-start">
                  <Grid
                    container
                    item
                    xs={11}
                    justifyContent="flex-start"
                    alignContent="center"
                    alignItems="center"
                    style={{
                      lineHeight: '22px',
                    }}
                    onClick={() => {
                      if (isSpeedyOffice && cashPayment) {
                        setSelectedAddress(data.id);
                      } else if (!isSpeedyOffice) {
                        setSelectedAddress(data.id);
                      }
                    }}
                  >
                    {addressLabel}
                  </Grid>

                  <Grid
                    container
                    item
                    xs={1}
                    justifyContent="flex-end"
                    alignContent="center"
                    alignItems="center"
                  >
                    <DeleteIcon
                      className={css.deleteIcon}
                      onClick={() => handleDeleteSavedAddress(data.id)}
                    />
                  </Grid>
                  {/* <Grid
                    container
                    item
                    xs={2}
                    justifyContent="flex-end"
                    alignContent="center"
                    alignItems="center"
                  >
                    {priceLabel}
                  </Grid> */}
                  {/* <Grid container item xs={2} justifyContent='flex-end' alignContent='center' alignItems='center'>
                <EditIcon/>
              </Grid> */}
                </Grid>
              </div>
            );
          };

          const required = validators.required('This field is required');
          const submitDisabled =
            stateData === 'addresses-list-address' || stateData === 'addresses-list-office'
              ? false
              : (stateData === 'new-address' || stateData === 'new-office') &&
                values.deliveryPlace !== 'speedyOffice'
              ? !values.country ||
                !values.site ||
                // !values.streetName ||
                // !values.streetNumber ||
                // !values.complex ||
                // !values.blockNumber ||
                // !values.entranceNumber ||
                // !values.apartmentNo ||
                !values.fullName ||
                !values.phoneNumber
              : invalid || pristine || submitting;
          const hideSubmitButton =
            stateData === 'addresses-list-address' || stateData === 'addresses-list-office'
              ? !!!selectedAddress
              : !values.deliveryPlace;

          const focusedAddress = currentUserAddresses?.find(
            x => x?.id?.toString() === selectedAddress?.toString()
          );

          const hoursArray = [
            {
              key: '08:00',
              label: '08:00',
            },
            {
              key: '08:30',
              label: '08:30',
            },
            {
              key: '09:00',
              label: '09:00',
            },
            {
              key: '09:30',
              label: '09:30',
            },
            {
              key: '10:00',
              label: '10:00',
            },

            {
              key: '10:30',
              label: '10:30',
            },
            {
              key: '11:00',
              label: '11:00',
            },
            {
              key: '11:30',
              label: '11:30',
            },
            {
              key: '12:00',
              label: '12:00',
            },

            {
              key: '12:30',
              label: '12:30',
            },
            {
              key: '13:00',
              label: '13:00',
            },

            {
              key: '13:30',
              label: '13:30',
            },

            {
              key: '14:00',
              label: '14:00',
            },

            {
              key: '14:30',
              label: '14:30',
            },

            {
              key: '15:00',
              label: '15:00',
            },

            {
              key: '15:30',
              label: '15:30',
            },

            {
              key: '16:00',
              label: '16:00',
            },

            {
              key: '16:30',
              label: '16:30',
            },

            {
              key: '17:00',
              label: '17:00',
            },
          ];

          const isSameDay = (date1, date2) => {
            const date1Date = new Date(date1).getDate();
            const date2Date = new Date(date2).getDate();
            const date1Month = new Date(date1).getMonth();
            const date2Month = new Date(date2).getMonth();
            const date1Year = new Date(date1).getFullYear();
            const date2Year = new Date(date2).getFullYear();
            const sameDate = date1Date === date2Date;
            const sameMonth = date1Month === date2Month;
            const sameYear = date1Year === date2Year;

            return sameDate && sameMonth && sameYear;
          };

          const today = new Date();
          const todaySelectedForPickup =
            values?.pickupDate?.date && isSameDay(today, values.pickupDate.date);

          return (
            <form
              onSubmit={e => {
                e.preventDefault();
                handleSubmit(e);
              }}
            >
              <FormSpy onChange={handleOnChange} />
              {/* <FieldTextInput
            className={css.field}
            type="text"
            id="textInput"
            name="textInput"
            label="Text input"
            placeholder="text"
            validate={required}
          /> */}

              {isProvider && (
                <>
                  <center>
                    <h1 className={css.modalTitle}>
                      <FormattedMessage id={'TransactionPanel.providerShippingDetailsModalTitle'} />
                    </h1>
                  </center>

                  <center>
                    <p className={css.modalSubtitle}>
                      <FormattedMessage
                        id={
                          stateData === 'addresses-list-address' ||
                          stateData === 'addresses-list-office'
                            ? 'TransactionPanel.providerShippingDetailsModalSubtitleList'
                            : 'TransactionPanel.providerShippingDetailsModalSubtitleNew'
                        }
                      />
                    </p>
                  </center>
                </>
              )}

              {(stateData === 'addresses-list-address' ||
                stateData === 'addresses-list-office') && (
                <>
                  {currentUserAddresses
                    .filter(i => {
                      if (stateData === 'addresses-list-address') {
                        return i?.deliveryPlace === 'address';
                      } else {
                        return i?.deliveryPlace === 'speedyOffice';
                      }
                    })
                    .map(x => {
                      return <DeliveryAddressOption data={x} />;
                    })}

                  <div
                    className={hideSubmitButton ? css.addAddressButton : css.addAddressButton2}
                    onClick={() => {
                      if (stateData === 'addresses-list-address') {
                        setStateData('new-address');
                      } else {
                        setStateData('new-office');
                      }

                      setSelectedAddress(null);
                    }}
                  >
                    <FormattedMessage id="DeliveryAddressForm.addAddressButton" />
                  </div>
                </>
              )}

              {stateData === 'select-delivery-method' && (
                <Field
                  name="deliveryPlace"
                  // label="Adults"
                  component={deliveryPlaceField}
                />
              )}

              {stateData === 'new-office' && (
                <div className={css.ownAddressOption}>
                  {/* <Field name="site" label="Site" component={siteField} />

                      <Field
                        name="speedyOffice"
                        // label="Street"
                        component={officeField}
                      /> */}

                  <FieldSelect
                    id={`site`}
                    className={css.speedyOfficeField}
                    name="site"
                    label={intl.formatMessage({ id: 'ProfileSettingsForm.siteLabel' })}
                    validate={selectRequired(
                      intl.formatMessage({ id: 'DeliveryAddressForm.requiredField' })
                    )}
                  >
                    <option disabled value="" hidden>
                      {intl.formatMessage({
                        id: 'ProfileSettingsForm.cityPlaceholder',
                      })}
                    </option>
                    {selectSiteFieldOptions
                      .filter(s => {
                        return speedyOfficesOptions.find(
                          o => o.siteId.toString() === s.key.toString()
                        ); //only show sites that have speedy offices
                      })
                      .sort((a, b) => a.label.localeCompare(b.label))
                      .map(o => {
                        return <option value={o.key}>{o.label}</option>;
                      })}
                  </FieldSelect>

                  {values.site && (
                    <FieldSelect
                      id={`speedyOffice`}
                      className={css.speedyOfficeField}
                      name="speedyOffice"
                      label={intl.formatMessage({ id: 'ProfileSettingsForm.speedyOffice' })}
                      validate={selectRequired(
                        intl.formatMessage({ id: 'DeliveryAddressForm.requiredField' })
                      )}
                    >
                      <option disabled value="" hidden>
                        {intl.formatMessage({
                          id: 'DeliveryAddressForm.speedyOfficePlaceholder',
                        })}
                      </option>
                      {speedyOfficesOptions
                        .filter(o => o.siteId.toString() === values.site.toString())
                        .map(o => {
                          return {
                            key: o.id,
                            label: isEnglish ? o.nameEn : o.name,
                          };
                        })
                        .sort((a, b) => a.label.localeCompare(b.label))
                        .map(o => {
                          return <option value={o.key}>{o.label}</option>;
                        })}
                    </FieldSelect>
                  )}

                  <FieldTextInput
                    className={css.formInput}
                    type="text"
                    id="fullName"
                    name="fullName"
                    label={intl.formatMessage({ id: 'DeliveryAddressForm.fullNameLabel' })}
                    placeholder={intl.formatMessage({
                      id: 'DeliveryAddressForm.fullNamePlaceholder',
                    })}
                    validate={required}
                  />

                  <FieldTextInput
                    className={css.formInput}
                    type="phone"
                    id="phoneNumber"
                    name="phoneNumber"
                    label={intl.formatMessage({
                      id: 'DeliveryAddressForm.phoneNumberLabel',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'DeliveryAddressForm.phoneNumberPlaceholder',
                    })}
                    validate={required}
                  />
                  {phoneNumberError && (
                    <p style={{ color: 'red', marginTop: '10px' }}>
                      <FormattedMessage id="DeliveryAddressForm.phoneNumberError" />
                    </p>
                  )}
                </div>
              )}

              {stateData === 'new-address' && (
                <div className={css.ownAddressOption}>
                  <Field
                    name="country"
                    label={intl.formatMessage({ id: 'DeliveryAddressForm.countryLabel' })}
                    component={countryField}
                  />

                  <Field
                    name="site"
                    label={intl.formatMessage({ id: 'DeliveryAddressForm.siteLabel' })}
                    component={siteField}
                  />

                  <Field
                    name="streetName"
                    label={intl.formatMessage({ id: 'DeliveryAddressForm.streetLabel' })}
                    component={streetField}
                  />

                  <FieldTextInput
                    type="text"
                    id="streetNumber"
                    name="streetNumber"
                    label={intl.formatMessage({
                      id: 'DeliveryAddressForm.streetNumberLabel',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'DeliveryAddressForm.streetNumberPlaceholder',
                    })}
                    className={css.formInput}
                  />

                  <Field
                    name="complex"
                    label={intl.formatMessage({ id: 'DeliveryAddressForm.complexLabel' })}
                    component={complexField}
                  />

                  <FieldTextInput
                    type="text"
                    id="block"
                    name="blockNumber"
                    label={intl.formatMessage({ id: 'DeliveryAddressForm.blockLabel' })}
                    placeholder={intl.formatMessage({
                      id: 'DeliveryAddressForm.blockPlaceholder',
                    })}
                    className={css.formInput}
                  />

                  <FieldTextInput
                    type="text"
                    id="entrance"
                    name="entranceNumber"
                    label={intl.formatMessage({ id: 'DeliveryAddressForm.entranceLabel' })}
                    placeholder={intl.formatMessage({
                      id: 'DeliveryAddressForm.entrancePlaceholder',
                    })}
                    className={css.formInput}
                  />

                  <FieldTextInput
                    type="text"
                    id="apartmentNo"
                    name="apartmentNo"
                    label={intl.formatMessage({ id: 'DeliveryAddressForm.apartmentLabel' })}
                    placeholder={intl.formatMessage({
                      id: 'DeliveryAddressForm.apartmentPlaceholder',
                    })}
                    className={css.formInput}
                  />

                  <FieldTextInput
                    className={css.formInput}
                    type="text"
                    id="fullName"
                    name="fullName"
                    label={intl.formatMessage({ id: 'DeliveryAddressForm.fullNameLabel' })}
                    placeholder={intl.formatMessage({
                      id: 'DeliveryAddressForm.fullNamePlaceholder',
                    })}
                    validate={required}
                  />

                  <FieldTextInput
                    className={css.formInput}
                    type="phone"
                    id="phoneNumber"
                    name="phoneNumber"
                    label={intl.formatMessage({
                      id: 'DeliveryAddressForm.phoneNumberLabel',
                    })}
                    placeholder={intl.formatMessage({
                      id: 'DeliveryAddressForm.phoneNumberPlaceholder',
                    })}
                    validate={required}
                  />
                  {phoneNumberError && (
                    <p style={{ color: 'red', marginTop: '10px' }}>
                      <FormattedMessage id="DeliveryAddressForm.phoneNumberError" />
                    </p>
                  )}
                </div>
              )}

              {stateData === 'select-delivery-method' && 
                <PricesTable 
                  isProvider={isProvider} />}

              {(stateData === 'new-address' || focusedAddress?.deliveryPlace === 'address') &&
              isProvider ? (
                <div className={css.pickupTimeWrapper}>
                  <FieldDateInput
                    id="pickupDate"
                    name="pickupDate"
                    className={css.pickupDate}
                    label={intl.formatMessage({ id: 'DeliveryAddressForm.pickupDateLabel' })}
                    placeholderText={intl.formatMessage({
                      id: 'DeliveryAddressForm.pickupDatePlaceholder',
                    })}
                    validate={required}
                    isDateBlockedFn={date => {
                      const isSunday = new Date(date).getDay() === 0;
                      return isSunday;
                    }}
                  />

                  <FieldSelect
                    id={`pickupTime`}
                    className={css.pickupTime}
                    name="pickupTime"
                    label={intl.formatMessage({ id: 'ProfileSettingsForm.pickupTimeLabel' })}
                    validate={selectRequired(
                      intl.formatMessage({ id: 'DeliveryAddressForm.requiredField' })
                    )}
                  >
                    <option disabled value="" hidden>
                      {intl.formatMessage({
                        id: 'ProfileSettingsForm.selectPlaceholder',
                      })}
                    </option>
                    {hoursArray
                      .filter(time => {
                        if (todaySelectedForPickup) {
                          const workingHour = time.key.split(':')[0];
                          const todayHour = new Date().getHours();

                          return Number(workingHour) > Number(todayHour) + 1;
                        } else {
                          return true;
                        }
                      })
                      .map(o => {
                        return <option value={o.key}>{o.label}</option>;
                      })}
                  </FieldSelect>

                  <FieldSelect
                    id={`lastPickupTime`}
                    className={css.lastPickupTime}
                    name="lastPickupTime"
                    label={intl.formatMessage({ id: 'ProfileSettingsForm.lastPickupTimeLabel' })}
                    validate={selectRequired(
                      intl.formatMessage({ id: 'DeliveryAddressForm.requiredField' })
                    )}
                  >
                    <option disabled value="" hidden>
                      {intl.formatMessage({
                        id: 'ProfileSettingsForm.selectPlaceholder',
                      })}
                    </option>
                    {hoursArray
                      .filter(time => {
                        if (todaySelectedForPickup) {
                          const workingHour = time.key.split(':')[0];
                          const todayHour = new Date().getHours();

                          return Number(workingHour) > Number(todayHour) + 1;
                        } else {
                          return true;
                        }
                      })
                      .map(o => {
                        return <option value={o.key}>{o.label}</option>;
                      })}
                  </FieldSelect>
                </div>
              ) : null}

              {submitError && (
                <center>
                  <p style={{ color: 'red', lineHeight: '22px' }}>{submitError}</p>
                </center>
              )}

              {!hideSubmitButton && (
                <Button
                  className={submitDisabled ? css.submitAddressFormDisabled : css.submitAddressForm}
                  type="submit"
                  disabled={submitDisabled}
                >
                  <FormattedMessage
                    id={
                      isProvider
                        ? 'TransactionPage.generateShippingLabel'
                        : 'ProfileSettingsForm.sendRequest'
                    }
                  />
                </Button>
              )}
            </form>
          );
        }}
      />
    </div>
  );
};

export default DeliveryAddressForm;
