/**
* Use the CSS tab above to style your Element's container.
*/
import React from 'react';
import {CardElement} from '@stripe/react-stripe-js';
import {useStripe, useElements} from '@stripe/react-stripe-js';
import './CardSection.css'
const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: "#32325d",
      fontFamily: '"sofiapro", Helvetica, Arial, sans-serif',
      fontSmoothing: "antialiased",
      fontSize: "18px",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
      iconColor: "#fa755a",
    },
  },
};
function CardSection(props) {
  const handleStripeToken = props.handleStripeToken;
  const stripe = useStripe();
  const elements = useElements();

  const handleChange = (event) => {

      return handleStripeToken(event, stripe, elements)
  }

  return (
    <form >
        <label>
          <CardElement options={CARD_ELEMENT_OPTIONS} onChange={handleChange} handleStripeToken={handleStripeToken}/>
        </label>
    </form>
  );
};
export default CardSection;