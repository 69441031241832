import React, { useState, useEffect } from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { arrayOf, array, bool, func, node, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import config from '../../config';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import {
  propTypes,
  LISTING_STATE_CLOSED,
  LINE_ITEM_NIGHT,
  LINE_ITEM_DAY,
  LINE_ITEM_UNITS,
} from '../../util/types';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import { userDisplayNameAsString } from '../../util/data';
import {
  ModalInMobile,
  Button,
  AvatarSmall,
  AvatarMedium,
  AvatarLarge,
  NamedLink,
  Modal,
} from '../../components';
import { ensureUser, ensureCurrentUser } from '../../util/data';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import BookingDatesForm from './BookingDatesForm/BookingDatesForm';
import ProductOrderForm from './ProductOrderForm/ProductOrderForm';
import css from './OrderPanel.module.css';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import BumpListingForm from './BumpListingForm/BumpListingForm';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { findConfigForSelectFilter } from '../../util/search';
import { isArray } from 'lodash';
import { types as sdkTypes } from '../../util/sdkLoader';
import messageIcon from './MessageIcon.svg';
import routeConfiguration from '../../routing/routeConfiguration';
import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';

import DeliveryAddressForm from './ProductOrderForm/DeliveryAddressForm';
const { UUID, Money } = sdkTypes;
const sharetribeSdk = require('sharetribe-flex-sdk');
const sdk = sharetribeSdk.createInstance({
  clientId: process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID,
});
// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;

const priceData = (price, intl) => {
  if (price && price.currency === config.currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openOrderModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), orderOpen: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeOrderModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'orderOpen');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const OrderPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    isOwnListing,
    unitType,
    onSubmit,
    title,
    author,
    onManageDisableScrolling,
    timeSlots,
    fetchTimeSlotsError,
    history,
    location,
    intl,
    onFetchTransactionLineItems,
    onContactUser,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    currentUser,
    onSendShippingRequest,
    editButton,
    setShippingModalOpen,
    shippingModalOpen,
    oneSizeSystem,
  } = props;

  const listingId = listing.id.uuid;
  const [isFavourite, setIsFavourite] = useState(false);
  const [favListingsArray, setFavListingsArray] = useState([]);
  const [bumpListingModalOpen, setBumpListingModalOpen] = useState(false);
  const [listingIsBumped, setListingIsBumped] = useState(false);
  const [bumpWardrobeSelected, setBumpWardrobeSelected] = useState(false);

  useEffect(() => {
    sdk.currentUser
      .show()
      .then(res => {
        if (
          res.data.data.attributes.profile.privateData &&
          res.data.data.attributes.profile.privateData.favListingsArray
        ) {
          setFavListingsArray(res.data.data.attributes.profile.privateData.favListingsArray);
          const listingsIdsArray = res.data.data.attributes.profile.privateData.favListingsArray;
          const isFavourite = listingsIdsArray.find(id => {
            return id === listingId;
          });
          if (isFavourite) {
            setIsFavourite(true);
          }
        }
      })
      .catch(e => console.log(e));
  }, []);

  const addToFavourites = action => {
    if (action === 'add') {
      console.log([...favListingsArray, listingId]);
      return sdk.currentUser
        .updateProfile({
          privateData: {
            favListingsArray: [...favListingsArray, listingId],
          },
        })
        .then(resp => {
          return setIsFavourite(true);
        })
        .catch(e => console.log(e));
    } else {
      const favListingsArrayCopy = [...favListingsArray];

      for (var i = 0; i < favListingsArrayCopy.length; i++) {
        if (favListingsArrayCopy[i] === listingId) {
          favListingsArrayCopy.splice(i, 1);
        }
      }

      return sdk.currentUser
        .updateProfile({
          privateData: {
            favListingsArray: favListingsArrayCopy,
          },
        })
        .then(resp => {
          return setIsFavourite(false);
        })
        .catch(e => console.log(e));
    }
  };

  const { publicData } = listing.attributes;
  const description = publicData.description ?? listing.attributes.description;

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;
  const isUnits = unitType === LINE_ITEM_UNITS;
  const shouldHaveBooking = isNightly || isDaily;

  const price = listing.attributes.price;
  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const showBookingDatesForm = shouldHaveBooking && hasListingState && !isClosed;
  const showClosedListingHelpText = listing.id && isClosed;
  const { formattedPrice, priceTitle } = priceData(price, intl);
  const isOrderOpen = !!parse(location.search).orderOpen;

  //handle original price

  const originalPriceAmount = publicData && publicData.originalPrice;
  const originalPriceObject =
    originalPriceAmount && new Money(originalPriceAmount, config.currency);
  const formattedOriginalPrice =
    originalPriceAmount && priceData(originalPriceObject, intl)?.formattedPrice;
  const originalPriceTitle =
    originalPriceAmount && priceData(originalPriceObject, intl)?.priceTitle;

  // The listing resource has a relationship: `currentStock`,
  // which you should include when making API calls.
  const currentStock = listing.currentStock?.attributes?.quantity;
  const isOutOfStock = config.listingManagementType === 'stock' && currentStock === 0;
  // Show form only when stock is fully loaded. This avoids "Out of stock" UI by
  // default before all data has been downloaded.
  const showProductOrderForm =
    config.listingManagementType === 'stock' && typeof currentStock === 'number';
  const { pickupEnabled, shippingEnabled } = listing?.attributes?.publicData || {};

  const subTitleText = showClosedListingHelpText
    ? intl.formatMessage({ id: 'OrderPanel.subTitleClosedListing' })
    : null;

  const unitTranslationKey = isNightly
    ? 'OrderPanel.perNight'
    : isDaily
    ? 'OrderPanel.perDay'
    : 'OrderPanel.perUnit';

  const authorDisplayName = userDisplayNameAsString(author, '');

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.orderTitle);

  const user = listing.author;
  const userIsCurrentUser = user && user.type === 'currentUser';
  const ensuredCurrentUser = ensureCurrentUser(currentUser);
  const ensuredUser = userIsCurrentUser ? ensureCurrentUser(user) : ensureUser(user);
  console.log(user);
  console.log(ensuredCurrentUser);
  const isProvider = user?.id?.uuid === ensuredCurrentUser?.id?.uuid;
  console.log(isProvider);

  const city =
    listing?.attributes?.publicData?.location?.address &&
    listing?.attributes?.publicData?.location?.address.split(',')[0];

  const now = new Date().getTime();
  const bumpedListing = listingIsBumped || listing?.attributes?.publicData?.bumped;
  const isBumpStillAvailable = listing?.attributes?.publicData?.bumpEnd > now;

  const bumpedWardrobe = currentUser && currentUser.attributes.profile.publicData.bumped;
  const bumpedWardrobeStillAvailable =
    currentUser?.attributes?.profile?.publicData?.bumpEnd &&
    currentUser?.attributes?.profile?.publicData?.bumpEnd > now;

  const productSubcategoryConfig = findConfigForSelectFilter(
    'productSubcategory',
    config.custom.filters
  );
  const productSubcategory =
    publicData &&
    publicData.productSubcategory &&
    productSubcategoryConfig.options.find(o => o.key === publicData.productSubcategory).label;

  // const compositionConfig = findConfigForSelectFilter('composition', config.custom.filters);
  // const composition =
  //   publicData &&
  //   publicData.composition &&
  //   isArray(publicData.composition) &&
  //   publicData.composition
  //     .map(c => compositionConfig.options.find(o => o.key === c).label)
  //     .join(', ');

  const conditionConfig = findConfigForSelectFilter('condition', config.custom.filters);
  const condition =
    publicData &&
    publicData.condition &&
    conditionConfig.options.find(o => o.key === publicData.condition).label;

  const defects = publicData && publicData.defects;

  const productColorConfig = findConfigForSelectFilter('productColor', config.custom.filters);
  const productColor =
    publicData &&
    publicData.productColor &&
    isArray(publicData.productColor) &&
    publicData.productColor
      .map(c => {
        const foundColor = productColorConfig.options.find(o => o.key === c);
        if (foundColor.label?.props?.id) {
          return intl.formatMessage({ id: foundColor.label?.props?.id });
        } else {
          return foundColor.label;
        }
      })
      .join(', ');

  const sizeConfig = findConfigForSelectFilter('size', config.custom.filters);
  const size =
    publicData && publicData.size && sizeConfig.options.find(o => o.key === publicData.size).label;

  const keyCategoryConfig = findConfigForSelectFilter('keyCategory', config.custom.filters);
  const keyCategory =
    publicData &&
    publicData.keyCategory &&
    keyCategoryConfig.options.find(o => o.key === publicData.keyCategory[0])?.label;

  const productCategoryConfig = findConfigForSelectFilter('productCategory', config.custom.filters);
  const productCategory =
    publicData &&
    publicData.productCategory &&
    productCategoryConfig.options.find(o => o.key === publicData.productCategory).label;

  const productTypeConfig = findConfigForSelectFilter('productType', config.custom.filters);
  const productType =
    publicData &&
    publicData.productType &&
    productTypeConfig.options.find(o => o.key === publicData.productType).label;

  return (
    <div className={classes}>
      <ModalInMobile
        containerClassName={css.modalContainer}
        id="BookingDatesFormInModal"
        isModalOpenOnMobile={isOrderOpen}
        onClose={() => closeOrderModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <div className={css.modalHeading}>
          <h1 className={css.title}>{title}</h1>
        </div>

        <div className={css.orderHeading}>
          <h2 className={titleClasses}>
            {title}
            {/* {subTitleText ? <div className={css.orderHelp}>{subTitleText}</div> : null} */}
            <div className={css.favButtonWrapper}>
              {!isFavourite ? (
                <FavoriteBorderIcon
                  onClick={() => addToFavourites('add')}
                  className={css.favButtonNotSelected}
                />
              ) : (
                <FavoriteIcon
                  onClick={() => addToFavourites('remove')}
                  className={css.favButtonSelected}
                />
              )}
            </div>
          </h2>
          {/* {editButton} */}
        </div>
        <div className={css.priceWrapper}>
          <p className={css.priceCut}>
            {originalPriceAmount && formatMoney(intl, originalPriceObject)}
          </p>
          <p className={originalPriceAmount ? css.price : css.singlePrice}>
            {formatMoney(intl, price)}
          </p>
        </div>
        <div className={css.author}>
          <AvatarMedium user={author} className={css.providerAvatar} />
          <div className={css.authorInfoWrapper}>
            <div>
              <FormattedMessage id="ListingPage.listedBy" />
              <NamedLink
                className={css.link}
                name="ProfilePage"
                params={{ id: ensuredUser?.id?.uuid }}
              >
                <FormattedMessage
                  id="ListingPage.viewProfileLink"
                  values={{ name: authorDisplayName }}
                />
              </NamedLink>
            </div>

            {/* <div className={css.locationWrapper}>
              <LocationOnOutlinedIcon /> {city}
            </div> */}
          </div>
          {currentUser && isOwnListing ? (
            <div className={css.dualButtonsWrapper}>
              {/* <div
                className={bumpedListing ? css.sendMessageWrapperDisabled : css.sendMessageWrapper}
                onClick={() => {
                  if (!bumpedListing || !isBumpStillAvailable) {
                    setBumpListingModalOpen(true);
                  }
                }}
              >
                {bumpedListing && isBumpStillAvailable ? (
                  <FormattedMessage id="ListingPage.listingIsBumped" />
                ) : (
                  <FormattedMessage id="ListingPage.bumpThisListing" />
                )}
              </div> */}

              <NamedLink
                name="EditListingPage"
                className={css.sendMessageWrapper}
                params={props.editParams}
              >
                <FormattedMessage id="ListingPage.editListing" />
              </NamedLink>

              <div
                className={bumpedWardrobe ? css.sendMessageWrapperDisabled : css.sendMessageWrapper}
                onClick={() => {
                  if (!bumpedWardrobe || !bumpedWardrobeStillAvailable) {
                    setBumpListingModalOpen(true);
                    setBumpWardrobeSelected(true);
                  }
                }}
              >
                {bumpedWardrobe && bumpedWardrobeStillAvailable ? (
                  <FormattedMessage id="ListingPage.wardrobeIsBumped" />
                ) : (
                  <FormattedMessage id="ListingPage.bumpThisWardrobe" />
                )}
              </div>
            </div>
          ) : (
            <div className={css.dualButtonsWrapper}>
              <div className={css.sendMessageWrapper} onClick={() => onContactUser(ensuredUser)}>
                {/* <img src={messageIcon} className={css.messageIcon} />{' '} */}
                <FormattedMessage id="ListingPage.messageSeller" />
              </div>

              <div
                className={css.sendMessageWrapper}
                onClick={() => {
                  if (!currentUser) {
                    const state = {
                      from: `${location.pathname}${location.search}${location.hash}`,
                    };

                    // signup and return back to listingPage.
                    history.push(
                      createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}),
                      state
                    );
                  } else {
                    setShippingModalOpen(true);
                  }
                }}
              >
                {/* <img src={messageIcon} className={css.messageIcon} />{' '} */}
                <FormattedMessage id="ProductOrderForm.ctaButton" />
              </div>
            </div>
          )}

          {/* <FormattedMessage id="OrderPanel.soldBy" values={{ name: authorDisplayName }} /> */}
        </div>

        <div className={css.infoWrapper}>
          {description && <div className={css.description}>{description}</div>}

          <div className={css.type}>
            <strong>
              <FormattedMessage id="ListingPage.typeLabel" />
            </strong>
            <span>{productSubcategory ?? 'Not provided'}</span>
          </div>

          <div className={css.brand}>
            <strong>
              <FormattedMessage id="ListingPage.brandLabel" />
            </strong>
            <span>{publicData?.brand ?? 'Not provided'}</span>
          </div>

          <div className={css.otherInfo}>
            <Accordion
              sx={{
                background: 'transparent',
                border: 'none',
                boxShadow: 'none',
                borderRadius: '0px !important',
                '& .MuiButtonBase-root': {
                  padding: '0px',
                },
                '& .MuiAccordionDetails-root': {
                  padding: '0px',
                },
              }}
              // MuiAccordionDetails-root css-15v22id-MuiAccordionDetails-root
              defaultExpanded={true}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>
                  <strong className={css.accordionLabel}>
                    <FormattedMessage id="ListingPage.productDetailsLabel" />
                  </strong>
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography>
                  <div className={css.otherInfoItems}>
                    <div className={css.otherInfoItem}>
                      <div className={css.otherInfoItemLabel}>
                        <FormattedMessage id="ListingPage.sizeLabel" />
                      </div>
                      <div className={css.otherInfoItemValue}>
                        {oneSizeSystem
                          ? oneSizeSystem
                          : size ?? <FormattedMessage id="ListingPage.notProvided" />}
                      </div>
                    </div>

                    <div className={css.otherInfoItem}>
                      <div className={css.otherInfoItemLabel}>
                        <FormattedMessage id="ListingPage.colourLabel" />
                      </div>
                      <div className={css.otherInfoItemValue}>
                        {productColor ?? <FormattedMessage id="ListingPage.notProvided" />}
                      </div>
                    </div>

                    {/* <div className={css.otherInfoItem}>
                      <div className={css.otherInfoItemLabel}>Composition</div>
                      <div className={css.otherInfoItemValue}>{composition ?? 'Not provided'}</div>
                    </div> */}

                    <div className={css.otherInfoItem}>
                      <div className={css.otherInfoItemLabel}>
                        <FormattedMessage id="ListingPage.conditionLabel" />
                      </div>
                      <div className={css.otherInfoItemValue}>
                        {condition ?? <FormattedMessage id="ListingPage.notProvided" />}
                      </div>
                    </div>

                    {defects && (
                      <div className={css.otherInfoItem}>
                        <div className={css.otherInfoItemLabel}>
                          <FormattedMessage id="ListingPage.defectsLabel" />
                        </div>
                        <div className={css.otherInfoItemValue}>{defects}</div>
                      </div>
                    )}
                  </div>
                </Typography>
              </AccordionDetails>
            </Accordion>
          </div>

          <div className={css.categories}>
            <strong>
              <FormattedMessage id="ListingPage.categoriesLabel" />{' '}
            </strong>
            {keyCategory && (
              <a href={`/s?pub_keyCategory=has_any%3A${publicData.keyCategory}`}>{keyCategory} </a>
            )}
            {productCategory && (
              <a href={`/s?pub_productCategory=${publicData.productCategory}`}>
                {productCategory}{' '}
              </a>
            )}
            {productSubcategory && (
              <a href={`/s?pub_productSubcategory=${publicData.productSubcategory}`}>
                {productSubcategory}{' '}
              </a>
            )}
            {productType && (
              <a href={`/s?pub_productType=${publicData.productType}`}>{productType}</a>
            )}
          </div>
        </div>

        {showBookingDatesForm ? (
          <BookingDatesForm
            className={css.bookingForm}
            formId="OrderPanelBookingDatesForm"
            submitButtonWrapperClassName={css.bookingDatesSubmitButtonWrapper}
            unitType={unitType}
            onSubmit={onSubmit}
            price={price}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            timeSlots={timeSlots}
            fetchTimeSlotsError={fetchTimeSlotsError}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
          />
        ) : showProductOrderForm && !isOwnListing ? (
          <ProductOrderForm
            formId="OrderPanelProductOrderForm"
            onSubmit={onSubmit}
            price={price}
            currentStock={currentStock}
            pickupEnabled={pickupEnabled}
            shippingEnabled={shippingEnabled}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            onFetchTransactionLineItems={onFetchTransactionLineItems}
            onContactUser={onContactUser}
            lineItems={lineItems}
            fetchLineItemsInProgress={fetchLineItemsInProgress}
            fetchLineItemsError={fetchLineItemsError}
            currentUser={currentUser}
            onSendShippingRequest={onSendShippingRequest}
            onManageDisableScrolling={onManageDisableScrolling}
            setShippingModalOpen={setShippingModalOpen}
            shippingModalOpen={shippingModalOpen}
          />
        ) : null}
      </ModalInMobile>
      <div className={css.openOrderForm}>
        <div className={css.priceContainer}>
          <div className={css.priceValueCut} title={originalPriceTitle}>
            {formattedOriginalPrice}
          </div>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>
          {/* <div className={css.perUnit}>
            <FormattedMessage id={unitTranslationKey} />
          </div> */}
        </div>

        {isOwnListing ? (
          <div className={css.mButtonsWrapper}>
            {/* <div
              className={bumpedListing ? css.sendMessageWrapperDisabled : css.sendMessageWrapper}
              onClick={() => {
                if (!bumpedListing || !isBumpStillAvailable) {
                  setBumpListingModalOpen(true);
                }
              }}
            >
              {bumpedListing && isBumpStillAvailable ? (
                <FormattedMessage id="ListingPage.listingIsBumped" />
              ) : (
                <FormattedMessage id="ListingPage.bumpThisListing" />
              )}
            </div> */}

            <NamedLink
              name="EditListingPage"
              className={css.sendMessageWrapper}
              params={props.editParams}
            >
              <FormattedMessage id="ListingPage.editListing" />
            </NamedLink>

            <div
              className={bumpedWardrobe ? css.sendMessageWrapperDisabled : css.sendMessageWrapper}
              onClick={() => {
                if (!bumpedWardrobe || !bumpedWardrobeStillAvailable) {
                  setBumpListingModalOpen(true);
                  setBumpWardrobeSelected(true);
                }
              }}
            >
              {bumpedWardrobe && bumpedWardrobeStillAvailable ? (
                <FormattedMessage id="ListingPage.wardrobeIsBumped" />
              ) : (
                <FormattedMessage id="ListingPage.bumpThisWardrobe" />
              )}
            </div>
          </div>
        ) : (
          <div className={css.mButtonsWrapper}>
            <div className={css.sendMessageWrapper} onClick={() => onContactUser(ensuredUser)}>
              {/* <img src={messageIcon} className={css.messageIcon} />{' '} */}
              <FormattedMessage id="ListingPage.messageSeller" />
            </div>

            {/* {isClosed ? (
              <div className={css.closedListingButton}>
                <FormattedMessage id="OrderPanel.closedListingButtonText" />
              </div>
            ) : ( */}
            <Button
              rootClassName={css.orderButton}
              onClick={() => {
                if (!currentUser) {
                  const state = {
                    from: `${location.pathname}${location.search}${location.hash}`,
                  };

                  // signup and return back to listingPage.
                  history.push(
                    createResourceLocatorString('SignupPage', routeConfiguration(), {}, {}),
                    state
                  );
                } else {
                  setShippingModalOpen(true);
                }
              }}
              disabled={isOutOfStock}
            >
              {/* {isOutOfStock ? (
                  <FormattedMessage id="OrderPanel.ctaButtonMessageNoStock" />
                ) : ( */}
              <FormattedMessage id="OrderPanel.ctaButtonMessage" />
              {/* )} */}
            </Button>
            {/* )} */}
          </div>
        )}
      </div>

      <Modal
        isOpen={shippingModalOpen}
        onClose={e => {
          e.preventDefault();
          setShippingModalOpen(false);
        }}
        onManageDisableScrolling={onManageDisableScrolling}
        wide={true}
      >
        <div className={css.modalContentWrapper}>
          <center className={css.deliveryModalTitle}>
            <h2>
              <FormattedMessage id="ProductOrderForm.deliveryModalTitle" />
            </h2>
          </center>
          <DeliveryAddressForm
            currentUser={currentUser}
            intl={intl}
            handleSubmit={onSendShippingRequest}
            quantity={1}
            handleModalOpen={setShippingModalOpen}
            modalOpen={shippingModalOpen}
            isProvider={isProvider}
          />
        </div>
      </Modal>

      <Modal
        isOpen={bumpListingModalOpen}
        onClose={() => {
          setBumpListingModalOpen(false);
          setBumpWardrobeSelected(false);
        }}
        onManageDisableScrolling={onManageDisableScrolling}
      >
        <BumpListingForm
          listing={bumpWardrobeSelected ? null : listing}
          success={listingIsBumped}
          setSuccess={setListingIsBumped}
          currentUser={currentUser}
        />
      </Modal>
    </div>
  );
};

OrderPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.lineItemUnitType,
  timeSlots: null,
  fetchTimeSlotsError: null,
  lineItems: null,
  fetchLineItemsError: null,
};

OrderPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.lineItemUnitType,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  onManageDisableScrolling: func.isRequired,
  timeSlots: arrayOf(propTypes.timeSlot),
  fetchTimeSlotsError: propTypes.error,
  onFetchTransactionLineItems: func.isRequired,
  onContactUser: func,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(withRouter, injectIntl)(OrderPanel);
